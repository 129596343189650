import React from "react";
import { CCTabPanel, CCTabs } from "styles/components";
import { useIntl } from "utils/language";
import DetailModify from "../DetailModify";
import { Controller, useFormContext } from "react-hook-form";
import { Box } from "@material-ui/core";

const Tabs = props => {
  const intl = useIntl();
  const { value, handleOnChangeTab } = props;
  const { control } = useFormContext();

  const tabList = [
    intl.formatMessage({ id: "inventoryReceiving" }),
    intl.formatMessage({ id: "inventoryShipping" })
  ];

  return (
    <>
      <Controller
        name={"inOut"}
        control={control}
        defaultValue={0}
        render={props => {
          const { value: inputValue, onChange } = props.field;
          console.log("inputValue", inputValue)
          return (
            <Box>
              <CCTabs
                size={"medium"}
                tabList={tabList}
                selectedTab={inputValue}
                onChangeTab={(e) => {
                  console.log("onChangeTab", e)
                  handleOnChangeTab && handleOnChangeTab(e);
                  onChange(e);
                }}
              />
              {tabList?.map((d, i) => (
                <CCTabPanel key={i} value={inputValue} index={i}>
                  <DetailModify value={value} inOut={inputValue} />
                </CCTabPanel>
              ))}
            </Box>
          );
        }}
      />
    </>
  );
};

export default Tabs;
