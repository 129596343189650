import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { CCTextField } from 'styles/src/components';
import { makeList } from 'types';
import CHART from 'types/chart';

const _status = makeList(CHART.TREATMENT_PLAN.STATUS.TREATMENT);

const TreatmentStatusItem = (props) => {
  const { rowIndex, rowData, onChange, pl } = props;
  return (
    <CCTextField
      value={rowData.status}
      variant={'outlined'}
      margin={'dense'}
      disabled={
        pl.status !== CHART.TREATMENT_PLAN.STATUS.PLAN.CONFIRMATION.value
      }
      select
      onChange={(e) => {
        let _temp = {
          rowIndex: rowIndex,
          rowData: { ...rowData },
        };
        _temp.rowData.status = e.target.value;
        onChange && onChange(_temp);
      }}
    >
      {_status.map((item) => {
        return (
          <MenuItem
            key={'TreatmentStatusItem' + rowIndex + item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        );
      })}
    </CCTextField>
  );
};
export default TreatmentStatusItem;
