import React from "react";
import CCDateTimeToolbarHeader from "../CCDateTimeToolbarHeader";
import CCDateTimeToolbarTab from "../CCDateTimeToolbarTab";

const CCDateTimeToolbar = props => {
  return (
    <>
      <CCDateTimeToolbarHeader {...props} />
      <CCDateTimeToolbarTab {...props} />
    </>
  );
};

export default CCDateTimeToolbar;
