import React from "react";

const WidthIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.017 7L21 11.997 16.022 17l-1.427-1.444 2.554-2.568H6.85l2.555 2.568L7.978 17 3 11.997 7.983 7l1.41 1.418-2.535 2.548h10.283l-2.535-2.548L16.017 7z"
    />
  </svg>
);
export default WidthIcon;
