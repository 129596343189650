import moment from 'moment';

export const formatUnixInSecToYYYYMMDD = (date) => {
  if (!date) return '';

  return moment(date * 1000).format('L');
};

export const truncate = (input, length = 15) => {
  if (input && input.length > length) {
    return input.substring(0, length) + '...';
  }
  return input;
};

export const shortDateFormat = (lang = "en") => {
  switch (lang) {
    case "vi":
    case "ru":
    case "ar":
      return "DD/MM";
    default:
      return "MM/DD"
  }
}

export const monthYearFormat = (lang = "en") => {
  switch (lang) {
    case "ko":
      return "YYYY/MM";
      break;
    default:
      return "MM/YYYY"
  }
}