import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "utils/language";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useProductValidation from "validations/useProductValidation";
import { getTimestamp } from "utils/datetime";
import { Box, Checkbox } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  CCButton,
  CCDialog,
  CCDialogContent,
  CCDialogTitle,
  CCDialogActions,
  CCTypography,
  CCTooltip,
  CCIconButton
} from "styles/src/components";
import { CVTextField, InfiniteTable } from "components";
import axios from "axios";
import clsx from "clsx";
import { DetailResults, ImageResults } from "./components";
import { LocalizeVariable } from "utils/env";
import { CalculationIcon, PictureIcon } from "styles/src/themes/common/icons";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/utils/TableItems";
import PurchaseCheckDialog from "views/Normal/InventoryManagement/Component/Purchase/Component/StatusDialog/PurchaseCheckDialog";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 2000,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  },
  head__field: {
    width: 300,
    display: "flex",
    flexWrap: "wrap"
    // gap: 5,
  },
  supplier__field: {
    width: 400
  },
  trading__number__field: {
    width: 300
  },
  total__estimate__field: {
    width: 150
  },
  money__field: {
    width: 120
  },
  background: {
    background: theme.palette.background.default
  },
  head__an__field: {
    width: 120
  },
  head__an__search__field: {
    width: 230
  },
  button__filter: {
    marginTop: "8px"
  },
  content__top__border: {
    height: "100%",
    width: "100%",
    // backgroundColor: theme.palette.result.main,
    borderTop: `1px solid ${theme.palette.border.main}`,
    // borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },

  content__bottom__border: {
    height: "100%",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  widthPer60: {
    width: "60%",
    padding: "4px 6px"
  },
  widthPer40: {
    width: "20%"
  },
  width100: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10
  },
  width50Per: {
    width: "50%"
  },
  width60: {
    width: "60%"
  },
  width40: {
    width: "40%"
  },
  width70: {
    width: "70%"
  },
  width30: {
    width: "30%"
  },
  contents: {
    overflowY: "hidden",
    width: 1200,
    minWidth: 1075,
    height: 800
  },
  detailField: {
    width: 900
  },
  innerNext: {
    width: 500,
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginBottom: "-100px"
  },
  divider: {
    height: "80%",
    width: 1,
    borderLeft: `1px solid ${theme.palette.border.main}`,
    margin: 5
  },
  actions: {
    height: 40
  },
  table__root: {
    height: 500,
    backgroundColor: theme.palette.common.white,
    boxSizing: "border-box",
    width: 800
  },
  table__container: {
    height: "97%"
  },
  table__item__table: {
    height: "calc(100% - 60px - 30px)",
    width: "100%",
    // alignItems: "center",
    // textAlign: "center",
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: "8px"
  },
  table__title__table: {
    height: 30,
    display: "space-between"
  }
}));

const EstimateDetailDialog = props => {
  const intl = useIntl();
  const initData = {
    hospital: "",
    important: false,
    unit: 1,
    currentCnt: 0,
    optCnt: 0,
    alert: false,
    editDate: getTimestamp(),
    editorID: "",
    canExpire: true,
    expire: {
      expireCount: 0,
      expireDate: getTimestamp()
    },
    binCnt: 0,
    barcode: false,
    isAppropriate: false
  };

  const { open, onClose, product = [] } = props;
  const { schema } = useProductValidation();

  const classes = useStyles();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [orderDialog, setOrderDialog] = useState(false);

  const fetchVendor = useCallback(async () => {
    try {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_PAM_URL}/api/v1/order/${product}`,
        // url: "https://server-test.cleverpam.com/api/v1/order/21-12-17-00184",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          console.log("%% 품목상세정보 : ", response?.data);
          setProducts(response?.data);
        })
        .catch(e => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      fetchVendor();
    }
  }, [product, fetchVendor]);

  return (
    <>
      <FormProvider {...methods}>
        <CCDialog open={open} onClose={onClose} maxWidth={"lg"}>
          <CCDialogTitle onCloseButton={onClose} className={classes.title}>
            {intl.formatMessage({ id: "detailInfo" })}
          </CCDialogTitle>
          <CCDialogContent className={classes.contents}>
            <form name={"patientReservationDialogForm"}>
              <Grid container wrap={"nowrap"} justifyContent={"space-between"}>
                <Grid item className={classes.detailField}>
                  <Grid item>
                    <CVTextField
                      className={classes.trading__number__field}
                      variant={"outlined"}
                      margin={"dense"}
                      name={"searchField"}
                      value={products?.orderNumber || ""}
                      disabled={true}
                      readOnly={true}
                      label={intl.formatMessage({ id: "supplierNumber" })}
                    />
                    <CVTextField
                      className={classes.money__field}
                      variant={"outlined"}
                      margin={"dense"}
                      name={"searchField"}
                      value={intl.formatMessage({
                        id: `pam${products?.status}`
                      })}
                      disabled={true}
                      readOnly={true}
                      label={intl.formatMessage({ id: "supplierNumber" })}
                    />
                  </Grid>
                  <Grid item>
                    <CVTextField
                      className={classes.supplier__field}
                      variant={"outlined"}
                      margin={"dense"}
                      name={"searchField"}
                      value={products?.Material?.name || ""}
                      disabled={true}
                      readOnly={true}
                      label={intl.formatMessage({ id: "partner" })}
                    />
                    <CVTextField
                      className={classes.total__estimate__field}
                      variant={"outlined"}
                      margin={"dense"}
                      name={"searchField"}
                      value={products?.totalSum || ""}
                      disabled={true}
                      readOnly={true}
                      label={intl.formatMessage(
                        { id: "totalEstimateAmount" },
                        { cost: LocalizeVariable.currencyUnit }
                      )}
                    />
                  </Grid>
                  <Box className={classes.table__root}>
                    <Grid
                      className={classes.table__container}
                      container
                      direction="column"
                    >
                      <Grid className={classes.table__item__table} item>
                        <InfiniteTable
                          disableTypography={true}
                          // inputRef={ref}
                          title={
                            <>
                              <Box className={classes.table__title__table}>
                                <CCTypography
                                  variant={"h5"}
                                  style={{ marginBottom: 15 }}
                                >
                                  {intl.formatMessage({ id: "product" })}
                                </CCTypography>
                              </Box>
                            </>
                          }
                          // classes={tableClasses}
                          heads={[
                            {
                              key: "Material",
                              headComponent: ({ cellData, rowData }) => {
                                return (
                                  <Checkbox
                                    disableRipple
                                    // checked={rowData}
                                  />
                                );
                              },
                              width: 60,
                              component: ({ cellData, rowData }) => {
                                let _checked = selectedProduct?.includes(
                                  rowData
                                );
                                return (
                                  <TableItem
                                    disableTypography
                                    label={
                                      <Checkbox
                                        style={{ padding: 10 }}
                                        disableRipple
                                        className={classes.row__focus}
                                        checked={_checked}
                                        onChange={e => {
                                          if (e.target.checked) {
                                            if (
                                              Boolean(
                                                selectedProduct?.length > 0
                                              )
                                            ) {
                                              setSelectedProduct([
                                                ...selectedProduct,
                                                rowData
                                              ]);
                                              // setSelectList([...selectList, rowData]);
                                            } else {
                                              setSelectedProduct([rowData]);
                                              // setSelectList([rowData]);
                                            }
                                          } else {
                                            setSelectedProduct(
                                              selectedProduct.filter(
                                                e => e !== rowData
                                              )
                                            );
                                            // setSelectList(
                                            //   selectList.filter(e => e !== rowData)
                                            // );
                                          }
                                        }}
                                      />
                                    }
                                  />
                                );
                              }
                            },
                            {
                              key: "",
                              headComponent: () => (
                                <TableHeader label={"registerNumber"} />
                              ),
                              width: 100,
                              label: () => (
                                <TableHeader label={"registerNumber"} />
                              ),
                              component: ({ rowIndex }) => {
                                return (
                                  <TableItem
                                    label={rowIndex ? rowIndex + 1 : 1}
                                  />
                                );
                              }
                            },
                            {
                              key: "name",
                              headComponent: () => (
                                <TableHeader
                                  label={intl.formatMessage({
                                    id: "productName"
                                  })}
                                />
                              ),
                              width: 250,
                              component: ({ cellData }) => {
                                return (
                                  <TableItem
                                    className={{
                                      [classes.change__color]:
                                        cellData === "Quoted"
                                    }}
                                    label={cellData ? cellData : ""}
                                  />
                                );
                              }
                            },
                            {
                              key: "manufacturer",
                              width: 100,
                              headComponent: () => (
                                <TableHeader
                                  label={intl.formatMessage({
                                    id: "manufacture"
                                  })}
                                />
                              ),
                              component: ({ cellData }) => {
                                // let _date = new Date(cellData).toString();

                                return (
                                  <TableItem label={cellData ? cellData : ""} />
                                );
                              }
                            },
                            {
                              key: "unit1",
                              width: 100,
                              headComponent: () => (
                                <TableHeader
                                  // label={intl.formatMessage({ id: "partner" })}
                                  label={intl.formatMessage({ id: "unit" })}
                                />
                              ),
                              component: ({ cellData }) => (
                                <TableItem label={cellData ? cellData : ""} />
                              )
                            },
                            {
                              key: "unitPrice",
                              width: 200,
                              headComponent: () => (
                                <TableHeader
                                  label={intl.formatMessage({
                                    id: "payAmount"
                                  })}
                                />
                              ),
                              component: ({ cellData }) => {
                                return (
                                  <TableItem
                                    label={
                                      Number(cellData) !== 0 ? (
                                        cellData
                                      ) : (
                                        <CCTypography
                                          className={classes.disabled__color}
                                        >
                                          {intl.formatMessage({
                                            id: "requestForQuote"
                                          })}
                                        </CCTypography>
                                      )
                                    }
                                    // label={cellData?.length > 1 ? `${cellData[0]?.name} 외 ${cellData?.length - 1} 품목` : cellData ? cellData[0]?.name : ""}
                                  />
                                );
                              }
                            },
                            {
                              key: "image",
                              width: 50,
                              headComponent: () => (
                                <TableHeader
                                  label={intl.formatMessage({ id: "image" })}
                                />
                              ),
                              component: ({ cellData }) => {
                                return (
                                  <TableItem
                                    label={
                                      <CCTooltip
                                        title={
                                          <>
                                            <Box
                                              style={{
                                                width: 300,
                                                height: 300,
                                                backgroundImage: `url(${cellData?.url})`,
                                                backgroundPosition:
                                                  "center center",
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat"
                                              }}
                                            />
                                          </>
                                        }
                                      >
                                        <CCIconButton
                                          style={{ padding: 0, margin: 0 }}
                                          disableRipple
                                          variant={"text"}
                                          size={"small"}
                                        >
                                          {cellData?.url ? <PictureIcon /> : ""}
                                        </CCIconButton>
                                      </CCTooltip>
                                    }
                                  />
                                );
                              }
                            }
                          ]}
                          contents={products?.OrderItems || []}
                          rowHoverHightlight={true}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/*<ProductResults products={products} />*/}
                </Grid>
                <Grid item>
                  <Box className={classes.divider} />
                </Grid>
                <Grid item className={classes.innerNext}>
                  <DetailResults result={[]} info={products} />
                </Grid>
              </Grid>
              <Grid container wrap={"nowrap"} style={{ marginTop: -40 }}>
                <ImageResults images={products?.OrderImages} />
              </Grid>
            </form>

            {Boolean(orderDialog) && (
              <PurchaseCheckDialog
                open={Boolean(orderDialog)}
                onClose={() => setOrderDialog(false)}
                selectedProduct={[selectedProduct]}
                productList={products}
                imageList={products?.OrderImages}
                vendorList={products?.Material?.id}
                // product={orderDialog}
              />
            )}
          </CCDialogContent>

          <CCDialogActions className={classes.actions}>
            <CCButton
              style={{
                height: 35,
                // paddingTop: 0,
                // paddingBottom: 0,
                width: 110,
                marginRight: 1000
              }}
              variant={"outlined"}
              color={"secondary"}
              startIcon={<CalculationIcon />}
              onClick={() => {
                setOrderDialog(selectedProduct);
              }}
              disabled={
                products?.status === "Quote" || selectedProduct?.length === 0
              }
            >
              {intl.formatMessage({ id: "order" })}
            </CCButton>
            <CCButton
              className={clsx(classes.marginright__8)}
              onClick={() => onClose()}
              color={"primary"}
            >
              {intl.formatMessage({ id: "confirm" })}
            </CCButton>
          </CCDialogActions>
        </CCDialog>
      </FormProvider>
    </>
  );
};

export default EstimateDetailDialog;
