import { useQuery } from "@apollo/client";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { GET_CONFIG_PRICING } from "queries/setting";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCTable, CCTypography } from "styles/components";
import { translate } from "components"

const useStyles = makeStyles(theme => ({
  cell__price: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  cell__pros: {
    paddingLeft: 45,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center"
  },

  cell: {
    flex: 1,
    textAlignLast: "center",
    color: theme.palette.text.primary
  }
}));

const tableStyle = makeStyles(theme => ({
  root: {
    flex: 1
  },
  table__body: {
    backgroundColor: theme.palette.background.default,
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    },
    borderRight: "1px solid #b0bec5"
  },
  table__head__cell: {
    background: theme.palette.common.white
  }
}));

const ProsthesisPrice = props => {
  const { labId } = props;
  const classes = useStyles();
  const resultTableClasses = tableStyle();
  const intl = useIntl();

  const [arrayPros, setArrayPros] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { data, refetch, loading } = useQuery(GET_CONFIG_PRICING, {
    variables: {
      category: "prosthesisPriceSetting",
      labId
    },
    fetchPolicy: "network-only",
    onCompleted: e => {
      const arrayTabsContent =
        e.getConfig.prosthesisPriceSetting[0].items[0].item.contents;
      const newArray = arrayTabsContent.reduce((acc, cur) => {
        acc = acc.concat([
          ...cur.tabContents.map(item => ({
            prosthesis: item.material + " " + cur.category,
            price: item.price
          }))
        ]);
        return acc;
      }, []);
      setArrayPros(newArray);
    }
  });
  const heads = [
    {
      label: () => (
        <CCTypography>
          {translate(intl, "common.ui.prosthesis" )}
        </CCTypography>
      ),
      key: "prosthesis",
      className: clsx({
        [classes.cell]: true
      }),

      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell__pros}>
            <CCTypography>{`${cellData}`}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: () => (
        <CCTypography>
          <div>{translate(intl,  "settings.productPrice.price" )}</div>
        </CCTypography>
      ),
      key: "price",
      className: clsx({
        [classes.cell]: true
      }),
      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell__price}>
            <CCTypography>{`${cellData}`}</CCTypography>
          </Box>
        );
      }
    }
  ];

  return (
    <Grid container>
      <Grid item xs={6}>
        <CCTable
          classes={resultTableClasses}
          heads={heads}
          contents={arrayPros.slice(0, arrayPros.length / 2 + 1)}
        />
      </Grid>
      <Grid item xs={6}>
        <CCTable
          classes={resultTableClasses}
          heads={heads}
          contents={arrayPros.slice(arrayPros.length / 2 + 1)}
        />
      </Grid>
    </Grid>
  );
};

export default ProsthesisPrice;
