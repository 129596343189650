import { useMutation } from "@apollo/client";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { enqueueToast } from "components";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { CHECK_STAFF_ID, RESET_PASSWORD } from "queries/setting";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogSimple,
  CCTextField,
  CCTypography
} from "styles/components";
import { CVButton } from "components";
import { WarningIcon } from "styles/icons";
import { translate } from "components";

function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  // str = str.replace(/ + /g," ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
  return str;
}

const useStyles = makeStyles(theme => ({
  root: {},
  warning__color: { color: theme.palette.error.main },
  text: { marginTop: 8, width: 280 },
  disagree: { marginRight: 8 }
}));

const IdField = props => {
  const {
    value = "",
    onChange,
    errorOnChange,
    required,
    disabled,
    mode,
    ...others
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // value
  const [error, setError] = useState(!value);
  const [isIdUnavai, setIsIdUnavai] = useState(disabled ? -1 : 0);
  const [warningDialogVisible, setWarningDialogVisible] = useState(false);

  // graphql
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [checkStaffId] = useMutation(CHECK_STAFF_ID);

  // function
  const validateId = async id => {
    const ID_VALIDATION_REGEXP = /^([0-9a-z]{4,20})$/;
    if (!id || !ID_VALIDATION_REGEXP.test(id)) return false;
    return true;
  };

  const handleIdField = async e => {
    if (e.target.value.length > 20) return;
    onChange({
      ...e,
      target: { value: removeVietnameseTones(e.target.value) }
    });
    setIsIdUnavai(0);
    const isValid = await validateId(e.target.value);
    setError(!isValid);
  };

  // useEffect
  useEffect(() => {
    errorOnChange(error || isIdUnavai !== -1);
  }, [errorOnChange, error, isIdUnavai]);

  // inReturn
  const checkIDButton = (
    <CCButton
      disabled={error}
      variant={"contained"}
      size={"small"}
      onClick={async () => {
        const id = value;
        const isValid = await validateId(id);

        const { data } = await checkStaffId({ variables: { id } });
        let check = data?.checkStaffId;
        setIsIdUnavai(check ? 1 : -1);

        if (!isValid || check) {
          setError(true);
          return;
        }
      }}
    >
      {translate( intl,"signUp.ui.checkId" )}
    </CCButton>
  );

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <CCTextField
            disabled={disabled}
            value={value}
            onChange={handleIdField}
            required={required}
            error={error || isIdUnavai !==  -1}
            {...others}
            InputProps={{
              inputProps: {
                maxLength: 20
              },
              endAdornment: mode === 'add' ? checkIDButton : null
            }}
          />
        </Grid>

        <Grid item hidden={disabled || value.length < 4 || isIdUnavai <= 0}>
          <CCTypography color="error">
            {intl.formatMessage({
              id: "settings.employee.unavailableId"
            })}
            .
          </CCTypography>
        </Grid>
        <Grid item hidden={disabled || value.length < 4 || isIdUnavai >= 0}>
          <CCTypography color="primary">
            {intl.formatMessage({
              id: "settings.employee.availableId"
            })}
          </CCTypography>
        </Grid>
        <Grid item hidden={!disabled}>
          <CVButton
            permission={"employeeInfoGeneralSetting"}
            color="normal"
            variant="contained"
            onClick={() => {
              setWarningDialogVisible(true);
            }}
          >
            {translate( intl,"settings.employee.resetPassword" )}
          </CVButton>
        </Grid>
      </Grid>

      {disabled && warningDialogVisible && (
        <CCDialogSimple
          open={warningDialogVisible}
          isHeader={false}
          onClose={() => setWarningDialogVisible(false)}
          onCloseButton={() => setWarningDialogVisible(false)}
          contents={
            <Box>
              <Box>
                <WarningIcon className={classes.warning__color} />
              </Box>
              <Box className={classes.text}>
                <CCTypography>
                  {intl.formatMessage({
                    id: "settings.employee.resetPasswordWarning"
                  })}
                </CCTypography>
              </Box>
            </Box>
          }
          endActions={
            <>
              <CCButton
                className={classes.disagree}
                color="sub"
                onClick={() => {
                  setWarningDialogVisible(false);
                }}
              >
                {translate( intl,"no" )}
              </CCButton>
              <CCButton
                variant="contained"
                color="error"
                onClick={() => {
                  resetPassword({ variables: { id: value } }).then(() => {
                    enqueueToast(
                      enqueueSnackbar,
                      intl.formatMessage({
                        id: "settings.employee.resetPasswordSuccess"
                      })
                    );
                    setWarningDialogVisible(false);
                  });
                }}
              >
                {translate( intl,"yes" )}
              </CCButton>
            </>
          }
        />
      )}
    </>
  );
};

IdField.defaultProps = {
  value: "",
  variant: "outlined",
  label: "ID",
  margin: "dense",
  disabled: false,
  errorOnChange: () => {}
};

IdField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default IdField;
