import { makeStyles } from "@material-ui/core";
export default makeStyles(theme => ({
    button: {
      width: "70px",
      height: "30px",
      display: "flex",
      color: "white",
      cursor: "pointer",
      borderRadius: "18px",
      backgroundColor: theme.palette.sub.main,
      alignItems: "center",
      justifyContent: "center",
      marginRight: "8px"
    },
    width130: { width: 130 },
    selected: {
      backgroundColor: theme.palette.select.main
    }
  }));