import React from "react";

const StarIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5017 -192) translate(5017 192)"
            />
          </g>
          <path
            fill="currentColor"
            d="M14.43 10L12 2 9.57 10 2 10 8.18 14.41 5.83 22 12 17.31 18.18 22 15.83 14.41 22 10z"
            transform="translate(-5017 -192) translate(5017 192)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default StarIcon;
