import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { ErrorTooltip } from "components";
import { useCountValidation } from "validations";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { CCButton, CCDialogSimple, CCTextFieldNumber } from "styles/components";
import { useIntl, useLanguage } from "utils/language";

const useStyles = makeStyles(() => ({
  root: { overflow: "hidden", width: 345 },
  btn: { marginRight: 8 },
  w200: { width: 200 }
}));

const CountDialog = props => {
  const { open, onClose, value, onChange } = props;

  const { schema: PaymentScheme } = useCountValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: {
      count: String(value.count)
    },
    resolver: yupResolver(PaymentScheme)
  });
  const { errors } = methods;

  const onSave = () => {
    methods.handleSubmit(submit, () => {})();
  };

  const submit = e => {
    const count = Number(e.count);

    onChange({
      ...value,
      count: count,
      price: (value.price / value.count) * count
    });
  };

  const classes = useStyles();
  const [language] = useLanguage();
  const intl = useIntl();

  return (
    <CCDialogSimple
      maxWidth="md"
      open={open}
      onClose={onClose}
      onCloseButton={onClose}
      // onRefreshButton={() => {
      //   setField(value);
      // }}
      title={`${intl.formatMessage({
        id: "treatText"
      })} ${intl.formatMessage({
        id: "times"
      })}`}
      contents={
        <Controller
          name={"count"}
          control={methods?.control}
          defaultValue={
            methods.getValues("count") ? methods.getValues("count") : ""
          }
          render={props => {
            const { value, onChange } = props;

            return (
              <ErrorTooltip
                open={Boolean(errors?.count?.message)}
                errorMsg={errors?.count?.message}
              >
                <CCTextFieldNumber
                  className={clsx({ [classes.w200]: language === "ru" })}
                  label={intl.formatMessage({
                    id: "times"
                  })}
                  margin="dense"
                  variant="outlined"
                  number={value}
                  decimalPoint={0}
                  step={1}
                  onChange={onChange}
                  error={Boolean(errors?.count?.message)}
                />
              </ErrorTooltip>
            );
          }}
        />
      }
      endActions={
        <>
          <CCButton className={classes.btn} color="sub" onClick={onClose}>
            {intl.formatMessage({
              id: "cancel"
            })}
          </CCButton>
          <CCButton variant="contained" onClick={onSave}>
            {intl.formatMessage({
              id: "save"
            })}
          </CCButton>
        </>
      }
    />
  );
};

export default CountDialog;
