const PAYMENT_STATUS = {
  All: { value: "1", localeKey: "common.ui.all" },
  Paid: { value: "2", localeKey: "common.ui.payment.paid" },
  Partial: { value: "3", localeKey: "payment.partial" },
  Unpaid: { value: "4", localeKey: "common.ui.payment.unpaid" }
};

const PAYMENT_CATEGORY = {
  paymentPaid: { value: "1", localeKey: "paymentPaid" }, // 수입
  paymentDeduction: { value: "2", localeKey: "paymentDeduction" }, // 차감
  paymentDeductionCancel: { value: "3", localeKey: "paymentDeductionCancel" }, // 차감취소
  paymentRefund: { value: "4", localeKey: "paymentRefund" }, // 환불
  paymentChartDelete: { value: "5", localeKey: "paymentChartDelete" } // 수입(진료삭제)
};

export { PAYMENT_CATEGORY, PAYMENT_STATUS };

// ./src/views/Normal/Payment
