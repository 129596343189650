import { InputAdornment, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "../../../locales/language";
import { DaygoIcon, MoonborderIcon, MoonIcon } from "../../themes/common/icons";
import CCIconButton from "../CCIconButton";
import CCTextField from "../CCTextField";
import CCFlattenDatePicker from "./CCFlattenDatePicker";

const useStyle = makeStyles(
  theme => ({
    root: {},
    dateButton: {
      color: theme.palette.icon
    },
    selected__button: {
      color: theme.palette.select.main
    },
    padding__right: { paddingRight: 8 },
    disabled: {
      color: theme.palette.disabled.hex
    }
  }),
  { name: "CCFlattenDateField" }
);

function weekOfMonth(input = moment(), gongdan = false) {
  const firstDayOfMonth = gongdan
    ? input.clone().locale("gondan").startOf("month")
    : input.clone().startOf("month");
  const firstDayOfWeek = gongdan
    ? firstDayOfMonth.clone().locale("gondan").startOf("week")
    : firstDayOfMonth.clone().startOf("week");

  const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

  return Math.ceil((input.date() + offset) / 7);
}

const CCFlattenDateField = props => {
  const {
    value,
    onChange = () => {},
    lunar,
    lunarOnChange,
    label,
    disabled,
    dateIcon,
    dateIconTooltip,
    components,
    checkedList,
    onChangeHeader,
    onPickerChange = () => {},
    onClickIconPermission,
    mode,
    needWeek,
    preserveCurrentTime,
    defaultValue,
    onBlur,
    gongdan,
    ...others
  } = props;
  const intl = useIntl();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [momentValue, setMomentValue] = useState(value);
  const [textValue, setTextValue] = useState(
    value === false
      ? ""
      : mode === "day"
      ? value.clone().format("L")
      : `${weekOfMonth(value.begin, gongdan)}주`
  );
  const classes = useStyle();

  useEffect(() => {
    if (!value) {
      setTextValue("");
      // setMomentValue(moment());
      setMomentValue(prevState => {
        if (
          Boolean(prevState) &&
          moment().format("L") !== prevState.format("L")
        ) {
          return moment();
        } else {
          return prevState;
        }
      });
      // console.log("tttt");
      return;
    }
    // console.log("momentValue", momentValue.toString());
    if (mode === "day") {
      setMomentValue(prevState => {
        if (prevState === false) {
          return value;
        } else if (value.format("L") !== prevState.format("L")) {
          return value;
        } else {
          return prevState;
        }
      });
      setTextValue(value.clone().format("L"));
    } else {
      setMomentValue(prevState => {
        if (prevState === false) {
          return value;
        } else if (value.begin.format("L") !== prevState.begin.format("L")) {
          return value;
        } else {
          return prevState;
        }
      });
      setTextValue(
        `${value.begin.clone().format("YYYY/MM")} ${weekOfMonth(
          value.begin,
          gongdan
        )}주`
      );
    }
  }, [value, momentValue, mode, gongdan]);

  const Icon = dateIcon ? dateIcon : DaygoIcon;
  const tooltip = dateIconTooltip ? { tooltip: dateIconTooltip } : {};

  return (
    <>
      {pickerOpen && (
        <CCFlattenDatePicker
          gongdan={gongdan}
          open={pickerOpen}
          value={momentValue === false ? moment() : momentValue}
          onChange={e => {
            onChange(e);
            setPickerOpen(false);
            onPickerChange(false);
          }}
          onClose={() => {
            setPickerOpen(false);
            onPickerChange(false);
          }}
          components={components}
          checkedList={checkedList}
          onChangeHeader={onChangeHeader}
          mode={mode}
          needWeek={needWeek}
          preserveCurrentTime={preserveCurrentTime}
        />
      )}
      <CCTextField
        {...others}
        disabled={disabled}
        variant={"outlined"}
        margin={"dense"}
        label={label}
        value={textValue}
        onChange={e => setTextValue(e.target.value)}
        onBlur={() => {
          onBlur && onBlur();
          // setTextValue(
          //   momentValue === false ? "" : momentValue.format("YYYY/MM/DD")
          // );
        }}
        InputProps={{
          classes: { adornedEnd: classes.padding__right },
          readOnly: true,
          endAdornment: (
            <InputAdornment position={"end"}>
              <CCIconButton
                size={"small"}
                onClick={() => {
                  if (onClickIconPermission) {
                    onClickIconPermission();
                    return;
                  }
                  if (disabled) return;
                  setPickerOpen(true);
                  onPickerChange(true);
                }}
                {...tooltip}
              >
                <Icon
                  className={clsx(classes.dateButton, {
                    [classes.disabled]: disabled
                  })}
                />
              </CCIconButton>
              {lunarOnChange && (
                <CCIconButton
                  size={"small"}
                  onClick={() => {
                    if (onClickIconPermission) {
                      onClickIconPermission();
                      return;
                    }
                    if (disabled) return;
                    lunarOnChange(!lunar);
                  }}
                  tooltip={
                    lunar
                      ? intl.formatMessage({
                          id: "ccLunarYear"
                        })
                      : intl.formatMessage({
                          id: "ccSunYear"
                        })
                  }
                  className={clsx({
                    [classes.selected__button]: lunar,
                    [classes.disabled]: disabled
                  })}
                >
                  {lunar ? <MoonIcon /> : <MoonborderIcon />}
                </CCIconButton>
              )}
            </InputAdornment>
          )
        }}
      />
    </>
  );
};
CCFlattenDateField.propTypes = {
  value: propTypes.oneOfType([
    propTypes.instanceOf(moment),
    propTypes.bool,
    propTypes.shape({
      begin: propTypes.instanceOf(moment),
      end: propTypes.instanceOf(moment)
    })
  ]).isRequired,
  onChange: propTypes.func.isRequired,
  lunar: propTypes.bool,
  lunarOnChange: propTypes.func,
  label: propTypes.string,
  mode: PropTypes.oneOf(["day", "week"]),
  // readOnly: propTypes.bool
  needWeek: PropTypes.bool,
  preserveCurrentTime: propTypes.bool,
  gongdan: propTypes.bool
};
CCFlattenDateField.defaultProps = {
  // readOnly: true
  mode: "day",
  label: "날짜",
  needWeek: false,
  preserveCurrentTime: false,
  gongdan: false
};
export default CCFlattenDateField;
