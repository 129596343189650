import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import React from "react";

const useStyle = makeStyles(theme => ({
  root: {
    "&>a>span": {
      color: "#000 !important"
    }
  },
  saturday: {
    "&>a>span": {
      color: "#0277bd !important"
    }
  },
  sunday: {
    "&>a>span": {
      color: "#f50057 !important"
    }
  }
}));

const DateHeader = props => {
  const { date, onDrillDown, label } = props;

  const classes = useStyle();

  return (
    <div
      onClick={e => {
        onDrillDown(e);
      }}
      className={clsx(classes.root, {
        [classes.saturday]: moment(date).format("E") === "6",
        [classes.sunday]: moment(date).format("E") === "7"
      })}
    >
      <a href={"/#"} style={{ textDecoration: "none" }}>
        <span>{`${""}${label}`}</span>
      </a>
    </div>
  );
};

export default React.memo(DateHeader);
