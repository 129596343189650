import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { CCButton, CCDialogSimple, CCTypography } from 'styles/components';
import { CheckIcon } from 'styles/icons';
import { DOC_CATEGORIES } from 'types/docissue';
import { getDocIssuePrint, getDocPrint } from 'utils/docissue';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    padding: '16px 16px',
  },
  box: {},
  grid__item__control: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '9px 16px',
  },
  grid__container__doc: {
    height: '100%',
    overflow: 'auto',
  },
  grid__item__doc: {
    width: '100%',
    paddingLeft: 34,
    paddingRight: 34,
    backgroundColor: '#eceff1',
  },
  grid__container__control: {
    height: 54,
  },
  grid__container__nav: {
    alignItems: 'baseline',
    justifyContent: 'flex-start',
  },
  grid__container__zoom: {
    alignItems: 'baseline',
    justifyContent: 'flex-end',
  },
  button: {
    margin: 0,
    padding: '8px 12px',
    height: 36,
  },
  iconbutton: {
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const getDocByCategory = (state, ref, baseClasses) => {
  if (Object.keys(state.info).length) {
    if (!state.info.category || !state.info.type || !state.info.name)
      return (
        <CCTypography variant='body1'>데이터에 오류가 있습니다.</CCTypography>
      );

    return state.info?.category === DOC_CATEGORIES.GENERAL.value
      ? getDocPrint(state, ref, baseClasses)
      : getDocIssuePrint(state, ref, baseClasses);
  }
  return null;
};

const dataInitialState = {
  info: {},
  data: {},
  patientData: {},
  isModified: true,
  signature: '',
};

const OverviewDialog = (props) => {
  const {
    id = null,
    state = null,
    open,
    onClose,
    printButtonLabel = '발급/출력',
    baseClasses = null,
  } = props;

  const classes = useStyles();

  const [data, setData] = useState(dataInitialState);
  // const [openRRNMaskingSelectDialog, setOpenRRNMaskingSelectDialog] = useState(
  //   false,
  // );

  const ref = useRef(null);

  // const handleOpenRRNMaskingSelectDialog = () =>
  //   setOpenRRNMaskingSelectDialog(true);
  // const handleCloseRRNMaskingSelectDialog = () =>
  //   setOpenRRNMaskingSelectDialog(false);

  // const handleChangeMaskingYes = () => setData({ ...data, masking: true });
  const handleChangeMaskingNo = () => setData({ ...data, masking: false });

  // const [getDocIssue] = useLazyQuery(GET_DOCISSUE, {
  //   onCompleted: res => {
  //     const docIssue = res.getDocIssue;
  //     const newData = {
  //       info: {
  //         category: docIssue.category,
  //         name: docIssue.name,
  //         type: docIssue.type
  //       },
  //       data: { ...JSON.parse(docIssue.contents) },
  //       patientData: { ...docIssue.patient },
  //       isModified: true,
  //       signature: "signature"
  //     };
  //     setData(newData);
  //   },
  //   onError: err => console.log(err)
  // });

  useEffect(() => {
    if (id) {
      console.log('id', id);
      // getDocIssue({
      //   variables: {
      //     hospitalId: localStorage.getItem('labId'),
      //     id: id
      //   }
      // });
    }

    if (state) setData({ ...state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, state]);

  return (
    open && (
      <CCDialogSimple
        open={Boolean(open)}
        onClose={onClose}
        title='미리보기'
        maxWidth='md'
        fullWidth
        onCloseButton={onClose}
        classes={classes}
        contentsPadding={false}
        contents={
          <Box className={classes.box}>
            <Grid
              container
              className={classes.grid__container__doc}
              direction='column'
            >
              <Grid item className={classes.grid__item__doc}>
                {getDocByCategory(data, ref, baseClasses)}
              </Grid>
            </Grid>
          </Box>
        }
        endActions={
          <>
            <CCButton
              variant={'text'}
              color={'sub'}
              onClick={(e) => {
                console.log('취소');
                onClose && onClose();
              }}
            >
              취소
            </CCButton>
            {data &&
              data.info?.category === DOC_CATEGORIES.CERTIFICATION.value && (
                <CCButton
                  variant={'contained'}
                  className={classes.button}
                  color={'primary'}
                  startIcon={<CheckIcon />}
                  // onClick={handleOpenRRNMaskingSelectDialog}
                >
                  {printButtonLabel}
                </CCButton>
              )}
            <ReactToPrint
              trigger={() =>
                // data.info?.category === DOC_CATEGORIES.GENERAL.value ? 
                (
                  <CCButton
                    variant={'contained'}
                    className={classes.button}
                    color={'primary'}
                    startIcon={<CheckIcon />}
                  >
                    {printButtonLabel}
                  </CCButton>
                ) 
                // : (
                  // <RRNMaskingSelectDialog
                  //   // open={openRRNMaskingSelectDialog}
                  //   onClick={() => {}}
                  //   onChangeYes={handleChangeMaskingNo}
                  //   onChangeNo={handleChangeMaskingYes}
                  // />
                // )
              }
              onBeforeGetContent={async () => {
                // *don't* remove async
                if (
                  data &&
                  data.info?.category === DOC_CATEGORIES.CERTIFICATION.value
                ) {
                  // handleCloseRRNMaskingSelectDialog();
                }
              }}
              onAfterPrint={async () => {
                if (
                  data &&
                  data.info?.category === DOC_CATEGORIES.CERTIFICATION.value
                ) {
                  handleChangeMaskingNo();
                }
              }}
              content={() => ref.current}
              pageStyle={`@page { size: ${
                data?.info?.orientation ? data?.info?.orientation : 'auto'
              }; margin: auto; } @media print { body { -webkit-print-color-adjust: exact; } table { page-break-inside: auto; } tr { page-break-inside: avoid; page-break-after: auto; } }`}
            />
          </>
        }
      />
    )
  );
};

export default OverviewDialog;
