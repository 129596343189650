/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import Paper, { Group, Path, Point, PointText, Raster, Rectangle } from "paper";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { range } from "utils/range.js";

var manifests = {};

range(1, 8).forEach(index => {
  manifests[`teeth.${index + 10}.normal`] = `teethselector/adult-${
    index + 10
  }-normal-icon.png`;
  manifests[`teeth.${index + 20}.normal`] = `teethselector/adult-${
    index + 20
  }-normal-icon.png`;
  manifests[`teeth.${index + 30}.normal`] = `teethselector/adult-${
    index + 30
  }-normal-icon.png`;
  manifests[`teeth.${index + 40}.normal`] = `teethselector/adult-${
    index + 40
  }-normal-icon.png`;
  manifests[`teeth.${index + 10}.select`] = `teethselector/adult-${
    index + 10
  }-select-icon.png`;
  manifests[`teeth.${index + 20}.select`] = `teethselector/adult-${
    index + 20
  }-select-icon.png`;
  manifests[`teeth.${index + 30}.select`] = `teethselector/adult-${
    index + 30
  }-select-icon.png`;
  manifests[`teeth.${index + 40}.select`] = `teethselector/adult-${
    index + 40
  }-select-icon.png`;
});

range(1, 5).forEach(index => {
  manifests[`teeth.${index + 50}.normal`] = `teethselector/baby-${
    index + 50
  }-normal-icon.png`;
  manifests[`teeth.${index + 60}.normal`] = `teethselector/baby-${
    index + 60
  }-normal-icon.png`;
  manifests[`teeth.${index + 70}.normal`] = `teethselector/baby-${
    index + 70
  }-normal-icon.png`;
  manifests[`teeth.${index + 80}.normal`] = `teethselector/baby-${
    index + 80
  }-normal-icon.png`;
  manifests[`teeth.${index + 50}.select`] = `teethselector/baby-${
    index + 50
  }-select-icon.png`;
  manifests[`teeth.${index + 60}.select`] = `teethselector/baby-${
    index + 60
  }-select-icon.png`;
  manifests[`teeth.${index + 70}.select`] = `teethselector/baby-${
    index + 70
  }-select-icon.png`;
  manifests[`teeth.${index + 80}.select`] = `teethselector/baby-${
    index + 80
  }-select-icon.png`;
});

manifests["button.adult.top"] = "teethselector/adult-selecttop-icon.png";
manifests["button.adult.whole"] = "teethselector/adult-selectwhole-icon.png";
manifests["button.adult.bottom"] = "teethselector/adult-selectbottom-icon.png";

manifests["button.baby.top"] = "teethselector/baby-selecttop-icon.png";
manifests["button.baby.whole"] = "teethselector/baby-selectwhole-icon.png";
manifests["button.baby.bottom"] = "teethselector/baby-selectbottom-icon.png";

manifests["division"] = "teethselector/division-icon.png";

var layouts = {
  normal: {
    columns: 48,
    rows: 12,
    configs: {},
    targets: []
  },
  simple: {
    columns: 48,
    rows: 6,
    configs: {},
    targets: []
  }
};

range(1, 8).forEach(index => {
  layouts.normal.targets[`teeth.${index + 10}`] = {
    x: 24 - (index - 0) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 10}.normal`] = {
    x: 24 - (index - 0) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 10}.select`] = {
    x: 24 - (index - 0) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.normal.targets[`teeth.${index + 20}`] = {
    x: 24 + (index - 1) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 20}.normal`] = {
    x: 24 + (index - 1) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 20}.select`] = {
    x: 24 + (index - 1) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.normal.targets[`teeth.${index + 40}`] = {
    x: 24 - (index - 0) * 3,
    y: 9,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 40}.normal`] = {
    x: 24 - (index - 0) * 3,
    y: 9,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 40}.select`] = {
    x: 24 - (index - 0) * 3,
    y: 9,
    width: 3,
    height: 3
  };
  layouts.normal.targets[`teeth.${index + 30}`] = {
    x: 24 + (index - 1) * 3,
    y: 9,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 30}.normal`] = {
    x: 24 + (index - 1) * 3,
    y: 9,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 30}.select`] = {
    x: 24 + (index - 1) * 3,
    y: 9,
    width: 3,
    height: 3
  };
});

range(1, 5).forEach(index => {
  layouts.normal.targets[`teeth.${index + 50}`] = {
    x: 24 - (index - 0) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 50}.normal`] = {
    x: 24 - (index - 0) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 50}.select`] = {
    x: 24 - (index - 0) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.normal.targets[`teeth.${index + 60}`] = {
    x: 24 + (index - 1) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 60}.normal`] = {
    x: 24 + (index - 1) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 60}.select`] = {
    x: 24 + (index - 1) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.normal.targets[`teeth.${index + 80}`] = {
    x: 24 - (index - 0) * 3,
    y: 6,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 80}.normal`] = {
    x: 24 - (index - 0) * 3,
    y: 6,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 80}.select`] = {
    x: 24 - (index - 0) * 3,
    y: 6,
    width: 3,
    height: 3
  };
  layouts.normal.targets[`teeth.${index + 70}`] = {
    x: 24 + (index - 1) * 3,
    y: 6,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 70}.normal`] = {
    x: 24 + (index - 1) * 3,
    y: 6,
    width: 3,
    height: 3
  };
  layouts.normal.configs[`teeth.${index + 70}.select`] = {
    x: 24 + (index - 1) * 3,
    y: 6,
    width: 3,
    height: 3
  };
});

layouts.normal.targets[`button.adult.top`] = {
  x: 0,
  y: 3,
  width: 9,
  height: 2,
  indices: [...range(11, 18), ...range(21, 28)]
};
layouts.normal.configs[`button.adult.top`] = {
  x: 0,
  y: 3,
  width: 9,
  height: 2
};
layouts.normal.targets[`button.adult.whole`] = {
  x: 0,
  y: 5,
  width: 9,
  height: 2,
  indices: [
    ...range(11, 18),
    ...range(21, 28),
    ...range(31, 38),
    ...range(41, 48)
  ]
};
layouts.normal.configs[`button.adult.whole`] = {
  x: 0,
  y: 5,
  width: 9,
  height: 2
};
layouts.normal.targets[`button.adult.bottom`] = {
  x: 0,
  y: 7,
  width: 9,
  height: 2,
  indices: [...range(31, 38), ...range(41, 48)]
};
layouts.normal.configs[`button.adult.bottom`] = {
  x: 0,
  y: 7,
  width: 9,
  height: 2
};

layouts.normal.targets[`button.baby.top`] = {
  x: 39,
  y: 3,
  width: 9,
  height: 2,
  indices: [...range(51, 55), ...range(61, 65)]
};
layouts.normal.configs[`button.baby.top`] = {
  x: 39,
  y: 3,
  width: 9,
  height: 2
};
layouts.normal.targets[`button.baby.whole`] = {
  x: 39,
  y: 5,
  width: 9,
  height: 2,
  indices: [
    ...range(51, 55),
    ...range(61, 65),
    ...range(71, 75),
    ...range(81, 85)
  ]
};
layouts.normal.configs[`button.baby.whole`] = {
  x: 39,
  y: 5,
  width: 9,
  height: 2
};
layouts.normal.targets[`button.baby.bottom`] = {
  x: 39,
  y: 7,
  width: 9,
  height: 2,
  indices: [...range(71, 75), ...range(81, 85)]
};
layouts.normal.configs[`button.baby.bottom`] = {
  x: 39,
  y: 7,
  width: 9,
  height: 2
};

layouts.normal.configs["division"] = {
  x: 0,
  y: 0,
  width: 48,
  height: 12
};

range(1, 8).forEach(index => {
  layouts.simple.targets[`teeth.${index + 10}`] = {
    x: 24 - (index - 0) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.simple.configs[`teeth.${index + 10}.normal`] = {
    x: 24 - (index - 0) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.simple.configs[`teeth.${index + 10}.select`] = {
    x: 24 - (index - 0) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.simple.targets[`teeth.${index + 20}`] = {
    x: 24 + (index - 1) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.simple.configs[`teeth.${index + 20}.normal`] = {
    x: 24 + (index - 1) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.simple.configs[`teeth.${index + 20}.select`] = {
    x: 24 + (index - 1) * 3,
    y: 0,
    width: 3,
    height: 3
  };
  layouts.simple.targets[`teeth.${index + 40}`] = {
    x: 24 - (index - 0) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.simple.configs[`teeth.${index + 40}.normal`] = {
    x: 24 - (index - 0) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.simple.configs[`teeth.${index + 40}.select`] = {
    x: 24 - (index - 0) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.simple.targets[`teeth.${index + 30}`] = {
    x: 24 + (index - 1) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.simple.configs[`teeth.${index + 30}.normal`] = {
    x: 24 + (index - 1) * 3,
    y: 3,
    width: 3,
    height: 3
  };
  layouts.simple.configs[`teeth.${index + 30}.select`] = {
    x: 24 + (index - 1) * 3,
    y: 3,
    width: 3,
    height: 3
  };
});

const useStyles = makeStyles(() => ({
  root: {}
}));

const TeethSelector = props => {
  const {
    width,
    height,
    background,
    layout,
    manual,
    disabled,
    sources,
    padding = 0.4,
    vborder = 0,
    hborder = 0,
    hiddens = [],
    labels = [],
    buttons = ["adult", "baby"],
    onSelect,
    onClick,
    onClickAll
  } = props;
  const classes = useStyles();
  const canvasRef = useRef(null);
  const paper = useMemo(() => {
    return new Paper.PaperScope();
  }, []);
  const [objects, setObjects] = useState({});
  const [configs, setConfigs] = useState({});
  const [contents, setContents] = useState([]);
  const [selects, setSelects] = useState([]);
  const [touchGrab, setTouchGrab] = useState(null);
  const [touchGuide, setTouchGuide] = useState(null);

  const canvasWidth = useMemo(() => {
    return width;
  }, [width]);
  const canvasHeight = useMemo(() => {
    return height;
  }, [height]);

  const canvasX = useMemo(() => {
    return hborder;
  }, [hborder]);
  const canvasY = useMemo(() => {
    return vborder;
  }, [vborder]);

  const fullWidth = useMemo(() => {
    return width + hborder * 2;
  }, [width]);
  const fullHeight = useMemo(() => {
    return height + vborder * 2;
  }, [height]);

  const gridWidth = useMemo(() => {
    return canvasWidth / layouts[layout].columns;
  }, [canvasWidth, layout]);
  const gridHeight = useMemo(() => {
    return canvasHeight / layouts[layout].rows;
  }, [canvasHeight, layout]);

  const getCanvasPoint = useCallback(e => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    var clientX = e.clientX;
    var clientY = e.clientY;

    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX;
      clientY = e.changedTouches[0].clientY;
    }

    clientX -= rect.left;
    clientY -= rect.top;

    return new Point(clientX, clientY);
  }, []);

  const newPaperRaster = useCallback((name, source) => {
    return new Promise((resolve, reject) => {
      var item = new Raster(source);
      item.visible = false;
      item.data.name = name;
      item.onLoad = () => {
        resolve(item);
      };
      item.onError = () => {
        reject(null);
      };
    });
  }, []);

  useEffect(() => {
    paper.activate();
    paper.setup(canvasRef.current);

    return () => {
      paper.remove();
    };
  }, []);

  useEffect(() => {
    paper.activate();

    if (background) {
      var path = new Path.Rectangle(
        new Rectangle(new Point(0, 0), new Point(fullWidth, fullHeight))
      );
      path.fillColor = background;
    }

    for (var oname in objects) {
      objects[oname].remove();
    }

    var list = [];

    for (const cname in layouts[layout].configs) {
      const config = layouts[layout].configs[cname];

      if (manifests.hasOwnProperty(cname)) {
        list.push(newPaperRaster(cname, manifests[cname]));
      }
    }

    Promise.all(list).then(items => {
      var images = {};

      items.forEach(item => {
        images[item.data.name] = item;
      });

      var _objects = {};
      var _configs = {};

      for (const cname in layouts[layout].configs) {
        const config = layouts[layout].configs[cname];
        const tags = cname.split(".");

        if (tags[0] === "button" && !buttons.includes(tags[1])) {
          continue;
        }

        const item = images[cname];
        item.fitBounds(
          new Rectangle(
            new Point(
              canvasX + config.x * gridWidth,
              canvasY + config.y * gridHeight
            ),
            new Point(
              canvasX + (config.x + config.width) * gridWidth,
              canvasY + (config.y + config.height) * gridHeight
            )
          )
        );
        item.visible = true;

        _objects[cname] = item;
        _configs[cname] = {
          x: config.x,
          y: config.y,
          width: config.width,
          height: config.height
        };
      }

      setObjects({ ..._objects });
      setConfigs({ ..._configs });
    });
  }, [paper, layout, background, fullWidth, fullHeight]);

  useEffect(() => {
    setSelects([...sources] || []);
  }, [sources]);

  useEffect(() => {
    if (!objects) return;

    paper.activate();

    for (const tname in layouts[layout].targets) {
      const target = layouts[layout].targets[tname];
      const tags = tname.split(".");
      try {
        if (hiddens.includes(parseInt(tags[1]))) {
          objects[`${tags[0]}.${tags[1]}.normal`].visible = false;
          objects[`${tags[0]}.${tags[1]}.select`].visible = false;
        } else if (selects.includes(parseInt(tags[1]))) {
          objects[`${tags[0]}.${tags[1]}.normal`].visible = false;
          objects[`${tags[0]}.${tags[1]}.select`].visible = true;
        } else {
          objects[`${tags[0]}.${tags[1]}.normal`].visible = true;
          objects[`${tags[0]}.${tags[1]}.select`].visible = false;
        }
      } catch (e) {}
    }
  }, [configs, selects, objects]);

  useEffect(() => {
    paper.activate();

    contents.forEach(item => {
      item.remove();
    });

    var items = [];

    labels.forEach(label => {
      const target = layouts[layout].targets[`teeth.${label.tooth}`];
      const bounds = [1, 2, 5, 6].includes(Math.floor(label.tooth / 10))
        ? new Rectangle(
            new Point(
              canvasX + target.x * gridWidth,
              canvasY + target.y * gridHeight
            ),
            new Point(
              canvasX + (target.x + target.width) * gridWidth,
              canvasY +
                (target.y + target.height) * gridHeight -
                target.height * gridHeight * (1.0 - label.ratio)
            )
          )
        : new Rectangle(
            new Point(
              canvasX + target.x * gridWidth,
              canvasY +
                target.y * gridHeight +
                target.height * gridHeight * (1.0 - label.ratio)
            ),
            new Point(
              canvasX + (target.x + target.width) * gridWidth,
              canvasY + (target.y + target.height) * gridHeight
            )
          );

      var item = new Group();

      var box = new Path.RoundRectangle(bounds, 5);
      box.fillColor = label.fill;
      box.opacity = 0.45;

      var text = new PointText(new Point(0, 0));
      text.content = label.text;
      text.style = {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        fontSize: 24,
        fillColor: label.stroke,
        justification: "center"
      };
      text.opacity = 0.75;
      text.fitBounds(bounds);

      item.addChild(box);
      item.addChild(text);

      items.push(item);
    });

    setContents(items);
  }, [labels]);

  const handleDown = e => {
    paper.activate();

    const point = getCanvasPoint(e);
    var clicked = false;

    for (const tname in layouts[layout].targets) {
      const target = layouts[layout].targets[tname];
      const tags = tname.split(".");

      if (!hiddens.includes(parseInt(tags[1]))) {
        const bounds = new Rectangle(
          new Point(
            canvasX + target.x * gridWidth,
            canvasY + target.y * gridHeight
          ),
          new Point(
            canvasX + (target.x + target.width) * gridWidth,
            canvasY + (target.y + target.height) * gridHeight
          )
        );

        if (bounds.contains(point)) {
          if (tags[0] === "button" && buttons.includes(tags[1])) {
            const indices = target.indices;

            if (manual) {
              onClickAll(indices);
            } else {
              const choose = indices
                .filter(index => !hiddens.includes(index))
                .every(index => selects.includes(index));
              var _selects = selects;

              if (choose) {
                indices.forEach(index => {
                  if (_selects.includes(index)) {
                    _selects.splice(_selects.indexOf(index), 1);
                  }
                });
              } else {
                indices.forEach(index => {
                  if (!_selects.includes(index)) {
                    _selects.push(index);
                  }
                });
              }

              onSelect(_selects);
            }

            clicked = true;
          }

          break;
        }
      }
    }

    if (!clicked) {
      setTouchGrab(point);
    }
  };
  const handleUp = e => {
    paper.activate();

    if (touchGuide) {
      touchGuide.remove();
      setTouchGuide(null);
    }

    if (touchGrab) {
      const bounds0 = new Rectangle(touchGrab, getCanvasPoint(e));
      var indices = [];

      for (const tname in layouts[layout].targets) {
        const target = layouts[layout].targets[tname];
        const tags = tname.split(".");

        if (!hiddens.includes(parseInt(tags[1]))) {
          if (tags[0] === "teeth") {
            if (bounds0.width > gridWidth && bounds0.height > gridHeight) {
              const bounds1 = new Rectangle(
                new Point(
                  canvasX +
                    target.x * gridWidth +
                    target.width * padding * gridWidth,
                  canvasY +
                    target.y * gridHeight +
                    target.height * padding * gridHeight
                ),
                new Point(
                  canvasX +
                    (target.x + target.width) * gridWidth -
                    target.width * padding * gridWidth,
                  canvasY +
                    (target.y + target.height) * gridHeight -
                    target.height * padding * gridHeight
                )
              );

              if (bounds0.intersects(bounds1)) {
                indices.push(parseInt(tags[1]));
              }
            } else {
              const bounds1 = new Rectangle(
                new Point(
                  canvasX + target.x * gridWidth,
                  canvasY + target.y * gridHeight
                ),
                new Point(
                  canvasX + (target.x + target.width) * gridWidth,
                  canvasY + (target.y + target.height) * gridHeight
                )
              );

              if (bounds0.intersects(bounds1)) {
                indices.push(parseInt(tags[1]));
              }
            }
          }
        }
      }

      if (manual) {
        onClick(indices);
      } else {
        var _selects = selects;

        indices.forEach(index => {
          if (_selects.includes(index)) {
            _selects.splice(_selects.indexOf(index), 1);
          } else {
            _selects.push(index);
          }
        });

        onSelect(_selects);
      }

      setTouchGrab(null);
    }
  };
  const handleMove = e => {
    if (touchGuide) {
      touchGuide.remove();
      setTouchGuide(null);
    }

    if (touchGrab) {
      const bounds0 = new Rectangle(touchGrab, getCanvasPoint(e));

      var path = new Path.Rectangle(bounds0);
      path.strokeColor = "rgba(50, 197, 255, 1.0)";
      path.strokeWidth = 1;
      path.fillColor = "rgba(50, 197, 255, 0.25)";
      setTouchGuide(path);
    }

    if (touchGrab && !disabled) {
      paper.activate();

      const bounds0 = new Rectangle(touchGrab, getCanvasPoint(e));

      for (const tname in layouts[layout].targets) {
        const target = layouts[layout].targets[tname];
        const tags = tname.split(".");

        if (tags[0] === "teeth") {
          try {
            const bounds1 = new Rectangle(
              new Point(
                canvasX + target.x * gridWidth,
                canvasY + target.y * gridHeight
              ),
              new Point(
                canvasX + (target.x + target.width) * gridWidth,
                canvasY + (target.y + target.height) * gridHeight
              )
            );

            if (hiddens.includes(parseInt(tags[1]))) {
              objects[`${tags[0]}.${tags[1]}.normal`].visible = false;
              objects[`${tags[0]}.${tags[1]}.select`].visible = false;
            } else if (
              bounds0.intersects(bounds1) ===
              selects.includes(parseInt(tags[1]))
            ) {
              objects[`${tags[0]}.${tags[1]}.normal`].visible = true;
              objects[`${tags[0]}.${tags[1]}.select`].visible = false;
            } else {
              objects[`${tags[0]}.${tags[1]}.normal`].visible = false;
              objects[`${tags[0]}.${tags[1]}.select`].visible = true;
            }
          } catch (e) {}
        }
      }
    }
  };

  return (
    <Box className={clsx(classes.root)}>
      <canvas
        ref={canvasRef}
        width={fullWidth}
        height={fullHeight}
        onMouseDown={e => handleDown(e)}
        onMouseUp={e => handleUp(e)}
        onMouseMove={e => handleMove(e)}
        onMouseOut={e => handleUp(e)}
      ></canvas>
    </Box>
  );
};

TeethSelector.propTypes = {
  /** 가로 길이 (480) */
  width: PropTypes.number,

  /** 세로 길이 (layout에 따라 변경됨, normal(120), simple(60)) */
  height: PropTypes.number,

  /** 베경색 */
  background: PropTypes.string,

  /** layout (normal or simple) */
  layout: PropTypes.string,

  /** manual */
  manual: PropTypes.bool,

  /** disabled */
  disabled: PropTypes.bool,

  /** 선택된 치식, 배열 형태 */
  sources: PropTypes.array,

  hiddens: PropTypes.array,

  buttons: PropTypes.array,

  labels: PropTypes.array,

  padding: PropTypes.number,
  hborder: PropTypes.number,
  vborder: PropTypes.number,

  /** 선택된 치식을 반환해주는 함수 */
  onSelect: PropTypes.func,

  /** 함수 */
  onClick: PropTypes.func,

  /** 함수 */
  onClickAll: PropTypes.func
};

export default TeethSelector;
