import React from "react";
import { CAPITALIZE_TYPE, FormattedMessage } from "utils/language";

const makeList = (type, capitalizeType = CAPITALIZE_TYPE.FIRST_WORD) => {
  return Object.values(type).map(value => ({
    value: value.value,
    label: (
      <FormattedMessage id={value.localeKey} capitalizeType={capitalizeType} />
    )
  }));
};
const makeSomeList = (type, values) => {
  return Object.values(type)
    .filter(value => values.includes(value.value))
    .map(value => ({
      value: value.value,
      label: <FormattedMessage id={value.localeKey} />
    }));
};
const makeType = (value, localeKey) => ({ value: value, localeKey: localeKey });
const makeIndexList = type =>
  Object.values(type).reduce((accumulator, currentValue) => {
    accumulator[currentValue.value] = currentValue.localeKey;
    return accumulator;
  }, {});
const makeIndexFormattedMessageList = (
  type,
  capitalizeType = CAPITALIZE_TYPE.FIRST_WORD
) =>
  Object.values(type).reduce((accumulator, currentValue) => {
    accumulator[currentValue.value] = (
      <FormattedMessage
        id={currentValue.localeKey}
        capitalizeType={capitalizeType}
      />
    );
    return accumulator;
  }, {});

const makeIndexFormattedMessageListShort = type =>
  Object.values(type).reduce((accumulator, currentValue) => {
    accumulator[currentValue.value] = (
      <FormattedMessage id={currentValue.localeKey + "Short"} />
    );
    return accumulator;
  }, {});
export {
  makeList,
  makeSomeList,
  makeType,
  makeIndexList,
  makeIndexFormattedMessageList,
  makeIndexFormattedMessageListShort
};
