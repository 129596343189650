/*eslint react-hooks/exhaustive-deps: 0*/
import { useApolloClient, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVButton, DialogWarningMessage, enqueueToast, translate } from "components";
import { useSnackbar } from "notistack";
import {
  UPDATE_PRODUCT,
  PRODUCT_DETAIL,
  BATCH_REMOVE_PRODUCT
} from "queries/product";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  CCButton,
  CCDialogAlert,
  CCIconButton,
  CCTypography
} from "styles/components";
import { BringInIcon } from "styles/icons";
import {
  CCDialog,
  CCDialogContent,
  CCDialogTitle
} from "styles/src/components";
import { DeleteIcon, MenunextIcon } from "styles/src/themes/common/icons";
import { getCustomFormat, getTimestamp } from "utils/datetime";
import { useIntl } from "utils/language";
import useProductValidation from "validations/useProductValidation";
import { useRemoveHistory } from "views/Normal/InventoryManagement/Component/Stock/Component/ProductTab/hooks";
import { TabContext } from "../../../../Stock";
import DetailModify from "./components/DetailModify/DetailModify";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 880,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  icon__history: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  },
  delete__button: {
    position: "absolute",
    left: 10,
    width: "200px"
  }
}));

const DetailDialog = props => {
  const client = useApolloClient();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const isArabic = localStorage.getItem("lang") === "ar";

  const [errorDialog, setErrorDialog] = useState('');
  const { open, onClose, value, refetchProductList } = props;

  const classes = useStyles();
  const selectRef = useRef();

  const [selected, setSelected] = useState();
  const { setTabs, setProduct } = useContext(TabContext);

  const [alertOpen, setAlertOpen] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const [detail, setDetail] = useState();

  const { data, refetch } = useQuery(PRODUCT_DETAIL, {
    fetchPolicy: "network-only",
    variables: {
      id: value
    },
    onCompleted: e => {
      if (e?.productDetail) {
        setDetail(e?.productDetail);
      }
    }
  });

  const [storage, setStorage] = useState({
    hospital: "",
    important: false,
    unit: "",
    currentCnt: detail?.storage?.currentCnt,
    optCnt: 0,
    alert: false,
    editDate: getTimestamp(),
    editorID: "",
    canExpire: Boolean(detail?.storage?.canExpire) ? "2" : "1",
    expire: {
      expireCount: 0,
      expireDate: getTimestamp()
    },
    storage: {},
    binCnt: 0,
    barcode: false,
    isAppropriate: false
  });

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);

  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);

  useEffect(() => {
    setSelected(selectRef?.current);
  }, [selectRef, selected]);

  const { schemaDetailModify: schema } = useProductValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: {
      ...storage,
      currentCnt: Number(detail?.storage?.currentCnt),
      canExpire: Boolean(detail?.storage?.canExpire) ? "2" : "1"
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    methods.reset(data?.productDetail);
    setStorage({
      ...data?.productDetail,
      alert: data?.productDetail?.storage.alert,
      canExpire: Boolean(data?.productDetail?.storage?.canExpire) ? "2" : "1",
    });
    refetch();
  }, [data, methods.reset]);

  const onClosure = () => {

    if (Object.keys(methods.formState.dirtyFields)?.length > 0 || selectRef) {
      setCancelDialog({
        title: intl.formatMessage(
          { id: "confirmCancelwritingInfo" },
          { br: () => <br /> }
        ),
        onClose: () => {
          setCancelDialog(null);
        },
        onDisagree: () => {
          setCancelDialog(null);
        },
        onAgree: () => {
          methods.reset(storage);
          onClose && onClose();
        }
      });
    } else {
      onClose && onClose();
    }
  };

  const save = useCallback(
    async e => {
      console.log("DetailDialog > save");
      await client
        .mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            input: {
              def: "1",
              major: storage?.major,
              minor: storage?.minor,
              manufacture: e?.manufacture,
              productName: e?.productName,
              productID: storage?.productID,
              id: e.id,
              storage: {
                ...storage?.storage,
                editDate: getTimestamp(),
                alert: Boolean(e?.alert === "true"),
                canExpire: Boolean(e?.canExpire === "2"),
                unit: e?.storage?.unit,
                binCnt: parseInt(e?.storage?.binCnt, 10),
                expire: Boolean(e?.canExpire === "2")
                  ? e?.expireList.map(item => {
                    return {
                      expireDate: item?.expireDate?.unix ? item?.expireDate?.unix() : item?.expireDate,
                      expireCount: Number(item?.expireCount)
                    };
                  })
                  : []
              }
            }
          }
        })
        .then(r => {
          refetchProductList()
        });

      enqueueToast(
        enqueueSnackbar,
        ` ${intl.formatMessage({
          id: "saveCompleted"
        })}`
      );

      onClose();
    },
    [client, data, detail, storage]
  );

  const onDelete = useCallback(async () => {
    await client
      .mutate({
        mutation: BATCH_REMOVE_PRODUCT,
        variables: {
          ids: [value]
        }
      })
      .then(async () => {
        enqueueToast(
          enqueueSnackbar,
          ` ${intl.formatMessage({
            id: "deletedProduct"
          })}`
        );
        refetchProductList()
        onClose();
      })
      .catch(errors => {
        const { graphQLErrors } = errors;
        if (graphQLErrors) {
          graphQLErrors.forEach(graphQLError => {
            setErrorDialog(graphQLError.message)
          });

        }
        setDeleteDialog(null)

      });;

  }, [client, storage]);

  const { control } = methods;

  const onError = error => {
    if (error?.storage) {
      const errorMessagesStorage = Object.keys(error.storage).map(key => {
        return error.storage[key].message;
      })
      if (errorMessagesStorage && errorMessagesStorage.length > 0) {
        setErrorDialog(errorMessagesStorage[0])
      }
    } else {
      const errorMessages = Object.keys(error).map(key => {
        return error[key].message;
      })
      if (errorMessages && errorMessages.length > 0) {
        setErrorDialog(errorMessages[0])
      }
    }
  }

  const onCreate = () => {
    methods.handleSubmit(save, onError)();
  };

  return (
    <>
      {/*<DialogLoading open={creating} text={"Loading"} />*/}
      <FormProvider {...methods}>
        <CCDialog open={open} className={classes.root} onClose={onClosure} maxWidth="md">
          <CCDialogTitle onCloseButton={onClosure} className={classes.height}>
            <Grid style={{ height: "15px" }} item>
              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="row">
                  <CCTypography variant="h3">
                    {intl.formatMessage({ id: "productInformation" })}
                  </CCTypography>
                </Box>
                <CVButton
                  color="normal"
                  variant={"contained"}
                  endIcon={<MenunextIcon />}
                  className={
                    isArabic ? classes.delete__button : classes.icon__history
                  }
                  onClick={() => {
                    onClose();
                    setTabs("1");
                    setProduct(detail);
                  }}
                >
                  {intl.formatMessage({ id: "inventoryHistory" })}
                </CVButton>
              </Box>
            </Grid>
          </CCDialogTitle>
          <CCDialogContent noPadding={true} style={{ overflow: "hidden" }}>
            <Controller
              name={"directInput"}
              control={control}
              defaultValue={Boolean(detail) || true}
              render={props => {
                const { ref, value: inputValue, onBlur } = props;
                return (
                  <DetailModify
                    modify={detail}
                    methods={methods}
                    detail={value}
                    bool={inputValue}
                    noPadding={true}
                    value={storage}
                    ref={ref}
                    inputRef={ref}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </CCDialogContent>

          <Box
            style={{ overflowY: "auto", padding: 10 }}
            marginLeft={"auto"}
            className={classes.icon__end}
          >
            <>
              <CCButton
                color="normal"
                startIcon={<DeleteIcon />}
                variant={"contained"}
                className={classes.delete__button}
                onClick={() => {
                  setDeleteDialog({
                    title: translate(intl, "common.ui.areYouSureYouWantToDeleteIt"),
                    subTitle: translate(intl, "alertDeleteNote"),
                    onClose: () => {
                      setDeleteDialog(null);
                    },
                    onDisagree: () => {
                      setDeleteDialog(null);
                    },
                    onAgree: onDelete
                  });
                }}
                disabled={detail?.storage?.currentCnt !== 0}
              >
                {intl.formatMessage({ id: "removeProduct" })}
              </CCButton>
              <CCButton
                className={classes.cancel__icon}
                variant={"text"}
                color={"normal"}
                onClick={onClosure}
              >
                {intl.formatMessage({ id: "cancel" })}
              </CCButton>
              <CCButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  onCreate();
                }}
              >
                {intl.formatMessage({ id: "save" })}
              </CCButton>
              <CCDialogAlert
                contents={
                  <Box className={classes.root}>
                    <CCTypography>
                      {intl.formatMessage({ id: "registerProduct" })}
                      <CCIconButton color={"secondary"} variant={"contained"}>
                        <BringInIcon />
                      </CCIconButton>
                      {intl.formatMessage({ id: "clickBtn" })}
                    </CCTypography>
                  </Box>
                }
                open={alertOpen}
                onClose={setCloseAlert}
                onAgree={setCloseAlert}
                onDisagree={setCloseAlert}
              />
              <CCDialogAlert
                contents={
                  <CCTypography>
                    {intl.formatMessage({ id: "registerProduct" })}
                    <CCIconButton color={"secondary"} variant={"contained"}>
                      <BringInIcon />
                    </CCIconButton>
                    {intl.formatMessage({ id: "registerInputContent" })}
                  </CCTypography>
                }
                open={saveAlertOpen}
                onClose={setCloseSaveAlert}
                onAgree={setCloseSaveAlert}
                onDisagree={setCloseSaveAlert}
              />
            </>
          </Box>
        </CCDialog>
      </FormProvider>
      <DialogWarningMessage open={Boolean(deleteDialog)} {...deleteDialog} />
      {Boolean(cancelDialog) && <DialogWarningMessage open={Boolean(cancelDialog)} {...cancelDialog} />}
      {Boolean(errorDialog) &&
        <DialogWarningMessage
          open={Boolean(errorDialog)}
          title={errorDialog}
          agreeButtonLabel={intl.formatMessage({ id: "common.ui.common.ui.ok" })}
          onAgree={() => { setErrorDialog(false) }}
          onClose={() => { setErrorDialog(false) }}
        />}
    </>
  );
};

export default DetailDialog;
