import gql from "graphql-tag";

const GET_CONFIG_PRICING = gql`
  query getConfig($labId: String, $category: String) {
    getConfig(labId: $labId, category: $category) {
      id
      category
      labId
      prosthesisPriceSetting {
        name
        id
        state
        isCustom
        items {
          name
          id
          partnerId
          discount
          createdBy
          item {
            contents {
              id
              category
              tabContents {
                id
                material
                unit
                price
                updatedAt
                shade
                pontic
                productProcess{
                  id
                  processName
                  stages{
                    id
                    stageName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const GET_CONFIG_PROCESS = gql`
  query getConfig($labId: String, $category: String) {
    getConfig(labId: $labId, category: $category) {
      id
      category
      labId
      productProcessSetting {
        id
        processName
        stages{
          id
          stageName
        }
      }
    }
  }
`;

const GET_PERMISSION_SETTING = gql`
  query getConfig($labId: String, $category: String) {
    getConfig(labId: $labId, category: $category) {
      id
      labId
      category
      permissionSetting {
        id
        category
        data {
          id
          category
          page
          permission {
            read
            write
          }
          items {
            id
            category
            permission {
              read
              write
            }
            items {
              id
              category
              freeze
              permission {
                read
                write
              }
            }
          }
        }
      }
    }
  }
`;

const GET_LAB_SETTING = gql`
  query {
    labDetail {
      labId
      birthDate
      openDate
      labName
      managerName
      address
      specialties
      aboutUs
      availableArea
      labTelNumber
      managerCellNumber
      erc
      email
      bankName
      bankAccountNumber
      bankAccountHolder
      statusMessage
      avatar {
        target
        id
        name
        type
      }
      workDaySetting {
        dayOfWeek
        holiday
      }
      workTimeSetting {
        begin
        end
      }
      prothesisImages {
        target
        id
        name
        type
      }
      location{
        address
        placeId
        detailAddress
        coordinate{
          latitude
          longitude
        }
      }
      representative {
        staffName
        id
        birthDate
        isChiefDirector
      }
    }
  }
`;

const GET_LAB_PREVIEW = gql`
  query LabPreview($labId: String) {
    labPreview(labId: $labId) {
      labId
      birthDate
      labName
      managerName
      address
      specialties
      aboutUs
      availableArea
      labTelNumber
      managerCellNumber
      erc
      email
      statusMessage
      avatar {
        target
        id
        name
        type
      }
      workDaySetting {
        dayOfWeek
        holiday
      }
      workTimeSetting {
        begin
        end
      }
      prothesisImages {
        target
        id
        name
        type
      }
    }
  }
`;

const UPLOAD_LAB = gql`
  mutation updateLab($input: UpdateLabInput!) {
    updateLab(input: $input) {
      code
      message
    }
  }
`;

const GET_SMTP_SETTING = gql`
  query getSmtpSetting($labId: String!) {
    getConfig(labId: $labId, category: "smtpSetting") {
      smtpSetting {
        ... on SmtpSetting {
          password
          port
          sender
          smtpUrl
          ssl
        }
      }
    }
  }
`;

const GET_STAFF_LIST = gql`
  query GetStaffList($query: GetStaffListQueryInput!) {
    getStaffList(query: $query) {
      total
      items {
        staffId
        birthDate
        cellphoneNumber
        telephoneNumber
        staffName
        lab {
          labId
          labName
          address
        }
        status
        position
        occupation
        authority
        hiredDate
        resignedDate
        salary
        address
      }
    }
  }
`;

const PARTNER_LIST = gql`
  query PartnerList($query: DefaultQueryListInput!) {
    partnerList(query: $query) {
      items {
        id
        name
        email
        tel
        fax
        tax
        address
        address2
        manager
        managerCellNo
        discount
        paymentDate
        type
        isLink
        createdBy
      }
      hasNext
      total
    }
  }
`;

const UPDATE_PROSTHESIS_PRICE_SETTING = gql`
  mutation createProsthesisPriceSetting(
    $labId: String!
    $category: String!
    $prosthesisPriceSetting: [ProsthesisSettingInput]
  ) {
    createProsthesisPriceSetting(
      input: {
        labId: $labId
        category: $category
        prosthesisPriceSetting: $prosthesisPriceSetting
      }
    ) {
      code
    }
  }
`;
const UPDATE_PROCESS_SETTING = gql`
  mutation createProductProcessSetting(
    $id: ID
    $category: String
    $productProcessSetting: [ProductProcessSettingInput]
  ) {
    createProductProcessSetting(
      input: {
        id: $id
        category: $category
        productProcessSetting: $productProcessSetting
      }
    ) {
      code
    }
  }
`;
const SEND_EMAIL_TEST = gql`
  mutation sendEmailTest(
    $from: String!
    $host: String!
    $port: Int!
    $ssl: Boolean!
    $password: String!
    $language: String
  ) {
    sendEmailTest(
      from: $from
      host: $host
      port: $port
      ssl: $ssl
      password: $password
      language: $language
    ) {
      code
    }
  }
`;
const UPDATE_PERMISSION_SETTING = gql`
  mutation createPermissionSetting(
    $labId: String!
    $category: String!
    $permissionSetting: [PermissionSettingInput]!
  ) {
    createPermissionSetting(
      input: {
        labId: $labId
        category: $category
        permissionSetting: $permissionSetting
      }
    ) {
      code
    }
  }
`;

const GET_LAB_DETAILS = gql`
  query {
    getLabDetailList {
      id
      content
    }
  }
`;

const UPDATE_LAB_DETAIL = gql`
  mutation updateLabDetailList($input: [UpdateLabDetail]!) {
    updateLabDetailList(input: $input) {
      id
      content
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($id: String!) {
    resetPassword(id: $id) {
      staffId
    }
  }
`;

const CHECK_STAFF_ID = gql`
  mutation CheckStaffId($id: String!) {
    checkStaffId(id: $id)
  }
`;

export {
    GET_SMTP_SETTING,
    SEND_EMAIL_TEST,
    UPLOAD_LAB,
    GET_LAB_SETTING,
    GET_LAB_PREVIEW,
    UPDATE_PERMISSION_SETTING,
    GET_PERMISSION_SETTING,
    GET_STAFF_LIST,
    GET_CONFIG_PRICING,
    UPDATE_PROSTHESIS_PRICE_SETTING,
    PARTNER_LIST,
    GET_LAB_DETAILS,
    UPDATE_LAB_DETAIL,
    RESET_PASSWORD,
    CHECK_STAFF_ID,
    GET_CONFIG_PROCESS,
    UPDATE_PROCESS_SETTING
};

