import { Box, LinearProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { CCButton, CCDialogSimple, CCTypography } from "styles/components";
import { DownloadIcon } from "styles/icons";
import { saveAttachmentToDisk } from "../../utils";
import { getObject, Target } from "utils/s3";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 16,
    width: 400
  },
  title: {
    display: "flex",
    flexDirection: "column"
  },
  title__text: {},
  icon_button: {
    color: theme.palette.common.white
  },
  item__progress: {
    minWidth: "calc(100% - 5ch)",
    maxWidth: "calc(100% - 2ch)",
    padding: "8px"
  },
  group_button: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 12
  },
  container: {
    height: `calc(100% - 36px)`,
    overflow: "auto",
    boxSizing: "border-box",
    marginTop: 16,
    paddingTop: "8px",
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  progress__bar: {
    height: "16px",
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.background.default
  },
}));

const NotSupportedDialog = props => {
  const { url, fileName, open, onClose, attachment: photoOne } = props;
  const intl = useIntl();

  const classes = useStyles();
  const abortControllerRef = useRef();
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    if (!open) {
      setCurrentProgress(0);
      abortControllerRef.current && abortControllerRef.current.abort();
    }
  }, [open]);


  const onDownload = () => {
    if (photoOne) {
      getObject(Target.PHOTO, photoOne.bucket, photoOne?.fileId)
        .then(async res => {
          const a = document.createElement("a");
          // a.href = res;
          // a.download = `${
          //   photoOne.content.filename ? photoOne.content.filename : "output"
          // }`;
          const controller = new AbortController();
          const { signal } = controller;

          abortControllerRef.current = controller;

          a.href = await fetch(res, { signal })
            .then(async res => {
              const reader = res.body.getReader();

              // Step 2: get total length
              const contentLength = +res.headers.get("Content-Length");

              // Step 3: read the data
              let receivedLength = 0; // received that many bytes at the moment
              let chunks = []; // array of received binary chunks (comprises the body)
              while (true) {
                const { done, value } = await reader.read();

                if (done) {
                  break;
                }

                chunks.push(value);
                receivedLength += value.length;

                setCurrentProgress(
                  parseInt((receivedLength / contentLength) * 100)
                );
              }

              let blob = new Blob(chunks);
              return blob;
              // res.blob();
            })
            .then(blob => window.URL.createObjectURL(blob));
          a.download = photoOne.filename ? photoOne.filename : "output";
          a.click();
        })
        .catch(fail => console.log(fail))
        .finally(() => {
          abortControllerRef.current = undefined;
        });
    }
  };

  return (
    <CCDialogSimple
      maxWidth={false}
      open={open}
      isHeader={false}
      contentsPadding={false}
      onClose={onClose}
      contents={
        <Box className={classes.root}>
          <Box className={classes.title}>
            <CCTypography
              variant="body1"
              color="error"
              className={classes.title__text}
            >
              {intl.formatMessage(
                { id: "warningOpenZip" },
                { br: () => <br /> }
              )}
            </CCTypography>

          </Box>
          <Grid
            container
            className={clsx(classes.container)}
            style={{ position: "relative" }}
            direction="row"
            alignItems="center"
          >
            <Grid className={classes.item__progress} item>
              <LinearProgress
                className={classes.progress__bar}
                // classes={{ barColorPrimary: classes.progress__bar__primary }}
                color="secondary"
                variant="determinate"
                value={currentProgress}
              />
            </Grid>
            <Grid item>
              <CCTypography>{`${currentProgress}%`}</CCTypography>
            </Grid>
          </Grid>

          <Box className={classes.group_button}>
            <CCButton
              title="Upload attachments"
              variant={"contained"}
              startIcon={<DownloadIcon />}
              onClick={onDownload}
            >
              {intl.formatMessage({ id: "photoDownload" })}
            </CCButton>

            <CCButton color="secondary" onClick={onClose}>
              {intl.formatMessage({ id: "close" })}
            </CCButton>
          </Box>
        </Box>
      }
    />
  );
};

export default NotSupportedDialog;
