import { useMutation } from "@apollo/client";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  CCFlattenDateField,
  CVButton,
  enqueueToastSave,
  NumberFormatPayment
} from "components";
import produce from "immer";
import moment from "moment";
import { useSnackbar } from "notistack";
import { UPDATE_PAYMENT } from "queries/payment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogSimple,
  CCTextField,
  CCTypography
} from "styles/components";
import { ProgressIcon } from "styles/icons";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import { Commas } from "..";
import MonthPicker from "../MonthPicker";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  w100: { width: 100 },
  w180: { width: 180 },
  w164: { width: 164, margin: "8px 16px" },
  date: { width: 130 },
  flex: { display: "flex" },
  main__text: {
    padding: 16,
    borderBottom: "1px solid #b0bec5",
    "& p": {
      fontWeight: "bold"
    }
  },
  bg__white: { background: "white" },
  flex__center: {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": { padding: 0 }
  },
  root: {
    "& .MuiDialog-paperWidthSm": { maxWidth: "inherit", width: 500 }
  },
  input__group: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px 0",
    background: "#eceff1"
  },
  card: {
    marginLeft: 0,
    width: 85
  },
  divided: {
    height: 1,
    backgroundColor: "#b0bec5",
    margin: "16px"
  },
  line__text: {
    width: 200,
    margin: "16px 0"
  },
  color__grey: {
    color: "#b0bec5"
  },

  width150: {
    width: 150
  }
}));

const initState = {
  date: moment().unix(),
  staff: "",
  name: "",
  money: {
    cardPaymentAmount: 0,
    cashPaymentAmount: 0
  },
  memo: ""
};

const RefundDialog = props => {
  const { open, paymentHistoryId, onClose, value, labOrder, refetch } = props;
  const {
    totalAmountOnPayment,
    staffId,
    cardPaymentAmount,
    cashPaymentAmount,
    id
  } = value;

  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [obj, setObj] = useState(initState);

  const [payment] = useMutation(UPDATE_PAYMENT, {
    onCompleted: () => {
      onClose();
      refetch();
    }
  });

  const handleChange = (name1, name2) => value => {
    const regExp = /^[0-9]+$/;
    if (!regExp.test(value)) {
      return;
    }
    const newObj = produce(obj, draftState => {
      draftState[name1][name2] = Number(value);
    });
    setObj(newObj);
  };
  const handleSubmit = () => {
    payment({
      variables: {
        input: {
          id: paymentHistoryId,
          cardPaymentAmount: cardPaymentAmount,
          cashPaymentAmount: cashPaymentAmount,
          transactionType: "2",
          staffId: obj.staff || staffId,
          payId: id
        }
      }
    }).then(() => enqueueToastSave(intl, enqueueSnackbar));
  };
  const staffs = Array.isArray(labOrder) ? labOrder : (Array.isArray(labOrder?.staff) ? labOrder?.staff : [])
  return (
    <CCDialogSimple
      classes={classes}
      title={translate(intl, "payment.refund")}
      open={open}
      onClose={onClose}
      onCloseButton={onClose}
      contentsPadding={false}
      contents={
        <Grid container style={{ flexDirection: "column" }}>
          <Grid container justify="space-between">
            <MonthPicker
              disabled
              label={translate(intl, "payment.month")}
              className={classes.width150}
              margin={"dense"}
              variant={"outlined"}
              value={moment()}
            />
            <CCFlattenDateField
              className={classes.width150}
              onChange={e => { }}
              label={translate(intl, "payment.receiptDate")}
              value={moment()}
              disabled
            />

            <CCTextField
              className={classes.width150}
              label={translate(intl, "payment.receiptDate")}
              margin={"dense"}
              variant={"outlined"}
              value={obj.staff || staffId}
              select
              onChange={e => {
                setObj(preState => ({ ...preState, staff: e.target.value }));
              }}
            >
              {staffs.map(item => (
                <CCMenuItem
                  key={item.staffId || item.staffName}
                  value={item.staffId}
                >
                  {item.staffName}
                </CCMenuItem>
              ))}
            </CCTextField>
          </Grid>
          <Grid
            item
            className={classes.main__text}
            container
            justify="space-between"
          >
            <Grid
              container
              className={classes.line__text}
              justify="space-between"
            >
              <CCTypography className={classes.color__grey}>
                {translate(intl, "payment.receiptDate")}
                {/* {"Total Charged ($)"} */}
              </CCTypography>
              <CCTypography variant="h4">
                {Commas(totalAmountOnPayment)}
              </CCTypography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className={classes.input__group}>
              <CCTextField
                InputProps={{
                  inputComponent: NumberFormatPayment,
                  inputProps: {
                    style: { textAlign: "right" },
                    maxLength: 11
                  }
                }}
                className={clsx(classes.w164, classes.bg__white)}
                margin="dense"
                variant="outlined"
                label={<CCTypography><div>{translate(intl, "payment.creditCard")}</div></CCTypography>}
                value={cardPaymentAmount}
                disabled
                onChange={handleChange("money", "cardPaymentAmount")}
                startIcon={<ProgressIcon />}
              />
              <CCTextField
                InputProps={{
                  inputComponent: NumberFormatPayment,
                  inputProps: {
                    style: { textAlign: "right" },
                    maxLength: 11
                  }
                }}
                className={clsx(classes.w164, classes.bg__white)}
                margin="dense"
                variant="outlined"
                label={<CCTypography><div>{translate(intl, "payment.cash")}</div></CCTypography>}
                value={cashPaymentAmount}
                disabled
                onChange={handleChange("money", "cashPaymentAmount")}
                startIcon={<ProgressIcon />}
              />
              <Box className={classes.divided} />
              <CCTextField
                InputProps={{
                  inputComponent: NumberFormatPayment,
                  inputProps: {
                    style: { textAlign: "right" },
                    maxLength: 11
                  }
                }}
                className={classes.w164}
                margin="dense"
                variant="outlined"
                label={<CCTypography><div>{translate(intl, "payment.total")}</div></CCTypography>}
                value={cardPaymentAmount + cashPaymentAmount}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      }
      endActions={
        <>
          <CCButton onClick={onClose} style={{
            textTransform: "unset",
          }}>
            {translate(intl, "payment.cancel")}
          </CCButton>
          <CVButton
            permission={"payment.billManage"}
            startIcon={<ProgressIcon />}
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              // const isCreate = checkPaymentCreate(); // 생성여부, index 번호
              // calculation(isCreate);
              handleSubmit();
            }}
          >
            {translate(intl, "payment.refund")}
          </CVButton>
        </>
      }
    />
  );
};

export default RefundDialog;
