import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, makeStyles, Zoom } from "@material-ui/core";
import clsx from "clsx";
import { DialogPermissionWarn, useWritePermission } from "components";
import CVMenu from "components/CVMenu";
import { UPDATE_ORDER_STATUS } from "queries/labOrder";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCPaper, CCTooltip, CCTypography } from "styles/components";
import { LAB_ORDER_STATE, LAB_ORDER_TYPE } from "types/labOrder";
import { makeIndexFormattedMessageList } from "types/utils";
import { DialogError } from "components";
import { IS_ORDER_BELONGS_TO_PAYMENT } from "queries/payment";
import OrderItem from "../OrderItem";
import { translate } from "../../../../../components";

const useStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    boxSizing: "border-box",
    height: "100%"
  },
  tooltip: {
    background: "transparent"
  },
  paper: {
    position: "relative",
    overflowY: "auto",
    width: 200,
    borderRadius: 8,
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24)",
    padding: 8
  },
  cleverClinicName: {
    color: "green"
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  }
}));

const WeekEventItem = props => {
  const { event, searchLabRefetch, handleOpenDetailOrder } = props;

  const classes = useStyle();
  const isPermission = useWritePermission("calendar");
  const intl = useIntl();

  const [openPermissionWarn, setOpenPermissionWarn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [warningChangeStatus, setWarningChangeStatus] = useState(false);
  const [confirmChangeCleverStatus, setConfirmChangeCleverStatus] =
    useState(false);

  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS, {
    onCompleted: data => {
      searchLabRefetch();
    }
  });

  const { data: isOrderBelongsToPaidPaymentData } = useQuery(
    IS_ORDER_BELONGS_TO_PAYMENT,
    {
      fetchPolicy: "network-only",
      variables: { ids: [selectedItem.id] }
    }
  );

  const changeStatusOrder = e => {
    e.stopPropagation();
    if (!isPermission) {
      setOpenPermissionWarn(true);
      return;
    }

    if (
      isOrderBelongsToPaidPaymentData.isOrderBelongsToPaidPayment
        .isOrdersBelongToSendCleverPayment
    ) {
      setConfirmChangeCleverStatus(true);
      return;
    }
    if (
      isOrderBelongsToPaidPaymentData.isOrderBelongsToPaidPayment
        .isOrdersBelongToPaidPayment
    ) {
      setWarningChangeStatus(true);
      return;
    }

    updateOrderStatus({
      variables: {
        ids: [selectedItem.id],
        status: e.target.value.toString()
      }
    }).then(() => {
      searchLabRefetch();
    });
  };

  const labOrderType = makeIndexFormattedMessageList(LAB_ORDER_TYPE);
  const menuItemList = [
    {
      value: LAB_ORDER_STATE.ORDERED.value,
      label: translate(intl, "labOrder.ordered"),
      disabled: selectedItem.status === LAB_ORDER_STATE.ORDERED.value,
      onClick: changeStatusOrder
    },
    {
      value: LAB_ORDER_STATE.CAD_DESIGN.value,
      label: translate(intl, "labOrder.filterCadDesign"),
      disabled: selectedItem.status === LAB_ORDER_STATE.CAD_DESIGN.value,
      onClick: changeStatusOrder
    },
    {
      value: LAB_ORDER_STATE.IN_PROD.value,
      label: translate(intl, "labOrder.inProd"),
      disabled: selectedItem.status === LAB_ORDER_STATE.IN_PROD.value,
      onClick: changeStatusOrder
    },
    {
      value: LAB_ORDER_STATE.COMPLETE.value,
      label: translate(intl, "labOrder.filterComplete"),
      disabled: selectedItem.status === LAB_ORDER_STATE.COMPLETE.value,
      onClick: changeStatusOrder
    },
    {
      value: LAB_ORDER_STATE.SHIPPED.value,
      label: translate(intl, "labOrder.shipped"),
      disabled: selectedItem.status === LAB_ORDER_STATE.SHIPPED.value,
      onClick: changeStatusOrder
    },
    {
      value: "detail",
      label: translate(intl, "labOrder.detail"),
      onClick: e => {
        e.stopPropagation();
        handleOpenDetailOrder(selectedItem);
      }
    }
  ];

  return (
    <>
      <Box className={classes.root}>
        {event.items.map((item, index) => {
          return (
            <CCTooltip
              key={index}
              enterDelay={200}
              TransitionComponent={Zoom}
              className={classes.tooltip}
              title={
                <CCPaper variant={"outlined"} className={clsx(classes.paper)}>
                  <CCTypography variant="body1">
                    {item.manufacture.name}
                  </CCTypography>
                  <CCTypography variant="body1">
                    {labOrderType[item.type]}
                  </CCTypography>
                  <CCTypography
                    variant="body1"
                    className={
                      item.clinic.createdBy === "Clever"
                        ? clsx(classes.cleverClinicName)
                        : ""
                    }
                  >
                    {item.clinic.name}
                  </CCTypography>
                  <CCTypography variant="body1">{item.doctor}</CCTypography>
                </CCPaper>
              }
            >
              <div>
                <OrderItem
                  status={item.status}
                  title={item.manufacture.name}
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedItem(item);
                    setAnchorEl(e.target);
                  }}
                />
                <div className={classes.divider}></div>
              </div>
            </CCTooltip>
          );
        })}

        <CVMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          menuItems={menuItemList}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        />
      </Box>
      {/* {detailedOrderModalVisible && (
        <LabOrderModal
          open={detailedOrderModalVisible}
          onClose={() => {
            console.log("onclose LabOrderModal");
            setDetailedOrderModalVisible(false);
            markMessageRead({
              variables: { orderId: selectedItem.id }
            })
              .then(() => {
                searchLabRefetch();
              })
              .catch(e => {
                searchLabRefetch();
              });
          }}
          refetchOrders={() => searchLabRefetch()}
          value={selectedItem.id}
          onModify={() => {}}
          buttonHidden={true}
        />
      )} */}
      {openPermissionWarn && (
        <DialogPermissionWarn
          open={openPermissionWarn}
          onClose={() => {
            setOpenPermissionWarn(false);
          }}
        />
      )}

      {warningChangeStatus && (
        <DialogError
          open={Boolean(warningChangeStatus)}
          content={translate(intl, "changeStatusAOrder")}
          onClose={() => setWarningChangeStatus(false)}
        />
      )}
      {confirmChangeCleverStatus && (
        <DialogError
          open={Boolean(confirmChangeCleverStatus)}
          content={translate(intl, "changeCleverStatusOrder")}
          onClose={() => setConfirmChangeCleverStatus(false)}
        />
      )}
    </>
  );
};

export default WeekEventItem;
