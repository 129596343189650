import { Grid, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useEffect, useState } from "react";
import { getObject, Target } from "utils/s3";

const useStyles = makeStyles(() => ({
  removeicon: {
    cursor: "pointer",
    background: "#b0bec5",
    width: 20,
    height: 20,
    position: "absolute",
    top: 5,
    right: 5,
    borderRadius: "50%",
    zIndex: 1
  },
  card: {
    borderRadius: 10,
    display: "flex",
    position: "relative",
    height: "100px",
    width: "133px",
    background: "white",
    border: "1px solid #b0bec5",
    margin: 6,

    backgroundPosition: "center center",
    backgroundOrigin: "border-box",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat"
  }
}));

const Card = props => {
  const { type, target, id, handleRemoveImage, loading } = props;

  const classes = useStyles();

  const [image, setImage] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    let func = () => {
      switch (type) {
        case "paper":
        case "image":
          getObject(target, localStorage.getItem("labId"), id).then(res => {
            setImage(res);
          });
          break;
        case "stamp":
          getObject(Target.STAMP, localStorage.getItem("labId"), id).then(
            res => {
              setImage(res);
            }
          );
          break;
        case "logo":
          getObject(Target.LOGO, localStorage.getItem("labId"), id).then(
            res => {
              setImage(res);
            }
          );
          break;
        case "video":
          setImage("/contents/video.svg");
          break;
        case "consult":
          setImage("/contents/slide.svg");
          break;
        case "folder":
          setImage("/contents/folder.svg");
          break;
        default:
          getObject(target, localStorage.getItem("labId"), id).then(res => {
            setImage(res);
          });
      }
    };
    func();
  }, [id, target, type]);

  return (
    <Grid
      item
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classes.card}
      style={{
        backgroundImage: `url(${image})`
      }}
    >
      {loading && <CircularProgress style={{ margin: "auto" }} />}
      {handleRemoveImage && !loading
        ? hover && (
            <RemoveIcon
              className={classes.removeicon}
              onClick={() => handleRemoveImage(id)}
            />
          )
        : ""}
    </Grid>
  );
};

Card.propTypes = {};

export default Card;
