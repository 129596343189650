import React from "react";

const BringupIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M15.5 10.5h4l-7-7-7 7h4.1c0 5-1.6 8.5-5.1 11 5-1 10-4 11-11z"
      />
      <path d="M0 0H24V24H0z" transform="matrix(0 1 1 0 0 0)" />
    </g>
  </svg>
);
export default BringupIcon;
