import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { CCTable, CCTypography } from "styles/components";
import { translate } from "components";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    flex: 1,
    // borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: "none"
  },
  wordBreak: { wordBreak: "break-all" },
  title: {
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
    padding: "8px 16px 8px 45px",
    borderTop: "1px solid #b0bec5",
    borderBottom: "1px solid #b0bec5"
  },
  cell__surgery: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: 45,
    justifyContent: "center"
  },

  cell: {
    textAlignLast: "start",
    flex: 1,
    color: theme.palette.text.primary
  },
  table__product: {
    textAlignLast: "start",
    flex: 2,
    color: theme.palette.text.primary
  }
}));

const tableStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    flex: 1
  },
  table__body: {
    backgroundColor: theme.palette.background.default,
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  }
}));

const LabIfo = props => {
  const {
    data: {
      labName,
      managerName,
      address,
      workTimeSetting,
      workDaySetting,
      labTelNumber,
      availableArea
    }
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  const heads = [
    {
      key: "titles",
      className: clsx({
        [classes.cell]: true
      }),

      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell__surgery}>
            <CCTypography>{`${cellData}`}</CCTypography>
          </Box>
        );
      }
    },
    {
      key: "value",
      className: clsx({
        [classes.table__product]: true
      }),

      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell__surgery}>
            <CCTypography
              className={classes.wordBreak}
            >{`${cellData}`}</CCTypography>
          </Box>
        );
      }
    }
  ];
  const contents1 = [
    {
      titles: translate(intl, "payment.bill.labName"),
      value: labName
    },
    {
      titles: translate(intl, "common.ui.manager"),
      value: managerName
    },
    { titles: translate(intl, "common.ui.address"), value: address },
  ];
  const DAYLABELS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const contents2 = [
    {
      titles: translate(intl, "settings.lab.workingHours"),
      value: `${moment.unix(workTimeSetting.begin).format("LT")} - ${moment
        .unix(workTimeSetting.end)
        .format("LT")}`
    },
    {
      titles: translate(intl, "settings.lab.workingDays"),
      value: workDaySetting.slice(0, 7).reduce((acc, cur) => {
        if (!cur.holiday) {
          const dayLabel = intl.formatMessage({
            id: `settings.lab.${
              DAYLABELS[moment().weekday(Number(cur.dayOfWeek)).format("d")]
            }`
          });
          acc = acc ? acc + ", " + dayLabel : dayLabel;
        }

        return acc;
      }, "")
    },
    {
      titles: translate(intl, "settings.lab.tel"),
      value: labTelNumber
    },
    {
      titles: translate(intl, "settings.lab.availableArea"),
      value: availableArea ? availableArea : ""
    }
  ];

  const resultTableClasses = tableStyle();
  return (
    <Grid container direction="column">
      <CCTable
        classes={resultTableClasses}
        heads={heads}
        contents={contents1}
        labelShow={false}
      />

      <CCTypography className={classes.title}>
        {translate(intl, "settings.lab.operationInfo")}
      </CCTypography>

      <CCTable
        classes={resultTableClasses}
        heads={heads}
        contents={contents2}
        labelShow={false}
      />
    </Grid>
  );
};

export default LabIfo;
