import { Box, Grid, Link, makeStyles } from "@material-ui/core";
import { CVButton, Explorer, translate } from "components";
import ImageViewer from "components/Explorer/Component/FilePhoto/component/FileViewer/Component/ImageViewer/ImageViewer";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Files from "react-butterfiles";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogSimple,
  CCIconButton,
  CCMenu,
  CCTypography
} from "styles/components";
import { ClipIcon, RemoveIcon, WarningIcon } from "styles/icons";
import { getObject, putObject, Target } from "utils/s3";
import { v4 as uuid } from "uuid";
import { CAPITALIZE_TYPE } from "utils/language";
import DialogError from "./DialogError";

const useStyles = makeStyles(theme => ({
  root: {},
  error: {
    color: theme.palette.error.main
  }
}));

const FileUploadButton = props => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    // value, // 따로 저장 하는 값 photoId
    buttonIcon,
    onChange, // photo onChange
    onlyDirect, // true 일 경우 클라우드 없이 바로 올리기
    nonAttachPhoto, // true 인경우 클라우드 사진 선택시 그대로 onChange
    title,
    target,
    disablePreview,
    tab,
    patient,
    // category,
    // folder,
    startIcon,
    accept,
    allowExtension,
    // filetype,
    color = "normal",
    maxSize = "1024mb",
    handleSetLoadigFile,
    handleProgress,
    disabled,
    multiple = false,
    multipleMaxCount = 100,
    setMultipleCount = () => { }
  } = props;
  const fileUploadButton = useRef();
  const fileUploadIconButton = useRef();
  const [open, setOpen] = useState("");
  const [explorerOpen, setExplorerOpen] = useState(false);
  const hospitalId = localStorage.getItem("labId");
  const [openLogoMenu, setOpenLogoMenu] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [openImageView, setOpenImageView] = useState(false);
  const [openSizeCheck, setOpenSizeCheck] = useState(false);
  const [openMaxFileCheck, setOpenMaxFileCheck] = useState(false);
  const [openDuplicateWarning, setOpenDuplicateWarning] = useState(false);
  const [openUploadError, setOpenUploadError] = useState(false);

  // const [createPhoto] = useMutation(CREATE_PHOTO, {
  //   onCompleted: data => {
  //     if (data.createPhoto) {
  //       getPhoto({
  //         variables: { hospitalId: hospitalId, id: data.createPhoto }
  //       });
  //     }
  //   }
  // });

  const fetchObjectUrl = async photo => {
    // if (photo.content.object) {
    //   const url = await getObject(
    //     photo.content.filetype === 'image'
    //       ? Target.PHOTO
    //       : photo.content.filetype,
    //     hospitalId,
    //     photo.content.object,
    //   );
    //   photo.content.source = url;
    //   photo.content.thumbnail = url;
    // }
    // return photo;
  };

  // const [getPhoto] = useLazyQuery(GET_PHOTO, {
  //   onCompleted: data => {
  //     if (data.getPhoto) {
  //       onChange(data.getPhoto);
  //       setPhoto(data.getPhoto);
  //     }
  //   }
  // });

  const handleBlobFileChange = async file => {
    const ext = file.name.slice(file.name.lastIndexOf(".") + 1).toUpperCase();

    if (allowExtension.includes(ext)) {
      if (file) {
        handleSetLoadigFile(true);
        try {
          const fileUUID = uuid() + "." + ext.toLowerCase();
          await putObject(target, hospitalId, fileUUID, file, handleProgress);
          const url = await getObject(target, hospitalId, fileUUID);
          url && onChange({
            id: fileUUID,
            target,
            name: file.name,
            type: ext,
            url: url
          });
        } catch (error) {
          setOpenUploadError(true);
        }
        handleSetLoadigFile(false);
      }
    } else {
      setOpen(allowExtension.join(", "));
    }
  };

  const handlePhotoChange = photo => {
    setExplorerOpen(false);
    if (nonAttachPhoto) {
      onChange(photo);
    } else {
      fetchObjectUrl(photo)
        .then(async photo => {
          const url = photo.content.source;
          const response = await fetch(url);
          return response.blob();
        })
        .then(blobFile => {
          if (blobFile) {
            // const fileUUID = uuid();
            // putObjectBlob(target, hospitalId, fileUUID, blobFile).then(() => {
            //   // createPhoto({
            //   //   variables: {
            //   //     hospitalId: hospitalId,
            //   //     id: "",
            //   //     type: patient?.id ? category : "library",
            //   //     date: getTimestampFromDate(),
            //   //     lastModifiedTime: getTimestampFromDate(),
            //   //     patient: patient?.id ? patient.id : "__libraries__",
            //   //     content: {
            //   //       filetype: filetype,
            //   //       filename: photo?.content?.filename,
            //   //       filenode: uuid(),
            //   //       folder: folder,
            //   //       object: fileUUID,
            //   //       detail: photo?.content?.detail
            //   //     }
            //   //   }
            //   // });
            // });
          }
        });
    }
  };

  // useEffect(() => {
  //   if (value) {
  //     getPhoto({ variables: { hospitalId: hospitalId, id: value } });
  //   }
  // }, [value, getPhoto, hospitalId]);
  return (
    <>
      <Files
        multiple={multiple}
        maxSize={maxSize}
        multipleMaxSize={maxSize}
        multipleMaxCount={multipleMaxCount === 0 ? -1 : multipleMaxCount}
        accept={accept && accept}
        onSuccess={async files => {
          setMultipleCount(files.length > 6 ? 6 : files.length);
          for (let i in files) {
            if (i > 5) {
              return;
            }
            await handleBlobFileChange(files[i])
          }
        }}
        onError={errors => {
          if (errors[0].type === "maxSizeExceeded") {
            setOpenSizeCheck(true);
          } else if (errors[0].type === "multipleMaxCountExceeded") {
            setOpenMaxFileCheck(true);
          }
          else {
            console.error(errors);
          }
        }}
      >
        {({ browseFiles }) => {
          return (
            <Grid container alignItems={"center"}>
              <Grid item hidden={!!buttonIcon}>
                <CVButton
                  variant={"contained"}
                  color={color}
                  startIcon={startIcon}
                  children={title}
                  ref={fileUploadButton}
                  onClick={() => {
                    if (disabled) {
                      setOpenDuplicateWarning(true)
                      return;
                    }
                    onlyDirect ? browseFiles() : setOpenLogoMenu(true)
                  }}
                />
              </Grid>
              <Grid item hidden={!buttonIcon}>
                <CCIconButton
                  children={buttonIcon}
                  ref={fileUploadIconButton}
                  onClick={() => {
                    if (disabled) {
                      setOpenDuplicateWarning(true)
                      return;
                    }
                    onlyDirect ? browseFiles() : setOpenLogoMenu(true)
                  }}
                />
              </Grid>
              <Grid item>
                <CCMenu
                  anchorEl={
                    buttonIcon
                      ? fileUploadIconButton.current
                      : fileUploadButton.current
                  }
                  open={openLogoMenu}
                  onClose={() => setOpenLogoMenu(false)}
                  menuItems={[
                    {
                      value: "1",
                      label: "탐색기에서 가져오기",
                      onClick: () => {
                        setExplorerOpen(true);
                      }
                    },
                    {
                      value: "2",
                      label: "PC에서 가져오기",
                      onClick: browseFiles
                    }
                  ]}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                />
              </Grid>
              <Grid item style={{ margin: "0 8px" }} hidden={disablePreview}>
                <Grid container alignItems={"center"}>
                  <Grid item hidden={!photo}>
                    <Link
                      onClick={() => setOpenImageView(true)}
                      component="button"
                    >
                      <CCTypography variant="body1">
                        {photo?.content?.filename}
                      </CCTypography>
                    </Link>
                  </Grid>
                  <Grid item hidden={!photo}>
                    <CCIconButton
                      variant={"text"}
                      style={{ margin: 0 }}
                      onClick={() => {
                        setPhoto(null);
                        onChange(null);
                      }}
                    >
                      <RemoveIcon />
                    </CCIconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Files>
      {openDuplicateWarning && (
        <CCDialogSimple
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpenDuplicateWarning(false)}
          contents={
            <Grid container direction={"column"}>
              <CCTypography className={classes.error}>
                {intl.formatMessage(
                  {
                    id: "duplicateUploadError",
                    defaultMessage: "Only one image can be uploaded at a time."
                  },
                  {},
                )}
              </CCTypography>
            </Grid>
          }
          endActions={
            <CCButton
              color="error"
              variant='contained'
              style={{ textTransform: 'unset' }}
              onClick={() => setOpenDuplicateWarning(false)}
            >
              {intl.formatMessage({ id: "labOrder.confirm" }, {}, { capitalizeType: CAPITALIZE_TYPE.APA })}
            </CCButton>
          }
        />
      )}
      {openSizeCheck && (
        <DialogError
          onClose={() => setOpenSizeCheck(false)}
          contents={
            <>
              <CCTypography className={classes.error}>
                {translate(intl, "common.messages.maxFileSizeExceeded")}
                {/* {(() => (maxSize === "0.3mb" ? " 300kb" : " " + maxSize))()} */}
              </CCTypography>
            </>
          }
          onClick={() => setOpenSizeCheck("")}
        />
      )}
      {openMaxFileCheck && (
        <DialogError
          onClose={() => setOpenMaxFileCheck(false)}
          contents={
            <>
              <CCTypography className={classes.error}>
                {/* {translate(intl, "Exceed the maximum numbers of files")} */}
                {translate(intl, "common.ui.upTo6FilesAttached")}
              </CCTypography>
            </>
          }
          onClick={() => setOpenMaxFileCheck(false)}
        />
      )}
      {explorerOpen && (
        <Explorer
          open={explorerOpen}
          patient={patient ? patient : ""}
          onClose={() => setExplorerOpen(false)}
          onFileSelect={handlePhotoChange}
          tab={tab}
        />
      )}
      {openImageView && (
        <CCDialogSimple
          maxWidth="md"
          open={openImageView}
          onClose={() => setOpenImageView(false)}
          title={"이미지 보기"}
          onCloseButton={() => setOpenImageView(false)}
          contents={
            <Box style={{ height: 660 }}>
              <ImageViewer clickedItem={photo} />
            </Box>
          }
          endActions={
            <CCButton
              variant={"contained"}
              onClick={() => setOpenImageView(false)}
            >
              {intl.formatMessage({ id: "common.ui.ok" })}
            </CCButton>
          }
        />
      )}
      {Boolean(open) && (
        <DialogError 
          onClose={() => setOpen("")}
          contents={
            <>
              <CCTypography className={classes.error}>
                {translate(intl, "common.ui.thisFileExtensionsIsNotAllowed")}
              </CCTypography>
              <CCTypography>
                {translate(
                  intl,
                  "common.ui.youCanOnlyUploadPngJpgJpegZipStlFiles",
                  { number: open, extensions: allowExtension.join(", ") }
                )}
              </CCTypography>
            </>
          }
          onClick={() => setOpen("")}
        />
      )}

      {openUploadError && (
        <DialogError 
          onClose={() => setOpenUploadError("")}
          contents={
            <Grid container direction={"column"}>
              <CCTypography className={classes.error}>
                {intl.formatMessage(
                  {
                    id: "uploadFailedError",
                    defaultMessage: "Upload failed. Please try again."
                  },
                  {},
                  { capitalizeType: CAPITALIZE_TYPE.NONE }
                )}
              </CCTypography>
            </Grid>
          }
          onClick={() => setOpenUploadError("")}
        />
      )}
    </>
  );
};

FileUploadButton.defaultProps = {
  multiple: false,
  multipleMaxCount: 6,
  value: "",
  onChange: e => console.log(e),
  onlyDirect: false, // 바로 파일 등록 시 사용
  nonAttachPhoto: false,
  title: "이미지 등록",
  target: Target.PHOTO,
  disablePreview: true, // 오른쪽 파일 링크
  tab: 2,
  category: "photo",
  folder: "attatchment",
  startIcon: <ClipIcon />,
  //accept: ["image/jpg", "image/jpeg", "image/png"],
  allowExtension: ["PNG", "JPG", "JPEG"],
  maxSize: "10mb",
  disabled: false
};

FileUploadButton.propTypes = {
  patient: PropTypes.object // { id: patientContext.id, value: patientContext }
};

export default FileUploadButton;
