import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CCIconButton from "styles/src/components/CCIconButton";
import {
    ResizeIcon,
    ZoominIcon,
    ZoomoutIcon
} from "styles/src/themes/common/icons";
import { getObject, Target } from "utils/s3";

const useStyle = makeStyles(theme => ({
  root: {
    position: "relative",
    backgroundColor: "#000",
    height: "100%",
    "& > div": {
      height: "100%"
    },
    "& > div > div": {
      height: "100%"
    }
  },
  tools: {
    position: "absolute",
    top: 12,
    right: 12,
    height: "auto !important",
    "& button": {
      borderRadius: "50%",
      backgroundColor: "rgba(0,0,0,0.54) !important"
    }
  }
}));

const ImageViewer = props => {
  // console.log(props);
  const { type, target, id, widthImage = 788 } = props;
  const classes = useStyle();
  const [image, setImage] = useState("");
  useEffect(() => {
    let func = () => {
      switch (type) {
        case "paper":
        case "image":
          getObject(target, localStorage.getItem("labId"), id).then(res => {
            setImage(res);
          });
          break;
        case "stamp":
          getObject(Target.STAMP, localStorage.getItem("labId"), id).then(
            res => {
              setImage(res);
            }
          );
          break;
        case "logo":
          getObject(Target.LOGO, localStorage.getItem("labId"), id).then(
            res => {
              setImage(res);
            }
          );
          break;
        case "video":
          setImage("/contents/video.svg");
          break;
        case "consult":
          setImage("/contents/slide.svg");
          break;
        case "folder":
          setImage("/contents/folder.svg");
          break;
        default:
          getObject(target, localStorage.getItem("labId"), id).then(res => {
            setImage(res);
          });
      }
    };
    func();
  }, [id, target, type]);
  return (
    <Box className={classes.root}>
      <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
          // console.log(rest);
          return (
            <>
              <TransformComponent>
                <Box
                  style={{
                    width: widthImage,
                    backgroundColor: "#000",
                    height: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundPosition: "center center",
                    backgroundOrigin: "border-box",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat"
                  }}
                />
                {/*<img src={image} width={"90%"} style={{ minWidth: 788 }} alt="test" />*/}
              </TransformComponent>
              <div className={classes.tools}>
                <ButtonGroup>
                  <CCIconButton variant={"contained"} onClick={zoomIn}>
                    <ZoominIcon />
                  </CCIconButton>
                  <CCIconButton variant={"contained"} onClick={resetTransform}>
                    <ResizeIcon />
                  </CCIconButton>
                  <CCIconButton variant={"contained"} onClick={zoomOut}>
                    <ZoomoutIcon />
                  </CCIconButton>
                </ButtonGroup>
              </div>
            </>
          );
        }}
      </TransformWrapper>
    </Box>
  );
};
export default ImageViewer;

export { ImageViewer };
