const HOSPITAL_INFO = {
  TYPE: {
    CLINIC: { value: "0", localeKey: "hospitalInfo.type.clinic" },
    CITY_HOSPITAL: { value: "1", localeKey: "hospitalInfo.type.cityHospital" },
    TOWN_HOSPITAL: { value: "2", localeKey: "hospitalInfo.type.townHospital" },
    CITY_GENERAL_HOSPITAL: {
      value: "3",
      localeKey: "hospitalInfo.type.cityGeneralHospital"
    },
    TOWN_GENERAL_HOSPITAL: {
      value: "4",
      localeKey: "hospitalInfo.type.townGeneralHospital"
    }
  }
};

const SCHOOL_SETTING = {
  CATEGORY: {
    ELEMENTARY_SCHOOL: {
      value: "0",
      localeKey: "schoolSetting.category.elementarySchool"
    },
    MIDDLE_SCHOOL: {
      value: "1",
      localeKey: "schoolSetting.category.middleSchool"
    },
    HIGH_SCHOOL: { value: "2", localeKey: "schoolSetting.category.highSchool" }
  },
  MAX_GRADE: {}
};
SCHOOL_SETTING.MAX_GRADE[SCHOOL_SETTING.CATEGORY.ELEMENTARY_SCHOOL.value] = 6;
SCHOOL_SETTING.MAX_GRADE[SCHOOL_SETTING.CATEGORY.MIDDLE_SCHOOL.value] = 3;
SCHOOL_SETTING.MAX_GRADE[SCHOOL_SETTING.CATEGORY.HIGH_SCHOOL.value] = 3;

const SETTING = {
  HOSPITAL_INFO: HOSPITAL_INFO,
  SCHOOL_SETTING: SCHOOL_SETTING
};

export default SETTING;
export { HOSPITAL_INFO, SCHOOL_SETTING };
