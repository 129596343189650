import { useLazyQuery, useQuery } from "@apollo/client";
import { Box, FormControlLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { PermissionBackdrop, RadioGroup, useReadPermission } from "components";
import moment from "moment";
import { PRODUCT_LOG_LIST } from "queries/product";
import React, { useEffect, useState, useMemo } from "react";
import { CCTextField } from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import { CCFlattenDateField } from "styles/src/components/CCFlattenDateTimePicker";
import CCPaper from "styles/src/components/CCPaper";
import CCTypography from "styles/src/components/CCTypography";
import { SearchIcon } from "styles/src/themes/common/icons";
import { INOUT_STOCK } from "types/inventory";
import { getEndTimestamp, getMomentFormatTimestamp } from "utils/datetime";
import { useIntl } from "utils/language";
import { makeList } from "utils/type";
import SearchResults from "./Component/SearchResults";

const useStyle = makeStyles(theme => ({
  init: {
    position: "relative",
    height: "calc(100% - 70px)",
    padding: 16,
    backgroundColor: theme.palette.common.white
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: 8,
    marginBottom: 16,
    minWidth: 1400
  },
  input: {
    backgroundColor: theme.palette.background.paper
  },
  timeInput: {
    width: 130
  },
  selectInput: {
    width: 142
  },
  memoInput: {
    width: 236
  },
  marginLeft8: {
    marginLeft: 8
  },
  description: {
    display: "flex",
    marginLeft: "10px",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px"
  },
  paper: {
    width: "100%",
    height: props => {
      if (props?.collapse && props?.collapseRef?.current) {
        return `calc(100% - ${props?.collapseRef?.current.offsetHeight}px)`;
      } else {
        return `calc(100% - 110px)`;
      }
    },
    minWidth: 1000
  },
  select__field: {
    marginLeft: 20,
    width: 200
  },
  small__button: {
    "&:hover": {
      backgroundColor: theme.palette.select.main
    }
  },
  selected: {
    backgroundColor: theme.palette.select.main,
    cursor: "default"
  }
}));

const initSelectedValue = {
  major: [],
  minor: null,
  name: "",
  alarm: null,
  stock: false,
  inOut: "0",
  noStock: {}
};

const PERMISSION = "inventoryHistory";

const InoutTab = props => {
  const { product, majorProps } = props;
  const classes = useStyle();

  // const permission = useReadPermission(PERMISSION);
  const permission = true;
  const [major, setMajor] = useState([]);
  const [searchResult, setSearchResult] = useState();
  const [selectedValue, setSelectedValue] = useState(initSelectedValue);

  const [date, setDate] = useState({
    begin: moment().subtract(1, "month").add(1, "day").unix(),
    end: getEndTimestamp("day")
  });

  const intl = useIntl();
  const ALL_SELECTION = intl.formatMessage({ id: "all" });
  const [productMajor, setProductMajor] = useState([ALL_SELECTION]);
  const [inventoryStatus, setInventoryStatus] = useState([ALL_SELECTION]);

  const InoutStock = makeList(INOUT_STOCK);

  // const [getProduct] = useLazyQuery(SEARCH_PRODUCT_LOG, {
  //   fetchPolicy: "no-cache",
  //   onCompleted: data => {
  //     if (data?.searchProductLog) {
  //       setSearchResult(data.searchProductLog);
  //     }
  //   }
  // });

  const { data: productLogData, refetch } = useQuery(PRODUCT_LOG_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      query: { productID: product?.productID, }
    }
  });


  useEffect(() => {
    setSearchResult(productLogData?.productLogList.items)
  }, [productLogData])


  useEffect(() => {
    setMajor(majorProps)
  }, [majorProps])

  const tabList =
    selectedValue?.inOut === "0"
      ? [
        'registerProduct',
        "errorCorrection",
        "otherPurchase",
        "otherAcquisition",
        "materialUsage",
        "otherReturn"
      ]
      : selectedValue?.inOut === "1"
        ? ["registerProduct", "errorCorrection", "otherPurchase", "otherAcquisition",]
        : ["materialUsage", "errorCorrection", "otherReturn", "otherAcquisition"];

  const periodFilter = useMemo(() => {
    return (
      moment(date.end, "X").diff(moment(date.begin, "X"), 'months') >= 2
    );
  }, [date]);

  return (
    <>
      <Grid className={classes.init}>
        <CCPaper className={classes.root}>
          {/* <PermissionBackdrop permission={PERMISSION} /> */}
          {permission && (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              wrap={"nowrap"}
            >
              <Grid
                item
                style={{
                  paddingLeft: 12,
                  paddingRight: 12,
                  minHeight: 54,
                  height: 54
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  style={{ height: "100%" }}
                >
                  <CCTextField
                    label={intl.formatMessage({ id: "majorCategory" })}
                    className={classes.select__field}
                    style={{ width: "168px" }}
                    variant={"outlined"}
                    margin={"dense"}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: value => {
                        setProductMajor(value);
                        return value.join(", ");
                      }
                    }}
                    value={productMajor}
                    onChange={e => {
                      const {
                        target: { value }
                      } = e;

                      if (value.length >= 1) {
                        if (value[value.length - 1] === ALL_SELECTION) {
                          setProductMajor([ALL_SELECTION]);
                        } else if (value[0] === ALL_SELECTION) {
                          setProductMajor(
                            value?.filter(item => item !== ALL_SELECTION)
                          );
                        } else {
                          setProductMajor(value);
                        }
                      } else {
                        setProductMajor([ALL_SELECTION])
                      }
                    }}
                  >
                    <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                      {ALL_SELECTION}
                    </MenuItem>
                    {Boolean(major) &&
                      major.map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </CCTextField>
                  <CCTextField
                    className={classes.head__field}
                    style={{ width: "300px" }}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({
                      id: "searchProductwithKeyword"
                    })}
                    value={selectedValue?.name}
                    onChange={e => {
                      setSelectedValue({
                        ...selectedValue,
                        name: e.target.value
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems={"center"} wrap={"nowrap"}>
                  <CCTypography variant={"h6"} style={{ width: "130px" }}>
                    {intl.formatMessage({ id: "inquiryPeriod" })}
                  </CCTypography>
                  <Grid item>
                    <CCFlattenDateField
                      label={intl.formatMessage({ id: "dateText" })}
                      onChange={e => {
                        setDate({
                          ...date,
                          begin: e.startOf("day").unix()
                        });
                      }}
                      value={
                        date?.begin
                          ? getMomentFormatTimestamp(date.begin, "X")
                          : false
                      }
                      className={clsx(classes.input, classes.timeInput)}
                    />
                  </Grid>
                  <Grid item>{"~"}</Grid>
                  <Grid item>
                    <CCFlattenDateField
                      label={intl.formatMessage({ id: "dateText" })}
                      onChange={e => {
                        setDate({
                          ...date,
                          end: e.endOf("day").unix()
                        });
                      }}
                      value={
                        date?.end
                          ? getMomentFormatTimestamp(date.end, "X")
                          : false
                      }
                      className={clsx(classes.input, classes.timeInput)}
                    />
                  </Grid>
                  <Grid item className={classes.marginLeft8}>
                    <CCButton
                      variant={"contained"}
                      size={"small"}
                      color={"normal"}
                      className={clsx(classes.small__button, {
                        [classes.selected]: !periodFilter
                      })}
                      onClick={() => {
                        setDate({
                          begin: moment()
                            .subtract(1, "month")
                            .add(1, "day")
                            .unix(),
                          end: getEndTimestamp("day")
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "monthUnit" }, { number: 1 })}
                    </CCButton>
                  </Grid>
                  <Grid item className={classes.marginLeft8}>
                    <CCButton
                      variant={"contained"}
                      size={"small"}
                      color={"normal"}
                      className={clsx(classes.small__button, {
                        [classes.selected]: periodFilter
                      })}
                      onClick={() => {
                        setDate({
                          begin: moment()
                            .subtract(3, "month")
                            .add(1, "day")
                            .unix(),
                          end: getEndTimestamp("day")
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "monthsUnit" }, { number: 3 })}
                    </CCButton>
                  </Grid>

                  <Box className={clsx(classes.description, classes.divider)} />
                  <Grid
                    container
                    alignItems={"center"}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <FormControlLabel
                      control={
                        <RadioGroup
                          tabList={InoutStock}
                          value={selectedValue?.inOut}
                          onChange={e => {
                            setSelectedValue({
                              ...selectedValue,
                              stock: null,
                              inOut: e?.value
                            });
                            setInventoryStatus([ALL_SELECTION]);
                          }}
                          required={true}
                          defaultValue={0}
                        />
                      }
                      label={
                        <CCTypography variant="h6">
                          {intl.formatMessage({ id: "inquiryCondition" })}
                        </CCTypography>
                      }
                      labelPlacement="start"
                    />

                    <CCTextField
                      className={classes.select__field}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({ id: "inventoryStatus" })}
                      select
                      SelectProps={{
                        multiple: true,
                        renderValue: value => {
                          setInventoryStatus(value);
                          return value
                            ?.map(e => intl.formatMessage({ id: e }))
                            .join(", ");
                        }
                      }}
                      value={inventoryStatus}
                      onChange={e => {
                        const {
                          target: { value }
                        } = e;

                        if (value.length > 1) {
                          if (value[value.length - 1] === ALL_SELECTION) {
                            setInventoryStatus([ALL_SELECTION]);
                          } else if (value[0] === ALL_SELECTION) {
                            setInventoryStatus(
                              value?.filter(item => item !== ALL_SELECTION)
                            );
                          } else {
                            setInventoryStatus(value);
                          }
                        } else {
                          value?.indexOf(ALL_SELECTION) !== -1
                            ? setInventoryStatus([ALL_SELECTION])
                            : setInventoryStatus(value);
                        }
                      }}
                    >
                      <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                        {ALL_SELECTION}
                      </MenuItem>
                      {tabList.map(e => (
                        <MenuItem key={e} value={e}>
                          {intl.formatMessage({ id: e })}
                        </MenuItem>
                      ))}
                    </CCTextField>

                    <Grid item className={classes.marginLeft8}>
                      <CCButton
                        style={{
                          height: 36,
                          paddingTop: 0,
                          paddingBottom: 0,
                          width: 180
                        }}
                        variant={"contained"}
                        color={"secondary"}
                        startIcon={<SearchIcon />}
                        onClick={() => {
                          const _variable = {
                            major: productMajor
                              ? productMajor.includes(ALL_SELECTION) ||
                                productMajor === [ALL_SELECTION] ||
                                productMajor === undefined
                                ? []
                                : productMajor
                              : [],
                            searchText: selectedValue?.name
                              ? selectedValue?.name
                              : '',
                            detailedClassification:
                              !inventoryStatus ||
                                inventoryStatus.includes(ALL_SELECTION)
                                ? []
                                : inventoryStatus,
                            createdAt: {
                              from: date?.begin,
                              to: date?.end
                            },
                            inOutType:
                              selectedValue?.inOut === "2"
                                ? "inventoryShipping"
                                : selectedValue?.inOut === "1"
                                  ? "inventoryReceiving"
                                  : '',
                          };

                          refetch({
                            query: _variable
                          });
                        }}
                      >
                        {intl.formatMessage({ id: "search" })}
                      </CCButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CCPaper>
        <CCPaper className={classes.paper}>
          <SearchResults result={searchResult} />
        </CCPaper>
      </Grid>
    </>
  );
};

export default InoutTab;
