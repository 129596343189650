import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";

const selectHeight = 20;

const useStyles = makeStyles(theme => {
  const radius = theme.shape.borderRadius;

  return {
    left: { marginRight: -1, marginLeft: 0 },
    right: { marginRight: 0, marginLeft: 0 },
    input__left: {
      borderRadius: `${radius}px 0px 0px ${radius}px`,
      "& > fieldset": {
        borderRightWidth: 0
      },
      "& > input": {
        "&.Mui-disabled": {
          borderRadius: `${radius}px 0px 0px ${radius}px`
        }
      }
    },
    input__mid__1: {
      borderRadius: 0,
      "& > input": {
        "&.Mui-disabled": {
          borderRadius: 0
        }
      }
    },
    input__mid__2: {
      borderRadius: 0,
      "& > input": {
        "&.Mui-disabled": {
          borderRadius: 0
        }
      }
    },
    input__right: {
      borderRadius: `0px ${radius}px ${radius}px 0px`,
      "& > fieldset": {
        borderLeftWidth: 0
      },
      "& > input": {
        "&.Mui-disabled": {
          borderRadius: `0px ${radius}px ${radius}px 0px`
        }
      }
    },
    input__disabled: {
      "&.Mui-disabled": {
        backgroundColor: theme.palette.inactive.rgba
      },
      "& > input": {
        "&.Mui-disabled": {
          backgroundColor: "unset"
        }
      }
    },
    input__focus__left: {
      "& > fieldset": {
        borderLeftWidth: 0
      }
    },
    input__focus__right: {
      "& > fieldset": {
        borderRightWidth: 0
      }
    },
    startAdornment: {
      marginRight: 0
    },
    endAdornmentRight: {
      marginLeft: 0
    },
    startIconButton: {
      padding: 5
    },
    endIconButton: {
      padding: 5
    },
    iconButton__focus: {
      color: theme.palette.select.main
    }
  };
});

const selectStyles = makeStyles(() => ({
  root: {},
  select: {
    height: selectHeight,
    lineHeight: `${selectHeight}px`
  },
  outlined: {},
  selectMenu: {},
  disabled: {}
}));

const useLocaleStyles = makeStyles(
  () => ({
    notchedOutline: {
      "&  legend": {
        fontSize: props =>
          `${
            Boolean(props?.labelLegendSize) ? props.labelLegendSize : 0.75
          }em !important`
      }
    },
    marginDense: {
      fontSize: props =>
        `${
          Boolean(props?.labelFontSize)
            ? props.labelFontSize
            : localStorage.getItem("lang") === "ru"
            ? 12
            : 14
        }px !important`
    }
  }),
  { name: "CCTextFieldTrio" }
);

const CCTextFieldQuattro = props => {
  const classes = useStyles();
  const selectClasses = selectStyles();

  const { LeftProps, MidProps, Mid2Props, RightProps, ...other } = props;
  const LLocaleClasses = useLocaleStyles(LeftProps);
  const MLocaleClasses = useLocaleStyles(MidProps);
  const M2LocaleClasses = useLocaleStyles(Mid2Props);
  const RLocaleClasses = useLocaleStyles(RightProps);
  const {
    label: Llabel,
    value: Lvalue,
    classes: Lclasses,
    className: LclassName,
    InputLabelProps: LinputLabelProps,
    InputProps: LinputProps,
    onBlur: LonBlur,
    onChange: LonChange,
    onClick: LonClick,
    onFocus: LonFocus,
    onKeyPress: LonKeyPress,
    SelectProps: LselectProps,
    startIcon: LstartIcon,
    endIcon: LendIcon,
    onClickIcon: LonClickIcon,
    variant: Lvariant,
    inputProps: LrawInputProps,
    multiline: Lmultiline,
    ...Lother
  } = LeftProps;

  const {
    label: Mlabel,
    value: Mvalue,
    classes: Mclasses,
    className: MclassName,
    InputLabelProps: MinputLabelProps,
    InputProps: MinputProps,
    onBlur: MonBlur,
    onChange: MonChange,
    onClick: MonClick,
    onFocus: MonFocus,
    onKeyPress: MonKeyPress,
    SelectProps: MselectProps,
    startIcon: MstartIcon,
    endIcon: MendIcon,
    onClickIcon: MonClickIcon,
    variant: Mvariant,
    inputProps: MrawInputProps,
    multiline: Mmultiline,
    ...Mother
  } = MidProps;

  const {
    label: M2label,
    value: M2value,
    classes: M2classes,
    className: M2className,
    InputLabelProps: M2inputLabelProps,
    InputProps: M2inputProps,
    onBlur: M2onBlur,
    onChange: M2onChange,
    onClick: M2onClick,
    onFocus: M2onFocus,
    onKeyPress: M2onKeyPress,
    SelectProps: M2selectProps,
    startIcon: M2startIcon,
    endIcon: M2endIcon,
    onClickIcon: M2onClickIcon,
    variant: M2variant,
    inputProps: M2rawInputProps,
    multiline: M2multiline,
    ...M2other
  } = Mid2Props;

  const {
    label: Rlabel,
    value: Rvalue,
    classes: Rclasses,
    className: RclassName,
    InputLabelProps: RinputLabelProps,
    InputProps: RinputProps,
    onBlur: RonBlur,
    onChange: RonChange,
    onClick: RonClick,
    onFocus: RonFocus,
    onKeyPress: RonKeyPress,
    SelectProps: RselectProps,
    startIcon: RstartIcon,
    endIcon: RendIcon,
    onClickIcon: RonClickIcon,
    variant: Rvariant,
    inputProps: RrawInputProps,
    multiline: Rmultiline,
    ...Rother
  } = RightProps;

  const [lFocuse, setLfocuse] = useState(false);
  const [mFocuse, setMfocuse] = useState(false);
  const [m2Focuse, setM2focuse] = useState(false);
  const [rFocuse, setRfocuse] = useState(false);
  const L_DEFAULT_MAX_LENGTH = Boolean(Lmultiline) ? 500 : 200;
  const M_DEFAULT_MAX_LENGTH = Boolean(Mmultiline) ? 500 : 200;
  const M2_DEFAULT_MAX_LENGTH = Boolean(M2multiline) ? 500 : 200;
  const R_DEFAULT_MAX_LENGTH = Boolean(Rmultiline) ? 500 : 200;

  const LinputMore = {};
  const MinputMore = {};
  const M2inputMore = {};
  const RinputMore = {};

  if (LinputProps) {
    if (LinputProps.startAdornment !== "undefined")
      LinputMore.startAdornment = LinputProps.startAdornment;
    if (LinputProps.endAdornment !== "undefined")
      LinputMore.endAdornment = LinputProps.endAdornment;
    if (LinputProps.className !== "undefined")
      LinputMore.className = LinputProps.className;
  }

  if (MinputProps) {
    if (MinputProps.startAdornment !== "undefined")
      MinputMore.startAdornment = MinputProps.startAdornment;
    if (MinputProps.endAdornment !== "undefined")
      MinputMore.endAdornment = MinputProps.endAdornment;
    if (MinputProps.className !== "undefined")
      MinputMore.className = MinputProps.className;
  }

  if (M2inputProps) {
    if (M2inputProps.startAdornment !== "undefined")
      M2inputMore.startAdornment = M2inputProps.startAdornment;
    if (M2inputProps.endAdornment !== "undefined")
      M2inputMore.endAdornment = M2inputProps.endAdornment;
    if (M2inputProps.className !== "undefined")
      M2inputMore.className = M2inputProps.className;
  }

  if (RinputProps) {
    if (RinputProps.startAdornment !== "undefined")
      RinputMore.startAdornment = RinputProps.startAdornment;
    if (RinputProps.endAdornment !== "undefined")
      RinputMore.endAdornment = RinputProps.endAdornment;
    if (RinputProps.className !== "undefined")
      RinputMore.className = RinputProps.className;
  }

  return (
    <>
      <TextField
        label={Llabel}
        value={Lvalue}
        classes={Lclasses}
        className={clsx(classes.left, LclassName)}
        InputLabelProps={{
          ...LinputLabelProps,
          classes: {
            ...LinputLabelProps?.classes,
            marginDense: clsx(LLocaleClasses.marginDense)
          }
        }}
        inputProps={{
          maxLength: L_DEFAULT_MAX_LENGTH,
          ...LrawInputProps
        }}
        InputProps={{
          ...LinputProps,
          classes: {
            ...LinputProps?.classes,
            notchedOutline: clsx(LLocaleClasses.notchedOutline)
          },
          className: LinputMore.className
            ? clsx(LinputProps.className, {
                [classes.input__left]: true,
                [classes.input__disabled]:
                  LstartIcon ||
                  LendIcon ||
                  LinputMore.startAdornment ||
                  LinputMore.endAdornment
              })
            : clsx({
                [classes.input__left]: true,
                [classes.input__disabled]:
                  LstartIcon ||
                  LendIcon ||
                  LinputMore.startAdornment ||
                  LinputMore.endAdornment
              }),
          startAdornment: LinputMore.startAdornment ? (
            LinputMore.startAdornment
          ) : LstartIcon ? (
            <InputAdornment className={classes.startAdornment} position="start">
              <IconButton
                className={clsx({
                  [classes.startIconButton]: true,
                  [classes.iconButton__focus]: lFocuse
                })}
                onClick={LonClickIcon}
                edge="start"
              >
                {LstartIcon}
              </IconButton>
            </InputAdornment>
          ) : null,
          endAdornment: LinputMore.endAdornment ? (
            LinputMore.startAdornment
          ) : LendIcon ? (
            <InputAdornment className={classes.endAdornment} position="end">
              <IconButton
                className={clsx({
                  [classes.endIconButton]: true,
                  [classes.iconButton__focus]: lFocuse
                })}
                onClick={LonClickIcon}
                edge="end"
              >
                {LendIcon}
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        onBlur={e => {
          LonBlur && LonBlur(e);
          setLfocuse(false);
        }}
        onChange={LonChange}
        onClick={LonClick}
        onFocus={e => {
          LonFocus && LonFocus(e);
          setLfocuse(true);
        }}
        onKeyPress={LonKeyPress}
        variant={Lvariant}
        SelectProps={{
          ...LselectProps,
          classes: selectClasses
        }}
        {...Lother}
        {...other}
      />
      <TextField
        label={Mlabel}
        value={Mvalue}
        classes={Mclasses}
        className={clsx(classes.left, MclassName)}
        InputLabelProps={{
          ...MinputLabelProps,
          classes: {
            ...MinputLabelProps?.classes,
            marginDense: clsx(MLocaleClasses.marginDense)
          }
        }}
        inputProps={{
          maxLength: M_DEFAULT_MAX_LENGTH,
          ...MrawInputProps
        }}
        InputProps={{
          ...MinputProps,
          classes: {
            ...MinputProps?.classes,
            notchedOutline: clsx(MLocaleClasses.notchedOutline)
          },
          className: MinputMore.className
            ? clsx(MinputProps.className, {
                [classes.input__mid__1]: true,
                [classes.input__mid__2]: true,
                [classes.input__disabled]:
                  MstartIcon ||
                  MendIcon ||
                  MinputMore.startAdornment ||
                  MinputMore.endAdornment,
                [classes.input__focus__left]: lFocuse,
                [classes.input__focus__right]: rFocuse
              })
            : clsx({
                [classes.input__mid__1]: true,
                [classes.input__mid__2]: true,
                [classes.input__disabled]:
                  MstartIcon ||
                  MendIcon ||
                  MinputMore.startAdornment ||
                  MinputMore.endAdornment,
                [classes.input__focus__left]: lFocuse,
                [classes.input__focus__right]: rFocuse
              }),
          startAdornment: MinputMore.startAdornment ? (
            MinputMore.startAdornment
          ) : MstartIcon ? (
            <InputAdornment className={classes.startAdornment} position="start">
              <IconButton
                className={clsx({
                  [classes.startIconButton]: true,
                  [classes.iconButton__focus]: mFocuse
                })}
                onClick={MonClickIcon}
                edge="start"
              >
                {MstartIcon}
              </IconButton>
            </InputAdornment>
          ) : null,
          endAdornment: MinputMore.endAdornment ? (
            MinputMore.startAdornment
          ) : MendIcon ? (
            <InputAdornment className={classes.endAdornment} position="end">
              <IconButton
                className={clsx({
                  [classes.endIconButton]: true,
                  [classes.iconButton__focus]: mFocuse
                })}
                onClick={MonClickIcon}
                edge="end"
              >
                {MendIcon}
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        onBlur={e => {
          MonBlur && MonBlur(e);
          setMfocuse(false);
        }}
        onChange={MonChange}
        onClick={MonClick}
        onFocus={e => {
          MonFocus && MonFocus(e);
          setMfocuse(true);
        }}
        onKeyPress={MonKeyPress}
        variant={Mvariant}
        SelectProps={{
          ...MselectProps,
          classes: selectClasses
        }}
        {...Mother}
        {...other}
      />
      <TextField
        label={M2label}
        value={M2value}
        classes={M2classes}
        className={clsx(classes.left, M2className)}
        InputLabelProps={{
          ...M2inputLabelProps,
          classes: {
            ...M2inputLabelProps?.classes,
            marginDense: clsx(M2LocaleClasses.marginDense)
          }
        }}
        inputProps={{
          maxLength: M2_DEFAULT_MAX_LENGTH,
          ...M2rawInputProps
        }}
        InputProps={{
          ...M2inputProps,
          classes: {
            ...M2inputProps?.classes,
            notchedOutline: clsx(M2LocaleClasses.notchedOutline)
          },
          className: M2inputMore.className
            ? clsx(M2inputProps.className, {
                [classes.input__mid__1]: true,
                [classes.input__mid__2]: true,
                [classes.input__disabled]:
                  M2startIcon ||
                  M2endIcon ||
                  M2inputMore.startAdornment ||
                  M2inputMore.endAdornment,
                [classes.input__focus__left]: lFocuse,
                [classes.input__focus__right]: rFocuse
              })
            : clsx({
                [classes.input__mid__1]: true,
                [classes.input__mid__2]: true,
                [classes.input__disabled]:
                  M2startIcon ||
                  M2endIcon ||
                  M2inputMore.startAdornment ||
                  M2inputMore.endAdornment,
                [classes.input__focus__left]: lFocuse,
                [classes.input__focus__right]: rFocuse
              }),
          startAdornment: M2inputMore.startAdornment ? (
            M2inputMore.startAdornment
          ) : M2startIcon ? (
            <InputAdornment className={classes.startAdornment} position="start">
              <IconButton
                className={clsx({
                  [classes.startIconButton]: true,
                  [classes.iconButton__focus]: m2Focuse
                })} //123
                onClick={M2onClickIcon}
                edge="start"
              >
                {M2startIcon}
              </IconButton>
            </InputAdornment>
          ) : null,
          endAdornment: M2inputMore.endAdornment ? (
            M2inputMore.endAdornment
          ) : M2endIcon ? (
            <InputAdornment className={classes.endAdornment} position="end">
              <IconButton
                className={clsx({
                  [classes.endIconButton]: true,
                  [classes.iconButton__focus]: m2Focuse
                })}
                onClick={M2onClickIcon}
                edge="end"
              >
                {M2endIcon}
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        onBlur={e => {
          M2onBlur && M2onBlur(e);
          setM2focuse(false);
        }}
        onChange={M2onChange}
        onClick={M2onClick}
        onFocus={e => {
          M2onFocus && M2onFocus(e);
          setM2focuse(true);
        }}
        onKeyPress={M2onKeyPress}
        variant={M2variant}
        SelectProps={{
          ...M2selectProps,
          classes: selectClasses
        }}
        {...M2other}
        {...other}
      />
      <TextField
        label={Rlabel}
        value={Rvalue}
        classes={Rclasses}
        className={clsx(classes.right, RclassName)}
        InputLabelProps={{
          ...RinputLabelProps,
          classes: {
            ...RinputLabelProps?.classes,
            marginDense: clsx(RLocaleClasses.marginDense)
          }
        }}
        inputProps={{
          maxLength: R_DEFAULT_MAX_LENGTH,
          ...RrawInputProps
        }}
        InputProps={{
          ...RinputProps,
          classes: {
            ...RinputProps?.classes,
            notchedOutline: clsx(RLocaleClasses.notchedOutline)
          },
          className: RinputMore.className
            ? clsx(RinputProps.className, {
                [classes.input__right]: true,
                [classes.input__disabled]:
                  RstartIcon ||
                  RendIcon ||
                  RinputMore.startAdornment ||
                  RinputMore.endAdornment
              })
            : clsx({
                [classes.input__right]: true,
                [classes.input__disabled]:
                  RstartIcon ||
                  RendIcon ||
                  RinputMore.startAdornment ||
                  RinputMore.endAdornment
              }),
          startAdornment: RinputMore.startAdornment ? (
            RinputMore.startAdornment
          ) : RstartIcon ? (
            <InputAdornment className={classes.startAdornment} position="start">
              <IconButton
                className={clsx({
                  [classes.startIconButton]: true,
                  [classes.iconButton__focus]: rFocuse
                })}
                onClick={RonClickIcon}
                edge="start"
              >
                {RstartIcon}
              </IconButton>
            </InputAdornment>
          ) : null,
          endAdornment: RinputMore.endAdornment ? (
            RinputMore.endAdornment
          ) : RendIcon ? (
            <InputAdornment className={classes.endAdornment} position="end">
              <IconButton
                className={clsx({
                  [classes.endIconButton]: true,
                  [classes.iconButton__focus]: rFocuse
                })}
                onClick={RonClickIcon}
                edge="end"
              >
                {RendIcon}
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        onBlur={e => {
          RonBlur && RonBlur(e);
          setRfocuse(false);
        }}
        onChange={RonChange}
        onClick={RonClick}
        onFocus={e => {
          RonFocus && RonFocus(e);
          setRfocuse(true);
        }}
        onKeyPress={RonKeyPress}
        variant={Rvariant}
        SelectProps={{
          ...RselectProps,
          classes: selectClasses
        }}
        {...Rother}
        {...other}
      />
    </>
  );
};

CCTextFieldQuattro.propTypes = {
  /** TextField 좌측에 위치 */
  LeftProps: PropTypes.object,
  /** TextField 중앙1 에 위치 */
  MidProps: PropTypes.object,
  /** TextField 중앙2 에 위치 */
  Mid2Props: PropTypes.object,
  /** TextField 우측에 위치 */
  RightProps: PropTypes.object
};

export default CCTextFieldQuattro;
