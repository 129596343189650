import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./components";

const WithRouter = props => (
  <Router>
    <Routes {...props} />
  </Router>
);

export default WithRouter;
