import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, ClickAwayListener, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  CVButton,
  DialogPermissionWarn,
  enqueueToastSave,
  useWritePermission
} from "components";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "context";
import {
  CCDialogActions,
  CCDialogContent,
  CCDialogTitle,
  CCPaper,
  CCTextFieldNumber,
  CCTypography
} from "styles/components";
import { CheckIcon } from "styles/icons";
import { useIntl } from "utils/language";
import { translate } from "components";

const GET_OTHER_SETTING = gql`
  query getConfig {
    labDetail {
      passwordChangeCycle
      automaticLogOut
    }
  }
`;

const UPDATE_OTHER_SETTING = gql`
  mutation updateConfig($passwordChangeCycle: Int, $automaticLogOut: Int) {
    updateLab(
      input: {
        passwordChangeCycle: $passwordChangeCycle
        automaticLogOut: $automaticLogOut
      }
    ) {
      code
      message
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: { height: "100%", width: "1248px" },
  container: {
    height: "100%",
    width: "100%",
    paddingLeft: "8px",
    display: "flex",
    flexDirection: "row"
  },
  heading: { padding: "8px 16px" },
  selected: { color: theme.palette.common.white },
  icon: { position: "absolute", top: -2, right: 16 },
  table: {
    height: "321px",
    width: "766px"
  },
  table__body: {
    backgroundColor: theme.palette.common.white,
    "& > div:nth-child(even)": {
      background: theme.palette.background.default
    }
  },
  table__header: {
    display: "flex",
    flexDirection: "row",
    height: "36px",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  field__value: {
    width: "260px",
    height: "100%",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center"
  },
  field__description: {
    width: "505px",
    height: "100%",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center"
  },
  field__description__header: {
    justifyContent: "center"
  },
  field__table: {
    display: "flex",
    flexDirection: "row",
    height: "72px",
    alignItems: "center"
  },
  text__description: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  ml32: {
    marginLeft: "32px"
  },
  checkbox: {
    height: "32px"
  },
  selector__number: {
    width: "72px",
    minWidth: "72px",
    margin: "0px 8px",
    backgroundColor: theme.palette.common.white,
    direction: 'ltr'
  },
  ml8: {
    marginLeft: "8px",
    marginRight: "8px",
    display: "flex",
    alignItems: "center"
  },
  condition: {
    flexDirection: "row",
    alignItems: "center",
    position: "relative"
  },
  ml24: {
    marginLeft: "24px"
  }
}));

const AdvancedSetting = () => {
  // classes
  const classes = useStyles();
  const intl = useIntl();
  const isPermission = useWritePermission("advancedOtherSetting");
  const { enqueueSnackbar } = useSnackbar();
  const { dispatchApp } = useContext(AppContext);

  // value
  const [passwordDayLimit, setPasswordDayLimit] = useState(180);
  const [loginTimeLimit, setLoginTimeLimit] = useState(240);
  const [openWarning, setOpenWarning] = useState(false);

  // function
  const onSave = () => {
    updateSetting({
      variables: {
        passwordChangeCycle: passwordDayLimit,
        automaticLogOut: loginTimeLimit < 10 ? 10 : loginTimeLimit
      },
      refetchQueries: GET_OTHER_SETTING
    });
  };
  const onCancel = () => {
    refetch().then(result => {
      if (result.data && result.data.labDetail) {
        const { labDetail } = result.data;
        setPasswordDayLimit(
          labDetail.passwordChangeCycle ? labDetail.passwordChangeCycle : 180
        );
        setLoginTimeLimit(
          labDetail.automaticLogOut ? labDetail.automaticLogOut : 240
        );
      } else {
        setPasswordDayLimit(180);
        setLoginTimeLimit(240);
      }
    });
  };

  // graphql
  const { data, refetch } = useQuery(GET_OTHER_SETTING, {
    fetchPolicy: "network-only"
  });
  const [updateSetting] = useMutation(UPDATE_OTHER_SETTING, {
    onCompleted: data => {
      if (data.updateLab && data.updateLab.code === 200) {
        dispatchApp({
          target: loginTimeLimit,
          type: "automaticLogOut"
        });
      }
      refetch();
      enqueueToastSave(intl, enqueueSnackbar);
    }
  });

  // useEffect
  useEffect(() => {
    if (data && data.labDetail) {
      setPasswordDayLimit(data.labDetail.passwordChangeCycle);
      setLoginTimeLimit(
        data.labDetail.automaticLogOut ? data.labDetail.automaticLogOut : 240
      );
    }
  }, [data]);
  let lang = localStorage.getItem('lang')
  let passwordChangeCycleHead = intl.formatMessage({ id: "passwordChangeCycleDetail" })
  let passwordChangeCycleTail = intl.formatMessage({ id: "passwordChangeCycleDetailTail" })
  let automaticLogoutDetailHead = intl.formatMessage({ id: "automaticLogoutDetail" })
  let automaticLogoutDetailTail =  intl.formatMessage({ id: "automaticLogoutDetailTail" })
  switch (lang) {
    case 'ar':
      passwordChangeCycleHead = intl.formatMessage({ id: "passwordChangeCycleDetailTail" })
      passwordChangeCycleTail = intl.formatMessage({ id: "passwordChangeCycleDetail" })
      automaticLogoutDetailHead = intl.formatMessage({ id: "automaticLogoutDetailTail" })
      automaticLogoutDetailTail = intl.formatMessage({ id: "automaticLogoutDetail" })
      break;
    case 'ko':
      passwordChangeCycleHead = ""
      automaticLogoutDetailHead = ""
      break;
  }
  return (
    <Box className={classes.root}>
      <CCPaper type="normal" style={{ height: "100%", position: "relative" }}>
        <CCDialogTitle>
          {intl.formatMessage(
            // { id: "otherSetting" },
            { id: "advancedOtherSetting" },
            {}
            // { capitalizeType: CAPITALIZE_TYPE.APA }
          )}
        </CCDialogTitle>
        <CCDialogContent style={{ height: `calc(100% - 56px )` }}>
          <CCPaper className={classes.table}>
            <CCDialogContent
              style={{ height: `calc(100% - 68px)` }}
              noPadding={true}
            >
              <Grid className={classes.table__body} container>
                <Grid item>
                  <Grid className={classes.table__header} container>
                    <Grid className={classes.field__value} item>
                      <Box display="flex" flexDirection="row">
                        <Box width="50px" />
                        <CCTypography className={classes.ml32}>
                          {translate(intl, "function")}
                        </CCTypography>
                      </Box>
                    </Grid>
                    <Grid
                      className={clsx(
                        classes.field__description,
                        classes.field__description__header
                      )}
                      item
                    >
                      <CCTypography>
                        {translate(intl, "functionDetail")}
                      </CCTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid className={classes.field__table} container>
                    <Grid className={classes.field__value} item>
                      <CCTypography className={classes.ml32} variant="h5">
                        {intl.formatMessage(
                          { id: "passwordChangeCycle" },
                          {}
                          //   { capitalizeType: CAPITALIZE_TYPE.APA }
                        )}
                      </CCTypography>
                    </Grid>
                    <Grid className={classes.field__description} item>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <CCTypography className={classes.ml8}>
                          {
                            <>
                              {passwordChangeCycleHead}
                              <CCTextFieldNumber
                                className={clsx({
                                  [classes.selector__number]: true
                                })}
                                number={passwordDayLimit}
                                decimalPoint={0}
                                step={1}
                                onChange={value => {
                                  if (!isPermission) {
                                    setOpenWarning(true);
                                    return;
                                  }
                                  if (value !== "") {
                                    const newValue = parseInt(
                                      !value ? 1 : value
                                    );
                                    setPasswordDayLimit(
                                      Math.min(Math.max(newValue, 1), 180)
                                    );
                                  } else {
                                    setPasswordDayLimit(value);
                                  }
                                }}
                                onBlur={() => {
                                  if (passwordDayLimit === "") {
                                    setPasswordDayLimit(1);
                                  }
                                }}
                              />
                              {passwordChangeCycleTail}
                            </>
                          }
                        </CCTypography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid className={classes.field__table} container>
                    <Grid className={classes.field__value} item>
                      <CCTypography className={classes.ml32} variant="h5">
                        {translate(intl, "automaticLogout")}
                      </CCTypography>
                    </Grid>
                    <Grid className={classes.field__description} item>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <CCTypography className={classes.ml8}>
                          {localStorage.getItem("lang") !== "ko" ? (
                            <>
                              {automaticLogoutDetailHead}
                              <ClickAwayListener
                                onClickAway={() => {
                                  if (loginTimeLimit < 10) {
                                    setLoginTimeLimit(10);
                                  }
                                }}
                              >
                                <div>
                                  <CCTextFieldNumber
                                    className={clsx({
                                      [classes.selector__number]: true
                                    })}
                                    value={loginTimeLimit}
                                    number={loginTimeLimit}
                                    decimalPoint={0}
                                    step={1}
                                    onChange={value => {
                                      if (!isPermission) {
                                        setOpenWarning(true);
                                        return;
                                      }
                                      if (value !== "") {
                                        const newValue = parseInt(
                                          !value ? 1 : value
                                        );
                                        setLoginTimeLimit(
                                          Math.min(Math.max(newValue, 1), 240)
                                        );
                                      } else {
                                        setLoginTimeLimit(0);
                                      }
                                    }}
                                  />
                                </div>
                              </ClickAwayListener>
                              {automaticLogoutDetailTail}
                            </>
                          ) : (
                            <>
                              {" "}
                              <ClickAwayListener
                                onClickAway={() => {
                                  if (loginTimeLimit < 10) {
                                    setLoginTimeLimit(10);
                                  }
                                }}
                              >
                                <div>
                                  <CCTextFieldNumber
                                    className={clsx({
                                      [classes.selector__number]: true
                                    })}
                                    value={loginTimeLimit}
                                    number={loginTimeLimit}
                                    decimalPoint={0}
                                    step={1}
                                    onChange={value => {
                                      console.log("onChange");
                                      if (!isPermission) {
                                        setOpenWarning(true);
                                        return;
                                      }
                                      if (value !== "") {
                                        const newValue = parseInt(
                                          !value ? 1 : value
                                        );
                                        setLoginTimeLimit(
                                          Math.min(Math.max(newValue, 1), 240)
                                        );
                                      } else {
                                        setLoginTimeLimit(0);
                                      }
                                    }}
                                    onBlur={() => {
                                      if (loginTimeLimit < 10) {
                                        setLoginTimeLimit(10);
                                      }
                                    }}
                                  />{" "}
                                </div>
                              </ClickAwayListener>
                              {intl.formatMessage({
                                id: "automaticLogoutDetail"
                              })}
                            </>
                          )}
                        </CCTypography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid className={classes.field__table} container>
                    <Grid className={classes.field__value} item></Grid>
                    <Grid className={classes.field__description} item>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      ></Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CCDialogContent>
            <CCDialogActions>
              <CVButton
                permission={"smtpGeneralSetting"}
                variant="text"
                color="sub"
                onClick={() => {
                  onCancel();
                }}
              >
                {intl.formatMessage(
                  { id: "cancel" },
                  {}
                  //   { capitalizeType: CAPITALIZE_TYPE.APA }
                )}
              </CVButton>
              <CVButton
                permission={"smtpGeneralSetting"}
                variant="contained"
                startIcon={<CheckIcon />}
                onClick={() => {
                  if (!isPermission) {
                    setOpenWarning(true);
                    return;
                  }
                  onSave();
                }}
              >
                {intl.formatMessage(
                  { id: "save" },
                  {}
                  //   { capitalizeType: CAPITALIZE_TYPE.APA }
                )}
              </CVButton>
            </CCDialogActions>
          </CCPaper>
        </CCDialogContent>
      </CCPaper>
      {openWarning && (
        <DialogPermissionWarn
          open={openWarning}
          onClose={() => {
            setOpenWarning(false);
          }}
        />
      )}
    </Box>
  );
};

export default AdvancedSetting;
