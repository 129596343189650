import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, makeStyles } from "@material-ui/core";
import {
  CCTableToolbar,
  CVButton,
  enqueueToastSave,
  DialogPermissionWarn,
  useWritePermission
} from "components";
import { useSnackbar } from "notistack";
import { GET_LAB_DETAILS, UPDATE_LAB_DETAIL } from "queries/setting";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCDialogActions,
  CCDialogContent,
  CCDialogTitle,
  CCDialogTitleAddMod as AddItemDialog,
  CCPaper,
  CCTypography
} from "styles/components";
import { CheckIcon } from "styles/icons";
import { v4 as uuid } from "uuid";
import { translate } from "components";
const useStyles = makeStyles(theme => ({
  root: { height: "100%", width: "624px" },
  table: {
    height: "100%",
    width: "100%"
  },
  table__root: {
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  table__body: {
    backgroundColor: theme.palette.background.default,
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  },
  cell: {
    textAlignLast: "left",
    overflow: "initial",
    padding: "8px",
    color: theme.palette.text.primary
  }
}));

const OrderDetailsSetting = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // value
  const settingPermission = "orderDetailsOtherSetting";
  const isPermission = useWritePermission(settingPermission);
  const [errorPermission, setErrorPermission] = useState(false);
  const [values, setValues] = useState([]);
  const [currentValue, setCurrentValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);

  // graphql
  const {
    data: dataLabDetails,
    loading: dataLabDetailsLoading,
    refetch: refetchLabDetails
  } = useQuery(GET_LAB_DETAILS, { fetchPolicy: "network-only" });
  const [updateLabDetailList] = useMutation(UPDATE_LAB_DETAIL);

  // function
  const onAddItem = content => {
    setValues(prev => [
      ...prev,
      {
        id: uuid(),
        content: content
      }
    ]);
  };
  const onDeleteItem = deleteIndex => {
    const newList = values.filter((_, index) => index !== deleteIndex);
    setCurrentValue("");
    setValues(newList);
  };
  const onEditItem = item => {
    const newList = values.map((value, index) => {
      if (value.content === currentValue) {
        return { id: uuid(), content: item };
      } else {
        return value;
      }
    });
    setCurrentValue("");
    setValues(newList);
  };
  const onSave = () => {
    updateLabDetailList({
      variables: {
        input: values
      }
    }).then(() => {
      refetchLabDetails();
      enqueueToastSave(intl, enqueueSnackbar);
      if (window.opener) {
        window.opener.postMessage({ source: "clboard", payload: "close" }, "*");
        window.close();
      }
    });
  };
  const onCancel = () => {
    if (dataLabDetails && dataLabDetails?.getLabDetailList) {
      setValues(dataLabDetails?.getLabDetailList);
    }
  };

  // useEffect
  useEffect(() => {
    if (dataLabDetails && dataLabDetails?.getLabDetailList) {
      setValues(dataLabDetails?.getLabDetailList);
    }
  }, [dataLabDetails]);

  // inReturn
  const itemHeader = [
    {
      label: () => (
        <CCTypography style={{ display: "flex", justifyContent: "center" }}>
          {translate(intl, "settings.orderDetail.orderDetailList")}
        </CCTypography>
      ),
      key: "content",
      className: classes.cell,
      component: ({ cellData }) => {
        return <CCTypography>{cellData}</CCTypography>;
      }
    }
  ];

  return (
    <Box className={classes.root}>
      <CCPaper type="normal" style={{ height: "100%", position: "relative" }}>
        <CCDialogTitle>
          {translate(intl, "settings.orderDetail.orderDetailTitle")}
        </CCDialogTitle>
        <CCDialogContent
          noPadding={true}
          style={{ height: `calc(100% - 56px - 68px)` }}
        >
          {!dataLabDetailsLoading ? (
            <CCTableToolbar
              permission={settingPermission}
              className={classes.table}
              classes={{
                root: classes.table__root,
                table__body: classes.table__body
              }}
              heads={itemHeader}
              contents={values}
              onFilter={rowData => {
                return rowData.content
                  .toUpperCase()
                  .includes(filter.toUpperCase());
              }}
              onSearch={value => {
                setFilter(value);
              }}
              onModClick={({ rowIndex }) => {
                if (!isPermission) {
                  setErrorPermission(true);
                  return;
                }
                setCurrentValue(
                  values.filter(each =>
                    each.content.toUpperCase().includes(filter.toUpperCase())
                  )[rowIndex].content
                );
                setOpenDialog(true);
              }}
              onDelClick={({ rowIndex }) => {
                if (!isPermission) {
                  setErrorPermission(true);
                  return;
                }
                onDeleteItem(rowIndex);
              }}
              onUpClick={() => {
                if (selectedIndex === -1) return;
                if (selectedIndex === 0) return;
                const newData = [...values];
                const [deleteItem] = newData.splice(selectedIndex, 1);
                newData.splice(selectedIndex - 1, 0, deleteItem);
                setValues(newData);
                setSelectedIndex(selectedIndex - 1);
              }}
              onDownClick={() => {
                if (selectedIndex === -1) return;
                if (selectedIndex === values.length - 1) return;
                const newData = [...values];
                const [deleteItem] = newData.splice(selectedIndex, 1);
                newData.splice(selectedIndex + 1, 0, deleteItem);
                setValues(newData);
                setSelectedIndex(selectedIndex + 1);
              }}
              onAddClick={() => {
                if (!isPermission) {
                  setErrorPermission(true);
                  return;
                }
                setCurrentValue("");
                setOpenDialog(true);
              }}
              onRowClick={({ rowIndex }) => {
                setSelectedIndex(rowIndex);
              }}
              selectedRow={selectedIndex}
            />
          ) : null}
        </CCDialogContent>
        <CCDialogActions>
          <CVButton
            variant="text"
            color="sub"
            onClick={() => {
              onCancel();
            }}
          >
            {translate(intl, "common.ui.cancel")}
          </CVButton>
          <CVButton
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={() => {
              if (!isPermission) {
                setErrorPermission(true);
                return;
              }
              onSave();
            }}
          >
            {translate(intl, "common.ui.save")}
          </CVButton>
        </CCDialogActions>
      </CCPaper>
      <AddItemDialog
        title={intl.formatMessage({
          id: "settings.orderDetail.orderDetailItem"
        })}
        open={openDialog}
        value={currentValue ? currentValue : ""}
        onSave={value => {
          if (!currentValue) {
            onAddItem(value);
          } else {
            onEditItem(value);
          }
          setOpenDialog(false);
        }}
        onClose={() => setOpenDialog(false)}
        maxLength={60}
      />
      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
    </Box>
  );
};

export default OrderDetailsSetting;
