import React from "react";

const VisibleeditIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M22 14c.55 0 1 .45 1 1v7c0 .55-.45 1-1 1h-7c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1zm-2.25 4L18 20.255l-1.25-1.505L15 21h7l-2.25-3zM12 4c5 0 9.27 3.194 11 7.703-.128.334-.27.66-.426.98-.13-.093-.283-.154-.448-.175L22 12.5h-5.06c.04-.26.06-.526.06-.797 0-2.835-2.24-5.135-5-5.135s-5 2.3-5 5.135c0 2.834 2.24 5.135 5 5.135.169 0 .336-.009.5-.026v2.583c-.165.007-.332.01-.5.01-5 0-9.27-3.194-11-7.702C2.73 7.194 7 4 12 4zm0 4.622c1.66 0 3 1.376 3 3.08 0 .277-.035.544-.1.798h-.4c-1.054 0-1.918.816-1.995 1.85l-.005.15v.241c-.162.028-.33.043-.5.043-1.66 0-3-1.376-3-3.081s1.34-3.081 3-3.081z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default VisibleeditIcon;
