import { makeStyles } from "@material-ui/core";
import { Views } from "react-big-calendar";

const TimeSlotHeight = 140;
const useStyles = makeStyles(
  theme => ({
    paper: {
      height: "100%",
      border: `1px solid ${theme.palette.border.main}`,
      position: "relative"
    },
    container: {
      height: "100%"
    },
    header: {
      borderBottom: `1px solid ${theme.palette.border.main}`,
      padding: "13px 16px",
      width: "100%"
    },
    calendar: {
      // height: "calc(100% - 52px)"
      flex: 1,
      overflow: "auto"
    },
    height: {
      height: "calc(100vh - 145px)"
    },
    relativePosition: {
      position: "relative"
    },
    notWorkTime: {
      backgroundColor: "#ababab !important"
    },
    weekDay: {
      "&>a>span": {
        color: "#000 !important"
      }
    },
    saturday: {
      "&>a>span": {
        color: "#0277bd !important"
      }
    },
    sunday: {
      "&>a>span": {
        color: "#f50057 !important"
      }
    },
    root: props => ({
      padding: 12,
      boxSizing: "border-box",
      position: "relative",
      height:
        props.currentView !== Views.MONTH ? "-webkit-fill-available" : "100%",
      "& .rbc-calendar": {
        height: "100%"
      },

      "& .rbc-event": {
        display: "flex",
        alignItems: "center",
        border: 0,
        borderRadius: "0",
        outline: "none",
        cursor: "default",
        minHeight: 36,
        backgroundColor: "transparent",
        padding: 0,

        "&.rbc-event-allday": {
          "& .rbc-addons-dnd-resize-ew-anchor": {
            display: "none"
          }
        }
      },
      "& .rbc-addons-dnd-resize-ew-anchor": {
        zIndex: 2
      },
      "& .rbc-addons-dnd-resize-ns-anchor": {
        zIndex: 2
      },

      //Time View(Week, Day)
      "& .rbc-time-view": {
        border: 0,
        "& .rbc-time-header": {
          boxShadow: "0 2px 1px 0 rgba(0, 0, 0, 0.24)",
          "& .rbc-time-header-gutter": {
            display: "none"
          },
          "& .rbc-time-header-content": {
            border: 0,
            "& .rbc-time-header-cell": {
              minHeight: 34,

              "& .rbc-header": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTop: `1px solid ${theme.palette.border.main}`,
                borderLeft: `1px solid ${theme.palette.border.main}`,
                borderBottom: `1px solid ${theme.palette.border.main}`,
                minHeight: 34,
                backgroundColor: theme.palette.common.white,

                "&:first-child": {
                  borderLeft: 0
                },
                "& a": {
                  "& span": {
                    ...theme.typography.h5
                  }
                }
              },

              "& .rbc-today": {
                borderTop: "2px solid #40c4ff",
                borderRight: "2px solid #40c4ff",
                borderLeft: "2px solid #40c4ff"
              }
            },
            "& .rbc-allday-cell": {
              display: "none"
            }
          }
        },
        "& .rbc-time-content": {
          border: 0,
          "& .rbc-time-gutter": {
            display: "none"
          }
        },
        "& .rbc-time-column": {
          borderLeft: `1px solid ${theme.palette.border.main}`,
          backgroundColor: "#eceff1",

          "&:nth-child(2)": {
            borderLeft: 0
          },
          "&.rbc-today": {
            borderBottom: "2px solid #40c4ff",
            borderRight: "2px solid #40c4ff",
            borderLeft: "2px solid #40c4ff"
          },
          "&.rbc-day-slot": {
            "& .rbc-timeslot-group": {
              minHeight: TimeSlotHeight,
              border: 0,

              "& .rbc-time-slot": {
                border: "none"
              },
              "& .rbc-event-label": {
                display: "none"
              }
            },
            "& .rbc-events-container": {
              "& .rbc-event": {
                top: "0 !important",
                height: "100% !important",
                overflowY: "auto"
              }
            },
            "& .rbc-slot-selection": {
              display: "none",
              cursor: "default"
            }
          }
        },
        "& .rbc-events-container": {
          marginRight: 0,
          border: 0,
          "& .rbc-event": {
            "& .rbc-event-content": {
              backgroundColor: "transparent",
              minWidth: 0,
              height: "100%",
              flexBasis: "100%",
              position: "relative"
            },
            "& .rbc-addons-dnd-resize-ew-anchor": {
              zIndex: 2
            },
            "& .rbc-addons-dnd-resize-ns-anchor": {
              zIndex: 2
            },
            "& .rbc-event-label": {
              display: "none"
            }
          }
        },
        "& .rbc-current-time-indicator": {
          backgroundColor: "unset"
        }
      },
      //Month View
      "& .rbc-month-view": {
        height: "calc(100% - 80px)",
        overflowY: "auto",
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
        "& .rbc-row.rbc-month-header": {
          "& > .rbc-header": {
            minHeight: 34,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            "&:first-child": {
              color: "#f50057"
            },
            "&:last-child": {
              color: "#0277bd"
            }
          }
        },
        "& .rbc-month-row": {
          flexBasis: "125px",
          "& .rbc-row-bg": {
            backgroundColor: "transparent",
            "& .rbc-day-bg": {
              backgroundColor: "#eceff1",
              "&.rbc-off-range-bg": {
                backgroundColor: "rgba(0,0,0,0.16)"
              }
            },

            "& .rbc-today": {
              border: "2px solid #40c4ff"
            }
          },
          "& .rbc-row-content": {
            "& .rbc-row": {
              "& .rbc-event-content": {
                width: "100%"
              },
              "& .rbc-date-cell": {
                ...theme.typography.h5,
                color: theme.palette.input.hover,
                lineHeight: 1,
                boxSizing: "borderBox",
                padding: "9px 9px 0px 0",
                "&.rbc-now": {
                  color: theme.palette.secondary.main
                },
                "&.rbc-off-range": {
                  ...theme.typography.h5,
                  color: theme.palette.input.main
                }
              }
            },
            "& .rbc-addons-dnd-row-body": {
              "& .rbc-row": {
                "& .rbc-row-segment": {
                  "& .rbc-show-more": {
                    backgroundColor: "transparent",
                    textDecoration: "none",
                    color: theme.palette.secondary.main
                  },
                  "& .rbc-event": {
                    "& .rbc-addons-dnd-resizable": {
                      "& .rbc-event-content": {
                        position: "static",
                        display: "flex",
                        width: "100%"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    })
  }),
  { name: "Reservation-Schedule" }
);

export { useStyles };
