import React from "react";

const MoveIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M6 14v3l-5-5 5-5v3h4V6H7l5-5 5 5h-3v4h4V7l5 5-5 5v-3H6zm8 0h-4v4H7l5 5 5-5h-3v-4z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default MoveIcon;
