import React from "react";

const FemaleIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M14.86 11c.45 0 .84.247.938.596l2.69 9.503c.068.238-.161.465-.47.465H15L14.518 23H9.482l-.481-1.436h-3.02c-.302 0-.53-.219-.472-.454l2.327-9.493c.088-.358.484-.617.944-.617zM11.8 2c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default FemaleIcon;
