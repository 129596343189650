import { Minimal as MiniLayout } from "layouts";
import { Timeout } from "./Error";

const pages = [
  {
    path: "/timeout",
    component: Timeout,
    layout: MiniLayout
  }
].map(el => {
  el.mode = "common";
  return el;
});
export { pages };
