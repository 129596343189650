import { makeStyles, MenuList, Popover } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "../../../locales/language";
import { CCSelectItem } from "./Component";

const useStyle = makeStyles(
  () => ({
    root: {
      "& .MuiPaper-elevation8": {
        boxShadow:
          "0 19px 38px 0 rgba(0, 0, 0, 0.3), 0 15px 12px 0 rgba(0, 0, 0, 0.22)"
      },
      "& .MuiPopover-paper": {
        minWidth: "146px"
      },
      "& .MuiPaper-rounded": {
        borderRadius: "8px"
      }
    },

    menu: {
      "&.MuiList-padding": {
        padding: "0"
      }
    }
  }),
  { name: "CCSelect" }
);

const CCSelect = props => {
  const {
    onClose,
    open,
    menuItem,
    onChange,
    value,
    MenuListProps,
    ...others
  } = props;

  const classes = useStyle();
  const intl = useIntl();

  const _onChange = event => {
    let _value = [...value];
    let exist = _value.indexOf(event.value);
    if (exist > -1) {
      _value.splice(exist, 1);
    } else {
      _value.push(event.value);
    }
    if (onChange) {
      onChange(_value);
    }
  };

  return (
    <Popover
      {...others}
      className={clsx(classes.root)}
      open={open}
      onClose={onClose}
    >
      <MenuList
        className={clsx(classes.menu)}
        variant={"selectedMenu"}
        {...MenuListProps}
      >
        {menuItem &&
          menuItem.map((item, index) => (
            <CCSelectItem
              key={clsx(classes.root + item.value + index)}
              item={item}
              selected={value.indexOf(item.value) > -1}
              disabled={item.disabled}
              onClick={_onChange}
            />
          ))}
        {(!menuItem || menuItem?.length === 0) && (
          <CCSelectItem
            key={clsx(classes.root)}
            item={{
              value: "",
              label: intl.formatMessage({ id: "ccHasNoItem" }),
              disabled: true
            }}
          />
        )}
      </MenuList>
    </Popover>
  );
};

CCSelect.propTypes = {
  /** Select Close 시 실행되는 함수 */
  onClose: PropTypes.func.isRequired,
  /** Select Open 유무 */
  open: PropTypes.bool.isRequired,
  /** Select시 실행되는 함수 */
  onChange: PropTypes.func.isRequired,
  /** Select된 item */
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** 추가적인 menulist props */
  MenuListProps: PropTypes.object,
  /** Select 클릭 시 보여지는 items */
  menuItem: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      color: PropTypes.string
    })
  )
};

export default CCSelect;
