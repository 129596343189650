import React from "react";

const PaidIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M18.5 14c2.484 0 4.5 2.016 4.5 4.5S20.984 23 18.5 23 14 20.984 14 18.5s2.016-4.5 4.5-4.5zM12 2c5.52 0 10 4.48 10 10 0 .525-.04 1.04-.119 1.544-.963-.66-2.127-1.044-3.381-1.044-.896 0-1.745.196-2.509.548-.766-1.136-2.237-1.667-3.641-2.028-2.02-.52-2.67-1.07-2.67-1.91 0-.96.9-1.64 2.4-1.64 1.511 0 2.117.695 2.21 1.727l.01.143h1.96c-.058-1.469-.917-2.827-2.631-3.331l-.219-.059V4h-2.67v1.93c-1.72.37-3.11 1.49-3.11 3.21 0 2.05 1.7 3.07 4.18 3.67 1.543.367 2.229.853 2.505 1.393-.65.63-1.156 1.407-1.467 2.277-.224.039-.48.06-.768.06-1.754 0-2.488-.753-2.634-1.74l-.016-.13H7.47c.106 1.865 1.45 2.954 3.074 3.355l.196.045V20h1.95c.175.682.466 1.316.852 1.88-.501.08-1.017.12-1.542.12-5.52 0-10-4.48-10-10S6.48 2 12 2zm9.015 14.061L17.6 19.477l-1.615-1.611-.635.634 2.25 2.25 4.05-4.05-.634-.639z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default PaidIcon;
