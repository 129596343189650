import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import {
  AbbreviationIcon,
  AccessibleIcon,
  AddClaimIcon,
  AddIcon,
  AgreementIcon,
  AlignBottomIcon,
  AlignTopIcon,
  AnnounceIcon,
  AssessmentIcon,
  BackendIcon,
  BackspaceIcon,
  BadIcon,
  BarcodeIcon,
  BarcodenIcon,
  BrightnessIcon,
  BringInIcon,
  BringupIcon,
  ButtonIcon,
  CalculationIcon,
  CalculationIcon18,
  CalendarselIcon,
  CallIcon,
  CameraIcon,
  CategoryIcon,
  CCIcon,
  ChartIcon,
  ChartitemIcon,
  ChartnIcon,
  CheckallIcon,
  CheckDateIcon,
  CheckedIcon,
  CheckIcon,
  CircleIcon,
  CircularIcon,
  CircularIcon18,
  ClaimexcludedIcon,
  ClaimIcon,
  ClaimincludedIcon,
  ClaimnIcon,
  ClipIcon,
  CloudIcon,
  ColorinvertIcon,
  ColorpickerIcon,
  CommitmentIcon,
  CommitnIcon,
  ComplaintIcon,
  ContrastIcon,
  CorporationIcon,
  CrmIcon,
  CrmnIcon,
  CropIcon,
  CTIcon,
  CustomermanageIcon,
  DategoIcon,
  DateIcon,
  DaygoIcon,
  DeleteIcon,
  DentalmirrorIcon,
  DenturesIcon,
  DeskIcon,
  DesknIcon,
  DigitalSignIcon,
  DigitalSignnIcon,
  DiscountIcon,
  DiscountIcon18,
  DiseasesIcon,
  DocumentIcon,
  DollaregyptIcon,
  DollarkorIcon,
  DollarruIcon,
  DollarvietIcon,
  DotIcon,
  DownIcon,
  DownloadIcon,
  DownloadnIcon,
  DrawingIcon,
  EnterIcon,
  EraserIcon,
  ExcelIcon,
  ExplorerIcon,
  EzdentiIcon,
  FamilyIcon,
  FemaleIcon,
  FilterIcon,
  FlagIcon,
  FlashIcon,
  FoldallIcon,
  FolderIcon,
  FoldIcon,
  FontsizeIcon,
  FullviewIcon,
  GammaIcon,
  GoldIcon,
  GooglelogoIcon,
  GotoendIcon,
  GotofirstIcon,
  HappyIcon,
  HeightIcon,
  HelpIcon,
  HolidayIcon,
  HomeIcon,
  HrIcon,
  HueIcon,
  ImplantIcon,
  InfoIcon,
  InvisibleeditIcon,
  InvisibleIcon,
  JxIcon,
  KeyboardIcon,
  KeyIcon,
  LeftIcon,
  LockIcon,
  MainIcon,
  MaleIcon,
  MedicineIcon,
  MedicinepayIcon,
  MembershipIcon,
  MenucloseIcon,
  MenunextIcon,
  MenuopenIcon,
  MessageAddIcon,
  MessageaddIcon18,
  MessageIcon,
  MessageIcon18,
  MicIcon,
  MinusIcon,
  ModifyIcon,
  MoonborderIcon,
  MoonIcon,
  MoonstandIcon,
  MorebIcon,
  MoreIcon,
  MovedownIcon,
  MoveIcon,
  MoveupIcon,
  NewIcon,
  NonannounceIcon,
  NotificationsIcon,
  NotificationsoffIcon,
  NumbereightIcon,
  NumberelevenIcon,
  NumberfiveIcon,
  NumberfourIcon,
  NumbernineIcon,
  NumberoneIcon,
  NumbersevenIcon,
  NumbersixIcon,
  NumbertenIcon,
  NumberthreeIcon,
  NumbertwelveIcon,
  NumbertwoIcon,
  OfficialIcon,
  OpenfolderIcon,
  OpeninnewIcon,
  OralexamIcon,
  OralexamnIcon,
  OverviewIcon,
  PaidIcon,
  PathIcon,
  PauseIcon,
  PeriodIcon,
  PersonIcon,
  PhotoIcon,
  PhotoIconBigBackIcon,
  PhotoIconBigFolderIcon,
  PhotoIconBigVideoIcon,
  PhotonIcon,
  PhotosizeIcon,
  PictureIcon,
  PlusIcon,
  PregnantIcon,
  PrintIcon,
  ProgressIcon,
  ProgressIcon18,
  QuotesIcon,
  RadiationIcon,
  RecordsIcon,
  RedoIcon,
  ReducesizeIcon,
  ReflectrightIcon,
  ReflectupIcon,
  RefreshIcon,
  RemoveIcon,
  ReservationIcon,
  ReservationnIcon,
  ResetIcon,
  ResignIcon,
  ResizeIcon,
  RestIcon,
  RightIcon,
  RotateIcon,
  RotateleftIcon,
  RotaterightIcon,
  SaturationIcon,
  SaveIcon,
  ScannerIcon,
  SchoolIcon,
  ScreensaverIcon,
  ScreenshotIcon,
  SearchIcon,
  SelectPersonIcon,
  SentIcon,
  SettingIcon,
  SettingnIcon,
  SettlementIcon,
  ShoppingIcon,
  SignatureIcon,
  SignoutIcon,
  SmalldownIcon,
  SmallupIcon,
  SMSIcon,
  SpecialcalculationIcon,
  SpreadoverIcon,
  SquarefilledIcon,
  SquareIcon,
  StarIcon,
  StatisticsIcon,
  StatisticsnIcon,
  StockIcon,
  StraightenIcon,
  SubtractIcon,
  TabkeyIcon,
  TackIcon,
  TagIcon,
  TakedownIcon,
  TakeinIcon,
  TextIcon,
  TimeIcon,
  TodayIcon,
  UndoIcon,
  UnlockIcon,
  UpdownsIcon,
  UpIcon,
  VideoIcon,
  VisibleeditIcon,
  VisibleIcon,
  WarningIcon,
  WidthIcon,
  XrayIcon,
  ZaloIcon,
  ZalonIcon,
  ZoominIcon,
  ZoomoutIcon
} from "../../themes/common/icons";

const useStyles = makeStyles(() => ({
  root: {},
  textfield__wrap: {
    marginLeft: "16px"
  },
  textfield: {
    "& .MuiOutlinedInput-inputAdornedStart": {
      marginLeft: 10
    }
  },
  result__text: {
    marginTop: "10px",
    fontSize: "16px"
  },
  copy__text: {
    fontSize: "12px",
    opacity: 0.7
  },
  grid__wrap: { flexDirection: "column" },
  icon__grid__wrap: {
    position: "relative",
    width: "68px",
    height: "72px",
    display: "inline-flex",
    flexDirection: "column",
    margin: "10px"
  },
  component: {
    width: "68px",
    textAlign: "center",
    "& svg": {
      width: "25px",
      height: "25px",
      cursor: "pointer",
      margin: "4px 0px",
      padding: "10px 10px 3px 10px"
    }
  },
  typography: {
    width: "68px",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "& .MuiTypography-body1": {
      fontSize: "12px"
    }
  },
  clicked: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  }
}));

const Icons = [
  { component: <AbbreviationIcon />, text: "AbbreviationIcon" },
  { component: <AccessibleIcon />, text: "AccessibleIcon" },
  { component: <AddIcon />, text: "AddIcon" },
  { component: <AgreementIcon />, text: "AgreementIcon" },
  { component: <AlignBottomIcon />, text: "AlignBottomIcon" },
  { component: <AlignTopIcon />, text: "AlignTopIcon" },
  { component: <BackspaceIcon />, text: "BackspaceIcon" },
  { component: <BadIcon />, text: "BadIcon" },
  { component: <BrightnessIcon />, text: "BrightnessIcon" },
  { component: <BringInIcon />, text: "BringInIcon" },
  { component: <BringupIcon />, text: "BringupIcon" },
  { component: <ButtonIcon />, text: "ButtonIcon" },
  { component: <CalculationIcon />, text: "CalculationIcon" },
  { component: <CallIcon />, text: "CallIcon" },
  { component: <CalendarselIcon />, text: "CalendarselIcon" },
  { component: <CameraIcon />, text: "CameraIcon" },
  { component: <CategoryIcon />, text: "CategoryIcon" },
  { component: <CCIcon />, text: "CCIcon" },
  { component: <ChartIcon />, text: "ChartIcon" },
  { component: <ChartitemIcon />, text: "ChartitemIcon" },
  { component: <CheckallIcon />, text: "CheckallIcon" },
  { component: <CheckDateIcon />, text: "CheckDateIcon" },
  { component: <CheckIcon />, text: "CheckIcon" },
  { component: <CircleIcon />, text: "CircleIcon" },
  { component: <ClaimexcludedIcon />, text: "ClaimexcludedIcon" },
  { component: <ClaimIcon />, text: "ClaimIcon" },
  { component: <ClaimincludedIcon />, text: "ClaimincludedIcon" },
  { component: <ClipIcon />, text: "ClipIcon" },
  { component: <ColorinvertIcon />, text: "ColorinvertIcon" },
  { component: <ColorpickerIcon />, text: "ColorpickerIcon" },
  { component: <CommitmentIcon />, text: "CommitmentIcon" },
  { component: <ComplaintIcon />, text: "ComplaintIcon" },
  { component: <ContrastIcon />, text: "ContrastIcon" },
  { component: <CorporationIcon />, text: "CorporationIcon" },
  { component: <CropIcon />, text: "CropIcon" },
  { component: <CustomermanageIcon />, text: "CustomermanageIcon" },
  { component: <DateIcon />, text: "DateIcon" },
  { component: <DeleteIcon />, text: "DeleteIcon" },
  { component: <DentalmirrorIcon />, text: "DentalmirrorIcon" },
  { component: <DenturesIcon />, text: "DenturesIcon" },
  { component: <DeskIcon />, text: "DeskIcon" },
  { component: <DiscountIcon />, text: "DiscountIcon" },
  { component: <DiseasesIcon />, text: "DiseasesIcon" },
  { component: <DocumentIcon />, text: "DocumentIcon" },
  { component: <DotIcon />, text: "DotIcon" },
  { component: <DownIcon />, text: "DownIcon" },
  { component: <DownloadIcon />, text: "DownloadIcon" },
  { component: <DrawingIcon />, text: "DrawingIcon" },
  { component: <EraserIcon />, text: "EraserIcon" },
  { component: <UpdownsIcon />, text: "UpdownsIcon" },
  { component: <DaygoIcon />, text: "DaygoIcon" },
  { component: <EzdentiIcon />, text: "EzdentiIcon" },
  { component: <FamilyIcon />, text: "FamilyIcon" },
  { component: <FoldallIcon />, text: "FoldallIcon" },
  { component: <FolderIcon />, text: "FolderIcon" },
  { component: <FoldIcon />, text: "FoldIcon" },
  { component: <FullviewIcon />, text: "FullviewIcon" },
  { component: <GammaIcon />, text: "GammaIcon" },
  { component: <HappyIcon />, text: "HappyIcon" },
  { component: <HelpIcon />, text: "HelpIcon" },
  { component: <HolidayIcon />, text: "HolidayIcon" },
  { component: <HueIcon />, text: "HueIcon" },
  { component: <ImplantIcon />, text: "ImplantIcon" },
  { component: <InvisibleeditIcon />, text: "InvisibleeditIcon" },
  { component: <InvisibleIcon />, text: "InvisibleIcon" },
  { component: <JxIcon />, text: "JxIcon" },
  { component: <KeyboardIcon />, text: "KeyboardIcon" },
  { component: <KeyIcon />, text: "KeyIcon" },
  { component: <LeftIcon />, text: "LeftIcon" },
  { component: <LockIcon />, text: "LockIcon" },
  { component: <MainIcon />, text: "MainIcon" },
  { component: <MedicineIcon />, text: "MedicineIcon" },
  { component: <MembershipIcon />, text: "MembershipIcon" },
  { component: <MenucloseIcon />, text: "MenucloseIcon" },
  { component: <MenunextIcon />, text: "MenunextIcon" },
  { component: <MenuopenIcon />, text: "MenuopenIcon" },
  { component: <MessageAddIcon />, text: "MessageAddIcon" },
  { component: <MessageIcon />, text: "MessageIcon" },
  { component: <ModifyIcon />, text: "ModifyIcon" },
  { component: <MoonborderIcon />, text: "MoonborderIcon" },
  { component: <MoonIcon />, text: "MoonIcon" },
  { component: <MoreIcon />, text: "MoreIcon" },
  { component: <MovedownIcon />, text: "MovedownIcon" },
  { component: <MoveIcon />, text: "MoveIcon" },
  { component: <MoveupIcon />, text: "MoveupIcon" },
  { component: <NotificationsIcon />, text: "NotificationsIcon" },
  { component: <NumberoneIcon />, text: "NumberoneIcon" },
  { component: <NumbertwoIcon />, text: "NumbertwoIcon" },
  { component: <NumberthreeIcon />, text: "NumberthreeIcon" },
  { component: <NumberfourIcon />, text: "NumberfourIcon" },
  { component: <NumberfiveIcon />, text: "NumberfiveIcon" },
  { component: <NumbersixIcon />, text: "NumbersixIcon" },
  { component: <NumbersevenIcon />, text: "NumbersevenIcon" },
  { component: <NumbereightIcon />, text: "NumbereightIcon" },
  { component: <NumbernineIcon />, text: "NumbernineIcon" },
  { component: <NumbertenIcon />, text: "NumbertenIcon" },
  { component: <NumberelevenIcon />, text: "NumberelevenIcon" },
  { component: <NumbertwelveIcon />, text: "NumbertwelveIcon" },
  { component: <OfficialIcon />, text: "OfficialIcon" },
  { component: <OpenfolderIcon />, text: "OpenfolderIcon" },
  { component: <OpeninnewIcon />, text: "OpeninnewIcon" },
  { component: <OralexamIcon />, text: "OralexamIcon" },
  { component: <OverviewIcon />, text: "OverviewIcon" },
  { component: <PathIcon />, text: "PathIcon" },
  { component: <MicIcon />, text: "MicIcon" },
  { component: <PersonIcon />, text: "PersonIcon" },
  { component: <PhotoIcon />, text: "PhotoIcon" },
  { component: <PictureIcon />, text: "PictureIcon" },
  { component: <PregnantIcon />, text: "PregnantIcon" },
  { component: <PrintIcon />, text: "PrintIcon" },
  { component: <ProgressIcon />, text: "ProgressIcon" },
  { component: <QuotesIcon />, text: "QuotesIcon" },
  { component: <RadiationIcon />, text: "RadiationIcon" },
  { component: <RecordsIcon />, text: "RecordsIcon" },
  { component: <RedoIcon />, text: "RedoIcon" },
  { component: <ReflectrightIcon />, text: "ReflectrightIcon" },
  { component: <ReflectupIcon />, text: "ReflectupIcon" },
  { component: <RefreshIcon />, text: "RefreshIcon" },
  { component: <RemoveIcon />, text: "RemoveIcon" },
  { component: <ReservationIcon />, text: "ReservationIcon" },
  { component: <ResetIcon />, text: "ResetIcon" },
  { component: <ResignIcon />, text: "ResignIcon" },
  { component: <RightIcon />, text: "RightIcon" },
  { component: <RotateIcon />, text: "RotateIcon" },
  { component: <DesknIcon />, text: "DesknIcon" },
  { component: <SaturationIcon />, text: "SaturationIcon" },
  { component: <SaveIcon />, text: "SaveIcon" },
  { component: <ScannerIcon />, text: "ScannerIcon" },
  { component: <ScreenshotIcon />, text: "ScreenshotIcon" },
  { component: <SearchIcon />, text: "SearchIcon" },
  { component: <SelectPersonIcon />, text: "SelectPersonIcon" },
  { component: <SettingIcon />, text: "SettingIcon" },
  { component: <SignatureIcon />, text: "SignatureIcon" },
  { component: <SignoutIcon />, text: "SignoutIcon" },
  { component: <SmalldownIcon />, text: "SmalldownIcon" },
  { component: <SmallupIcon />, text: "SmallupIcon" },
  { component: <SMSIcon />, text: "SMSIcon" },
  { component: <SpreadoverIcon />, text: "SpreadoverIcon" },
  { component: <SquarefilledIcon />, text: "SquarefilledIcon" },
  { component: <SquareIcon />, text: "SquareIcon" },
  { component: <StatisticsIcon />, text: "StatisticsIcon" },
  { component: <StraightenIcon />, text: "StraightenIcon" },
  { component: <SubtractIcon />, text: "SubtractIcon" },
  { component: <TabkeyIcon />, text: "TabkeyIcon" },
  { component: <TackIcon />, text: "TackIcon" },
  { component: <TagIcon />, text: "TagIcon" },
  { component: <TakeinIcon />, text: "TakeinIcon" },
  { component: <TextIcon />, text: "TextIcon" },
  { component: <TimeIcon />, text: "TimeIcon" },
  { component: <TodayIcon />, text: "TodayIcon" },
  { component: <UndoIcon />, text: "UndoIcon" },
  { component: <UnlockIcon />, text: "UnlockIcon" },
  { component: <UpIcon />, text: "UpIcon" },
  { component: <VisibleeditIcon />, text: "VisibleeditIcon" },
  { component: <VisibleIcon />, text: "VisibleIcon" },
  { component: <WarningIcon />, text: "WarningIcon" },
  { component: <XrayIcon />, text: "XrayIcon" },
  { component: <ZoominIcon />, text: "ZoominIcon" },
  { component: <ZoomoutIcon />, text: "ZoomoutIcon" },
  { component: <AddClaimIcon />, text: "AddClaimIcon" },
  { component: <DategoIcon />, text: "DategoIcon" },
  { component: <DigitalSignIcon />, text: "DigitalSignIcon" },
  { component: <ExcelIcon />, text: "ExcelIcon" },
  { component: <InfoIcon />, text: "InfoIcon" },
  { component: <PaidIcon />, text: "PaidIcon" },
  { component: <ResizeIcon />, text: "ResizeIcon" },
  { component: <ScreensaverIcon />, text: "ScreensaverIcon" },
  { component: <SettlementIcon />, text: "SettlementIcon" },
  { component: <VideoIcon />, text: "VideoIcon" },
  { component: <HeightIcon />, text: "HeightIcon" },
  { component: <PhotoIconBigBackIcon />, text: "PhotoIconBigBackIcon" },
  { component: <PhotoIconBigVideoIcon />, text: "PhotoIconBigVideoIcon" },
  { component: <PhotoIconBigFolderIcon />, text: "PhotoIconBigFolderIcon" },
  { component: <WidthIcon />, text: "WidthIcon" },
  { component: <TakedownIcon />, text: "TakedownIcon" },
  { component: <CalculationIcon18 />, text: "CalculationIcon18" },
  { component: <CircularIcon />, text: "CircularIcon" },
  { component: <CTIcon />, text: "CTIcon" },
  { component: <FlagIcon />, text: "FlagIcon" },
  { component: <HomeIcon />, text: "HomeIcon" },
  { component: <MessageaddIcon18 />, text: "MessageaddIcon18" },
  { component: <MessageIcon18 />, text: "MessageIcon18" },
  { component: <NewIcon />, text: "NewIcon" },
  { component: <ProgressIcon18 />, text: "ProgressIcon18" },
  { component: <SchoolIcon />, text: "SchoolIcon" },
  { component: <ZaloIcon />, text: "ZaloIcon" },
  { component: <CircularIcon18 />, text: "CircularIcon18" },
  { component: <CloudIcon />, text: "CloudIcon" },
  { component: <DiscountIcon18 />, text: "DiscountIcon18" },
  { component: <NotificationsoffIcon />, text: "NotificationsoffIcon" },
  { component: <MoonstandIcon />, text: "MoonstandIcon" },
  { component: <FlashIcon />, text: "FlashIcon" },
  { component: <PauseIcon />, text: "PauseIcon" },
  { component: <FontsizeIcon />, text: "FontsizeIcon" },
  { component: <PhotosizeIcon />, text: "PhotosizeIcon" },
  { component: <GoldIcon />, text: "GoldIcon" },
  { component: <PeriodIcon />, text: "PeriodIcon" },
  { component: <SpecialcalculationIcon />, text: "SpecialcalculationIcon" },
  { component: <SentIcon />, text: "SentIcon" },
  { component: <MaleIcon />, text: "MaleIcon" },
  { component: <FemaleIcon />, text: "FemaleIcon" },
  { component: <CheckedIcon />, text: "CheckedIcon" },
  { component: <MedicinepayIcon />, text: "MedicinepayIcon" },
  { component: <MorebIcon />, text: "MorebIcon" },

  { component: <AssessmentIcon />, text: "AssessmentIcon" },
  { component: <BarcodeIcon />, text: "BarcodeIcon" },
  { component: <StockIcon />, text: "StockIcon" },
  { component: <BackendIcon />, text: "BackendIcon" },
  { component: <StarIcon />, text: "StarIcon" },
  { component: <ShoppingIcon />, text: "ShoppingIcon" },
  { component: <ReducesizeIcon />, text: "ReducesizeIcon" },

  { component: <CrmIcon />, text: "CrmIcon" },
  { component: <EnterIcon />, text: "EnterIcon" },
  { component: <ExplorerIcon />, text: "ExplorerIcon" },
  { component: <RotaterightIcon />, text: "RotaterightIcon" },
  { component: <RotateleftIcon />, text: "RotateleftIcon" },
  { component: <FilterIcon />, text: "FilterIcon" },

  { component: <CommitnIcon />, text: "CommitnIcon" },
  { component: <ReservationnIcon />, text: "ReservationnIcon" },
  { component: <ChartnIcon />, text: "ChartnIcon" },
  { component: <PhotonIcon />, text: "PhotonIcon" },
  { component: <ZalonIcon />, text: "ZalonIcon" },
  { component: <DigitalSignnIcon />, text: "DigitalSignnIcon" },
  { component: <CrmnIcon />, text: "CrmnIcon" },
  { component: <StatisticsnIcon />, text: "StatisticsnIcon" },
  { component: <SettingnIcon />, text: "SettingnIcon" },
  { component: <DownloadnIcon />, text: "DownloadnIcon" },
  { component: <OralexamnIcon />, text: "OralexamnIcon" },
  { component: <ClaimnIcon />, text: "ClaimnIcon" },
  { component: <BarcodenIcon />, text: "BarcodenIcon" },

  { component: <AnnounceIcon />, text: "AnnounceIcon" },
  { component: <DollaregyptIcon />, text: "DollaregyptIcon" },
  { component: <PlusIcon />, text: "PlusIcon" },
  { component: <NonannounceIcon />, text: "NonannounceIcon" },
  { component: <MinusIcon />, text: "MinusIcon" },
  { component: <GotoendIcon />, text: "GotoendIcon" },
  { component: <GotofirstIcon />, text: "GotofirstIcon" },
  { component: <DollarvietIcon />, text: "DollarvietIcon" },
  { component: <DollarruIcon />, text: "DollarruIcon" },
  { component: <DollarkorIcon />, text: "DollarkorIcon" },
  { component: <RestIcon />, text: "RestIcon" },
  { component: <GooglelogoIcon />, text: "GooglelogoIcon" },
  { component: <HrIcon />, text: "HrIcon" }
];

const CCIcons = () => {
  const classes = useStyles();

  const [term, setTerm] = useState("");
  const [icons, setIcons] = useState([...Icons]);

  const handleTerm = e => {
    setTerm(e.target.value);
  };

  const copy = e => {
    const Name = e.target.getAttribute("name");
    const tempElem = document.createElement("textarea");
    tempElem.value = `import { ${Name} } from "theme/icons"`;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
  };

  useEffect(() => {
    const tIcons = Icons.filter(d =>
      d.text.toUpperCase().includes(term.toUpperCase())
    );

    setIcons(tIcons);
  }, [term]);

  return (
    <Box className={classes.root}>
      <Grid container className={classes.grid__wrap}>
        <Grid item className={classes.textfield__wrap}>
          <TextField
            InputProps={{
              startAdornment: <SearchIcon />
            }}
            margin={"dense"}
            variant={"outlined"}
            placeholder={"Search"}
            className={classes.textfield}
            value={term}
            onChange={handleTerm}
          />
          <Typography className={classes.result__text}>
            {icons.length} matching results
          </Typography>
          <Typography className={classes.copy__text}>
            * Icon 클릭 시 클립보드로 복사됨 (ex. import &#123; AddIcon &#125;
            from "theme/icons")
          </Typography>
        </Grid>
        <Grid
          item
          onClick={e => {
            const Border = e.target;
            Border.style.border = "1px solid gray";
            setTimeout(() => {
              Border.style.border = "none";
            }, 500);
          }}
        >
          {icons.map(d => (
            <Grid container className={classes.icon__grid__wrap} key={d.text}>
              <Grid item className={classes.component}>
                {d.component}
              </Grid>
              <Grid item>
                <Typography className={classes.typography}>{d.text}</Typography>
              </Grid>
              <Grid
                item
                className={classes.clicked}
                name={d.text}
                onClick={copy}
              ></Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
export default CCIcons;
