import { Box, makeStyles } from "@material-ui/core";
import { PermissionBackdrop, useReadPermission } from "components";
import { AppContext } from "context";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { CCPaper, CCTabPanel, CCTabs } from "styles/components";
import { Clinic, LabGold, LabStorage } from "./components";
import { translate } from "components";

const tabsHeight = 54;
const tabWidth = 190;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    padding: "12px 0 0 12px",
    background: theme.palette.background.default
  },
  paper: {
    position: "relative",
    width: 1254,
    height: "100%"
  },
  tabs: {
    borderRadius: "8px 8px 0 0",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    height: tabsHeight,
    boxSizing: "border-box",
    "& button": {
      height: tabsHeight,
      width: tabWidth
    }
  },
  panel: {
    backgroundColor: "white",
    overflow: "auto"
  }
}));

const GoldManagement = props => {
  const { init } = props;

  const intl = useIntl();
  const classes = useStyles();
  const readPermission = useReadPermission("goldManager");
  const { appContext } = useContext(AppContext);

  const [tabIndex, setTabIndex] = useState(init || 0);

  const tabList = [
    {
      label: translate(intl, "labManageGold.useLabSGold"),
      component: () => <LabGold />
    },
    {
      label: translate(intl, "labManageGold.labStorage"),
      component: () => <LabStorage />
    },
    {
      label: translate(intl, "labManageGold.receivedGold"),
      component: () => <Clinic />
    }
  ];

  return (
    <Box className={classes.root}>
      {appContext.ready &&
        (!readPermission ? (
          <PermissionBackdrop permission={"goldManager"} />
        ) : (
          <CCPaper className={classes.paper}>
            <CCTabs
              className={classes.tabs}
              tabList={tabList.map(el => el.label)}
              selectedTab={tabIndex}
              onChangeTab={index => setTabIndex(index)}
            />
            {tabList.map((d, i) => (
              <CCTabPanel
                className={classes.panel}
                key={i}
                value={tabIndex}
                index={i}
              >
                {d.component()}
              </CCTabPanel>
            ))}
          </CCPaper>
        ))}
    </Box>
  );
};

export default GoldManagement;
