import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid, makeStyles } from "@material-ui/core";
import {
  CVButton,
  enqueueToastSave,
  DialogPermissionWarn,
  useWritePermission,
  translate
} from "components";
import { useSnackbar } from "notistack";
import { GET_LAB_DETAILS, UPDATE_LAB_DETAIL } from "queries/setting";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { AddIcon } from "styles/src/themes/common/icons";
import {
  CCDialogTitleAddMod as AddItemDialog,
  CCTypography,
  CCTable,
  CCIconButton,
  CCTextField
} from "styles/components";
import { CCTooltip } from "styles/src/components";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: "10px",
    overflow: "hidden"
  },
  table__head__row: {
    border: 'none'
  },
  table: {
    width: "400px",
    height: "calc(100% - 96px)",
  },
  process__input: {
    margin: 12,
    width: 376
  },
  cell: {
    alignSelf: 'center',
    padding: 0
  },
  table__body: {
    backgroundColor: theme.palette.background.default,
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  },
  icon__btn: { borderRadius: 50, margin: 0 },
  title: { fontSize: 16, fontWeight: "bold", marginLeft: 20 },
  toolbar__container: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    marginBottom: 6
  },
  no__content__text: {
    textAlign: 'center',
    marginTop: '290px',
  }
}));

const Stages = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  const { currentStageIndex, values, moveItem, onClickItem, onChangeItem, onDeleteItem } = props

  // value
  const settingPermission = "orderDetailsOtherSetting";
  // const isPermission = useWritePermission(settingPermission);
  const isPermission = true;
  const [errorPermission, setErrorPermission] = useState(false);

  const itemHeader = [
    {
      key: "stageName",
      className: classes.cell,
      component: ({ cellData }) => {
        return <CCTypography>{cellData}</CCTypography>;
      }
    }
  ];
  // inReturn
  return <Grid className={classes.root}>
    <Grid container justify="space-between" className={classes.toolbar__container} alignItems="center">
      <CCTypography className={classes.title}>
        {translate(intl, "stages")}
      </CCTypography>
      <Grid>
        <CCIconButton
          className={classes.icon__btn}
          onClick={() => {
            moveItem && moveItem(1);
          }}
        >
          <KeyboardArrowDownIcon />
        </CCIconButton>

        <CCIconButton
          className={classes.icon__btn}
          onClick={() => {
            moveItem && moveItem(-1);
          }}
        >
          <KeyboardArrowUpIcon />
        </CCIconButton>
        <CCIconButton
          className={classes.icon__btn}
          onClick={() => {
            onChangeItem && values?.id && onChangeItem(values.id, uuid());
          }}
        >
          <AddIcon />
        </CCIconButton>
      </Grid>
    </Grid>
    <CCTooltip
      title={values.processName?.length > 45 ? values.processName : ''}
      placement={"top"}
    >
      <CCTextField
        label={translate(intl, "process")}
        variant="outlined"
        margin="dense"
        className={classes.process__input}

        value={values.processName || ""}
        InputProps={{
          disabled: true
        }}
      />
    </CCTooltip>
    {values?.stages?.length > 0 ?
      <CCTable
        permission={settingPermission}
        className={classes.table}
        classes={{
          root: classes.table__root,
          table__body: classes.table__body,
          table__head__row: classes.table__head__row
        }}
        heads={itemHeader}
        contents={values?.stages}

        onModClick={({ rowIndex, rowData }) => {
          if (!isPermission) {
            setErrorPermission(true);
            return;
          }
          onChangeItem && onChangeItem(values.id, rowData.id);
        }}
        onDelClick={({ rowData }) => {
          if (!isPermission) {
            setErrorPermission(true);
            return;
          }
          onDeleteItem(values.id, rowData.id);
        }}
        onRowClick={({ data }) => {
          onClickItem(data)
        }}
        selectedRow={currentStageIndex}

      />
      :
      <CCTypography className={classes.no__content__text}>
        {translate(intl, "pleaseSelectAProcess")}
      </CCTypography>
    }

  </Grid>
};

export default Stages;
