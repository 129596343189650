import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import {
  translate,
  CVButton,
} from "components";
import clsx from "clsx";
import { useIntl } from "react-intl";
import {
  LAB_ORDER_FILTER_STATE,
} from "types/labOrder";

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.background.default
  },
  padding8: {
    padding: 8,
    margin: "auto 0"
  },
  button: {
    width: "fit-content",
    height: "36px",
    padding: "0 16px",
    marginRight: 8,
    background: "white",
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": {
      background: "#0277bd",
      color: "white"
    }
  },
  selected__orders: {
    color: theme.palette.error.main,
    fontWeight: "bold"
  }
}));

const LabOrderChangeStatusBar = props => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    permission,
    changeAllStatusOrder,
    selectedOrders,
  } = props;

  return (
    <Box className={clsx(classes.box)}>
      <Grid
        container
        wrap={"nowrap"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className={classes.padding8}
      >
        <Grid container>
          <Typography className={classes.padding8}>
            {translate(intl, "labOrder.changeListStatus", { selectedOrders: <span className={classes.selected__orders}>{ selectedOrders}</span>})}
          </Typography>
          <Grid item className={classes.padding8}>
            <CVButton
              permission={permission}
              variant={"outlined"}
              className={classes.button}
              onClick={() => {
                changeAllStatusOrder(LAB_ORDER_FILTER_STATE.ORDERED.value);
              }}
            >
              {translate(intl, "labOrder.ordered")}
            </CVButton>
            <CVButton
              permission={permission}
              className={classes.button}
              variant={"outlined"}
              onClick={() => {
                changeAllStatusOrder(LAB_ORDER_FILTER_STATE.CAD_DESIGN.value);
              }}
            >
              {translate(intl, "labOrder.filterCadDesign")}
            </CVButton>
            <CVButton
              permission={permission}
              className={classes.button}
              variant={"outlined"}
              onClick={() => {
                changeAllStatusOrder(LAB_ORDER_FILTER_STATE.IN_PROD.value);
              }}
            >
              {translate(intl, "labOrder.inProd")}
            </CVButton>
            <CVButton
              permission={permission}
              className={classes.button}
              variant={"outlined"}
              onClick={() => {
                changeAllStatusOrder(LAB_ORDER_FILTER_STATE.COMPLETE.value);
              }}
            >
              {translate(intl, "labOrder.filterComplete")}
            </CVButton>
            <CVButton
              permission={permission}
              className={classes.button}
              variant={"outlined"}
              onClick={() => {
                changeAllStatusOrder(LAB_ORDER_FILTER_STATE.SHIPPED.value);
              }}
            >
              {translate(intl, "labOrder.shipped")}
            </CVButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LabOrderChangeStatusBar;
