import debounce from "lodash/debounce";
import { Grid, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import WarningIcon from "@material-ui/icons/Warning";
import { CCFlattenDateField, translate } from "components";
import moment from "moment";
import React, {
  useCallback,
  // useEffect,
  useState
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CCButton,
  CCDialogSimple,
  CCTextField,
  CCTypography
} from "styles/src/components";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import { LAB_ORDER_TYPE } from "types/labOrder";
import { RadioButton } from "../";
import CVTextField from "components/CVTextField";
import NumberFormat from "react-number-format";
import DialogError from "./DialogError";

const useStyles = makeStyles(theme => ({
  root: { padding: 0 },
  padding0: { padding: 0 },
  padding8: { padding: 8 },
  margin0: { margin: 0 },
  side__padding8: {
    paddingRight: 8,
    paddingLeft: 8
  },
  side__margin0: {
    marginRight: 0,
    marginLeft: 0
  },
  side__margin8: {
    marginRight: 8,
    marginLeft: 8
  },
  container__line: {
    width: "inherit",
    paddingBottom: 8
  },
  icon: {
    color: theme.palette.error.main,
    marginRight: 8
  },
  warning___color: { color: theme.palette.error.main }
}));

const InputDebounceOnChange = ({ onChange, ...props }) => {
  const debounced = useCallback(
    debounce(nextValue => {
      onChange(nextValue);
    }, 100)
  );

  return (
    <CVTextField
      {...props}
      onChange={e => {
        debounced(e.target.value);
      }}
    />
  );
};

const NumberFormatAge = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({ target: { value: values.formattedValue } });
      }}
      allowNegative={false}
      displayType={"input"}
      decimalSeparator={false}
      {...other}
    />
  );
};

const LabOrderTarget = props => {
  const { value, onChange, setTempDialog } = props;
  const { labOrder, labOrderList, labSetting } = value;
  const {
    type,
    patient,
    doctor,
    clinic,
    requestDate,
    deliveryRequestDate,
    connectedRequestId
  } = labOrder;

  const classes = useStyles();
  const intl = useIntl();

  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Grid container alignItems={"center"} justify={"space-between"}>
        <Grid container style={{ paddingBottom: "8px" }}>
          <Grid item>
            <RadioButton
              variant="outlined"
              value={LAB_ORDER_TYPE.NEW_REQUEST.value}
              checked={type === LAB_ORDER_TYPE.NEW_REQUEST.value}
              onChange={onChange}
            >
              <FormattedMessage id={LAB_ORDER_TYPE.NEW_REQUEST.localeKey} />
            </RadioButton>
          </Grid>
          <Grid item className={classes.side__margin8}>
            <RadioButton
              variant="outlined"
              value={LAB_ORDER_TYPE.MODIFY_REQUEST.value}
              checked={type === LAB_ORDER_TYPE.MODIFY_REQUEST.value}
              onChange={onChange}
            >
              <FormattedMessage id={LAB_ORDER_TYPE.MODIFY_REQUEST.localeKey} />
            </RadioButton>
          </Grid>
          <Grid item>
            <RadioButton
              variant="outlined"
              value={LAB_ORDER_TYPE.RE_REQUEST.value}
              checked={type === LAB_ORDER_TYPE.RE_REQUEST.value}
              onChange={onChange}
            >
              <FormattedMessage id={LAB_ORDER_TYPE.RE_REQUEST.localeKey} />
            </RadioButton>
          </Grid>
        </Grid>

        <Grid container>
          <CCFlattenDateField
            style={{ width: 130 }}
            className={classes.side__margin0}
            label={translate(intl, "common.ui.orderDate")}
            margin={"dense"}
            variant={"outlined"}
            value={moment(requestDate, "X")}
            onChange={e => {
              if (
                moment
                  .duration(e.diff(moment(deliveryRequestDate, "X")))
                  .asDays() < 0
              ) {
                onChange({ requestDate: e.unix() });
              } else {
                setOpen(true);
              }
            }}
          />

          <CCFlattenDateField
            style={{ width: 130 }}
            className={classes.side__margin8}
            label={translate(intl, "common.ui.dueDate")}
            margin={"dense"}
            variant={"outlined"}
            value={moment(deliveryRequestDate, "X")}
            onChange={e => {
              if (
                moment.duration(e.diff(moment(requestDate, "X"))).asDays() > 0
              ) {
                onChange({ deliveryRequestDate: e.unix() });
              } else {
                setOpen(true);
              }
            }}
          />
          <CCTextField
            className={classes.side__margin0}
            style={{ width: 150 }}
            label={translate(intl, "common.ui.clinicName")}
            margin={"dense"}
            variant={"outlined"}
            error={!clinic}
            required
            value={clinic}
            disabled={!!labOrderList.length}
            onChange={e => {
              onChange({
                clinic: e.target.value,
                target: labSetting.filter(item => item.id === e.target.value)[0]
                  .target
              });
            }}
            select
          >
            {labSetting &&
              labSetting.map(item => (
                <CCMenuItem key={item.id} value={item.id} name={item.name}>
                  {item.name}
                </CCMenuItem>
              ))}
          </CCTextField>

          <InputDebounceOnChange
            style={{ width: 150 }}
            className={classes.side__margin8}
            label={translate(intl, "common.ui.drName")}
            margin={"dense"}
            variant={"outlined"}
            valueDefault={doctor}
            InputProps={{
              inputProps: {
                maxLength: 50
              }
            }}
            onChange={value => {
              onChange({ doctor: value });
            }}
          />
          <InputDebounceOnChange
            style={{ width: 150 }}
            className={classes.side__margin0}
            label={translate(intl, "common.ui.patientName")}
            margin={"dense"}
            variant={"outlined"}
            InputProps={{
              inputProps: {
                maxLength: 50
              }
            }}
            required
            valueDefault={patient.name}
            error={!patient.name}
            onChange={value => {
              onChange({
                patient: { ...patient, name: value }
              });
            }}
          />

          <InputDebounceOnChange
            style={{ width: 80 }}
            className={classes.side__margin8}
            label={translate(intl, "common.ui.age")}
            margin={"dense"}
            variant={"outlined"}
            InputProps={{
              inputProps: {
                maxLength: 20
              },
              inputComponent: NumberFormatAge
            }}
            valueDefault={patient.age}
            onChange={value => {
              onChange({
                patient: {
                  ...patient,
                  age: value
                }
              });
            }}
          />

          <CCTextField
            label={translate(intl, "common.ui.sex")}
            variant="outlined"
            margin="dense"
            value={patient.sex || 1}
            onChange={e =>
              onChange({ patient: { ...patient, sex: e.target.value } })
            }
            className={classes.side__margin0}
            select
          >
            <CCMenuItem value={1}>M</CCMenuItem>
            <CCMenuItem value={2}>F</CCMenuItem>
          </CCTextField>
        </Grid>
      </Grid>
      <Grid container wrap={"nowrap"} alignItems={"center"}>
        <Grid item>
          {connectedRequestId && (
            <Link
              color={"textSecondary"}
              component="button"
              variant="body2"
              onClick={setTempDialog}
            >
              {connectedRequestId}
            </Link>
          )}
        </Grid>
      </Grid>
      {open && <DialogError
        title={<WarningIcon className={classes.icon} />}
        open={open}
        onClose={() => setOpen(false)}
        contents={
          <CCTypography className={classes.warning___color}>
            {translate(intl, "common.ui.theDueDateIsBeforeTheOrderedDate")}
          </CCTypography>
        }
        onClick={() => setOpen(false)}
      />}
    </Box>
  );
};

LabOrderTarget.defaultProps = {};

LabOrderTarget.propTypes = {};

export default LabOrderTarget;
