const validPassword = password => {
  let hasNumber = false;
  let hasChar = false;
  let hasSpecialChar = false;

  if (password.length < 8) return false;
  password.split().forEach(e => {
    const isNumber = /[0-9]/;
    if (isNumber.test(e)) hasNumber = true;

    const isChar = /[a-zA-Z]/;
    if (isChar.test(e)) hasChar = true;

    const isSpecialChar = /[~!?@\-+#$%^&*()_]/;
    if (isSpecialChar.test(e)) hasSpecialChar = true;
  });

  return hasNumber && hasChar && hasSpecialChar;
};
export { validPassword };
