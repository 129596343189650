import React, {
  useCallback,
  useReducer,
  useEffect,
  useContext,
  useState
} from "react";
import { AppContext } from "context";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import clsx from "clsx";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { GET_LAB_ORDER } from "queries/labOrder";
import { Grid, Paper } from "@material-ui/core";
import { CCTypography } from "styles/components";
import { v4 as uuid } from "uuid";
import { PermissionBackdrop, useReadPermission } from "components";
import { LabOrderModal } from "views/Normal/DentalLab/components";
import { initializer, reducer } from "./reducer";
import { MARK_MESSAGE_READ } from "queries/labOrder";
import {
  DateHeader,
  MonthEventItem,
  WeekEventItem,
  Toolbar
} from "./components";
import { useStyles } from "./useStyles";
import { makeStatus } from "./utils";
import { useIntl } from "react-intl";
import PERMISSIONS from "types/permission";
import { translate } from "components";

const localizer = momentLocalizer(moment);

const RawReservation = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const [state, dispatchState] = useReducer(reducer, {}, initializer);
  const [status, setStatus] = useState({
    total: 0,
    shipped: 0,
    pending: 0
  })
  const [detailedOrderModalVisible, setDetailedOrderModalVisible] =
    useState(null);
  const readPermission = useReadPermission("calendar");

  const [markMessageRead] = useMutation(MARK_MESSAGE_READ, {
    onCompleted: data => {
      searchLabRefetch();
    }
  });

  const { data, refetch: searchLabRefetch } = useQuery(GET_LAB_ORDER, {
    fetchPolicy: "network-only",
    variables: {
      query: {
        limit: 200,
        dueDateFilter:
          state.currentView === "week"
            ? {
              from: moment(state.currentDate).startOf("week").unix(),
              to: moment(state.currentDate).endOf("week").unix()
            }
            : {
              from: moment(state.currentDate).startOf("month").unix(),
              to: moment(state.currentDate).endOf("month").unix()
            }
      }
    },
    pollInterval: 3000
  });

  useEffect(() => {
    if (data?.orderList?.items) {
      const res = data.orderList.items.reduce((acc, cur) => {
        const key =
          moment(cur.deliveryRequestDate, "X").startOf("day").unix() +
          "_" +
          moment(cur.deliveryRequestDate, "X").endOf("day").unix();

        acc[key] = (acc[key] || []).concat(cur);

        return acc;
      }, {});
      const target = Object.entries(res).map(([key, value]) => ({
        items: value.sort((a, b) => {
          if (a.status < b.status) return -1;
          if (a.status > b.status) return 1;
          return 0;
        }),
        id: uuid(),
        start: moment(key.split("_")[0], "X").toDate(),
        end: moment(key.split("_")[1], "X").toDate()
      }))
      const filterTodayEvents = target.reduce((acc, cur) => {
        if (
          moment(cur.start).diff(
            moment().startOf("day"),
            "days"
          ) === 0
        )
          return acc.concat(cur.items);
        return acc;
      }, [])
      console.log('status', status)
      if (filterTodayEvents.length || status === null) {
        setStatus(makeStatus(filterTodayEvents))
      }
      dispatchState({
        type: "setEvents",
        target
      });
    }
  }, [data]);

  const CalendarEvent = {
    handleView: useCallback(
      e => {
        dispatchState({ type: "setCurrentView", target: e });
      },
      [dispatchState]
    ),
    handleNavigate: useCallback(
      e => {
        dispatchState({ type: "setCurrentDate", target: e });
      },
      [dispatchState]
    ),
    handleSelectEvent: useCallback(
      (event, e) => {
        dispatchState({ type: "setCurrentDate", target: event.start });
        dispatchState({ type: "setCurrentView", target: Views.WEEK });
      },
      [dispatchState]
    )
  };

  const CalendarComponents = {
    month: {
      event: MonthEventItem,
      dateHeader: DateHeader
    },
    week: {
      event: props => (
        <WeekEventItem
          {...props}
          searchLabRefetch={searchLabRefetch}
          handleOpenDetailOrder={selectedItem => {
            setDetailedOrderModalVisible(selectedItem);
          }}
        />
      )
      // event: WeekEventItem
    },
    toolbar: props => {
      return <Toolbar {...props} />;
    }
  };
  const formats = {
    dayFormat: (date, culture, localizer) =>
      localizer.format(date, "D/ddd", culture)
  };
  
  return (
    <div className={clsx(classes.root)}>
      <Paper variant={"outlined"} className={clsx(classes.paper)}>
        {appContext.ready &&
          (!readPermission ? (
            <PermissionBackdrop permission={"calendar"} />
          ) : (
            <>
              <Grid
                container
                direction="column"
                className={clsx(classes.container)}
              >
                <Grid item className={classes.header}>
                  <CCTypography variant="h3">
                    {translate( intl,"labCalendar.ordersCalendar" )}
                  </CCTypography>
                </Grid>
                <Grid item className={clsx(classes.calendar)}>
                  <Calendar
                    dayLayoutAlgorithm={"no-overlap"}
                    localizer={localizer}
                    view={state.currentView}
                    onView={CalendarEvent.handleView}
                    defaultView={Views.WEEK}
                    views={[Views.WEEK, Views.MONTH]}
                    events={state.events}
                    showMultiDayTimes={true}
                    defaultDate={new Date()}
                    date={state.currentDate}
                    step={state.timeStep}
                    timeslots={state.timeSlotCount}
                    resizable
                    onNavigate={CalendarEvent.handleNavigate}
                    onEventResize={CalendarEvent.handleEventResize}
                    onEventDrop={CalendarEvent.handleEventDrop}
                    // selectable
                    onSelectEvent={CalendarEvent.handleSelectEvent}
                    components={CalendarComponents}
                    min={state.min}
                    max={state.min}
                    slotPropGetter={date => {
                      return {
                        // className: clsx({ [classes.notWorkTime]: !isWorkTime(date) }),
                      };
                    }}
                    dayPropGetter={date => {
                      return {
                        className: clsx({
                          [classes.saturday]: moment(date).format("E") === "6",
                          [classes.sunday]: moment(date).format("E") === "7"
                          // || isHolyday checking
                        })
                      };
                    }}
                    formats={formats}
                    messages={{
                      status: status || []
                    }}
                  />
                </Grid>
              </Grid>

              {Boolean(detailedOrderModalVisible) && (
                <LabOrderModal
                  open={Boolean(detailedOrderModalVisible)}
                  onClose={() => {
                    setDetailedOrderModalVisible(null);
                    markMessageRead({
                      variables: { orderId: detailedOrderModalVisible.id }
                    })
                      .then(() => {
                        searchLabRefetch();
                      })
                      .catch(e => {
                        searchLabRefetch();
                      });
                  }}
                  refetchOrders={() => searchLabRefetch()}
                  value={detailedOrderModalVisible.id}
                  onModify={() => {}}
                  buttonHidden={true}
                  permission={PERMISSIONS.CATEGORY.CALENDAR.DEFAULT.localeKey}
                />
              )}
            </>
          ))}
      </Paper>
    </div>
  );
};

const OrderCalendar = props => {
  return <RawReservation {...props} />;
};

export default OrderCalendar;
