import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { AppContext, RouteContext } from "context";
import gql from "graphql-tag";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from "react";
import { useIntl } from "react-intl";
import { CCButton, CCTextField, CCTypography } from "styles/components";
import { MembershipIcon, ProgressIcon } from "styles/icons";
import { getFlatPermission } from "types/permission";
import msg from "utils/errorMsg";
import {
  CVPasswordField,
  DialogNewPassword,
  DialogWarn,
  translate,
} from "../../../components";
import { CleverRegistration } from "./components";
import { DialogWarningConfirm } from "components";
import moment from "moment";

const LOGIN_STAFF = gql`
  mutation login($staffId: String!, $labId: String!, $password: String!) {
    login(staffId: $staffId, labId: $labId, password: $password) {
      token
      staff {
        initPassword
        authority
        staffName
        staffId
        permissionSetting {
          category
          data {
            permission {
              write
              read
            }
            category
            items {
              category
              permission {
                read
                write
              }
              items {
                category
                permission {
                  read
                  write
                }
                items {
                  category
                  freeze
                  permission {
                    read
                    write
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangeStaffPassword($newPassword: String!) {
    changeStaffPassword(newPassword: $newPassword) {
      staffId
    }
  }
`;

const GET_NOTICES = gql`
  query GetNotices($input: NoticeListInput!) {
    notices(input: $input) {
      total
      items {
        id
        title
        link
        date
      }
    }
  }
`;

const width = 340;
const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16,
    boxSizing: "border-box",
    // backgroundImage: "url(bg1.png)",
    backgroundRepeat: "no-repeat",
    // backgroundSize: "1920px 1080px"
    backgroundSize: "cover"
  },

  top: {
    alignSelf: "flex-end",
    display: "flex",
    color: "white",
    padding: "2px 8px",
    background: "gray",
    borderRadius: 25
  },

  top__text: {
    lineHeight: "14px",
    alignSelf: "center",
    color: "inherit",
    marginLeft: 8,
    cursor: "pointer"
  },
  middle: {
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  },
  middle__coverbox: {
    backgroundColor: "rgba(255, 255, 255, 0.64)",
    width: 700,
    height: 790,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  middle__box: {
    width: width
  },
  middle__border: {
    width: 1,
    height: 400,
    margin: "0 60px",
    background: "rgba(0, 0, 0, 0.54)"
  },
  middle__rightcolumn: {
    width: 793
  },
  rightcolumn__header: {

    fontSize: 48,
    lineHeight: "34px",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "Bold",
    paddingBottom: 26
  },
  direction_ltr: {
    direction: 'rtl',
    textAlign: "right",
  },
  rightcolumn__description: {
    fontSize: 20,
    lineHeight: "28px",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "Bold"
  },
  desc__list_title: {
    color: theme.palette.sub.main,
    fontWeight: "bold"
  },
  desc___list__item: {
    color: theme.palette.sub.main,

    marginBottom: 4,
    fontWeight: "400"
  },
  rightcolumn__headerlink: {
    fontFamily: "Noto Sans",
    color: "white",
    fontSize: 14,
    paddingBottom: 8,
    lineHeight: "20px",
    fontWeight: "bold"
  },
  rightcolumn__textlink: {
    fontFamily: "Noto Sans",
    color: "white",
    fontSize: 14,
    lineHeight: "20px"
  },
  rightcolumn__link: {
    marginTop: 63,
    background: "rgba(0, 0, 0, 0.54)",
    borderRadius: 8,
    padding: "8px 16px",
    minHeight: "300px"
  },
  rightcolumn__boxlink: {
    color: "white",
    padding: "8px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  middle__input: {
    margin: "12px 0"
  },
  bottom: {
    alignSelf: "center",
    padding: "4px 14px",
    background: "black",
    borderRadius: 25,
    color: "white",
    textAlign: 'center'
  },

  text__field: {
    width: width,
    margin: "24px 0 0 0",
    background: "white"
  },
  btn__login: {
    marginTop: 24,
    padding: "8px 16px"
  },
  btn__tablet__login: {
    marginTop: 16,
    padding: 16
  },

  text__login: {
    width: "100%",
    lineHeight: "20px",
    alignSelf: "center",
    color: "white"
  },
  text__tablet__login: {
    width: "100%",
    lineHeight: "20px",
    alignSelf: "center",
    color: "white"
  },
  checkbox: {
    display: "flex",
    flexDirection: "column",
    marginTop: 24
  },
  warning___color: { color: theme.palette.error.main },
  text_vn: { fontSize: 16 }
}));

const initDialog = {
  new: { state: false, value: null },
  mod: { state: false, value: null },
  warn: { state: false, value: null }
};

const dialogReducer = (state, action) => {
  return { ...state, [action.type]: action.value };
};

const SignIn = () => {
  const classes = useStyles();
  // const theme = useTheme();
  const intl = useIntl();
  const { appContext, dispatchApp } = useContext(AppContext);
  const { dispatchRoute } = useContext(RouteContext);
  const [dialog, dispatchDialog] = useReducer(dialogReducer, initDialog);
  const isArLang = localStorage.getItem('lang') === 'ar';

  const [open, setOpen] = useState(false);
  const [loginFailMessage, setLoginFailMessage] = useState("")

  const [loginFailWarning, setLoginFailWarning] = useState(false);
  const [hospitalIdSave, setHospitalIdSave] = useState(
    localStorage.getItem("hospitalIdSave") === "true"
  );
  const [idPwSave, setIdPwSave] = useState(
    localStorage.getItem("idPwSave") === "true"
  );
  const hospitalIdRef = useRef(null);
  const idRef = useRef(null);
  const passwordRef = useRef(null);
  const childRef = useRef();

  const [changePassword] = useMutation(CHANGE_PASSWORD, {});
  const [signIn] = useMutation(LOGIN_STAFF, {
    onCompleted: data => {
      localStorage.setItem("labId", hospitalIdRef.current.value);
      localStorage.setItem("id", idRef.current.value);
      dispatchApp({ type: "needNotify", target: true });

      if (data?.login?.staff.permissionSetting) {
        const permission = getFlatPermission(
          data.login.staff.permissionSetting.data
        );
        dispatchApp({ type: "permission", target: permission });
      }
      if (data?.login?.token) {
        sessionStorage.setItem("token", data.login.token);
        dispatchApp({ type: "token", target: data.login.token });
      }
      if (data?.login?.token && data?.login?.staff?.initPassword) {
        dispatchDialog({ type: "new", value: { state: true } });
        return;
      } else {
        dispatchRoute({ path: "/orders" });
      }
    },
    onError: error => {
      const { graphQLErrors } = error;
      if (graphQLErrors) {

        graphQLErrors.forEach(graphQLError => {
          const errCode = graphQLErrors[0];
          const { extensions: { code } } = graphQLError;

          if (code === "LABREQTERMINATED") {
            setLoginFailMessage(translate(intl, "signIn.ui.labRequestTerminated"));
            setLoginFailWarning(true);
          } else if (code === "LABDISCONTINUED") {
            setLoginFailMessage(translate(intl, "signIn.ui.temporarySuspension"));
            setLoginFailWarning(true);
          } else if (code === "LABPROCESSINGTERMINATED") {
            setLoginFailMessage(translate(intl, "signIn.ui.processingTerminated", { phone: process.env.REACT_APP_DEFAULT_SUPPORT_PHONE || "024 3226 2932" }));
            setLoginFailWarning(true);
          } else if (code === "AUTH03") {
            let serviceNumber = process.env.REACT_APP_DEFAULT_SUPPORT_PHONE ? process.env.REACT_APP_DEFAULT_SUPPORT_PHONE : ""
            if (process.env.REACT_APP_DEFAULT_WHATSAPP) {
              serviceNumber += `/ WhatsApp: ${process.env.REACT_APP_DEFAULT_WHATSAPP}`
            }
            setLoginFailMessage(translate(intl, "errorMsg.auth03", { serviceNumber }));
            setLoginFailWarning(true);
          }
          else {
            dispatchDialog({
              type: "warn",
              value: { state: true, value: graphQLError?.extensions?.code }
            });
          }
        });
      }
    }
  });
  const { data: noticeData, loading: noticeDataLoading } = useQuery(
    GET_NOTICES,
    {
      variables: {
        input: {
          isPublished: true
        }
      },
      fetchPolicy: "network-only"
    }
  );

  const normalSignIn = () => {
    sessionStorage.setItem("mode", "normal");
    if (
      !idRef.current.value ||
      !hospitalIdRef.current.value ||
      !passwordRef.current.value
    ) {
      dispatchDialog({
        type: "warn",
        value: { state: true, value: "INPUTMISSING" }
      });
      return;
    }
    signIn({
      variables: {
        staffId: idRef.current.value,
        labId: hospitalIdRef.current.value,
        password: passwordRef.current.value
      }
    })

    // .then(rs => {
    //   console.log('res', rs)
    //   const { errors: { graphQLErrors } , networkError } = rs;
    //   if(graphQLErrors.length) {
    //     // extensions
    //     const errCode = graphQLErrors[0];
    //     const { extensions: { code } } = errCode;

    //     // setLoginFailWarning(true);
    //     // console.log("code", code)
    //   }

    //   // console.log("graphQLErrors", graphQLErrors);

    //   // console.log("networkError", networkError);
    //   // if (graphQLErrors.length === 0) {
    //   //   setStatusRegister("networkError");
    //   // }

    // })
    // .catch(err => {
    // });
  };
  const handleClose = () => {
    setOpen(false);
    childRef.current.resetValue();
  };

  useEffect(() => {
    if (localStorage.getItem("hospitalIdSave") === "true") {
      setHospitalIdSave(localStorage.getItem("hospitalIdSave") === "true");
      hospitalIdRef.current.value = localStorage.getItem("labId");
    }
    if (localStorage.getItem("idPwSave") === "true") {
      setIdPwSave(localStorage.getItem("idPwSave") === "true");
      idRef.current.value = localStorage.getItem("id");
      // passwordRef.current.value = localStorage.getItem("pw");
    }
  }, []);
  useEffect(() => {
    hospitalIdRef.current.focus();
  });
  useEffect(() => {
    sessionStorage.setItem("token", "");
  }, []);

  return (
    <div
      className={clsx(classes.root, classes.height)}
      style={{ backgroundImage: appContext.bg }}
    >
      <div className={classes.top} onClick={() => setOpen(true)}>
        <MembershipIcon />
        <CCTypography className={classes.top__text}>
          {translate(intl, "signIn.ui.vatechCleverLabRegistration")}
        </CCTypography>
      </div>
      <CleverRegistration open={open} onClose={handleClose} ref={childRef} />

      <div className={classes.middle}>
        <div className={classes.middle__rightcolumn}>
          <Typography className={clsx({ [classes.direction_ltr]: isArLang }, classes.rightcolumn__header)}>
            FOR CLEVER LABS
          </Typography>

          <div style={{ marginTop: 16 }}>
            <CCTypography variant="h4" className={clsx({ [classes.direction_ltr]: isArLang }, classes.desc___list__item)}>
              <span className={classes.desc__list_title}>
                1. CONNECT ANYTIME ANYWHERE :{" "}
              </span>
              Access Clever Lab through any internet device!
            </CCTypography>
            <CCTypography variant="h4" className={clsx({ [classes.direction_ltr]: isArLang }, classes.desc___list__item)}>
              <span className={classes.desc__list_title}>
                2. STORE DATA SECURELY:{" "}
              </span>
              Store, sync and access your data securely on cloud!
            </CCTypography>
            <CCTypography variant="h4" className={clsx({ [classes.direction_ltr]: isArLang }, classes.desc___list__item)}>
              <span className={classes.desc__list_title}>
                3. GO ECO-FRIENDLY:{" "}
              </span>
              Go paperless and manage data online!
            </CCTypography>
            <CCTypography variant="h4" className={clsx({ [classes.direction_ltr]: isArLang }, classes.desc___list__item)}>
              <span className={classes.desc__list_title}>
                4. ENJOY INTUITIVE UI:{" "}
              </span>
              Start using without any training!
            </CCTypography>
            <CCTypography
              variant="h4"
              className={clsx({ [classes.direction_ltr]: isArLang }, classes.desc___list__item, classes.margin)}
            >
              <span className={classes.desc__list_title}>
                5. MANAGE CLINICS ONLINE:{" "}
              </span>
              Communicate with Clever clinics using built-in messenger!
            </CCTypography>
            <CCTypography
              variant="h4"
              className={clsx({ [classes.direction_ltr]: isArLang }, classes.desc___list__item, classes.margin)}
            >
              <span className={classes.desc__list_title}>
                6. STAY COMPETITIVE:{" "}
              </span>
              Be the first choice of the Clever clinics!
            </CCTypography>
          </div>

          <div className={classes.rightcolumn__link}>
            <Typography className={classes.rightcolumn__headerlink}>
              {translate(intl, "signIn.ui.vatechNotice")}

            </Typography>
            {!noticeDataLoading &&
              noticeData &&
              noticeData.notices.items.map((notice, index) => {
                return (
                  <div key={index} className={classes.rightcolumn__boxlink}>
                    <a
                      className={classes.rightcolumn__textlink}
                      href={
                        notice.link.match(/^https?:\/\//i) ||
                          notice.link.match(/^http?:\/\//i)
                          ? notice.link
                          : `http://${notice.link}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {notice.title} - {moment.unix(notice.date).format('L')}
                    </a>
                    <ChevronRightIcon />
                  </div>
                );
              })}
          </div>
        </div>

        <div className={classes.middle__border} />

        <div className={classes.middle__coverbox}>
          <div className={classes.middle__box}>
            <div>
              <img src="clever.png" alt="clever" />
            </div>
            <div className={classes.middle__input}>
              <CCTextField
                className={classes.text__field}
                variant={"outlined"}
                id="labId"
                label={translate(intl, "signIn.ui.labId")}
                fullWidth
                required
                inputRef={hospitalIdRef}
              />

              <CCTextField
                className={classes.text__field}
                variant={"outlined"}
                id="staffid"
                label={translate(intl, "signIn.ui.userId")}
                fullWidth
                autoComplete={"username"}
                required
                inputRef={idRef}
              />

              <CVPasswordField
                className={classes.text__field}
                variant={"outlined"}
                id="password"
                label={translate(intl, "signIn.ui.password")}
                fullWidth
                required
                autoComplete={"current-password"}
                inputRef={passwordRef}
                type="password"
                onFocus={() => {
                  document.dispatchEvent(
                    new KeyboardEvent("keyDown", { code: 229 })
                  );
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    normalSignIn();
                  }
                }}
              />
            </div>

            <CCButton
              id="signin"
              className={classes.btn__login}
              variant="contained"
              color="primary"
              fullWidth
              onClick={normalSignIn}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <ProgressIcon style={{ width: 60, height: 60 }} />
                <CCTypography variant="h3"
                  className={clsx({ [classes.text__login]: true, [classes.text_vn]: localStorage.getItem("lang") === "vi" })}
                >
                  {translate(intl, "signIn.ui.signInButton")}
                </CCTypography>
              </div>
            </CCButton>

            <div className={classes.checkbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hospitalIdSave}
                    onChange={e => {
                      setHospitalIdSave(e.target.checked);
                      localStorage.setItem("hospitalIdSave", e.target.checked);
                    }}
                  />
                }
                label={
                  <CCTypography>
                    {translate(intl, "signIn.ui.rememberLabId")}
                  </CCTypography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={idPwSave}
                    onChange={e => {
                      setIdPwSave(e.target.checked);
                      localStorage.setItem("idPwSave", e.target.checked);
                      if (!e.target.checked) {
                        localStorage.setItem(
                          "hospitalIdSave",
                          e.target.checked
                        );
                      }
                    }}
                  />
                }
                label={
                  <CCTypography>
                    {translate(intl, "signIn.ui.rememberUserId")}
                  </CCTypography>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.bottom}>
        <CCTypography color="inherit">
          {translate(intl, "signIn.ui.vatechCleverSupportCenterPhone", { phone: process.env.REACT_APP_DEFAULT_SUPPORT_PHONE || "024 3226 2932" })}
        </CCTypography>
        {process.env.REACT_APP_LANGUAGE_REGION === "ru" &&
          <CCTypography color="inherit">
            {translate(intl, "signIn.ui.vatechCleverSupportCenterWhatsapp", { phone: process.env.REACT_APP_DEFAULT_WHATSAPP || "" })}
          </CCTypography>
        }

      </div>

      {/* <Loading open={loading} /> */}
      {dialog.warn.state && (
        <DialogWarn
          open={dialog.warn.state}
          onClose={() => {
            dispatchDialog({
              type: "warn",
              value: { state: false, value: null }
            });
          }}
          text={translate(
            intl,
            msg[dialog.warn.value] ? msg[dialog.warn.value] : msg["UNKOWNERROR"],
            {
              br: () => <br />,
              phone: process.env.REACT_APP_DEFAULT_SUPPORT_PHONE || "024 3226 2932"
            },
          )}
        />
      )}
      {dialog.new.state && (
        <DialogNewPassword
          open={dialog.new.state}
          onClose={() => {
            dispatchDialog({
              type: "new",
              value: { state: false, value: null }
            });
          }}
          onSave={password => {
            changePassword({
              variables: {
                newPassword: password
              }
            })
              .then(() => {
                dispatchRoute({ path: "/orders" });
              })
              .catch(error => {
                const { graphQLErrors } = error;
                if (graphQLErrors) {
                  graphQLErrors.forEach(graphQLError => {
                    console.log("graphQLError", graphQLError);
                    const errCode = graphQLError?.extensions?.code;
                    if (errCode === "PASSWORD04") {
                      setLoginFailMessage(translate(intl, "common.message.changePasswordError2"));
                      setLoginFailWarning(true);
                    } else {
                      dispatchDialog({
                        type: "warn",
                        value: {
                          state: true,
                          value: graphQLError?.extensions?.code
                        }
                      });
                    }
                  });
                }
              });
          }}
        />
      )}
      {loginFailWarning &&
        <DialogWarningConfirm
          open={loginFailWarning}
          onAgree={() => {
            setLoginFailWarning(false);
          }}
          // onClose={() => setLoginFailWarning(false)}
          agreeButtonLabel={translate(intl, "common.ui.confirm")}
          agreeButtonColor={"error"}
          // disagreeButtonLabel={translate(intl, "common.ui.cancel")}
          // title={translate(intl, "signIn.ui.loginFailWarning")}
          title={loginFailMessage}
        />
      }
    </div>
  );
};

export default SignIn;
