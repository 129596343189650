import axios from "axios";

const Method = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST"
};

const Action = {
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
};

const Target = {
  PROFILE: 'profile',
  STAMP: 'stamp',
  PHOTO: 'photo',
  LOGO: 'logo',
  DAEMON: 'daemon',
  AUDIO: 'audio'
};

const requestAction = async (action, target, id, name) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    const body = JSON.stringify({ action, target, id, name });

    xhr.open('POST', process.env.REACT_APP_CF_UPLOAD_URL, true);
    // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(body);
    xhr.onload = function () {
      const statusCode = JSON.parse(this.responseText).statusCode;

      if (statusCode === 200) {
        const body = JSON.parse(this.responseText).body;
        const url = JSON.parse(body).data;
        resolve(url);
      }
      resolve('');
    };
  });
};

const getPresignedPostData = (request) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    const body = JSON.stringify({
      ...request,
    });

    console.log("getPresignedPostData.body", body);
    xhr.open('POST', process.env.REACT_APP_CF_UPLOAD_URL, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.send(body);
    xhr.onload = function () {
      resolve(JSON.parse(this.responseText));
    };
  });
};

const getObject = async (target, id, name) => {
  const result = await requestAction(Action.GET, target, id, name);
  return result;
}

const deleteObject = async (target, id, name) =>
  requestAction(Action.DELETE, target, id, name);

const putObject = async (target, id, name, selectedFile, onDownload, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    const presignedPostData = await getPresignedPostData({
      action: Action.PUT,
      target,
      id,
      name,
      type: selectedFile.type
    });

    const { file } = selectedFile.src;

    console.log("putObject.file", file);
    const { url } = JSON.parse(presignedPostData.body).data;
    axios({
      url,
      method: Method.PUT,
      data: file,
      onUploadProgress: progress => onDownload && onDownload(progress),
      cancelToken
    })
      .then(() => {
        resolve();
      })
      .catch(err => {
        if (err) reject(err);

        resolve();
      });
  });
};

const putObjectBlob = async (target, id, name, blob, type = 'image/png', onDownload = () => {}) => {
  return new Promise(async (resolve, reject) => {
    const { body } = await getPresignedPostData({
      action: Action.PUT,
      target,
      id,
      name,
      type: type
    });

    const data = JSON.parse(body).data;

    const { fields, _method: method, url } = data;
    const formData = new FormData();

    if (fields) {
      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
    }
    formData.append("file", blob);

    axios({
      url: url,
      method: method,
      data: method === "PUT" ? blob : formData,
      onUploadProgress: progress => onDownload && onDownload(progress)
    })
      .then(res => {
        if (res.status === 200 || res.status === 204) {
          resolve();
        } else {
          reject(res?.responseText);
        }
      })
      .catch(err => {
        if (err) reject(err);

        resolve();
      });
  });
};

export default {
  requestAction,
  getPresignedPostData,
  getObject,
  putObject,
  putObjectBlob,
  deleteObject,
  Target,
};
