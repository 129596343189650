import { useQuery } from "@apollo/react-hooks";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { GOLD_LAB_LIST, GOLD_UNIT_PRICE } from "queries/goldManagement";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCButton } from "styles/components";
import { PaidIcon } from "styles/icons";
import ManageGoldTable from "../ManageGoldTable";
import MonthNav from "../MonthNav";
import UnitPriceManageModal from "../UnitPriceManageModal";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0"
  },
  unitprice__button: {
    position: "absolute",
    right: 16,
    height: 36,
    padding: 8,
    color: theme.palette.icon,
    fontWeight: 700
  }
}));

const UsageHistory = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [
    unitPriceManageModalVisible,
    setUnitPriceManageModalVisible
  ] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const {
    data: goldLabListData,
    loading: goldLabListLoading,
    refetch: goldLabListRefetch
  } = useQuery(GOLD_LAB_LIST, {
    variables: {
      query: {
        month: moment().format("M"),
        year: moment().format("YYYY")
      }
    }
  });
  const {
    data: goldUnitPriceData,
    loading: goldUnitPriceLoading,
    refetch: goldUnitPriceRefetch
  } = useQuery(GOLD_UNIT_PRICE, {
    variables: { query: { importDate: {} } }
  });

  const handleOnMoveToPrevMonth = () => {
    let newMonth = month - 1;
    let newYear = year;
    if (newMonth === 0) {
      newMonth = 12;
      newYear -= 1;
      setYear(newYear);
    }
    setMonth(newMonth);
    goldLabListRefetch({
      query: {
        month: String(newMonth),
        year: String(newYear)
      }
    });
  };
  const handleOnMoveToNextMonth = () => {
    let newMonth = month + 1;
    let newYear = year;
    if (newMonth === 13) {
      newMonth = 1;
      newYear += 1;
      setYear(newYear);
    }
    setMonth(newMonth);
    goldLabListRefetch({
      query: {
        month: String(newMonth),
        year: String(newYear)
      }
    });
  };

  return (
    <>
      <Box className={classes.root}>
        <MonthNav
          month={month}
          year={year}
          onMoveToNextMonth={handleOnMoveToNextMonth}
          onMoveToPrevMonth={handleOnMoveToPrevMonth}
        />
        <CCButton
          color="normal"
          variant="contained"
          startIcon={<PaidIcon />}
          className={classes.unitprice__button}
          onClick={() => setUnitPriceManageModalVisible(true)}
        >
          {translate(intl, "labManageGold.unitPriceManagement")}
        </CCButton>
      </Box>
      {!goldLabListLoading && !goldUnitPriceLoading && (
        <ManageGoldTable
          month={month}
          year={year}
          goldLabList={goldLabListData?.goldLabList?.items}
          goldLabListData={goldLabListData}
          goldLabListLoading={goldLabListLoading}
          goldLabListRefetch={goldLabListRefetch}
          goldUnitPriceData={goldUnitPriceData}
          goldUnitPriceLoading={goldUnitPriceLoading}
          goldUnitPriceRefetch={goldUnitPriceRefetch}
        />
      )}

      {unitPriceManageModalVisible && (
        <UnitPriceManageModal
          open={unitPriceManageModalVisible}
          onClose={() => setUnitPriceManageModalVisible(false)}
        />
      )}
    </>
  );
};

export default UsageHistory;
