const ALL = {
  ALL: { value: "0", localeKey: "all" }
};

const INOUT_CATEGORY = {
  RECEIVING: { value: "1", localeKey: "receiving" },
  SENDING: { value: "2", localeKey: "sending" }
};

const STOCK_STATUS = {
  LACK_STOCK: { value: "1", localeKey: "lackStock" },
  NO_STOCK: { value: "2", localeKey: "noStock" }
};

const EXPIRE_STATUS = {
  NO_EXPIRE: { value: "1", localeKey: "noExpire" },
  HAS_EXPIRE: { value: "2", localeKey: "hasExpire" }
};

const UNIT = {
  EA_UNIT: { value: "1", localeKey: "eaUnit" },
  BIN_UNIT: { value: "2", localeKey: "binUnit" }
};

const STOCK_ALERT = {
  EA_UNIT: { value: "1", localeKey: "noAlert" },
  BIN_UNIT: { value: "2", localeKey: "alertSetting" }
};

const EXSISTENT_ALERT = {
  NON_EXISTENT: { value: "1", localeKey: "nonExistent" },
  EXISTENT: { value: "2", localeKey: "existent" }
};

const INOUT_STOCK = {
  ALL: { value: "0", localeKey: "all" },
  IN_STOCK: { value: "1", localeKey: "inventoryReceiving" },
  OUT_STOCK: { value: "2", localeKey: "inventoryShipping" }
};

const IN_DETAIL = {
  // INVENTORY_SURVEY: { value: "1", localeKey: "inventorySurvey" },
  ERROR_CORRECTION: { value: "2", localeKey: "errorCorrection" },
  OTHER_PURCHASE: { value: "3", localeKey: "otherPurchase" },
  OTHER_ACQUISITION: { value: "4", localeKey: "otherLoss" }
};

const OUT_DETAIL = {
  // INVENTORY_SURVEY: { value: "1", localeKey: "inventorySurvey" },
  ERROR_CORRECTION: { value: "2", localeKey: "errorCorrection" },
  OTHER_RETURN: { value: "3", localeKey: "otherReturn" },
  OTHER_LOSS: { value: "4", localeKey: "otherLoss" }
};

const ESTIMATION_STATUS = {
  REQUEST: { value: "1", localeKey: "request" },
  ARRIVE: { value: "2", localeKey: "arrive" }
};

const ORDER_STATUS = {
  // INVENTORY_SURVEY: { value: "1", localeKey: "inventorySurvey" },
  ERROR_CORRECTION: { value: "2", localeKey: "errorCorrection" },
  OTHER_RETURN: { value: "3", localeKey: "otherReturn" },
  OTHER_LOSS: { value: "4", localeKey: "otherLoss" }
};

const PRODUCT_CATEGORY = {
  SUPPLIER_PRODUCT: { value: "1", localeKey: "supplierDealingProduct" },
  HOSPITAL_PRODUCT: { value: "2", localeKey: "ourHospitalInventoryProduct" }
};

const RETURN_STATUS = {
  REQUEST_RETURN: { value: "1", localeKey: "returnRequest" },
  RETURNING: { value: "2", localeKey: "returning" },
  OTHER_RETURN: { value: "3", localeKey: "otherReturn" },
  OTHER_LOSS: { value: "4", localeKey: "otherLoss" }
};

export {
  ALL,
  INOUT_CATEGORY,
  STOCK_STATUS,
  EXPIRE_STATUS,
  INOUT_STOCK,
  IN_DETAIL,
  OUT_DETAIL,
  STOCK_ALERT,
  EXSISTENT_ALERT,
  UNIT,
  ESTIMATION_STATUS,
  ORDER_STATUS,
  RETURN_STATUS,
  PRODUCT_CATEGORY
};
