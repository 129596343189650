import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import {
  ModifyIcon,
  PersonIcon,
  RemoveIcon
} from "../../../../themes/common/icons";

const useStyles = makeStyles(theme => ({
  root: {
    color: "white",
    backgroundColor: props =>
      props.selected ? theme.palette.select.main : theme.palette.sub.main,
    "&.MuiChip-deletable:focus": {
      backgroundColor: props =>
        props.selected ? theme.palette.select.main : theme.palette.sub.main
    }
  },
  // icon: { margin: 0 },
  // icon__lock: {
  //   color: props => (props.selected ? "white" : "rgba(0, 0, 0, 0.38)")
  // },
  // icon__modify: {
  //   color: props => (props.selected ? "white" : "rgba(0, 0, 0, 0.38)")
  // },
  // icon__close: {
  //   color: "white"
  // },
  noImgIcon: { color: "white" },
  avatar: {
    backgroundColor: "transparent",
    "& > svg": {
      color: "white"
    }
  },
  clickableIcon: {
    cursor: "pointer",
    marginRight: 4
  }
}));

const CCChips = props => {
  const {
    className,
    onClickIcon,
    onClickMod,
    onClickDel,
    useAvatar,
    chipIcon,
    avatar,
    children
  } = props;
  const classes = useStyles(props);

  return (
    <Chip
      className={clsx(classes.root, className)}
      avatar={
        Boolean(useAvatar) ? (
          <Avatar
            className={classes.avatar}
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onClickIcon && onClickIcon(e);
            }}
            src={avatar}
          >
            <PersonIcon className={classes.noImgIcon} />
          </Avatar>
        ) : chipIcon ? (
          <Avatar
            className={classes.avatar}
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onClickIcon && onClickIcon(e);
            }}
          >
            {chipIcon}
          </Avatar>
        ) : null
      }
      deleteIcon={
        <>
          {onClickMod && (
            <ModifyIcon
              className={classes.clickableIcon}
              onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onClickMod(e);
              }}
            />
          )}
          {onClickDel && (
            <RemoveIcon
              className={classes.clickableIcon}
              onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onClickDel(e);
              }}
            />
          )}
        </>
      }
      label={children}
      onDelete={onClickDel || onClickMod ? () => {} : null}
    />
  );
};
export default CCChips;
