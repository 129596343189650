/*eslint react-hooks/exhaustive-deps: 0*/
import { Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { DialogWarningMessage, TypographyTooltip } from "components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CCButton, CCDialogSimple, CCTypography } from "styles/src/components";
import { CAPITALIZE_TYPE, useIntl } from "utils/language";
import axios from "axios";
import { DBVendorContext } from "context/DBContext/components";

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: "732px",
      height: "850px",
      flexWrap: "nowrap"
    },
    table: {
      height: "100%",
      width: "50%",
      borderRight: `1px solid ${theme.palette.border.main}`,
      borderTop: `1px solid ${theme.palette.border.main}`
    },
    cell__header: {},
    cell__avatar: { padding: "8px 16px" },
    cell__description: {
      width: "calc(100% - 72px - 85px)",
      display: "flex",
      alignItems: "center"
    },
    cell__button: {
      display: "flex",
      alignItems: "center"
    },
    cell__specialties: {
      paddingLeft: "8px",
      color: theme.palette.input.main
    },
    cell: {
      display: "flex",
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    button__details: {
      height: "20px",
      width: "58px",
      padding: "0px",
      alignItems: "center",
      fontSize: "12px",
      zIndex: 10
    },
    introduction: {
      height: "115px",
      width: "100%",
      borderTop: `1px solid ${theme.palette.border.main}`,
      backgroundColor: theme.palette.background.default,
      padding: "16px"
    },
    introduction__text: {
      color: theme.palette.input.main
    },
    item__tab: {
      width: "100%",
      height: "calc(100% - 72px - 116px)"
    },
    item__tab__panel: {
      width: "100%",
      minHeight: "calc(100%  - 42px)",
      backgroundColor: theme.palette.background.default
    },
    table__container: {
      minHeight: "100%",
      width: "100%",
      backgroundColor: theme.palette.common.white
    },
    detail__cell: {
      height: "38px",
      width: "100%",
      margin: "1px 0px",
      backgroundColor: theme.palette.common.white
    },
    detail__thick__cell: {
      height: "50px",
      width: "100%",
      margin: 0,
      backgroundColor: theme.palette.common.white
    },
    detail__next: {
      height: "100%",
      paddingLeft: "32px",
      display: "flex",
      alignItems: "center"
    },
    detail__cell__left: {
      height: "100%",
      width: "187px",
      paddingLeft: "32px",
      display: "flex",
      alignItems: "center"
    },
    detail__cell__long___left: {
      height: "100%",
      width: "300px",
      paddingLeft: "32px",
      display: "flex",
      alignItems: "center",
      color: theme.palette.sub.main
    },
    detail__cell__right: {
      height: "100%",
      width: "calc(100% - 187px)",
      display: "flex",
      alignItems: "center"
    },
    detail__cell__header: {
      borderTop: `1px solid ${theme.palette.border.main}`,
      borderBottom: `1px solid ${theme.palette.border.main}`,
      margin: "0px"
    },
    container: {
      height: "100%",
      width: "100%",
      display: "flex",
      overflow: "auto",
      boxSizing: "border-box",
      borderTop: `1px solid ${theme.palette.border.main}`,
      backgroundColor: theme.palette.background.default,
      padding: 8
    },
    left__padding: {
      paddingLeft: 35
    }
  };
});

const SupplierDetailDialog = props => {
  const classes = useStyles();
  const intl = useIntl();
  const { open = false, onClose, id } = props;
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [detail, setDetail] = useState([]);
  const { dispatchdbVendor } = useContext(DBVendorContext);

  const fetchDetail = useCallback(async () => {
    try {
      // setLoading(true);
      if (id) {
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_PAM_URL}/api/v1/material/${id}`,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
          }
        })
          .then(response => {
            setDetail(response?.data);
          })
          .catch(err => {
            console.error(err);
          });
      }
    } catch (e) {
      console.error(e);
    }
  });

  const fetchConnect = useCallback(async () => {
    try {
      // setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_PAM_URL
        }/api/v1/clinic/${sessionStorage.getItem("clinicId")}/material/${id}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          if (response?.data?.Material) {
            setVendor(true);
          } else {
            setVendor(false);
          }

          dispatchdbVendor();
        })

        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
    // setLoading(false);
  }, [id]);

  const pamConnect = useCallback(async () => {
    try {
      axios({
        method: "POST",
        url: `${
          process.env.REACT_APP_PAM_URL
        }/api/v1/clinic/${sessionStorage.getItem("clinicId")}/material`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        },
        data: {
          materialId: id
        }
      })
        .then(() => {
          setVendor(true);
          dispatchdbVendor();
        })
        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  const pamCancel = useCallback(async () => {
    try {
      axios({
        method: "DELETE",
        url: `${
          process.env.REACT_APP_PAM_URL
        }/api/v1/clinic/${sessionStorage.getItem("clinicId")}/material/${id}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(() => {
          setVendor(false);
          dispatchdbVendor();
        })
        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error("Error for connect", e);
    }
  }, [detail]);

  const fetchImage = useCallback(async () => {
    try {
      // setLoading(true);
      window.open(
        `${process.env.REACT_APP_PAM_URL}/api/v1/material/${id}/image`,
        "_parent"
      );
    } catch (e) {
      console.error(e);
    }
    // setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchConnect();
    fetchDetail();
  }, []);

  return (
    <>
      {/*<DialogLoading*/}
      {/*  open={loading}*/}
      {/*  text={(() => {*/}
      {/*    if (loading) {*/}
      {/*      return intl.formatMessage(*/}
      {/*        { id: "checkingMsgWithName" },*/}
      {/*        {*/}
      {/*          name: intl.formatMessage({*/}
      {/*            id: "partner"*/}
      {/*          })*/}
      {/*        }*/}
      {/*      );*/}
      {/*    }*/}
      {/*  })()}*/}
      {/*/>*/}
      <CCDialogSimple
        maxWidth={"md"}
        open={open}
        title={intl.formatMessage(
          {
            id: "cleverPamPartnerInformation"
          },
          {},
          {
            capitalizeType: CAPITALIZE_TYPE.APA
          }
        )}
        contentsPadding={false}
        onClose={() => onClose()}
        onCloseButton={() => onClose()}
        contents={
          <Grid className={classes.root} container direction="column">
            <Grid item className={classes.left__padding}>
              <Grid container direction="row">
                <Grid className={classes.cell__description} item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <CCTypography variant="h5">
                            {detail?.name || ""}
                          </CCTypography>
                        </Grid>
                        <Grid className={classes.cell__specialties} item>
                          <CCTypography variant="h5" color="inherit">
                            {detail?.region || ""}
                          </CCTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <CCTypography>{detail?.shortIntro || ""}</CCTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.cell__button} item>
                  <CCButton
                    className={classes.button__details}
                    variant="contained"
                    size="small"
                    color={vendor ? "selected" : "normal"}
                    // disabled={vendor}
                    onClick={() => {
                      Boolean(vendor) ? pamCancel() : pamConnect();
                    }}
                  >
                    <TypographyTooltip color="inherit">
                      {Boolean(vendor)
                        ? intl.formatMessage({
                            id: "cancel"
                          })
                        : intl.formatMessage({ id: "registerPartner" })}
                    </TypographyTooltip>
                  </CCButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                className={classes.introduction}
                container
                direction="column"
              >
                <Grid item>
                  <CCTypography className={classes.introduction__text}>
                    {intl.formatMessage({
                      id: "introduce"
                    })}
                  </CCTypography>
                </Grid>
                <Grid item>
                  <CCTypography>{detail?.shortIntro || ""}</CCTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.item__tab} item>
              <Grid
                container
                direction="column"
                className={classes.item__tab__panel}
              >
                <Grid item>
                  <Grid
                    className={clsx(classes.detail__thick__cell)}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__next} item>
                      <TypographyTooltip variant="h5">
                        {intl.formatMessage({
                          id: "sellingProductInfo"
                        })}
                      </TypographyTooltip>
                    </Grid>
                    <Grid className={classes.detail__next} item>
                      <Grid className={classes.cell__button} item>
                        <CCButton
                          variant="contained"
                          size="small"
                          color={"normal"}
                          onClick={() => {
                            fetchImage();
                          }}
                        >
                          <TypographyTooltip color="inherit">
                            {intl.formatMessage({
                              id: "fileDownload"
                            })}
                          </TypographyTooltip>
                        </CCButton>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TypographyTooltip
                        variant="h6"
                        className={classes.detail__cell__long___left}
                      >
                        {intl.formatMessage({
                          id: "fileUploadedbyVendor"
                        })}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={clsx(
                      classes.detail__cell,
                      classes.detail__cell__header
                    )}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip variant="h5">
                        {intl.formatMessage(
                          {
                            id: "businessInfo"
                          },
                          {},
                          {
                            capitalizeType: CAPITALIZE_TYPE.APA
                          }
                        )}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.detail__cell}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip>
                        {intl.formatMessage(
                          {
                            id: "supplierName"
                          },
                          {},
                          {
                            capitalizeType: CAPITALIZE_TYPE.APA
                          }
                        )}
                      </TypographyTooltip>
                    </Grid>
                    <Grid className={classes.detail__cell__right} item>
                      <TypographyTooltip>
                        {detail?.name || ""}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.detail__cell}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip>
                        {intl.formatMessage(
                          {
                            id: "managerName"
                          },
                          {},
                          {
                            capitalizeType: CAPITALIZE_TYPE.APA
                          }
                        )}
                      </TypographyTooltip>
                    </Grid>
                    <Grid className={classes.detail__cell__right} item>
                      <TypographyTooltip>
                        {detail?.representation || ""}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.detail__cell}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip>
                        {intl.formatMessage({
                          id: "address"
                        })}
                      </TypographyTooltip>
                    </Grid>
                    <Grid className={classes.detail__cell__right} item>
                      <TypographyTooltip>
                        {detail?.region || ""}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.detail__cell}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip>Business ID</TypographyTooltip>
                    </Grid>
                    <Grid className={classes.detail__cell__right} item>
                      <TypographyTooltip>{detail?.id || ""}</TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={clsx(
                      classes.detail__cell,
                      classes.detail__cell__header
                    )}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip variant="h5">
                        {intl.formatMessage(
                          {
                            id: "operatingDetails"
                          },
                          {},
                          {
                            capitalizeType: CAPITALIZE_TYPE.APA
                          }
                        )}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.detail__cell}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip>
                        {intl.formatMessage(
                          {
                            id: "workingHours"
                          },
                          {},
                          {
                            capitalizeType: CAPITALIZE_TYPE.APA
                          }
                        )}
                      </TypographyTooltip>
                    </Grid>
                    <Grid className={classes.detail__cell__right} item>
                      <TypographyTooltip>
                        {String(detail?.operatingStart)?.slice(-2, ":")} ~{" "}
                        {String(detail?.operatingEnd)?.slice(-2, ":")}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.detail__cell}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip>
                        {intl.formatMessage(
                          {
                            id: "workingDays"
                          },
                          {},
                          {
                            capitalizeType: CAPITALIZE_TYPE.APA
                          }
                        )}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.detail__cell}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip>
                        {intl.formatMessage(
                          {
                            id: "phoneNumber"
                          },
                          {},
                          {
                            capitalizeType: CAPITALIZE_TYPE.APA
                          }
                        )}
                      </TypographyTooltip>
                    </Grid>
                    <Grid className={classes.detail__cell__right} item>
                      <TypographyTooltip>
                        {detail?.phone || ""}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.detail__cell}
                    container
                    direction="row"
                  >
                    <Grid className={classes.detail__cell__left} item>
                      <TypographyTooltip>
                        {intl.formatMessage(
                          {
                            id: "availableAreas"
                          },
                          {},
                          {
                            capitalizeType: CAPITALIZE_TYPE.APA
                          }
                        )}
                      </TypographyTooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <DialogWarningMessage
        open={openCancelDialog}
        title={intl.formatMessage({
          id: "cancelConfirmation"
        })}
        onAgree={() => {
          setOpenCancelDialog(false);
        }}
        onDisagree={() => setOpenCancelDialog(false)}
        onClose={() => setOpenCancelDialog(false)}
      />
    </>
  );
};

export default SupplierDetailDialog;
