import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import ContractCheck from "../ContractCheck";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import {
  CCLabelCheckBox,
  CCPaper
} from "styles/src/components";
import CCDialogContent from "styles/src/components/CCDialogContent";
import CCTypography from "styles/src/components/CCTypography";
import { useIntl } from "utils/language";

const useStyle = makeStyles(theme => ({
  root: {},
  borderBottomContent: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  borderRightContent: {
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  width100Per: {
    width: "100%"
  },
  width50Per: {
    width: "50%"
  },
  paddingLeftRight16: {
    paddingLeft: 16,
    paddingRight: 16
  },
  paddingBottom16: {
    paddingBottom: 16
  },
  paddingBottom8: {
    paddingBottom: 8
  },
  paddingTopBottom8: {
    paddingTop: 8,
    paddingBottom: 8
  },
  paddingTopBottom0: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  },
  padding16: {
    padding: 16
  }
}));



const ContractAssign = props => {
  const {
    handleAcceptConditionsAndTerms,
    checked
  } = props;
  const classes = useStyle();
  const theme = useTheme();
  const intl = useIntl();

  const listContract = () => {
    let contract = {};
    if (process.env.REACT_APP_LANGUAGE_REGION) {
      switch (process.env.REACT_APP_LANGUAGE_REGION) {
        case "vi":
          contract = {
            termsOfUse: `/PDF/contract/Confidential_1.Vatech Clever Lab Terms of use_Viet.pdf`,
            applicationForm: `/PDF/contract/Confidential_2.Vatech Clever Lab Application form_Viet.pdf`,
            personalInformationPolicy: `/PDF/contract/Confidential_3.Vatech Clever Lab Processing of Personal Information Policy_Viet.pdf`,
            usageAgreement: `/PDF/contract/Confidential_4.Vatech Clever Lab Personal information collection and usage agreement_Viet.pdf`,
            personalInformationCollected: `/PDF/contract/Confidential_5.Vatech Clever Lab Consignment of personal information collected_Viet.pdf`,
          };
          break
        default:
          contract = {
            termsOfUse: `/PDF/contract/Document_Being_Prepared.pdf`,
            applicationForm: `/PDF/contract/Document_Being_Prepared.pdf`,
            personalInformationPolicy: `/PDF/contract/Document_Being_Prepared.pdf`,
            usageAgreement: `/PDF/contract/Document_Being_Prepared.pdf`,
            personalInformationCollected: `/PDF/contract/Document_Being_Prepared.pdf`,
          };

      }
    }
    return contract
  }

  const contractsDefault = [
    {
      checked,
      title: `1. ${intl.formatMessage({
        id: "termsOfUse"
      })}(${intl.formatMessage({ id: "required" })})`,
      required: true,
      contract: listContract().termsOfUse
    },
    {
      checked,
      title: `2. ${intl.formatMessage({
        id: "applicationForm"
      })}(${intl.formatMessage({ id: "required" })})`,
      required: true,
      contract: listContract().applicationForm,
    },
    {
      checked,
      title: `3. ${intl.formatMessage({
        id: "personalInformationPolicy"
      })}(${intl.formatMessage({ id: "required" })})`,
      required: true,
      contract: listContract().personalInformationPolicy,
    },
    {
      checked,
      title: `4. ${intl.formatMessage({
        id: "usageAgreement"
      })}(${intl.formatMessage({ id: "required" })})`,
      required: true,
      contract: listContract().usageAgreement,
    },
    {
      checked,
      title: `5. ${intl.formatMessage({
        id: "personalInformationCollected"
      })}(${intl.formatMessage({ id: "required" })})`,
      required: true,
      contract: listContract().personalInformationCollected,
      needMigration: true
    }
  ]

  const [selectedContract, setSelectedContract] = useState(false)
  const [contracts, setContracts] = useState(contractsDefault)

  const onOpenContract = (contract) => {
    setSelectedContract(contract);
  }
  const onChange = (newContracts) => {
    setContracts(newContracts)
    handleAcceptConditionsAndTerms(!newContracts.some(item => item.checked === false))
  }

  return (
    <>
      <CCDialogContent className={classes.borderBottomContent}>
        <CCLabelCheckBox
          color={"secondary"}
          checked={!contracts.some(item => item.checked === false)}
          label={
            <CCTypography variant={"h5"}>
              {intl.formatMessage({ id: "acceptAllTerms" })}
            </CCTypography>
          }
          onChange={e => {
            onChange(
              contracts.map(item => {
                return {
                  ...item,
                  checked: e.target.checked
                };
              })
            );
          }}
        />
      </CCDialogContent>
      <CCDialogContent noPadding>
        <Grid container>
          <Grid item className={clsx(classes.padding16, classes.width50Per)}>
            <Grid
              item
              className={clsx(classes.width100Per, classes.paddingBottom8)}
            >
              {contracts.map((item, index) => {
                if (index >= contracts.length / 2) {
                  return undefined;
                }
                return (
                  <ContractCheck
                    {...item}
                    key={index}
                    index={index}
                    onOpenContract={onOpenContract}
                    onChange={e => {
                      onChange &&
                        onChange(
                          contracts.map((item, contractIndex) => {
                            return {
                              ...item,
                              checked:
                                contractIndex === index
                                  ? e.target.checked
                                  : item.checked
                            };
                          })
                        );
                    }}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid item className={clsx(classes.padding16, classes.width50Per)}>
            <Grid
              item
              className={clsx(classes.width100Per, classes.paddingBottom8)}
            >
              {contracts.map((item, index) => {
                if (index < contracts.length / 2) {
                  return undefined;
                }
                return (
                  <ContractCheck
                    {...item}
                    key={index}
                    index={index}
                    onOpenContract={onOpenContract}
                    onChange={e => {
                      onChange &&
                        onChange(
                          contracts.map((item, contractIndex) => {
                            return {
                              ...item,
                              checked:
                                contractIndex === index
                                  ? e.target.checked
                                  : item.checked
                            };
                          })
                        );

                    }}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </CCDialogContent>
      <CCDialogContent style={{ height: "calc(100vh - 535px)" }}>
        <CCPaper style={{ height: "99%" }}>
          {selectedContract && (
            <Iframe width={"100%"} height={"100%"} url={selectedContract} />
          )}
          {!selectedContract && (
            <Grid
              container
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: theme.palette.sub.mainHex
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CCTypography variant={"body1"}>
                {intl.formatMessage({ id: "noSelectPayment" })}
              </CCTypography>
            </Grid>
          )}
        </CCPaper>
      </CCDialogContent>

    </>
  );
};
export default ContractAssign;
