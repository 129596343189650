import React from "react";

const ClaimexcludedIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M4.856 3.545l14.849 14.85-1.06 1.06-1.09-1.09c-2.45 2.177-6.23 3.449-11.413 2.046l-.356-.1.553-2.688C4.213 16.052 3.02 13.67 3 11.338c-.018-2.09.783-3.97 2.114-5.413l-1.319-1.32 1.06-1.06zM11.929 3c5.005-.044 9.03 3.578 9.07 8.198.014 1.48-.497 3.284-1.551 4.94l-3.465-3.467c.346-.698.523-1.44.517-2.157-.018-2.112-1.428-3.023-2.477-3.014-1.47.013-2.025 1.115-2.025 1.115s-.284-.464-.853-.781L7.441 4.13c1.323-.709 2.858-1.116 4.488-1.13zM7.5 10.496c.015 1.81 1.484 4.03 4.825 4.157l.282.006.022.941c.526-.194 1.002-.443 1.427-.735l-6.091-6.09c-.307.511-.47 1.124-.465 1.721z"
      />
      <path d="M0 0H24V24H0z" transform="matrix(0 1 1 0 0 0)" />
    </g>
  </svg>
);
export default ClaimexcludedIcon;
