import { useQuery } from "@apollo/react-hooks";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { InfiniteTable, translate } from "components";
import { GOLD_UNIT_PRICE } from "queries/goldManagement";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CellMeasurerCache } from "react-virtualized";
import { CCIconButton, CCTypography } from "styles/components";
import { AgreementIcon } from "styles/icons";
import LabHistoryModal from "../LabHistoryModal";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: 690,
    "& .ReactVirtualized__List": {
      overflowY: "overlay !important"
    }
  },
  header: {
    position: "absolute",
    display: "flex",
    zIndex: "10",
    width: "100%",
    height: 72,
    textAlign: "center"
  },
  header__cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box"
  },
  sub__header: {
    flexBasis: "20%"
  },
  fullwidth: {
    width: "100%"
  },
  halfheight: {
    height: "50%"
  },
  goldused__container: {
    display: "flex",
    flexDirection: "column"
  },
  goldused__items: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  border__cell__left: {
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  border__cell__top: {
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  border__cell__bottom: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__content: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 32
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 36
  },
  usage_price: {
    marginLeft: 8,
    color: "rgba(0, 0, 0, 0.54)"
  },
  history__button: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  color__green: {
    color: "#00897b"
  }
}));

const ManageGoldTable = props => {
  const { month, year, goldLabList } = props;

  const intl = useIntl();
  const classes = useStyles();

  const [header, setHeader] = useState({});
  const [labHistoryModalVisible, setLabHistoryModalVisible] = useState(false);
  const [cache] = useState(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true
    })
  );
  const [selectedClinic, setSelectedClinic] = useState("");
  const [goldUnitPrices, setGoldUnitPrices] = useState({
    inlay: 0,
    aType: 0,
    sA: 0,
    pt: 0,
    pfg: 0
  });

  const { data: goldUnitPriceData, loading: goldUnitPriceLoading } = useQuery(
    GOLD_UNIT_PRICE,
    {
      variables: { query: { importDate: {} } }
    }
  );

  useEffect(() => {
    // setting data: list of clinic's gold usage
    if (!goldUnitPriceLoading && goldUnitPriceData) {
      setGoldUnitPrices(goldUnitPriceData.goldUnitPriceManageDetail);
    }
  }, [goldUnitPriceData, goldUnitPriceLoading]);

  const heads = [
    {
      key: "no",
      width: 70,
      component: ({ rowIndex }) => {
        return <Box className={classes.box}>{rowIndex + 1}</Box>;
      }
    },
    {
      key: "clinic",
      width: 194,
      component: ({ cellData }) => {
        return (
          <Box className={classes.box}>
            <CCTypography
              className={clsx({
                [classes.color__green]:
                  cellData?.createdBy === "Clever" ? true : false
              })}
            >
              {cellData ? cellData.name : "-"}
            </CCTypography>
            {/* <CCIconButton color={"primary"}>
              <CommitmentIcon />
            </CCIconButton> */}
          </Box>
        );
      }
    },
    {
      key: "inlay",
      component: ({ cellData, rowData }) => {
        return (
          <Box className={clsx(classes.border__cell__left, classes.box)}>
            <CCTypography component="span">
              {cellData ? cellData : "-"}
            </CCTypography>
            <CCTypography compoPnent="span" className={classes.usage_price}>
              {rowData.monthlyGold["inlay"]
                ? `(${rowData.monthlyGold["inlay"]} ${translate(intl, "common.ui.localizedCurrencySymbol")})`
                : null}
            </CCTypography>
          </Box>
        );
      }
    },
    {
      key: "aType",
      component: ({ cellData, rowData }) => (
        <Box className={classes.box}>
          <CCTypography component="span">
            {cellData ? cellData : "-"}
          </CCTypography>
          <CCTypography component="span" className={classes.usage_price}>
            {rowData.monthlyGold["aType"]
              ? `(${rowData.monthlyGold["aType"]} ${translate(intl, "common.ui.localizedCurrencySymbol")})`
              : null}
          </CCTypography>
        </Box>
      )
    },
    {
      key: "sA",
      component: ({ cellData, rowData }) => {
        return (
          <Box className={classes.box}>
            <CCTypography component="span">
              {cellData ? cellData : "-"}
            </CCTypography>
            <CCTypography component="span" className={classes.usage_price}>
              {rowData.monthlyGold["sA"]
                ? `(${rowData.monthlyGold["sA"]} ${translate(intl, "common.ui.localizedCurrencySymbol")})`
                : null}
            </CCTypography>
          </Box>
        );
      }
    },
    {
      key: "pt",
      component: ({ cellData, rowData }) => {
        return (
          <Box className={classes.box}>
            <CCTypography component="span">
              {cellData ? cellData : "-"}
            </CCTypography>
            <CCTypography component="span" className={classes.usage_price}>
              {rowData.monthlyGold["pt"]
                ? `(${rowData.monthlyGold["pt"]} ${translate(intl, "common.ui.localizedCurrencySymbol")})`
                : null}
            </CCTypography>
          </Box>
        );
      }
    },
    {
      key: "pfg",
      component: ({ cellData, rowData }) => {
        return (
          <Box className={classes.box}>
            <CCTypography component="span">
              {cellData ? cellData : "-"}
            </CCTypography>
            <CCTypography component="span" className={classes.usage_price}>
              {rowData.monthlyGold["pfg"]
                ? `(${rowData.monthlyGold["pfg"]} ${translate(intl, "common.ui.localizedCurrencySymbol")})`
                : null}
            </CCTypography>
          </Box>
        );
      }
    },
    {
      width: 154,
      key: "charged",
      component: ({ cellData }) => (
        <Box className={clsx(classes.border__cell__left, classes.box)}>
          {cellData ? cellData : "-"}
        </Box>
      )
    },
    {
      width: 76,
      key: "history",
      component: ({ rowData }) => {
        return (
          <Box className={clsx(classes.box, classes.border__cell__left)}>
            <CCIconButton
              className={classes.history__button}
              onClick={() => {
                setSelectedClinic(rowData.clinic.id);
                setLabHistoryModalVisible(true);
              }}
            >
              <AgreementIcon />
            </CCIconButton>
          </Box>
        );
      }
    }
  ];

  useEffect(() => {
    const normalizeHeader = headers => {
      const headerObj = {};
      headers.forEach(header => {
        headerObj[header.key] = header;
      });
      return headerObj;
    };

    setHeader(normalizeHeader(heads));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box
          style={{
            flex: header["no"] ? `0 0 ${header["no"]["width"]}px` : "none"
          }}
          className={clsx(classes.header__cell, classes.border__cell__top)}
        >
          {translate(intl, "labManageGold.no")}
        </Box>
        <Box
          style={{
            flex: header["clinic"]
              ? `0 0 ${header["clinic"]["width"]}px`
              : "none"
          }}
          className={clsx(classes.header__cell, classes.border__cell__top)}
        >
          {translate(intl, "labManageGold.clinic")}
        </Box>
        <Box
          className={clsx(
            classes.header__cell,
            classes.fullwidth,
            classes.goldused__container,
            classes.border__cell__top,
            classes.border__cell__left
          )}
        >
          <Box
            className={clsx(
              classes.header__cell,
              classes.border__cell__bottom,
              classes.fullwidth,
              classes.halfheight
            )}
          >
            {translate(intl, "labManageGold.goldUsedG")}
          </Box>
          <Box
            className={clsx(
              classes.goldused__items,
              classes.fullwidth,
              classes.halfheight
            )}
          >
            <Box className={classes.sub__header}>
              Inlay ({goldUnitPrices?.inlay ? goldUnitPrices.inlay : 0}{" "}
              {translate(intl, "common.ui.currencyPerGram")})
            </Box>
            <Box className={classes.sub__header}>
              A-Type ({goldUnitPrices?.aType ? goldUnitPrices.aType : 0}{" "}
              {translate(intl, "common.ui.currencyPerGram")})
            </Box>
            <Box className={classes.sub__header}>
              S-A ({goldUnitPrices?.sA ? goldUnitPrices.sA : 0}{" "}
              {translate(intl, "common.ui.currencyPerGram")})
            </Box>
            <Box className={classes.sub__header}>
              PT ({goldUnitPrices?.pt ? goldUnitPrices.pt : 0}{" "}
              {translate(intl, "common.ui.currencyPerGram")})
            </Box>
            <Box className={classes.sub__header}>
              PFG ({goldUnitPrices?.pfg ? goldUnitPrices.pfg : 0}{" "}
              {translate(intl, "common.ui.currencyPerGram")})
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            flex: header["charged"]
              ? `0 0 ${header["charged"]["width"]}px`
              : "none"
          }}
          className={clsx(
            classes.header__cell,
            classes.border__cell__top,
            classes.border__cell__left
          )}
        >
          <CCTypography>
            {translate(intl, "labManageGold.charged")}
          </CCTypography>
        </Box>
        <Box
          style={{
            flex: header["history"]
              ? `0 0 ${header["history"]["width"]}px`
              : "none"
          }}
          className={clsx(
            classes.header__cell,
            classes.border__cell__top,
            classes.border__cell__left
          )}
        >
          <CCTypography>
            {translate(intl, "labManageGold.history")}
          </CCTypography>
        </Box>
      </Box>
      <Box className={classes.table__content}>
        <InfiniteTable
          className={classes.table}
          heads={heads}
          contents={goldLabList}
          rowClickHighlight={true}
          cache={cache}
        />
      </Box>
      {labHistoryModalVisible && (
        <LabHistoryModal
          open={labHistoryModalVisible}
          clinicId={selectedClinic}
          month={month}
          year={year}
          onClose={() => setLabHistoryModalVisible(false)}
        />
      )}
    </Box>
  );
};

export default ManageGoldTable;
