// import { useMutation } from '@apollo/react-hooks';
import Box from "@material-ui/core/Box";
import { useSearchForExplorer } from "components/Explorer/util";
import { Loading } from "components/index";
import gql from "graphql-tag";
import moment from "moment";
// import { useSnackbar } from 'notistack';
// import { REMOVE_RESERVATION, UPDATE_RESERVATION } from "queries/reservation";
import React, { useEffect, useReducer, useRef, useState } from "react";
import RESERVATION from "types/reservation";
import { FilterCollapse, ResultList } from "./component";

const SEARCH_RESERVATION_FOR_EXPLORER_PAGINATION = gql`
  query searchReservationForExplorerPagination(
    $args: InputSearchReservation
    $comparison: String
    $hospitalId: String!
    $match: String
    $page: InputPage
  ) {
    content: searchReservationPagination(
      args: $args
      comparison: $comparison
      hospitalId: $hospitalId
      match: $match
      page: $page
    ) {
      data {
        ... on RetReservation {
          id
          hospitalId
          dateBegin
          dateEnd
          status
          phoneNumber
          content {
            ... on RetReservationContent {
              patient {
                ... on RetReservationPatient {
                  oralExam
                  newOrExistingPatient
                  name
                  doctor {
                    ... on RetStaff {
                      id
                      name
                    }
                  }
                  staff {
                    ... on RetStaff {
                      id
                      name
                    }
                  }
                  next {
                    ... on RetReservationNext {
                      cc
                      selectedTeeth
                    }
                  }
                  patient {
                    ... on RetPatient {
                      id
                      name
                      chartNumber
                      residentRegistrationNumber
                      cellphoneNumber
                    }
                  }
                }
              }
            }
          }
        }
      }
      lastKey
    }
  }
`;

const SEARCH_RESERVATION_FOR_EXPLORER = gql`
  query searchReservationForExplorer(
    $args: InputSearchPatient
    $comparison: String
    $hospitalId: String!
    $match: String
    $subArgs: InputSearchReservation
  ) {
    content: searchPatient(
      args: $args
      comparison: $comparison
      hospitalId: $hospitalId
      match: $match
    ) {
      ... on RetPatient {
        id
        name
        chartNumber
        residentRegistrationNumber
        cellphoneNumber
        reservation(args: $subArgs) {
          ... on RetReservation {
            id
            hospitalId
            dateBegin
            dateEnd
            status
            phoneNumber
            content {
              ... on RetReservationContent {
                patient {
                  ... on RetReservationPatient {
                    oralExam
                    newOrExistingPatient
                    name
                    doctor {
                      ... on RetStaff {
                        id
                        name
                      }
                    }
                    staff {
                      ... on RetStaff {
                        id
                        name
                      }
                    }
                    next {
                      ... on RetReservationNext {
                        cc
                        selectedTeeth
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const adaptData = list =>
  list.reduce((acc, cur) => {
    return acc.concat(
      cur?.reservation?.map &&
        cur.reservation.map(item => {
          return {
            ...item,
            content: {
              ...item?.content,
              patient: {
                ...item?.content?.patient,
                patient: {
                  id: cur.id,
                  name: cur.name,
                  chartNumber: cur.chartNumber,
                  residentRegistrationNumber: cur.residentRegistrationNumber,
                  cellphoneNumber: cur.cellphoneNumber
                }
              }
            }
          };
        })
    );
  }, []);

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return { ...state, filter: { ...action.target } };
    case "addPatientFilter":
      return {
        ...state,
        filter: {
          ...state.filter,
          args: {
            ...state?.filter?.args,
            id: action.target
          }
        }
      };
    case "removePatientFilter":
      let _state = { ...state };
      if (_state?.filter?.args?.id) {
        delete _state.filter.args.id;
      }
      return _state;
    case "setOpenDeleteWarning":
      return {
        ...state,
        openDeleteWarning: action.target
      };
    default:
      break;
  }
  return state;
};
const initializer = props => {
  return {
    ...props,
    filter: {
      args: {},
      subArgs: {
        type: RESERVATION.TYPE.PATIENT.value,
        dateBegin: {
          begin:
            props?.filter?.dateBegin?.begin || moment().startOf("day").unix(),
          end: props?.filter?.dateBegin?.end || moment().endOf("day").unix()
        },
        dateEnd: {
          begin:
            props?.filter?.dateBegin?.begin || moment().startOf("day").unix(),
          end: props?.filter?.dateBegin?.end || moment().endOf("day").unix()
        }
      },
      ...props.filter
    },
    openDeleteWarning: null
  };
};

const Reservation = props => {
  const { patient } = props;
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const [list, setList] = useState([]);
  // const [openDeleteWarning, setOpenDeleteWarning] = useState();
  const collapseRef = useRef();
  const {
    response,
    loading,
    nextPage,
    hasNext
    // fetchInit,
  } = useSearchForExplorer(
    state?.filter?.args,
    state.filter?.subArgs,
    SEARCH_RESERVATION_FOR_EXPLORER,
    SEARCH_RESERVATION_FOR_EXPLORER_PAGINATION,
    adaptData,
    null,
    (a, b) => b.dateBegin - a.dateBegin,
    100
  );
  // const [updateReservation] = useMutation(UPDATE_RESERVATION, {
  //   onCompleted: data => {
  //     fetchInit();
  //     enqueueToast(enqueueSnackbar, "예약이 변경 되었습니다.");
  //   },
  //   onError: error => {
  //     enqueueToast(enqueueSnackbar, "예약 변경이 실패하였습니다.");
  //   }
  // });

  // const [removeReservation] = useMutation(REMOVE_RESERVATION, {
  //   onCompleted: data => {
  //     fetchInit();
  //     enqueueToast(enqueueSnackbar, "예약이 삭제 되었습니다.");
  //     dispatchState({ type: "setOpenDeleteWarning", target: null });
  //   },
  //   onError: error => {
  //     enqueueToast(enqueueSnackbar, "예약 삭제가 실패하였습니다.");
  //     dispatchState({ type: "setOpenDeleteWarning", target: null });
  //   }
  // });

  useEffect(() => {
    if (response?.length >= 0) {
      // console.log("length", response.length);
      setList([...response]);
    }
  }, [response]);

  useEffect(() => {
    if (patient?.id) {
      dispatchState({
        type: "addPatientFilter",
        target: patient.id
      });
    } else {
      dispatchState({
        type: "removePatientFilter"
      });
    }
  }, [patient]);

  return (
    <Box style={{ height: "100%", overflowY: "hidden", position: "relative" }}>
      <Box style={{ height: "100%", minWidth: 1700 }}>
        <Loading open={loading} msg={"Loading..."} />
        <FilterCollapse
          ref={collapseRef}
          collapse={props.collapse}
          filter={state.filter}
          onChange={e => {
            dispatchState({ type: "filter", target: { ...e } });
          }}
        />
        <ResultList
          list={list?.length > 0 ? list : []}
          collapseRef={collapseRef}
          collapse={props.collapse}
          hasNext={Boolean(hasNext !== null)}
          onChange={e => {
            // updateReservation({
            //   variables: {
            //     ...e,
            //     dateEnd: moment(e.dateBegin, "X")
            //       .add(e.content.patient.timeSpent, "minutes")
            //       .unix()
            //   }
            // });
          }}
          onDelete={e => {
            // removeReservation({
            //   variables: {
            //     hospitalId: e?.hospitalId,
            //     id: e?.id
            //   }
            // });
          }}
          onMoreContents={() => {
            nextPage && nextPage();
          }}
        />
      </Box>
      {/*{Boolean(state?.openDeleteWarning?.id) && removeReservation && (*/}
      {/*<DialogDelete*/}
      {/*  permission={"reservation"}*/}
      {/*  open={Boolean(state?.openDeleteWarning?.id)}*/}
      {/*  onAgree={() => {*/}
      {/*    console.log("delete");*/}
      {/*    // removeReservation({*/}
      {/*    //   variables: {*/}
      {/*    //     hospitalId: state?.openDeleteWarning?.hospitalId,*/}
      {/*    //     id: state?.openDeleteWarning?.id*/}
      {/*    //   }*/}
      {/*    // });*/}
      {/*  }}*/}
      {/*  onDisagree={() => {*/}
      {/*    console.log("onDisagree");*/}
      {/*    // dispatchState({ type: "setOpenDeleteWarning", target: null });*/}
      {/*  }}*/}
      {/*  onClose={() => {*/}
      {/*    console.log("onClose");*/}
      {/*    // dispatchState({ type: "setOpenDeleteWarning", target: null });*/}
      {/*  }}*/}
      {/*/>*/}
      {/*)}*/}
    </Box>
  );
};

export default Reservation;
