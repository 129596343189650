/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { Box, Grid } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Image, Layer, Rect, Stage, Text } from 'react-konva';
import {
    CCButton,
    CCDialogSimple,
    CCLabelCheckBox,
    CCPaper,
    CCTable,
    CCTypography
} from 'styles/components';
import { TakeinIcon } from 'styles/icons';
import useImage from 'use-image';

const tableHeight = 438;
const toolbarHeight = 84;
const stageWidth = 320;
const stageHeight = tableHeight - toolbarHeight;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden',
    },
  },
  table__paper: {
    height: '100%',
    width: '180px',
  },
  table__title: {
    padding: '8px 16px',
  },
  table__contents: {
    width: '100%',
    height: tableHeight,
    padding: 0,
  },
  table__cell: {
    display: 'flex',
    justifyContent: 'center',
  },
  preview__paper: {
    height: '100%',
    width: '320px',
    marginLeft: '8px',
  },
  preview__title: {
    padding: '8px 16px',
  },
  preview__contents: {
    width: '100%',
    height: tableHeight,
    padding: 0,
  },
  preview__toolbar: {
    width: '100%',
    height: toolbarHeight,
    padding: '0px 8px 0px 8px',
  },
  preview__button: {
    margin: '0px 4px 0px 4px',
  },
}));

const toothList = {
  anterior: '/implants/implant-anterior.svg',
  posterior: '/implants/implant-posterior.svg',
};

const implantList = [
  {
    diameter: 2,
    length: 8.5,
    source: '/implants/implant-20-x-85.svg',
    tooltip: '2.0 x 8.5',
  },
  {
    diameter: 2,
    length: 10,
    source: '/implants/implant-20-x-100.svg',
    tooltip: '2.0 x 10',
  },
  {
    diameter: 2,
    length: 11.5,
    source: '/implants/implant-20-x-115.svg',
    tooltip: '2.0 x 11.5',
  },
  {
    diameter: 3.5,
    length: 8.5,
    source: '/implants/implant-35-x-85.svg',
    tooltip: '3.5 x 8.5',
  },
  {
    diameter: 3.5,
    length: 10,
    source: '/implants/implant-35-x-100.svg',
    tooltip: '3.5 x 10',
  },
  {
    diameter: 3.5,
    length: 11.5,
    source: '/implants/implant-35-x-115.svg',
    tooltip: '3.5 x 11.5',
  },
  {
    diameter: 3.5,
    length: 13,
    source: '/implants/implant-35-x-130.svg',
    tooltip: '3.5 x 13',
  },
  {
    diameter: 4.5,
    length: 7,
    source: '/implants/implant-45-x-70.svg',
    tooltip: '4.5 x 7',
  },
  {
    diameter: 4.5,
    length: 8.5,
    source: '/implants/implant-45-x-85.svg',
    tooltip: '4.5 x 8.5',
  },
  {
    diameter: 4.5,
    length: 10,
    source: '/implants/implant-45-x-100.svg',
    tooltip: '4.5 x 10',
  },
  {
    diameter: 4.5,
    length: 11.5,
    source: '/implants/implant-45-x-115.svg',
    tooltip: '4.5 x 11.5',
  },
  {
    diameter: 4.5,
    length: 13,
    source: '/implants/implant-45-x-130.svg',
    tooltip: '4.5 x 13',
  },
];

const toothImageWidth = 74;
const toothImageHeight = 56;
const implantImageWidth = 74;
const implantImageHeight = 116;
const implantLengthMax = 13;

const ImplantTable = (props) => {
  const { classes, target, onClick } = props;

  return (
    <CCPaper className={classes.table__paper}>
      <Grid container direction='column'>
        <Grid item className={classes.table__title}>
          <CCTypography variant='h4'>임플란트 정보</CCTypography>
        </Grid>
        <Grid item className={classes.table__contents}>
          <CCTable
            heads={[
              {
                key: 'diameter',
                label: '직경',
                className: classes.table__cell,
                component: ({ cellData, rowIndex }) => {
                  return (
                    <Box className={classes.table__cell}>
                      <CCTypography>{cellData}</CCTypography>
                    </Box>
                  );
                },
              },
              {
                key: 'length',
                label: '길이',
                className: classes.table__cell,
                component: ({ cellData, rowIndex }) => {
                  return (
                    <Box className={classes.table__cell}>
                      <CCTypography>{cellData}</CCTypography>
                    </Box>
                  );
                },
              },
            ]}
            contents={implantList}
            onRowClick={({ rowIndex }) => {
              onClick && onClick(rowIndex);
            }}
            selectedRow={target}
          />
        </Grid>
      </Grid>
    </CCPaper>
  );
};

const ImplantPreview = (props) => {
  const {
    classes,
    crownVisible,
    tooltipVisible,
    toothRegion,
    imageFlip,
    implantIndex,
    onClick,
  } = props;
  const [toothImage] = useImage(toothList[toothRegion]);
  const [implantImage] = useImage(implantList[implantIndex].source);
  const [flipImage] = useImage('/contents/flipup.svg');
  const [crownType, setCrownType] = useState('anterior');

  return (
    <CCPaper className={classes.preview__paper}>
      <Grid container direction='column'>
        <Grid item className={classes.preview__title}>
          <CCTypography variant='h4'>미리보기</CCTypography>
        </Grid>
        <Grid item className={classes.preview__contents}>
          <Box display='flex' flexDirection='column' justifyContent='center'>
            <Box className={classes.preview__toolbar}>
              <CCLabelCheckBox
                color='secondary'
                label='크라운 표시'
                checked={crownVisible}
                onChange={() => {
                  onClick && onClick('crown');
                }}
              ></CCLabelCheckBox>
              <ToggleButtonGroup
                value={crownType}
                size='small'
                exclusive
                onChange={(e, v) => {
                  onClick && onClick(v);

                  setCrownType(v);
                }}
                aria-label='tooth region'
              >
                <ToggleButton value='anterior' aria-label='anterior'>
                  전치부
                </ToggleButton>
                <ToggleButton value='posterior' aria-label='posterior'>
                  구치부
                </ToggleButton>
              </ToggleButtonGroup>
              <CCLabelCheckBox
                color='secondary'
                label='임플란트 직경, 길이 정보 표시'
                checked={tooltipVisible}
                onChange={() => {
                  onClick && onClick('tooltip');
                }}
              ></CCLabelCheckBox>
            </Box>
            <Box>
              <Stage width={stageWidth} height={stageHeight}>
                <Layer
                  offsetY={imageFlip ? stageHeight : 0}
                  scaleY={imageFlip ? -1 : 1}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={stageWidth}
                    height={stageHeight}
                    fill='black'
                    cornerRadius={6}
                  ></Rect>
                  {crownVisible && (
                    <Image
                      x={104}
                      y={30}
                      width={toothImageWidth * 1.5}
                      height={toothImageHeight * 1.5}
                      image={toothImage}
                    ></Image>
                  )}
                  <Image
                    x={104}
                    y={30 + toothImageHeight * 1.5}
                    width={implantImageWidth * 1.5}
                    height={implantImageHeight * 1.5}
                    image={implantImage}
                  ></Image>
                </Layer>
                <Layer>
                  {tooltipVisible && (
                    <Text
                      x={104}
                      y={
                        imageFlip
                          ? 30
                          : 30 + (toothImageHeight + implantImageHeight) * 1.5
                      }
                      width={implantImageWidth * 1.5}
                      height={24 * 1.5}
                      text={implantList[implantIndex].tooltip}
                      fontSize={12}
                      fontStyle='bold'
                      fill='yellow'
                      align='center'
                      verticalAlign='middle'
                    ></Text>
                  )}
                  <Image
                    x={stageWidth - 50}
                    y={stageHeight - 50}
                    width={42}
                    height={42}
                    image={flipImage}
                    onClick={(e) => {
                      onClick && onClick('flip');
                    }}
                  ></Image>
                </Layer>
              </Stage>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CCPaper>
  );
};

const ImplantPlacementDialog = (props) => {
  const { open, tooth, implant, onSelect, onClose } = props;
  const classes = useStyles();
  const [implantIndex, setImplantIndex] = useState(0);
  const [crownVisible, setCrownVisible] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [toothRegion, setToothRegion] = useState('anterior');
  const [imageFlip, setImageFlip] = useState(false);

  useEffect(() => {
    if (tooth && implant) {
      setToothRegion(tooth);
      setImplantIndex(implant);
    }
  }, [tooth, implant]);

  return (
    <CCDialogSimple
      classes={classes}
      title='임플란트 식립'
      open={open}
      maxWidth={false}
      contents={
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <ImplantTable
            classes={classes}
            target={implantIndex}
            onClick={(index) => {
              setImplantIndex(index);
            }}
          />
          <ImplantPreview
            classes={classes}
            crownVisible={crownVisible}
            tooltipVisible={tooltipVisible}
            toothRegion={toothRegion}
            imageFlip={imageFlip}
            implantIndex={implantIndex}
            onClick={(target) => {
              if (target === 'crown') {
                setCrownVisible(!crownVisible);
              } else if (target === 'tooltip') {
                setTooltipVisible(!tooltipVisible);
              } else if (target === 'flip') {
                setImageFlip(!imageFlip);
              } else {
                setToothRegion(target);
              }
            }}
          />
        </Box>
      }
      endActions={
        <>
          <CCButton
            color='sub'
            onClick={() => {
              onClose && onClose();
            }}
          >
            취소
          </CCButton>
          <CCButton
            variant='contained'
            startIcon={<TakeinIcon />}
            onClick={() => {
              onSelect &&
                onSelect(
                  toothRegion,
                  implantIndex,
                  crownVisible,
                  tooltipVisible,
                  imageFlip,
                );

              onClose && onClose();
            }}
          >
            입력
          </CCButton>
        </>
      }
      onCloseButton={() => {
        onClose && onClose();
      }}
      onClose={() => {
        onClose && onClose();
      }}
    />
  );
};

export default ImplantPlacementDialog;
export {
    toothList,
    implantList,
    toothImageWidth,
    toothImageHeight,
    implantImageWidth,
    implantImageHeight,
    implantLengthMax,
};

