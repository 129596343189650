import { useQuery } from "@apollo/react-hooks";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { translate } from "components";
import { GET_ATTACHMENT_ARCHIVE } from "queries/labOrder";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCDialogSimple, CCTypography } from "styles/components";
import { PictureIcon } from "styles/icons";
import { PreviewStlFile } from "../../../../../../../../../components";
import ImagePreviewerModal from "../ImagePreviewerModal";
import NotSupportedDialog from "../NotSupportedDialog";
import { getObject, Target } from "utils/s3";
import ImageArchive from './ImageArchive'

const useStyles = makeStyles(theme => ({
  root: {
    display: "grid",
    gridGap: 8,
    gridTemplateColumns: "repeat(5, 1fr)",
    gridTemplateRows: '102px 102px 102px 102px',
    backgroundColor: "rgba(207, 216, 220, 0.87)",
    padding: 16,
    width: 704,
    height: 566,
    overflow: "auto"
  },
  attachment: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 102,
    borderRadius: 8,
    border: `1px solid ${theme.palette.border.main}`,
    background: theme.palette.common.white,
    cursor: "pointer",

    "& svg": {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  filename: {
    position: "absolute",
    bottom: 8,
    fontSize: "1rem",
    maxWidth: 110,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  }
}));
const LIMIT = 20000;

const AttachmentArchiveDialog = props => {
  const { open, chatGroupId, onClose } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [modals, setModals] = useState({});
  const [openMesh3D, setOpenMesh3D] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const { data, loading } = useQuery(GET_ATTACHMENT_ARCHIVE, {
    variables: {
      input: {
        chatGroupId: chatGroupId,
        limit: LIMIT
      }
    }
  });
  console.log("AttachmentArchiveDialog");

  const handleOpenStl = photo => {
    getObject(Target.PHOTO, photo.bucket, photo?.fileId).then(res => {
      if (res.includes(".stl")) {
        setOpenMesh3D(res);
        setSelectedFile(photo)
      }
    });

  };

  return (
    <CCDialogSimple
      maxWidth={false}
      open={open}
      title="Attachment Archive"
      contentsPadding={false}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <Box className={classes.root}>
          {!loading && (
            <>
              {data.gallery.map((attachment, index) => (
                <>
                  <Box
                    key={index}
                    className={classes.attachment}
                    onClick={() => {
                      if (attachment?.contentType === "STL") {
                        handleOpenStl(attachment)
                      } else {
                        setModals(prevState => ({
                          ...prevState,
                          [attachment.id]: true
                        }))
                      }
                    }
                    }
                  >
                    {attachment.contentType === "PNG" ||
                      attachment.contentType === "JPG" ||
                      attachment.contentType === "JPEG" ? (
                      <ImageArchive attachment={attachment} />
                    ) : (
                      <>
                        {attachment.contentType === "ZIP" ?
                          <img
                            alt={'zip'}
                            src={'/contents/zip.svg'}
                            className={classes.image}
                          /> :
                          attachment.contentType === "STL" ?
                            <img
                              alt={'stl'}
                              src={'/contents/stl.svg'}
                              className={classes.image}
                            /> : <PictureIcon />}
                        <CCTypography
                          variant="body1"
                          title={attachment.filename}
                          className={classes.filename}
                        >
                          {attachment.filename}
                        </CCTypography>
                      </>
                    )}
                  </Box>
                  {(!!modals[attachment.id] &&
                    attachment.contentType === "PNG") ||
                    attachment.contentType === "JPG" ||
                    attachment.contentType === "JPEG" ? (
                    <ImagePreviewerModal
                      fileName={attachment.filename}
                      attachments={[attachment]}
                      open={!!modals[attachment.id]}
                      onClose={() =>
                        setModals(prevState => ({
                          ...prevState,
                          [attachment.id]: false
                        }))
                      }
                    />
                  ) : (attachment.contentType === "STL" && Boolean(openMesh3D)) && selectedFile?.fileId === attachment.fileId ?
                    <PreviewStlFile
                      openMesh3D={openMesh3D}
                      handleClose={() => {
                        setOpenMesh3D(false);
                        setSelectedFile(null);
                      }}
                    />
                    : (
                      <NotSupportedDialog
                        fileName={attachment.filename}
                        attachment={attachment}
                        url={attachment.url}
                        open={!!modals[attachment.id]}
                        onClose={() =>
                          setModals(prevState => ({
                            ...prevState,
                            [attachment.id]: false
                          }))
                        }
                      />
                    )}
                </>
              ))}
            </>
          )}
        </Box>
      }
      endActions={
        <CCButton color="secondary" onClick={onClose}>
          {translate(intl, "common.ui.close")}
        </CCButton>
      }
    />
  );
};

export default AttachmentArchiveDialog;
