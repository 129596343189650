import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { CCButton, CCTypography } from "styles/components";
import { AddIcon } from "styles/icons";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 8,
    width: 831
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 8px 8px 16px"
  },
  currentstock: {
    display: "flex",
    padding: 16
  },
  item__currentstock: {
    flexBasis: "20%",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    paddingLeft: 8,

    "&:first-child": {
      border: "none",
      paddingLeft: 0
    }
  },
  mb8: {
    marginBottom: 8
  }
}));

const CurrentStockBox = props => {
  const {
    goldTypes,
    goldStock = {
      inlay: 0,
      aType: 0,
      sA: 0,
      pt: 0,
      pfg: 0
    },
    onClickAddStockButton
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid
      container
      wrap={"nowrap"}
      direction={"column"}
      className={classes.root}
    >
      {onClickAddStockButton && (
        <Grid item className={classes.header}>
          <CCTypography variant="h4">
            {translate(intl, "labManageGold.currentStockG")}
          </CCTypography>
          <CCButton
            color="normal"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onClickAddStockButton}
          >
            {translate(intl, "labManageGold.addStock")}
          </CCButton>
        </Grid>
      )}

      <Grid item className={classes.currentstock}>
        {Object.keys(goldTypes).map(type => {
          return (
            <Box key={type} className={classes.item__currentstock}>
              <CCTypography variant="body1" className={classes.mb8}>
                {goldTypes[type]}
              </CCTypography>
              <CCTypography variant="h4" color="primary">
                {goldStock[type]
                  ? `${Number(goldStock[type]).toFixed(2)} g`
                  : "-"}
              </CCTypography>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default CurrentStockBox;
