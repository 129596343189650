import React from "react";

const DentalmirrorIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 2c2.21 0 4 1.79 4 4 0 2.04-1.526 3.723-3.5 3.969V13h.5v1h-.5c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1h-1c-.552 0-1-.448-1-1v-7c0-.552.448-1 1-1H16v-1h.5V9h.5c1.657 0 3-1.343 3-3s-1.343-3-3-3c-.828 0-1.578.336-2.121.879l-.707-.707C14.895 2.448 15.895 2 17 2zM4 6c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.04-1.526 3.723-3.5 3.969V13H9v1h-.5c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1h-1c-.552 0-1-.448-1-1v-7c0-.552.448-1 1-1H7v-1h.5V9.97C5.527 9.722 4 8.04 4 6zm4-3C6.343 3 5 4.343 5 6s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"
    />
  </svg>
);
export default DentalmirrorIcon;
