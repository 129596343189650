import { DialogPermissionWarn } from "components";
import React, { useState } from "react";

const CVBox = props => {
  const { children, permission, onClick, ...others } = props;
  const [open, setOpen] = useState(false);
  const isPermission = true;

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div onClick={isPermission ? onClick : handleClick} {...others}>
        {children}
      </div>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default CVBox;
