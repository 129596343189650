import React from "react";

const DenturesIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M20.923 6c.586-.003 1.145.271 1.54.755.345.425.535.977.537 1.545 0 .08-.002.159-.009.239l-.537 5.953c.017.253.025.511.025.776v.803c0 1.006-1.43 1.467-2.753 1.467a4.123 4.123 0 0 1-1.066-.1c-.412-.083-.792-.216-1.088-.402-.505.656-1.73.964-2.889.964-1.027 0-2.108-.24-2.683-.752-.573.511-1.656.752-2.683.752-1.16 0-2.384-.308-2.89-.965-.295.187-.675.32-1.087.403-.35.08-.707.114-1.066.1-1.323 0-2.753-.461-2.753-1.467v-.803c0-.265.008-.523.025-.776L1.009 8.54A2.576 2.576 0 0 1 1 8.3c.002-.568.192-1.12.537-1.545.395-.484.954-.758 1.54-.755h17.846zm-6.237 3.23c-1.07 0-2.27 2.558-2.27 6.232v.923l-.003.014c.065.207.89.678 2.27.678 1.414 0 2.245-.494 2.274-.692v-.923c0-3.674-1.199-6.231-2.274-6.231l.003-.001zm-5.373 0c-1.071 0-2.27 2.558-2.27 6.232v.923c.03.198.86.692 2.274.692 1.38 0 2.205-.471 2.27-.678l-.004-.014v-.923c0-3.674-1.198-6.231-2.273-6.231l.003-.001zm-5.026.674c-.906 0-1.923 2.202-1.923 5.364v.789c.054.157.71.558 1.923.558 1.218 0 1.87-.401 1.923-.558v-.79c0-3.16-1.017-5.362-1.926-5.362l.003-.001zm15.426 0c-.906 0-1.923 2.202-1.923 5.364v.789c.054.157.705.557 1.922.558 1.214 0 1.87-.401 1.924-.558v-.79c0-3.16-1.017-5.362-1.926-5.362l.003-.001zM4.29 8.982c1.012 0 1.86 1.124 2.335 2.876.529-2.135 1.52-3.55 2.685-3.55h.007c1.163 0 2.153 1.406 2.683 3.53.53-2.124 1.52-3.53 2.683-3.53h.007c1.165 0 2.156 1.415 2.686 3.55.475-1.752 1.322-2.876 2.334-2.876h.002c.169 0 .333.031.492.092.396.152.758.486 1.07.964a4.753 4.753 0 0 1 .196.33c.158.293.3.621.423.982l.144-1.597.118-1.307a1.49 1.49 0 0 0-.317-1.07 1.185 1.185 0 0 0-.923-.453H3.085a1.185 1.185 0 0 0-.923.453 1.49 1.49 0 0 0-.317 1.07l.117 1.307.145 1.597a6.5 6.5 0 0 1 .475-1.076 4.44 4.44 0 0 1 .141-.231c.315-.483.677-.817 1.074-.969.158-.06.322-.092.491-.092h.002z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default DenturesIcon;
