import { MenuList, Popover } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import InputIcon from "@material-ui/icons/Input";
import clsx from "clsx";
import { DialogVersionInfo, PasswordChangeDialog } from "components";
import DialogWarningConfirm from "components/DialogWarningConfirm";
import { AppContext, RouteContext } from "context";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { InfoIcon, UnlockIcon } from "styles/icons";
import { CCTypography } from "styles/src/components";
import { translate } from "../../../../../../components";
// import { LOG_CATEGORY } from 'types/log';

const useStyle = makeStyles(theme => ({
  root: {},
  menuList: { padding: 0 },
  menuItem: {
    minWidth: 200,
    padding: `4px 8px`
  },
  userBackground: {
    backgroundColor: theme.palette.background.default,
    padding: `4px 8px`
  },
  highlight: {
    color: theme.palette.secondary.main
  },
  icon: { margin: 4, color: theme.palette.disabled },
  title: { alignSelf: "center", marginLeft: 4 }
}));

const LogoutSelect = props => {
  const { anchorEl = null, onClose, open } = props;
  const classes = useStyle();
  const intl = useIntl();
  const [logoutAlert, setLogoutAlert] = useState(false);
  const [versionDialog, setVersionDialog] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const { dispatchRoute } = useContext(RouteContext);
  const { appContext, dispatchApp } = useContext(AppContext);
  // const [createLog] = useMutation(CREATE_LOG);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        open={open}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuList className={classes.menuList}>
          <MenuItem className={clsx(classes.menuItem, classes.userBackground)}>
            <Grid container justify={"center"} alignItems={"center"}>
              <Grid item>
                <CCTypography variant={"body1"} component={"span"}>
                  {translate(intl, "common.ui.login")}
                </CCTypography>
              </Grid>
              <Grid item>
                <CCTypography
                  variant={"body1"}
                  component={"span"}
                  className={classes.highlight}
                >
                  {appContext.me?.staffId || appContext.me?.staffName || ""}
                </CCTypography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={clsx(classes.menuItem)}
            onClick={() => {
              setChangePasswordDialog(true);
            }}
          >
            <UnlockIcon className={classes.icon} />
            <CCTypography variant={"body1"} className={classes.title}>
              {translate(intl, "common.ui.changePassword")}
            </CCTypography>
          </MenuItem>

          <MenuItem
            className={clsx(classes.menuItem)}
            onClick={() => {
              setVersionDialog(true);
            }}
          >
            <InfoIcon className={classes.icon} />
            <CCTypography variant={"body1"} className={classes.title}>
              {translate(intl, "common.ui.versionInfo")}
            </CCTypography>
          </MenuItem>
          <MenuItem
            className={clsx(classes.menuItem)}
            onClick={() => {
              setLogoutAlert(true);
            }}
          >
            <InputIcon className={classes.icon} />

            <CCTypography variant={"body1"} className={classes.title}>
              {translate(intl, "logout")}
            </CCTypography>
          </MenuItem>
        </MenuList>
      </Popover>

      {logoutAlert &&
        <DialogWarningConfirm
          open={logoutAlert}
          onAgree={() => {
            dispatchRoute({ path: "/signin" });
            dispatchApp({ type: "init", target: null });
            dispatchApp({ type: "permission", target: null });
            sessionStorage.setItem("token", "");
          }}
          onClose={() => setLogoutAlert(false)}
          agreeButtonLabel={translate(intl, "logout")}
          agreeButtonColor={"primary"}
          disagreeButtonLabel={translate(intl, "common.ui.cancel")}
          title={translate(intl, "common.messages.areYouSureYouWantToLogOut")}
        />
      }

      {changePasswordDialog && (
        <PasswordChangeDialog
          open={changePasswordDialog}
          onClose={() => setChangePasswordDialog(false)}
        />
      )}

      {versionDialog && (
        <DialogVersionInfo
          open={versionDialog}
          onClose={() => setVersionDialog(false)}
        />
      )}
    </>
  );
};
export default withRouter(LogoutSelect);
