import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { DialogDelete } from "components/index";
import InfiniteTable from "components/InfiniteTable";
import moment from "moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { GENDER } from "types/patient";
import RESERVATION from "types/reservation";
import { makeIndexFormattedMessageList, makeIndexList } from "types/utils";
import { calcBirthDate, getAgeToBirthDay, getSex } from "utils/patient";
import { DetailContext } from "../../../index";
import { CommonHeader } from "../../../ListHeader";
import { CommonItem, NextItem, TeethGraphItem } from "../../../ListItem";

const useStyle = makeStyles(theme => ({
  root: {
    height: props => {
      if (props?.collapse && props?.collapseRef?.current) {
        return `calc(100% - ${props?.collapseRef?.current.offsetHeight}px)`;
      } else {
        return "calc(100%)";
      }
    }
  }
}));
const GENDER_LIST = makeIndexList(GENDER);
const STATUS_LIST = makeIndexFormattedMessageList(RESERVATION.STATUS.PATIENT);

const ResultList = props => {
  const {
    list,
    collapseRef,
    collapse,
    hasNext,
    onMoreContents,
    // onChange,
    onDelete
  } = props;
  const classes = useStyle({ collapseRef: collapseRef, collapse: collapse });
  const [selected, setSelected] = useState(null);
  const [selectRef, setSelectRef] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const intl = useIntl();
  return (
    <Box className={classes.root}>
      <DetailContext
        open={Boolean(selectRef)}
        anchorEl={selectRef}
        onClose={() => {
          setSelected(-1);
          setSelectRef(null);
        }}
        onShowDetail={() => {
          // console.log(selectedValue);
          setOpenDialog(true);
        }}
        onDelete={() => {
          // console.log(selectedValue);
          setOpenDeleteDialog(true);
        }}
      />
      <DialogDelete
        permission={"reservation"}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDisagree={() => setOpenDeleteDialog(false)}
        onAgree={() => {
          onDelete && onDelete({ ...selectedValue });
          setOpenDeleteDialog(false);
        }}
      />
      {/* {openDialog && (
        <PatientDialog
          open={openDialog}
          selected={{ ...selectedValue }}
          onChange={e => {
            onChange && onChange(e);
            setSelected(-1);
            setSelectRef(null);
            setSelectedValue(null);
            setOpenDialog(false);
          }}
          onClose={() => {
            setSelected(-1);
            setSelectRef(null);
            setSelectedValue(null);
            setOpenDialog(false);
          }}
          disableContext
        />
      )} */}
      <InfiniteTable
        heads={[
          {
            key: "dateBegin",
            width: 140,
            headComponent: () => <CommonHeader label={"예약일/시간"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={moment(cellData, "X").format("YYYY/MM/DD HH:mm")}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 100,
            headComponent: () => <CommonHeader label={"환자이름"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={
                  cellData?.patient?.patient?.name ||
                  cellData?.patient?.name ||
                  ""
                }
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 100,
            headComponent: () => <CommonHeader label={"차트번호"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={cellData?.patient?.patient?.chartNumber || ""}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 100,
            headComponent: () => <CommonHeader label={"연령/성별"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={
                  cellData?.patient?.patient?.residentRegistrationNumber
                    ? `${getAgeToBirthDay(
                        calcBirthDate(
                          cellData?.patient?.patient?.residentRegistrationNumber
                        )
                      )}세/${intl.formatMessage({
                        id:
                          GENDER_LIST[
                            getSex(
                              cellData?.patient?.patient
                                ?.residentRegistrationNumber
                            )
                          ]
                      })}`
                    : ""
                }
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 120,
            headComponent: () => <CommonHeader label={"전화번호"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={
                  rowData?.phoneNumber ||
                  cellData?.patient?.patient?.cellphoneNumber ||
                  ""
                }
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 60,
            headComponent: () => <CommonHeader label={"환자구분"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={
                  cellData?.patient?.newOrExistingPatient ? "신환" : "구환"
                }
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 70,
            headComponent: () => <CommonHeader label={"진료/검진"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={cellData?.patient?.oralExam ? "검진" : "진료"}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 100,
            headComponent: () => <CommonHeader label={"담당의사"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={cellData?.patient?.doctor?.name || ""}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 100,
            headComponent: () => <CommonHeader label={"담당직원"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={cellData?.patient?.staff?.name || ""}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "status",
            width: 100,
            headComponent: () => <CommonHeader label={"이행여부"} />,
            component: ({ rowData, cellData }) => (
              <CommonItem
                label={STATUS_LIST[cellData]}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            width: 200,
            headComponent: () => <CommonHeader label={"치식"} />,
            component: ({ rowData, cellData }) => (
              <TeethGraphItem
                numbers={cellData?.patient?.next[0]?.selectedTeeth || []}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "content",
            headComponent: () => <CommonHeader label={"내용"} left />,
            component: ({ rowData, cellData }) => (
              <NextItem
                next={cellData?.patient?.next}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          }
        ]}
        contents={list || []}
        selected={e => selected === e.id}
        onRowClick={e => {
          setSelected(e.rowData.id);
        }}
        hasNext={Boolean(hasNext)}
        onMoreContents={e => {
          // console.log(e);
          onMoreContents && onMoreContents(e);
        }}
        // cache={cache}
        rowClickHighlight
      />
    </Box>
  );
};

export default ResultList;
