/*eslint react-hooks/exhaustive-deps: 0*/
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CCIconButton, CCTextField } from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import CCPaper from "styles/src/components/CCPaper";
import CCTypography from "styles/src/components/CCTypography";
import {
  CheckIcon,
  SearchIcon,
  ShoppingIcon
} from "styles/src/themes/common/icons";
import { CAPITALIZE_TYPE, useIntl } from "utils/language";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  CVButton,
  DialogWarningMessage,
  InfiniteTable,
  PermissionBackdrop,
  TypographyTooltip
} from "components";
import axios from "axios";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/utils/TableItems";
import { useApolloClient } from "@apollo/client";
import { CREATE_PAMCART, SEARCH_PAMCART, UPDATE_PAMCART } from "queries/stock";
import { TabContext } from "../../Purchase";
import { DBVendorContext } from "context/DBContext/components";
import { SupplierDetailDialog } from "./Component/SearchProductResults/Component";
import { EstimateDetailDialog } from "views/Normal/InventoryManagement/Component/Purchase/Component/EstimationTab/Component/EstimateResults/Component";

const useStyle = makeStyles(theme => ({
  init: {
    position: "relative",
    height: "calc(100% - 70px)",
    padding: 16,
    backgroundColor: theme.palette.common.white
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: 8,
    marginBottom: 16,
    minWidth: 1400
  },
  input: {
    backgroundColor: theme.palette.background.paper
  },
  timeInput: {
    width: 130
  },
  selectInput: {
    width: 142
  },
  memoInput: {
    width: 236
  },
  marginLeft8: {
    marginLeft: 8
  },
  description: {
    display: "flex",
    marginLeft: "10px",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px"
  },
  paper: {
    width: "100%",
    height: props => {
      if (props?.collapse && props?.collapseRef?.current) {
        return `calc(80% - ${props?.collapseRef?.current.offsetHeight}px)`;
      } else {
        return `calc(80% - 110px)`;
      }
    },
    minWidth: 1000
  },
  select__field: {
    marginLeft: 20,
    width: 200
  },
  small__button: {
    "&:hover": {
      backgroundColor: theme.palette.select.main
    }
  },
  selected: {
    backgroundColor: theme.palette.select.main,
    cursor: "default"
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  item: {
    width: "150%",
    display: "inline-flex",
    alignItems: "center"
  },
  margin__box: {
    width: 50
  },
  root_table: {
    height: "500px",
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  container: { height: "100%" },
  item__table: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    textAlign: "center"
  },
  button__details: {
    height: "20px",
    width: "58px",
    padding: "0px",
    alignItems: "center",
    fontSize: "12px",
    zIndex: 10
  }
}));

const initSelectedValue = {
  major: [],
  minor: null,
  name: "",
  alarm: null,
  stock: false,
  status: "0",
  noStock: {}
};

const PERMISSION = "inventoryHistory";

const SearchProductTab = props => {
  const classes = useStyle();

  const client = useApolloClient();
  const _url = process.env.REACT_APP_PAM_URL;
  const [selectedValue, setSelectedValue] = useState(initSelectedValue);
  // const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [major, setMajor] = useState([]);
  // const [minor, setMinor] = useState([]);
  const [detail, setDetail] = useState(false);
  const [connected, setConnected] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [lastKey, setLastKey] = useState(0);
  const [searchList, setSearchList] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [detailDialog, setDetailDialog] = useState(false);
  const { setTabs } = useContext(TabContext);
  // const { dbVendorContext, dispatchDBVendor } = useContext(DBVendorContext);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  const intl = useIntl();
  const ALL_SELECTION = intl.formatMessage({ id: "all" });
  const [inventoryStatus, setInventoryStatus] = useState([ALL_SELECTION]);

  const searchProduct = useCallback(async () => {
    try {
      // setLoading(true);
      axios({
        method: "GET",
        url: `${_url}/api/v1/clinic/${sessionStorage.getItem(
          "clinicId"
        )}/material`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          setConnected(response?.data?.map(e => e.id));
        })

        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
    // setLoading(false);
  }, []);

  // useEffect(() => {
  //   if (dbVendorContext) {
  //     searchProduct();
  //   }
  // }, [dbVendorContext, searchProduct]);

  const fetchVendor = async args => {
    let variables = {
      offset: 0,
      limit: 10
    };
    if (args) {
      variables.offset = args.lastKey;
    }

    try {
      // setLoading(true);
      axios({
        method: "POST",
        url: `${_url}/api/v1/material_item/list`,
        data: variables,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          if (response?.data?.count > vendor?.length) {
            setVendor(prevState =>
              prevState
                ? prevState.concat(response?.data?.rows)
                : response?.data?.rows
            );
            setMajor(prevState =>
              prevState
                ? prevState.concat(
                    response?.data?.rows
                      ?.map(e => e.category1)
                      .filter(onlyUnique)
                  )
                : response?.data?.rows?.map(e => e.category1).filter(onlyUnique)
            );

            setHasNext(true);
          } else {
            setHasNext(false);
          }
        })
        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
    // setLoading(false);
  };

  const searchVendor = async args => {
    let variables = {
      offset: 0,
      limit: 10
    };
    if (args) {
      variables.offset = args.lastKey;
    }

    try {
      // setLoading(true);
      axios({
        method: "POST",
        url: `${_url}/api/v1/material_item/list`,
        data: variables,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          if (response?.data?.count > vendor?.length) {
            setVendor(prevState =>
              prevState
                ? prevState.concat(response?.data?.rows)
                : response?.data?.rows
            );
            // setLastKey(vendor?.length);
            setHasNext(true);
          } else {
            setHasNext(false);
          }
        })
        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
    // setLoading(false);
  };

  const addPamCart = useCallback(async material => {
    if (material?.length > 0) {
      for (const v of Object.keys(material)) {
        let _list = material[v];
        let _material = material[v]?.Material?.id;

        return client
          .query({
            query: SEARCH_PAMCART,
            variables: {
              hospitalId: localStorage.getItem("hospitalId"),
              args: {
                materialId: _material
              }
            }
          })
          .then(async res => {
            //TODO: update Pamcart items 안에 넣어주기

            let _product = res?.data?.searchPamcart[0];

            if (res?.data?.searchPamcart?.length > 0) {
              if (!_product.item?.map(e => e.name)?.includes(_list?.name)) {
                await client
                  .mutate({
                    mutation: UPDATE_PAMCART,
                    variables: {
                      hospitalId: localStorage.getItem("hospitalId"),
                      materialId: _material,
                      id: _product?.id,
                      item: [
                        ..._product?.item,
                        {
                          category1: _list?.category1,
                          category2: _list?.category2,
                          name: _list?.name,
                          unit1: _list?.unit1,
                          unit2: _list?.unit2,
                          unitPrice: Number(_list?.unitPrice),
                          count: 0,
                          manufacture: _list?.maufacturer,
                          material: _list?.materialId
                        }
                      ]
                    }
                  })
                  .then(res => console.log("RES for searchPAMCART", res));
              }
            } else {
              //TODO: create Pamcart

              console.log("codus 3번 CREATE 장바구니 >>>", _material);

              await client
                .mutate({
                  mutation: CREATE_PAMCART,
                  variables: {
                    hospitalId: localStorage.getItem("hospitalId"),
                    materialId: _material,
                    item: _list
                      ? [
                          {
                            category1: _list?.category1,
                            category2: _list?.category2,
                            name: _list?.name,
                            unit1: _list?.unit1,
                            unit2: _list?.unit2,
                            unitPrice: Number(_list?.unitPrice),
                            count: 0,
                            manufacture: _list?.maufacturer,
                            material: _list?.materialId
                          }
                        ]
                      : []
                  }
                })
                .then(res => console.log(res));

              // await client.mutate({
              //   mutation:CREATE_PAMCART,
              //   variables:{
              //     hospitalId:localStorage.getItem("hospitalId"),
              //     materialId:_material,
              //     item:[
              //
              //     ]
              //   }
              // })

              // await client.mutate({
              //   mutation: CREATE_PAMCART
              // });
            }

            // client.mutate({
            //   mutation: UPDATE_PAMCART,
            //   variables: {}
            // });
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }, []);

  const onMoreContents = useCallback(() => {
    // setLoading(true);
    let variables = {};
    variables.lastKey = lastKey;

    fetchVendor(variables);
  }, [lastKey]);

  //dbVendorContext
  useEffect(() => {
    // TODO: add Context
    fetchVendor();

    // return () => {
    //   setLoading(true);
    // };
  }, []);

  const tabList =
    selectedValue?.status === "0"
      ? [
          "registerProduct",
          "inventorySurvey",
          "errorCorrection",
          "otherPurchase",
          "otherReturn",
          "otherAcquisition",
          "otherLoss"
        ]
      : selectedValue?.status === "1"
      ? [
          "registerProduct",
          "inventorySurvey",
          "errorCorrection",
          "otherPurchase",
          "otherAcquisition"
        ]
      : ["inventorySurvey", "errorCorrection", "otherReturn", "otherLoss"];

  useEffect(() => {
    setLastKey(vendor?.length);
  }, [vendor]);

  return (
    <>
      <Grid className={classes.init}>
        <CCPaper className={classes.root}>
          {/* <PermissionBackdrop permission={PERMISSION} /> */}
          {/*{permission && (*/}
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            wrap={"nowrap"}
          >
            <Grid
              item
              style={{
                paddingLeft: 12,
                paddingRight: 12,
                minHeight: 54,
                height: 54
              }}
            >
              <Grid
                container
                alignItems={"center"}
                style={{ height: "100%" }}
                direction="row"
              >
                <Grid item className={classes.item}>
                  <Box>
                    <CCTypography
                      variant="h5"
                      component={"span"}
                      style={{ marginRight: 20 }}
                    >
                      {intl.formatMessage({ id: "pamProductSearch" })}
                    </CCTypography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedValue?.status}
                          value={selectedValue?.status}
                        />
                      }
                      label={
                        <Box>
                          <CCTypography variant={"body1"}>
                            {intl.formatMessage({
                              id: "searchOnlyClinicSupplier"
                            })}
                          </CCTypography>
                        </Box>
                      }
                      onChange={() => {
                        setSelectedValue({
                          ...selectedValue,
                          stock: null,
                          status: !selectedValue?.status
                        });
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} wrap={"nowrap"}>
                <Grid
                  container
                  alignItems={"center"}
                  style={{ height: "100%", width: "100%" }}
                >
                  <CCTextField
                    className={classes.select__field}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "major" })}
                    select
                    SelectProps={{
                      multiple: false
                    }}
                    value={selectedValue?.category1 || ALL_SELECTION}
                    onChange={e => {
                      const {
                        target: { value }
                      } = e;

                      setSelectedValue({ ...selectedValue, category1: value });
                    }}
                  >
                    <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                      {ALL_SELECTION}
                    </MenuItem>
                    {major.map(e => (
                      <MenuItem key={e} value={e}>
                        {intl.formatMessage({ id: e })}
                      </MenuItem>
                    ))}
                  </CCTextField>
                  <CCTextField
                    className={classes.select__field}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "major" })}
                    select
                    SelectProps={{
                      multiple: false
                    }}
                    value={selectedValue?.category2 || ALL_SELECTION}
                    onChange={e => {
                      const {
                        target: { value }
                      } = e;

                      setSelectedValue({ ...selectedValue, category2: value });
                    }}
                  >
                    <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                      {ALL_SELECTION}
                    </MenuItem>
                    {/*{minor.map(e => (*/}
                    {/*  <MenuItem key={e} value={e}>*/}
                    {/*    {intl.formatMessage({ id: e })}*/}
                    {/*  </MenuItem>*/}
                    {/*))}*/}
                    {/*)}*/}
                  </CCTextField>
                  <CCTextField
                    className={classes.select__field}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "minor" })}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: value => {
                        setInventoryStatus(value);
                        return value.join(", ");
                      }
                    }}
                    value={inventoryStatus}
                    onChange={e => {
                      const {
                        target: { value }
                      } = e;

                      if (value.length > 1) {
                        if (value[value.length - 1] === ALL_SELECTION) {
                          setInventoryStatus([ALL_SELECTION]);
                        } else if (value[0] === ALL_SELECTION) {
                          setInventoryStatus(
                            value?.filter(item => item !== ALL_SELECTION)
                          );
                        } else {
                          setInventoryStatus(value);
                        }
                      }
                    }}
                  >
                    <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                      {ALL_SELECTION}
                    </MenuItem>
                    {tabList.map(e => (
                      <MenuItem key={e} value={e}>
                        {intl.formatMessage({ id: e })}
                      </MenuItem>
                    ))}
                  </CCTextField>
                  <CCTextField
                    // className={classes.head__field}
                    style={{ width: "350px" }}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({
                      id: "searchProductwithKeyword"
                    })}
                    value={selectedValue?.name}
                    onChange={e => {
                      setSelectedValue({
                        ...selectedValue,
                        name: e.target.value
                      });
                    }}
                  />
                  {/*<CCTextField*/}
                  {/*  // className={classes.head__field}*/}
                  {/*  style={{ width: "350px" }}*/}
                  {/*  variant={"outlined"}*/}
                  {/*  margin={"dense"}*/}
                  {/*  label={intl.formatMessage({*/}
                  {/*    id: "manufacturer"*/}
                  {/*  })}*/}
                  {/*  value={selectedValue?.name}*/}
                  {/*  onChange={e => {*/}
                  {/*    setSelectedValue({*/}
                  {/*      ...selectedValue,*/}
                  {/*      name: e.target.value*/}
                  {/*    });*/}
                  {/*  }}*/}
                  {/*/>*/}

                  <Grid item className={classes.marginLeft8}>
                    <CCButton
                      style={{
                        height: 36,
                        paddingTop: 0,
                        paddingBottom: 0,
                        width: 110
                      }}
                      variant={"contained"}
                      color={"secondary"}
                      startIcon={<SearchIcon />}
                      onClick={() => {
                        searchVendor({
                          status:
                            selectedValue?.status === "1"
                              ? "Quote"
                              : selectedValue?.status === "2"
                              ? "Quoted"
                              : null
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "search" })}
                    </CCButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CCPaper>
        <CCPaper className={classes.paper}>
          <Box className={classes.root_table}>
            <Grid className={classes.container} container direction="column">
              <Grid className={classes.item__table} item>
                <InfiniteTable
                  heads={[
                    {
                      key: "Material",
                      headComponent: ({ cellData, rowData }) => {
                        return (
                          <Checkbox
                            disableRipple
                            // checked={rowData}
                          />
                        );
                      },
                      width: 60,
                      component: ({ cellData, rowData }) => {
                        let _checked = searchList?.includes(rowData);

                        return (
                          <TableItem
                            disableTypography
                            label={
                              connected.includes(cellData?.id) ? (
                                <Checkbox
                                  //TODO: checked 선택된 내용 불러올 수 있게
                                  checked={_checked}
                                  style={{ padding: 10 }}
                                  disableRipple
                                  onChange={e => {
                                    if (e.target.checked) {
                                      if (searchList?.length > 0) {
                                        setSearchList([...searchList, rowData]);
                                      } else {
                                        setSearchList([rowData]);
                                      }
                                    } else {
                                      setSearchList(
                                        searchList.filter(e => e !== rowData)
                                      );
                                    }
                                  }}
                                  // onChange={e => {
                                  //   if (e.target.checked) {
                                  //     if (Boolean(deleteList?.length > 0)) {
                                  //       setDeleteList([...deleteList, cellData]);
                                  //     } else {
                                  //       setDeleteList([cellData]);
                                  //     }
                                  //   } else {
                                  //     setDeleteList(
                                  //       deleteList.filter(e => e !== cellData)
                                  //     );
                                  //   }
                                  // }}
                                  className={classes.row__focus}
                                />
                              ) : null
                            }
                          />
                        );
                      }
                    },
                    {
                      key: "Material",
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "supplier" })}
                        />
                      ),
                      width: 160,
                      label: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "supplier" })}
                        />
                      ),
                      component: ({ cellData }) => {
                        return (
                          <TableItem label={cellData ? cellData?.name : ""} />
                        );
                      }
                    },
                    {
                      key: "MaterialItemImage",
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "image" })}
                        />
                      ),
                      label: () => <TableHeader label={"status"} />,
                      width: 200,
                      component: ({ cellData }) => {
                        return (
                          <Box
                            style={{
                              width: 150,
                              backgroundColor: "#000",
                              height: 100,
                              backgroundImage: `url(${cellData?.url})`,
                              backgroundPosition: "center center",
                              backgroundOrigin: "border-box",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat"
                            }}
                          />
                        );
                      }
                    },
                    {
                      key: "category1",
                      width: 200,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "major" })}
                        />
                      ),
                      label: () => <TableHeader label={"major"} />,
                      component: ({ cellData }) => {
                        return <TableItem label={cellData ? cellData : ""} />;
                      }
                    },
                    {
                      key: "category2",
                      width: 200,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "minor" })}
                        />
                      ),
                      label: <TableHeader label={"minor"} />,
                      component: ({ cellData }) => {
                        return <TableItem label={cellData ? cellData : ""} />;
                      }
                    },
                    {
                      key: "name",
                      width: 470,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "productName" })}
                        />
                      ),
                      label: (
                        <TableHeader
                          label={intl.formatMessage(
                            { id: "tradingProduct" },
                            {},
                            { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                          )}
                        />
                      ),
                      component: ({ cellData }) => {
                        return <TableItem label={cellData ? cellData : ""} />;
                      }
                    },
                    {
                      key: "manufacturer",
                      width: 160,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "manufacturer" })}
                        />
                      ),
                      label: (
                        <TableHeader
                          label={intl.formatMessage(
                            { id: "manufacture" },
                            {},
                            { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                          )}
                        />
                      ),
                      component: ({ cellData }) => (
                        <TableItem label={cellData ? cellData : ""} />
                      )
                    },
                    {
                      key: "unit1",
                      width: 180,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "unit" })}
                        />
                      ),
                      label: (
                        <TableHeader
                          label={intl.formatMessage({ id: "unit" })}
                        />
                      ),
                      component: ({ cellData }) => (
                        <TableItem label={cellData ? cellData : ""} />
                      )
                    },
                    {
                      key: "unitPrice",
                      width: 160,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "pricePerUnit" })}
                        />
                      ),
                      label: (
                        <TableHeader
                          label={intl.formatMessage(
                            { id: "unitPriceGold" },
                            {},
                            { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                          )}
                        />
                      ),
                      component: ({ cellData, rowData }) => {
                        return <TableItem label={cellData ? cellData : ""} />;
                      }
                    },
                    {
                      key: "Material",
                      width: 190,
                      label: (
                        <TableHeader
                          label={intl.formatMessage({ id: "supplierSetting" })}
                        />
                      ),
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "supplierSetting" })}
                        />
                      ),
                      component: ({ cellData, rowData }) => {
                        return connected.includes(cellData?.id) ? (
                          <TableItem
                            label={
                              <CCIconButton
                                style={{ padding: 0, margin: 0 }}
                                disableRipple
                                color={"primary"}
                                variant={"text"}
                                size={"small"}
                                onClick={() => {
                                  if (cellData) {
                                    setDetail(cellData?.id);
                                  }
                                }}
                              >
                                <CheckIcon />
                              </CCIconButton>
                            }
                          />
                        ) : (
                          <TableItem
                            label={
                              <CVButton
                                className={classes.button__details}
                                variant="contained"
                                size="small"
                                color={"normal"}
                                onClick={() => {
                                  setDetailDialog(cellData?.id);
                                }}
                              >
                                <TypographyTooltip color="inherit">
                                  {intl.formatMessage({
                                    id: "registerPartner"
                                  })}
                                </TypographyTooltip>
                              </CVButton>
                            }
                          />
                        );
                      }
                    }
                  ]}
                  contents={vendor || []}
                  hasNext={hasNext}
                  onMoreContents={e => {
                    onMoreContents && onMoreContents(e);
                  }}
                />
              </Grid>
            </Grid>

            {Boolean(detail) && (
              <EstimateDetailDialog
                open={Boolean(detail)}
                onClose={() => setDetail(false)}
                product={detail ? parseInt(detail) : null}
              />
            )}

            {Boolean(detailDialog) && (
              <SupplierDetailDialog
                open={Boolean(detailDialog)}
                onClose={() => {
                  // dispatchDBVendor();
                  setDetailDialog(false);
                }}
                id={detailDialog}
              />
            )}
          </Box>
        </CCPaper>
        <Box
          marginLeft="auto"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CCButton
            style={{
              height: 36,
              marginTop: 60,
              paddingTop: 0,
              paddingBottom: 0,
              width: 300
            }}
            variant={"contained"}
            color={"secondary"}
            startIcon={<ShoppingIcon />}
            onClick={() => {
              addPamCart(searchList);
              setDialog({
                //TODO : temporary locale

                // title: intl.formatMessage(
                //   { id: "selectedCartAlert" },
                //   { br: () => <br /> }
                // ),
                title:
                  "Successfully added selected items in cart. Do you want to check out cart Tab?",
                onAgree: () => {
                  setDialog(null);
                  setTabs("3");
                  // dispatchDBVendor();
                },
                onDisagree: () => {
                  setDialog(null);
                }
              });
            }}
          >
            {intl.formatMessage({ id: "addToShoppingCart" })}
          </CCButton>
        </Box>
        <DialogWarningMessage open={Boolean(dialog)} {...dialog} />
      </Grid>
    </>
  );
};

export default SearchProductTab;
