import React from "react";

const MorebIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="24"
    viewBox="0 0 8 24 {...props}"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="currentColor">
            <path
              d="M2 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              transform="translate(-4816 -256) translate(4816 256) translate(2 4)"
            />
          </g>
          <g>
            <path
              d="M0 0H8V24H0z"
              transform="translate(-4816 -256) translate(4816 256)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default MorebIcon;
