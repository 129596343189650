import React from "react";

const DollaregyptIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm4.093 5c-.533 0-1.019.094-1.457.283a2.306 2.306 0 0 0-1.05.898c-.23.358-.359.821-.387 1.388l-.006.249v1.312h-1.18v1.478h1.18v.98c0 .525-.107.905-.319 1.14-.17.188-.39.353-.661.497L12 15.33V17h6.939v-1.754h-4.757c.235-.12.46-.306.678-.56.19-.221.296-.533.32-.934l.005-.177v-.967h2.48V11.13h-2.48V9.83c0-.432.106-.734.318-.904.213-.17.463-.256.753-.256.28 0 .571.04.874.118.202.052.406.115.614.19l.314.12.61-1.547a8.412 8.412 0 0 0-1.186-.386A5.663 5.663 0 0 0 16.092 7zM10.87 7H5v9.962h5.87v-1.744H7.157V12.65h3.456v-1.73H7.157V8.73h3.713V7z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default DollaregyptIcon;
