import React from "react";

const NumbertwelveIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M9.334 17.09V7.123h-.212L5 8.6v1.606l2.358-.731v7.615h1.976zm10.056 0v-1.593h-4.28l2.263-2.386c.62-.679 1.057-1.273 1.313-1.784.255-.51.382-1.012.382-1.504 0-.898-.283-1.593-.85-2.085C17.65 7.246 16.853 7 15.827 7c-.67 0-1.27.142-1.798.427-.528.285-.937.678-1.227 1.18-.29.5-.434 1.054-.434 1.66h1.983c0-.5.128-.905.386-1.213.257-.307.61-.461 1.056-.461.415 0 .734.126.957.38.223.252.335.6.335 1.042 0 .323-.106.665-.318 1.025-.212.36-.539.782-.98 1.265l-3.22 3.431v1.354h6.822z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default NumbertwelveIcon;
