import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  CCDialogSimpleAddMod,
  CCDialogSimpleDel,
  CCIconButton,
  CCTooltip
} from "../";
import {
  RightIcon,
  SearchIcon,
  SelectPersonIcon,
  SettingIcon,
  SignoutIcon,
  SpreadoverIcon,
  StatisticsIcon,
  UpIcon,
  WarningIcon
} from "../../themes/common/icons";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    "& span": {}
  },
  container: {
    flexDirection: "column",
    overflow: "hidden"
  },
  selected: {
    background: "green"
  },
  text: {
    opacity: "0.87",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.71",
    letterSpacing: "-0.13px",
    color: "#000000",
    // width: "269px",
    width: "100%",
    height: "36px",
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: "8px"
  }
}));

let Arr = [];
let index = [];

let addCategoryArr = [];
let addCategoryIndex = -1;

let addItemArr = [];
let addItemIndex = -1;

let delArr = [];
let delIndex = -1;

let updateArr = [];
let updateIndex = -1;
/**
 * @deprecated
 */
const Tree = props => {
  const { treeData, onChange, fold, spread, onClickItem } = props;
  const classes = useStyles();

  const [id, setId] = useState(null);
  const [data, setData] = useState(treeData);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [el, setEl] = useState(null);
  const [text, setText] = useState(null);

  const rootRef = useRef(null);

  const displayTree = (Data, pd) => {
    const table = [];
    for (let i = 0; i < Data.length; i++) {
      table.push(
        <Box key={Data[i].id}>
          <Typography
            component="div"
            onClick={makeBackground}
            className={classes.text}
            id={Data[i].id}
            name={Data[i].name}
            style={{ paddingLeft: pd }}
            role={Data[i].hasOwnProperty("item") ? "leaf" : "parent"}
          >
            {!Data[i].hasOwnProperty("item") && Data[i].open && (
              <UpIcon className={classes.icon} />
            )}
            {!Data[i].hasOwnProperty("item") && !Data[i].open && (
              <WarningIcon className={classes.icon} />
            )}
            {Data[i].hasOwnProperty("item") && (
              <RightIcon className={classes.icon} />
            )}
            {Data[i].name}
          </Typography>
          <Box>
            {!Data[i].hasOwnProperty("item") &&
              displayTree(Data[i].items, pd + 10)}
          </Box>
        </Box>
      );
    }

    return table;
  };

  const setOriData = Text => {
    const routes = [];
    let i = -1;
    find(data, routes, i);

    routes.reverse();
    i = 0;
    const newData = updateOpen(routes, i, true, Text);
    if (Array.isArray(newData)) {
      newData.reverse();

      const last = data.map(d => {
        if (d.id === newData[0].id) {
          return { ...newData[0] };
        } else return d;
      });

      onChange(last);
    }
  };

  const makeBackground = e => {
    const Target = e.currentTarget;
    const Parent = Target.parentNode;
    const Id = Target.getAttribute("id");
    const Text = Target.getAttribute("name");

    if (el !== null && el.target !== Target) {
      el.target.style.background = "none";
    }

    Arr = [];

    Log(data, Id);
    setId(Id);
    setText(Text);

    setEl({ target: Target });
    // 배경색 변경
    Target.style.background = "yellow";

    // tree fold, spread
    if (Parent.childNodes[1].style.display === "none") {
      Parent.childNodes[1].style.display = "block";
      setOriData(Text);
    } else {
      Parent.childNodes[1].style.display = "none";
      setOriData(Text);
    }

    // leaf node
    if (Target.getAttribute("role") === "leaf") {
      returnFunction(data, index, 0);
    }
  };

  const openUpdateDialog = () => {
    if (id === null) {
      alert("수정할 폴더 및 파일 위치를 선택하세요.");
    } else {
      if (id === "부모") {
        alert("수정 불가");
      } else {
        setOpenUpdate(true);
      }
    }
  };

  const openDeleteDialog = () => {
    if (id === null) {
      alert("삭제할 폴더 및 파일 위치를 선택하세요.");
    } else {
      if (id === "부모") {
        alert("삭제 불가");
      } else {
        setOpenDelete(true);
      }
    }
  };

  const openAddCategoryDialog = () => {
    if (id === null) {
      alert("추가할 위치를 선택하세요");
    } else {
      setOpenAddCategory(true);
    }
  };

  const openAddItemDialog = () => {
    if (id === null) {
      alert("추가할 위치를 선택하세요");
    } else {
      setOpenAddItem(true);
    }
  };

  const closeUpdateDialog = () => {
    setOpenUpdate(false);
  };

  const closeDeleteDialog = () => {
    setOpenDelete(false);
  };

  const closeAddCategoryDialog = () => {
    setOpenAddCategory(false);
  };

  const closeAddItemDialog = () => {
    setOpenAddItem(false);
  };

  // 부모로부터 내 위치 알아내기
  const Log = (data, Id) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === Id) {
        data[i].id = Id;
      }

      if (i === 0) {
        Arr.push(i);
      } else {
        Arr.pop();
        Arr.push(i);
      }

      if (Id === data[i].id) {
        index = [...Arr];
        return;
      }

      if (!data[i].hasOwnProperty("item") && data[i].items.length !== 0) {
        Log(data[i].items, Id);
        Arr.pop();
      }
    }
  };

  const changeId = e => {
    const Target = e.target;

    Target.style.background = "yellow";

    if (el !== null) {
      if (Target !== el.target) {
        el.target.style.background = "none";
      }
    }
    setEl({ target: Target });
    setId("부모");
    Arr = [];

    // leaf node
    if (Target.getAttribute("role") === "leaf") {
      console.log("index", index);
      returnFunction(data, index, 0);
    }
  };

  const foldTree = () => {
    const Tree = rootRef.current.childNodes;
    for (let i = 0; i < Tree.length; i++) {
      Tree[i].style.display = "none";
    }
  };

  const openTree = () => {
    const Tree = rootRef.current.childNodes;
    for (let i = 0; i < Tree.length; i++) {
      Tree[i].style.display = "block";
    }
  };

  const find = (data, routes, i) => {
    if (i < index.length && index.length !== 0) {
      i++;
      routes.push({ ...data[index[i]] });
      if (i === index.length - 1) {
        return;
      } else {
        find(data[index[i]].items, routes, i);
      }
    }
  };

  const updateOpen = (routes, i, opened, Text) => {
    const open = opened;
    if (routes.length === 0) return;
    if (i === 0) {
      const tObj = { ...routes[i] };
      tObj.open = open;
      const id = routes[i].id;

      const tArr = routes.map((d, index) => {
        if (i === index) return tObj;
        else return d;
      });

      // 최상단을 클릭한 경우
      if (i + 1 === routes.length && i === 0) {
        const tArr = routes.map(d => {
          return { name: Text, items: [...d.items], id: d.id, open: open };
        });
        return tArr;
      }

      // 그 부모에서 내가 들어있는 곳 변경
      const ttArr = tArr[i + 1].items.map(d => {
        if (d.id === id) {
          return tObj;
        } else {
          return d;
        }
      });

      const tttArr = tArr.map((d, ind) => {
        if (ind === i + 1) {
          return { name: d.name, items: [...ttArr], id: d.id, open: d.open };
        } else {
          return d;
        }
      });

      i++;
      return updateOpen(tttArr, i, opened, Text);
    } else {
      const findId = routes[i].id;

      if (i + 1 === routes.length) {
        const Arr = [...routes];
        return Arr;
      }

      const tArr = routes[i + 1].items.map(d => {
        if (d.id === findId) {
          return {
            name: d.name,
            items: [...routes[i].items],
            id: d.id,
            open: d.open
          };
        } else {
          return d;
        }
      });

      const ttArr = routes.map((d, ind) => {
        if (ind === i + 1) {
          return { name: d.name, items: [...tArr], id: d.id, open: d.open };
        } else {
          return d;
        }
      });

      if (routes.length - 1 !== i) {
        i++;
        return updateOpen(ttArr, i, opened, Text);
      }
    }
  };

  // ------------------------------------ 카테고리 추가 시작
  const addCategorySave = newValue => {
    if (id === "부모") {
      onChange([
        ...data,
        { name: newValue, items: [], id: uuid(), open: false }
      ]);
    } else {
      addCategoryFind(data);
      addCategoryArr.reverse();
      addCategoryIndex = 0;

      addCategoryFunc(addCategoryArr, newValue);
    }
    addCategoryIndex = -1;
    addCategoryArr = [];
    closeAddCategoryDialog();
  };

  // 추가하는
  const addCategoryFunc = (arr, newValue) => {
    if (arr[0].hasOwnProperty("item")) {
      alert("항목에는 추가할 수 없습니다.");
      return;
    }
    // 카테고리 추가
    if (addCategoryIndex === 0) {
      if (arr[addCategoryIndex].hasOwnProperty("items")) {
        const findId = arr[addCategoryIndex].id;
        // items에 추가
        const tArr = [
          ...arr[addCategoryIndex].items,
          { name: newValue, items: [], id: uuid(), open: false }
        ];

        // 현재 해당하는 부모에서 자식 변경
        const ttArr = arr.map(d => {
          if (d.id === findId) {
            return { name: d.name, items: tArr, id: d.id, open: d.open };
          } else {
            return d;
          }
        });
        const tttArr = addCategoryArr.map(d => {
          if (d.id === findId) {
            return {
              name: d.name,
              items: [...ttArr[addCategoryIndex].items],
              id: d.id,
              open: d.open
            };
          } else {
            return d;
          }
        });
        addCategoryIndex++;

        addCategoryFunc(tttArr, newValue);
      } else {
        const findId = arr[addCategoryIndex].id;

        // 새로운 아이템 배열 생성
        const newItem = { name: newValue, item: {}, id: uuid(), open: false };

        const tArr = arr.map(d => {
          if (d.id === findId) {
            return { name: d.name, items: newItem, id: d.id, open: d.open };
          } else {
            return d;
          }
        });

        addCategoryIndex++;
        addCategoryFunc(tArr, newValue);
        return;
      }
    }
    // 부모에서 추가된 자식을 변경
    else {
      let findId = arr[addCategoryIndex - 1].id;

      // 최종본
      if (addCategoryIndex === addCategoryArr.length) {
        const findId = arr[addCategoryIndex - 1].id;
        const tArr = data.map(d => {
          if (d.id === findId) {
            return {
              name: d.name,
              items: [...arr[addCategoryIndex - 1].items],
              id: d.id,
              open: d.open
            };
          } else {
            return d;
          }
        });
        onChange(tArr);
        return;
      }
      // 자식을 교체
      const tArr = addCategoryArr[addCategoryIndex].items.map(d => {
        if (d.id === findId) {
          return {
            name: d.name,
            items: [...arr[addCategoryIndex - 1].items],
            id: d.id,
            open: d.open
          };
        } else {
          return d;
        }
      });

      // 교체한 자식을 부모에 추가
      findId = arr[addCategoryIndex].id;
      const ttArr = arr.map(d => {
        if (d.id === findId) {
          return { name: d.name, items: [...tArr], id: d.id, open: d.open };
        } else {
          return d;
        }
      });
      addCategoryIndex++;

      addCategoryFunc(ttArr, newValue);
    }
  };

  const addCategoryFind = data => {
    if (addCategoryIndex < index.length) {
      addCategoryIndex++;
      addCategoryArr.push({ ...data[index[addCategoryIndex]] });
      if (addCategoryIndex === index.length - 1) {
        return;
      } else {
        addCategoryFind(data[index[addCategoryIndex]].items);
      }
    }
  };
  // ------------------------------------ 카테고리 추가 끝

  // ------------------------------------ 항목 추가 시작
  const addItemSave = newValue => {
    if (id === "부모") {
      onChange([
        ...data,
        { name: newValue, item: {}, id: uuid(), open: false }
      ]);
    } else {
      addItemFind(data);
      addItemArr.reverse();
      addItemIndex = 0;

      addItemFunc(addItemArr, newValue);
    }
    addItemIndex = -1;
    addItemArr = [];
    closeAddItemDialog();
  };

  const addItemFunc = (arr, newValue) => {
    if (arr[0].hasOwnProperty("item")) {
      alert("항목에는 추가할 수 없습니다.");
      return;
    }
    // 카테고리 추가
    if (addItemIndex === 0) {
      if (arr[addItemIndex].hasOwnProperty("items")) {
        const findId = arr[addItemIndex].id;
        // items에 추가
        const tArr = [
          ...arr[addItemIndex].items,
          { name: newValue, item: {}, id: uuid(), open: false }
        ];

        // 현재 해당하는 부모에서 자식 변경
        const ttArr = arr.map(d => {
          if (d.id === findId) {
            return { name: d.name, items: tArr, id: d.id, open: d.open };
          } else {
            return d;
          }
        });
        const tttArr = addItemArr.map(d => {
          if (d.id === findId) {
            return {
              name: d.name,
              items: [...ttArr[addItemIndex].items],
              id: d.id,
              open: d.open
            };
          } else {
            return d;
          }
        });
        addItemIndex++;

        addItemFunc(tttArr, newValue);
      } else {
        const findId = arr[addItemIndex].id;

        // 새로운 아이템 배열 생성
        const newItem = [{ name: newValue, item: {}, id: uuid(), open: false }];

        const tArr = arr.map(d => {
          if (d.id === findId) {
            return { name: d.name, items: newItem, id: d.id, open: d.open };
          } else {
            return d;
          }
        });

        addItemIndex++;
        addItemFunc(tArr, newValue);
        return;
      }
    }
    // 부모에서 추가된 자식을 변경
    else {
      let findId = arr[addItemIndex - 1].id;

      // 최종본
      if (addItemIndex === addItemArr.length) {
        const findId = arr[addItemIndex - 1].id;
        const tArr = data.map(d => {
          if (d.id === findId) {
            return {
              name: d.name,
              items: [...arr[addItemIndex - 1].items],
              id: d.id,
              open: d.open
            };
          } else {
            return d;
          }
        });
        onChange(tArr);
        return;
      }
      // 자식을 교체
      const tArr = addItemArr[addItemIndex].items.map(d => {
        if (d.id === findId) {
          return {
            name: d.name,
            items: [...arr[addItemIndex - 1].items],
            id: d.id,
            open: d.open
          };
        } else {
          return d;
        }
      });

      // 교체한 자식을 부모에 추가
      findId = arr[addItemIndex].id;
      const ttArr = arr.map(d => {
        if (d.id === findId) {
          return { name: d.name, items: [...tArr], id: d.id, open: d.open };
        } else {
          return d;
        }
      });
      addItemIndex++;

      addItemFunc(ttArr, newValue);
    }
  };

  const addItemFind = data => {
    if (addItemIndex < index.length) {
      addItemIndex++;
      addItemArr.push({ ...data[index[addItemIndex]] });
      if (addItemIndex === index.length - 1) {
        return;
      } else {
        addItemFind(data[index[addItemIndex]].items);
      }
    }
  };
  // ------------------------------------ 항목 삭제 끝

  // ------------------------------------ 트리 삭제 시작
  const delTreeSave = () => {
    delFind(data);
    delArr.reverse();

    delIndex = 1;
    console.log(delArr);
    // 최상단을 클릭한 경우
    if (delArr.length === 1) {
      const findId = delArr[0].id;
      const tData = data.filter(d => d.id !== findId);
      onChange([...tData]);
    }
    // 그 외의 경우
    else filterDelFunc(delArr);

    delArr = [];
    delIndex = -1;
    closeDeleteDialog();
  };

  const filterDelFunc = arr => {
    // 선택한 것 부모 items에서 제거
    if (delIndex === 1) {
      const tArr = arr[delIndex].items.filter(d => d.id !== id);
      arr[delIndex].items = tArr;
      delIndex++;
      filterDelFunc(arr);
    }
    // 해당 부모에서 자식을 교체
    else {
      if (delIndex === delArr.length) {
        const findId = arr[delIndex - 1].id;
        const tData = data.map(d => {
          if (d.id === findId) {
            return { ...arr[delIndex - 1] };
          } else {
            return d;
          }
        });
        onChange([...tData]);

        return;
      }
      const findId = arr[delIndex - 1].id;
      // 해당 부모에서 자식을 교체
      const tArr = arr[delIndex].items.map(d => {
        if (d.id === findId) {
          return { ...arr[delIndex - 1] };
        } else {
          return d;
        }
      });
      // 교체한 자식을 부모에 삽입
      arr[delIndex].items = tArr;
      delIndex++;
      filterDelFunc(arr);
    }
  };

  const delFind = data => {
    if (delIndex < index.length) {
      delIndex++;
      delArr.push({ ...data[index[delIndex]] });
      if (delIndex === index.length - 1) {
        return;
      } else {
        delFind(data[index[delIndex]].items);
      }
    }
  };
  // ------------------------------------ 트리 삭제 끝

  // ------------------------------------ 트리 수정 시작
  const updateSave = newValue => {
    updateFind(data);
    updateArr.reverse();

    updateCopy(updateArr, 0, newValue);

    // 최상단이 아닌 경우
    if (updateArr.length !== 1) {
      const tt = data.map(d => {
        if (d.id === updateArr[updateArr.length - 1].id) {
          return {
            name: d.name,
            items: [...updateArr[updateArr.length - 1].items],
            id: d.id,
            open: d.open
          };
        } else {
          return d;
        }
      });

      onChange(tt);
    }
    // 최상단인 경우, updateArr 길이가 1임
    else {
      const tt = data.map(d => {
        if (d.id === id) {
          return {
            name: updateArr[0].name,
            items: [...updateArr[0].items],
            id: d.id,
            open: d.open
          };
        } else {
          return d;
        }
      });
      onChange(tt);
    }
    updateArr = [];
    updateIndex = -1;
    closeUpdateDialog();
  };

  const updateCopy = (arr, i, newValue) => {
    // map 으로 위치만 변경
    if (i === 0) {
      const tObj = { ...arr[i] };
      let index = -1;
      tObj.name = newValue;

      const t = arr.map((d, i) => {
        if (d.id === id) {
          index = i;
          return { ...tObj };
        } else return d;
      });

      // 나 자신 변경
      const tArr = updateArr.map((d, i) => {
        if (i === index) return { ...t[i] };
        else return d;
      });

      // 최상단을 클릭한 경우
      if (i + 1 === arr.length && i === 0) {
        const tArr = arr.map(d => {
          return {
            name: newValue,
            items: [...d.items],
            id: d.id,
            open: d.open
          };
        });
        updateArr = [...tArr];
        return;
      }

      // 그 부모에서 내가 들어있는 곳 변경
      const ttArr = tArr[i + 1].items.map(d => {
        if (d.id === id) {
          if (d.hasOwnProperty("item"))
            return {
              name: newValue,
              item: { ...d.item },
              id: d.id,
              open: d.open
            };
          else
            return {
              name: newValue,
              items: [...d.items],
              id: d.id,
              open: d.open
            };
        } else return d;
      });

      const tttArr = tArr.map((d, ind) => {
        if (ind === i + 1) {
          return { name: d.name, items: [...ttArr], id: d.id, open: d.open };
        } else {
          return d;
        }
      });
      if (arr.length - 1 !== i) {
        i++;
        updateCopy(tttArr, i);
      }
    }
    // filter로 갈아치기
    else {
      const findId = arr[i].id;

      if (i + 1 === arr.length) {
        updateArr = [...arr];
        return;
      }

      const tArr = arr[i + 1].items.map(d => {
        if (d.id === findId) {
          return {
            name: d.name,
            items: [...arr[i].items],
            id: d.id,
            open: d.open
          };
        } else {
          return d;
        }
      });

      const ttArr = arr.map((d, ind) => {
        if (ind === i + 1) {
          return { name: d.name, items: [...tArr], id: d.id, open: d.open };
        } else {
          return d;
        }
      });

      if (arr.length - 1 !== i) {
        i++;
        updateCopy(ttArr, i);
      }
    }
  };

  const updateFind = data => {
    if (updateIndex < index.length) {
      updateIndex++;
      updateArr.push({ ...data[index[updateIndex]] });
      if (updateIndex === index.length - 1) {
        return;
      } else {
        updateFind(data[index[updateIndex]].items);
      }
    }
  };
  // ------------------------------------ 트리 수정 끝

  // 최종 항목 출력
  const returnFunction = (arr, index, i) => {
    if (index.length === i) {
      onClickItem(arr.item);
      return arr.item;
    } else {
      if (i === 0) {
        returnFunction(arr[index[i]], index, i + 1);
      } else {
        returnFunction(arr.items[index[i]], index, i + 1);
      }
    }
  };

  useEffect(() => {
    setData(treeData);
  }, [treeData]);

  // 이렇게 했던 이유가 파라미터에 쓸모 없는 변수가 들어가 있다고 생각함.

  return (
    <Box>
      <Grid container className={classes.container}>
        <Grid item>
          <CCTooltip title={<Box>수정</Box>}>
            <CCIconButton onClick={openUpdateDialog}>
              <SearchIcon />
            </CCIconButton>
          </CCTooltip>
          <CCTooltip title={<Box>삭제</Box>}>
            <CCIconButton onClick={openDeleteDialog}>
              <SelectPersonIcon />
            </CCIconButton>
          </CCTooltip>
          <CCTooltip title={<Box>카테고리 추가</Box>}>
            <CCIconButton onClick={openAddCategoryDialog}>
              <SettingIcon />
            </CCIconButton>
          </CCTooltip>
          <CCTooltip title={<Box>항목 추가</Box>}>
            <CCIconButton onClick={openAddItemDialog}>
              <SignoutIcon />
            </CCIconButton>
          </CCTooltip>
          {fold && (
            <CCTooltip title={<Box>접기</Box>}>
              <CCIconButton onClick={foldTree}>
                <SpreadoverIcon />
              </CCIconButton>
            </CCTooltip>
          )}
          {spread && (
            <CCTooltip title={<Box>열기</Box>}>
              <CCIconButton onClick={openTree}>
                <StatisticsIcon />
              </CCIconButton>
            </CCTooltip>
          )}
        </Grid>
        <Grid item onClick={changeId}>
          부모
        </Grid>
        <Grid item ref={rootRef} id={"tree"}>
          {displayTree(data, 10)}
        </Grid>
      </Grid>

      {openUpdate && (
        <CCDialogSimpleAddMod
          open={openUpdate}
          onClose={closeUpdateDialog}
          title={"수정"}
          value={text}
          onSave={updateSave}
          onCancel={closeUpdateDialog}
        />
      )}
      {openDelete && (
        <CCDialogSimpleDel
          open={openDelete}
          onClose={closeDeleteDialog}
          onAgree={delTreeSave}
          onDisagree={closeDeleteDialog}
        />
      )}
      {openAddCategory && (
        <CCDialogSimpleAddMod
          open={openAddCategory}
          onClose={closeAddCategoryDialog}
          title={"추가"}
          onSave={addCategorySave}
          onCancel={closeAddCategoryDialog}
        />
      )}
      {openAddItem && (
        <CCDialogSimpleAddMod
          open={openAddItem}
          onClose={closeAddItemDialog}
          title={"추가"}
          onSave={addItemSave}
          onCancel={closeAddItemDialog}
        />
      )}
    </Box>
  );
};

export default Tree;
