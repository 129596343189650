import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  CVFlattenDateField,
  CVIconButton,
  DialogWarningMessage,
  PermissionBackdrop
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CCPaper,
  CCTextFieldNumber,
  CCTypography
} from "styles/src/components";
import { getMomentFormatTimestamp, getTimestamp } from "utils/datetime";
import { AddIcon } from "styles/src/themes/common/icons";
import { useIntl } from "utils/language";
import { CCTable } from "styles/src/components";
import * as moment from "moment";

const head__height = 40;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "95%"
  },
  receipt_paper: {
    position: "relative",
    height: "inherit",
    width: "inherit"
  },
  receipt_table: {
    marginTop: "10px",
    height: "150px"
  },
  receipt_container: {
    height: head__height
  },
  reception__item: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 8
  },

  reception__subitem: {
    marginTop: 8,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 8
  },
  mouse__hover: {
    cursor: "pointer"
  },
  table: {
    height: `calc(100% - ${head__height}px + 1px)`,
    position: "relative"
  },
  date__field: {
    width: 150
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  }
}));

//For DetailDialog > DetailModify
const ExpireTable = props => {
  console.log("DetailDialog > DetailModify > ExpireTable");
  const intl = useIntl();
  const { data, expire, currentCnt, value, onChange } = props;
  console.log("DetailDialog > DetailModify > ExpireTable { data, expire, currentCnt, value, onChange }", { data, expire, currentCnt, value, onChange });

  const classes = useStyles();
  // const permission = "desk";
  const [total, setTotal] = useState(currentCnt);
  const [alrDialog, setAlrDialog] = useState(false);
  const [zeroDialog, setZeroDialog] = useState(false);
  const [exceedDialog, setExceedDialog] = useState(false);
  console.log("DetailDialog > DetailModify > ExpireTable total", total);

  const onAdd = () => {
    if (currentCnt - total > 0) {
      console.log("DetailDialog > DetailModify > ExpireTable onAdd currentCnt - total > 0");
      onChange([
        ...value,
        {
          expireDate: getTimestamp(),
          expireCount: 0
        }
      ]);
    } else {
      console.log("DetailDialog > DetailModify > ExpireTable onAdd currentCnt - total <= 0");
      setZeroDialog({
        title: intl.formatMessage(
          { id: "alrInsertExpireInfo" },
          { br: () => <br /> }
        ),
        onAgree: () => {
          setZeroDialog(null);
        }
      });
    }
  };
  const onDelete = ({ rowIndex }) => {
    if (value?.length > 1) {
      onChange(value.filter((_, index) => index !== rowIndex));
    } else {
      setAlrDialog({
        title: intl.formatMessage(
          { id: "informNoExpire" },
          { br: () => <br /> }
        ),
        onAgree: () => {
          setAlrDialog(null);
        }
      });
    }
  };

  const onModify = useCallback(
    row => {
      console.log("DetailDialog > DetailModify > ExpireTable onModify");
      const { option, rowIndex, e } = row;
      const counts = value.map(item => item?.expireCount);
      const dates = value.map(item => item?.expireDate);
      const ModMap = [];

      for (let i = 0; i < value.length; i++) {
        if (i === rowIndex) {
          if (option) {
            dates[rowIndex] = e;
          } else {
            counts[rowIndex] = e;
          }
        }
        ModMap.push({
          expireDate: dates[i],
          expireCount: counts[i]
        });
      }
      console.log("DetailDialog > DetailModify > ExpireTable onModify ModMap", ModMap);
      onChange(ModMap);
    },
    [onChange, value]
  );

  const heads = useMemo(() => {
    return [
      {
        key: "expireDate",
        value: "expireDate",
        width: 180,
        component: ({ rowIndex }) => (
          <CVFlattenDateField
            label={intl.formatMessage({ id: "expireDate" })}
            // permission={permission}
            margin={"dense"}
            variant={"outlined"}
            value={getMomentFormatTimestamp(value[rowIndex].expireDate, "X")}
            onChange={e => onModify({ option: true, rowIndex, e })}
          />
        )
      },
      {
        key: "expireCount",
        value: "expireCount",
        width: 150,
        component: ({ rowIndex }) => (
          <CCTextFieldNumber
            label={intl.formatMessage({ id: "inventoryCount" })}
            number={value[rowIndex].expireCount}
            decimalPoint={0}
            step={1}
            onChange={e => onModify({ option: false, rowIndex, e })}
          />
        )
      }
    ];
  }, [intl, onModify, value]);

  useEffect(() => {
    console.log("DetailDialog > DetailModify > ExpireTable useEffect ");
    if (Boolean(value?.length > 0)) {
      console.log("DetailDialog > DetailModify > ExpireTable useEffect value?.length > 0");
      const newTotal = Number(
        value
          .map(e => e.expireCount)
          .reduce(
            (acc, cur) => (cur > 0 ? Number(acc) + Number(cur) : Number(acc)),
            0
          )
      )
      console.log("DetailDialog > DetailModify > ExpireTable useEffect value?.length > 0 >>> newTotal", newTotal);
      setTotal(newTotal);
      if (currentCnt - total < 0 && currentCnt !== 0) {
        console.log("DetailDialog > DetailModify > ExpireTable useEffect (currentCnt - total < 0 && currentCnt !== 0)");
        onChange(
          value.map(e => {
            return {
              expireDate: e?.expireDate,
              expireCount: 0
            };
          })
        );

        setTotal(0);
        setExceedDialog({
          title: intl.formatMessage(
            { id: "inputCntExceedCurrentCount" },
            { count: currentCnt }
          ),
          onAgree: () => {
            setExceedDialog(null);
          }
        });
      }
    }
  }, [value, currentCnt, total, expire, intl, onChange]);
  return (
    <Box className={clsx(classes.root)}>
      <CCPaper className={clsx(classes.receipt_paper)}>
        {/* <PermissionBackdrop permission={permission} /> */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={clsx(classes.receipt_container)}
        >
          <Grid item className={clsx(classes.reception__item)}>
            <Grid container alignItems={"center"}>
              <Grid
                item
                className={clsx(classes.reception__item)}
                style={{ paddingLeft: 8 }}
              >
                <CCTypography variant={"h4"}>
                  {intl.formatMessage({ id: "expireDateSetting" })}
                </CCTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={clsx(classes.reception__item)}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item className={clsx(classes.reception__item)}>
                <CVIconButton
                  onClick={() => {
                    if (data?.storage?.currentCnt === 0) {
                      setZeroDialog({
                        title: intl.formatMessage(
                          { id: "alrInsertExpireInfo" },
                          { br: () => <br /> }
                        ),
                        onAgree: () => {
                          setZeroDialog(null);
                        }
                      });
                    } else {
                      onAdd();
                    }
                  }}
                >
                  <AddIcon />
                </CVIconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={clsx(classes.reception__item)}>
          <Grid container alignItems={"center"}>
            <Grid
              item
              className={clsx(classes.reception__subitem)}
              style={{ paddingLeft: 8 }}
            >
              <CCTypography>
                {`${intl.formatMessage({ id: "leftCntforSetting" })} : ${currentCnt - total >= 0 ? currentCnt - total : 0
                  }`}
              </CCTypography>
            </Grid>
          </Grid>
        </Grid>

        <Box className={clsx(classes.table)}>
          <CCTable
            // permission={permission}
            heads={heads.map(item => ({ ...item, className: classes.cell }))}
            contents={value}
            onDelClick={onDelete}
            className={classes.receipt_table}
          />
        </Box>
      </CCPaper>
      <DialogWarningMessage open={Boolean(alrDialog)} {...alrDialog} />
      <DialogWarningMessage open={Boolean(exceedDialog)} {...exceedDialog} />
      <DialogWarningMessage open={Boolean(zeroDialog)} {...zeroDialog} />
    </Box>
  );
};
export default ExpireTable;
