import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import { YearPicker } from "components";
import React from "react";
import { SmalldownIcon, SmallupIcon } from "styles/icons";
import {
  getLocalTimeFromTimestamp,
  unixCloneAddTimestamp,
  unixCloneSubtractTimestamp
} from "utils/datetime";
import { CAPITALIZE_TYPE, useIntl } from "utils/language";

const useStyles = makeStyles(theme => ({
  textfield__year: {
    width: 120,
    margin: 0,
    marginRight: "-1px !important"
  },
  textfield__month: {
    width: 120,
    height: 36,
    margin: 0,
    marginRight: 8
  },
  input__year: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
    backgroundColor: theme.palette.common.white,
    "&.Mui-disabled": {
      backgroundColor: theme.palette.inactive.rgba
      // backgroundColor: theme.palette.disabled
    },
    "& > fieldset": {
      borderRightWidth: 0
    },
    "& > input": {
      "&.Mui-disabled": {
        backgroundColor: "unset"
      }
    }
  },
  input__month: {
    height: 36,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: theme.palette.common.white,
    "&.Mui-disabled": {
      backgroundColor: theme.palette.inactive.rgba
      // backgroundColor: theme.palette.disabled
    },
    "& > div": {
      "&.Mui-disabled": {
        backgroundColor: "unset"
      }
    }
  },
  end__adornment: {
    flexDirection: "column",
    margin: 0,
    height: "auto",
    "&:hover": {
      cursor: "pointer"
    }
  },
  up: {
    marginBottom: "5px",
    color: theme.palette.icon
  },
  down: {
    color: theme.palette.icon
  },
  icon__disabled: {
    color: theme.palette.disabled
  }
}));

const YearMonthPicker = props => {
  const classes = useStyles();
  const intl = useIntl();
  const { value, onChange } = props;

  return (
    <>
      <YearPicker
        className={classes.textfield__year}
        value={value}
        onChange={value => {
          onChange && onChange(value);
        }}
      />
      <TextField
        className={classes.textfield__month}
        variant="outlined"
        margin="dense"
        value={getLocalTimeFromTimestamp(
          value,
          intl.formatMessage(
            { id: "monthSelectFormat" },
            {},
            { capitalizeType: CAPITALIZE_TYPE.ALL_UPPER }
          )
        )}
        InputProps={{
          className: classes.input__month,
          "aria-readonly": "true",
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end" className={classes.end__adornment}>
              <SmallupIcon
                className={clsx(classes.up, {})}
                onClick={event => {
                  onChange &&
                    onChange({
                      target: {
                        name: "periodMonth",
                        value: unixCloneAddTimestamp(value, 1, "month")
                      }
                    });
                }}
              />
              <SmalldownIcon
                className={clsx(classes.down, {})}
                onClick={event => {
                  onChange &&
                    onChange({
                      target: {
                        name: "periodMonth",
                        value: unixCloneSubtractTimestamp(value, 1, "month")
                      }
                    });
                }}
              />
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

export default YearMonthPicker;
