const RECEIPT_STATUS = {
  RECEIPT_SELECT: {
    value: "0",
    localeKey: "receiptStatus.receiptSelect"
  },
  RECEIPT: { value: "1", localeKey: "receiptStatus.receipt" },
  TREATMENT_IN_PROGRESS: {
    value: "2",
    localeKey: "receiptStatus.treatmentInProgress"
  },
  TREATMENT_COMPLETE: {
    value: "3",
    localeKey: "receiptStatus.treatmentComplete"
  },
  STORAGE_COMPLETE: {
    value: "4",
    localeKey: "receiptStatus.storageComplete"
  },
  RECEIPT_DELETE: {
    value: "5",
    localeKey: "receiptStatus.receiptDelete"
  }
  // CHECK_UP: { value: "6", localeKey: "receiptStatus.checkUp" }
};
const ORALEXAM_TYPES = {
  NONE: {
    value: "0",
    localeKey: "oralexamType.none"
  },
  GENERAL: {
    value: "1",
    localeKey: "oralexamType.general"
  },
  ALL_LIFE: {
    value: "2",
    localeKey: "oralexamType.allLife"
  },
  INFANT_EIGHTEEN_TO_TWENTY_NINE: {
    value: "3",
    localeKey: "oralexamType.infantEighteenToTwentyNine"
  },
  INFANT_FORTY_TWO_TO_FIFTY_THREE: {
    value: "4",
    localeKey: "oralexamType.infantFortyTwoToFiftyThree"
  },
  INFANT_FIFTY_FOUR_TO_SIXTY_FIVE: {
    value: "5",
    localeKey: "oralexamType.infantFiftyFourToSixtyFive"
  },
  OUT_OF_SCHOOL: {
    value: "6",
    localeKey: "oralexamType.outOfSchool"
  }
};

const PATIENT_TYPE = {
  INIT_PATIENT: {
    value: "0",
    localeKey: "patientType.initPatient"
  },
  NEW_PATIENT: {
    value: "1",
    localeKey: "patientType.newPatient"
  },
  EXISTING_PATIENT: {
    value: "2",
    localeKey: "patientType.existingPatient"
  }
};

export { RECEIPT_STATUS, ORALEXAM_TYPES, PATIENT_TYPE };
export default RECEIPT_STATUS;
