import { useQuery } from "@apollo/react-hooks";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { GET_LAB_ORDER_DETAIL } from "queries/labOrder";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { LAB_ORDER_TYPE } from "types/labOrder";
import { makeIndexList } from "types/utils";
import Viewer from "./components/Viewer";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: { width: 1000, height: 700 }
}));

const LabOrderPrint = props => {
  const { labOrderId } = props;

  const classes = useStyles();
  const intl = useIntl();
  const orderTypeIndex = makeIndexList(LAB_ORDER_TYPE);

  const [state, setState] = useState({ hospital: "", data: "" });

  useQuery(GET_LAB_ORDER_DETAIL, {
    variables: {
      id: labOrderId
    },
    onCompleted: data => {
      const item = data.orderDetail;
      setState({
        ...state,
        data: {
          ...item,
          type: translate(intl, orderTypeIndex[item?.type])
        },
        hospital: item.clinic
      });
    }
  });

  return (
    <Box className={clsx(classes.root)}>
      {state.hospital && state.data && (
        <Viewer
          data={{
            id: state.data?.labId,
            labName: state.data?.labName,
            doctorName: state.data?.doctor,
            patient: { ...state.data?.patient },
            data: [state.data]
          }}
          hospital={state.hospital}
        />
      )}
    </Box>
  );
};

LabOrderPrint.defaultProps = {};

LabOrderPrint.propTypes = {};

export default LabOrderPrint;
