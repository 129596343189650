import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

const useStyles = makeStyles(theme => ({
  root: { borderRadius: 8, overflow: "hidden" },
  elevation: { border: `1px solid ${theme.palette.border.main}` },
  select: { borderColor: theme.palette.select.main }
}));

const CCPaper = forwardRef((props, ref) => {
  const { classes: userClasses, className, type, ...others } = props;
  const classes = useStyles();
  const { variant, elevation } = (type => {
    if (type === "normal") return { variant: "elevation", elevation: 0 };
    if (type === "low") return { variant: "elevation", elevation: 2 };
    if (type === "deep") return { variant: "elevation", elevation: 3 };
    return { variant: "outlined", elevation: 0 };
  })(type);

  return (
    <Paper
      classes={{
        root: clsx(className && className, userClasses && userClasses.root, {
          [classes.root]: true,
          [classes.select]: type === "active",
          [classes.elevation]: type !== "flat" && type !== "active"
        })
      }}
      variant={variant}
      elevation={elevation}
      ref={ref}
      {...others}
    >
      {props.children}
    </Paper>
  );
});

CCPaper.propTypes = {
  /** Paper Type */
  type: PropTypes.oneOf(["flat", "normal", "low", "deep", "active"])
};

CCPaper.defaultProps = {
  type: "normal"
};

export default CCPaper;
