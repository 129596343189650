// 사용법 : key에는 JSON data의 key 값이 들어가며, origin에는 JSON data가 들어갑니다.
// ex) getJsonParse("patient.name", rowData);
const getJsonParse = (key, origin) => {
  const splitKey = key.split(/[,[\].]+?/);
  if (!Boolean(origin)) return null;
  if (splitKey.length > 1) {
    const jsonValue = splitKey.reduce((result, key) => {
      if (!Boolean(result)) return null;
      if (typeof result === "string") {
        return Boolean(origin[result]) ? origin[result][key] : null;
      }
      if (typeof result === "object") {
        return Boolean(result[key]) ? result[key] : null;
      }
      return null;
    });
    return jsonValue;
  }
  return origin[key];
};

export { getJsonParse };
