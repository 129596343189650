import React from "react";

const ReflectrightIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M13 2v20h-1V2h1zm9 1v18l-7-9 7-9zM3 3l7 9-7 9V3z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default ReflectrightIcon;
