/*eslint react-hooks/exhaustive-deps: 0*/
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CCTextField } from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import { CCFlattenDateField } from "styles/src/components/CCFlattenDateTimePicker";
import CCPaper from "styles/src/components/CCPaper";
import CCTypography from "styles/src/components/CCTypography";
import { SearchIcon } from "styles/src/themes/common/icons";
import {
  getCustomFormat,
  getEndTimestamp,
  getMomentFormatTimestamp
} from "utils/datetime";
import { useIntl } from "utils/language";
import { Box, FormControlLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeList } from "utils/type";
import SearchResults from "./Component/SearchResults";
import { ALL, ORDER_STATUS, RETURN_STATUS } from "types/inventory";
import axios from "axios";
import { RadioGroup } from "components";
import ProductAddDialog from "./Component/SearchResults/Component/ProductAddDialog";
import { DBVendorContext } from "context/DBContext/components";

const useStyle = makeStyles(theme => ({
  init: {
    position: "relative",
    height: "calc(100% - 70px)",
    padding: 16,
    backgroundColor: theme.palette.common.white
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: 8,
    marginBottom: 16,
    minWidth: 1400
  },
  input: {
    backgroundColor: theme.palette.background.paper
  },
  timeInput: {
    width: 130
  },
  selectInput: {
    width: 142
  },
  memoInput: {
    width: 236
  },
  marginLeft8: {
    marginLeft: 8
  },
  description: {
    display: "flex",
    marginLeft: "10px",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px"
  },
  paper: {
    width: "100%",
    height: props => {
      if (props?.collapse && props?.collapseRef?.current) {
        return `calc(100% - ${props?.collapseRef?.current.offsetHeight}px)`;
      } else {
        return `calc(100% - 110px)`;
      }
    },
    minWidth: 1000
  },
  select__field: {
    marginLeft: 20,
    width: 200
  },
  small__button: {
    "&:hover": {
      backgroundColor: theme.palette.select.main
    }
  },
  selected: {
    backgroundColor: theme.palette.select.main,
    cursor: "default"
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  border: {
    display: "flex",
    marginLeft: "10px",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px"
  }
}));

const OrderReturnTab = () => {
  const classes = useStyle();

  const _url = process.env.REACT_APP_PAM_URL;
  const [order, setOrder] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [requestDialog, setRequestDialog] = useState(false);
  const [lastKey, setLastKey] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [filter, setFilter] = useState({});
  // const { dbVendorContext } = useContext(DBVendorContext);

  const [date, setDate] = useState({
    begin: moment()
      .subtract(3, "month")
      .add(1, "day")
      .unix(),
    end: getEndTimestamp("day")
  });

  const intl = useIntl();
  const ALL_SELECTION = intl.formatMessage({ id: "all" });
  const initSelectedValue = {
    major: [],
    minor: null,
    name: "",
    alarm: null,
    stock: false,
    status: { value: "0" },
    noStock: {},
    vendor: [ALL_SELECTION]
  };

  const [selectedValue, setSelectedValue] = useState(initSelectedValue);
  const OrderStatus = makeList(ORDER_STATUS);
  const ReturnStatus = makeList(RETURN_STATUS);
  const All = makeList(ALL);

  const searchOrder = async props => {
    let variables = {
      offset: 0,
      limit: 20,
      types: ["Order", "Return"]
    };

    if (props) {
      variables.startDate = props.args.startDate;
      variables.endDate = props.args.endDate;

      if (props?.args?.status) {
        variables.status = props.args.status;
      }

      if (props.args?.materialId) {
        variables.materialId = props.args.materialId;
      }
      if (props.args?.keyword) {
        variables.keywordType = props.args.keywordType;
        variables.keyword = props.args.keyword;
      }
      variables.offset = props.lastKey;
    }
    try {
      axios({
        method: "POST",
        url: `${_url}/api/v1/order/list`,
        data: variables,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          if (response?.data?.count > order?.length) {
            setOrder(prevState =>
              prevState
                ? prevState.concat(response?.data?.rows)
                : response?.data?.rows
            );
            setHasNext(true);
          } else {
            setHasNext(false);
          }
        })

        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
  };

  //dbVendorContext
  useEffect(() => {
    fetchSupplier();
  }, []);

  const periodFilter = count => {
    return (
      date.begin ===
        moment()
          .subtract(count, "month")
          .add(1, "day")
          .unix() && date.end === getEndTimestamp("day")
    );
  };

  const fetchSupplier = useCallback(async () => {
    try {
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_PAM_URL
        }/api/v1/clinic/${sessionStorage.getItem("clinicId")}/material`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          if (response?.data?.length > 0) {
            setVendor(response?.data);
          }
        })

        .catch(err => {});
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onMoreContents = useCallback(() => {
    let variables = {};
    variables.args = filter;
    variables.lastKey = lastKey;

    searchOrder(variables);
  }, [filter, lastKey]);

  // useEffect(() => {
  //   //TODO: 검색 및 새 견적 추가 시 context 이용하여 목록 새로고침
  //   if (filter && dbVendorContext) {
  //     let variables = {};
  //     variables = { args: filter, lastKey: lastKey };
  //     searchOrder(variables);
  //   } else {
  //     searchOrder();
  //   }
  // }, [filter, dbVendorContext]);

  useEffect(() => {
    setLastKey(order?.length);
  }, [order]);

  return (
    <>
      <Grid className={classes.init}>
        <CCPaper className={classes.root}>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            wrap={"nowrap"}
          >
            {/*DONE : 주문 상태에 따라 검색*/}
            <Grid
              item
              style={{
                paddingLeft: 12,
                paddingRight: 12,
                minHeight: 54,
                height: 54
              }}
            >
              <Grid
                container
                alignItems={"center"}
                style={{ height: "100%" }}
                direction="row"
              >
                <Grid item className={classes.item}>
                  <Box>
                    <FormControlLabel
                      control={
                        <>
                          <RadioGroup
                            tabList={All}
                            value={selectedValue?.status?.value}
                            onChange={e => {
                              setSelectedValue({
                                ...selectedValue,
                                stock: null,
                                status: { value: "0" }
                              });
                            }}
                          />
                          <Box className={classes.border} />
                          <RadioGroup
                            tabList={OrderStatus}
                            value={selectedValue?.status?.value}
                            onChange={e => {
                              setSelectedValue({
                                ...selectedValue,
                                stock: null,
                                status: e
                              });
                            }}
                            required={true}
                            defaultValue={0}
                          />
                          <Box className={classes.border} />
                          <RadioGroup
                            tabList={ReturnStatus}
                            value={selectedValue?.status?.value}
                            onChange={e => {
                              console.log(
                                "VALUE several VALUE >2",
                                e,
                                e?.label
                              );

                              setSelectedValue({
                                ...selectedValue,
                                stock: null,
                                status: e
                              });
                            }}
                            required={true}
                            defaultValue={0}
                          />
                        </>
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} wrap={"nowrap"}>
                <CCTypography variant={"h6"} style={{ width: "130px" }}>
                  {intl.formatMessage({ id: "inquiryPeriod" })}
                </CCTypography>

                {/* DONE : 3개월 Default =>
                   3개월 버튼을 클릭하면 => (옳은 결과) 8/1~10/31, (틀린 결과) 7/31~10/31*/}
                <Grid item>
                  <CCFlattenDateField
                    label={intl.formatMessage({ id: "dateText" })}
                    onChange={e => {
                      setDate({
                        ...date,
                        begin: e.startOf("day").unix()
                      });
                    }}
                    value={
                      date?.begin
                        ? getMomentFormatTimestamp(date.begin, "X")
                        : false
                    }
                    className={clsx(classes.input, classes.timeInput)}
                  />
                </Grid>
                <Grid item>{"~"}</Grid>
                <Grid item>
                  <CCFlattenDateField
                    label={intl.formatMessage({ id: "dateText" })}
                    onChange={e => {
                      setDate({
                        ...date,
                        end: e.endOf("day").unix()
                      });
                    }}
                    value={
                      date?.end
                        ? getMomentFormatTimestamp(date.end, "X")
                        : false
                    }
                    className={clsx(classes.input, classes.timeInput)}
                  />
                </Grid>
                <Grid item className={classes.marginLeft8}>
                  <CCButton
                    variant={"contained"}
                    size={"small"}
                    color={"normal"}
                    className={clsx(classes.small__button, {
                      [classes.selected]: periodFilter(1)
                    })}
                    onClick={() => {
                      setDate({
                        begin: moment()
                          .subtract(1, "month")
                          .add(1, "day")
                          .unix(),
                        end: getEndTimestamp("day")
                      });
                    }}
                  >
                    {intl.formatMessage({ id: "monthUnit" }, { number: 1 })}
                  </CCButton>
                </Grid>
                <Grid item className={classes.marginLeft8}>
                  <CCButton
                    variant={"contained"}
                    size={"small"}
                    color={"normal"}
                    // FIXME: Default 값 일 때 선택되어 있게 하기
                    className={clsx(classes.small__button, {
                      [classes.selected]: periodFilter(3)
                    })}
                    onClick={() => {
                      setDate({
                        begin: moment()
                          .subtract(3, "month")
                          .add(1, "day")
                          .unix(),
                        end: getEndTimestamp("day")
                      });
                    }}
                  >
                    {intl.formatMessage({ id: "monthsUnit" }, { number: 3 })}
                  </CCButton>
                </Grid>
                <Grid item className={classes.marginLeft8}>
                  <CCButton
                    variant={"contained"}
                    size={"small"}
                    color={"normal"}
                    className={clsx(classes.small__button, {
                      [classes.selected]: periodFilter(6)
                    })}
                    onClick={() => {
                      setDate({
                        begin: moment()
                          .subtract(6, "month")
                          .add(1, "day")
                          .unix(),
                        end: getEndTimestamp("day")
                      });
                    }}
                  >
                    {intl.formatMessage({ id: "monthsUnit" }, { number: 6 })}
                  </CCButton>
                </Grid>
                <Grid item className={classes.marginLeft8}>
                  <CCButton
                    variant={"contained"}
                    size={"small"}
                    color={"normal"}
                    className={clsx(classes.small__button, {
                      [classes.selected]: periodFilter(12)
                    })}
                    onClick={() => {
                      setDate({
                        begin: moment()
                          .subtract(1, "year")
                          .add(1, "day")
                          .unix(),
                        end: getEndTimestamp("day")
                      });
                    }}
                  >
                    {intl.formatMessage({ id: "monthsUnit" }, { number: 12 })}
                  </CCButton>
                </Grid>

                <Box className={clsx(classes.description, classes.divider)} />

                {/*TODO : 등록한 거래처를 조회합니다.*/}

                <Grid
                  container
                  alignItems={"center"}
                  style={{ height: "100%", width: "100%" }}
                >
                  <CCTextField
                    className={classes.select__field}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "partner" })}
                    select
                    value={selectedValue?.vendor}
                    onChange={e => {
                      setSelectedValue({
                        ...selectedValue,
                        vendor: e.target.value
                      });
                    }}
                  >
                    <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                      {ALL_SELECTION}
                    </MenuItem>
                    {vendor.map(e => (
                      <MenuItem key={e} value={e}>
                        {e.name}
                        {/*{intl.formatMessage({ id: e })}*/}
                      </MenuItem>
                    ))}
                  </CCTextField>
                  <CCTextField
                    // className={classes.head__field}
                    style={{ width: "350px" }}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({
                      id: "productName"
                    })}
                    value={selectedValue?.name}
                    onChange={e => {
                      setSelectedValue({
                        ...selectedValue,
                        name: e.target.value
                      });
                    }}
                  />

                  <Grid item className={classes.marginLeft8}>
                    <CCButton
                      style={{
                        height: 36,
                        paddingTop: 0,
                        paddingBottom: 0,
                        width: 110
                      }}
                      variant={"contained"}
                      color={"secondary"}
                      startIcon={<SearchIcon />}
                      onClick={() => {
                        setLastKey(0);
                        setOrder([]);

                        setFilter({
                          keywordType: "itemName",
                          keyword: selectedValue?.name,
                          materialId: selectedValue?.vendor?.id,
                          status: selectedValue?.status?.label?.props?.id.substr(
                            3
                          ),
                          startDate: getCustomFormat(
                            date?.begin,
                            "X",
                            "YYYY-MM-DD"
                          ),
                          endDate: getCustomFormat(date?.end, "X", "YYYY-MM-DD")
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "search" })}
                    </CCButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CCPaper>
        <CCPaper className={classes.paper}>
          <SearchResults
            result={order}
            onMoreContents={() => onMoreContents && onMoreContents()}
            hasNext={hasNext}
            vendor={vendor}
          />
        </CCPaper>

        {Boolean(requestDialog) && (
          <ProductAddDialog
            // className={classes.background__color}
            open={requestDialog}
            onClose={() => setRequestDialog(false)}
          />
        )}
      </Grid>
      {/*)} }*/}
    </>
  );
};

export default OrderReturnTab;
