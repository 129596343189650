import React from "react";

const HrIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="raytk22hka" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-1168.000000, -368.000000) translate(1168.000000, 368.000000)">
          <path fill="#D8D8D8" fillOpacity=".01" d="M0 0H32V32H0z" />
          <rect
            width="30"
            height="30"
            x="1"
            y="1"
            fill="url(#raytk22hka)"
            rx="4"
          />
          <path
            fill="#00897B"
            d="M14.667 17c1.005 0 2.294.143 3.626.427-1.71 1.467-2.793 3.644-2.793 6.073 0 .998.183 1.953.516 2.834H4v-4c0-2.322 3.045-3.89 6.147-4.695l.517-.128c.086-.02.172-.04.258-.058l.512-.106.507-.092.496-.078.244-.034.476-.056.232-.023.449-.036.216-.012.415-.014.198-.002zm0-12C17.613 5 20 7.387 20 10.333c0 2.947-2.387 5.334-5.333 5.334-2.947 0-5.334-2.387-5.334-5.334C9.333 7.387 11.72 5 14.667 5z"
          />
          <path
            fill="#00BFA5"
            d="M23.494 17C27.088 17 30 19.912 30 23.5S27.088 30 23.494 30C19.905 30 17 27.088 17 23.5s2.905-6.5 6.494-6.5zm.006 1.3c-2.873 0-5.2 2.327-5.2 5.2s2.327 5.2 5.2 5.2 5.2-2.327 5.2-5.2-2.327-5.2-5.2-5.2zm.325 1.95v3.413l2.925 1.735-.488.8-3.412-2.048v-3.9h.975z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default HrIcon;
