/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { makeStyles } from '@material-ui/styles';
import Paper, {
    Color,
    Group,
    Path,
    Point,
    PointText,
    Raster,
    Rectangle,
    Size
} from 'paper';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { range } from 'utils/range.js';
import { getTeethList, getTeethSME } from 'utils/teeth.js';

var manifests = {};

range(1, 8).forEach((index) => {
  manifests[`teeth.${index + 10}`] = `oralexam/adult-${index + 10}-icon.png`;
  manifests[`teeth.${index + 40}`] = `oralexam/adult-${index + 40}-icon.png`;
  manifests[`teeth.${index + 50}`] = `oralexam/baby-${index + 50}-icon.png`;
  manifests[`teeth.${index + 80}`] = `oralexam/baby-${index + 80}-icon.png`;
});

manifests['range.X.normal'] = 'oralexam/range-0-normal-icon.png';
manifests['range.B.normal'] = 'oralexam/range-1-normal-icon.png';
manifests['range.D.normal'] = 'oralexam/range-2-normal-icon.png';
manifests['range.L.normal'] = 'oralexam/range-3-normal-icon.png';
manifests['range.M.normal'] = 'oralexam/range-4-normal-icon.png';
manifests['range.O.normal'] = 'oralexam/range-5-normal-icon.png';
manifests['range.X.select'] = 'oralexam/range-0-select-icon.png';
manifests['range.B.select'] = 'oralexam/range-1-select-icon.png';
manifests['range.D.select'] = 'oralexam/range-2-select-icon.png';
manifests['range.L.select'] = 'oralexam/range-3-select-icon.png';
manifests['range.M.select'] = 'oralexam/range-4-select-icon.png';
manifests['range.O.select'] = 'oralexam/range-5-select-icon.png';
manifests['background'] = 'oralexam/bgcross-icon.png';

const chartings = [
  'abs',
  'af',
  'attrition',
  'bridge-cebr',
  'bridge-gbr',
  'bridge-mbr',
  'bridge-pfmbr',
  'c-1',
  'c-2',
  'c-3',
  'ca',
  'circle-start',
  'circle-link',
  'crack',
  'crown-gcr',
  'crown-pfmcr',
  'crown-sscr',
  'crown-zircr',
  'dotted-link',
  'dotted-start',
  'eruption',
  'extrusion',
  'fis',
  'food',
  'fx',
  'impacted',
  'implant',
  'inlay-cei',
  'inlay-gi',
  'inlay-gif',
  'inlay-ri',
  'intrusion',
  'missing',
  'mob-1',
  'mob-2',
  'mob-3',
  'pus',
  'recession',
  'rf',
  'rootcanal',
  'rr',
  'sealant',
  'semieruption',
  'space',
  'straight-link',
  'swelling',
  'tempf',
  'uneruption',
];

chartings.forEach((type) => {
  ['upper', 'lower'].forEach((dir) => {
    manifests[`${type}.${dir}`] = `oralexam/charting-${type}-${dir}-icon.png`;
  });
});

var commons = {
  configs: {},
  targets: {},
  templates: {},
  bounds: {},
};

range(1, 8).forEach((index) => {
  commons.bounds[`teeth.${index + 10}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 8,
  };
  commons.bounds[`teeth.${index + 20}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 8,
  };
  commons.bounds[`teeth.${index + 40}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 8,
  };
  commons.bounds[`teeth.${index + 30}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 8,
  };
  commons.bounds[`teeth.${index + 50}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 8,
  };
  commons.bounds[`teeth.${index + 60}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 8,
  };
  commons.bounds[`teeth.${index + 80}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 8,
  };
  commons.bounds[`teeth.${index + 70}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 8,
  };
});

range(1, 8).forEach((index) => {
  commons.bounds[`teeth.${index + 10}.head`] = {
    x: 32 - (index - 0) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 20}.head`] = {
    x: (index - 1) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 40}.head`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 30}.head`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 50}.head`] = {
    x: 32 - (index - 0) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 60}.head`] = {
    x: (index - 1) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 80}.head`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 70}.head`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
});

range(1, 8).forEach((index) => {
  commons.bounds[`teeth.${index + 10}.body`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 20}.body`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 40}.body`] = {
    x: 32 - (index - 0) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 30}.body`] = {
    x: (index - 1) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 50}.body`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 60}.body`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 80}.body`] = {
    x: 32 - (index - 0) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.bounds[`teeth.${index + 70}.body`] = {
    x: (index - 1) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
});

range(1, 8).forEach((index) => {
  commons.bounds[`tags.${index + 10}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.bounds[`tags.${index + 20}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.bounds[`tags.${index + 40}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.bounds[`tags.${index + 30}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.bounds[`tags.${index + 50}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.bounds[`tags.${index + 60}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.bounds[`tags.${index + 80}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.bounds[`tags.${index + 70}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
});

range(1, 8).forEach((index) => {
  commons.targets[`teeth.${index + 10}.body`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.targets[`teeth.${index + 10}.head`] = {
    x: 32 - (index - 0) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.configs[`teeth.${index + 10}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 8,
    source: `teeth.${index + 10}`,
    sx: 1.0,
    sy: 1.0,
  };
  commons.targets[`teeth.${index + 20}.body`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.targets[`teeth.${index + 20}.head`] = {
    x: (index - 1) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.configs[`teeth.${index + 20}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 8,
    source: `teeth.${index + 10}`,
    sx: -1.0,
    sy: 1.0,
  };
  commons.targets[`teeth.${index + 40}.body`] = {
    x: 32 - (index - 0) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.targets[`teeth.${index + 40}.head`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.configs[`teeth.${index + 40}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 8,
    source: `teeth.${index + 40}`,
    sx: 1.0,
    sy: 1.0,
  };
  commons.targets[`teeth.${index + 30}.body`] = {
    x: (index - 1) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.targets[`teeth.${index + 30}.head`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.configs[`teeth.${index + 30}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 8,
    source: `teeth.${index + 40}`,
    sx: -1.0,
    sy: 1.0,
  };
});

range(1, 8).forEach((index) => {
  commons.targets[`numbers.${index + 10}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.configs[`numbers.${index + 10}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
    source: `${index + 10}`,
  };
  commons.targets[`numbers.${index + 20}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.configs[`numbers.${index + 20}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
    source: `${index + 20}`,
  };
  commons.targets[`numbers.${index + 40}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.configs[`numbers.${index + 40}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
    source: `${index + 40}`,
  };
  commons.targets[`numbers.${index + 30}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.configs[`numbers.${index + 30}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
    source: `${index + 30}`,
  };
});

range(1, 8).forEach((index) => {
  commons.targets[`teeth.${index + 50}.body`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.targets[`teeth.${index + 50}.head`] = {
    x: 32 - (index - 0) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.configs[`teeth.${index + 50}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 8,
    source: `teeth.${index + 50}`,
    sx: 1.0,
    sy: 1.0,
  };
  commons.targets[`teeth.${index + 60}.body`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.targets[`teeth.${index + 60}.head`] = {
    x: (index - 1) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.configs[`teeth.${index + 60}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 8,
    source: `teeth.${index + 50}`,
    sx: -1.0,
    sy: 1.0,
  };
  commons.targets[`teeth.${index + 80}.body`] = {
    x: 32 - (index - 0) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.targets[`teeth.${index + 80}.head`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.configs[`teeth.${index + 80}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 8,
    source: `teeth.${index + 80}`,
    sx: 1.0,
    sy: 1.0,
  };
  commons.targets[`teeth.${index + 70}.body`] = {
    x: (index - 1) * 4,
    y: 4,
    width: 4,
    height: 4,
  };
  commons.targets[`teeth.${index + 70}.head`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 4,
  };
  commons.configs[`teeth.${index + 70}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 8,
    source: `teeth.${index + 80}`,
    sx: -1.0,
    sy: 1.0,
  };
});

range(1, 5).forEach((index) => {
  commons.targets[`numbers.${index + 50}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.configs[`numbers.${index + 50}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
    source: `${index + 50}`,
  };
  commons.targets[`numbers.${index + 60}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.configs[`numbers.${index + 60}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
    source: `${index + 60}`,
  };
  commons.targets[`numbers.${index + 80}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.configs[`numbers.${index + 80}`] = {
    x: 32 - (index - 0) * 4,
    y: 0,
    width: 4,
    height: 2,
    source: `${index + 80}`,
  };
  commons.targets[`numbers.${index + 70}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
  };
  commons.configs[`numbers.${index + 70}`] = {
    x: (index - 1) * 4,
    y: 0,
    width: 4,
    height: 2,
    source: `${index + 70}`,
  };
});

range(1, 8).forEach((index) => {
  ['X', 'B', 'D', 'L', 'M', 'O'].forEach((range) => {
    commons.configs[`teeth.${index + 10}.${range}.normal`] = {
      x: 32 - (index - 0) * 4,
      y: range === 'X' ? 0 : 4,
      width: 4,
      height: 4,
      source: `range.${range}.normal`,
    };
    commons.configs[`teeth.${index + 10}.${range}.select`] = {
      x: 32 - (index - 0) * 4,
      y: range === 'X' ? 0 : 4,
      width: 4,
      height: 4,
      source: `range.${range}.select`,
    };
    commons.configs[`teeth.${index + 20}.${range}.normal`] = {
      x: (index - 1) * 4,
      y: range === 'X' ? 0 : 4,
      width: 4,
      height: 4,
      source: `range.${range}.normal`,
    };
    commons.configs[`teeth.${index + 20}.${range}.select`] = {
      x: (index - 1) * 4,
      y: range === 'X' ? 0 : 4,
      width: 4,
      height: 4,
      source: `range.${range}.select`,
    };
    commons.configs[`teeth.${index + 40}.${range}.normal`] = {
      x: 32 - (index - 0) * 4,
      y: range === 'X' ? 4 : 0,
      width: 4,
      height: 4,
      source: `range.${range}.normal`,
    };
    commons.configs[`teeth.${index + 40}.${range}.select`] = {
      x: 32 - (index - 0) * 4,
      y: range === 'X' ? 4 : 0,
      width: 4,
      height: 4,
      source: `range.${range}.select`,
    };
    commons.configs[`teeth.${index + 30}.${range}.normal`] = {
      x: (index - 1) * 4,
      y: range === 'X' ? 4 : 0,
      width: 4,
      height: 4,
      source: `range.${range}.normal`,
    };
    commons.configs[`teeth.${index + 30}.${range}.select`] = {
      x: (index - 1) * 4,
      y: range === 'X' ? 4 : 0,
      width: 4,
      height: 4,
      source: `range.${range}.select`,
    };
  });
});

range(1, 8).forEach((index) => {
  ['X', 'B', 'D', 'L', 'M', 'O'].forEach((range) => {
    commons.configs[`teeth.${index + 50}.${range}.normal`] = {
      x: 32 - (index - 0) * 4,
      y: range === 'X' ? 0 : 4,
      width: 4,
      height: 4,
      source: `range.${range}.normal`,
    };
    commons.configs[`teeth.${index + 50}.${range}.select`] = {
      x: 32 - (index - 0) * 4,
      y: range === 'X' ? 0 : 4,
      width: 4,
      height: 4,
      source: `range.${range}.select`,
    };
    commons.configs[`teeth.${index + 60}.${range}.normal`] = {
      x: (index - 1) * 4,
      y: range === 'X' ? 0 : 4,
      width: 4,
      height: 4,
      source: `range.${range}.normal`,
    };
    commons.configs[`teeth.${index + 60}.${range}.select`] = {
      x: (index - 1) * 4,
      y: range === 'X' ? 0 : 4,
      width: 4,
      height: 4,
      source: `range.${range}.select`,
    };
    commons.configs[`teeth.${index + 80}.${range}.normal`] = {
      x: 32 - (index - 0) * 4,
      y: range === 'X' ? 4 : 0,
      width: 4,
      height: 4,
      source: `range.${range}.normal`,
    };
    commons.configs[`teeth.${index + 80}.${range}.select`] = {
      x: 32 - (index - 0) * 4,
      y: range === 'X' ? 4 : 0,
      width: 4,
      height: 4,
      source: `range.${range}.select`,
    };
    commons.configs[`teeth.${index + 70}.${range}.normal`] = {
      x: (index - 1) * 4,
      y: range === 'X' ? 4 : 0,
      width: 4,
      height: 4,
      source: `range.${range}.normal`,
    };
    commons.configs[`teeth.${index + 70}.${range}.select`] = {
      x: (index - 1) * 4,
      y: range === 'X' ? 4 : 0,
      width: 4,
      height: 4,
      source: `range.${range}.select`,
    };
  });
});

chartings.forEach((type) => {
  ['upper', 'lower'].forEach((dir) => {
    commons.templates[`${type}.${dir}`] = {
      x: 0,
      y: 0,
      width: 4,
      height: 8,
      source: `${type}.${dir}`,
    };
  });
});

const useStyles = makeStyles(() => ({
  root: {},
}));

const OECanvas = (props) => {
  const {
    width,
    height,
    layouts,
    actives,
    selects,
    enables,
    entries,
    settings,
    onChange,
    onTooltip,
    timeout,
  } = props;
  const classes = useStyles();
  const canvasRef = useRef(null);
  const paper = useMemo(() => {
    return new Paper.PaperScope();
  }, []);
  const [board, setBoard] = useState({});
  const [objects, setObjects] = useState(null);
  const [instances, setInstances] = useState([]);
  const [touchGrab, setTouchGrab] = useState(null);
  const [clientPoint, setClientPoint] = useState(null);
  const [timer, setTimer] = useState(null);

  const gridWidth = useMemo(() => {
    return width / board.columns;
  }, [width, board]);

  const gridHeight = useMemo(() => {
    return height / board.rows;
  }, [height, board]);

  const getObjectPosition = useCallback(
    (bounds) => {
      return new Point(
        (bounds.x + bounds.width / 2) * gridWidth,
        (bounds.y + bounds.height / 2) * gridHeight,
      );
    },
    [gridWidth, gridHeight],
  );

  const getObjectBounds = useCallback(
    (bounds) => {
      return new Rectangle(
        new Point(bounds.x * gridWidth, bounds.y * gridHeight),
        new Point(
          (bounds.x + bounds.width) * gridWidth,
          (bounds.y + bounds.height) * gridHeight,
        ),
      );
    },
    [gridWidth, gridHeight],
  );

  const getObjectBoundsWithMargins = useCallback(
    (bounds, margins) => {
      return new Rectangle(
        new Point(
          bounds.x * gridWidth + margins.mx,
          bounds.y * gridHeight + margins.my,
        ),
        new Point(
          (bounds.x + bounds.width) * gridWidth - margins.mx * 2,
          (bounds.y + bounds.height) * gridHeight - margins.my * 2,
        ),
      );
    },
    [gridWidth, gridHeight],
  );

  const getPlaneBounds = useCallback((bounds, plane) => {
    const x = bounds.x;
    const y = bounds.y;
    const w = bounds.width;
    const h = bounds.height;
    const planes = {
      B: {
        x0: 0.25,
        y0: 0.0,
        x1: 0.75,
        y1: 0.25,
      },
      D: {
        x0: 0.75,
        y0: 0.25,
        x1: 1.0,
        y1: 0.75,
      },
      L: {
        x0: 0.25,
        y0: 0.75,
        x1: 0.75,
        y1: 1.0,
      },
      M: {
        x0: 0.0,
        y0: 0.25,
        x1: 0.25,
        y1: 0.75,
      },
      O: {
        x0: 0.25,
        y0: 0.25,
        x1: 0.75,
        y1: 0.75,
      },
    };

    return new Rectangle(
      new Point(planes[plane].x0 * w + x, planes[plane].y0 * h + y),
      new Point(planes[plane].x1 * w + x, planes[plane].y1 * h + y),
    );
  });

  const getToothPlane = useCallback((bounds, point) => {
    const x = point.x - bounds.x;
    const y = point.y - bounds.y;
    const w = bounds.width;
    const h = bounds.height;

    if (w * 0.25 <= x && x <= w * 0.75 && h * 0.25 <= y && y <= h * 0.75) {
      return 'O';
    } else {
      const r = Math.PI / 4;
      const c = Math.cos(r);
      const s = Math.sin(r);
      const rx = c * (x - w / 2) + s * (y - h / 2);
      const ry = c * (y - h / 2) - s * (x - w / 2);

      if (rx < 0 && ry < 0) {
        return 'B';
      } else if (rx > 0 && ry < 0) {
        return 'D';
      } else if (rx > 0 && ry > 0) {
        return 'L';
      } else {
        return 'M';
      }
    }
  });

  const getCanvasPoint = useCallback((e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    var clientX = e.clientX;
    var clientY = e.clientY;

    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX;
      clientY = e.chagnedTouches[0].clientY;
    }

    clientX -= rect.left;
    clientY -= rect.top;

    return new Point(clientX, clientY);
  }, []);

  const getClientPoint = useCallback((e) => {
    var clientX = e.clientX;
    var clientY = e.clientY;

    return new Point(clientX, clientY);
  }, []);

  const getCanvasPointFromClient = useCallback((p) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    var clientX = p.x - rect.left;
    var clientY = p.y - rect.top;

    return new Point(clientX, clientY);
  }, []);

  const setTooltipTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        try {
          const point = getCanvasPointFromClient(clientPoint);

          for (const tname in board.targets) {
            const target = board.targets[tname];
            const tags = tname.split('.');

            if (actives.includes(parseInt(tags[1]))) {
              if (tags[0] === 'teeth') {
                const bounds = getObjectBounds(target);
                if (bounds.contains(point)) {
                  onTooltip(clientPoint, [parseInt(tags[1])]);
                  return;
                }
              }
            }
          }
        } catch (e) {}
      }, timeout),
    );
  };
  const clearTooltipTimer = () => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  };

  useEffect(() => {
    paper.activate();
    paper.setup(canvasRef.current);

    return () => {
      paper.remove();
    };
  }, []);

  useMemo(() => {
    var _board = {
      columns: layouts.columns,
      rows: layouts.rows,
      configs: {},
      targets: {},
      templates: {},
      bounds: {},
    };
    var key;

    for (key in commons.configs) {
      try {
        const tags = key.split('.');
        const target = tags[0];
        const number = parseInt(tags[1]);
        const region = parseInt(number / 10);
        const upper = layouts[target].upper;
        const lower = layouts[target].lower;
        const left = layouts[target].left;
        const right = layouts[target].right;
        const regions = [
          {
            dx: 0,
            dy: 0,
          },
          {
            dx: left,
            dy: upper,
          },
          {
            dx: right,
            dy: upper,
          },
          {
            dx: right,
            dy: lower,
          },
          {
            dx: left,
            dy: lower,
          },
          {
            dx: left,
            dy: upper,
          },
          {
            dx: right,
            dy: upper,
          },
          {
            dx: right,
            dy: lower,
          },
          {
            dx: left,
            dy: lower,
          },
        ];

        _board.configs[key] = {};
        _board.configs[key].x = commons.configs[key].x + regions[region].dx;
        _board.configs[key].y = commons.configs[key].y + regions[region].dy;
        _board.configs[key].width = commons.configs[key].width;
        _board.configs[key].height = commons.configs[key].height;
        _board.configs[key].sx = commons.configs[key].sx;
        _board.configs[key].sy = commons.configs[key].sy;
        _board.configs[key].source = commons.configs[key].source;
      } catch (e) {}
    }

    for (key in commons.targets) {
      try {
        const tags = key.split('.');
        const target = tags[0];
        const number = parseInt(tags[1]);
        const region = parseInt(number / 10);
        const upper = layouts[target].upper;
        const lower = layouts[target].lower;
        const left = layouts[target].left;
        const right = layouts[target].right;
        const regions = [
          {
            dx: 0,
            dy: 0,
          },
          {
            dx: left,
            dy: upper,
          },
          {
            dx: right,
            dy: upper,
          },
          {
            dx: right,
            dy: lower,
          },
          {
            dx: left,
            dy: lower,
          },
          {
            dx: left,
            dy: upper,
          },
          {
            dx: right,
            dy: upper,
          },
          {
            dx: right,
            dy: lower,
          },
          {
            dx: left,
            dy: lower,
          },
        ];

        _board.targets[key] = {};
        _board.targets[key].x = commons.targets[key].x + regions[region].dx;
        _board.targets[key].y = commons.targets[key].y + regions[region].dy;
        _board.targets[key].width = commons.targets[key].width;
        _board.targets[key].height = commons.targets[key].height;
      } catch (e) {}
    }

    for (key in commons.bounds) {
      try {
        const tags = key.split('.');
        const target = tags[0];
        const number = parseInt(tags[1]);
        const region = parseInt(number / 10);
        const upper = layouts[target].upper;
        const lower = layouts[target].lower;
        const left = layouts[target].left;
        const right = layouts[target].right;
        const regions = [
          {
            dx: 0,
            dy: 0,
          },
          {
            dx: left,
            dy: upper,
          },
          {
            dx: right,
            dy: upper,
          },
          {
            dx: right,
            dy: lower,
          },
          {
            dx: left,
            dy: lower,
          },
          {
            dx: left,
            dy: upper,
          },
          {
            dx: right,
            dy: upper,
          },
          {
            dx: right,
            dy: lower,
          },
          {
            dx: left,
            dy: lower,
          },
        ];

        _board.bounds[key] = {};
        _board.bounds[key].x = commons.bounds[key].x + regions[region].dx;
        _board.bounds[key].y = commons.bounds[key].y + regions[region].dy;
        _board.bounds[key].width = commons.bounds[key].width;
        _board.bounds[key].height = commons.bounds[key].height;
      } catch (e) {}
    }

    for (key in commons.templates) {
      _board.templates[key] = {};
      _board.templates[key].x = commons.templates[key].x;
      _board.templates[key].y = commons.templates[key].y;
      _board.templates[key].width = commons.templates[key].width;
      _board.templates[key].height = commons.templates[key].height;
      _board.templates[key].source = commons.templates[key].source;
    }

    setBoard({ ..._board });
  }, []);

  useEffect(() => {
    var __objects = {};

    for (const name in board.configs) {
      const config = board.configs[name];

      if (manifests.hasOwnProperty(config.source)) {
        var item = new Raster(manifests[config.source]);
        item.scale(config.sx || 1.0, config.sy || 1.0);
        item.fitBounds(getObjectBounds(config));

        __objects[name] = item;
      } else {
        var item = new Group();

        const margins = { mx: 2, my: 2 };
        var round = new Path.RoundRectangle(
          getObjectBoundsWithMargins(config, margins),
          new Size(8, 8),
        );
        round.fillColor = '#888888';

        var text = new PointText(new Point(0, 0));
        text.content = config.source;
        text.style = {
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          fontSize: 24,
          fillColor: 'white',
          justification: 'center',
        };
        text.fitBounds(getObjectBoundsWithMargins(config, margins));

        item.addChild(round);
        item.addChild(text);

        __objects[name] = item;
      }
    }

    setObjects({ ...__objects });
  }, [board]);

  const getTemplate = useCallback(
    (name) => {
      try {
        const template = board.templates[name];

        var item = new Raster(manifests[template.source]);
        item.fitBounds(getObjectBounds(template));
        item.visible = false;

        return item;
      } catch (e) {
        console.error(e);
      }
    },
    [board],
  );

  useEffect(() => {
    if (!objects) return;

    paper.activate();

    for (const cname in board.configs) {
      const tags = cname.split('.');

      if (tags[0] === 'teeth') {
        if (actives.includes(parseInt(tags[1]))) {
          const opacity =
            enables && !enables.includes(parseInt(tags[1])) ? 0.25 : 1.0;

          try {
            objects[`teeth.${tags[1]}`].visible = true;
            objects[`teeth.${tags[1]}`].opacity = opacity;
            if (selects.includes(`teeth.${tags[1]}`)) {
              objects[`teeth.${tags[1]}.X.normal`].visible = false;
              objects[`teeth.${tags[1]}.X.select`].visible = true;
              objects[`teeth.${tags[1]}.X.select`].opacity = opacity;
            } else {
              objects[`teeth.${tags[1]}.X.normal`].visible = true;
              objects[`teeth.${tags[1]}.X.normal`].opacity = opacity;
              objects[`teeth.${tags[1]}.X.select`].visible = false;
            }
            ['B', 'D', 'L', 'M', 'O'].forEach((plane) => {
              if (selects.includes(`plane.${tags[1]}.${plane}`)) {
                objects[`teeth.${tags[1]}.${plane}.normal`].visible = false;
                objects[`teeth.${tags[1]}.${plane}.select`].visible = true;
                objects[`teeth.${tags[1]}.${plane}.select`].opacity = opacity;
              } else {
                objects[`teeth.${tags[1]}.${plane}.select`].visible = false;
                objects[`teeth.${tags[1]}.${plane}.normal`].visible = true;
                objects[`teeth.${tags[1]}.${plane}.normal`].opacity = opacity;
              }
            });
          } catch (e) {}
        } else {
          objects[`teeth.${tags[1]}`].visible = false;
          ['X', 'B', 'D', 'L', 'M', 'O'].forEach((plane) => {
            objects[`teeth.${tags[1]}.${plane}.normal`].visible = false;
            objects[`teeth.${tags[1]}.${plane}.select`].visible = false;
          });
        }
      } else if (tags[0] === 'numbers') {
        const opacity =
          enables && !enables.includes(parseInt(tags[1])) ? 0.25 : 1.0;

        if (actives.includes(parseInt(tags[1]))) {
          objects[`numbers.${tags[1]}`].visible = true;
          objects[`numbers.${tags[1]}`].opacity = opacity;
        } else {
          objects[`numbers.${tags[1]}`].visible = false;
        }
      }
    }
  }, [actives, selects, objects]);

  useEffect(() => {
    paper.activate();

    instances.forEach((instance) => {
      instance.remove();
    });

    var _instances = [];

    var customs = entries.filter((entry) => {
      const setting = settings.find((el) => el.id === entry.target);
      if (!setting || setting.readonly) {
        return false;
      }
      return true;
    });
    var indices = [];
    customs.forEach((entry) => {
      if (entry.mode === 'tooth' || entry.mode === 'plane') {
        entry.teeth.forEach((tooth) => {
          indices = indices.concat(tooth);
        });
      }
    });
    [...new Set(indices)]
      .filter((number) => actives.includes(number))
      .forEach((number) => {
        try {
          const elements = customs.filter((entry) =>
            entry.teeth.includes(number),
          );
          const bounds = board.bounds[`tags.${number}`];
          const margins = { mx: 2, my: 2 };
          var item = new Group();
          var round = new Path.RoundRectangle(
            getObjectBoundsWithMargins(bounds, margins),
            new Size(8, 8),
          );
          var text = new PointText(new Point(0, 0));

          var targets = [...new Set(elements.map((el) => el.target))];

          if (targets.length === 1) {
            const setting = settings.find((el) => el.id === targets[0]);
            const rgba = setting.color.split(',');
            const color = new Color(
              parseInt(rgba[0]) / 255,
              parseInt(rgba[1]) / 255,
              parseInt(rgba[2]) / 255,
              parseFloat(rgba[3]),
            );

            round.fillColor = color;

            text.content = setting.abbrev;
          } else {
            round.fillColor = new Color(0.5, 0.5, 0.5, 0.5);

            text.content = '+2';
          }

          text.style = {
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontSize: 24,
            fillColor: 'white',
            justification: 'center',
          };
          text.fitBounds(getObjectBoundsWithMargins(bounds, margins));

          item.addChild(round);
          item.addChild(text);

          _instances.push(item);
        } catch (e) {
          console.error(e);
        }
      });

    for (var index in entries) {
      const entry = entries[index];
      const setting = settings.find((el) => el.id === entry.target);

      if (!setting) {
        continue;
      }

      if (entry.mode === 'tooth') {
        try {
          entry.teeth.forEach((tooth) => {
            const region = parseInt(tooth / 10);
            if (actives.includes(tooth)) {
              if (setting.readonly) {
                const bounds = board.bounds[`teeth.${tooth}`];
                var item = getTemplate(
                  [1, 2, 5, 6].includes(region)
                    ? `${setting.icon}.upper`
                    : `${setting.icon}.lower`,
                );
                if (item) {
                  item.fitBounds(getObjectBounds(bounds));
                  item.visible = true;

                  _instances.push(item);
                }
              }
            }
          });
        } catch (e) {
          console.error(e);
        }
      } else if (entry.mode === 'plane') {
        try {
          entry.teeth.forEach((tooth) => {
            const region = parseInt(tooth / 10);
            if (actives.includes(tooth)) {
              if (setting.readonly) {
                const bounds = board.bounds[`teeth.${tooth}`];
                var item = getTemplate(
                  [1, 2, 5, 6].includes(region)
                    ? `${setting.icon}.upper`
                    : `${setting.icon}.lower`,
                );
                if (item) {
                  item.fitBounds(getObjectBounds(bounds));
                  item.visible = true;

                  _instances.push(item);
                }
              }

              const bounds = getObjectBounds(
                board.bounds[`teeth.${tooth}.head`],
              );
              const rgba = setting.color.split(',');
              const color = new Color(
                parseInt(rgba[0]) / 255,
                parseInt(rgba[1]) / 255,
                parseInt(rgba[2]) / 255,
                parseFloat(rgba[3]),
              );
              const planes = entry.planes[`teeth.${tooth}`];

              for (var index = 0; index < planes.length; index++) {
                const plane = planes[index];

                var item = new Path();

                if (plane.includes('B')) {
                  item.moveTo(
                    new Point(
                      bounds.x + bounds.width * 0.0,
                      bounds.y + bounds.height * 0.0,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.25,
                      bounds.y + bounds.height * 0.25,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.75,
                      bounds.y + bounds.height * 0.25,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 1.0,
                      bounds.y + bounds.height * 0.0,
                    ),
                  );
                  item.closePath();
                } else if (plane.includes('D')) {
                  item.moveTo(
                    new Point(
                      bounds.x + bounds.width * 1.0,
                      bounds.y + bounds.height * 0.0,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.75,
                      bounds.y + bounds.height * 0.25,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.75,
                      bounds.y + bounds.height * 0.75,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 1.0,
                      bounds.y + bounds.height * 1.0,
                    ),
                  );
                  item.closePath();
                } else if (plane.includes('L')) {
                  item.moveTo(
                    new Point(
                      bounds.x + bounds.width * 1.0,
                      bounds.y + bounds.height * 1.0,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.75,
                      bounds.y + bounds.height * 0.75,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.25,
                      bounds.y + bounds.height * 0.75,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.0,
                      bounds.y + bounds.height * 1.0,
                    ),
                  );
                  item.closePath();
                } else if (plane.includes('M')) {
                  item.moveTo(
                    new Point(
                      bounds.x + bounds.width * 0.0,
                      bounds.y + bounds.height * 1.0,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.25,
                      bounds.y + bounds.height * 0.75,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.25,
                      bounds.y + bounds.height * 0.25,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.0,
                      bounds.y + bounds.height * 0.0,
                    ),
                  );
                  item.closePath();
                } else if (plane.includes('O')) {
                  item.moveTo(
                    new Point(
                      bounds.x + bounds.width * 0.25,
                      bounds.y + bounds.height * 0.25,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.75,
                      bounds.y + bounds.height * 0.25,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.75,
                      bounds.y + bounds.height * 0.75,
                    ),
                  );
                  item.lineTo(
                    new Point(
                      bounds.x + bounds.width * 0.25,
                      bounds.y + bounds.height * 0.75,
                    ),
                  );
                  item.closePath();
                }

                item.fillColor = color;

                _instances.push(item);
              }
            }
          });
        } catch (e) {
          console.error(e);
        }
      } else if (entry.mode === 'double') {
        try {
          if (entry.teeth.some((el) => actives.includes(el))) {
            var bounds = null;
            entry.teeth.forEach((tooth) => {
              if (bounds === null) {
                bounds = getObjectBounds(board.bounds[`teeth.${tooth}`]);
              } else {
                bounds = bounds.unite(
                  getObjectBounds(board.bounds[`teeth.${tooth}`]),
                );
              }
            });

            var item = getTemplate(
              [1, 2, 5, 6].includes(parseInt(entry.teeth[0] / 10))
                ? `${setting.icon}.upper`
                : `${setting.icon}.lower`,
            );
            if (item) {
              item.fitBounds(bounds);
              item.visible = true;

              _instances.push(item);
            }
          }
        } catch (e) {
          console.error(e);
        }
      } else if (entry.mode === 'multiple') {
        try {
          if (entry.teeth.some((el) => actives.includes(el))) {
            var bounds0 = getObjectBounds({
              x: 0,
              y: 0,
              width: 4,
              height: 8,
            });
            var bounds1 = null;
            entry.teeth.forEach((tooth) => {
              if (bounds1 === null) {
                bounds1 = getObjectBounds(board.bounds[`teeth.${tooth}`]);
              } else {
                bounds1 = bounds1.unite(
                  getObjectBounds(board.bounds[`teeth.${tooth}`]),
                );
              }
            });

            var item = getTemplate(
              [1, 2, 5, 6].includes(parseInt(entry.teeth[0] / 10))
                ? `${setting.icon}.upper`
                : `${setting.icon}.lower`,
            );
            if (item) {
              item.scale(
                bounds1.width / bounds0.width,
                bounds1.height / bounds0.height,
              );
              item.translate(
                bounds1.center.x - bounds0.center.x,
                bounds1.center.y - bounds0.center.y,
              );
              item.visible = true;

              _instances.push(item);
            }
          }
        } catch (e) {
          console.error(e);
        }
      } else if (entry.mode === 'many') {
        try {
          if (entry.teeth.some((el) => actives.includes(el))) {
            const sme = getTeethSME(entry.teeth, getTeethList(actives));
            const region = parseInt(sme[0] / 10);

            if (actives.includes(sme[0])) {
              if (sme[0] && setting.icon) {
                const bounds = board.bounds[`teeth.${sme[0]}`];
                var item = getTemplate(
                  [1, 2, 5, 6].includes(region)
                    ? `${setting.icon}.upper`
                    : `${setting.icon}.lower`,
                );
                if (item) {
                  item.fitBounds(getObjectBounds(bounds));
                  item.visible = true;

                  _instances.push(item);
                }
              }
              if (sme[0] && setting.link) {
                const bounds = board.bounds[`teeth.${sme[0]}`];
                var item = getTemplate(
                  [1, 2, 5, 6].includes(region)
                    ? `${setting.link}.upper`
                    : `${setting.link}.lower`,
                );
                if (item) {
                  item.fitBounds(getObjectBounds(bounds));
                  item.visible = true;

                  _instances.push(item);
                }
              }
              if (sme[1] && setting.icon) {
                const bounds = board.bounds[`teeth.${sme[1]}`];
                var item = getTemplate(
                  [1, 2, 5, 6].includes(region)
                    ? `${setting.icon}.upper`
                    : `${setting.icon}.lower`,
                );
                if (item) {
                  item.fitBounds(getObjectBounds(bounds));
                  item.visible = true;

                  _instances.push(item);
                }
              }
              if (sme[1] && setting.link) {
                const bounds = board.bounds[`teeth.${sme[1]}`];
                var item = getTemplate(
                  [1, 2, 5, 6].includes(region)
                    ? `${setting.link}.upper`
                    : `${setting.link}.lower`,
                );
                if (item) {
                  item.scale(-1.0, 1.0);
                  item.fitBounds(getObjectBounds(bounds));
                  item.visible = true;

                  _instances.push(item);
                }
              }
              sme.slice(2).forEach((number) => {
                const tag = `teeth.${number}`;
                const bounds = board.bounds[tag];
                var icons = entry.icons;

                if (icons.hasOwnProperty(tag)) {
                  var item = getTemplate(
                    [1, 2, 5, 6].includes(region)
                      ? `${icons[tag]}.upper`
                      : `${icons[tag]}.lower`,
                  );
                  if (item) {
                    item.fitBounds(getObjectBounds(bounds));
                    item.visible = true;

                    _instances.push(item);
                  }
                }
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

    setInstances([..._instances]);
  }, [entries, actives]);

  const handleDown = (e) => {
    paper.activate();

    const point = getCanvasPoint(e);

    setTouchGrab(point);

    clearTooltipTimer();
  };
  const handleUp = (e) => {
    paper.activate();

    const point = getCanvasPoint(e);

    if (touchGrab) {
      const bounds0 = new Rectangle(touchGrab, point);

      var indices = [];

      for (const tname in board.targets) {
        const target = board.targets[tname];
        const tags = tname.split('.');

        if (enables && !enables.includes(parseInt(tags[1]))) continue;

        if (actives.includes(parseInt(tags[1]))) {
          if (tags[0] === 'teeth' && tags[2] === 'body') {
            const bounds1 = getObjectBounds(target);
            if (bounds0.intersects(bounds1)) {
              indices.push('teeth.' + tags[1]);
            }
          }

          if (tags[0] === 'numbers') {
            const bounds1 = getObjectBounds(target);
            if (bounds1.contains(point) && bounds1.contains(touchGrab)) {
              indices.push('teeth.' + tags[1]);
            }
          }

          if (tags[0] === 'teeth' && tags[2] === 'head') {
            const bounds1 = getObjectBounds(target);

            ['B', 'D', 'L', 'M', 'O'].forEach((plane) => {
              if (bounds0.intersects(getPlaneBounds(bounds1, plane))) {
                indices.push(`plane.${tags[1]}.${plane}`);
              }
            });
          }
        }
      }

      var sources = selects;

      indices
        .filter((index) => index.startsWith('plane'))
        .forEach((index) => {
          if (sources.includes(index)) {
            sources.splice(sources.indexOf(index), 1);
          } else {
            sources.push(index);
          }
        });

      indices
        .filter((index) => index.startsWith('teeth'))
        .forEach((index) => {
          const tags = index.split('.');

          if (sources.includes(index)) {
            sources.splice(sources.indexOf(index), 1);

            ['B', 'D', 'L', 'M', 'O'].forEach((plane) => {
              if (sources.includes(`plane.${tags[1]}.${plane}`)) {
                sources.splice(sources.indexOf(`plane.${tags[1]}.${plane}`), 1);
              }
            });
          } else {
            sources.push(index);

            ['B', 'D', 'L', 'M', 'O'].forEach((plane) => {
              if (!sources.includes(`plane.${tags[1]}.${plane}`)) {
                sources.push(`plane.${tags[1]}.${plane}`);
              }
            });
          }
        });

      onChange([...sources]);

      setTouchGrab(null);
    }

    clearTooltipTimer();
  };
  const handleMove = (e) => {
    if (!objects) return;

    paper.activate();

    const point = getCanvasPoint(e);

    if (touchGrab) {
      const bounds0 = new Rectangle(touchGrab, point);

      var normal0 = [];
      var select0 = [];

      for (const tname in board.targets) {
        const target = board.targets[tname];
        const tags = tname.split('.');

        if (enables && !enables.includes(parseInt(tags[1]))) continue;

        if (actives.includes(parseInt(tags[1]))) {
          if (tags[0] === 'teeth' && tags[2] === 'body') {
            try {
              const bounds1 = getObjectBounds(target);

              if (
                bounds0.intersects(bounds1) ===
                selects.includes(`teeth.${tags[1]}`)
              ) {
                objects[`teeth.${tags[1]}.X.normal`].visible = true;
                objects[`teeth.${tags[1]}.X.select`].visible = false;

                if (bounds0.intersects(bounds1)) {
                  normal0.push(`teeth.${tags[1]}`);
                }
              } else {
                objects[`teeth.${tags[1]}.X.normal`].visible = false;
                objects[`teeth.${tags[1]}.X.select`].visible = true;

                if (bounds0.intersects(bounds1)) {
                  select0.push(`teeth.${tags[1]}`);
                }
              }
            } catch (e) {}
          }

          if (tags[0] === 'teeth' && tags[2] === 'head') {
            try {
              const bounds1 = getObjectBounds(target);

              if (normal0.includes(`teeth.${tags[1]}`)) {
                ['B', 'D', 'L', 'M', 'O'].forEach((plane) => {
                  objects[`teeth.${tags[1]}.${plane}.normal`].visible = true;
                  objects[`teeth.${tags[1]}.${plane}.select`].visible = false;
                });
              } else if (select0.includes(`teeth.${tags[1]}`)) {
                ['B', 'D', 'L', 'M', 'O'].forEach((plane) => {
                  objects[`teeth.${tags[1]}.${plane}.normal`].visible = false;
                  objects[`teeth.${tags[1]}.${plane}.select`].visible = true;
                });
              } else {
                ['B', 'D', 'L', 'M', 'O'].forEach((plane) => {
                  if (
                    bounds0.intersects(getPlaneBounds(bounds1, plane)) ===
                    selects.includes(`plane.${tags[1]}.${plane}`)
                  ) {
                    objects[`teeth.${tags[1]}.${plane}.normal`].visible = true;
                    objects[`teeth.${tags[1]}.${plane}.select`].visible = false;
                  } else {
                    objects[`teeth.${tags[1]}.${plane}.normal`].visible = false;
                    objects[`teeth.${tags[1]}.${plane}.select`].visible = true;
                  }
                });
              }
            } catch (e) {}
          }
        }
      }
    } else {
      setTooltipTimer();
    }

    setClientPoint(getClientPoint(e));
  };

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      onMouseDown={(e) => handleDown(e)}
      onMouseUp={(e) => handleUp(e)}
      onMouseMove={(e) => handleMove(e)}
      onMouseOut={(e) => handleUp(e)}
      onTouchStart={(e) => handleDown(e)}
      onTouchEnd={(e) => handleUp(e)}
      onTouchMove={(e) => handleMove(e)}
      onTouchCancel={(e) => handleUp(e)}
    ></canvas>
  );
};

OECanvas.propTypes = {
  /** 가로 길이 (700px) */
  width: PropTypes.number,

  /** 세로 길이 (260px) */
  height: PropTypes.number,

  /** 화면 구성 정보 */
  layouts: PropTypes.object,

  /** 선택된 치식, 배열 형태 */
  actives: PropTypes.array,
  selects: PropTypes.array,

  /** 선택된 치식을 반환해주는 함수 */
  onChange: PropTypes.func,
  onTooltip: PropTypes.func,

  /** tooltip timeout (milliseconds) */
  timeout: PropTypes.number,
};

export default OECanvas;
