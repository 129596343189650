import React from "react";

const SignatureIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M22 21.017v1H2v-1h20zM12.735 2l3.685 2.135-2.17 3.692.397 9.207-9.616 3.543 3.588-6.213c.208.036.428.029.646-.03.8-.214 1.275-1.036 1.06-1.837-.214-.8-1.036-1.275-1.836-1.06-.8.214-1.275 1.037-1.061 1.837.061.229.172.431.319.598l-3.59 6.219L2.4 9.962l8.174-4.26L12.735 2z"
      />
      <path d="M0 0H24V24H0z" transform="matrix(0 1 1 0 0 0)" />
    </g>
  </svg>
);
export default SignatureIcon;
