import { flatten } from "flat";
import { default as enWord } from '../en/locale.js';
import { default as log } from "./log.json";
import { default as word } from "./words.json";

const ko = flatten({
  ...enWord,
  ...word,
  ...log
});

export default ko;
