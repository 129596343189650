import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import DentalRequestDialog from "components/DentalLabRequestDialog";
import React, { useState } from "react";
import { CCButton, CCDialogSimple, CCIconButton } from "styles/src/components";
import { ResetIcon } from "styles/src/themes/common/icons";
import { LAB_ORDER_TYPE } from "types/labOrder";
import { LabOrderSetting, LabOrderTarget } from "./components";

const height = 700;

const useStyles = makeStyles(theme => ({
  root: { height: "100%", width: "940px" },
  paper: { height: "100%", position: "relative" },
  box: { padding: 8 },
  padding0: { padding: 0 },
  padding8: { padding: 8 },
  margin0: { margin: 0 },
  side__margin0: {
    marginRight: 0,
    marginLeft: 0
  },
  backdrop: { height: "100%", width: "100%", position: "absolute", zIndex: 95 },
  bottom__box: {
    height: `calc(100% - ${height}px  )`,
    paddingTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 0
  }
}));

const LabOrderInput = props => {
  const { state, loadingGetLabOrder, dispatch } = props;
  const {
    // doctor, staff, target ,
    isModify
  } = state.labOrder;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [mailOpen, setMailOpen] = useState(false);
  const [
    emailIdList
    // setEmailIdList
  ] = useState([]);
  const [
    // eslint-disable-next-line no-unused-vars
    tempDialog,
    setTempDialog
  ] = useState(false);

  const handleChange = props => {
    if (props) {
      if (
        props.type === LAB_ORDER_TYPE.RE_REQUEST.value ||
        props.type === LAB_ORDER_TYPE.MODIFY_REQUEST.value
      ) {
        setTempDialog(true);
      } else if (props.type === LAB_ORDER_TYPE.NEW_REQUEST.value) {
        dispatch({ type: "CHANGE_VALUE", value: { connectedRequestId: "" } });
      }
      dispatch({ type: "CHANGE_VALUE", value: props });
    }
  };

  const resetLabOrder = () => {
    // dispatch({ type: 'RESET_LAB_ORDER' });
  };

  const handleReset = () => {
    isModify && resetLabOrder();
  };

  const EmailOnComplete = e => {
    setOpen(false);
    // if (e && e.split(",").length) {
    //   e.split(",").map(item =>
    //     updateLabOrder({
    //       variables: {
    //         id: item,
    //         deliveryType: LAB_ARRIVE_TYPE.NON_ARRIVE.value,
    //         state: LAB_ORDER_STATE.REQUEST_COMPLETED.value,
    //         log: {
    //           category: LOG_CATEGORY.LAB_ORDER.value,
    //           contents: `${patientContext.name}(${patientContext.chartNumber})의 기공의뢰 정보가 업데이트 되었습니다.`,
    //           patient: patientContext.name
    //         }
    //       }
    //     })
    //   );
    // }
  };

  return (
    <Box className={classes.root}>
      {/* <Loading
          open={
            loadingCreateLabOrder ||
            loadingUpdateLabOrder ||
            loadingModifyLabOrder ||
            loadingGetLabOrder
          }
          msg={"Loading..."}
          zIndex={110}
        /> */}
      <Box>
        <Grid container alignItems={"center"} justify={"space-between"}>
          <Grid item style={{ paddingRight: 8 }} hidden={!isModify}>
            <CCIconButton
              size={"small"}
              className={clsx(classes.margin0, classes.padding0)}
              onClick={handleReset}
            >
              <ResetIcon />
            </CCIconButton>
          </Grid>
        </Grid>
        <LabOrderTarget
          value={state}
          onChange={handleChange}
          setTempDialog={setTempDialog}
          dispatch={dispatch}
        />
      </Box>

      <LabOrderSetting
        value={state.labOrder}
        onChange={handleChange}
        dispatch={dispatch}
      />

      {/* <Box className={classes.bottom__box}>
          <LabOrderTable value={state.labOrderList} onDelClick={onDelClick} />
        </Box> */}
      {/* 
        <Grid container dispaly={'flex'} className={classes.padding8}>
          <Grid item>
            <StaffSelect
              value={doctor || ''}
              onChange={(e) => handleChange({ doctor: e.target.value })}
              args={{
                status: STATUS.WORKING.value,
                charge: CHARGE.DOCTOR_IN_CHARGE.value,
              }}
              label={'담당의사'}
              required
            />
          </Grid>
          <Grid item>
            <StaffSelect
              value={staff || ''}
              onChange={(e) => handleChange({ staff: e.target.value })}
            />
          </Grid>
          <Grid item style={{ flex: 1 }} />
          <Grid
            item
            className={classes.padding8}
            hidden={
              !isModify ||
              state?.labOrderList[0]?.state === LAB_ORDER_STATE.TEMP_SAVE.value
            }
          >
            <CCButton
              margin={'dense'}
              variant={'contained'}
              startIcon={<SaveIcon />}
              onClick={onUpdate}
              disabled={!state.labOrderList?.length}
            >
              저장
            </CCButton>
          </Grid>

          <Grid
            item
            className={classes.padding8}
            hidden={
              isModify &&
              state?.labOrderList[0]?.state !== LAB_ORDER_STATE.TEMP_SAVE.value
            }
          >
            <CCButton
              margin={'dense'}
              variant={'contained'}
              startIcon={<SaveIcon />}
              onClick={tempSaveClick}
              disabled={!state.labOrderList?.length}
            >
              임시 저장
            </CCButton>
          </Grid>

          <Grid
            item
            className={classes.padding8}
            hidden={
              isModify &&
              state?.labOrderList[0]?.state !== LAB_ORDER_STATE.TEMP_SAVE.value
            }
          >
            <CCButton
              margin={'dense'}
              variant={'contained'}
              startIcon={<SMSIcon />}
              onClick={eMailClick}
              disabled={!mailTargetList.length}
            >
              E-mail 전송
            </CCButton>
          </Grid>
          <Grid
            item
            className={classes.padding8}
            hidden={
              isModify &&
              state?.labOrderList[0]?.state !== LAB_ORDER_STATE.TEMP_SAVE.value
            }
          >
            <CCButton
              margin={'dense'}
              variant={'contained'}
              startIcon={<ProgressIcon />}
              disabled={target !== TARGET.CLEVER_LAB.value}
            >
              Clever lab 전송
            </CCButton>
          </Grid>
        </Grid>
       */}
      {open && !loadingGetLabOrder && (
        <DentalRequestDialog
          id={!!isModify ? state.labOrder.id : emailIdList}
          open
          onClose={() => {
            setOpen(false);
          }}
          onComplete={EmailOnComplete}
          onFail={() => {
            setMailOpen(true);
          }}
        />
      )}
      {mailOpen && (
        <CCDialogSimple
          open={mailOpen}
          onclose={() => setMailOpen(false)}
          title={"전송실패"}
          contents={`메일전송에 실패하였습니다.`}
          endActions={
            <CCButton onClick={() => setMailOpen(false)}>확인</CCButton>
          }
        />
      )}
      {/* {tempDialog && (
        <ConnectLabIdSelect
          open={tempDialog}
          patient={{
            id: state.labOrder.patient,
            name: state.labOrder.name
          }}
          onClose={() => setTempDialog(false)}
          onComplete={
            requestId => {}
            // dispatch({
            //   type: 'CHANGE_VALUE',
            //   value: { connectedRequestId: requestId },
            // })
          }
        />
      )} */}
    </Box>
  );
};

LabOrderInput.defaultProps = {};

LabOrderInput.propTypes = {};

export default LabOrderInput;
