import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MailIcon from "@material-ui/icons/Mail";
import { pdf } from "@react-pdf/renderer";
import { enqueueToast, translate } from "components";
import { AppContext } from "context/AppContext";
import { useSnackbar } from "notistack";
import {
  BILL_CONFIRM_DETAIL,
  SEND_EMAIL, SEND_PAYMENT_BILL
} from "queries/payment";
import { GET_SMTP_SETTING } from "queries/setting";
import React, { useContext, useEffect, useRef, useState } from "react";
import Iframe from "react-iframe";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogAlert,
  CCDialogSimple,
  CCTypography
} from "styles/components";
import { ProgressIcon } from "styles/src/themes/common/icons";
import { getObject, putObject, Target } from "utils/s3";
import { v4 as uuid } from "uuid";
import Receipt from "./components/Receipt";
import ReceiptDocPDF from "./components/ReceiptDocPDF/ReceiptDocPDF";
import * as moment_tz from "moment-timezone";

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "column",
    position: "relative",
    // borderTop: '1px solid #b0bec5',
    background: "#eceff1",
    marginTop: '1%'
  },

  item2: {
    margin: 12,
    flex: 1,
    background: theme.palette.common.white,
  },
  color__red: { color: "#f50057" },
  button__print: {
    marginLeft: 16
  },
  title: { 
    // marginBottom: '1%',
    paddingBottom: '2.5%',
    // zIndex: 1
  }
}));

const BillPaper = props => {
  const { open, onClose, id: paymentId, clinic, ids, note } = props;

  


  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const { appContext } = useContext(AppContext);
  const componentRef = useRef();
  const [doc, setDoc] = useState("");
  const [sending, setSending] = useState(null);
  const [filePDFToMinio, setFilePDFToMinio] = useState("");
  const [inputErr, setInputErr] = useState(null);
  const { data: dataPaymentBill, loading } = useQuery(BILL_CONFIRM_DETAIL, {
    variables: { id: paymentId, ids, clinic },
    onCompleted: () => {
      const getStamp = async () => {
        const blob = await pdf(
          <ReceiptDocPDF
            lang={localStorage.getItem("lang")}
            data={{ ...dataPaymentBill?.paymentBillConfirm, note: note || dataPaymentBill?.paymentBillConfirm.note}}
            intl={intl}
          />
        ).toBlob();

        var file = new File([blob], "billPayment", { type: "application/pdf" });
        setFilePDFToMinio({
          src: {
            file: file
          }
        });

        setDoc(URL.createObjectURL(blob));

      };
      if (doc === "" || filePDFToMinio === "") {
        getStamp();
      }

      return () => {
        URL.revokeObjectURL(doc);
      };
    }
  });
  // console.log("BillPaper.props.dataPaymentBill", dataPaymentBill)

  const [sendEmail] = useMutation(SEND_EMAIL, {
    onCompleted: () => {
      setSending("sent");
      // enqueueToast(enqueueSnackbar, `Mail have been sent to ${dataPaymentBill?.paymentBillConfirm.partner.email}.`);

      onClose();
    }
  });
  const [updatePaymentBill] = useMutation(SEND_PAYMENT_BILL, {
    onCompleted: () => {
      // enqueueToast(enqueueSnackbar, "The email have been sent.");
      onClose();
    }
  });
  const { data, refetch } = useQuery(GET_SMTP_SETTING, {
    variables: {
      labId: localStorage.getItem("labId")
    },
    fetchPolicy: "no-cache"
  });

  const handleSendToClever = async () => {
    if (!filePDFToMinio) return;
    const target = Target.PHOTO;
    // const labId = localStorage.getItem("labId");
    const fileUUID = uuid();
    // await putObject(target, labId, fileUUID, filePDFToMinio).then(async () => {
    // const url = await getObject(target, labId, fileUUID);
    // delete dataPaymentBill.paymentBillConfirm.savedBill

    // console.log("dataPaymentBill?.paymentBillConfirm", dataPaymentBill?.paymentBillConfirm)
    updatePaymentBill({
      variables: {
        clinic: clinic,
        orderIds: ids,
        note: note,
        sendBillTo: 'clever',
      }
    }).then(rs => {
      if (rs.data?.sendPaymentBill?.code === 200) {
        enqueueToast(
          enqueueSnackbar,
          translate(intl, "common.message.billSendTo", {
            email: dataPaymentBill?.paymentBillConfirm.partner.name
          })
        );
      } else {
        enqueueToast(
          enqueueSnackbar,
          translate(intl, "common.message.billSendToFailed", {
            email: dataPaymentBill?.paymentBillConfirm.partner.name
          })
        );
      }
    }).catch(error => {
      enqueueToast(
        enqueueSnackbar,
        translate(intl, "common.message.billSendToFailed", {
          email: dataPaymentBill?.paymentBillConfirm.partner.name
        })
      );
    });
  };
  
  const createPaymentBillContentByRegion = () => {
    // let regionLanguage = process.env.REACT_APP_LANGUAGE_REGION;
    let regionLanguage = localStorage.getItem("lang");
    // regionLanguage = 'ko';
    // console.log("regionLanguage", regionLanguage)
    let defaultBillContent = {};

    switch (regionLanguage) {
      case 'en': 
        defaultBillContent = {
          to: dataPaymentBill?.paymentBillConfirm.partner.email,
          subject: `[${dataPaymentBill?.paymentBillConfirm.lab.labName}] Payment Bills of ${dataPaymentBill?.paymentBillConfirm.month}/${dataPaymentBill?.paymentBillConfirm.year}`,
          // text: "Hey there, it’s our first message sent with Nodemailer ;) ",
          html: `
            <p>
              Dear, ${dataPaymentBill?.paymentBillConfirm.partner.name}<br><br>
              Hello.<br>This is ${dataPaymentBill?.paymentBillConfirm.lab.labName}.
              <br><br>We are sending you the Payment Bills of ${dataPaymentBill?.paymentBillConfirm.month}/${dataPaymentBill?.paymentBillConfirm.year}.
              <br><br>Please check  the attachments.
              <br><br>Best regards,
              <br>${dataPaymentBill?.paymentBillConfirm.lab.labName}.
            </p>
          `,
        };
      break;

      case 'vi':
        defaultBillContent = {
          to: dataPaymentBill?.paymentBillConfirm.partner.email,
          subject: `[${dataPaymentBill?.paymentBillConfirm.lab.labName}] Hóa đơn thanh toán ${dataPaymentBill?.paymentBillConfirm.month}/${dataPaymentBill?.paymentBillConfirm.year}`,
          // text: "Hey there, it’s our first message sent with Nodemailer ;) ",
          html: `
            <p>
              Kính gửi, ${dataPaymentBill?.paymentBillConfirm.partner.name}<br><br>
              Xin chào.<br>Đây là ${dataPaymentBill?.paymentBillConfirm.lab.labName}.
              <br><br>Chúng tôi gửi cho bạn hóa đơn thanh toán của ${dataPaymentBill?.paymentBillConfirm.month}/${dataPaymentBill?.paymentBillConfirm.year}.
              <br>
              <br>Xin vui lòng kiểm tra các tập tin đính kèm.
              <br><br>Trân trọng,
              <br>${dataPaymentBill?.paymentBillConfirm.lab.labName}.
            </p>
          `,
        };
      break;
      
      case 'ru': 
        defaultBillContent = {
          to: dataPaymentBill?.paymentBillConfirm.partner.email,
          subject: `[${dataPaymentBill?.paymentBillConfirm.lab.labName}] Платежный счет от ${dataPaymentBill?.paymentBillConfirm.month}.${dataPaymentBill?.paymentBillConfirm.year}`,
          // text: "Hey there, it’s our first message sent with Nodemailer ;) ",
          html: `
            <p>
              Уважаемый, ${dataPaymentBill?.paymentBillConfirm.partner.name}<br><br>
              Здравствуйте.<br>Это ${dataPaymentBill?.paymentBillConfirm.lab.labName}.
              <br><br>Мы отправляем Вам счет на оплату за ${dataPaymentBill?.paymentBillConfirm.month}.${dataPaymentBill?.paymentBillConfirm.year}.
              <br><br>Смотрите прикрепленные вложения.
              <br><br>С наилучшими пожеланиями,
              <br>${dataPaymentBill?.paymentBillConfirm.lab.labName}.
            </p>
          `,
        };
      break;

      case 'ar': 
        defaultBillContent = {
          to: dataPaymentBill?.paymentBillConfirm.partner.email,
          subject: `[${dataPaymentBill?.paymentBillConfirm.lab.labName}] فاتورة دفع بقيمة ${dataPaymentBill?.paymentBillConfirm.month}/${dataPaymentBill?.paymentBillConfirm.year}`,
          // text: "Hey there, it’s our first message sent with Nodemailer ;) ",
          html: `
            <p>
              عزيزي, ${dataPaymentBill?.paymentBillConfirm.partner.name}<br><br>
              مرحبًا.<br>هذا ${dataPaymentBill?.paymentBillConfirm.lab.labName}.
              <br><br>نرسل إليك فواتير الدفع البالغة ${dataPaymentBill?.paymentBillConfirm.month}/${dataPaymentBill?.paymentBillConfirm.year}.
              <br><br>يرجى التحقق من المرفقات.
              <br><br>​مع أطيب التحيات،,
              <br>${dataPaymentBill?.paymentBillConfirm.lab.labName}.
            </p>
          `,
        };
      break;

      case 'ko': 
        defaultBillContent = {
          to: dataPaymentBill?.paymentBillConfirm.partner.email,
          subject: `[${dataPaymentBill?.paymentBillConfirm.lab.labName}] 기공대금 청구서 ${dataPaymentBill?.paymentBillConfirm.year}/${dataPaymentBill?.paymentBillConfirm.month}`,
          // text: "Hey there, it’s our first message sent with Nodemailer ;) ",
          html: `
            <p>
              ${dataPaymentBill?.paymentBillConfirm.partner.name} 고객님,<br><br>
              안녕하세요.<br> ${dataPaymentBill?.paymentBillConfirm.lab.labName} 입니다.
              <br><br>${dataPaymentBill?.paymentBillConfirm.year}/${dataPaymentBill?.paymentBillConfirm.month} 기공대금 청구서 전달드립니다.
              <br><br>.첨부파일 확인을 부탁드립니다. 
              <br><br>감사합니다.
              <br>${dataPaymentBill?.paymentBillConfirm.lab.labName} 드림.
            </p>
          `,
        };
      break;
         

    }
    return defaultBillContent;
  }

  const handleAttachToZalo = async () => {
    if (!filePDFToMinio) return;
    const target = Target.PHOTO;
    const labId = localStorage.getItem("labId");
    const fileUUID = uuid();
    await putObject(target, labId, fileUUID, filePDFToMinio).then(async () => {
      return updatePaymentBill({
        variables: {
          clinic: clinic,
          orderIds: ids,
          note: note,
          sendBillTo: 'email'
        }
      })
    }).then( async (rs) => {
      const url = await getObject(target, labId, fileUUID);
      const emailContent = createPaymentBillContentByRegion();

      console.log("emailContent", emailContent)
      
      if (rs.data?.sendPaymentBill?.code === 200) {
        return sendEmail({
          variables: {
            ... emailContent,
            attachments: [
              {
                filename: `${dataPaymentBill?.paymentBillConfirm.partner.name}_Payment Bill_${moment_tz()
                  .tz(moment_tz.tz.guess(true))
                  .format("L")}.pdf`,
                contentType: "application/pdf",
                path: url
              }
            ]
          }
        })
      } else {
        return {
          data: {
            sendEmail: { code: 0 }
          }
        }
      }
    })
      .then(res => {
        const {
          data: {
            sendEmail: { code: responseCode }
          }
        } = res;
        if (responseCode === 200) {
          enqueueToast(
            enqueueSnackbar,
            translate(intl, "common.message.mailSendTo", {
              email: dataPaymentBill?.paymentBillConfirm.partner.email
            })
          );
        } else {
          enqueueToast(
            enqueueSnackbar,
            translate(intl, "common.message.mailSendToFailed", {
              email: dataPaymentBill?.paymentBillConfirm.partner.email
            })
          );
        }
      })
      .catch(err => {
        console.log("Err sendEmail =>>>>>>", err);
        enqueueToast(
          enqueueSnackbar,
          translate(intl, "common.message.mailSendToFailed", {
            email: dataPaymentBill?.paymentBillConfirm.partner.email
          })
        );
      });
  };

  useEffect(() => {
    refetch();
    setInputErr(null);
  }, [appContext.refresh, refetch]);

  return (
    !loading && (
      <>
        {dataPaymentBill?.paymentBillConfirm && (
          <CCDialogSimple
            maxWidth="md"
            fullWidth
            classes={classes}
            open={open}
            onClose={onClose}
            onCloseButton={onClose}
            // title={"Send Bill"}
            // title={translate(intl, "payment.bill.sendBill")}
            title={""}
            onPrintButtonF={() => {
              window.frames["iFramePdf"].focus();
              window.frames["iFramePdf"].print();
            }}
            contents={
              <Grid container className={classes.container}>
                <Grid item className={classes.item2}>
                  <Receipt
                    ref={componentRef}
                    data={{ ...dataPaymentBill?.paymentBillConfirm, id: paymentId }}
                    note={note || dataPaymentBill?.paymentBillConfirm?.note}
                  />
                </Grid>
              </Grid>
            }
            endActions={
              <>
                {/* <CCButton margin="dense" onClick={() => onClose()}>
                  {translate(intl, "payment.bill.cancel")}
                </CCButton> */}
                {!paymentId && (
                  <CCButton
                    variant="contained"
                    margin="dense"
                    startIcon={
                      sending === "sending" ? (
                        <CircularProgress size={20} color="normal" />
                      ) : sending === "sent" ? (
                        <MailIcon />
                      ) : (
                        <MailIcon />
                      )
                    }
                    // disabled={
                    //   !patientContext.cellphoneNumber ||
                    //   !data?.socialAccountListByCpNos?.items?.length
                    // }
                    onClick={() => {
                      if (sending === "sending") return;
                      console.log("dataPaymentBill", data)
                      if (
                        !data?.getConfig?.smtpSetting?.sender ||
                        !data?.getConfig?.smtpSetting?.port ||
                        !data?.getConfig?.smtpSetting?.smtpUrl ||
                        !data?.getConfig?.smtpSetting?.password
                      ) {
                        setInputErr(true);
                        return;
                      }
                      setSending("sending");
                      handleAttachToZalo();
                    }}
                  >
                    {translate(intl, "payment.bill.sendEMail")}
                    {/* Send E-Mail */}
                  </CCButton>
                )}
                {!paymentId &&
                  dataPaymentBill?.paymentBillConfirm?.partner?.isClever && (
                    <CCButton
                      className={classes.button__print}
                      variant="contained"
                      margin="dense"
                      startIcon={<ProgressIcon />}
                      onClick={() => {
                        handleSendToClever();
                      }}
                    >
                      {translate(intl, "payment.bill.sendToClever")}
                      {/* Send to Clever */}
                    </CCButton>
                  )}
              </>
            }
          />
        )}

        {doc && (
          <Iframe name="iFramePdf" id="iFramePdf" src={doc} display="none" />
        )}
        {inputErr && (
          <CCDialogAlert
            open={inputErr}
            contents={
              <>
                <CCTypography className={classes.color__red}>
                  {translate(intl, "payment.bill.smtpNotSet1")}
                </CCTypography>
                <CCTypography className={classes.color__red}>
                  {translate(intl, "payment.bill.smtpNotSet2")}
                </CCTypography>
              </>
            }
            
            endActions={
              <>
                <CCButton
                  // variant="contained"
                  // color="primary"
                  onClick={() => {
                    setInputErr(null);
                  }}
                >
                  {translate(intl, "payment.bill.cancel")}
                </CCButton>{" "}
                <CCButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const w = window.open(
                      `${window.location.origin}/setting`,
                      "_blank"
                    );
                    w.variables = { category: "common.ui.smtp" };
                  }}
                >
                  {translate(intl, "payment.bill.shiftBtn")}
                </CCButton>
              </>
            }
            onClose={() => setInputErr(null)}
          />
        )}
      </>
    )
  );
};

export default BillPaper;
