import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import GroupMessage from "../GroupMessage";
import { convertData } from "./helperViewMessage";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: 1,
    height: "100%",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,

  },
  table: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: 16,
    position: "relative",
    height: "100%"
  },
  load__more: {
    position: "absolute",
    width: "100%",
    top: "0",
    left: "0",
    paddingTop: 6,
    textAlign: "center"
  },
  newmsg__button: {
    position: "absolute",
    bottom: 40,
    right: 40,
    width: 40,
    height: 40,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main
  },
  newmsg__notice: {
    position: "absolute",
    top: -4,
    right: -4,
    background: "red",
    color: "white",
    fontSize: 10,
    borderRadius: "50%",
    width: 16,
    height: 16
  }
}));

const MessageView = props => {
  const {
    labId,
    items,
    hasNext,
    operator,
    hasPrev,
    handleFetchMore,
    handleFetchMoreDown,
    loadMore,
    me
  } = props;

  const classes = useStyles();
  const dataAfterConvert = convertData(items, me.id);

  const [haveNewMessage, setHaveNewMessage] = useState([]);
  const [oldScrollHeight, setOldScrollHeight] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const refTable = useRef();

  const handleScrollNewMessage = () => {
    setHaveNewMessage([]);
    const scrollTable = document.getElementById("scroll-reverse");
    scrollTable.scrollTop = scrollTable.scrollHeight;
  };
  const handleOnScroll = e => {
    const st = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const offsetHeight = e.target.offsetHeight;
    if (st < 20 && !loadMore && hasNext) {
      handleFetchMore();
    }
    if (st + offsetHeight > scrollHeight - 50 && !loadMore && hasPrev) {
      handleFetchMoreDown();
    }
    if (
      e.target.offsetHeight + e.target.scrollTop + 100 >=
      e.target.scrollHeight
    ) {
      setHaveNewMessage([]);
    }
    setLastScrollTop(st);
  };

  useEffect(() => {
    const scrollTable = document.getElementById("scroll-reverse");
    scrollTable.scrollTop = scrollTable.scrollHeight;
  }, []);
  useEffect(() => {
    if (operator !== "AFTER") {
      const scrollTable = document.getElementById("scroll-reverse");
      if (oldScrollHeight && lastScrollTop < 20) {
        scrollTable.scrollTop = scrollTable.scrollHeight - oldScrollHeight;
      } else {
        if (items[0].from.id === me.id) {
          scrollTable.scrollTop = scrollTable.scrollHeight;
        } else {
          if (
            scrollTable.offsetHeight + scrollTable.scrollTop + 100 >=
            scrollTable.scrollHeight
          ) {
            scrollTable.scrollTop = scrollTable.scrollHeight;
          } else {
            setHaveNewMessage(prev => prev.includes(items[0].id) ? prev : [...prev, items[0].id]);
          }
        }
      }

      setOldScrollHeight(scrollTable.scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, operator]);

  return (
    <Box className={classes.root}>
      <div
        className={classes.table}
        id="scroll-reverse"
        onScroll={handleOnScroll}
        ref={refTable}
      >
        {loadMore && (
          <div className={classes.load__more}>
            <CircularProgress color="secondary" size={40} />
          </div>
        )}
        {dataAfterConvert.map((item, index) => (
          <GroupMessage
            {...item}
            key={item.id}
            labId={labId}
            meId={me.staffId}
            listPureMessage={items}
            endOfGroup={index === dataAfterConvert.length - 1}
          />
        ))}
      </div>
      {haveNewMessage.length !== 0 && (
        <Fab
          color="inherit"
          aria-label="add"
          className={classes.newmsg__button}
          onClick={handleScrollNewMessage}
        >
          <KeyboardArrowDownIcon />
          <div className={classes.newmsg__notice}>{haveNewMessage.length}</div>
        </Fab>
      )}
    </Box>
  );
};

export default MessageView;
