import { gql } from "@apollo/client";

const CREATE_VENDOR = gql`
  mutation createVendor(
    $hospitalId: String!
    $createVendorId: String
    $vendorName: String
    $email: String
    $phone: String
    $taxCode: String
    $location: InputLocation
    $bank: String
    $director: String
    $accountNumber: String
    $cellNumber: String
    $bizno: String
    $idNumber: String
    $createTime: Date
  ) {
    createVendor(
      hospitalId: $hospitalId
      id: $createVendorId
      vendorName: $vendorName
      email: $email
      phone: $phone
      taxCode: $taxCode
      location: $location
      bank: $bank
      director: $director
      accountNumber: $accountNumber
      cellNumber: $cellNumber
      bizno: $bizno
      idNumber: $idNumber
      createTime: $createTime
    ) {
      hospitalId
      id
      vendorName
      email
      phone
      taxCode
      location {
        address
        placeId
        detailAddress
        coordinate {
          latitude
          longitude
        }
      }
      bank
      accountNumber
      director
      cellNumber
      bizno
      idNumber
      createTime
    }
  }
`;

const UPDATE_VENDOR = gql`
  mutation updateVendor(
    $hospitalId: String!
    $id: String
    $vendorName: String
    $email: String
    $phone: String
    $taxCode: String
    $location: InputLocation
    $bank: String
    $accountNumber: String
    $director: String
    $cellNumber: String
    $bizno: String
    $idNumber: String
    $createTime: Date
  ) {
    updateVendor(
      hospitalId: $hospitalId
      id: $id
      vendorName: $vendorName
      email: $email
      phone: $phone
      taxCode: $taxCode
      location: $location
      bank: $bank
      accountNumber: $accountNumber
      director: $director
      cellNumber: $cellNumber
      bizno: $bizno
      idNumber: $idNumber
      createTime: $createTime
    ) {
      createTime
      idNumber
      bizno
      cellNumber
      director
      accountNumber
      bank
      location {
        address
        coordinate {
          longitude
          latitude
        }
        detailAddress
        placeId
      }
      hospitalId
      id
      vendorName
      email
      phone
      taxCode
    }
  }
`;

const SEARCH_VENDOR = gql`
  query searchVendor(
    $hospitalId: String!
    $args: InputSearchVendor
    $comparison: String
    $match: String
    $sort: InputSort
  ) {
    searchVendor(
      hospitalId: $hospitalId
      args: $args
      comparison: $comparison
      match: $match
      sort: $sort
    ) {
      hospitalId
      id
      vendorName
      email
      phone
      taxCode
      location {
        address
        placeId
        detailAddress
        coordinate {
          latitude
          longitude
        }
      }
      bank
      accountNumber
      director
      cellNumber
      bizno
      idNumber
      createTime
    }
  }
`;

const SEARCH_PRODUCT = gql`
  query searchProduct(
    $def: String!
    $args: InputSearchProduct
    $storage: InputStorage
    $comparison: String
    $match: String
    $sort: InputSort
  ) {
    searchProduct(
      def: $def
      args: $args
      storage: $storage
      comparison: $comparison
      match: $match
    ) {
      def
      hospital
      major
      minor
      productID
      productName
      origin
      manaufacture
      unit
      dvFamilyPrice
      specialPrice
      retailPrice
      material
      fixtureOrder
      order
      confirm
      storage {
        hospital
        important
        unit
        currentCnt
        optCnt
        alert
        editDate
        editorID
        canExpire
        expire
        binCnt
      }
    }
  }
`;

const REMOVE_VENDOR = gql`
  mutation removeVendor($hospitalId: String!, $removeVendorId: String!) {
    removeVendor(hospitalId: $hospitalId, id: $removeVendorId) {
      hospitalId
      id
      vendorName
      email
      phone
      taxCode
      location {
        address
        coordinate {
          latitude
          longitude
        }
        detailAddress
        placeId
      }
      bank
      accountNumber
      director
      cellNumber
      bizno
      idNumber
      createTime
    }
  }
`;

const CREATE_PAMCART = gql`
  mutation createPamcart(
    $hospitalId: String!
    $parentId: Int
    $materialId: Int
    $requestMessage: String
    $item: [InputSearchPamcartItem]
    $images: [String]
    $createTime: Date
  ) {
    createPamcart(
      hospitalId: $hospitalId
      parentId: $parentId
      materialId: $materialId
      requestMessage: $requestMessage
      item: $item
      images: $images
      createTime: $createTime
    ) {
      hospitalId
      id
      parentId
      materialId
      requestMessage
      item {
        category1
        category2
        name
        unit1
        unit2
        unitPrice
        expiration
        count
        manufacture
        material
      }
      totalSum
      totalSum
      createTime
      images
    }
  }
`;

const UPDATE_PAMCART = gql`
  mutation updatePamcart(
    $hospitalId: String!
    $id: String
    $parentId: Int
    $materialId: Int
    $requestMessage: String
    $item: [InputSearchPamcartItem]
    $images: [String]
  ) {
    updatePamcart(
      hospitalId: $hospitalId
      id: $id
      parentId: $parentId
      materialId: $materialId
      requestMessage: $requestMessage
      item: $item
      images: $images
    ) {
      createTime
      images
      totalSum
      item {
        material
        manufacture
        expiration
        unitPrice
        count
        unit2
        unit1
        name
        category2
        category1
      }
      hospitalId
      id
      parentId
      materialId
      requestMessage
    }
  }
`;

const SEARCH_PAMCART = gql`
  query searchPamcart(
    $hospitalId: String!
    $sort: InputSort
    $match: String
    $comparison: String
    $args: InputSearchPamcart
  ) {
    searchPamcart(
      hospitalId: $hospitalId
      sort: $sort
      match: $match
      comparison: $comparison
      args: $args
    ) {
      createTime
      images
      totalSum
      item {
        material
        manufacture
        expiration
        count
        unitPrice
        unit2
        unit1
        name
        category2
        category1
      }
      requestMessage
      parentId
      materialId
      id
      hospitalId
    }
  }
`;

export {
  CREATE_VENDOR,
  UPDATE_VENDOR,
  SEARCH_VENDOR,
  SEARCH_PRODUCT,
  REMOVE_VENDOR,
  CREATE_PAMCART,
  UPDATE_PAMCART,
  SEARCH_PAMCART
};
