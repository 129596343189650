import { Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import { CVBox, CVTable, DialogDelete, DialogWarningMessage } from "components";
import React, { useReducer } from "react";
import { CCTooltip, CCTypography } from "styles/components";
import { useIntl } from "utils/language";
import { formatCommas } from "utils/number";
import { CountDialog } from "./components";
import DetailDialog from "../../../DetailDialog";

const useTableStyles = makeStyles(theme => ({
  table__head__row: {
    height: 33,
    alignItems: "center"
  }
}));

const useStyles = makeStyles(theme => ({
  root: { height: "400px" },
  table: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 8,
    background: "white"
  },
  col__exception: {
    textDecoration: "line-through",
    color: theme.palette.disabled.hex
  },
  col__name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  col__count: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.secondary.main,
    textDecorationLine: "underline"
  },
  col__price__wrap: { position: "relative" },
  col__price: { textAlign: "end" },
  col__price__selective__wage: { color: theme.palette.secondary.main },
  col__name__cancel: {},
  icon__add: { margin: "4px 8px", float: "right" },
  icon__more: {
    position: "absolute",
    top: 8,
    right: 0,
    cursor: "pointer",
    color: theme.palette.sub.main,
    width: 12
  },
  hover: {
    cursor: "pointer",
    "&:hover": {}
  }
}));

const initDialog = {
  warn: { state: false },
  del: { state: false, value: null },
  count: { state: false, index: -1 },
  menu: { state: null, rowIndex: -1, value: false },
  add: { state: false }
};

const dialogReducer = (state, action) => {
  return { ...state, [action.type]: action.value };
};

const RequestTable = props => {
  const { value = [], onChange = () => {}, item = [] } = props;
  const intl = useIntl();

  const classes = useStyles();
  const theme = useTheme();
  const tableClasses = useTableStyles();
  const [dialog, dispatchDialog] = useReducer(dialogReducer, initDialog);

  const heads = [
    {
      label: () => (
        <CCTypography variant="h5" style={{ marginLeft: -15 }}>
          {intl.formatMessage({ id: "requestProduct" })}
        </CCTypography>
      ),
      key: "folder",
      width: 70,
      component: ({ cellData }) => (
        <CCTooltip title={cellData}>
          <CCTypography className={clsx(classes.col__name)}>
            {cellData}
          </CCTypography>
        </CCTooltip>
      )
    },
    {
      label: "",
      key: "name",
      component: ({ cellData, rowData }) => (
        <CCTooltip title={cellData}>
          <CCTypography className={clsx(classes.col__name)}>
            {cellData}
          </CCTypography>
        </CCTooltip>
      )
    },
    {
      label: "",
      key: "count",
      width: 30,
      className: classes.hover,
      component: ({ cellData, rowData, rowIndex }) => (
        <CVBox
          permission="chart"
          onClick={() => {
            dispatchDialog({
              type: "count",
              value: { state: true, index: rowIndex }
            });
          }}
        >
          <CCTypography className={clsx(classes.col__count)}>
            {cellData}
          </CCTypography>
        </CVBox>
      )
    },
    {
      label: "",
      key: "price",
      width: 100,
      component: ({ cellData, rowIndex, rowData }) => {
        return (
          <div className={classes.col__price__wrap}>
            <CCTypography className={classes.col__price}>
              {formatCommas(cellData)}
            </CCTypography>
          </div>
        );
      }
    }
  ];

  return (
    <Box className={classes.root}>
      <CVTable
        classes={tableClasses}
        permission="chart"
        className={classes.table}
        heads={heads}
        onAddClick={() => {
          dispatchDialog({
            type: "add",
            value: { state: true }
          });
        }}
        delTooltip={intl.formatMessage({ id: "delete" })}
        onDelClick={({ rowIndex, rowData }) => {
          const { name } = rowData;
          const newValue = value.filter((el, index) => rowIndex !== index);

          onChange({
            name: "delTreat",
            value: newValue,
            tabName: name
          });
        }}
        contents={value}
        rowStyle={rowData => {
          if (rowData.exception)
            return {
              textDecoration: "line-through",
              color: theme.palette.normal.hover
            };
          return {};
        }}
      />
      {dialog.add.state && (
        <DetailDialog
          product={item}
          open={dialog.add.state}
          onClose={() => {
            dispatchDialog({
              type: "add",
              value: { state: false }
            });
          }}
        />
      )}
      {dialog.count.state && (
        <CountDialog
          open={dialog.count.state}
          onClose={() => {
            dispatchDialog({
              type: "count",
              value: { state: false, index: -1 }
            });
          }}
          value={value[dialog.count.index]}
          onChange={modValue => {
            const newValue = value.map((el, index) => {
              if (index === dialog.count.index) return modValue;
              return el;
            });

            onChange({
              type: "count",
              name: "treats",
              value: newValue
            });

            dispatchDialog({
              type: "count",
              value: { state: false, index: -1 }
            });
          }}
        />
      )}
      <DialogDelete
        open={dialog.del.state}
        onClose={() => {
          dispatchDialog({
            type: "del",
            value: { state: false, value: null }
          });
        }}
        onAgree={() => {
          onChange({ type: "field", name: "treats", value: dialog.del.value });

          dispatchDialog({
            type: "del",
            value: { state: false, value: null }
          });
        }}
      />
      {dialog.warn.state && (
        <DialogWarningMessage
          title={dialog.warn.title}
          contents={dialog.warn.contents}
          open={dialog.warn.state}
          onClose={() => {
            dispatchDialog({
              type: "warn",
              value: { state: null, value: null }
            });
          }}
          onAgree={() => {
            dispatchDialog({
              type: "warn",
              value: { state: null, value: null }
            });
          }}
        />
      )}
    </Box>
  );
};

export default RequestTable;
