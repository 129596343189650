import { makeStyles } from "@material-ui/styles";
import { Backdrop, useReadPermission } from "components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CCTypography } from "styles/components";
import { BadIcon } from "styles/icons";

const useStyles = makeStyles(theme => ({
  no__permission: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.sub.main,
    textAlign: "center"
  }
}));

const PermissionBackdrop = props => {
  const { permission, open = false } = props;
  const classes = useStyles();

  const isPermission = useReadPermission(permission);

  return (
    <Backdrop
      type="permission"
      open={!isPermission || open}
      className={classes.no__permission}
      msg={
        <>
          <BadIcon color="#263238" />
          <CCTypography>
            <FormattedMessage
              id={"common.ui.youDoNotHavePermissionToReadTheContents"}
              defaultMessage="You do not have permission to read the contents."
            />
          </CCTypography>
        </>
      }
    />
  );
};

export default PermissionBackdrop;
