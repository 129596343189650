import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CCTypography } from 'styles/src/components';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingLeft: (props) => (props.left ? 8 : 0),
    boxSizing: 'border-box',
  },
}));

const CommonHeader = (props) => {
  const { label, left } = props;
  const classes = useStyle({ left: left });
  return (
    <Grid
      container
      justify={left ? 'flex-start' : 'center'}
      alignItems={'center'}
      className={classes.root}
    >
      <Grid item>
        <CCTypography variant={'body1'}>{label}</CCTypography>
      </Grid>
    </Grid>
  );
};
export default CommonHeader;
