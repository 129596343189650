import { DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    overflowY: "hidden",
    padding: props => (props.noPadding ? "0 !important" : "16px !important")
  },
  dividers: {}
}));

const CCDialogContent = props => {
  const { classes: userClasses, noPadding, ...others } = props;
  const classes = useStyles(props);

  return (
    <DialogContent
      classes={{
        root: clsx(userClasses && userClasses.root, classes.root),
        dividers: clsx(userClasses && userClasses.dividers, classes.dividers)
      }}
      {...others}
    >
      {props.children}
    </DialogContent>
  );
};

export default CCDialogContent;
