import { useMutation } from "@apollo/react-hooks";
import { Box, LinearProgress, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DialogPermissionWarn, useWritePermission, DialogDeviceWarn } from "components";
import { CREATE_MESSAGE } from "queries/labOrder";
import React, { forwardRef, useState, useEffect, useCallback } from "react";
import { CCTextField, CCButton } from "styles/components";
import { ProgressIcon } from "styles/icons";
import { getObject, putObject, Target, putObjectBlob } from "utils/s3";
import { v4 as uuid } from "uuid";
import { Mic, Cancel, CheckCircle, FiberManualRecord } from '@material-ui/icons';
import AttachmentUploadButton from "../AttachmentUploadButton";
import useRecorder from "./hooks/useRecorder";

const useStyles = makeStyles(theme => ({
  recording: {
    display: "flex",
    borderRadius: 2,
    border: '1px solid rgba(0, 0, 0, 0.38)',
    backgroundColor: "white",
    width: "100%",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center"
  },
  leftBar: {
    display: "flex",
    alignItems: "center"
  },
  time: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontSize: 14
  },
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    padding: 16,
    width: "100%",
    boxSizing: "border-box"
  },
  add__button: {
    backgroundColor: theme.palette.white,
    minWidth: 0,
    padding: "3px 3px",
    marginRight: 8
  },
  message__input: {
    margin: 0,
    height: 36,
    width: "100%",
    backgroundColor: theme.palette.white,
    borderRadius: 4
  },
  send__icon: {
    color: theme.palette.icon
  },
  progress__bar: {
    height: "16px",
    width: "100%",
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.background.default
  },
  attachment__btn: {
    backgroundColor: "#CFD8DC",
    border: "none !important",
    marginTop: "8px",
    marginRight: "0px",
  },
  progress__bar__text: {
    marginRight: "3px",
    fontSize: "14px",
    padding: "2px"
  }
}));

const allowExtension = ["PNG", "JPG", "JPEG", "ZIP", "STL"];

function formatSeconds(seconds) {
  return seconds < 10 ? `0${seconds}` : seconds;
}

function formatMinutes(minutes) {
  return minutes < 10 ? `0${minutes}` : minutes;
}

const ChatEditor = forwardRef((props, ref) => {
  const { chatGroupId, labId } = props;

  const classes = useStyles();
  const isPermission = useWritePermission("messenger");

  const [chatContent, setChatContent] = useState("");
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorPermission, setErrorPermission] = useState(false);
  const [openUploadError, setOpenUploadError] = useState(false);

  const { recorderState, startRecording, cancelRecording, saveRecording, setDeviceError } = useRecorder();
  const { audio, recordingMinutes, recordingSeconds, initRecording, isDeviceError } = recorderState;

  const [createMessage] = useMutation(CREATE_MESSAGE, {
    onError: err => alert(err)
  });

  const handleSendMessage = useCallback((content = "", attachments = []) => {
    createMessage({
      variables: {
        input: {
          chatGroupId: chatGroupId,
          content,
          attachments
        }
      }
    });
    setChatContent("");
  });
  
  useEffect(() => {
    if (audio) {
      const ext  = 'opus';
      const fileUUID = uuid() + "." + ext.toLowerCase();
      putObjectBlob(Target.AUDIO, labId, fileUUID, audio, 'audio/ogg').then(() => {
        getObject(Target.AUDIO, labId, fileUUID).then((url) => {
          createMessage({
            variables: {
              input: {
                chatGroupId: chatGroupId,
                content: "",
                attachments: [{
                  url: url,
                  contentType: ext,
                  filename: fileUUID,
                  fileId: fileUUID,
                  bucket: labId
                }]
              }
            }
          });
          setChatContent("");
        });
      });
    }
  }, [audio, chatGroupId, createMessage, labId]);

  
  const handleUploadAttachment = async file => {
    setProgress(0);
    const ext = file.name.slice(file.name.lastIndexOf(".") + 1).toUpperCase();
    setIsLoading(true)
    try {
      const fileUUID = uuid() + "." + ext.toLowerCase();
      await putObject(Target.PHOTO, labId, fileUUID, file, handleProgress);
      const url = await getObject(Target.PHOTO, labId, fileUUID);
      handleSendMessage("", [
        {
          url: url,
          contentType: ext,
          filename: file.name,
          fileId: fileUUID,
          bucket: labId
        }
      ]);
    } catch (error) {
      setOpenUploadError(true);
    }
    setIsLoading(false)

  };

  const handleProgress = progressEvent => {
    // console.log("progress", progressEvent);
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader("content-length") ||
      progressEvent.target.getResponseHeader("x-decompressed-content-length");
    // console.log("onUploadProgress", totalLength);
    if (totalLength !== null) {
      setProgress(
        Math.round((progressEvent.loaded * 100) / totalLength)
      );
    }
  };

  return (
    <>
      {initRecording && (
        <Box className={classes.recording}>
          <div className={classes.leftBar}>
            <IconButton onClick={cancelRecording} component="span">
              <Cancel />
            </IconButton>
            <Mic style={{color: "#4CC7FD"}} />
          </div>
          <span className={classes.time}>
              <FiberManualRecord style={{color: "red", fontSize: "14"}} />
              <span>{formatMinutes(recordingMinutes)}</span>
              <span>:</span>
              <span>{formatSeconds(recordingSeconds)}</span>
          </span>
          <IconButton onClick={saveRecording} color="primary" component="span">
            <CheckCircle />
          </IconButton>
        </Box>
      )}
      {!initRecording && (
        <Box className={classes.root}>
          <AttachmentUploadButton
            allowExtension={allowExtension}
            maxSize={"1024MB"}
            onChange={(e) => {
              if (!isPermission) {
                setErrorPermission(true);
                return;
              }
              return handleUploadAttachment(e)
            }}
            onClick={() => {
              if (!isPermission) {
                setErrorPermission(true);
                return;
              }
            }}
            disabled={isLoading}
            style={isLoading ? classes.attachment__btn : null}
            openUploadError={openUploadError}
            handleCloseUploadError={() => { setOpenUploadError("") }}
          />
          {/* <IconButton onClick={startRecording} style={{color: "#01579B"}} component="span">
            <Mic />
          </IconButton> */}
          {isLoading && (
            <>
              <span
                className={classes.progress__bar__text}
              >
                {`${progress}%`}
              </span>
              <LinearProgress
                className={classes.progress__bar}
                // classes={{ barColorPrimary: classes.progress__bar__primary }}
                color="secondary"
                variant="determinate"
                value={progress}
              />
            </>
          )
          }
          {
            !isLoading && (
              <CCTextField
                value={chatContent}
                size="small"
                variant={"outlined"}
                ref={ref}
                type={"text"}
                endIcon={<ProgressIcon className={classes.send__icon} />}
                onClickIcon={() => {
                  if (!isPermission) {
                    setErrorPermission(true);
                  } else {
                    if (chatContent) handleSendMessage(chatContent);
                  }
                }}
                onChange={e => setChatContent(e.target.value)}
                onKeyPress={e => {
                  if (!isPermission) {
                    setErrorPermission(true);
                    return;
                  }
                  if (e.key === "Enter" && chatContent) handleSendMessage(chatContent);
                }}
                className={classes.message__input}
              />
            )
          }

         
        </Box>
      )}
       <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
      <DialogDeviceWarn
        open={isDeviceError}
        onClose={() => {
          setDeviceError(false);
        }}
      />
    </>
  );
});

export default ChatEditor;
