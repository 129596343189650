import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import { translate } from "components";
import { NotoSans } from "components/DentalLabRequestDialog/font";
import moment from "moment";
import React from "react";
import { getFontFaceByLang } from "utils/font";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 40
  },
  title: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 600
  },
  paper: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 0,
    borderColor: "#ababab",
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: "#eeeeee"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5
  },
  col: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5
  },
  border: {
    borderColor: "#ababab",
    borderWidth: 1,
    borderStyle: "solid"
  },
  title_text_field: {
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: 14,
    lineHeight: 1
  },
  info_text_field: {
    minWidth: "25%",
    width: "25%",
    fontSize: 11,
    lineHeight: 1,
    fontWeight: 400
  },
  info_text_field_bold: {
    minWidth: "25%",
    width: "25%",
    fontSize: 11,
    lineHeight: 1,
    fontWeight: 600
  },
  request_container: {
    display: "flex",
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "#fff"
  },
  white_background: {
    backgroundColor: "#fff"
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    width: "100%",
    minHeight: 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5
  },
  tableTextItem: {
    fontSize: 11,
    fontWeight: 400,
    wordBreak: "break-all"
  },
  image: {
    width: "100%",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    height: 32
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  tableHeader: {
    backgroundColor: "#eeeeee"
  },
  request_header: {
    marginBottom: 5
  },
  comments: {
    display: "flex",
    flexDirection: "row",
    minHeight: 28,
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 10
  },
  commentsHeader: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    width: "25%",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    backgroundColor: "#eeeeee",
    minHeight: 28,
    height: "100%"
  },
  commentsBody: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    width: "75%",
    minHeight: 28,
    height: "100%"
  },
  commentText: {
    width: "100%"
  },
  stamp: {
    width: 24,
    height: 24
  },
  footer: {
    width: "100%"
  },
  footer_text_field: {
    minWidth: "25%",
    width: "100%",
    fontSize: 11,
    lineHeight: 1,
    fontWeight: 400,
    paddingTop: 5,
    paddingBottom: 5
  },
  topDivider: {
    borderTopStyle: "solid",
    borderTopWidth: 1,
    paddingTop: 10
  },
  rightAlign: {
    textAlign: "right"
  },
  signitureArea: {
    paddingTop: 20,
    paddingBottom: 20,
    position: "relative"
  },
  paddingForSigniture: {
    paddingTop: 5,
    paddingRight: 35
  }
});

const Footer = props => {
  const { data, hospital, stamp } = props;
  const isLangAr = localStorage.getItem("lang") === 'ar'
  return (
    <View style={[styles.footer]}>
      {/* <Text style={[styles.footer_text_field, styles.topDivider]}>
        의료기사 등에 관한 법률 제 11조의3 및 같은 법 시행규칙 제 12조의5 제
        1항에 따라 위와 같이 제작을 의뢰합니다.
      </Text> */}

      <View style={styles.signitureArea}>
        <Text style={[styles.footer_text_field, styles.rightAlign,  {textAlign: isLangAr? "left": "right"}]}>
          {moment().format("LL")}
        </Text>
        {stamp && (
          <Text
            style={[
              styles.footer_text_field,
              styles.rightAlign,
              styles.paddingForSigniture,
              {textAlign: isLangAr? "left": "right"}
            ]}
          >
            {`${hospital.name} ${data.doctorName}`}
          </Text>
        )}
        {!stamp && (
          <Text style={[styles.footer_text_field, styles.rightAlign,  {textAlign: isLangAr? "left": "right"}]}>
            {hospital.name} {data.doctorName}
          </Text>
        )}
        {stamp && (
          <Image
            style={{
              width: 30,
              height: 30,
              position: "absolute",
              top: 40,
              right: 0
            }}
            cache={false}
            src={{ uri: stamp, method: "GET", headers: {}, body: "" }}
          />
        )}
      </View>
    </View>
  );
};

const getTeethGraphImage = data => {
  const { numbers, width, height, missings } = data;
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  const _width = 480;
  const _height = 120;
  const ts = _width / 16;

  ctx.beginPath();
  ctx.clearRect(0, 0, _width, _height);

  ctx.save();
  ctx.scale(canvas.width / _width, canvas.height / _height);

  // ctx.fillStyle = background;
  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, _width, _height);

  // ctx.strokeStyle = foreground;
  ctx.strokeStyle = "#000";
  ctx.lineWidth = 2;

  ctx.beginPath();
  ctx.moveTo(0, _height / 2);
  ctx.lineTo(_width, _height / 2);
  ctx.stroke();
  ctx.moveTo(_width / 2, 0);
  ctx.lineTo(_width / 2, _height);
  ctx.stroke();

  var configs = [...Array(4).keys()].map(() => Array(8).fill(0));
  numbers.forEach(number => {
    const babies = number < 50 ? 0 : 1;
    const region = parseInt(((number - 10) % 40) / 10);
    const position = (number % 10) - 1;

    try {
      if (babies) {
        configs[region][position] = 2;
      } else {
        configs[region][position] = 1;
      }
    } catch (e) {
      console.error(e);
    }
  });

  missings.forEach(number => {
    const region = parseInt(((number - 10) % 40) / 10);
    const position = (number % 10) - 1;

    try {
      configs[region][position] = 3;
    } catch (e) {
      console.error(e);
    }
  });

  const texts = [
    ["1", "2", "3", "4", "5", "6", "7", "8"],
    ["A", "B", "C", "D", "E"]
  ];
  const offsets = [
    [ts * 8 - ts / 2, ts * 0.25, -1],
    [ts * 8 + ts / 2, ts * 0.25, 1],
    [ts * 8 + ts / 2, ts * 2 + ts * 0.25, 1],
    [ts * 8 - ts / 2, ts * 2 + ts * 0.25, -1]
  ];

  for (var region = 0; region < 4; region++) {
    for (var position = 0; position < 8; position++) {
      if (configs[region][position] === 1) {
        ctx.font = `${ts * 1.8}px bold monospace`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#000";
        ctx.fillText(
          texts[0][position],
          ts * position * offsets[region][2] + offsets[region][0],
          ts + offsets[region][1]
        );
      } else if (configs[region][position] === 2) {
        ctx.font = `${ts * 1.8}px bold monospace`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#000";
        ctx.fillText(
          texts[1][position],
          ts * position * offsets[region][2] + offsets[region][0],
          ts + offsets[region][1]
        );
      } else if (configs[region][position] === 3) {
        ctx.font = `${ts * 1.8}px bold monospace`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#000";
        ctx.fillText(
          "x",
          ts * position * offsets[region][2] + offsets[region][0],
          ts + offsets[region][1]
        );
      }
    }
  }

  ctx.restore();

  let res = canvas.toDataURL("image/jpeg");
  return res;
};

const PatientInfo = props => {
  const { data, hospital, intl } = props;
  const isLangAr = localStorage.getItem('lang') === 'ar'
  return (
    <>
      <View style={[styles.paper]}>
        <View style={[styles.row, {flexDirection: isLangAr? 'row-reverse' : 'row', alignItems: "flex-start", textAlign: isLangAr? "right": "left"}]}>
          <Text style={styles.info_text_field_bold}>
            {translate(intl, "common.ui.patientName")}
          </Text>
          <Text style={styles.info_text_field}>{data.patient.name}</Text>
          <Text style={styles.info_text_field_bold}>
            {translate(intl, "common.ui.ageSex")}
          </Text>
          <Text style={styles.info_text_field}>{`${data.patient.age}/${
            data.patient.sex === 1 ? "M" : "F"
          }`}</Text>
        </View>
        <View style={[styles.row, {flexDirection: isLangAr? 'row-reverse' : 'row', alignItems: "flex-start", textAlign: isLangAr? "right": "left"}]}>
          <Text style={styles.info_text_field_bold}>
            {translate(intl, "common.ui.clinic")}
          </Text>
          <Text style={styles.info_text_field}>{hospital?.name}</Text>
          <Text style={styles.info_text_field_bold}>
            {translate(intl, "common.ui.dentalLab")}
          </Text>
          <Text style={styles.info_text_field}>{data?.labName}</Text>
        </View>
      </View>
    </>
  );
};

const RequestContent = props => {
  const { data, index, item, intl } = props;
  const isLangAr = localStorage.getItem('lang') === 'ar'
  let _image = getTeethGraphImage({
    numbers: item?.teeth || [],
    width: 192,
    height: 76,
    missings: item?.pontic || []
  });
  let tableHeaders = [
    { title: "common.ui.toothNo", content:  <Image src={_image} style={[styles.image]} /> },
    { title: "common.ui.prosthesis", content:  <Text style={styles.tableTextItem}> {item?.manufacture?.name} </Text> },
    {
      title: "common.ui.shade", content: <>
      <Text style={styles.tableTextItem}>
        {item?.shade?.top && `Incisal : ${item?.shade?.top}`}
      </Text>
      <Text style={styles.tableTextItem}>
        {item?.shade?.middle && `Middle : ${item?.shade?.middle}`}
      </Text>
      <Text style={styles.tableTextItem}>
        {item?.shade?.bottom && `Cervical : ${item?.shade?.bottom}`}
      </Text></> },
    {title:  "labManageGold.pontic", content:  <Text style={styles.tableTextItem}> {item?.pontic?.join(",")} </Text>}
  ]
  if (isLangAr) {
    tableHeaders.reverse()
  }
  return (
    <View style={[styles.request_container]}>
      <Text
        style={[
          styles.info_text_field_bold,
          styles.request_header,
          { width: "100%" }, { textAlign: isLangAr ? "right": "left"}
        ]}
      >
        {`${index}. ${item?.manufacture?.name}`}
      </Text>
      <Text
        style={[
          styles.info_text_field_bold,
          styles.request_header,
          { width: "100%" }, { textAlign: isLangAr ? "right": "left"}
        ]}
      >
        {`(${translate(intl, "common.ui.orderNo")} : ${item?.requestId} )`}
      </Text>
      <View style={[styles.paper]}>
        <View style={[styles.row, {flexDirection: isLangAr? 'row-reverse' : 'row', alignItems: "flex-start", textAlign: isLangAr? "right": "left"}]}>
          <Text style={styles.info_text_field_bold}>
            {translate(intl, "common.ui.drName")}
          </Text>
          <Text style={styles.info_text_field}>{data?.doctorName}</Text>
          <Text style={styles.info_text_field_bold}>
            {translate(intl, "common.ui.orderType")}
          </Text>
          <Text style={styles.info_text_field}>{item.type}</Text>
        </View>
        <View style={[styles.row, {flexDirection: isLangAr? 'row-reverse' : 'row', alignItems: "flex-start", textAlign: isLangAr? "right": "left"}]}>
          <Text style={styles.info_text_field_bold}>
            {translate(intl, "common.ui.orderDate")}
          </Text>
          <Text style={styles.info_text_field}>
            {moment(item.requestDate, "X").format("YYYY.MM.DD")}
          </Text>
          <Text style={styles.info_text_field_bold}>
            {translate(intl, "common.ui.dueDate")}
          </Text>
          <Text style={styles.info_text_field}>
            {" "}
            {moment(item.deliveryRequestDate, "X").format("YYYY.MM.DD")}
          </Text>
        </View>
      </View>
      <View style={styles.table}>
        {/* TableHeader */}
        <View style={[styles.tableRow]} wrap={false}>
          {tableHeaders.map(each => (
            <View style={styles.tableCol} wrap={false}>
              <View style={[styles.tableCell, styles.tableHeader]}>
                <Text style={styles.tableTextItem}>
                  {translate(intl, each.title)}
                </Text>
              </View>
            </View>
          ))}
        </View>
        {/* TableContent */}
        <View style={styles.tableRow} wrap={false}>
          {tableHeaders.map(each => (
            <View style={styles.tableCol} wrap={false}>
              <View style={styles.tableCell}>
                {each.content}
              </View>
            </View>
          ))}
        </View>
      </View>
      {item?.detail && item?.detail?.length > 0 && (
        <View style={styles.comments} wrap={false}>
          <View style={[styles.commentsHeader]}>
            <Text
              style={[styles.info_text_field, styles.commentText]}
              wrap={false}
            >
              {translate(intl, "common.ui.details")}
            </Text>
          </View>
          <View style={[styles.commentsBody]}>
            <Text
              style={[styles.info_text_field, styles.commentText]}
              wrap={false}
            >
              {item?.detail.join(",")}
            </Text>
          </View>
        </View>
      )}
      {!!item?.note && (
        <View style={styles.comments} wrap={false}>
          <View style={[styles.commentsHeader]}>
            <Text
              style={[styles.info_text_field, styles.commentText]}
              wrap={false}
            >
              Add Information
            </Text>
          </View>
          <View style={[styles.commentsBody]}>
            <Text
              style={[styles.info_text_field, styles.commentText]}
              wrap={false}
            >
              {item.note}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

const RequestDoc = props => {
  const { stamp, data, hospital, intl } = props;
  const lang = localStorage.getItem("lang")
  let fontConfig = getFontFaceByLang(lang)
  Font.register(fontConfig.fontFace);
  const isLangAr = lang === 'ar'
  return (
    <Document
      title={`${translate(intl, "common.ui.prosthesisOrderForm")} - ${
        hospital.name
      }`}
      author={"Clever"}
      creator={"Clever"}
      producer={"Clever"}
    >
      <Page size="A4" style={[styles.page, { fontFamily: fontConfig.fontFamily}]} wrap>
        <View style={[styles.title, {flexDirection: isLangAr? 'row-reverse' : 'row', alignItems: "flex-start"}]}>
          <Text style={styles.title_text_field}>
            {translate(intl, "common.ui.prosthesisOrderForm")}
          </Text>
        </View>
        <PatientInfo data={data} hospital={hospital} intl={intl} />
        {data.data.map((item, index) => {
          return (
            <RequestContent
              intl={intl}
              key={item.id}
              index={index + 1}
              data={data}
              hospital={hospital}
              item={item}
            />
          );
        })}
        <Footer stamp={stamp} hospital={hospital} data={data} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default RequestDoc;
