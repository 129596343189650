import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { FormattedMessage } from "../../../locales/language";
import { ProgressIcon18, RemoveIcon } from "../../themes/common/icons";
import { CCTextField, CCTypography } from "../index";

const useStyle = makeStyles(theme => ({
  root: {},
  inputProps: {
    width: 140
  },
  directInputIcon: {
    color: theme.palette.secondary.main,
    marginRight: 8
  }
  // directInput: {
  //   backgroundColor: "#ababab"
  // }
}));

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "init":
//       return { ...state, value: action.target };
//     case "change":
//       if (action.target === false) {
//         action.onChange &&
//           action.onChange({ target: { select: action.target, value: "" } });
//         return { select: action.target, value: "" };
//       } else {
//         return { ...state, select: action.target };
//       }
//     case "modify":
//       if (state.value !== action.target) {
//         action.onChange &&
//           action.onChange({ target: { ...state, value: action.target } });
//       }
//       return { ...state, value: action.target };
//     default:
//       return state;
//   }
// };

const CCTextFieldDualType = props => {
  const {
    value,
    onChange,
    variant,
    className,
    InputProps,
    directInput,
    error,
    autoFocus,
    ...others
  } = props;
  const classes = useStyle();
  // const [inputType, setInputType] = useState(directInput ? directInput : false);
  // const [state, dispatchState] = useReducer(
  //   reducer,
  //   {
  //     value: value,
  //     select: !directInput || true
  //   },
  //   value => {
  //     return {
  //       value: value,
  //       select: !directInput || true
  //     };
  //   }
  // );
  const inputRef = useRef();
  // useEffect(() => {
  //   dispatchState({
  //     type: "change",
  //     target: !directInput,
  //     onChange: onChange
  //   });
  // }, [directInput, onChange]);
  // useEffect(() => {
  //   dispatchState({ type: "init", target: value });
  // }, [value]);
  useEffect(() => {
    if (directInput === true && inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [directInput, autoFocus]);
  const endIcon = directInput ? { endIcon: <RemoveIcon /> } : {};
  // return (
  //   <CCTextField
  //     {...others}
  //     inputRef={inputRef}
  //     className={clsx(classes.root, className)}
  //     onChange={e => {
  //       console.log("e.target.value", e.target.value);
  //       if (e.target.value === null) {
  //         dispatchState({ type: "change", target: false, onChange: onChange });
  //       } else {
  //         dispatchState({
  //           type: "modify",
  //           target: e.target.value,
  //           onChange: onChange
  //         });
  //       }
  //     }}
  //     variant={variant || "outlined"}
  //     select={Boolean(state?.select)}
  //     value={state?.value || ""}
  //     onClickIcon={e => {
  //       dispatchState({ type: "change", target: true, onChange: onChange });
  //     }}
  //     InputProps={{
  //       ...InputProps,
  //       className: clsx(
  //         classes.inputProps,
  //         InputProps?.className ? InputProps.className : ""
  //       )
  //     }}
  //     {...endIcon}
  //   >
  //     {props.children}
  //     <MenuItem className={clsx(classes.directInput)} value={null}>
  //       <CCTypography variant={"body1"}>{"직접입력"}</CCTypography>
  //     </MenuItem>
  //   </CCTextField>
  // );
  if (!directInput) {
    return (
      <CCTextField
        {...others}
        error={error}
        inputRef={inputRef}
        className={clsx(classes.root, className)}
        onChange={e => {
          if (e.target.value === null) {
            onChange({ target: { value: "", select: false } });
          } else {
            onChange({ target: { value: e.target.value, select: true } });
          }
        }}
        variant={variant || "outlined"}
        select={true}
        value={value || ""}
        InputProps={{
          ...InputProps,
          className: clsx({
            [classes.inputProps]: Boolean(InputProps?.className),
            [InputProps?.className]: Boolean(InputProps?.className)
          })
        }}
      >
        <MenuItem className={clsx(classes.directInput)} value={null}>
          <ProgressIcon18
            color={"secondary"}
            className={classes.directInputIcon}
          />
          <CCTypography {...others} variant={"h5"}>
            <FormattedMessage id="ccDirectInput" />
          </CCTypography>
        </MenuItem>
        {props.children}
      </CCTextField>
    );
  } else {
    return (
      <CCTextField
        {...others}
        error={error}
        inputRef={inputRef}
        className={clsx(classes.root, className)}
        onChange={e => {
          onChange({ target: { value: e.target.value, select: false } });
        }}
        variant={variant || "outlined"}
        value={value || ""}
        onClickIcon={e => {
          onChange({ target: { value: "", select: true } });
        }}
        InputProps={{
          ...InputProps,
          className: clsx({
            [classes.inputProps]: Boolean(InputProps?.className),
            [InputProps?.className]: Boolean(InputProps?.className)
          })
        }}
        {...endIcon}
      />
    );
  }
};
CCTextFieldDualType.defaultProps = {
  autoFocus: true
};

export default CCTextFieldDualType;
