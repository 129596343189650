import React from "react";

const FilterIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M4.212 5.61C6.232 8.2 9.962 13 9.962 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.002c-.83 0-1.3.95-.79 1.61z"
            transform="translate(-5176 -263) translate(5176 263)"
          />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5176 -263) translate(5176 263)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default FilterIcon;
