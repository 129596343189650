import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useRef } from "react";
import {
  CCTypography
} from "styles/src/components";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflow: "hidden",
    boxSizing: "border-box"
  },
  table: {
    height: "100%",
    position: "relative",
    boxSizing: "border-box"
  },
  table__title: {
    padding: 8,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__head: {},
  table__body: {
    position: "relative",
    overflow: "auto",
    backgroundColor: theme.palette.result.blank
  },
  table__head__sort: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  table__head__row: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__head__cell: {
    flex: 1,
    fontSize: 14,
    padding: 8,
    boxSizing: "border-box",
    display: "inline-flex",
    minWidth: 0,
    justifyContent: "center",
    alignItems: "center"
    // alignSelf: "center"
  },
  table__head__label: {
    lineHeight: "24px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  table__head__cell__del: {
    display: "flex",
    // alignItems: "center",
    minWidth: 24,
    padding: "0px 4px"
  },
  table__head__cell__cursor: { cursor: "pointer" },
  table__body__row: {
    display: "flex",
    minHeight: 36,
    borderBottom: `1px solid ${theme.palette.background.default}`,
    backgroundColor: "#FFFFFF"
  },
  table__body__row__select: {
    background: `${theme.palette.select.main} !important`
  },
  table__body__cell: {
    flex: 1,
    fontSize: 14,
    overflow: "hidden",
    overflowWrap: "break-word",
    padding: "8px 4px",
    // alignSelf: "center",
    boxSizing: "border-box"
  },
  table__body__cell__del: {
    minWidth: 16,
    // alignSelf: "center",
    padding: "8px 4px"
  },
  table__body__cell__hover: {
    cursor: "pointer",
    "&:hover": {
      background: `${theme.palette.result.main} !important`
    }
  },
  table__contents__blank: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0
    // backgroundColor: "#000000 !important",
    // opacity: 0.54
  },
  table__contents__blank__container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  table__contents__blank__item: { color: "white" },
  table__day__button: {
    width: 230,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    top: 4,
    right: 40,
    borderRadius: 20,
    minWidth: 24,
    minHeight: 24,
    padding: 0,
    margin: 0,
    color: theme.palette.icon,
    "& > div": {
      margin: 0,
      height: 25,
      flexDirection: "unset"
    },
    "& > button": {
      padding: 0,
      fontSize: 12
    }
  },
  table__add__button: {
    position: "absolute",
    top: 4,
    right: 4,
    borderRadius: 20,
    minWidth: 24,
    minHeight: 24,
    padding: 0,
    margin: 0,
    color: theme.palette.icon
  },
  table__del__icon: { fontSize: 16 },
  table__sort__icon: {
    color: theme.palette.sub.main,
    width: 19,
    height: 19
  },
  table__icon__button: {
    borderRadius: 20,
    color: theme.palette.input.main,
    minWidth: 24,
    minHeight: 24,
    padding: 0,
    margin: "0 !important"
  },
  hidden: {
    display: "none"
  },
  visibilityHidden: {
    visibility: "hidden"
  },
  table__body__pagination__button: {
    width: "100%"
  },
  align__center: {
    display: "flex",
    alignItems: "center"
  },
  table__depth__background: {
    backgroundColor: theme.palette.background.default
  }
}));

const StatusTable = props => {
  const {
    classes: userClass,
    className,
    heads,
    contents,
    onRowClick,
    title,
    selectedRow,
    rowStyle = () => {
    }
  } = props;
  const classes = useStyles();
  const refBody = useRef(null);
  const refBodyRow = useRef(null);
  const refHead = useRef(null);

  return (
    <Box
      className={clsx(
        {
          [classes.root]: true,
          [className]: true
        },
        userClass && userClass.root
      )}
    >
      <Box className={classes.table}>
        <Box
          className={clsx({
            [classes.table__head]: true
          })}
          ref={refHead}
        >
          <Box className={classes.table__title}>{title}</Box>
        </Box>
        <Box
          className={clsx(
            classes.table__body,
            userClass && userClass.table__body
          )}
          ref={refBody}
          // style={{ height: `calc(100% - ${height}px)` }}
          style={{ height: "100%" }}
        >
          {contents?.length > 0 && contents
            .map((rowData, rowIndex) => {
              return (
                <Box
                  className={clsx(userClass && userClass.table__body__row, {
                    [classes.table__body__row]: true,
                    [classes.table__body__cell__hover]: onRowClick,
                    [classes.table__body__row__select]: selectedRow === rowIndex,
                    [classes.table__depth__background]: rowData?.depth === 2
                  })}
                  key={rowIndex}
                  ref={refBodyRow}
                  style={rowStyle(rowData)}
                >

                  {heads.map((head, headIndex) => {
                    return (
                      <Box
                        className={clsx({
                          [classes.table__body__cell]: head.hasOwnProperty(
                            "padding"
                          )
                            ? head.padding
                            : true,
                          [head.className]: true,
                          [classes.table__body__cell__hover]: head.hover
                        })}
                        style={
                          head.width && { width: head.width, flex: "unset" }
                        }
                        key={headIndex}
                        onClick={e => {
                          // setSelectRow(rowIndex);
                          onRowClick &&
                          onRowClick({
                            e,
                            rowIndex: rowIndex,
                            key: head.key,
                            data: rowData
                          });
                        }}
                      >
                        {(() => {
                          if (typeof head.component !== "function") {
                            return (
                              <CCTypography
                                style={{ lineHeight: "24px" }}
                                component={"span"}
                              >
                                {typeof rowData[head.key] === "string"
                                  ? rowData[head.key]
                                  : JSON.stringify(rowData[head.key])}
                              </CCTypography>
                            );
                          } else {
                            return head.component({
                              rowIndex: rowIndex,
                              key: head.key,
                              cellData: rowData[head.key],
                              rowData: rowData,
                              viewIndex: rowIndex
                            });
                          }
                        })()}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          {/*{!contents.length && (*/}
          {/*  <Box className={classes.table__contents__blank}>*/}
          {/*    <Grid*/}
          {/*      container*/}
          {/*      className={classes.table__contents__blank__container}*/}
          {/*    >*/}
          {/*      <Grid item className={classes.table__contents__blank__item}>*/}
          {/*        <CCTypography variant="body2">*/}
          {/*          {intl.formatMessage({ id: "ccHasNoData" })}*/}
          {/*        </CCTypography>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Box>
      </Box>
    </Box>
  );
};

export default StatusTable;
