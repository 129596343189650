import React from "react";

const NumbernineIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M10.483 17.186l.486-.007c1.513-.064 2.682-.57 3.507-1.518.824-.948 1.237-2.251 1.237-3.91v-.731c-.005-.793-.148-1.498-.43-2.113-.283-.615-.68-1.087-1.19-1.415-.51-.328-1.094-.492-1.75-.492-.638 0-1.212.152-1.723.455-.51.303-.908.726-1.193 1.271C9.142 9.271 9 9.87 9 10.521c0 1.025.264 1.84.793 2.443.529.604 1.233.906 2.112.906.688 0 1.29-.253 1.805-.759-.141 1.536-1.071 2.343-2.79 2.42l-.437.007v1.648zm1.908-4.827c-.452 0-.801-.174-1.05-.523-.248-.348-.372-.794-.372-1.336 0-.547.127-1.001.383-1.364.255-.362.58-.543.977-.543.428 0 .77.184 1.025.553.256.37.383.89.383 1.56v.813c-.118.232-.293.43-.523.594-.23.164-.504.246-.823.246z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default NumbernineIcon;
