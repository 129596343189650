import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  table: {
    borderCollapse: "separate"
  },
  fixed__table: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: 300
  },
  fixed__table__shadow: {
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24)"
  },
  fixed__table__head: {},
  fixed__table__body: { backgroundColor: "white" },
  unfixed__table: {
    overflowY: "auto",
    height: 400
  },
  body__table__row__selected: {
    backgroundColor: theme.palette.select.main
    // "&:hover": {
    //   backgroundColor: "red",
    //   color: "white"
    // }
  },
  head__cell: {
    height: 36,
    minHeight: 36,
    maxHeight: 36,
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    backgroundColor: "white"
  },
  fixed__cell: { borderBottom: "1px solid white" },
  unfixed__cell: { borderBottom: `1px solid ${theme.palette.background.default}` },
  cell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: 0,
    verticalAlign: "top",
    position: "relative",
    boxSizing: "border-box"
  },
  cell__first: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  cell__others__left: {
    borderLeft: `1px solid ${theme.palette.border.main}`
    // borderBottom: "1px solid #b0bec5"
  },
  cell__others__bottom: {
    // borderLeft: "1px solid #b0bec5",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  cell__text__center: {
    textAlign: "center"
  },
  hidden: {
    display: "none"
  },
  visibility__hidden: {
    // visibility: "hidden",
    height: 0,
    overflow: "hidden"
  },
  row__click: {
    cursor: "pointer"
  },
  first__row: {
    boxShadow: "inset 0 10px 10px -10px black"
  }
}));

const getColSpan = (name, contents, compare) => {
  var rowSpans = [];
  var start = -1;

  contents.forEach((el, index) => {
    if (index === 0) {
      start = 0;
      rowSpans[start] = 1;
    } else if (
      compare
        ? compare(contents[start][name], el[name], contents[start], el)
        : contents[start][name] === el[name]
    ) {
      rowSpans[start]++;
      rowSpans[index] = 0;
    } else {
      start = index;
      rowSpans[start] = 1;
    }
  });
  return rowSpans;
};

const getSpan = (heads, contents) => {
  var spans = {};

  Object.values(heads).forEach(head => {
    if (head.span)
      spans[head.key] = getColSpan(head.key, contents, head.compare);
  });

  return spans;
};

const CCTableExp = props => {
  const {
    className,
    heads,
    contents = [],
    fixedContents = [],
    rowClick,
    selectedItem
  } = props;
  const classes = useStyles();
  const fixedTableRef = useRef();
  const [height, setHeight] = useState(0);

  const spanInfo = getSpan(heads, contents);
  const fixedSpanInfo = getSpan(heads, fixedContents);

  // const getWidthStyle = (width, offset = 0) => {
  //   if (width)
  //     return {
  //       minWidth: width + offset,
  //       maxWidth: width + offset,
  //       width: width + offset
  //     };
  //   return {};
  // };

  const getNextRowSpan = (head, rowIndex, isFixed) => {
    const info = isFixed ? fixedSpanInfo : spanInfo;
    const lastIndex = (isFixed ? fixedContents.length : contents.length) - 1;
    const nextIndex = rowIndex + 1;
    if (nextIndex > lastIndex) {
      return true;
    }
    const span = head.span ? info[head.key][nextIndex] : 1;
    return Boolean(span);
  };

  useEffect(() => {
    setHeight(fixedTableRef.current.offsetHeight);
  }, [fixedContents]);

  return (
    <Box className={clsx(className, classes.root)}>
      <Box
        className={clsx({
          [classes.fixed__table]: true,
          // [classes.fixed__table__shadow]: true
          [classes.fixed__table__shadow]: fixedContents.length
        })}
        style={{ backgroundColor: "#ececec", position: "relative", zIndex: 1 }}
        ref={fixedTableRef}
      >
        <Table stickyHeader className={classes.table}>
          <TableHead className={classes.fixed__table__head}>
            <TableRow>
              {heads.map((head, cellIndex) => {
                return (
                  <TableCell
                    className={clsx({
                      [classes.head__cell]: true,
                      [classes.cell__text__center]: !head.NoHeadAlign
                    })}
                    key={cellIndex}
                    style={head.style}
                  >
                    {head.headComponent &&
                      head.headComponent({
                        cellIndex: cellIndex
                      })}
                    {!head.headComponent && head.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.fixed__table__body}>
            {fixedContents.map((row, rowIndex) => {
              return (
                <TableRow
                  key={rowIndex}
                  className={clsx({
                    [classes.row__click]: true,
                    [classes.body__table__row__selected]:
                      selectedItem.uuid === row.uuid &&
                      selectedItem.type === "fixed"
                  })}
                  onClick={() => {
                    rowClick &&
                      rowClick({
                        rowData: row,
                        rowIndex: rowIndex,
                        type: "fixed"
                      });
                  }}
                >
                  {heads.map((head, cellIndex) => {
                    const span = head.span
                      ? fixedSpanInfo[head.key][rowIndex]
                      : 1;

                    const component = () => {
                      if (head.fixedComponent) {
                        return head.fixedComponent({
                          rowIndex: rowIndex,
                          cellIndex: cellIndex,
                          cellData: row[head.key],
                          rowData: row,
                          rowSpan: span
                        });
                      } else if (head.component) {
                        return head.component({
                          rowIndex: rowIndex,
                          cellIndex: cellIndex,
                          cellData: row[head.key],
                          rowData: row,
                          rowSpan: span
                        });
                      } else {
                        return row[head.key];
                      }
                    };

                    return (
                      <TableCell
                        className={clsx(
                          {
                            // [classes.hidden]: !span,
                            [classes.cell]: true,
                            [classes.fixed__cell]: true,
                            [classes.cell__first]:
                              !cellIndex &&
                              (span === 1 ||
                                getNextRowSpan(head, rowIndex, true)),
                            [classes.cell__others__left]: cellIndex,
                            [classes.cell__others__bottom]:
                              span === 1 ||
                              getNextRowSpan(head, rowIndex, true),
                            [classes.cell__text__center]: !head.NoBodyAlign
                            // [classes.body__table__row__selected]:
                            //   selectedItem.uuid === row.uuid &&
                            //   selectedItem.type === "fixed"
                          },
                          head.className && head.className({ rowData: row })
                        )}
                        // rowSpan={span}
                        key={cellIndex}
                        style={{ ...head.style, ...head.bodyStyle }}
                        onClick={e => {
                          if (head.onClick) {
                            e.stopPropagation();
                            head.onClick({
                              rowData: row,
                              rowIndex: rowIndex,
                              cellData: row[head.key],
                              cellIndex: cellIndex
                            });
                          }
                        }}
                      >
                        <div
                          className={clsx({
                            [classes.visibility__hidden]: !span
                          })}
                        >
                          {component()}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* <Box style={{ position: "absolute" }}>aaa</Box> */}
      </Box>
      <Box
        className={classes.unfixed__table}
        style={{ height: `calc(100% - ${height}px)` }}
      >
        <Table className={classes.table}>
          <TableHead
            className={clsx({
              [classes.hidden]: false
            })}
            style={{ visibility: "collapse" }}
          >
            <TableRow>
              {heads.map((head, cellIndex) => {
                return (
                  <TableCell
                    className={clsx({
                      [classes.cell]: true
                    })}
                    key={cellIndex}
                  >
                    {head.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {contents.map((row, rowIndex) => {
              return (
                <TableRow
                  className={clsx({
                    [classes.row__click]: true,
                    // [classes.first__row]: rowIndex === 0,
                    [classes.body__table__row__selected]:
                      selectedItem.uuid === row.uuid &&
                      selectedItem.type === "unfixed"
                  })}
                  key={rowIndex}
                  onClick={() => {
                    rowClick &&
                      rowClick({
                        rowData: row,
                        rowIndex: rowIndex,
                        type: "unfixed"
                      });
                  }}
                >
                  {heads.map((head, cellIndex) => {
                    const span = head.span ? spanInfo[head.key][rowIndex] : 1;
                    const component = () => {
                      if (head.component) {
                        return head.component({
                          rowIndex: rowIndex,
                          cellIndex: cellIndex,
                          cellData: row[head.key],
                          rowData: row,
                          rowSpan: span
                        });
                      } else {
                        return row[head.key];
                      }
                    };

                    return (
                      <TableCell
                        className={clsx(
                          {
                            // [classes.hidden]: !span,
                            [classes.cell]: true,
                            [classes.unfixed__cell]: true,
                            [classes.cell__first]:
                              !cellIndex &&
                              (span === 1 ||
                                getNextRowSpan(head, rowIndex, false)),
                            [classes.cell__others__left]: cellIndex,
                            [classes.cell__others__bottom]:
                              span === 1 ||
                              getNextRowSpan(head, rowIndex, false),
                            [classes.cell__text__center]: !head.NoBodyAlign
                            // [classes.body__table__row__selected]:
                            //   selectedItem.uuid === row.uuid &&
                            //   selectedItem.type === "unfixed"
                          },
                          head.className && head.className({ rowData: row })
                        )}
                        // rowSpan={span}
                        key={cellIndex}
                        style={{ ...head.style, ...head.bodyStyle }}
                        onClick={e => {
                          if (head.onClick) {
                            e.stopPropagation();
                            head.onClick({
                              rowData: row,
                              rowIndex: rowIndex,
                              cellData: row[head.key],
                              cellIndex: cellIndex
                            });
                          }
                        }}
                      >
                        <div
                          className={clsx({
                            [classes.visibility__hidden]: !span
                          })}
                        >
                          {component()}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

CCTableExp.propTypes = {
  // onSearch: PropTypes.func,
  // onDownClick: PropTypes.func,
  // onUpClick: PropTypes.func,
  // onSettingClick: PropTypes.func,
  // onAddClick: PropTypes.func
};

export default CCTableExp;
