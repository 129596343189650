import React from "react";

const ClaimexcludedIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M20.71 5.633l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42L3 16.252v4.75h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42zM6.92 19.002L5 17.083l8.06-8.06 1.92 1.92-8.06 8.06z"
      />
      <path d="M0 0H24V24H0z" transform="matrix(0 1 1 0 0 0)" />
    </g>
  </svg>
);
export default ClaimexcludedIcon;
