import React from "react";

const ResizeIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M7.001 12v4.986h5.026L12.034 19H5l.007-7h1.994zm12.022-7l-.007 6.91H17.02V7.07h-5.012L12 5h7.023z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default ResizeIcon;
