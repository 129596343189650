import React from "react";

const NumberfiveIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12.37 17.09c.688 0 1.285-.14 1.791-.42.506-.28.895-.675 1.166-1.183.27-.508.406-1.084.406-1.726 0-1.058-.268-1.88-.806-2.468-.538-.588-1.28-.882-2.229-.882-.528 0-1.027.125-1.497.376l.246-2.14h3.958V7H9.827l-.574 5.059 1.572.39.192-.178c.291-.247.672-.37 1.141-.37.51 0 .905.15 1.183.452.278.3.417.733.417 1.298 0 .588-.121 1.043-.362 1.364-.242.321-.586.482-1.033.482-.4 0-.725-.115-.974-.345-.248-.23-.393-.553-.434-.967H9c.018.565.177 1.068.475 1.51.299.442.705.785 1.22 1.03.515.243 1.074.365 1.675.365z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default NumberfiveIcon;
