import React from "react";

const MoonstandIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M11.94 2.3c-2.38-.59-4.68-.27-6.63.64-.35.16-.41.64-.1.86 2.6 1.8 4.3 4.8 4.3 8.2 0 3.4-1.7 6.4-4.3 8.2-.32.22-.26.7.09.86 1.28.6 2.71.94 4.21.94 6.05 0 10.85-5.38 9.87-11.6-.61-3.92-3.59-7.16-7.44-8.1z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default MoonstandIcon;
