/*eslint react-hooks/exhaustive-deps: 0*/
import { makeStyles } from "@material-ui/styles";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import {
  CCButton,
  CCDialogAlert,
  CCIconButton,
  CCTypography
} from "styles/components";
import { BringInIcon } from "styles/icons";
import { useIntl } from "utils/language";
import DetailModify from "./components/DetailModify/DetailModify";
import { Box, Grid } from "@material-ui/core";
import {
  CCDialog,
  CCDialogContent,
  CCDialogTitle
} from "styles/src/components";
import { CVButton, DialogWarningMessage, enqueueToast } from "components";
import { DeleteIcon, MenunextIcon } from "styles/src/themes/common/icons";

import { getCustomFormat, getTimestamp } from "utils/datetime";
import {
  REMOVE_PRODUCT,
  SEARCH_PRODUCT,
  UPDATE_PRODUCT
} from "queries/product";
import { useApolloClient, useQuery } from "@apollo/client";
import useProductValidation from "validations/useProductValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { DBStorageContext } from "context/DBContext/components";
import { useSnackbar } from "notistack";
import { useNotification } from "utils/notification";
import { NOTIFICATION_TYPE } from "types/notification";
import { useRemoveHistory } from "views/Normal/InventoryManagement/Component/Stock/Component/ProductTab/hooks";
import { TabContext } from "../../../../../../Purchase";
import ProgressTable from "./components/ProgressTable";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 1600,
      display: "inline-block"
    }
  },
  width100Per: {
    width: "100%"
  },
  width70Per: {
    width: "70%",
    paddingRight: 16
  },
  width60Per: {
    width: "60%"
  },
  width50Per: {
    width: "50%"
  },
  width40Per: {
    width: "40%"
  },
  width20Per: {
    width: "20%"
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    // background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  icon__history: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  },
  delete__button: {
    position: "absolute",
    left: 10,
    width: "200px"
  }
}));

const initData = {
  hospital: "",
  important: false,
  unit: "",
  currentCnt: 0,
  optCnt: 0,
  alert: false,
  editDate: getTimestamp(),
  editorID: "",
  canExpire: false,
  expire: {
    expireCount: 0,
    expireDate: getTimestamp()
  },
  binCnt: 0,
  barcode: false,
  isAppropriate: false
};

const EstimateDetailDialog = props => {
  const client = useApolloClient();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const isArabic = localStorage.getItem("lang") === "ar";
  const createNotification = useNotification();
  const { deleteHistory } = useRemoveHistory();
  const { open, onClose, value, product } = props;

  const classes = useStyles();
  const selectRef = useRef();

  const [selected, setSelected] = useState();
  const { setTabs, setProduct } = useContext(TabContext);

  const [alertOpen, setAlertOpen] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const [detail, setDetail] = useState();

  const [storage, setStorage] = useState(initData);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);

  const { data } = useQuery(SEARCH_PRODUCT, {
    variables: {
      def: "1",
      args: {
        hospital: localStorage.getItem("hospitalId"),
        productID: value
      },
      comparison: "and",
      match: "contains"
    },
    onCompleted: e => {
      if (Boolean(e?.searchProduct?.length > 0)) {
        setDetail(e?.searchProduct[0]);
      }
    }
  });

  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);

  useEffect(() => {
    setSelected(selectRef?.current);
  }, [selectRef, selected]);

  const { schema } = useProductValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: storage,
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    methods.reset(storage);
    setStorage(data?.searchProduct[0]);
  }, [storage, data, methods.reset]);

  const onClosure = () => {
    methods.reset(storage);

    if (Object.keys(methods.formState.dirtyFields)?.length > 0 || selectRef) {
      setCancelDialog({
        title: intl.formatMessage(
          { id: "confirmCancelwritingInfo" },
          { br: () => <br /> }
        ),
        onClose: () => {
          setCancelDialog(null);
        },
        onDisagree: () => {
          setCancelDialog(null);
        },
        onAgree: () => {
          onClose && onClose();
        }
      });
    } else {
      onClose && onClose();
    }
  };

  const { dispatchDBStorage } = useContext(DBStorageContext);

  const save = useCallback(
    e => {
      client
        .mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            def: "1",
            hospital: localStorage.getItem("hospitalId"),
            major: storage?.major,
            minor: storage?.minor,
            manufacture: e?.manufacture,
            productName: e?.productName,
            productID: storage?.productID,
            storage: {
              ...storage?.storage,
              alert: Boolean(e?.alert === "true"),
              canExpire: Boolean(e?.expireList?.length > 0),
              unit: e?.storage?.unit,
              binCnt: e?.binCnt,
              expire: Boolean(e?.expireList?.length > 0)
                ? e?.expireList.map(item => {
                    let _include = Boolean(
                      storage?.storage?.expire
                        ?.map(i => i.expireDate)
                        .includes(item?.expireDate)
                    );

                    return {
                      expireDate: _include
                        ? item?.expireDate
                        : item?.expireDate?.unix(),
                      expireCount: Number(item?.expireCount)
                    };
                  })
                : []
            }
          }
        })
        .then(r => {
          let _product = r?.data?.updateProduct;

          dispatchDBStorage({
            type: "product",
            target: _product
          });

          let _expire = _product?.storage?.expire
            ? _product?.storage?.expire?.sort(
                (a, b) => a?.expireDate - b?.expireDate
              )[0]
            : false;

          if (
            !product?.storage?.expire ||
            product?.storage?.expire !== _product?.expire
          ) {
            createNotification({
              variables: {
                hospitalId: localStorage.getItem("hospitalId"),
                category: NOTIFICATION_TYPE.INVENTORY_MANAGEMENT.value,
                title: `${
                  _product?.productName
                } has an inventory that is about to expire. (${getCustomFormat(
                  _expire?.expireDate,
                  "X",
                  "L"
                )})`,
                localeKey: "expireDateNotification",
                localeArgs: [_product?.productName],
                dateArgs: _expire?.expireDate,
                keyId: _product?.productID
              }
            });
          }
        });

      enqueueToast(
        enqueueSnackbar,
        ` ${intl.formatMessage({
          id: "saveCompleted"
        })}`
      );

      onClose();
      dispatchDBStorage({ type: "init" });
    },
    [client, data, detail, storage]
  );

  const onDelete = useCallback(() => {
    client
      .mutate({
        mutation: REMOVE_PRODUCT,
        variables: {
          def: "1",
          productID: storage?.productID
        }
      })
      .then(async () => {
        await deleteHistory(storage?.productID);
      });
    enqueueToast(
      enqueueSnackbar,
      ` ${intl.formatMessage({
        id: "deletedProduct"
      })}`
    );

    onClose();
    dispatchDBStorage({ type: "init" });
  }, [client, data, detail, storage]);

  const { control } = methods;

  const onCreate = () => {
    methods.handleSubmit(save, errors => {
      console.error("error", errors);
    })();
  };

  return (
    <>
      {/*<DialogLoading open={creating} text={"Loading"} />*/}
      <FormProvider {...methods}>
        <CCDialog open={open} className={classes.root} onClose={onClose}>
          <CCDialogTitle onCloseButton={onClosure} className={classes.height}>
            <Grid style={{ height: "15px" }} item>
              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="row">
                  <CCTypography variant="h3">
                    {intl.formatMessage({ id: "productInformation" })}
                  </CCTypography>
                </Box>
                <CVButton
                  color="normal"
                  variant={"contained"}
                  endIcon={<MenunextIcon />}
                  className={
                    isArabic ? classes.delete__button : classes.icon__history
                  }
                  onClick={() => {
                    onClose();
                    setTabs("1");
                    setProduct(detail);
                  }}
                >
                  {intl.formatMessage({ id: "inventoryHistory" })}
                </CVButton>
              </Box>
            </Grid>
          </CCDialogTitle>
          <CCDialogContent noPadding={true} style={{ overflow: "hidden" }}>
            <Grid container alignItems="center">
              <Grid item className={classes.width60Per}>
                <Controller
                  name={"directInput"}
                  control={control}
                  defaultValue={Boolean(detail) || true}
                  render={props => {
                    const { ref, value: inputValue, onBlur } = props;
                    return (
                      <DetailModify
                        modify={detail}
                        detail={value}
                        bool={inputValue}
                        noPadding={true}
                        value={storage}
                        ref={ref}
                        inputRef={ref}
                        onBlur={onBlur}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item className={classes.width40Per}>
                <Controller
                  name={"directInput"}
                  control={control}
                  defaultValue={Boolean(detail) || true}
                  render={props => {
                    const { ref, value: inputValue, onBlur } = props;
                    return (
                      <ProgressTable
                        modify={detail}
                        detail={value}
                        bool={inputValue}
                        noPadding={true}
                        value={storage}
                        ref={ref}
                        inputRef={ref}
                        onBlur={onBlur}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </CCDialogContent>

          <Box
            style={{ overflowY: "auto", padding: 10 }}
            marginLeft={"auto"}
            className={classes.icon__end}
          >
            <>
              <CCButton
                color="normal"
                startIcon={<DeleteIcon />}
                variant={"contained"}
                className={classes.delete__button}
                onClick={() => {
                  setDeleteDialog({
                    title: intl.formatMessage(
                      { id: "alertDelete" },
                      { br: () => <br /> }
                    ),
                    onClose: () => {
                      setDeleteDialog(null);
                    },
                    onDisagree: () => {
                      setDeleteDialog(null);
                    },
                    onAgree: onDelete
                  });
                }}
                disabled={detail?.storage?.currentCnt !== 0}
              >
                {intl.formatMessage({ id: "removeProduct" })}
              </CCButton>
              <CCButton
                className={classes.cancel__icon}
                variant={"text"}
                color={"normal"}
                onClick={onClosure}
              >
                {intl.formatMessage({ id: "cancel" })}
              </CCButton>
              <CCButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  onCreate();
                }}
              >
                {intl.formatMessage({ id: "save" })}
              </CCButton>
              <CCDialogAlert
                contents={
                  <Box className={classes.root}>
                    <CCTypography>
                      {intl.formatMessage({ id: "registerProduct" })}
                      <CCIconButton color={"secondary"} variant={"contained"}>
                        <BringInIcon />
                      </CCIconButton>
                      {intl.formatMessage({ id: "clickBtn" })}
                    </CCTypography>
                  </Box>
                }
                open={alertOpen}
                onClose={setCloseAlert}
                onAgree={setCloseAlert}
                onDisagree={setCloseAlert}
              />
              <CCDialogAlert
                contents={
                  <CCTypography>
                    {intl.formatMessage({ id: "registerProduct" })}
                    <CCIconButton color={"secondary"} variant={"contained"}>
                      <BringInIcon />
                    </CCIconButton>
                    {intl.formatMessage({ id: "registerInputContent" })}
                  </CCTypography>
                }
                open={saveAlertOpen}
                onClose={setCloseSaveAlert}
                onAgree={setCloseSaveAlert}
                onDisagree={setCloseSaveAlert}
              />
            </>
          </Box>
        </CCDialog>
      </FormProvider>
      <DialogWarningMessage open={Boolean(deleteDialog)} {...deleteDialog} />
      <DialogWarningMessage open={Boolean(cancelDialog)} {...cancelDialog} />
    </>
  );
};

export default EstimateDetailDialog;
