import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "../../../locales/language";

const DATE = Object.freeze({
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
  AGENDA: "agenda"
});

const useStyles = makeStyles(
  theme => ({
    root: {
      // fontSize: 14,
      // height: 30
    },
    toggle__button: {
      // color: theme.palette.common.black,
      // padding: "4px 8px 5px 8px ",
      // width: 60,
      // height: 28,
      // borderRadius: 4,
      // boxShadow: "0 2px 1px 0 rgba(0, 0, 0, 0.24)",
      // border: "solid 1px #ababab"
      fontFamily: "Noto Sans",
      fontSize: 12,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white
    },
    selected: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.select.main
      // boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)"
    }
  }),
  { name: "CCSimpleDateTab" }
);

const CCSimpleDateTab = props => {
  const {
    selected,
    onClickMonth,
    onClickWeek,
    onClickDay,
    className,
    ...other
  } = props;
  const intl = useIntl();
  const [dateType, setDateType] = useState("");

  const handleAlignment = (event, newDateType) => {
    setDateType(newDateType);
  };

  const classes = useStyles();

  useEffect(() => {
    if (selected) {
      setDateType(selected);
    }
  }, [selected]);

  return (
    <ButtonGroup
      className={clsx(className, classes.root)}
      size={"small"}
      // value={dateType}
      // exclusive
      onChange={handleAlignment}
      variant={"contained"}
      {...other}
    >
      {/*<Button*/}
      {/*  className={clsx(classes.toggle__button, {*/}
      {/*    [classes.selected]: dateType === DATE.MONTH*/}
      {/*  })}*/}
      {/*  onClick={onClickMonth}*/}
      {/*>*/}
      {/*  {intl.formatMessage({ id: `ccMonth` })}*/}
      {/*</Button>*/}
      <Button
        className={clsx(classes.toggle__button, {
          [classes.selected]: dateType === DATE.WEEK
        })}
        onClick={onClickWeek}
      >
        {intl.formatMessage({ id: "ccWeek" })}
      </Button>
      <Button
        className={clsx(classes.toggle__button, {
          [classes.selected]: dateType === DATE.DAY
        })}
        onClick={onClickDay}
      >
        {intl.formatMessage({ id: "ccDay" })}
      </Button>
    </ButtonGroup>
  );
};

CCSimpleDateTab.propTypes = {
  /** month, week, day 중 택 1 */
  selected: PropTypes.oneOf(["month", "week", "day", "agenda"]),
  /** month 클릭 시 실행되는 함수 */
  onClickMonth: PropTypes.func,
  /** week 클릭 시 실행되는 함수 */
  onClickWeek: PropTypes.func,
  /** day 클릭 시 실행되는 함수 */
  onClickDay: PropTypes.func
};

CCSimpleDateTab.defaultProps = {
  /** default : day */
  selected: DATE.DAY
};

export default CCSimpleDateTab;
export { DATE };
