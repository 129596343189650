import React from "react";

const RotateleftIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M7.04 10.53L5.63 9.11C4.73 10.27 4.17 11.61 4 13h2.02c.14-.87.49-1.72 1.02-2.47zM6.02 15H4c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V19.9c-.87-.15-1.71-.49-2.46-1.03l-1.44 1.45zm5.9-14.25V3L8.38 7.55 12.93 12V8.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z"
            transform="translate(-5125 -192) translate(5125 192) rotate(-90 11.965 12.465)"
          />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5125 -192) translate(5125 192)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default RotateleftIcon;
