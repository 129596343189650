import { useMutation } from "@apollo/react-hooks";
import { Box, Checkbox, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVButton, CVDialogSimpleDel, CVIconButton } from "components";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCPaper, CCTable, CCTypography } from "styles/components";
import { PARTNER_TYPES } from "types/partner";
import { translate } from "components";
import {
  DeleteIcon,
} from "styles/src/themes/common/icons";
import moment from "moment";
import { pickBy } from 'lodash';

const partnerRequestStatus = {
  REQUESTED: 0,
  CONFIRMED: 1,
  REJECTED: 2,
  CANCELED: 4,
};


const HANDLE_PARTNER_REQUEST = gql`
  mutation HandlePartnerRequest($input: RequestPartnerHandlerInput) {
    handlePartnerRequest(input: $input) {
      id
    }
  }
`;

const HANDLE_DELETE_PARTNER_REQUEST = gql`
  mutation handleDeletePartnerRequest($ids: [ID !]) {
    handleDeletePartnerRequest(ids: $ids) {
      code
      message
    }
  }
`;

const useTableStyles = makeStyles(theme => ({
  table__body: {
    height: "calc(100% - 70px) !important"
  },
  table__head__row: {
    height: '36px',
  }
}))

const useStyles = makeStyles(theme => ({
  del__btn: {
    color: `${theme.palette.error.main}`,
    position: 'absolute',
    right: '18px',
    top: -10,
    cursor: "pointer"
  },
  root: {
    height: 308,
    width: 1260,
    position: 'relative',
    marginBottom: 16
  },
  title: {
    padding: "7px 16px",
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  content: {
    with: '120%'
  },
  group__button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  cell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  reject__button: {
    marginRight: 16
  }
}));



const PartnerRequest = props => {
  // props
  const {
    data: partnerRequestData,
    loading: partnerRequestLoading,
    refetch,
    partnerRefetch
  } = props;

  // classes
  const classes = useStyles();
  const classesCustom = useTableStyles();
  const intl = useIntl();

  // value
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [listIdOrderChecked, setListIdOrderChecked] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isSelectedItemDelete, setIsSelectedItemDelete] = useState(false);

  console.log("listIdOrderChecked", listIdOrderChecked)

  // graphql
  const [handlePartnerRequest] = useMutation(HANDLE_PARTNER_REQUEST, {
    onCompleted: () => {
      refetch();
      partnerRefetch();
    }
  });

  const [handleDeletePartnerRequest] = useMutation(HANDLE_DELETE_PARTNER_REQUEST, {
    onCompleted: () => {
      refetch();
      partnerRefetch();
    }
  })

  const getCheckAllData = useCallback((checked) => {
    const partnerRequestList = data?.partnerRequestList;
    return (partnerRequestList.items || []).reduce((accumulator, currentValue) => {

      if (currentValue.status !== partnerRequestStatus.REQUESTED) {
        accumulator[currentValue.id] = checked
      }
      return accumulator;
    }, {})
  }, [data.partnerRequestList])

  // useEffect
  useEffect(() => {
    setLoading(partnerRequestLoading);
    if (!partnerRequestLoading && partnerRequestData) {
      setData(partnerRequestData);
    }
  }, [partnerRequestData, partnerRequestLoading]);

  useEffect(() => {
    const values = Object.values(listIdOrderChecked);
    setCheckAll(values.length > 0 ? values.filter(item => !item).length === 0 : false)
  }, [listIdOrderChecked])

  const handleDelete = () => {
    const deleteRequestList = getDeleteClinicIdList();
    setDeleteDialog(false);
    handleDeletePartnerRequest({
      variables: {
        ids: deleteRequestList
      }
    }).then(() => {
      setListIdOrderChecked({});
      setCheckAll(false);
    })
  };

  const getDeleteClinicIdList = () => {
    const deleteRequestObjectKeys = pickBy(listIdOrderChecked, (value, key) => { return value });
    const deleteRequestList = Object.keys(deleteRequestObjectKeys);

    return deleteRequestList;
  };

  const handleOpenDeleteDialog = () => {
    // console.log("handleOpenDeleteDialog.listIdOrderChecked", listIdOrderChecked)
    const deleteRequestList = getDeleteClinicIdList();
    // console.log('deleteRequestList', deleteRequestList)

    if(deleteRequestList.length) {
      setIsSelectedItemDelete(true)
    }

    openDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setIsSelectedItemDelete(false);
    closeDeleteDialog();
  };

  const openDeleteDialog = () => {
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const formatDeleteClinicWarningDesc = () => {
    if(isSelectedItemDelete) {
      return intl.formatMessage({
        id: "settings.clinic.deleteClinicWarningDesc"
      })
    }

    return intl.formatMessage({
      id: "selectAnItemToDelete"
    })
  };

  const formatDeleteTitleWarning = () => {
  
    if(isSelectedItemDelete) {
      return intl.formatMessage({
        id: "settings.clinic.deleteClinicWarningTitle"
      })
    }

    return intl.formatMessage({
      id: "common.messages.emptyStr"
    })
  
  }

  const formatDeleteClinicWarningConfirmButton = () => {
    if(isSelectedItemDelete) {
      return intl.formatMessage({
        id: "delete"
      })
    }

    return intl.formatMessage({
      id: "common.ui.confirm"
    })
  };


  // inReturn
  const heads = [
    {
      label: () => (<Checkbox
        checked={checkAll}
        onChange={(event) => {
          setCheckAll(event.target.checked);
          setListIdOrderChecked(getCheckAllData(event.target.checked))
        }}
      />),
      key: "checkbox",
      className: clsx(classes.cell),
      width: 50,
      component: ({ rowData, index }) => {

        const { status } = rowData;
        return <Checkbox
          disabled={status === partnerRequestStatus.REQUESTED}
          // checked={false}
          checked={status !== partnerRequestStatus.REQUESTED && (listIdOrderChecked[rowData.id] === true || checkAll)}
          onChange={(event) => {
            setListIdOrderChecked({
              ...listIdOrderChecked,
              [rowData.id]: event.target.checked
            })
          }}
        />
      }
    },
    {
      label: translate(intl, "common.ui.clinicName"),
      key: "name",
      width: 120,
      className: classes.cell
    },
    // {
    //   label: translate(intl, "settings.clinics.clever"),
    //   key: "createdBy",
    //   width: 80,
    //   className: classes.cell,
    //   component: ({ cellData }) => (
    //     <Box>
    //       {cellData && cellData === "Clever" ? <span>O</span> : <span>X</span>}
    //     </Box>
    //   )
    // },
    {
      label: translate(intl, "common.ui.address"),
      key: "address",
      width: 300,
      className: classes.cell,
      component: ({ cellData, rowData }) => {
        return cellData
      }
    },
    {
      label: translate(intl, "common.ui.telNo"),
      key: "tel",
      width: 130,
      className: classes.cell
    },
    {
      label: translate(intl, "common.ui.manager"),
      key: "manager",
      width: 150,
      className: classes.cell
    },
    {
      // label: translate( intl,"common.ui.requestDate" ),
      label: translate(intl, "settings.clinics.requestDate"),
      key: "createdAt",
      width: 110,
      className: classes.cell,
      component: ({ cellData }) => {
        return moment.unix(cellData).format('YYYY/MM/DD')
      }
    },
    {
      label: translate(intl, "common.ui.status"),
      key: "status",
      width: 90,
      className: classes.cell,
      component: ({ cellData: status }) => {
        // console.log("status.cellData", cellData);
        if (status === partnerRequestStatus.REQUESTED) {

          return (translate(intl, "settings.clinics.requestedStatus"))
        }

        if (status === partnerRequestStatus.CONFIRMED) {
          return (translate(intl, "settings.clinics.confirmStatus"))
        }

        if (status === partnerRequestStatus.REJECTED) {
          return (translate(intl, "settings.clinics.reject"))
        }

        if (status === partnerRequestStatus.CANCELED) {
          return (translate(intl, "settings.clinics.cancel"))
        }

        return ''
      }

    },

    {
      label: "",
      key: "id",
      width: 280,
      className: classes.group__button,
      component: ({ rowData, cellData: partnerId }) => {
        const { status } = rowData;

        const { CONFIRMED, REJECTED, CANCELED } = partnerRequestStatus;

        if (![CONFIRMED, REJECTED, CANCELED].includes(status)) {
          return (
            <Grid container justifyContent="center">
              <CVButton
                permission="clinicsPartnersSetting"
                className={classes.reject__button}
                variant="contained"
                color="sub"
                onClick={() => {
                  handlePartnerRequest({
                    variables: {
                      input: {
                        id: partnerId,
                        action: Number(PARTNER_TYPES.REFUSE.value)
                      }
                    }
                  }).then(() => {
                    partnerRefetch();
                  });
                }}
              >
                {translate(intl, "common.ui.reject")}
              </CVButton>
              <CVButton
                permission="clinicsPartnersSetting"
                variant="contained"
                onClick={() => {
                  handlePartnerRequest({
                    variables: {
                      input: {
                        id: partnerId,
                        action: Number(PARTNER_TYPES.APPROVE.value)
                      }
                    }
                  }).then(() => {
                    partnerRefetch();
                  });
                }}
              >
                {translate(intl, "common.ui.confirm")}
              </CVButton>
            </Grid>
          );
        } else {
          return ""
        }
      }
    }
  ];

  return (
    <>
      <CCPaper className={clsx(classes.root)}>
        <CCTypography xs={9} variant="h4" className={classes.title}>{`${translate(
          intl,
          "settings.clinics.partnerRequests"
        )} (${data?.partnerRequestList?.items
          ? data.partnerRequestList.items.filter(function (item) {
            return item.status === partnerRequestStatus.REQUESTED;
          }).length
          : 0
          })`}
          <CVIconButton
            permission='clinicsPartnersSetting'
            className={classes.del__btn}
            // onClick={() => setDeleteDialog(true)}
            onClick={handleOpenDeleteDialog}
          >
            <DeleteIcon />
          </CVIconButton>
        </CCTypography>


        <CCTable
          classes={classesCustom}
          heads={heads}
          contents={
            data?.partnerRequestList?.items ? data.partnerRequestList.items : []
          }
        />

      </CCPaper>

      {deleteDialog && (
        <CVDialogSimpleDel
          open={deleteDialog}
          maxWidth="xs"
          // warningTitle={intl.formatMessage({
          //   id: "settings.clinic.deleteClinicWarningTitle"
          // })}
          warningTitle={formatDeleteTitleWarning()}
          warningDesc={formatDeleteClinicWarningDesc()}
          // onClose={() => setDeleteDialog(false)}
          // onDisagree={closeDeleteDialog}
          // onAgree={handleDelete}
          endActions={<>
           {isSelectedItemDelete && <CCButton
              color="sub"
              onClick={handleCloseDeleteDialog}
            >
              {translate(intl, "common.ui.cancel")}
            </CCButton>}
            <CCButton
              variant="contained"
              color="error"
              onClick={handleDelete}
            >
              {formatDeleteClinicWarningConfirmButton()}
            </CCButton>
          </>}
        />
      )}
    </>
  );
};


export default PartnerRequest;
