import { useQuery } from "@apollo/react-hooks";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Graph } from "components";
import gql from "graphql-tag";
import React, { useMemo, useState } from "react";
import { generateDoctorLineGraph, generateDoctorPieGraph } from "types/graph";
import { CHARGE, STATUS } from "types/staff";
import { parseDoctorData, parseDoctorValue } from "utils/graph";

const SEARCH_STAFF = gql`
  query searchStaff(
    $hospitalId: String!
    $status: String
    $jobType: String
    $charge: String
    $jobPosition: String
    $permission: String
  ) {
    searchStaff(
      hospitalId: $hospitalId
      args: {
        status: $status
        jobType: $jobType
        charge: $charge
        permission: $permission
        jobPosition: $jobPosition
      }
    ) {
      id
      name
      jobType
      charge
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    flexDirection: "row",
    overflow: "auto"
  },
  paper: {
    height: "calc(100% - 2px)",
    width: "100%",
    overflow: "auto"
  },
  content: { width: "100%", height: "calc(100% - 61px)", overflow: "auto" },
  container: { height: "100%", width: "1696px" },
  button__details: {
    position: "absolute",
    right: "16px",
    height: "36px",
    width: "130px",
    padding: "8px",
    alignItems: "center",
    color: theme.palette.icon
  },
  row__options: {
    display: "flex",
    padding: "16px",
    alignItems: "center"
  },
  row__search: {
    height: "60px",
    width: "100%",
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  field__date: {
    width: "130px",
    margin: "0px 8px 0px 0px",
    backgroundColor: theme.palette.common.white
  },
  tab: {
    width: "70px",
    height: "30px",
    display: "flex",
    borderRadius: "18px",
    backgroundColor: theme.palette.sub.main,
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px"
  },
  selected: {
    backgroundColor: theme.palette.select.main
  },
  tab__text: { color: theme.palette.common.white },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    margin: "0px 8px"
  },
  button__search: {
    height: "36px",
    "min-width": "81px",
    padding: "8px",
    alignItems: "center",
    color: theme.palette.common.white
  },

  item__header: {
    height: "calc(100% - 16px)",
    width: "180px",
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    justifyContent: "center",
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  item__header__long: {
    width: "300px"
  },
  item__header__end: {
    borderRight: `0px`
  },
  container__graph: {
    height: "calc(100% - 84px - 60px - 88px)",
    overflow: "auto"
  },
  paper__graph: { height: "325px", width: "800px", margin: "16px" }
}));

const GraphTab = props => {
  const {
    graphData,
    graphLabels,
    parseDataFunction,
    parsePieDataFunction,
    graphFunction,
    pieGraphFunction,
    titles,
    formatter,
    dataFields,
    currentLabel,
    currentDoctor,
    parseField,
    graphUnit
  } = props;

  const classes = useStyles();

  const [doctorList, setDoctorList] = useState([]);

  // const patientsPie = generatePatientPieGraph([44, 55]);

  useQuery(SEARCH_STAFF, {
    variables: {
      hospitalId: localStorage.getItem("labId"),
      status: STATUS.WORKING.value,
      charge: CHARGE.DOCTOR_IN_CHARGE.value
    },
    onCompleted: data => {
      if (data && data.searchStaff) {
        setDoctorList(data.searchStaff);
      }
    }
  });

  const mainGraph = useMemo(() => {
    const series = parseDataFunction(graphData, graphLabels, currentDoctor);
    return graphFunction(series, graphLabels, formatter, graphUnit);
  }, [
    graphLabels,
    graphData,
    formatter,
    graphFunction,
    parseDataFunction,
    currentDoctor,
    graphUnit
  ]);

  const mainPieGraph = useMemo(() => {
    const series = parsePieDataFunction(graphData, currentDoctor, currentLabel);
    return pieGraphFunction(series);
  }, [
    graphData,
    currentDoctor,
    currentLabel,
    pieGraphFunction,
    parsePieDataFunction
  ]);

  const doctorGraphs = useMemo(() => {
    const graphList = [];
    if (dataFields && graphData) {
      dataFields.forEach(field => {
        const doctorSeries = parseDoctorData(
          graphData,
          graphLabels,
          field,
          doctorList,
          parseField
        );
        if (doctorSeries.length > 0) {
          const graph = generateDoctorLineGraph(
            doctorSeries,
            graphLabels,
            formatter,
            field.unit
          );
          const { series: pieData, labels } = parseDoctorValue(
            graphData,
            currentLabel,
            field,
            doctorList,
            parseField
          );
          const totalValue = pieData.reduce((prev, next) => prev + next);
          const pieGraph = generateDoctorPieGraph(
            pieData,
            totalValue,
            labels,
            field.unitPie
          );
          graphList.push({
            title: field.title,
            graph: graph,
            pieTitle: field.pieTitle,
            pie: pieGraph,
            unit: field.unitPie
          });
        }
      });
    }
    return graphList;
  }, [
    dataFields,
    doctorList,
    graphData,
    graphLabels,
    formatter,
    currentLabel,
    parseField
  ]);

  return (
    <Box className={classes.root}>
      <Grid className={classes.container__graph} container direction="row">
        <Grid item>
          <Graph
            title={titles.line}
            titlePie={titles.pie}
            barGraphSetting={mainGraph}
            pieGraphSetting={mainPieGraph}
          />
        </Grid>
        {doctorGraphs.map((graphSetting, index) => {
          const { title, graph, pieTitle, pie } = graphSetting;
          return (
            <Grid key={"item-graph-" + index} item>
              <Graph
                key={"graph-" + index}
                title={title}
                titlePie={pieTitle}
                barGraphSetting={graph}
                pieGraphSetting={pie}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default GraphTab;
