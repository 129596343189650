import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { OECanvas } from 'components';
import React, { useEffect, useState } from 'react';
import { CCButton, CCDialogSimple, CCPaper } from 'styles/src/components';
import { getTeethGroup } from 'utils/teeth';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme) => ({
  root: { width: 800 },
  padding8: { padding: 8 },
  paddingLeft: { paddingLeft: 8 },
}));

const ADULTS_TEETH = [
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
];

const settings = [
  {
    abbrev: null,
    color: null,
    content: '',
    entries: { crown: 'circle-link', pontic: 'straight-link' },
    icon: '',
    id: 'bridge',
    link: 'circle-start',
    minimum: 2,
    range: 'many',
    readonly: true,
  },
];

const contents = settings[0].entries;

const BridgeSelector = (props) => {
  const classes = useStyles();
  const { open, onClose, teeth, missings, onSave } = props;
  const [selects, setSelects] = useState([]);
  const [newEntries, setNewEntries] = useState([]);
  const [actives, setActives] = useState([...ADULTS_TEETH]);

  useEffect(() => {
    let entries = [];
    const selectedBabyTeeth = teeth.filter((item) => item > 50); // 선택한 유치
    const targetTeeth = selectedBabyTeeth.map((item) => item - 40); // 유치대응 되는 영구치
    const changeTeethList = teeth.map((item) => (item > 50 ? item - 40 : item)); // 유치 => 영구치 변
    setActives((a) =>
      a.map((item) => (targetTeeth.includes(item) ? item + 40 : item)),
    );

    if (teeth.length > 1) {
      getTeethGroup(changeTeethList, null, 2).forEach((group) => {
        const changeGroup = group.map((item) =>
          targetTeeth.includes(item) ? item + 40 : item,
        );
        const entry = {
          id: uuid(),
          teeth: changeGroup,
          plane: '',
          mode: 'many',
          target: 'bridge',
          icons: changeGroup.reduce((list, changeTeethList) => {
            return {
              ...list,
              [`teeth.${changeTeethList}`]: missings.includes(changeTeethList)
                ? contents[Object.keys(contents)[1]]
                : contents[Object.keys(contents)[0]],
            };
          }, {}),
        };
        entries.push(entry);
      });
    }

    setNewEntries(entries);
    setSelects([]);
  }, [teeth, missings]);

  const handleSave = () => {
    let _missings = [];
    if (newEntries.length) {
      newEntries.forEach((item) => {
        if (item.teeth.length) {
          teeth.forEach((teethNum) => {
            if (
              item.icons[`teeth.${teethNum}`] ===
              contents[Object.keys(contents)[1]]
            ) {
              _missings.push(teethNum);
            }
          });
        }
      });
    }
    onSave(_missings);
    onClose();
  };

  return (
    <CCDialogSimple
      maxWidth={false}
      open={open}
      onClose={onClose}
      onCloseButton={onClose}
      endActions={
        <>
          <CCButton
            color='sub'
            onClick={() => {
              onClose();
            }}
          >
            취소
          </CCButton>
          <CCButton variant='contained' onClick={handleSave}>
            저장/입력
          </CCButton>
        </>
      }
      title={'브릿지 설정'}
      contents={
        <CCPaper className={clsx(classes.root, classes.padding8)}>
          <Grid container justify={'center'} alignItems={'center'}>
            <Grid item style={{ width: 'inherit' }}>
              <Grid container justify={'flex-end'} alignItems={'center'}>
                <Grid item>크라운/파닉변환</Grid>
                <Grid item className={classes.paddingLeft}>
                  <CCButton
                    size={'small'}
                    variant={'outlined'}
                    onClick={() => {
                      selects.forEach((target) => {
                        const tags = target.split('.');
                        const number = parseInt(tags[1]);
                        const index = newEntries.findIndex((el) =>
                          el.teeth.includes(number),
                        );

                        newEntries[index].icons[`teeth.${tags[1]}`] =
                          contents['crown'];
                      });
                      setNewEntries([...newEntries]);
                      setSelects([]);
                    }}
                  >
                    크라운
                  </CCButton>
                </Grid>
                <Grid item className={classes.paddingLeft}>
                  <CCButton
                    size={'small'}
                    variant={'outlined'}
                    onClick={() => {
                      selects.forEach((target) => {
                        const tags = target.split('.');
                        const number = parseInt(tags[1]);
                        const index = newEntries.findIndex((el) =>
                          el.teeth.includes(number),
                        );
                        newEntries[index].icons[`teeth.${tags[1]}`] =
                          contents['pontic'];
                      });
                      setNewEntries([...newEntries]);
                      setSelects([]);
                    }}
                  >
                    파닉
                  </CCButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ marginBottom: 32, marginTop: 32 }}>
              <OECanvas
                width={758}
                height={240}
                layouts={{
                  columns: 64,
                  rows: 20,
                  teeth: {
                    upper: 0,
                    lower: 12,
                    left: 0,
                    right: 32,
                  },
                  numbers: {
                    upper: 8,
                    lower: 10,
                    left: 0,
                    right: 32,
                  },
                }}
                actives={actives}
                selects={selects}
                enables={teeth}
                entries={newEntries}
                settings={settings}
                onChange={(newSelects) => {
                  setSelects([...newSelects]);
                }}
              />
            </Grid>
          </Grid>
        </CCPaper>
      }
    />
  );
};

BridgeSelector.defaultProps = {
  teeth: [],
  missings: [],
};

BridgeSelector.propTypes = {};

export default BridgeSelector;
