import React, { useReducer } from "react";
import produce from "immer";

const initialState = {
  refresh: 0
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case "init":
      draft.refresh = draft.refresh + 1;
      return draft;
    case "product":
      draft.product = action.target;
      return draft;
    default:
      break;
  }
});

const DBStorageContext = React.createContext();
const DBStorageContextConsumer = DBStorageContext.Consumer;

const DBStorageContextProvider = props => {
  const [dbStorageContext, dispatchDBStorage] = useReducer(
    reducer,
    initialState
  );

  const value = { dbStorageContext, dispatchDBStorage };

  return (
    <DBStorageContext.Provider value={value}>
      {props.children}
    </DBStorageContext.Provider>
  );
};
export default DBStorageContextProvider;
export { DBStorageContext, DBStorageContextConsumer };
