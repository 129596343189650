import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Loading, SearchTextField, CVButton } from "components";
import CVTable from "components/CVTable";
import moment, * as moment_tz from "moment-timezone";
import { PAYMENT_DETAIL, UPDATE_PAYMENT_NOTE } from "queries/payment";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogSimple,
  CCIconButton,
  CCTextField,
  CCTypography
} from "styles/components";
import { AgreementIcon, CalculationIcon, ModifyIcon } from "styles/icons";
import { translate, TypographyTooltip } from "../../../../../components";
import BillPaper from "../BillPaper";
import EditNoteDialog from "../EditNoteDialog";
import { Commas } from "../index";
import PaymentDialog from "../PaymentDialog";
import RefundDialog from "../RefundDialog";
const useStyles = makeStyles(theme => ({
  w100: { width: 100 },
  width190: { width: 190 },
  w180: { width: 180 },
  w164: { width: 164, margin: "8px 16px" },
  date: { width: 130 },
  flex: { display: "flex" },
  main__text: {
    padding: 16,
    borderBottom: "1px solid #b0bec5",
    "& p": {
      fontWeight: "bold"
    }
  },

  color__red: {
    color: "#f50057"
  },
  price__tag: {
    width: 260,
    marginTop: 24,
    marginBottom: 24,
    justifyContent: "space-between"
  },
  color__grey: {
    color: "#b0bec5"
  },
  divided__horizon: {
    width: 1,
    height: 36,
    backgroundColor: "#b0bec5",
    margin: "0 16px"
  },
  bg__white: { background: "white" },
  flex__center: {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": { padding: 0 }
  },
  root: {
    "& .MuiDialog-paperWidthXl": {
      maxWidth: "inherit",
      width: "fit-content !important"
    }
  },
  input__group: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px 0",
    background: "#eceff1"
  },
  card: {
    marginLeft: 0,
    width: 85
  },
  width130: { width: 130 },

  item__padding: {
    padding: "0 16px "
  },
  padding8: { padding: 8 },

  color__green: {
    color: "#00897b"
  },
  line__through__text: {
    textDecoration: "line-through"
  },
  text__underline: {
    textDecoration: "underline",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "60%",
    textAlign: "center"
  },
  border__table: {
    border: "1px solid #b0bec5",
    borderRadius: "8px"
  },
  title__table: {
    borderBottom: "1px solid #b0bec5",
    padding: 8
  },
  margin__right16: { marginRight: 16 },
  cell__center: {
    alignItems: "center",
    display: "flex",
    padding: "8px 4px",
    justifyContent: "center"
  },
  line__text: {
    padding: 8,
    background: theme.palette.common.white,
    "&:nth-child(even)": {
      background: theme.palette.background.white
    },
    "&:nth-child(3)": {
      borderTop: "1px solid #b0bec5",
      borderBottom: "1px solid #b0bec5"
    }
  },
  button__icon: {
    margin: 0,
    padding: 0
  },
  paddingLeft8: {
    paddingLeft: 8
  },
  wordBreakAll: {
    "word-break": "break-all"
  },
  paymentHistoryContainer: {
    width: 'calc(100% - 240px)'
  }
}));

const PaymentDetailDialog = props => {
  const { open, onClose, id, labOrder } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [openBill, setOpenBill] = useState(false);
  const [openEditNoteDialog, setOpenEditNoteDialog] = useState(false);
  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const inputRef = useRef(null);

  const { data, loading, refetch } = useQuery(PAYMENT_DETAIL, {
    variables: { id }
  });
  const [updatePaymentHistoryNote] = useMutation(UPDATE_PAYMENT_NOTE, {
    onCompleted: () => refetch()
  });

  const goldHeads = [
    {
      key: "material",
      width: 140,
      className: classes.cell__center,
      component: ({ rowData }) => (
        <Grid container justify="center">
          <CCTypography>{rowData?.goldType} </CCTypography>{" "}
          <CCTypography
            className={clsx(classes.color__grey, classes.paddingLeft8)}
          >
            ({rowData?.goldSettingPrice}{" "}
            {translate(intl, "common.ui.currencyPerGram")})
          </CCTypography>
        </Grid>
      )
    },
    {
      key: "quantity",
      className: clsx({
        [classes.cell__center]: true
      }),
      width: 140,
      component: ({ rowData }) =>
        rowData?.used ? (
          <Grid container justify="center">
            <CCTypography>{rowData?.used} g</CCTypography>
            <CCTypography
              className={clsx(classes.color__grey, classes.paddingLeft8)}
            >
              {"("}
              {rowData?.charged}
              {process.env.REACT_APP_LOCALIZED_CURRENCY_SYMBOL
                ? process.env.REACT_APP_LOCALIZED_CURRENCY_SYMBOL
                : "VNĐ"}
              {")"}
            </CCTypography>
          </Grid>
        ) : (
          "-"
        )
    }
  ];
  const historyHeads = [
    {
      key: "receiptAtStr",
      className: classes.cell__center,
      label: translate(intl, "payment.receiptDate"),
      width: 150,
      component: ({ rowData }) => (
        <CCTypography>
          {moment_tz(rowData?.receiptAt, "X")
            .tz(moment_tz.tz.guess(true))
            .format("L LT")}
        </CCTypography>
      )
    },
    {
      key: "transactionType",
      className: classes.cell__center,
      label: translate(intl, "payment.status"),
      width: 80,
      component: ({ rowData }) => (
        <CCTypography
          className={clsx({
            [classes.color__green]: rowData?.transactionType === "1",
            [classes.color__grey]: rowData?.transactionType !== "1",
            [classes.line__through__text]:
              rowData?.transactionType === "1" && rowData.isRefund
          })}
        >
          {translate(
            intl,
            rowData?.transactionType === "1"
              ? "common.ui.payment.paid"
              : "payment.refunded"
          )}
        </CCTypography>
      )
    },
    {
      key: "totalAmountOnPayment",
      className: clsx({
        [classes.cell__center]: true
      }),
      label: <div>{translate(intl, "payment.totalPaid")}</div>,
      width: 150,
      component: ({ rowData }) => (
        <CCTypography
          className={clsx({
            [classes.line__through__text]:
              rowData?.transactionType === "1" && rowData.isRefund,
            [classes.wordBreakAll]: true
          })}
        >
          {Commas(rowData?.totalAmountOnPayment)}
        </CCTypography>
      )
    },
    {
      key: "cardPaymentAmount",
      className: classes.cell__center,
      label: <div>{translate(intl, "payment.creditCard")}</div>,
      width: 100,
      component: ({ rowData }) => (
        <CCTypography
          className={clsx({
            [classes.line__through__text]:
              rowData?.transactionType === "1" && rowData.isRefund,
            [classes.wordBreakAll]: true
          })}
        >
          {Commas(rowData?.cardPaymentAmount)}
        </CCTypography>
      )
    },
    {
      key: "cashPaymentAmount",
      className: classes.cell__center,
      label: <div>{translate(intl, "payment.cash")}</div>,
      width: 100,
      component: ({ rowData }) => {
        return (
          <CCTypography
            className={clsx({
              [classes.line__through__text]:
                rowData?.transactionType === "1" && rowData.isRefund,
              [classes.wordBreakAll]: true
            })}
          >
            {Commas(rowData?.cashPaymentAmount)}
          </CCTypography>
        );
      }
    },
    {
      key: "staffName",
      className: classes.cell__center,
      label: translate(intl, "payment.recorder"),
      width: 100,
      component: ({ rowData }) => {
        return <CCTypography>{rowData?.staffName}</CCTypography>;
      }
    },
    {
      key: "note",
      className: classes.cell__center,
      label: translate(intl, "payment.memo"),
      flex: 1,
      width: 200,
      component: ({ rowData }) => {
        return (
          <Grid container alignItems="center" justify="space-around">
            <CCTypography className={classes.text__underline}>
              <TypographyTooltip placement="top">
                {rowData.note || translate(intl, "payment.memo")}
              </TypographyTooltip>
            </CCTypography>

            <CCIconButton
              color={"primary"}
              className={classes.button__icon}
              onClick={e => {
                e.stopPropagation();
                setOpenEditNoteDialog(rowData);
              }}
            >
              <ModifyIcon />
            </CCIconButton>
          </Grid>
        );
      }
    },
    {
      key: "refund",
      className: classes.cell__center,
      label: translate(intl, "payment.refund"),
      flex: 1,
      component: ({ rowData }) =>
        rowData?.transactionType === "1" && (
          <CVButton
            disabled={rowData.isRefund}
            variant="outlined"
            onClick={() => setOpenRefundDialog(rowData)}

          >
            {translate(intl, "payment.refund")}
          </CVButton>
        )
    }
  ];

  const goldContents = data?.paymentDetail?.clinicSendGoldByType
    ? data?.paymentDetail?.clinicSendGoldByType
    : [];

  return !loading ? (
    data?.paymentDetail ? (
      <CCDialogSimple
        classes={classes}
        title={
          translate(intl, "payment.paymentDetail")
          // "Payment Detail"
        }
        open={open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={onClose}
        onCloseButton={onClose}
        contentsPadding={false}
        contents={
          <Grid container style={{ flexDirection: "column" }}>
            <Grid
              container
              alignItems="center"
              className={classes.item__padding}
            >
              <SearchTextField
                inputRef={inputRef}
                label={translate(intl, "payment.clinic")}
                // label="Clinic"
                disabled
                value={data?.paymentDetail?.clinic?.name}
                // onIconClick={() => {
                //   const key = inputRef.current.value.replace(/\\/g, "");
                //   inputRef.current.value = key;

                //   setWord(key);
                //   cache.clearAll();
                // }}
                onKeyPress={e => {
                  // if (e.key === 'Enter') {
                  //   const key = inputRef.current.value.replace(/\\/g, '');
                  //   inputRef.current.value = key;
                  //   setWord(key);
                  //   cache.clearAll();
                  // }
                }}
                InputProps={{}}
              />
              <CCTextField
                className={classes.width130}
                label={translate(intl, "payment.cleverNormal")}
                margin={"dense"}
                value={
                  data?.paymentDetail?.clinic?.createdBy === "Clever"
                    ? translate(intl, "settings.clinics.clever")
                    : translate(intl, "settings.clinics.general")
                }
                variant={"outlined"}
                disabled
              />
              <CCTextField
                className={classes.width130}
                // label={"Pay On"}
                label={translate(intl, "payment.payOn")}
                margin={"dense"}
                variant={"outlined"}
                value={data?.paymentDetail?.clinic?.paymentDate}
                disabled
              />
              {/* <CCTextField
                className={classes.width130}
                // label={"Month"}
                label={translate(intl, "payment.month")}
                margin={"dense"}
                value={
                  lang === "ko"
                    ? `${data?.paymentDetail?.year}/${
                        data?.paymentDetail?.month?.length === 2
                          ? data?.paymentDetail?.month
                          : `0${data?.paymentDetail?.month}`
                      }`
                    : `${
                        data?.paymentDetail?.month?.length === 2
                          ? data?.paymentDetail?.month
                          : `0${data?.paymentDetail?.month}`
                      }/${data?.paymentDetail?.year}`
                }
                variant={"outlined"}
                disabled
              /> */}
              <CCTextField
                className={classes.width130}
                label={translate(intl, "payment.sendDate")}
                margin={"dense"}
                value={
                  data?.paymentDetail?.sendBillToCleverAt ? moment.unix(data?.paymentDetail?.sendBillToCleverAt).format("L") : ""
                }
                variant={"outlined"}
                disabled
              />
            </Grid>
            {openPaymentDialog && (
              <PaymentDialog
                open={Boolean(openPaymentDialog)}
                onClose={() => setOpenPaymentDialog(false)}
                value={{
                  id,
                  charged: data?.paymentDetail?.charged,
                  cardPaymentAmount: data?.paymentDetail?.cardPaymentAmount,
                  cashPaymentAmount: data?.paymentDetail?.cashPaymentAmount,
                  unpaid: data?.paymentDetail?.unpaid
                }}
                labOrder={labOrder}
                paymentHistoryId={id}
                refetch={refetch}
              />
            )}
            <Grid
              container
              alignItems={"center"}
              justify="space-between"
              className={classes.item__padding}
            >
              <Grid item>
                <Grid container alignItems={"center"}>
                  <Grid container className={classes.price__tag}>
                    <CCTypography className={classes.color__grey}>
                      <p>{translate(intl, "payment.totalCharged")}</p>
                      {/* Total Charged ($) */}
                    </CCTypography>
                    <CCTypography variant="h4">
                      <p>{Commas(data.paymentDetail.charged)}</p>
                    </CCTypography>
                  </Grid>

                  <Box className={classes.divided__horizon} />
                  <Grid container className={classes.price__tag}>
                    <CCTypography className={classes.color__grey}>
                      <p>{translate(intl, "payment.paid")}</p>
                      {/* Paid ($) */}
                    </CCTypography>
                    <CCTypography variant="h4" color="primary">
                      <p>{Commas(data.paymentDetail.totalPaid)}</p>
                    </CCTypography>
                  </Grid>

                  <Box className={classes.divided__horizon} />
                  <Grid container className={classes.price__tag}>
                    <CCTypography className={classes.color__grey}>
                      <p>{translate(intl, "payment.unpaid")}</p>
                      {/* Unpaid ($) */}
                    </CCTypography>
                    <CCTypography
                      variant="h4"
                      className={clsx({
                        [classes.color__red]: data?.paymentDetail?.unpaid !== 0
                      })}
                    >
                      <p>{Commas(data.paymentDetail.unpaid)}</p>
                    </CCTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <CVButton
                    variant={"outlined"}
                    startIcon={<AgreementIcon />}
                    className={classes.margin__right16}
                    onClick={() => setOpenBill(true)}
                  >
                    {translate(intl, "payment.bills")}
                    {/* Bill */}
                  </CVButton>
                  <CVButton
                    startIcon={<CalculationIcon />}
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                      // if (!obj.staff.length || !obj.name.length) {
                      //   setError(true);
                      //   return;
                      // }

                      // const availableCash = obj.money.cash + obj.money.account;

                      // if (availableCash < obj.money.cashReceipt) {
                      //   setError(true);
                      //   return;
                      // }

                      // const money =
                      //   obj.money.card + obj.money.cash + obj.money.account;
                      // if (!money) {
                      //   setMoneyError(true);
                      //   return;
                      // }

                      setOpenPaymentDialog(true);
                      // const isCreate = checkPaymentCreate(); // 생성여부, index 번호
                      // calculation(isCreate);
                    }}
                  >
                    {translate(intl, "payment.payment")}
                    {/* Payment */}
                  </CVButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.padding8}>
              <Grid item className={classes.padding8}>
                <Grid
                  container
                  className={classes.border__table}
                  direction="column"
                >
                  <CCTypography variant="h5" className={classes.title__table}>
                    {translate(intl, "payment.chargeDetails")}
                    {/* Charged Details */}
                  </CCTypography>
                  <Grid
                    container
                    className={classes.line__text}
                    justify="space-between"
                  >
                    <Grid
                      container
                      className={classes.width190}
                      justify="space-between"
                    >
                      <CCTypography variant="h5">
                        {" "}
                        {translate(intl, "payment.detail.prosthesis")}
                        {/* Shipped Prosthesis (ea) */}{" "}
                      </CCTypography>
                      <CCTypography variant="h5">
                        {" "}
                        {Commas(data?.paymentDetail?.arrivedProsthesis || 0)}
                      </CCTypography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    className={classes.line__text}
                    justify="space-between"
                  >
                    <Grid
                      container
                      className={classes.width190}
                      justify="space-between"
                    >
                      <CCTypography variant="h5">
                      <div>{" "}
                        {translate(intl, "payment.prosthesis")}{" "}</div>
                      </CCTypography>
                      <CCTypography variant="h5">
                        {" "}
                        {Commas(data?.paymentDetail?.prothesisPrice || 0)}{" "}
                      </CCTypography>
                    </Grid>
                  </Grid>


                  <Grid
                    container
                    className={classes.line__text}
                    justify="space-between"
                  >
                    <Grid
                      container
                      className={classes.width190}
                      justify="space-between"
                    >
                      <CCTypography variant="h5">
                        <div>{translate(intl, "payment.discount")}</div>
                        {/* Discount ($) */}
                      </CCTypography>
                      <CCTypography variant="h5">
                        {" "}
                        {Commas(data?.paymentDetail?.dc || 0)}
                      </CCTypography>
                    </Grid>
                  </Grid>

                  {/* <Grid container>
                    <CVTable
                      labelShow={false}
                      heads={goldHeads}
                      contents={goldContents.slice(0, 3)}
                    />
                    <div style={{ width: 1, backgroundColor: "#b0bec5" }} />
                    <CVTable
                      labelShow={false}
                      heads={goldHeads}
                      contents={goldContents.slice(3, 5)}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item className={[classes.padding8, classes.paymentHistoryContainer]}>
                <CVTable
                  className={classes.border__table}
                  title={translate(intl, "payment.paymentHistory")}
                  heads={historyHeads}
                  contents={data.paymentDetail.paymentHistory}
                />
              </Grid>
            </Grid>
            {openBill && (
              <BillPaper
                open={openBill}
                onClose={() => setOpenBill(false)}
                id={id}
              />
            )}
            {Boolean(openRefundDialog) && (
              <RefundDialog
                open={Boolean(openRefundDialog)}
                onClose={() => setOpenRefundDialog(false)}
                value={openRefundDialog}
                labOrder={labOrder}
                paymentHistoryId={id}
                refetch={refetch}
              />
            )}
            {Boolean(openEditNoteDialog) && (
              <EditNoteDialog
                open={Boolean(openEditNoteDialog)}
                onClose={() => {
                  setOpenEditNoteDialog(false);
                }}
                onChange={val => {
                  updatePaymentHistoryNote({
                    variables: {
                      id: data.paymentDetail.id,
                      note: data.paymentDetail.note,
                      paymentNote: { id: openEditNoteDialog.id, note: val }
                    }
                  });
                }}
                value={openEditNoteDialog.note}
              />
            )}
          </Grid>
        }
      />
    ) : (
      ""
    )
  ) : (
    <Loading open={loading} />
  );
};

export default PaymentDetailDialog;
