import React from "react";

const msg = {
  AUTH06: "errorMsg.auth06",
  AUTH03: "errorMsg.auth03",
  AUTH04: "errorMsg.auth04",
  AUTH05: "errorMsg.auth05",
  AUTH02: "errorMsg.auth02",
  AUTH01: "errorMsg.auth01",
  INPUTMISSING: "errorMsg.inputmissing",
  UNKOWNERROR: "errorMsg.unkownerror",
  PASSWORD01: "errorMsg.password01",
  LABNOTFOUND: "errorMsg.labnotfound",
  LABDISCONTINUED: "errorMsg.discontinue",
  LABWITHDRAW: "errorMsg.withdraw",
  HOSPITAL01: <>병원아이디가 존재하지 않습니다.</>,
  AUTH07: (
    <>
      만료된 비밀번호입니다. <br />
      대표자 권한 사용자에게 환경설정&gt;직원설정 메뉴를 통해 비밀번호 초기화를
      요청하세요.
    </>
  ),
  STAFF01: "errorMsg.staff01",
  STAFF02: "errorMsg.staff02",
  STAFF03: "errorMsg.staff03",
  STAFF04: "errorMsg.staff04",
  STAFF05: "errorMsg.staff05",
  TREATCONFIG01: "errorMsg.treatconfig01",
  TREATCONFIG02: "errorMsg.treatconfig02",
  LABEXIST: "errorMsg.labexist",
  PATIENT01: "errorMsg.patient01",
  MANAGER01: "errorMsg.manager01",
  UPDATELAB01: "errorMsg.updatelab01",
  UPDATELABAPP01: "errorMsg.updatelabapp01",
  NOTICE01: "errorMsg.notice01",
  PARTNER01: "errorMsg.partner01",
  PARTNERREQ02: "errorMsg.partnerreq02",
  PARTNERREQ01: "errorMsg.partnerreq01",
  PTS_SETTING01: "errorMsg.ptsSetting01",
  PTS_SETTING02: "errorMsg.ptsSetting02",
  ORDER01: "errorMsg.order01",
  CHATGROUP01: "errorMsg.chatgroup01"
};
export default msg;
