import { Grid, makeStyles } from "@material-ui/core";
import { CVButton } from "components";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogSimple,
  CCTextField,
  CCTypography
} from "styles/components";
import { CheckIcon } from "styles/icons";
import { ENTRY_LIMIT } from "../../../../../types/constants";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  w100: { width: 100 },
  w180: { width: 180 },
  w164: { width: 164 },
  date: { width: 130 },
  flex: { display: "flex" },
  main__text: {
    margin: "8px 16px",
    "& p": {
      fontWeight: "bold"
    }
  },
  textArea: { margin: "8px 16px" },
  bg__white: { background: "white" },
  flex__center: {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": { padding: 0 }
  },
  root: {
    "& .MuiDialog-paperWidthSm": { maxWidth: "inherit", width: 500 }
  },
  input__group: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px 0",
    background: "#eceff1"
  },
  card__group: {
    margin: "8px 0",
    paddingLeft: 8,
    display: "flex",
    alignItems: "center"
  },
  card__text: { margin: 0 },
  card: {
    marginLeft: 0,
    width: 85
  },
  tar: { textAlign: "right" },
  color__grey: {
    color: "#b0bec5"
  }
}));

const EditNoteDialog = props => {
  const { open, onClose, value, onChange } = props;

  const intl = useIntl();
  const classes = useStyles();

  const [obj, setObj] = useState(value);

  return (
    <>
      <CCDialogSimple
        classes={classes}
        // title={"Notes"}
        title={translate( intl,"payment.note" )}
        open={open}
        onClose={onClose}
        onCloseButton={onClose}
        contentsPadding={false}
        contents={
          <Grid container style={{ flexDirection: "column" }}>
            <Grid item className={classes.main__text}>
              <CCTypography className={classes.color__grey}>
                {translate( intl,"payment.noteWarning" )}
                {/* {"* Typed data are automatically inserted in bills"} */}
              </CCTypography>
            </Grid>
            <CCTextField
              variant={"outlined"}
              // placeholder={"Notes"}
              // label={"Notes"}
              placeholder={translate( intl,"payment.note" )}
              label={translate( intl,"payment.note" )}
              multiline
              rows={5}
              className={classes.textArea}
              InputProps={{
                inputProps: {
                  maxLength: ENTRY_LIMIT.BIG_FIELD
                }
              }}
              value={obj}
              onChange={e => {
                setObj(e.target.value);
              }}
            />
          </Grid>
        }
        endActions={
          <>
            <CCButton onClick={onClose}>
              {translate( intl,"payment.cancel" )}
              {/* Cancel */}
            </CCButton>
            <CVButton
              permission={"payment.billManage"}
              startIcon={<CheckIcon />}
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                onChange(obj);
                onClose();
              }}
            >
              {translate( intl,"payment.save" )}
              {/* Save */}
            </CVButton>
          </>
        }
      />
    </>
  );
};

export default EditNoteDialog;
