import { Box, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import ReactToPrint from "react-to-print";
import { CCIconButton, CCTypography } from "../";
import {
  DeleteIcon,
  ExcelIcon,
  PrintIcon,
  RemoveIcon,
  ResetIcon
} from "../../themes/common/icons";

const useStyles = makeStyles(theme => ({
  root: { position: "relative", padding: "16px 100px 16px 16px" },
  tool: { position: "absolute", top: 4, right: 8 },
  hidden: { display: "none" },
  close__btn: { color: `${theme.palette.sub.mainHex} !important` },
  del__btn: { color: `${theme.palette.error.main} !important` },
  refresh__btn: { color: `${theme.palette.icon} !important` },
  export__btn: { color: `${theme.palette.primary.main} !important` }
}));

const CCDialogTitle = props => {
  const {
    classes: userClasses,
    disableTypography = false,
    onPrintButton,
    onBeforeGetContent,
    onBeforePrint,
    onAfterPrint,
    onPrintButtonF,
    onCloseButton,
    onRefreshButton,
    onDeleteButton,
    onExportButton,
    printRef,
    children,
    ...others
  } = props;
  const classes = useStyles();

  return (
    <DialogTitle
      disableTypography
      classes={{ root: clsx(userClasses && userClasses.root, classes.root) }}
      {...others}
    >
      {disableTypography ? (
        children
      ) : (
        <CCTypography variant="h3">{children}</CCTypography>
      )}
      <Box className={classes.tool}>
        <ReactToPrint
          trigger={() => (
            <CCIconButton
              className={clsx({
                [classes.icon__btn]: true,
                [classes.hidden]: !onPrintButton
              })}
            >
              <PrintIcon />
            </CCIconButton>
          )}
          onBeforeGetContent={async () =>
            onBeforeGetContent && onBeforeGetContent()
          }
          onBeforePrint={async () => onBeforePrint && onBeforePrint()}
          onAfterPrint={async () => onAfterPrint && onAfterPrint()}
          content={() => printRef && printRef.current}
          pageStyle={
            "@page { size: auto; margin: auto; } @media print { body { -webkit-print-color-adjust: exact; } table { page-break-inside: auto; } tr { page-break-inside: avoid; page-break-after: auto; } }"
          }
        />
        <CCIconButton
          className={clsx({
            [classes.icon__btn]: true,
            [classes.hidden]: !onPrintButtonF
          })}
          onClick={() => {
            onPrintButtonF && onPrintButtonF();
          }}
        >
          <PrintIcon />
        </CCIconButton>
        <CCIconButton
          className={clsx({
            [classes.icon__btn]: true,
            [classes.hidden]: !onRefreshButton,
            [classes.refresh__btn]: true
          })}
          onClick={() => {
            onRefreshButton && onRefreshButton();
          }}
        >
          <ResetIcon />
        </CCIconButton>
        <CCIconButton
          className={clsx({
            [classes.icon__btn]: true,
            [classes.hidden]: !onDeleteButton,
            [classes.del__btn]: true
          })}
          onClick={() => {
            onDeleteButton && onDeleteButton();
          }}
        >
          <DeleteIcon />
        </CCIconButton>
        <CCIconButton
          className={clsx({
            [classes.icon__btn]: true,
            [classes.export__btn]: true,
            [classes.hidden]: !onExportButton
          })}
          onClick={() => {
            onExportButton && onExportButton();
          }}
        >
          <ExcelIcon />
        </CCIconButton>
        <CCIconButton
          className={clsx({
            [classes.icon__btn]: true,
            [classes.icon__close]: true,
            [classes.close__btn]: true,
            [classes.hidden]: !onCloseButton
          })}
          onClick={() => {
            onCloseButton && onCloseButton();
          }}
        >
          <RemoveIcon />
        </CCIconButton>
      </Box>
    </DialogTitle>
  );
};

export default CCDialogTitle;
