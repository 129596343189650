import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { SmalldownIcon, SmallupIcon } from 'styles/icons';

const useStyles = makeStyles((theme) => ({
  textfield__year: {
    width: 92,
    margin: 0,
    marginRight: 8,
  },
  input__year: {
    backgroundColor: theme.palette.common.white,
  },
  end__adornment: {
    flexDirection: 'column',
    margin: 0,
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  up: {
    marginBottom: '5px',
    color: theme.palette.icon,
  },
  down: {
    color: theme.palette.icon,
  },
}));

const YearPicker = (props) => {
  const classes = useStyles();
  const { value, onChange } = props;

  return (
    <TextField
      className={classes.textfield__year}
      variant='outlined'
      margin='dense'
      value={moment.unix(value).format('YYYY')}
      InputProps={{
        className: classes.input__year,
        'aria-readonly': 'true',
        readOnly: true,
        endAdornment: (
          <InputAdornment position='end' className={classes.end__adornment}>
            <SmallupIcon
              className={clsx(classes.up, {})}
              onClick={(event) => {
                onChange &&
                  onChange({
                    target: {
                      name: 'periodMonth',
                      value: moment.unix(value).clone().add(1, 'year').unix(),
                    },
                  });
              }}
            />
            <SmalldownIcon
              className={clsx(classes.down, {})}
              onClick={(event) => {
                onChange &&
                  onChange({
                    target: {
                      name: 'periodMonth',
                      value: moment
                        .unix(value)
                        .clone()
                        .subtract(1, 'year')
                        .unix(),
                    },
                  });
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default YearPicker;
