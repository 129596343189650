import { useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CCMenu } from 'styles/src/components';

const DetailContext = (props) => {
  const {
    anchorEl,
    open,
    onClose,
    onShowDetail,
    onDelete,
    allowDelete,
  } = props;
  const theme = useTheme();
  const [menuItems, setMenuItems] = useState(
    allowDelete
      ? [
          {
            value: '0',
            label: '상세보기',
            onClick: onShowDetail,
          },
        ]
      : [
          {
            value: '0',
            label: '상세보기',
            onClick: onShowDetail,
          },
          {
            value: '1',
            label: '삭제',
            onClick: onDelete,
            color: theme.palette.error.light,
          },
        ],
  );

  useEffect(() => {
    setMenuItems(
      allowDelete
        ? [
            {
              value: '0',
              label: '상세보기',
              onClick: onShowDetail,
            },
          ]
        : [
            {
              value: '0',
              label: '상세보기',
              onClick: onShowDetail,
            },
            {
              value: '1',
              label: '삭제',
              onClick: onDelete,
              color: theme.palette.error.light,
            },
          ],
    );
  }, [onShowDetail, onDelete, theme, allowDelete]);

  return (
    <CCMenu
      menuItems={menuItems}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    />
  );
};

DetailContext.defaultProps = {
  onShowDetail: () => {},
  onClose: () => {},
  onDelete: () => {},
  allowDelete: false,
};

export default DetailContext;
