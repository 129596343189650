/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { Box } from "@material-ui/core";
import { DialogWarningMessage } from "components";
import React, { useEffect, useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Group, Image, Layer, Rect, Stage, Text } from "react-konva";
import useImage from "use-image";
import useResizeObserver from "use-resize-observer";
import { useIntl } from "utils/language";
import { getPhotoTarget } from "utils/photo";
import { getObject } from "utils/s3";

const PhotoImage = props => {
  const {
    x,
    y,
    width,
    height,
    padding,
    corner,
    panel = false,
    checked,
    url,
    onClick
  } = props;
  const [image, status] = useImage(url, "Anonymous");

  if (url.startsWith("http") && status !== "loaded") {
    return (
      <Group
        clipFunc={ctx => {
          ctx.beginPath();
          ctx.moveTo(x + padding + corner, y + padding);
          ctx.lineTo(x - padding + width - corner, y + padding);
          ctx.quadraticCurveTo(
            x - padding + width,
            y + padding,
            x - padding + width,
            y + padding + corner
          );
          ctx.lineTo(x - padding + width, y - padding + height - corner);
          ctx.quadraticCurveTo(
            x - padding + width,
            y - padding + height,
            x - padding + width - corner,
            y - padding + height
          );
          ctx.lineTo(x + padding + corner, y - padding + height);
          ctx.quadraticCurveTo(
            x + padding,
            y - padding + height,
            x + padding,
            y - padding + height - corner
          );
          ctx.lineTo(x + padding, y + padding + corner);
          ctx.quadraticCurveTo(
            x + padding,
            y + padding,
            x + padding + corner,
            y + padding
          );
          ctx.closePath();

          ctx.strokeStyle = "#ababab";
          ctx.stroke();
        }}
      >
        <Text
          x={x + padding}
          y={y + padding}
          width={width - padding * 2}
          height={height - padding * 2}
          fill="#ababab"
          fontSize={24}
          text="loading..."
          align="center"
          verticalAlign="middle"
          wrap="none"
          ellipsis={true}
        ></Text>
      </Group>
    );
  }

  if (checked) {
    return (
      <Group
        clipFunc={ctx => {
          ctx.beginPath();
          ctx.moveTo(x + padding + corner, y + padding);
          ctx.lineTo(x - padding + width - corner, y + padding);
          ctx.quadraticCurveTo(
            x - padding + width,
            y + padding,
            x - padding + width,
            y + padding + corner
          );
          ctx.lineTo(x - padding + width, y - padding + height - corner);
          ctx.quadraticCurveTo(
            x - padding + width,
            y - padding + height,
            x - padding + width - corner,
            y - padding + height
          );
          ctx.lineTo(x + padding + corner, y - padding + height);
          ctx.quadraticCurveTo(
            x + padding,
            y - padding + height,
            x + padding,
            y - padding + height - corner
          );
          ctx.lineTo(x + padding, y + padding + corner);
          ctx.quadraticCurveTo(
            x + padding,
            y + padding,
            x + padding + corner,
            y + padding
          );
          ctx.closePath();

          ctx.lineWidth = 4;
          ctx.strokeStyle = "#2196f3";
          ctx.stroke();
        }}
      >
        <Image
          x={x + padding}
          y={y + padding}
          width={width - padding * 2}
          height={height - padding * 2}
          image={image}
          onClick={e => {
            onClick(e);
          }}
          onTap={e => {
            onClick(e);
          }}
        />
        <Rect
          x={x + padding}
          y={y + padding}
          width={width - padding * 2}
          height={height - padding * 2}
          fill="rgba(64, 196, 255, 0.38)"
          alpha={0.25}
          onClick={e => {
            onClick(e);
          }}
          onTap={e => {
            onClick(e);
          }}
        />
      </Group>
    );
  }

  return (
    <Group
      clipFunc={ctx => {
        ctx.beginPath();
        ctx.moveTo(x + padding + corner, y + padding);
        ctx.lineTo(x - padding + width - corner, y + padding);
        ctx.quadraticCurveTo(
          x - padding + width,
          y + padding,
          x - padding + width,
          y + padding + corner
        );
        ctx.lineTo(x - padding + width, y - padding + height - corner);
        ctx.quadraticCurveTo(
          x - padding + width,
          y - padding + height,
          x - padding + width - corner,
          y - padding + height
        );
        ctx.lineTo(x + padding + corner, y - padding + height);
        ctx.quadraticCurveTo(
          x + padding,
          y - padding + height,
          x + padding,
          y - padding + height - corner
        );
        ctx.lineTo(x + padding, y + padding + corner);
        ctx.quadraticCurveTo(
          x + padding,
          y + padding,
          x + padding + corner,
          y + padding
        );
        ctx.closePath();
      }}
    >
      <Image
        x={x + padding}
        y={y + padding}
        width={width - padding * 2}
        height={height - padding * 2}
        image={image}
        onClick={e => {
          onClick(e);
        }}
        onTap={e => {
          onClick(e);
        }}
      />
      {panel && (
        <Rect
          x={x + padding}
          y={y + padding}
          width={width - padding * 2}
          height={height - padding * 2}
          fill="rgba(0, 0, 0, 0.25)"
          onClick={e => {
            onClick(e);
          }}
          onTap={e => {
            onClick(e);
          }}
        />
      )}
    </Group>
  );
};

const PhotoIcon = props => {
  const { x, y, width, height, padding = 0, url, onClick } = props;
  const [image] = useImage(url);

  return (
    <Image
      x={x + padding}
      y={y + padding}
      width={width - padding * 2}
      height={height - padding * 2}
      image={image}
      onClick={e => {
        onClick(e);
      }}
    />
  );
};

const PhotoTile = props => {
  const {
    hospitalId = null,
    patientId = null,
    photo = null,
    image,
    header,
    action,
    actionExt,
    panel = false,
    filetype = null,
    tileChecked,
    tileMode,
    tileHeight = 100,
    tileAlign = "left",
    onClick,
    onClickMore,
    onClickCheck,
    onAction,
    onActionExt
  } = props;
  const intl = useIntl();
  const { ref, width = 0, height = 0 } = useResizeObserver();
  const [imageUrl, setImageUrl] = useState("");
  const [warning, setWarning] = useState(false);
  const [error, setError] = useState(false);
  const [mouseOver, setMouseOver] = useState(true);
  const [{ isDragging }, drag] = useDrag({
    item: {
      name: header,
      source: photo,
      type: filetype || "none"
    },
    end: (item, monitor) => {},
    collect: monitor => ({ isDragging: !!monitor.isDragging() })
  });
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "photo",
    drop: () => {
      return { name: "tile" };
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const headerText = useMemo(() => {
    if (
      ["stl", "zip", "consult", "unknown", "folder", "pdf"].includes(
        filetype
      ) ||
      mouseOver
    ) {
      if (header?.length > 30) {
        return header.substr(0, 30) + "...";
      }

      return header;
    }

    return null;
  }, [filetype, header, mouseOver]);

  useEffect(() => {
    if (image) {
      setImageUrl(image || "");
    } else if (hospitalId && patientId && photo) {
      if (photo?.content?.object) {
        // 이미지 1년 뒤 삭제한 경우 체크
        getObject(getPhotoTarget(photo), hospitalId, photo.content.object)
          .then(url => {
            console.log("url", url);
          })
          .catch(err => {
            setWarning(true);
          });

        // 이후 작업
        getObject(getPhotoTarget(photo), hospitalId, photo.content.filenode)
          .then(url => {
            setImageUrl(url);
          })
          .catch(err => {
            getObject(getPhotoTarget(photo), hospitalId, photo.content.object)
              .then(url => {
                setImageUrl(url);
              })
              .catch(err => {});
          });
      } else {
        setImageUrl(photo.content.source);
      }
    }
  }, [hospitalId, photo, image]);

  return (
    <>
      <Box
        ref={ref}
        height={tileHeight}
        onMouseEnter={e => {
          setMouseOver(true);
        }}
        onMouseOver={e => {
          setMouseOver(true);
        }}
        onMouseLeave={e => {
          setMouseOver(true);
        }}
      >
        {width > 0 && height > 0 ? (
          <Box ref={drag} width="100%" height="100%">
            <Stage width={width} height={height}>
              <Layer>
                <PhotoImage
                  x={0}
                  y={0}
                  width={126}
                  height={100}
                  padding={4}
                  corner={12}
                  panel={panel}
                  url={imageUrl}
                  onClick={e => {
                    if (warning) {
                      setError(true);
                    } else {
                      onClick &&
                        onClick({ x: e.evt.clientX, y: e.evt.clientY });
                    }
                  }}
                ></PhotoImage>
                {tileMode !== "none" && tileMode !== "check" && mouseOver && (
                  <PhotoIcon
                    x={12}
                    y={12}
                    width={20}
                    height={20}
                    url="/contents/moreline.svg"
                    onClick={e => {
                      onClickMore &&
                        onClickMore({ x: e.evt.clientX, y: e.evt.clientY });
                    }}
                  ></PhotoIcon>
                )}
                {tileMode === "check" && (
                  <PhotoIcon
                    x={12}
                    y={12}
                    width={20}
                    height={20}
                    url={
                      tileChecked
                        ? "/contents/check-select.svg"
                        : "/contents/check-normal.svg"
                    }
                    onClick={e => {
                      onClickCheck &&
                        onClickCheck({ x: e.evt.clientX, y: e.evt.clientY });
                    }}
                  ></PhotoIcon>
                )}
                {action && (
                  <PhotoIcon
                    x={width - 32}
                    y={12}
                    width={20}
                    height={20}
                    url={action}
                    onClick={e => {
                      onAction && onAction();
                    }}
                  />
                )}
                {actionExt && (
                  <PhotoIcon
                    x={width - 52}
                    y={12}
                    width={20}
                    height={20}
                    url={actionExt}
                    onClick={e => {
                      onActionExt && onActionExt();
                    }}
                  />
                )}
                <Text
                  x={15}
                  y={height * 0.5}
                  width={width - 30}
                  height={height * 0.45}
                  fill="white"
                  fontSize={14}
                  text={headerText}
                  align={tileAlign}
                  verticalAlign="middle"
                  wrap="wrap"
                  ellipsis={true}
                  onClick={e => {
                    onClick && onClick({ x: e.evt.clientX, y: e.evt.clientY });
                  }}
                  onTap={e => {
                    onClick && onClick({ x: e.evt.clientX, y: e.evt.clientY });
                  }}
                ></Text>
              </Layer>
            </Stage>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>

      <DialogWarningMessage
        open={error}
        title={intl.formatMessage({ id: "expiredImages" })}
        subTitle={intl.formatMessage({ id: "getExpiredImages" })}
        onAgree={() => {
          setError(false);
        }}
        onClose={() => {
          setError(false);
        }}
      />
    </>
  );
};

export default PhotoTile;
