import NotoSansBlack from "./NotoSansKR-Black.ttf";
import NotoSansBold from "./NotoSansKR-Bold.ttf";
import NotoSansDemiLight from "./NotoSansKR-DemiLight.ttf";
import NotoSansLight from "./NotoSansKR-Light.ttf";
import NotoSansMedium from "./NotoSansKR-Medium.ttf";
import NotoSansRegular from "./NotoSansKR-Regular.ttf";
import NotoSansThin from "./NotoSansKR-Thin.ttf";

import NotoSansArBlack from "./NotoSansArabic-Black.ttf";
import NotoSansArBold from "./NotoSansArabic-Bold.ttf";
import NotoSansArDemiLight from "./NotoSansArabic-ExtraLight.ttf";
import NotoSansArLight from "./NotoSansArabic-Light.ttf";
import NotoSansArMedium from "./NotoSansArabic-Medium.ttf";
import NotoSansArRegular from "./NotoSansArabic-Regular.ttf";
import NotoSansArThin from "./NotoSansArabic-Thin.ttf";

import RobotoBlack from "./Roboto-Black.ttf";
import RobotoBold from "./Roboto-Bold.ttf";
import { default as RobotoDemiLight, default as RobotoLight } from "./Roboto-Light.ttf";
import RobotoMedium from "./Roboto-Medium.ttf";
import RobotoRegular from "./Roboto-Regular.ttf";
import RobotoThin from "./Roboto-Thin.ttf";


const NotoSans = {
  Black: NotoSansBlack,
  Bold: NotoSansBold,
  DemiLight: NotoSansDemiLight,
  Light: NotoSansLight,
  Medium: NotoSansMedium,
  Regular: NotoSansRegular,
  Thin: NotoSansThin
};

const NotoSansAr = {
  Black: NotoSansArBlack,
  Bold: NotoSansArBold,
  DemiLight: NotoSansArDemiLight,
  Light: NotoSansArLight,
  Medium: NotoSansArMedium,
  Regular: NotoSansArRegular,
  Thin: NotoSansArThin
};

const Roboto = {
  Black: RobotoBlack,
  Bold: RobotoBold,
  DemiLight: RobotoDemiLight,
  Light: RobotoLight,
  Medium: RobotoMedium,
  Regular: RobotoRegular,
  Thin: RobotoThin
};

const genFontFace = (lang, font) => {
  return {
    family: "NotoSans",
    fonts: [
      {
        src: font.Thin,
        fontWeight: 100
      },
      {
        src: font.DemiLight,
        fontWeight: 200
      },
      {
        src: font.Light,
        fontWeight: 300
      },
      {
        src: font.Regular,
        fontWeight: 400
      },
      {
        src: font.Medium,
        fontWeight: 500
      },
      {
        src: font.Bold,
        fontWeight: 600
      },
      {
        src: font.Black,
        fontWeight: 700
      }
    ]
  };
}
const fontFaceKo = genFontFace('ko', NotoSans)

const fontFaceAr = genFontFace('ar', NotoSansAr)
const fontFaceEn = {
  family: "Roboto",
  fonts: [
    {
      src: Roboto.Thin,
      fontWeight: 100
    },
    {
      src: Roboto.DemiLight,
      fontWeight: 200
    },
    {
      src: Roboto.Light,
      fontWeight: 300
    },
    {
      src: Roboto.Regular,
      fontWeight: 400
    },
    {
      src: Roboto.Medium,
      fontWeight: 500
    },
    {
      src: Roboto.Bold,
      fontWeight: 600
    },
    {
      src: Roboto.Black,
      fontWeight: 700
    }
  ]
};

const getFontFaceByLang = (lang) => {
  switch (lang) {
    case "ko":
      return { fontFace: fontFaceKo, fontFamily: 'NotoSans' }
    case "ar":
      return { fontFace: fontFaceAr, fontFamily: 'NotoSans' }
    default:
      return { fontFace: fontFaceEn, fontFamily: 'Roboto' }
  }
}
export { getFontFaceByLang };
