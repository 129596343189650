import React from "react";

const TakedownIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M8.5 14.5h-4l7 7 7-7h-4.1c0-5 1.6-8.5 5.1-11-5 1-10 4-11 11z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default TakedownIcon;
