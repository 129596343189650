import { Tab, Tabs } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { CCTypography } from "styles/src/components";

const useTabsStyles = makeStyles(theme => ({
  root: {
    minHeight: 24,
    alignItems: "center",
    backgroundColor: props => props.backgroundColor
  },
  flexContainer: {
    padding: "0 8px"
  },
  scrollButtons: {
    width: 30,
    color: props =>
      props.scrollButtonsColor ? props.scrollButtonsColor : "rgb(38, 50, 56)",
    height: 30,
    borderRadius: "50%",
    marginRight: 4,
    marginLeft: 4
  },
  indicator: { width: "0 !important" }
}));

const useTabStyles = makeStyles(
  theme => ({
    root: {
      minWidth: 50,
      minHeight: 24,
      margin: "6px 4px",
      padding: 0,
      // borderRadius: 25,
      opacity: 1
    }
  }),
  { name: "RadioTabs" }
);
const RadioGroup = props => {
  const {
    tabList = [],
    value,
    onChange,
    backgroundColor = "transparent",
    scrollButtonsColor
  } = props;
  const tabsClasses = useTabsStyles({
    backgroundColor: backgroundColor,
    scrollButtonsColor: scrollButtonsColor
  });
  const tabClasses = useTabStyles();

  return (
    <Tabs
      classes={tabsClasses}
      variant="scrollable"
      scrollButtons="auto"
      value={
        tabList.findIndex(item => item.value === value) > -1
          ? tabList.findIndex(item => item.value === value)
          : 0
      }
      onChange={(e, v) => {
        // if (tabList.length === v) {
        //   onClickAdd && onClickAdd();
        //   return;
        // }
        onChange && onChange(tabList[v]);
      }}
    >
      {tabList.map(item => (
        <Tab
          key={item.value}
          classes={tabClasses}
          disableRipple
          label={
            <Grid container alignItems={"center"} wrap={"nowrap"}>
              <Radio
                checked={item.value === value}
                // onChange={e => {
                //   console.log(e);
                //   onChange && onChange(e);
                // }}
              />
              <CCTypography variant={"body1"} component={"span"}>
                {item.label}
              </CCTypography>
            </Grid>
          }
        />
      ))}
    </Tabs>
  );
};
export default RadioGroup;
