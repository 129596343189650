import { gql } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import moment from "moment";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { useIntl } from "react-intl";
import { ProgressIcon } from "styles/icons";
import { CCDialogInputError, CCDialogSimple } from "styles/src/components";
import {
  CompleteRegistration,
  FailedRegistration,
  NetworkErrRegistration
} from "./components";
import ApplicationForm from "./components/ApplicationForm/ApplicationForm";
import ContractAssign from "./components/ContractAssign";
import { translate } from "components";
const REGISTER_NEW_LAB = gql`
  mutation createLabApplicationRequest($input: CreateLabInput!) {
    createLabApplicationRequest(input: $input) {
      id
      status
      labName
      managerName
      managerCellNumber
      labId
      labTelNumber
      email
      createdAt
      updatedAt
    }
  }
`;

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.16)",
    zIndex: 1,
    padding: "6px 14px",
    display: "flex",
    fontSize: 14,
    borderRadius: "50px",
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.38)",
    alignItems: "center"
  },
  active: {
    backgroundColor: "#40c4ff",
    color: "#fff",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.54)"
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      width: 1114,
      maxWidth: 1114,
      height: 900
    }
  },
  grid__container: { flexDirection: "column", display: "flex", height: "100%" },
  grid__item1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px"
  },

  grid__item2: {
    display: "flex",
    margin: "20px 0",
    padding: "0 16px",
    position: "relative"
  },
  grid__item3: { padding: "0 16px", marginBottom: 16 },
  grid__item4: { padding: "20px 16px 16px", background: "#eceff1" },

  icon__connector: {
    color: "rgba(0, 0, 0, 0.24)",
    fontSize: 22
  },
  alternativeLabel: {
    flex: "none",
    display: "flex",
    alignItems: "center"
  },
  button: {
    textTransform: "none",
    padding: "6px 16px 6px 8px"
  },
  button__back: {
    color: "white",
    background: "rgba(0, 0, 0, 0.38)",
    textTransform: "none",
    padding: "6px 10px 6px 6px"
  },
  button__close: {
    color: "rgba(0, 0, 0, 0.54)",
    textTransform: "none",
    padding: "6px 16px 6px 8px"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepper: {
    padding: 8
  },
  steplabel: { marginTop: "0 !important" },
  button__bar: {
    padding: "8px 16px"
  },
  icon: {
    marginRight: 8
  }
}));

const ColorlibStepIcon = props => {
  const { active, completed } = props;
  const classes = useColorlibStepIconStyles();
  const intl = useIntl();
  const icons = {
    1: translate(intl, "signUp.ui.stepsTerm"),
    2: translate(intl, "signUp.ui.stepsForm"),
    3: translate(intl, "signUp.ui.stepsComplete")
  };

  return (
    <Typography
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </Typography>
  );
};

const validateSignUp = error => {
  if (error?.labId) {
    return "signUp.error.labId";
  }
  if (error?.userId) {
    return "signUp.error.userId";
  }
  // if (error?.password) {
  //   return "signUp.error.password";
  // }
  // if (error?.confirmPassword) {
  //   return "signUp.error.confirmPassword";
  // }
  if (error?.labName) {
    return "signUp.error.labName";
  }
  if (error?.tellPhoneNumber) {
    return "signUp.error.tellPhoneNumber";
  }
  if (error?.email) {
    return "signUp.error.email";
  }
  if (error?.address) {
    return "signUp.error.address";
  }
  if (error?.birthDate) {
    return "signUp.error.birthDate";
  }
  if (error?.managerName) {
    return "signUp.error.managerName";
  }
  if (error?.cellPhoneNumber) {
    return "signUp.error.cellPhoneNumber";
  }

  return null;
};

const CleverRegistration = forwardRef(({ open, onClose }, ref) => {
  const classes = useStyles();
  const intl = useIntl();
  const steps = [
    translate(intl, "signUp.ui.stepsTerm"),
    translate(intl, "signUp.ui.stepsForm"),
    translate(intl, "signUp.ui.stepsComplete")
  ];
  useImperativeHandle(ref, () => ({
    resetValue() {
      setValueForm({});
      setActiveStep(0);
      setDisableNext(true);
      error.current = {
        ...error.current,
        labId: true,
        userId: true,
        birthDate: true,
        cellPhoneNumber: true,
        tellPhoneNumber: true,
        // password: true,
        address: true,
        managerName: true,
        email: true,
        labName: true,
        // confirmPassword: true
      };
    }
  }));

  const [inputErr, setInputErr] = useState(null);
  const [statusRegister, setStatusRegister] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [disableNext, setDisableNext] = React.useState(true);
  const [valueForm, setValueForm] = useState({});

  const [registerNewLab] = useMutation(REGISTER_NEW_LAB);

  const onChange = (key, value) => {
    setValueForm({ ...valueForm, [key]: value });
  };
  const error = useRef({
    labId: true,
    userId: true,
    birthDate: true,
    cellPhoneNumber: true,
    tellPhoneNumber: true,
    // password: true,
    address: true,
    managerName: true,
    email: true,
    labName: true,
    // confirmPassword: true
  });
  const onError = (key, value) => {
    error.current = { ...error.current, [key]: value };
  };
  const handleNext = () => {
    if (activeStep === 1) {
      if (validateSignUp(error.current)) {
        setInputErr(validateSignUp(error.current));
        return;
      } else {
        registerNewLab({
          variables: {
            input: {
              labId: valueForm.labId,
              labName: valueForm.labName,
              labTelNumber: valueForm.tellPhoneNumber,
              // password: valueForm.password,
              email: valueForm.email,
              address: valueForm.address,
              birthDate: valueForm.birthDate || moment().unix(),
              managerName: valueForm.managerName,
              managerCellNumber: valueForm.cellPhoneNumber,
              staffId: valueForm.userId,
              tax: valueForm.tax
            }
          }
        })
          .then(res => { })
          .catch(err => {
            const { graphQLErrors, networkError } = err;
            if (graphQLErrors.length === 0) {
              setStatusRegister("networkError");
            }
            if (graphQLErrors) {
              graphQLErrors.forEach(graphQLError => {
                setStatusRegister("registrationFailed");
              });
            }
            if (networkError) {
              setStatusRegister("networkError");
            }
          });
      }
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const handleClose = () => {
    setActiveStep(0);
    onClose();
  };

  return (
    <CCDialogSimple
      classes={classes}
      open={open}
      maxWidth="lg"
      fullWidth
      onCloseButton={onClose}
      onClose={onClose}
      contentsPadding={false}
      title={translate(intl, "signUp.ui.signUpTitle")}
      contents={
        <Box className={classes.grid__container}>
          <Stepper
            className={classes.stepper}
            alternativeLabel
            activeStep={activeStep}
            connector={
              <div id="conntectorrr">
                <ChevronRightIcon className={classes.icon__connector} />
              </div>
            }
          >
            {steps.map(label => (
              <Step
                key={label}
                classes={{ alternativeLabel: classes.alternativeLabel }}
              >
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  classes={{ alternativeLabel: classes.steplabel }}
                />
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <ContractAssign
              handleAcceptConditionsAndTerms={requiredFieldAccepted => {
                setDisableNext(!requiredFieldAccepted);
              }}
              checked={!disableNext}
            />
          ) : activeStep === 1 ? (
            <ApplicationForm
              errors={error.current}
              onChange={onChange}
              onError={onError}
              value={valueForm}
            />
          ) : statusRegister === "networkError" ? (
            <NetworkErrRegistration />
          ) : statusRegister === "registrationFailed" ? (
            <FailedRegistration />
          ) : (
            <CompleteRegistration />
          )}

          <Grid
            container
            justify={activeStep !== 1 ? "flex-end" : "space-between"}
            className={classes.button__bar}
          >
            {activeStep === 1 && (
              <Button
                variant="contained"
                onClick={handleBack}
                className={classes.button__back}
              >
                <ChevronLeftIcon className={classes.icon} />{" "}
                {translate(intl, "common.ui.back")}
              </Button>
            )}

            {activeStep === steps.length - 1 ? (
              <Button onClick={handleClose} className={classes.button__close}>
                {translate(intl, "common.ui.close")}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                disabled={disableNext}
              >
                <ProgressIcon className={classes.icon} />{" "}
                {translate(intl, "common.ui.next")}
              </Button>
            )}
          </Grid>
          {Boolean(inputErr) && (
            <CCDialogInputError
              open={Boolean(inputErr)}
              title={translate(intl, inputErr)}
              onClose={() => setInputErr(null)}
            />
          )}
        </Box>
      }
    />
  );
});

export default CleverRegistration;
