import React from "react";

const SettingnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="xci3xqwbxa" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-931.000000, -369.000000) translate(931.000000, 369.000000)"
          />
          <path
            fill="url(#xci3xqwbxa)"
            d="M27 1c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4H5c-2.21 0-4-1.79-4-4V5c0-2.21 1.79-4 4-4h22zM13.964 2.667h-3.701c-.197 0-.373.128-.44.306l-.023.08-.344 2.378c-.444.185-.87.411-1.268.679l-.291.208-2.3-.9c-.185-.067-.39-.017-.519.126l-.05.067-1.85 3.111c-.105.17-.077.377.047.521l.058.058 1.957 1.491c-.04.283-.066.579-.066.875 0 .197.011.394.03.591l.036.296-1.957 1.491c-.15.124-.21.326-.142.504l.037.075 1.85 3.111c.104.169.3.25.488.215l.08-.022 2.3-.9c.381.288.787.535 1.226.74l.334.147.344 2.379c.035.18.191.33.38.365l.083.008h3.701c.197 0 .373-.128.44-.307l.023-.079.343-2.379c.445-.185.872-.411 1.269-.678l.291-.209 1.197.469-1.287 2.226c-.077.129-.059.287.03.398l.05.052 1.48 1.16c-.03.22-.05.45-.05.68 0 .153.009.307.024.46l.026.23-1.48 1.16c-.111.094-.157.247-.11.384l.03.066 1.4 2.42c.077.129.22.191.36.168l.07-.018 1.74-.7c.288.224.595.416.927.576l.253.114.26 1.85c.026.137.14.252.279.282L21.6 29h2.8c.146 0 .277-.096.33-.23l.02-.07.26-1.85c.336-.144.66-.32.96-.528l.22-.162 1.74.7c.137.051.289.015.386-.091l.044-.059 1.4-2.42c.077-.129.059-.287-.03-.398l-.05-.052-1.48-1.16c.03-.22.05-.45.05-.68 0-.153-.009-.307-.024-.46l-.026-.23 1.48-1.16c.111-.094.157-.247.11-.384l-.03-.066-1.4-2.42c-.077-.129-.22-.191-.36-.168l-.07.018-1.74.7c-.288-.224-.595-.416-.927-.576l-.253-.114-.26-1.85c-.026-.137-.14-.252-.279-.282L24.4 15h-2.8c-.146 0-.277.096-.33.23l-.02.07-.26 1.85c-.336.144-.66.32-.96.528l-.22.162-.55-.221 1.79-3.008c.103-.169.076-.377-.048-.521l-.059-.058-1.956-1.491c.04-.283.066-.579.066-.874 0-.197-.012-.395-.031-.592l-.035-.295 1.956-1.492c.15-.124.21-.326.143-.504l-.037-.074-1.85-3.112c-.104-.169-.3-.249-.489-.215l-.08.022-2.3.9c-.38-.288-.787-.535-1.225-.74l-.335-.147-.343-2.378c-.035-.18-.191-.33-.38-.366l-.083-.007zM23 20c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM12.113 9.095c1.454 0 2.644 1.157 2.644 2.572 0 1.414-1.19 2.571-2.644 2.571-1.454 0-2.643-1.157-2.643-2.571 0-1.415 1.19-2.572 2.643-2.572z"
            transform="translate(-931.000000, -369.000000) translate(931.000000, 369.000000)"
          />
          <path
            fill="#00BFA5"
            d="M23 20c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm5.25 2c0 .23-.02.46-.05.68l1.48 1.16c.13.11.17.3.08.45l-1.4 2.42c-.09.15-.27.21-.43.15l-1.74-.7c-.36.28-.76.51-1.18.69l-.26 1.85c-.03.17-.18.3-.35.3h-2.8c-.17 0-.32-.13-.35-.29l-.26-1.85c-.43-.18-.82-.41-1.18-.69l-1.74.7c-.16.06-.34 0-.43-.15l-1.4-2.42c-.09-.15-.05-.34.08-.45l1.48-1.16c-.03-.23-.05-.46-.05-.69 0-.23.02-.46.05-.68l-1.48-1.16c-.13-.11-.17-.3-.08-.45l1.4-2.42c.09-.15.27-.21.43-.15l1.74.7c.36-.28.76-.51 1.18-.69l.26-1.85c.03-.17.18-.3.35-.3h2.8c.17 0 .32.13.35.29l.26 1.85c.43.18.82.41 1.18.69l1.74-.7c.16-.06.34 0 .43.15l1.4 2.42c.09.15.05.34-.08.45l-1.48 1.16c.03.23.05.46.05.69z"
            transform="translate(-931.000000, -369.000000) translate(931.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M12.113 9.095c-1.454 0-2.643 1.157-2.643 2.572 0 1.414 1.19 2.571 2.643 2.571 1.454 0 2.644-1.157 2.644-2.571 0-1.415-1.19-2.572-2.644-2.572zm6.94 2.572c0 .295-.026.591-.066.874l1.956 1.491c.172.142.225.386.106.579l-1.85 3.111c-.12.193-.357.27-.569.193l-2.3-.9c-.476.36-1.004.656-1.56.887l-.343 2.379c-.04.219-.238.386-.463.386h-3.701c-.225 0-.423-.167-.463-.373l-.344-2.379c-.568-.231-1.083-.527-1.56-.887l-2.3.9c-.21.077-.449 0-.568-.193l-1.85-3.111c-.12-.193-.067-.437.105-.579l1.957-1.491c-.04-.296-.066-.592-.066-.887 0-.296.026-.592.066-.875L3.283 9.301c-.172-.141-.224-.386-.105-.579l1.85-3.111c.12-.193.357-.27.569-.193l2.3.9c.476-.36 1.004-.656 1.56-.887L9.8 3.052c.04-.218.238-.385.463-.385h3.701c.225 0 .423.167.463.373l.343 2.378c.569.232 1.084.527 1.56.887l2.3-.9c.212-.077.45 0 .569.193l1.85 3.112c.12.192.066.437-.106.578l-1.956 1.492c.04.295.066.591.066.887z"
            transform="translate(-931.000000, -369.000000) translate(931.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SettingnIcon;
