import { Grid, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { translate } from "components";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { RemoveIcon } from "styles/icons";
import {
  CCIconButton,
  CCPaper,
  CCTooltip,
  CCTypography
} from "styles/src/components";
import OrderItem from "../OrderItem";

const useStyle = makeStyles(theme => ({
  root: {
    background: "transparent"
  },
  paper: {
    position: "relative",
    width: 260,
    height: 576,
    overflowY: "auto",
    borderRadius: 8,
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24)",
    backgroundColor: "rgba(207, 216, 220, 0.87)"
  },
  item__header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    backgroundColor: theme.palette.common.white
  },
  item__total: {
    padding: "8px 16px",
    backgroundColor: theme.palette.common.white
  },
  header__title: {
    lineHeight: 1.5
  },
  closebutton: {
    margin: 0,
    color: theme.palette.sub.main
  },
  text__shipped: {
    color: theme.palette.primary.main
  },
  text__ordered: {
    color: theme.palette.secondary.main
  },
  text__pending: {
    color: theme.palette.error.main
  },
  text__empty: {
    color: theme.palette.input.main
  },
  orderitems: {
    display: "flex",
    flexDirection: "column"
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  }
}));

const MonthTooltipTitle = props => {
  const { children, items, event, localizer, tooltipType, total } = props;

  const classes = useStyle();
  const intl = useIntl();

  const [open, setOpen] = useState(false);

  return (
    <CCTooltip
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      interactive
      enterDelay={500}
      TransitionComponent={Zoom}
      className={clsx(classes.root)}
      title={
        <CCPaper variant={"outlined"} className={clsx(classes.paper)}>
          <Grid container direction="column">
            <Grid item className={classes.item__header}>
              <CCTypography variant="h4" className={classes.header__title}>
                {localizer.format(event.start, "DD/ddd")}
              </CCTypography>
              <CCIconButton
                size="small"
                onClick={e => {
                  setOpen(false);
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className={classes.closebutton}
              >
                <RemoveIcon />
              </CCIconButton>
            </Grid>
            <Grid item className={classes.item__total}>
              <CCTypography
                variant="body1"
                component="span"
                className={clsx({
                  [classes.text__ordered]: tooltipType === "ordered",
                  [classes.text__pending]: tooltipType === "pending",
                  [classes.text__shipped]: tooltipType === "shipped",
                  [classes.text__empty]: total === 0
                })}
              >
                {tooltipType === "ordered"
                  ? translate(intl, "labOrder.ordered")
                  : tooltipType === "pending"
                  ? translate(intl, "labOrder.pending")
                  : translate(intl, "labOrder.shipped")}
                : {total}
              </CCTypography>
            </Grid>
            <div className={classes.divider}></div>
            <Grid item className={classes.orderitems}>
              {items.map(item => (
                <div key={item.id}>
                  <OrderItem
                    status={item.status}
                    title={item.manufacture.name}
                  />
                  <div className={classes.divider}></div>
                </div>
              ))}
            </Grid>
          </Grid>
        </CCPaper>
      }
    >
      {children}
    </CCTooltip>
  );
};

export default MonthTooltipTitle;
