import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { translate, CVButton } from "components";
import React from "react";
import { useIntl } from "react-intl";
import { CCButton, CCTypography } from "styles/src/components";
import CCPaper from "styles/src/components/CCPaper";
import Tooltip from "@material-ui/core/Tooltip";

const topHeight = 42;

const useStyles = makeStyles(theme => ({
  root: { height: "100%", position: "relative" },
  top: { height: topHeight },
  item: { paddingLeft: 16 },
  container: {
    height: `calc(100% - ${topHeight}px)`,
    overflow: "auto",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default,
    padding: 8
  },
  backdrop: { height: "100%", width: "100%", position: "absolute", zIndex: 90 },
  padding0: { padding: 0 },
  margin0: { margin: 0 },
  button: {
    width: 75,
    wordBreak: "break-word"
  },
  button__item: {
    paddingBottom: 8
  }
}));

const PonicItem = [
  "Hygienic",
  "Conical",
  "Saddle",
  "Full Ridge",
  "Partial Ridge"
];

const Pontic = props => {
  const {
    value,
    onChange
    // open
  } = props;
  const { pontic } = value;

  const classes = useStyles();
  const intl = useIntl();

  const handleButton = selectItem => {
    if (pontic.includes(selectItem)) {
      onChange({ pontic: pontic.filter(item => item !== selectItem) });
    } else {
      onChange({ pontic: [selectItem] });
    }
  };

  return (
    <CCPaper className={clsx(classes.root)}>
      {/* <Backdrop open={open} msg={'Pontic'} className={classes.backdrop} /> */}
      <Grid
        className={classes.top}
        container
        wrap={"nowrap"}
        alignItems={"center"}
      >
        <Grid item className={classes.item}>
          <CCTypography variant={"h5"}>
            {translate(intl, "labManageGold.pontic")}
          </CCTypography>
        </Grid>
      </Grid>
      <Grid
        container
        className={clsx(classes.container)}
        alignItems={"center"}
        justify={"center"}
      >
        {PonicItem.map(item => (

          <Grid item className={classes.button__item} key={item}>
            <Tooltip title={item}>
              <CVButton
                size={"small"}
                variant={"contained"}
                color={pontic?.includes(item) ? "selected" : "normal"}
                className={classes.button}
                value={item}
                onClick={() => handleButton(item)}
              >
                {item}
              </CVButton>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </CCPaper>
  );
};

Pontic.defaultProps = {
  value: {
    pontic: []
  }
};

Pontic.propTypes = {};

export default Pontic;
