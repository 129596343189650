import React from "react";

const SaturationIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm5.972 3.959l-6.548 4.365c-.29.29-.452.682-.452 1.091 0 .41.162.802.452 1.091.29.29.682.453 1.091.453.41 0 .802-.163 1.092-.453l4.365-6.547z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default SaturationIcon;
