import gql from "graphql-tag";

const GET_LAB_ORDER_NUMBER = gql`
  query getLabNumber($hospitalId: String!) {
    getLabNumber(hospitalId: $hospitalId)
  }
`;

const GET_NUMBER = gql`
  query getNumber($hospitalId: String!) {
    getNumber(hospitalId: $hospitalId) {
      labIndex
    }
  }
`;

const BATCH_PUT_LAB_ORDER = gql`
  mutation batchPutLabOrder(
    $hospitalId: String!
    $args: [InputBatchPutLabOrder!]
  ) {
    batchCreateLabOrder(hospitalId: $hospitalId, args: $args)
  }
`;

const GET_CONFIG_PRICING = gql`
  query getConfig($labId: String, $category: String) {
    getConfig(labId: $labId, category: $category) {
      id
      category
      labId
      prosthesisPriceSetting {
        name
        id
        state
        isCustom
        items {
          name
          id
          partnerId
          item {
            contents {
              category
              tabContents {
                id
                material
                unit
                price
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_ORDER = gql`
  mutation createOrder(
    $requestDate: Int
    $deliveryRequestDate: Int!
    $type: String!
    $clinic: String!
    $patient: PatientInput
    $teeth: [Int!]
    $prosthesis: String!
    $unites: Int!
    $used: Float
    $consume: Float
    $status: String
    $arrival: String
    $mailCheck: Boolean
    $manufacture: ManufactureInput!
    $photo: [MinioImageInput]
    $pontic: [String]
    $shade: ShadeInput
    $missings: [String]
    $detail: [String]
    $staffId: String
    $note: String
    $doctor: String
  ) {
    createOrder(
      input: {
        requestDate: $requestDate
        deliveryRequestDate: $deliveryRequestDate
        type: $type
        clinic: $clinic
        patient: $patient
        teeth: $teeth
        prosthesis: $prosthesis
        unites: $unites
        used: $used
        consume: $consume
        status: $status
        arrival: $arrival
        mailCheck: $mailCheck
        manufacture: $manufacture
        photo: $photo
        pontic: $pontic
        shade: $shade
        missings: $missings
        detail: $detail
        staffId: $staffId
        note: $note
        doctor: $doctor
      }
    ) {
      code
      message
    }
  }
`;

const UPDATE_LAB_ORDER = gql`
  mutation updateLabOrder(
    $connectedRequestId: String
    $deliveryRequestDate: Int
    $deliveryType: String
    $detail: [String]
    $doctor: String
    $hospitalId: String!
    $id: String!
    $lab: String
    $manufacture: InputLabOrderManufacture
    $memo: String
    $missings: [String]
    $note: String
    $patient: String
    $photo: [String]
    $pontic: [String]
    $requestDate: Int
    $requestId: String
    $shade: InputLabOrderShade
    $staff: String
    $state: String
    $teeth: [Int]
    $type: String
    $log: InputLogDetail
  ) {
    updateLabOrder(
      connectedRequestId: $connectedRequestId
      deliveryRequestDate: $deliveryRequestDate
      deliveryType: $deliveryType
      detail: $detail
      doctor: $doctor
      hospitalId: $hospitalId
      id: $id
      lab: $lab
      manufacture: $manufacture
      memo: $memo
      missings: $missings
      note: $note
      patient: $patient
      photo: $photo
      pontic: $pontic
      requestDate: $requestDate
      requestId: $requestId
      shade: $shade
      staff: $staff
      state: $state
      teeth: $teeth
      type: $type
      log: $log
    )
  }
`;

const SEARCH_LAB_ORDER = gql`
  query searchLabOrder(
    $hospitalId: String!
    $args: InputSearchLabOrder
    $comparison: String
    $match: String
    $sort: InputSort
  ) {
    searchLabOrder(
      hospitalId: $hospitalId
      args: $args
      comparison: $comparison
      match: $match
      sort: $sort
    ) {
      connectedRequestId
      deliveryRequestDate
      deliveryType
      detail
      doctor {
        id
        name
      }
      id
      lab {
        accountHolder
        address
        bank
        bankAccountNumber
        businessLicenseNumber
        ceoName
        ceoPhoneNumber
        detailAddress
        email
        faxNumber
        id
        name
        phoneNumber
        postCode
        settlementDate
        target
        tradingStatus
      }
      manufacture {
        id
        name
      }
      memo
      missings
      note
      patient {
        id
        name
        chartNumber
        telephoneNumber
      }
      photo {
        id
      }
      pontic
      requestDate
      id
      requestId
      shade {
        target
        top
        middle
        bottom
      }
      staff {
        id
        name
      }
      state
      teeth
      type
    }
  }
`;

const GET_LAB_ORDER = gql`
  query orderList($query: OrderListFilterInput) {
    orderList(query: $query) {
      hasNext
      total
      items {
        id
        requestId
        requestDate
        deliveryRequestDate
        designConfirmMessageRead
        type
        clinic {
          id
          name
          createdBy
        }
        patient {
          name
          age
          sex
        }
        doctor
        teeth
        prosthesis
        manufacture {
          id
          name
        }
        unites
        totalPrice
        photo {
          target
          id
          name
          type
          hospitalId
        }
        used
        consume
        status
        arrival
        mailCheck
        staffId
        note
        clinicProthesisPrice {
          price
          discount
          totalPrice
          material
          unit
          amount
        }
        clinicGoldPrice {
          ownerShip
          goldType
          goldPrice
          goldUsed
          goldConsumed
          goldReceived
          remainGoldCalc
          totalGoldPriceCalc
        }
        designConfirmRequests {
          date
          sender
          status
          note
          attachments {
            target
            id
            name
            type
          }
        }
        messageRead
      }
    }
  }
`;

const GET_GOLD_UNIT_PRICE_MANAGE_DETAIL = gql`
  query GetGoldUnitPriceManageDetail($query: UnitPriceManageQueryInput) {
    goldUnitPriceManageDetail(query: $query) {
      labId
      inlay
      aType
      sA
      pt
      pfg
    }
  }
`;

const ON_DESIGN_CONFIRM_ADDED = gql`
  subscription onDesignConfirmationRequestAdded($orderId: String!){
    onDesignConfirmationRequestAdded(input: {
      orderId: $orderId
    }) {
      sender
      status
      isLabRead
      isClinicRead
      createdBy
    }
  }
`;
const ON_LAB_ORDER_UPDATED = gql`
  subscription ($input: OrderListFilterInput!){
    onLabOrderUpdated(input: $input){
      id
      arrival
      clinic {
        id
        name
      }
      labId
      labName
      consume
      detail
      mailCheck
      manufacture {
        id
        name
      }
      doctor
      missings
      note
      requestDate
      type
      deliveryRequestDate
      patient {
        name
        age
        sex
      }
      photo {
        target
        id
        name
        type
        hospitalId
      }
      pontic
      prosthesis
      requestId
      shade {
        target
        top
        middle
        bottom
      }
      status
      teeth
      totalPrice
      unites
      used
      isClever
      clinicGoldPrice {
        ownerShip
        goldType
        goldPrice
        goldUsed
        goldConsumed
        goldReceived
        remainGoldCalc
        totalGoldPriceCalc
      }
      staffId
      doctor
      clinicProthesisPrice {
        price
        discount
        totalPrice
        material
        unit
        amount
      }
      designConfirmRequests {
        date
        sender
        status
        createdBy
        note
        attachments {
          target
          id
          name
          type
        }
      }
      linkedOrderIds
      createdBy
      linkedCleverOrderIdMap {
        labOrderId
        cleverOrderId
      }
      productProcess{
        id
        processName
        stages{
          id
          stageName
          isChecked
          date
          editor{
            staffUUID
            staffId
            staffName
          }
        }
      }
    }
  }
`;

const GET_DESIGN_CONFIRM_REQUESTS = gql`
  query orderDetail($id: String) {
    orderDetail(id: $id) {
      designConfirmRequests {
        date
        sender
        status
        createdBy
        note
        isLabRead
        isClinicRead
        attachments {
          target
          id
          name
          type
        }
      }
    }
  }
`;
const GET_LAB_ORDER_DETAIL = gql`
  query orderDetail($id: String) {
    orderDetail(id: $id) {
      id
      arrival
      clinic {
        id
        name
      }
      labId
      labName
      consume
      detail
      mailCheck
      manufacture {
        id
        name
      }
      doctor
      missings
      note
      requestDate
      type
      deliveryRequestDate
      patient {
        name
        age
        sex
      }
      photo {
        target
        id
        name
        type
        hospitalId
      }
      pontic
      prosthesis
      requestId
      shade {
        target
        top
        middle
        bottom
      }
      status
      teeth
      totalPrice
      unites
      used
      isClever
      clinicGoldPrice {
        ownerShip
        goldType
        goldPrice
        goldUsed
        goldConsumed
        goldReceived
        remainGoldCalc
        totalGoldPriceCalc
      }
      staffId
      doctor
      clinicProthesisPrice {
        price
        discount
        totalPrice
        material
        unit
        amount
      }
      designConfirmRequests {
        date
        sender
        status
        createdBy
        note
        attachments {
          target
          id
          name
          type
        }
      }
      linkedOrderIds
      createdBy
      linkedCleverOrderIdMap {
        labOrderId
        cleverOrderId
      }
      productProcess{
        id
        processName
        stages{
          id
          stageName
          isChecked
          date
          editor{
            staffUUID
            staffId
            staffName
          }
        }
      }
    }
  }
`;

const GET_CONFIG = gql`
  query getConfig($category: String, $labId: String) {
    getConfig(category: $category, labId: $labId) {
      labId
      category
      crownSetting {
        id
        name
        state
        items {
          name
          item {
            contents {
              tabList
              tabContents {
                material
                shade
                pontic
                deliveryDate
              }
            }
          }
        }
      }
    }
  }
`;

const BATCH_GET_LAB_ORDER = gql`
  query batchGetLabOrder($hospitalId: String!, $id: [String!]) {
    batchGetLabOrder(hospitalId: $hospitalId, id: $id) {
      connectedRequestId
      deliveryRequestDate
      deliveryType
      detail
      doctor {
        id
        name
        stamp {
          id
          content {
            object
          }
        }
      }
      id
      lab {
        accountHolder
        address
        bank
        bankAccountNumber
        businessLicenseNumber
        ceoName
        ceoPhoneNumber
        detailAddress
        email
        faxNumber
        id
        name
        phoneNumber
        postCode
        settlementDate
        target
        tradingStatus
      }
      manufacture {
        id
        name
      }
      memo
      missings
      note
      patient {
        id
        name
        chartNumber
        residentRegistrationNumber
      }
      photo {
        id
        content {
          filename
          object
        }
      }
      pontic
      requestDate
      requestId
      shade {
        target
        top
        middle
        bottom
      }
      staff {
        id
        name
      }
      state
      teeth
      type
    }
  }
`;

const REMOVE_LAB_ORDER = gql`
  mutation removeLabOrder(
    $hospitalId: String!
    $id: String!
    $log: InputLogDetail
  ) {
    removeLabOrder(hospitalId: $hospitalId, id: $id, log: $log)
  }
`;

const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus($ids: [String], $status: String) {
    updateOrderStatus(ids: $ids, status: $status) {
      code
    }
  }
`;

const DELETE_ORDER = gql`
  mutation deleteOrders($id: String) {
    deleteOrders(id: $id) {
      code
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      code
    }
  }
`;

const GET_STAFF_INFO = gql`
  query GetStaffInfo {
    me {
      staffId
      staffName
      lab {
        labId
        id
      }
    }
  }
`;

const GET_ATTACHMENT_ARCHIVE = gql`
  query getAttachmentArchive($input: MessagesGalleryInputList!) {
    gallery(input: $input) {
      id
      url
      contentType
      key
      bucket
      filename
      fileId
    }
  }
`;

const CREATE_MESSAGE = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
    }
  }
`;

const GET_MESSAGE_LIST = gql`
  query GetMessageList($input: MessagesInputList!) {
    messages(input: $input) {
      items {
        content
        timestamp
        chatGroupId
        from {
          id
          name
          avatar
        }
        id
        attachments {
          bucket
          contentType
          id
          url
          filename
          fileId
        }
      }
      hasNext
      hasPrev
      total
    }
  }
`;

const ON_MESSAGE_RECEIVED = gql`
  subscription OnMessageReceived($input: OnMessageReceivedInput!) {
    onMessageReceived(input: $input) {
      content
      timestamp
      chatGroupId
      from {
        id
        name
        avatar
      }
      id
      attachments {
        bucket
        contentType
        id
        url
        filename
        fileId
      }
    }
  }
`;

const MARK_MESSAGE_READ = gql`
  mutation ($orderId: String!) {
    markMessageRead(orderId: $orderId)
  }
`;

const ADD_DESIGN_CONFIRM_REQUEST = gql`
  mutation addDesignConfirmRequest(
    $labOrderId: String!
    $designConfirmRequest: DesignConfirmRequestInput
  ) {
    addDesignConfirmRequest(
      labOrderId: $labOrderId
      designConfirmRequest: $designConfirmRequest
    ) {
      date
      sender
      status
      note
      attachments {
        target
        id
        name
        type
      }
    }
  }
`;

export {
  GET_LAB_ORDER_DETAIL,
  UPDATE_ORDER_STATUS,
  GET_CONFIG_PRICING,
  GET_CONFIG,
  CREATE_ORDER,
  SEARCH_LAB_ORDER,
  GET_GOLD_UNIT_PRICE_MANAGE_DETAIL,
  GET_LAB_ORDER,
  UPDATE_LAB_ORDER,
  GET_LAB_ORDER_NUMBER,
  GET_NUMBER,
  BATCH_GET_LAB_ORDER,
  BATCH_PUT_LAB_ORDER,
  REMOVE_LAB_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER,
  GET_STAFF_INFO,
  GET_ATTACHMENT_ARCHIVE,
  CREATE_MESSAGE,
  GET_MESSAGE_LIST,
  ON_MESSAGE_RECEIVED,
  MARK_MESSAGE_READ,
  ON_DESIGN_CONFIRM_ADDED,
  ADD_DESIGN_CONFIRM_REQUEST,
  ON_LAB_ORDER_UPDATED,
  GET_DESIGN_CONFIRM_REQUESTS
};
