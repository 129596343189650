import React from "react";

const FlagIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M9.147 2.765L8.8 1 1 1 1 16 2.733 16 2.733 9.824 7.587 9.824 7.933 11.588 14 11.588 14 2.765z"
        transform="translate(4.5 3.5)"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default FlagIcon;
