import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  CVFlattenDateField,
  CVIconButton,
  CVTable,
  DialogWarningMessage
} from "components";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  CCPaper,
  CCTextFieldNumber,
  CCTypography
} from "styles/src/components";
import { getMomentFormatTimestamp, getTimestamp } from "utils/datetime";
import { AddIcon } from "styles/src/themes/common/icons";
import DisableTable from "./components/DisableTable";
import { useIntl } from "utils/language";

const head__height = 30;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: 820
  },
  paper: {
    position: "relative",
    height: "inherit",
    width: "inherit"
  },
  expire_table: {
    marginTop: "10px",
    height: "150px"
  },
  container: {
    height: head__height
  },
  expire__item: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 8
  },
  subitem: {
    marginTop: 8,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 8
  },
  mouse__hover: {
    cursor: "pointer"
  },
  table: {
    height: `calc(100% - ${head__height}px + 1px)`,
    position: "relative"
  },
  date__field: {
    width: 150
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  }
}));

const ExpireTable = props => {
  const intl = useIntl();
  const { expire, currentCnt, value, onChange } = props;
  console.log("ExpireTable expire", expire)
  const classes = useStyles();
  const [total, setTotal] = useState(currentCnt);
  const [lastEditedCountIndex, setlastEditedCountIndex] = useState(0);
  const [alrDialog, setAlrDialog] = useState(false);
  const [zeroDialog, setZeroDialog] = useState(false);
  const [exceedDialog, setExceedDialog] = useState(false);

  const onAdd = () => {
    if (currentCnt - total > 0) {
      onChange([
        ...value,
        {
          expireDate: getTimestamp(),
          expireCount: 0
        }
      ]);
    } else {
      setZeroDialog({
        title: intl.formatMessage(
          { id: "alrInsertExpireInfo" },
          { br: () => <br /> }
        ),
        onAgree: () => {
          setZeroDialog(null);
        }
      });
    }
  };

  const onDelete = ({ rowIndex }) => {
    if (value?.length > 1) {
      onChange(value.filter((_, index) => index !== rowIndex));
    } else {
      setAlrDialog({
        title: intl.formatMessage(
          { id: "informNoExpire" },
          { br: () => <br /> }
        ),
        onAgree: () => {
          setAlrDialog(null);
        }
      });
    }
  };

  const onModify = useCallback(
    row => {
      const { option, rowIndex, e } = row;
      const counts = value.map(item => item?.expireCount);
      const dates = value.map(item => item?.expireDate);
      const ModMap = [];

      for (let i = 0; i < value.length; i++) {
        if (i === rowIndex) {
          if (option) {
            dates[rowIndex] = e;
          } else {
            counts[rowIndex] = e;
          }
        }
        ModMap.push({
          expireDate: dates[i],
          expireCount: counts[i]
        });
      }

      onChange(ModMap);
    },
    [onChange, value]
  );
  const heads = useMemo(() => {
    return [
      {
        key: "expireDate",
        value: "expireDate",
        width: 200,
        component: ({ rowIndex }) => (
          <CVFlattenDateField
            label={intl.formatMessage({ id: "expireDate" })}
            margin={"dense"}
            variant={"outlined"}
            value={getMomentFormatTimestamp(value[rowIndex].expireDate, "X")}
            onChange={e => onModify({ option: true, rowIndex, e })}
          />
        )
      },
      {
        key: "expireCount",
        value: "expireCount",
        width: 190,
        component: ({ rowIndex }) => (
          <CCTextFieldNumber
            label={intl.formatMessage({ id: "inventoryCount" })}
            number={value[rowIndex].expireCount}
            decimalPoint={0}
            step={1}
            onChange={e => {
              setlastEditedCountIndex(rowIndex)
              onModify({
                option: false,
                rowIndex,
                e
              });
            }}
          />
        )
      }
    ];
  }, [intl, onModify, value]);

  useEffect(() => {
    if (Boolean(value?.length > 0)) {
      setTotal(
        Number(
          value
            .map(e => e.expireCount)
            .reduce(
              (acc, cur) => (cur > 0 ? Number(acc) + Number(cur) : Number(acc)),
              0
            )
        )
      );
      if (currentCnt - total < 0 && currentCnt !== 0) {
        onChange(
          value.map((e, index) => {
            if (lastEditedCountIndex === index) {
              return {
                expireDate: e?.expireDate,
                expireCount: 0
              }
            } else {
              return e;
            }
          })
        );

        setExceedDialog({
          title: intl.formatMessage(
            { id: "currentStockExpireCntAlert" },
            { count: currentCnt },
            { br: () => <br /> }
          ),
          onAgree: () => {
            setExceedDialog(null);
          }
        });
      }
    }
  }, [value, currentCnt, total, expire, intl, onChange, lastEditedCountIndex]);

  return (
    <Box className={clsx(classes.root)}>
      <CCPaper className={clsx(classes.paper)}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={clsx(classes.container)}
        >
          <Grid item className={clsx(classes.expire__item)}>
            <Grid container alignItems={"center"}>
              <Grid
                item
                className={clsx(classes.expire__item)}
                style={{ paddingLeft: 8 }}
              >
                <CCTypography variant={"h4"}>
                  {intl.formatMessage({ id: "expireDateSetting" })}
                </CCTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={clsx(classes.expire__item)}>
            <Grid container justify="flex-end" alignItems="center">
              <Grid item className={clsx(classes.expire__item)}>
                <CVIconButton disabled={!expire} onClick={() => onAdd()}>
                  <AddIcon />
                </CVIconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={clsx(classes.expire__item)}>
          <Grid container alignItems={"center"}>
            <Grid
              item
              className={clsx(classes.subitem)}
              style={{ paddingLeft: 8 }}
            >
              <CCTypography>
                {`${intl.formatMessage({ id: "leftCntforSetting" })}:${currentCnt - total >= 0 ? currentCnt - total : 0
                  }`}
              </CCTypography>
            </Grid>
          </Grid>
        </Grid>

        <Box className={clsx(classes.table)}>
          <CVTable
            heads={heads.map(item => ({ ...item, className: classes.cell }))}
            contents={expire ? value : []}
            onDelClick={onDelete}
            className={classes.expire_table}
          />
          <DisableTable
            open={!expire}
            contents={expire ? value : []}
            zIndex={90}
          />
        </Box>
      </CCPaper>
      <DialogWarningMessage open={Boolean(alrDialog)} {...alrDialog} />
      <DialogWarningMessage open={Boolean(zeroDialog)} {...zeroDialog} />
      <DialogWarningMessage open={Boolean(exceedDialog)} {...exceedDialog} />
    </Box>
  );
};
export default ExpireTable;
