/*eslint react-hooks/exhaustive-deps: 0*/
import { Box, Checkbox, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVButton, InfiniteTable, TypographyTooltip } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { CAPITALIZE_TYPE, useIntl } from "utils/language";
import { CheckIcon } from "styles/src/themes/common/icons";
import { CCIconButton } from "styles/src/components";
import axios from "axios";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/utils/TableItems";
import { EstimateDetailDialog } from "../../../EstimationTab/Component/EstimateResults/Component";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  container: { height: "100%" },
  container__filter: {
    textAlign: "center"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  item__table: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    textAlign: "center"
  },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  text__description: {
    color: theme.palette.disabled.hex
  },
  button__sms: {
    width: "110px",
    height: "24px",
    padding: "0px"
  },
  button__filter: {
    minWidth: "100px",
    minHeight: "10px",
    margin: "16px"
  },
  select: { width: "100px", margin: "4px" },
  select__w126: { width: "126px" },
  select__w155: { width: "155px" },
  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center"
  },
  head__field: {
    width: "100px"
  },
  headertwo: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "80px",
    alignItems: "center"
  },
  headerfilter: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  header__table: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  field: {
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    textAlign: "center"
  },
  table: {
    height: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  shrink: {
    background: theme.palette.common.white,
    paddingRight: "4px"
  },
  button_register: {
    padding: "0px",
    width: "120px",
    margin: "10px"
  },
  button_right: {
    marginTop: "10px",
    marginRight: "10px"
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  icon__star: {
    margin: 12,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  primary__color: {
    color: theme.palette.primary.main
  },
  error__color: {
    color: theme.palette.error.main
  },
  change__color: {
    color: theme.palette.secondary.main
  },
  button__details: {
    height: "20px",
    width: "58px",
    padding: "0px",
    alignItems: "center",
    fontSize: "12px",
    zIndex: 10
  }
}));

const SearchProductResults = props => {
  const { result, onMoreContents, hasNext } = props;
  const classes = useStyles();
  const intl = useIntl();
  const _url = process.env.REACT_APP_PAM_URL;
  const [detail, setDetail] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState([]);

  const fetchVendor = useCallback(async () => {
    try {
      // setLoading(true);
      axios({
        method: "GET",
        url: `${_url}/api/v1/clinic/${sessionStorage.getItem(
          "clinicId"
        )}/material`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          console.log("&&&& SearchProductResults ", response);
          setConnected(response?.data?.map(e => e.id));
        })

        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
    // setLoading(false);
  }, []);

  useEffect(() => {
    fetchVendor();
  }, [fetchVendor]);

  return (
    <Box className={classes.root}>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.item__table} item>
          <InfiniteTable
            heads={[
              {
                key: "Material",
                headComponent: ({ cellData, rowData }) => {
                  return (
                    <Checkbox
                      disableRipple
                      // checked={rowData}
                    />
                  );
                },
                width: 60,
                component: ({ cellData }) => {
                  // let _checked = deleteList?.includes(cellData);
                  return (
                    <TableItem
                      disableTypography
                      label={
                        connected.includes(cellData?.id) ? (
                          <Checkbox
                            // checked={_checked}
                            style={{ padding: 10 }}
                            disableRipple
                            // onChange={e => {
                            //   if (e.target.checked) {
                            //     if (Boolean(deleteList?.length > 0)) {
                            //       setDeleteList([...deleteList, cellData]);
                            //     } else {
                            //       setDeleteList([cellData]);
                            //     }
                            //   } else {
                            //     setDeleteList(
                            //       deleteList.filter(e => e !== cellData)
                            //     );
                            //   }
                            // }}
                            className={classes.row__focus}
                          />
                        ) : null
                      }
                    />
                  );
                }
              },
              {
                key: "Material",
                headComponent: () => (
                  <TableHeader label={intl.formatMessage({ id: "supplier" })} />
                ),
                width: 160,
                label: () => (
                  <TableHeader label={intl.formatMessage({ id: "supplier" })} />
                ),
                component: ({ cellData }) => {
                  return <TableItem label={cellData ? cellData?.name : ""} />;
                }
              },
              {
                key: "MaterialItemImage",
                headComponent: () => (
                  <TableHeader label={intl.formatMessage({ id: "image" })} />
                ),
                label: () => <TableHeader label={"status"} />,
                width: 200,
                component: ({ cellData }) => {
                  return (
                    <Box
                      style={{
                        width: 150,
                        backgroundColor: "#000",
                        height: 100,
                        backgroundImage: `url(${cellData?.url})`,
                        backgroundPosition: "center center",
                        backgroundOrigin: "border-box",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat"
                      }}
                    />
                  );
                }
              },
              {
                key: "category1",
                width: 200,
                headComponent: () => (
                  <TableHeader label={intl.formatMessage({ id: "major" })} />
                ),
                label: () => <TableHeader label={"major"} />,
                component: ({ cellData }) => {
                  return <TableItem label={cellData ? cellData : ""} />;
                }
              },
              {
                key: "category2",
                width: 200,
                label: <TableHeader label={"minor"} />,
                component: ({ cellData }) => {
                  return <TableItem label={cellData ? cellData : ""} />;
                }
              },
              {
                key: "name",
                width: 470,
                label: (
                  <TableHeader
                    label={intl.formatMessage(
                      { id: "tradingProduct" },
                      {},
                      { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                    )}
                  />
                ),
                component: ({ cellData }) => {
                  return <TableItem label={cellData ? cellData : ""} />;
                }
              },
              {
                key: "manufacturer",
                width: 160,
                label: (
                  <TableHeader
                    label={intl.formatMessage(
                      { id: "manufacture" },
                      {},
                      { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                    )}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData : ""} />
                )
              },
              {
                key: "unit1",
                width: 180,
                label: (
                  <TableHeader label={intl.formatMessage({ id: "unit" })} />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData : ""} />
                )
              },
              {
                key: "unitPrice",
                width: 160,
                label: (
                  <TableHeader
                    label={intl.formatMessage(
                      { id: "unitPriceGold" },
                      {},
                      { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                    )}
                  />
                ),
                component: ({ cellData, rowData }) => {
                  return (
                    <TableItem label={cellData ? cellData : ""} />
                    // <TableItem
                    //   disableTypography
                    //   label={
                    //     <CCIconButton
                    //       style={{ padding: 0, margin: 0 }}
                    //       disableRipple
                    //       variant={"text"}
                    //       size={"small"}
                    //       onClick={() => {
                    //         console.log(
                    //           "CELL >>>",
                    //           cellData,
                    //           typeof cellData,
                    //           "ROW >>>",
                    //           rowData
                    //         );
                    //
                    //         if (cellData) {
                    //           setDetail(cellData);
                    //         }
                    //       }}
                    //     >
                    //       <MenunextIcon />
                    //     </CCIconButton>
                    //   }
                    // />
                  );
                }
              },
              {
                key: "Material",
                width: 190,
                label: (
                  <TableHeader
                    label={intl.formatMessage({ id: "supplierSetting" })}
                  />
                ),
                component: ({ cellData, rowData }) => {
                  return connected.includes(cellData?.id) ? (
                    <TableItem
                      label={
                        <CCIconButton
                          style={{ padding: 0, margin: 0 }}
                          disableRipple
                          color={"primary"}
                          variant={"text"}
                          size={"small"}
                          onClick={() => {
                            console.log(
                              "CELL >>>",
                              cellData,
                              typeof cellData,
                              "ROW >>>",
                              rowData
                            );

                            if (cellData) {
                              setDetail(cellData?.id);
                            }
                          }}
                        >
                          <CheckIcon />
                        </CCIconButton>
                      }
                    />
                  ) : (
                    <TableItem
                      label={
                        <CVButton
                          className={classes.button__details}
                          variant="contained"
                          size="small"
                          color={"normal"}
                        >
                          <TypographyTooltip color="inherit">
                            {intl.formatMessage({ id: "registerPartner" })}
                          </TypographyTooltip>
                        </CVButton>
                      }
                    />
                  );
                }
              }
            ]}
            contents={result || []}
            hasNext={hasNext}
            onMoreContents={e => {
              onMoreContents && onMoreContents(e);
            }}
          />
        </Grid>
      </Grid>

      {Boolean(detail) && (
        <EstimateDetailDialog
          open={Boolean(detail)}
          onClose={() => setDetail(false)}
          product={detail ? parseInt(detail) : null}
        />
      )}

      {/*{Boolean(detail) && (*/}
      {/*  <PatientDialog*/}
      {/*    open={Boolean(detail)}*/}
      {/*    onClose={() => setDetail(false)}*/}
      {/*    product={detail}*/}
      {/*  />*/}
      {/*)}*/}
    </Box>
  );
};

export default SearchProductResults;
