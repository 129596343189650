import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CCTextField } from "styles/components";
import { CCTooltip } from "styles/src/components";


const EmailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*(\.[a-zA-Z]{2,})$/i;

const EmailField = props => {
  const {
    value,
    select,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;

  // value
  const [error, setError] = useState(false);

  // function
  const isValidEmail = email => EmailRegExp.test(email);
  const handleOnChangeEmail = e => {
    const email = e.target.value;
    onChange({ target: { value: email } });
    if (isValidEmail(email)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  // useEffect
  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);
  useEffect(() => {
    if (required && value && isValidEmail(value)) {
      setError(false);
    } else setError(true);
  }, [value, required]);

  return (
    <CCTooltip placement={"top"} 
      arrow title={select ? "" : value || ""}
    >
      <CCTextField
        margin={margin}
        variant={variant}
        label={label}
        onChange={handleOnChangeEmail}
        error={error}
        required={required}
        value={value || ""}
        select={select}
        {...others}
      />
    </CCTooltip>
  );
};

EmailField.defaultProps = {
  value: "",
  margin: "dense",
  variant: "outlined",
  label: "Email",
  required: false,
  errorOnChange: () => {}
};

EmailField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default EmailField;
