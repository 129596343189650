import { useQuery } from "@apollo/react-hooks";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { InfiniteTable } from "components";
import { GOLD_CLINIC_STORAGE_LIST } from "queries/goldManagement";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CellMeasurerCache } from "react-virtualized";
import { CCIconButton, CCTypography } from "styles/components";
import { AgreementIcon } from "styles/icons";
import ClinicHistoryModal from "../ClinicHistoryModal";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: 812
  },
  border__cell__left: {
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  border__cell__top: {
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  border__cell__bottom: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__content: {
    height: "100%",
    width: "100%"
  },
  header__cell: {
    height: 72
  },
  cell__center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: 36
  },
  history__button: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  color__green: {
    color: "#00897b"
  }
}));

const ClinicTable = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [clinicHistoryModalVisible, setClinicHistoryModalVisible] =
    useState(false);
  const [cache] = useState(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true
    })
  );
  const [selectedClinicId, setSelectedClinicId] = useState("");

  const {
    data: goldClinicStorageListData,
    loading: goldClinicStorageListLoading
  } = useQuery(GOLD_CLINIC_STORAGE_LIST);

  const heads = [
    {
      key: "no",
      label: translate(intl, "labManageGold.no"),
      width: 70,
      component: ({ rowIndex }) => {
        return <Box className={classes.cell__center}>{rowIndex + 1}</Box>;
      }
    },
    {
      key: "clinic",
      label: translate(intl, "labManageGold.clinic"),
      width: 194,
      component: ({ cellData }) => {
        return (
          <Box className={classes.cell__center}>
            <CCTypography
              className={clsx({
                [classes.color__green]:
                  cellData?.createdBy === "Clever" ? true : false
              })}
            >
              {cellData.name}
            </CCTypography>
          </Box>
        );
      }
    },
    {
      width: 76,
      key: "history",
      label: translate(intl, "labManageGold.history"),
      component: ({ rowData }) => (
        <Box className={clsx(classes.cell__center, classes.border__cell__left)}>
          <CCIconButton
            className={classes.history__button}
            onClick={() => {
              console.log("History rowData", rowData);
              setClinicHistoryModalVisible(true);
              setSelectedClinicId(rowData.clinic.id);
            }}
          >
            <AgreementIcon />
          </CCIconButton>
        </Box>
      )
    },
    {
      key: "",
      label: ""
    }
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.table__content}>
        {!goldClinicStorageListLoading && (
          <InfiniteTable
            className={classes.table}
            heads={heads}
            contents={goldClinicStorageListData?.goldClinicStorageList?.items}
            rowClickHighlight={true}
            cache={cache}
            onRowClick={({ rowData }) => {
              setSelectedClinicId(rowData.clinic.id);
            }}
          />
        )}
      </Box>
      {clinicHistoryModalVisible && (
        <ClinicHistoryModal
          open={clinicHistoryModalVisible}
          clinicId={selectedClinicId}
          onClose={() => setClinicHistoryModalVisible(false)}
        />
      )}
    </Box>
  );
};

export default ClinicTable;
