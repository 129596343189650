import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { CCTextField, CCTypography } from "styles/components";
import FloatNumberFormat from "../FloatNumberFormat";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& input": {
      textAlign: "right"
    }
  }
}));

const UnitPriceInputField = props => {
  const { label, ...otherProps } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CCTypography>{label}</CCTypography>
      <CCTextField
        InputProps={{
          inputComponent: FloatNumberFormat
        }}
        {...otherProps}
      />
    </Box>
  );
};

export default UnitPriceInputField;
