import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import React, { forwardRef, useState } from "react";
import { CCIconButton, CCTooltip, CCTypography } from "styles/components";
import { PictureIcon } from "styles/icons";
import { useIntl } from "utils/language";
import { TableItem } from "views/Normal/InventoryManagement/Component/utils/TableItems";
import { CVTable, DialogWarningMessage } from "components";
import { EstimateSearchDialog } from "views/Normal/InventoryManagement/Component/Purchase/Component/EstimationTab/Component/EstimateCreateDialog/Component/EstimateSelectTable/components/EstimateSearchDialog";

const tableStyles = makeStyles(theme => ({
  table__body__row: {
    alignItems: "center"
  },
  table__body__column: {
    alignItems: "center"
  },
  table__head: {
    backgroundColor: theme.palette.text.primary,
    height: "120px"
  }
}));

const useStyles = makeStyles(theme => ({
  root: { width: "100%", height: "400px" },
  table: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 8,
    height: 500
  },
  alert: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10
  },
  text1: {
    color: theme.palette.error.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  text__align: {
    textAlign: "center"
  },
  divider: {
    width: "160px",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  text__color: {
    color: theme.palette.secondary.main
  }
}));

const EstimateSelectTable = forwardRef((props, ref) => {
  const { value = [], onChange, partner, pam, changePam } = props;

  const intl = useIntl();
  const classes = useStyles();
  const tableClasses = tableStyles();

  const [selectDialog, setSelectDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [product, setProduct] = useState([]);

  const onChangeProduct = item => {
    setProduct(item);
  };

  const onClickEnter = () => {
    if (value?.length > 0) {
      onChange(
        product?.length > 0 ? [...value, ...product] : [...value, product]
      );
    } else {
      onChange(product);
    }

    setProduct([]);
    setAlertOpen(false);
  };

  const heads = [
    {
      key: "",
      label: (
        <CCTypography className={classes.text__align}>
          {intl.formatMessage({ id: "major" })}
        </CCTypography>
      ),
      component: ({ rowData }) => {
        return (
          <CCTypography className={classes.text__align}>
            {rowData?.major}
          </CCTypography>
        );
      }
    },
    {
      key: "",
      label: (
        <CCTypography className={classes.text__align}>
          {intl.formatMessage({ id: "productName" })}
        </CCTypography>
      ),
      component: ({ rowData }) => {
        return (
          <CCTypography className={classes.text__align}>
            {rowData?.productName}
          </CCTypography>
        );
      }
    },
    {
      key: "",
      label: (
        <CCTypography className={classes.text__align}>
          {intl.formatMessage({ id: "manufacturer" })}
        </CCTypography>
      ),
      component: ({ rowData }) => {
        return (
          <CCTypography className={classes.text__align}>
            {rowData?.manufacture}
          </CCTypography>
        );
      }
    },
    {
      key: "",
      label: (
        <CCTypography className={classes.text__align}>
          {intl.formatMessage({ id: "unit" })}
        </CCTypography>
      ),
      component: ({ rowData }) => {
        return (
          <CCTypography className={classes.text__align}>
            {rowData?.unit}
          </CCTypography>
        );
      }
    },
    {
      key: "image",
      label: (
        <CCTypography className={classes.text__align}>
          {intl.formatMessage({ id: "image" })}
        </CCTypography>
      ),
      component: ({ rowData, cellData }) => {
        return (
          <TableItem
            label={
              <CCTooltip
                title={
                  <>
                    <Box
                      style={{
                        width: 300,
                        height: 300,
                        backgroundImage: `url(${cellData?.url})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                    />
                  </>
                }
              >
                <CCIconButton
                  style={{ padding: 0, margin: 0 }}
                  disableRipple
                  variant={"text"}
                  size={"small"}
                >
                  {cellData?.url ? <PictureIcon /> : ""}
                </CCIconButton>
              </CCTooltip>
            }
          />
        );
      }
    }
  ];

  const onDelClick = ({ rowIndex }) =>
    onChange(value.filter((_, index) => index !== rowIndex));

  return (
    <>
      <Box className={classes.root}>
        <CVTable
          classes={tableClasses}
          className={classes.table}
          title={
            <Grid container>
              <Grid item style={{ marginRight: 20 }}>
                <CCTypography variant="h5" component={"span"}>
                  {intl.formatMessage({ id: "requestProduct" })}
                </CCTypography>
              </Grid>

              <Grid item variant={"body1"}>
                <CCTypography className={classes.text__color}>
                  {value?.length}
                </CCTypography>
              </Grid>
              <Grid item variant={"body1"}>
                <CCTypography>
                  /100
                  {intl.formatMessage({ id: "hundredProductAddAvailable" })}
                </CCTypography>
              </Grid>
            </Grid>
          }
          heads={heads}
          contents={value}
          onAddClick={() => {
            if (partner) {
              setAlertOpen(true);
            } else {
              setSelectDialog({
                //TODO:LOCALE

                // title: intl.formatMessage(
                //   { id: "pleaseSelectPartner" }
                // ),
                title: "Please select partner",
                onAgree: () => {
                  setSelectDialog(null);
                }
              });
            }
          }}
          onDelClick={onDelClick}
        />
      </Box>
      {alertOpen && (
        <EstimateSearchDialog
          onChangeProduct={onChangeProduct}
          onClose={() => setAlertOpen(false)}
          onClickEnter={onClickEnter}
          open={alertOpen}
          partner={partner}
          products={value}
          pam={pam}
          changePam={changePam}
        />
      )}
      <DialogWarningMessage open={Boolean(selectDialog)} {...selectDialog} />
      />
    </>
  );
});

export default EstimateSelectTable;
