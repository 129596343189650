import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { forwardRef, useState } from "react";
import { CCTypography } from "styles/components";
import { useIntl } from "utils/language";
import { DetailResultsDialog } from "../DetailResults/components";
import StatusTable from "views/Normal/InventoryManagement/Component/utils/StatusTable/StatusTable";

const tableStyles = makeStyles(() => ({
  table__title: {
    height: 500,
    padding: "12px 12px 8px 16px"
  },
  table__head__row: {
    textAlign: "left",
    fontWeight: "bold",
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px"
  },
  table__body: {
    height: 15
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    height: 300,
    backgroundColor: theme.palette.common.white,
    boxSizing: "border-box",
    width: 1170,
    marginTop: "-50px"
  },
  container: { height: "100%" },
  // container__filter: {
  //   textAlign: "center"
  // },
  item: {
    display: "inline-flex"
    // alignItems: "center"
  },
  item__table: {
    height: "calc(100% - 60px - 30px)",
    width: "100%",
    // alignItems: "center",
    // textAlign: "center",
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: "8px"
  },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  row__divider: {
    width: 400,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  text__description: {
    color: theme.palette.disabled.hex
  },
  button__sms: {
    width: "110px",
    height: "24px",
    padding: "0px"
  },
  button__filter: {
    minWidth: "100px",
    minHeight: "10px",
    margin: "16px"
  },
  select: { width: "100px", margin: "4px" },
  select__w126: { width: "126px" },
  select__w155: { width: "155px" },
  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center"
  },
  head__field: {
    width: "100px"
  },
  title__table: {
    height: 30,
    display: "space-between"
  },
  headertwo: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "80px",
    alignItems: "center"
  },
  headerfilter: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  header__table: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  field: {
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    textAlign: "center"
  },
  table: {
    height: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  shrink: {
    background: theme.palette.common.white,
    paddingRight: "4px"
  },
  button_register: {
    padding: "0px",
    width: "120px",
    margin: "10px"
  },
  button_right: {
    marginTop: "10px",
    marginRight: "10px"
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  icon__star: {
    margin: 12,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  primary__color: {
    color: theme.palette.primary.main
  },
  error__color: {
    color: theme.palette.error.main
  }
}));

const ImageResults = forwardRef((props, ref) => {
  const { images = [] } = props;

  const classes = useStyles();
  const tableClasses = tableStyles();
  const intl = useIntl();

  const [detail, setDetail] = useState(false);

  return (
    <Box className={classes.root}>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.item__table} item>
          <StatusTable
            disableTypography={true}
            title={
              <>
                <Box className={classes.title__table}>
                  <CCTypography variant={"h5"} style={{ marginBottom: 15 }}>
                    {intl.formatMessage({ id: "imageAttachment" })}
                  </CCTypography>
                </Box>
              </>
            }
            classes={tableClasses}
            heads={[
              {
                key: "",
                component: ({ rowData }) => {
                  return rowData?.length > 0 ? (
                    <Grid container>
                      {rowData?.map(e => {
                        return (
                          <Grid item style={{ marginLeft: 10 }}>
                            <Box
                              style={{
                                width: 150,
                                height: 150,
                                borderRadius: 10,
                                backgroundImage: `url(${e?.url})`,
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat"
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Grid item />
                  );
                }
              }
            ]}
            contents={[images] || []}
          />
        </Grid>
      </Grid>

      {Boolean(detail) && (
        <DetailResultsDialog
          value={detail}
          open={Boolean(detail)}
          onClose={() => setDetail(false)}
        />
      )}
    </Box>
  );
});

export default ImageResults;
