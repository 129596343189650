import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";
import { CCButton, CCDialogSimple } from "../";
import { useIntl } from "../../../locales/language";

const useStyles = makeStyles(theme => ({
  // title: { width: 316 },
  icon: {
    color: theme.palette.error.main,
    marginRight: 8
  }
}));

const CCDialogAlert = props => {
  const { onAgree, contents, onDisagree, onClose, ...others } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <CCDialogSimple
      classes={classes}
      title={<WarningIcon className={classes.icon} />}
      onKeyDown={e => {
        e.preventDefault();
        if (e.key === "Enter" || e.key === "Escape") {
          onAgree && onAgree();
        }
      }}
      contents={contents}
      onCloseButton={onClose}
      endActions={
        <CCButton
          variant="contained"
          color="primary"
          onClick={() => {
            onAgree && onAgree();
          }}
        >
          {intl.formatMessage({ id: "ccCheck" })}
        </CCButton>
      }
      // onCloseButton={onClose}
      onClose={onClose}
      {...others}
    />
  );
};

export default CCDialogAlert;
