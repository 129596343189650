import { LinearProgress, makeStyles, Grid } from '@material-ui/core'
import clsx from "clsx";
import FileUploadButton from "components/FileUploadButton";
import { PatientContext } from "context/PatientContext";
import React, { useContext, useEffect, useState } from 'react'
import { CCPaper, CCTypography } from "styles/src/components";
import AddIcon from "styles/src/themes/common/icons/AddIcon";
import { PhotoThumbnailTile } from "components";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";


const topHeight = 42;
const toolbarHeight = 52 + 37 + 44;
const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  top: { height: topHeight },
  item: { paddingLeft: 16 },
  container: {
    position: "relative",
    height: `calc(100% - ${topHeight}px)`,
    overflow: "overlay",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default,
    padding: 8
  },
  padding0: { padding: 0 },
  margin0: { margin: 0 },
  button: {},
  button__item: {
    paddingBottom: 8
  },
  error: {
    color: theme.palette.error.main
  },
  photoGrid: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-around",
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: `calc(100% - ${toolbarHeight}px)`
  },
  loading: {
    zIndex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    background: "#00000080"
  },
  progress__bar__text: {
    marginRight: "3px",
    fontSize: "14px"
  },
  progress__bar: {
    height: "16px",
    width: "100%",
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.background.default
  },
  image__thumbnail: {
    width: 126,
    height: 100
  }
}));

const FileUploadPaper = props => {
  const classes = useStyles();
  const {
    onChange,
    value,
    title,
    buttonHidden,
    xs,
    readonly = true,
    message,
    onlyDirect,
    actionExt,
    multiple = false,
    multipleMaxCount = 1
  } = props;
  const patientContext = useContext(PatientContext);
  const [loadingFile, setLoadingFile] = useState(false);
  const [progress, setProgress] = useState(0);
  const [multipleCount, setMultipleCount] = useState(1);
  const [receivedFile, setReceivedFile] = useState([]);
  // const [batchGetPhoto] = useLazyQuery(BATCH_GET_PHOTO, {
  //   onCompleted: data => setPhotos(data?.batchGetPhoto)
  // });

  // useEffect(() => {
  //   if (value?.length) {
  //     batchGetPhoto({
  //       variables: {
  //         hospitalId: hospitalId,
  //         id: value
  //       }
  //     });
  //   } else {
  //     setPhotos([]);
  //   }
  // }, [value, hospitalId, batchGetPhoto]);

  // const handleRemoveImage = id => {
  //   onChange(value.filter(item => item.id !== id));
  // };

  const addPhoto = photo => {
    setProgress(0);
    if (photo) {
      setReceivedFile(receivedFile => [...receivedFile, photo]);
    }
  };

  useEffect(() => {
    if (receivedFile.length === multipleCount && onChange) {
      onChange([...value, ...receivedFile]);
      setReceivedFile([])
    }
  }, [multipleCount, onChange, receivedFile, value])

  const onDeletePhoto = photo => {
    setProgress(0);
    photo && onChange([...value.filter((item) => item.id !== photo.id)]);
  };

  const handleProgress = progressEvent => {
    // console.log("progress", progressEvent);
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader("content-length") ||
      progressEvent.target.getResponseHeader("x-decompressed-content-length");
    // console.log("onUploadProgress", totalLength);
    if (totalLength !== null) {
      setProgress(
        Math.round((progressEvent.loaded * 100) / totalLength)
      );
    }
  };

  return (
    <DndProvider backend={Backend}>
      <CCPaper className={clsx(classes.root)}>
        <Grid
          className={classes.top}
          container
          wrap={"nowrap"}
          alignItems={"center"}
          justify={"space-between"}
        >
          <Grid item className={classes.item}>
            <CCTypography variant={"h5"}>{title}</CCTypography>
          </Grid>
          {!buttonHidden && (
            <Grid item className={classes.item} hidden={buttonHidden}>
              <FileUploadButton
                patient={{ id: patientContext?.id, value: patientContext }}
                onChange={addPhoto}
                handleSetLoadigFile={setLoadingFile}
                buttonIcon={<AddIcon />}
                // accept={[
                //   "image/jpg",
                //   "image/jpeg",
                //   "image/png",
                //   "application/zip"
                // ]}
                disabled={loadingFile}
                onlyDirect={onlyDirect}
                allowExtension={[
                  "PNG",
                  "JPG",
                  "JPEG",
                  // "GIF",
                  "ZIP",
                  "STL"
                  // "DCM"
                ]}
                filetype={"image"}
                maxSize={"1024mb"}
                handleProgress={handleProgress}
                multiple={multiple}
                setMultipleCount={setMultipleCount}
                multipleMaxCount={multipleMaxCount}
              />
            </Grid>
          )}
        </Grid>
        <Grid container className={clsx(classes.container)}>
          <Grid item hidden={value && !!value.length}>
            <CCTypography>{message}</CCTypography>
          </Grid>
          {/* {value &&
          !!value.length &&
          value.map(photo => {
            return (
              <Card
                key={photo.id}
                type={photo.type}
                target={photo.target}
                name={photo.name}
                id={photo.id}
                hospitalId={photo.hospitalId}
                readonly={!buttonHidden}
                handleRemoveImage={handleRemoveImage}
              />
            );
          })} */}

          {value && !loadingFile &&
            !!value.length &&
            value.map(photo => {
              return (
                <Grid item className={classes.image__thumbnail} key={photo?.id}>
                  <PhotoThumbnailTile
                    photo={photo}
                    header={photo.ame}
                    onDeletePhoto={onDeletePhoto ? onDeletePhoto : () => {}}
                    patientId={patientContext?.id}
                    readonly={readonly}
                    viewMode
                    actionExt={actionExt}
                    action={"/contents/subtractline.svg"}
                  />
                </Grid>
              );
            })}
          {loadingFile && (
            <Grid
              container
              style={{ position: "relative" }}
              direction="column"
              justifyContent="center"
            >
              <Grid item>
                <CCTypography>{`${progress}% Uploaded`}</CCTypography>
              </Grid>
              <Grid className={classes.item__progress} item>
                <LinearProgress
                  className={classes.progress__bar}
                  // classes={{ barColorPrimary: classes.progress__bar__primary }}
                  color="secondary"
                  variant="determinate"
                  value={progress}
                />
              </Grid>
            </Grid>
          )
          }
        </Grid>
      </CCPaper>
    </DndProvider>
  );
};

FileUploadPaper.defaultProps = {
  title: "첨부파일",
  buttonHidden: false,
  onChange: e => {
    console.log(e);
  },
  value: []
};

FileUploadPaper.propTypes = {};

export default FileUploadPaper;
