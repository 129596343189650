import React from "react";

const DiscountIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm2.895 10.207c-.82 0-1.489.242-2.004.725-.47.44-.725 1.006-.767 1.7l-.007.212v.65c0 .803.255 1.447.765 1.934.51.486 1.186.73 2.03.73.838 0 1.509-.242 2.013-.726.458-.44.708-1.003.75-1.692l.006-.21v-.634c0-.837-.257-1.495-.77-1.973-.512-.477-1.184-.716-2.016-.716zm-.598-5.853l-6.25 10.001 1.24.668 6.25-10.002-1.24-.667zm.598 7.32c.328 0 .591.108.79.322.171.183.269.417.293.701l.006.147v.668c0 .79-.357 1.186-1.072 1.186-.316 0-.58-.114-.79-.343-.182-.195-.285-.426-.31-.69l-.007-.136v-.659c0-.369.1-.66.299-.874.199-.214.462-.321.79-.321zM8.769 5c-.827 0-1.495.245-2.004.734-.464.445-.717 1.012-.759 1.701L6 7.645v.625c0 .82.256 1.472.769 1.955.513.483 1.185.725 2.017.725.826 0 1.494-.24 2.004-.72.463-.437.716-1.002.758-1.696l.007-.212V7.69c0-.826-.255-1.48-.765-1.964C10.28 5.242 9.606 5 8.769 5zm0 1.468c.34 0 .606.108.8.325.165.186.26.421.283.706l.006.147v.65c0 .363-.096.653-.29.87-.193.217-.454.325-.782.325s-.593-.107-.795-.32c-.174-.184-.273-.418-.297-.702l-.006-.147v-.641c0-.381.1-.679.298-.892.2-.214.46-.321.783-.321z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default DiscountIcon;
