import { Box, Card, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { CCTypography } from "styles/components";
import { Target } from "utils/s3";
import { useGetAttachments } from "../../utils";
import ImagePreviewerModal from "../ImagePreviewerModal";

const useStyles = makeStyles(theme => ({
  root: ({ isFromSender }) => ({
    backgroundColor: isFromSender
      ? theme.palette.secondary.main
      : theme.palette.white
  }),
  text: ({ isFromSender }) => ({
    marginTop: 8,
    color: isFromSender ? theme.palette.white : theme.palette.black,
    fontSize: 14,
    whiteSpace: "pre-line",
    wordBreak: "break-word"
  }),
  image: {
    // maxHeight: "calc(100vh - 500px)",
    // maxWidth: "100%",
    width: 128,
    height: 102,
    // width: "auto",
    cursor: "pointer"
  }
}));

const ImageMessage = props => {
  const { labId, attachments = [], isFromSender, styles, content } = props;

  const classes = useStyles({ isFromSender });

  const [modals, setModals] = useState({});

  const { data: imageUrls } = useGetAttachments(
    attachments,
    Target.PHOTO,
    labId
  );

  return (
    <Box className={clsx(classes.root, styles)}>
      {attachments.length &&
        attachments.map((attachment, index) => {
          return (
            <Card key={index}>
              <CardMedia
                component="img"
                image={imageUrls[index]}
                className={classes.image}
                onClick={() =>
                  setModals(prevState => ({
                    ...prevState,
                    [attachment.id]: true
                  }))
                }
              />
              {!!modals[attachment.id] && (
                <ImagePreviewerModal
                  fileName={attachment.filename}
                  attachments={[attachment]}
                  open={!!modals[attachment.id]}
                  onClose={() =>
                    setModals(prevState => ({
                      ...prevState,
                      [attachment.id]: false
                    }))
                  }
                />
              )}
            </Card>
          );
        })}
      <CCTypography className={classes.text}>{content}</CCTypography>
    </Box>
  );
};

export default ImageMessage;
