import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import {
  CCButton,
  CCDialogAlert,
  CCIconButton,
  CCTypography
} from "styles/components";
import { BringInIcon } from "styles/icons";
import { useIntl } from "utils/language";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProductLogValidation } from "validations";
import { useQuery } from "@apollo/client";
import { getTimestamp } from "utils/datetime";
import { Box } from "@material-ui/core";
import DetailModify from "./components/DetailModify";
import { SEARCH_PRODUCT_LOG } from "queries/product";
import Grid from "@material-ui/core/Grid";
import { CCDialog, CCDialogContent } from "styles/src/components";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 700
    }
  },
  box: {
    width: 650
  }
}));

const initData = {
  hospital: "",
  important: false,
  unit: "",
  currentCnt: 0,
  optCnt: 0,
  alert: false,
  editDate: getTimestamp(),
  editorID: "",
  canExpire: false,
  expire: {
    expireCount: 0,
    expireDate: getTimestamp()
  },
  binCnt: 0,
  barcode: false,
  isAppropriate: false
};

const DetailResultsDialog = props => {
  const intl = useIntl();

  const { open, onClose, value = [] } = props;

  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const [detail, setDetail] = useState(initData);

  const { schema } = useProductLogValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const { data } = useQuery(SEARCH_PRODUCT_LOG, {
    variables: {
      def: "1",
      args: {
        id: value
      },
      comparison: "or",
      match: "contains"
    }
  });

  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);

  useEffect(() => {
    if (data?.searchProductLog) {
      setDetail(data?.searchProductLog[0]);
    }
  }, [detail, data]);

  return (
    <>
      <FormProvider {...methods}>
        <CCDialog open={open} classes={classes} onClose={onClose}>
          <CCDialogContent
            noPadding={true}
            style={{ overflow: "hidden" }}
            onCloseButton={onClose}
          >
            <Grid
              className={classes.container__filter}
              container
              direction="row"
            >
              <Grid item style={{ padding: 20 }} className={classes.item}>
                <Box display="flex" width="300px" flexDirection="row">
                  <Box display="flex" flexDirection="row">
                    <CCTypography variant="h3">
                      {detail?.inOut
                        ? intl.formatMessage({ id: detail?.inOut })
                        : ""}
                    </CCTypography>
                  </Box>
                </Box>

                <Box className={classes.box}>
                  <DetailModify value={detail} inOut={detail?.inOut} />
                </Box>
              </Grid>
            </Grid>
          </CCDialogContent>
          <Box style={{ overflowY: "auto", padding: 10 }} marginLeft={"auto"}>
            <CCButton
              style={{ overflowY: "auto", padding: 10 }}
              marginLeft={"auto"}
              className={classes.cancel__icon}
              variant={"text"}
              color={"normal"}
              onClick={onClose}
            >
              {intl.formatMessage({ id: "check" })}
            </CCButton>
          </Box>
          <CCDialogAlert
            contents={
              <Box className={classes.root}>
                <CCTypography>
                  {intl.formatMessage({ id: "registerProduct" })}
                  <CCIconButton color={"secondary"} variant={"contained"}>
                    <BringInIcon />
                  </CCIconButton>
                  {intl.formatMessage({ id: "clickBtn" })}
                </CCTypography>
              </Box>
            }
            open={alertOpen}
            onClose={setCloseAlert}
            onAgree={setCloseAlert}
            onDisagree={setCloseAlert}
          />
          <CCDialogAlert
            contents={
              <CCTypography>
                {intl.formatMessage({ id: "registerProduct" })}
                <CCIconButton color={"secondary"} variant={"contained"}>
                  <BringInIcon />
                </CCIconButton>
                {intl.formatMessage({ id: "registerInputContent" })}
              </CCTypography>
            }
            open={saveAlertOpen}
            onClose={setCloseSaveAlert}
            onAgree={setCloseSaveAlert}
            onDisagree={setCloseSaveAlert}
          />
        </CCDialog>
      </FormProvider>
    </>
  );
};

export default DetailResultsDialog;
