import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { CCFlattenDatePicker, CCIconButton } from "styles/components";
import { ReservationIcon, SmalldownIcon, SmallupIcon } from "styles/icons";

const useStyles = makeStyles(theme => ({
  textfield__year: {
    width: 92,
    margin: 0,
    marginRight: -1
  },
  textfield__week: {
    width: 110,
    height: 36,
    margin: 0,
    marginRight: 8
  },

  input__year: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
    backgroundColor: theme.palette.common.white,
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.16)"
    },
    "& > fieldset": {
      borderRightWidth: 0
    },
    "& > input": {
      "&.Mui-disabled": {
        backgroundColor: "unset"
      }
    }
  },
  input__week: {
    height: 36,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: theme.palette.common.white,
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.16)"
    },
    "& > div": {
      "&.Mui-disabled": {
        backgroundColor: "unset"
      }
    }
  },
  end__adornment: {
    flexDirection: "column",
    margin: 0,
    height: "auto",
    "&:hover": {
      cursor: "pointer"
    }
  },
  up: {
    marginBottom: "5px",
    color: theme.palette.icon
  },
  down: {
    color: theme.palette.icon
  },
  icon__disabled: {
    color: theme.palette.disabled
  },
  padding__right: { paddingRight: "8px" }
}));

const YearWeekPicker = props => {
  const classes = useStyles();
  const { onChange, value } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const monthValue = useMemo(() => {
    // const weekOfMonth = input => {
    //   const firstDayOfMonth = input.clone().startOf("month");
    //   const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

    //   const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

    //   return Math.ceil((input.date() + offset) / 7);
    // };

    const date = moment.unix(value).endOf("week");
    const month = date.format("MMMM");
    // const week = weekOfMonth(date);
    return `${month}`;
  }, [value]);

  return (
    <>
      <TextField
        className={classes.textfield__year}
        variant="outlined"
        margin="dense"
        value={moment.unix(value).format("YYYY") }
        InputProps={{
          className: classes.input__year,
          "aria-readonly": "true",
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end" className={classes.end__adornment}>
              <SmallupIcon
                className={clsx(classes.up, {})}
                onClick={event => {
                  onChange &&
                    onChange({
                      target: {
                        name: "periodMonth",
                        value: moment.unix(value).clone().add(1, "year").unix()
                      }
                    });
                }}
              />
              <SmalldownIcon
                className={clsx(classes.down, {})}
                onClick={event => {
                  onChange &&
                    onChange({
                      target: {
                        name: "periodMonth",
                        value: moment
                          .unix(value)
                          .clone()
                          .subtract(1, "year")
                          .unix()
                      }
                    });
                }}
              />
            </InputAdornment>
          )
        }}
      />
      <TextField
        className={classes.textfield__week}
        variant="outlined"
        value={monthValue}
        margin="dense"
        InputProps={{
          className: classes.input__week,
          readOnly: true,
          classes: { adornedEnd: classes.padding__right },
          //inputComponent: DateMask,
          endAdornment: (
            <InputAdornment style={{ margin: "0px" }} position={"end"}>
              <CCIconButton
                onClick={e => {
                  setAnchorEl(e.target);
                }}
                size={"small"}
              >
                <ReservationIcon />
              </CCIconButton>
            </InputAdornment>
          )
        }}
      />
      <CCFlattenDatePicker
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        onChange={value => {
          onChange({
            target: { name: props.name, value: value.unix() }
          });
          setAnchorEl(null);
        }}
        value={value ? moment.unix(value) : moment()}
      />
    </>
  );
};

export default YearWeekPicker;
