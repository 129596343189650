import gql from "graphql-tag";

const DEFECTIVE_PROTHESES_STATISTICS = gql`
  query defectiveProthesesStatistics($input: StatisticInput) {
    defectiveProthesesStatistics(input: $input) {
      total
      normal
      defective
      category
    }
  }
`;

const DEFECTIVE_PROTHESES_STATISTICS_DETAIL = gql`
  query defectiveProthesesDetailStatistics($input: StatisticInput) {
    defectiveProthesesDetailStatistics(input: $input) {
      total
      normal
      defective
      category
    }
  }
`;

const ORDER_STATISTICS = gql`
  query ordersStatistics($input: OrderStatisticsInput) {
    ordersStatistics(input: $input) {
      total
      new
      mend
      remake
      time
      clinic
    }
  }
`;
const ORDER_PROSTHESES_STATISTICS = gql`
  query orderProsthesesStatistics($input: StatisticInput) {
    orderProsthesesStatistics(input: $input) {
      value
      category
    }
  }
`;

const ORDER_PROSTHESES_STATISTICS_DETAIL = gql`
  query orderProsthesesDetailStatistics($input: StatisticInput) {
    orderProsthesesDetailStatistics(input: $input) {
      value
      category
    }
  }
`;


const SALES_ESTIMATES_STATISTICS = gql`
  query salesEstimatesStatistics($input: SalesEstimatesStatisticsInput) {
    salesEstimatesStatistics(input: $input) {
      arrived
      estimates
      time
      clinic
      ordered
    }
  }
`;
const LAB_APPLICATION_REQUEST_STATISTICS = gql`
  query labApplicationRequestStatistics {
    labApplicationRequestStatistics {
      totalNewApplication
      totalAcceptedApplication
      totalRefusedApplication
      total
    }
  }
`;
const LAB_STATISTICS = gql`
  query labStatistics {
    labStatistics {
      totalApproval
      totalInuse
      totalDiscontinued
      totalWithdraw
    }
  }
`;

export {
    DEFECTIVE_PROTHESES_STATISTICS,
    ORDER_STATISTICS,
    ORDER_PROSTHESES_STATISTICS,
    LAB_STATISTICS,
    SALES_ESTIMATES_STATISTICS,
    LAB_APPLICATION_REQUEST_STATISTICS,
    ORDER_PROSTHESES_STATISTICS_DETAIL,
    DEFECTIVE_PROTHESES_STATISTICS_DETAIL
};

