import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { AppContext } from "context/AppContext";
import { GET_LAB_DETAILS } from "queries/setting";
import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCIconButton, CCTypography } from "styles/src/components";
import CCPaper from "styles/src/components/CCPaper";
import { SettingIcon } from "styles/src/themes/common/icons";
import { translate, TypographyTooltip } from "../../../../../../../components";

const topHeight = 42;

const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  top: { height: topHeight },
  item: { paddingLeft: 16 },
  container: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "flex-start",
    height: `calc(100% - ${topHeight}px)`,
    overflow: "auto",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default,
    padding: 8
  },
  padding0: { padding: 0 },
  margin0: { margin: 0 },
  button: {
    width: 105,
    wordBreak: "break-word",
    textTransform: "unset",
  },
  button__item: {
    paddingBottom: 8
  }
}));

const Detail = props => {
  const { value, onChange } = props;
  const { detail } = value;

  const classes = useStyles();
  const intl = useIntl();
  const { appContext } = useContext(AppContext);

  const {
    data: dataLabDetails,
    loading: dataLabDetailsLoading,
    refetch: refetchLabDetails
  } = useQuery(GET_LAB_DETAILS, { fetchPolicy: "network-only" });

  const handleButton = selectItem => {
    if (detail.includes(selectItem)) {
      onChange({ detail: detail.filter(item => item !== selectItem) });
    } else {
      onChange({ detail: detail.concat(selectItem) });
    }
  };

  useEffect(() => {
    refetchLabDetails();
  }, [appContext.refresh, refetchLabDetails]);

  return (
    <CCPaper className={clsx(classes.root)}>
      <Grid
        className={classes.top}
        container
        wrap={"nowrap"}
        alignItems={"center"}
        justify={"space-between"}
      >
        <Grid item className={classes.item}>
          <CCTypography variant={"h5"}>
            {translate(intl, "menuStatistics.detail")}
          </CCTypography>
        </Grid>
        <Grid item className={classes.item}>
          <CCIconButton
            color={"sub"}
            onClick={() => {
              const w = window.open(
                `${window.location.origin}/setting`,
                "_blank"
              );
              w.variables = { category: "common.ui.orderDetails" };
            }}
          >
            <SettingIcon />
          </CCIconButton>
        </Grid>
      </Grid>
      <Grid container className={clsx(classes.container)} alignItems={"center"}>
        {!dataLabDetailsLoading &&
          dataLabDetails?.getLabDetailList.map((item, index) => (
            <Grid item className={classes.button__item} key={index}>
              <CCButton
                size={"small"}
                variant={"contained"}
                color={detail?.includes(item.content) ? "selected" : "normal"}
                className={classes.button}
                value={item}
                onClick={() => handleButton(item.content)}
              >
                <TypographyTooltip placement="top">
                  {item.content}
                </TypographyTooltip>
              </CCButton>
            </Grid>
          ))}
      </Grid>
    </CCPaper>
  );
};

Detail.defaultProps = {};

Detail.propTypes = {};

export default Detail;
