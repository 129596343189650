/*eslint react-hooks/exhaustive-deps: 0*/
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useContext, useState } from "react";
import { CCTypography } from "styles/components";
import { BringInIcon, CalculationIcon } from "styles/icons";
import { useIntl } from "utils/language";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getTimestamp } from "utils/datetime";
import { DBVendorContext } from "context/DBContext/components";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { CVTable } from "components";
import {
  CCButton,
  CCDialog,
  CCDialogAlert,
  CCDialogContent,
  CCDialogTitle,
  CCIconButton
} from "styles/src/components";
import { CVTextField } from "components";
import axios from "axios";
import useQuoteValidation from "validations/useQuoteValidation";
import { TableItem } from "views/Normal/InventoryManagement/Component/utils/TableItems";

const tableStyles = makeStyles(theme => ({
  table__body__row: {
    alignItems: "center"
  },
  table__body__column: {
    alignItems: "center"
  },
  table__head: {
    backgroundColor: theme.palette.text.primary,
    height: "120px"
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 1000,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  head__field: {
    width: 300,
    display: "flex",
    flexWrap: "wrap",
    marginTop: -10
  },
  head__search__field: {
    width: 900
  },
  background: {
    background: theme.palette.background.default
  },
  image__button: {
    marginLeft: 10
  },
  typo: {
    marginLeft: 15,
    color: theme.palette.disabled.rgba
  },
  table: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 8,
    height: "100%",
    textAlign: "center"
  },
  supplier__box: {
    padding: 15,
    marginBottom: 30,
    marginLeft: 7,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.border.main}`
  }
}));

const initData = {
  hospital: "",
  important: false,
  unit: 1,
  currentCnt: 0,
  optCnt: 0,
  alert: false,
  editDate: getTimestamp(),
  editorID: "",
  canExpire: true,
  expire: {
    expireCount: 0,
    expireDate: getTimestamp()
  },
  binCnt: 0,
  barcode: false,
  isAppropriate: false,
  images: []
};

const PurchaseCheckDialog = props => {
  const intl = useIntl();

  const {
    open,
    onClose,
    selectedProduct,
    imageList = [],
    vendorList = []
  } = props;

  const classes = useStyles();
  const tableClasses = tableStyles();

  const [alertOpen, setAlertOpen] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);

  // const { dispatchDBVendor } = useContext(DBVendorContext);
  const [message, setMessage] = useState("");

  const { schema } = useQuoteValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);

  const heads = [
    {
      key: "",
      label: (
        <CCTypography>{intl.formatMessage({ id: "rawNumber" })}</CCTypography>
      ),
      width: 100,
      component: ({ rowData, rowIndex }) => {
        return <CCTypography>{rowIndex + 1}</CCTypography>;
      }
    },
    {
      key: "name",
      label: (
        <CCTypography>{intl.formatMessage({ id: "productName" })}</CCTypography>
      ),
      width: 600,
      component: ({ cellData }) => {
        return <CCTypography>{cellData}</CCTypography>;
      }
    },
    {
      key: "manufacturer",
      label: (
        <CCTypography>
          {intl.formatMessage({ id: "manufacturer" })}
        </CCTypography>
      ),
      width: 200,
      component: ({ cellData }) => {
        return <CCTypography>{cellData}</CCTypography>;
      }
    },
    {
      key: "unitPrice",
      label: (
        <CCTypography>
          {intl.formatMessage({ id: "pricePerUnit" })}
        </CCTypography>
      ),
      width: 150,
      component: ({ cellData }) => {
        return <CCTypography>{cellData}</CCTypography>;
      }
    },
    {
      key: "count",
      label: <CCTypography>{intl.formatMessage({ id: "qty" })}</CCTypography>,
      width: 150,
      component: ({ cellData }) => {
        return (
          <TableItem
            label={<CCTypography>{cellData ? cellData : 0}</CCTypography>}
          />
        );
      }
    },
    {
      key: "",
      label: (
        <CCTypography>
          {intl.formatMessage({
            id: "payAmount"
          })}
        </CCTypography>
      ),
      width: 150,
      component: ({ rowData }) => {
        return (
          <CCTypography>
            {Number(rowData?.unitPrice) * Number(rowData?.count)}
          </CCTypography>
        );
      }
    }
  ];

  return (
    <>
      <FormProvider {...methods}>
        <CCDialog open={open} className={classes.root} onClose={onClose}>
          <CCDialogTitle onCloseButton={onClose}>
            <Grid item>
              <Box display="flex" flexDirection="row">
                <CCTypography variant="h3">
                  {/*TODO:ADD LOCALE*/}
                  Confirm Order
                  {/*  intl.formatMessage({id:"confirmOrderRequest"})*/}
                </CCTypography>
              </Box>
            </Grid>
          </CCDialogTitle>
          <CCDialogContent
            className={classes.background}
            noPadding={false}
            style={{ overflow: "hidden" }}
          >
            {selectedProduct?.length > 0 &&
              selectedProduct.map(item => (
                <Box className={classes.supplier__box}>
                  <Box className={classes.root}>
                    <CVTable
                      classes={tableClasses}
                      className={classes.table}
                      title={
                        <Grid container>
                          <Grid item>
                            <CCTypography variant="h5" component={"span"}>
                              {intl.formatMessage({ id: "requestProduct" })}
                            </CCTypography>
                          </Grid>
                        </Grid>
                      }
                      heads={heads}
                      contents={item}
                    />
                  </Box>
                  <Box>
                    <CVTextField
                      name={"message"}
                      className={classes.head__search__field}
                      permission={false}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({ id: "requestList" })}
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      rows="4"
                      multiline={true}
                    />
                  </Box>
                </Box>
              ))}
          </CCDialogContent>

          <Box
            style={{
              overflowY: "auto",
              padding: 10,
              display: "flex",
              justifyContent: "flex-end"
            }}
            marginLeft={"auto"}
            className={classes.icon__end}
          >
            <>
              <CCButton
                className={classes.cancel__icon}
                variant={"text"}
                color={"normal"}
                onClick={onClose}
              >
                {intl.formatMessage({ id: "cancel" })}
              </CCButton>
              <CCButton
                variant={"contained"}
                color={"primary"}
                startIcon={<CalculationIcon />}
                onClick={() => {
                  try {
                    axios({
                      method: "POST",
                      url: `${process.env.REACT_APP_PAM_URL}/api/v1/order`,
                      headers: {
                        Authorization:
                          "Bearer " + sessionStorage.getItem("jwtToken")
                      },
                      data: {
                        type: "Order",
                        materialId: vendorList,
                        requestMessage: message,
                        items: selectedProduct[0].map(e => {
                          return {
                            category1: e?.category1,
                            category2: e?.category2,
                            name: e?.name,
                            unit1: e?.unit1,
                            unit2: e?.unit2,
                            unitPrice: e?.unitPrice,
                            //FIXME
                            count: e?.count !== 0 ? e?.count : 1
                          };
                        }),
                        images: imageList
                      }
                    })
                      .then(async response => {
                        console.log(response);
                      })
                      .catch(err => {
                        console.error(err);
                      });

                    // dispatchDBVendor();
                    onClose();
                  } catch (e) {
                    console.error(e);

                    onClose();
                    // setError(e);
                  }
                }}
              >
                {intl.formatMessage({ id: "pamOrder" })}
              </CCButton>
              <CCDialogAlert
                contents={
                  <Box className={classes.root}>
                    <CCTypography>
                      {intl.formatMessage({ id: "registerProduct" })}
                      <CCIconButton color={"secondary"} variant={"contained"}>
                        <BringInIcon />
                      </CCIconButton>
                      {intl.formatMessage({ id: "clickBtn" })}
                    </CCTypography>
                  </Box>
                }
                open={alertOpen}
                onClose={setCloseAlert}
                onAgree={setCloseAlert}
                onDisagree={setCloseAlert}
              />
              <CCDialogAlert
                contents={
                  <CCTypography>
                    {intl.formatMessage({ id: "registerProduct" })}
                    <CCIconButton color={"secondary"} variant={"contained"}>
                      <BringInIcon />
                    </CCIconButton>
                    {intl.formatMessage({ id: "registerInputContent" })}
                  </CCTypography>
                }
                open={saveAlertOpen}
                onClose={setCloseSaveAlert}
                onAgree={setCloseSaveAlert}
                onDisagree={setCloseSaveAlert}
              />
            </>
          </Box>
        </CCDialog>
      </FormProvider>
    </>
  );
};

export default PurchaseCheckDialog;
