/*eslint react-hooks/exhaustive-deps: 0*/
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CCTextField } from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import { CCFlattenDateField } from "styles/src/components/CCFlattenDateTimePicker";
import CCPaper from "styles/src/components/CCPaper";
import CCTypography from "styles/src/components/CCTypography";
import { AddIcon, SearchIcon } from "styles/src/themes/common/icons";
import {
  getCustomFormat,
  getEndTimestamp,
  getMomentFormatTimestamp
} from "utils/datetime";
import { useIntl } from "utils/language";
import { Box, FormControlLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeList } from "utils/type";
import {
  CVButton,
  Loading,
  PermissionBackdrop,
  RadioGroup,
  useReadPermission
} from "components";
import { ALL, ESTIMATION_STATUS } from "types/inventory";
import axios from "axios";
import {
  EstimateCreateDialog,
  EstimateResults
} from "views/Normal/InventoryManagement/Component/Purchase/Component/EstimationTab/Component";
import { DBVendorContext } from "context/DBContext/components";

const useStyle = makeStyles(theme => ({
  init: {
    position: "relative",
    height: "calc(100% - 70px)",
    padding: 16,
    backgroundColor: theme.palette.common.white
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: 8,
    marginBottom: 16,
    minWidth: 1400
  },
  input: {
    backgroundColor: theme.palette.background.paper
  },
  timeInput: {
    width: 130
  },
  selectInput: {
    width: 142
  },
  memoInput: {
    width: 236
  },
  marginLeft8: {
    marginLeft: 8
  },
  description: {
    display: "flex",
    marginLeft: "10px",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px"
  },
  paper: {
    width: "100%",
    height: props => {
      if (props?.collapse && props?.collapseRef?.current) {
        return `calc(100% - ${props?.collapseRef?.current.offsetHeight}px)`;
      } else {
        return `calc(100% - 110px)`;
      }
    },
    minWidth: 1000
  },
  select__field: {
    marginLeft: 20,
    width: 200
  },
  small__button: {
    "&:hover": {
      backgroundColor: theme.palette.select.main
    }
  },
  selected: {
    backgroundColor: theme.palette.select.main,
    cursor: "default"
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  border: {
    display: "flex",
    marginLeft: "10px",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px"
  }
}));

const initSelectedValue = {
  major: [],
  minor: null,
  name: "",
  alarm: null,
  stock: false,
  status: "0",
  noStock: {}
};

const PERMISSION = "inventoryHistory";

const EstimationTab = () => {
  const classes = useStyle();
  // const permission =  useReadPermission(PERMISSION);
  const permission =  true;
  const dbVendorContext = useContext(DBVendorContext);
  const [selectedValue, setSelectedValue] = useState(initSelectedValue);
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState([]);
  const [vendor, setVendor] = useState([]);

  const [hasNext, setHasNext] = useState(false);
  const [lastKey, setLastKey] = useState(0);
  const [filter, setFilter] = useState({});
  const [estimateDialog, setEstimateDialog] = useState(false);

  const [date, setDate] = useState({
    begin: moment()
      .subtract(1, "month")
      .add(1, "day")
      .unix(),
    end: getEndTimestamp("day")
  });

  const intl = useIntl();
  const ALL_SELECTION = intl.formatMessage({ id: "all" });

  const InoutStock = makeList(ESTIMATION_STATUS);
  const All = makeList(ALL);

  const fetchSupplier = useCallback(async () => {
    try {
      setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_PAM_URL
        }/api/v1/clinic/${sessionStorage.getItem("clinicId")}/material`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          if (response?.data?.length > 0) {
            setPartner(response?.data);
          }
        })

        .catch(err => {});
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  const searchVendor = async props => {
    let variables = {
      offset: 0,
      limit: 15,
      type: "Quote"
    };

    if (props) {
      variables.status = props.args.status;
      variables.startDate = props.args.startDate;
      variables.endDate = props.args.endDate;

      if (props.args.keyword) {
        variables.keywordType = props.args.keywordType;
        variables.keyword = props.args.keyword;
      }
      variables.offset = props.lastKey;
    }

    try {
      setLoading(true);

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_PAM_URL}/api/v1/order/list`,
        data: variables,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          console.log(
            "LASTKEY>>",
            lastKey,
            "RESPONSE>>",
            response?.data?.rows?.map(e => e.status)
          );
          if (response?.data?.count > vendor?.length) {
            setVendor(prevState =>
              prevState
                ? prevState.concat(response?.data?.rows)
                : response?.data?.rows
            );
            setHasNext(true);
          } else {
            setHasNext(false);
          }
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const periodFilter = count => {
    return (
      date.begin ===
        moment()
          .subtract(count, "month")
          .add(1, "day")
          .unix() && date.end === getEndTimestamp("day")
    );
  };

  const onMoreContents = useCallback(() => {
    setLoading(true);
    let variables = {};
    variables.args = filter;
    variables.lastKey = lastKey;

    searchVendor(variables);
  }, [filter, lastKey]);

  useEffect(() => {
    //TODO: 검색 및 새 견적 추가 시 context 이용하여 목록 새로고침
    if (filter && dbVendorContext) {
      let variables = {};
      variables = { args: filter, lastKey: lastKey };
      searchVendor(variables);
    } else {
      searchVendor();
    }
  }, [filter, dbVendorContext]);

  useEffect(() => {
    setLastKey(vendor?.length);
  }, [vendor]);

  //dbVendorContext
  useEffect(() => {
    fetchSupplier();
    // return () => {
    //   setLoading(true);
    // };
  }, []);

  return (
    <>
      <Grid className={classes.init}>
        <CCPaper className={classes.root}>
          <Loading open={loading} zIndex={100} />
          {/* <PermissionBackdrop permission={PERMISSION} /> */}
          {permission && (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              wrap={"nowrap"}
            >
              <Grid
                item
                style={{
                  paddingLeft: 12,
                  paddingRight: 12,
                  minHeight: 54,
                  height: 54
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  style={{ height: "100%" }}
                  direction="row"
                >
                  <Grid item className={classes.item}>
                    <Box width={"200px"}>
                      <FormControlLabel
                        control={
                          <>
                            <RadioGroup
                              tabList={All}
                              value={selectedValue?.status}
                              onChange={() => {
                                setSelectedValue({
                                  ...selectedValue,
                                  stock: null,
                                  status: "0"
                                });
                              }}
                              required={true}
                              defaultValue={0}
                            />
                            <Box className={classes.border} />
                            <RadioGroup
                              tabList={InoutStock}
                              value={selectedValue?.status}
                              onChange={e => {
                                setSelectedValue({
                                  ...selectedValue,
                                  stock: null,
                                  status: e?.value
                                });
                              }}
                              required={true}
                              defaultValue={0}
                            />
                          </>
                        }
                      />
                    </Box>
                  </Grid>
                  <Box marginLeft="auto">
                    <CVButton
                      color="normal"
                      startIcon={<AddIcon />}
                      variant={"contained"}
                      onClick={() => {
                        setEstimateDialog(true);
                      }}
                    >
                      {intl.formatMessage({ id: "estimateRequest" })}
                    </CVButton>
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems={"center"} wrap={"nowrap"}>
                  <CCTypography variant={"h6"} style={{ width: "130px" }}>
                    {intl.formatMessage({ id: "inquiryPeriod" })}
                  </CCTypography>
                  <Grid item>
                    <CCFlattenDateField
                      label={intl.formatMessage({ id: "dateText" })}
                      onChange={e => {
                        setDate({
                          ...date,
                          begin: e.startOf("day").unix()
                        });
                      }}
                      value={
                        date?.begin
                          ? getMomentFormatTimestamp(date.begin, "X")
                          : false
                      }
                      className={clsx(classes.input, classes.timeInput)}
                    />
                  </Grid>
                  <Grid item>{"~"}</Grid>
                  <Grid item>
                    <CCFlattenDateField
                      label={intl.formatMessage({ id: "dateText" })}
                      onChange={e => {
                        setDate({
                          ...date,
                          end: e.endOf("day").unix()
                        });
                      }}
                      value={
                        date?.end
                          ? getMomentFormatTimestamp(date.end, "X")
                          : false
                      }
                      className={clsx(classes.input, classes.timeInput)}
                    />
                  </Grid>
                  <Grid item className={classes.marginLeft8}>
                    <CCButton
                      variant={"contained"}
                      size={"small"}
                      color={"normal"}
                      className={clsx(classes.small__button, {
                        [classes.selected]: periodFilter(1)
                      })}
                      onClick={() => {
                        setDate({
                          begin: moment()
                            .subtract(1, "month")
                            .add(1, "day")
                            .unix(),
                          end: getEndTimestamp("day")
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "monthUnit" }, { number: 1 })}
                    </CCButton>
                  </Grid>
                  <Grid item className={classes.marginLeft8}>
                    <CCButton
                      variant={"contained"}
                      size={"small"}
                      color={"normal"}
                      className={clsx(classes.small__button, {
                        [classes.selected]: periodFilter(3)
                      })}
                      onClick={() => {
                        setDate({
                          begin: moment()
                            .subtract(3, "month")
                            .add(1, "day")
                            .unix(),
                          end: getEndTimestamp("day")
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "monthsUnit" }, { number: 3 })}
                    </CCButton>
                  </Grid>
                  <Grid item className={classes.marginLeft8}>
                    <CCButton
                      variant={"contained"}
                      size={"small"}
                      color={"normal"}
                      className={clsx(classes.small__button, {
                        [classes.selected]: periodFilter(6)
                      })}
                      onClick={() => {
                        setDate({
                          begin: moment()
                            .subtract(6, "month")
                            .add(1, "day")
                            .unix(),
                          end: getEndTimestamp("day")
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "monthsUnit" }, { number: 6 })}
                    </CCButton>
                  </Grid>
                  <Grid item className={classes.marginLeft8}>
                    <CCButton
                      variant={"contained"}
                      size={"small"}
                      color={"normal"}
                      className={clsx(classes.small__button, {
                        [classes.selected]: periodFilter(12)
                      })}
                      onClick={() => {
                        setDate({
                          begin: moment()
                            .subtract(1, "year")
                            .add(1, "day")
                            .unix(),
                          end: getEndTimestamp("day")
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "monthsUnit" }, { number: 12 })}
                    </CCButton>
                  </Grid>
                  <Box className={clsx(classes.description, classes.divider)} />
                  <Grid
                    container
                    alignItems={"center"}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <CCTextField
                      className={classes.select__field}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({ id: "partner" })}
                      select
                      value={selectedValue?.vendor}
                      onChange={e => {
                        setSelectedValue({
                          ...selectedValue,
                          vendor: e.target.value
                        });
                      }}
                    >
                      <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                        {ALL_SELECTION}
                      </MenuItem>
                      {partner?.length > 0 &&
                        partner.map(e => (
                          <MenuItem key={e} value={e}>
                            {e.name}
                            {/*{intl.formatMessage({ id: e })}*/}
                          </MenuItem>
                        ))}
                    </CCTextField>
                    <CCTextField
                      style={{ width: "350px" }}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({
                        id: "searchProductwithKeyword"
                      })}
                      value={selectedValue?.name}
                      onChange={e => {
                        setSelectedValue({
                          ...selectedValue,
                          name: e.target.value
                        });
                      }}
                    />

                    <Grid item className={classes.marginLeft8}>
                      <CCButton
                        style={{
                          height: 36,
                          paddingTop: 0,
                          paddingBottom: 0,
                          width: 110
                        }}
                        variant={"contained"}
                        color={"secondary"}
                        startIcon={<SearchIcon />}
                        onClick={() => {
                          setLastKey(0);
                          setVendor([]);

                          let _fixed =
                            selectedValue?.status !== "0"
                              ? {
                                  status:
                                    selectedValue?.status === "1"
                                      ? "Quote"
                                      : selectedValue?.status === "2"
                                      ? "Quoted"
                                      : null,
                                  startDate: getCustomFormat(
                                    date?.begin,
                                    "X",
                                    "YYYY-MM-DD"
                                  ),
                                  endDate: getCustomFormat(
                                    date?.end,
                                    "X",
                                    "YYYY-MM-DD"
                                  )
                                }
                              : {
                                  startDate: getCustomFormat(
                                    date?.begin,
                                    "X",
                                    "YYYY-MM-DD"
                                  ),
                                  endDate: getCustomFormat(
                                    date?.end,
                                    "X",
                                    "YYYY-MM-DD"
                                  )
                                };

                          if (_fixed) {
                            setFilter(
                              selectedValue?.name
                                ? {
                                    ..._fixed,
                                    keywordType: "itemName",
                                    keyword: selectedValue?.name
                                  }
                                : _fixed
                            );
                          }
                        }}
                      >
                        {intl.formatMessage({ id: "search" })}
                      </CCButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CCPaper>
        <CCPaper className={classes.paper}>
          <EstimateResults
            result={vendor}
            onMoreContents={() => onMoreContents && onMoreContents()}
            hasNext={hasNext}
          />
        </CCPaper>
        {Boolean(estimateDialog) && (
          <EstimateCreateDialog
            open={estimateDialog}
            onClose={() => setEstimateDialog(false)}
          />
        )}
      </Grid>
      )} }
    </>
  );
};

export default EstimationTab;
