import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVFlattenDateField, CVTable } from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CCPaper, CCTextFieldNumber, CCTypography } from "styles/src/components";
import { getMomentFormatTimestamp } from "utils/datetime";
import { useIntl } from "utils/language";

const head__height = 10;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "650px"
  },
  paper: {
    position: "relative",
    height: "inherit",
    width: "inherit"
  },
  expire_table: {
    marginTop: "10px",
    height: "150px"
  },
  container: {
    height: head__height,
    padding: 10
  },
  mouse__hover: {
    cursor: "pointer"
  },
  table: {
    height: `calc(100% - ${head__height}px + 1px)`,
    position: "relative"
  },
  date__field: {
    width: 150
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  }
}));

const ExpireTable = props => {
  const intl = useIntl();
  const { expire, currentCnt, value, onChange } = props;
  const classes = useStyles();
  const permission = "desk";
  const [total, setTotal] = useState(currentCnt);

  const onModify = useCallback(
    row => {
      const { option, rowIndex, e } = row;
      const counts = value.map(item => item?.expireCount);
      const dates = value.map(item => item?.expireDate);
      const ModMap = [];

      for (let i = 0; i < value.length; i++) {
        if (i === rowIndex) {
          if (option) {
            dates[rowIndex] = e;
          } else {
            counts[rowIndex] = e;
          }
        }
        ModMap.push({
          expireDate: dates[i],
          expireCount: counts[i]
        });
      }

      onChange(ModMap);
    },
    [onChange, value]
  );

  const heads = useMemo(() => {
    return [
      {
        key: "expireDate",
        value: "expireDate",
        width: 180,
        component: ({ rowIndex }) => (
          <CVFlattenDateField
            label={intl.formatMessage({ id: "expireDate" })}
            permission={permission}
            margin={"dense"}
            variant={"outlined"}
            value={getMomentFormatTimestamp(value[rowIndex].expireDate, "X")}
            onChange={e => onModify({ option: true, rowIndex, e })}
            disabled={true}
          />
        )
      },
      {
        key: "expireCount",
        value: "expireCount",
        width: 150,
        component: ({ rowIndex }) => (
          <CCTextFieldNumber
            label={intl.formatMessage({ id: "inventoryCount" })}
            number={value[rowIndex].expireCount}
            decimalPoint={0}
            step={1}
            onChange={e => onModify({ option: false, rowIndex, e })}
            disabled={true}
          />
        )
      }
    ];
  }, [intl, onModify, value]);

  useEffect(() => {
    if (Boolean(value?.length > 0)) {
      setTotal(
        Number(
          value
            .map(e => e.expireCount)
            .reduce(
              (acc, cur) => (cur > 0 ? Number(acc) + Number(cur) : Number(acc)),
              0
            )
        )
      );
    }
  }, [value, currentCnt, total, expire]);

  return (
    <Box className={clsx(classes.root)}>
      <CCPaper className={clsx(classes.paper)}>
        <Grid
          container
          alignItems="center"
          className={clsx(classes.container)}
        >
          <CCTypography variant={"h4"}>
            {intl.formatMessage({ id: "expireDateSetting" })}
          </CCTypography>
        </Grid>

        <Box className={clsx(classes.table)}>
          <CVTable
            permission={permission}
            heads={heads.map(item => ({ ...item, className: classes.cell }))}
            contents={value}
            className={classes.expire_table}
          />
        </Box>
      </CCPaper>
    </Box>
  );
};
export default ExpireTable;
