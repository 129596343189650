function _getPermission(data, category) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].category === category) {
      return data[i];
    }
    if (data[i].items) {
      let _temp = _getPermission(data[i].items, category);
      if (_temp) {
        return _temp;
      }
    }
  }
  return null;
}
function getPermission(data, group, category) {
  return data[data.findIndex(item => item.category === group)]?.data
    ? _getPermission(
        data[data.findIndex(item => item.category === group)].data,
        category
      )
    : null;
}

function _updateLowerDepthPermission(data, permission) {
  for (let i = 0; i < data.length; i++) {
    if (Boolean(data[i]?.permission)) {
      data[i].permission = {
        read: permission.read,
        write: permission.write
      };
    }
    if (data[i].items) {
      _updateLowerDepthPermission(data[i].items, permission);
    }
  }
}

function _updatePermission(target, permission, category) {
  for (let i = 0; i < target.length; i++) {
    if (target[i].category === category) {
      target[i].permission = {
        read: permission.read,
        write: permission.write
      };
      if (target[i].items) {
        _updateLowerDepthPermission(target[i].items, permission);
      }
      continue;
    }
    if (target[i].items) {
      _updatePermission(target[i].items, permission, category);
    }
  }
  return target;
}
function updatePermission(target, permission, group, category) {
  let _target = [...target];
  let _temp = null;
  if (_target[_target.findIndex(item => item.category === group)]?.data) {
    _temp = _updatePermission(
      _target[_target.findIndex(item => item.category === group)].data,
      permission,
      category
    );
    _target[_target.findIndex(item => item.category === group)].data = _temp;
    return _target;
  } else {
    return null;
  }
}

export { getPermission, updatePermission };
