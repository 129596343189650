import React from "react";

const HueIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M11.488 17.967v4.008c-2.385-.117-4.55-1.07-6.209-2.571l2.834-2.835c.926.784 2.093 1.292 3.375 1.398zm4.374-1.398l2.834 2.835c-1.659 1.501-3.824 2.454-6.208 2.571v-4.008c1.28-.105 2.448-.614 3.374-1.398zm-8.456-.707l-2.835 2.834C3.07 17.037 2.117 14.872 2 12.488l4.008-.001c.106 1.281.614 2.449 1.398 3.375zm11.998 2.834l-2.835-2.834c.784-.926 1.293-2.093 1.398-3.374h4.008c-.117 2.384-1.07 4.549-2.57 6.208zm2.571-7.208h-4.008c-.106-1.282-.614-2.449-1.398-3.375l2.835-2.834c1.501 1.659 2.454 3.824 2.571 6.209zM4.572 5.279l2.835 2.834c-.784.926-1.293 2.093-1.399 3.374H2c.117-2.384 1.07-4.549 2.572-6.208zM11.488 2v4.008c-1.281.106-2.448.614-3.374 1.398L5.279 4.572C6.938 3.07 9.103 2.117 11.488 2zm7.208 2.571l-2.834 2.835c-.926-.784-2.093-1.292-3.374-1.398V2c2.384.117 4.549 1.07 6.208 2.571z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default HueIcon;
