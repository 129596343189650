import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { CCIconButton, CCSearchTextField, CCTable } from "../";
import { useIntl } from "../../../locales/language";
import { AddIcon, SettingIcon } from "../../themes/common/icons";

const toolbarHeight = 52;
const useStyles = makeStyles(theme => ({
  root: { minWidth: 400, height: "100%" },
  adornedEnd: { paddingRight: 8 },
  icon__btn: { borderRadius: 50 },
  textfield: { width: 200 },
  toolbar__container: {
    height: toolbarHeight,
    padding: "0 8px",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__container: { height: `calc(100% - ${toolbarHeight}px)` },
  table__container__item: { height: "100%", width: "100%" }
}));

const CCTableToolbar = props => {
  const {
    className,
    onSearch,
    onUpClick,
    onDownClick,
    onSettingClick,
    onAddClick,
    customComponent,
    disableUpClick = false,
    disableDownClick = false,
    disableAddClick = false,
    disableSettingClick = false,
    onClearClick,
    ...others
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const inputRef = useRef();

  const component = props => {
    if (customComponent) return customComponent(props);
    return <CCTable {...props} />;
  };

  const handleSearchClick = () => {
    onSearch && onSearch(inputRef.current.value);
  };

  const handleDownClick = () => {
    onDownClick && onDownClick();
  };

  const handleUpClick = () => {
    onUpClick && onUpClick();
  };

  const handleSettingClick = () => {
    onSettingClick && onSettingClick();
  };

  const handleAddClick = () => {
    onAddClick && onAddClick();
  };

  const handleClearClick = () => {
    onClearClick && onClearClick();
    inputRef.current.value = "";
  };

  return (
    <Box className={clsx(className, classes.root)}>
      <Grid
        container
        justifyContent="space-between"
        className={classes.toolbar__container}
      >
        <Grid item>
          <CCSearchTextField
            placeholder={intl.formatMessage({
              id: "ccSearch"
            })}
            className={classes.textfield}
            variant="outlined"
            margin="dense"
            inputRef={inputRef}
            onKeyPress={e => {
              if (e.key === "Enter") {
                handleSearchClick();
              }
            }}
            onIconClick={handleSearchClick}
            onClear={handleClearClick}
          />
        </Grid>
        <Grid item>
          {onDownClick && (
            <CCIconButton
              disabled={disableDownClick}
              className={classes.icon__btn}
              onClick={handleDownClick}
            >
              <KeyboardArrowDownIcon />
            </CCIconButton>
          )}
          {onUpClick && (
            <CCIconButton
              disabled={disableUpClick}
              className={classes.icon__btn}
              onClick={handleUpClick}
            >
              <KeyboardArrowUpIcon />
            </CCIconButton>
          )}
          {onSettingClick && (
            <CCIconButton
              disabled={disableSettingClick}
              className={classes.icon__btn}
              onClick={handleSettingClick}
            >
              <SettingIcon />
            </CCIconButton>
          )}
          {onAddClick && (
            <CCIconButton
              disabled={disableAddClick}
              className={classes.icon__btn}
              onClick={handleAddClick}
            >
              <AddIcon />
            </CCIconButton>
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.table__container}>
        <Grid item className={classes.table__container__item}>
          {component(others)}
        </Grid>
      </Grid>
    </Box>
  );
};

CCTableToolbar.propTypes = {
  onSearch: PropTypes.func,
  onDownClick: PropTypes.func,
  onUpClick: PropTypes.func,
  onSettingClick: PropTypes.func,
  onAddClick: PropTypes.func
};

export default CCTableToolbar;
