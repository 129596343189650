import React, { useEffect, useContext, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import WithRouter from "./components/WithRouter";
import { AppContext, RouteContext } from "context";
import { pages as commonRoute } from "./views/Common/Route";
import { pages as normalRoute } from "./views/Normal/Route";
import { getFlatPermission } from "types/permission";
import gql from "graphql-tag";
import moment from "moment";
import {
  createMuiTheme,
  jssPreset,
  StylesProvider
} from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { create } from "jss";
import rtl from "jss-rtl";

const GET_LOGIN_STAFF = gql`
  query getLoginStaff {
    me {
      id
      staffName
      # permission
      isChiefDirector 
      permissionSetting {
        id
        category
        data {
          id
          permission {
            write
            read
          }
          category
          items {
            id
            category
            permission {
              read
              write
            }
            items {
              id
              category
              permission {
                read
                write
              }
              items {
                id
                category
                freeze
                permission {
                  read
                  write
                }
              }
            }
          }
        }
      }
      staffId
    }
  }
`;

function App() {
  const { appContext, dispatchApp } = useContext(AppContext);
  const pages = [...commonRoute, ...normalRoute];
  const { dispatchRoute } = useContext(RouteContext);
  const isEgyptRegion = process.env.REACT_APP_LANGUAGE_REGION === 'ar'
  const currentLang = localStorage.getItem("lang") || process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
  if (!localStorage.getItem("lang")) {
    dispatchApp({ type: "language", target: (process.env.REACT_APP_DEFAULT_LANGUAGE || "en") });
  }
  const longDateFormats = {
    en: "DD/MM/YYYY",
    vi: "DD/MM/YYYY",
    ko: "YYYY/MM/DD",
    ru: "DD.MM.YYYY",
    ar: "YYYY/MM/DD"
  };
  const hourMinutesFormat = {
    en: isEgyptRegion ? "hh:mm A": "HH:mm",
    vi: "HH:mm",
    ru: "HH:mm",
    ko: "A h:mm",
    ar: "hh:mm A"
  };

  const LLLFormat = {
    en: "DD/MM/YYYY" + (isEgyptRegion ? " hh:mm A": " HH:mm"),
    vi: "DD/MM/YYYY HH:mm",
    ru: "DD.MM.YYYY HH:mm",
    ko: "YYYY/MM/DD A h:mm",
    ar: "YYYY/MM/DD hh:mm A"
  };

  Object.keys(longDateFormats).forEach(key => {
    const momentConfig = {
      longDateFormat: {
        L: longDateFormats[key],
        LT: hourMinutesFormat[key],
        LLL: LLLFormat[key]
      }
    };
    // if (currentLang === 'ar') {
    //   momentConfig.postformat = string => string
    // }
    moment.locale(key, momentConfig);
  });

  moment.locale(currentLang);

  useQuery(GET_LOGIN_STAFF, {
    variables: {
      hospitalId: localStorage.getItem("labId"),
      id: appContext.token
    },
    onCompleted: data => {
      if (data?.me?.permissionSetting) {
        const permission = getFlatPermission(data.me.permissionSetting.data);
        dispatchApp({ type: "permission", target: permission });
      }
      dispatchApp({ type: "me", target: data.me });

      dispatchApp({ type: "ready", target: true });
    },
    skip: !appContext.token
  });

  const onMessageReceivedFromIframe = useCallback(
    event => {
      if (
        event.data &&
        event.data.source === "clboard" &&
        event.data.payload === "close"
      ) {
        dispatchApp({ type: "refresh" });
      }
    },
    [dispatchApp]
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () => {
      window.removeEventListener("message", onMessageReceivedFromIframe);
    };
  }, [onMessageReceivedFromIframe]);

  const token = (() => {
    if (!window.opener) {
      return sessionStorage.getItem("token");
    } else {
      return window.opener.sessionStorage.getItem("token");
    }
  })();

  useEffect(() => {
    if (token)
      dispatchApp({
        type: "token",
        target: token
      });
    else {
      dispatchRoute({ path: "/signin" });
    }
  }, [dispatchApp, dispatchRoute, token]);
  const theme = {
    ...appContext.theme,
  };
  const plugins = [...jssPreset().plugins];
  if (currentLang === 'ar') {
    document.body.setAttribute("dir", "rtl");
    plugins.push(rtl());
    theme.direction = "rtl";
  } else {
    document.body.setAttribute("dir", "");
  }
  const jss = create({ plugins  });

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider
        theme={createMuiTheme(theme)}
      >
        <WithRouter pages={pages} />
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
