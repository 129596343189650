import { useCallback } from "react";
import { useIntl } from "react-intl";

const getRemoteLanguageSet = async (location, isGlobalMode = true) => {
  const defaultLocation = "/locales/";
  const getRemoteLanguage = async (location = defaultLocation, endPoint) => {
    try {
      let res = await fetch(`${location}${endPoint}`);
      return await res.json();
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const _langArray = isGlobalMode
    ? await Promise.all([
        getRemoteLanguage(location, "vtStyles/ko/words.json"),
        getRemoteLanguage(location, "vtStyles/ko/log.json"),
        getRemoteLanguage(location, "vtStyles/en/words.json"),
        getRemoteLanguage(location, "vtStyles/en/log.json"),
        getRemoteLanguage(location, "vtStyles/vi/words.json"),
        getRemoteLanguage(location, "vtStyles/vi/log.json"),
        getRemoteLanguage(location, "vtStyles/ru/words.json"),
        getRemoteLanguage(location, "vtStyles/ru/log.json"),
        getRemoteLanguage(location, "vtStyles/ar/words.json"),
        getRemoteLanguage(location, "vtStyles/ar/log.json")
      ])
    : await Promise.all([
        getRemoteLanguage(location, "vtStyles/ko/words.json"),
        getRemoteLanguage(location, "vtStyles/ko/log.json"),
        getRemoteLanguage(location, "vtStyles/en/words.json"),
        getRemoteLanguage(location, "vtStyles/en/log.json")
      ]);
  return isGlobalMode
    ? {
        ko: {
          ..._langArray[0],
          ..._langArray[1]
        },
        en: {
          ..._langArray[2],
          ..._langArray[3]
        },
        vi: {
          ..._langArray[4],
          ..._langArray[5]
        },
        ru: {
          ..._langArray[6],
          ..._langArray[7]
        },
        ar: {
          ..._langArray[8],
          ..._langArray[9]
        },
        develop: {}
      }
    : {
        ko: {
          ..._langArray[0],
          ..._langArray[1]
        },
        en: {
          ..._langArray[2],
          ..._langArray[3]
        },
        develop: {}
      };
};
const ARABIC_REGEXP = /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/;
const useFindArabic = props => {
  const intl = useIntl();
  const findArabic = useCallback(
    children =>
      (typeof children === "object" || typeof children === "string") &&
      intl?.locale === "ar" &&
      !Boolean(ARABIC_REGEXP.test(children)),
    [intl]
  );
  return { findArabic };
};

// const setDigitCovertFunc = () => {
//   //English to Persian digits.
//   String.prototype.EntoFa = function () {
//     return this.replace(/\d/g, d => "۰۱۲۳۴۵۶۷۸۹"[d]);
//   };
//
//   //English to Arabic digits.
//   String.prototype.EntoAr = function () {
//     return this.replace(/\d/g, d => "٠١٢٣٤٥٦٧٨٩"[d]);
//   };
//
//   //Arabic to English digits.
//   String.prototype.ArtoEn = function () {
//     return this.replace(/[\u0660-\u0669]/g, d => d.charCodeAt() - 1632);
//   };
//
//   //Persian to English digits.
//   String.prototype.PetoEn = function () {
//     return this.replace(/[\u06F0-\u06F9]/g, d => d.charCodeAt() - 1776);
//   };
//
//   //Persian to Arabic digits.
//   String.prototype.PetoAr = function () {
//     return this.replace(
//       /[\u06F0-\u06F9]/g,
//       d => "٠١٢٣٤٥٦٧٨٩"[d.charCodeAt() - 1776]
//     );
//   };
//
//   //Arabic to Persian digits.
//   String.prototype.ArtoPe = function () {
//     return this.replace(
//       /[\u0660-\u0669]/g,
//       d => "۰۱۲۳۴۵۶۷۸۹"[d.charCodeAt() - 1632]
//     );
//   };
// };

export { getRemoteLanguageSet, useFindArabic };
