import React from "react";

const DollarruIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm.888 4.29H9.307v4.838H8.05v1.947h1.257V14.3H8.05v1.524h1.257v1.68h2.371v-1.68h2.42V14.3h-2.42v-1.225H12.7c1.09 0 1.95-.162 2.584-.487.633-.324 1.086-.753 1.359-1.288a3.717 3.717 0 0 0 .408-1.712c0-1.026-.332-1.832-.997-2.418-.621-.548-1.581-.84-2.881-.876l-.284-.004zm-.126 1.948c1.257 0 1.885.476 1.885 1.429 0 .513-.196.885-.59 1.115-.392.23-.923.346-1.593.346h-.786v-2.89h1.084z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default DollarruIcon;
