import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { InfiniteTable } from "components";
import { GET_LAB_GOLD_STORAGE_LIST } from "queries/goldManagement";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CellMeasurerCache } from "react-virtualized";
import { CCIconButton, CCTypography } from "styles/components";
import { AgreementIcon } from "styles/icons";
import LabStorageHistoryModal from "../LabStorageHistoryModal";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: 812,
    "& .ReactVirtualized__List": {
      overflowY: "overlay !important"
    }
  },
  header: {
    position: "absolute",
    display: "flex",
    zIndex: "10",
    width: "100%",
    height: 72,
    textAlign: "center"
  },
  header__cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box"
  },
  sub__header: {
    flexBasis: "20%"
  },
  fullwidth: {
    width: "100%"
  },
  halfheight: {
    height: "50%"
  },
  goldused__container: {
    display: "flex",
    flexDirection: "column"
  },
  goldused__items: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  border__cell__left: {
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  border__cell__top: {
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  border__cell__bottom: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__content: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 32
  },
  cell__center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 36
  },
  history__button: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  color__green: {
    color: "#00897b"
  }
}));

const LabStorageTable = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [header, setHeader] = useState({});
  const [labStorageHistoryModalVisible, setLabStorageHistoryModalVisible] =
    useState(false);
  const [selectedClinicId, setSelectedClinicId] = useState("");
  const [cache] = useState(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true
    })
  );

  const { data: labGoldStorageData, loading: labGoldStorageLoading } = useQuery(
    GET_LAB_GOLD_STORAGE_LIST
  );

  const heads = [
    {
      key: "no",
      width: 70,
      component: ({ rowIndex }) => {
        return <Box className={classes.cell__center}>{rowIndex + 1}</Box>;
      }
    },
    {
      key: "clinic",
      width: 194,
      component: ({ cellData }) => {
        return (
          <Box className={classes.cell__center}>
            <CCTypography
              className={clsx({
                [classes.color__green]:
                  cellData?.createdBy === "Clever" ? true : false
              })}
            >
              {cellData ? cellData.name : "-"}
            </CCTypography>
          </Box>
        );
      }
    },
    {
      key: "inlay",
      component: ({ cellData }) => {
        return (
          <Box
            className={clsx(classes.border__cell__left, classes.cell__center)}
          >
            {cellData ? cellData.toFixed(2) : "-"}
          </Box>
        );
      }
    },
    {
      key: "aType",
      component: ({ cellData }) => (
        <Box className={classes.cell__center}>
          {cellData ? cellData.toFixed(2) : "-"}
        </Box>
      )
    },
    {
      key: "sA",
      component: ({ cellData }) => {
        return (
          <Box className={classes.cell__center}>
            {cellData ? cellData.toFixed(2) : "-"}
          </Box>
        );
      }
    },
    {
      key: "pt",
      component: ({ cellData }) => {
        return (
          <Box className={classes.cell__center}>
            {cellData ? cellData.toFixed(2) : "-"}
          </Box>
        );
      }
    },
    {
      key: "pfg",
      component: ({ cellData }) => {
        return (
          <Box className={classes.cell__center}>
            {cellData ? cellData.toFixed(2) : "-"}
          </Box>
        );
      }
    },
    {
      width: 76,
      key: "history",
      component: () => (
        <Box className={clsx(classes.cell__center, classes.border__cell__left)}>
          <CCIconButton
            className={classes.history__button}
            onClick={() => {
              setLabStorageHistoryModalVisible(true);
            }}
          >
            <AgreementIcon />
          </CCIconButton>
        </Box>
      )
    }
  ];

  useEffect(() => {
    const normalizeHeader = headers => {
      const headerObj = {};
      headers.forEach(header => {
        headerObj[header.key] = header;
      });
      return headerObj;
    };

    setHeader(normalizeHeader(heads));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box
          style={{
            flex: header["no"] ? `0 0 ${header["no"]["width"]}px` : "none"
          }}
          className={clsx(classes.header__cell, classes.border__cell__top)}
        >
          {translate(intl, "labManageGold.no")}
        </Box>
        <Box
          style={{
            flex: header["clinic"]
              ? `0 0 ${header["clinic"]["width"]}px`
              : "none"
          }}
          className={clsx(classes.header__cell, classes.border__cell__top)}
        >
          {translate(intl, "labManageGold.clinic")}
        </Box>
        <Box
          className={clsx(
            classes.header__cell,
            classes.fullwidth,
            classes.goldused__container,
            classes.border__cell__top,
            classes.border__cell__left
          )}
        >
          <Box
            className={clsx(
              classes.header__cell,
              classes.border__cell__bottom,
              classes.fullwidth,
              classes.halfheight
            )}
          >
            {translate(intl, "labManageGold.gold")}
          </Box>
          <Box
            className={clsx(
              classes.goldused__items,
              classes.fullwidth,
              classes.halfheight
            )}
          >
            <Box className={classes.sub__header}>Inlay</Box>
            <Box className={classes.sub__header}>A-Type</Box>
            <Box className={classes.sub__header}>S-A</Box>
            <Box className={classes.sub__header}>PT</Box>
            <Box className={classes.sub__header}>PFG</Box>
          </Box>
        </Box>
        <Box
          style={{
            flex: header["history"]
              ? `0 0 ${header["history"]["width"]}px`
              : "none"
          }}
          className={clsx(
            classes.header__cell,
            classes.border__cell__top,
            classes.border__cell__left
          )}
        >
          <CCTypography>
            {translate(intl, "labManageGold.history")}
          </CCTypography>
        </Box>
      </Box>
      <Box className={classes.table__content}>
        {!labGoldStorageLoading && (
          <InfiniteTable
            className={classes.table}
            heads={heads}
            contents={labGoldStorageData?.goldLabStorageList?.items}
            rowClickHighlight={true}
            cache={cache}
            onRowClick={({ rowData }) => {
              setSelectedClinicId(rowData.clinic.id);
            }}
          />
        )}
      </Box>
      {labStorageHistoryModalVisible && (
        <LabStorageHistoryModal
          open={labStorageHistoryModalVisible}
          clinicId={selectedClinicId}
          onClose={() => setLabStorageHistoryModalVisible(false)}
        />
      )}
    </Box>
  );
};

export default LabStorageTable;
