import React from "react";

const CheckedIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M17.5 12.5c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5S13 19.484 13 17s2.016-4.5 4.5-4.5zm2.515 2.061L16.6 17.977l-1.615-1.611-.635.634 2.25 2.25 4.05-4.05-.634-.639zM19 1.5c1.05 0 1.918.82 1.994 1.851L21 3.5v8.627c-.984-.71-2.193-1.127-3.5-1.127-3.314 0-6 2.686-6 6 0 .169.007.336.02.5H3c-1.05 0-1.918-.82-1.994-1.851L1 15.5l.01-12c0-1.05.81-1.918 1.841-1.994L3 1.5h16zm0 2l-8 5-8-5v2l8 5 8-5v-2z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default CheckedIcon;
