import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useReducer } from "react";
import { CCTextFieldDuo } from "styles/components";
import CCTextFieldDualType from "styles/src/components/CCTextFieldDualType";

const EmailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*(\.[a-zA-Z]{2,})$/i;

const domainList = [
  {
    value: "naver.com",
    label: "naver.com"
  },
  {
    value: "daum.net",
    label: "daum.net"
  },
  {
    value: "gmail.com",
    label: "gmail.com"
  },
  {
    value: "kakao.com",
    label: "kakao.com"
  }
];

const initialiseState = {
  emailAddress: "",
  emailDomain: "",
  error: false,
  directInputState: false
};

const reducer = (state, action) => {
  let email;
  switch (action.type) {
    case "INIT":
      return initialiseState;

    case "SET_EMAIL":
      email = action.email;
      if (EmailRegExp.test(email)) {
        return {
          ...state,
          emailAddress: email.split("@")[0],
          emailDomain: email.split("@")[1],
          directInputState: !domainList
            .map(item => item.value)
            .includes(email.split("@")[1]),
          emailError: false
        };
      } else {
        return {
          ...state,
          emailAddress: "",
          emailDomain: "",
          emailError: false,
          directInputState: false
        };
      }

    case "CHANGE_ADDRESS":
      email = `${action.value}@${state.emailDomain}`;
      if (EmailRegExp.test(email)) {
        return {
          ...state,
          emailError: false,
          emailAddress: action.value
        };
      } else {
        return {
          ...state,
          emailError: true,
          emailAddress: action.value
        };
      }
    case "CHANGE_DOMAIN":
      email = `${state.emailAddress}@${action.value}`;
      if (EmailRegExp.test(email)) {
        return {
          ...state,
          emailDomain: action.value,
          emailError: false,
          directInputState: !action.select
        };
      } else {
        return {
          ...state,
          emailDomain: action.value,
          emailError: true,
          directInputState: !action.select
        };
      }
    case "CHECK_ERROR":
      if (!state.emailAddress && !state.emailDomain) {
        return {
          ...state,
          emailError: false
        };
      }
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const EmailField = props => {
  const {
    margin,
    variant,
    emailClassName,
    domainClassName,
    emailLabel,
    domainLabel,
    onChange,
    errorOnChange,
    value,
    required,
    error: _,
    ...others
  } = props;

  // value
  const [state, dispatch] = useReducer(reducer, initialiseState);
  const { directInputState, emailAddress, emailDomain, emailError } = state;

  // function
  const onChangeAddress = useCallback(e => {
    dispatch({
      type: "CHANGE_ADDRESS",
      value: e.target.value
    });
  }, []);
  const onChangeDomain = useCallback(e => {
    dispatch({
      type: "CHANGE_DOMAIN",
      value: e.target.value,
      select: e.target.select
    });
  }, []);
  const handleOnChange = () => {
    if (
      emailAddress &&
      emailDomain &&
      EmailRegExp.test(`${emailAddress}@${emailDomain}`)
    ) {
      onChange({ target: { value: `${emailAddress}@${emailDomain}` } });
    } else if (!emailAddress && !emailDomain) {
      onChange({ target: { value: "" } });
      dispatch({ type: "CHECK_ERROR" });
    }
  };

  // useEffect
  useEffect(() => {
    if (value) {
      dispatch({ type: "SET_EMAIL", email: value });
    } else {
      dispatch({ type: "INIT" });
    }
  }, [value]);
  useEffect(() => {
    errorOnChange(emailError);
  }, [emailError, errorOnChange]);

  return (
    <Grid container wrap={"nowrap"}>
      <CCTextFieldDuo
        LeftProps={{
          className: emailClassName,
          error: emailError,
          margin: margin,
          variant: variant,
          label: emailLabel,
          required: required,
          InputProps: {
            style: { imeMode: "disabled" }
          },
          onBlur: handleOnChange,
          value: emailAddress,
          onChange: onChangeAddress
        }}
        RightProps={{
          component: (
            <CCTextFieldDualType
              style={{ margin: "8px 0" }}
              error={emailError}
              margin={margin}
              required={required}
              variant={variant}
              label={domainLabel}
              onBlur={handleOnChange}
              directinput={directInputState.toString()}
              value={emailDomain}
              onChange={onChangeDomain}
              InputProps={{
                className: domainClassName
              }}
              children={domainList.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            />
          )
        }}
        {...others}
      />
    </Grid>
  );
};

EmailField.defaultProps = {
  margin: "dense",
  variant: "outlined",
  emailLabel: "이메일",
  domainLabel: "도메인",
  errorOnChange: () => {},
  value: "",
  error: false
};

EmailField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default EmailField;
