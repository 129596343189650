import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DialogWarningMessage } from "components";
import React, { useCallback, useState } from "react";
import { CCTypography } from "styles/components";
import { BringInIcon } from "styles/icons";
import {
  CCDialog,
  CCDialogAlert,
  CCDialogTitle,
  CCIconButton
} from "styles/src/components";
import { getTimestamp } from "utils/datetime";
import { useIntl } from "utils/language";
import classnames from 'classnames';
import BatchRegistration from "./components/BatchRegistration/BatchRegistration";
import IndividualRegistration from "./components/IndividualRegistration";

const useStyles = makeStyles(theme => ({

  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 900,
      display: "inline-block"
    }
  },
  dialogPaperContainer: {
    minWidth: 1200
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  },
  radio__group: {
    padding: '0 16px'
  }
}));

const ProductCreateDialog = props => {
  const intl = useIntl();

  const { open, onClose, refetchGetProductList } = props;

  const classes = useStyles();

  const [alertOpen, setAlertOpen] = useState(false);
  const [typeRegister, setTypeRegister] = useState("0");
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const [expireD, setExpireD] = useState({
    expireDate: getTimestamp(),
    expireCount: 0
  });
  const [cancelDialog, setCancelDialog] = useState(false);
  const [alrDialog, setAlrDialog] = useState(false);


  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);



  const onChangeTypeRegister = (e) => {
    setTypeRegister(e.target.value)
  }

  const onClosure = () => {
    setCancelDialog({
      title: intl.formatMessage(
        { id: "confirmCancelwritingInfo" },
        { br: () => <br /> }
      ),
      onClose: () => {
        setCancelDialog(null);
      },
      onDisagree: () => {
        setCancelDialog(null);
      },
      onAgree: () => {
        onClose && onClose();
      }
    });
  };

  return (
    <>
      <CCDialog open={open} maxWidth="xl" className={classes.root} onClose={onClosure} classes={{paperWidthXl: classnames({[classes.dialogPaperContainer]: typeRegister === "1"})}}>
        <CCDialogTitle onCloseButton={onClosure}>
          <CCTypography variant="h3">
            {intl.formatMessage({ id: "registerProduct" })}
          </CCTypography>
        </CCDialogTitle>


        <RadioGroup
          row
          value={typeRegister}
          onChange={onChangeTypeRegister}
          className={classes.radio__group}
        >
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={intl.formatMessage({ id: "individualRegistration" })}
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label={intl.formatMessage({ id: "batchRegistration" })}
          />
        </RadioGroup>
        {typeRegister === '0' ?
          <IndividualRegistration onCancel={onClosure} onClose={onClose} refetchGetProductList={refetchGetProductList} />
          : <BatchRegistration onCancel={onClosure} onClose={onClose} refetchGetProductList={refetchGetProductList} />
        }
      </CCDialog>
      <CCDialogAlert
        contents={
          <Box className={classes.root}>
            <CCTypography>
              {intl.formatMessage({ id: "registerProduct" })}
              <CCIconButton color={"secondary"} variant={"contained"}>
                <BringInIcon />
              </CCIconButton>
              {intl.formatMessage({ id: "clickBtn" })}
            </CCTypography>
          </Box>
        }
        open={alertOpen}
        onClose={setCloseAlert}
        onAgree={setCloseAlert}
        onDisagree={setCloseAlert}
      />
      <CCDialogAlert
        contents={
          <CCTypography>
            {intl.formatMessage({ id: "registerProduct" })}
            <CCIconButton color={"secondary"} variant={"contained"}>
              <BringInIcon />
            </CCIconButton>
            {intl.formatMessage({ id: "registerInputContent" })}
          </CCTypography>
        }
        open={saveAlertOpen}
        onClose={setCloseSaveAlert}
        onAgree={setCloseSaveAlert}
        onDisagree={setCloseSaveAlert}
      />
      {cancelDialog && <DialogWarningMessage open={Boolean(cancelDialog)} {...cancelDialog} />}
      {alrDialog && <DialogWarningMessage open={Boolean(alrDialog)} {...alrDialog} />}
    </>
  );
};

export default ProductCreateDialog;
