import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import { Grid, makeStyles } from "@material-ui/core";
import { CVButton } from "components";
import moment from "moment";
import {
  GOLD_UNIT_PRICE,
  UPDATE_LAB_GOLD_UNIT_PRICE
} from "queries/goldManagement";
import React, { useEffect, useReducer } from "react";
import { useIntl } from "react-intl";
import CCDialogSimple from "./CCDialogSimple";
import { CCTypography } from "styles/components";
import { SaveIcon } from "styles/icons";
import { LAB_GOLD_TYPES } from "types/goldManagement";
import { makeIndexFormattedMessageList } from "types/utils";
import { HistoryTable } from "views/Normal/GoldManagement/components";
import UnitPriceInputField from "../UnitPriceInputField";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "8px 16px 16px",
    width: 833,
    minHeight: 800
  },
  unit__price__textfield: {
    width: 92,
    backgroundColor: theme.palette.common.white
  },
  unit__price__container: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 8
  },
  table__title: {
    padding: 8,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  item_unit__price__fields: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    padding: "16px 0 16px 8px"
  },
  item__save__button: {
    margin: 16,
    alignSelf: "flex-end"
  },
  fixed__price__history__table: {
    marginTop: 16
  },
  cell__center: {
    textAlign: "center"
  }
}));

const initializer = () => {
  return {
    filter: {
      args: {
        date: {
          begin: moment().startOf("week").unix(),
          end: moment().endOf("week").unix()
        },
        goldType: ""
      }
    },
    unitPriceFields: {
      inlay: 0,
      aType: 0,
      sA: 0,
      pt: 0,
      pfg: 0
    }
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.target.filter
        }
      };
    case "changeFieldValue":
      const { field, value } = action.target;
      return {
        ...state,
        unitPriceFields: {
          ...state.unitPriceFields,
          [field]: value ? value : 0
        }
      };
    default:
      break;
  }

  return state;
};

const UnitPriceManageModal = props => {
  const { open, onClose } = props;

  const classes = useStyles();
  const intl = useIntl();
  const labGoldTypes = makeIndexFormattedMessageList(LAB_GOLD_TYPES);
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const { inlay, aType, sA, pt, pfg } = state.unitPriceFields;

  const {
    data: goldUnitPriceData,
    loading: goldUnitPriceLoading,
    refetch: goldUnitPriceRefetch
  } = useQuery(GOLD_UNIT_PRICE, {
    variables: { query: { importDate: {} } }
  });
  const [updateLabGoldUnitPriceManage] = useMutation(
    UPDATE_LAB_GOLD_UNIT_PRICE
  );

  const handleSaveLabGoldPriceSetting = () => {
    updateLabGoldUnitPriceManage({
      variables: {
        input: {
          inlay: Number(inlay),
          aType: Number(aType),
          sA: Number(sA),
          pt: Number(pt),
          pfg: Number(pfg)
        }
      }
    }).then(() =>
      goldUnitPriceRefetch({
        query: {
          importDate: {}
        }
      })
    );
  };
  const handleOnUnitPriceInputChange = type => e => {
    const indexOfFloatPoint = e.target.value.indexOf(".");

    let floatPrice = '';
    if(indexOfFloatPoint > -1) {
      floatPrice = e.target.value.substr(0, indexOfFloatPoint + 2);
    } else {
      floatPrice = e.target.value;
    }

    console.log("handleOnUnitPriceInputChange.indexOfFloatPoint", indexOfFloatPoint)
     

    dispatchState({
      type: "changeFieldValue",
      target: {
        field: type,
        value: floatPrice
      }
    });
  };
  const handleOnFilter = e => {
    dispatchState({ type: "filter", target: e });
    goldUnitPriceRefetch({
      query: {
        importDate: {
          from: e.filter.args.date.begin,
          to: e.filter.args.date.end
        },
        goldType: e.filter.args.goldType
      }
    });
  };

  useEffect(() => {
    if (!goldUnitPriceLoading && goldUnitPriceData) {
      const goldUnitPrice = goldUnitPriceData?.goldUnitPriceManageDetail;

      dispatchState({
        type: "changeFieldValue",
        target: { field: "inlay", value: goldUnitPrice?.inlay }
      });
      dispatchState({
        type: "changeFieldValue",
        target: { field: "aType", value: goldUnitPrice?.aType }
      });
      dispatchState({
        type: "changeFieldValue",
        target: { field: "sA", value: goldUnitPrice?.sA }
      });
      dispatchState({
        type: "changeFieldValue",
        target: { field: "pt", value: goldUnitPrice?.pt }
      });
      dispatchState({
        type: "changeFieldValue",
        target: { field: "pfg", value: goldUnitPrice?.pfg }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goldUnitPriceData]);

  const priceFixedHistoryHeads = [
    {
      key: "updateGoldPriceAt",
      className: classes.cell__center,
      label: translate(intl, "labManageGold.date"),
      width: 150,
      component: ({ cellData }) => (
        <CCTypography>{moment.unix(cellData).format("L")}</CCTypography>
      )
    },
    {
      key: "goldType",
      className: classes.cell__center,
      label: translate(intl, "common.ui.type"),
      width: 80,
      component: ({ cellData }) => (
        <CCTypography>{labGoldTypes[cellData]}</CCTypography>
      )
    },
    {
      key: "priceBefore",
      className: classes.cell__center,
      label: translate(intl, "labManageGold.before"),
      width: 100,
      component: ({ cellData }) => (
        <CCTypography color={"primary"}>{cellData}</CCTypography>
      )
    },
    {
      key: "priceAfter",
      className: classes.cell__center,
      label: translate(intl, "labManageGold.after"),
      width: 100,
      component: ({ cellData }) => <CCTypography>{cellData}</CCTypography>
    }
  ];

  return (
    <>
      <CCDialogSimple
        title={translate(intl, "labManageGold.unitPriceManagement")}
        open={open}
        maxWidth={"xl"}
        fullWidth={false}
        onClose={onClose}
        onCloseButton={onClose}
        contentsPadding={false}
        contents={
          <Grid container direction={"column"} className={classes.root}>
            <Grid
              container
              direction={"column"}
              className={classes.unit__price__container}
            >
              <CCTypography variant="h4" className={classes.table__title}>
                {translate(intl, "labManageGold.unitPrice")}
              </CCTypography>
              <Grid item className={classes.item_unit__price__fields}>
                <UnitPriceInputField
                  label={"Inlay"}
                  margin={"dense"}
                  value={inlay}
                  variant={"outlined"}
                  className={classes.unit__price__textfield}
                  onChange={handleOnUnitPriceInputChange("inlay")}
                />
                <UnitPriceInputField
                  label={"A-Type"}
                  margin={"dense"}
                  value={aType}
                  variant={"outlined"}
                  className={classes.unit__price__textfield}
                  onChange={handleOnUnitPriceInputChange("aType")}
                />
                <UnitPriceInputField
                  label={"S-A"}
                  margin={"dense"}
                  value={sA}
                  variant={"outlined"}
                  className={classes.unit__price__textfield}
                  onChange={handleOnUnitPriceInputChange("sA")}
                />
                <UnitPriceInputField
                  label={"PT"}
                  margin={"dense"}
                  value={pt}
                  variant={"outlined"}
                  className={classes.unit__price__textfield}
                  onChange={handleOnUnitPriceInputChange("pt")}
                />
                <UnitPriceInputField
                  label={"PFG"}
                  margin={"dense"}
                  value={pfg}
                  variant={"outlined"}
                  className={classes.unit__price__textfield}
                  onChange={handleOnUnitPriceInputChange("pfg")}
                />
              </Grid>
              <Grid item className={classes.item__save__button}>
                <CVButton
                  permission={"goldManager"}
                  startIcon={<SaveIcon />}
                  variant={"contained"}
                  color={"primary"}
                  onClick={handleSaveLabGoldPriceSetting}
                >
                  {translate(intl, "common.ui.save")}
                </CVButton>
              </Grid>
            </Grid>

            {!goldUnitPriceLoading && (
              <HistoryTable
                title={intl.formatMessage({
                  id: "labManageGold.priceFixedHistory"
                })}
                heads={priceFixedHistoryHeads}
                contents={goldUnitPriceData?.goldUnitPriceManageDetail?.history}
                filter={{
                  filter: state.filter
                }}
                styles={classes.fixed__price__history__table}
                onFilter={handleOnFilter}
              />
            )}
          </Grid>
        }
      />
    </>
  );
};

export default UnitPriceManageModal;
