import { gql } from "@apollo/client";

const SEARCH_PRODUCT = gql`
  query searchProduct(
    $def: String!
    $args: InputSearchProduct
    $comparison: String
    $match: String
  ) {
    searchProduct(
      def: $def
      args: $args
      comparison: $comparison
      match: $match
    ) {
      ... on RetProduct {
        def
        hospital
        major
        minor
        productID
        productName
        origin
        manufacture
        unit
        dvFamilyPrice
        specialPrice
        retailPrice
        material
        fixureOrder
        order
        confirm
        storage {
          hospital
          important
          unit
          currentCnt
          optCnt
          alert
          editDate
          editorID
          canExpire
          expire {
            expireDate
            expireThirtySoon
            expireCount
          }
          binCnt
          # barcode
          isAppropriate
        }
      }
    }
  }
`;

const SEARCH_PRODUCT_PAGINATION = gql`
  query searchProductPagination(
    $def: String!
    $args: InputSearchProduct
    $page: InputPage
    $comparison: String
    $match: String
  ) {
    searchProductPagination(
      def: $def
      args: $args
      page: $page
      comparison: $comparison
      match: $match
    ) {
      ... on RetSearchProductPagination {
        lastKey
        data {
          ... on RetProduct {
            def
            hospital
            major
            minor
            productID
            productName
            origin
            manufacture
            unit
            dvFamilyPrice
            specialPrice
            retailPrice
            material
            fixureOrder
            order
            confirm
            storage {
              hospital
              important
              unit
              currentCnt
              optCnt
              alert
              editDate
              editorID
              canExpire
              expire {
                expireDate
                expireThirtySoon
                expireCount
              }
              binCnt
              # barcode
              isAppropriate
            }
          }
        }
      }
    }
  }
`;

const PRODUCT_LIST = gql`
  query productList($query: SearchProductInput){
    productList(query: $query){
      items{
        id
        def
        hospital
        major
        minor
        productID
        productName
        origin
        manufacture
        unit
        dvFamilyPrice
        specialPrice
        retailPrice
        material
        fixureOrder
        order
        confirm
        QRcode
        editorID {
          staffName
          staffId
        }
        storage {
          hospital
          important
          unit
          currentCnt
          optCnt
          alert
          editDate
          editorID
          canExpire
          expire {
            expireDate
            expireThirtySoon
            expireCount
          }
          binCnt
          # barcode
          isAppropriate
        }
      }
    }
}`

const PRODUCT_DETAIL = gql`
  query productDetail($id: ID){
    productDetail(id: $id){
        id
        def
        hospital
        major
        minor
        productID
        productName
        origin
        manufacture
        unit
        dvFamilyPrice
        specialPrice
        retailPrice
        material
        fixureOrder
        order
        confirm
        # QRcode
        storage{
          hospital
          important
          unit
          currentCnt
          optCnt
          alert
          editDate
          editorID
          canExpire
          expire{
            expireDate
            expireThirtySoon
            expireCount
          }
          binCnt
          # barcode
          isAppropriate
      }
    }
}`
const PRODUCT_LOG_LIST = gql`
  query productLogList($query: SearchProductLogInput){
    productLogList(query: $query){
      items{
        def
        id
        createTime
        major
        minor
        productID
        productName
        hospital
        unit
        inOut
        binCnt
        detail
        previousAmount
        changedAmount
        changedCnt
        staffName
        remarks
        retailPrice
        expire {
            expireDate
            expireCount
          }
        manufacture
      }
    }

}`

const BATCH_GET_PRODUCT = gql`
  query batchGetProduct($def: String!, $productID: [String!]) {
    batchGetProduct(def: $def, productID: $productID) {
      ... on RetProduct {
        def
        hospital
        major
        minor
        productID
        productName
        origin
        manufacture
        unit
        dvFamilyPrice
        specialPrice
        retailPrice
        material
        fixureOrder
        order
        confirm
        storage {
          hospital
          important
          unit
          currentCnt
          optCnt
          alert
          editDate
          editorID
          canExpire
          expire {
            expireDate
            expireThirtySoon
            expireCount
          }
          binCnt
          # barcode
          isAppropriate
        }
      }
    }
  }
`;

const BATCH_REMOVE_PRODUCT = gql`
  mutation batchRemoveProduct($ids: [ID]) {
    batchRemoveProduct(ids: $ids){
      code
      message
    }
  }
`;

const BATCH_REMOVE_PRODUCT_LOG = gql`
  mutation batchRemoveProductLog($def: String!, $id: [String]) {
    batchRemoveProductLog(def: $def, id: $id)
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct(
    $input: ProductInput
  ) {
    createProduct(
      input: $input
    ) {
      id
      def
      hospital
      major
      minor
      productID
      productName
      origin
      manufacture
      unit
      dvFamilyPrice
      specialPrice
      retailPrice
      material
      fixureOrder
      order
      confirm
      # QRcode
      storage{
        hospital
        important
        unit
        currentCnt
        optCnt
        alert
        editDate
        editorID
        canExpire
        expire{
          expireDate
          expireThirtySoon
          expireCount
        }
        binCnt
        # barcode
        isAppropriate
      }
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $input: ProductInput
  ) {
    updateProduct(
      input: $input
    ) {
      code 
      message
    }
  }
`;
const UPDATE_IMPORTANT_PRODUCT = gql`
  mutation updateImportantState(
    $id: ID!
    $important: Boolean!
  ) {
    updateImportantState(
      id: $id
      important: $important
    ) {
      code 
      message
    }
  }
`;

const REMOVE_PRODUCT = gql`
  mutation removeProduct($def: String!, $productID: String!) {
    removeProduct(def: $def, productID: $productID) {
      ... on RetProduct {
        def
        hospital
        major
        minor
        productID
        productName
        origin
        manufacture
        unit
        dvFamilyPrice
        specialPrice
        fixureOrder
        retailPrice
        material
        order
        confirm
        storage {
          hospital
          important
          unit
          currentCnt
          optCnt
          alert
          editDate
          editorID
          canExpire
          expire {
            expireDate
            expireThirtySoon
            expireCount
          }
          binCnt
          # barcode
          isAppropriate
        }
      }
    }
  }
`;

const SEARCH_PRODUCT_LOG = gql`
  query productLogList(
    $query: SearchProductLogInput
  ) {
    productLogList(
      query: $query
    ) {
      items {
        def
        id
        major
        minor
        productID
        productName
        hospital
        unit
        binCnt
        inOut
        detail
        previousAmount
        changedAmount
        changedCnt
        staffName
        remarks
        retailPrice
        expire {
          expireDate
          expireThirtySoon
          expireCount
        }
        manufacture
        createdAt
        createTime
      }
      hasNext
      hasPrev
      total
    }
  }
`;

const SEARCH_PRODUCT_LOG_PAGINATION = gql`
  query searchProductLogPagination(
    $def: String!
    $args: InputSearchProductLog
    $page: InputPage
    $comparison: String
    $match: String
    $sort: InputSort
  ) {
    searchProductLogPagination(
      def: $def
      args: $args
      page: $page
      comparison: $comparison
      match: $match
      sort: $sort
    ) {
      ... on RetSearchProductLogPagination {
        lastKey
        data {
          ... on RetProductLog {
            def
            id
            createTime
            major
            minor
            productName
            hospital
            unit
            binCnt
            inOut
            detail
            previousAmount
            changedAmount
            changedCnt
            staffName
            remarks
            retailPrice
            expire {
              expireDate
              expireThirtySoon
              expireCount
            }
            manufacture
          }
        }
      }
    }
  }
`;

const CREATE_PRODUCT_LOG = gql`
  mutation createProductLog(
    $input: ProductLogInput
  ) {
    createProductLog(
      input: $input
    ) {
      code
      message
    }
  }
`;

const UPDATE_PRODUCT_LOG = gql`
  mutation updateProductLog(
    $input: ProductLogInput
  ) {
    updateProductLog(
     input: $input
    ) {
      code
      message
    }
  }
`;

const SEARCH_PRODUCT_PM = gql`
  query searchProductPM(
    $def: String!
    $args: InputSearchProduct
    $comparison: String!
    $match: String!
    $sort: InputSort
  ) {
    searchProductPM(
      def: $def
      args: $args
      comparison: $comparison
      match: $match
      sort: $sort
    ) {
      ... on RetProduct {
        def
        hospital
        major
        minor
        productID
        productName
        origin
        manufacture
        unit
        dvFamilyPrice
        specialPrice
        retailPrice
        material
        fixureOrder
        order
        confirm
        storage {
          hospital
          important
          unit
          currentCnt
          optCnt
          alert
          editDate
          editorID
          canExpire
          expire {
            expireDate
            expireThirtySoon
            expireCount
          }
          binCnt
          # barcode
          isAppropriate
        }
      }
    }
  }
`;

const GET_UNIQUE_CATEGORY = gql`
  query getUniqueCategory($query: SearchCategoryInput){
    getUniqueCategory (query: $query)
  }
`

const GET_UNIQUE_SUBCATEGORY = gql`
  query getUniqueSubCategory($category: String!){
    getUniqueSubCategory (category: $category)
  }
`

const GET_PRODUCT_SAMPLES = gql`
  query getProductSamples($query: SearchProductSampleInput){
    getProductSamples (query: $query){
      productID
      major
      manufacture
      minor
      origin
      productName
      id
    }
  }
`

const VERIFY_PRODUCT_EXCEL_FILE = gql`
  mutation verifyProductExcelFile($file: Upload!){
    verifyProductExcelFile (file: $file){
      items{
        productID
        major
        minor
        productName
        unit
        currentCnt
        optCnt
        cost
        isFailed
        origin
        failedCode
        failedMessage
        manufacture
        binCnt
      }
      successCount
      failCount
    }
  }
`
const BATCH_REGISTRATION_PRODUCT = gql`
  mutation batchRegistrationProduct($input: [batchRegistrationInput]){
    batchRegistrationProduct (input: $input){
      code
      message    
    }
  }
`

export {
  BATCH_REGISTRATION_PRODUCT,
  VERIFY_PRODUCT_EXCEL_FILE,
  GET_PRODUCT_SAMPLES,
  GET_UNIQUE_CATEGORY,
  SEARCH_PRODUCT,
  SEARCH_PRODUCT_PAGINATION,
  BATCH_GET_PRODUCT,
  BATCH_REMOVE_PRODUCT,
  BATCH_REMOVE_PRODUCT_LOG,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  REMOVE_PRODUCT,
  SEARCH_PRODUCT_LOG,
  SEARCH_PRODUCT_LOG_PAGINATION,
  SEARCH_PRODUCT_PM,
  CREATE_PRODUCT_LOG,
  UPDATE_PRODUCT_LOG,
  PRODUCT_LIST,
  GET_UNIQUE_SUBCATEGORY,
  PRODUCT_LOG_LIST,
  PRODUCT_DETAIL,
  UPDATE_IMPORTANT_PRODUCT
};
