import React from "react";

const PhotoIconBigVideoIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="98"
    viewBox="0 0 120 98"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        fillOpacity=".54"
        d="M85 35.5V18c0-2.75-2.25-5-5-5H20c-2.75 0-5 2.25-5 5v50c0 2.75 2.25 5 5 5h60c2.75 0 5-2.25 5-5V50.5l20 20v-55l-20 20z"
      />
      <path d="M0 0H120V98H0z" />
    </g>
  </svg>
);
export default PhotoIconBigVideoIcon;
