import React from "react";

const DigitalSignIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M17 1c1.1 0 2 .9 2 2v3h-2V5H7v14h10v-1h2v3c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V3c0-1.1.9-1.99 2-1.99L17 1zm1.228 8L20 10.772 14.772 16H13v-1.772L18.228 9zm1.707-1.85c.2-.2.52-.2.72 0l1.196 1.195c.199.2.199.52 0 .72l-.935.935L19 8.084z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default DigitalSignIcon;
