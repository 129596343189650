import React from "react";

const DownloadnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="ud8ylj5tla" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
      <filter id="jkyn6ksusb" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.537255 0 0 0 0 0.482353 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-996.000000, -369.000000) translate(996.000000, 369.000000)"
          />
          <path
            fill="url(#ud8ylj5tla)"
            d="M27 1c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4H5c-2.21 0-4-1.79-4-4V5c0-2.21 1.79-4 4-4h22zM8 17.333H5.333v8h21.334v-8H24v5.334H8v-5.334zM18.667 6.667h-5.334V12h-4L16 18.667 22.667 12h-4V6.667z"
            transform="translate(-996.000000, -369.000000) translate(996.000000, 369.000000)"
          />
          <g
            filter="url(#jkyn6ksusb)"
            transform="translate(-996.000000, -369.000000) translate(996.000000, 369.000000)"
          >
            <g>
              <path
                fill="#FFF"
                d="M18.667 0L18.667 5.333 2.667 5.333 2.667 0 0 0 0 8 21.333 8 21.333 0z"
                transform="translate(5.333333, 17.333333)"
              />
            </g>
          </g>
          <path
            fill="#00BFA5"
            d="M18.667 6.667L13.333 6.667 13.333 12 9.333 12 16 18.667 22.667 12 18.667 12z"
            transform="translate(-996.000000, -369.000000) translate(996.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default DownloadnIcon;
