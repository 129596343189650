import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CCTypography } from 'styles/src/components';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100%',
    minHeight: 54,
  },
}));

function pad(n, width) {
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

const TimerItem = (props) => {
  const { origin } = props;
  const classes = useStyle();
  const [wait, setWait] = useState(
    origin ? moment.duration(moment().diff(origin.clone())) : null,
  );
  useEffect(() => {
    let timer = setInterval(() => {
      if (origin) setWait(moment.duration(moment().diff(origin.clone())));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [origin]);
  return (
    <Grid
      container
      justify={'center'}
      alignItems={'center'}
      className={classes.root}
    >
      <Grid item>
        <CCTypography variant={'body1'}>{`${pad(wait?.get('hours'), 2)}:${pad(
          wait?.get('minutes'),
          2,
        )}:${pad(wait?.get('seconds'), 2)}`}</CCTypography>
      </Grid>
    </Grid>
  );
};
export default TimerItem;
