import React from "react";

const ClaimincludedIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M11.929 3c5.005-.044 9.03 3.578 9.07 8.198.04 4.533-4.838 12.128-15.213 9.114l.553-2.689C4.213 16.052 3.02 13.67 3 11.338 2.96 6.667 7.011 3.044 11.93 3zm2.094 4.5c-1.47.013-2.025 1.115-2.025 1.115s-.664-1.084-1.99-1.071c-1.573.014-2.52 1.525-2.508 2.952.016 1.86 1.567 4.153 5.107 4.163l.022.941c2.503-.924 3.888-3.1 3.87-5.086-.017-2.112-1.427-3.023-2.476-3.014z"
      />
      <path d="M0 0H24V24H0z" transform="matrix(0 1 1 0 0 0)" />
    </g>
  </svg>
);

export default ClaimincludedIcon;
