const TREATMENT_PLAN = {
  STATUS: {
    PLAN: {
      IDLE: {
        value: "0",
        localeKey: "treatmentPlan.status.idle"
      },
      TEMPORARY_STORAGE: {
        value: "1",
        localeKey: "treatmentPlan.status.temporaryStorage"
      },
      CONFIRMATION: {
        value: "2",
        localeKey: "treatmentPlan.status.confirmation"
      },
      CLOSING: {
        value: "3",
        localeKey: "treatmentPlan.status.closing"
      }
    },
    TREATMENT: {
      BE_SCHEDULED: {
        value: "0",
        localeKey: "treatmentPlan.status.beScheduled"
      },
      HOLD: {
        value: "1",
        localeKey: "treatmentPlan.status.hold"
      },
      CANCEL: {
        value: "2",
        localeKey: "treatmentPlan.status.cancel"
      },
      COMPLETION: {
        value: "3",
        localeKey: "treatmentPlan.status.completion"
      }
    }
  },
  TREATMENT_UNIT: {
    PER_TOOTH: { value: "0", localeKey: "treatmentUnit.perTooth" },
    PER_ROOT_CANAL: { value: "1", localeKey: "treatmentUnit.perRootCanal" },
    PER_HALF_JAW: { value: "2", localeKey: "treatmentUnit.perHalfJaw" },
    PER_THIRD_JAW: { value: "3", localeKey: "treatmentUnit.perThirdJaw" },
    PER_JAW: { value: "4", localeKey: "treatmentUnit.perJaw" },
    WHOLE_JAWS: { value: "5", localeKey: "treatmentUnit.wholeJaws" }
  }
};
const CONSULTING = {
  IMPORTANT_FACTOR: {
    COST: { value: "0", localeKey: "consulting.importantFactor.cost" },
    VISIT_COUNT: {
      value: "1",
      localeKey: "consulting.importantFactor.visitCount"
    },
    AESTHETIC: {
      value: "2",
      localeKey: "consulting.importantFactor.aesthetic"
    },
    DENTAL_FEAR: {
      value: "3",
      localeKey: "consulting.importantFactor.dentalFear"
    },
    SKILLS: {
      value: "4",
      localeKey: "consulting.importantFactor.skills"
    },
    SERVICES: {
      value: "5",
      localeKey: "consulting.importantFactor.services"
    }
  }
};

const CHART = {
  TREATMENT_PLAN,
  CONSULTING
};
export default CHART;
