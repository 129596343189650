import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => {
  return {
    root: ({ isFromSender }) => ({
      backgroundColor: isFromSender
        ? theme.palette.secondary.main
        : theme.palette.white,
      color: isFromSender ? theme.palette.white : theme.palette.black,
      fontSize: 14,
      whiteSpace: "pre-line",
      wordBreak: "break-word"
    })
  };
});

const TextMessage = props => {
  const { content, isFromSender, styles } = props;

  const classes = useStyles({ isFromSender });

  return <Box className={clsx(classes.root, styles)}>{content}</Box>;
};

export default TextMessage;
