import React, { useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import {  CCDialogSimple, CCTypography,CCIconButton } from "styles/src/components";
import { useIntl, CAPITALIZE_TYPE } from "utils/language";
import { DownloadIcon } from "styles/icons";
import { StlViewer } from 'react-stl-file-viewer'
import {
  Loading
} from "components";


const useStyles = makeStyles(theme => ({
  icon__btn: {
    right: "40px",
    top: "4px",
    position: "absolute"
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  subtitles: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  subtitle__text: { padding: "8px", color: theme.palette.disabled.hex }
}));

const PreviewStlFile = props => {
  const {
    openMesh3D,
    handleClose,
    fileName= 'file_name'
  } = props;

  const [volume, setvolume] = useState(0)

  const onDownload = async (photoOne) => {
    if (Boolean(openMesh3D)) {
      const a = document.createElement("a");
      a.href = openMesh3D
      a.download = fileName
      a.click();
      window.URL.revokeObjectURL(openMesh3D);
    }
  };

  const intl = useIntl();
  const classes = useStyles();

  return (
    <CCDialogSimple
      open={Boolean(openMesh3D)}
      maxWidth={'lg'}
      fullWidth={true}
      title={
        <div style={{ width: "100%" }}>
          {intl.formatMessage(
            {
              id: "photoMesh3D",
              defaultMessage: "Photo Mesh 3D"
            },
            {},
            { capitalizeType: CAPITALIZE_TYPE.NONE }
          )}
          <CCIconButton
            className={classes.icon__btn}
            onClick={() => {
              onDownload();
            }}
          >
            <DownloadIcon />
          </CCIconButton>
        </div>
      }
      onCloseButton={handleClose}
      onClose={handleClose}
      contents={
        <Box className={classes.content}>
          <Box className={classes.subtitles}>
            <CCTypography variant="h5">{`* ${intl.formatMessage({
              id: "stlViewerRotate",
              defaultMessage: "Rotate an object"
            })}`}</CCTypography>
            <CCTypography className={classes.subtitle__text}>
              {intl.formatMessage({
                id: "stlViewerRotateDescription",
                defaultMessage: ": click the background to rotate /"
              })}
            </CCTypography>
            <CCTypography variant="h5">
              {intl.formatMessage({
                id: "stlViewerMove",
                defaultMessage: "Move an object"
              })}
            </CCTypography>
            <CCTypography className={classes.subtitle__text}>
              {intl.formatMessage({
                id: "stlViewerMoveDescription",
                defaultMessage: ": click an object to move /"
              })}
            </CCTypography>
            <CCTypography variant="h5">
              {intl.formatMessage({
                id: "stlViewerZoom",
                defaultMessage: "Zoom in & zoom out"
              })}
            </CCTypography>
            <CCTypography className={classes.subtitle__text}>
              {intl.formatMessage({
                id: "stlViewerZoomDescription",
                defaultMessage: ": zoom in&out with the mouse wheel"
              })}
            </CCTypography>
          </Box>
          <Box>
            <Loading open={volume === 0} msg={"Loading..."} />
            <StlViewer
              width={1248}
              height={676}
              url={openMesh3D}
              volume={setvolume}
              groundColor='rgb(255, 255, 255)'
              objectColor='rgb(50, 255, 255)'
              skyboxColor='rgb(255, 255, 255)'
              gridLineColor='rgb(0, 0, 0)'
              lightColor='rgb(255, 255, 255)'
            />
          </Box>
        </Box>
      }
    />
  )
};

export default PreviewStlFile;
