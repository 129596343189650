import { Grid, makeStyles, Radio } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { CCTable, CCTypography } from "styles/src/components";
import { translate } from "components";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 310px)",
    width: "100%"
  },
  table: {
    height: "100%",
    width: "100%"
  },
  description: {
    color: "#b0bec5",
    margin: 16
  },
  cell: {
    alignSelf: "center",
    justifyContent: "center",
    display: "flex",
  },
  checkbox: {
    padding: 0
  }

}));

const ChiefDirector = ({ onChange, value, onError, errors }) => {
  const classes = useStyles();
  const intl = useIntl();


  const onCheck = (id) => {
    onChange("representative", value.map(item => item.id === id ? { ...item, isChiefDirector: !item.isChiefDirector } : { ...item, isChiefDirector: false }))
  }

  const heads = [
    {
      label: translate(intl, "settings.lab.chief.selectToChief"),
      key: "isChiefDirector",
      width: 250,
      className: classes.cell,
      component: ({ cellData, rowData }) => {
        return <Radio checked={cellData} color='secondary' className={classes.checkbox} onChange={() => onCheck(rowData.id)} />
      }
    },
    {
      label: translate(intl, "settings.lab.chief.director"),
      key: "staffName",
      width: 250,
      className: classes.cell,
      component: ({ cellData }) => cellData
    },
    {
      label: translate(intl, "settings.lab.chief.dateOfBirth"),
      key: "birthDate",
      width: 250,
      className: classes.cell,
      component: ({ cellData }) =>
        moment.unix(cellData).format(
          localStorage.getItem("lang") === "ru" ?
            "DD.MM.YYYY"
            : localStorage.getItem("lang") === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY")
    },
  ];


  return (
    <Grid container className={classes.root}>
      <CCTypography className={classes.description}>
        {
          translate(intl, "settings.lab.descriptionChief")
        }
      </CCTypography>
      <CCTable
        className={classes.table}
        classes={{ table__body: classes.table__body }}
        heads={heads}
        contents={value}
      />
    </Grid>
  );
};

export default ChiefDirector;
