import { useQuery } from "@apollo/react-hooks";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { BridgeSelector, TeethSelectors } from "components";
import { AppContext } from "context/AppContext";
import gql from "graphql-tag";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCButtonList,
  CCDialogSimple,
  CCPaper,
  CCTabs,
  CCTypography
} from "styles/src/components";
import { WarningIcon } from "styles/src/themes/common/icons";
import PopoverSearchBtn from "../PopoverSearchBtn";
import { translate } from "components";

const GET_PROTHESIS_PRICES_FOR_CLINIC = gql`
  query GetProthesisPricesForClinic($partnerId: String) {
    getProthesisPriceSettingForClinic(partnerId: $partnerId) {
      item {
        contents {
          category
          partnerId
          tabContents {
            material
            unit
            id
            price
          }
        }
      }
    }
  }
`;

const teethHeight = 124;
const tabHeight = 48;

const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  teeth__selector: {
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  btn__list: { height: `calc(100% - ${teethHeight + tabHeight}px)` },
  overflow: {
    overflowY: "auto"
  },
  warning__color: { color: theme.palette.error.main }
}));

const LabOrderTeethSelector = props => {
  const {
    value,
    onChange,
    // dispatch,
    selectedClinicId
  } = props;
  const {
    teeth,
    missings,
    manufacture
    // requestDate
  } = value;

  const classes = useStyles();
  const { appContext } = useContext(AppContext);
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [focusId, setFocusId] = useState("");
  const [openBridge, setOpenBridge] = useState(false); //false
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [warnMessage, setWarnMessage] = useState("");
  const btnRef = useRef(null);

  const { data: prothesisPricesData, refetch: refetchProthesisPricesData } =
    useQuery(GET_PROTHESIS_PRICES_FOR_CLINIC, {
      variables: {
        partnerId: selectedClinicId
      }
    });

  const handleButtonClick = e => {
    if (!teeth.length) {
      setWarnMessage("Choose the Tooth number at first.");
      setOpenWarnDialog(true);
      setFocusId("");
    } else {
      onChange({
        // deliveryRequestDate: moment(requestDate, 'X')
        //   .add(e.deliveryDate, 'day')
        //   .unix(),
        // requestDate: moment().unix(),
        manufacture: {
          name: e.text,
          id: e.id
        },
        prosthesis: categoryList[selectedTab]?.category,
        // deliveryDate: e.deliveryDate,
        shadeSetting: e.shade,
        ponticSetting: e.pontic
      });

      if (!e.pontic) {
        onChange({ pontic: [] });
      } else {
        setOpenBridge(true);
      }

      if (!e.shade) {
        onChange({
          shade: {
            manufacturer: "Vita",
            product: "Classic",
            top: "",
            middle: "",
            bottom: ""
          }
        });
      }
    }
  };

  const categoryList = useMemo(() => {
    if (
      prothesisPricesData?.getProthesisPriceSettingForClinic?.item?.contents
    ) {
      return prothesisPricesData.getProthesisPriceSettingForClinic.item
        .contents;
    }
    return [];
  }, [prothesisPricesData]);
  const tabList = useMemo(() => {
    return categoryList.map(el => el.category);
  }, [categoryList]);
  const buttonList = useMemo(() => {
    if (categoryList.length)
      return categoryList[selectedTab]?.tabContents.map(el => ({
        ...el,
        id: el.id,
        text: el.material
      }));
    return [];
  }, [selectedTab, categoryList]);

  useEffect(() => {
    if (buttonList && buttonList.length && focusId) {
      const test = btnRef.current.getButtonsRef();
      test.current[focusId] && test.current[focusId].focus();
      const btnSelected = buttonList.find(item => item.id === focusId);
      btnSelected && handleButtonClick(btnSelected);
    }
    const test = btnRef.current.getButtonsRef();

    if (test.current) {
      Object.values(test.current).forEach(element => {
        if (element && element.style) {
          element.style['max-width'] = '98%'
          element.style['text-transform'] = 'unset'
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonList, focusId]);

  useEffect(() => {
    setSelectedTab(0);
    onChange({
      manufacture: {
        name: "",
        id: ""
      },
      prosthesis: ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClinicId]);
  useEffect(() => {
    refetchProthesisPricesData();
  }, [appContext.refresh, refetchProthesisPricesData]);

  return (
    <CCPaper className={clsx(classes.root)}>
      <Box className={classes.teeth__selector}>
        <TeethSelectors
          width={480}
          height={120}
          hborder={80}
          layout={"normal"}
          selects={teeth}
          onChange={e => onChange({ teeth: e })}
        />
      </Box>
      <CCTabs
        size="small"
        tabList={tabList}
        onSearchBtn={e => {
          setAnchorEl(e.currentTarget);
        }}
        selectedTab={selectedTab}
        onChangeTab={newValue => {
          setSelectedTab(Number(newValue));
        }}
      />
      <Box className={classes.btn__list}>
        <CCButtonList
          btnGroupList={buttonList?.length ? buttonList : []}
          settingClick={() => {
            const w = window.open(
              `${window.location.origin}/setting`,
              "_blank"
            );
            w.variables = { category: "common.ui.productPrice" };
          }}
          button={{ height: "fit-content" }}
          ref={btnRef}
          tooltip={true}
          onTooltip={(e) => {
            return e.text
          }}
          btnClick={handleButtonClick}
          selectedBtn={[{ id: manufacture.id }]}
          className={classes.overflow}
        />
      </Box>
      <PopoverSearchBtn
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        // value={categoryList}
        value={categoryList}
        onClick={selected => {
          setFocusId(selected.id);
          setSelectedTab(selected.categoryIndex);
        }}
      />
      {openBridge && (
        <BridgeSelector
          open={openBridge}
          onClose={() => setOpenBridge(false)}
          teeth={teeth}
          missings={missings}
          onSave={e => onChange({ missings: e })}
        />
      )}
      <CCDialogSimple
        open={openWarnDialog}
        title={<WarningIcon className={classes.warn__icon} />}
        contents={
          <>
            <CCTypography className={classes.warning__color}>
              {warnMessage}
            </CCTypography>
          </>
        }
        endActions={
          <>
            <CCButton
              variant="contained"
              // color="error"
              onClick={() => {
                setOpenWarnDialog(false);
              }}
            >
              {translate(intl, "common.ui.confirm")}
            </CCButton>
          </>
        }
      />
    </CCPaper>
  );
};

LabOrderTeethSelector.defaultProps = {};

LabOrderTeethSelector.propTypes = {};

export default LabOrderTeethSelector;
