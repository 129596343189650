import { useQuery } from "@apollo/react-hooks";
import { Box, Checkbox, Grid, makeStyles, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import {
  CVButton,
  CVTable,
  PermissionBackdrop,
  translate,
  useReadPermission
} from "components";
import { AppContext } from "context";
import moment from "moment";
import {
  CREAT_BILL_GET
} from "queries/payment";
import { PARTNER_LIST } from "queries/setting";
import React, { useCallback, useContext, useMemo, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCTextField,
  CCTypography
} from "styles/components";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import { AddIcon } from "styles/src/themes/common/icons";
// import { CheckBox } from "@material-ui/icons";
// import { CCDatePicker } from "styles/components";
import {
  LAB_ORDER_STATE, LAB_ORDER_TYPE
} from "types/labOrder";
import { initialState, reducer } from "views/Normal/Payment/reducer";
import { CCFlattenDateField } from "../../../components";
import { makeIndexLocaleKeyList } from "../../../types/utils";
import { BillPaper } from "./components";
import BillConfirm from './components/BillConfirm/BillConfirm';
import ButtonMonth, { getTimeStampOfMonth } from "./components/DateSelectionButtons/ButtonMonth";
import ButtonToday from "./components/DateSelectionButtons/ButtonToday";
import ButtonWeek from "./components/DateSelectionButtons/ButtonWeek";

const labOrderStateMapping = makeIndexLocaleKeyList(LAB_ORDER_STATE)
const labOrderTypeMapping = makeIndexLocaleKeyList(LAB_ORDER_TYPE)

const width = 930;
const padding = 12;
const useStyles = makeStyles(() => ({
  root: { height: "100%", position: "relative" },
  container: { height: "100%" },
  item__right: {
    width: width,
    padding: padding
  },
  width130: {
    width: 130
  },
  button__icon: {
    margin: 0,
    padding: 0
  },
  color__grey: {
    color: "#b0bec5"
  },
  tableContainer: {
    height: 'calc(100% - 140px)',
    marginBottom: 70
  },
  table: {
    width: "100%",
    borderTop: "1px solid #b0bec5",
    borderBottom: "1px solid #b0bec5"
  },
  border__right: {
    borderRight: "1px solid #b0bec5 "
  },

  color__red: {
    color: "#f50057"
  },
  color__green: {
    color: "#00897b"
  },
  pricetag: {
    width: 300,
    marginTop: 24,
    marginBottom: 24,
    justifyContent: "space-between"
  },
  divided: {
    width: 1,
    height: 36,
    backgroundColor: "#b0bec5",
    margin: "0 16px"
  },
  dividedItem: {
    width: 1,
    height: 36,
    opacity: 0,
    margin: "0 8px"
  },
  item__padding: {
    padding: padding
  },
  filter: {
    padding: "16px 0"
  },
  padding0: { padding: 0 },
  cell: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  cell__full: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  font__bold: {
    fontWeight: "bold"
  },
  note: {
    textDecoration: "underline",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "60%",
    textAlign: "center"
  },
  minwidth__100: {
    minWidth: 100
  },
  bill_btn: {
    position: "fixed",
    bottom: 20,
    background: "white"
  }
}));

const CreateBill = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { appContext } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { labSetting } = state;
  const readPermission = useReadPermission("payment.createBill");
  const lang = localStorage.getItem('lang')
  let currentMonthTimeStamp = getTimeStampOfMonth()
  const [selectedDateFrom, setSelectedDateFrom] = useState(currentMonthTimeStamp.from)
  const [selectedDateTo, setSelectedDateTo] = useState(currentMonthTimeStamp.to)
  const [dateFilterBtnState, setDateFilterBtnState] = useState('month')

  const [listIdOrderChecked, setListIdOrderChecked] = useState({})
  const [selectedClinic, setSelectedClinic] = useState("")
  const [checkAll, setCheckAll] = useState(false)
  const [openBill, setOpenBill] = useState(false)
  const [openSendBill, setOpenSendBill] = useState(false)
  const [note, setNote] = useState("")
  const { data: paymentHistoryData, refetch: searchLabRefetch } = useQuery(
    CREAT_BILL_GET,
    {
      variables: {
        query: {
          clinic: selectedClinic,
          orderDateFilter: { from: selectedDateFrom, to: selectedDateTo }
        }
      },
      fetchPolicy: "network-only",
      pollInterval: 30000 // 30초 간격 폴링
    }
  );

  useQuery(PARTNER_LIST, {
    variables: {
      query: { skip: 0, limit: 20, keyword: "" }
    },
    onCompleted: data => {
      const value = data?.partnerList?.items.sort((a, b) => a.name > b.name)
      setSelectedClinic(value && value[0] ? value[0].id : "")
      searchLabRefetch()
      dispatch({
        type: "SET_SETTING",
        name: "labSetting",
        value
      });
    }
  });

  const headConfigs = [
    { label: "common.ui.orderNo", dataProperty: "requestId" },
    { label: "payment.bill.orderDate", customFormat: (rowData) => rowData ? moment.unix(rowData.requestDate).format("L") : "" },
    { label: "common.ui.dueDate", customFormat: (rowData) => rowData ? moment.unix(rowData.deliveryRequestDate).format("L") : "" },
    { label: "common.ui.orderType", customFormat: (rowData) => rowData && rowData.type ? translate(intl, labOrderTypeMapping[rowData.type]) : "-" },
    { label: "common.ui.patientName", dataProperty: "patient.name", customFormat: (rowData) => rowData && rowData.patient ? rowData.patient.name : "-" },
    { label: "common.ui.prosthesis", dataProperty: "prosthesis" },
    { label: "common.ui.status", customFormat: (rowData) => rowData && rowData.status ? translate(intl, labOrderStateMapping[rowData.status]) : "-" },
    { label: "payment.createBill.bill", dataProperty: "isOrderSentOnBill", customFormat: (rowData) => rowData && rowData.isOrderSentOnBill ? "Sent" : "-" },
  ]
  const getCheckAllData = useCallback((checked) => {
    return (paymentHistoryData?.paymentBillOrderList?.items || []).reduce((accumulator, currentValue) => {
      if (!currentValue.isOrderSentOnBill) {
        accumulator[currentValue.id] = checked
      }
      return accumulator;
    }, {})
  }, [paymentHistoryData])

  var heads = [{
    label: () => (<Checkbox
      checked={checkAll}
      onChange={(event) => {
        setCheckAll(event.target.checked)
        setListIdOrderChecked(getCheckAllData(event.target.checked))
      }}
    />),
    key: "checkbox",
    className: clsx(classes.cell),
    width: 50,
    component: ({ rowData, index }) =>
      <Checkbox
        disabled={checkAll || rowData.isOrderSentOnBill}
        checked={rowData.isOrderSentOnBill ? false : listIdOrderChecked[rowData.id] ? true : checkAll}
        onChange={(event) => {
          console.log(rowData.id, event.target.checked)
          if (rowData.isOrderSentOnBill) {
            return
          }
          setListIdOrderChecked({
            ...listIdOrderChecked,
            [rowData.id]: event.target.checked
          })
        }}
      />
  }]
  heads = heads.concat(headConfigs.map(each => {
    return {
      label: each.label ? translate(intl, each.label) : each.label,
      key: each.label,
      className: clsx(classes.cell),
      // width: 'auto',
      component: ({ rowData }) =>
        <CCTypography>
          {each.customFormat ? each.customFormat(rowData) : rowData[each.dataProperty]}
        </CCTypography>
    }
  }))

  // useEffect(() => {
  //   searchLabRefetch()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [openAnchorEl]);
  const enableCreateBillButton = useMemo(() => {
    return Object.values(listIdOrderChecked).includes(true)
  }, [listIdOrderChecked])

  return (
    <Box className={classes.root}>
      {appContext.ready &&
        (!readPermission ? (
          <PermissionBackdrop permission={"payment.createBill"} />
        ) : (
          <Grid container className={classes.container}>
            <Grid
              container
              wrap={"nowrap"}
              alignItems={"center"}
              justify={"space-between"}
              className={classes.padding8}
            >
              <Grid item
                container
                alignItems="center"
                className={classes.item__padding}
              >
                <CCTextField
                  className={classes.width130}
                  label={translate(intl, "payment.clinic")}
                  margin={"dense"}
                  variant={"outlined"}
                  value={selectedClinic || ""}
                  select
                  onChange={e => {
                    setCheckAll(false)
                    setListIdOrderChecked({})
                    setSelectedClinic(e.target.value);
                  }}
                >
                  {labSetting &&
                    labSetting.map(item => (
                      <CCMenuItem key={item.id} value={item.id}>
                        {item.name}
                      </CCMenuItem>
                    ))
                  }
                </CCTextField>

                <CCFlattenDateField
                  label=""
                  className={classes.field__date}
                  value={moment.unix(selectedDateFrom)}
                  onChange={e => {
                    setSelectedDateFrom(moment.utc(e, "L").unix());
                  }}
                />
                ~
                <CCFlattenDateField
                  label=""
                  className={classes.field__date}
                  value={moment.unix(selectedDateTo)}
                  onChange={e => {
                    setSelectedDateTo(moment.utc(e, "L").unix());
                  }}
                />

                <ButtonToday
                  isActive={dateFilterBtnState === 'today'}
                  onClick={(value) => {
                    setDateFilterBtnState('today')
                    setSelectedDateFrom(value.from)
                    setSelectedDateTo(value.to)
                  }} />

                <Box className={classes.dividedItem} />
                <ButtonWeek
                  isActive={dateFilterBtnState === 'week'}
                  onClick={(value) => {
                    setDateFilterBtnState('week')
                    setSelectedDateFrom(value.from)
                    setSelectedDateTo(value.to)
                  }} />
                <Box className={classes.dividedItem} />
                <ButtonMonth
                  isActive={dateFilterBtnState === 'month'}
                  onClick={(value) => {
                    setDateFilterBtnState('month')
                    setSelectedDateFrom(value.from)
                    setSelectedDateTo(value.to)
                  }} />
                <Box className={classes.dividedItem} />
                <CVButton
                  variant={"contained"}
                  color={"secondary"}
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    // handleRefetchData();
                    // handleRefetchAccumulate();

                    // dispatch({
                    //   type: "CHANGE_SEARCH_VARIABLES",
                    //   value: {
                    //     ...filterState,
                    //     clinic:
                    //       filterState.clinic === "all"
                    //         ? ""
                    //         : filterState.clinic
                    //   }
                    // });
                    searchLabRefetch()
                  }}
                >
                  {translate(intl, "payment.search")}

                </CVButton>
              </Grid>
              <Grid item className={classes.padding8}>
                <Typography style={{ width: 350, textAlign: lang === 'ar' ? 'left' : 'right' }}>
                  {translate(intl, "payment.createBill.selectCheckbox")}
                </Typography>
              </Grid>
            </Grid>
            {/* {!loading && ( */}
            <Grid item container className={classes.tableContainer}>
              <CVTable
                heads={heads}
                contents={paymentHistoryData?.paymentBillOrderList?.items}
                className={classes.table}
              />
            </Grid>
            {enableCreateBillButton && <Grid
              container
              alignItems="center"
              className={[classes.item__padding, classes.bill_btn]}
            >

              <CVButton
                variant={"outlined"}
                startIcon={<AddIcon />}
                permission={"payment.createBill"}
                onClick={e => {
                  console.log(listIdOrderChecked)
                  setOpenBill(true)
                }}
              >
                {translate(intl, "payment.createBill")}
              </CVButton>

            </Grid>
            }
          </Grid>
        ))}
      {Boolean(openBill) && (
        <BillConfirm
          open={Boolean(openBill)}
          paymentHistoryData={paymentHistoryData}
          ids={Object.keys(listIdOrderChecked).filter(id => listIdOrderChecked[id])}
          clinic={selectedClinic}
          onClose={() => {
            setOpenBill(false);
          }}
          onNoteUpdate={setNote}
          onConfirm={() => {
            setOpenSendBill(true)
            setTimeout(() => {
              setOpenBill(false);
            }, 2000)
          }}
        />

      )}

      {Boolean(openSendBill) && (
        <BillPaper
          open={Boolean(openSendBill)}
          note={note}
          // id={selectedClinic}
          ids={Object.keys(listIdOrderChecked).filter(id => listIdOrderChecked[id])}
          clinic={selectedClinic}
          onClose={() => {
            setNote("")

            // clear list
            setCheckAll(false)
            setListIdOrderChecked({})
            setOpenSendBill(false);
          }}
        />
      )}
    </Box>
  );
};

CreateBill.defaultProps = {};

CreateBill.propTypes = {};

export default CreateBill;
