/*eslint react-hooks/exhaustive-deps: 0*/
import { makeStyles } from "@material-ui/styles";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState
} from "react";
import { CCTypography } from "styles/components";
import { useIntl } from "utils/language";
import { Controller, useFormContext } from "react-hook-form";
import { Box, FormControlLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { CVTextField, ErrorTooltip, RadioGroup } from "components";
import {
  CCButton,
  CCDialog,
  CCDialogContent,
  CCDialogTitle
} from "styles/src/components";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import { makeList } from "utils/type";
import { PRODUCT_CATEGORY } from "types/inventory";
import produce from "immer";
import { useQuery } from "@apollo/client";
import axios from "axios";
import { SEARCH_PRODUCT_PAGINATION } from "queries/product";
import SearchResults from "views/Normal/InventoryManagement/Component/Purchase/Component/EstimationTab/Component/EstimateCreateDialog/Component/EstimateSelectTable/components/EstimateSearchDialog/components/SearchResults";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 920,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  },
  head__field: {
    width: 300,
    display: "flex",
    flexWrap: "wrap"
    // gap: 5,
  },
  head__an__field: {
    width: 120
  },
  head__an__search__field: {
    width: 400
  },
  button__filter: {
    marginTop: "8px"
  },
  content__top__border: {
    height: "100%",
    width: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  content__bottom__border: {
    height: "100%",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  text__color: {
    color: theme.palette.secondary.main
  }
}));

const reducer = produce((draft, action) => {
  switch (action.type) {
    case "getMinor":
      draft.minor = Object.values(action.target);
      return draft;
    case "getMajor":
      draft.major = Object.values(action.target);
      return draft;
    default:
      break;
  }
});

const EstimateSearchDialog = forwardRef((props, ref) => {
  const intl = useIntl();

  const {
    open,
    onClose,
    products = [],
    partner,
    onChangeProduct = () => {},
    onClickEnter = () => {},
    changePam = () => {}
  } = props;

  const classes = useStyles();

  const [searchResult, setSearchResult] = useState([]);
  const [supplierResult, setSupplierResult] = useState([]);
  const [option, setOption] = useState("1");
  const [value, setValue] = useState([]);

  const [state, dispatchState] = useReducer(reducer, { minor: [], major: [] });

  useImperativeHandle(ref, () => ref?.current);

  const { data } = useQuery(SEARCH_PRODUCT_PAGINATION, {
    variables: {
      def: "1",
      comparison: "or",
      match: "contains"
    },
    onCompleted: () => {
      try {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_PAM_URL}/api/v1/material_item/match`,
          data: {
            materialId: partner?.id
          },
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
          }
        })
          .then(response => {
            setSupplierResult(
              response?.data?.rows?.map(e => {
                return {
                  ...e,
                  major: e?.category1,
                  minor: e?.category2,
                  productName: e?.name,
                  manufacture: e?.manufacturer,
                  unit: e?.unit1,
                  image: e?.MaterialItemImage
                };
              })
            );
          })

          .catch(err => {
            console.log("codus codus Error", err);
          });
      } catch (e) {
        console.error(e);
      }
    }
  });

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const ALL_SELECTION = intl.formatMessage({ id: "all" });
  const productCategory = makeList(PRODUCT_CATEGORY);

  const { getValues, watch, control, errors } = useFormContext();

  useEffect(() => {
    if (option === "1") {
      let _data = data?.searchProductPagination?.data
        ? [...data?.searchProductPagination?.data]
        : [];

      setSearchResult(
        _data.sort(a => {
          if (a?.storage?.important) {
            return -1;
          } else {
            return 1;
          }
        })
      );
    }

    if (option === "0") {
      dispatchState({
        type: "getMajor",
        target: {
          ...supplierResult?.map(e => e?.category1).filter(onlyUnique)
        }
      });
    } else if (option === "1" && data?.searchProductPagination?.data) {
      dispatchState({
        type: "getMajor",
        target: {
          ...data.searchProductPagination?.data
            ?.map(item => item.major)
            .filter(onlyUnique)
        }
      });
    }
  }, [data, option, supplierResult]);

  useEffect(() => {
    changePam(option === "0");
  }, [option]);

  return (
    <>
      <CCDialog open={open} className={classes.root} onClose={onClose}>
        <CCDialogTitle onCloseButton={onClose}>
          <Grid item>
            <Box display="flex" flexDirection="row">
              <CCTypography variant="h3">
                {intl.formatMessage({ id: "productSearchorAdd" })}
              </CCTypography>
            </Box>
          </Grid>
        </CCDialogTitle>
        <CCDialogContent noPadding={true} style={{ overflow: "hidden" }}>
          <Box>
            <CVTextField
              className={classes.head__field}
              variant={"outlined"}
              margin={"dense"}
              name={"searchField"}
              value={partner?.name || ""}
              disabled={true}
              readOnly={true}
              label={intl.formatMessage({ id: "partner" })}
            />
          </Box>
          <Box className={classes.content__top__border}>
            <Controller
              name={"category"}
              control={control}
              defaultValue={getValues("category") || "1"}
              render={props => {
                const { ref, value, onChange, onBlur } = props;

                return (
                  <FormControlLabel
                    control={
                      <RadioGroup
                        tabList={productCategory}
                        value={value}
                        required={true}
                        onChange={e => {
                          onChange(e.value);
                          setOption(e.value);
                        }}
                        inputRef={ref}
                        onBlur={onBlur}
                        defaultValue={value}
                      />
                    }
                  />
                );
              }}
            />
          </Box>
          <Box>
            {/*<Box className={clsx(classes.root, className)}>*/}
            <Controller
              name={"major"}
              control={control}
              defaultValue={getValues("major") || undefined}
              render={props => {
                const { ref, value, onChange, onBlur } = props;
                return (
                  <ErrorTooltip
                    open={Boolean(errors?.major?.message)}
                    errorMsg={errors?.major?.message}
                    placement={"top-start"}
                  >
                    <CVTextField
                      inputRef={ref}
                      control={control}
                      name={"major"}
                      // required={watch("directInput")}
                      className={classes.head__an__field}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({ id: "majorCategory" })}
                      select
                      value={value || ALL_SELECTION}
                      disabled={state.major === []}
                      onChange={e => {
                        onChange(e?.target?.value);
                      }}
                      onBlur={onBlur}
                    >
                      <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                        {ALL_SELECTION}
                      </MenuItem>
                      {Boolean(state?.major) &&
                        state.major.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </CVTextField>
                  </ErrorTooltip>
                );
              }}
            />

            <Controller
              name={"minor"}
              control={control}
              defaultValue={getValues("minor") || null}
              render={props => {
                const { ref, value, onChange, onBlur } = props;
                return (
                  <CVTextField
                    inputRef={ref}
                    control={control}
                    name={"minor"}
                    className={classes.head__an__field}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "minorCategory" })}
                    select
                    value={value || ALL_SELECTION}
                    onChange={e => {
                      onChange(e?.target?.value);
                    }}
                    disabled={
                      state.minor === [] ||
                      watch("major") === null ||
                      watch("major") === "All"
                    }
                    onBlur={onBlur}
                  >
                    <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                      {ALL_SELECTION}
                    </MenuItem>
                  </CVTextField>
                );
              }}
            />

            <Controller
              name={"productName"}
              control={control}
              defaultValue={getValues("productName") || undefined}
              render={props => {
                const { ref, value, onChange, onBlur } = props;
                return (
                  <ErrorTooltip
                    open={Boolean(errors?.productName?.message)}
                    errorMsg={errors?.productName?.message}
                    placement={"top-start"}
                  >
                    <CVTextField
                      inputRef={ref}
                      control={control}
                      name={"productName"}
                      required={watch("directInput")}
                      className={classes.head__an__search__field}
                      permission={false}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({
                        id: "searchProductwithKeyword"
                      })}
                      value={value}
                      onChange={e => onChange(e?.target?.value)}
                      onKeyPress={e =>
                        e.key === "Enter" ? onChange(e?.target?.value) : null
                      }
                      onBlur={onBlur}
                    />
                  </ErrorTooltip>
                );
              }}
            />

            <CCButton
              className={classes.button__filter}
              variant="contained"
              color="secondary"
              startIcon={<SearchIcon />}
              disabled={watch("directInput")}
              onClick={() => {}}
            >
              {intl.formatMessage({ id: "filter" })}
            </CCButton>
          </Box>
          <Box style={{ margin: "15px 20px" }}>
            <Grid container>
              <Grid item variant={"body1"}>
                <CCTypography className={classes.text__color}>
                  {products?.length + value?.length}
                </CCTypography>
              </Grid>
              <Grid item variant={"body1"}>
                <CCTypography>
                  / 100
                  {intl.formatMessage({ id: "hundredProductAddAvailable" })}
                </CCTypography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <SearchResults
              inputRef={ref}
              pam={Boolean(option === "1")}
              result={option === "1" ? supplierResult : searchResult}
              option={option}
              values={value}
              setValues={values => setValue(values)}
              onChangeProduct={values => {
                onChangeProduct(values);
              }}
            />
          </Box>
        </CCDialogContent>

        <Box
          style={{
            overflowY: "auto",
            padding: 10,
            display: "flex",
            justifyContent: "flex-end"
          }}
          marginLeft={"auto"}
          className={classes.icon__end}
        >
          <>
            <CCButton
              className={classes.cancel__icon}
              variant={"text"}
              color={"normal"}
              onClick={onClose}
            >
              {intl.formatMessage({ id: "cancel" })}
            </CCButton>
            <CCButton
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                onClickEnter();
                onClose();
              }}
            >
              {intl.formatMessage({ id: "save" })}
            </CCButton>
          </>
        </Box>
      </CCDialog>
    </>
  );
});
export default EstimateSearchDialog;
