import React from "react";

const UpdownsIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M16 13l-4 5-4-5h8zm-4-7l4 5H8l4-5z"
            transform="translate(-5222 -261) translate(5222 261)"
          />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5222 -261) translate(5222 261)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default UpdownsIcon;
