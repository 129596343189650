import { Grid, makeStyles, Typography } from "@material-ui/core";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import React from "react";
import { useIntl } from "react-intl";
import { translate } from "components";
const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    height: 'calc(100vh - 230px)',
  },
  text__thanks: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 24,
    margin: "32px 0"
  },
  description: {
    width: "640px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)"
  },
  hotline: {
    background: "rgba(0, 0, 0, 0.38)",
    color: "white",
    fontWeight: "bold",
    padding: "4px 16px",
    borderRadius: "50px"
  },
  line: {
    height: 1,
    width: 300,
    background: "#b0bec5",
    margin: "32px 0"
  },
  iconErr: {
    fontSize: 40,
    color: "rgba(0, 0, 0, 0.38)",
    fontWeight: "bold"
  }
}));

const NetworkErrRegistration = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container className={classes.root} direction="column">
      <MoodBadIcon className={classes.iconErr} />
      <Typography className={classes.text__thanks}>
        {translate(intl, "signUp.ui.netWorkErr")}
      </Typography>
      <Typography className={classes.description}>
        {translate(intl, "signUp.ui.netWorkErrMessageLine1")}
        <br />
        {translate(intl, "signUp.ui.netWorkErrMessageLine2")}
      </Typography>
      <div className={classes.line} />
      <Typography className={classes.hotline}>
        {translate(intl, "signIn.ui.vatechCleverSupportCenterPhone", { phone: process.env.REACT_APP_DEFAULT_SUPPORT_PHONE || "024 3226 2932" })}
      </Typography>
    </Grid>
  );
};
export default NetworkErrRegistration;
