import { DialogPermissionWarn } from 'components';
import React, { useState } from 'react';
import { CCTable } from 'styles/components';

const CVTable = (props) => {
  const {
    children,
    permission,
    onAddClick,
    onModClick,
    onDelClick,
    onUpClick,
    onDownClick,
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const isPermission = true;

  const handleClick = () => {
    setOpen(true);
  };

  const options = (() => {
    const opt = {};
    if (onAddClick) {
      if (isPermission) opt.onAddClick = onAddClick;
      else opt.onAddClick = handleClick;
    }
    if (onModClick) {
      if (isPermission) opt.onModClick = onModClick;
      else opt.onModClick = handleClick;
    }
    if (onDelClick) {
      if (isPermission) opt.onDelClick = onDelClick;
      else opt.onDelClick = handleClick;
    }
    return opt;
  })();

  return (
    <>
      <CCTable {...options} {...others} />
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default CVTable;
