import Grid from "@material-ui/core/Grid";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { DayItem, NoopDayItem, WeekHeader } from "./component";

const gongdanLocale = {
  months: "1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월".split("_"),
  monthsShort: "1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월".split("_"),
  weekdays: "일요일_월요일_화요일_수요일_목요일_금요일_토요일".split("_"),
  weekdaysShort: "일_월_화_수_목_금_토".split("_"),
  weekdaysMin: "일_월_화_수_목_금_토".split("_"),
  longDateFormat: {
    LT: "A h:mm",
    LTS: "A h:mm:ss",
    L: "YYYY.MM.DD.",
    LL: "YYYY년 MMMM D일",
    LLL: "YYYY년 MMMM D일 A h:mm",
    LLLL: "YYYY년 MMMM D일 dddd A h:mm",
    l: "YYYY.MM.DD.",
    ll: "YYYY년 MMMM D일",
    lll: "YYYY년 MMMM D일 A h:mm",
    llll: "YYYY년 MMMM D일 dddd A h:mm"
  },
  calendar: {
    sameDay: "오늘 LT",
    nextDay: "내일 LT",
    nextWeek: "dddd LT",
    lastDay: "어제 LT",
    lastWeek: "지난주 dddd LT",
    sameElse: "L"
  },
  relativeTime: {
    future: "%s 후",
    past: "%s 전",
    s: "몇 초",
    ss: "%d초",
    m: "1분",
    mm: "%d분",
    h: "한 시간",
    hh: "%d시간",
    d: "하루",
    dd: "%d일",
    M: "한 달",
    MM: "%d달",
    y: "일 년",
    yy: "%d년"
  },
  dayOfMonthOrdinalParse: /\d{1,2}(일|월|주)/,
  ordinal: function (number, period) {
    switch (period) {
      case "d":
      case "D":
      case "DDD":
        return number + "일";
      case "M":
        return number + "월";
      case "w":
      case "W":
        return number + "주";
      default:
        return number;
    }
  },
  meridiemParse: /오전|오후/,
  isPM: function (token) {
    return token === "오후";
  },
  meridiem: function (hour, minute, isUpper) {
    return hour < 12 ? "오전" : "오후";
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 6 // The week that contains Jan 4th is the first week of the year.
  }
};
moment.defineLocale("gongdan", gongdanLocale);

const DayItemSeries = function* (
  value,
  selected,
  onChange,
  components,
  checkedList,
  mode,
  preserveCurrentTime,
  gongdan
) {
  let _value = value.clone().startOf("week");
  let _endOfWeek = value.clone().endOf("week");
  // console.log("_endOfWeek", _endOfWeek.toString());
  do {
    if (_value.isSame(value, "month")) {
      if (components && components.dayItem) {
        yield (
          <Grid
            key={"Calender-dayItem-" + _value.toString()}
            container
            item
            style={{ width: 36, height: 36 }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <components.dayItem
              value={_value.clone()}
              selected={selected}
              onChange={onChange}
            />
          </Grid>
        );
      } else {
        yield (
          <DayItem
            key={"Calender-dayItem-" + _value.toString()}
            value={_value.clone()}
            selected={selected}
            onChange={onChange}
            checkedList={checkedList}
            mode={mode}
            preserveCurrentTime={preserveCurrentTime}
          />
        );
      }
    } else {
      yield <NoopDayItem key={"Calender-dayItem-" + _value.toString()} />;
    }
    _value.add(1, "day");
  } while (_value.isSameOrBefore(_endOfWeek));
};
const WeekRow = function* (
  value,
  onChange,
  components,
  checkedList,
  mode,
  preserveCurrentTime,
  gongdan
) {
  // console.log(value.begin, value.begin.clone());
  let _value =
    mode === "day"
      ? value.clone().startOf("month")
      : value.begin.clone().startOf("month");
  let _endOfMonth =
    mode === "day"
      ? value.clone().endOf("month")
      : value.begin.clone().endOf("month");
  do {
    // console.log(_value.toString());
    yield (
      <Grid
        key={"Calender-WeekRow-" + _value.toString()}
        item
        container
        justifyContent={"center"}
      >
        {[
          ...DayItemSeries(
            _value,
            value,
            onChange,
            components,
            checkedList,
            mode,
            preserveCurrentTime
          )
        ]}
      </Grid>
    );
    _value.add(1, "week").startOf("week");
  } while (_value.isSameOrBefore(_endOfMonth));
};

const Calendar = props => {
  const {
    value,
    onChange,
    components,
    checkedList,
    mode,
    preserveCurrentTime,
    gongdan
  } = props;
  // console.log("calendar", props);
  const _value = Boolean(mode === "week")
    ? gongdan
      ? {
          begin: value.begin.clone().locale("gongdan"),
          end: value.end.clone().locale("gongdan")
        }
      : {
          begin: value.begin.clone(),
          end: value.end.clone()
        }
    : gongdan
    ? value.clone().locale("gongdan")
    : value.clone();
  return (
    <Grid container item>
      <WeekHeader gongdan={gongdan} gongdanLocale={gongdanLocale} />
      {[
        ...WeekRow(
          _value,
          onChange,
          components,
          checkedList,
          mode,
          preserveCurrentTime,
          gongdan,
          gongdanLocale
        )
      ]}
    </Grid>
  );
};
Calendar.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  components: PropTypes.shape({
    DayItem: PropTypes.elementType
  }),
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["day", "week"]),
  preserveCurrentTime: PropTypes.bool
};
export default Calendar;
