const getSex = ResidentRegistrationNumber => {
  if (ResidentRegistrationNumber) {
    switch (ResidentRegistrationNumber.substring(7, 8)) {
      case "1":
      case "3":
        return "남";
      default:
        return "여";
    }
  }
  return ResidentRegistrationNumber;
};

const make5ZeroString = number => {
  let numberStr = "0000" + number;
  numberStr = numberStr.slice(-5);

  return numberStr;
};

const insertComma = v => {
  return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export { getSex, make5ZeroString, insertComma };
