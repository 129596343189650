import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { translate } from "components";
import VietnameseTermsAndCondition from "./VietnameseTermsAndCondition";
const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  box: {
    border: "1px solid #b0bec5",
    borderRadius: 8,
    padding: 16,
    background: "white",
    flex: 1
  },
  content: {
    background: "#eceff1",
    display: "flex",
    flexDirection: "column",
    overflowY: 'auto',
    height: 'calc(100vh - 263px)' ,
  },
  accept__all: {
    padding: "0 16px",
    borderBottom: "1px solid #b0bec5",
    borderTop: "1px solid #b0bec5"
  },
  text: {
    fontSize: 12
  },
  box__conditions: {
    padding: "0 16px",
    display: "flex",
    flexDirection: "column"
  },
  box__terms: {
    padding: "0 16px",
    display: "flex",
    flexDirection: "column"
    // borderRight: "1px solid #b0bec5"
  }
}));

const TermsAndConditions = props => {
  const { handleAcceptConditionsAndTerms, checked } = props;

  const classes = useStyles();
  const intl = useIntl();
  const language = localStorage.getItem('lang');
  const [acceptTerms, setAcceptTerms] = useState(checked);
  const [acceptConditions, setAcceptConditions] = useState(checked);
  const [acceptAllTerms, setAcceptAllTerms] = useState(false);

  const handleAcceptAll = acceptAllEvent => {
    if (!acceptConditions || !acceptTerms) {
      setAcceptConditions(true);
      setAcceptTerms(true);
    }
    if (acceptConditions || acceptTerms) {
      setAcceptConditions(true);
      setAcceptTerms(true);
    }
    if (acceptConditions && acceptTerms) {
      setAcceptConditions(false);
      setAcceptTerms(false);
    }
    setAcceptAllTerms(acceptAllEvent.target.checked);
    handleAcceptConditionsAndTerms(acceptAllEvent.target.checked);
  };
  const handleTerms = changeTermEvent => {
    setAcceptTerms(changeTermEvent.target.checked);
    handleAcceptConditionsAndTerms(changeTermEvent.target.checked);
  };
  const handleConditions = changeConditionEvent => {
    setAcceptConditions(changeConditionEvent.target.checked);
    handleAcceptConditionsAndTerms(acceptTerms);
  };

  return (
    <Box className={classes.root}>
      <Grid className={classes.accept__all}>
        <FormControlLabel
          control={
            <Checkbox
              checked={(acceptTerms && acceptConditions) || acceptAllTerms}
              onChange={handleAcceptAll}
              name="acceptAll"
            />
          }
          label={translate( intl,"signUp.ui.acceptAllTerms" )}
        />
      </Grid>
      <Grid className={classes.content}>
        {/*@todo add other region*/}
        {true ?
            (<VietnameseTermsAndCondition
              acceptAllTerms={acceptAllTerms}
              handleAcceptAllTerms={(allAreAccepted) => {
                handleAcceptConditionsAndTerms(allAreAccepted);
                setAcceptAllTerms(allAreAccepted);
              }}
            />) :
        <Box className={classes.box__terms}>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTerms}
                onChange={handleTerms}
                name="acceptTerms"
              />
            }
            label={translate( intl,"signUp.ui.termsCheck" )}
          />

          <Box className={classes.box__conditions}>
            <div className={classes.box}>
              <Typography className={classes.text}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
                <br />
                <br />
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
                <br />
                <br />
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </div>
          </Box>
          <Box className={classes.box__conditions}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptConditions}
                  onChange={handleConditions}
                  name="acceptConditions"
                />
              }
              label={translate( intl,"signUp.ui.conditionsCheck" )}
            />
            <div className={classes.box}>
              <Typography className={classes.text}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
                <br />
                <br />
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
                <br />
                <br />
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </div>
          </Box>
        </Box>}
      </Grid>
    </Box>
  );
};

export default TermsAndConditions;
