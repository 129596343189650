/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/styles";
import React, { forwardRef, useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "hidden"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SlideShow = forwardRef((props, __ref) => {
  const { open, photos, timeout = 5000, onClose } = props;
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (open) {
        setSlideIndex((slideIndex + 1) % photos.length);
      }
    }, timeout);

    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    if (open) {
      setSlideIndex(0);
    }
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box className={classes.root}>
        {photos && (
          <Box
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${photos[slideIndex].content.source})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain"
            }}
            onClick={() => {
              onClose();
            }}
          />
        )}
      </Box>
    </Dialog>
  );
});

export default SlideShow;
