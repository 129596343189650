import moment from "moment";
// import { Views } from "react-big-calendar";
// import { RESERVATION } from "types/reservation";
import { LAB_ORDER_STATE } from "types/labOrder";

const makeAdaptData = (data, filter) => {
  let res = [];
  for (let currentValue of data.values()) {
    let _res = {
      id: currentValue.id,
      title: currentValue.content?.hospital?.title || "",
      start: moment(Number(currentValue.dateBegin), "X").toDate(),
      end: moment(Number(currentValue.dateEnd), "X").toDate(),
      data: currentValue,
      allDay: Boolean(currentValue.allDayLong)
    };
    res.push(_res);
  }
  console.log(res);
  return res;
};
const makeStatus = data =>
  data.reduce(
    (accumulator, currentValue, index) => {
      let _status = currentValue.status;
      accumulator.total++;

      if (_status === LAB_ORDER_STATE.SHIPPED.value) accumulator.shipped++;
      if (_status !== LAB_ORDER_STATE.SHIPPED.value) accumulator.pending++;

      return accumulator;
    },
    {
      total: 0,
      shipped: 0,
      pending: 0
    }
  );

const isHolyDay = (date, workTime) => {
  let _date = moment(date);
  let _dayOfWeek = _date.weekday();
  let _workTime = workTime;
  let _currentDay = _workTime[_dayOfWeek];
  if (_currentDay.holiday) {
    return true;
  }
  return false;
};

const isWorkTime = (date, workTime, timeStep) => {
  let _date = moment(date);
  let _dayOfWeek = _date.weekday();
  let _workTime = workTime;
  if (!Boolean(_workTime[_dayOfWeek])) {
    return true;
  }

  let _currentDay = _workTime[_dayOfWeek];
  if (_currentDay.holiday) {
    return false;
  }
  if (_currentDay.lunch) {
    let _lunchTime = _currentDay.lunchTime;
    let _lunchStart = _date
      .clone()
      .hours(_lunchTime.begin.hours())
      .minute(_lunchTime.begin.minute())
      .second(_lunchTime.begin.second());
    let _lunchEnd = _date
      .clone()
      .hours(_lunchTime.end.hours())
      .minute(_lunchTime.end.minute())
      .second(_lunchTime.end.second());
    if (
      // _date.isSameOrAfter(_lunchStart) &&
      _date.isBetween(_lunchStart, _lunchEnd, "minute", "[)")
      // _date.isBetween(_lunchStart, _lunchEnd)
      // &&
      // _dateEnd.isAfter(_lunchStart)
      // &&
      // _dateEnd.isSameOrBefore(_lunchEnd)
    ) {
      // console.log("_lunchEnd", _lunchEnd.toString());
      // console.log(_date.toString());
      return false;
    }
  }
  if (_currentDay.dinner) {
    let _dinnerTime = _currentDay.dinnerTime;
    let _dinnerStart = _date
      .clone()
      .hours(_dinnerTime.begin.hours())
      .minute(_dinnerTime.begin.minute())
      .second(_dinnerTime.begin.second());
    let _dinnerEnd = _date
      .clone()
      .hours(_dinnerTime.end.hours())
      .minute(_dinnerTime.end.minute())
      .second(_dinnerTime.end.second());
    if (_date.isBetween(_dinnerStart, _dinnerEnd, "minute", "[)")) {
      return false;
    }
  }
  let _workDayTime = _currentDay.workTime;
  let _workDayStart = _date
    .clone()
    .hours(_workDayTime.begin.hours())
    .minute(_workDayTime.begin.minute())
    .second(_workDayTime.begin.second());
  let _workDayEnd = _date
    .clone()
    .hours(_workDayTime.end.hours())
    .minute(_workDayTime.end.minute())
    .second(_workDayTime.end.second());
  return _date.isBetween(_workDayStart, _workDayEnd, "minute", "[)");
};
const getMinTime = function (workTime, currentView) {
  let res = 9999999999;
  for (let i in workTime) {
    res = Math.min(
      res,
      workTime[i].workTime.begin.unix()
      // workTime[i].lunchTime.begin.unix(),
      // workTime[i].dinnerTime.begin.unix()
    );
  }
  let _temp = moment(res, "X");
  return moment()
    .startOf(currentView)
    .hour(_temp.hour())
    .minute(_temp.minute())
    .second(0);
};
const getMaxTime = function (workTime, timeStep, timeSlotCount, currentView) {
  let res = 0;
  for (let i in workTime) {
    res = Math.max(
      res,
      workTime[i].workTime.end.unix()
      // workTime[i].lunchTime.end.unix(),
      // workTime[i].dinnerTime.end.unix()
    );
  }
  let _temp = moment(res, "X");
  return moment()
    .endOf(currentView)
    .hour(_temp.hour())
    .minute(_temp.minute())
    .second(0);
  // return moment(res, "X").subtract(timeStep * timeSlotCount, "minute");
};

const isEventTimeFitWorkTime = (event, workTime) => {
  // console.log(event);
  let _res = {
    begin: moment(event.start),
    end: moment(event.end)
  };
  let _workTimeBegin = moment(getMinTime(workTime), "X");
  let _workTimeEnd = moment(getMaxTime(workTime), "X");
  let _begin = _res.begin
    .clone()
    .hours(_workTimeBegin.hours())
    .minutes(_workTimeBegin.minutes())
    .seconds(_workTimeBegin.seconds());
  let _end = _res.end
    .clone()
    .hour(_workTimeEnd.hour())
    .minutes(_workTimeEnd.minutes())
    .second(_workTimeEnd.second());

  if (_res.begin.isBefore(_begin)) {
    return false;
  }
  if (_res.end.isAfter(_end)) {
    return false;
  }
  return true;
};

// const searchReservationVariables = (
//   type = RESERVATION.TYPE.PATIENT.value,
//   currentDate = moment().toDate(),
//   currentView = Views.MONTH
// ) => {
//   let defaultValue = {
//     hospitalId: localStorage.getItem("labId"),
//     // jobType: JOB_TYPES.DENTIST.value,
//     // charge: CHARGE.DOCTOR_IN_CHARGE.value,
//     // status: STATUS.WORKING.value,
//     type: type
//   };
//   switch (currentView) {
//     case Views.MONTH:
//       return {
//         ...defaultValue,
//         dateBegin: {
//           begin: moment(currentDate)
//             .startOf(currentView)
//             .startOf("week")
//             .unix(),
//           end: moment(currentDate).endOf(currentView).endOf("week").unix()
//         },
//         dateEnd: {
//           begin: moment(currentDate)
//             .startOf(currentView)
//             .startOf("week")
//             .unix(),
//           end: moment(currentDate).endOf(currentView).endOf("week").unix()
//         }
//       };
//     case Views.WEEK:
//     case Views.DAY:
//       return {
//         ...defaultValue,
//         dateBegin: {
//           begin: moment(currentDate).startOf(currentView).unix(),
//           end: moment(currentDate).endOf(currentView).unix()
//         },
//         dateEnd: {
//           begin: moment(currentDate).startOf(currentView).unix(),
//           end: moment(currentDate).endOf(currentView).unix()
//         }
//       };
//     case Views.AGENDA:
//       return {
//         ...defaultValue,
//         dateBegin: {
//           begin: moment(currentDate).startOf("day").unix(),
//           end: moment(currentDate).add(1, "month").unix()
//         },
//         dateEnd: {
//           begin: moment(currentDate).startOf("day").unix(),
//           end: moment(currentDate).add(1, "month").unix()
//         }
//       };
//     default:
//       break;
//   }
// };

export {
  makeAdaptData,
  makeStatus,
  isWorkTime,
  getMinTime,
  getMaxTime,
  isEventTimeFitWorkTime,
  isHolyDay
};
