import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { TeethGraph } from "components/index";
import React from "react";

const useStyle = makeStyles(theme => ({
  root: {
    height: "100%",
    cursor: "pointer"
  }
}));

const TeethGraphItem = props => {
  const { numbers = [], onClick = () => {} } = props;
  const classes = useStyle();
  return (
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      className={classes.root}
      onClick={onClick}
    >
      <TeethGraph
        numbers={numbers}
        width={120}
        height={30}
        foreground={"#000"}
      />
    </Grid>
  );
};

export default TeethGraphItem;
