import { Button } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "../../../locales/language";
import { LeftIcon, RightIcon } from "../../themes/common/icons";

const DATE = Object.freeze({
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
  AGENDA: "agenda"
});

const useStyles = makeStyles(
  theme => ({
    root: {
      // fontSize: 14,
      // height: 28,
      position: "relative",
      top: 2
    },
    button: {
      // height: 26,
      color: theme.palette.common.black,
      padding: "4px 8px 5px 8px ",
      // borderRadius: 4,
      // boxShadow: "0 2px 1px 0 rgba(0, 0, 0, 0.24)",
      // border: "solid 1px #ababab",
      backgroundColor: "var(--white)",
      fontFamily: "Noto Sans",
      fontSize: 12
    },
    path__button: {
      padding: 0,
      minWidth: 28
    },
    today__button: {
      padding: 0,
      paddingTop: 1,
      width: 60
    }
  }),
  { name: "CCSimpleDateNav" }
);

const CCSimpleDateNav = props => {
  const intl = useIntl();
  const {
    value,
    onChange,
    step,
    className,
    title = intl.formatMessage({ id: "ccToday" }),
    ...other
  } = props;

  const onPrePathClick = () => {
    switch (step) {
      case DATE.MONTH:
        value.setMonth(value.getMonth() - 1);
        break;
      case DATE.WEEK:
        value.setDate(value.getDate() - 7);
        break;
      case DATE.DAY:
        value.setDate(value.getDate() - 1);
        break;
      case DATE.AGENDA:
        value.setMonth(value.getMonth() - 1);
        break;
      default:
        throw new Error(`Unhandled step type`);
    }
    onChange(value);
  };

  const onTodayClick = () => {
    onChange(new Date());
  };

  const onNextPathClick = () => {
    switch (step) {
      case DATE.MONTH:
        value.setMonth(value.getMonth() + 1);
        break;
      case DATE.WEEK:
        value.setDate(value.getDate() + 7);
        break;
      case DATE.DAY:
        value.setDate(value.getDate() + 1);
        break;
      case DATE.AGENDA:
        value.setMonth(value.getMonth() + 1);
        break;
      default:
        throw new Error(`Unhandled step type`);
    }
    onChange(value);
  };
  const classes = useStyles(props);
  return (
    <ButtonGroup
      size="small"
      className={clsx(className, classes.root)}
      variant={"contained"}
      {...other}
    >
      <Button
        onClick={onPrePathClick}
        className={clsx(classes.button, classes.path__button)}
      >
        {document.body.dir === "rtl" ? <RightIcon /> : <LeftIcon />}
      </Button>
      <Button
        onClick={onTodayClick}
        className={clsx(classes.button, classes.today__button)}
      >
        {title}
      </Button>
      <Button
        onClick={onNextPathClick}
        className={clsx(classes.button, classes.path__button)}
      >
        {document.body.dir === "rtl" ? <LeftIcon /> : <RightIcon />}
      </Button>
    </ButtonGroup>
  );
};

CCSimpleDateNav.propTypes = {
  /** Date 객체 */
  value: PropTypes.instanceOf(Date).isRequired,
  /** Date 변경 시 실행되는 함수 */
  onChange: PropTypes.func.isRequired,
  /** 변경될 단위 */
  step: PropTypes.oneOf([...Object.values(DATE)])
};

CCSimpleDateNav.defaultProps = {
  /** step 초기값 : day */
  step: DATE.DAY
};

export default CCSimpleDateNav;
export { DATE };
