/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CVDialogSimpleDel, PhotoEditor } from "components";
import React, { useRef, useState } from "react";
import { CCButton, CCDialogSimple } from "styles/components";
import { getTimestampFromDate } from "utils/datetime";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialogContent-root": {
      overflowY: "hidden"
    }
  }
}));

const PhotoEditorDialog = props => {
  const classes = useStyles();
  const editorRef = useRef(null);
  const {
    open,
    hospitalId,
    patientId,
    photo0,
    onClose,
    onSave,
    onDeleteButton
  } = props;
  const [photo, setPhoto] = useState(photo0);
  const oldPhoto = { ...photo0 };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onChangePhoto = photo => {
    photo && setPhoto(photo);
  };

  return (
    <>
      <CCDialogSimple
        classes={classes}
        title="이미지 편집"
        open={open}
        maxWidth={false}
        contents={
          <Box width="1280px" height="720px">
            <PhotoEditor
              ref={editorRef}
              hospitalId={hospitalId}
              patientId={patientId}
              photo0={photo}
              modes="dialog"
              onChangePhoto={onChangePhoto}
            />
          </Box>
        }
        endActions={
          <>
            <CCButton
              color="sub"
              onClick={() => {
                onClose && onClose();
              }}
            >
              취소
            </CCButton>
            <CCButton
              variant="contained"
              onClick={() => {
                if (photo?.content) {
                  const data = editorRef.current.dispatchExport(0);
                  const region = editorRef.current.dispatchRegion(0);
                  const updatedPhoto = {
                    ...photo,
                    lastModifiedTime: getTimestampFromDate(),
                    content: {
                      ...photo.content,
                      content: data,
                      region: region
                    },
                    hospitalId: hospitalId,
                    modify: true
                  };
                  onSave && onSave(oldPhoto, updatedPhoto);
                  onClose && onClose();
                }
              }}
            >
              저장
            </CCButton>
          </>
        }
        // onRefreshButton={() => {
        //   if (oldPhoto.id) {
        //     setPhoto(oldPhoto);
        //   }
        // }}
        /*onDeleteButton={() => {
          onDeleteButton && onDeleteButton(photo);
        }}*/
        onCloseButton={() => {
          onClose && onClose();
        }}
        onClose={() => {
          onClose && onClose();
        }}
      />
      {openDeleteDialog && (
        <CVDialogSimpleDel
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onDisagree={() => setOpenDeleteDialog(false)}
          onAgree={() => {
            setOpenDeleteDialog(false);
          }}
        />
      )}
    </>
  );
};

export default PhotoEditorDialog;
