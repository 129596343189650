import React from "react";

const NumbertwoIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M15.02 17.09v-1.593h-4.279l2.263-2.386c.62-.679 1.057-1.273 1.312-1.784.256-.51.383-1.012.383-1.504 0-.898-.283-1.593-.85-2.085C13.28 7.246 12.483 7 11.458 7c-.67 0-1.27.142-1.798.427-.529.285-.938.678-1.227 1.18-.29.5-.434 1.054-.434 1.66h1.982c0-.5.13-.905.387-1.213.257-.307.61-.461 1.056-.461.415 0 .734.126.957.38.223.252.335.6.335 1.042 0 .323-.106.665-.318 1.025-.212.36-.539.782-.981 1.265l-3.22 3.431v1.354h6.823z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default NumbertwoIcon;
