import { Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CVFlattenTimeField, CVLabelCheckBox, CVTextField } from "components";
import moment from "moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCDialogSimple, CCTypography } from "styles/src/components";
import { ClipIcon, WarningIcon } from "styles/src/themes/common/icons";
import DialogProsthesisImage from "../DialogProsthesisImage/DialogProsthesisImage";
import { translate } from "components";
const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "inherit"
  },
  icon: {
    color: theme.palette.error.main,
    marginRight: 8
  },
  warning__color: { color: theme.palette.error.main },
  line: {
    height: 1,
    width: "100%",
    background: " #b0bec5",
    margin: "16px 0"
  },
  content: {
    padding: 16,
    height: "100%"
  },
  header: {
    fontWeight: "bold",
    padding: "8px 16px",
    borderBottom: "1px solid #b0bec5",
    width: "100%"
  },
  inputWidth216: {
    width: 280
  },
  inputWidth169: {
    width: 169,
    margin: "8px 16px"
  },
  inputWidth500: {
    flex: 1
  },
  inputWidth330: {
    width: 330
  },
  inputWidthFull: {
    width: "100%"
  },
  inputMargin: {
    margin: "8px 0"
  },
  inputMarginMiddle: {
    margin: "8px 16px"
  },
  margin__right__10: {
    marginRight: 10
  },
  requeired: {
    color: "#b0bec5"
  },
  button: {
    width: 270,
    margin: "8px 0"
  },
  image: {
    color: "#b0bec5",
    marginLeft: 8
  },
  numberimage: {
    color: "#0277bd",
    marginLeft: 8,
    marginRight: 4,
    textDecoration: "underline"
  }
}));

const initializer = value => {
  let res = [];
  for (let i = 0; i < 7; i++) {
    if (value[i]) {
      res.push(value[i]);
    } else {
      res.push({
        dayOfWeek: i.toString(),
        holiday: true
      });
    }
  }
  return res;
};

const workDayOfWeekCheckBox = function* (value, onChange, intl) {
  const DAYLABELS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  for (let i = 0; i < 7; i++) {
    yield (
      <Grid item key={"workDayOfWeek" + i}>
        <CVLabelCheckBox
          // permission={'setting'}
          labelColor={moment().weekday(Number(i)).day() === 0 ? "#f50057" : ""}
          checked={
            value &&
            value[i] &&
            !value[i].holiday &&
            value[i].holiday !== null &&
            value[i].holiday !== undefined
          }
          onChange={e => {
            onChange(
              "workDaySetting",
              value.map((item, index) =>
                index === i ? { ...item, holiday: !item.holiday } : item
              )
            );
          }}
          label={intl.formatMessage({
            id: `settings.lab.${
              DAYLABELS[moment().weekday(Number(i)).day()]
            }`
          })}
          color={"secondary"}
        />
      </Grid>
    );
  }
};
const isStartBeforeEnd = (start, end) => {
  start = moment.unix(start, "HH:mm")
  end = moment.unix(end, "HH:mm")
  return start.hour()*60 + start.minutes() < (end.hour()*60 + end.minutes())
}
const Details = props => {
  const { value, onChange, refetch } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const [openProsthesisImage, setOpenProsthesisImage] = useState(false);
  if (!value.workTimeSetting) {
    value.workTimeSetting = {
      begin: moment().hours(7).minute(0).second(0).unix(),
      end: moment().hours(19).minute(30).second(0).unix()
    }
  }
  const isEgyptRegion = process.env.REACT_APP_LANGUAGE_REGION === 'ar'
  const currentLang = localStorage.getItem("lang") || process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
  const disableMeridiem = currentLang === "vi" || currentLang === "ru" || (!isEgyptRegion && currentLang === "en")
  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.content} direction="column">
        <Grid
          container
          justify={"flex-start"}
          alignItems={"center"}
          className={clsx(classes.inputMargin)}
        >
          <Grid item className={clsx(classes.margin__right__10)}>
            <CCTypography variant={"h4"}>
              {translate( intl,"settings.lab.workingDays" )}
            </CCTypography>
          </Grid>
          {[
            ...workDayOfWeekCheckBox(
              value?.workDaySetting && value.workDaySetting.length > 0
                ? value.workDaySetting
                : initializer(value.workDaySetting),
              onChange,
              intl
            )
          ]}
        </Grid>

        <Grid
          container
          className={clsx(classes.inputMargin)}
          alignItems={"center"}
        >
          <CCTypography variant={"h4"}>
            {translate( intl,"settings.lab.workingHours" )}
          </CCTypography>
          <Grid item>
            <Grid
              container
              justify={"center"}
              alignItems={"center"}
              wrap={"nowrap"}
            >
              <Grid item>
                <CVFlattenTimeField
                  step={1}
                  disableMeridiem={disableMeridiem}
                  stepperHours={true}
                  stepperMinutes={true}
                  onChange={e => {
                    if (isStartBeforeEnd(e.unix(), value.workTimeSetting.end)) {
                      onChange("workTimeSetting", {
                        ...value.workTimeSetting,
                        begin: e.unix()
                      });
                    } else {
                      setOpen(true);
                    }
                  }}
                  value={
                    moment.unix(value.workTimeSetting.begin)
                  }
                  InputProps={{
                    className: classes.timeField
                  }}
                 
                />
              </Grid>
              <Grid item>
                <CCTypography variant={"body1"}>{"~"}</CCTypography>
              </Grid>
              <Grid item>
                <CVFlattenTimeField
                  stepperHours={true}
                  disableMeridiem={disableMeridiem}
                  stepperMinutes={true}
                  onChange={e => {
                    if (
                      isStartBeforeEnd(value.workTimeSetting.begin, e.unix())
                    ) {
                      onChange("workTimeSetting", {
                        ...value.workTimeSetting,
                        end: e.unix()
                      });
                    } else {
                      setOpen(true);
                    }
                  }}
                  value={
                    moment.unix(value.workTimeSetting.end)
                  }
                  InputProps={{
                    className: classes.timeField
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <CVTextField
          className={classes.inputMargin}
          variant={"outlined"}
          margin={"dense"}
          value={value.specialties}
          InputProps={{
            inputProps: {
              maxLength: 40
            }
          }}
          label={translate(intl, "common.ui.specialties")}
          onChange={e => onChange("specialties", e.target.value)}
          placeholder={translate(
            intl,
            "settings.lab.specialtiesExInrayZiconia"
          )}
        />
        <CVTextField
          className={classes.inputMargin}
          InputProps={{
            inputProps: {
              maxLength: 80
            }
          }}
          variant={"outlined"}
          margin={"dense"}
          onChange={e => onChange("statusMessage", e.target.value)}
          value={value.statusMessage}
          label={translate( intl,"settings.lab.statusMessage" )}
        />
        <CVTextField
          className={classes.inputMargin}
          // permission='treatButton'
          label={translate( intl,"settings.lab.aboutUs" )}
          multiline={true}
          InputProps={{
            inputProps: {
              maxLength: 300
            }
          }}
          value={value.aboutUs}
          rows="4"
          onChange={e => onChange("aboutUs", e.target.value)}
          variant="outlined"
          margin="dense"
        />
        <CVTextField
          className={classes.inputMargin}
          // permission='treatButton'
          label={translate( intl,"settings.lab.availableArea" )}
          InputProps={{
            inputProps: {
              maxLength: 200
            }
          }}
          multiline={true}
          value={value.availableArea}
          rows="4"
          variant="outlined"
          onChange={e => onChange("availableArea", e.target.value)}
          margin="dense"
        />

        <Grid container alignItems="center">
          <CCButton
            variant={"contained"}
            color={"normal"}
            startIcon={<ClipIcon />}
            className={classes.button}
            // value={value.prosthesisImage}
            children={translate(
              intl,
              "common.ui.prosthesisSampleImage"
            )}
            onClick={() => setOpenProsthesisImage(true)}
          />
          <CCTypography className={classes.image}>
            {value.prothesisImages.length > 0 ? (
              <>
                <span className={classes.numberimage}>
                  {value.prothesisImages.length}
                </span>
                {translate(intl, "common.ui.imagesUploaded")}
              </>
            ) : (
              translate( intl,"settings.lab.noImage" )
            )}
          </CCTypography>
        </Grid>
      </Grid>

      <CCDialogSimple
        title={<WarningIcon className={classes.icon} />}
        open={open}
        onClose={() => setOpen(false)}
        contents={
          <CCTypography className={classes.warning__color}>
            {translate( intl,"theEndTimeIsBeforeTheStartTime" )}
          </CCTypography>
        }
        endActions={
          <CCButton
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            {translate( intl,"common.ui.confirm" )}
          </CCButton>
        }
      />

      {openProsthesisImage && (
        <DialogProsthesisImage
          open={openProsthesisImage}
          refetch={refetch}
          value={value}
          onChange={onChange}
          onClose={() => setOpenProsthesisImage(false)}
        />
      )}
    </Grid>
  );
};

export default Details;
