import React from "react";

const PathIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M20.417 9.583c0 .871-.713 1.584-1.584 1.584-.142 0-.277-.016-.403-.056l-2.819 2.81c.04.127.056.27.056.412 0 .871-.713 1.584-1.584 1.584-.87 0-1.583-.713-1.583-1.584 0-.142.016-.285.055-.411l-2.018-2.02c-.127.04-.27.056-.412.056-.143 0-.285-.015-.412-.055l-3.602 3.61c.04.127.056.261.056.404 0 .87-.713 1.583-1.584 1.583-.87 0-1.583-.713-1.583-1.583 0-.871.713-1.584 1.583-1.584.143 0 .277.016.404.056l3.61-3.602c-.04-.127-.055-.27-.055-.412 0-.87.712-1.583 1.583-1.583.87 0 1.583.712 1.583 1.583 0 .143-.015.285-.055.412l2.019 2.018c.126-.04.269-.055.411-.055.143 0 .285.016.412.055l2.81-2.818c-.04-.127-.055-.261-.055-.404 0-.87.713-1.583 1.583-1.583.871 0 1.584.713 1.584 1.583z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default PathIcon;
