import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  formatPhoneNumber,
  isValidPhoneNumber
} from "react-phone-number-input";
import { CCTextField } from "styles/components";

const PhoneNumberField = props => {
  const {
    value,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;

  const [error, setError] = useState(false);

  // function
  const handlePhoneNum = e => {
    if (e.target.value.length > 15) return; // 입력 자리수 제한
    e.target.value = e.target.value.replace(/[^\d,-]/g, ""); // 숫자, -만  입력 가능
    const phoneNumber = e.target.value.replace(/[^\d]/g, ""); // 숫자만 따로 추출
    const krPhoneNumber = "+82".concat(phoneNumber);
    //번호가 있고 한국 유효한 번호가 아니면 에러표시
    phoneNumber && !isValidPhoneNumber(krPhoneNumber)
      ? setError(true)
      : setError(false);

    phoneNumber.length > 9
      ? onChange({
          target: { value: formatPhoneNumber(krPhoneNumber) }
        })
      : onChange({ target: { value: e.target.value } });
  };

  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);
  useEffect(() => {
    if (value) {
      const phoneNumber = value.replace(/[^\d]/g, ""); // 숫자만 따로 추출
      const krPhoneNumber = "+82".concat(phoneNumber);
      if (!isValidPhoneNumber(krPhoneNumber)) {
        setError(true);
      } else setError(false);
    } else setError(false);
    required && value === "" && setError(true);
  }, [value, required]);

  /*
        const handlePhoneNumFormat = () => {
          const phoneNumber = value.replace(/[^\d]/g, ""); // 숫자만 따로 추출
          const krPhoneNumber = "+82".concat(phoneNumber);
          //번호가 있고 한국 전화번호가 맞으면 변경
          if (phoneNumber && isValidPhoneNumber(krPhoneNumber))
            onChange({
              target: { name: props.name, value: formatPhoneNumber(krPhoneNumber) }
            });
        };
      */

  return (
    <CCTextField
      margin={margin}
      variant={variant}
      label={label}
      onChange={handlePhoneNum}
      error={error}
      required={required}
      value={value || ""}
      {...others}
      //onBlur={handlePhoneNumFormat}
    />
  );
};

PhoneNumberField.defaultProps = {
  value: "",
  margin: "dense",
  variant: "outlined",
  label: "전화번호",
  required: false,
  errorOnChange: () => {}
};

PhoneNumberField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default PhoneNumberField;
