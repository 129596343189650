import React from "react";
import NumberFormat from "react-number-format";
const MAX_VAL = 999999999999;
const NumberFormatPayment = props => {
  const { inputRef, onChange, ...other } = props;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value < MAX_VAL) return inputObj;
  };
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.floatValue || 0
          }
        });
      }}
      isAllowed={withValueLimit}
      thousandSeparator
      decimalScale={2}
      displayType={"input"}
      {...other}
    />
  );
};

export default NumberFormatPayment;
