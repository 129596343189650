import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  root: { height: "100%" }
}));

const Contents = props => {
  const { children } = props;
  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
};

export default Contents;
