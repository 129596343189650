import { makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import { useFindArabic } from "../../../locales/utils";

const useStyle = makeStyles(
  theme => ({
    root: {},
    tooltip: {
      borderRadius: "8px",
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      backgroundColor: theme.palette.sub.main,
      direction: props => (props.direction ? "rtl" : "ltr")
    }
  }),
  { name: "CCToolTip" }
);

const CCTooltip = props => {
  const { children, className, ...others } = props;
  const { findArabic } = useFindArabic();
  const classes = useStyle({ direction: findArabic(children) });
  return (
    <Tooltip
      {...others}
      // open={true}
      // className={clsx(classes.tooltip, className)}
      classes={{ tooltip: className || classes.tooltip }}
    >
      {children}
    </Tooltip>
  );
};

CCTooltip.propTypes = {
  /** hover시 출력될 text 및 element */
  // title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default CCTooltip;
