import React from "react";
import NumberFormat from "react-number-format";

const MAX_VAL = 999999999999;
const withValueLimit = inputObj => {
  const { value } = inputObj;
  if (value < MAX_VAL) return inputObj;
};

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isAllowed={withValueLimit}
      decimalScale={2}
      isNumericString
    />
  );
};

export default NumberFormatCustom;
