import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVTextField } from "components";
import React, { useCallback, useEffect, useRef, useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  label: {
    width: "calc(100% - 32px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  shrunk: {
    width: "calc(100% - 9px) !important"
  },
  tooltip: {
    // left: "-10px"
    zIndex: 1
    // transform: "translateZ(-1px)"
  },
  disabled: {
    // position: "relative"
    // "&.Mui-disabled": {
    //   pointerEvents: "auto"
    // }
  }
}));

const TextFieldTooltip = props => {
  const { label, InputLabelProps, className, value, ...others } = props;

  const classes = useStyles();

  const textElementRef = useRef();

  const compareSize = useCallback(() => {
    if (label && textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  }, [textElementRef, label]);

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, [compareSize]);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    [compareSize]
  );

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  // console.log(value);
  return (
    <Tooltip
      title={label ? label : ""}
      interactive
      disableHoverListener={!hoverStatus}
      disableFocusListener={!hoverStatus}
      classes={{ popper: clsx({ [classes.tooltip]: isSelected }) }}
      placement="top"
    >
      <span>
        <CVTextField
          className={clsx(className, classes.disabled)}
          SelectProps={{
            onClose: () => setIsSelected(false),
            onOpen: () => setIsSelected(true)
          }}
          InputLabelProps={{
            ...InputLabelProps,
            classes: {
              ...InputLabelProps?.classes,
              root: clsx(InputLabelProps?.classes?.root, classes.label),
              shrink: clsx(InputLabelProps?.classes?.shrink, classes.shrunk)
            },
            ref: textElementRef
          }}
          label={label}
          value={value}
          {...others}
        />
      </span>
    </Tooltip>
  );
};

export default TextFieldTooltip;
