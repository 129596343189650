import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { flatten } from "lodash";
import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";
import { useIntl } from "react-intl";
import { useFindArabic } from "../../../locales/utils";

const margin = 8;
const selectHeight = 20;

const baseStyles = makeStyles(
  theme => ({
    root: {},
    startAdornment: {
      marginRight: 0
    },
    endAdornment: {
      marginLeft: 0
    },
    startIconButton: {
      padding: 5
    },
    iconButton__focus: {
      color: theme.palette.select.main
    },
    endIconButton: {
      padding: 5
    },
    fullWidth: {
      width: `calc(100% - ${margin * 2}px)`
    },
    input__disabled: {
      "&.Mui-disabled": {
        backgroundColor: theme.palette.inactive.rgba
      },
      "& > input": {
        "&.Mui-disabled": {
          backgroundColor: "unset"
        }
      }
    },
    input__ellipsis: { textOverflow: "ellipsis", overflow: "hidden" },
    arabic_fix: {
      direction: "rtl",
      textAlign: "left"
    }
  }),
  { name: "CCTextField" }
);

const selectStyles = makeStyles(
  () => ({
    root: {},
    select: {
      height: selectHeight,
      lineHeight: `${selectHeight}px`
    },
    outlined: {},
    selectMenu: {},
    disabled: {}
  }),
  { name: "CCTextField" }
);

const useLocaleStyles = makeStyles(
  () => ({
    notchedOutline:
      localStorage.getItem("lang") === "ru"
        ? {
            "&  legend": {
              fontSize: `${0.75}em !important`
            }
          }
        : {},
    marginDense:
      localStorage.getItem("lang") === "ru"
        ? {
            fontSize: `${12}px !important`
          }
        : {},
    formControl:
      localStorage.getItem("lang") === "ru"
        ? {
            fontSize: `${0.75}em !important`
          }
        : null
  }),
  { name: "CCTextField" }
);

const p2e = s => s.replace(/[۰-۹]/g, d => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
const a2e = s => s.replace(/[٠-٩]/g, d => "٠١٢٣٤٥٦٧٨٩".indexOf(d));

const CCTextField = forwardRef((props, ref) => {
  const {
    children,
    classes,
    className,
    disabled,
    endIcon,
    fullWidth,
    InputLabelProps,
    InputProps,
    onClickIcon,
    SelectProps,
    startIcon,
    onBlur,
    onFocus,
    onChange,
    error,
    inputProps,
    multiline,
    maxLength = 500,
    ...other
  } = props;

  const baseClasses = baseStyles();
  const selectClasses = selectStyles();
  const localeClasses = useLocaleStyles();
  const DEFAULT_MAX_LENGTH = Boolean(multiline) ? maxLength : 200;
  const { findArabic } = useFindArabic();
  const [focuse, setFocuse] = useState(false);
  const intl = useIntl();
  const InputMore = {};

  if (InputProps) {
    if (InputProps.startAdornment !== "undefined")
      InputMore.startAdornment = InputProps.startAdornment;
    if (InputProps.endAdornment !== "undefined")
      InputMore.endAdornment = InputProps.endAdornment;
    if (InputProps.className !== "undefined")
      InputMore.className = InputProps.className;
  }
  const currentLabel = (() => {
    if (intl?.locale !== "ar" || !Boolean(props?.select)) {
      return null;
    }
    if (props.children?.length > 0) {
      let _children = flatten(props.children);
      let _res = _children.filter(item => item?.props?.value === props?.value);
      if (_res?.length > 0) {
        if (
          _res.length > 0 &&
          _res[0]?.props?.children &&
          typeof _res[0].props.children === "string"
        ) {
          return _res[0].props.children;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  })();

  return (
    <TextField
      ref={ref}
      multiline={Boolean(multiline)}
      error={Boolean(error)}
      onChange={e => {
        const value =
          typeof e?.target?.value === "string"
            ? String(e?.target?.value || "")
            : e.target.value;
        const normalize = Boolean(value?.normalize)
          ? value.normalize("NFC")
          : "";
        if (normalize && typeof e?.target?.value === "string") {
          e.target.value = normalize;
        }
        if (typeof e?.target?.value === "string") {
          e.target.value = a2e(e.target.value);
          e.target.value = p2e(e.target.value);
        }

        onChange && onChange(e);
      }}
      classes={classes}
      className={clsx(className, {
        [baseClasses.fullWidth]: fullWidth
      })}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          marginDense: clsx({
            [localeClasses.marginDense]: Boolean(localeClasses?.marginDense)
          }),
          formControl: clsx({
            [localeClasses.formControl]: Boolean(localeClasses?.formControl)
          }),
          ...InputLabelProps?.classes
        }
      }}
      inputProps={{
        maxLength: DEFAULT_MAX_LENGTH,
        ...inputProps
      }}
      InputProps={{
        ...InputProps,
        classes: {
          input: clsx({
            [baseClasses.input__ellipsis]: !multiline,
            [baseClasses.arabic_fix]: intl?.locale === "ar"
          }),
          ...InputProps?.classes
          // notchedOutline: clsx({
          //   [localeClasses.notchedOutline]: Boolean(
          //     localeClasses?.notchedOutline
          //   )
          // })
        },
        className: InputMore.className
          ? clsx(InputProps.className, {
              [baseClasses.input__disabled]:
                startIcon ||
                endIcon ||
                InputMore.startAdornment ||
                InputMore.endAdornment
            })
          : clsx({
              [baseClasses.input__disabled]:
                startIcon ||
                endIcon ||
                InputMore.startAdornment ||
                InputMore.endAdornment
            }),
        startAdornment: InputMore.startAdornment ? (
          InputMore.startAdornment
        ) : startIcon ? (
          <InputAdornment
            className={baseClasses.startAdornment}
            position="start"
          >
            <IconButton
              className={clsx({
                [baseClasses.startIconButton]: true,
                [baseClasses.iconButton__focus]: focuse
              })}
              onClick={onClickIcon}
              edge="start"
              disabled={disabled}
            >
              {startIcon}
            </IconButton>
          </InputAdornment>
        ) : null,
        endAdornment: InputMore.endAdornment ? (
          InputMore.endAdornment
        ) : endIcon ? (
          <InputAdornment className={baseClasses.endAdornment} position="end">
            <IconButton
              className={clsx({
                [baseClasses.endIconButton]: true,
                [baseClasses.iconButton__focus]: focuse
              })}
              onClick={onClickIcon}
              edge="end"
              disabled={disabled}
            >
              {endIcon}
            </IconButton>
          </InputAdornment>
        ) : null,
        fullWidth: fullWidth
      }}
      SelectProps={{
        ...SelectProps,
        classes: selectClasses,
        className: clsx(
          {
            [baseClasses.arabic_fix]:
              intl?.locale === "ar" && findArabic(currentLabel)
          },
          SelectProps?.className
        )
      }}
      onFocus={e => {
        onFocus && onFocus(e);
        setFocuse(true);
      }}
      onBlur={e => {
        onBlur && onBlur(e);
        setFocuse(false);
      }}
      disabled={disabled}
      {...other}
    >
      {children}
    </TextField>
  );
});

CCTextField.defaultProps = {
  fullWidth: false
};

CCTextField.propTypes = {
  /** TextField 오른쪽에 위치할 Icon */
  endIcon: PropTypes.element,
  /** Icon 클릭 시 실행되는 함수 */
  onClickIcon: PropTypes.func,
  /** TextField 왼쪽에 위치할 Icon */
  startIcon: PropTypes.element
};

export default CCTextField;
