import { useQuery } from "@apollo/react-hooks";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  CVButton,
  DetailsDialog,
  Graph,
  YearMonthPicker,
  YearPicker
  // YearWeekPicker
} from "components";
import {
  defectiveProsthesesStructure,
  orderedProsthesesStructure,
  parseDefectiveProsthesesData,
  parseOrderedProsthesesData
} from "graphs/orderedProstheses";
import { parseOrdersData, salesStructure } from "graphs/orders";
import {
  parseSalesEstimatesData,
  salesEstimatesStructure
} from "graphs/salesEstimates";
import moment from "moment";
import {
  DEFECTIVE_PROTHESES_STATISTICS,
  ORDER_PROSTHESES_STATISTICS,
  ORDER_STATISTICS,
  SALES_ESTIMATES_STATISTICS,
  ORDER_PROSTHESES_STATISTICS_DETAIL,
  DEFECTIVE_PROTHESES_STATISTICS_DETAIL
} from "queries/statistics";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCDialogContent,
  CCDialogTitle,
  CCPaper,
  CCTypography
} from "styles/components";
import { SearchIcon } from "styles/icons";
import {
  generateOrderedProsthesesGraph,
  generateOrdersPieGraph,
  generatePatientBarGraph,
  generateProsthesesGraph,
  generateSalesBarGraph
} from "types/graph";
import {
  patientHeader,
  salesHeader,
  uninsuredPatientHeader,
  uninsuredSalesHeader
} from "utils/table";
import { CCFlattenDateField, translate } from "../../../../../components";
import { monthYearFormat, shortDateFormat } from "../../../../../utils/utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "calc(100% - 80px - 24px)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "row",
    overflow: "auto",
    margin: "16px 16px 0 16px"
  },
  paper: {
    height: "calc(100% - 2px)",
    width: "1696px",
    overflow: "auto"
  },
  content: { width: "100%", overflow: "auto" },
  container: { height: "100%", width: "1696px" },
  date__time: {
    position: "absolute",
    right: "16px",
    top: 72,
    padding: "8px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  row__options: {
    display: "flex",
    padding: "6px 16px",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  field__date: {
    width: "130px",
    margin: "0px 8px 0px 0px",
    backgroundColor: theme.palette.common.white
  },
  tab: {
    // width: "70px",
    height: "30px",
    display: "flex",
    borderRadius: "18px",
    backgroundColor: theme.palette.sub.main,
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px"
  },
  selected: {
    backgroundColor: theme.palette.select.main
  },
  tab__text: { color: theme.palette.common.white },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    margin: "0px 8px"
  },
  button__search: {
    height: "36px",
    minwidth: "81px",
    padding: "8px",
    alignItems: "center",
    color: theme.palette.common.white
  },
  row__header: {
    height: "88px",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    alignItems: "center"
  },
  item__header: {
    // height: 'calc(100% - 16px)',
    width: "180px",
    display: "flex",
    padding: "8px 16px",
    flexDirection: "column",
    justifyContent: "space-around",
    borderRight: `1px solid ${theme.palette.border.main}`,
    color: theme.palette.sub.main
  },
  item__header__date: {
    borderColor: theme.palette.secondary.main
  },
  item__header__sales: {
    borderColor: theme.palette.error.main
  },
  item__header__long: {
    width: "300px"
  },
  item__header__end: {
    borderRight: `0px`
  },
  container__graph: {
    // height: 'calc(100% - 65px)',
    overflow: "auto",
    backgroundColor: theme.palette.background.default
  },
  paper__graph: { height: "325px", width: "800px", margin: "16px" },
  cell: {
    display: "flex",
    height: "36px",
    alignItems: "center",
    justifyContent: "center"
  },
  cell__selected: {
    backgroundColor: "rgba(64, 196, 255,0.16)",
    borderTop: "1px solid #2196f3",
    borderBottom: "1px solid #2196f3"
  },
  button__today: {
    padding: 0,
    marginRight: 8
  }
}));

const formatDateLabel = (value, option) => {
  const weekOfMonth = input => {
    const firstDayOfMonth = input.clone().startOf("month");
    const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

    const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

    return Math.ceil((input.date() + offset) / 7);
  };

  if (value === "") {
    return value;
  }

  if (option === "day") {
    const lang =
      localStorage.getItem("lang") ||
      process.env.REACT_APP_DEFAULT_LANGUAGE ||
      "en";
    let format = shortDateFormat(lang)
    return moment(value, "YYYYMMDD").format(format);
  }
  if (option === "week") {
    const date = moment(value, "YYYYMMDD").endOf("week");
    const month = date.format("M");
    const week = weekOfMonth(date);
    const lang =
      localStorage.getItem("lang") ||
      process.env.REACT_APP_DEFAULT_LANGUAGE ||
      "en";
    return lang === "ko"
      ? `${month}월 ${week}주`
      : `Month ${month} Week ${week}`;
  }
  if (option === "month") {
    const lang =
      localStorage.getItem("lang") ||
      process.env.REACT_APP_DEFAULT_LANGUAGE ||
      "en";
    let format = monthYearFormat(lang)
    return moment(value, "YYYYMMDD").format(format);
  }
  if (option === "year") {
    return moment(value, "YYYYMMDD").format("YYYY");
  }
};

const formatDateRangeTitle = (value, option) => {
  // const weekOfMonth = input => {
  //   const firstDayOfMonth = input.clone().startOf("month");
  //   const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

  //   const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

  //   return Math.ceil((input.date() + offset) / 7);
  // };

  if (option === "day") {
    const label = moment.unix(value).format("L");
    return `${label}~${label}`;
  }
  if (option === "week") {
    const date = moment.unix(value).endOf("week");
    const firstDateOfWeek = date.diff(6, "days");
    return `${firstDateOfWeek.format("L")}~${date.format("L")}`;
  }
  if (option === "month") {
    const month = moment.unix(value).startOf("month").format("L");
    const date = moment.unix(value).endOf("month");
    return `${month}~${date.format("L")}`;
  }
  if (option === "year") {
    const startOfYear = moment.unix(value).startOf("year").format("L");
    const endOfYear = moment.unix(value).endOf("year").format("L");
    return `${startOfYear}~${endOfYear}`;
  }
};

const formatDateTitle = (value, option) => {
  const weekOfMonth = input => {
    const firstDayOfMonth = input.clone().startOf("month");
    const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

    const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

    return Math.ceil((input.date() + offset) / 7);
  };

  if (option === "day") {
    return moment.unix(value).format("L");
  }
  if (option === "week") {
    const date = moment.unix(value).endOf("week");
    const year = date.format("YYYY");
    const month = date.format("M");
    const week = weekOfMonth(date);
    const lang =
      localStorage.getItem("lang") ||
      process.env.REACT_APP_DEFAULT_LANGUAGE ||
      "en";
    return lang === "ko"
      ? `${year}년 ${month}월 ${week}주`
      : `Year ${year} Month ${month} Week ${week} `;
  }
  if (option === "month") {
    const lang =
      localStorage.getItem("lang") ||
      process.env.REACT_APP_DEFAULT_LANGUAGE ||
      "en";
    const formatMonth = monthYearFormat(lang)
    return moment.unix(value).format(formatMonth);
  }
  if (option === "year") {
    const lang =
      localStorage.getItem("lang") ||
      process.env.REACT_APP_DEFAULT_LANGUAGE ||
      "en";
    return lang === "ko"
      ? moment.unix(value).format("YYYY년")
      : moment.unix(value).format("YYYY");
  }
};

const SalesManagement = () => {
  const classes = useStyles();
  const intl = useIntl();
  const periodSelectors = [
    { value: "day", label: translate(intl, "common.ui.day"), valueEnum: "DAY" },
    {
      value: "month",
      label: translate(intl, "common.ui.month"),
      valueEnum: "MONTH"
    },
    {
      value: "year",
      label: translate(intl, "common.ui.year"),
      valueEnum: "YEAR"
    }
  ];
  const today = moment.utc(moment().format("L"), "L").unix();
  const [timeSelection, setTimeSelection] = useState(0);
  const [selectedDate, setSelectedDate] = useState(today);
  // const [currentDate, setCurrentDate] = useState(moment().unix());
  const [openDialog, setOpenDialog] = useState(false);
  // const [openSalesDialog, setOpenSalesDialog] = useState(false);
  const [currentGraphParameters, setCurrentGraphParameters] = useState(
    salesEstimatesStructure(intl)
  );
  const [currentHeader, setCurrentHeader] = useState(patientHeader(classes));
  const [stateOrdersData, setOrdersData] = useState([]);
  const [stateSalesEstimates, setSalesEstimates] = useState([]);
  const [stateOrderedProsthesesData, setOrderedProsthesesData] = useState([]);
  const [stateDefectiveProsthesesData, setDefectiveProsthesesData] = useState(
    []
  );

  const { data: dataSalesEstimates, fetchMore: fetchMoreSalesEstimates } =
    useQuery(SALES_ESTIMATES_STATISTICS, {
      variables: {
        input: {
          currentDate: selectedDate,
          periodTime: periodSelectors[timeSelection].valueEnum,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    });
  const { data: dataOrders, fetchMore: fetchMoreOrders } = useQuery(
    ORDER_STATISTICS,
    {
      variables: {
        input: {
          currentDate: selectedDate,
          periodTime: periodSelectors[timeSelection].valueEnum,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    }
  );
  const { data: dataOrderedProstheses } = useQuery(
    ORDER_PROSTHESES_STATISTICS,
    {
      variables: {
        input: {
          currentDate: selectedDate,
          periodTime: periodSelectors[timeSelection].valueEnum,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    }
  );
  const { data: dataDefectiveProstheses } = useQuery(
    DEFECTIVE_PROTHESES_STATISTICS,
    {
      variables: {
        input: {
          currentDate: selectedDate,
          periodTime: periodSelectors[timeSelection].valueEnum,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    }
  );

  useEffect(() => {
    setOrdersData(dataOrders?.ordersStatistics);
    setSalesEstimates(dataSalesEstimates?.salesEstimatesStatistics);
    setOrderedProsthesesData(dataOrderedProstheses?.orderProsthesesStatistics);
    setDefectiveProsthesesData(
      dataDefectiveProstheses?.defectiveProthesesStatistics
    );
  }, [
    dataDefectiveProstheses,
    dataOrderedProstheses,
    dataOrders,
    dataSalesEstimates
  ]);

  const timeInputFields = [
    <CCFlattenDateField
      label=""
      className={classes.field__date}
      value={moment.unix(selectedDate)}
      onChange={e => {
        setSelectedDate(moment.utc(e, "L").unix());
      }}
    />,
    <YearMonthPicker
      value={selectedDate}
      onChange={e => {
        setSelectedDate(e.target.value);
      }}
    />,
    <YearPicker
      value={selectedDate}
      onChange={e => {
        setSelectedDate(e.target.value);
      }}
    />
  ];

  const dateOption = useMemo(() => {
    //const options = ["day", "week", "month", "year"];
    return periodSelectors[timeSelection].value;
  }, [periodSelectors, timeSelection]);

  const graphLabels = useMemo(() => {
    const date = moment.unix(selectedDate).startOf(dateOption);
    let startIndex = -8;
    if (dateOption === "year") {
      startIndex = -3;
    }

    const labels = [];
    for (let i = startIndex; i < 1; i++) {
      if (i < 0) {
        const fieldDate = date.clone().subtract(Math.abs(i), dateOption);
        labels.push(fieldDate.format("YYYYMMDD"));
      } else if (i > 0) {
        const fieldDate = date.clone().add(Math.abs(i), dateOption);
        labels.push(fieldDate.format("YYYYMMDD"));
      } else {
        labels.push(date.format("YYYYMMDD"));
      }
    }
    return labels;
  }, [dateOption, selectedDate]);

  const salesEstimatesGraph = useMemo(() => {
    const series = parseSalesEstimatesData(
      stateSalesEstimates,
      graphLabels,
      "",
      intl
    );

    return generatePatientBarGraph(series, graphLabels, value =>
      formatDateLabel(value, dateOption)
    );
  }, [stateSalesEstimates, graphLabels, intl, dateOption]);

  const ordersColumnGraph = useMemo(() => {
    const series = parseOrdersData(stateOrdersData, graphLabels, intl);
    return generateSalesBarGraph(series, graphLabels, value =>
      formatDateLabel(value, dateOption)
    );
  }, [stateOrdersData, graphLabels, intl, dateOption]);

  const ordersPie = useMemo(() => {
    let now = moment().locale('en').format("YYYY-MM-DD");
    if (dateOption === "day") {
      now = moment.unix(selectedDate).locale('en').format("YYYY-MM-DD");
    } else if (dateOption === "month") {
      now = moment.unix(selectedDate).locale('en').format("YYYY-MM");
    } else if (dateOption === "year") {
      now = moment.unix(selectedDate).locale('en').format("YYYY");
    }
    const pieData = stateOrdersData?.find(item => item.time === now) || {};

    return generateOrdersPieGraph(
      [pieData?.new || 0, pieData?.mend || 0, pieData?.remake || 0],
      pieData.total || 0,
      intl
    );
  }, [intl, stateOrdersData, dateOption, selectedDate]);

  const orderedProsthesesGraph = useMemo(() => {
    const series = parseOrderedProsthesesData(stateOrderedProsthesesData, intl);
    const labels =
      stateOrderedProsthesesData?.length > 0
        ? stateOrderedProsthesesData
          .sort((a, b) => {
            if (a.value > b.value) return -1;
            if (a.value < b.value) return 1;
            return 0;
          })
          .slice(0, 5)
          .map(item => item.category)
        : [];

    return generateOrderedProsthesesGraph(series, labels, value =>
      value?.length > 20 ? `${value.slice(0, 15)}...` : value
    );
  }, [intl, stateOrderedProsthesesData]);

  const defectiveProsthesesGraph = useMemo(() => {
    const series = parseDefectiveProsthesesData(
      stateDefectiveProsthesesData,
      intl
    );
    const labels =
      stateDefectiveProsthesesData?.length > 0
        ? stateDefectiveProsthesesData
          .sort((a, b) => {
            if (a.defective > b.defective) return -1;
            if (a.defective < b.defective) return 1;
            return 0;
          })
          .slice(0, 5)
          .map(item => item.category)
        : [];
    return generateProsthesesGraph(series, labels, value =>
      value?.length > 20 ? `${value.slice(0, 15)}...` : value
    );
  }, [stateDefectiveProsthesesData, intl]);
  const selectedDateRangeLabel = formatDateRangeTitle(selectedDate, dateOption);
  //  moment(selectedDate, "X").subtract(9, "days").format("YYYY/MM/DD")}{ " "}~{ moment(selectedDate, "X").format("YYYY/MM/DD")

  return (
    <Box className={classes.root}>
      <CCPaper className={classes.paper}>
        <CCDialogTitle>
          {translate(intl, "statistics")}
          <CCTypography
            // variant="h4"
            className={clsx({
              [classes.date__time]: true
            })}
          >
            {selectedDateRangeLabel}
          </CCTypography>
        </CCDialogTitle>
        <CCDialogContent className={classes.content} noPadding>
          <Grid className={classes.container} container direction="column">
            <Grid item>
              <Grid container className={classes.row__options} direction="row">
                {periodSelectors.map((value, index) => {
                  return (
                    <Grid key={"selector-" + index} item>
                      <CVButton
                        className={clsx({
                          [classes.tab]: true,
                          [classes.selected]: timeSelection === index
                        })}
                        onClick={() => setTimeSelection(index)}
                      >
                        <CCTypography className={classes.tab__text}>
                          {value.label}
                        </CCTypography>
                      </CVButton>
                    </Grid>
                  );
                })}
                <Grid item>
                  <Box className={classes.divider} />
                </Grid>
                <Grid item>{timeInputFields[timeSelection]}</Grid>
                <CVButton
                  className={classes.button__today}
                  variant={"outlined"}
                  // color="secondary"
                  onClick={e => {
                    setSelectedDate(today);
                    // setTimeSelection(0);
                  }}
                >
                  {translate(intl, "labManageGold.today")}
                </CVButton>
                <Grid item>
                  <CVButton
                    width="auto"
                    className={classes.button__search}
                    variant="contained"
                    color="secondary"
                    startIcon={
                      false ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <SearchIcon />
                      )
                    }
                  >
                    {translate(intl, "common.ui.search")}
                  </CVButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classes.container__graph}
              container
              direction="row"
            >
              <Grid item>
                <Box flexDirection="column">
                  <Graph
                    title={translate(intl, "menuStatistics.salesEstimates")}
                    barGraphSetting={salesEstimatesGraph}
                    onDetailClick={() => {
                      setCurrentGraphParameters({
                        ...salesEstimatesStructure(intl),
                        query: SALES_ESTIMATES_STATISTICS,
                        dataName: "salesEstimatesStatistics",
                        groupBy: "clinic"
                      });
                      setCurrentHeader(patientHeader(classes));
                      setOpenDialog(true);
                    }}
                    getMoreData={fetchMoreSalesEstimates}
                    periodTime={periodSelectors[timeSelection].valueEnum}
                    currentDate={selectedDate}
                  />
                  <Graph
                    title={translate(intl, "menuStatistics.orderedProstheses")}
                    barGraphSetting={orderedProsthesesGraph}
                    onDetailClick={() => {
                      setCurrentGraphParameters({
                        ...orderedProsthesesStructure(intl),
                        query: ORDER_PROSTHESES_STATISTICS_DETAIL,
                        dataName: "orderProsthesesDetailStatistics",
                      });
                      setCurrentHeader(uninsuredPatientHeader(classes));
                      setOpenDialog(true);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Graph
                    title={translate(intl, "menuStatistics.orders")}
                    titlePie={formatDateTitle(selectedDate, dateOption)}
                    barGraphSetting={ordersColumnGraph}
                    pieGraphSetting={ordersPie}
                    onDetailClick={() => {
                      setCurrentGraphParameters({
                        ...salesStructure(intl),
                        query: ORDER_STATISTICS,
                        dataName: "ordersStatistics",
                        groupBy: "clinic"
                      });
                      setCurrentHeader(salesHeader(classes));
                      setOpenDialog(true);
                    }}
                    getMoreData={fetchMoreOrders}
                    periodTime={periodSelectors[timeSelection].valueEnum}
                    currentDate={selectedDate}
                  />
                  <Graph
                    title={translate(
                      intl,
                      "menuStatistics.defectiveProstheses"
                    )}
                    barGraphSetting={defectiveProsthesesGraph}
                    onDetailClick={() => {
                      setCurrentHeader(uninsuredSalesHeader(classes));
                      setCurrentGraphParameters({
                        ...defectiveProsthesesStructure(intl),
                        query: DEFECTIVE_PROTHESES_STATISTICS_DETAIL,
                        dataName: "defectiveProthesesDetailStatistics"
                      });
                      setOpenDialog(true);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </CCDialogContent>
      </CCPaper>
      {openDialog && (
        <DetailsDialog
          open={openDialog}
          dateSelection={timeSelection}
          selectedDate={selectedDate}
          onClose={() => setOpenDialog(false)}
          header={currentHeader}
          periodList={periodSelectors}
          query={SALES_ESTIMATES_STATISTICS}
          dataName={"salesEstimatesStatistics"}
          {...currentGraphParameters}
        />
      )}
    </Box>
  );
};

export default SalesManagement;
