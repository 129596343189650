import React from "react";

const CommitnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="kdbw0hkyma" x1="50%" x2="50%" y1="42.158%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
      <linearGradient id="4mxpd349kb" x1="50%" x2="50%" y1="50%" y2="100%">
        <stop offset="0%" stopColor="#00E1D1" />
        <stop offset="100%" stopColor="#00BFA5" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#FFF"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-606.000000, -369.000000) translate(606.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M19 2c6.627 0 12 5.373 12 12v4c0 3.038-1.129 5.812-2.99 7.926l-4.52-4.521 1.96-1.961.087-.097c.318-.403.318-.977 0-1.38l-.086-.097-2.544-2.544-.097-.087c-.402-.319-.977-.319-1.38 0l-.098.086-6.008 6.008-.086.097c-.318.403-.317.977 0 1.38l.087.097 2.544 2.544.094.084c.158.126.344.205.542.232l.151.01.129-.007c.2-.023.387-.099.545-.22l.113-.1 1.961-1.96 4.4 4.397C23.87 29.22 21.525 30 19 30h-6C6.373 30 1 24.627 1 18v-4C1 7.373 6.373 2 13 2h6zm-4 24l.229.928c.156.627.683 1.011 1.25 1.065l.132.007.143-.006c.431-.04.856-.275 1.135-.741l.079-.147.032-.08-.168.098c-.235.116-.5.179-.774.18-.398 0-.774-.132-1.078-.372l-.125-.11L15 26zM7.305 5.001h-.217c-2.234.053-4.386 1.703-5.035 4.796-.165.787.055 1.949.66 3.484l.458 1.137c.868 2.238 1.528 4.554 1.97 6.918l.14.79.069.408.173 1.005.16.887.147.767.134.648.082.365.076.313.07.26.073.23c.224.636.676.945 1.135.986l.114.005.124-.007c.451-.05.871-.351 1.042-.845l.04-.14 1.789-7.758c.174-.567.698-.984 1.298-1.017l.41.001c.544.04 1.023.395 1.229.89l.045.126.18.784 6.771-6.924c.296-.3.685-.449 1.073-.442.43-1.235.574-2.192.432-2.871C21.297 6.7 19.14 5.05 16.905 5h-.21l-.216.01c-.854.059-1.706.351-2.477.886l-.401.292c-.117.078-.24.147-.365.207l-.192.082.788.569.08.07c.17.177.215.445.112.67l-.053.093-.08.095c-.053.05-.112.09-.176.117l-.097.034-.102.017c-.103.007-.208-.011-.306-.058l-.094-.056L10.99 6.49c-.203-.076-.399-.175-.582-.297l-.451-.327c-.826-.56-1.742-.845-2.651-.866zm20.869 7.318l-1.932.518.517 1.931 1.932-.517-.517-1.932zm-3.157-.19l-1.932.518.518 1.932 1.932-.518-.518-1.932zm1.19-4.156l-1.932.517.517 1.932 1.932-.517-.517-1.932z"
            transform="translate(-606.000000, -369.000000) translate(606.000000, 369.000000)"
          />
          <path
            fill="url(#kdbw0hkyma)"
            d="M15 26l.855.823c.322.31.749.48 1.203.48.275 0 .539-.063.774-.18l.168-.097-.032.08c-.273.564-.74.844-1.214.888L16.61 28c-.616-.003-1.214-.396-1.382-1.072L15 26zM7.305 5.001c.91.02 1.825.306 2.65.866l.272.197c.234.184.491.326.762.427l2.127 1.538c.121.087.263.124.4.114l.102-.017c.1-.025.194-.076.272-.151l.081-.095c.166-.24.135-.56-.059-.763l-.08-.07-.788-.569c.194-.075.381-.171.557-.289l.172-.125c.83-.641 1.767-.988 2.706-1.053l.216-.01c2.31-.052 4.582 1.603 5.252 4.796.142.679-.002 1.636-.432 2.87-.388-.006-.777.142-1.073.443l-6.77 6.924-.18-.784c-.173-.56-.686-.973-1.276-1.016l-.27-.002c-.656-.03-1.25.407-1.437 1.018L8.72 27.008c-.136.577-.59.931-1.083.985L7.514 28c-.496.003-1.007-.302-1.25-.992l-.072-.229-.07-.26-.076-.313-.082-.365-.134-.648-.147-.767-.16-.887-.173-1.005-.069-.408c-.438-2.638-1.146-5.221-2.11-7.708l-.331-.825c-.7-1.69-.963-2.956-.787-3.796C2.702 6.704 4.854 5.054 7.088 5h.217zm17.712 7.128l.518 1.932-1.932.518-.518-1.932 1.932-.518zm1.19-4.156l.517 1.932-1.932.517-.517-1.932 1.932-.517z"
            transform="translate(-606.000000, -369.000000) translate(606.000000, 369.000000)"
          />
          <path
            fill="url(#4mxpd349kb)"
            d="M21.332 15.325c.405-.403 1.042-.431 1.478-.086l.097.087 2.544 2.544c.402.403.43 1.04.086 1.477l-.086.097-1.961 1.96 5.658 5.66c.06.06.123.114.188.166l.285.224c.21.173.379.357.379.651 0 .507-.197.983-.556 1.34-.356.358-.832.555-1.338.555-.295 0-.479-.168-.652-.379l-.224-.285-.08-.096-.086-.092-5.66-5.659-1.96 1.961c-.18.18-.41.291-.66.32l-.128.007c-.254 0-.496-.085-.693-.242l-.094-.084-2.544-2.544c-.402-.403-.431-1.04-.087-1.477l.086-.097 6.008-6.008zm6.842-3.006l.517 1.932-1.932.517-.517-1.931 1.932-.518z"
            transform="translate(-606.000000, -369.000000) translate(606.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default CommitnIcon;
