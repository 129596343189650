import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useState } from "react";
import { CCTabPanel, CCTabs } from "styles/components";
import { Stock, Purchase } from "./Component";
import { useIntl } from "utils/language";
import { PermissionBackdrop, useReadPermission } from "components";
import { AppContext } from "context";
const tabsHeight = 54;
const tabWidth = 306;

const useStyles = makeStyles(theme => ({
  root: { height: "100%", minWidth: 1500 },
  tabs: {
    borderBottom: `0px solid ${theme.palette.border}`,
    height: tabsHeight,
    backgroundColor: theme.palette.background.default,
    boxSizing: "border-box",
    minWidth: 1500,
    "& button:nth-child(1)": {
      width: tabWidth
    },
    "& button:nth-child(2)": {
      width: tabWidth
    }
  },
  panel: {
    height: `calc(100% - ${tabsHeight}px)`,
    overflow: "auto",
    backgroundColor: theme.palette.background.paper
  }
}));

const InventoryMain = props => {
  const { appContext } = useContext(AppContext);
  const { location } = props;
  const intl = useIntl();
  const classes = useStyles();
  const readPermission = useReadPermission("inventoryManagement");
  const [tabIndex, setTabIndex] = useState(0);

  const tabList = [
    {
      label: intl.formatMessage({ id: "inventoryManagement" }),
      component: () => <Stock keyId={location?.state?.keyId} />
    },
    // {
    //   label: intl.formatMessage({ id: "purchaseManagement" }),
    //   component: () => <Purchase />
    // }
  ];

  return (
    <Box className={classes.root}>
      {appContext.ready &&
        (!readPermission ? (
          <PermissionBackdrop permission={"inventoryManagement"} />
        ) : (
          <>
            <CCTabs
              className={classes.tabs}
              tabList={tabList.map(el => el.label)}
              selectedTab={tabIndex}
              onChangeTab={index => {
                setTabIndex(index);
              }}
            />
            {tabList.map((d, i) => (
              <CCTabPanel
                className={classes.panel}
                key={i}
                value={tabIndex}
                index={i}
              >
                {d.component()}
              </CCTabPanel>
            ))}
          </>
        ))
      }
    </Box>
  );
};

export default InventoryMain;
