import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { LabGoldTabSelector, StockManage, UsageHistory } from "./components";

const useStyles = makeStyles(() => ({
  tab__selector: {
    padding: "8px 16px"
  }
}));

const LabGold = () => {
  const classes = useStyles();

  const [panel, setPanel] = useState(0);

  const handleChangePanel = target => setPanel(target);

  return (
    <Box>
      <Box className={classes.tab__selector}>
        <LabGoldTabSelector panel={panel} onClick={handleChangePanel} />
      </Box>
      <Box hidden={panel !== 0}>
        <UsageHistory />
      </Box>
      <Box hidden={panel !== 1}>
        <StockManage />
      </Box>
    </Box>
  );
};

export default LabGold;
