import React from "react";

const AbbreviationIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              d="M9 11l-.007 7h-1.99v-4.902H2.006L2 11h7zm3.991-9v4.986h5.002L18 9h-7l.007-7h1.984z"
              transform="translate(-5056 -192) translate(5056 192) translate(2 2)"
            />
            <path
              d="M0 0H20V20H0z"
              transform="translate(-5056 -192) translate(5056 192) translate(2 2)"
            />
          </g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5056 -192) translate(5056 192)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default AbbreviationIcon;
