import React from "react";

const InvisibleeditIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M2.615 3l18.384 18.385-1.176 1.177-1.724-1.725-2.327 2.327H14V21.39l2.326-2.327-1.359-1.359-1.906 1.912c-.35.031-.704.047-1.061.047-5 0-9.27-3.11-11-7.5.755-1.915 1.992-3.586 3.563-4.862L1.438 4.177 2.615 3zm16.613 13.164L21 17.936l-.31.31-1.773-1.773.31-.31zM7.419 10.158C7.15 10.772 7 11.451 7 12.164c0 2.76 2.24 5 5 5 .713 0 1.392-.15 2.006-.42l-1.607-1.606c-.13.017-.264.026-.399.026-1.66 0-3-1.34-3-3 0-.135.009-.268.026-.399L7.42 10.158zm14.166 4.096l.07.06 1.196 1.195c.177.177.196.45.059.65l-.06.07-.934.935L20 15.248l.935-.935c.177-.177.45-.196.65-.059zM12 4.664c4.808 0 8.941 2.876 10.79 7H21l-3.445 3.448-.964-.964c.263-.609.409-1.28.409-1.984 0-2.76-2.24-5-5-5-.705 0-1.375.146-1.983.41L7.856 5.411C9.145 4.93 10.54 4.664 12 4.664zm0 4.5c1.66 0 3 1.34 3 3 0 .125-.008.248-.022.369L11.63 9.186c.121-.015.244-.022.369-.022z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default InvisibleeditIcon;
