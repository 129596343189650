import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { CCDialogSimple, CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {},
  contents: {
    display: "flex"
  },
  text: { alignSelf: "center", margin: `0 12px` }
}));

const CircularProgressWithLabel = props => {
  const { timeout = 10 } = props;
  const [progress, setProgress] = useState({ time: 0, percent: 0 });

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => {
        const nextProgress = prevProgress.percent + 100 / timeout;
        return {
          ...prevProgress,
          percent: nextProgress >= 100 ? 0 : nextProgress,
          time: prevProgress.time + 1
        };
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timeout]);

  const text =
    progress.time < timeout ? `${Math.round(progress.percent)}%` : `timeout`;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={progress.time < timeout ? progress.percent : 0}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CCTypography variant="caption" component="div" color="textSecondary">
          {text}
        </CCTypography>
      </Box>
    </Box>
  );
};

const DialogLoading = props => {
  const {
    open,
    onClose = () => {},
    text = "설정 업데이트 중...",
    timeout = 10
  } = props;
  const classes = useStyles();

  return (
    <CCDialogSimple
      open={open}
      isHeader={false}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <div className={classes.contents}>
          <CircularProgressWithLabel timeout={timeout} />
          <CCTypography className={classes.text}>{text}</CCTypography>
        </div>
      }
    />
  );
};

export default DialogLoading;
