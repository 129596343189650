import { useQuery } from "@apollo/react-hooks";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { PermissionBackdrop, useReadPermission } from "components";
import { AppContext } from "context";
import { PARTNER_LIST } from "queries/setting";
import React, { useContext, useReducer } from "react";
import LabOrderView from "views/Normal/DentalLab/components/LabOrderView";
import { initialState, reducer } from "views/Normal/DentalLab/reducer";
import { DesignConfirmationModal } from "./components/LabOrderModal/components";

const width = 930;
const padding = 12;
const minWidth = 900;
const useStyles = makeStyles(() => ({
  root: { height: "100%" },
  container: { height: "100%", flexWrap: "nowrap" },
  item__left: {
    // width: `calc(100% - ${width}px)`,
    width: "100%",
    minWidth: minWidth,
    padding: padding,
    boxSizing: "border-box"
  },
  item__right: {
    width: width,
    padding: padding
  }
}));

const DentalLab = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { appContext } = useContext(AppContext);
  const permission = "orders";
  const readPermission = useReadPermission(permission);

  useQuery(PARTNER_LIST, {
    variables: {
      query: { skip: 0, limit: 20, keyword: "" }
    },
    onCompleted: data => {
      dispatch({
        type: "SET_SETTING",
        name: "labSetting",
        value: data?.partnerList?.items
      });
    }
  });

  // 기공의뢰서 수정 및 재재작
  const onModify = ({ id, labState, typeState }) => {
    // switch (typeState) {
    //   case LAB_ORDER_TYPE.MODIFY_REQUEST.value:
    //   case LAB_ORDER_TYPE.RE_REQUEST.value:
    //     dispatch({
    //       type: 'SET_LAB_ORDER',
    //       value: {
    //         //patient: labState.patientId
    //         //name: labState.name,
    //         //chartNumber: labState.chartNumber,
    //         type: typeState,
    //         lab: labState.labId,
    //         connectedRequestId: labState.requestId,
    //         doctor: labState.doctorId,
    //         staff: labState.staffId,
    //         target: labState.labType,
    //       },
    //     });
    //     break;
    //   default:
    //   // getLabOrder({ variables: { args: { id: id } } });
    // }
  };

  return (
    <Box className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item className={classes.item__left}>
          {appContext.ready &&
          
            (!readPermission ? (
              <PermissionBackdrop permission={permission} />
            ) : (
              <LabOrderView
                permission={permission}
                onModify={onModify}
                state={state}
                dispatch={dispatch}
              />
            ))}
          <DesignConfirmationModal />
        </Grid>
      </Grid>
    </Box>
  );
};

DentalLab.defaultProps = {};

DentalLab.propTypes = {};

export default DentalLab;
