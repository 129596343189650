import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { CCTimeToolbar } from "./Component";

/**
 * @deprecated
 */
const CCTimePicker = props => {
  const { onChange, date, minutesStep } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TimePicker
        autoOk
        ampm={true}
        variant="determinate"
        openTo="hours"
        value={date}
        onChange={date => {
          onChange(date);
        }}
        minutesStep={minutesStep}
        ToolbarComponent={CCTimeToolbar}
      />
    </MuiPickersUtilsProvider>
  );
};
CCTimePicker.propTypes = {
  /**
   * Date Value( Moment Object, Only Using Time Value )
   */
  date: PropTypes.instanceOf(moment).isRequired,
  /**
   * Date Change Event Handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Minutes Select Step in Clock UI
   */
  minutesStep: PropTypes.number
};
CCTimePicker.defaultProps = {
  date: moment(),
  onChange: () => {},
  minutesStep: 1
};

export default CCTimePicker;
