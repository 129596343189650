import { AppContext } from "context/AppContext";
import { useContext, useMemo } from "react";
import { permissionList } from "types/permission";

const useReadPermission = category => {
  const { appContext } = useContext(AppContext);

  if (category && !permissionList.includes(category))
    console.error(`\`${category}\` is not in the list (${permissionList}).`);

  const permission = useMemo(() => {
    if (!category) return true;
    if (!appContext.permission) {
      return false;
    }
    if (category) {
      if (!appContext.permission.hasOwnProperty(category)) {
        return false;
      }
      return appContext.permission[category].read;
    }
    return false;
  }, [category, appContext.permission]);

  return permission;
};

export default useReadPermission;
