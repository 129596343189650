import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CCTypography from "styles/src/components/CCTypography";
import { useIntl } from "utils/language";
import {
  CartTab,
  EstimationTab,
  OrderReturnTab,
  SearchProductTab
} from "views/Normal/InventoryManagement/Component/Purchase/Component";
import axios from "axios";
import { DialogLoading } from "components";

const useStyle = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    minWidth: 1430
  },
  tabList: {
    padding: "9px 16px",
    borderBottom: `1px solid ${theme.palette.border}`
  },
  tabs: {
    padding: "0 10px",
    height: 36,
    minWidth: 113,
    borderRadius: 25,
    backgroundColor: theme.palette.grey[500],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 16,
    "& span": {
      color: theme.palette.common.white
    },
    "&:hover": {
      backgroundColor: theme.palette.select.hover
    }
  },
  selected: {
    backgroundColor: theme.palette.select.main
  }
}));

export const TabContext = createContext({
  setTabs: () => {
  },
  setProduct: () => {
  }
});

const Purchase = props => {
  const { keyId } = props;
  const classes = useStyle();
  const [tabs, setTabs] = useState("0");
  const [product, setProduct] = useState(keyId || "");
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  let history = useHistory();
  const tabList = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "estimateManagement" }),
        value: "0"
      },
      {
        label: intl.formatMessage({ id: "orderReturnList" }),
        value: "1"
      },
      {
        label: intl.formatMessage({ id: "searchProduct" }),
        value: "2"
      },
      {
        label: intl.formatMessage({ id: "cartOrder" }),
        value: "3"
      }
    ],
    [intl]
  );

  useEffect(() => {
    if (keyId) {
      setProduct({
        productID: keyId
      });
      setTabs("0");
      history.replace();
    }
  }, [keyId, history]);

  const fetchUsers = async () => {
    try {
      // setError(null);
      setLoading(true);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_PAM_URL}/api/v1/login/clinic`,
        data: {
          token: sessionStorage.getItem("token")
        }
      })
        .then(response => {
          sessionStorage.setItem("jwtToken", response?.data?.jwt);
          sessionStorage.setItem("clinicId", response?.data?.clinicId);
        })

        .catch(err => {
          console.log("LOGIN FAIL >>>", err);
          // console.log(err) :;
          //
          // data = err;
        });
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box className={classes.root}>
      <DialogLoading
        open={loading}
        text={"Loading"}
      />
      <Grid container className={classes.tabList}>
        {tabList.map(item => {
          return (
            <Grid
              key={JSON.stringify(item)}
              className={clsx(classes.tabs, {
                [classes.selected]: item.value === tabs
              })}
              item
              onClick={() => {
                setTabs(item.value);
                setProduct(null);
              }}
            >
              <CCTypography variant={"h5"} component={"span"}>
                {item.label}
              </CCTypography>
            </Grid>
          );
        })}
      </Grid>
      <TabContext.Provider value={{ setTabs, setProduct }}>
        {tabs === "0" && <EstimationTab product={product} />}
        {tabs === "1" && <OrderReturnTab product={product} />}
        {tabs === "2" && <SearchProductTab product={product} />}
        {/*{tabs === "3" && <ProductTab product={product} />}*/}
        {tabs === "3" && <CartTab product={product} />}
      </TabContext.Provider>
    </Box>
  );
};

export default Purchase;
