import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useIntl } from "react-intl";
import { WarningIcon } from "styles/icons";
import {
  CCButton,
  CCDialogSimple,
  CCTextField,
  CCTypography,
} from "styles/components";
import { translate } from "components";
import { validPassword } from "utils/password";
import { CVPasswordField } from "components/index";

const useStyles = makeStyles(theme => ({
  root: {},
  script: { color: theme.palette.sub.main, width: 320 },
  cancel: { marginRight: 4 },
  textfield: { marginTop: 16 },
  warning_color: { color: theme.palette.error.main }
}));

const DialogNewPassword = props => {
  const {
    open,
    onClose = () => { },
    onSave = e => {
      console.log(e);
    }
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [error, setError] = useState("");
  const [errorConfirmedPassword, setErrorConfirmedPassword] = useState(false);

  const handleSave = () => {
    if (!validPassword(password)) {
      setErrorPassword(true);
      setError(translate(intl, "common.message.changePasswordNote"))
    } else if (password !== confirmedPassword) {
      setErrorConfirmedPassword(true);
      setError(translate(intl, "signUp.error.confirmPassword"))
    } else {
      onSave(password);
    }
  };

  return (
    <>
      <CCDialogSimple
        title={intl.formatMessage({
          id: "signIn.passwordSetting.title"
        })}
        open={open}
        onClose={onClose}
        onCloseButton={onClose}
        contents={
          <>
            <CCTypography className={classes.script}>
              {translate(intl, "signIn.passwordSetting.setNewPassword")}
              <br />
              ({translate(intl, "signIn.passwordSetting.setNewPasswordNote")})
            </CCTypography>
            <div style={{ width: 300, marginTop: 16, overflow: "hidden" }}>
              <CVPasswordField
                className={classes.textfield}
                label={intl.formatMessage({
                  id: "signIn.passwordSetting.newPassword"
                })}
                variant={"outlined"}
                fullWidth
                required
                type="password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                  setErrorPassword(!validPassword(e.target.value));
                }}
                error={errorPassword}
              />
              <CVPasswordField
                className={classes.textfield}
                label={intl.formatMessage({
                  id: "signIn.passwordSetting.confirmedPassword"
                })}
                variant={"outlined"}
                fullWidth
                required
                type="password"
                value={confirmedPassword}
                onChange={e => {
                  setConfirmedPassword(e.target.value);
                  setErrorConfirmedPassword(
                    !(
                      password === e.target.value && validPassword(e.target.value)
                    )
                  );
                }}
                onBlur={e => {
                  setErrorConfirmedPassword(
                    !(
                      password === confirmedPassword &&
                      validPassword(confirmedPassword)
                    )
                  );
                }}
                error={errorConfirmedPassword}
              />
            </div>
          </>
        }
        endActions={
          <>
            <CCButton className={classes.cancel} color="sub" onClick={onClose}>
              {intl.formatMessage({
                id: "cancel"
              })}
            </CCButton>
            <CCButton variant="contained" color="primary" onClick={handleSave}>
              {intl.formatMessage({
                id: "save"
              })}
            </CCButton>
          </>
        }
      />

      <CCDialogSimple
        open={Boolean(error)}
        onClose={() => setError("")}
        title={<WarningIcon className={classes.warning_color} />}
        contents={
          <Box className={classes.text}>
            <CCTypography className={classes.warning_color}>
              {error}
            </CCTypography>
          </Box>
        }
        endActions={
          <CCButton
            className={classes.disagree}
            color="secondary"
            onClick={() => setError("")}
          >
            {intl.formatMessage({ id: "common.ui.ok" })}
          </CCButton>
        }
      />
    </>
  );
};

export default DialogNewPassword;
