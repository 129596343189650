import Grid from '@material-ui/core/Grid';
import React, { useEffect, useReducer } from 'react';
import { getFolderPathItemList } from 'utils/photo';
import FileViewer from '../FileViewer';
import { BackwardItem, FileItem, FilePath } from './component';

const initializer = (props) => {
  return {
    list: [],
    folderPath: [],
    folderList: [],
    currentPointer: props?.selected?.item,
    currentItem: null,
  };
};

function reducer(state, action) {
  switch (action.type) {
    case 'list':
      return {
        ...state,
        list: action.target
          .filter((item) => item.content.filename !== '/')
          .sort((a, b) => {
            if (
              a.content.filetype === 'folder' &&
              b.content.filetype === 'folder'
            ) {
              return b.date - a.date;
            }
            if (a.content.filetype === 'folder') {
              return -1;
            }
            if (b.content.filetype === 'folder') {
              return 1;
            }
            return b.date - a.date;
          }),
      };
    case 'currentPointer':
      return {
        ...state,
        currentPointer: { ...action.target },
        currentItem: null,
      };
    case 'folderPath':
      return {
        ...state,
        folderList: [...action.target],
        folderPath: [
          ...getFolderPathItemList([...action.target], {
            ...state.currentPointer,
          }),
        ],
      };
    case 'currentItem':
      return {
        ...state,
        currentItem: { ...action.target },
      };
    default:
      break;
  }
  return state;
}

const FileGrid = (props) => {
  const { patient, onClose, onFileSelect, selected, onClickItem } = props;
  const [
    { list, root, folderPath, currentPointer, folderList, currentItem },
    dispatchState,
  ] = useReducer(reducer, props, initializer);
  // const [getFolderList, { variables: folderListVariable }] = useLazyQuery(
  //   SEARCH_PHOTO,
  //   {
  //     fetchPolicy: "network-only",
  //     onCompleted: data => {
  //       dispatchState({
  //         type: "folderPath",
  //         target: [
  //           ...getDefaultFolders(
  //             folderListVariable.args.type,
  //             folderListVariable.args.patient
  //           ),
  //           ...data.searchPhoto
  //         ]
  //       });
  //     }
  //   }
  // );
  // const [getFileList, { variables }] = useLazyQuery(SEARCH_PHOTO, {
  //   fetchPolicy: "network-only",
  //   onCompleted: data => {
  //     // console.log("onCompleted", data);
  //     // console.log(
  //     //   getDefaultFolders(variables.args.type, variables.args.patient).filter(
  //     //     item => {
  //     //       console.log(item);
  //     //       console.log(variables);
  //     //       return item?.content?.folder === variables?.args?.content?.folder;
  //     //     }
  //     //   )
  //     // );
  //     dispatchState({
  //       type: "list",
  //       target: [
  //         ...getDefaultFolders(
  //           variables.args.type,
  //           variables.args.patient
  //         ).filter(item => {
  //           return item?.content?.folder === variables?.args?.content?.folder;
  //         }),
  //         ...data.searchPhoto
  //       ]
  //     });
  //   }
  // });
  // const [
  //   getConsultFolderList,
  //   { variables: consultFolderVariables }
  // ] = useLazyQuery(SEARCH_PHOTO_NOAUTH, {
  //   fetchPolicy: "network-only",
  //   onCompleted: data => {
  //     dispatchState({
  //       type: "folderPath",
  //       target: [
  //         ...getDefaultFolders(consultFolderVariables.args.type),
  //         ...data.searchPhotoNoAuth
  //       ]
  //     });
  //   }
  // });
  // const [getConsultFileList, { variables: consultVariables }] = useLazyQuery(
  //   SEARCH_PHOTO_NOAUTH,
  //   {
  //     fetchPolicy: "network-only",
  //     onCompleted: data => {
  //       dispatchState({
  //         type: "list",
  //         target:
  //           consultVariables.args.content.folder === "root"
  //             ? [
  //                 ...getDefaultFolders(consultVariables.args.type),
  //                 ...data.searchPhotoNoAuth
  //               ]
  //             : [...data.searchPhotoNoAuth]
  //       });
  //     }
  //   }
  // );
  useEffect(() => {
    // console.log("selected", selected);
    if (selected?.id !== currentPointer?.id)
      dispatchState({ type: 'currentPointer', target: selected });
  }, [selected, currentPointer]);
  // useEffect(() => {
  //   // console.log("currentPointer", currentPointer);
  //   if (currentPointer) {
  //     switch (currentPointer?.type) {
  //       case "library":
  //         getFileList({
  //           variables: {
  //             hospitalId: localStorage.getItem('labId'),
  //             args: {
  //               type: "library",
  //               patient: "__libraries__",
  //               content: {
  //                 folder: currentPointer.content.filenode
  //               }
  //             }
  //           }
  //         });
  //         getFolderList({
  //           variables: {
  //             hospitalId: localStorage.getItem('labId'),
  //             args: {
  //               type: "library",
  //               patient: "__libraries__",
  //               content: {
  //                 filetype: "folder"
  //               }
  //             }
  //           }
  //         });
  //         break;
  //       case "photo":
  //         getFileList({
  //           variables: {
  //             hospitalId: localStorage.getItem('labId'),
  //             args: {
  //               type: "photo",
  //               patient: currentPointer.patient.toString(),
  //               content: {
  //                 folder: currentPointer.content.filenode
  //               }
  //             }
  //           }
  //         });
  //         getFolderList({
  //           variables: {
  //             hospitalId: localStorage.getItem('labId'),
  //             args: {
  //               type: "photo",
  //               patient: currentPointer.patient.toString(),
  //               content: {
  //                 filetype: "folder"
  //               }
  //             }
  //           }
  //         });
  //         break;
  //       case "consult":
  //         getConsultFileList({
  //           variables: {
  //             hospitalId: null,
  //             args: {
  //               type: "consult",
  //               patient: null,
  //               content: {
  //                 folder: currentPointer.content.filenode
  //               }
  //             }
  //           }
  //         });
  //         getConsultFolderList({
  //           variables: {
  //             hospitalId: null,
  //             args: {
  //               type: "consult",
  //               patient: null,
  //               content: {
  //                 filetype: "folder"
  //               }
  //             }
  //           }
  //         });
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [
  //   currentPointer,
  //   getFileList,
  //   getConsultFileList,
  //   getFolderList,
  //   getConsultFolderList
  // ]);
  // console.log(list);
  return (
    <>
      <Grid
        container
        direction={'column'}
        style={{
          height: '100%',
          minWidth: 785,
          width: '100%',
          backgroundColor: '#eceff1',
        }}
      >
        <FilePath
          patient={patient}
          folderPath={folderPath}
          folderList={folderList}
          currentPointer={currentPointer}
          onChange={(e) => {
            // if (e.id !== currentPointer.id) {
            //   dispatchState({
            //     type: "currentPointer",
            //     target: JSON.parse(JSON.stringify(e))
            //   });
            // }
            onClickItem({ ...e });
          }}
        />
        <Grid item style={{ height: 'calc(100% - 52px)', overflowY: 'auto' }}>
          <Grid
            container
            justify='flex-start'
            alignItems='flex-start'
            style={{
              width: '100%',
              // height: "100%",
              backgroundColor: '#eceff1',
              padding: 16,
              boxSizing: 'border-box',
            }}
          >
            {/*<Grid item container style={{ height: "100%" }}>*/}
            {Boolean(currentPointer?.content?.folder) && (
              <Grid item key={'backward'}>
                <BackwardItem
                  root={root}
                  onClick={(e) => {
                    let _index = folderList.findIndex(
                      (item) =>
                        item.content.filenode === currentPointer.content.folder,
                    );
                    // console.log(_index, folderList[_index]);
                    // dispatchState({
                    //   type: "currentPointer",
                    //   target: { ...folderList[_index] }
                    // });
                    onClickItem({ ...folderList[_index] });
                  }}
                />
              </Grid>
            )}
            {list.map((item) => (
              <Grid item key={item.id}>
                <FileItem
                  currentItem={currentItem}
                  data={item}
                  onClick={(e) => {
                    // console.log(e);
                    if (e.content.filetype === 'folder') {
                      // dispatchState({
                      //   type: "currentPointer",
                      //   target: { ...e }
                      // });
                      onClickItem({ ...e });
                    } else {
                      dispatchState({
                        type: 'currentItem',
                        target: { ...e },
                      });
                    }
                  }}
                  folderList={folderList}
                />
              </Grid>
            ))}
            {/*</Grid>*/}
          </Grid>
        </Grid>
      </Grid>
      {currentItem && (
        <FileViewer
          onClose={onClose}
          clickedItem={currentItem}
          folderList={folderList}
          onFileSelect={onFileSelect}
        />
      )}
    </>
  );
};

FileGrid.defaultProps = {
  path: '/',
};
export default FileGrid;
