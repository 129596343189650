import React from "react";

const CTIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zM8.545 8c-.711 0-1.335.167-1.872.501-.537.334-.95.813-1.24 1.438-.252.547-.395 1.169-.426 1.865L5 12.107v.522c0 1.285.313 2.296.94 3.032.627.736 1.488 1.105 2.581 1.105 1 0 1.806-.264 2.417-.791.56-.484.892-1.131.995-1.943l.022-.225h-1.758c-.039.52-.192.903-.46 1.151-.267.248-.673.372-1.216.372-.601 0-1.042-.213-1.32-.639-.252-.383-.39-.967-.416-1.752l-.004-.27v-.644c.008-.882.157-1.534.448-1.954.292-.42.73-.63 1.316-.63.539 0 .94.124 1.204.372.23.218.377.55.439 1l.021.199h1.758c-.094-.953-.442-1.694-1.043-2.221C10.322 8.264 9.529 8 8.544 8zm10.816.117h-6.949v1.424h2.578v7.107h1.758V9.541h2.613V8.117z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default CTIcon;
