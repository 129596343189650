import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import { PhotoTile } from "components/index";
import React from "react";

const useStyle = makeStyles(theme => ({
  root: {
    width: 128,
    height: 102,
    cursor: "pointer"
  }
}));

const BackwardItem = props => {
  // const { root, onClick } = props;
  const classes = useStyle();
  return (
    <Grid item key={"root"} className={classes.root}>
      {/* <PhotoTile
        photo={null}
        tileMode="none"
        image="/contents/backward.svg"
        header={null}
        tileHeight={100}
        onClick={(e, photo) => {
          onClick && onClick(root);
        }}
        onClickMore={e => {}}
        onCheck={e => {}}
      /> */}
    </Grid>
  );
};

export default BackwardItem;
