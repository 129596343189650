import { makeStyles, Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import clsx from "clsx";
import React from "react";
import { CCPaper, CCTextField, CCTypography } from "styles/src/components";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import { useIntl } from "utils/language";
import { translate } from "../../../../../../../components";

const topHeight = 36;

const useStyles = makeStyles(theme => ({
  root: { height: "100%", position: "relative" },
  top: { height: topHeight },
  item: { paddingLeft: 16 },
  container: {
    height: `calc(100% - ${topHeight}px )`,
    overflow: "auto",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default,
    paddingLeft: 8,
    paddingRight: 8
  },
  leftContainer: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    width: 150,
    height: "100%",
    backgroundImage: `url("/teeth.svg")`,
    backgroundPosition: "right"
  },
  padding0: { padding: 0 },
  margin0: { margin: 0 },
  select__field: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    width: 130,
    backgroundColor: theme.palette.background.default
  }
}));

const shadeItem = {
  Vita: [
    {
      product: "Classic",
      shade: [
        "A1",
        "A2",
        "A3",
        "A3.5",
        "A4",
        "B1",
        "B2",
        "B3",
        "B4",
        "C1",
        "C2",
        "C3",
        "C4",
        "D2",
        "D3",
        "D4"
      ]
    },
    {
      product: "3D Master",
      shade: [
        "0M-1",
        "0M-2",
        "0M-3",
        "2L-1.5",
        "2L-2.5",
        "2M-1",
        "2M-2",
        "2M-3",
        "2R-1.5",
        "2R-2.5",
        "3L-1.5",
        "3L-2.5",
        "3M-1",
        "3M-2",
        "3M-3",
        "3R-1.5",
        "3R-2.5",
        "4L-1.5",
        "4L-2.5",
        "4M-1",
        "4M-2",
        "4M-3",
        "4R-1.5",
        "4R-2.5",
        "5M-1",
        "5M-2",
        "5M-3"
      ]
    }
  ],
  Ivoclar: [
    {
      product: "A-D",
      shade: [
        "BL1",
        "BL2",
        "BL3",
        "BL4",
        "A1",
        "A2",
        "A3",
        "A3.5",
        "A4",
        "B1",
        "B2",
        "B3",
        "B4",
        "C1",
        "C2",
        "C3",
        "C4",
        "D2",
        "D3",
        "D4"
      ]
    },
    {
      product: "Chromascop",
      shade: [
        "110",
        "120",
        "130",
        "140",
        "210",
        "220",
        "230",
        "240",
        "310",
        "320",
        "330",
        "340",
        "410",
        "420",
        "430",
        "440",
        "510",
        "520",
        "530",
        "540"
      ]
    }
  ]
};

const ShadeSelector = props => {
  const { value, onChange } = props;
  const { shade } = value;
  const { manufacturer, product, top, middle, bottom } = shade;
  const lang = localStorage.getItem('lang')
  const intl = useIntl();
  const classes = useStyles();

  const productList = Object.keys(shadeItem);

  const handleOnChange = name => e => {
    switch (name) {
      // 상단 제조사 선택
      case "manufacturer":
        return onChange({
          shade: {
            manufacturer: e.target.value,
            product: shadeItem[e.target.value][0].product,
            top: "",
            middle: "",
            bottom: ""
          }
        });
      case "product":
        return onChange({
          shade: {
            ...shade,
            product: e.target.value,
            top: "",
            middle: "",
            bottom: ""
          }
        });
      case "top":
      case "middle":
      case "bottom":
        return onChange({ shade: { ...shade, [name]: e.target.value } });
      default:
        console.log("no type!!");
    }
  };

  return (
    <CCPaper className={clsx(classes.root)}>
      <Grid
        className={classes.top}
        container
        wrap={"nowrap"}
        alignItems={"center"}
        justify={"space-between"}
      >
        <Grid item className={classes.item}>
          <CCTypography variant={"h5"}>
            {translate(intl, "common.ui.shade")}
          </CCTypography>
        </Grid>
      </Grid>

      <Grid
        container
        className={clsx(classes.container)}
        alignItems={"center"}
        justify={"space-between"}
      >
        <Grid item>
          <CCTextField
            label={translate(intl, "common.ui.manufacturer")} //제조사
            className={classes.select__field}
            variant={"outlined"}
            margin={"dense"}
            value={manufacturer}
            onChange={handleOnChange("manufacturer")}
            select
          >
            {productList?.map(product => (
              <CCMenuItem key={product} value={product}>
                {product}
              </CCMenuItem>
            ))}
          </CCTextField>
          <RadioGroup
            aria-label="position"
            name="position"
            defaultValue="top"
            value={product}
            onChange={handleOnChange("product")}
          >
            <FormControlLabel
              value={shadeItem[manufacturer][0].product}
              control={<Radio color="secondary" />}
              label={shadeItem[manufacturer][0].product}
            />
            <FormControlLabel
              value={shadeItem[manufacturer][1].product}
              control={<Radio color="secondary" />}
              label={shadeItem[manufacturer][1].product}
            />
          </RadioGroup>
        </Grid>

        <Grid item className={classes.leftContainer}>
          <Grid
            container
            direction={"column"}
            justify={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <CCTextField
                label={top ? "INCISAL(OCCLUSAL)" : (lang === 'ar' ? "...INCISAL" : "INCISAL...")}
                className={classes.select__field}
                variant={"outlined"}
                margin={"dense"}
                value={top}
                onChange={handleOnChange("top")}
                select
              >
                <CCMenuItem value={""}>
                  {translate(intl, "noSelection")}
                </CCMenuItem>
                {shadeItem[manufacturer]
                  ?.filter(item => item.product === product)[0]
                  ?.shade.map(item => (
                    <CCMenuItem key={item} value={item}>
                      {item}
                    </CCMenuItem>
                  ))}
              </CCTextField>
            </Grid>
            <Grid item>
              <CCTextField
                label={"MIDDLE"}
                className={classes.select__field}
                variant={"outlined"}
                margin={"dense"}
                value={middle}
                onChange={handleOnChange("middle")}
                select
              >
                <CCMenuItem value={""}>
                  {translate(intl, "noSelection")}
                </CCMenuItem>
                {shadeItem[manufacturer]
                  ?.filter(item => item.product === product)[0]
                  ?.shade.map(item => (
                    <CCMenuItem key={item} value={item}>
                      {item}
                    </CCMenuItem>
                  ))}
              </CCTextField>
            </Grid>
            <Grid item>
              <CCTextField
                label={"CERVILLAL"}
                className={classes.select__field}
                variant={"outlined"}
                margin={"dense"}
                value={bottom}
                onChange={handleOnChange("bottom")}
                select
              >
                <CCMenuItem value={""}>
                  {translate(intl, "noSelection")}
                </CCMenuItem>
                {shadeItem[manufacturer]
                  ?.filter(item => item.product === product)[0]
                  ?.shade.map(item => (
                    <CCMenuItem key={item} value={item}>
                      {item}
                    </CCMenuItem>
                  ))}
              </CCTextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CCPaper>
  );
};

ShadeSelector.defaultProps = {};

ShadeSelector.propTypes = {};

export default ShadeSelector;
