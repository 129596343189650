import React from "react";

const BarcodenIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="otbh9w51ta" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-1223.000000, -368.000000) translate(1223.000000, 368.000000)">
          <path fill="#D8D8D8" fillOpacity=".01" d="M0 0H32V32H0z" />
          <rect
            width="30"
            height="30"
            x="1"
            y="1"
            fill="url(#otbh9w51ta)"
            rx="4"
          />
          <g>
            <path
              fill="#00897B"
              d="M0 9.333h9.333V0H0v9.333zm2.333-7H7V7H2.333V2.333z"
              transform="translate(5.500000, 5.500000)"
            />
            <path
              fill="#00BFA5"
              d="M0 21h9.333v-9.333H0V21zm2.333-7H7v4.667H2.333V14zM11.667 0v9.333H21V0h-9.333zm7 7H14V2.333h4.667V7z"
              transform="translate(5.500000, 5.500000)"
            />
            <path
              fill="#00897B"
              d="M18.667 18.667H21V21H18.667zM11.667 11.667H14V14H11.667zM14 14H16.333V16.333H14zM11.667 16.333H14V18.665999999999997H11.667zM14 18.667H16.333V21H14zM16.333 16.333H18.665999999999997V18.665999999999997H16.333zM16.333 11.667H18.665999999999997V14H16.333zM18.667 14H21V16.333H18.667z"
              transform="translate(5.500000, 5.500000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default BarcodenIcon;
