/*eslint no-unused-vars: 0*/

import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { useIntl } from "../../../locales/language";
import {
  MoonborderIcon,
  MoonIcon,
  ReservationIcon
} from "../../themes/common/icons/";
import { CCFlattenDatePicker } from "../CCFlattenDateTimePicker";
import CCIconButton from "../CCIconButton";
import CCTextField from "../CCTextField";

const useStyles = makeStyles(
  theme => ({
    root: {},
    selected__button: {
      color: theme.palette.select.main
    },
    padding__right: { paddingRight: 8 }
  }),
  { name: "CCDateField" }
);

/*const DateMask = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      guide={true} // 가이드 사용 여부, 기본값 true
      placeholderChar={"\u2000"} // 가이드 문자 공백 "\u2000" 으로 지정
      mask={[/\d/, /\d/, /\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/]}
      showMask={false}
      keepCharPositions={true} // 중간 삭제 시 뒤에 값 자리 위치
      {...other}
    />
  );
};*/
/**
 * @deprecated
 */

const CCDateField = props => {
  const {
    className,
    value,
    onChange,
    onChanging,
    lunar,
    lunarOnChange,
    width,
    disabled,
    checkedList,
    _InputProps,
    ...others
  } = props;
  const intl = useIntl();
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState(value ? moment(value, "L") : moment());

  return (
    <>
      <CCTextField
        className={className}
        disabled={disabled}
        style={{ width: width }}
        margin={"dense"}
        variant={"outlined"}
        value={value}
        //onChange={e => onChange(e)}
        onKeyPress={e => setAnchorEl(e.target)}
        InputLabelProps={{ shrink: Boolean(value) }}
        InputProps={{
          className: _InputProps,
          readOnly: true,
          classes: { adornedEnd: classes.padding__right },
          //inputComponent: DateMask,
          endAdornment: (
            <InputAdornment position={"end"}>
              <CCIconButton
                disabled={disabled}
                onClick={e => {
                  setAnchorEl(e.target);
                }}
                size={"small"}
              >
                <ReservationIcon />
              </CCIconButton>
              {lunarOnChange && (
                <CCIconButton
                  size={"small"}
                  onClick={() => {
                    lunarOnChange(!lunar);
                  }}
                  tooltip={
                    lunar
                      ? intl.formatMessage({
                          id: "ccLunarYear"
                        })
                      : intl.formatMessage({
                          id: "ccSunYear"
                        })
                  }
                  className={lunar ? classes.selected__button : {}}
                >
                  {lunar ? <MoonIcon /> : <MoonborderIcon />}
                </CCIconButton>
              )}
            </InputAdornment>
          )
        }}
        {...others}
      />
      <CCFlattenDatePicker
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        onChange={value => {
          onChange({
            target: { name: props.name, value: value.format("L") }
          });
          setAnchorEl(null);
        }}
        onChanging={value => {
          onChanging &&
            onChanging({
              target: { name: props.name, value: value.format("L") }
            });
        }}
        checkedList={checkedList}
        value={date}
      />
    </>
  );
};

CCDateField.defaultProps = {
  value: ""
};

CCDateField.propTypes = {
  lunarOnChange: PropTypes.func,
  lunar: PropTypes.bool,
  /** Date가 변경되면 실행되는 함수 */
  onChange: PropTypes.func,
  /** Date가 변경 중 실행되는 함수 */
  onChanging: PropTypes.func,
  /** YYYY/MM/DD 형태의 Date */
  value: PropTypes.string
};

export default CCDateField;
