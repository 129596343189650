import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { LeftIcon, RightIcon } from "styles/icons";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 36
  },
  nav__button: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    padding: "4px 8px 5px 8px ",
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.24)",
    border: `1px solid ${theme.palette.border.main}`
  },
  button: {
    padding: 0,
    minWidth: 28
  },
  prev__button: {
    borderRadius: "4px 0 0 4px"
  },
  next__button: {
    borderRadius: "0 4px 4px 0"
  },
  time: {
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 700,
    padding: "0 16px"
  }
}));

const MonthNav = props => {
  const { month, year, onMoveToNextMonth, onMoveToPrevMonth } = props;

  const classes = useStyles();

  return (
    <Box className={clsx(classes.root)}>
      <Button
        size="small"
        onClick={onMoveToPrevMonth}
        className={clsx(
          classes.nav__button,
          classes.button,
          classes.prev__button
        )}
      >
        <LeftIcon />
      </Button>
      <span className={classes.time}>
        {year} - {month}
      </span>
      <Button
        size="small"
        onClick={onMoveToNextMonth}
        className={clsx(
          classes.nav__button,
          classes.button,
          classes.next__button
        )}
      >
        <RightIcon />
      </Button>
    </Box>
  );
};

export default MonthNav;
