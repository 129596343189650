import { Card, CardActionArea, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 8,
    margin: 8,
    overflow: "hidden",
    position: "relative"
  },
  actionarea: {
    height: 102,
    padding: "8px 12px",
    width: 128
  },
  over: {
    "&:hover": {
      backgroundColor: theme.palette.background.default
    }
  },
  selected: {
    backgroundColor: theme.palette.select.main,
    color: "white",

    "&:hover": {
      backgroundColor: theme.palette.select.main,
      color: "white"
    }
  }
}));

const CCCard = props => {
  const {
    children,
    classes,
    className,
    raised = false,
    selected = false,
    onMouseOver,
    onMouseOut,
    ...other
  } = props;
  const baseClasses = useStyles();

  const [over, setOver] = useState(false);
  const { actionarea, ...otherClasses } = { actionarea: "", ...classes };

  return (
    <Card
      className={clsx(
        baseClasses.root,
        {
          [baseClasses.over]: !selected,
          [baseClasses.selected]: selected
        },
        className
      )}
      elevation={over || raised ? 8 : 1}
      onMouseOver={e => {
        onMouseOver && onMouseOver(e);
        setOver(true);
      }}
      onMouseOut={e => {
        onMouseOut && onMouseOut(e);
        setOver(false);
      }}
      classes={otherClasses}
      {...other}
    >
      <CardActionArea
        className={clsx(baseClasses.actionarea, {
          [classes?.actionarea]: actionarea
        })}
      >
        {children}
      </CardActionArea>
    </Card>
  );
};

CCCard.propTypes = {
  /** CCCard 컴포넌트 자식 요소 */
  children: PropTypes.node,
  /** CCCard 컴포넌트와 같이 사용할 merge될 css */
  classes: PropTypes.object,
  /** CCCard 컴포넌트와 같이 사용할 merge될 css */
  className: PropTypes.string,
  /** CCCard 컴포넌트 고도 (그림자 느낌) */
  raised: PropTypes.bool
};

export default CCCard;
