import { Box, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { CCDialogSimple, CCIconButton, CCTypography } from "styles/components";
import {
  DownloadIcon,
  RemoveIcon,
  ResizeIcon,
  ZoominIcon,
  ZoomoutIcon
} from "styles/icons";
import { saveAttachmentToDisk, useGetAttachments } from "../../utils";
import { useIntl } from "react-intl";
import { translate } from "components"
import { Target } from "utils/s3";

const useStyles = makeStyles(theme => ({
  previewer: {
    position: "relative",
    backgroundColor: "#000",
    height: 800,
    "& > div": {
      height: "100%"
    },
    "& > div > div": {
      height: "100%"
    }
  },
  title: {
    backgroundColor: theme.palette.common.black,
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px"
  },
  title__text: {
    color: theme.palette.common.white,
    marginTop: 16
  },
  icon_button: {
    color: theme.palette.common.white
  },
  tools: {
    position: "absolute",
    top: 12,
    right: 12,
    height: "auto !important",
    "& button": {
      borderRadius: "50%",
      backgroundColor: "rgba(0,0,0,0.54) !important"
    }
  }
}));

const ImagePreviewerModal = props => {
  const { imageUrl, open, attachments = [], onClose, fileName } = props;
  const intl = useIntl();
  const classes = useStyles();

  const { data: imageUrls } = useGetAttachments(
    attachments,
    Target.PHOTO,
    localStorage.getItem('labId')
  );

  return (
    <CCDialogSimple
      maxWidth={false}
      open={open}
      isHeader={false}
      contentsPadding={false}
      onClose={onClose}
      contents={
        <Box>
          <Box className={classes.title}>
            <CCTypography variant="h3" className={classes.title__text}>
              {translate(intl, 'settings.lab.preview')}
            </CCTypography>
            <Box>
              <CCIconButton
                className={classes.icon_button}
                onClick={() => saveAttachmentToDisk(imageUrl || imageUrls[0], fileName)}
              >
                <DownloadIcon />
              </CCIconButton>
              <CCIconButton className={classes.icon_button} onClick={onClose}>
                <RemoveIcon />
              </CCIconButton>
            </Box>
          </Box>

          <Box className={classes.previewer}>
            <TransformWrapper>
              {({ zoomIn, zoomOut, resetTransform }) => {
                return (
                  <>
                    <TransformComponent>
                      <Box
                        style={{
                          backgroundColor: "#000",
                          minWidth: 1442,
                          backgroundImage: `url(${imageUrl || imageUrls[0]})`,
                          backgroundPosition: "center center",
                          backgroundOrigin: "border-box",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain"
                        }}
                      />
                    </TransformComponent>
                    <div className={classes.tools}>
                      <ButtonGroup>
                        <CCIconButton variant={"contained"} onClick={zoomIn}>
                          <ZoominIcon />
                        </CCIconButton>
                        <CCIconButton
                          variant={"contained"}
                          onClick={resetTransform}
                        >
                          <ResizeIcon />
                        </CCIconButton>
                        <CCIconButton variant={"contained"} onClick={zoomOut}>
                          <ZoomoutIcon />
                        </CCIconButton>
                      </ButtonGroup>
                    </div>
                  </>
                );
              }}
            </TransformWrapper>
          </Box>
        </Box>
      }
    />
  );
};

export default ImagePreviewerModal;
