import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
    Contents
    // Footer
} from "./components";

const useStyles = makeStyles(() => ({
  root: { height: "100vh", backgroundColor: "white" },
  main: { height: "100%" }
}));

const Minimal = props => {
  const { children, ...rest } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <main className={classes.main}>
        <Contents children={children} {...rest} />
      </main>
      {/* <Footer /> */}
    </Box>
  );
};

export default Minimal;
