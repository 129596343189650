import React from "react";

const CalculationIcon18 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H18V18H0z" />
      <path
        fill="currentColor"
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zm1.057 12.068V15H8.055v-1.447c-1.282-.27-2.37-1.096-2.452-2.55h1.47c.074.787.614 1.402 1.987 1.402 1.47 0 1.8-.735 1.8-1.192 0-.623-.33-1.208-2.002-1.605-1.86-.45-3.136-1.216-3.136-2.753 0-1.29 1.043-2.13 2.333-2.408V3h2.002v1.462c1.396.338 2.093 1.396 2.138 2.543h-1.47c-.037-.832-.48-1.402-1.665-1.402-1.125 0-1.8.51-1.8 1.23 0 .63.487 1.042 2.002 1.432 1.515.39 3.135 1.043 3.135 2.933-.007 1.372-1.034 2.122-2.34 2.37z"
      />
    </g>
  </svg>
);
export default CalculationIcon18;
