import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCPaper, CCTabPanel, CCTabs } from "styles/src/components";
import { translate } from "components";
import BillManagement from './BillManagement';
import CreateBill from "./CreateBill";
import {
  useReadPermission
} from "components";

const tabsHeight = 52;
const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  paper: { position: "relative", height: "100%"},
  tabs: {
    borderBottom: `1px solid ${theme.palette.border}`,
    height: tabsHeight,
    boxSizing: "border-box"
  },
  panel: { height: `calc(100% - ${tabsHeight}px)`, overflow: "auto" }
}));

const PaymentSection = props => {
  const { dispatch, permission, onModify, state } = props;
  const manageBillreadPermission = useReadPermission("payment.createBill");
  const createBillreadPermission = useReadPermission("payment.billManage");
  
  const classes = useStyles();
  const intl = useIntl();

  const [tabIndex, setTabIndex] = useState(1); // 0

  const tabList = [
    {
      label: translate( intl,"payment.createBill" ),
      component: () => (
        <CreateBill
          permission={createBillreadPermission}
          onModify={onModify}
          state={state}
          dispatch={dispatch}
        />
      )
    },
    {
      label: translate(intl, "payment.billManage"),
      component: () => (
        <BillManagement
          permission={manageBillreadPermission}
          onModify={onModify}
          state={state}
          dispatch={dispatch}
        />
      )
    }
  ];

  return (
    <Box className={clsx(classes.root)}>
      <CCPaper className={classes.paper}>
        {/* <PermissionBackdrop permission={permission} /> */}
        <CCTabs
          className={classes.tabs}
          tabList={tabList.map(el => el.label)}
          selectedTab={tabIndex}
          onChangeTab={index => {
            setTabIndex(index);
          }}
        />
        {tabList.map((d, i) => (
          <CCTabPanel
            className={classes.panel}
            key={i}
            value={tabIndex}
            index={i}
          >
            {d.component()}
          </CCTabPanel>
        ))}
      </CCPaper>
    </Box>
  );
};

PaymentSection.defaultProps = {};

PaymentSection.propTypes = {};

export default PaymentSection;
