import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, LinearProgress, Grid, makeStyles } from "@material-ui/core";
import { PhotoEditorDialog, PreviewStlFile } from "components/index";
import PhotoTile from "components/PhotoTile";
import { CCButton, CCDialogSimple, CCTypography } from "styles/src/components";
import { getObject, Target } from "utils/s3";
import { useIntl } from "utils/language";
import clsx from "clsx";
import { DownloadIcon } from "styles/icons";
import ImagePreviewerModal from "views/Normal/DentalLab/components/LabOrderModal/components/ViewNoteLabOrder/components/ImagePreviewerModal/ImagePreviewerModal.js";

const topHeight = 36;
const toolbarHeight = 52 + 37 + 44;
const useStyles = makeStyles(theme => ({
  root: { height: "100%", position: "relative" },
  top: { height: topHeight },
  item: { paddingLeft: 16 },
  container: {
    height: `calc(100% - ${topHeight}px)`,
    overflow: "auto",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  padding0: { padding: 0 },
  margin0: { margin: 0 },
  button: {},
  button__item: {
    paddingBottom: 8
  },
  error: {
    color: theme.palette.error.main
  },
  item__progress: {
    minWidth: "calc(100% - 5ch)",
    maxWidth: "calc(100% - 2ch)",
    padding: "8px"
  },
  progress__bar: {
    height: "16px",
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.background.default
  },
  progress__bar__primary: { backgroundColor: theme.palette.disabled.hex },
  photoGrid: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-around",
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: `calc(100% - ${toolbarHeight}px)`
  }
}));

const PhotoThumbnailTile = props => {
  const {
    photo: photoOne,
    onDeletePhoto,
    readonly = true,
    patientId,
    onSave,
    action,
    actionExt = false,
    onActionExt,
    onAction,
    onClick,
    type = Target.PHOTO,
    bucket = photoOne.hospitalId || localStorage.getItem("labId"),
    url
  } = props;

  const intl = useIntl();
  const classes = useStyles();
  const labId = photoOne.hospitalId || localStorage.getItem("labId");
  const [thumbnail, setThumbnail] = useState(null);
  const [openImageView, setOpenImageView] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [openZipError, setOpenZipError] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const { object: objectId } = photoOne.id;
  const [currentProgress, setCurrentProgress] = useState(0);
  const abortControllerRef = useRef();
  const [openMesh3D, setOpenMesh3D] = useState("");

  useEffect(() => {
    if (objectId) {
      const fetchObjectUrl = async () => {
        const url = await getObject(photoOne.target, labId, objectId);
        setThumbnail(url);
      };
      fetchObjectUrl();
    }
  }, [objectId, labId, photoOne.type, photoOne]);

  const handleClick = photo => {
    if (onClick) {
      onClick();
    }
    switch (photo.type.toLowerCase()) {
      case "zip":
        setOpenZipError(true);
        break;
      case "stl":
        getObject(photo?.target, labId, photo?.id).then(res => {
          if (res.includes(".stl")) {
            setOpenMesh3D(res);
          } else {
            setOpenZipError(true);
          }
        });
        break;
      default:
        setOpenImageView(true);
        break;
    }
  };

  const onClose = () => {
    setOpenImageView(false);
    setMouseOver(false);
  };

  const onDownload = () => {
    if (photoOne) {
      const labId = photoOne.hospitalId || localStorage.getItem("labId");

      getObject(photoOne?.target, labId, photoOne?.id)
        .then(async res => {
          const a = document.createElement("a");
          // a.href = res;
          // a.download = `${
          //   photoOne.content.filename ? photoOne.content.filename : "output"
          // }`;
          const controller = new AbortController();
          const { signal } = controller;

          abortControllerRef.current = controller;

          a.href = await fetch(res, { signal })
            .then(async res => {
              const reader = res.body.getReader();

              // Step 2: get total length
              const contentLength = +res.headers.get("Content-Length");

              // Step 3: read the data
              let receivedLength = 0; // received that many bytes at the moment
              let chunks = []; // array of received binary chunks (comprises the body)
              while (true) {
                const { done, value } = await reader.read();

                if (done) {
                  break;
                }

                chunks.push(value);
                receivedLength += value.length;

                setCurrentProgress(
                  parseInt((receivedLength / contentLength) * 100)
                );
              }

              let blob = new Blob(chunks);
              return blob;
              // res.blob();
            })
            .then(blob => window.URL.createObjectURL(blob));
          a.download = photoOne.name ? photoOne.name : "output";
          a.click();
        })
        .catch(fail => console.log(fail))
        .finally(() => {
          abortControllerRef.current = undefined;
        });
    }
  };

  const getThumbnail = useCallback(
    async (photo, type = Target.PHOTO, targetBucket) => {
      let url;

      try {
        url = await getPhotoImage(photo);
        setThumbnail(url);
      } catch (err) {
        try {
          url = await getPhotoImage(photo);
          setThumbnail(url);
        } catch (err) {
          console.error("GET Thumbnail Error", err);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPhotoImage = photo => {
    switch (photo.type) {
      case "stl":
      case "STL":
        return "/contents/stl.svg";
      case "zip":
      case "ZIP":
        return "/contents/zip.svg";
      case "video":
      case "VIDEO":
        return "/contents/video.svg";
      case "consult":
        return "/contents/slide.svg";
      case "unknown":
        return "/contents/unknown.svg";
      default:
        let url;
        const fetchObjectUrl = async () => {
          url = await getObject(photo.target, labId, photo.id);
          setThumbnail(url);
        };
        fetchObjectUrl();
        return thumbnail;
    }
  };

  useEffect(() => {
    if (photoOne && !url) {
      getThumbnail(photoOne, type, bucket);
    } else {
      setThumbnail(url);
    }
  }, [photoOne, type, getThumbnail, bucket, url]);

  useEffect(() => {
    if (!openZipError) {
      setCurrentProgress(0);
      abortControllerRef.current && abortControllerRef.current.abort();
    }
  }, [openZipError]);

  return (
    <>
      <Box
        // onMouseEnter={e => {
        //   setMouseOver(true);
        // }}
        // onMouseOver={e => {
        //   setMouseOver(true);
        // }}
        // onMouseLeave={e => {
        //   setMouseOver(false);
        // }}
      >
        <PhotoTile
          image={thumbnail}
          header={photoOne.name}
          tileMode={"none"}
          filetype={photoOne?.content?.filetype}
          onClick={() => {
            handleClick(photoOne);
          }}
          actionExt={!readonly  && actionExt}
          action={!readonly  && action}
          onActionExt={() => (onActionExt ? onActionExt : setOpenEditor(true))}
          onAction={() =>
            onAction
              ? onAction
              : !readonly &&
                onDeletePhoto &&
                onDeletePhoto(photoOne)
          }
        />
        {openImageView && (
          <CCDialogSimple
            maxWidth="md"
            open={openImageView}
            onClose={onClose}
            // title={intl.formatMessage({ id: "showImage" })}
            title={""}
            onCloseButton={onClose}
            contents={
              <Box style={{ height: 660 }}>
                <ImagePreviewerModal
                  clickedItem={photoOne}
                  bucket={bucket}
                  fileName={photoOne.name}
                  imageUrl={thumbnail}
                  open={openImageView}
                  onClose={() => {
                    setOpenImageView(false);
                  }}
                />
              </Box>
            }
            endActions={
              <CCButton variant={"contained"} onClick={onClose}>
                {intl.formatMessage({ id: "check" })}
              </CCButton>
            }
          />
        )}
        {openEditor && (
          <PhotoEditorDialog
            open={openEditor}
            hospitalId={labId}
            patientId={patientId}
            photo0={photoOne}
            onClose={() => {
              setOpenEditor(false);
            }}
            onSave={onSave}
          />
        )}
        {Boolean(openMesh3D) && (
          <PreviewStlFile
            openMesh3D={openMesh3D}
            handleClose={ () => {
              setOpenMesh3D("");
            }}/>
        )}
        {openZipError && (
          <CCDialogSimple
            open={openZipError}
            onClose={() => setOpenZipError(false)}
            onAgree={() => setOpenZipError(false)}
            title={
              <CCTypography variant="body1" color="error">
                {intl.formatMessage(
                  { id: "warningOpenZip" },
                  { br: () => <br /> }
                )}
              </CCTypography>
            }
            contents={
              <Grid
                container
                className={clsx(classes.container)}
                style={{ position: "relative" }}
                direction="row"
                alignItems="center"
              >
                <Grid className={classes.item__progress} item>
                  <LinearProgress
                    className={classes.progress__bar}
                    // classes={{ barColorPrimary: classes.progress__bar__primary }}
                    color="secondary"
                    variant="determinate"
                    value={currentProgress}
                  />
                </Grid>
                <Grid item>
                  <CCTypography>{`${currentProgress}%`}</CCTypography>
                </Grid>
              </Grid>
            }
            startActions={
              <CCButton
                variant="contained"
                onClick={e => {
                  // e.stopPropagation();
                  // zoomOut(e);
                  e.stopPropagation();
                  onDownload();
                }}
                startIcon={<DownloadIcon />}
              >
                {intl.formatMessage({ id: "photoDownload" })}
              </CCButton>
            }
            endActions={
              <>
                <CCButton onClick={() => setOpenZipError(false)}>
                  {intl.formatMessage({ id: "close" })}
                </CCButton>
              </>
            }
          />
        )}
      </Box>
    </>
  );
};

PhotoThumbnailTile.defaultProps = {
  readonly: false,
  viewMode: false,
  actionExt: "/contents/modify.svg",
  action: "/contents/subtract.svg"
};

PhotoThumbnailTile.propTypes = {};

export default PhotoThumbnailTile;
