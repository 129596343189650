import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SelectorStaff from 'components/SelectorStaff';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { CCTextField } from 'styles/src/components';
import CCButton from 'styles/src/components/CCButton';
import { CCFlattenDateField } from 'styles/src/components/CCFlattenDateTimePicker';
import SearchIcon from 'styles/src/themes/common/icons/SearchIcon';
import {
    DOC_CATEGORIES,
    DOC_PURPOSES,
    DOC_STATUS,
    DOC_TYPES
} from 'types/docissue';
import { CHARGE, STATUS } from 'types/staff';
import { makeList } from 'types/utils';

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: 24,
  },
  input: {
    backgroundColor: theme.palette.background.paper,
  },
  timeInput: {
    width: 130,
  },
  selectInput: {
    width: 142,
  },
  memoInput: {
    width: 236,
  },
  marginLeft8: {
    marginLeft: 8,
  },
}));

const DOC_CATEGORIES_LIST = makeList(DOC_CATEGORIES);
const DOC_TYPES_LIST = makeList(DOC_TYPES);
const DOC_STATUS_LIST = makeList(DOC_STATUS);
const DOC_PURPOSES_LIST = makeList(DOC_PURPOSES);

const FilterCollapse = forwardRef((props, ref) => {
  const { filter, collapse, onChange } = props;
  const classes = useStyle();
  const [value, setValue] = useState(filter);
  useEffect(() => {
    setValue(filter);
  }, [filter]);
  return (
    <Collapse in={collapse}>
      <Grid
        ref={ref}
        className={classes.root}
        container
        justify={'space-between'}
        alignItems={'center'}
        wrap={'nowrap'}
      >
        <Grid item>
          <Grid container alignItems={'center'} wrap={'nowrap'}>
            <Grid item>
              <CCFlattenDateField
                onChange={(e) => {
                  setValue({
                    ...value,
                    subArgs: {
                      ...value?.subArgs,
                      date: {
                        ...value?.subArgs?.date,
                        begin: e.startOf('day').unix(),
                      },
                    },
                  });
                }}
                value={
                  value?.subArgs?.date?.begin
                    ? moment(value.subArgs.date.begin, 'X')
                    : false
                }
                className={clsx(classes.input, classes.timeInput)}
              />
            </Grid>
            <Grid item>{'~'}</Grid>
            <Grid item>
              <CCFlattenDateField
                onChange={(e) => {
                  setValue({
                    ...value,
                    subArgs: {
                      ...value?.subArgs,
                      date: {
                        ...value?.subArgs?.date,
                        end: e.endOf('day').unix(),
                      },
                    },
                  });
                }}
                value={
                  value?.subArgs?.date?.end
                    ? moment(value.subArgs.date.end, 'X')
                    : false
                }
                className={clsx(classes.input, classes.timeInput)}
              />
            </Grid>
            <Grid item>
              <CCButton
                variant={'contained'}
                size={'small'}
                color={'normal'}
                onClick={() => {
                  setValue({
                    ...value,
                    subArgs: {
                      ...value?.subArgs,
                      date: {
                        begin: moment().startOf('day').unix(),
                        end: moment().endOf('day').unix(),
                      },
                    },
                  });
                }}
              >
                {'Today'}
              </CCButton>
            </Grid>
            <Grid item className={classes.marginLeft8}>
              <CCButton
                variant={'contained'}
                size={'small'}
                color={'normal'}
                onClick={() => {
                  setValue({
                    ...value,
                    subArgs: {
                      ...value?.subArgs,
                      date: {
                        begin: moment().startOf('week').unix(),
                        end: moment().endOf('week').unix(),
                      },
                    },
                  });
                }}
              >
                {'Week'}
              </CCButton>
            </Grid>
            <Grid item className={classes.marginLeft8}>
              <CCButton
                variant={'contained'}
                size={'small'}
                color={'normal'}
                onClick={() => {
                  setValue({
                    ...value,
                    subArgs: {
                      ...value?.subArgs,
                      date: {
                        begin: moment().startOf('month').unix(),
                        end: moment().endOf('month').unix(),
                      },
                    },
                  });
                }}
              >
                {'Month'}
              </CCButton>
            </Grid>
            <Grid item>
              <CCTextField
                label={'구분'}
                value={
                  value?.subArgs?.hasOwnProperty('category')
                    ? value.subArgs.category
                    : 'blank'
                }
                onChange={(e) => {
                  // console.log(e.target.value);
                  if (e.target.value === 'blank') {
                    let _temp = { ...value };
                    if (_temp?.subArgs?.hasOwnProperty('category')) {
                      delete _temp.subArgs.category;
                    }
                    setValue(_temp);
                  } else {
                    setValue({
                      ...value,
                      subArgs: {
                        ...value.subArgs,
                        category: e.target.value,
                      },
                    });
                  }
                }}
                select
                margin={'dense'}
                variant={'outlined'}
                className={clsx(classes.input, classes.selectInput)}
              >
                <MenuItem value={'blank'}>{'전체'}</MenuItem>
                {DOC_CATEGORIES_LIST.map((item) => {
                  if (item.value === DOC_CATEGORIES.ORAL_EXAMINATION.value) {
                    return null;
                  }
                  return (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </CCTextField>
            </Grid>
            <Grid item>
              <CCTextField
                value={
                  value?.subArgs?.hasOwnProperty('type')
                    ? value.subArgs.type
                    : 'blank'
                }
                onChange={(e) => {
                  // console.log(e.target.value);
                  if (e.target.value === 'blank') {
                    let _temp = { ...value };
                    if (_temp?.subArgs?.hasOwnProperty('type')) {
                      delete _temp.subArgs.type;
                    }
                    setValue(_temp);
                  } else {
                    setValue({
                      ...value,
                      subArgs: {
                        ...value.subArgs,
                        type: e.target.value,
                      },
                    });
                  }
                }}
                label={'종류'}
                variant={'outlined'}
                margin={'dense'}
                className={clsx(classes.input, classes.selectInput)}
                select
              >
                <MenuItem value={'blank'}>{'전체'}</MenuItem>
                {DOC_TYPES_LIST.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </CCTextField>
            </Grid>
            <Grid item>
              <CCTextField
                value={
                  value?.subArgs?.hasOwnProperty('purpose')
                    ? value.subArgs.purpose
                    : 'blank'
                }
                onChange={(e) => {
                  // console.log(e.target.value);
                  if (e.target.value === 'blank') {
                    let _temp = { ...value };
                    if (_temp?.subArgs?.hasOwnProperty('purpose')) {
                      delete _temp.subArgs.purpose;
                    }
                    setValue(_temp);
                  } else {
                    setValue({
                      ...value,
                      subArgs: {
                        ...value.subArgs,
                        purpose: e.target.value,
                      },
                    });
                  }
                }}
                label={'용도'}
                variant={'outlined'}
                margin={'dense'}
                className={clsx(classes.input, classes.selectInput)}
                select
              >
                <MenuItem value={'blank'}>{'전체'}</MenuItem>
                {DOC_PURPOSES_LIST.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </CCTextField>
            </Grid>
            <Grid item>
              <CCTextField
                value={
                  value?.subArgs?.hasOwnProperty('status')
                    ? value.subArgs.status
                    : 'blank'
                }
                onChange={(e) => {
                  // console.log(e.target.value);
                  if (e.target.value === 'blank') {
                    let _temp = { ...value };
                    if (_temp?.subArgs?.hasOwnProperty('status')) {
                      delete _temp.subArgs.status;
                    }
                    setValue(_temp);
                  } else {
                    setValue({
                      ...value,
                      subArgs: {
                        ...value.subArgs,
                        status: e.target.value,
                      },
                    });
                  }
                }}
                label={'상태'}
                variant={'outlined'}
                margin={'dense'}
                className={clsx(classes.input, classes.selectInput)}
                select
              >
                <MenuItem value={'blank'}>{'전체'}</MenuItem>
                {DOC_STATUS_LIST.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </CCTextField>
            </Grid>

            <Grid item>
              <SelectorStaff
                className={clsx(classes.input, classes.selectInput)}
                label={'담당의사'}
                value={
                  value?.subArgs?.hasOwnProperty('doctor')
                    ? value.subArgs.doctor
                    : 'blank'
                }
                onChange={(e) => {
                  if (e.target.value === 'blank') {
                    let _temp = { ...value };
                    if (_temp?.subArgs?.hasOwnProperty('doctor')) {
                      delete _temp.subArgs.doctor;
                    }
                    setValue(_temp);
                  } else {
                    setValue({
                      ...value,
                      subArgs: {
                        ...value.subArgs,
                        doctor: e.target.value,
                      },
                    });
                  }
                }}
                blank={{ label: '전체', value: 'blank' }}
                args={{
                  status: STATUS.WORKING.value,
                  charge: CHARGE.DOCTOR_IN_CHARGE.value,
                }}
                variant={'outlined'}
                margin={'dense'}
                InputProps={{}}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CCButton
            style={{ minWidth: 87 }}
            startIcon={<SearchIcon />}
            variant={'contained'}
            color={'secondary'}
            onClick={() => {
              // if (
              //   value?.content?.patient &&
              //   Object.values(value?.content?.patient).length === 0
              // ) {
              //   delete value.content;
              // }

              onChange && onChange(value);
            }}
          >
            {'검색'}
          </CCButton>
        </Grid>
      </Grid>
    </Collapse>
  );
});
export default FilterCollapse;
