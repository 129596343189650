import React from "react";

const MoveupIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M1.41 7.41L6 2.83 10.59 7.41 12 6 6 0 0 6z"
        transform="matrix(-1 0 0 1 18 8)"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default MoveupIcon;
