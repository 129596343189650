import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CCTable, CCTextField, CCTextFieldNumber } from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import CCPaper from "styles/src/components/CCPaper";
import CCTypography from "styles/src/components/CCTypography";
import { CAPITALIZE_TYPE, useIntl } from "utils/language";
import { Box, Checkbox } from "@material-ui/core";
import { CVButton } from "components";
import StatusTable from "views/Normal/InventoryManagement/Component/utils/StatusTable/StatusTable";
import { useQuery } from "@apollo/client";
import { SEARCH_PAMCART } from "queries/stock";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/utils/TableItems";
import DeleteIcon from "@material-ui/icons/Delete";
import { CalculationIcon } from "styles/src/themes/common/icons";
import axios from "axios";
import { DBVendorContext } from "../../../../../../../context/DBContext/components";

const tableStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },

  table__title: {
    // height: 30,
    // padding: "12px 12px 8px 16px"
  },
  table__head__row: {
    // width: "100%",
    // textAlign: "center"
  },
  table__body: {
    // backgroundColor: theme.palette.background.default
    // height: "100%",
    // width: "100%"
  }
}));

const useStyle = makeStyles(theme => ({
  init: {
    position: "relative",
    height: "100%",
    padding: 16
    // backgroundColor: theme.palette.common.white
  },
  root: {
    // backgroundColor: theme.palette.background.paper,
    padding: 8,
    marginBottom: 16,
    minWidth: 1400
  },
  input: {
    // backgroundColor: theme.palette.background.paper
  },
  timeInput: {
    width: 130
  },
  selectInput: {
    width: 142
  },
  memoInput: {
    width: 236
  },
  marginLeft8: {
    marginLeft: 8
  },
  description: {
    display: "flex",
    marginLeft: "10px",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px"
  },
  paper: {
    width: "100%",
    minWidth: 1000,
    // paddingTop: 5,
    // padding: 5,
    height: "85%"
    // display: "grid",
    // justifyContent: "center",
    // alignContent: "center"
  },
  select__field: {
    marginLeft: 20,
    width: 200
  },
  small__button: {
    "&:hover": {
      backgroundColor: theme.palette.select.main
    }
  },
  selected: {
    backgroundColor: theme.palette.select.main,
    cursor: "default"
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  item: {
    width: "150%",
    display: "inline-flex",
    alignItems: "center"
  },
  margin__box: {
    width: 50
  },
  title__table: {
    height: 20,
    display: "space-between"
  },
  height__table: {
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    justifyContent: "center",
    width: "95%",
    height: "100%",
    border: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    borderRadius: 10
    // height: 400
  },
  align__center: {
    // width: "100%",
    width: "100%",
    padding: 5,
    display: "grid",
    justifyContent: "center",
    alignContent: "center"
    // border: `1px solid ${theme.palette.border.main}`,
    // boxSizing: "border-box",
    // backgroundColor: theme.palette.background.default,
    // borderRadius: 10
  },
  container: { height: "100%", alignItems: "center", justifyContent: "center" },
  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    // padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "50px",
    alignItems: "center"
  },
  cart__box: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "90%",
    width: "100%",
    boxSizing: "border-box",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  left__box: {
    marginLeft: "auto",
    margin: 10
  },
  left__individual: {
    marginLeft: "auto",
    marginBottom: "-20px"
  },
  button__box: {
    height: "10%",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex"
  }
}));

const CartTab = () => {
  const classes = useStyle();
  const tableClasses = tableStyles();

  const [material, setMaterial] = useState([]);
  const [list, setList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  // const { dbVendorContext } = useContext(DBVendorContext);

  const intl = useIntl();

  const { data } = useQuery(SEARCH_PAMCART, {
    variables: {
      hospitalId: localStorage.getItem("hospitalId")
    }
  });

  const onModify = useCallback(
    row => {
      const { rowIndex, e, rowData } = row;

      let rows = material.filter(e => e.materialId === rowData?.material)[0]
        ?.item;
      const counts = rows.map(item => item?.count);
      const prices = rows.map(item => item?.unitPrice);
      const totals = rows.map(item => item?.total);

      const ModMap = [];
      const FinalMap = [];

      for (let i = 0; i < rows?.length; i++) {
        if (i === rowIndex) {
          counts[i] = Number(e);
          totals[i] = Number(e) * Number(prices[i]);
        }

        // }
        ModMap.push({
          ...rows[i],
          count: counts[i],
          total: totals[i]
        });
      }

      for (let i = 0; i < material?.length; i++) {
        if (Number(material[i].materialId) === Number(rowData?.material)) {
          FinalMap.push({ ...material[i], item: ModMap });
        } else {
          FinalMap.push(material[i]);
        }
      }

      setMaterial(FinalMap);
    },
    [material]
  );

  const getVendorInfo = async () => {
    try {
      // setLoading(true);

      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_PAM_URL
        }/api/v1/clinic/${sessionStorage.getItem("clinicId")}/material`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          setList(response?.data);
          // setList(response?.data?.map(e => e.id));
        })
        .catch(err => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const fetchVendor = async args => {
    let variables = {
      offset: 0,
      limit: 10
    };
    if (args) {
      variables.offset = args.lastKey;
    }

    try {
      // setLoading(true);
    } catch (e) {
      console.error(e);
    }
    // setLoading(false);
  };

  //dbVendorContext
  // useEffect(() => {
  //   // TODO: add Context
  //   if (dbVendorContext) {
  //     fetchVendor();
  //   }
  // }, [dbVendorContext]);

  const heads = [
    {
      key: "item",
      component: ({ cellData, rowData, rowIndex }) => {
        return (
          <Grid container className={classes.align__center}>
            <Grid item style={{ height: "90%" }}>
              <CCTable
                title={
                  <>
                    <Box className={classes.title__table}>
                      <CCTypography variant={"h5"} style={{ marginBottom: 15 }}>
                        장바구니
                      </CCTypography>
                    </Box>
                  </>
                }
                contents={cellData || []}
                heads={[
                  {
                    key: "Material",
                    headComponent: ({ cellData, rowData }) => {
                      return (
                        <Checkbox
                          disableRipple
                          // checked={rowData}
                        />
                      );
                    },
                    width: 50,
                    component: ({ cellData, rowData }) => {
                      let _checked = searchList?.includes(rowData);
                      return (
                        <TableItem
                          disableTypography
                          label={
                            // connected.includes(cellData?.id) ? (
                            <Checkbox
                              //TODO: checked 선택된 내용 불러올 수 있게
                              checked={_checked}
                              style={{ padding: 10 }}
                              disableRipple
                              onChange={e => {
                                if (e.target.checked) {
                                  if (searchList?.length > 0) {
                                    setSearchList([...searchList, rowData]);
                                  } else {
                                    setSearchList([rowData]);
                                  }
                                } else {
                                  setSearchList(
                                    searchList.filter(e => e !== rowData)
                                  );
                                }
                              }}
                              className={classes.row__focus}
                            />
                          }
                        />
                      );
                    }
                  },
                  {
                    key: "material",
                    headComponent: () => (
                      <TableHeader
                        label={intl.formatMessage({ id: "supplier" })}
                      />
                    ),
                    width: 160,
                    label: () => (
                      <TableHeader
                        label={intl.formatMessage({ id: "supplier" })}
                      />
                    ),
                    component: ({ cellData }) => {
                      let data = list.filter(e => e.id === cellData);
                      return <TableItem label={data ? data[0]?.name : ""} />;
                    }
                  },
                  {
                    key: "category1",
                    width: 200,
                    headComponent: () => (
                      <TableHeader
                        label={intl.formatMessage({ id: "major" })}
                      />
                    ),
                    label: () => <TableHeader label={"major"} />,
                    component: ({ cellData }) => {
                      return <TableItem label={cellData ? cellData : ""} />;
                    }
                  },
                  {
                    key: "category2",
                    width: 200,
                    headComponent: () => (
                      <TableHeader
                        label={intl.formatMessage({ id: "minor" })}
                      />
                    ),
                    label: <TableHeader label={"minor"} />,
                    component: ({ cellData }) => {
                      return <TableItem label={cellData ? cellData : ""} />;
                    }
                  },
                  {
                    key: "name",
                    width: 470,
                    label: (
                      <TableHeader
                        label={intl.formatMessage({ id: "productName" })}
                      />
                    ),
                    component: ({ cellData }) => {
                      return <TableItem label={cellData ? cellData : ""} />;
                    }
                  },
                  {
                    key: "manufacture",
                    width: 110,
                    headComponent: () => (
                      <TableHeader
                        label={intl.formatMessage({ id: "manufacturer" })}
                      />
                    ),
                    label: (
                      <TableHeader
                        label={intl.formatMessage(
                          { id: "manufacture" },
                          {},
                          { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                        )}
                      />
                    ),
                    component: ({ cellData }) => (
                      <TableItem label={cellData ? cellData : ""} />
                    )
                  },
                  {
                    key: "unit1",
                    width: 90,
                    headComponent: () => (
                      <TableHeader label={intl.formatMessage({ id: "unit" })} />
                    ),
                    label: (
                      <TableHeader label={intl.formatMessage({ id: "unit" })} />
                    ),
                    component: ({ cellData }) => (
                      <TableItem label={cellData ? cellData : ""} />
                    )
                  },
                  {
                    key: "unitPrice",
                    width: 90,
                    headComponent: () => (
                      <TableHeader
                        label={intl.formatMessage({ id: "pricePerUnit" })}
                      />
                    ),
                    label: (
                      <TableHeader
                        label={intl.formatMessage(
                          { id: "unitPriceGold" },
                          {},
                          { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                        )}
                      />
                    ),
                    component: ({ cellData, rowData }) => {
                      return <TableItem label={cellData ? cellData : ""} />;
                    }
                  },
                  {
                    key: "Material",
                    width: 120,
                    label: (
                      <TableHeader
                        label={intl.formatMessage({ id: "pricePerUnit" })}
                      />
                    ),
                    component: ({ cellData, rowData, rowIndex }) => {
                      return (
                        <CCTextFieldNumber
                          label={intl.formatMessage({ id: "qty" })}
                          // number={value[rowIndex].expireCount}
                          decimalPoint={0}
                          step={1}
                          onChange={e => {
                            onModify({
                              rowIndex,
                              e,
                              rowData
                            });
                            // onModify({
                            //   option: false,
                            //   rowIndex,
                            //   e
                            // });
                          }}
                        />
                      );
                    }
                  },
                  {
                    key: "total",
                    width: 90,
                    label: (
                      <TableHeader
                        label={intl.formatMessage({ id: "payAmount" })}
                      />
                    ),
                    component: ({ cellData, rowData }) => {
                      console.log();
                      return <TableItem label={cellData ? cellData : ""} />;
                    }
                  }
                ]}
                className={classes.height__table}
              />
            </Grid>
            <Grid item className={classes.button__box}>
              <CCButton
                style={{
                  height: 36,
                  paddingTop: 0,
                  paddingBottom: 0,
                  width: 110
                }}
                variant={"contained"}
                color={"error"}
                startIcon={<DeleteIcon />}
              >
                {intl.formatMessage({ id: "search" })}
              </CCButton>
              <Box marginLeft={"auto"}>
                <CCTextField
                  style={{ width: "200px" }}
                  variant={"outlined"}
                  margin={"dense"}
                  value={rowData.item
                    .map(e => (e?.total ? e?.total : 0))
                    ?.reduce((partial_sum, a) => partial_sum + a, 0)}
                  disabled={true}
                  label={"합계"}
                  // label={intl.formatMessage({
                  //   id: "productNameOrManufacture"
                  // })}
                />
              </Box>
            </Grid>
          </Grid>
        );
      }
    }
  ];

  useEffect(() => {
    if (data?.searchPamcart?.length > 0) {
      setMaterial(data?.searchPamcart);
    }
  }, [data]);

  useEffect(() => {
    getVendorInfo();
  }, []);

  return (
    <>
      <Grid className={classes.init}>
        <CCPaper className={classes.paper}>
          <Box className={classes.cart__box}>
            <Box className={classes.root}>
              <Grid className={classes.container} container direction="column">
                <Grid style={{ height: "200px", boxSizing: "border-box" }} item>
                  <Box>
                    <StatusTable
                      title={
                        <>
                          <Box className={classes.title__table}>
                            <CCTypography
                              variant={"h5"}
                              style={{ marginBottom: 15 }}
                            >
                              장바구니
                              {/*장바구니({material?.length})*/}
                            </CCTypography>
                          </Box>
                        </>
                      }
                      heads={heads}
                      // contents={material?.length > 0 ? material.map(e => {
                      //   return { ...e, depth: 2 };
                      // }) : []}
                      contents={material || []}
                      classes={tableClasses}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classes.button__box}>
            <CCTextField
              style={{ width: "300px" }}
              variant={"outlined"}
              margin={"dense"}
              value={material?.name}
              disabled={true}
              label={"합계"}
              // label={intl.formatMessage({
              //   id: "productNameOrManufacture"
              // })}
            />
            <Box marginLeft={"auto"}>
              <CVButton
                color="primary"
                startIcon={<CalculationIcon />}
                variant={"contained"}
                // onClick={() => {
                //   setEstimateDialog(true);
                // }}
              >
                {intl.formatMessage({ id: "order" })}
              </CVButton>
            </Box>
          </Box>
        </CCPaper>
      </Grid>
    </>
  );
};

export default CartTab;
