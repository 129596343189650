import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import React from "react";
import { CCLabelCheckBox } from "styles/src/components";
import CCTypography from "styles/src/components/CCTypography";
import { useIntl } from "utils/language";

const ContractCheck = props => {
  const {
    checked,
    title,
    required,
    contract,
    onChange,
    onOpenContract
  } = props;
  const intl = useIntl();
  return (
    <Grid container>
      <CCLabelCheckBox
        color={"secondary"}
        checked={checked}
        required={required}
        requirePosition={"right"}
        label={<CCTypography variant={"h5"}>{title}</CCTypography>}
        onChange={e => {
          onChange && onChange(e);
        }}
      />
      <Link
        component={"button"}
        color={"secondary"}
        variant={"body1"}
        onClick={() => {
          onOpenContract && onOpenContract(contract);
        }}
      >
        {intl.formatMessage({id: "viewContract"})}
      </Link>
    </Grid>
  );
};
export default ContractCheck;
