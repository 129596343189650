import { Badge, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { translate } from "components";
import React from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";
import { MenunextIcon } from "styles/icons";
import MonthTooltipTitle from "../MonthTooltipTitle";

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 36
  },
  badge: {
    marginLeft: 8,
    marginRight: 24,
    "& span": {
      transform: "scale(1) translate(100%, -50%)",
      backgroundColor: theme.palette.sub.main
    }
  },
  total: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 60,
    height: 30,
    borderRadius: 18,
    color: theme.palette.common.white,
    paddingLeft: 16
  },
  total__number: {
    color: theme.palette.common.white
  },
  bg__shipped: {
    backgroundColor: theme.palette.primary.main
  },
  bg__ordered: {
    backgroundColor: theme.palette.secondary.main
  },
  bg__pending: {
    backgroundColor: theme.palette.error.main
  },
  bg__empty: {
    backgroundColor: theme.palette.input.main,

    "& svg": {
      color: theme.palette.sub.main
    }
  },
  text__shipped: {
    color: theme.palette.primary.main
  },
  text__ordered: {
    color: theme.palette.secondary.main
  },
  text__pending: {
    color: theme.palette.error.main
  },
  text__empty: {
    color: theme.palette.input.main
  }
}));

const MonthItemTooltip = props => {
  const { items, tooltipType, total } = props;

  const classes = useStyle();
  const intl = useIntl();

  return (
    <MonthTooltipTitle items={items} {...props}>
      <Grid container className={classes.root}>
        <Grid item>
          <Badge variant="dot" className={classes.badge} />
          <CCTypography
            variant="body1"
            component="span"
            className={clsx({
              [classes.text__ordered]: tooltipType === "ordered",
              [classes.text__pending]: tooltipType === "pending",
              [classes.text__shipped]: tooltipType === "shipped",
              [classes.text__empty]: total === 0
            })}
          >
            {tooltipType === "ordered"
              ? translate(intl, "labOrder.ordered")
              : tooltipType === "pending"
              ? translate(intl, "labOrder.pending")
              : translate(intl, "labOrder.shipped")}
          </CCTypography>
        </Grid>
        <Grid
          item
          className={clsx(classes.total, {
            [classes.bg__ordered]: tooltipType === "ordered",
            [classes.bg__pending]: tooltipType === "pending",
            [classes.bg__shipped]: tooltipType === "shipped",
            [classes.bg__empty]: total === 0
          })}
        >
          <CCTypography
            variant="h5"
            component="span"
            className={classes.total__number}
          >
            {total}
          </CCTypography>
          <MenunextIcon />
        </Grid>
      </Grid>
    </MonthTooltipTitle>
  );
};

export default React.memo(MonthItemTooltip);
