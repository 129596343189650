import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { CCButton, CCIconButton, CCTooltip } from "../";
import { SettingIcon } from "../../themes/common/icons";

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    border: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.result.main,
    position: "relative",
    padding: "9px 8px 0px 8px"
  },
  btn: {
    margin: "0 8px 8px 0",
    // "&:focus": {
    //   outline: "-webkit-focus-ring-color auto 5px;",
    //   zIndex: 1
    // },
    height: props => {
      if (props?.button?.height) {
        return props.button.height;
      }

      return 20;
    }
  },
  setting__icon: {
    top: "-4px",
    right: "4px",
    position: "absolute"
  },
  root__padding: {
    padding: "9px 25px 0px 8px"
  },
  ellipsis: {
    "& .MuiButton-label": {
      display: "block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  },
  arabic_fix: {
    direction: "rtl",
    textAlign: "left"
  }
}));

const CCButtonList = forwardRef((props, ref) => {
  const {
    btnGroupList,
    selectedBtn,
    btnClick,
    settingClick,
    className,
    tooltip = false,
    maxWidth = false,
    onTooltip = () => {
      return "";
    }
  } = props;
  const classes = useStyles(props);
  const [btn, setBtn] = useState(selectedBtn.map(d => d.id));
  const iconRefs = useRef({});

  const btnClickFunc = useCallback(
    e => {
      btnClick(e);
    },
    [btnClick]
  );

  useImperativeHandle(ref, () => ({
    getButtonsRef: () => {
      return iconRefs;
    }
  }));

  useEffect(() => {
    setBtn(selectedBtn.map(d => d.id));
  }, [selectedBtn]);

  return (
    <Box
      className={clsx(
        {
          [classes.root]: true,
          [classes.root__padding]: settingClick
        },
        className
      )}
    >
      {btnGroupList.map((d, index) => {
        if (tooltip)
          return (
            <CCTooltip
              title={onTooltip(d)}
              key={index}
              placement="bottom"
              arrow
              enterDelay={1000}
            >
              <CCButton
                key={d.id}
                className={clsx(classes.btn, {
                  [classes.ellipsis]: !!maxWidth
                })}
                style={{ maxWidth: maxWidth ? maxWidth : "auto" }}
                onClick={() => btnClickFunc(d)}
                color={btn.indexOf(d.id) !== -1 ? "selected" : "normal"}
                variant={"contained"}
                size={"small"}
                ref={ref => {
                  iconRefs.current[d.id] = ref;
                }}
              >
                {d.text}
              </CCButton>
            </CCTooltip>
          );
        return (
          <CCButton
            key={d.id}
            className={clsx(classes.btn, { [classes.ellipsis]: !!maxWidth })}
            style={{ maxWidth: maxWidth ? maxWidth : "auto" }}
            onClick={() => btnClickFunc(d)}
            color={btn.indexOf(d.id) !== -1 ? "selected" : "normal"}
            variant={"contained"}
            size={"small"}
            ref={ref => {
              iconRefs.current[d.id] = ref;
            }}
          >
            {d.text}
          </CCButton>
        );
      })}
      {settingClick && (
        <CCIconButton className={classes.setting__icon} onClick={settingClick}>
          <SettingIcon />
        </CCIconButton>
      )}
    </Box>
  );
});

CCButtonList.defaultProps = {
  btnGroupList: [
    "구강검진",
    "P/E",
    "C/E",
    "C/I",
    "C/F",
    "RF",
    "Ext",
    "S/O",
    "Dr",
    "Inlay Prep",
    "Cr Prep",
    "Inlay Setting",
    "Crown Setting",
    "S/C",
    "Cu",
    "Implant 1st op",
    "Implant 2st op",
    "Implant ck"
  ],
  selectedBtn: ["구강검진", "CU"],
  btnClick: e => {
    console.log(e);
  }
};

CCButtonList.propTypes = {
  /** 버튼 이름 */
  btnGroupList: PropTypes.array.isRequired,
  /** 선택된 버튼 */
  selectedBtn: PropTypes.array.isRequired,
  /** 버튼 클릭 시 실행되는 함수 */
  btnClick: PropTypes.func.isRequired,
  /** Setting Icon 클릭 시 실행되는 함수 */
  settingClick: PropTypes.func
};

export default CCButtonList;
