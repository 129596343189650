import React from "react";

const ScreenshotIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M18.8 12l1.098 1.222H21.8c.619 0 1.132.484 1.194 1.098l.006.124v7.334c0 .63-.475 1.153-1.078 1.216L21.8 23h-9.6c-.619 0-1.132-.483-1.194-1.098L11 21.778v-7.334c0-.63.475-1.153 1.078-1.215l.122-.007h1.902L15.2 12h3.6zM17 14.75c-1.794 0-3.25 1.456-3.25 3.25s1.456 3.25 3.25 3.25 3.25-1.456 3.25-3.25-1.456-3.25-3.25-3.25zM9 19v2H7v-2h2zm-4 0v2c-1.1 0-2-.9-2-2h2zm12-3c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zM5 15v2H3v-2h2zm0-4v2H3v-2h2zm16 0v1h-1l-1-1h2zM5 7v2H3V7h2zm16 0v2h-2V7h2zM5 3v2H3c0-1.1.9-2 2-2zm8 0v2h-2V3h2zm6 0c1.1 0 2 .9 2 2h-2zM9 3v2H7V3h2zm8 0v2h-2V3h2z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default ScreenshotIcon;
