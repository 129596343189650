import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { DialogMiddleSizeInfo, IconMenu } from "../";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  icon__button: {
    borderRadius: 20,
    margin: 2,
    background: "white",
    "&:hover": {
      background: "white"
    }
  },
  icon__open: { color: theme.palette.sub.main },
  icon__close: { color: theme.palette.select.main }
}));

// const useChipsStyles = makeStyles((theme) => ({
//   root: { backgroundColor: theme.palette.sub.main },
//   content: {
//     paddingLeft: 4,
//     color: 'white',
//   },
// }));

const MiddleSizeInfo = () => {
  const classes = useStyles();
  // const chipsClasses = useChipsStyles();
  // const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [
    anchorEl
    // setAnchorEl
  ] = useState(null);

  return (
    <div className={classes.root}>
      {/* <Chips
        background={open ? theme.palette.select.main : theme.palette.sub.main}
        classes={chipsClasses}
        endIcon={
          open ? (
            <CCIconButton
              className={clsx(classes.icon__button, classes.icon__close)}
              size='small'
              onClick={() => {
                setOpen(false);
                setAnchorEl(null);
              }}
            >
              <MenucloseIcon />
            </CCIconButton>
          ) : (
            <CCIconButton
              className={clsx(classes.icon__button, classes.icon__open)}
              size='small'
              onClick={(e) => {
                setOpen(true);
                setAnchorEl(e.currentTarget);
              }}
            >
              <MenunextIcon className={classes.icon__open} />
            </CCIconButton>
          )
        }
      >
        More
      </Chips> */}

      <IconMenu />

      <DialogMiddleSizeInfo
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default MiddleSizeInfo;
