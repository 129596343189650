import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    position: "fixed",
    zIndex: 100,
    background: "gray",
    opacity: 0.5,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 12
  },
  footerShift: {
    marginLeft: theme.sidebar.width,
    width: `calc(100% - ${theme.sidebar.width}px)`
  }
}));

const Footer = props => {
  const { open, isDesktop } = props;

  const classes = useStyles();
  
  const [clever, setClever] = useState({});
  
  useEffect(() => {
    fetch("/version.json")
      .then(res => res.json())
      .then(meta => {
        console.log('meta', meta)
        setClever({
          version: meta.version,
          date: process.env.REACT_APP_DEPLOY_DATE,
          commit: meta.commit
        });
      });
  }, []);
  
  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.footerShift]: open && isDesktop
      })}
    >
      {`Version: ${clever.version} (${moment(clever.date, "X").format("YYYY.MM.DD HH:mm:ss")} ${clever.commit})`}
    </Box>
  );
};

export default Footer;
