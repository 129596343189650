/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import {
    implantImageHeight,
    implantImageWidth,
    implantLengthMax,
    implantList,
    toothImageHeight,
    toothList
} from "components/ImplantPlacementDialog";
import * as pako from "pako";
import Paper, {
    Color,
    Group,
    Layer,
    Matrix,
    Path,
    Point,
    PointText,
    Raster,
    Rectangle,
    Size
} from "paper";
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import { useDrop } from "react-dnd";
import { v4 as uuid } from "uuid";

var assets = {};
assets["scale"] = "/contents/scale.svg";
assets["scalev"] = "/contents/scalev.svg";
assets["scaleh"] = "/contents/scaleh.svg";
assets["rotate"] = "/contents/rotate.svg";
assets["delete"] = "/contents/delete.svg";
assets["visible"] = "/contents/visible.svg";
assets["upload"] = "/contents/save.svg";
assets["subtract"] = "/contents/subtract.svg";

var manifests = {};

for (const name in assets) {
  fetch(assets[name])
    .then(res => res.text())
    .then(res => {
      manifests[name] = res;
    })
    .catch(err => console.error(err));
}

const pointSize = 8;
const hitTolerance = 8;

const contentSize = 1024;
const minimumSize = 32;

const useStyles = makeStyles(theme => ({}));

const PhotoCanvas = forwardRef((props, ref) => {
  const {
    width,
    height,
    layout,
    target,
    source,
    content,
    region,
    detail,
    modes = "",
    menus = ["subtract", "upload", "visible"],
    action,
    styles,
    onDrop,
    onPhotoAction,
    onPhotoLoading,
    onPhotoLoaded,
    onImplantEdit,
    onMouseDown,
    onMouseUp
  } = props;
  const classes = useStyles();
  const [dropInfo, setDropInfo] = useState(null);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "photo",
    drop: (props, monitor, component) => {
      setDropInfo({
        name: props.name,
        source: props.source,
        point: monitor.getClientOffset()
      });
      return { name: "canvas" };
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const paper = useMemo(() => {
    return new Paper.PaperScope();
  }, []);
  const [layers, setLayers] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentRegion, setCurrentRegion] = useState(null);
  const [currentBackgrounds, setCurrentBackgrounds] = useState(null);
  const [currentNotices, setCurrentNotices] = useState(null);
  const [currentControls, setCurrentControls] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [currentGrab, setCurrentGrab] = useState(null);
  const [currentOver, setCurrentOver] = useState(null);
  const [currentSegment, setCurrentSegment] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);
  const [currentPoint, setCurrentPoint] = useState(null);
  const [currentOffset, setCurrentOffset] = useState(null);
  const [currentDelta, setCurrentDelta] = useState(null);
  const [currentGuide, setCurrentGuide] = useState(null);
  const [currentText, setCurrentText] = useState("");
  const [currentCursor, setCurrentCursor] = useState("default");
  const [currentLogs, setCurrentLogs] = useState({
    undo: [],
    redo: [],
    images: []
  });
  const [currentVisible, setCurrentVisible] = useState(true);
  const [actionMode, setActionMode] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [openTextField, setOpenTextField] = useState(false);
  const [textFieldPosition, setTextFieldPosition] = useState({});

  const actionPath = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      var path = new Path({
        strokeColor: styles.color,
        strokeWidth: styles.weight,
        strokeCap: "round",
        strokeJoin: "round",
        fullySelected: true
      });
      pushContents(path);
      updateContents(path);

      setCurrentPath(path);
      setActionMode("grab");
    },
    handleUp: e => {
      paper.activate();

      if (actionMode === "grab") {
        if (currentPath !== null) {
          currentPath.simplify(10);

          currentPath.data.id = uuid();
          currentPath.data.tag = "item";
          currentPath.data.type = "path";
          currentPath.fullySelected = false;

          pushActionLogs(currentPath, "create");

          setCurrentPath(null);
        }
      }

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        if (currentPath !== null) {
          currentPath.add(point.x, point.y);
        }
      }

      setCurrentPoint(point);
    }
  };

  const actionLine = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      setActionMode("grab");

      setCurrentOffset(point);
      setCurrentPoint(point);
    },
    handleUp: e => {
      paper.activate();

      hideCurrentGuide();

      if (getScreenLength(currentOffset, currentPoint) > minimumSize) {
        if (actionMode === "grab") {
          var path = new Path.Line(currentOffset, currentPoint);
          path.strokeColor = styles.color;
          path.strokeWidth = styles.weight;
          path.strokeCap = "round";
          path.strokeJoin = "round";
          path.fillColor = "rgba(0, 0, 0, 0.01)";
          path.data.id = uuid();
          path.data.tag = "item";
          path.data.type = "line";

          pushActionLogs(path, "create");
          pushContents(path);
          updateContents(path);
        }
      }

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        showCurrentGuide("line", new Rectangle(currentOffset, point), {
          from: currentOffset,
          to: point
        });
      }

      setCurrentPoint(point);
    }
  };

  const actionCircle = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      setActionMode("grab");

      setCurrentOffset(point);
      setCurrentPoint(point);
    },
    handleUp: e => {
      paper.activate();

      hideCurrentGuide();

      if (getScreenLength(currentOffset, currentPoint) > minimumSize) {
        if (actionMode === "grab") {
          var path = new Path.Ellipse(currentOffset, currentPoint);
          path.strokeColor = styles.color;
          path.strokeWidth = styles.weight;
          path.strokeCap = "round";
          path.strokeJoin = "round";
          path.data.id = uuid();
          path.data.tag = "item";
          path.data.type = "circle";

          pushActionLogs(path, "create");
          pushContents(path);
          updateContents(path);
        }
      }

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        showCurrentGuide("circle", new Rectangle(currentOffset, point));
      }

      setCurrentPoint(point);
    }
  };

  const actionBox = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      setActionMode("grab");

      setCurrentOffset(point);
      setCurrentPoint(point);
    },
    handleUp: e => {
      paper.activate();

      hideCurrentGuide();

      if (getScreenLength(currentOffset, currentPoint) > minimumSize) {
        if (actionMode === "grab") {
          var path = new Path.Rectangle(currentOffset, currentPoint);
          path.strokeColor = styles.color;
          path.strokeWidth = styles.weight;
          path.strokeCap = "round";
          path.strokeJoin = "round";
          path.data.id = uuid();
          path.data.tag = "item";
          path.data.type = "box";

          pushActionLogs(path, "create");
          pushContents(path);
          updateContents(path);
        }
      }

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        showCurrentGuide("box", new Rectangle(currentOffset, point));
      }

      setCurrentPoint(point);
    }
  };

  const actionText = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point = getCanvasPoint(e);

      setCurrentText("");

      setActionMode("grab");

      setCurrentOffset(getSketchPoint(point));
    },
    handleUp: e => {
      paper.activate();

      if (actionMode === "grab" && hasCurrentGuide()) {
        var path = new PointText(new Point(0, 0));
        path.data.id = uuid();
        path.data.tag = "item";
        path.data.type = "text";
        path.fullySelected = false;
        path.content = " ";
        path.style = {
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fontSize: 24,
          fillColor: styles.color,
          justification: "center"
        };
        pushContents(path);
        updateContents(path);

        fitItemInBounds(path, getGuideBounds());
        setCurrentPath(path);

        setTextFieldPosition(getClientPoint(e));
        setOpenTextField(true);

        setActionType("create");
      }

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        showCurrentGuide("box", new Rectangle(currentOffset, point));
      }

      setCurrentPoint(point);
    }
  };

  const actionFigure = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      setActionMode("grab");

      setCurrentOffset(point);
      setCurrentPoint(point);
    },
    handleUp: e => {
      paper.activate();

      hideCurrentGuide();

      if (getScreenLength(currentOffset, currentPoint) > minimumSize) {
        if (actionMode === "grab") {
          var figure = new Group();
          figure.data.id = uuid();
          figure.data.tag = "item";
          figure.data.type = "figure";
          figure.visible = false;
          figure.importSVG(styles.figure, item => {
            fitItemInBounds(item, new Rectangle(currentOffset, currentPoint));

            setTimeout(() => {
              figure.visible = true;
            }, 180);
          });

          pushActionLogs(figure, "create");
          pushContents(figure);
          updateContents(figure);
        }
      }

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        showCurrentGuide("box", new Rectangle(currentOffset, point));
      }

      setCurrentPoint(point);
    }
  };

  const actionRuler = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      setActionMode("grab");

      setCurrentOffset(point);
      setCurrentPoint(point);
    },
    handleUp: e => {
      paper.activate();

      hideCurrentGuide();

      if (getScreenLength(currentOffset, currentPoint) > minimumSize) {
        if (actionMode === "grab") {
          var path = new Path.Line(currentOffset, currentPoint);
          path.strokeColor = "green";
          path.strokeWidth = 4;
          path.strokeCap = "round";
          path.strokeJoin = "round";
          path.dashArray = [8, 8];
          path.data.id = uuid();
          path.data.tag = "ruler";
          path.data.type = "line";

          pushActionLogs(path, "create");
          pushContents(path);
          updateContents(path);
        }
      }

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        showCurrentGuide("ruler", new Rectangle(currentOffset, point), {
          from: currentOffset,
          to: point
        });
      }

      setCurrentPoint(point);
    }
  };

  const actionMove = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point = getLayerPoint(getCanvasPoint(e));

      setActionMode("grab");

      setCurrentPoint(point);
    },
    handleUp: e => {
      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getLayerPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        const dx = point.x - currentPoint.x;
        const dy = point.y - currentPoint.y;

        layers.sketch.matrix.translate(new Point(dx, dy));
      }

      setCurrentPoint(point);
    }
  };

  const actionDelete = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      setActionMode("grab");

      setCurrentOffset(point);
    },
    handleUp: e => {
      paper.activate();

      if (actionMode === "grab") {
        const bounds = getGuideBounds();
        const targets = [];

        currentGroup.children.forEach(item => {
          if (bounds.contains(item.bounds) || bounds.intersects(item.bounds)) {
            targets.push(item);
          }
        });

        targets.forEach(item => {
          pushActionLogs(item, "delete");
          popContents(item);
        });

        hideCurrentGuide();
      }

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const point = getSketchPoint(getCanvasPoint(e));

      if (actionMode === "grab") {
        showCurrentGuide("box", new Rectangle(currentOffset, point));
      }

      setCurrentPoint(point);
    }
  };

  const actionCrop = {
    handleEnter: e => {},
    handleLeave: e => {},
    handleDown: e => {
      paper.activate();

      const point0 = getCanvasPoint(e);
      const point = getSketchPoint(point0);

      var hitResult = paper.project.hitTest(point0, {
        stroke: true,
        fill: true
      });

      var item = hitResult ? getItemByTags(hitResult.item, ["guide"]) : null;

      if (item) {
        if (item.data.type === "apply") {
          const contentWidth = getContentWidth();
          const contentHeight = getContentHeight(currentImage, currentRegion);
          const _region = currentRegion
            ? currentRegion
            : {
                x: 0,
                y: 0,
                width: contentWidth,
                height: contentHeight
              };
          const bounds = getGuideBounds();
          const point0 = new Point(
            _region.x + (bounds.x * _region.width) / contentWidth,
            _region.y + (bounds.y * _region.height) / contentHeight
          );
          const point1 = new Point(
            _region.x +
              ((bounds.x + bounds.width) * _region.width) / contentWidth,
            _region.y +
              ((bounds.y + bounds.height) * _region.height) / contentHeight
          );
          const bound0 = new Point(
            (bounds.x * currentImage.width) / contentWidth,
            (bounds.y * currentImage.height) / contentHeight
          );
          const bound1 = new Point(
            ((bounds.x + bounds.width) * currentImage.width) / contentWidth,
            ((bounds.y + bounds.height) * currentImage.height) / contentHeight
          );
          const image = currentImage.getSubRaster(
            new Rectangle(bound0, bound1)
          );
          image.fitBounds(
            new Rectangle({
              x: 0,
              y: 0,
              width: width,
              height: height
            })
          );

          currentGroup.matrix.reset();
          currentGroup.matrix.translate(image.bounds.x, image.bounds.y);
          currentGroup.matrix.scale(
            image.bounds.width / contentWidth,
            image.bounds.height / contentHeight
          );

          pushActionLogs(currentImage, "crop");

          setCurrentRegion({
            x: point0.x,
            y: point0.y,
            width: point1.x - point0.x,
            height: point1.y - point0.y
          });
          setCurrentImage(image);

          hideCurrentGuide();
        } else {
          const border = getGuideBorder(point);
          if (border) {
            setActionMode("edit");
            setActionType(border);

            setCurrentDelta(getGuideOffset(point0));
          }
        }
      } else {
        setActionMode("grab");
      }

      setCurrentOffset(point);
    },
    handleUp: e => {
      paper.activate();

      setActionMode(null);
    },
    handleMove: e => {
      paper.activate();

      const offset = currentOffset;
      const delta = currentDelta;
      const point = getCanvasPoint(e);
      const pointk = getSketchPoint(point);

      if (actionMode === "grab") {
        showCurrentGuide("crop", new Rectangle(offset, pointk));
      } else if (actionMode === "edit") {
        if (actionType === "center") {
          moveCurrentGuide(new Point(point.x + delta.x, point.y + delta.y));
        } else {
          showCurrentGuide("crop", getGuideBounds(), {
            type: actionType,
            point: pointk
          });
        }
      }
    }
  };

  const actions = {
    path: actionPath,
    line: actionLine,
    circle: actionCircle,
    box: actionBox,
    text: actionText,
    figure: actionFigure,
    ruler: actionRuler,
    delete: actionDelete,
    move: actionMove,
    crop: actionCrop
  };

  useEffect(() => {
    paper.activate();
    paper.setup(canvasRef.current);

    paper.settings.handleSize = pointSize;
    paper.settings.hitTolerance = hitTolerance;

    var background = new Layer();
    background.applyMatrix = false;
    var underlay = new Layer();
    underlay.applyMatrix = false;
    var sketch = new Layer();
    sketch.applyMatrix = false;
    var overlay = new Layer();
    overlay.applyMatrix = false;

    paper.project.addLayer(background);
    paper.project.addLayer(underlay);
    paper.project.addLayer(sketch);
    paper.project.addLayer(overlay);

    sketch.activate();

    setLayers({
      background: background,
      underlay: underlay,
      sketch: sketch,
      overlay: overlay
    });

    var group = new Group();
    group.applyMatrix = false;
    group.visible = false;

    setCurrentGroup(group);

    return () => {
      paper.remove();
    };
  }, []);

  useEffect(() => {
    if (layers) {
      paper.activate();

      if (currentBackgrounds) {
        currentBackgrounds.remove();
      }
      if (currentNotices) {
        currentNotices.remove();
      }
      if (currentControls) {
        currentControls.remove();
      }
      if (currentImage) {
        currentImage.remove();
      }
      if (currentGuide) {
        currentGuide.remove();

        setCurrentGuide(null);
      }
      if (currentGroup) {
        currentGroup.visible = false;
      }

      var backgrounds = new Group();
      layers.background.addChild(backgrounds);

      var background = new Path.Rectangle(
        new Point(0, 0),
        new Point(width, height)
      );
      background.fillColor = "black";
      backgrounds.addChild(background);

      setCurrentBackgrounds(backgrounds);

      var controls = new Group();
      controls.visible = false;
      layers.overlay.addChild(controls);

      menus.forEach((target, index) => {
        var size = Math.min(24, width);
        var item = new Group();
        item.importSVG(manifests[target]);
        item.fitBounds(
          new Rectangle(
            new Point(width - size * (index + 1), 0),
            new Point(width - size * (index + 0), size)
          )
        );
        item.fillColor = "rgba(255, 255, 255, 1.0)";
        item.data.tag = "layout";
        item.data.type = target;
        controls.addChild(item);
      });

      setCurrentControls(controls);

      if (source) {
        onPhotoLoading && onPhotoLoading();

        var image = new Raster({
          crossOrigin: true,
          source: source
        });
        image.visible = false;
        image.data.tag = "image";
        image.data.url = source;
        image.on("load", () => {
          const contentWidth = getContentWidth();
          const contentHeight = getContentHeight(image, region);

          if (region) {
            pushActionLogs(image, "crop");

            const bound0 = new Point(
              (region.x * image.width) / contentWidth,
              (region.y * image.height) / contentHeight
            );
            const bound1 = new Point(
              ((region.x + region.width) * image.width) / contentWidth,
              ((region.y + region.height) * image.height) / contentHeight
            );
            var image0 = image.getSubRaster(new Rectangle(bound0, bound1));
            image.remove();
            image = image0;

            setCurrentImage(image);
          }

          image.fitBounds(
            new Rectangle({
              x: 0,
              y: 0,
              width: width,
              height: height
            })
          );
          image.visible = true;

          currentGroup.matrix.reset();
          currentGroup.matrix.translate(image.bounds.x, image.bounds.y);
          currentGroup.matrix.scale(
            image.bounds.width / contentWidth,
            image.bounds.height / contentHeight
          );
          currentGroup.visible = true;

          onPhotoLoaded && onPhotoLoaded();
        });
        image.on("error", () => {});

        setCurrentImage(image);

        setCurrentNotices(null);
      } else {
        var notices = new Group();
        layers.underlay.addChild(notices);

        var number = new PointText(new Point(width / 2, height / 2));
        number.content = `${parseInt(layout) + 1}`;
        number.style = {
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fontSize: 24,
          fillColor: "#00897b",
          justification: "center"
        };
        number.fitBounds({
          x: width * 0.25,
          y: height * 0.25,
          width: width * 0.5,
          height: height * 0.5
        });
        notices.addChild(number);

        var message = new PointText(new Point(width / 2, height / 2));
        message.content = `*이미지를 선택합니다`;
        message.style = {
          fontFamily: "sans-serif",
          fontSize: 24,
          fillColor: "#00897b",
          justification: "center"
        };
        message.fitBounds({
          x: width * 0.35,
          y: height * 0.7,
          width: width * 0.3,
          height: height * 0.15
        });
        notices.addChild(message);

        setCurrentNotices(notices);

        setCurrentImage(null);
      }
    }
  }, [canvasRef, layers, width, height, layout, source]);

  useEffect(() => {
    if (currentGroup) {
      try {
        currentGroup.removeChildren();

        if (content) {
          const data = pako.inflate(content, { to: "string" });

          currentGroup.importJSON(JSON.parse(data));
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [layout, content, target, currentGroup]);

  useEffect(() => {
    if (currentGroup) {
      currentGroup.bringToFront();
    }
  }, [currentBackgrounds, currentImage]);

  useEffect(() => {
    if (dropInfo && onDrop) {
      onDrop(layout, dropInfo.source);
    }
  }, [dropInfo]);

  const showCurrentGuide = (type, bounds, options) => {
    const group = currentGroup;

    if (type === "pick") {
      const iconsize = Math.min(32, Math.min(bounds.width, bounds.height) / 3);

      if (currentGuide) {
        var guide = currentGuide;

        var path = new Path.Rectangle(bounds);
        path.strokeColor = "rgba(0, 255, 255, 0.75)";
        path.strokeWidth = 1;
        path.strokeCap = "round";
        path.strokeJoin = "round";
        path.fillColor = "rgba(0, 255, 255, 0.25)";
        path.data.tag = "guide";

        guide.data.path.remove();
        guide.data.path = path;
        guide.addChild(path);
        path.sendToBack();

        guide.data.rotate.fitBounds(
          new Rectangle({
            x: bounds.x,
            y: bounds.y,
            width: iconsize,
            height: iconsize
          })
        );
        guide.data.scale.fitBounds(
          new Rectangle({
            x: bounds.x + bounds.width - iconsize,
            y: bounds.y,
            width: iconsize,
            height: iconsize
          })
        );
        guide.data.scalev.fitBounds(
          new Rectangle({
            x: bounds.x + bounds.width / 2 - iconsize / 2,
            y: bounds.y,
            width: iconsize,
            height: iconsize
          })
        );
        guide.data.scaleh.fitBounds(
          new Rectangle({
            x: bounds.x + bounds.width - iconsize,
            y: bounds.y + bounds.height / 2 - iconsize / 2,
            width: iconsize,
            height: iconsize
          })
        );
        guide.data.remove.fitBounds(
          new Rectangle({
            x: bounds.x + bounds.width - iconsize,
            y: bounds.y + bounds.height - iconsize,
            width: iconsize,
            height: iconsize
          })
        );
      } else {
        var path = new Path.Rectangle(bounds);
        path.strokeColor = "rgba(0, 255, 255, 0.75)";
        path.strokeWidth = 1;
        path.strokeCap = "round";
        path.strokeJoin = "round";
        path.fillColor = "rgba(0, 255, 255, 0.25)";
        path.data.tag = "guide";

        var rotate = new Group();
        rotate.importSVG(manifests["rotate"]);
        rotate.fitBounds(
          new Rectangle(
            new Point(bounds.x, bounds.y),
            new Point(bounds.x + iconsize, bounds.y + iconsize)
          )
        );
        rotate.fillColor = "rgba(0, 255, 255, 0.85)";
        rotate.data.tag = "guide";
        rotate.data.type = "rotate";

        var scale = new Group();
        scale.importSVG(manifests["scale"]);
        scale.fitBounds(
          new Rectangle(
            new Point(bounds.x + bounds.width - iconsize, bounds.y),
            new Point(bounds.x + bounds.width, bounds.y + iconsize)
          )
        );
        scale.fillColor = "rgba(0, 255, 255, 0.85)";
        scale.data.tag = "guide";
        scale.data.type = "scale";

        var scaleh = new Group();
        scaleh.importSVG(manifests["scaleh"]);
        scaleh.fitBounds(
          new Rectangle(
            new Point(
              bounds.x + bounds.width - iconsize,
              bounds.y + bounds.height / 2 - iconsize / 2
            ),
            new Point(
              bounds.x + bounds.width,
              bounds.y + +bounds.height / 2 + iconsize / 2
            )
          )
        );
        scaleh.fillColor = "rgba(0, 255, 255, 0.85)";
        scaleh.data.tag = "guide";
        scaleh.data.type = "scaleh";

        var scalev = new Group();
        scalev.importSVG(manifests["scalev"]);
        scalev.fitBounds(
          new Rectangle(
            new Point(bounds.x + bounds.width / 2 - iconsize / 2, bounds.y),
            new Point(
              bounds.x + bounds.width / 2 + iconsize / 2,
              bounds.y + iconsize
            )
          )
        );
        scalev.fillColor = "rgba(0, 255, 255, 0.85)";
        scalev.data.tag = "guide";
        scalev.data.type = "scalev";

        var remove = new Group();
        remove.importSVG(manifests["delete"]);
        remove.fitBounds(
          new Rectangle(
            new Point(
              bounds.x + bounds.width - iconsize,
              bounds.y + bounds.height - iconsize
            ),
            new Point(bounds.x + bounds.width, bounds.y + bounds.height)
          )
        );
        remove.fillColor = "rgba(0, 255, 255, 0.85)";
        remove.data.tag = "guide";
        remove.data.type = "remove";

        var guide = new Group();
        guide.applyMatrix = false;
        guide.matrix = group.matrix;
        guide.data.tag = "guide";
        guide.data.type = type;
        guide.data.path = path;
        guide.data.rotate = rotate;
        guide.data.scale = scale;
        guide.data.scalev = scalev;
        guide.data.scaleh = scaleh;
        guide.data.remove = remove;
        guide.addChild(path);
        guide.addChild(rotate);
        guide.addChild(scale);
        guide.addChild(scalev);
        guide.addChild(scaleh);
        guide.addChild(remove);
        setCurrentGuide(guide);
      }
    } else if (type === "crop") {
      const iconsize = Math.min(32, Math.min(bounds.width, bounds.height) / 3);

      if (currentGuide) {
        currentGuide.remove();
      }

      if (options) {
        var bounds0 = bounds;

        if (options.type === "right") {
          bounds0 = new Rectangle(
            new Point(bounds.x, bounds.y),
            new Point(options.point.x, bounds.y + bounds.height)
          );
        } else if (options.type === "left") {
          bounds0 = new Rectangle(
            new Point(options.point.x, bounds.y),
            new Point(bounds.x + bounds.width, bounds.y + bounds.height)
          );
        } else if (options.type === "top") {
          bounds0 = new Rectangle(
            new Point(bounds.x, options.point.y),
            new Point(bounds.x + bounds.width, bounds.y + bounds.height)
          );
        } else if (options.type === "bottom") {
          bounds0 = new Rectangle(
            new Point(bounds.x, bounds.y),
            new Point(bounds.x + bounds.width, options.point.y)
          );
        }

        var guide = new Group();
        guide.applyMatrix = false;
        guide.matrix = group.matrix;

        var path = new Path.Rectangle(bounds0);
        path.strokeColor = "rgba(0, 255, 255, 0.75)";
        path.strokeWidth = 4;
        path.strokeCap = "round";
        path.strokeJoin = "round";
        path.dashArray = [8, 8];
        path.fillColor = "rgba(0, 255, 255, 0.25)";
        path.data.tag = "guide";
        path.data.type = type;

        var button = new Path.Circle(bounds0.center, iconsize);
        button.fillColor = "rgba(0, 255, 255, 0.75)";
        button.data.tag = "guide";
        button.data.type = "apply";

        var text = new PointText();
        text.content = "Click";
        text.style = {
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fontSize: 16,
          fillColor: "rgba(0, 255, 255, 1.0)",
          justification: "center"
        };
        text.data.tag = "guide";
        text.data.type = "apply";
        text.fitBounds(
          new Rectangle(
            new Point(
              bounds.center.x - iconsize * 0.75,
              bounds.center.y - iconsize * 0.75
            ),
            new Point(
              bounds.center.x + iconsize * 0.75,
              bounds.center.y + iconsize * 0.75
            )
          )
        );

        guide.addChild(path);
        guide.addChild(button);
        guide.addChild(text);
        setCurrentGuide(guide);
      } else {
        var guide = new Group();
        guide.applyMatrix = false;
        guide.matrix = group.matrix;

        var path = new Path.Rectangle(bounds);
        path.strokeColor = "rgba(0, 255, 255, 0.75)";
        path.strokeWidth = 4;
        path.strokeCap = "round";
        path.strokeJoin = "round";
        path.dashArray = [8, 8];
        path.fillColor = "rgba(0, 255, 255, 0.25)";
        path.data.tag = "guide";
        path.data.type = type;

        var button = new Path.Circle(bounds.center, iconsize);
        button.fillColor = "rgba(0, 255, 255, 0.75)";
        button.data.tag = "guide";
        button.data.type = "apply";

        var text = new PointText();
        text.content = "Click";
        text.style = {
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fontSize: 16,
          fillColor: "rgba(0, 255, 255, 1.0)",
          justification: "center"
        };
        text.data.tag = "guide";
        text.data.type = "apply";
        text.fitBounds(
          new Rectangle(
            new Point(
              bounds.center.x - iconsize * 0.75,
              bounds.center.y - iconsize * 0.75
            ),
            new Point(
              bounds.center.x + iconsize * 0.75,
              bounds.center.y + iconsize * 0.75
            )
          )
        );

        guide.addChild(path);
        guide.addChild(button);
        guide.addChild(text);
        setCurrentGuide(guide);
      }
    } else if (type === "box") {
      if (currentGuide) {
        currentGuide.remove();
      }

      var strokeColor = new Color(styles.color);
      strokeColor.alpha = 0.75;
      var fillColor = new Color(styles.color);
      fillColor.alpha = 0.25;

      var path = new Path.Rectangle(bounds);
      path.applyMatrix = false;
      path.matrix = group.matrix;
      path.strokeColor = strokeColor;
      path.strokeWidth = 1;
      path.strokeCap = "round";
      path.strokeJoin = "round";
      path.fillColor = fillColor;
      path.data.tag = "guide";
      path.data.type = type;
      setCurrentGuide(path);
    } else if (type === "circle") {
      if (currentGuide) {
        currentGuide.remove();
      }

      var strokeColor = new Color(styles.color);
      strokeColor.alpha = 0.75;
      var fillColor = new Color(styles.color);
      fillColor.alpha = 0.25;

      var path = new Path.Ellipse(bounds);
      path.applyMatrix = false;
      path.matrix = group.matrix;
      path.strokeColor = strokeColor;
      path.strokeWidth = 1;
      path.strokeCap = "round";
      path.strokeJoin = "round";
      path.fillColor = fillColor;
      path.data.tag = "guide";
      path.data.type = type;
      setCurrentGuide(path);
    } else if (type === "line") {
      if (currentGuide) {
        currentGuide.remove();
      }

      var strokeColor = new Color(styles.color);
      strokeColor.alpha = 0.75;
      var fillColor = new Color(styles.color);
      fillColor.alpha = 0.25;

      var path = new Path.Line(options.from, options.to);
      path.applyMatrix = false;
      path.matrix = group.matrix;
      path.strokeColor = strokeColor;
      path.strokeWidth = 1;
      path.strokeCap = "round";
      path.strokeJoin = "round";
      path.fillColor = fillColor;
      path.data.tag = "guide";
      path.data.type = type;
      setCurrentGuide(path);
    } else if (type === "ruler") {
      if (currentGuide) {
        currentGuide.remove();
      }

      var path = new Path.Line(options.from, options.to);
      path.applyMatrix = false;
      path.matrix = group.matrix;
      path.strokeColor = "green";
      path.strokeWidth = 4;
      path.strokeCap = "round";
      path.strokeJoin = "round";
      path.dashArray = [8, 8];
      path.fullySelected = true;
      setCurrentGuide(path);
    }
  };

  const moveCurrentGuide = point => {
    if (currentGuide) {
      currentGuide.position = point;
    }
  };

  const getGuideBounds = () => {
    if (currentGuide) {
      if (currentImage) {
        const contentWidth = getContentWidth();
        const contentHeight = getContentHeight(currentImage, currentRegion);
        const bound0 = currentImage.bounds;
        const bound1 = currentGuide.bounds;
        const point0 = new Point(bound1.x - bound0.x, bound1.y - bound0.y);
        const point1 = new Point(
          (point0.x * contentWidth) / bound0.width,
          (point0.y * contentHeight) / bound0.height
        );
        const point2 = new Point(
          bound1.x + bound1.width - bound0.x,
          bound1.y + bound1.height - bound0.y
        );
        const point3 = new Point(
          (point2.x * contentWidth) / bound0.width,
          (point2.y * contentHeight) / bound0.height
        );

        return new Rectangle(point1, point3);
      }

      return currentGuide.bounds;
    }

    return null;
  };

  const getGuideOffset = point => {
    if (currentGuide) {
      return new Point(
        currentGuide.position.x - point.x,
        currentGuide.position.y - point.y
      );
    }

    return null;
  };

  const getGuideBorder = point => {
    if (currentGuide) {
      const bounds = currentGuide.bounds;
      const point0 = currentGroup.matrix.transform(point);
      return getBorderOnBounds(bounds, point0, 8);
    }

    return null;
  };

  const hideCurrentGuide = () => {
    if (currentGuide) {
      currentGuide.remove();

      setCurrentGuide(null);
    }
  };

  const hasCurrentGuide = () => {
    return !!currentGuide;
  };

  const setLayerScale = (sx, sy) => {
    paper.activate();
    layers.sketch.matrix.scale(sx, sy, new Point(width / 2, height / 2));
  };

  const initLayerMatrix = () => {
    paper.activate();
    layers.sketch.matrix.reset();
  };

  const fitLayerInBounds = bounds => {
    layers.sketch.matrix.reset();
    layers.sketch.matrix.scale(
      width / bounds.width,
      height / bounds.height,
      bounds.center
    );
    layers.sketch.matrix.translate(
      -bounds.center.x + width / 2,
      -bounds.center.y + height / 2
    );
  };

  const getContentWidth = () => {
    return contentSize;
  };

  const getContentHeight = (image = null, region = null) => {
    if (region !== null) {
      return contentSize * (region.height / region.width);
    } else if (image !== null && image.width && image.height) {
      return contentSize * (image.height / image.width);
    }

    return contentSize;
  };

  const getSketchPoint = point => {
    if (currentImage) {
      const contentWidth = getContentWidth();
      const contentHeight = getContentHeight(currentImage, currentRegion);
      const bounds = currentImage.bounds;
      const bound0 = layers.sketch.matrix.transform(
        new Point(bounds.x, bounds.y)
      );
      const bound1 = layers.sketch.matrix.transform(
        new Point(bounds.x + bounds.width, bounds.y + bounds.height)
      );
      const point0 = new Point(point.x - bound0.x, point.y - bound0.y);
      const point1 = new Point(
        (point0.x * contentWidth) / (bound1.x - bound0.x),
        (point0.y * contentHeight) / (bound1.y - bound0.y)
      );

      return point1;
    }

    return point;
  };

  const getLayerPoint = point => {
    const matrix = layers.sketch.matrix.clone();
    matrix.tx = 0;
    matrix.ty = 0;
    return matrix.inverseTransform(point);
  };

  const getCanvasPoint = useCallback(e => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    var clientX = e.clientX;
    var clientY = e.clientY;

    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX;
      clientY = e.chagnedTouches[0].clientY;
    }

    clientX -= rect.left;
    clientY -= rect.top;

    return new Point(clientX, clientY);
  }, []);

  const getClientPoint = useCallback(e => {
    var clientX = e.clientX;
    var clientY = e.clientY;

    return new Point(clientX, clientY);
  }, []);

  const getBorderOnBounds = useCallback((bounds, point, epsilon) => {
    if (Math.abs(bounds.top - point.y) < epsilon) {
      return "top";
    } else if (Math.abs(bounds.bottom - point.y) < epsilon) {
      return "bottom";
    } else if (Math.abs(bounds.left - point.x) < epsilon) {
      return "left";
    } else if (Math.abs(bounds.right - point.x) < epsilon) {
      return "right";
    } else if (bounds.contains(point)) {
      return "center";
    }

    return null;
  }, []);

  const fitItemInBounds = useCallback((item, bounds) => {
    const bounds0 = item.bounds;

    item.scale(bounds.width / bounds0.width, bounds.height / bounds0.height);
    item.translate(
      bounds.center.x - bounds0.center.x,
      bounds.center.y - bounds0.center.y
    );
  }, []);

  const getItemByID = useCallback(id => {
    for (var index in currentGroup.children) {
      if (currentGroup.children[index].data.id === id) {
        return currentGroup.children[index];
      }
    }

    return null;
  });

  const getItemByTags = useCallback((item, tags) => {
    for (var tt = item; tt; tt = tt.parent) {
      if (tags.includes(tt.data.tag)) {
        return tt;
      }
    }

    return null;
  });

  const getItemAngle = (item, point) => {
    return (
      (Math.atan2(point.y - item.position.y, point.x - item.position.x) * 180) /
      Math.PI
    );
  };

  const getItemDistance = (item, point) => {
    const dx = point.x - item.position.x;
    const dy = point.y - item.position.y;

    return Math.sqrt(dx * dx + dy * dy);
  };

  const hasPointOnImage = (item, point, ratio) => {
    const delta = item.position.getDistance(point) / item.bounds.width;

    return delta < ratio;
  };

  const getScreenLength = (point0, point1) => {
    const dx = point0.x - point1.x;
    const dy = point0.y - point1.y;

    return Math.sqrt(dx * dx + dy * dy);
  };

  const getRealLength = (point0, point1) => {
    if (
      detail.width &&
      detail.height &&
      detail.pixelspacing &&
      detail.pixelspacing.x &&
      detail.pixelspacing.y
    ) {
      const width0 = detail.width;
      const height0 = detail.height;
      const width1 = getContentWidth();
      const height1 = getContentHeight(currentImage, currentRegion);
      const x0 = (point0.x * width0) / width1;
      const y0 = (point0.y * height0) / height1;
      const x1 = (point1.x * width0) / width1;
      const y1 = (point1.y * height0) / height1;
      const dx = (x1 - x0) * detail.pixelspacing.x;
      const dy = (y1 - y0) * detail.pixelspacing.y;

      return Math.sqrt(dx * dx + dy * dy);
    }

    const dx = point0.x - point1.x;
    const dy = point0.y - point1.y;

    return Math.sqrt(dx * dx + dy * dy);
  };

  const showControls = () => {
    if (currentControls) {
      currentControls.bringToFront();
      currentControls.visible = true;
    }
  };

  const hideControls = () => {
    if (currentControls) {
      currentControls.visible = false;
    }
  };

  const pushContents = item => {
    currentGroup.addChild(item);
  };

  const showContentsAll = () => {
    setCurrentVisible(true);

    currentGroup.visible = true;
  };

  const hideContentsAll = () => {
    setCurrentVisible(false);

    currentGroup.visible = false;
  };

  const popContents = item => {
    if (item.data.tag === "ruler" || item.data.tag === "implant") {
      for (var index in currentGroup.children) {
        if (
          currentGroup.children[index].data.id === item.data.id &&
          currentGroup.children[index].data.type === "tooltip"
        ) {
          currentGroup.children[index].remove();
        }
      }
    }

    item.remove();
  };

  const updateContents = item => {
    if (item.data.tag === "ruler") {
      for (var index in currentGroup.children) {
        if (
          currentGroup.children[index].data.id === item.data.id &&
          currentGroup.children[index].data.type === "tooltip"
        ) {
          currentGroup.children[index].remove();
        }
      }

      var length = getRealLength(
        item.firstSegment.point,
        item.lastSegment.point
      );

      var tooltip = new PointText(item.position);
      tooltip.content = `${length.toFixed(2)}`;
      tooltip.style = {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        fontSize: 16,
        fillColor: "yellow",
        justification: "center"
      };
      tooltip.data.id = item.data.id;
      tooltip.data.tag = "ruler";
      tooltip.data.type = "tooltip";

      currentGroup.addChild(tooltip);
    } else if (item.data.tag === "implant") {
      if (item.data.tooltip) {
        var fontsize = 16;
        var dx = 0;
        var dy = 0;

        for (var index in currentGroup.children) {
          if (
            currentGroup.children[index].data.id === item.data.id &&
            currentGroup.children[index].data.type === "tooltip"
          ) {
            currentGroup.children[index].remove();
          }
        }

        if (item.rotation >= -90 && item.rotation <= 90) {
          dx = item.bounds.bottomCenter.x;
          dy = item.bounds.bottomCenter.y + fontsize;
        } else {
          dx = item.bounds.topCenter.x;
          dy = item.bounds.topCenter.y;
        }

        var tooltip = new PointText(new Point(dx, dy));
        tooltip.content = item.data.tooltip;
        tooltip.style = {
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fontSize: fontsize,
          fillColor: "yellow",
          justification: "center"
        };
        tooltip.data.id = item.data.id;
        tooltip.data.tooth = item.data.tooth;
        tooltip.data.implant = item.data.implant;
        tooltip.data.tag = "implant";
        tooltip.data.type = "tooltip";

        currentGroup.addChild(tooltip);
      }
    }
  };

  const pushActionLogs = (item, event) => {
    if (event === "crop") {
      item.visible = false;

      currentLogs.images.push(item);
      currentLogs.undo.push("crop");
    } else {
      if (event) {
        item.data.event = event;
      }

      setTimeout(() => {
        const logs = item.exportJSON();

        currentLogs.undo.push(logs);
      }, 300);
    }
  };

  const getActionObject = logs => {
    const jobj = JSON.parse(logs);

    if (Array.isArray(jobj[0]) && Array.isArray(jobj[1])) {
      if (jobj[0][0] !== "dictionary") {
        return jobj[0][1];
      }
      if (jobj[1][0] !== "dictionary") {
        return jobj[1][1];
      }
    }

    return jobj[1];
  };

  useImperativeHandle(ref, () => ({
    activateAction(name) {},
    deactivateAction(name) {},
    dispatchImplant(target, tooth, implant, crown, tooltip, flip) {
      var matrix = null;

      if (target) {
        for (var index in currentGroup.children) {
          const item = currentGroup.children[index];

          if (item.data.id === target && item.data.type === "figure") {
            matrix = item.matrix;
          }

          if (item.data.id === target) {
            item.remove();
          }
        }
      }

      const contentWidth = getContentWidth();
      const contentHeight = getContentHeight(currentImage, currentRegion);
      const imageWidth = detail ? detail.width : contentWidth;
      const imageHeight = detail ? detail.height : contentHeight;
      const implantSize =
        detail && detail.pixelspacing
          ? implantLengthMax / detail.pixelspacing.y
          : implantImageHeight;
      const implantHeight = (implantSize * contentHeight) / imageHeight;
      const implantWidth =
        (implantSize *
          (implantImageWidth / implantImageHeight) *
          contentWidth) /
        imageWidth;
      const toothHeight =
        (implantSize *
          (toothImageHeight / implantImageHeight) *
          contentHeight) /
        imageHeight;
      const cx = contentWidth / 2;
      const cy = contentHeight / 2;
      const dx = contentWidth / 2 - implantWidth / 2;
      const dy = contentHeight / 2 - (toothHeight + implantHeight) / 2;

      var figure = new Group();
      figure.applyMatrix = false;
      figure.matrix = matrix ? matrix : new Matrix();
      if (flip) figure.matrix.rotate(180, new Point(cx, cy));
      figure.data.id = target ? target : uuid();
      figure.data.tag = "implant";
      figure.data.type = "figure";
      figure.data.tooth = tooth;
      figure.data.implant = implant;
      figure.data.tooltip = tooltip ? implantList[implant].tooltip : null;
      if (crown) {
        figure.importSVG(toothList[tooth], item => {
          fitItemInBounds(
            item,
            new Rectangle(
              new Point(dx, dy),
              new Size(implantWidth, toothHeight)
            )
          );
        });
      }
      figure.importSVG(implantList[implant].source, item => {
        fitItemInBounds(
          item,
          new Rectangle(
            new Point(dx, dy + toothHeight),
            new Size(implantWidth, implantHeight)
          )
        );
      });

      pushActionLogs(figure, "create");
      pushContents(figure);
      updateContents(figure);
      setTimeout(() => {
        updateContents(figure);
      }, 300);
    },
    dispatchScale(sx, sy) {
      setLayerScale(sx, sy);
    },
    dispatchReset() {
      initLayerMatrix();
    },
    dispatchUndo() {
      try {
        const logs = currentLogs.undo.pop();

        if (logs === "crop") {
          const image = currentLogs.images.pop();
          image.fitBounds(
            new Rectangle({
              x: 0,
              y: 0,
              width: width,
              height: height
            })
          );
          image.visible = true;

          currentGroup.matrix.reset();
          currentGroup.matrix.translate(image.bounds.x, image.bounds.y);
          currentGroup.matrix.scale(
            image.bounds.width / getContentWidth(),
            image.bounds.height / getContentHeight(currentImage, currentRegion)
          );
          currentGroup.visible = true;

          currentImage.remove();

          setCurrentImage(image);
        } else if (logs) {
          const jobj = getActionObject(logs);

          var item0 = getItemByID(jobj.data.id);
          if (item0) {
            popContents(item0);
          }

          if (jobj.data.event !== "create") {
            for (var index = currentLogs.undo.length - 1; index >= 0; index--) {
              const jobj0 = getActionObject(currentLogs.undo[index]);

              if (jobj0.data.id === jobj.data.id) {
                var item1 = paper.project.importJSON(currentLogs.undo[index]);

                pushContents(item1);
                updateContents(item1);

                break;
              }
            }
          }

          currentLogs.redo.push(logs);
        }
      } catch (e) {
        console.error(e);
      }
    },
    dispatchRedo() {
      try {
        const logs = currentLogs.redo.pop();

        if (logs) {
          const jobj = getActionObject(logs);

          var item0 = getItemByID(jobj.data.id);
          if (item0) {
            popContents(item0);
          }

          if (jobj.data.event !== "delete") {
            var item1 = paper.project.importJSON(logs);

            pushContents(item1);
            updateContents(item1);
          }

          currentLogs.undo.push(logs);
        }
      } catch (e) {
        console.error(e);
      }
    },
    dispatchClear() {
      currentGroup.removeChildren();
    },
    dispatchFilter(filters) {
      const ctx = canvasRef.current.getContext("2d");
      var cmd = "";

      if (filters.brightness !== 0) {
        cmd = cmd.concat(`brightness(${filters.brightness + 100}%)`);
      }
      if (filters.saturation !== 0) {
        cmd = cmd.concat(`saturate(${filters.saturation + 100}%)`);
      }
      if (filters.contrast !== 0) {
        cmd = cmd.concat(`contrast(${filters.contrast + 100}%)`);
      }
      if (filters.huerotate !== 0) {
        cmd = cmd.concat(`hue-rotate(${filters.huerotate}deg)`);
      }
      if (filters.invert > 0) {
        cmd = cmd.concat(`invert(${filters.invert}%)`);
      }

      if (cmd.length > 0) {
        ctx.filter = cmd;
      } else {
        ctx.filter = "none";
      }

      if (currentBackgrounds) {
        currentBackgrounds.visible = false;
        currentBackgrounds.visible = true;
      }
    },
    dispatchVisible(visible) {
      if (visible === null) {
        if (currentVisible) {
          hideContentsAll();
        } else {
          showContentsAll();
        }
      } else if (visible) {
        showContentsAll();
      } else {
        hideContentsAll();
      }
    },
    dispatchExport() {
      try {
        const data = JSON.stringify(currentGroup.exportJSON());

        return pako.deflate(data, { to: "string" });
      } catch (e) {
        console.error(e);
      }
    },
    dispatchRegion() {
      try {
        if (currentRegion) {
          const data = JSON.stringify(currentRegion);

          return data;
        } else {
          return null;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }));

  const handleEnter = e => {
    if (!modes.includes("dialog")) {
      showControls();
    }

    try {
      if (actions.hasOwnProperty(action)) {
        return actions[action].handleEnter(e);
      }

      return actions["path"].handleEnter(e);
    } catch (e) {
      console.error(e);
    }
  };

  const handleLeave = e => {
    if (!modes.includes("dialog")) {
      hideControls();
    }

    try {
      if (actions.hasOwnProperty(action)) {
        return actions[action].handleLeave(e);
      }

      return actions["path"].handleLeave(e);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOver = e => {
    if (!modes.includes("dialog")) {
      showControls();
    }
  };

  const handleDown = e => {
    try {
      const point = getCanvasPoint(e);

      var hitResult = paper.project.hitTest(point, {
        segments: true,
        stroke: true,
        fill: true
      });
      const item = getItemByTags(hitResult ? hitResult.item : null, [
        "layout",
        "item",
        "ruler",
        "implant"
      ]);
      if (item) {
        if (item.data.tag === "layout") {
          onPhotoAction &&
            onPhotoAction(layout, item.data.type, getClientPoint(e));
        } else if (item.data.tag === "item") {
          const pointk = getSketchPoint(point);

          if (
            item.data.type === "path" ||
            item.data.type === "line" ||
            item.data.type === "box" ||
            item.data.type === "circle"
          ) {
            if (hitResult.segment) {
              setCurrentDelta(
                new Point(
                  hitResult.segment.point.x - pointk.x,
                  hitResult.segment.point.y - pointk.y
                )
              );

              setCurrentGrab(item);
              setCurrentSegment(hitResult.segment);

              setActionMode("pick");
              setActionType("segment");
              setCurrentCursor("default");
            } else {
              setCurrentDelta(
                new Point(
                  item.position.x - pointk.x,
                  item.position.y - pointk.y
                )
              );

              setCurrentGrab(item);

              setActionMode("pick");
              setActionType("translate");
              setCurrentCursor("move");
            }
          } else if (item.data.type === "figure") {
            setCurrentGrab(item);
            setActionMode("pick");

            if (hasPointOnImage(item, pointk, 0.2)) {
              setCurrentDelta(
                new Point(
                  item.position.x - pointk.x,
                  item.position.y - pointk.y
                )
              );

              setActionType("translate");
              setCurrentCursor("move");
            } else if (hasPointOnImage(item, pointk, 0.35)) {
              setActionType("rotate");
              setCurrentCursor("alias");
            } else {
              setActionType("scale");
              setCurrentCursor("nwse-resize");
            }
          } else if (item.data.type === "text") {
            setCurrentGrab(item);
            setActionMode("pick");

            if (hasPointOnImage(item, pointk, 0.1)) {
              showCurrentGuide("box", item.bounds);

              setCurrentText(item.content);
              setCurrentPath(item);
              setTextFieldPosition(getClientPoint(e));
              setOpenTextField(true);

              setActionType(null);
              setActionMode(null);
            } else if (hasPointOnImage(item, pointk, 0.2)) {
              setCurrentDelta(
                new Point(
                  item.position.x - pointk.x,
                  item.position.y - pointk.y
                )
              );

              setActionType("translate");
              setCurrentCursor("move");
            } else if (hasPointOnImage(item, pointk, 0.35)) {
              setActionType("rotate");
              setCurrentCursor("alias");
            } else {
              setActionType("scale");
              setCurrentCursor("nwse-resize");
            }
          } else {
            setCurrentDelta(
              new Point(item.position.x - pointk.x, item.position.y - pointk.y)
            );

            setCurrentGrab(item);

            setActionMode("pick");
            setActionType("translate");
            setCurrentCursor("move");
          }
        } else if (item.data.tag === "ruler") {
          const pointk = getSketchPoint(point);

          if (hitResult.segment) {
            setCurrentDelta(
              new Point(
                hitResult.segment.point.x - pointk.x,
                hitResult.segment.point.y - pointk.y
              )
            );

            setCurrentGrab(item);
            setCurrentSegment(hitResult.segment);

            setActionMode("pick");
            setActionType("segment");
            setCurrentCursor("default");
          } else {
            setCurrentDelta(
              new Point(item.position.x - pointk.x, item.position.y - pointk.y)
            );

            setCurrentGrab(item);

            setActionMode("pick");
            setActionType("translate");
            setCurrentCursor("move");
          }
        } else if (item.data.tag === "implant") {
          if (item.data.type === "figure") {
            const pointk = getSketchPoint(point);

            setCurrentGrab(item);
            setActionMode("pick");

            if (hasPointOnImage(item, pointk, 0.3)) {
              setCurrentDelta(
                new Point(
                  item.position.x - pointk.x,
                  item.position.y - pointk.y
                )
              );

              setActionType("translate");
              setCurrentCursor("move");
            } else {
              setActionType("rotate");
              setCurrentCursor("alias");
            }
          } else if (item.data.type === "tooltip") {
            // onImplantEdit &&
            //   onImplantEdit(item.data.id, item.data.tooth, item.data.implant);
          }
        }

        setCurrentPoint(point);
      } else {
        if (actions.hasOwnProperty(action)) {
          return actions[action].handleDown(e);
        }

        return actions["path"].handleDown(e);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleMove = e => {
    try {
      const point = getCanvasPoint(e);
      const pointk = getSketchPoint(point);

      if (actionMode === "pick") {
        const point0 = getSketchPoint(currentPoint);
        const item = currentGrab;

        if (actionType === "rotate") {
          const d0 = getItemAngle(item, point0);
          const d1 = getItemAngle(item, pointk);

          item.rotate(d1 - d0);
        } else if (actionType === "scale") {
          const d0 = getItemDistance(item, point0);
          const d1 = getItemDistance(item, pointk);

          item.scale(d1 / d0);
        } else if (actionType === "scaleh") {
          const d0 = getItemDistance(item, point0);
          const d1 = getItemDistance(item, pointk);

          item.scale(d1 / d0, 1.0);
        } else if (actionType === "scalev") {
          const d0 = getItemDistance(item, point0);
          const d1 = getItemDistance(item, pointk);

          item.scale(1.0, d1 / d0);
        } else if (actionType === "segment") {
          if (item.data.type === "box") {
            const neighbors = [
              { x: 1, y: 3 },
              { x: 0, y: 2 },
              { x: 3, y: 1 },
              { x: 2, y: 0 }
            ];
            const segment = currentSegment;
            const delta = currentDelta;
            const index = currentSegment.index;

            item.segments[index].point.x = pointk.x + delta.x;
            item.segments[index].point.y = pointk.y + delta.y;

            item.segments[neighbors[index].x].point.x = pointk.x + delta.x;
            item.segments[neighbors[index].y].point.y = pointk.y + delta.y;
          } else {
            const segment = currentSegment;
            const delta = currentDelta;

            segment.point.x = pointk.x + delta.x;
            segment.point.y = pointk.y + delta.y;
          }
        } else {
          const delta = currentDelta;

          item.position.x = pointk.x + delta.x;
          item.position.y = pointk.y + delta.y;
        }

        updateContents(item);

        setCurrentPoint(point);
      } else if (actionMode !== null) {
        if (actions.hasOwnProperty(action)) {
          setCurrentCursor("default");

          return actions[action].handleMove(e);
        }

        return actions["path"].handleMove(e);
      } else {
        var hitResult = paper.project.hitTest(point);
        if (hitResult && hitResult.item) {
          const item = getItemByTags(hitResult.item, [
            "layout",
            "item",
            "ruler",
            "implant"
          ]);

          if (item !== currentOver && currentOver) {
            if (
              currentOver.data.tag === "item" ||
              currentOver.data.tag === "ruler" ||
              currentOver.data.tag === "implant"
            ) {
              currentOver.selected = false;
            }

            setCurrentOver(null);
            setCurrentCursor("default");
          }

          if (item) {
            if (item.data.tag === "layout") {
              setCurrentOver(item);
              setCurrentCursor("default");
            } else if (item.data.tag === "item") {
              if (
                item.data.type === "path" ||
                item.data.type === "line" ||
                item.data.type === "box" ||
                item.data.type === "circle"
              ) {
                if (hitResult.segment) {
                  setCurrentCursor("default");
                } else {
                  setCurrentCursor("move");
                }

                item.selected = true;
              } else if (item.data.type === "figure") {
                if (hasPointOnImage(item, pointk, 0.2)) {
                  setCurrentCursor("move");
                } else if (hasPointOnImage(item, pointk, 0.35)) {
                  setCurrentCursor("alias");
                } else {
                  setCurrentCursor("nwse-resize");
                }
              } else if (item.data.type === "text") {
                if (hasPointOnImage(item, pointk, 0.1)) {
                  setCurrentCursor("text");
                } else if (hasPointOnImage(item, pointk, 0.2)) {
                  setCurrentCursor("move");
                } else if (hasPointOnImage(item, pointk, 0.35)) {
                  setCurrentCursor("alias");
                } else {
                  setCurrentCursor("nwse-resize");
                }
              }

              setCurrentOver(item);
            } else if (item.data.tag === "ruler") {
              item.selected = true;

              setCurrentOver(item);
              setCurrentCursor("move");
            } else if (item.data.tag === "implant") {
              if (hasPointOnImage(item, pointk, 0.3)) {
                setCurrentCursor("move");
              } else {
                setCurrentCursor("alias");
              }
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUp = e => {
    try {
      if (actionMode === "pick") {
        const item = currentGrab;

        pushActionLogs(item, "edit");

        setActionMode(null);
      } else {
        if (actions.hasOwnProperty(action)) {
          return actions[action].handleUp(e);
        }

        return actions["path"].handleUp(e);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isPaperRunning = () => {
    if (paper.project) {
      return true;
    }

    return false;
  };

  return (
    <Box
      ref={drop}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      style={{ width: "100%", height: "100%" }}
    >
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          cursor:
            currentOver && currentOver.data.tag === "layout"
              ? "grab"
              : currentCursor
        }}
        onMouseEnter={e => isPaperRunning() && handleEnter(e)}
        onMouseLeave={e => isPaperRunning() && handleLeave(e)}
        onMouseOver={e => isPaperRunning() && handleOver(e)}
        onMouseDown={e => isPaperRunning() && handleDown(e)}
        onMouseUp={e => isPaperRunning() && handleUp(e)}
        onMouseMove={e => isPaperRunning() && handleMove(e)}
        onMouseOut={e => isPaperRunning() && handleUp(e)}
        onTouchStart={e => isPaperRunning() && handleDown(e)}
        onTouchEnd={e => isPaperRunning() && handleUp(e)}
        onTouchMove={e => isPaperRunning() && handleMove(e)}
        onTouchCancel={e => isPaperRunning() && handleUp(e)}
      ></canvas>
      <Popover
        open={openTextField}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        anchorPosition={{
          top: textFieldPosition.y || 0,
          left: textFieldPosition.x || 0
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onEntered={() => {
          inputRef.current.focus();
        }}
        onClose={() => {
          hideCurrentGuide();

          if (currentPath) {
            if (actionType === "create") {
              if (currentText.length > 0) {
                pushActionLogs(currentPath, "create");
              } else {
                popContents(currentPath);
              }
            } else {
              if (currentText.length > 0) {
                pushActionLogs(currentPath, "edit");
              } else {
                pushActionLogs(currentPath, "delete");
                popContents(currentPath);
              }
            }

            setCurrentPath(null);
          }

          setOpenTextField(false);
        }}
      >
        <TextField
          id="outlined-basic"
          variant="outlined"
          margin="dense"
          style={{ width: 160 }}
          inputRef={inputRef}
          value={currentText}
          onKeyPress={e => {
            if (e.key === "Enter") {
              hideCurrentGuide();

              if (currentPath) {
                if (actionType === "create") {
                  if (currentText.length > 0) {
                    pushActionLogs(currentPath, "create");
                  } else {
                    popContents(currentPath);
                  }
                } else {
                  if (currentText.length > 0) {
                    pushActionLogs(currentPath, "edit");
                  } else {
                    pushActionLogs(currentPath, "delete");
                    popContents(currentPath);
                  }
                }

                setCurrentPath(null);
              }

              setOpenTextField(false);
            }
          }}
          onChange={e => {
            if (currentPath) {
              currentPath.content =
                e.target.value.length > 0 ? e.target.value : " ";
              fitItemInBounds(currentPath, getGuideBounds());

              setCurrentText(e.target.value);

              if (actionType === null) {
                setActionType("edit");
              }
            }
          }}
        />
      </Popover>
    </Box>
  );
});

export default PhotoCanvas;
