import React from "react";

const CalendarselIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path d="M0 0h24v24H0z" />
      <path d="M0 0h24v24H0z" />
      <g fill="currentColor">
        <path d="M8 2v2h8V2h2v2h1c1.1 0 2 .9 2 2v4h-2V9H5v11h5v2H5a2 2 0 0 1-1.995-1.851L3 20l.01-14c0-1.1.88-2 1.99-2h1V2h2zm1 9v2H7v-2h2zm4 0v2h-2v-2h2z" />
        <path
          d="M17 17a2.5 2.5 0 1 0 .001-4.999A2.5 2.5 0 0 0 17 17zM17 18.25c-1.669 0-5 .837-5 2.5V22h10v-1.25c0-1.663-3.331-2.5-5-2.5z"
          fillOpacity=".98"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);
export default CalendarselIcon;
