import { Box, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { RouteContext } from 'context';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { CCTypography } from 'styles/components';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    // overflowY: "scroll",
    overflowX: 'hidden',
  },
  box__gradient: {
    position: 'absolute',
    width: '100%',
    height: 200,
    bottom: 0,
    // backgroundImage: `linear-gradient(to bottom, #00897b, #00bfa5)`
  },
  tab__selected: {
    backgroundColor: 'white',
    color: `${theme.palette.primary.main} !important`,
  },
  tab__selected__patient: {
    backgroundColor: '#00bfa5',
  },
  typography: {
    color: 'inherit',
    marginTop: 8,
  },
  tab: {
    minWidth: 80,
    height: 80,
    color: 'white',
    opacity: 1,
  },
}));

const useTabStyles = makeStyles((theme) => ({
  scrollButtons: {
    height: 20,
  },
  indicator: {
    backgroundColor: 'none',
    opacity: 0
  },
}));

const SidebarNav = (props) => {
  const { pages, location } = props;
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const { dispatchRoute } = useContext(RouteContext);
  const intl = useIntl();
  return (
    <Box className={classes.root}>
      <Box className={classes.box__gradient} />

      <Tabs
        classes={tabClasses}
        aria-label='simple tabs example'
        orientation='vertical'
        variant='scrollable'
        scrollButtons='auto'
        style={{ height: '100%' }}
        value={0}
      >
        {pages
          .filter((page) => {
            const mode = sessionStorage.getItem('mode') || 'normal';

            if (page.mode !== mode) return false;
            return page.display;
          })
          .map((page, index) => {
            return (
              <Tab
                key={index}
                // value={index}
                label={
                  <>
                    {page.icon}
                    <CCTypography
                      variant='body2'
                      className={classes.typography}
                    >
                      {intl.formatMessage({ id: page.title })}
                    </CCTypography>
                  </>
                }
                className={clsx({
                  [classes.tab]: true,

                  [classes.tab__selected]: location.pathname === page.path,
                })}
                onClick={() => {
                  dispatchRoute({ path: page.path });
                }}
              />
            );
          })}
      </Tabs>
    </Box>
  );
};

export default SidebarNav;
