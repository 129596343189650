import React from "react";

const PhotoIconBigBackIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="98"
    viewBox="0 0 120 98"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H120V98H0z" />
      <path
        fill="currentColor"
        fillOpacity=".36"
        fillRule="nonzero"
        d="M50.2 2l9.8 9.75h39.2c5.302 0 9.655 4.245 9.796 9.487l.004.263v48.75c0 5.275-4.267 9.606-9.536 9.746L99.2 80H20.8c-5.302 0-9.655-4.245-9.796-9.487L11 70.25l.049-58.5c0-5.275 4.22-9.606 9.487-9.746L20.8 2h29.4zm-.38.929H20.8c-4.787 0-8.69 3.827-8.823 8.571l-.003.25-.05 58.5c0 4.764 3.858 8.684 8.624 8.818l.252.003h78.4c4.797 0 8.737-3.837 8.872-8.571l.003-.25V21.5c0-4.764-3.857-8.684-8.623-8.818l-.252-.003H59.62l-9.8-9.75zM65.575 30L69 33.525 57.874 45 69 56.475 65.575 60 51 45l14.575-15z"
      />
    </g>
  </svg>
);
export default PhotoIconBigBackIcon;
