import React from "react";

const StatisticsnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="vyvm4op3wa" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-866.000000, -369.000000) translate(866.000000, 369.000000)"
          />
          <path
            fill="url(#vyvm4op3wa)"
            d="M27 1c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4H5c-2.21 0-4-1.79-4-4V5c0-2.21 1.79-4 4-4h22zm-.137 19.333h-4.999v8h5v-8zm-15.73-5h-5v13h5v-13zm7.865-6h-5v19h5v-19z"
            transform="translate(-866.000000, -369.000000) translate(866.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M26.863 20.333v8h-4.999v-8h5zm-15.73-5v13h-5v-13h5z"
            transform="translate(-866.000000, -369.000000) translate(866.000000, 369.000000)"
          />
          <path
            fill="#00BFA5"
            d="M13.999 9.333L18.998 9.333 18.998 28.333 13.999 28.333z"
            transform="translate(-866.000000, -369.000000) translate(866.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default StatisticsnIcon;
