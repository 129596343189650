import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple } from "styles/components";
import ImageViewer from "../../../../../../../components/Explorer/Component/FilePhoto/component/FileViewer/Component/ImageViewer/ImageViewer";
import { translate } from "components";
const useStyles = makeStyles(theme => ({
  boldtitle: {
    fontWeight: "bold",
    padding: "16px 16px 0px 16px"
  },
  container: {
    width: "100%",
    height: "515px"
  }
}));

const DialogAvatarView = props => {
  const { open, avatar, onClose } = props;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <CCDialogSimple
      maxWidth="sm"
      fullWidth
      // classes={tableClasses}
      // className={classes.root}
      open={open}
      contentsPadding={false}
      title={translate( intl,"settings.lab.preview" )}
      onCloseButton={() => {
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
      contents={
        <Grid className={classes.container} container>
          <ImageViewer {...avatar} widthImage={600} />
        </Grid>
      }
      // endActions={
      //   <>
      //     <CCButton
      //       color='sub'
      //       onClick={() => {
      //         onClose();
      //       }}
      //     >
      //       취소
      //     </CCButton>
      //     <CCButton
      //       variant='contained'
      //       onClick={() => {
      //         onChange(surgeryValues);
      //       }}
      //     >
      //       저장/입력
      //     </CCButton>
      //   </>
      // }
    />
  );
};

export default DialogAvatarView;
