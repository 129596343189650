export { default as BillPaper } from "./BillPaper";
export { default as DatePicker } from "./DatePicker";
export { default as EditNoteDialog } from "./EditNoteDialog";
export { default as MonthPicker } from "./MonthPicker";
export { default as PaymentDetailDialog } from "./PaymentDetailDialog";
export { default as PaymentDialog } from "./PaymentDialog";
export { default as RefundDialog } from "./RefundDialog";

export const Commas = x =>
  (x || "0").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
