import { Grid } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { CCTypography } from "styles/components";
import { generatePatientBarGraph, generatePatientPieGraph } from "types/graph";
import { formatDateTitle } from "utils/graph";
import { formatCommas } from "utils/number";
import { translate } from "../components";
import { insertComma } from "../utils/etc";

export const parseSalesEstimates = (data, searchDate) => {
  if (data) {
    const value = data.find(
      item => (moment(searchDate).locale('en').format("YYYY-MM-DD") === item.time || moment(searchDate).locale('en').format("YYYY-MM") === item.time || moment(searchDate).locale('en').format("YYYY") === item.time)
    );
    const arrivedValue = value && value.arrived ? value.arrived : 0;
    const estimatesValue = value && value.estimates ? value.estimates : 0;
    const total = arrivedValue + estimatesValue;
    const ordered = value && value.ordered ? value.ordered : 0;
    return { total: total, arrived: arrivedValue, estimates: estimatesValue, ordered: ordered };
  }
  return { total: 0, arrived: 0, estimates: 0, ordered: 0 };
};

export const parseSalesEstimatesData = (data, labels, doctor = "", intl) => {
  const totalData = [];
  const arrivedData = [];
  const estimatesData = [];
  const orderedData = [];
  labels.forEach(label => {
    const { total, arrived, estimates, ordered } = parseSalesEstimates(data, label);
    totalData.push(total);
    arrivedData.push(arrived);
    estimatesData.push(estimates);
    orderedData.push(ordered);
  });
  return [
    {
      name: translate(intl, "menuStatistics.estimates"),
      type: "column",
      data: estimatesData
    },
    {
      name: translate(intl, "labOrder.ordered"),
      type: "line",
      data: orderedData
    }
  ];
};

export const generatePatientFields = (dateLabel, currentDate, dateOption) => {
  return [
    {
      title: `의사별 총 내원 환자 (${dateLabel})`,
      label: "total",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const newVal = value && value.new ? value.new : 0;
        const existingVal = value && value.existing ? value.existing : 0;
        return newVal + existingVal;
      },
      unit: "(명)",
      unitPie: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 신환 환자 (${dateLabel})`,
      label: "new",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const retVal = value ? value.new : 0;
        return retVal;
      },
      unit: "(명)",
      unitPie: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 구환 환자 (${dateLabel})`,
      label: "existing",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const retVal = value ? value.existing : 0;
        return retVal;
      },
      unit: "(명)",
      unitPie: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    }
  ];
};

export const salesEstimatesStructure = (intl) => {
  return {
    title: translate(intl, "menuStatistics.salesEstimates"),
    // fetchURL: process.env.REACT_APP_CF_QUERY_URL + "/patients",
    dataParser: parseSalesEstimatesData,
    singleDataParser: parseSalesEstimates,
    generateFields: generatePatientFields,
    graphFunction: generatePatientBarGraph,
    pieGraphFunction: value =>
      generatePatientPieGraph([value.new, value.existing], value.total),
    dialogHeader: (data, currentDate, dateOption, currentDoctor, classes) => {
      const searchDate = moment
        .unix(currentDate)
        .startOf(dateOption)
        .format("YYYYMMDD");
      const result = parseSalesEstimates(data, currentDoctor, searchDate);
      return [
        <Grid className={classes.item__header} key="header-total" item>
          <CCTypography variant="body1" color="inherit">
            진료 환자수(명)
          </CCTypography>
          <CCTypography variant="h4" color="primary">
            {formatCommas(result.total)}
          </CCTypography>
        </Grid>,
        <Grid className={classes.item__header} key="header-new" item>
          <CCTypography variant="body1" color="inherit">
            신환(명)
          </CCTypography>
          <CCTypography variant="h4" color="inherit">
            {formatCommas(result.new)}
          </CCTypography>
        </Grid>,
        <Grid
          className={clsx(classes.item__header, classes.item__header__end)}
          key="header-existing"
          item
        >
          <CCTypography variant="body1" color="inherit">
            구환(명)
          </CCTypography>
          <CCTypography variant="h4" color="inherit">
            {formatCommas(result.existing)}
          </CCTypography>
        </Grid>
      ];
    },
    head: [
      {
        label: translate(intl, "labManageGold.no"),
        key: "date",
        width: 50,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography>{rowIndex + 1}</CCTypography>;
        }
      },
      {
        label: translate(intl, "common.ui.clinic"),
        key: "clinic",
        flex: 1,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography style={{textOverflow: "ellipsis", wordBreak: "break-all"}}>{cellData}</CCTypography>;
        }
      },
      {
        label: <div>{translate(intl, "common.ui.estimates")}</div>,
        key: "estimates",
        width: 150,
        component: ({ cellData, rowData }) => {
          return <CCTypography>{insertComma(cellData)}</CCTypography>;
        }
      },
      {
        label: translate(intl, "menuStatistics.arrived"),
        key: "arrived",
        width: 100,
        component: ({ cellData, rowData }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      }
    ]
  };
};
