import React from "react";

const ReservationnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="7l9fxc7sya" x1="50%" x2="50%" y1="65.306%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
      <linearGradient id="ii412y6uhb" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#00E1D1" />
        <stop offset="100%" stopColor="#00BFA5" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-411.000000, -369.000000) translate(411.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M31 23c0 4.418-3.582 8-8 8H9c-4.418 0-8-3.582-8-8 0-2.527 1.171-4.78 3-6.246V26c0 1.657 1.343 3 3 3h18c1.657 0 3-1.343 3-3v-9.245c1.83 1.466 3 3.719 3 6.245zm-3-11.001V12h-2v14l-.007.117c-.057.497-.48.883-.993.883H7l-.117-.007C6.386 26.936 6 26.513 6 26V12h20v-.001h2zM12.5 22h-5v4h5v-4zm6 0h-5v4h5v-4zm-6-5h-5v4h5v-4zm6 0h-5v4h5v-4zm6 0h-5v4h5v-4zm-12-5h-5v4h5v-4zm6 0h-5v4h5v-4zm6 0h-5v4h5v-4z"
            transform="translate(-411.000000, -369.000000) translate(411.000000, 369.000000)"
          />
          <path
            fill="url(#7l9fxc7sya)"
            d="M6 11.999V26c0 .513.386.936.883.993L7 27h18c.513 0 .936-.386.993-.883L26 26V11.999h2V26c0 1.657-1.343 3-3 3H7c-1.657 0-3-1.343-3-3V11.999h2zM12.5 22v4h-5v-4h5zm6 0v4h-5v-4h5zm-6-5v4h-5v-4h5zm6 0v4h-5v-4h5zm6 0v4h-5v-4h5zm-12-5v4h-5v-4h5zm6 0v4h-5v-4h5zm6 0v4h-5v-4h5z"
            transform="translate(-411.000000, -369.000000) translate(411.000000, 369.000000)"
          />
          <path
            fill="url(#ii412y6uhb)"
            d="M24.5 22v4h-5v-4h5zM22.666 2C23.403 2 24 2.597 24 3.333v1.334h1.333C26.8 4.667 28 5.867 28 7.334v4.665H4l.014-4.665c0-1.467 1.172-2.667 2.653-2.667H8V3.334C8 2.596 8.597 2 9.334 2c.736 0 1.333.597 1.333 1.333v1.334h10.666V3.334c0-.737.597-1.334 1.334-1.334z"
            transform="translate(-411.000000, -369.000000) translate(411.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default ReservationnIcon;
