import React from "react";

const OralexamIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M7.76 6.74c.157.124.33.22.514.29l1.001.71a5.949 5.949 0 0 0 6.182 4.13l-.002.004a30 30 0 0 0-1.666 5.59l-.131.72-.05.288c-.26 1.507-.457 2.453-.593 2.838-.354 1-1.522.836-1.72 0-.131-.557-.548-2.356-1.251-5.397-.12-.39-.48-.677-.893-.707l-.113-.001h-.076c-.459-.02-.875.283-1.006.708-.703 3.04-1.12 4.84-1.252 5.397-.197.836-1.365 1-1.719 0-.136-.385-.334-1.331-.593-2.838l-.05-.289a30 30 0 0 0-1.797-6.31c-.458-1.123-.627-1.969-.508-2.536.674-3.193 3.666-4.175 5.722-2.597zM14.46 2a4.46 4.46 0 0 1 4.46 4.46 4.44 4.44 0 0 1-1.078 2.902l.192.185h.542L22 12.977 20.978 14l-3.43-3.424v-.542l-.186-.192a4.44 4.44 0 0 1-2.902 1.077 4.46 4.46 0 1 1 0-8.919zm0 1.372a3.083 3.083 0 0 0-3.072 2.774c1.847-.561 3.932.511 4.539 3.031a3.08 3.08 0 0 0 1.62-2.717 3.083 3.083 0 0 0-3.087-3.088z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default OralexamIcon;
