import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CCDateField } from "styles/components";

const RequiredDateField = props => {
  const {
    value,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    width,
    InputLabelProps,
    ...others
  } = props;

  // value
  const [error, setError] = useState(false);

  // function
  const handleOnChange = ({ target: { value } }) => {
    onChange({ target: { value: value } });
    if (value) {
      setError(false);
    } else {
      setError(true);
    }
  };

  // useEffect
  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);

  useEffect(() => {
    if (value) {
      setError(false);
    } else setError(true);
    required && value === "" && setError(true);
  }, [value, required]);
  return (
    <CCDateField
      width={width}
      label={label}
      value={value}
      onChange={handleOnChange}
      InputLabelProps={InputLabelProps}
      required={required}
      {...others}
      error={error}
    />
  );
};

RequiredDateField.defaultProps = {
  value: "",
  margin: "",
  variant: "outlined",
  label: "",
  required: false,
  errorOnChange: () => { }
};

RequiredDateField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default RequiredDateField;
