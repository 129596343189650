import { Box } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  translate,
  CVButton,
} from "components";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useIntl } from "react-intl";

import {
  CCIconButton,
  CCPaper,
  CCTypography
} from "styles/components";
import moment from "moment";
import { parseSalesEstimatesData } from "../../graphs/salesEstimates";
import {
  generatePatientBarGraph,
  generateSalesBarGraph
} from "../../types/graph";
import { parseOrdersData } from "../../graphs/orders";
import { monthYearFormat, shortDateFormat } from "../../utils/utils";

const useStyles = makeStyles(theme => ({
  root: {
    height: "325px",
    width: "800px",
    margin: "16px",
    position: "relative",
    color: theme.palette.disabled
  },
  iconleft: {
    zIndex: 1,
    position: "absolute",
    bottom: 3,
    left: 60
  },
  iconright: {
    zIndex: 1,
    position: "absolute",
    bottom: 3,
    right: 40
  },
  tab: {
    width: "70px",
    height: "20px",
    display: "flex",
    borderRadius: "18px",
    backgroundColor: theme.palette.sub.main,
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px"
  },
  selected: {
    backgroundColor: theme.palette.select.main
  },
  tab__text: { color: theme.palette.common.white },
  button__details: {
    position: "absolute",
    right: "8px",
    top: "8px",
    height: "20px",
    width: "58px",
    padding: "0px",
    alignItems: "center",
    fontSize: "12px"
  },
  contents: {
    display: "flex",
    height: "calc(100% - 36px)",
    justifyContent: "center",
    margin: "0px",
    alignItems: "flex-end",
    "& .apexcharts-tooltip": {
      background: "rgba(0, 0, 0, 0.54) !important",
      border: "0px !important",
      color: "#FFFFFF",
      "& .apexcharts-tooltip-title": {
        background: "#FFFFFF",
        color: "#212121"
      }
    },

  },
  contents__left: { width: "50%" },
  contents__right: { width: "50%" },
  text__color: {
    color: "grey"
  },
  text: {
    display: "inline-block"
  },
  border: {
    margin: "16px 0px",
    border: `1px solid ${theme.palette.border.main}`,
    width: 330
  },
  time: {
    marginRight: 6
  },
  container__pie: {
    marginBottom: 16,
    height: "280px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#878787"
  }
}));

const Graph = props => {
  const {
    className,
    title,
    options,
    subtitle,
    selected,
    onSelect,
    titlePie,
    barGraphSetting: barGraphSettingProp,
    pieGraphSetting,
    onDetailClick,
    size,
    getMoreData,
    periodTime,
    currentDate,
    forceDirection,
  } = props;
  const intl = useIntl();
  const classes = useStyles();

  const [currentTime, setCurrentTime] = useState(currentDate);
  const [barGraphSetting, setBarGraphSetting] = useState();
  const isLangAr = localStorage.getItem("lang") === 'ar'
  useEffect(() => {
    setBarGraphSetting(barGraphSettingProp);
    setCurrentTime(currentDate);
  }, [barGraphSettingProp, currentDate]);

  const formatDateLabel = (value, option) => {
    const weekOfMonth = input => {
      const firstDayOfMonth = input.clone().startOf("month");
      const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

      const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

      return Math.ceil((input.date() + offset) / 7);
    };

    if (value === "") {
      return value;
    }

    if (option === "day") {
      const lang =
        localStorage.getItem("lang") ||
        process.env.REACT_APP_DEFAULT_LANGUAGE ||
        "en";
      let format = shortDateFormat(lang);
      return moment(value, "YYYYMMDD").format(format);
    }
    if (option === "week") {
      const date = moment(value, "YYYYMMDD").endOf("week");
      const month = date.format("M");
      const week = weekOfMonth(date);
      const lang =
        localStorage.getItem("lang") ||
        process.env.REACT_APP_DEFAULT_LANGUAGE ||
        "en";
      return lang === "ko"
        ? `${month}월 ${week}주`
        : `Month ${month} Week ${week}`;
    }
    if (option === "month") {
      const lang =
        localStorage.getItem("lang") ||
        process.env.REACT_APP_DEFAULT_LANGUAGE ||
        "en";
      let format = monthYearFormat(lang)
      return moment(value, "YYYYMMDD").format(format);
    }
    if (option === "year") {
      return moment(value, "YYYYMMDD").format("YYYY");
    }
  };

  const graphLabels = (dateOption, selectedDate) => {
    const date = moment.unix(selectedDate).startOf(dateOption);
    let startIndex = -8;
    if (dateOption === "year") {
      startIndex = -3;
    }

    const labels = [];
    for (let i = startIndex; i < 1; i++) {
      if (i < 0) {
        const fieldDate = date.clone().subtract(Math.abs(i), dateOption);
        labels.push(fieldDate.format("YYYYMMDD"));
      } else if (i > 0) {
        const fieldDate = date.clone().add(Math.abs(i), dateOption);
        labels.push(fieldDate.format("YYYYMMDD"));
      } else {
        labels.push(date.format("YYYYMMDD"));
      }
    }
    return labels;
  };

  return (
    <CCPaper
      className={clsx(classes.root, className)}
    //style={{ height: size === "large" ? "667px" : "325px" }}
    >
      {getMoreData && (
        <>
          <CCIconButton
            onClick={() => {
              const selectedDate = moment
                .utc(
                  moment
                    .unix(currentTime)
                    .subtract(
                      periodTime.toLowerCase() === "year" ? 4 : 5,
                      `${periodTime.toLowerCase()}s`
                    )
                    .format("L"),
                  "L"
                )
                .unix();
              const dateOption = periodTime.toLowerCase();
              getMoreData({
                variables: {
                  input: {
                    currentDate: selectedDate,
                    periodTime: periodTime,
                    limit: periodTime.toLowerCase() === "year" ? 4 : 10,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (
                    fetchMoreResult &&
                    fetchMoreResult.salesEstimatesStatistics
                  ) {
                    setCurrentTime(selectedDate);
                    const data = (() => {
                      const series = parseSalesEstimatesData(
                        fetchMoreResult.salesEstimatesStatistics,
                        graphLabels(dateOption, selectedDate),
                        "",
                        intl
                      );

                      return generatePatientBarGraph(
                        series,
                        graphLabels(dateOption, selectedDate),
                        value => formatDateLabel(value, dateOption)
                      );
                    })();
                    setBarGraphSetting(data);
                  }

                  if (fetchMoreResult && fetchMoreResult.ordersStatistics) {
                    setCurrentTime(selectedDate);
                    const data = (() => {
                      const series = parseOrdersData(
                        fetchMoreResult.ordersStatistics,
                        graphLabels(dateOption, selectedDate),
                        intl
                      );
                      return generateSalesBarGraph(
                        series,
                        graphLabels(dateOption, selectedDate),
                        value => formatDateLabel(value, dateOption)
                      );
                    })();
                    setBarGraphSetting(data);
                  }
                }
              });
            }}
            className={classes.iconleft}
          >
            {isLangAr ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </CCIconButton>
          <CCIconButton
            onClick={() => {
              const selectedDate = moment
                .unix(currentTime)
                .add(
                  periodTime.toLowerCase() === "year" ? 4 : 5,
                  `${periodTime.toLowerCase()}s`
                )
                .unix();
              const dateOption = periodTime.toLowerCase();
              getMoreData({
                variables: {
                  input: {
                    currentDate: selectedDate,
                    periodTime: periodTime,
                    limit: periodTime.toLowerCase() === "year" ? 4 : 10,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (
                    fetchMoreResult &&
                    fetchMoreResult.salesEstimatesStatistics
                  ) {
                    setCurrentTime(selectedDate);
                    const data = (() => {
                      const series = parseSalesEstimatesData(
                        fetchMoreResult.salesEstimatesStatistics,
                        graphLabels(dateOption, selectedDate),
                        "",
                        intl
                      );

                      return generatePatientBarGraph(
                        series,
                        graphLabels(dateOption, selectedDate),
                        value => formatDateLabel(value, dateOption)
                      );
                    })();
                    setBarGraphSetting(data);
                  }

                  if (fetchMoreResult && fetchMoreResult.ordersStatistics) {
                    setCurrentTime(selectedDate);
                    const data = (() => {
                      const series = parseOrdersData(
                        fetchMoreResult.ordersStatistics,
                        graphLabels(dateOption, selectedDate),
                        intl
                      );
                      return generateSalesBarGraph(
                        series,
                        graphLabels(dateOption, selectedDate),
                        value => formatDateLabel(value, dateOption)
                      );
                    })();
                    setBarGraphSetting(data);
                  }
                }
              });
            }}
            className={classes.iconright}
          >
            {isLangAr ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </CCIconButton>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 8,
          color: "inherit"
        }}
      >
        <CCTypography variant="h4">{title}</CCTypography>
        <Box width="16px" />
        {options &&
          options.map((option, index) => {
            return (
              <Box
                className={clsx({
                  [classes.tab]: true,
                  [classes.selected]: selected === index
                })}
                key={option}
                onClick={() => {
                  if (onSelect) {
                    onSelect(index);
                  }
                }}
              >
                <CCTypography className={classes.tab__text}>
                  {option}
                </CCTypography>
              </Box>
            );
          })}
        <CCTypography color="inherit">{subtitle ? subtitle : ""}</CCTypography>
        {onDetailClick && (
          <CVButton
            className={classes.button__details}
            color="normal"
            variant="contained"
            onClick={() => {
              if (onDetailClick) {
                onDetailClick();
              }
            }}
          >
            {translate(intl, "payment.detail")}
          </CVButton>
        )}
      </div>
      <div className={classes.contents} >
        {barGraphSetting && (
          <div style={{ direction: forceDirection ? "ltr" : "unset" }}>
            <Chart
              options={barGraphSetting.options}
              series={barGraphSetting.series}
              type={barGraphSetting.type ? barGraphSetting.type : "line"}
              height={size === "large" ? "570" : "270"}
              width={!pieGraphSetting ? "750" : "500"}
            />
          </div>
        )}
        {pieGraphSetting && (
          <div className={classes.container__pie} style={{ direction: "ltr" }}>
            <CCTypography color="inherit">{titlePie}</CCTypography>
            <Chart
              options={pieGraphSetting.options}
              series={pieGraphSetting.series}
              type="pie"
              // height="300"
              width="250"
            />
          </div>
        )}
      </div>
    </CCPaper>
  );
};

export default Graph;
