import { useMutation } from "@apollo/client";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  CCFlattenDateField,
  CVButton,
  DialogCancel,
  DialogError,
  NumberFormatPayment,
  enqueueToastSave
} from "components";
import { AppContext } from "context/AppContext";
import produce from "immer";
import moment from "moment";
import { UPDATE_PAYMENT } from "queries/payment";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  CCButton,
  CCDialogSimple,
  CCTextField,
  CCTypography
} from "styles/components";
import { CalculationIcon, ProgressIcon } from "styles/icons";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import { formatCommas } from "utils/number";
import { translate } from "../../../../../components";
import { ENTRY_LIMIT } from "../../../../../types/constants";
import MonthPicker from "../MonthPicker";
const useStyles = makeStyles(theme => ({
  w100: { width: 100 },
  w180: { width: 180 },
  w164: { width: 164, margin: "8px 16px" },
  date: { width: 130 },
  flex: { display: "flex" },
  main__text: {
    padding: 16,
    borderBottom: "1px solid #b0bec5",
    "& p": {
      fontWeight: "bold"
    }
  },
  bg__white: { background: "white" },
  flex__center: {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": { padding: 0 }
  },
  root: {
    "& .MuiDialog-paperWidthSm": { maxWidth: "inherit", width: 500 }
  },
  input__group: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px 0",
    background: "#eceff1"
  },
  card: {
    marginLeft: 0,
    width: 85
  },
  divided: {
    height: 1,
    backgroundColor: "#b0bec5",
    margin: "16px"
  },
  line__text: {
    width: 200,
    margin: "16px 0"
  },
  color__grey: {
    color: "#b0bec5"
  },
  color__red: {
    color: "#f50057"
  },
  width150: {
    width: 150
  },
  divided__horizon: {
    width: 1,
    backgroundColor: "#b0bec5"
  }
}));

const initState = {
  date: moment().unix(),
  staff: "",
  name: "",
  money: {
    cardPaymentAmount: 0,
    cashPaymentAmount: 0
  },
  memo: ""
};

const PaymentDialog = props => {
  const { open, onClose, value, labOrder, refetch } = props;
  const { id, charged, unpaid } = value;

  const classes = useStyles();
  const intl = useIntl();
  const { appContext } = useContext(AppContext);

  const [openWarning, setOpenWarning] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [obj, setObj] = useState(initState);
  const { enqueueSnackbar } = useSnackbar();

  const [payment] = useMutation(UPDATE_PAYMENT, {
    onCompleted: () => {
      onClose();

      refetch();
    }
  });

  const handleChange = (name1, name2) => value => {
    const regExp = /^[0-9]+$/;
    if (!regExp.test(value)) {
      return;
    }
    const newObj = produce(obj, draftState => {
      draftState[name1][name2] = Number(value);
    });
    setObj(newObj);
  };
  const handleSubmit = () => {
    if (obj.money.cardPaymentAmount + obj.money.cashPaymentAmount === 0) {
      setErrorDialog(translate(intl, "common.ui.pleaseInputPaymentAmount"));
      return;
    }

    if (obj.money.cardPaymentAmount < 0 || obj.money.cashPaymentAmount < 0) {
      setErrorDialog(translate(intl, "settings.employee.invalidInput"));
      return;
    }
    if (obj.money.cardPaymentAmount + obj.money.cashPaymentAmount > unpaid) {
      setErrorDialog(translate(intl, "payment.dialog.message.overpayment"));
      return;
    } else
      payment({
        variables: {
          input: {
            id,
            cardPaymentAmount: obj.money.cardPaymentAmount,
            cashPaymentAmount: obj.money.cashPaymentAmount,
            transactionType: "1",
            staffId: obj.staff || appContext.me.staffId
          }
        }
      }).then(() => enqueueToastSave(intl, enqueueSnackbar));;
  };
  const handleAutoFillChange = (key, value) => {
    setObj(pre => ({ ...pre, money: { ...pre.money, [key]: value } }));
  };
  const staffs = Array.isArray(labOrder) ? labOrder : (Array.isArray(labOrder?.staff) ? labOrder?.staff : [])
  console.log(value)
  return (
    <>
      <CCDialogSimple
        classes={classes}
        title={translate(intl, "payment.payment")}
        open={open}
        onClose={onClose}
        onCloseButton={onClose}
        contentsPadding={false}
        contents={
          <Grid container style={{ flexDirection: "column" }}>
            <Grid container justify="space-between">
              <CCFlattenDateField
                disabled
                label={translate(intl, "payment.sendDate")}
                className={classes.width150}
                margin={"dense"}
                variant={"outlined"}
                value={moment.unix(value.sendBillToCleverAt)}
              />
              <CCFlattenDateField
                className={classes.width150}
                onChange={e => { }}
                label={translate(intl, "payment.receiptDate")}
                value={moment()}
                disabled
              />

              <CCTextField
                className={classes.width150}
                label={translate(intl, "payment.recorder")}
                margin={"dense"}
                variant={"outlined"}
                value={obj.staff || appContext.me.staffId}
                select
                onChange={e => {
                  setObj(prevState => ({
                    ...prevState,
                    staff: e.target.value
                  }));
                }}
              >
                {staffs.map(item => (
                  <CCMenuItem
                    key={item.staffId || item.staffName}
                    value={item.staffId}
                  >
                    {item.staffName}
                  </CCMenuItem>
                ))}
              </CCTextField>
            </Grid>
            <Grid
              item
              className={classes.main__text}
              container
              justify="space-between"
            >
              <Grid
                container
                className={classes.line__text}
                justify="space-between"
              >
                <CCTypography className={classes.color__grey}>
                  <p>{translate(intl, "payment.totalCharged")}</p>
                </CCTypography>
                <CCTypography variant="h4">
                  <p>{formatCommas(charged)}</p>
                </CCTypography>
              </Grid>
              <Box className={classes.divided__horizon} />
              <Grid
                container
                className={classes.line__text}
                justify="space-between"
              >
                <CCTypography className={classes.color__grey}>
                  <p>{translate(intl, "payment.unpaid")}</p>
                </CCTypography>
                <CCTypography variant="h4" className={classes.color__red}>
                  <p>{unpaid -
                    (obj.money.cardPaymentAmount +
                      obj.money.cashPaymentAmount) >
                    0
                    ? formatCommas(
                      unpaid -
                      (obj.money.cardPaymentAmount +
                        obj.money.cashPaymentAmount)
                    )
                    : 0}</p>
                </CCTypography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item className={classes.input__group}>
                <CCTextField
                  InputProps={{
                    inputComponent: NumberFormatPayment,
                    inputProps: {
                      style: { textAlign: "right", direction: 'ltr' }
                    }
                  }}
                  className={clsx(classes.w164, classes.bg__white)}
                  margin="dense"
                  variant="outlined"
                  label={<CCTypography><div>{translate(intl, "payment.creditCard")}</div></CCTypography>}
                  value={obj.money.cardPaymentAmount}
                  onChange={(event) => handleChange("money", "cardPaymentAmount")(event.target.value)}
                  startIcon={<ProgressIcon />}
                  onClickIcon={() => {
                    if (obj.money.cardPaymentAmount) {
                      handleAutoFillChange("cardPaymentAmount", 0);
                    } else
                      handleAutoFillChange(
                        "cardPaymentAmount",
                        unpaid - obj.money.cashPaymentAmount
                      );
                  }}
                />
                <CCTextField
                  InputProps={{
                    inputComponent: NumberFormatPayment,
                    inputProps: {
                      style: { textAlign: "right", direction: 'ltr' },
                      maxLength: ENTRY_LIMIT.PRICE
                    }
                  }}
                  className={clsx(classes.w164, classes.bg__white)}
                  margin="dense"
                  variant="outlined"
                  label={<CCTypography><div>{translate(intl, "payment.cash")}</div></CCTypography>}
                  value={obj.money.cashPaymentAmount}
                  onChange={(event) => handleChange("money", "cashPaymentAmount")(event.target.value)}
                  startIcon={<ProgressIcon />}
                  onClickIcon={() => {
                    if (obj.money.cashPaymentAmount) {
                      handleAutoFillChange("cashPaymentAmount", 0);
                    } else
                      handleAutoFillChange(
                        "cashPaymentAmount",
                        unpaid - obj.money.cardPaymentAmount
                      );
                  }}
                />
                <Box className={classes.divided} />
                <CCTextField
                  InputProps={{
                    inputComponent: NumberFormatPayment,
                    inputProps: {
                      style: { textAlign: "right" },
                      maxLength: 11
                    }
                  }}
                  className={classes.w164}
                  margin="dense"
                  variant="outlined"
                  label={<CCTypography><div>{translate(intl, "payment.total")}</div></CCTypography>}
                  value={
                    obj.money.cardPaymentAmount + obj.money.cashPaymentAmount
                  }
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        endActions={
          <>
            <CVButton
              onClick={() => {
                if (
                  obj.money.cardPaymentAmount + obj.money.cashPaymentAmount !==
                  0
                ) {
                  setOpenWarning(true);
                } else onClose();
              }}
              style={{
                textTransform: "unset",
              }}
            >
              {translate(intl, "common.ui.cancel")}
            </CVButton>
            <CVButton
              permission={"payment.billManage"}
              startIcon={<CalculationIcon />}
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                // const isCreate = checkPaymentCreate(); // 생성여부, index 번호
                // calculation(isCreate);
                handleSubmit();
              }}
            >
              {translate(intl, "common.ui.confirm")}
            </CVButton>
          </>
        }
      />

      <DialogCancel
        open={openWarning}
        onAgree={() => {
          onClose();
          setOpenWarning(false);
        }}
        onDisagree={() => setOpenWarning(false)}
        onClose={() => setOpenWarning(false)}
      />

      <DialogError
        open={Boolean(errorDialog)}
        content={errorDialog}
        onClose={() => setErrorDialog(false)}
      />
    </>
  );
};

export default PaymentDialog;
