import { makeStyles } from "@material-ui/styles";
import React, { useReducer } from "react";
import { CCIconButton } from "styles/components";
import { HelpIcon, SignoutIcon, NotificationsIcon } from "styles/icons";
import { HelpMenu, LogoutSelect, Notification } from "../";

const useStyles = makeStyles(theme => ({
  root: {},
  border: {
    width: 1,
    height: 32,
    margin: "0 0px",
    borderRight: `1px solid ${theme.palette.border.main}`,
    display: "inline-flex",
    verticalAlign: "middle"
  },
  icon__button: { margin: 0, color: "rgba(0, 0, 0, 0.54)" }
}));

const initDialog = {
  help: { state: false, value: null },
  logout: { state: false, value: null },
  notification: { state: false, value: null },
};

const dialogReducer = (state, action) => {
  return { ...state, [action.type]: action.value };
};

const IconMenu = () => {
  const classes = useStyles();
  const [dialog, dispatchDialog] = useReducer(dialogReducer, initDialog);
  let hasTwoManual = false
  if (process.env.REACT_APP_LANGUAGE_REGION) {
    let region = process.env.REACT_APP_LANGUAGE_REGION
    switch (region) {
      case "ar":
        hasTwoManual = true
    }
  }
  return (
    <div className={classes.root}>
      <CCIconButton
        onClick={e => {
          dispatchDialog({
            type: "notification",
            value: { state: true, value: e.currentTarget }
          });
        }}
      >
        <Notification
          anchorEl={dialog.notification.value}
          open={dialog.notification.state}
          onClose={() =>
            dispatchDialog({
              type: "notification",
              value: { state: false, value: null }
            })
          }
        >
           <NotificationsIcon />
        </Notification>
      </CCIconButton>

      <CCIconButton
        onClick={e => {
          if (hasTwoManual) {
            dispatchDialog({
              type: "help",
              value: { state: true, value: e.currentTarget }
            });
          } else {
            const openManual = url => window.open(url);
            let region = process.env.REACT_APP_LANGUAGE_REGION
            let link = '/PDF/help/En_Clever_Lab_Manual.pdf'
            switch (region) {
              case "en":
                link =  "/PDF/help/En_Clever_Lab_Manual.pdf"
                break
              case "vi":
                link = "/PDF/help/Vn_Clever_Lab_Manual.pdf"
                break
              case "ko":
                link= "/PDF/help/Kr_Clever_Lab_Manual.pdf"
                break
              case "ru":
                link = "/PDF/help/Rus_Clever_Lab_Manual.pdf" 
                break 
            }
            openManual(link)
          }
        }}
      >
        <HelpIcon />
      </CCIconButton>

      <CCIconButton
        className={classes.icon__button}
        onClick={e => {
          dispatchDialog({
            type: "logout",
            value: { state: true, value: e.currentTarget }
          });
        }}
      >
        <SignoutIcon />
      </CCIconButton>

      <LogoutSelect
        anchorEl={dialog.logout.value}
        open={dialog.logout.state}
        onClose={() =>
          dispatchDialog({
            type: "logout",
            value: { state: false, value: null }
          })
        }
      />

      <HelpMenu
        anchorEl={dialog.help.value}
        open={dialog.help.state}
        onClose={() =>
          dispatchDialog({
            type: "help",
            value: { state: false, value: null }
          })
        }
      />
    </div>
  );
};

export default IconMenu;
