import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import CCTooltip from "../CCTooltip";

const selectHeight = 20;

const useStyles = makeStyles(theme => {
  const radius = theme.shape.borderRadius;

  return {
    left: { marginRight: -1, marginLeft: 0 },
    right: { marginRight: 0, marginLeft: 0 },
    input__left: {
      borderRadius: `${radius}px 0px 0px ${radius}px`,
      "& > fieldset": {
        borderRightWidth: 0
      },
      "& > input": {
        "&.Mui-disabled": {
          borderRadius: `${radius}px 0px 0px ${radius}px`
        }
      }
    },
    input__mid: {
      borderRadius: 0,
      "& > input": {
        "&.Mui-disabled": {
          borderRadius: 0
        }
      }
    },
    input__right: {
      borderRadius: `0px ${radius}px ${radius}px 0px`,
      "& > fieldset": {
        borderLeftWidth: 0
      },
      "& > input": {
        "&.Mui-disabled": {
          borderRadius: `0px ${radius}px ${radius}px 0px`
        }
      }
    },
    input__disabled: {
      "&.Mui-disabled": {
        backgroundColor: theme.palette.inactive.rgba
      },
      "& > input": {
        "&.Mui-disabled": {
          backgroundColor: "unset"
        }
      }
    },
    input__focus__left: {
      "& > fieldset": {
        borderLeftWidth: 0
      }
    },
    input__focus__right: {
      "& > fieldset": {
        borderRightWidth: 0
      }
    },
    startAdornment: {
      marginRight: 0
    },
    endAdornmentRight: {
      marginLeft: 0
    },
    startIconButton: {
      padding: 5
    },
    endIconButton: {
      padding: 5
    },
    iconButton__focus: {
      color: theme.palette.select.main
    }
  };
});

const selectStyles = makeStyles(() => ({
  root: {},
  select: {
    height: selectHeight,
    lineHeight: `${selectHeight}px`
  },
  outlined: {},
  selectMenu: {},
  disabled: {}
}));

const useLocaleStyles = makeStyles(
  () => ({
    notchedOutline:
      localStorage.getItem("lang") === "ru"
        ? {
            "&  legend": {
              fontSize: `${0.75}em !important`
            }
          }
        : {},
    marginDense:
      localStorage.getItem("lang") === "ru"
        ? {
            fontSize: `${12}px !important`
          }
        : {}
  }),
  { name: "CCTextFieldTrio" }
);

const CCTextFieldTrio = props => {
  const classes = useStyles();
  const selectClasses = selectStyles();

  const { LeftProps, MidProps, RightProps, ...other } = props;
  const LLocaleClasses = useLocaleStyles();
  const MLocaleClasses = useLocaleStyles();
  const RLocaleClasses = useLocaleStyles();

  const {
    label: Llabel,
    value: Lvalue,
    classes: Lclasses,
    className: LclassName,
    component: Lcomponent,
    disabled: Ldisabled,
    InputLabelProps: LinputLabelProps,
    InputProps: LinputProps,
    onBlur: LonBlur,
    onChange: LonChange,
    onClick: LonClick,
    onFocus: LonFocus,
    onKeyPress: LonKeyPress,
    SelectProps: LselectProps,
    startIcon: LstartIcon,
    tooltipProps: LtooltipProps,
    endIcon: LendIcon,
    onClickIcon: LonClickIcon,
    variant: Lvariant,
    inputProps: LrawInputProps,
    multiline: Lmultiline,
    ...Lother
  } = LeftProps;

  const {
    label: Mlabel,
    value: Mvalue,
    classes: Mclasses,
    className: MclassName,
    component: Mcomponent,
    disabled: Mdisabled,
    InputLabelProps: MinputLabelProps,
    InputProps: MinputProps,
    onBlur: MonBlur,
    onChange: MonChange,
    onClick: MonClick,
    onFocus: MonFocus,
    onKeyPress: MonKeyPress,
    SelectProps: MselectProps,
    startIcon: MstartIcon,
    tooltipProps: MtooltipProps,
    endIcon: MendIcon,
    onClickIcon: MonClickIcon,
    variant: Mvariant,
    inputProps: MrawInputProps,
    multiline: Mmultiline,
    ...Mother
  } = MidProps;

  const {
    label: Rlabel,
    value: Rvalue,
    classes: Rclasses,
    className: RclassName,
    component: Rcomponent,
    disabled: Rdisabled,
    InputLabelProps: RinputLabelProps,
    InputProps: RinputProps,
    onBlur: RonBlur,
    onChange: RonChange,
    onClick: RonClick,
    onFocus: RonFocus,
    onKeyPress: RonKeyPress,
    SelectProps: RselectProps,
    startIcon: RstartIcon,
    tooltipProps: RtooltipProps,
    endIcon: RendIcon,
    onClickIcon: RonClickIcon,
    variant: Rvariant,
    inputProps: RrawInputProps,
    multiline: Rmultiline,
    ...Rother
  } = RightProps;

  const [lFocuse, setLfocuse] = useState(false);
  const [mFocuse, setMfocuse] = useState(false);
  const [rFocuse, setRfocuse] = useState(false);
  const L_DEFAULT_MAX_LENGTH = Boolean(Lmultiline) ? 500 : 200;
  const M_DEFAULT_MAX_LENGTH = Boolean(Mmultiline) ? 500 : 200;
  const R_DEFAULT_MAX_LENGTH = Boolean(Rmultiline) ? 500 : 200;

  const LinputMore = {};
  const MinputMore = {};
  const RinputMore = {};

  if (LinputProps) {
    if (LinputProps.startAdornment !== "undefined")
      LinputMore.startAdornment = LinputProps.startAdornment;
    if (LinputProps.endAdornment !== "undefined")
      LinputMore.endAdornment = LinputProps.endAdornment;
    if (LinputProps.className !== "undefined")
      LinputMore.className = LinputProps.className;
  }

  if (MinputProps) {
    if (MinputProps.startAdornment !== "undefined")
      MinputMore.startAdornment = MinputProps.startAdornment;
    if (MinputProps.endAdornment !== "undefined")
      MinputMore.endAdornment = MinputProps.endAdornment;
    if (MinputProps.className !== "undefined")
      MinputMore.className = MinputProps.className;
  }

  if (RinputProps) {
    if (RinputProps.startAdornment !== "undefined")
      RinputMore.startAdornment = RinputProps.startAdornment;
    if (RinputProps.endAdornment !== "undefined")
      RinputMore.endAdornment = RinputProps.endAdornment;
    if (RinputProps.className !== "undefined")
      RinputMore.className = RinputProps.className;
  }

  var LtooltipMore = {};
  var MtooltipMore = {};
  var RtooltipMore = {};

  if (LtooltipProps) LtooltipMore = { ...LtooltipProps };
  if (MtooltipProps) MtooltipMore = { ...MtooltipProps };
  if (RtooltipProps) RtooltipMore = { ...RtooltipProps };

  const LeftTextField = (
    <TextField
      label={Llabel}
      value={Lvalue}
      classes={Lclasses}
      className={clsx(classes.left, LclassName)}
      InputLabelProps={{
        ...LinputLabelProps,
        classes: {
          ...LinputLabelProps?.classes,
          marginDense: clsx(LLocaleClasses.marginDense)
        }
      }}
      inputProps={{
        maxLength: L_DEFAULT_MAX_LENGTH,
        ...LrawInputProps
      }}
      InputProps={{
        ...LinputProps,
        classes: {
          ...LinputProps?.classes,
          notchedOutline: clsx(LLocaleClasses.notchedOutline)
        },
        className: LinputMore.className
          ? clsx(LinputProps.className, {
              [classes.input__left]: true,
              [classes.input__disabled]:
                LstartIcon ||
                LendIcon ||
                LinputMore.startAdornment ||
                LinputMore.endAdornment
            })
          : clsx({
              [classes.input__left]: true,
              [classes.input__disabled]:
                LstartIcon ||
                LendIcon ||
                LinputMore.startAdornment ||
                LinputMore.endAdornment
            }),
        startAdornment: LinputMore.startAdornment ? (
          LinputMore.startAdornment
        ) : LstartIcon ? (
          <InputAdornment className={classes.startAdornment} position="start">
            <IconButton
              className={clsx({
                [classes.startIconButton]: true,
                [classes.iconButton__focus]: lFocuse
              })}
              onClick={LonClickIcon}
              edge="start"
              disabled={Ldisabled}
            >
              {LstartIcon}
            </IconButton>
          </InputAdornment>
        ) : null,
        endAdornment: LinputMore.endAdornment ? (
          LinputMore.startAdornment
        ) : LendIcon ? (
          <InputAdornment className={classes.endAdornment} position="end">
            <IconButton
              className={clsx({
                [classes.endIconButton]: true,
                [classes.iconButton__focus]: lFocuse
              })}
              onClick={LonClickIcon}
              edge="end"
              disabled={Ldisabled}
            >
              {LendIcon}
            </IconButton>
          </InputAdornment>
        ) : null
      }}
      onBlur={e => {
        LonBlur && LonBlur(e);
        setLfocuse(false);
      }}
      onChange={LonChange}
      onClick={LonClick}
      onFocus={e => {
        LonFocus && LonFocus(e);
        setLfocuse(true);
      }}
      onKeyPress={LonKeyPress}
      variant={Lvariant}
      SelectProps={{
        ...LselectProps,
        classes: selectClasses
      }}
      disabled={Ldisabled}
      {...Lother}
      {...other}
    />
  );

  const MidTextField = (
    <TextField
      label={Mlabel}
      value={Mvalue}
      classes={Mclasses}
      className={clsx(classes.left, MclassName)}
      InputLabelProps={{
        ...MinputLabelProps,
        classes: {
          ...MinputLabelProps?.classes,
          marginDense: clsx(MLocaleClasses.marginDense)
        }
      }}
      inputProps={{
        maxLength: M_DEFAULT_MAX_LENGTH,
        ...MrawInputProps
      }}
      InputProps={{
        ...MinputProps,
        classes: {
          ...MinputProps?.classes,
          notchedOutline: clsx(MLocaleClasses.notchedOutline)
        },
        className: MinputMore.className
          ? clsx(MinputProps.className, {
              [classes.input__mid]: true,
              [classes.input__disabled]:
                MstartIcon ||
                MendIcon ||
                MinputMore.startAdornment ||
                MinputMore.endAdornment,
              [classes.input__focus__left]: lFocuse,
              [classes.input__focus__right]: rFocuse
            })
          : clsx({
              [classes.input__mid]: true,
              [classes.input__disabled]:
                MstartIcon ||
                MendIcon ||
                MinputMore.startAdornment ||
                MinputMore.endAdornment,
              [classes.input__focus__left]: lFocuse,
              [classes.input__focus__right]: rFocuse
            }),
        startAdornment: MinputMore.startAdornment ? (
          MinputMore.startAdornment
        ) : MstartIcon ? (
          <InputAdornment className={classes.startAdornment} position="start">
            <IconButton
              className={clsx({
                [classes.startIconButton]: true,
                [classes.iconButton__focus]: mFocuse
              })}
              onClick={MonClickIcon}
              edge="start"
              disabled={Mdisabled}
            >
              {MstartIcon}
            </IconButton>
          </InputAdornment>
        ) : null,
        endAdornment: MinputMore.endAdornment ? (
          MinputMore.startAdornment
        ) : MendIcon ? (
          <InputAdornment className={classes.endAdornment} position="end">
            <IconButton
              className={clsx({
                [classes.endIconButton]: true,
                [classes.iconButton__focus]: mFocuse
              })}
              onClick={MonClickIcon}
              edge="end"
              disabled={Mdisabled}
            >
              {MendIcon}
            </IconButton>
          </InputAdornment>
        ) : null
      }}
      onBlur={e => {
        MonBlur && MonBlur(e);
        setMfocuse(false);
      }}
      onChange={MonChange}
      onClick={MonClick}
      onFocus={e => {
        MonFocus && MonFocus(e);
        setMfocuse(true);
      }}
      onKeyPress={MonKeyPress}
      variant={Mvariant}
      SelectProps={{
        ...MselectProps,
        classes: selectClasses
      }}
      disabled={Mdisabled}
      {...Mother}
      {...other}
    />
  );

  const RightTextField = (
    <TextField
      label={Rlabel}
      value={Rvalue}
      classes={Rclasses}
      className={clsx(classes.right, RclassName)}
      InputLabelProps={{
        ...RinputLabelProps,
        classes: {
          ...RinputLabelProps?.classes,
          marginDense: clsx(RLocaleClasses.marginDense)
        }
      }}
      inputProps={{
        maxLength: R_DEFAULT_MAX_LENGTH,
        ...RrawInputProps
      }}
      InputProps={{
        ...RinputProps,
        classes: {
          ...RinputProps?.classes,
          notchedOutline: clsx(RLocaleClasses.notchedOutline)
        },
        className: RinputMore.className
          ? clsx(RinputProps.className, {
              [classes.input__right]: true,
              [classes.input__disabled]:
                RstartIcon ||
                RendIcon ||
                RinputMore.startAdornment ||
                RinputMore.endAdornment
            })
          : clsx({
              [classes.input__right]: true,
              [classes.input__disabled]:
                RstartIcon ||
                RendIcon ||
                RinputMore.startAdornment ||
                RinputMore.endAdornment
            }),
        startAdornment: RinputMore.startAdornment ? (
          RinputMore.startAdornment
        ) : RstartIcon ? (
          <InputAdornment className={classes.startAdornment} position="start">
            <IconButton
              className={clsx({
                [classes.startIconButton]: true,
                [classes.iconButton__focus]: rFocuse
              })}
              onClick={RonClickIcon}
              edge="start"
              disabled={Rdisabled}
            >
              {RstartIcon}
            </IconButton>
          </InputAdornment>
        ) : null,
        endAdornment: RinputMore.endAdornment ? (
          RinputMore.endAdornment
        ) : RendIcon ? (
          <InputAdornment className={classes.endAdornment} position="end">
            <IconButton
              className={clsx({
                [classes.endIconButton]: true,
                [classes.iconButton__focus]: rFocuse
              })}
              onClick={RonClickIcon}
              edge="end"
              disabled={Rdisabled}
            >
              {RendIcon}
            </IconButton>
          </InputAdornment>
        ) : null
      }}
      onBlur={e => {
        RonBlur && RonBlur(e);
        setRfocuse(false);
      }}
      onChange={RonChange}
      onClick={RonClick}
      onFocus={e => {
        RonFocus && RonFocus(e);
        setRfocuse(true);
      }}
      onKeyPress={RonKeyPress}
      variant={Rvariant}
      SelectProps={{
        ...RselectProps,
        classes: selectClasses
      }}
      disabled={Rdisabled}
      {...Rother}
      {...other}
    />
  );

  var LeftElement;
  var MidElement;
  var RightElement;

  if (Lcomponent) {
    LeftElement = Lcomponent;
  } else {
    LeftElement = LeftTextField;
    if (Object.keys(LtooltipMore).length) {
      LeftElement = <CCTooltip {...LtooltipMore}>{LeftTextField}</CCTooltip>;
    }
  }

  if (Mcomponent) {
    MidElement = Mcomponent;
  } else {
    MidElement = MidTextField;
    if (Object.keys(MtooltipMore).length) {
      MidElement = <CCTooltip {...MtooltipMore}>{MidTextField}</CCTooltip>;
    }
  }

  if (Rcomponent) {
    RightElement = Rcomponent;
  } else {
    RightElement = RightTextField;
    if (Object.keys(RtooltipMore).length) {
      RightElement = <CCTooltip {...RtooltipMore}>{RightTextField}</CCTooltip>;
    }
  }

  return (
    <>
      {LeftElement}
      {MidElement}
      {RightElement}
    </>
  );
};

CCTextFieldTrio.propTypes = {
  /** TextField 좌측에 위치 */
  LeftProps: PropTypes.object,
  /** TextField 중앙에 위치 */
  MidProps: PropTypes.object,
  /** TextField 우측에 위치 */
  RightProps: PropTypes.object
};

export default CCTextFieldTrio;
