import { Grid, makeStyles } from "@material-ui/core";
import {
  useWritePermission,
  translate
} from "components";
import { v4 as uuid } from "uuid";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { AddIcon, MenunextIcon } from "styles/src/themes/common/icons";
import {
  CCTypography,
  CCTable,
  CCIconButton,
} from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: "10px",
    overflow: "hidden"
  },
  table: {
    width: "400px",
    height: "calc(100% - 36px)",
  },

  table__body: {
    backgroundColor: theme.palette.background.default,
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  },
  cell: {
    justifyContent: "flex-start",
    overflow: "initial",
    alignSelf: "center",
    padding: 0,
    color: theme.palette.text.primary
  },
  icon__btn: { borderRadius: 50, margin: 0 },
  title: { fontSize: 16, fontWeight: "bold", marginLeft: 20 },
  title__process: {
    wordBreak: 'break-all'
  }
}));

const Process = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  const { currentProcessIndex, moveItem, values, onChangeItem, onDeleteItem, onClickItem } = props

  const itemHeader = [
    {
      key: "processName",
      className: classes.cell,
      component: ({ cellData }) => {
        return <Grid container justify="space-between" alignItems="center" wrap={"nowrap"}>
          <CCTypography className={classes.title__process}>{cellData}</CCTypography>
          <Grid item>
            <MenunextIcon />
          </Grid>
        </Grid>;
      }
    }
  ];


  return <Grid className={classes.root}>
    <Grid container justify="space-between" className={classes.toolbar__container} alignItems="center">
      <CCTypography className={classes.title}>
        {translate(intl, "process")}
      </CCTypography>
      <Grid>
        <CCIconButton
          className={classes.icon__btn}
          onClick={() => {
            moveItem && moveItem(1);
          }}
        >
          <KeyboardArrowDownIcon />
        </CCIconButton>

        <CCIconButton
          className={classes.icon__btn}
          onClick={() => {
            moveItem && moveItem(-1);
          }}
        >
          <KeyboardArrowUpIcon />
        </CCIconButton>
        <CCIconButton
          className={classes.icon__btn}
          onClick={() => {
            onChangeItem && onChangeItem(uuid());
          }}
        >
          <AddIcon />
        </CCIconButton>
      </Grid>
    </Grid>
    <CCTable
      // permission={settingPermission}
      className={classes.table}
      classes={{
        root: classes.table__root,
        table__body: classes.table__body
      }}
      heads={itemHeader}
      contents={values}
      onModClick={({ rowIndex, rowData }) => {
        onChangeItem && onChangeItem(rowData?.id)
      }}
      onDelClick={({ rowData }) => {
        onDeleteItem(rowData?.id);
      }}
      onRowClick={({ data }) => {
        onClickItem(data)
      }}
      selectedRow={currentProcessIndex}
    />
  </Grid>
};

export default Process;
