import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import {gql} from "@apollo/client";

const SEARCH_STAFF = gql`
  query searchStaff($hospitalId: String!, $args: InputSearchStaff) {
    searchStaff(hospitalId: $hospitalId, args: $args) {
      id
      name
    }
  }
`;

const useGetStaff = () => {
  const client = useApolloClient();
  const getStaff = useCallback(async () => {
    try {
      const _staff = await client.query({
        query: SEARCH_STAFF,
        variables: {
          hospitalId: localStorage.getItem("hospitalId")
        }
      });

      return _staff?.data?.searchStaff;
    } catch (e) {
      console.error(e);
    }
  }, [client]);
  return { getStaff };
};

export default useGetStaff;
