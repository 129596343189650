import React from "react";
import { DialogWarningMessage } from "components";
import { useIntl } from "utils/language";

const DialogEmptyAddress = props => {
  const { open, onClose } = props;
  const intl = useIntl();
  return (
    <DialogWarningMessage
      open={open}
      onClose={onClose}
      onAgree={onClose}
      maxWidth="md"
      title={intl.formatMessage({ id: "emptyAddress" })}
    />
  );
};
export default DialogEmptyAddress;
