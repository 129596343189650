import { makeStyles } from "@material-ui/core";
import moment from "moment";
import React, { useState, useCallback } from "react";
import { CCFlattenDatePicker, CCFlattenTimePicker, CCTextFieldDuo } from "../";
import { ReservationIcon, TimeIcon } from "../../themes/common/icons";

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.icon
  }
}));

const CCDateTimeFieldDuo = props => {
  const {
    inputClasses1 = {},
    inputClasses2 = {},
    date,
    time,
    handleDate,
    handleTime
  } = props;

  const classes = useStyles(props);

  const [dateAnchor, setDateAnchorEl] = useState(null);
  const [timeOpen, setTimeOpen] = useState(false);

  const dateIconClick = useCallback(e => {
    setDateAnchorEl(e.target);
  }, []);

  const timeIconClick = useCallback(() => {
    setTimeOpen(true);
  }, []);

  const dateOnChange = useCallback(
    e => {
      handleDate(e);
      setDateAnchorEl(null);
    },
    [handleDate]
  );

  const dateOnClose = useCallback(() => {
    setDateAnchorEl(null);
  }, []);

  const timeOnChange = useCallback(
    e => {
      handleTime(e);
      setTimeOpen(false);
    },
    [handleTime]
  );

  const timeOnClose = useCallback(() => {
    setTimeOpen(false);
  }, []);

  return (
    <>
      <CCTextFieldDuo
        LeftProps={{
          label: "전송날짜",
          onClickIcon: dateIconClick,
          endIcon: <ReservationIcon className={classes.button} />,
          value: moment(date).format("YYYY/MM/DD"),
          InputProps: {
            classes: inputClasses1
          }
        }}
        RightProps={{
          label: "시간",
          onClickIcon: timeIconClick,
          endIcon: <TimeIcon className={classes.button} />,
          value: moment(time).format("hh:mm A"),
          InputProps: {
            classes: inputClasses2
          }
        }}
        margin={"dense"}
        variant={"outlined"}
      />
      <CCFlattenDatePicker
        open={Boolean(dateAnchor)}
        value={date}
        onChange={dateOnChange}
        onClose={dateOnClose}
      />
      <CCFlattenTimePicker
        open={timeOpen}
        value={time}
        onChange={timeOnChange}
        onClose={timeOnClose}
        step={1}
      />
    </>
  );
};

export default CCDateTimeFieldDuo;
