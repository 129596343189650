import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { CCButton, CCDialogSimple, CCTypography } from 'styles/components';

const useStyles = makeStyles((theme) => ({
  root: {},
  warning___color: { color: theme.palette.error.main },
  desc___color: { color: theme.palette.sub.main },
  text: { marginTop: 8, width: 280 },
  disagree: { marginRight: 8 },
}));

const DialogResultBlank = (props) => {
  const { open, onClose } = props;
  const classes = useStyles();

  return (
    <CCDialogSimple
      open={open}
      isHeader={false}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <Box>
          <Box className={classes.text}>
            <CCTypography className={classes.warning___color}>
              결과 값이 없습니다.
            </CCTypography>
          </Box>
        </Box>
      }
      endActions={
        <CCButton
          className={classes.disagree}
          color='secondary'
          onClick={() => {
            onClose && onClose();
          }}
        >
          확인
        </CCButton>
      }
    />
  );
};

export default DialogResultBlank;
