import { Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List
} from "react-virtualized";
import { CCTypography } from "styles/components";
import translate from "../Translate";

const useStyles = makeStyles(theme => ({
  height: { height: "100%" },
  root: { height: "100%" },
  title: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    padding: 8,
    boxSizing: "border-box"
  },
  row: { display: "flex" },
  list: {
    "&:focus": {
      outline: "none"
    }
  },
  cell: { flex: 1, position: "relative" },
  border__row__end: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  border__cell__left: {
    // borderBottom: `1px solid ${theme.palette.border.main}`,
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  align__center: {
    textAlign: "center"
  },
  selected__row: {
    backgroundColor: `${theme.palette.select.main} !important`
  },
  hidden: {
    height: 0,
    overflow: "hidden"
  },
  fixed__table: {
    maxHeight: 250,
    overflow: "auto"
  },
  fixed__table__shadow: {
    position: "relative",
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24)",
    zIndex: 1
  },
  unfixed__table: {
    backgroundColor: props =>
      props.backgroundColor
        ? props.backgroundColor
        : theme.palette.background.default,
    position: "relative"
  },
  none__contnets: {
    position: "absolute",
    top: "45%",
    color: theme.palette.disabled,
    width: "100%",
    textAlign: "center"
  }
}));

const getColSpan = (name, contents, compare) => {
  var rowSpans = [];
  var start = -1;

  contents.forEach((el, index) => {
    if (index === 0) {
      start = 0;
      rowSpans[start] = 1;
    } else if (
      compare
        ? compare(contents[start][name], el[name], contents[start], el)
        : contents[start][name] === el[name]
    ) {
      rowSpans[start]++;
      rowSpans[index] = 0;
    } else {
      start = index;
      rowSpans[start] = 1;
    }
  });
  return rowSpans;
};

const getSpan = (heads, contents) => {
  var spans = {};

  Object.values(heads).forEach(head => {
    if (head.span)
      spans[head.key] = getColSpan(head.key, contents, head.compare);
  });

  return spans;
};

const getNextRowSpan = (head, rowIndex, lastIndex, spanInfo) => {
  const nextIndex = rowIndex + 1;
  if (nextIndex > lastIndex) {
    return true;
  }
  const span = head.span ? spanInfo[head.key][nextIndex] : 1;
  return Boolean(span);
};

const ChartRow = props => {
  const {
    index: rowIndex,
    heads,
    contents,
    spanInfo,
    disableBorder = true,
    type = "unfixed"
  } = props;
  const classes = useStyles();
  const rowData = contents[rowIndex];

  return (
    <>
      {heads.map((head, cellIndex) => {
        const span = head.span ? spanInfo[head.key][rowIndex] : 1;
        const nextRow = getNextRowSpan(
          head,
          rowIndex,
          contents.length - 1,
          spanInfo
        );
        const component = () => {
          if (type === "fixed" && head.fixedComponent)
            return head.fixedComponent({
              rowIndex: rowIndex,
              cellIndex: cellIndex,
              cellData: rowData[head.key],
              rowData: rowData,
              nextRow: nextRow
              // rowSpan: span
            });

          if (head.component)
            return head.component({
              rowIndex: rowIndex,
              cellIndex: cellIndex,
              cellData: rowData[head.key],
              rowData: rowData,
              nextRow: nextRow
              // rowSpan: span
            });

          return (
            <CCTypography
              style={{
                padding: 8,
                color: "inherit",
                textAlign: head.textAlign ? "center" : ""
              }}
            >
              {rowData[head.key]}
            </CCTypography>
          );
        };

        const style = (() => {
          const tmp = { ...head.bodyStyle };
          if (head.width) {
            tmp.width = head.width;
            tmp.minWidth = head.width;
            tmp.flex = "unset";
          }
          return tmp;
        })();

        return (
          <div
            key={cellIndex}
            className={clsx(
              {
                [classes.cell]: true,
                [classes.border__row__end]: !disableBorder && nextRow,
                [classes.border__cell__left]: !disableBorder && cellIndex
                // [classes.align__center]: head.textCenter
              },
              head.className && head.className({ rowData: rowData })
            )}
            // style={head.width && { width: head.width, flex: "unset" }}
            style={style}
          >
            <div
              className={clsx({
                [classes.hidden]: !span,
                // [classes.height]: span
              })}
            >
              {component()}
            </div>
          </div>
        );
      })}
    </>
  );
};

const labelHeight = 40;
const titleHeight = 33;

const InfiniteTable = props => {
  const theme = useTheme();
  const intl = useIntl();
  const lang = localStorage.getItem("lang")
  const {
    className,
    heads = [],
    contents = [],
    fixedContents = [],
    onMoreContents = () => { },
    activeOddColor = true,
    rowOddColor = (activeOddColor => {
      if (activeOddColor) return "white";
      return "none";
    })(activeOddColor),
    disableBorder = true,
    onRowClick = () => { },
    rowClickHighlight = false,
    hasNext = false,
    selected = () => { },
    scrollToIndex,
    cache,
    title,
    rowCount,
  } = props;
  const classes = useStyles(props);
  const spanInfo = getSpan(heads, contents);
  const fixedSpanInfo = getSpan(heads, fixedContents);
  const fixedTableRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [__cache] = useState(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true
    })
  );

  const cellMeasurerCache = cache ? cache : __cache;
  const __titleHeight = title ? titleHeight : 0;
  const renderRow = ({ index, key, style, parent, isScrolling }) => {
    // const height = 30 * ((index % 3) + 1);
    // const content = isScrolling ? "..." : "complete";

    if (rowOddColor && index % 2) {
      var background = rowOddColor;
    }
    // console.log(selected, contents[index]);

    const isSelected = selected(contents[index], "unfixed");

    return (
      <CellMeasurer
        key={key}
        cache={cellMeasurerCache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div
          style={{
            ...style,
            direction: lang === 'ar' ? 'rtl' : 'ltr',
            background:
              rowClickHighlight && isSelected
                ? // selected?.type === "unfixed" &&
                // // selected?.uuid === contents[index].uuid &&
                // // selected?.id === contents[index].id &&
                // selected?.index === index
                theme.palette.select.main
                : background
          }}
          className={clsx({
            [classes.row]: true
          })}
          onClick={(e) => {
            onRowClick({
              rowData: contents[index],
              type: "unfixed",
              rowIndex: index,
              e
            });
          }}
        >
          <ChartRow
            index={index}
            heads={heads}
            contents={contents}
            spanInfo={spanInfo}
            disableBorder={disableBorder}
          />
        </div>
      </CellMeasurer>
    );
  };

  const renderFixedRow = ({ index, key }) => {
    if (rowOddColor && index % 2) {
      var background = rowOddColor;
    }

    const isSelected = selected(fixedContents[index], "fixed");

    return (
      <div
        key={key}
        style={{
          background:
            rowClickHighlight && isSelected
              ? // selected?.type === "fixed" &&
              // selected?.uuid === fixedContents[index].uuid
              theme.palette.select.main
              : background
        }}
        className={clsx({
          [classes.row]: true
        })}
        onClick={() => {
          onRowClick({
            rowData: fixedContents[index],
            type: "fixed",
            rowIndex: index
          });
        }}
      >
        <ChartRow
          index={index}
          heads={heads}
          contents={fixedContents}
          spanInfo={fixedSpanInfo}
          disableBorder={disableBorder}
          type="fixed"
        />
      </div>
    );
  };

  useEffect(() => {
    setHeight(fixedTableRef.current.offsetHeight);
  }, [fixedContents]);

  // useMemo(() => {
  //   const cacheClear = (cellMeasurerCache, startIndex, length) => {
  //     console.log(startIndex, length);
  //     for (var i = startIndex; i < length; i++) {
  //       cellMeasurerCache.clear(i, 0);
  //     }
  //   };

  //   var tmp = -1;
  //   for (var i = 0; i < contents.length; i++) {
  //     if (i > lastContentRef.current.length - 1) {
  //       break;
  //     }

  //     if (lastContentRef.current[i].id !== contents[i].id) {
  //       tmp = i;
  //       break;
  //     } else if (
  //       lastContentRef.current[i].customContent.lastModifiedTime !==
  //       contents[i].customContent.lastModifiedTime
  //     ) {
  //       console.log(contents[i]);
  //       tmp = i;
  //       break;
  //     }
  //   }

  //   if (tmp !== -1) {
  //     cacheClear(cellMeasurerCache, tmp, contents.length);
  //   }

  //   lastContentRef.current = contents;
  // }, [contents, cellMeasurerCache]);
  const [scrollTop, setScrollTop] = useState(-1);
  useEffect(() => {
    setScrollTop(-1);
  }, [scrollToIndex]);

  return (
    <Box className={clsx(classes.root, className)}>
      {!!title && (
        <div className={classes.title} style={{ height: titleHeight }}>
          <CCTypography variant="h5">{title}</CCTypography>
        </div>
      )}
      <div style={{ height: labelHeight }} className={classes.row}>
        {heads.map((head, cellIndex) => {
          const component = () => {
            if (head.headComponent)
              return head.headComponent({
                cellIndex: cellIndex,
                cellData: head.label
              });
            return (
              <CCTypography
                style={{
                  textAlign: "center",
                  lineHeight: `${labelHeight}px`,
                  color: "inherit"
                }}
              // variant="h6"
              >
                {head.label}
              </CCTypography>
            );
          };
          return (
            <div
              key={cellIndex}
              className={clsx({
                [classes.cell]: true,
                [classes.border__row__end]: true
              })}
              style={head.width && { width: head.width, flex: "unset", minWidth: head.width }}
            >
              {component()}
            </div>
          );
        })}
      </div>
      {/* fixed Table */}
      <div
        className={clsx({
          [classes.fixed__table]: true,
          [classes.fixed__table__shadow]: fixedContents.length
        })}
        ref={fixedTableRef}
      >
        {fixedContents.map((item, rowIndex) => {
          return renderFixedRow({ index: rowIndex, key: rowIndex });
        })}
      </div>

      <div
        style={{
          height: `calc(100% - ${labelHeight + __titleHeight + height}px)`
        }}
        className={classes.unfixed__table}
      >
        <InfiniteLoader
          isRowLoaded={({ index }) => {
            console.log("isRowLoaded index", index)
            return (!!contents[index])
          }}
          loadMoreRows={onMoreContents}
          rowCount={rowCount || contents.length}
        >
          {({ onRowsRendered, registerChild }) => {
            return (
              <AutoSizer>
                {({ width, height }) => {
                  return (
                    <List
                      className={classes.list}
                      width={width}
                      height={height}
                      onRowsRendered={onRowsRendered}
                      ref={registerChild}
                      rowRenderer={renderRow}
                      rowCount={rowCount || contents.length}
                      deferredMeasurementCache={cellMeasurerCache}
                      rowHeight={cellMeasurerCache.rowHeight}
                      overscanRowCount={50}
                      scrollToIndex={scrollToIndex}
                      onScroll={args => {
                        const { scrollTop } = args;
                        setScrollTop(scrollTop);
                      }}
                      scrollTop={scrollTop}
                      style={{
                        direction: lang === 'ar' ? 'rtl' : 'ltr',
                      }}
                    />
                  );
                }}
              </AutoSizer>
            );
          }}
        </InfiniteLoader>
        {!contents.length && (
          <div className={classes.none__contnets}>
            {translate(intl, "common.ui.noResultsFound")}
          </div>
        )}
      </div>
    </Box>
  );
};

export default InfiniteTable;
