import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { CCButton, CCDialogSimple, CCTypography } from "../";
import { useIntl } from "../../../locales/language";

const useStyles = makeStyles(theme => ({
  root: {},
  warning___color: { color: theme.palette.error.main },
  desc___color: { color: theme.palette.sub.main },
  text: { marginTop: 8, width: 280 },
  disagree: { marginRight: 8 }
}));

const CCDialogInputError = props => {
  const { open, onClose, title, subTitle } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <CCDialogSimple
      open={open}
      isHeader={false}
      onKeyDown={e => {
        e.preventDefault();
        if (e.key === "Enter" || e.key === "Escape") onClose();
      }}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <Box>
          <Box className={classes.text}>
            <CCTypography className={classes.warning___color}>
              {title ? title : intl.formatMessage({ id: "ccInputError" })}
            </CCTypography>
            {subTitle && (
              <CCTypography className={classes.desc___color}>
                {subTitle}
              </CCTypography>
            )}
          </Box>
        </Box>
      }
      endActions={
        <CCButton
          className={classes.disagree}
          color="secondary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          {intl.formatMessage({ id: "ccCheck" })}
        </CCButton>
      }
    />
  );
};

export default CCDialogInputError;
