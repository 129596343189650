import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/styles";
import { Chips, CVDialogSimple, translate } from "components";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCTypography } from "styles/components";
const GET_VERSION = gql`
  query getVersion {
    getVersion {
      date
      version
      commit
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {},
  title: { color: theme.palette.error.main },
  contents: { marginTop: 16 },
  text: { marginTop: 8, minWidth: 280, paddingRight: 40 },
  disagree: { marginRight: 8 },
  loading: { textAlign: "center" },
  chips: {
    color: "white",
    background: theme.palette.sub.main,
    padding: `4px 8px`,
    marginBottom: 12
  },
  clever: { marginBottom: 24, color: theme.palette.error.main },
  daemon: { marginBottom: 24, color: theme.palette.secondary.main },
  version: { color: "inherit" }
}));

const Version = props => {
  const { className, title = "", version = "", date = "", hash = "" } = props;
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={className}>
      <Chips className={classes.chips}>{title}</Chips>
      <CCTypography className={classes.version}>
        {translate(intl, "common.ui.version")}: {version}
      </CCTypography>
      <CCTypography className={classes.version}>
        {translate(intl, "labManageGold.date")}: {moment(date, "X").format("YYYY.MM.DD HH:mm:ss")}
      </CCTypography>
      <CCTypography className={classes.version}>Hash: {hash}</CCTypography>
    </div>
  );
};

const DialogVersionInfo = props => {
  const { open, onClose = () => { } } = props;
  const classes = useStyles();
  const [clever, setClever] = useState({});
  const [graphql, setGraphql] = useState({});
  const intl = useIntl();
  useQuery(GET_VERSION, {
    onCompleted: res => {
      setGraphql(res.getVersion);
    }
  });

  useEffect(() => {
    fetch("/version.json")
      .then(res => res.json())
      .then(meta => {
        setClever({
          version: meta.version,
          date: process.env.REACT_APP_DEPLOY_DATE,
          commit: meta.commit
        });
      });
  }, []);

  return (
    <CVDialogSimple
      open={open}
      title={translate(intl, "common.ui.versionInfo")}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <>
          <Version
            className={classes.clever}
            title="Clever Lab"
            version={clever.version}
            date={clever.date}
            hash={clever.commit}
          />
          <Version
            className={classes.graphql}
            title="Graphql"
            version={graphql.version}
            date={graphql.date}
            hash={graphql.commit}
          />
        </>
      }
      endActions={
        <CCButton
          className={classes.disagree}
          color="sub"
          onClick={() => {
            onClose();
          }}
        >
          {translate(intl, "common.ui.close")}
        </CCButton>
      }
    />
  );
};

export default DialogVersionInfo;
