import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { getObject } from "utils/s3";
import { Card } from "../../../../../LabInformation/components/DialogProsthesisImage/components";

const Samples = props => {
  const { samples } = props;
  // const [urls, setUrls] = useState([]);

  useEffect(() => {
    samples.forEach(sample => {
      getObject(sample?.target, localStorage.getItem("labId"), sample?.id).then(
        res => {
          // urls.push(res);
        }
      );
    });
  }, [props, samples]);

  return (
    <Grid container>
      {samples.map(sample => (
        <Card
          key={sample.id}
          type={sample.type}
          target={sample.target}
          id={sample.id}
        />
      ))}
    </Grid>
  );
};

Samples.propTypes = {};

export default Samples;
