import { useLazyQuery } from "@apollo/react-hooks";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import FileGrid from "components/Explorer/Component/FilePhoto/component/FileGrid";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import CCTreeExp from "styles/src/components/CCTreeExp";
import {
  findHistory,
  openFolder
} from "styles/src/components/CCTreeExp/CCTreeExp";
import { getDefaultFolders } from "utils/photo";

const useStyle = makeStyles(theme => ({
  root: {
    minWidth: 256,
    width: 256,
    borderRight: `1px solid ${theme.palette.border.main}`
  }
}));

const SEARCH_PHOTO = gql`
  query searchPhoto($hospitalId: String!, $args: InputSearchPhoto) {
    searchPhoto(hospitalId: $hospitalId, args: $args) {
      id
      date
      type
      patient
      content {
        folder
        filetype
        filename
        filenode
        object
        source
        thumbnail
        tags
      }
    }
  }
`;

const DEFAULT_TREE = [
  {
    id: "__libraries__",
    name: "라이브러리",
    state: false,
    item: {
      id: "__libraries__",
      date: moment().unix(),
      type: "library",
      patient: "__libraries__",
      content: {
        folder: null,
        filetype: "folder",
        filename: "라이브러리",
        filenode: "root",
        object: null,
        source: null,
        thumbnail: null,
        tags: null
      }
    },
    items: []
  },
  {
    id: "__consult__",
    name: "클래버 컨설트",
    state: false,
    item: {
      id: "__consult__",
      date: moment().unix(),
      type: "consult",
      patient: "__consult__",
      content: {
        folder: null,
        filetype: "folder",
        filename: "클래버 컨설트",
        filenode: "root",
        object: null,
        source: null,
        thumbnail: null,
        tags: null
      }
    },
    items: []
  }
];

const getDefaultPatientRoot = patient => ({
  id: "__photo__",
  name: patient.value.name,
  state: false,
  item: {
    id: patient.id,
    date: moment().unix(),
    type: "photo",
    patient: patient.id,
    content: {
      folder: null,
      filetype: "folder",
      filename: patient.value.name,
      filenode: "root",
      fileattr: "readonly",
      object: null,
      source: null,
      thumbnail: null,
      tags: null
    }
  },
  items: []
});

const reducer = (state, action) => {
  let _patient;
  let _index = -1;
  let _tree;
  switch (action.type) {
    case "change":
    case "state":
      return { ...state, tree: [...action.target] };
    case "selected":
      return {
        ...state,
        history: action.target
      };
    case "setPatient":
      _patient = { ...action.target };
      _index = state.tree.findIndex(
        item => item?.item?.content?.patient === _patient.id
      );

      _tree = JSON.parse(JSON.stringify(state.tree));
      if (_index > -1) {
        _tree.splice(_index, 1, getDefaultPatientRoot(_patient));
      } else {
        _tree.unshift(getDefaultPatientRoot(_patient));
      }
      return { ...state, tree: [..._tree] };
    case "removePatient":
      console.log(state.tree);
      _index = state.tree.findIndex(item => item?.item?.type === "photo");
      if (_index > -1) {
        _tree = JSON.parse(JSON.stringify(state.tree));
        _tree.splice(_index, 1);
        console.log(_tree);
        return { ...state, tree: [..._tree] };
      }
      return state;
    case "openTree":
      let _openTarget = findHistory(state.tree, action.target);
      if (_openTarget) {
        // console.log(openFolder(state.tree, _openTarget));
        return {
          ...state,
          history: _openTarget,
          tree: openFolder(state.tree, _openTarget)
        };
      }
      return state;
    default:
      return state;
  }
};
const buildTree = (trees, target = null, patient = null) => {
  // console.log(target, trees);
  let _temp = trees.filter(item => item.content.folder === target);
  return _temp.reduce((acc, cur) => {
    let _fileName = cur.content.filename;
    if (cur.type === "consult" && cur.content.folder === null) {
      _fileName = "클래버 컨설트";
    }
    if (cur.type === "library" && cur.content.folder === null) {
      _fileName = "라이브러리";
    }
    if (cur.type === "photo" && cur.content.folder === null) {
      _fileName = patient?.value?.name || "/";
    }
    acc.push({
      id: cur.content.filenode,
      name: _fileName || cur.content.filename,
      state: false,
      item: {
        ...cur
      },
      items: [...buildTree(trees, cur.content.filenode)]
    });
    return acc;
  }, []);
};

const makeTree = (trees, patient = null) => {
  let _temp = trees.reduce((acc, cur) => {
    if (cur.content.filetype === "folder") {
      acc.push(cur);
    }
    return acc;
  }, []);
  return buildTree(_temp, null, patient);
};

const FileTree = props => {
  const { patient, onChange, onClose, onFileSelect } = props;
  const classes = useStyle();
  const [{ tree, history }, dispatchState] = useReducer(reducer, {
    history: [],
    tree: [...DEFAULT_TREE]
  });
  const [selected, setSelected] = useState(null);
  // const selected = useMemo(() => getItems(tree, history), [tree, history]);
  const [getFolder, { variables }] = useLazyQuery(SEARCH_PHOTO, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      let _index;
      let _temp;
      switch (variables.args.type) {
        case "library":
          _index = tree.findIndex(
            item => item.item.patient === "__libraries__"
          );
          _temp = [...tree];
          _temp[_index] = makeTree([
            ...getDefaultFolders(variables.args.type),
            ...data.searchPhoto
          ])[0];
          _temp[_index].state = true;
          dispatchState({ type: "change", target: _temp });
          break;
        case "photo":
          _index = tree.findIndex(item => item.item.patient === patient.id);
          _temp = [...tree];
          _temp[_index] = makeTree(
            [
              ...getDefaultFolders(variables.args.type, variables.args.patient),
              ...data.searchPhoto
            ],
            { ...patient }
          )[0];
          _temp[_index].state = true;
          dispatchState({ type: "change", target: _temp });
          break;
        default:
          break;
      }
    }
  });
  // const [getConsultFolder, { variables: consultVariables }] = useLazyQuery(
  //   SEARCH_PHOTO_NOAUTH,
  //   {
  //     fetchPolicy: "network-only",
  //     onCompleted: data => {
  //       let _index = tree.findIndex(
  //         item => item.item.patient === "__consult__"
  //       );
  //       let _temp = [...tree];
  //       _temp[_index] = makeTree([
  //         ...getDefaultFolders(consultVariables.args.type),
  //         ...data.searchPhotoNoAuth
  //       ])[0];
  //       _temp[_index].state = true;
  //       // console.log(_temp);
  //       dispatchState({ type: "change", target: _temp });
  //       // makeTree(data.searchPhoto);
  //     }
  //   }
  // );
  useEffect(() => {
    console.log("patient", patient);
    if (Boolean(patient?.id)) {
      dispatchState({ type: "setPatient", target: { ...patient } });
    } else {
      dispatchState({ type: "removePatient" });
    }
  }, [patient]);
  // console.log(tree);
  return (
    <>
      <Grid item className={classes.root}>
        <CCTreeExp
          openToFolder
          disabledSearch
          selected={history}
          totalDepth={999}
          title={"파일 탐색기"}
          treeData={tree}
          disabledToolbar
          onClickDir={(_target, history) => {
            // console.log(history, _target);

            switch (_target.id) {
              case "__libraries__":
                if (!_target.state) {
                  getFolder({
                    variables: {
                      hospitalId: localStorage.getItem("labId"),
                      args: {
                        type: _target.item.type,
                        patient: _target.item.patient,
                        content: {
                          filetype: "folder"
                        }
                      }
                    }
                  });
                  setSelected({ ..._target.item });
                }
                break;
              case "__consult__":
                if (!_target.state) {
                  // getConsultFolder({
                  //   variables: {
                  //     hospitalId: null,
                  //     args: {
                  //       type: _target.item.type,
                  //       patient: null,
                  //       content: {
                  //         filetype: "folder"
                  //       }
                  //     }
                  //   }
                  // });
                  setSelected({ ..._target.item });
                }
                break;
              case "__photo__":
                if (!_target.state) {
                  getFolder({
                    variables: {
                      hospitalId: localStorage.getItem("labId"),
                      args: {
                        type: "photo",
                        patient: _target.item.patient,
                        content: {
                          filetype: "folder"
                        }
                      }
                    }
                  });
                  setSelected({ ..._target.item });
                }
                break;
              default:
                setSelected({ ..._target.item });
                break;
            }
            onChange && onChange(_target);
            dispatchState({ type: "selected", target: history });
          }}
          onChange={newContents => {
            dispatchState({
              type: newContents.type,
              target: newContents.item
            });
          }}
        />
      </Grid>
      <FileGrid
        patient={patient}
        selected={selected}
        onClose={onClose}
        onFileSelect={onFileSelect}
        onClickItem={e => {
          // console.log(e);
          setSelected(e);
          dispatchState({ type: "openTree", target: e.content.filenode });
        }}
      />
    </>
  );
};

export default FileTree;
