import { ApolloProvider as Provider } from "@apollo/react-hooks";
import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient, from } from "@apollo/client";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import React, { useMemo, useContext, useState } from "react";
import { AppContext } from "context/AppContext";
import { onError } from "@apollo/client/link/error";
import { DialogWarningMessage } from "components";
import { useIntl } from "react-intl";
import { defaultSettings } from "utils/auth";

const ApolloProvider = props => {
  const { appContext, dispatchApp } = useContext(AppContext);
  const token = appContext.token;
  const [aError, setAError] = useState(false)
  const intl = useIntl();
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        )
        if (
          extensions?.code === "AUTH01"
        ) {
          setAError(true);
        }
      });
  });
  const client = useMemo(() => {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ""
        }
      };
    });
    const link = authLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      authLink.concat(
        new WebSocketLink({
          uri: process.env.REACT_APP_CF_GRAPHQL_SOCKET,
          options: {
            reconnect: true,
            connectionParams: {
              Authorization: token ? `Bearer ${token}` : ""
            },
            lazy: true
          }
        })
      ),
      authLink.concat(
        createUploadLink({
          uri: process.env.REACT_APP_CF_GRAPHQL_URL,
          headers: {
            "keep-alive": "true"
          }
        })
      )
    );

    return new ApolloClient({
      connectToDevTools: true,
      cache: new InMemoryCache({
        addTypename: false
      }),
      link: from([errorLink, link]),
      defaultOptions: {
        query: {
          fetchPolicy: "network-only"
        },
        watchQuery: {
          fetchPolicy: "network-only"
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <>
    <Provider client={client}>{props.children}</Provider>
    {aError && <DialogWarningMessage
      title={
        intl.formatMessage({
          id: "loginFailed"
        })
      }
      subTitle={
        intl.formatMessage({
          id: "loginFailedSub"
        })
      }
      open={aError}
      onAgree={() => {
        console.log('checked')
        setAError(false);
      }
      }
      onRefresh={() => {
        setAError(false);
        const id_token_hint = appContext.token.split("/")[1];
        document.location.href =
          process.env.REACT_APP_CF_AUTH_URL +
          "/session/end?id_token_hint=" +
          id_token_hint +
          "&post_logout_redirect_uri=" +
          defaultSettings.post_logout_uri;

        console.log('document.location.href', document.location.href)
      }}
      authError={aError}
      minWidth={400}
    />}
  </>;
};

export default ApolloProvider;
