import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "../../../locales/language";

const useStyles = makeStyles(
  theme => ({
    root: {
      fontSize: 14,
      height: 30,
      backgroundColor: "unset"
    },
    toggle__button: {
      backgroundColor: "white",
      color: theme.palette.common.black,
      padding: "4px 8px 5px 8px ",
      height: 28,
      borderRadius: "4px !important ",
      boxShadow: "0 2px 1px 0 rgba(0, 0, 0, 0.24)",
      border: `solid 1px ${theme.palette.normal.hover} !important`,
      "&.Mui-selected": {
        color: "black",
        backgroundColor: "unset"
      }
    },
    mid: {
      margin: "0 8px !important"
    }
  }),
  { name: "CCSelectTab" }
);

const CCSelectTab = props => {
  const intl = useIntl();
  const {
    selected,
    onClickMonth,
    onClickWeek,
    onClickDay,
    className,
    first = intl.formatMessage({ id: "ccNextDay" }),
    second = intl.formatMessage({ id: "ccNextWeek" }),
    third = intl.formatMessage({ id: "ccNextMonth" }),
    ...other
  } = props;

  const [dateType, setDateType] = useState("");

  const handleAlignment = (event, newDateType) => {
    setDateType(newDateType);
  };

  const classes = useStyles();

  useEffect(() => {
    if (selected) {
      setDateType(selected);
    }
  }, [selected]);

  return (
    <ToggleButtonGroup
      className={clsx(className, classes.root)}
      size={"small"}
      value={dateType}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      {...other}
    >
      <ToggleButton
        className={clsx(classes.toggle__button)}
        value={first}
        onClick={onClickDay}
      >
        {first}
      </ToggleButton>
      <ToggleButton
        className={clsx(classes.toggle__button, classes.mid)}
        value={second}
        onClick={onClickWeek}
      >
        {second}
      </ToggleButton>
      <ToggleButton
        className={clsx(classes.toggle__button)}
        value={third}
        onClick={onClickMonth}
      >
        {third}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

CCSelectTab.propTypes = {
  /** month, week, day 중 택 1 */
  onClickMonth: PropTypes.func,
  onClickWeek: PropTypes.func,
  onClickDay: PropTypes.func
};

CCSelectTab.defaultProps = {
  /** default : day */
  selected: "내일"
};

export default CCSelectTab;
