import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import FileTree from "../FileTree";

const useStyle = makeStyles(theme => ({
  root: {
    height: props => {
      if (props?.collapse && props?.collapseRef?.current) {
        return `calc(100% - ${props?.collapseRef?.current.offsetHeight}px)`;
      } else {
        return `calc(100%)`;
      }
    }
  },
  tree: {
    minWidth: 256,
    width: 256,
    borderRight: `1px solid ${theme.palette.border.main}`
  }
}));

const FileList = props => {
  const { collapseRef, collapse } = props;
  const classes = useStyle({ collapseRef: collapseRef, collapse: collapse });
  return (
    <Grid container wrap={"nowrap"} className={classes.root}>
      {/*<Grid item className={classes.tree}>*/}
      <FileTree {...props} />
      {/*</Grid>*/}
      {/*<FileGrid*/}
      {/*  patient={patient}*/}
      {/*  selected={selected}*/}
      {/*  onClickItem={e => {*/}
      {/*    setClickedItem(e);*/}
      {/*  }}*/}
      {/*/>*/}
    </Grid>
  );
};
export default FileList;
