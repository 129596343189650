import { useQuery } from "@apollo/react-hooks";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { PermissionBackdrop, useReadPermission } from "components";
import {
  GET_MESSAGE_LIST,
  GET_STAFF_INFO,
  ON_MESSAGE_RECEIVED
} from "queries/labOrder";
import React, { useEffect } from "react";
import { ChatEditor, MessageView, MessengerHeader } from "./components";

const useStyles = makeStyles(theme => ({
  root: { height: "inherit", width: 525 },
  note__contents: {
    position: "relative",
    height: 599,
    flexDirection: "column"
  },
  nodata: {
    height: 523,
    flex: 1
  }
}));

const LIMIT = 20;
const NETWORK_STATUS_FETCH_MORE = 3;

const ViewNoteLabOrder = props => {
  const { value } = props;
  const { id } = value;

  const classes = useStyles();
  const readPermission = useReadPermission("messenger");

  const { data: staffInfo, loading: staffLoading } = useQuery(GET_STAFF_INFO);
  const {
    data,
    loading,
    error,
    subscribeToMore,
    fetchMore: fetchMoreMessages,
    networkStatus
  } = useQuery(GET_MESSAGE_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        chatGroupId: id,
        limit: LIMIT
      }
    }
  });

  const handleFetchMoreUp = () => {
    fetchMoreMessages({
      variables: {
        input: {
          limit: LIMIT,
          operator: "BEFORE",
          chatGroupId: id,
          cursor: data.messages.items[data.messages.items.length - 1].id
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const fetchedMessageList = fetchMoreResult.messages;
        let cacheMessageList = prev.messages;
        const items = [...cacheMessageList.items, ...fetchedMessageList.items];
        const hasNext = fetchedMessageList.hasNext;
        const hasPrev = fetchedMessageList.hasPrev;
        const operator = fetchedMessageList.operator;

        return {
          messages: {
            ...cacheMessageList,
            items,
            hasNext,
            hasPrev,
            operator
          }
        };
      }
    });
  };
  const handleFetchMoreDown = () => { };

  useEffect(() => {
    subscribeToMore({
      document: ON_MESSAGE_RECEIVED,
      variables: {
        input: {
          chatGroupId: id
        }
      },
      shouldResubscribe: true,
      updateQuery: (prev, { subscriptionData }) => {
        let newMessage = subscriptionData.data.onMessageReceived;

        return Object.assign({}, prev, {
          messages: {
            ...prev.messages,
            items: [newMessage, ...prev.messages.items]
          }
        });
      }
    });
  }, [id, subscribeToMore]);

  if (error) return <div>Error</div>;

  return (
    <Box className={clsx(classes.root)}>
      <MessengerHeader chatGroupId={id} />
      {
        <Grid
          container
          className={classes.note__contents}
          alignItems={"center"}
          justify={"center"}
        >
          {!readPermission ? (
            <PermissionBackdrop permission={"messenger"} />
          ) : (
            <>
              {staffLoading ? (
                <Box className={classes.nodata}>Loading...</Box>
              ) : data && data.messages.items.length > 0 ? (
                <MessageView
                  labId={staffInfo?.me?.lab?.labId}
                  me={staffInfo?.me}
                  items={data.messages.items}
                  hasNext={data.messages.hasNext}
                  hasPrev={data.messages.hasPrev}
                  operator={data.messages.operator}
                  handleFetchMore={handleFetchMoreUp}
                  handleFetchMoreDown={handleFetchMoreDown}
                  loadMore={networkStatus === NETWORK_STATUS_FETCH_MORE}
                />
              ) : (
                <Box className={classes.nodata}>No messages</Box>
              )}
              <ChatEditor chatGroupId={id} labId={staffInfo?.me?.lab?.labId} />
            </>
          )}
        </Grid>
      }
    </Box>
  );
};

export default ViewNoteLabOrder;
