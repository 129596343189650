import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { CCButton, CCIconButton } from "styles/components";
import { ExcelIcon } from "styles/icons";
import { DialogWarningMessage } from "components";

const useStyles = makeStyles(theme => ({
  icon__export: {
    color: `${theme.palette.primary.main} !important`,
    margin: 0,
    padding: 0
  }
}));

const download = (url, name) => {
  let a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
};

/* generate a download */
function s2ab(s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

const exportExcel = (data, fileName) => {
  if (data && data.length === 0) {
    alert(`${fileName}이 존재하지 않습니다.`);
    return;
  }

  let url = window.URL.createObjectURL(
    new Blob([s2ab(data)], { type: "application/octet-stream" })
  );

  download(url, `${fileName}.xlsx`);
};

const ExcelDownloadButton = props => {
  const {
    exportData,
    exportFileName,
    type = "button",
    className,
    onClick,
    ...others
  } = props;

  const classes = useStyles();
  const [openWarning, setOpenWarning] = useState(false);

  switch (type) {
    case "button":
      return (
        <>
          <CCButton
            className={className}
            onClick={() => {
              if (exportData) {
                exportExcel(exportData, exportFileName);
              } else {
                setOpenWarning(true);
              }
              onClick && onClick();
            }}
            {...others}
          />
          <DialogWarningMessage
            title={"No Input Data"}
            open={openWarning}
            onClose={() => {
              setOpenWarning(false);
            }}
            onAgree={() => {
              setOpenWarning(false);
            }}
          />
        </>
      );
    case "icon":
      return (
        <>
          <CCIconButton
            className={clsx(className, classes.icon__export)}
            onClick={() => {
              if (exportData) {
                exportExcel(exportData, exportFileName);
              } else {
                setOpenWarning(true);
              }
              onClick && onClick();
            }}
            {...others}
          >
            <ExcelIcon />
          </CCIconButton>
          <DialogWarningMessage
            title={"No Input Data"}
            open={openWarning}
            onClose={() => {
              setOpenWarning(false);
            }}
            onAgree={() => {
              setOpenWarning(false);
            }}
          />
        </>
      );
    default:
      break;
  }
};

ExcelDownloadButton.defaultProps = {
  data: []
};

ExcelDownloadButton.propTypes = {};

export default ExcelDownloadButton;
