import { makeStyles, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { CCTypography } from "styles/src/components";

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: 0
  },
  tooltip_box: {
    borderRadius: "8px",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.sub.main
  },
  elevation: { zIndex: 1300 }
}));

function capitalize(str) {
  return Boolean(str) ? str.charAt(0).toUpperCase() + str.slice(1) : "";
}

const ErrorTooltip = props => {
  const {
    open = false,
    errorMsg,
    onClose,
    placement = "bottom-start",
    severity = "error",
    color = "error",
    popperOptions = {},
    ...others
  } = props;
  const classes = useStyles();
  const [openState, setOpenState] = useState(open);
  useEffect(() => {
    setOpenState(open);
  }, [open]);

  return (
    <Tooltip
      open={Boolean(openState)}
      classes={{ tooltip: classes.tooltip || classes.tooltip_box }}
      PopperProps={{
        className: classes.elevation,
        popperOptions: popperOptions
      }}
      title={
        <>
          <Alert severity={severity} variant={"outlined"}>
            <CCTypography variant={"body1"} color={color}>
              {capitalize(
                errorMsg?.toLowerCase ? errorMsg.toLowerCase() : errorMsg
              )}
            </CCTypography>
          </Alert>
        </>
      }
      placement={placement}
      {...others}
    >
      {props.children}
    </Tooltip>
  );
};

export default ErrorTooltip;
