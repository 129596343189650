import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Box,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { CVButton } from "components";
import { enqueueToast } from "components/Toast";
import { useSnackbar } from "notistack";
import { GET_LAB_SETTING, UPLOAD_LAB } from "queries/setting";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import CCButton from "styles/src/components/CCButton";
import { CCDialogInputError } from "../../../../../styles/src/components";
import Basic from "./components/Basic/Basic";
import Details from "./components/Details/Details";
import ChiefDirector from "./components/ChiefDirector/ChiefDirector";
import DialogPreview from "./components/DialogPreview/DialogPreview";
import { translate } from "components";

const useStyle = makeStyles(
  theme => ({
    root: {
      position: "relative",
      width: 870,
      border: "1px solid #b0bec5",
      borderRadius: "4px",
      padding: 8,
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    help__button: {
      color: theme.palette.icon
    },
    content: {
      overflowY: "hidden"
    },
    subText: {
      color: theme.palette.sub.main,
      paddingLeft: 5
    },
    input: {
      width: 268
    },
    header: {
      fontSize: 20,
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "bold",
      marginBottom: 8
    },
    line: {
      background: "#b0bec5",
      width: " 100%",
      height: 2,
      position: "absolute",
      bottom: 0,
      zIndex: -1
    },
    positionrelative: {
      position: "relative"
    },
    endaction: {
      justifyContent: "space-between",
      padding: "0px 16px"
    },
    leftcol: {
      alignItems: "center"
    },
    note: {
      color: "#b0bec5",
      paddingLeft: 16
    }
  }),
  { name: "SMTPSetting" }
);

const useTabsStyles = makeStyles(theme => ({
  root: {
    minHeight: 24,
    position: "relative"
  },

  scrollButtons: { width: 30 },
  indicator: { color: "#01579b" }
}));

const useTabStyles = makeStyles(theme => ({
  root: {
    // minWidth: 50,
    // minHeight: 24,
    // margin: '6px 4px',
    // padding: 0,
    // borderRadius: 25,
    // opacity: 1,
    // flex: 1,
    fontWeight: "bold",
    textTransform: "none"
  }
}));

const validateSignUp = error => {
  if (error?.labName) {
    return "common.ui.fieldRequired";
  }
  if (error?.managerName) {
    return "common.ui.fieldRequired";
  }
  if (error?.address) {
    return "common.ui.fieldRequired";
  }
  if (error?.email) {
    return "signUp.error.email";
  }
  if (error?.labTelNumber) {
    return "signUp.error.tellPhoneNumber";
  }
  if (error?.managerCellNumber) {
    return "signUp.error.cellPhoneNumber";
  }
  if (error?.bankAccountNumber) {
    return "common.ui.fieldRequired";
  }
  if (error?.bankAccountHolder) {
    return "common.ui.fieldRequired";
  }

  if (error?.bankName) {
    return "common.ui.fieldRequired";
  }

  return null;
};

const LabInformation = props => {
  const classes = useStyle();
  const intl = useIntl();
  const tabsClasses = useTabsStyles();
  const tabClasses = useTabStyles();
  const { enqueueSnackbar } = useSnackbar();

  // value
  const [inputErr, setInputErr] = useState(null);
  const [value, setValue] = useState(0);
  const [state, setState] = useState(null);
  const [openDialogPreview, setOpenDialogPreview] = useState(false);
  const [error, setError] = useState({})
  // graphql
  const { data, loading, refetch } = useQuery(GET_LAB_SETTING, {
    onCompleted: e => {
      setState(e?.labDetail);
    }
  });
  const [updateLab] = useMutation(UPLOAD_LAB);

  // function
  const onError = useCallback((key, value) => {
    setError(prev => ({ ...prev, [key]: value }))
  }, []);
  const onChange = (key, val) => {
    setState(preState => ({ ...preState, [key]: val }));
  };
  const handleReset = () => {
    setState(data?.labDetail);

  };
  const handleUpdateLab = async () => {
    if (validateSignUp(error)) {
      setInputErr(validateSignUp(error));
      return;
    }
    const updateLabResult = (await updateLab({
      variables: {
        input: {
          ...state,
          representative: [state.representative.find(item => item.isChiefDirector)?.id]
        }
      }
    }))
      .data;
    if (updateLabResult.updateLab && updateLabResult.updateLab.code === 200) {
      enqueueToast(enqueueSnackbar, translate(intl, "common.ui.saved"));
    } else {
      enqueueToast(enqueueSnackbar, translate(intl, "common.ui.notSaved"));
    }
  };

  return (
    <Box className={classes.root}>
      {/* <ApplicationForm /> */}
      <Grid container direction="column">
        <Typography className={classes.header}>
          {translate(intl, "settings.lab.labInformation")}
        </Typography>
        <Box className={classes.positionrelative}>
          <Tabs
            classes={tabsClasses}
            variant="scrollable"
            scrollButtons="auto"
            textColor="secondary"
            value={value}
            onChange={(e, v) => {
              setValue(v);
            }}
          >
            <Tab
              classes={tabClasses}
              label={translate(intl, "settings.lab.basic")}
            />

            <Tab
              classes={tabClasses}
              label={intl.formatMessage({
                id: "settings.lab.cleverLabDetails"
              })}
            />

            <Tab
              classes={tabClasses}
              label={intl.formatMessage({
                id: "settings.lab.chiefDirector"
              })}
            />
          </Tabs>
          <div className={classes.line} />
        </Box>
        {!loading && state && (
          <Box>
            {value === 1 ? (
              <Details onChange={onChange} value={state} refetch={refetch} />
            ) : value === 0 ? (
              <Basic
                onChange={onChange}
                value={state}
                onError={onError}
              />
            ) :
              <ChiefDirector value={state.representative} onChange={onChange} />
            }
          </Box>
        )}
      </Grid>

      <Box>
        <Grid container className={classes.endaction}>
          <Grid item>
            <Grid container className={classes.leftcol}>
              <CCButton
                variant={"contained"}
                color={"normal"}
                startIcon={<VisibilityIcon />}
                children={translate(intl, "settings.lab.preview")}
                onClick={e => {
                  setOpenDialogPreview(true);
                }}
              />
              <Typography className={classes.note}>
                {intl.formatMessage({
                  id: "settings.lab.theInformationWillBeDisplayedOnSearchByCustomersClinics"
                })}
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              <CVButton variant="text" color="sub" onClick={handleReset}>
                {translate(intl, "common.ui.cancel")}
              </CVButton>
              <CVButton
                permission="labGeneralSetting"
                variant="contained"
                onClick={handleUpdateLab}
              >
                {translate(intl, "common.ui.save")}
              </CVButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {openDialogPreview && (
        <DialogPreview
          open={openDialogPreview}
          avatar={state?.avatar}
          onClose={() => setOpenDialogPreview(false)}
          samples={state.prothesisImages}
          labId={state?.labId}
          labDetail={data?.labDetail}
          previewState={state}
        />
      )}
      {Boolean(inputErr) && (
        <CCDialogInputError
          open={Boolean(inputErr)}
          title={translate(intl, inputErr)}
          onClose={() => setInputErr(null)}
        />
      )}
    </Box>
  );
};

export default LabInformation;
