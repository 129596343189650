import { useQuery } from "@apollo/react-hooks";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CVMenu, translate } from "components";
import moment from "moment";
import { GET_GOLD_LAB_DETAILS } from "queries/goldManagement";
import { PARTNER_LIST } from "queries/setting";
import React, { useEffect, useReducer } from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple, CCTypography } from "styles/components";
import { GOLD_OWNERSHIP_TYPES, LAB_GOLD_TYPES } from "types/goldManagement";
import { makeIndexFormattedMessageList } from "types/utils";
import { LabOrderModal } from "views/Normal/DentalLab/components";
import {
  ClinicFields,
  HistoryTable
} from "views/Normal/GoldManagement/components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
    width: 925,
    minHeight: 830
  },
  input__group: {
    width: "50%"
  },
  unit_price_text_field: {
    width: 92,
    backgroundColor: theme.palette.white
  },
  total__used__container: {
    border: "1px solid #b0bec5",
    borderRadius: 8
  },
  table__title: {
    padding: 8,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  total__used: {
    display: "flex",
    padding: 16
  },
  item__total__used: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "20%",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    paddingLeft: 8,

    "&:first-child": {
      border: "none",
      paddingLeft: 0
    }
  },
  text__red: {
    color: theme.palette.error.main
  },
  text__gray: {
    color: theme.palette.sub.main
  },
  mb8: {
    marginBottom: 8
  },
  cell__center: {
    textAlign: "center"
  },
  mouse__hover: {
    cursor: "pointer"
  },
  padding8: { padding: 8 }
}));

const initializer = props => {
  const { month, year, clinicId } = props;

  const startDate = moment([year, month - 1]);
  const endDate = moment(startDate).endOf("month");

  return {
    filter: {
      args: {
        date: {
          begin: startDate.unix(),
          end: endDate.unix()
        },
        goldType: ""
      }
    },
    selectedClinic: clinicId,
    clinicType: "",
    unitPriceFields: {
      inlay: 0,
      aType: 0,
      sA: 0,
      pt: 0,
      pfg: 0
    },
    anchorEl: null,
    selectedContent: {},
    labOrderModalVisible: false
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.target.filter
        }
      };
    case "setSelectedClinic":
      return {
        ...state,
        selectedClinic: action.target
      };
    case "setClinicType":
      return {
        ...state,
        clinicType: action.target
      };
    case "setAnchorEl":
      return {
        ...state,
        anchorEl: action.target
      };
    case "setSelectedContent":
      return {
        ...state,
        selectedContent: action.target
      };
    case "setLabOrderModalVisible":
      return {
        ...state,
        labOrderModalVisible: action.target
      };
    default:
      break;
  }

  return state;
};

const goldTypes = {
  inlay: "Inlay",
  aType: "A-Type",
  sA: "S-A",
  pt: "PT",
  pfg: "PFG"
};

const labGoldTypes = makeIndexFormattedMessageList(LAB_GOLD_TYPES);

const LabHistoryModal = props => {
  const { open, month, year, onClose, clinicId } = props;

  const classes = useStyles();
  const intl = useIntl();
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const {
    anchorEl,
    labOrderModalVisible,
    selectedContent,
    selectedClinic,
    clinicType
  } = state;

  const {
    data: goldLabDetailsData,
    loading: goldLabDetailsLoading,
    refetch: goldLabDetailsRefetch
  } = useQuery(GET_GOLD_LAB_DETAILS, {
    variables: {
      query: {
        clinic: selectedClinic,
        month: String(month),
        year: String(year),
        requestDate: {}
      }
    }
  });
  const { data: clinicListData, loading: clinicListLoading } = useQuery(
    PARTNER_LIST,
    {
      variables: {
        query: {
          sourcingGoldMethod: GOLD_OWNERSHIP_TYPES.LAB_GOLD.value
        }
      }
    }
  );

  const updateClinicType = id => {
    const clinic = clinicListData.partnerList.items.find(
      item => item.id === id
    );
    dispatchState({
      type: "setClinicType",
      target: clinic?.createdBy === "Lab" ? "Normal" : "Clever"
    });
  };
  const handleOnChangeClinic = e => {
    updateClinicType(e.target.value);
    dispatchState({
      type: "setSelectedClinic",
      target: e.target.value
    });
    goldLabDetailsRefetch({
      query: {
        clinic: e.target.value,
        month: String(month),
        year: String(year),
        requestDate: {}
      }
    });
  };
  const handleOnFilter = e => {
    dispatchState({ type: "filter", target: e });
    goldLabDetailsRefetch({
      query: {
        clinic: selectedClinic,
        month: String(month),
        year: String(year),
        requestDate: {
          from: e.filter.args.date.begin,
          to: e.filter.args.date.end
        },
        goldType: e.filter.args.goldType
      }
    });
  };

  useEffect(() => {
    if (clinicListData?.partnerList) {
      updateClinicType(clinicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicListData]);

  const stockHistoryHeads = [
    {
      key: "date",
      className: classes.cell__center,
      label: translate(intl, "labManageGold.date"),
      width: 150,
      component: ({ rowData }) =>
        eventComponent({
          data: (
            <CCTypography>
              {moment.unix(rowData.requestDate).format("L")}
            </CCTypography>
          )
        })
    },
    {
      key: "type",
      className: classes.cell__center,
      label: translate(intl, "common.ui.type"),
      component: ({ rowData }) => {
        return eventComponent({
          data: <CCTypography>{labGoldTypes[rowData.goldType]}</CCTypography>
        });
      }
    },
    {
      key: "unitPrice",
      className: classes.cell__center,
      label: translate(intl, "labManageGold.unitPrice"),
      component: ({ rowData }) =>
        eventComponent({
          data: <CCTypography>{rowData.usedPrice}</CCTypography>
        })
    },
    {
      key: "usedGold",
      className: classes.cell__center,
      label: translate(intl, "labManageGold.usedGoldG"),
      component: ({ rowData }) =>
        eventComponent({
          data: (
            <CCTypography variant="h4" className={classes.text__red}>
              {rowData.goldUsed}
            </CCTypography>
          )
        })
    },
    {
      key: "charged",
      className: classes.cell__center,
      label: translate(intl, "labManageGold.charge"),
      component: ({ rowData }) =>
        eventComponent({
          data: (
            <CCTypography variant="h4" className={classes.text__gray}>
              {rowData.charged}
            </CCTypography>
          )
        })
    }
  ];
  const eventComponent = ({ component, data }) => {
    return (
      component || (
        <Box
          className={clsx(classes.mouse__hover)}
          onClick={e => {
            dispatchState({ type: "setAnchorEl", target: e.target });
          }}
        >
          {data}
        </Box>
      )
    );
  };

  return (
    <>
      <CCDialogSimple
        title={translate(intl, "labManageGold.labHistory")}
        open={open}
        maxWidth={"xl"}
        fullWidth={false}
        onClose={onClose}
        onCloseButton={onClose}
        contentsPadding={false}
        contents={
          <Grid container direction={"column"} className={classes.root}>
            <Grid item className={classes.input__group}>
              {!clinicListLoading && (
                <ClinicFields
                  clinic={selectedClinic}
                  type={clinicType}
                  clinicList={clinicListData?.partnerList?.items || []}
                  onChangeClinic={handleOnChangeClinic}
                />
              )}
            </Grid>

            <Grid item className={classes.padding8}>
              <Grid
                container
                direction={"column"}
                className={classes.total__used__container}
              >
                <Grid item>
                  <CCTypography variant="h4" className={classes.table__title}>
                  {translate(intl, "labManageGold.totalUsedGoldGTotalCharge")}
                    {/* {intl.formatMessage({
                      id: "labManageGold.totalUsedGoldGTotalCharge"
                    })} */}

                  </CCTypography>
                </Grid>
                <Grid item className={classes.total__used}>
                  {!goldLabDetailsLoading &&
                    Object.keys(goldTypes).map(type => {
                      return (
                        <Box key={type} className={classes.item__total__used}>
                          <CCTypography variant="body1" className={classes.mb8}>
                            {goldTypes[type]}
                          </CCTypography>
                          <CCTypography
                            variant="h4"
                            className={classes.text__red}
                          >
                            {goldLabDetailsData.goldLabDetail[type] !== 0
                              ? `${Number(
                                  goldLabDetailsData.goldLabDetail[type]
                                ).toFixed(2)} g`
                              : "-"}
                          </CCTypography>
                          <CCTypography
                            variant="h4"
                            className={classes.text__gray}
                          >
                            {goldLabDetailsData.goldLabDetail.monthlyGold[
                              type
                            ] !== 0
                              ? `${Number(
                                  goldLabDetailsData.goldLabDetail.monthlyGold[
                                    type
                                  ]
                                ).toFixed(2)} $`
                              : "-"}
                          </CCTypography>
                        </Box>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={classes.padding8}>
              {!goldLabDetailsLoading && (
                <HistoryTable
                  // title={intl.formatMessage({
                  //   id: "labManageGold.stockHistory"
                  // })}
                  title={translate(intl, "labManageGold.stockHistory")}
                  heads={stockHistoryHeads}
                  contents={goldLabDetailsData.goldLabDetail.orders}
                  filter={{
                    filter: state.filter
                  }}
                  onRowClick={e => {
                    dispatchState({
                      type: "setSelectedContent",
                      target: e.data
                    });
                  }}
                  onFilter={handleOnFilter}
                />
              )}

              {Boolean(anchorEl) && (
                <CVMenu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  menuItems={[
                    {
                      // label: intl.formatMessage({
                      //   id: "labManageGold.moveToOrder"
                      // }),
                      label: translate(intl, "labManageGold.moveToOrder"),
                      onClick: () =>
                        dispatchState({
                          type: "setLabOrderModalVisible",
                          target: true
                        })
                    }
                  ]}
                  onClose={() =>
                    dispatchState({ type: "setAnchorEl", target: null })
                  }
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                />
              )}
            </Grid>
          </Grid>
        }
      />

      {labOrderModalVisible && (
        <LabOrderModal
          open={labOrderModalVisible}
          onClose={() =>
            dispatchState({
              type: "setLabOrderModalVisible",
              target: false
            })
          }
          value={selectedContent.orderId}
          onModify={() => {}}
        />
      )}
    </>
  );
};

export default LabHistoryModal;
