const WS_URL = "ws://127.0.0.1:8181";
const WS_CID_URL = "ws://127.0.0.1:8182";
const WS_RECONNECT_INTERVAL = 5 * 1000; // ms

const WS_SERVICE = {
  CERTIFICATE: "Certificate",
  DUR: "Dur",
  BILL: "Bill",
  CID: "CID",
  HIRA: "Hira",
  EMAIL: "Email",
  EYEFI_POLLING: "EyeFiPolling",
  SCANNER_POLLING: "ScannerPolling",
  EYEFI_ROUTE_SETTING: "EyeFiRouteSetting",
  SCANNER_ROUTE_SETTING: "ScannerRouteSetting",
  EZ_POLLING: "EzPolling",
  EZDENTI: "EzDenti",
  OCS: "OCS",
  PATIENT: "Patient",
  RUN_PROGRAM: "RunProgram",
  SMS: "SMS",
  EDI: "EDI"
};

const REQ_TYPE = {
  CERTIFICATE: "certificate",
  DUR: "dur",
  BILL: "bill",
  CID: "cid",
  HIRA: "hira",
  EMAIL: "email",
  EYEFI_POLLING: "eyefipolling",
  SCANNER_POLLING: "ScannerPolling",
  EYEFI_ROUTE_SETTING: "eyefiroutesetting",
  SCANNER_ROUTE_SETTING: "scannerroutesetting",
  EZDENTI: "ezdenti",
  EZ_POLLING: "ezpolling",
  OCS: "ocs",
  PATIENT: "patient",
  RUN_PROGRAM: "run_program",
  SMS: "sms",
  EDI: "edi"
};

const RES_TYPE = {
  SIGN: "sign",
  REGIST: "regist",
  CERTIFICATE: "certificate",
  DUR: "dur",
  DURCANCEL: "cancelDur",
  DURINSPECT: "durInspect",

  SUNABBILL: "sunabbill",
  SUNABGETINFOBILL: "sunabgetinfobill",

  DETAILBILL: "detailbill",
  DETAILCANCELBILL: "detailcancelbill",
  DETAILGETINFOBILL: "detailgetinfobill",
  DETAILPRINTBILL: "detailprintbill",

  ETCBILL: "etcbill",
  ETCCANCELBILL: "etccancelbill",
  ETCCANCELBILL2: "etccancelbill2",
  ETCGETINFOBILL: "etcgetinfobill",
  ETCGETINFOBILL2: "etcgetinfobill2",
  ETCPRINTBILL: "etcprintbill",

  SUNSUBILL: "sunsubill",
  SUNSUCANCELBILL: "sunsucancelbill",
  SUNSUCANCELBILL2: "sunsucancelbill2",
  SUNSUGETINFOBILL: "sunsugetinfobill",
  SUNSUGETINFOBILL2: "sunsugetinfobill2",
  SUNSUPRINTBILL: "sunsuprintbill",

  HISTORYPRINTBILL: "historyprintbill",

  CHECKBILL: "checkbill",

  CID: "cid",
  Email: "email",
  EyeFiPolling: "eyefipolling",
  EyeFiRouteSetting: "eyefiroutesetting",
  ScannerRouteSetting: "scannerroutesetting",
  EzDentI: "ezdenti",
  OCS: "ocs",
  PATIENT: "patient",
  PHOTO: "photo",
  SMS: "sms",
  SMSSEARCH: "smssearch",
  EZPOLLING: "ezpolling"
};

const CALLBACK_TYPE = {
  DURREGIST: "regist",
  DURDOCTOR: "doctor",
  DURCHART: "durChart",
  SIGN: "sign",
  REGIST: "regist",
  CERTIFICATE: "certificate",
  DUR: "dur",
  DURCANCEL: "cancelDur",
  DURINSPECT: "durInspect",
  SUNABBILL: "sunabbill",
  SUNABCANCELBILL: "sunabcancelbill",
  SUNABGETINFOBILL: "sunabgetinfobill",
  DETAILBILL: "detailbill",
  DETAILCANCELBILL: "detailcancelbill",
  DETAILGETINFOBILL: "detailgetinfobill",
  DETAILPRINTBILL: "detailprintbill",
  ETCBILL: "etcbill",
  ETCCANCELBILL: "etccancelbill",
  ETCCANCELBILL2: "etccancelbill2",
  ETCGETINFOBILL: "etcgetinfobill",
  ETCGETINFOBILL2: "etcgetinfobill2",
  ETCPRINTBILL: "etcprintbill",
  SUNSUBILL: "sunsubill",
  SUNSUCANCELBILL: "sunsucancelbill",
  SUNSUCANCELBILL2: "sunsucancelbill2",
  SUNSUGETINFOBILL: "sunsugetinfobill",
  SUNSUGETINFOBILL2: "sunsugetinfobill2",
  SUNSUPRINTBILL: "sunsuprintbill",
  HISTORYPRINTBILL: "historyprintbill",
  CHECKBILL: "checkbill",
  CID: "cid",
  CID_CONFIG: "cid_config",
  Email: "email",
  EyeFiPolling: "eyefipolling",
  ScannerPolling: "scannerpolling",
  EyeFiRouteSetting: "eyefiroutesetting",
  ScannerRouteSetting: "scannerroutesetting",
  EzDentI: "ezdenti",
  PATIENT_CURRENT: "current",
  PATIENT_PREVIOUS: "previous",
  SMS: "sms",
  SMSSEARCH: "smssearch",
  EZPOLLING: "ezpolling",
  EDI: "edi"
};

const INQUIRY_TYPE = {
  HCN: "hicardNo",
  RRN: "residentRegistrationNumber"
};

export {
  WS_URL,
  WS_CID_URL,
  WS_RECONNECT_INTERVAL,
  WS_SERVICE,
  REQ_TYPE,
  RES_TYPE,
  CALLBACK_TYPE,
  INQUIRY_TYPE
};
