import React from "react";

const GotoendIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M5.59 7.41 10.18 12l-4.59 4.59L7 18l6-6-6-6-1.41 1.41zM16 6h2v12h-2V6z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default GotoendIcon;
