import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { CCTextField } from "styles/src/components";
import { makeList } from "types";
import JOB_TYPES from "types/staff";

const JobTypeField = props => {
  const {
    variant,
    label,
    margin,
    errorOnChange,
    value,
    onChange,
    required,
    ...others
  } = props;

  const jobType = makeList(JOB_TYPES);

  // value
  const [selectValue, setSelectValue] = useState("");
  const [error, setError] = useState(false);

  // function
  const handleField = e => {
    onChange(e);
    setSelectValue(e.target.value);
  };

  // useEffect
  useEffect(() => {
    errorOnChange(error);
  }, [error, errorOnChange]);
  useEffect(() => {
    value ? setSelectValue(value) : setSelectValue("");
    !required && value ? setError(true) : setError(false);
    required && value ? setError(false) : setError(true);
  }, [value, required]);

  return (
    <CCTextField
      value={selectValue}
      onChange={handleField}
      variant={variant}
      label={label}
      margin={margin}
      error={error}
      required={required}
      select
      {...others}
    >
      {jobType.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </CCTextField>
  );
};

JobTypeField.defaultProps = {
  value: "",
  variant: "outlined",
  label: "Occupation",
  margin: "dense",
  errorOnChange: () => {}
};

JobTypeField.propTypes = {};

export default JobTypeField;
