import { Box, makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import { TeethGraph } from "components";
// import { SEARCH_LAB_ORDER } from "queries/labOrder";
import React, { useState } from "react";
import {
  CCButton,
  CCDialogSimple,
  CCIconButton,
  CCMenu,
  CCTable,
  CCTextField,
  CCTooltip,
  CCTypography
} from "styles/src/components";
import CCTabs from "styles/src/components/CCTabs";
import { SearchIcon, SMSIcon } from "styles/src/themes/common/icons";
import ProgressIcon from "styles/src/themes/common/icons/ProgressIcon";
import {
  LAB_ARRIVE_TYPE,
  LAB_ORDER_STATE,
  LAB_ORDER_TYPE,
  NOTE_STATE
} from "types/labOrder";
import { makeIndexFormattedMessageList } from "types/utils";
import { LabOrderModal } from "views/Normal/DentalLab/components/index";
import { translate } from "components";
import { useIntl } from "react-intl";

const tabsHeight = 52;
const width = 160;

const useStyles = makeStyles(theme => ({
  root: {
    width: 1200
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    height: tabsHeight,
    boxSizing: "border-box"
  },
  table: {
    height: 550
  },
  textfield: {
    paddingLeft: 8
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  },
  box: { position: "relative" },
  sub__main: {
    color: theme.palette.sub.main
  }
}));

const labOrderState = makeIndexFormattedMessageList(LAB_ORDER_STATE);
const labOrderType = makeIndexFormattedMessageList(LAB_ORDER_TYPE);

const ConnectLabIdSelect = props => {
  const { open, onClose, onComplete, patient } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [selectLabOrder, setSelectLabOrder] = useState({});
  const [
    contents
    // setContents
  ] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);

  // const { loading } = useQuery(SEARCH_LAB_ORDER, {
  //   variables: {
  //     hospitalId: localStorage.getItem('labId'),
  //     args: {
  //       patient: patient.id,
  //       deliveryType: LAB_ARRIVE_TYPE.ARRIVE.value
  //     }
  //   },
  //   onCompleted: data => {
  //     if (data) {
  //       setContents(
  //         data?.searchLabOrder?.map(item => ({
  //           id: item.id,
  //           requestId: item.requestId,
  //           name: item.patient?.name,
  //           chartNumber: item.patient?.chartNumber,
  //           doctor: item.doctor?.name,
  //           staff: item.staff?.name,
  //           lab: item.lab?.name,
  //           manufacture: item.manufacture?.name,
  //           type: item.type,
  //           teeth: item.teeth,
  //           missings: item.missings,
  //           state: item.state,
  //           reDate: moment(item.requestDate, "X").format("YYYY-MM-DD"),
  //           deDate: moment(item.deliveryRequestDate, "X").format("YYYY-MM-DD"),
  //           note: item.note,
  //           requestDate: item.requestDate,
  //           deliveryRequestDate: item.deliveryRequestDate,
  //           deliveryType: item.deliveryType
  //         }))
  //       );
  //     }
  //   },
  //   fetchPolicy: "network-only"
  // });

  const eventComponent = ({ data }) => {
    return (
      <Box
        className={clsx(classes.mouse__hover)}
        onClick={e => {
          setOpenMenu(true);
          setAnchorEl(e.target);
        }}
      >
        {data}
      </Box>
    );
  };

  return (
    <CCDialogSimple
      maxWidth={false}
      fullWidth
      open={open}
      onClose={onClose}
      title={"기공의뢰서 정보"}
      contentsPadding={false}
      endActions={
        <>
          <CCButton onClick={() => onClose()}>취소</CCButton>
          <CCButton
            variant={"contained"}
            color={"primary"}
            endIcon={<ProgressIcon />}
            onClick={() => {
              onComplete(selectLabOrder.requestId);
              onClose();
            }}
          >
            선택
          </CCButton>
        </>
      }
      startActions={
        <CCTypography variant={"body1"} className={classes.sub__main}>
          * 수정 / 재재작을 진행할 원본(연결) 기공의뢰서를 선택 합니다.
        </CCTypography>
      }
      contents={
        <>
          <CCTextField
            className={classes.textfield}
            margin={"dense"}
            variant={"outlined"}
            value={patient.name}
            endIcon={<SearchIcon />}
            disabled
          />
          <CCTabs tabList={["기공내역"]} selectedTab={0} />
          <Box className={classes.box}>
            {/* <Loading open={loading} msg={"Loading..."} zIndex={110} /> */}
            <CCTable
              selectedRow={selectedIndex}
              className={classes.table}
              onRowClick={e => {
                setSelectedIndex(e.rowIndex);
                setSelectLabOrder({ ...e.data });
              }}
              heads={[
                {
                  label: "의뢰일",
                  key: "reDate",
                  className: classes.cell,
                  component: ({ rowData }) => {
                    return eventComponent({
                      data: rowData?.reDate
                    });
                  }
                },
                {
                  label: "납품일",
                  key: "deDate",
                  className: classes.cell,
                  component: ({ rowData }) =>
                    eventComponent({
                      data: rowData?.deDate
                    })
                },
                {
                  label: "의뢰번호",
                  key: "deDate",
                  className: classes.cell,
                  component: ({ rowData }) =>
                    eventComponent({
                      data: rowData?.requestId
                    })
                },
                {
                  label: "담당의사",
                  key: "doctor",
                  className: classes.cell,
                  component: ({ rowData }) =>
                    eventComponent({
                      data: rowData?.doctor
                    })
                },
                {
                  label: "담당직원",
                  key: "staff",
                  className: classes.cell,
                  component: ({ rowData }) =>
                    eventComponent({
                      data: rowData?.staff
                    })
                },
                {
                  label: "차트번호",
                  key: "chartNumber",
                  className: classes.cell,
                  component: ({ rowData }) =>
                    eventComponent({
                      data: rowData?.chartNumber
                    })
                },
                {
                  label: "이름",
                  key: "name",
                  className: classes.cell,
                  component: ({ rowData }) =>
                    eventComponent({
                      data: rowData?.name
                    })
                },
                {
                  label: "기공소",
                  key: "lab",
                  className: classes.cell,
                  width: 85,
                  component: ({ rowData }) => {
                    return eventComponent({
                      //클래버 기공소인 경우 툴팁과 그린색
                      data:
                        rowData?.target === 1 ? (
                          <CCTooltip
                            placement="top"
                            arrow
                            title={"Clever Lab 에 등록/연동된 기공소입니다."}
                          >
                            <CCTypography variant={"h5"} color={"primary"}>
                              {rowData?.lab}
                            </CCTypography>
                          </CCTooltip>
                        ) : (
                          <CCTypography>{rowData?.lab}</CCTypography>
                        )
                    });
                  }
                },
                {
                  key: "teeth",
                  label: "치식",
                  className: classes.cell,
                  width: width + 16,
                  component: ({ cellData, rowData }) =>
                    eventComponent({
                      data: (
                        <TeethGraph
                          width={width}
                          height={width / 4}
                          foreground={"black"}
                          numbers={cellData || []}
                          missings={rowData?.missings || []}
                        />
                      )
                    })
                },
                {
                  label: "의뢰종류",
                  key: "type",
                  className: classes.cell,
                  component: ({ rowData }) =>
                    eventComponent({
                      data: labOrderType[rowData?.type]
                    })
                },
                /*{
                label: "기공물",
                key: "manufacture",
                className: classes.cell,
                width: 60,
                component: ({ rowData }) =>
                  eventComponent({
                    data: rowData?.manufacture
                  })
              },*/
                {
                  label: "기공현황",
                  key: "state",
                  className: classes.cell,
                  component: ({ rowData }) => {
                    return eventComponent({
                      data: <Box>{labOrderState[rowData?.state]}</Box>
                    });
                  }
                },
                {
                  label: "도착현황",
                  key: "deliveryType",
                  className: classes.cell,
                  component: ({ rowData }) => {
                    return eventComponent({
                      data:
                        rowData?.deliveryType ===
                        LAB_ARRIVE_TYPE.ARRIVE.value ? (
                          <Chip label={"도착"} color={"primary"} />
                        ) : rowData?.deliveryType ===
                          LAB_ARRIVE_TYPE.NON_ARRIVE.value ? (
                          <Chip label={"미도착"} />
                        ) : (
                          ""
                        )
                    });
                  }
                },
                {
                  label: "쪽지",
                  key: "note",
                  className: classes.cell,
                  component: ({ rowData }) => {
                    if (rowData?.note === NOTE_STATE.CHECK.value)
                      return (
                        <CCTooltip
                          placement="top"
                          arrow
                          title={translate(intl, "order.newMessages")}
                        >
                          <CCIconButton color={"primary"}>
                            <SMSIcon />
                          </CCIconButton>
                        </CCTooltip>
                      );
                  }
                }
              ]}
              contents={contents}
            />
          </Box>
          <CCMenu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setOpenMenu(false)}
            menuItems={[
              {
                value: "1",
                label: "상세보기",
                onClick: () => {
                  setOpenDetail(true);
                }
              }
            ]}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          />
          {openDetail && (
            <LabOrderModal
              open={openDetail}
              onClose={() => setOpenDetail(false)}
              value={selectLabOrder.id}
              buttonHidden={true}
            />
          )}
        </>
      }
    />
  );
};

ConnectLabIdSelect.defaultProps = {};

ConnectLabIdSelect.propTypes = {};

export default ConnectLabIdSelect;
