import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { DialogCancel, DialogError } from "components";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCDialogSimple } from "styles/components";
import { CheckIcon } from "styles/icons";
import ClinicInfoField from "../ClinicInfoField";
import { translate } from "components";
import { enqueueToast } from "components/Toast";
import { useSnackbar } from "notistack";

const useStyle = makeStyles(theme => ({
  button__cancel: {
    marginRight: 8
  }
}));

const initField = {
  id: "",
  name: "",
  email: "",
  tel: "",
  fax: "",
  tax: "",
  address: "",
  manager: "",
  managerCellNo: "",
  discount: "",
  paymentDate: "",
  type: "",
  // city: "",
  // state: "",
  // zipCode: "",
  sourcingGoldMethod: 1,
};

const DialogClinicAdd = props => {
  // props
  const { open, selectedClinic, onClose, createClinic, updateClinic } = props;
  const { enqueueSnackbar } = useSnackbar();
  // classes
  const classes = useStyle();
  const intl = useIntl();

  // value
  const [field, setField] = useState(initField);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const clinicFieldError = useRef({
    name : true,
    email : true,
    tel : true,
    tax : true,
    address : true,
    discount : false,
    paymentDate : true,
  });

  // function
  const handleOnSubmit = () => {
    const validateForm = () => {
      const hasError = Object.values(clinicFieldError.current).includes(true);
      if (hasError) {
        setErrorDialog(true);
        return false;
      }
      return true;
    };
    const verifyChanges = () => {
      for (let key of Object.keys(initField)) {
        if (selectedClinic) {
          if (field[key] !== selectedClinic[key]) return true;
        } else {
          return true;
        }
      }
      return false;
    };
    const isFormValid = validateForm();
    const hasChanged = verifyChanges();
    if (!isFormValid) return;
    if (!hasChanged) return onClose();
    const input = {
      id: field.id,
      name: field.name,
      email: field.email,
      tel: field.tel,
      fax: field.fax,
      tax: field.tax,
      address: field.address,
      manager: field.manager,
      managerCellNo: field.managerCellNo,
      discount: Number(field.discount),
      paymentDate: field.paymentDate,
      type: field.type,
      // city: field.city,
      // state: field.state,
      // zipCode: field.zipCode,
      sourcingGoldMethod: String(field.sourcingGoldMethod)
    };
    if (!selectedClinic) {
      createClinic({
        variables: {
          input: { ...input, id: field.id ? field.id : undefined }
        }
      });
    } else {
      updateClinic({
        variables: {
          input: { ...input, id: field.id ? field.id : undefined }
        }
      });
    }
    enqueueToast(enqueueSnackbar, translate(intl, "common.ui.saved"));
    return onClose();
  };

  // useEffect
  useEffect(() => {
    if (selectedClinic) {
      setField(prevState => ({
        ...prevState,
        ...selectedClinic
      }));
      clinicFieldError.current = {
        name : !Boolean(selectedClinic.name),
        email : !Boolean(selectedClinic.email),
        tel : !Boolean(selectedClinic.tel),
        tax : !Boolean(selectedClinic.tax),
        address : !Boolean(selectedClinic.address),
        discount : false,
        paymentDate : !Boolean(selectedClinic.paymentDate)
      };
    } else {
      setField({ ...initField });
    }
  }, [selectedClinic]);

  return (
    <Box>
      <CCDialogSimple
        open={open}
        title={intl.formatMessage({
          id: "settings.clinics.partnerInformation"
        })}
        contents={
          <ClinicInfoField
            disableSomeFields={selectedClinic && field.createdBy === "Clever"}
            field={field}
            onHandle={setField}
            clinicFieldError={clinicFieldError.current}
            onHandleError={error => {
              clinicFieldError.current = {
                ...clinicFieldError.current,
                ...error
              };
            }}
          />
        }
        contentsPadding={false}
        maxWidth={"md"}
        endActions={
          <>
            <CCButton className={classes.button__cancel} onClick={onClose}>
              {translate( intl,"common.ui.cancel" )}
            </CCButton>
            <CCButton
              startIcon={<CheckIcon />}
              variant="contained"
              onClick={handleOnSubmit}
            >
              {translate( intl,"common.ui.save" )}
            </CCButton>
          </>
        }
        onClose={() => {
          onClose();
        }}
        onCloseButton={() => {
          onClose();
        }}
      />
      <DialogError
        open={errorDialog}
        content={intl.formatMessage({
          id: "settings.clinics.plsFillEssentialInformation"
        })}
        onClose={() => setErrorDialog(false)}
      />
      <DialogCancel
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        onAgree={onClose}
        onDisagree={() => setCancelDialog(false)}
      />
    </Box>
  );
};

export default DialogClinicAdd;
