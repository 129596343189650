import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    bottom: 0,
    position: "fixed",
    zIndex: 100,
    background: "gray",
    opacity: 0.5,
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
}));

const Footer = () => {
  const classes = useStyles();

  return <Box className={classes.root}>Footer</Box>;
};

export default Footer;
