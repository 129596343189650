import { DialogPermissionWarn, useWritePermission } from "components";
import React, { forwardRef, useState } from "react";
import { CCButton } from "styles/components";

const CVButton = forwardRef((props, ref) => {
  const { children, permission, onClick, ...others } = props;
  const [open, setOpen] = useState(false);
  const isPermission = useWritePermission(permission);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <CCButton
        onClick={isPermission ? onClick : handleClick}
        ref={ref}
        style={{
          ...others.style,
          textTransform: "unset",
        }}
        {...others}
      >
        {children}
      </CCButton>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default CVButton;
