import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { makeStyles, Checkbox } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import clsx from "clsx";
import moment from "moment";
import { AppContext } from "context";
import {
  DialogPermissionWarn,
  TeethGraph,
  translate,
  useWritePermission
} from "components";
import FileUploadPaper from "components/FileUploadPaper";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCIconButton,
  CCPaper,
  CCTextField,
  CCTypography,
  CCTooltip
} from "styles/src/components";
import CCMenuItem from "styles/src/components/CCMenu/Component/CCMenuItem";
import CCTable from "styles/src/components/CCTable";
import { InfoIcon } from "styles/src/themes/common/icons";
import { makeList } from "types";
import { LAB_ORDER_STATE } from "types/labOrder";
import { LabOrderModal } from "views/Normal/DentalLab/components/index";
import ViewLabOrderCalculator from "views/Normal/DentalLab/components/LabOrderModal/components/ViewLabOrderCalculator";
import { DialogError } from "../../../../../../../components";
import { GET_LAB_ORDER_DETAIL } from "queries/labOrder";
import { IS_ORDER_BELONGS_TO_PAYMENT } from "../../../../../../../queries/payment";
import { LabOrderProcessModal } from "../../../LabOrderView/components";

const topHeight = 42;
const width = 160;

const useStyles = makeStyles(theme => ({
  root: { width: "100%", height: "100%", display: 'flex', flexDirection: 'column' },
  calculator__view: { width: "100%" },
  title__container: {
    padding: 8,
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  body__container: {},
  prothesis_name: {
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
    overflow: "hidden",
  },
  detail__container: {
    padding: 8,
    display: "flex",
    flexDirection: "row",
    flexWrap: 'nowrap',
    flex: 1,
    backgroundColor: theme.palette.background.default
  },
  container__prothesis__status: {
    display: "flex",
    justifyContent: "space-between"
  },
  item_prothesis: {
    display: "flex",
    alignItems: "center"
  },
  padding8: {
    padding: 8
  },
  flexbox: {
    display: "flex"
  },
  fullWidth: {
    width: "100%"
  },
  padding__side: {
    paddingLeft: 8,
    paddingRight: 8
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  top: { height: topHeight },
  photo__padding: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8
  },
  photo__item: { paddingLeft: 16 },
  photo__container: {
    height: 90,
    overflow: "auto",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default,
    padding: 8
  },
  forceRTL: {
    direction: 'ltr !important'
  },
  column2: {
    width: 380,
    borderLeft: '1px solid #b0bec5',
    paddingLeft: 6,
    marginLeft: 6,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    padding: "8px 16px",
    width: '-webkit-fill-available',
  },
  production__process: {
    background: 'white',
    height: '445px',
    flexWrap: 'nowrap',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 10,
    overflowY: 'auto',
    marginTop: 8,
  },
  process__input: {
    margin: 16,
    width: '-webkit-fill-available',
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  process__stage: {
    margin: 8,
    flexWrap: 'nowrap',
    width: '-webkit-fill-available',
    alignItems: 'flex-start',
  },
  date__stage: {
    color: '#0277bd'
  },
  title__stage: {
    marginRight: 8,
    wordBreak: 'break-all'
  },
  na__productProcess: {
    margin: 'auto'
  }
}));

const labOrderStateItem = makeList(LAB_ORDER_STATE);


const ViewDetailLabOrder = props => {
  const { value, deliveryUpdate, onChange, permission } = props;
  const {
    prosthesis,
    status,
    shade,
    teeth,
    photo,
    pontic,
    missings,
    detail,
    note,
    id,
    isClever,
    manufacture,
    productProcess,
    linkedCleverOrderIdMap
  } = value;

  const classes = useStyles();
  const intl = useIntl();
  const isPermission = useWritePermission(permission);
  const lang = localStorage.getItem('lang')
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectId, setSelectId] = useState("");
  const [errorPermission, setErrorPermission] = useState(false);
  const [confirmChangePaidStatus, setConfirmChangePaidStatus] = useState(false);
  const [confirmChangeCleverStatus, setConfirmChangeCleverStatus] =
    useState(false);

  const { appContext } = useContext(AppContext);

  const { data: isOrderBelongsToPaidPaymentData } = useQuery(
    IS_ORDER_BELONGS_TO_PAYMENT,
    {
      fetchPolicy: "network-only",
      variables: { ids: [id] }
    }
  );

  const [getLabOrder, {called, data}] = useLazyQuery(GET_LAB_ORDER_DETAIL, {
    onCompleted: data => {
      setSelectId(data?.orderDetail?.id);
      setOpenDetail(true);
    }
  });

  const handleCloseDetail = () => {
    setOpenDetail(false); 
    setSelectId(undefined);
  };

  const handleClickLinkedOrderId = (linkedOrderId) => () => {
    if (called && data) {
      setSelectId(data?.orderDetail?.id);
      setOpenDetail(true);
    } else {
      getLabOrder({
        variables: {
          id: linkedOrderId
        }
      });
    }
  }

  return (
    <Box className={classes.root}>
      <Grid container className={classes.title__container}>
        <Grid item className={classes.padding__side}>
          <CCTypography variant={"h4"}>
            {translate(intl, "common.ui.details")}
          </CCTypography>
        </Grid>
      </Grid>
      <Grid container className={classes.detail__container}>
        <Grid item style={{ width: 560 }}>
          <Grid container>
            <Grid item className={classes.fullWidth}>
              <Grid container justify={"space-between"}>
                <Grid item className={classes.item_prothesis}>
                  <CCTextField
                    style={{ width: 130 }}
                    label={translate(intl, "common.ui.prosthesis")}
                    variant="outlined"
                    margin="dense"
                    value={prosthesis || ""}
                    InputLabelProps={{
                      shrink: !!prosthesis
                    }}
                    disabled
                  />
                  {isClever && linkedCleverOrderIdMap && (
                    <CCTypography display={"inline"} color={"textSecondary"}>
                      {translate(intl, "pageOrders.linkedOrder")}
                      {linkedCleverOrderIdMap.map(linkedOrderIdMap => (
                        <Link
                          key={linkedOrderIdMap.labOrderId}
                          color={"textSecondary"}
                          component="button"
                          variant="body2"
                          onClick={handleClickLinkedOrderId(linkedOrderIdMap.labOrderId)}
                        >
                          - {linkedOrderIdMap.cleverOrderId}
                        </Link>
                      ))}
                    </CCTypography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={clsx(classes.fullWidth, classes.padding8)}>
              <CCPaper>
                <CCTable
                  heads={[
                    {
                      key: "teeth",
                      label: translate(intl, "common.ui.toothNo"),
                      className: classes.cell,
                      width: width + 16,
                      component: ({ cellData, rowData }) => {
                        return (
                          <TeethGraph
                            width={120}
                            height={30}
                            foreground={"black"}
                            numbers={cellData || []}
                            missings={rowData.missings || []}
                          />
                        );
                      }
                    },
                    {
                      key: "prosthesis",
                      label: translate(intl, "common.ui.prosthesis"),
                      className: classes.cell,
                      component: ({ rowData, cellData }) => {
                        return (
                          <CCTooltip
                            title={rowData?.manufacture?.name
                              ? rowData?.manufacture?.name
                              : cellData}
                          >
                            <Grid
                              container
                              style={{ height: 50 }}
                              alignItems={"center"}
                              justify={"center"}
                            >
                              <Grid item className={classes.prothesis_name}>
                                {rowData?.manufacture?.name
                                  ? rowData?.manufacture?.name
                                  : cellData}
                              </Grid>
                            </Grid>
                          </CCTooltip>
                        );
                      }
                    },
                    {
                      key: "tableShade",
                      label: translate(intl, "common.ui.shade"),
                      className: classes.cell
                    },
                    {
                      key: "pontic",
                      label: translate(intl, "labManageGold.pontic"),
                      className: classes.cell,
                      component: ({ cellData }) => (
                        <CCTypography>
                          {cellData ? cellData.join(", ") : ""}
                        </CCTypography>
                      )
                    }
                  ]}
                  contents={[
                    {
                      teeth: teeth,
                      prosthesis: prosthesis,
                      tableShade: `
                      ${shade?.top ? `I : ${shade?.top}` : ""}
                      ${shade?.top && shade?.middle ? ` / ` : ""}
                      ${shade?.middle ? `M : ${shade?.middle}` : ""}
                      ${shade?.middle && shade?.bottom ? ` / ` : ""}
                      ${shade?.bottom ? `C : ${shade?.bottom}` : ""}
                    `,
                      pontic: pontic,
                      missings: missings,
                      manufacture
                    }
                  ]}
                />
              </CCPaper>
            </Grid>
            <Grid
              item
              className={clsx(classes.fullWidth, classes.padding8)}
              style={{ height: 162 }}
            >
              <FileUploadPaper
                value={photo}
                buttonHidden
                xs={2}
                readonly
                title={translate(intl, "labManageGold.attachments")}
              />
            </Grid>
            <Grid item className={clsx(classes.fullWidth, classes.flexbox)}>
              <CCTextField
                label={translate(intl, "labOrder.detail")}
                value={detail ? detail.join(", ") : ""}
                variant="outlined"
                disabled
                margin="dense"
                className={clsx(classes.fullWidth)}
              />
            </Grid>
            <Grid item className={clsx(classes.fullWidth, classes.flexbox)}>
              <CCTextField
                label={intl.formatMessage({
                  id: "common.ui.additionalInformation"
                })}
                value={note}
                variant="outlined"
                disabled
                margin="dense"
                className={clsx(classes.fullWidth)}
              />
            </Grid>
            {/* {isClever && ( */}
            <Grid item className={clsx(classes.calculator__view, classes.flexbox)}>
              <ViewLabOrderCalculator
                permission={permission}
                value={value}
                deliveryUpdate={deliveryUpdate}
                onChange={onChange}
              />
            </Grid>
            {/* )} */}
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.column2)}>
          <Grid container direction="column" style={{ height: '100%', }}>
            <Grid item>
              <CCTextField
                style={{ width: 130 }}
                label={translate(intl, "common.ui.status")}
                variant="outlined"
                margin="dense"
                value={status || ""}
                select
                InputProps={{ classes: { input: lang === 'ar' ? classes.forceRTL : '' } }}
                onChange={e => {
                  if (!isPermission) {
                    setErrorPermission(true);
                  } else if (
                    isOrderBelongsToPaidPaymentData.isOrderBelongsToPaidPayment
                      .isOrdersBelongToSendCleverPayment
                  ) {
                    setConfirmChangeCleverStatus(true);
                  } else if (
                    isOrderBelongsToPaidPaymentData.isOrderBelongsToPaidPayment
                      .isOrdersBelongToPaidPayment
                  ) {
                    setConfirmChangePaidStatus(true);
                  } else {
                    onChange("status", e.target.value);
                  }
                }}
              >
                {labOrderStateItem.map(item => (
                  <CCMenuItem
                    key={item.value}
                    value={item.value}
                    disabled={item.value === LAB_ORDER_STATE.CONFIRM_REQUEST.value || item.value === LAB_ORDER_STATE.CONFIRMED.value}>
                    {item.label}
                  </CCMenuItem>
                ))}
              </CCTextField>
              <CCIconButton color={"sub"} onClick={() => setOpen(true)}>
                <InfoIcon />
              </CCIconButton>
            </Grid>
            <Grid item style={{ flex: 1, padding: "0 8px" }}>
              <Grid container direction="column" className={classes.production__process}>
                <CCTypography className={classes.title}>
                  {translate(intl, "productProcess")}
                </CCTypography>
                {productProcess?.stages?.length > 0 ?
                  <>
                    <CCTooltip
                      placement={"top"}
                      title={productProcess.processName}
                    >
                      <Grid item>
                        <CCTextField
                          label={translate(intl, "process")}
                          variant="outlined"
                          margin="dense"
                          className={classes.process__input}

                          value={productProcess.processName || ""}
                          InputProps={{
                            disabled: true
                          }}
                        />
                      </Grid>
                    </CCTooltip>
                    {productProcess?.stages?.map(item => (
                      <Grid container className={classes.process__stage} key={item.id}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={item.isChecked}
                          onChange={() => {
                            onChange(
                              "productProcess",
                              {
                                ...productProcess,
                                stages:
                                  productProcess.stages.map(stage => stage.id === item.id ?
                                    {
                                      ...stage,
                                      isChecked: !stage.isChecked,
                                      date: !stage.isChecked && moment().unix(),
                                      editor: !stage.isChecked ? {
                                        staffUUID: appContext.me.id,
                                        staffId: appContext.me.staffId,
                                        staffName: appContext.me.staffName
                                      } : {}
                                    } :
                                    stage
                                  )
                              }
                            )
                          }}
                          color='secondary'
                        />
                        <Grid container direction="row">
                          <CCTypography className={classes.title__stage}>
                            {item.stageName}
                          </CCTypography>
                          {item.isChecked &&
                            <CCTypography className={classes.date__stage}>
                              ({item.editor.staffName}, {moment(item.date, "X").format("L LT")})
                            </CCTypography>
                          }
                        </Grid>
                      </Grid>
                    ))}
                  </> :
                  <CCTypography className={classes.na__productProcess}>
                    {translate(intl, "naProductProcess")}
                  </CCTypography>
                }



              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <LabOrderProcessModal open={open} onClose={() => setOpen(false)} />
      {openDetail && (
        <LabOrderModal
          open={openDetail}
          onClose={handleCloseDetail}
          value={selectId}
          buttonHidden={true}
        />
      )}
      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
      {confirmChangePaidStatus && (
        <DialogError
          open={Boolean(confirmChangePaidStatus)}
          content={translate(intl, "changeStatusAOrder")}
          onClose={() => setConfirmChangePaidStatus(false)}
        />
      )}
      {confirmChangeCleverStatus && (
        <DialogError
          open={Boolean(confirmChangeCleverStatus)}
          content={translate(intl, "changeCleverStatusOrder")}
          onClose={() => setConfirmChangeCleverStatus(false)}
        />
      )}
    </Box>
  );
};

ViewDetailLabOrder.propTypes = {};

export default ViewDetailLabOrder;
