import { CVMenuItem, DialogPermissionWarn } from 'components';
import { CVNestedMenuItem } from 'components/index';
import React, { forwardRef, useState } from 'react';
import { CCMenu } from 'styles/src/components';

const CVMenu = forwardRef((props, ref) => {
  // const {
  //   children,
  //   permission,
  //   onChange,
  //   onKeyDown,
  //   onKeyPress,
  //   onClickIcon,
  //   ...others
  // } = props;
  const [open, setOpen] = useState(false);

  // const handleChange = () => {
  //   setOpen(true);
  // };

  return (
    <>
      <CCMenu
        NestedMenuItemComponent={CVNestedMenuItem}
        MenuItemComponent={CVMenuItem}
        {...props}
      />
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default CVMenu;
