import { useQuery } from "@apollo/react-hooks";
import { Grid, makeStyles } from "@material-ui/core";
import { translate } from "components";
import { AppContext } from "context/AppContext";
import {
  BILL_CONFIRM_DETAIL
} from "queries/payment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogSimple
} from "styles/components";
import { ProgressIcon } from "styles/src/themes/common/icons";
import Receipt from "./components/Receipt";

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "column",
    position: "relative",
    // borderTop: '1px solid #b0bec5',
    background: "#eceff1"
  },

  item2: {
    margin: 12,
    flex: 1,
    background: theme.palette.common.white
  },
  color__red: { color: "#f50057" },

  button__print: {
    marginLeft: 16
  }
}));

const BillConfirm = props => {
  const { open, onClose, onConfirm, ids, clinic, onNoteUpdate, note } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const componentRef = useRef();
  const [inputErr, setInputErr] = useState(null);
  // console.log("ids, clinic", ids, clinic)
  const { data: dataPaymentBill, loading } = useQuery(BILL_CONFIRM_DETAIL, {
    variables: { ids, clinic },
  });
  


  useEffect(() => {
    setInputErr(null);
  }, [appContext.refresh]);

  return (
    !loading && (
      <>
        {dataPaymentBill?.paymentBillConfirm && (
          <CCDialogSimple
            maxWidth="md"
            fullWidth
            classes={classes}
            open={open}
            onClose={onClose}
            onCloseButton={onClose}
            contentsPadding={false}
            // title={"Send Bill"}
            title={translate(intl, "payment.bill.confirmBill")}
            contents={
              <Grid container className={classes.container}>
                <Grid item className={classes.item2}>
                  <Receipt
                    ref={componentRef}
                    data={dataPaymentBill?.paymentBillConfirm}
                    onNoteUpdate={onNoteUpdate}
                  />
                </Grid>
              </Grid>
            }
            endActions={
              <>
                <CCButton margin="dense" onClick={() => onClose()}>
                  {translate(intl, "payment.bill.cancel")}
                  {/* Cancel */}
                </CCButton>
                <CCButton
                  className={classes.button__print}
                  variant="contained"
                  margin="dense"
                  startIcon={<ProgressIcon />}
                  onClick={() => onConfirm()}
                >
                  {translate(intl, "common.ui.confirm")}
                </CCButton>
              </>
            }
          />
        )}
      </>
    )
  );
};

export default BillConfirm;
