import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { CCTextField } from "styles/src/components";
import { makeList } from "types";
import { GOLD_SOURCING_METHOD_TYPES } from "types/goldManagement";

const GoldSourcingMethodField = props => {
  const {
    variant,
    label,
    margin,
    errorOnChange,
    value,
    onChange,
    required,
    ...others
  } = props;

  //value
  const [selectValue, setSelectValue] = useState("");
  const [error, setError] = useState(false);
  const goldType = makeList(GOLD_SOURCING_METHOD_TYPES);

  // function
  const handleField = e => {
    onChange(e);
    setSelectValue(e.target.value);
  };

  // use effect
  useEffect(() => {
    errorOnChange(error);
  }, [error, errorOnChange]);
  useEffect(() => {
    String(value) ? setSelectValue(value) : setSelectValue("");
    !required && String(value) ? setError(true) : setError(false);
    required && String(value) ? setError(false) : setError(true);
  }, [value, required]);

  return (
    <CCTextField
      value={selectValue}
      onChange={handleField}
      variant={variant}
      label={label}
      margin={margin}
      error={error}
      required={required}
      select
      {...others}
    >
      {goldType.map(item => (
        <MenuItem
          key={item.value}
          value={item.value}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {item.label}
        </MenuItem>
      ))}
    </CCTextField>
  );
};

GoldSourcingMethodField.defaultProps = {
  value: "",
  variant: "outlined",
  label: "",
  margin: "dense",
  errorOnChange: () => {}
};

GoldSourcingMethodField.propTypes = {};

export default GoldSourcingMethodField;
