export const formatCommas = number => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0";
  }
};

export const sumArray = array => {
  return array.length > 0 ? array.reduce((prev, curr) => prev + curr) : 0;
};
