import React from "react";

const AddClaimIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M20 13v4h4v2h-4v4h-2v-4h-4v-2h4v-4h2zM14 2c1.11 0 2 .89 2 2v2h4c1.11 0 2 .89 2 2v3.017h-2V8H4v11h8.003v2H4c-1.11 0-2-.89-2-2l.01-11c0-1.11.88-2 1.99-2h4V4c0-1.11.89-2 2-2h4zm0 2h-4v2h4V4z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default AddClaimIcon;
