import gql from "graphql-tag";

const ADD_LAB_GOLD_STOCK = gql`
  mutation AddLabGoldStock($input: ImportGoldStockInput) {
    importGoldToStockManage(input: $input) {
      code
      message
    }
  }
`;

const UPDATE_LAB_GOLD_UNIT_PRICE = gql`
  mutation UpdateLabGoldUnitPriceManage(
    $input: UpdateLabGoldUnitPriceManageInput!
  ) {
    updateLabGoldUnitPriceManage(input: $input) {
      code
      message
    }
  }
`;

const GOLD_STOCK_DETAIL = gql`
  query GoldStockDetail($query: GoldStockQueryInput) {
    goldStockDetail(query: $query) {
      labId
      inlay
      aType
      sA
      pt
      pfg
      history {
        importDate
        createdAt
        goldAddedAmount
        goldStockRemain
        goldType
      }
    }
  }
`;
const GOLD_LAB_LIST = gql`
  query GoldLabList($query: GoldLabListQueryInput) {
    goldLabList(query: $query) {
      items {
        aType
        aTypePrice
        charged
        clinic {
          id
          name
          createdBy
        }
        monthlyGold {
          inlay
          sA
          aType
          pt
          pfg
        }
        id
        inlay
        inlayPrice
        labId
        month
        pfg
        pfgPrice
        pt
        ptPrice
        sA
        sAPrice
        year
        orders {
          id
          orderId
          goldType
          usedPrice
          goldUsed
          charged
          requestDate
        }
      }
      hasNext
      total
    }
  }
`;

const GET_GOLD_LAB_DETAILS = gql`
  query GetGoldLabDetail($query: GoldLabQueryDetailInput) {
    goldLabDetail(query: $query) {
      clinic {
        name
        id
      }
      orders {
        orderId
        goldType
        usedPrice
        goldUsed
        charged
        requestDate
      }
      monthlyGold {
        aType
        inlay
        pfg
        pt
        sA
      }
      aType
      id
      inlay
      pfg
      pt
      sA
    }
  }
`;

const GOLD_UNIT_PRICE = gql`
  query goldUnitPriceManageDetail($query: UnitPriceManageQueryInput) {
    goldUnitPriceManageDetail(query: $query) {
      labId
      inlay
      aType
      sA
      pt
      pfg
      history {
        updateGoldPriceAt
        goldType
        priceBefore
        priceAfter
      }
    }
  }
`;

const GET_LAB_GOLD_STORAGE_LIST = gql`
  query GetLabGoldStorageList {
    goldLabStorageList {
      items {
        aType
        clinic {
          name
          id
          createdBy
        }
        history {
          goldType
          isAddGoldToStorage
          orderId
          updateStorageAt
          weight
        }
        id
        inlay
        pfg
        pt
        sA
      }
    }
  }
`;

const ADD_LAB_STORAGE_GOLD = gql`
  mutation AddGoldToLabStorage($input: AddGoldToLabStorageInput) {
    addGoldToLabStorage(input: $input) {
      code
      message
    }
  }
`;

const GOLD_LAB_STORAGE_DETAIL = gql`
  query GoldLabStorageDetail($query: GoldLabStorageQueryInput) {
    goldLabStorageDetail(query: $query) {
      aType
      clinic {
        name
        id
      }
      history {
        goldType
        isAddGoldToStorage
        orderId
        updateStorageAt
        weight
      }
      id
      inlay
      pfg
      pt
      sA
    }
  }
`;

const GOLD_CLINIC_STORAGE_LIST = gql`
  query GoldClinicStorageList {
    goldClinicStorageList {
      items {
        aType
        clinic {
          id
          name
          createdBy
        }
        id
        inlay
        labId
        pfg
        pt
        sA
        orders {
          goldConsumed
          goldLeft
          goldReceived
          goldType
          goldUsed
          orderId
          orderPaymentAt
        }
      }
      hasNext
      total
    }
  }
`;

const GOLD_CLINIC_STORAGE_DETAIL = gql`
  query GoldClinicStorageDetail($query: GoldClinicStorageQueryInput) {
    goldClinicStorageDetail(query: $query) {
      aType
      clinic {
        id
        name
      }
      id
      inlay
      labId
      pfg
      pt
      sA
      orders {
        goldConsumed
        goldLeft
        goldReceived
        goldType
        goldUsed
        orderId
        orderPaymentAt
      }
    }
  }
`;

export {
    ADD_LAB_GOLD_STOCK,
    UPDATE_LAB_GOLD_UNIT_PRICE,
    GOLD_STOCK_DETAIL,
    GOLD_LAB_LIST,
    GET_GOLD_LAB_DETAILS,
    GOLD_UNIT_PRICE,
    GET_LAB_GOLD_STORAGE_LIST,
    ADD_LAB_STORAGE_GOLD,
    GOLD_LAB_STORAGE_DETAIL,
    GOLD_CLINIC_STORAGE_LIST,
    GOLD_CLINIC_STORAGE_DETAIL
};

