import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { CCButton, CCDialogSimple, CCTypography } from "styles/src/components";
import translate from "../Translate";

const useStyles = makeStyles(theme => ({
  title: { width: 316 },
  icon: {
    color: theme.palette.error.main,
    marginRight: 8
  },
  text1: {
    color: theme.palette.error.main
  },
  text2: {
    color: theme.palette.sub.main
  }
}));

const CVDialogSimpleDel = props => {
  const {
    warningTitle,
    warningDesc,
    onAgree,
    onDisagree,
    onClose,
    ...others
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <CCDialogSimple
      classes={classes}
      title={<WarningIcon className={classes.icon} />}
      contents={
        <>
          <CCTypography className={classes.text1}>{translate(intl, warningTitle)}</CCTypography>
          <CCTypography className={classes.text2}>{translate(intl, warningDesc)}</CCTypography>
        </>
      }
      endActions={
        <>
          <CCButton
            color="sub"
            onClick={() => {
              onDisagree && onDisagree();
            }}
          >
            {translate(intl, "common.ui.cancel")}
          </CCButton>
          <CCButton
            variant="contained"
            color="error"
            onClick={() => {
              onAgree && onAgree();
            }}
          >
            {translate(intl, "patientContextMenu.delete")}
          </CCButton>
        </>
      }
      // onCloseButton={onClose}
      onClose={onClose}
      {...others}
    />
  );
};

CVDialogSimpleDel.propTypes = {
  /** Dialog open 유무 */
  open: PropTypes.bool,
  /** Title of warning */
  warningTitle: PropTypes.string,
  /** Description of warning */
  warningDesc: PropTypes.string,
  /** Dialog close 시키는 함수 */
  onClose: PropTypes.func,
  /** 해당 item 삭제 동의 함수 */
  onAgree: PropTypes.func,
  /** 해당 item 삭제 비동의 함수 */
  onDisagree: PropTypes.func
};

CVDialogSimpleDel.defaultProps = {
  warningTitle: "common.ui.areYouSureYouWantToDeleteIt",
  warningDesc: "common.ui.deletedValuesCannotBeRecovered"
};

export default CVDialogSimpleDel;
