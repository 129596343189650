/*eslint react-hooks/exhaustive-deps: 0*/
import {
  useApolloClient, useQuery
} from "@apollo/client";
import { Box, FormControlLabel, Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  CVButton,
  CVIconButton,
  CVTextField,
  DialogWarningMessage,
  enqueueToast,
  ExcelDownloadButton,
  TypographyTooltip,
  InfiniteTable,
  translate
} from "components";
import { useSnackbar } from "notistack";
import {
  BATCH_REMOVE_PRODUCT, PRODUCT_LIST, UPDATE_IMPORTANT_PRODUCT
} from "queries/product";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CCTypography } from "styles/components";
import { CCPaper, CCTextField } from "styles/src/components";
import {
  AddIcon,
  DeleteIcon,
  MenunextIcon,
  SearchIcon,
  StarIcon
} from "styles/src/themes/common/icons";
import { STOCK_STATUS } from "types/inventory";
import { makeList } from "types/utils";
import { getCustomFormat, getMoment } from "utils/datetime";
import { CAPITALIZE_TYPE, useIntl } from "utils/language";
import { buildXLSXData } from "utils/table";
import { TabContext } from "../../Stock";
import DetailDialog from "./Component/DetailDialog";
import InventoryDialog from "./Component/InventoryDialog";
import ProductCreateDialog from "./Component/ProductCreateDialog";
import {
  TableHeader,
  TableItem
} from "./Component/ProductCreateDialog/components/TableItems";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  rootTwo: {
    height: "calc(110% - 70px)",
    padding: 16,
    backgroundColor: theme.palette.common.white,
    position: "relative"
  },
  rootThree: {
    width: "100%",
    height: "calc(100% - 110px)",
    minWidth: 1000
  },
  container: { height: "100%" },
  container__filter: {
    textAlign: "center"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  item__table: {
    height: "calc(90% - 96px)",
    width: "100%",
    alignItems: "center",
    textAlign: "center"
  },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  button__sms: {
    width: "110px",
    height: "24px",
    padding: "0px"
  },
  button__filter: {
    minWidth: "100px",
    minHeight: "10px",
    margin: "16px"
  },
  select: { width: "100px", margin: "4px" },
  select__w126: { width: "126px" },
  select__w155: { width: "155px" },
  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "50px",
    alignItems: "center"
  },
  head__field: {
    width: "180px"
  },
  headertwo: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  headerfilter: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  header__table: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  field: {
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    textAlign: "center"
  },
  table: {
    height: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  shrink: {
    background: theme.palette.common.white,
    paddingRight: "4px"
  },
  button_register: {
    padding: "0px",
    width: "120px",
    margin: "10px"
  },
  button_right: {
    marginTop: "10px",
    marginRight: "10px"
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "90px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },

  icon__barcode: {
    width: "20px",
    height: "20px",
    color: theme.palette.disabled.rgba
  },
  icon__star: {
    margin: 12,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  star__border__icon: {
    backgroundColor: "none"
  },
  background__color: {
    backgroundColor: theme.palette.result.main
  },
  change__color: {
    color: theme.palette.error.main
  }
}));

const initSelectedValue = {
  major: [],
  minor: null,
  name: "",
  alarm: null,
  stock: false,
  noStock: {}
};

// const PERMISSION = "productInventoryStatus";

const ProductTab = props => {
  const { product, majorProps } = props;

  const client = useApolloClient();
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const permission = false
  const [dialog, setDialog] = useState(false);
  const [detail, setDetail] = useState(false);
  const [inventory, setInventory] = useState(false);
  const [major, setMajor] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [searchResult, setSearchResult] = useState();
  const [selectedValue, setSelectedValue] = useState(initSelectedValue);
  const [noStock, setNoStock] = useState(false);
  const [appropriate, setAppropriate] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [alertDeleteDialog, setAlertDeleteDialog] = useState(false);
  const { setProduct } = useContext(TabContext);
  const [selected, setSelected] = useState(product);
  const isArabic = localStorage.getItem("lang") === "ar";
  const { enqueueSnackbar } = useSnackbar();
  const StockStatus = makeList(STOCK_STATUS);
  const ALL_SELECTION = intl.formatMessage({ id: "all" });

  const [productMajor, setProductMajor] = useState([ALL_SELECTION]);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const { data: productList, refetch: refetchGetProductList, loading } = useQuery(PRODUCT_LIST, {
    fetchPolicy: "network-only",
    variables: {
      query: {}
    },
  });

  useEffect(() => {
    if (!loading && productList) {
      const newData = productList?.productList?.items.sort((a, b) => {
        if (a?.storage?.important === b?.storage?.important) {
          if (a?.storage?.important) {
            if (a?.major === b?.major) {
              return a?.productName.localeCompare(b?.productName);
            } else {
              return a?.major.localeCompare(b?.major);
            }
          } else {
            let _aDate = getCustomFormat(a?.storage?.editDate, "X", "L");
            let _bDate = getCustomFormat(b?.storage?.editDate, "X", "L");

            if (_aDate === _bDate) {
              if (a?.major === b?.major) {
                return a?.productName.localeCompare(b?.productName);
              } else {
                return a?.major.localeCompare(b?.major);
              }
            } else {
              return b?.storage?.editDate - a?.storage?.editDate;
            }
          }
        } else {
          if (a?.storage?.important) {
            return -1;
          } else {
            return 1;
          }
        }
      });
      setSearchResult(newData || []);
    }
  }, [productList, loading])


  const refetchProductList = () => {
    refetchGetProductList({
      query: {
        searchText: selectedValue?.name,
        major: (productMajor && productMajor[0] !== ALL_SELECTION)
          ? productMajor
          : [],
        inventoryStatus: selectedValue.stock && selectedValue.stock !== "All" ? selectedValue.stock : ""
      },
    })
    setDeleteList([])
  };

  const onUpdateStar = useCallback(
    async (important, id) => {
      if (id) {
        await client.mutate({
          mutation: UPDATE_IMPORTANT_PRODUCT,
          variables: {
            id,
            important,
          }
        }).then(() => { refetchProductList() });
      }
    },
    [client]
  );

  const openWarningDeleteProduct = () => {
    const listProduct = searchResult.filter(item => deleteList.includes(item.id))
    if (listProduct.length > 0 && listProduct.filter(item => item.storage.currentCnt > 0)?.length > 0) {
      setErrorDialog(intl.formatMessage(
        { id: "warningDeleteProduct" },
      ))
    } else if (deleteList?.length > 0) {
      setAlertDeleteDialog(true)
    } else {
      setErrorDialog(intl.formatMessage(
        { id: "selectAnItemToDelete" },
      ))
    }
  }

  const onDelete = useCallback(async () => {
    await client
      .mutate({
        mutation: BATCH_REMOVE_PRODUCT,
        variables: {
          ids: deleteList
        }
      })
      .then(async ({ data }) => {

        refetchProductList()
        enqueueToast(
          enqueueSnackbar,
          ` ${intl.formatMessage({
            id: "deletedProduct"
          })}`
        );

      })
      .catch(errors => {
        const { graphQLErrors } = errors;
        if (graphQLErrors) {
          graphQLErrors.forEach(graphQLError => {
            setErrorDialog(graphQLError.message)
          });
        }

      });
    setAlertDeleteDialog(false)

  }, [client, deleteList]);


  useEffect(() => {
    setMajor(majorProps)
  }, [majorProps])

  const excelHeader = [
    {
      label: intl.formatMessage(
        { id: "majorCategory" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "major"
    },
    {
      label: intl.formatMessage(
        { id: "productName" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "productName"
    },
    {
      label: intl.formatMessage(
        { id: "manufacturer" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "manufacture"
    },
    {
      label: intl.formatMessage(
        { id: "unit" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "unit"
    },
    {
      label: intl.formatMessage(
        { id: "cntforBin" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "binCnt"
    },
    {
      label: intl.formatMessage(
        { id: "currentCnt" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "currentCnt"
    },
    {
      label: intl.formatMessage(
        { id: "optCnt" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "optCnt"
    },
    {
      label: intl.formatMessage(
        { id: "imminentExpireDate" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "imminentExpireDate"
    },
    {
      label: intl.formatMessage(
        { id: "lastModifiedDate" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "lastModifiedDate"
    },
    {
      label: intl.formatMessage(
        { id: "staffInCharge" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "staff"
    }
  ];

  return (
    <Box className={classes.rootTwo}>
      <CCPaper className={classes.rootThree}>
        {/* <PermissionBackdrop permission={PERMISSION} /> */}
        {!permission && (
          <Box className={classes.root}>
            <Grid className={classes.container} container direction="column" wrap={"nowrap"}>
              <Grid style={{ height: "50px" }} item>
                <Box className={classes.header}>
                  <CCTypography variant="h4">
                    {intl.formatMessage({ id: "productInventoryStatus" })}
                  </CCTypography>
                </Box>
              </Grid>
              <Grid style={{ height: "60px" }} item>
                <Box className={classes.headertwo}>
                  <Grid item className={classes.item}>
                    <Box width="200px">
                      <CVTextField
                        className={classes.head__field}
                        variant={"outlined"}
                        margin={"dense"}
                        label={intl.formatMessage({ id: "inventoryStatus" })}
                        select
                        value={selectedValue?.stock || ALL_SELECTION}
                        onChange={e => {
                          if (e?.target?.value === "2") {
                            setSelectedValue({
                              ...selectedValue,
                              noStock: {
                                currentCnt: 0
                              },
                              stock: e?.target?.value
                            });

                            setNoStock(true);
                            setAppropriate(false);
                          } else if (e?.target?.value === "1") {
                            setSelectedValue({
                              ...selectedValue,
                              noStock: {
                                isAppropriate: false
                              },
                              stock: e?.target?.value
                            });

                            setNoStock(false);
                            setAppropriate(true);
                          } else {
                            setSelectedValue({
                              ...selectedValue,
                              noStock: {},
                              stock: e?.target?.value
                            });

                            setNoStock(false);
                            setAppropriate(false);
                          }
                        }}
                      >

                        <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                          {ALL_SELECTION}
                        </MenuItem>
                        {Boolean(StockStatus) &&
                          StockStatus.map(item => (
                            <MenuItem key={item?.value} value={item?.value}>
                              {item?.label}
                            </MenuItem>
                          ))}
                      </CVTextField>
                    </Box>
                  </Grid>

                  <CVTextField
                    label={intl.formatMessage({ id: "majorCategory" })}
                    className={classes.head__field}
                    variant="outlined"
                    margin="dense"
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: value => {
                        return value.join(", ");
                      }
                    }}
                    value={productMajor}
                    onChange={e => {
                      const {
                        target: { value }
                      } = e;

                      if (value.length >= 1) {
                        if (value[value.length - 1] === ALL_SELECTION) {
                          setProductMajor([ALL_SELECTION]);
                        } else if (value[0] === ALL_SELECTION) {
                          setProductMajor(
                            value?.filter(item => item !== ALL_SELECTION)
                          );
                        } else {
                          setProductMajor(value);
                        }
                      } else {
                        setProductMajor([ALL_SELECTION])
                      }
                    }}
                  >
                    <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                      {ALL_SELECTION}
                    </MenuItem>
                    {Boolean(major) &&
                      major.map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </CVTextField>

                  <CCTextField
                    className={classes.head__field}
                    style={{ width: "500px" }}
                    variant={"outlined"}
                    margin={"dense"}
                    value={selectedValue?.name}
                    onChange={e =>
                      setSelectedValue({
                        ...selectedValue,
                        name: e.target.value
                      })
                    }
                    label={intl.formatMessage({
                      id: "productNameOrManufacture"
                    })}
                  />

                  <CVButton
                    color="secondary"
                    startIcon={<SearchIcon />}
                    variant={"contained"}
                    onClick={async () => {
                      refetchProductList()
                    }}
                  >
                    {intl.formatMessage({ id: "filter" })}
                  </CVButton>

                  <Box marginLeft="auto">
                    <CVButton
                      permission="inventoryManagement"
                      color="normal"
                      startIcon={<AddIcon />}
                      variant={"contained"}
                      onClick={() => {
                        setDialog(true);
                      }}
                    >
                      {intl.formatMessage({ id: "registerProduct" })}
                    </CVButton>
                  </Box>
                </Box>
              </Grid>
              <Grid style={{ height: "60px" }} item>
                <Box className={classes.headertwo}>
                  <Grid
                    className={classes.container__filter}
                    container
                    direction="row"
                  >
                    <Grid item className={classes.item}>
                      <Box
                        display="flex"
                        width="100px"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          padding="16px"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <CCTypography
                            style={{
                              color: theme.palette.secondary.main,
                              paddingRight: "5px"
                            }}
                            variant="h5"
                          >
                            {searchResult?.length}
                          </CCTypography>
                          <CCTypography variant="body1">
                            {intl.formatMessage(
                              { id: "results" },
                              {},
                              { capitalizeType: CAPITALIZE_TYPE.APA }
                            )}
                          </CCTypography>
                        </Box>
                      </Box>
                    </Grid>
                    <Box marginLeft="auto">
                      <CVButton
                        permission="inventoryManagement"
                        color="error"
                        startIcon={<DeleteIcon />}
                        variant={"contained"}
                        width={"120px"}
                        className={classes.button_right}
                        onClick={openWarningDeleteProduct}
                      >
                        {intl.formatMessage({ id: "removeSelectedProduct" })}
                      </CVButton>
                      {/* {console.log("ExcelDownloadButton.exportData", searchResult)} */}
                      <ExcelDownloadButton
                        type="icon"
                        exportData={
                          searchResult?.length &&
                          buildXLSXData(
                            isArabic ? excelHeader.reverse() : excelHeader,
                            searchResult.map(e => {
                              let _dup = Boolean(e?.storage?.expire?.length > 0)
                                ? [...e?.storage?.expire]
                                : [];
                              let _expireList = _dup
                                ? _dup.sort(
                                  (a, b) => a?.expireDate - b?.expireDate
                                )[0]
                                : null;
                              return {
                                ...e,
                                binCnt: e?.storage.binCnt,
                                unit: e?.storage?.unit,
                                currentCnt: e?.storage?.currentCnt,
                                optCnt: e?.storage?.optCnt,
                                lastModifiedDate: getCustomFormat(
                                  e?.storage?.editDate,
                                  "X",
                                  "L"
                                ),
                                imminentExpireDate: _expireList
                                  ? `${getCustomFormat(
                                    _expireList?.expireDate,
                                    "X",
                                    "L"
                                  )}(${_expireList?.expireCount})`
                                  : null,
                                staff: e?.editorID?.staffName
                              };
                            }),
                            `Inventory`
                          )
                        }
                        exportFileName={`${intl.formatMessage({
                          id: "productInventoryStatus"
                        })}_${getMoment()
                          .locale("en")
                          .format("YYYYMMDDHHmm")}`}
                      />{" "}
                    </Box>
                  </Grid>
                </Box>
              </Grid>
              <Grid className={classes.item__table} item>
                <InfiniteTable
                  className={classes.row__focus}
                  heads={[
                    {
                      key: "id",
                      headComponent: ({ cellData }) => {
                        let _data = searchResult?.map(e => e.id).filter(onlyUnique);
                        const _deleteList = deleteList.filter(value => _data.includes(value))

                        return (
                          <Checkbox
                            checked={_deleteList?.length === _data?.length}
                            style={{ padding: 10 }}
                            disableRipple
                            onChange={e => {
                              if (e.target.checked) {
                                setDeleteList(_data);
                              } else {
                                setDeleteList([]);
                              }
                            }}
                          />
                        );
                      },
                      width: 80,
                      component: ({ cellData }) => {
                        let _checked = deleteList?.includes(cellData);
                        return (
                          <TableItem
                            disableTypography
                            label={
                              <Checkbox
                                checked={_checked}
                                style={{ padding: 10 }}
                                disableRipple
                                onChange={e => {
                                  if (e.target.checked) {
                                    if (Boolean(deleteList?.length > 0)) {
                                      setDeleteList([...deleteList, cellData]);
                                    } else {
                                      setDeleteList([cellData]);
                                    }
                                  } else {
                                    setDeleteList(
                                      deleteList.filter(e => e !== cellData)
                                    );
                                  }
                                }}
                                className={classes.row__focus}
                              />
                            }
                          />
                        );
                      }
                    },
                    {
                      key: "major",
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "majorCategory" })}
                        />
                      ),
                      width: 140,
                      label: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "majorCategory" })}
                        />
                      ),
                      component: ({ cellData }) => {
                        return <TableItem label={cellData} />;
                      }
                    },
                    {
                      key: "storage",
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "importantProduct" })}
                        />
                      ),
                      width: 100,
                      label: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "importantProduct" })}
                        />
                      ),
                      component: ({ cellData, rowData }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={
                                  <StarIcon
                                    className={classes.star__border__icon}
                                  />
                                }
                                checkedIcon={<StarIcon />}
                                checked={cellData?.important}
                                className={classes.icon__button}
                                name="checkedH"
                              />
                            }
                            onChange={async () => {

                              await onUpdateStar(!cellData.important, rowData.id);
                            }}
                          />
                        );
                      }
                    },
                    {
                      key: "productName",
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "productName" })}
                        />
                      ),
                      width: 450,
                      component: ({ cellData }) => (
                        <TableItem label={
                          <TypographyTooltip placement="top">{cellData}</TypographyTooltip>
                        } />

                      )
                    },
                    {
                      key: "manufacture",
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "manufacturer" })}
                        />
                      ),
                      width: 120,
                      component: ({ cellData }) => {
                        return <TableItem label={cellData} />;
                      }
                    },

                    {
                      key: "storage",
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "unit" })}
                        />
                      ),
                      width: 80,
                      component: ({ cellData }) => {
                        return <TableItem label={cellData?.unit === "EA" ? cellData?.unit : `${cellData?.unit} (${cellData?.binCnt})`} />;
                      }
                    },

                    {
                      key: "storage",
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "currentCnt" })}
                        />
                      ),
                      width: 80,
                      component: ({ cellData, rowData }) => 
                      {
                        return <TableItem
                          
                          label={cellData?.currentCnt}
                          className={{
                            [classes.change__color]:  rowData?.storage?.currentCnt < rowData?.storage?.optCnt 
                          }}
                        />
                      }
                    },

                    {
                      key: "storage",
                      width: 80,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "optCnt" })}
                        />
                      ),
                      component: ({ cellData }) => (
                        <TableItem label={cellData?.optCnt} />
                      )
                    },

                    {
                      key: "storage",
                      width: 140,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({
                            id: "imminentExpireDate"
                          })}
                        />
                      ),
                      component: ({ cellData }) => {
                        if (Boolean(cellData?.expire?.length)) {
                          let _dup = Boolean(cellData?.expire?.length > 0)
                            ? [...cellData?.expire]
                            : [];
                          let list = _dup.sort(
                            (a, b) => a?.expireDate - b?.expireDate
                          )[0];
                          return (
                            <TableItem
                              className={{
                                [classes.change__color]: list?.expireThirtySoon
                              }}
                              label={`${getCustomFormat(
                                list?.expireDate,
                                "X",
                                "L"
                              )} (${list?.expireCount})`}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                    },
                    {
                      key: "storage",
                      width: 140,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "lastModifiedDate" })}
                        />
                      ),
                      component: ({ cellData }) => {
                        return (
                          <TableItem
                            label={getCustomFormat(
                              cellData?.editDate,
                              "X",
                              "L"
                            )}
                          />
                        );
                      }
                    },
                    {
                      key: "editorID",
                      width: 140,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "registrantEditor" })}
                        />
                      ),
                      component: ({ cellData }) => {
                        return (
                          <TableItem
                            label={cellData?.staffName || ""}
                          />
                        );
                      }
                    },
                    {
                      key: "productID",
                      width: 180,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "inoutManagement" })}
                        />
                      ),
                      component: ({ cellData, rowData }) => (
                        <Box>
                          {/*<CCButton*/}
                          {/*  className={classes.icon__barcode}*/}
                          {/*  onClick={() => {*/}
                          {/*    setQRcode(cellData);*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  <BarcodeIcon />*/}
                          {/*</CCButton>*/}

                          <CVButton
                            permission="inventoryManagement"
                            variant={"contained"}
                            color="normal"
                            className={classes.small__button}
                            onClick={e => {
                              if (selected?.productID === cellData) {
                                e.stopPropagation();
                              }

                              setInventory(rowData.id);
                            }}
                          >
                            {intl.formatMessage({ id: "inventory" })}
                          </CVButton>
                        </Box>
                      )
                    },
                    {
                      key: "id",
                      label: "",
                      width: 40,
                      component: ({ cellData, rowData }) => {
                        return (
                          <TableItem
                            disableTypography
                            label={
                              <CVIconButton
                                permission="inventoryManagement"
                                style={{ padding: 0, margin: 0 }}
                                disableRipple
                                variant={"text"}
                                size={"small"}
                                onClick={e => {
                                  if (selected?.id === cellData) {
                                    e.stopPropagation();
                                  }
                                  setDetail(rowData);
                                }}
                              >
                                <MenunextIcon />
                              </CVIconButton>
                            }
                          />
                        );
                      }
                    }
                  ]}
                  rowClickHighlight={true}
                  contents={searchResult || []}
                  selected={e => {
                    let _checked = deleteList?.includes(e.productID);
                    if (_checked) return true;

                    if (product) {
                      return (
                        product?.productID === e?.productID
                      );
                    } else if (selected) {
                      return selected?.productID === e?.productID;
                    }
                    return false
                  }}
                  onRowClick={({ rowData }) => {
                    setProduct(null);
                    if (
                      selected &&
                      selected?.productID === rowData?.productID
                    ) {
                      setSelected(null);
                      return;
                    } else {
                      setSelected(rowData);
                    }
                  }}
                />
              </Grid>
            </Grid>

            {Boolean(dialog) && (
              <ProductCreateDialog
                // className={classes.background__color}
                open={dialog}
                refetchGetProductList={refetchProductList}
                onClose={() => setDialog(false)}
              />
            )}

            {Boolean(detail?.id) && (
              <DetailDialog
                value={detail?.id}
                open={Boolean(detail?.id)}
                onClose={() => setDetail(false)}
                refetchProductList={refetchProductList}
              />
            )}

            {/*{Boolean(qrcode) && (*/}
            {/*  <QRDialog*/}
            {/*    value={qrcode}*/}
            {/*    open={Boolean(qrcode)}*/}
            {/*    onClose={() => setQRcode(false)}*/}
            {/*  />*/}
            {/*)}*/}

            {Boolean(inventory) && (
              <InventoryDialog
                valueId={inventory}
                open={Boolean(inventory)}
                onClose={() => setInventory(false)}
                refetchProductList={refetchGetProductList}
              />
            )}
          </Box>
        )}
        {Boolean(errorDialog) &&
          <DialogWarningMessage
            open={Boolean(errorDialog)}
            title={errorDialog}
            agreeButtonLabel={intl.formatMessage({ id: "common.ui.common.ui.ok" })}
            onAgree={() => { setErrorDialog(false) }}
            onClose={() => { setErrorDialog(false) }}
          />}
        {alertDeleteDialog &&
          <DialogWarningMessage
            open={Boolean(alertDeleteDialog)}
            title={translate(intl, "alertDelete", { number: deleteList?.length })}
            subTitle={translate(intl, "alertDeleteNote")}
            agreeButtonLabel={intl.formatMessage({ id: "removeSelectedProduct" })}
            onAgree={onDelete}
            onClose={() => { setAlertDeleteDialog(false) }}
            onDisagree={() => { setAlertDeleteDialog(false) }}
          />}
      </CCPaper>
    </Box>
  );
};

export default ProductTab;
