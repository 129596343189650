import { Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import CVTable from "components/CVTable";
import React, { useEffect, useState } from "react";
import { CCTypography } from "styles/components";
import { QueryFilter } from "views/Normal/GoldManagement/components";

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 8
  },
  table__title: {
    padding: 8,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__content: {
    height: 486
  }
}));

const HistoryTable = props => {
  const { title, heads, contents, filter, styles, onFilter, onRowClick } =
    props;

  const classes = useStyles();

  const [historyContents, setHistoryContents] = useState([]);

  useEffect(() => {
    setHistoryContents(contents);
  }, [contents]);

  return (
    <Grid container direction={"column"} className={clsx(classes.root, styles)}>
      <Grid item>
        <CCTypography variant="h4" className={classes.table__title}>
          {title}
        </CCTypography>
      </Grid>

      <Grid item>
        <QueryFilter filter={filter} onChange={onFilter} />
      </Grid>

      <Grid item className={classes.table__content}>
        <CVTable
          heads={heads}
          contents={historyContents}
          onRowClick={onRowClick}
        />
      </Grid>
    </Grid>
  );
};

export default HistoryTable;
