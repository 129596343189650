import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import * as moment_tz from "moment-timezone";
import { translate } from "components";
import React from "react";
import { insertComma } from "utils/etc";
import { getFontFaceByLang } from "utils/font";

const wordWrap = (str, maxWidth) => {
  const testWhite = x => {
    const white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
  };
  let newLineStr = "\n";
  let res = "";
  while (str.length > maxWidth) {
    let found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join("");
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join("");
      str = str.slice(maxWidth);
    }
  }

  return res + str;
};

const styles = StyleSheet.create({
  width13: {
    width: "13%"
  },
  width20: {
    width: "20%"
  },
  width25: {
    width: "25%"
  },
  width30: {
    width: "30%"
  },
  width11: {
    width: "11%"
  },
  width87: {
    width: "87%"
  },
  width33: {
    width: "33%"
  },
  width21: {
    width: "21%"
  },
  width34: {
    width: "34%"
  },
  width5: {
    width: "5%"
  },
  page: {
    backgroundColor: "#fff",
    // flexDirection: "column",
    // justifyContent: "flex-start",
    padding: 40
  },
  width65: {
    width: "65%"
  },
  color__red: {
    color: "#f50057"
  },
  tableColNoteContent: {
    width: "100%",
    overflow: "hidden",
    // minHeight: "fit-content",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColNote: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  width35: {
    width: "33%"
  },
  marginLeft10: {
    marginLeft: 10
  },
  width100: {
    width: "100%"
  },
  tableCellTotal: {
    padding: 10
  },
  title: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 600
  },
  paper: {
    // padding: "5px 20px",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    // margin: "5px 0px",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 0,
    // borderColor: "#ababab",
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: "#eeeeee"
  },
  paper__clinic: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 0,
    // borderColor: "#ababab",
    borderWidth: 1,
    borderStyle: "solid"
    // backgroundColor: "#eeeeee"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  row_col2_clinic: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    // borderColor: "#ababab",
    borderStyle: "solid"
  },

  padding_vertical: {
    paddingTop: 5,
    paddingBottom: 5,
    // paddingLeft: 20,
    textAlign: "left"
  },

  col: {
    display: "flex",
    flexDirection: "column"
  },
  col1_clinic: {
    display: "flex",
    width: "35%",
    // flexDirection: "column",
    // alignItems: "flex-start",
    // paddingLeft: "20px",
    justifyContent: "center",
    borderRightWidth: 1,
    alignItems: "center",
    // borderColor: "#ababab",
    borderStyle: "solid"
  },
  col2_clinic: {
    display: "flex",
    width: "65%",
    flexDirection: "column"
  },
  border: {
    // borderColor: "#ababab",
    borderWidth: 1,
    borderStyle: "solid"
  },
  border_left: {
    paddingTop: 5,
    paddingBottom: 5,
    // borderColor: "#ababab",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: "solid"
  },
  border__bottom: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0
  },
  title_text_field: {
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: 14,
    lineHeight: 1
  },
  info_text_field: {
    textAlign: "center",
    // borderColor: "#ababab",
    borderStyle: "solid",
    fontSize: 11,
    lineHeight: 1,
    fontWeight: 400
  },
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2
  },
  paddingLeft0: {
    paddingLeft: 0
  },
  info_text_field_bold: {
    paddingLeft: "20px",
    fontSize: 11,
    lineHeight: 1,
    fontWeight: 600
  },
  request_container: {
    display: "flex",
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "#fff"
  },
  white_background: {
    backgroundColor: "#fff"
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    marginTop: 5,
    marginBottom: 5,
    borderBottomWidth: 0
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  displayBetween: {
    justifyContent: "space-between",
    width: "100%"
  },
  borderBottomRight: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeader: {
    width: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    backgroundColor: "#eee",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColGold: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    width: "100%",
    minHeight: 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
    // padding: 5
  },
  tableTextItem: {
    fontSize: 9,
    fontWeight: 400,
    wordBreak: "break-all"
  },
  image: {
    width: "100%",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    height: 32
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  tableHeader: {
    backgroundColor: "#eeeeee"
  },
  request_header: {
    marginBottom: 5
  },
  comments: {
    display: "flex",
    flexDirection: "row",
    minHeight: 28,
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 10
  },
  commentsHeader: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    width: "25%",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    backgroundColor: "#eeeeee",
    minHeight: 28,
    height: "100%"
  },

  height100: {
    height: "100%"
  },
  commentsBody: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    width: "75%",
    minHeight: 28,
    height: "100%"
  },
  commentText: {
    width: "100%"
  },
  stamp: {
    width: 24,
    height: 24
  },
  footer: {
    width: "100%"
  },
  footer_text_field: {
    minWidth: "25%",
    width: "100%",
    fontSize: 11,
    lineHeight: 1,
    fontWeight: 400,
    paddingTop: 5,
    paddingBottom: 5
  },
  topDivider: {
    borderTopStyle: "solid",
    borderTopWidth: 1,
    paddingTop: 10
  },
  rightAlign: {
    textAlign: "right"
  },
  leftAlign: {
    textAlign: "right"
  },
  signitureArea: {
    paddingTop: 20,
    paddingBottom: 20,
    position: "relative"
  },
  paddingForSigniture: {
    paddingTop: 5,
    paddingRight: 35
  },
  noneBorder: {
    borderWidth: 0
  },
  justifyText: {
    textAlign: "justify"
  },
  paddingNote: {
    padding: "6px 6px",
    borderStyle: "solid"
  },
  tableNote: {
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 5,
    marginBottom: 5,
    width: "100%",
    minHeight: 175
  }
});

const maskBank = bankNum =>
  bankNum.split("").reduce((acc, cur, index) => {
    if (bankNum.length === 19) {
      if (index % 8 === 0 && acc) {
        return acc + "-" + cur;
      }
      return acc + cur;
    }

    if (index % 4 === 0 && acc) {
      return acc + "-" + cur;
    }
    return acc + cur;
  }, "");

const isLangAr = () => {
  return localStorage.getItem('lang') === 'ar'
}
const ClinicInfo = props => {
  const { data, intl } = props;
  const row_col2_clinic = [styles.row_col2_clinic, { flexDirection: isLangAr() ? 'row-reverse' : "row" }]
  const info_text_field_bold = [styles.info_text_field_bold, {textAlign:  isLangAr() ? 'right' : "left"}]
  return (
    <>
      <View style={[styles.paper__clinic, {flexDirection: isLangAr() ? 'row-reverse' : "row"}]}>
        <View style={[styles.col1_clinic, { borderLeftWidth: 1 } ]}>
          <Text style={[styles.info_text_field_bold, styles.paddingLeft0]}>
            {" "}
            {data?.partner?.name || ""}
          </Text>
          <Text style={[styles.info_text_field, styles.padding_vertical]}>
            {translate(intl, "payment.bill.sentDate")}:{" "}
            {data?.sendBillToCleverAt
              ? moment_tz(data.sendBillToCleverAt, "X")
                .tz(moment_tz.tz.guess(true))
                .format("L")
              : moment_tz()
                .tz(moment_tz.tz.guess(true))
                .format("L")}{" "}
          </Text>
        </View>

        <View style={styles.col2_clinic}>
          <View style={row_col2_clinic}>
            <Text style={[info_text_field_bold, styles.flex1]}>
              {translate(intl, "payment.bill.labName")}
            </Text>
            <Text
              style={[styles.info_text_field, styles.border_left, styles.flex2]}
            >
              {data?.lab?.labName || "-"}
            </Text>
          </View>
          <View style={row_col2_clinic}>
            <Text style={[info_text_field_bold, styles.flex1]}>
              {translate(intl, "payment.bill.owner")}
            </Text>
            <Text
              style={[styles.info_text_field, styles.border_left, styles.flex2]}
            >
              {data?.lab?.managerName || "-"}
            </Text>
          </View>
          <View style={row_col2_clinic}>
            <Text style={[info_text_field_bold, styles.flex1]}>
              {translate(intl, "payment.bill.telNo")}
            </Text>
            <Text
              style={[styles.info_text_field, styles.border_left, styles.flex2]}
            >
              {data?.lab?.labTelNumber || "-"}
            </Text>
          </View>
          <View style={row_col2_clinic}>
            <Text style={[info_text_field_bold, styles.flex1]}>
              {translate(intl, "settings.lab.eMail")}
            </Text>
            <Text
              style={[styles.info_text_field, styles.border_left, styles.flex2]}
            >
              {data?.lab?.email || "-"}
            </Text>
          </View>
          <View style={row_col2_clinic}>
            <Text style={[info_text_field_bold, styles.flex1]}>
              {translate(intl, "payment.bill.bank")}
            </Text>
            <Text
              style={[styles.info_text_field, styles.border_left, styles.flex2]}
            >
              {data?.lab?.bankName || "-"}
            </Text>
          </View>
          {/* <View style={row_col2_clinic}>
            <Text style={[info_text_field_bold, styles.flex1]}>
              {"Holder"}
            </Text>
            <Text
              style={[styles.info_text_field, styles.border_left, styles.flex1]}
            >
              {data?.lab?.bankAccountHolder || "-"}
            </Text>
          </View> */}
          <View style={[row_col2_clinic, styles.noneBorder]}>
            <Text style={[info_text_field_bold, styles.flex1]}>
              {translate(intl, "payment.bill.accountNo")}
            </Text>
            <Text
              style={[styles.info_text_field, styles.border_left, styles.flex2]}
            >
              {maskBank(`${data?.lab?.bankAccountNumber}`) || "-"} (
              {data?.lab?.bankAccountHolder || "-"})
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const maskPrice = (price, numBreak = 15) =>
  price.split("").reduce((acc, cur, index) => {
    if (index % numBreak === 0 && acc) {
      return acc + cur + "\n";
    }
    return acc + cur;
  }, "");

const TableProthesis = ({ data, intl }) => {
  const tableRow = [styles.tableRow, {flexDirection: isLangAr() ? 'row-reverse' : 'row'}]
  return (
    <>
      <View style={styles.table}>
        <View style={tableRow}>
          <View style={[styles.tableCol, styles.width25]}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.prosthesis")}
              </Text>
            </View>
          </View>
          {/* <View style={styles.tableCol}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.gold")}
              </Text>
            </View>
          </View> */}
          <View style={[styles.tableCol, styles.width25]}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.unpaid")}
              </Text>
            </View>
          </View>
          <View style={[styles.tableCol, styles.width25]}>
            <View
              style={[styles.tableCell, styles.tableHeader, styles.color__red]}
            >
              <Text style={styles.tableTextItem}>
                {translate( intl,"payment.bill.dC" )}
              </Text>
            </View>
          </View>
          <View style={[styles.tableCol, styles.width25]}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.total")}
              </Text>
            </View>
          </View>
        </View>
        <View style={tableRow}>
          <View style={[styles.tableCol, styles.width25]}>
            <View style={styles.tableCell}>
              <Text style={styles.tableTextItem}>
                {" "}
                {data?.prosthesis
                  ? maskPrice(insertComma(data?.prosthesis))
                  : "-"}
              </Text>
            </View>
          </View>
          {/* <View style={styles.tableCol}>
            <View style={styles.tableCell}>
              <Text style={styles.tableTextItem}>
                {" "}
                {data?.gold ? maskPrice(insertComma(data?.gold)) : "-"}
              </Text>
            </View>
          </View> */}
          <View style={[styles.tableCol, styles.width25]}>
            <View style={styles.tableCell}>
              <Text style={styles.tableTextItem}>
                {" "}
                {data?.unpaidAmount
                  ? maskPrice(insertComma(data?.unpaidAmount))
                  : "-"}
              </Text>
            </View>
          </View>
          <View style={[styles.tableCol, styles.width25]}>
            <View style={styles.tableCell}>
              <Text style={[styles.tableTextItem, styles.color__red]}>
                {" "}
                {data?.dc ? maskPrice(insertComma(data?.dc)) : "-"}
              </Text>
            </View>
          </View>
          <View style={[styles.tableCol, styles.width25]}>
            <View style={styles.tableCell}>
              <Text style={styles.tableTextItem}>
                {data?.total ? maskPrice(insertComma(data?.total)) : "-"}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const TableOrders = ({ data, intl }) => {
  const tableRow = [styles.tableRow, {flexDirection: isLangAr() ? 'row-reverse' : 'row'}]
  return (
    <>
      <View style={styles.table}>
        <View style={tableRow}>
          <View style={[styles.tableColHeader, styles.width13]}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.orderDate")}
              </Text>
            </View>
          </View>
          <View style={[styles.tableColHeader, styles.width11]}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.patient")}
              </Text>
            </View>
          </View>
          <View style={[styles.tableColHeader, styles.width11]}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.toothNo")}
              </Text>
            </View>
          </View>
          {/* <View style={[styles.width20]}>
            <View style={tableRow}>
              <View style={[styles.tableColHeader, styles.width100]}>
                <View style={[styles.tableCell, styles.tableHeader]}>
                  <Text style={styles.tableTextItem}>
                    {translate(intl, "labManageGold.gold")}
                  </Text>
                </View>
              </View>
            </View>
            <View style={tableRow}>
              <View style={[styles.tableColHeader, styles.width33]}>
                <View style={[styles.tableCell, styles.tableHeader]}>
                  <Text style={styles.tableTextItem}>
                    {translate(intl, "payment.bill.received")}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableColHeader, styles.width33]}>
                <View style={[styles.tableCell, styles.tableHeader]}>
                  <Text style={styles.tableTextItem}>
                    {translate(intl, "payment.bill.used")}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableColHeader, styles.width34]}>
                <View style={[styles.tableCell, styles.tableHeader]}>
                  <Text style={styles.tableTextItem}>
                    {translate(intl, "payment.bill.consumed")}
                  </Text>
                </View>
              </View>
            </View>
          </View> */}
          <View style={[styles.tableColHeader, styles.width20]} wrap={true}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.unitCostG")}
              </Text>
            </View>
          </View>
          <View style={[styles.tableColHeader, styles.width10]} wrap={true}>
            <View style={[styles.tableCell, styles.tableHeader]} wrap={true}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.unit")}
              </Text>
            </View>
          </View>
          <View style={[styles.tableColHeader, styles.width25]}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.total")}
              </Text>
            </View>
          </View>
        </View>
        {data.map(rowData => (
          <>
            <View style={tableRow}>
              <View style={[styles.tableCol, styles.width13]}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    {rowData?.orderDate
                      ? moment_tz(rowData?.orderDate, "X")
                          .tz(moment_tz.tz.guess(true))
                          .format("L")
                      : "-"}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCol, styles.width11]} wrap={true}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>{rowData?.patient}</Text>
                </View>
              </View>
              <View style={[styles.tableCol, styles.width11]}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    #{rowData?.teeth.join(", ")}
                  </Text>
                </View>
              </View>
              {/* <View style={[styles.width20]}>
                <View style={[tableRow, styles.flex1]}>
                  <View style={[styles.tableCol, styles.width33]}>
                    <View style={[styles.tableCell]}>
                      <Text style={styles.tableTextItem}>
                        {rowData?.gold.received}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableCol, styles.width33]}>
                    <View style={[styles.tableCell]}>
                      <Text style={styles.tableTextItem}>
                        {rowData?.gold.used}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableCol, styles.width34]}>
                    <View style={[styles.tableCell]}>
                      <Text style={styles.tableTextItem}>
                        {rowData?.gold.consumed}
                      </Text>
                    </View>
                  </View>
                </View>
              </View> */}
              <View style={[styles.tableCol, styles.width20]}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    {" "}
                    {rowData?.cost
                      ? maskPrice(insertComma(rowData?.cost))
                      : "-"}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCol, styles.width10]}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    {rowData?.amount}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCol, styles.width25]}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    {" "}
                    {rowData?.totalPrice
                      ? maskPrice(insertComma(rowData?.totalPrice))
                      : "-"}
                  </Text>
                </View>
              </View>
            </View>
          </>
        ))}
        {data.length === 0 && (
          <View style={tableRow}>
            <View style={[styles.tableCol, styles.width100]}>
              <View style={styles.tableCell}>
                <Text style={styles.tableTextItem}>
                  {translate(
                    intl,
                    "common.message.noInformationHasBeenEntered"
                  )}
                </Text>
              </View>
            </View>
          </View>
        )}
        <View
          style={[
            tableRow,
            styles.displayBetween,
            styles.borderBottomRight
          ]}
        >
          <View style={styles.tableCellTotal}>
            <Text style={styles.tableTextItem}>
              {isLangAr() ?
                `${data.length} : ${translate(intl, "payment.bill.totalOrders")}` :
                `${translate(intl, "payment.bill.totalOrders")}: ${data.length}`
              }
            </Text>
          </View>

          <View style={styles.tableCellTotal}>
            <Text style={styles.tableTextItem}>
              {insertComma(
                data.length > 0
                  ? data.reduce((acc, cur) => {
                      return acc + cur.totalPrice;
                    }, 0)
                  : 0
              )}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const TableGold = ({ dataGold, intl }) => {
  const tableRow = [styles.tableRow, {flexDirection: isLangAr() ? 'row-reverse' : 'row'}]
  return (
    <>
      <View style={styles.table}>
        <View style={tableRow}>
          <View style={styles.tableColGold}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.goldType")}
              </Text>
            </View>
          </View>
          <View style={styles.tableColGold}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.receivedG")}
              </Text>
            </View>
          </View>
          <View style={styles.tableColGold}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.usedG")}
              </Text>
            </View>
          </View>
          <View style={styles.tableColGold}>
            <View style={[styles.tableCell, styles.tableHeader]}>
              <Text style={styles.tableTextItem}>
                {translate(intl, "payment.bill.consumedG")}
              </Text>
            </View>
          </View>
        </View>

        {dataGold.map(rowData => (
          <>
            <View style={tableRow}>
              <View style={styles.tableColGold}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    {" "}
                    {rowData?.goldType ? rowData?.goldType : ""}
                  </Text>
                </View>
              </View>
              <View style={styles.tableColGold}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    {rowData?.received ? rowData?.received : 0}
                  </Text>
                </View>
              </View>
              <View style={styles.tableColGold}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    {rowData?.used ? rowData?.used : 0}
                  </Text>
                </View>
              </View>
              <View style={styles.tableColGold}>
                <View style={styles.tableCell}>
                  <Text style={styles.tableTextItem}>
                    {rowData?.consumed ? rowData?.consumed : 0}
                  </Text>
                </View>
              </View>
            </View>
          </>
        ))}
      </View>
    </>
  );
};

const ReceiptDocPDF = ({ data, intl, lang }) => {
  let fontConfig = getFontFaceByLang(lang)
  Font.register(fontConfig.fontFace);
  
  return (
    <Document>
      <Page
        size="A4"
        style={[
          styles.page,
          { fontFamily: fontConfig.fontFamily }
        ]}
        wrap
      >
        <View style={styles.title}>
          <Text style={[styles.title_text_field]}>
            {translate(intl, "payment.bill.paymentBill")}
          </Text>
        </View>
        <ClinicInfo data={data} intl={intl} />
        <TableProthesis data={data} intl={intl} />
        <TableOrders data={data?.orders} intl={intl} />


        <View style={[styles.row, {flexDirection: isLangAr? 'row-reverse' : 'row', alignItems: "flex-start"}]} break>
          {/* <View style={[styles.width65]}>
            <TableGold
              intl={intl}
              dataGold={
                data?.clinicSendGoldByType ? data.clinicSendGoldByType : []
              }
            />
          </View>
          <View style={{ width: 10 }} /> */}
          <View style={styles.tableNote}>
            <View
              style={[
                styles.tableCell,
                styles.border__bottom,

                styles.tableHeader
              ]}
            >
              <Text style={[styles.tableTextItem]}>
                {translate(intl, "payment.bill.notes")}
              </Text>
            </View>
            <View style={[styles.paddingNote]}>
              <Text style={[styles.tableTextItem]}>
                {data?.note ? data.note : ""}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ReceiptDocPDF;
