import React from "react";

const StockIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="currentColor">
            <path
              d="M15 0c.47 0 .88.21 1.15.55l1.39 1.68c.29.34.46.79.46 1.27V16c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V3.5c0-.48.17-.93.46-1.27L1.84.55C2.12.21 2.53 0 3 0zm-5 5H8v1H7c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H6v2h2v1h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1H8V8h4V6h-2V5zm4.93-4h-12l-.81 1h13.75l-.94-1z"
              transform="translate(-4869 -192) translate(4869 192) translate(3 3)"
            />
          </g>
          <path
            d="M0 0H24V24H0z"
            transform="translate(-4869 -192) translate(4869 192)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default StockIcon;
