import { DialogLastConnection, DialogPasswordExpire, DialogEmptyAddress } from "components";
import { AppContext } from "context/";
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useReducer,
    useRef
} from "react";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router";

const GET_LOCATION_LAB = gql`
  query {
    labDetail {
      address
      location{
        address
      }
    }
  }
`;

const checkItems = [
  { type: "passwordExpire" },
  { type: "emptyAddress" },
];

const initDialog = {
  lastConnectionIP: { state: false, value: null },
  passwordExpire: { state: false, value: null }
};

const dialogReducer = (state, action) => {
  return { ...state, [action.type]: action.value };
};

const DialogNotify = () => {
  const history = useHistory();
  const { appContext, dispatchApp } = useContext(AppContext);
  const [dialog, dispatchDialog] = useReducer(dialogReducer, initDialog);
  const [hasAddress, setHasAddress] = useState(false)
  const check = useRef(null);

  useQuery(GET_LOCATION_LAB, {
    onCompleted: data => {
      setHasAddress(Boolean(data?.labDetail?.address))
    }
  });

  const dispatchCheckList = useCallback(() => {
    const item = check.current.list.shift();
    if (item) {
      if (item.type === "lastConnectionIP") {
        dispatchDialog({ type: "lastConnectionIP", value: { state: true } });
      }
      if (item.type === "passwordExpire") {
        dispatchDialog({ type: "passwordExpire", value: { state: true } });
      }
      if (item.type === "emptyAddress") {
        if (!hasAddress) {
          dispatchDialog({ type: "emptyAddress", value: { state: true } });
        }
      }
    } else {
      dispatchApp({ type: "needNotify", target: false });
      dispatchApp({ type: "waitCheckNotify", target: true });
    }
  }, [dispatchApp, hasAddress]);

  useEffect(() => {
    if (appContext.needNotify) {
      check.current = { list: [...checkItems] };
      dispatchCheckList();
    } else {
      dispatchApp({ type: "waitCheckNotify", target: true });
    }
  }, [appContext.needNotify, dispatchApp, dispatchCheckList]);

  return (
    <>
      {dialog.lastConnectionIP.state && (
        <DialogLastConnection
          open={dialog.lastConnectionIP.state}
          onClose={() => {
            dispatchDialog({
              type: "lastConnectionIP",
              value: { state: false }
            });
            dispatchCheckList();
          }}
        />
      )}
      {dialog.passwordExpire.state && (
        <DialogPasswordExpire
          open={dialog.passwordExpire.state}
          onClose={() => {
            dispatchDialog({ type: "passwordExpire", value: { state: false } });
            dispatchCheckList();
            dispatchApp({ type: "waitCheckNotify", target: true });
            dispatchApp({ type: "needNotify", target: false });
          }}
        />
      )}
      {dialog.emptyAddress?.state && (
        <DialogEmptyAddress
          open={dialog.emptyAddress.state}
          onClose={() => {
            dispatchDialog({
              type: "emptyAddress",
              value: { state: false }
            });
            dispatchApp({ type: "needNotify", target: false });
            history.push("/setting");
            window.variables = { category: "common.ui.lab", openAddressInputDialog: true };
          }}
        />
      )}
    </>
  );
};

export default DialogNotify;
