import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CCTextField } from "styles/components";
import { CCTooltip } from "styles/src/components";

const RequiredTextField = props => {
  const {
    value,
    select,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;

  //value
  const [error, setError] = useState(false);

  //useEffect
  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);
  useEffect(() => {
    if (value) {
      setError(false);
    } else setError(true);
    required && value === "" && setError(true);
  }, [value, required]);

  return (
    <CCTooltip placement={"top"} 
      arrow title={select ? "" : value || ""}
    >
      <CCTextField
        margin={margin}
        variant={variant}
        label={label}
        onChange={({ target: { value } }) => {
          onChange({ target: { value: value } });
          if (value) {
            setError(false);
          } else {
            setError(true);
          }
        }}
        error={error}
        required={required}
        value={value || ""}
        select={select}
        {...others}
      />
    </CCTooltip>
  );
};

RequiredTextField.defaultProps = {
  value: "",
  margin: "dense",
  variant: "outlined",
  label: "Email",
  required: false,
  errorOnChange: () => {}
};

RequiredTextField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default RequiredTextField;
