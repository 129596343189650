import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  translate
} from "components";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import useStyles from './ButtonStyle'

const ButtonToday = (props) => {
  const { onClick, isActive } = props
  const intl = useIntl();
  const classes = useStyles();
  const today = moment(moment().format("YYYY-MM-DD 00:00:00")).unix();
  return (
    <Box
      className={clsx({
        [classes.button]: true,
        [classes.selected]: isActive
      })}
      onClick={e => {
        onClick({ from: today, to: today + 86399 })
      }}
    >
      {translate(intl, "labManageGold.today")}
    </Box>
  );
};

export default ButtonToday;
