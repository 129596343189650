import React from "react";

const ChartnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="qj6li9j7ea" x1="50%" x2="50%" y1="50%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
      <linearGradient id="qnzopcp6jb" x1="100%" x2="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#00E1D1" />
        <stop offset="100%" stopColor="#00BFA5" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#FFF"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-476.000000, -369.000000) translate(476.000000, 369.000000)"
          />
          <path
            fill="#FFF"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-476.000000, -369.000000) translate(476.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M19 2c6.627 0 12 5.373 12 12v4c0 .528-.034 1.048-.1 1.559l-1.36-1.36-.084-.071c-.236-.17-.556-.17-.792 0l-.084.071-1.247 1.247L29.888 22l.633-.631c-1.17 4.007-4.373 7.146-8.418 8.226l6.564-6.565-2.364-2.363-6.97 6.97.001 2.358L19 30h-6c-.97 0-1.912-.115-2.815-.332h6.877c-.547-1.223-.357-2.694.568-3.743l.152-.162L26 17.545V8.333l-.005-.168c-.084-1.333-1.16-2.41-2.494-2.493l-.168-.005H17.76l-.075-.191C17.077 4.029 15.661 3 14 3s-3.077 1.029-3.685 2.476l-.075.19H4.667l-.168.006-.15.014C6.53 3.414 9.6 2 13 2h6zM2 9.196v13.608c-.643-1.47-1-3.096-1-4.804v-4c0-1.708.357-3.333 1-4.804zm18.667 7.47v2.667H7.333v-2.666h13.334zm0-5.333V14H7.333v-2.667h13.334zM14 5.667c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333-1.333-.6-1.333-1.333.6-1.333 1.333-1.333z"
            transform="translate(-476.000000, -369.000000) translate(476.000000, 369.000000)"
          />
          <path
            fill="url(#qj6li9j7ea)"
            d="M14 3c1.661 0 3.077 1.029 3.685 2.476l.075.19h5.573c1.41 0 2.574 1.11 2.662 2.5l.005.167v9.212l-8.218 8.218c-1.055 1.056-1.296 2.618-.72 3.904H4.667c-1.41 0-2.574-1.11-2.662-2.499L2 27V8.333c0-1.41 1.11-2.574 2.499-2.661l.168-.005h5.573C10.8 4.12 12.267 3 14 3zm2.667 19H7.333v2.667h9.334V22zm4-5.333H7.333v2.666h13.334v-2.666zm0-5.334H7.333V14h13.334v-2.667zM14 5.667c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333 1.333-.6 1.333-1.333-.6-1.333-1.333-1.333z"
            transform="translate(-476.000000, -369.000000) translate(476.000000, 369.000000)"
          />
          <path
            fill="url(#qnzopcp6jb)"
            d="M26.303 20.667l2.364 2.363-6.97 6.97h-2.364v-2.363l6.97-6.97zM16.667 22v2.667H7.333V22h9.334zm11.913-3.8c.239-.24.61-.264.876-.072l.084.071 1.594 1.594c.24.24.263.61.072.876l-.072.085L29.888 22l-2.555-2.554 1.247-1.247z"
            transform="translate(-476.000000, -369.000000) translate(476.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default ChartnIcon;
