import { Box, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { InfiniteTable, SearchTextField, translate } from "components";
import React, { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {},
  textfield: { backgroundColor: "white", borderRadius: 4 },
  table: { width: 500, height: 400, color: "white" },
  table__cell: {
    padding: 8,
    cursor: "pointer",
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.8)" }
  }
}));

const usePopoverStyles = makeStyles(theme => ({
  paper: { backgroundColor: "rgba(0,0,0,0.70)" }
}));

const PopoverSearchBtn = props => {
  const {
    open = false,
    onClose = () => {},
    anchorEl = null,
    value = [],
    onClick = () => {}
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const popoverClasses = usePopoverStyles();

  const [field, setField] = useState("");
  const inputRef = useRef(null);

  const label = ({ cellData }) => (
    <CCTypography
      variant="h5"
      style={{ paddingLeft: 8, lineHeight: "40px", color: "inherit" }}
    >
      {cellData}
    </CCTypography>
  );
  const heads = [
    {
      label: translate(intl, "common.ui.category"),
      headComponent: label,
      key: "category",
      width: 150
    },
    {
      label: translate(intl, "settings.orderDetail.orderDetailItem"),
      headComponent: label,
      key: "btn",
      component: ({ cellData }) => (
        <div
          className={classes.table__cell}
          onClick={() => {
            onClick(cellData);
            onClose();
          }}
        >
          {cellData.tabList}
        </div>
      )
    }
  ];

  const flatList = useMemo(() => {
    const tmp = [];
    value.forEach((category, index) => {
      category.tabContents.forEach(btn => {
        tmp.push({
          category: category.category,
          btn: { categoryIndex: index, id: btn.id, tabList: btn.material }
        });
      });
    });
    return tmp;
  }, [value]);
  const filteredList = useMemo(() => {
    return flatList.filter(el => {
      if (el.btn.tabList?.toLowerCase().match(field.toLocaleLowerCase())) return true;
      return false;
    });
  }, [flatList, field]);

  return (
    <Popover
      classes={popoverClasses}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <SearchTextField
        className={classes.textfield}
        onIconClick={() => {
          if (field === inputRef.current.value) return;
          setField(inputRef.current.value);
        }}
        onKeyPress={e => {
          if (e.key === "Enter") {
            if (field === inputRef.current.value) return;
            setField(inputRef.current.value);
          }
        }}
        inputRef={inputRef}
      />
      <Box className={classes.table}>
        <InfiniteTable
          heads={heads}
          contents={filteredList}
          activeOddColor={false}
          backgroundColor={"none"}
          // onMoreContents={() => {
          //   setStartKey(lastKey);
          // }}
          // onRowClick={({ rowData }) => {
          //   onSelected(rowData);
          // }}
          // hasNext={!!lastKey}
        />
      </Box>
    </Popover>
  );
};

export default PopoverSearchBtn;
