import React from "react";

const ProgressIcon18 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M9.15 1.5c4.223 0 7.65 3.427 7.65 7.65 0 4.223-3.427 7.65-7.65 7.65-4.223 0-7.65-3.427-7.65-7.65 0-4.223 3.427-7.65 7.65-7.65zm0 1.53c-3.381 0-6.12 2.739-6.12 6.12 0 3.381 2.739 6.12 6.12 6.12 3.381 0 6.12-2.739 6.12-6.12 0-3.381-2.739-6.12-6.12-6.12zm.565 1.969l4.4 4.4-4.125 4.124-1.32-1.32 2-2-5.892-.242-.06-1.726 5.858.1-2.098-2.099 1.237-1.237z"
      />
      <path d="M0 0H18V18H0z" />
    </g>
  </svg>
);
export default ProgressIcon18;
