import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Files from "react-butterfiles";
import {  Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { CCButton } from "styles/components";
import { IconButton } from "@material-ui/core";
import { AddIcon } from "styles/icons";
import { translate } from "../../../../../../../../../components";
import { CAPITALIZE_TYPE } from "utils/language";
import {
  CCDialogSimple,
  CCTypography
} from "../../../../../../../../../styles/src/components";
import { WarningIcon } from "../../../../../../../../../styles/src/themes/common/icons";

const useStyles = makeStyles(theme => ({
  upload__input: {
    display: "none"
  },
  error: {
    color: theme.palette.error.main
  },
  add__button: {
    backgroundColor: theme.palette.white,
    minWidth: 0,
    padding: "3px 3px",
    marginRight: 8
  }
}));

const AttachmentUploadButton = props => {
  const { allowExtension, maxSize = "10mb", onChange, disabled, style , openUploadError, handleCloseUploadError} = props;

  const classes = useStyles();
  const intl = useIntl();
  const [openSizeCheck, setOpenSizeCheck] = useState(false);
  const [openCheckFile, setOpenCheckFile] = useState("");
  const [openDuplicateWarning, setOpenDuplicateWarning] = useState(false);

  const handleOnUploadComplete = file => {
    const ext = file.name.slice(file.name.lastIndexOf(".") + 1).toUpperCase();
    if (allowExtension.includes(ext)) {
      onChange(file);
    }else {
      setOpenCheckFile(allowExtension.join(", "));
    }
  };

  return (
    <>
      <Files
        multiple={false}
        maxSize={maxSize}
        multipleMaxCount={1}
        onSuccess={files => {
          handleOnUploadComplete(files[0]);
        }}
        onError={errors => {
          if (errors[0].type === "maxSizeExceeded") {
            setOpenSizeCheck(true);
          } else {
            console.error(errors);
          }
        }}
      >
        {({ browseFiles }) => {
          return (
          <IconButton onClick={() => {
                if (disabled) {
                  setOpenDuplicateWarning(true)
                } else browseFiles()
              }} style={{ color: "#01579B" }} aria-label="upload picture" component="span">
            <AddIcon />
          </IconButton>
          );
        }}
      </Files>
      {openSizeCheck && (
        <CCDialogSimple
          style={{ width: 300 }}
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpenSizeCheck(false)}
          contents={
            <>
              <CCTypography className={classes.error}>
                {translate(intl, "common.messages.maxFileSizeExceeded")}
                {/* {(() => (maxSize === "0.3mb" ? " 300kb" : " " + maxSize))()} */}
              </CCTypography>
            </>
          }
          endActions={
            <CCButton color="primary" onClick={() => setOpenSizeCheck("")}>
              {translate(intl, "common.ui.close")}
            </CCButton>
          }
        />
      )}
      {openDuplicateWarning && (
        <CCDialogSimple
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpenDuplicateWarning("")}
          contents={
            <Grid container direction={"column"}>
              <CCTypography className={classes.error}>
                {intl.formatMessage(
                  {
                    id: "duplicateUploadError",
                    defaultMessage: "Only one image can be uploaded at a time."
                  },
                  {},
                )}
              </CCTypography>
            </Grid>
          }
          endActions={
            <CCButton
              color="error"
              variant='contained'
              style={{ textTransform: 'unset'}}
              onClick={() => setOpenDuplicateWarning("")}
            >
              {intl.formatMessage({ id: "labOrder.confirm" }, {},{ capitalizeType: CAPITALIZE_TYPE.APA })}
            </CCButton>
          }
        />
      )}
       {openUploadError && (
        <CCDialogSimple
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => handleCloseUploadError("")}
          contents={
            <Grid container direction={"column"}>
              <CCTypography className={classes.error}>
                {intl.formatMessage(
                  {
                    id: "uploadFailedError",
                    defaultMessage: "Upload failed. Please try again."
                  },
                  {},
                  { capitalizeType: CAPITALIZE_TYPE.NONE }
                )}
              </CCTypography>
            </Grid>
          }
          endActions={
            <CCButton 
              color="error"
              variant='contained'
              style={{ textTransform: 'unset' }}
              onClick={() => handleCloseUploadError("")}
            >
              {intl.formatMessage({ id: "labOrder.confirm" })}
            </CCButton>
          }
        />
      )}
       {Boolean(openCheckFile) && (
        <CCDialogSimple
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpenCheckFile("")}
          contents={
            <>
              <CCTypography className={classes.error}>
                {translate(intl, "common.ui.thisFileExtensionsIsNotAllowed")}
              </CCTypography>
              <CCTypography>
                {translate(
                  intl,
                  "common.ui.youCanOnlyUploadPngJpgJpegZipStlFiles",
                  { number: openCheckFile, extensions: allowExtension.join(", ") }
                )}
              </CCTypography>
            </>
          }
          endActions={
            <CCButton color="primary" onClick={() => setOpenCheckFile("")}>
              {intl.formatMessage({ id: "common.ui.ok" })}
            </CCButton>
          }
        />
      )}
    </>
  );
};

export default AttachmentUploadButton;
