import React from "react";

const TabkeyIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12.59 7.41L16.17 11H2v2h14.17l-3.59 3.59L14 18l6-6-6-6-1.41 1.41zM21 6v12h2V6h-2z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default TabkeyIcon;
