const translate = (intl, id, params = {}) => {
  return intl.formatMessage(
    { id }, 
    {...params, 
      localizedCurrencySymbol: process.env.REACT_APP_LOCALIZED_CURRENCY_SYMBOL
        ? process.env.REACT_APP_LOCALIZED_CURRENCY_SYMBOL
        : "VNĐ", 
      localizedCurrencyShortName: process.env.REACT_APP_LOCALIZED_CURRENCY_SHORTNAME
        ? process.env.REACT_APP_LOCALIZED_CURRENCY_SHORTNAME
        : "VNĐ"
  });
};

export default translate;
