import { useQuery } from "@apollo/react-hooks";
import { DialogWarningMessage, PasswordChangeDialog } from "components";
import gql from "graphql-tag";
import React, { useState, useEffect } from "react";
import { useIntl } from "utils/language";

const GET_INFO = gql`
  query GetInFo {
    me {
      id
      staffId
      passwordExpiredRemainDays
    }
  }
`;

const DialogPasswordExpire = props => {
  const { open, onClose } = props;

  const [remainingDate, setRemainingDate] = useState(0);
  const [expire, setExpire] = useState(false);
  const [change, setChange] = useState(false);
  const [waiting, setWaiting] = useState(true);

  const intl = useIntl();

  const { data, loading} = useQuery(GET_INFO, {
    skip: !open,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data && !loading) {
      const remaining = data?.me?.passwordExpiredRemainDays || 0;
      if (remaining > 0 && remaining <= 7) {
        setRemainingDate(remaining);
        setWaiting(false);
        return;
      }
      if (remaining <= 0) {
        setExpire(true);
        setWaiting(false);
        return;
      }
      onClose();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <DialogWarningMessage
        open={open}
        title={
          expire ? (
            intl.formatMessage({ id: "passwordExpire" })
          ) : (
            <>
              {intl.formatMessage(
                { id: "remainingPasswordExpire" },
                {
                  remainingDate: remainingDate
                }
              )}
              <br />

              {intl.formatMessage({ id: "noteChangePassword" })}
            </>
          )
        }
        loading={waiting}
        loadingText={`${intl.formatMessage({
          id: "checkingPasswordExpire"
        })}...`}
        onAgree={() => {
          setChange(true);
        }}
        agreeButtonColor={"error"}
        agreeButtonLabel={intl.formatMessage({ id: "change" })}
        onDisagree={expire ? null : () => onClose()}
        disagreeButtonLabel={intl.formatMessage({ id: "common.ui.ok" })}
      />

      <PasswordChangeDialog
        open={change}
        onClose={() => {
          setChange(false);
          onClose();
        }}
        forceOpen={expire}
      />
    </>
  );
};

export default DialogPasswordExpire;
