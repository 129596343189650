import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import clsx from "clsx";
import React, { useState } from "react";
import { CCTypography } from "styles/components";
import { PreviewStlFile } from "../../../../../../../../../components";
import { getObject, Target } from "utils/s3";
import NotSupportedDialog from "../NotSupportedDialog";

const useStyles = makeStyles(theme => ({
  root: ({ isFromSender }) => ({
    backgroundColor: isFromSender
      ? theme.palette.secondary.main
      : theme.palette.white,
    color: isFromSender ? theme.palette.white : theme.palette.black,
    whiteSpace: "pre-line",
    wordBreak: "break-word"
  }),
  file__message: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: theme.palette.white,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    width: 128,
    height: 102,
    boxSizing: "border-box",
    cursor: "pointer"
  },
  file__icon: {
    fontSize: 24,
    color: theme.palette.border.main
  },
  icon__type__container: {
    display: "flex",
    alignItems: "center"
  },
  filetype: {
    textTransform: "uppercase",
    color: theme.palette.input.main
  },
  filename: {
    position: "absolute",
    bottom: 8,
    fontSize: "1rem",
    maxWidth: 110,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  }
}));

const FileMessage = props => {
  const { attachments = [], isFromSender, styles } = props;
  const classes = useStyles({ isFromSender });

  const [openMesh3D, setOpenMesh3D] = useState(false);
  const [openFileError, setOpenFileError] = useState(false);

  const handleClick = photo => {
    getObject(Target.PHOTO, photo.bucket, photo?.fileId).then(res => {
      if (res.includes(".stl")) {
        setOpenMesh3D(res);
      } else {
        setOpenFileError(true);
      }
    });

  };



  return (
    <Box className={clsx(classes.root, styles)}>
      {attachments.length &&
        attachments.map((attachment, index) => (
          <>
            <Box
              key={index}
              className={classes.file__message}
              onClick={() => {
                handleClick(attachment)
              }}
            >
              <Box className={classes.icon__type__container}>
                <img src={attachment.contentType === "STL" ? "/contents/stl.svg" : "/contents/zip.svg"} alt={"file_message"} />
              </Box>
              <CCTypography
                variant="body1"
                title={attachment?.filename}
                className={classes.filename}
              >
                {attachment?.filename}
              </CCTypography>

            </Box>

            {attachment.contentType === "STL" && Boolean(openMesh3D) ? (
              <PreviewStlFile
                openMesh3D={openMesh3D}
                handleClose={() => {
                  setOpenMesh3D("");
                }}
              />
            ) :
              openFileError && <NotSupportedDialog
                url={attachment.url}
                fileName={attachment.filename}
                attachment={attachment}
                open={openFileError}
                onClose={() =>
                  setOpenFileError(false)
                }
              />
            }
          </>
        ))}
    </Box>
  );
};

export default FileMessage;
