import React from "react";

const CorporationIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M21.565 15L23 16.412 16.314 23 12 18.765l1.435-1.412 2.879 2.823L21.565 15zM11.921 2C17.48 1.951 21.955 5.976 22 11.11c.007.86-.145 1.821-.46 2.805l-5.156 4.95L13.642 16l-2.915 2.866 2.72 2.72c-2.215.578-4.87.599-7.981-.247l-.37-.104.614-2.987C3.347 16.502 2.023 13.854 2 11.265 1.955 6.074 6.457 2.048 11.92 2zm2.327 5c-1.634.015-2.25 1.24-2.25 1.24s-.738-1.205-2.212-1.191c-1.747.016-2.8 1.694-2.786 3.28.017 2.017 1.658 4.49 5.391 4.62l.284.005.024 1.046c2.782-1.026 4.32-3.445 4.3-5.65-.019-2.347-1.586-3.36-2.751-3.35z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default CorporationIcon;
