import Box from '@material-ui/core/Box';
import { useSearchForExplorer } from 'components/Explorer/util';
import { Loading } from 'components/index';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { FilterCollapse, ResultList } from './component';

const SEARCH_RECEIPT_FOR_EXPLORER_PAGINATION = gql`
  query searchReceiptForExplorerPagination(
    $args: InputSearchReceipt
    $comparison: String
    $hospitalId: String!
    $match: String
    $page: InputPage
  ) {
    content: searchReceiptPagination(
      hospitalId: $hospitalId
      comparison: $comparison
      match: $match
      page: $page
      args: $args
    ) {
      ... on RetSearchReceiptPagination {
        data {
          ... on RetReceipt {
            chair
            doctor {
              ... on RetStaff {
                name
                id
              }
            }
            lastModifiedTime
            lastModifiedStaff
            receiptDate
            reservationDate
            staff {
              ... on RetStaff {
                name
                id
              }
            }
            type
            status
            id
            patient {
              ... on RetPatient {
                name
                id
                residentRegistrationNumber
                chartNumber
                newOrExistingPatient
              }
            }
          }
        }
        lastKey
      }
    }
  }
`;

const SEARCH_RECEIPT_FOR_EXPLORER = gql`
  query searchReceiptForExplorer(
    $hospitalId: String!
    $args: InputSearchPatient
    $match: String
    $comparison: String
    $subArgs: InputSearchReceipt
  ) {
    content: searchPatient(
      hospitalId: $hospitalId
      args: $args
      comparison: $comparison
      match: $match
    ) {
      ... on RetPatient {
        name
        id
        residentRegistrationNumber
        chartNumber
        newOrExistingPatient
        receipt(args: $subArgs) {
          ... on RetReceipt {
            chair
            doctor {
              ... on RetStaff {
                name
                id
              }
            }
            lastModifiedTime
            lastModifiedStaff
            receiptDate
            reservationDate
            staff {
              ... on RetStaff {
                name
                id
              }
            }
            type
            status
            id
          }
        }
      }
    }
  }
`;
const adaptData = (list) =>
  list.reduce((acc, cur) => {
    return acc.concat(
      cur?.receipt?.map &&
        cur.receipt.map((item) => {
          return {
            ...item,
            patient: {
              id: cur.id,
              name: cur.name,
              chartNumber: cur.chartNumber,
              residentRegistrationNumber: cur.residentRegistrationNumber,
              newOrExistingPatient: cur.newOrExistingPatient,
            },
          };
        }),
    );
  }, []);
const reducer = (state, action) => {
  switch (action.type) {
    // case "list":
    //   return { ...state, list: [...adaptData(action.target)] };
    case 'filter':
      return { ...state, filter: { ...action.target } };
    case 'addPatientFilter':
      return {
        ...state,
        filter: {
          ...state.filter,
          args: {
            ...state?.filter?.args,
            id: action.target,
          },
        },
      };
    case 'removePatientFilter':
      let _state = { ...state };
      if (_state?.filter?.args?.id) {
        delete _state.filter.args.id;
      }
      return _state;
    default:
      break;
  }
  return state;
};

const initializer = (props) => {
  // console.log(props);
  return {
    ...props,
    filter: {
      ...props.filter,
      args: {},
      subArgs: {
        receiptDate: {
          begin:
            props?.filter?.receiptDate?.begin || moment().startOf('day').unix(),
          end: props?.filter?.receiptDate?.end || moment().endOf('day').unix(),
        },
      },
    },
  };
};

const Receipt = (props) => {
  const { patient } = props;
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const collapseRef = useRef();
  // const { loading } = useQuery(SEARCH_RECEIPT_FOR_EXPLORER, {
  //   variables: {
  //     hospitalId: localStorage.getItem('labId'),
  //     ...state.filter,
  //     comparison: "and",
  //     match: "contains"
  //   },
  //   fetchPolicy: "network-only",
  //   onCompleted: data => {
  //     console.log(data);
  //     if (data?.content) {
  //       dispatchState({ type: "list", target: [...data.content] });
  //     }
  //   }
  // });
  const [list, setList] = useState([]);
  const { response, loading, nextPage, hasNext } = useSearchForExplorer(
    state?.filter?.args,
    state.filter?.subArgs,
    SEARCH_RECEIPT_FOR_EXPLORER,
    SEARCH_RECEIPT_FOR_EXPLORER_PAGINATION,
    adaptData,
    null,
    (a, b) => b.receiptDate - a.receiptDate,
    100,
  );

  useEffect(() => {
    // console.log(response);
    if (response?.length >= 0) {
      setList([...response]);
    }
  }, [response]);

  useEffect(() => {
    // console.log("Calendar-useEffect", patient);
    if (patient?.id) {
      dispatchState({
        type: 'addPatientFilter',
        target: patient.id,
      });
    } else {
      dispatchState({
        type: 'removePatientFilter',
      });
    }
  }, [patient]);

  return (
    <Box style={{ height: '100%', overflowY: 'hidden', position: 'relative' }}>
      <Box style={{ height: '100%', minWidth: 1280 }}>
        <Loading open={loading} msg={'Loading...'} />
        <FilterCollapse
          ref={collapseRef}
          collapse={props.collapse}
          filter={state.filter}
          onChange={(e) => {
            dispatchState({ type: 'filter', target: { ...e } });
          }}
        />
        <ResultList
          list={list?.length > 0 ? list : []}
          collapseRef={collapseRef}
          collapse={props.collapse}
          hasNext={Boolean(hasNext !== null)}
          onMoreContents={() => {
            nextPage && nextPage();
          }}
        />
      </Box>
    </Box>
  );
};

export default Receipt;
