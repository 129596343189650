import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVTextField } from "components";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { useIntl } from "utils/language";
import { CCTypography } from "styles/src/components";
import { useQuery } from "@apollo/client";
import { SEARCH_PRODUCT } from "queries/product";
import { Controller, useFormContext } from "react-hook-form";
import ButtonTabs from "../ButtonTabs";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: "10px",
    height: props => {
      if (Boolean(props?.length > 0)) {
        return "590px";
      } else {
        return "300px";
      }
    }
  },
  head__field: {
    width: 165
  },
  head__storage__field: {
    width: 195
  },
  head__search__field: {
    width: 230
  },
  bin__field: {
    marginLeft: 20,
    width: 80
  },
  margin__field: {
    marginLeft: 10,
    paddingBottom: 0,
    paddingLeft: 0
  },
  font__field: {
    fontSize: 14,
    marginTop: 20,
    marginLeft: 10,
    marginRight: 20,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    weight: "bold"
  },
  label__font__field: {
    fontSize: 14,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  top__margin__field: {
    marginTop: "-10px"
  },
  bottom__margin__field: {
    marginBottom: "-40px"
  },
  button__filter: {
    marginTop: "5px"
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  expire__info: {
    marginLeft: 10,
    color: props => {
      if (Boolean(props?.length > 0)) {
        return theme.palette.primary.main;
      } else {
        return theme.palette.sub.mainHex;
      }
    }
  }
}));

// for Tabs (ProductTab)
const DetailModify = forwardRef((props, ref) => {
  const { value, inOut } = props;

  const intl = useIntl();
  const [expireData, setExpireData] = useState([]);

  const initSelectedValue = {
    major: null,
    minor: null,
    name: null,
    alert: null,
    addition: false,
    expire: false,
    barcode: false,
    binCount: 0,
    currentCnt: 0,
    currentCntOut: 0,
    optCnt: 0
  };

  const [detail, setDetail] = useState(initSelectedValue);

  const classes = useStyles(expireData);

  useImperativeHandle(ref, () => ref?.current);

  const { control } = useFormContext();



  const tabList = Boolean(inOut === 0)
    ? ["errorCorrection", "otherPurchase", "otherAcquisition"]
    : ["materialUsage", "errorCorrection", "otherReturn", "otherAcquisition"];

  useEffect(() => {
    if (value?.id) {
      setDetail(value);
      setExpireData(value?.storage?.expire);
    }
  }, [value]);

  return (
    <>
      <Box className={clsx(classes.root)}>
        <CVTextField
          required={true}
          className={classes.head__field}
          variant={"outlined"}
          margin={"dense"}
          value={detail?.major || ""}
          label={intl.formatMessage({ id: "majorCategory" })}
          disabled={true}
          readOnly={true}
        >
          <MenuItem key={detail?.major} value={detail?.major}>
            {detail?.major}
          </MenuItem>
        </CVTextField>

        <CVTextField
          required={true}
          className={classes.head__search__field}
          variant={"outlined"}
          margin={"dense"}
          name={"searchField"}
          value={detail?.productName || ""}
          label={intl.formatMessage({ id: "productName" })}
          onChange={e => {
            setDetail({ ...detail, productName: e.target.value });
          }}
          disabled={true}
          readOnly={true}
        />

        <CVTextField
          required={true}
          className={classes.head__field}
          variant={"outlined"}
          margin={"dense"}
          name={"searchField"}
          value={detail?.manufacture || ""}
          label={intl.formatMessage({ id: "manufacturer" })}
          onChange={e => {
            setDetail({ ...detail, productName: e.target.value });
          }}
          disabled={true}
          readOnly={true}
        />

        <Box>
          <CCTypography className={classes.font__field}>
            {intl.formatMessage({ id: "productInformation" })}
          </CCTypography>
          <CVTextField
            className={clsx(classes.head__field, classes.head__storage__field)}
            value={detail?.storage?.currentCnt || 0}
            disabled={true}
            readOnly={true}
            inputRef={ref}
            variant={"outlined"}
            margin={"dense"}
            label={intl.formatMessage({ id: "currentCnt" })}
          />
          {Boolean(detail?.storage?.unit === "BIN") && (
            <Controller
              name={"binCnt"}
              control={control}
              defaultValue={detail?.storage?.binCnt || null}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;
                return (
                  <CVTextField
                    className={clsx(
                      classes.head__field,
                      classes.head__storage__field
                    )}
                    value={detail?.storage?.binCnt}
                    disabled={true}
                    inputRef={ref}
                    onChange={e => onChange(e.target.value)}
                    onBlur={onBlur}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "cntforBin" })}
                  />
                );
              }}
            />
          )}
        </Box>

        <ButtonTabs
          inOut={inOut}
          expireData={expireData}
          tabList={tabList}
          detail={detail}
          currentCnt={detail?.storage?.currentCnt}
          currentCntOut={detail?.storage?.currentCntOut}
        />
      </Box>
    </>
  );
});

export default DetailModify;
