import { useMutation } from "@apollo/react-hooks";
import { Box, Grid, makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { CVTextField, FileUploadPaper, translate, CVButton } from "components";
import moment from "moment";
import { CREATE_MESSAGE, ADD_DESIGN_CONFIRM_REQUEST } from "queries/labOrder";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCDialogSimple,
  CCTextField,
  CCTypography
} from "styles/src/components";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import { ProgressIcon } from "styles/src/themes/common/icons";
import { DESIGN_CONFIRM_STATUS } from "types/labOrder";
import { makeList } from "types/utils";

const useStyles = makeStyles(theme => ({
  root: { width: 536, padding: 8, boxSizing: "border-box" },
  padding8: {
    padding: 8
  },
  margin8: {
    margin: 8
  },
  item__attachments: {
    height: 270
  },
  text__description: {
    color: theme.palette.disabled
  },
  item__note: {
    width: "100%",
    display: "flex"
  },
  item__groupbutton: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16
  },
  full__width: {
    width: "100%"
  }
}));

const labOrderTypeItem = makeList(DESIGN_CONFIRM_STATUS);

const RequestDesignConfirmationModal = props => {
  const {
    value,
    open,
    onClose,
    labOrderId,
    isAddingRequest,
    labName,
    isCreateOrView,
    patientName
  } = props;

  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const [request, setRequest] = useState({
    attachments: []
  });
  const [multipleMaxCount, setMultipleMaxCount] = useState(6);

  const [createMessage] = useMutation(CREATE_MESSAGE, {
    onError: err => alert(err)
  });

  const [addDesignConfirmRequest] = useMutation(ADD_DESIGN_CONFIRM_REQUEST, {
    onError: err => alert(err)
  });

  useEffect(() => {
    setMultipleMaxCount(6 - request.attachments.length);
  }, [request])

  const handleSendRequest = async e => {
    const designConfirmRequest = {
      status: request.status || "2",
      attachments: request.attachments.map(attach => ({ ...attach, url: undefined })),
      note:
        request.note ||
        translate(intl, "labOrder.confirmationDesign.note.placeholder", { labName, patientName }),
      sender: request.sender,
      date: moment().unix()
    };
    // const result =
    await addDesignConfirmRequest({
      variables: {
        labOrderId,
        designConfirmRequest
      }
    });
    await createMessage({
      variables: {
        input: {
          chatGroupId: labOrderId,
          content: translate(intl, "labOrder.confirmationDesign.note.placeholder", { labName, patientName }),
        }
      }
    });
    onClose();
  };

  useEffect(() => {
    setRequest({
      attachments: value.attachments || [],
      sender: value.sender,
      note: value.note ? value.note : undefined,
      date: value.date
        ? moment.unix(value.date).format("DD-MM-YYYY")
        : moment().format("DD-MM-YYYY"),
      status: value.status
    });
  }, [value]);

  return (
    <CCDialogSimple
      open={open}
      onClose={onClose}
      contentsPadding={false}
      title={translate(intl, "common.ui.requestDesignConfirmation")}
      onCloseButton={onClose}
      contents={
        <Box className={classes.root}>
          <Grid container wrap={"nowrap"} alignItems={"center"}>
            <Grid item>
              <CCTextField
                style={{ width: 128 }}
                label={translate(intl, "labManageGold.date")}
                value={request.date || ""}
                variant="outlined"
                size={"small"}
                disabled
              />
            </Grid>
            <Grid item>
              <CCTextField
                style={{ width: 172 }}
                label={translate(intl, "common.ui.sender")}
                value={request.sender || ""}
                variant="outlined"
                size={"small"}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <CCTextField
                style={{ width: 172 }}
                label={translate(intl, "common.ui.status")}
                value={request.status || "2"}
                variant="outlined"
                size={"small"}
                select
                disabled={true}
                onChange={e => {
                  if (isAddingRequest)
                    setRequest({ ...request, status: e.target.value });
                }}
              >
                {labOrderTypeItem.map(item => {
                  const color =
                    item.value === "1"
                      ? theme.palette.primary.main
                      : item.value === "3"
                        ? theme.palette.error.main
                        : "";
                  return (
                    <CCMenuItem
                      key={item.value}
                      value={item.value}
                      color={color}
                    >
                      {item.label}
                    </CCMenuItem>
                  );
                })}
              </CCTextField>
            </Grid>

            <Grid item style={{ margin: "auto" }} />
          </Grid>
          <Grid item className={classes.padding8}>
            <CCTypography className={classes.text__description}>
              {
                translate(intl, "common.notes.attachTheFileForConfirmation")
              }
            </CCTypography>
          </Grid>
          <Grid
            item
            className={clsx(classes.item__attachments, classes.padding8)}
          >
            <FileUploadPaper
              actionExt={true}
              readonly={!isAddingRequest}
              onlyDirect
              title={translate(intl, "labManageGold.attachments")}
              value={request.attachments}
              onChange={e => {
                setMultipleMaxCount(6 - e.length)
                if (isAddingRequest) setRequest({ ...request, attachments: e });
              }}
              xs={6}
              message={``}
              buttonHidden={isCreateOrView === "view"}
              multiple={true}
              multipleMaxCount={multipleMaxCount}
            />
          </Grid>
          <Grid item className={classes.item__note}>
            <CVTextField
              permission="customerManagement"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              multiline
              rows={5}
              label={translate(intl, "payment.note")}
              value={request.note || ""}
              placeholder={isCreateOrView === "view" ? "" : translate(intl, "labOrder.confirmationDesign.note.placeholder", { labName, patientName })}
              onChange={e => {
                if (isAddingRequest)
                  setRequest({ ...request, note: e.target.value });
              }}
              className={classes.full__width}
              disabled={isCreateOrView === "view"}
            />
          </Grid>
          {isCreateOrView !== "view" && (
            <Grid item className={classes.item__groupbutton}>
              <CVButton
                color={"secondary"}
                onClick={onClose}
                margin="dense"
                className={classes.margin8}
              >
                {translate(intl, "common.ui.cancel")}
              </CVButton>
              <CVButton
                color={"primary"}
                variant={"contained"}
                startIcon={<ProgressIcon />}
                onClick={handleSendRequest}
                className={classes.margin8}
              >
                {translate(intl, "common.ui.send")}
              </CVButton>
            </Grid>
          )}
        </Box>
      }
    />
  );
};

RequestDesignConfirmationModal.defaultProps = {
  value: {
    sender: "",
    requestType: "",
    attachments: null,
    note: ""
  }
};

RequestDesignConfirmationModal.propTypes = {};

export default RequestDesignConfirmationModal;
