import produce from "immer";
import moment from "moment";
import { v4 as uuid } from "uuid";

const initSelectDate = {
  dateType: "requestDate",
  begin: moment().startOf("day").unix(),
  //begin: moment().add(-7, "day").startOf("day").unix(),
  end: moment().endOf("day").unix()
};

const initSearchVariables = {
  limit: 200,
  clinic: "",
  keyword: "",
  status: "",
  isYearFilter: false,
  monthFilterValue: (moment().get("month") + 1).toString(),
  yearFilterValue: moment().get("year").toString()
};

const initLabOrder = {
  isModify: "", // 1 수정상태
  type: "1", // 1 새의뢰, 2 수정, 3 재제작
  state: "0", // 0: 최초값 1:임시저장(수정가능), 2:의뢰완료(수정가능), 3:CAD Design, 4:컨펌 요청, 5:컨펌 완료 6:작업중, 7:작업완료, 8:배송중, 9:기공물 도착 10: 재제작
  requestId: "",
  clinic: "",
  connectedRequestId: "", // 연결 의뢰서 파일 이름
  requestDate: moment().unix(),
  deliveryRequestDate: moment().endOf("day").unix(),
  deliveryType: "0",
  teeth: [],
  target: "",
  missings: [],
  manufacture: {
    name: ""
  },
  shade: {
    target: "1", // 1 classic, 2 3D Master
    top: "",
    middle: "",
    bottom: ""
  },
  pontic: [],
  detail: [],
  photo: [],
  memo: "",
  mailCheck: false,
  note: "",
  shadeSetting: false,
  ponticSetting: false,
  deliveryDate: "0"
};

const initialState = {
  labOrder: {
    ...initLabOrder,
    id: "",
    lab: "",
    patient: "",
    chartNumber: "",
    name: "",
    doctor: "",
    staff: ""
  },
  labOrderList: [],
  labSetting: [],
  searchVariables: initSearchVariables
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SEARCH_VARIABLES":
      return {
        ...state,
        searchVariables: { ...state.searchVariables, ...action.value }
      };

    case "SET_SETTING":
      return {
        ...state,
        [action.name]: action.value
      };

    case "CHANGE_VALUE":
      return {
        ...state,
        labOrder: { ...state.labOrder, ...action.value }
      };

    case "ADD_SAVE_LIST":
      return produce(state, draft => {
        if (!state.labOrder?.ponticSetting) draft.labOrder.pontic = [];
        if (!state.labOrder?.shadeSetting) {
          draft.labOrder.shade.top = "";
          draft.labOrder.shade.middle = "";
          draft.labOrder.shade.bottom = "";
        }
        draft.labOrder.id = "imsi" + uuid();
        draft.labOrderList.push(draft.labOrder);
        draft.labOrder = { ...state.labOrder, ...initLabOrder };
      });

    case "ADD_SAVE_LAB_ORDER":
      return {
        ...state,
        labOrderList: [state.labOrder]
      };

    case "DELETE_SAVE_LIST":
      return {
        ...state,
        labOrderList: state.labOrderList.filter(item => item.id !== action.id)
      };

    case "RESET_LAB_ORDER":
      return {
        ...state,
        labOrder: { ...state.labOrder, ...initLabOrder },
        labOrderList: []
      };

    case "SET_LAB_ORDER":
      return {
        ...state,
        labOrder: { ...initLabOrder, ...action.value },
        labOrderList: []
      };

    case "INIT_LAB_ORDER":
      return {
        ...state,
        labOrder: { ...initialState.labOrder },
        labOrderList: []
      };

    default:
      return state;
  }
};

export { initialState, reducer, initSelectDate, initSearchVariables };
