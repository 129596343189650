import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CVButton, translate } from "components";
import React from "react";
import { useIntl } from "react-intl";
import {
  ModifyIcon,
  ProgressIcon,
  SaveIcon
} from "styles/src/themes/common/icons";
import {
  LAB_ARRIVE_TYPE,
  LAB_ORDER_STATE,
  LAB_ORDER_TYPE,
  TARGET
} from "types/labOrder";

const useStyles = makeStyles(theme => ({
  root: { width: "100%" },
  padding8: { padding: 8 }
}));

const ViewBottom = props => {
  const { onClose, value, onModify, onSave, buttonHidden, permission } = props;

  const classes = useStyles();
  const intl = useIntl();

  const {
    state,
    labType,
    id,
    deliveryType
    // patientId
  } = value;

  // 의뢰서 수정은 클래버랩일 경우는 임시저장 의뢰완료일 경우 활성, 일반기공소 인경우는 임시 저장일 경우만
  const checkModify = () => {
    if (
      labType === TARGET.CLEVER_LAB.value &&
      (state === LAB_ORDER_STATE.REQUEST_COMPLETED.value ||
        state === LAB_ORDER_STATE.TEMP_SAVE.value)
    ) {
      return true;
    } else if (
      labType === TARGET.NON_CLEVER_LAB.value &&
      state === LAB_ORDER_STATE.TEMP_SAVE.value
    ) {
      return true;
    } else {
      return false;
    }
  };

  // 수정의뢰 재재작 의뢰의 경우는 배송완료인 경우만 활성
  const checkDeliveryType = deliveryType === LAB_ARRIVE_TYPE.ARRIVE.value;

  return (
    <Box className={clsx(classes.root)}>
      <Grid container className={classes.padding8}>
        {checkModify() && (
          <Grid item className={classes.padding8} hidden={buttonHidden}>
            <CVButton
              variant={"outlined"}
              startIcon={<ModifyIcon />}
              onClick={() => {
                onClose();
                // dispatchTopbar({ type: "change", target: patientId });
                return onModify({
                  id: id
                });
              }}
            >
              의뢰서 수정
            </CVButton>
          </Grid>
        )}
        {checkDeliveryType && (
          <Grid item className={classes.padding8} hidden={buttonHidden}>
            <CVButton
              variant={"outlined"}
              startIcon={<ProgressIcon />}
              onClick={() => {
                // dispatchTopbar({ type: "change", target: patientId });
                onClose();
                return onModify({
                  id: id,
                  labState: value,
                  typeState: LAB_ORDER_TYPE.MODIFY_REQUEST.value
                });
              }}
            >
              수정의뢰
            </CVButton>
          </Grid>
        )}
        {checkDeliveryType && (
          <Grid item className={classes.padding8} hidden={buttonHidden}>
            <CVButton
              variant={"outlined"}
              startIcon={<ProgressIcon />}
              onClick={() => {
                // dispatchTopbar({ type: "change", target: patientId });
                onClose();
                return onModify({
                  id: id,
                  labState: value,
                  typeState: LAB_ORDER_TYPE.RE_REQUEST.value
                });
              }}
            >
              재제작 의뢰
            </CVButton>
          </Grid>
        )}
        <Grid item style={{ margin: "auto" }} />
        <Grid item className={classes.padding8}>
          <CVButton
            permission={permission}
            color={"secondary"}
            variant={"outlined"}
            startIcon={<SaveIcon />}
            onClick={() => {
              onSave();
              onClose();
            }}
          >
            {translate(intl, "common.ui.save")}
          </CVButton>
          <CVButton color={"secondary"} onClick={onClose}>
            {translate(intl, "common.ui.close")}
          </CVButton>
        </Grid>
      </Grid>
    </Box>
  );
};

ViewBottom.defaultProps = {};

ViewBottom.propTypes = {};

export default ViewBottom;
