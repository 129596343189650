import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { getObject, Target } from "utils/s3";

const useStyles = makeStyles(() => ({
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8
  },
}))
const ImageArchive = ({attachment}) => {
	const classes = useStyles();
	const [url, setUrl] = useState('')

	useEffect(() => {
		async function fetchImage() {
			const srcImage = await getObject(Target.PHOTO, attachment.bucket, attachment?.fileId);
			setUrl(srcImage)
		}
		fetchImage()
	}, [attachment])

	return (
		<img
			alt={attachment.filename}
			src={url}
			className={classes.image}
		/>
	)
}

export default ImageArchive