import { useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useReducer, useRef } from 'react';

const makeVariable = (
  args,
  subArgs,
  page,
  lastKey,
  pastVariables,
  keyChange,
) => {
  let _res = {
    hospitalId: localStorage.getItem('labId'),
    comparison: 'and',
    match: 'contains',
  };
  if (Boolean(args) && Object.keys(args).length > 0) {
    _res['args'] = { ...args };
    if (Boolean(subArgs) && Object.keys(subArgs).length > 0) {
      _res['subArgs'] = { ...subArgs };
    }
  } else {
    if (Boolean(subArgs) && Object.keys(subArgs).length > 0) {
      _res['args'] = { ...subArgs };
      if (checkChangeVariables(pastVariables, _res)) {
        _res['page'] = { ...page, startKey: null };
        if (lastKey.current === null) keyChange(null);
      } else {
        _res['page'] = {
          ...page,
          startKey: page.startKey || null,
        };
        // if (pastVariables.page.startKey === null) {
        //   _res["page"] = {
        //     ...page,
        //     startKey: page.startKey
        //   };
        // } else {
        // if (page.startKey === null) {
        //   _res["page"] = {
        //     ...page,
        //     startKey: page.startKey || null
        //   };
        // } else {
        //   if (lastKey.current === null) {
        //     _res["page"] = {
        //       ...page,
        //       startKey: page.startKey || null
        //     };
        //   } else {
        //     _res["page"] = {
        //       ...page,
        //       startKey: page.startKey || null
        //     };
        //   }
        // }
        // }
      }
    }
  }
  return _res;
};
const makeQuery = (args, subArgs, patientQuery, paginationQuery) => {
  return args && Object.values(args).length > 0
    ? patientQuery
    : paginationQuery;
};

const checkChangeVariables = (oldVal, newVal) => {
  let _old = { ...oldVal };
  let _new = { ...newVal };
  delete _old.page;
  delete _new.page;
  return JSON.stringify(_old) !== JSON.stringify(_new);
};

const responseInitializer = ({
  response = [],
  loading = false,
  error = null,
}) => {
  return {
    response: response,
    loading: loading,
    error: error,
  };
};

const responseReducer = (state, action) => {
  switch (action.type) {
    case 'input':
      return { ...state, ...action.target };
    case 'dataPatient':
      return {
        ...state,
        response: Boolean(action.adapt)
          ? Boolean(action?.sortFunc)
            ? action.adapt(action.target).sort(action.sortFunc)
            : action.adapt(action.target)
          : Boolean(action?.sortFunc)
          ? action.target.sort(action.sortFunc)
          : action.target,
      };
    case 'dataPagination':
      let _temp = { ...state };
      let _target = { ...action.target };
      _temp.response = action.reset
        ? Boolean(action.adapt)
          ? Boolean(action?.sortFunc)
            ? action.adapt([..._target.data]).sort(action.sortFunc)
            : action.adapt([..._target.data])
          : Boolean(action?.sortFunc)
          ? [..._target.data].sort(action.sortFunc)
          : [..._target.data]
        : _temp.response.concat(
            Boolean(action.adapt)
              ? Boolean(action?.sortFunc)
                ? action.adapt([..._target.data]).sort(action.sortFunc)
                : action.adapt([..._target.data])
              : Boolean(action?.sortFunc)
              ? [..._target.data].sort(action.sortFunc)
              : [..._target.data],
          );
      return { ..._temp };
    default:
      break;
  }
  return state;
};

const useSearchForExplorer = (
  args,
  subArgs,
  patientQuery,
  paginationQuery,
  adaptFuncPatientQuery,
  adaptFuncPaginationQuery,
  sortFunc,
  limit,
) => {
  const pastVariables = useRef(null);
  const lastKey = useRef(null);
  const startKey = useRef(null);
  // console.log("startKey", startKey);
  // console.log("lastKey", lastKey);

  const { data, loading, error, variables, refetch } = useQuery(
    makeQuery(args, subArgs, patientQuery, paginationQuery),
    {
      variables: {
        ...makeVariable(
          args,
          subArgs,
          {
            startKey: startKey.current,
            limit: limit,
          },
          lastKey,
          pastVariables.current,
          (e) => (startKey.current = e),
        ),
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        let _reset =
          checkChangeVariables(pastVariables.current, variables) ||
          (startKey.current === null && lastKey.current === null);
        // console.log("oncomplete", _reset, data);
        if (
          variables.hasOwnProperty('page') &&
          !data.content.hasOwnProperty('length')
        ) {
          lastKey.current = data?.content?.lastKey
            ? data?.content?.lastKey?.length === 0
              ? null
              : data.content.lastKey
            : null;
          dispatchResult({
            type: 'dataPagination',
            target: { ...data.content },
            adapt: adaptFuncPaginationQuery,
            reset: _reset,
            sortFunc: sortFunc,
          });
        } else {
          lastKey.current = null;
          dispatchResult({
            type: 'dataPatient',
            target: [...data.content],
            adapt: adaptFuncPatientQuery,
            reset: _reset,
            sortFunc: sortFunc,
          });
        }
        pastVariables.current = { ...variables };
      },
    },
  );
  const [result, dispatchResult] = useReducer(
    responseReducer,
    { data: data, loading: loading, error: error },
    responseInitializer,
  );
  const nextPage = useCallback(() => {
    startKey.current = lastKey.current === null ? null : { ...lastKey.current };
    refetch({
      ...makeVariable(
        args,
        subArgs,
        {
          startKey: startKey.current,
          limit: limit,
        },
        lastKey,
        pastVariables.current,
        (e) => (startKey.current = e),
      ),
    });
  }, [lastKey, startKey, args, subArgs, limit, pastVariables, refetch]);
  const fetchInit = useCallback(() => {
    // console.log("fetchInit");
    startKey.current = null;
    lastKey.current = null;
    refetch({
      ...makeVariable(
        args,
        subArgs,
        {
          startKey: startKey.current,
          limit: limit,
        },
        lastKey,
        pastVariables.current,
        (e) => (startKey.current = e),
      ),
    }).then((props) => {
      const { data } = props;
      // console.log(props);
      let _reset =
        checkChangeVariables(pastVariables.current, variables) ||
        (startKey.current === null && lastKey.current === null);
      // console.log("fetchInit - oncomplete", _reset, data);
      if (
        variables.hasOwnProperty('page') &&
        !data.content.hasOwnProperty('length')
      ) {
        lastKey.current = data?.content?.lastKey
          ? data?.content?.lastKey?.length === 0
            ? null
            : data.content.lastKey
          : null;
        dispatchResult({
          type: 'dataPagination',
          target: { ...data.content },
          adapt: adaptFuncPaginationQuery,
          reset: _reset,
          sortFunc: sortFunc,
        });
      } else {
        lastKey.current = null;
        dispatchResult({
          type: 'dataPatient',
          target: [...data.content],
          adapt: adaptFuncPatientQuery,
          reset: _reset,
          sortFunc: sortFunc,
        });
      }
    });
  }, [
    startKey,
    lastKey,
    refetch,
    args,
    subArgs,
    pastVariables,
    limit,
    variables,
    adaptFuncPatientQuery,
    adaptFuncPaginationQuery,
    sortFunc,
  ]);
  useEffect(() => {
    dispatchResult({ type: 'input', target: { loading: loading } });
  }, [loading]);

  return {
    ...result,
    fetchInit: fetchInit,
    nextPage: nextPage,
    hasNext: lastKey.current,
  };
};

export { useSearchForExplorer };
