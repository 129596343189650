import React from "react";

const ResignIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12 2c2.76 0 5 2.24 5 5v2h1c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V11c0-1.1.9-2 2-2h9.1V7c0-1.71-1.39-3.1-3.1-3.1-1.71 0-3.1 1.39-3.1 3.1H7c0-2.76 2.24-5 5-5zm6 9H6v10h12V11zm-3.025 3.157c.65 0 1.162.205 1.536.615.374.41.56.992.56 1.745v.519h-3.028l.028.15c.057.241.172.44.344.597.206.187.468.281.784.281.49 0 .872-.177 1.147-.532l.624.699-.1.13c-.178.208-.402.375-.673.5-.325.151-.686.227-1.081.227-.698 0-1.265-.214-1.703-.642-.438-.427-.657-.997-.657-1.71v-.122l.008-.235c.025-.384.115-.733.269-1.046.184-.377.446-.667.784-.87.339-.204.725-.306 1.158-.306zm-5.33-1.555l.245.006c.638.033 1.139.2 1.504.499.41.337.615.813.615 1.428 0 .437-.095.8-.284 1.092-.189.292-.475.524-.859.697l1.384 2.614V19h-1.415l-1.2-2.342h-1.05V19H7.267v-6.398h2.378zm5.326 2.584c-.252 0-.457.085-.613.254-.157.17-.257.414-.301.73h1.766v-.101l-.009-.135c-.024-.217-.097-.39-.22-.518-.146-.154-.354-.23-.623-.23zm-5.326-1.517h-1.06v1.92H9.65c.331 0 .587-.083.769-.252.182-.168.272-.4.272-.696 0-.302-.085-.54-.257-.712-.171-.173-.434-.26-.788-.26z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default ResignIcon;
