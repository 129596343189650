import Box from "@material-ui/core/Box";
import { FileList } from "components/Explorer/Component/FilePhoto/component";
import { Loading } from "components/index";
import React from "react";

const FilePhoto = props => {
  return (
    <Box style={{ height: "100%", overflowY: "hidden", position: "relative" }}>
      <Box style={{ height: "100%", minWidth: 1280 }}>
        <Loading open={false} msg={"Loading..."} />
        <FileList {...props} />
      </Box>
    </Box>
  );
};

export default FilePhoto;
