function isGlobalMode() {
  return process.env.REACT_APP_OPMODE === `global`;
}

const fetchJSONSetting = (defs, name, path) => {
  fetch(path)
    .then(res => res.text())
    .then(res => {
      defs[name] = JSON.parse(res);
    });
};

var oeSettingDefs = {};

// fetchJSONSetting(oeSettingDefs, "vi", "sheets/oesetting.vietnam.json");
// fetchJSONSetting(oeSettingDefs, "ru", "sheets/oesetting.russia.json");
// fetchJSONSetting(oeSettingDefs, "ar", "sheets/oesetting.arab.json");
// fetchJSONSetting(oeSettingDefs, "en", "sheets/oesetting.english.json");
// fetchJSONSetting(oeSettingDefs, "ko", "sheets/oesetting.korea.json");

var photoDefs = { ko: [], en: [], vi: [], ru: [], ar: [], k3s: [] };

// [
//   "sheets/photos.vietnam.json",
//   "sheets/consults.vietnam.json",
//   "sheets/libraries.vietnam.json"
// ].forEach(defs => {
//   fetch(defs)
//     .then(res => res.text())
//     .then(res => {
//       photoDefs["vi"] = photoDefs["vi"].concat(JSON.parse(res));
//     });
// });

// [
//   "sheets/photos.english.json",
//   "sheets/consults.english.json",
//   "sheets/libraries.english.json"
// ].forEach(defs => {
//   fetch(defs)
//     .then(res => res.text())
//     .then(res => {
//       photoDefs["en"] = photoDefs["en"].concat(JSON.parse(res));
//     });
// });

// [
//   "sheets/photos.korea.json",
//   "sheets/consults.korea.json",
//   "sheets/libraries.korea.json"
// ].forEach(defs => {
//   fetch(defs)
//     .then(res => res.text())
//     .then(res => {
//       photoDefs["ko"] = photoDefs["ko"].concat(JSON.parse(res));
//     });
// });

// [
//   "sheets/photos.russia.json",
//   "sheets/consults.russia.json",
//   "sheets/libraries.russia.json"
// ].forEach(defs => {
//   fetch(defs)
//     .then(res => res.text())
//     .then(res => {
//       photoDefs["ru"] = photoDefs["ru"].concat(JSON.parse(res));
//     });
// });

// [
//   "sheets/photos.arab.json",
//   "sheets/consults.arab.json",
//   "sheets/libraries.arab.json"
// ].forEach(defs => {
//   fetch(defs)
//     .then(res => res.text())
//     .then(res => {
//       photoDefs["ar"] = photoDefs["ar"].concat(JSON.parse(res));
//     });
// });

// [
//   "sheets/photos.k3s.json",
//   "sheets/consults.k3s.json",
//   "sheets/libraries.k3s.json"
// ].forEach(defs => {
//   fetch(defs)
//     .then(res => res.text())
//     .then(res => {
//       photoDefs["k3s"] = photoDefs["k3s"].concat(JSON.parse(res));
//     });
// });

const getPhotoDefs = language => {
  if (process.env.REACT_APP_HOSTMODE === "k3s") {
    return photoDefs["k3s"];
  }

  return photoDefs.hasOwnProperty(language)
    ? photoDefs[language]
    : photoDefs["en"];
};

const LocalizeVariable = {
  get helpDeskPhoneNumber() {
    switch (this.countryCode) {
      case "vi":
        return "024 3226 2932";
      case "ru":
        return "+8 (800) 533-99-87";
      case "eg":
        return "010 50 555 687";
      case "ko":
      default:
        return "1577-9115";
    }
  },
  get zaloEndPoint() {
    return "zalo";
  },
  get zaloOAStorageKey() {
    return "zaloOA";
  },
  get zaloOAuthPath() {
    return `https://oauth.zaloapp.com/v3/oa/permission?redirect_uri=${this.zaloCallbackUrl}`;
  },
  get zaloAppId() {
    return "1942346243348925970";
  },
  get zaloCallbackUrl() {
    return process.env.REACT_APP_ZALO_CALLBACK_URL
      ? process.env.REACT_APP_ZALO_CALLBACK_URL
      : "https://cvzalo.develop.vsmart00.com/api/zalo/callback";
  },
  get cyberLotusManual() {
    if (process.env?.REACT_APP_MINIO_DOC_URL) {
      switch (this.countryCode) {
        case "vi":
          return process.env.REACT_APP_MINIO_DOC_URL + "/cyberLotus_vi.pdf";
        default:
        case "en":
          return process.env.REACT_APP_MINIO_DOC_URL + "/cyberLotus_en.pdf";
      }
    } else {
      return null;
    }
  },
  get currencyUnit() {
    switch (this.countryCode) {
      case "vi":
        return "VNĐ";
      case "en":
        return "$";
      case "ru":
        return "₽";
      case "eg":
        return "E£";
      case "ko":
      default:
        return "₩";
    }
  },
  direction: language => {
    switch (language) {
      case "ar":
        return "rtl";
      case "vi":
      case "en":
      case "en-eg":
      case "ru":
      case "ko":
      default:
        return "ltr";
    }
  },
  googleMapLanguage: language => {
    switch (language) {
      case "ar":
        return "ar";
      case "vi":
        return "vi";
      case "ru":
        return "ru";
      case "ko":
        return "ko";
      case "en":
      default:
        return "en";
    }
  },
  get oeSetting() {
    switch (this.countryCode) {
      case "vi":
        return oeSettingDefs["vi"];
      case "ru":
        return oeSettingDefs["ru"];
      case "ar":
        return oeSettingDefs["ar"];
      case "eg":
        return oeSettingDefs["en"];
      case "korea":
      default:
        return oeSettingDefs["ko"];
    }
  },
  get countryCode() {
    switch (process.env.REACT_APP_REGION) {
      case "vietnam":
        return "vi";
      case "russia":
        return "ru";
      case "egypt":
        return "eg";
      case "korea":
        return "ko";
      case "develop":
      default:
        return "en";
    }
  },
  get isUsingMeridiem() {
    switch (this.countryCode) {
      case "vi":
        return false;
      case "ru":
        return false;
      case "eg":
        return false;
      case "ko":
        return true;
      case "develop":
      default:
        return true;
    }
  },
  get countryPhoneCode() {
    switch (this.countryCode) {
      case "vi":
        return "vn";
      case "ru":
        return "ru";
      case "eg":
        return "eg";
      case "ko":
        return "kr";
      case "develop":
      default:
        return "kr";
    }
  },

  get isDevelop() {
    return process.env.REACT_APP_REGION === "develop";
  },

  get countryAddress() {
    switch (process.env.REACT_APP_REGION) {
      case "vietnam":
        return "VATECH VIET CO,LTD | 4th Floor, TTC Building, 19 Duy Tan Street, Cau Giay District, Hanoi | 024 3226 2932";
      case "russia":
        return "117246, г Москва, Научный пр-д, д 17, 2 подъезд / +7 (495) 967-90-44";
      case "egypt":
        return "VALUE ADDED TECHNOLOGY MENA FZ-LLC | 16 Nehro St., El Merryland, Heliopolis Cairo, EGYPT | 010 50 555 687";
      case "korea":
      case "develop":
      default:
        return "(주)바텍엠시스 | 경기도 화성시 삼성1로2길 13 | 고객센터 1577-9115";
    }
  },

  get defaultLanguage() {
    switch (process.env.REACT_APP_REGION) {
      case "vietnam":
        return "vi";
      case "russia":
        return "ru";
      case "egypt":
        return "en-eg";
      case "korea":
        return "ko";
      case "develop":
      default:
        return "en";
    }
  },
  get contractsLanguage() {
    switch (process.env.REACT_APP_REGION) {
      case "vietnam":
        return "vi";
      case "russia":
        return "ru";
      case "egypt":
        return "ar";
      case "korea":
        return "ko";
      case "develop":
      default:
        return "en";
    }
  },
  get contracts() {
    return {
      TERMS_OF_USE: `${process.env.REACT_APP_MINIO_DOC_URL}/terms_of_use_${this.contractsLanguage}.pdf`,
      APPLICATION_FORM: `${process.env.REACT_APP_MINIO_DOC_URL}/application_form_${this.contractsLanguage}.pdf`,
      PERSONAL_INFORMATION_POLICY: `${process.env.REACT_APP_MINIO_DOC_URL}/processing_of_personal_information_policy_${this.contractsLanguage}.pdf`,
      USAGE_AGREEMENT: `${process.env.REACT_APP_MINIO_DOC_URL}/personal_information_collection_and_usage_agreement_${this.contractsLanguage}.pdf`,
      PERSONAL_INFORMATION_COLLECTED: `${process.env.REACT_APP_MINIO_DOC_URL}/consignment_of_personal_information_collected_${this.contractsLanguage}.pdf`,
      COLLECT_AND_USE_PERSONAL_DATA_FOR_STAFF: `${process.env.REACT_APP_MINIO_DOC_URL}/collect_and_use_personal_data_for_staff_${this.contractsLanguage}.pdf`,
      OPEN_SOURCE_SOFTWARE_LICENSE: `${process.env.REACT_APP_MINIO_DOC_URL}/open_source_software_license_${this.contractsLanguage}.pdf`
    };
  },

  get emailServers() {
    switch (process.env.REACT_APP_REGION) {
      case "korea":
        return [
          {
            domain: "naver.com"
          },
          {
            domain: "daum.net"
          },
          {
            domain: "gmail.com"
          },
          {
            domain: "kakao.com"
          }
        ];
      default:
        return [
          {
            domain: "gmail.com"
          },
          {
            domain: "kakao.com"
          }
        ];
    }
  },

  get languageList() {
    switch (process.env.REACT_APP_REGION) {
      case "vietnam":
        return [
          {
            label: "English",
            value: "en-vi"
          },
          {
            label: "Tiếng Việt",
            value: "vi"
          }
        ];
      case "russia":
        return [
          {
            label: "English",
            value: "en-ru"
          },
          {
            label: "Pусский",
            value: "ru"
          }
        ];
      case "egypt":
        return [
          {
            label: "English",
            value: "en-eg"
          },
          {
            label: "عربى",
            value: "ar"
          }
        ];
      case "korea":
      case "develop":
      default:
        return [
          {
            label: "English",
            value: "en"
          },
          {
            label: "Tiếng Việt",
            value: "vi"
          },
          {
            label: "Pусский",
            value: "ru"
          },
          {
            label: "عربى",
            value: "ar"
          },
          {
            label: "English For Egypt",
            value: "en-eg"
          },
          {
            label: "English For Russia",
            value: "en-ru"
          },
          {
            label: "English For Vietnam",
            value: "en-vi"
          }
        ];
    }
  }
};

export { isGlobalMode, getPhotoDefs, LocalizeVariable };
