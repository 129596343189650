import { DialogPermissionWarn } from 'components';
import React, { forwardRef, useState } from 'react';
import CCTextFieldDualType from 'styles/src/components/CCTextFieldDualType';

const CVTextFieldDualType = forwardRef((props, ref) => {
  const {
    children,
    permission,
    onChange,
    onKeyDown,
    onKeyPress,
    onClickIcon,
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const isPermission = true;

  const handleChange = () => {
    setOpen(true);
  };

  return (
    <>
      <CCTextFieldDualType
        ref={ref}
        onChange={isPermission ? onChange : handleChange}
        onKeyDown={isPermission ? onKeyDown : handleChange}
        onKeyPress={isPermission ? onKeyPress : handleChange}
        onClickIcon={isPermission ? onClickIcon : handleChange}
        {...others}
      >
        {children}
      </CCTextFieldDualType>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default CVTextFieldDualType;
