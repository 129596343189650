import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useMemo, useState } from "react";
import CCButton from "styles/src/components/CCButton";
import CCDialogActions from "styles/src/components/CCDialogActions";
import CCTabPanel from "styles/src/components/CCTabPanel";
import CCTabs from "styles/src/components/CCTabs";
import { ProgressIcon } from "styles/src/themes/common/icons";
import { DetailViewer, ImageViewer } from "./Component";

const tabsHeight = 52;
const headerHeight = 90;
const useStyles = makeStyles(theme => ({
  root: { width: 788, borderLeft: `1px solid ${theme.palette.border.main}` },
  searchHeader: {
    height: headerHeight,
    maxHeight: headerHeight,
    boxSizing: "border-box"
  },
  searchInput: {
    width: 194
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    height: tabsHeight,
    boxSizing: "border-box"
  },
  panel: {
    height: props =>
      Boolean(props.onFileSelect)
        ? `calc(100%  - ${tabsHeight * 2 + 10}px)`
        : `calc(100%  - ${tabsHeight}px)`,
    overflow: "auto"
  }
}));

const FileViewer = props => {
  const { clickedItem, onFileSelect, onClose } = props;
  const classes = useStyles({ onFileSelect: onFileSelect });
  const [tab, setTab] = useState(0);

  const tabList = useMemo(() => {
    switch (clickedItem?.content?.filetype) {
      case "image":
      case "paper":
      case "stamp":
      case "logo":
        return [
          {
            label: "미리보기",
            component: props => <ImageViewer {...props} />
          },
          {
            label: "상세보기",
            component: props => <DetailViewer {...props} />
          }
        ];
      default:
        return [
          {
            label: "상세보기",
            component: props => <DetailViewer {...props} />
          }
        ];
    }
  }, [clickedItem]);
  return (
    <Box className={classes.root}>
      <CCTabs
        className={classes.tabs}
        tabList={tabList.map(el => el.label)}
        selectedTab={tab}
        onChangeTab={e => setTab(e)}
      />
      {tabList.map((d, i) => (
        <CCTabPanel className={classes.panel} key={i} value={tab} index={i}>
          {d.component(props)}
        </CCTabPanel>
      ))}
      {onFileSelect && onClose && (
        <CCDialogActions>
          <CCButton
            variant={"text"}
            onClick={() => {
              onClose && onClose();
            }}
          >
            {"취소"}
          </CCButton>
          <CCButton
            startIcon={<ProgressIcon />}
            variant={"contained"}
            onClick={() => {
              onFileSelect && onFileSelect(clickedItem);
            }}
          >
            {"가져오기"}
          </CCButton>
        </CCDialogActions>
      )}
    </Box>
  );
};
export default FileViewer;
