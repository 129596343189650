import { makeStyles, Popover } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CCTimePicker from "../CCTimePicker";

const useStyle = makeStyles(theme => ({
    root: {},
    button: { fontWeight: "bold" },
    grayButton: {
      color: theme.palette.sub.main
    },
    noPaddingTop: {
      paddingTop: 0
    }
  }),
  { name: "CCDatePickerPopover" }
);
/**
 * @deprecated
 */
const CCTimePickerPopover = props => {
  const { open, onClose, date, onChange, minutesStep, ...others } = props;
  const [last, setLast] = useState(date || moment());
  const classes = useStyle();
  useEffect(() => {
    setLast(date);
  }, [date]);
  return (
    <Popover
      open={open}
      onClose={() => {
        onClose();
      }}
      {...others}
    >
      <CCTimePicker
        date={last}
        onChange={e => {
          setLast(e);
        }}
        minutesStep={minutesStep}
      />
      <DialogActions className={clsx(classes.noPaddingTop)}>
        <Button
          color={"secondary"}
          size={"small"}
          className={clsx(classes.button, classes.grayButton)}
          onClick={() => {
            onClose();
          }}
        >
          취소
        </Button>
        <Button
          color={"secondary"}
          size={"small"}
          className={clsx(classes.button)}
          onClick={() => {
            onChange(last);
            onClose();
          }}
        >
          확인
        </Button>
      </DialogActions>
    </Popover>
  );
};
CCTimePickerPopover.propTypes = {
  /**
   * Popover Anchor Element
   */
  anchorEl: PropTypes.element,
  /**
   * Dialog Open
   */
  open: PropTypes.bool.isRequired,
  /**
   * Dialog Close Event Handler
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Date Value ( Moment Object , Only use Time Value )
   */
  date: PropTypes.instanceOf(moment).isRequired,
  /**
   * Date Change Event Handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Minutes Select Step in Clock UI
   */
  minutesStep: PropTypes.number
};
CCTimePickerPopover.defaultProps = {
  anchorEl: document.body,
  minutesStep: 1
};
export default CCTimePickerPopover;
