import { Box, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { DialogPermissionWarn, useWritePermission } from "components";
import React, { useEffect, useState } from "react";
import { CCTypography } from "styles/components";
import CVTextField from "components/CVTextField";
import CCMenuItem from "styles/src/components/CCMenu/Component/CCMenuItem";
import {
  GOLD_SOURCING_METHOD_TYPES,
  LAB_GOLD_TYPES
} from "types/goldManagement";
import { makeList } from "types/utils";
import { translate } from "components";
import { useIntl } from "react-intl";
import { GoldSourcingMethodTooltip } from "../../../../../Setting/components/ClinicInfoSetting/components/ClinicInfoField/components";
import NumberFormat from "react-number-format";
// import { CCTooltip } from "styles/src/components";
import { formatCommas } from "utils/number";
const useStyles = makeStyles(theme => ({
  root: { height: "inherit" },
  divider: {
    backgroundColor: theme.palette.border.main
  },
  padding8: { padding: 8 },
  typography: {
    color: theme.palette.sub.main,
    paddingLeft: 8
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 999999999999;
  const withValueLimit = inputObj => {
    const { value } = inputObj;
    if (value < MAX_VAL) return inputObj;
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isAllowed={withValueLimit}
      decimalScale={2}
      isNumericString
    />
  );
}

const labGoldTypes = makeList(LAB_GOLD_TYPES);
const labGoldOwnerTypes = makeList(GOLD_SOURCING_METHOD_TYPES);
const goldPriceMap = {
  1: "inlay",
  2: "aType",
  3: "sA",
  4: "pt",
  5: "pfg"
};

const ViewLabOrderCalculator = props => {
  const { value, onChange, permission } = props;
  const { clinicProthesisPrice, clinicGoldPrice, goldUnitPriceManageDetail } =
    value;
  const {
    ownerShip,
    goldType,
    goldPrice = 0,
    goldUsed = 0,
    goldConsumed = 0,
    goldReceived = 0,
    remainGoldCalc = 0
  } = clinicGoldPrice;

  const classes = useStyles();
  const intl = useIntl();
  const isPermission = useWritePermission(permission);

  const [errorPermission, setErrorPermission] = useState(false);

  useEffect(() => {
    // gold price calculation
    if (ownerShip === "1") {
      // lab ownership
      const actualGoldPrice = goldUnitPriceManageDetail
        ? goldUnitPriceManageDetail[goldPriceMap[goldType]]
        : 0;
      const amount =
        (goldUsed ? Number(goldUsed) : 0) +
        (goldConsumed ? Number(goldConsumed) : 0);
      const totalGoldPriceCalc = amount * actualGoldPrice;
      onChange("clinicGoldPrice", {
        ...clinicGoldPrice,
        ownerShip,
        goldType,
        goldUsed,
        goldConsumed,
        goldPrice: actualGoldPrice,
        totalGoldPriceCalc
      });
    } else if (ownerShip === "2") {
      // clinic ownership
      const remainGoldCalc =
        (goldReceived ? Number(goldReceived) : 0) -
        ((goldUsed ? Number(goldUsed) : 0) +
          (goldConsumed ? Number(goldConsumed) : 0));

      onChange("clinicGoldPrice", {
        ownerShip,
        goldType,
        goldReceived,
        goldUsed,
        goldConsumed,
        remainGoldCalc
      });
    } else if (ownerShip === "3") {
      // lab storage gold
      onChange("clinicGoldPrice", {
        ownerShip,
        goldType,
        goldReceived: goldReceived ? goldReceived : 0,
        goldUsed: goldUsed ? goldUsed : 0,
        goldConsumed: goldConsumed ? goldConsumed : 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clinicGoldPrice.goldType,
    clinicGoldPrice.goldPrice,
    clinicGoldPrice.goldUsed,
    clinicGoldPrice.goldConsumed,
    clinicGoldPrice.goldReceived,
    clinicGoldPrice.ownerShip,
    clinicGoldPrice.goldType
  ]);
  useEffect(() => {
    // prosthesis price calculation
    const basePrice =
      Number(clinicProthesisPrice?.price) *
      Number(clinicProthesisPrice?.amount);
    const discountAmount =
      (basePrice * Number(clinicProthesisPrice?.discount)) / 100;
    const finalPrice = basePrice - discountAmount;

    onChange("clinicProthesisPrice", {
      ...clinicProthesisPrice,
      totalPrice: finalPrice
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clinicProthesisPrice.price,
    clinicProthesisPrice.amount,
    clinicProthesisPrice.discount
  ]);

  return (
    <Box className={clsx(classes.root)}>
      <Box className={classes.padding8}>
        <Divider className={classes.divider} />
      </Box>
      <Grid container alignItems={"center"} justify={"space-between"}>
        <Grid item>
          <CVTextField
            style={{ width: 110 }}
            label={translate(intl, "common.ui.price2")}
            margin={"dense"}
            variant={"outlined"}
            value={formatCommas(clinicProthesisPrice?.price)}
            disabled
            // onChange={e => {
            //   if (!isPermission) {
            //     setErrorPermission(true);
            //   } else
            //     onChange("clinicProthesisPrice", {
            //       ...clinicProthesisPrice,
            //       price: Number(e.target.value?.substring(0, 13).replace(/,/g, ""))
            //     });
            // }}
          />
        </Grid>
        x
        <Grid item>
          <CVTextField
            style={{ width: 118 }}
            label={translate(intl, "common.ui.unitsEa")}
            margin={"dense"}
            variant={"outlined"}
            value={clinicProthesisPrice?.amount}
            disabled
            // onChange={e => {
            //   if (!isPermission) {
            //     setErrorPermission(true);
            //   } else
            //     onChange("clinicProthesisPrice", {
            //       ...clinicProthesisPrice,
            //       amount: Number(e.target.value?.substring(0, 13).replace(/,/g, ""))
            //     });
            // }}
          />
        </Grid>
        x
        <Grid item>
          <CVTextField
            style={{ width: 70 }}
            label={"D/C(%)"}
            // label={translate(intl, "common.ui.dcPercent")}
            margin={"dense"}
            variant={"outlined"}
            value={clinicProthesisPrice?.discount}
            disabled
            onChange={e => {
              const number = Number(
                e.target.value?.substring(0, 3).replace(/,/g, "")
              );
              if (!isPermission) {
                setErrorPermission(true);
              } else
                onChange("clinicProthesisPrice", {
                  ...clinicProthesisPrice,
                  discount: number > 100 ? 100 : number
                });
            }}
          />
        </Grid>
        =
        <Grid item>
          <CVTextField
            style={{ width: 170 }}
            label={translate(intl, "common.ui.total2")}
            margin={"dense"}
            variant={"outlined"}
            value={formatCommas(clinicProthesisPrice.totalPrice || 0)}
            disabled
          />
        </Grid>
      </Grid>
      {/* <Grid container alignItems={"center"}>
        <Grid item>
          <CVTextField
            style={{ width: 130 }}
            label={translate(intl, "common.ui.goldSourcing")}
            select
            value={clinicGoldPrice.ownerShip}
            onChange={e => {
              if (!isPermission) {
                setErrorPermission(true);
              } else
                onChange("clinicGoldPrice", {
                  ...clinicGoldPrice,
                  ownerShip: e.target.value
                });
            }}
            variant="outlined"
            margin="dense"
            disabled
          >
            {labGoldOwnerTypes.map(item => (
              <CCMenuItem key={item.value} value={item.value}>
                {item.label}
              </CCMenuItem>
            ))}
          </CVTextField>
        </Grid>
        <Grid item>
          <GoldSourcingMethodTooltip />
        </Grid>
        <Grid item>
          <Box className={classes.padding8}>
            <Divider
              style={{ height: 36 }}
              orientation="vertical"
              flexItem={true}
              className={classes.divider}
            />
          </Box>
        </Grid>
        <Grid item>
          <CVTextField
            style={{ width: 130 }}
            label={translate(intl, "payment.bill.goldType")}
            variant="outlined"
            margin="dense"
            value={clinicGoldPrice.goldType}
            select
            onChange={e => {
              if (!isPermission) {
                setErrorPermission(true);
              } else
                onChange("clinicGoldPrice", {
                  ...clinicGoldPrice,
                  goldType: Number(e.target.value.replace(/,/g, ""))
                });
            }}
          >
            {labGoldTypes.map(item => (
              <CCMenuItem key={item.value} value={item.value}>
                {item.label}
              </CCMenuItem>
            ))}
          </CVTextField>
        </Grid>
      </Grid> */}

      {/* {clinicGoldPrice.ownerShip === "1" ? (
        <Grid container alignItems={"center"} justify={"space-between"}>
          <Grid item>
            <CVTextField
              style={{ width: 104 }}
              label={translate(intl, "common.ui.unitPriceG")}
              margin={"dense"}
              variant={"outlined"}
              value={formatCommas(goldPrice)}
              type={"number"}
              disabled
              onChange={e => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else
                  onChange("clinicGoldPrice", {
                    ...clinicGoldPrice,
                    goldPrice: Number(e.target.value.replace(/,/g, ""))
                  });
              }}
            />
          </Grid>
          x (
          <Grid item>
            <CVTextField
              style={{ width: 94 }}
              // label={"Used(g)"}
              label={translate(intl, "common.ui.usedG")}
              margin={"dense"}
              variant={"outlined"}
              value={clinicGoldPrice.goldUsed}
              onChange={e => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else
                  onChange("clinicGoldPrice", {
                    ...clinicGoldPrice,
                    goldUsed: Number(e.target.value.replace(/,/g, ""))
                  });
              }}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          +
          <Grid item>
            <CVTextField
              style={{ width: 94 }}
              // label={"Consumed(g)"}
              label={translate(intl, "common.ui.consumedG")}
              margin={"dense"}
              variant={"outlined"}
              value={clinicGoldPrice.goldConsumed}
              onChange={e => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else
                  onChange("clinicGoldPrice", {
                    ...clinicGoldPrice,
                    goldConsumed: Number(e.target.value.replace(/,/g, ""))
                  });
              }}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          ) =
          <Grid item>
            <CVTextField
              style={{ width: 112 }}
              // label={"Sub Total($)"}
              label={translate(intl, "common.ui.subTotal")}
              margin={"dense"}
              variant={"outlined"}
              value={formatCommas(clinicGoldPrice.totalGoldPriceCalc || 0)}
              disabled
            />
          </Grid>
        </Grid>
      ) : clinicGoldPrice.ownerShip === "2" ? (
        <Grid container alignItems={"center"} justify={"space-between"}>
          <Grid item>
            <CVTextField
              style={{ width: 104 }}
              label={"Receive(g)"}
              // label={translate(intl, "common.ui.receiveG")}
              margin={"dense"}
              variant={"outlined"}
              value={goldReceived}
              type={"number"}
              onChange={e => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else
                  onChange("clinicGoldPrice", {
                    ...clinicGoldPrice,
                    goldReceived: Number(
                      e.target.value
                        .substring(0, 12)
                        .replace(/,/g, "")
                        .replace(/-/g, "")
                    )
                  });
              }}
            />
          </Grid>
          - (
          <Grid item>
            <CVTextField
              style={{ width: 94 }}
              // label={"Used(g)"}
              label={translate(intl, "common.ui.usedG")}
              margin={"dense"}
              variant={"outlined"}
              value={clinicGoldPrice.goldUsed}
              onChange={e => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else
                  onChange("clinicGoldPrice", {
                    ...clinicGoldPrice,
                    goldUsed: Number(
                      e.target.value
                        .substring(0, 12)
                        .replace(/,/g, "")
                        .replace(/-/g, "")
                    )
                  });
              }}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          +
          <Grid item>
            <CVTextField
              style={{ width: 94 }}
              // label={"Consumed(g)"}
              label={translate(intl, "common.ui.consumedG")}
              margin={"dense"}
              variant={"outlined"}
              value={clinicGoldPrice.goldConsumed}
              onChange={e => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else {
                  onChange("clinicGoldPrice", {
                    ...clinicGoldPrice,
                    goldConsumed: Number(
                      e.target.value
                        .substring(0, 12)
                        .replace(/,/g, "")
                        .replace(/-/g, "")
                    )
                  });
                }
              }}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          ) =
          <Grid item>
            <CVTextField
              style={{ width: 112 }}
              label={"Remain(g)"}
              // label={translate(intl, "common.ui.RemainG")}
              margin={"dense"}
              variant={"outlined"}
              value={remainGoldCalc}
              type={"number"}
              disabled
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems={"center"}>
          <Grid item>
            <CVTextField
              style={{ width: 94 }}
              label={translate(intl, "common.ui.usedG")}
              margin={"dense"}
              variant={"outlined"}
              value={clinicGoldPrice.goldUsed}
              onChange={e => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else
                  onChange("clinicGoldPrice", {
                    ...clinicGoldPrice,
                    goldUsed: Number(e.target.value.replace(/,/g, ""))
                  });
              }}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item>
            <CVTextField
              style={{ width: 94 }}
              label={translate(intl, "common.ui.consumedG")}
              margin={"dense"}
              variant={"outlined"}
              value={clinicGoldPrice.goldConsumed}
              onChange={e => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else
                  onChange("clinicGoldPrice", {
                    ...clinicGoldPrice,
                    goldConsumed: Number(e.target.value.replace(/,/g, ""))
                  });
              }}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
        </Grid>
      )} */}

      {/* <Grid container alignItems={"center"} justify={"space-between"}>
        <Grid item>
          <CCTypography variant={"h5"} className={classes.typography}>
            *{" "}
            {translate(intl, "common.ui.whenTheLabSGoldIsUsedExtrasAreCharged")}
          </CCTypography>
        </Grid>
      </Grid> */}
      {/* <Box className={classes.padding8}>
        <Divider className={classes.divider} />
        <p style={{color: 'gray'}}> {translate(intl, "common.ui.goldManageNotification")} </p>
      </Box> */}
      {/* <Grid container alignItems={"center"} justify={"flex-end"}>
        <Grid item>
          <CVTextField
            style={{ width: 170 }}
            label={translate(intl, "common.ui.total2")}
            margin={"dense"}
            variant={"outlined"}
            disabled
            value={formatCommas(
              clinicProthesisPrice?.totalPrice +
                (clinicGoldPrice.totalGoldPriceCalc
                  ? clinicGoldPrice.totalGoldPriceCalc
                  : 0)
            )}
          />
        </Grid>
      </Grid> */}
      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
    </Box>
  );
};

ViewLabOrderCalculator.defaultProps = {};

ViewLabOrderCalculator.propTypes = {};

export default ViewLabOrderCalculator;
