import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { CCPaper, CCTypography } from "styles/src/components";
import { TakedownIcon } from "styles/src/themes/common/icons";
import { InfiniteTable } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "utils/language";
import { TableItem } from "../TableItems";
import { Box } from "@material-ui/core";

const useStyle = makeStyles(theme => ({
  root: {
    minWidth: 820,
    marginBottom: 10,
    padding: "6px important!",
    width: 820,
    height: "80%",
    textAlign: "center"
  },
  title: {
    padding: 16
  },
  content: {
    height: "calc(100% - 85px)"
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.border}`
  },
  noneSign: {
    "&  *": {
      color: theme.palette.primary.main
    }
  },
  needReSign: {
    "&  *": {
      color: theme.palette.error.light
    }
  },
  table: {
    height: 170
  },
  category__table: {
    height: 230
  },
  row: {
    height: 40
  },
  disable__table: {
    height: 30
  },
  dis__table: {
    background: theme.palette.sub.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 170
  },
  dis__typo: {
    color: theme.palette.background.default
  },
  box: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const CategoryTable = props => {
  const { contents, directInput } = props;
  const [selected, setSelected] = useState();
  const [focusIndex, setFocusIndex] = useState(0);
  const classes = useStyle();
  const { control } = useFormContext();
  const intl = useIntl();

  return (
    <CCPaper className={classes.root}>
      {directInput ? (
        <Box className={classes.dis__table}>
          <CCTypography className={classes.dis__typo}>
            {intl.formatMessage({ id: "deselectDirectInputForSearch" })}
          </CCTypography>
        </Box>
      ) : (
        <Controller
          name={"selectedList"}
          control={control}
          defaultValue={selected}
          render={props => {
            const { ref, value, onChange, onBlur } = props.field;

            return (
              <>
                <Box className={classes.root}>
                  <InfiniteTable
                    inputRef={ref}
                    heads={[
                      {
                        key: "checked",
                        width: 30,
                        component: () => {
                          return (
                            <TableItem
                              disableTypography
                              label={<TakedownIcon />}
                              className={classes.row}
                            />
                          );
                        }
                      },
                      {
                        key: "major",
                        width: 160,
                        label: intl.formatMessage({ id: "majorCategory" }),
                        component: ({ cellData }) => {
                          return (
                            <TableItem
                              label={cellData}
                              className={classes.row}
                              tooltip={true}
                            />
                          );
                        }
                      },
                      {
                        key: "minor",
                        label: intl.formatMessage({ id: "minorCategory" }),
                        width: 160,
                        component: ({ cellData }) => {
                          return (
                            <TableItem
                              label={cellData}
                              className={classes.row}
                              tooltip={true}
                            />
                          );
                        }
                      },
                      {
                        key: "productName",
                        label: intl.formatMessage({ id: "productName" }),
                        width: 280,
                        component: ({ cellData }) => {
                          return (
                            <TableItem
                              label={cellData}
                              className={classes.row}
                              tooltip={true}
                            />
                          );
                        }
                      },
                      {
                        key: "manufacture",
                        label: intl.formatMessage({ id: "manufacturer" }),
                        width: 140,
                        component: ({ cellData }) => {
                          return (
                            <TableItem
                              label={cellData}
                              className={classes.row}
                              tooltip={true}
                            />
                          );
                        }
                      }
                    ]}
                    contents={contents && !directInput ? contents : []}
                    selected={e => {
                      if (value) {
                        return e.productID === value.productID;
                      }
                      return false;
                    }}
                    onRowClick={({ rowData, rowIndex }) => {
                      onChange(rowData);
                      if (value && value?.productID === rowData?.productID) {
                        setSelected(null);
                        return;
                      }
                      setFocusIndex(rowIndex);
                    }}
                    rowClickHighlight={true}
                    scrollToIndex={focusIndex}
                    className={classes.category__table}
                    onBlur={onBlur}
                  />
                </Box>
              </>
            );
          }}
        />
      )}
    </CCPaper>
  );
};

export default CategoryTable;
