import { Box, makeStyles } from "@material-ui/core";
import { translate } from "components";
import React from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";
import { LAB_ORDER_STATE } from "types/labOrder";
import MonthItemTooltip from "../MonthItemTooltip";

const useStyle = makeStyles(() => ({
  root: {
    padding: 8
  }
}));

const MonthEventItem = props => {
  const { event } = props;

  const classes = useStyle();
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <CCTypography variant="body1">
        {translate(intl, "menuStatistics.total")}: {event.items.length}
      </CCTypography>
      <MonthItemTooltip
        {...props}
        tooltipType="shipped"
        total={event.items.reduce((acc, cur) => {
          if (cur.status === LAB_ORDER_STATE.SHIPPED.value) return acc + 1;
          return acc;
        }, 0)}
        items={event.items.filter(
          item => item.status === LAB_ORDER_STATE.SHIPPED.value
        )}
      />
      {
        (event.end,
        event.end.getTime() > new Date().getTime() ? (
          <MonthItemTooltip
            {...props}
            tooltipType="ordered"
            total={event.items.reduce((acc, cur) => {
              if (cur.status !== LAB_ORDER_STATE.SHIPPED.value) return acc + 1;
              return acc;
            }, 0)}
            items={event.items.filter(
              item => item.status !== LAB_ORDER_STATE.SHIPPED.value
            )}
          />
        ) : (
          <MonthItemTooltip
            {...props}
            tooltipType="pending"
            total={event.items.reduce((acc, cur) => {
              if (cur.status !== LAB_ORDER_STATE.SHIPPED.value) return acc + 1;
              return acc;
            }, 0)}
            items={event.items.filter(
              item => item.status !== LAB_ORDER_STATE.SHIPPED.value
            )}
          />
        ))
      }
    </Box>
  );
};

export default MonthEventItem;
