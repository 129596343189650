import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { DetailContext } from "components/Explorer/Component/index";
import InfiniteTable from "components/InfiniteTable";
// import { getTitle } from "components/OralExamination/Question";
import OverviewDialog from "components/OverviewDialog";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
    DOC_CATEGORIES,
    DOC_PURPOSES,
    DOC_STATUS,
    DOC_TYPES
} from "types/docissue";
import { GENDER } from "types/patient";
import { makeIndexFormattedMessageList, makeIndexList } from "types/utils";
import { calcBirthDate, getAgeToBirthDay, getSex } from "utils/patient";
import { CommonHeader } from "../../../ListHeader";
import { CommonItem } from "../../../ListItem";

const useStyle = makeStyles(theme => ({
  root: {
    height: props => {
      if (props?.collapse && props?.collapseRef?.current) {
        return `calc(100% - ${props?.collapseRef?.current.offsetHeight}px)`;
      } else {
        return "100%";
      }
    }
  }
}));

const useCertificationStyle = makeStyles(theme => ({
  root: {
    padding: 16,
    border: 0,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 0
  },
  container: {
    height: "100%",
    width: "100%",
    overflow: "auto"
  },
  item__header: {
    marginBottom: 30
  },
  header: {
    textAlign: "left"
  },
  title: {
    textAlign: "center",
    marginBottom: 10
  },
  table: {
    marginBottom: 10,
    "& td": {
      margin: 0,
      padding: 5,
      height: 40,
      border: "solid 1px",
      textAlign: "center"
    }
  },
  tr__note: {
    height: 200
  },
  td__height__issueNumber: {
    height: "20px !important"
  },
  td__align__center: {
    textAlign: "center !important"
  },
  td__align__left: {
    textAlign: "left !important"
  },
  td__border__top: { borderTop: "0px !important" },
  td__border__bottom: { borderBottom: "0px !important" },
  td__border__left: {
    borderLeft: "0px !important"
  },
  td__border__right: {
    borderRight: "0px !important"
  },
  textalign__center: {
    textAlign: "center"
  },
  radioGr: {
    "& label": {
      marginLeft: 4,
      marginRight: 8
    },
    "& label > span": {
      padding: "0px 5px 0 0",
      fontSize: 12
    },
    "& svg": {
      width: "1rem"
    }
  },
  checkbox: {
    "& svg": {
      width: "1rem"
    }
  },
  label: {
    margin: 0,
    fontSize: 12,
    "& .Mui-disabled": {
      fontSize: 12
    }
  },
  divider__solid: {
    backgroundColor: "black"
  },
  divider__double: {
    outlineStyle: "double"
  },
  td__no__padding: {
    padding: "0px !important"
  },
  box__colSpan: {
    display: "flex",
    height: "100%"
  },
  box__colSpan__item__width2: {
    width: "calc(100% / 2)",
    padding: 5
  },
  typography__lineHeight40: {
    lineHeight: "40px"
  },
  border__right: {
    borderRight: "solid 1px"
  },
  typography__diseaseCode: {
    height: "100%"
  }
}));

const useGeneralStyle = makeStyles(theme => ({
  root: {
    border: "unset",
    borderRadius: 0,
    marginTop: 10,
    marginBottom: 10,
    padding: 16
  },
  title: {
    marginTop: 20,
    marginBottom: 30,
    textAlign: "center"
  },
  subtitle: {
    marginTop: 20,
    marginBottom: 5
  },
  subtitle__patient: {
    marginBottom: 10
  },
  item: {
    marginBottom: 20
  },
  item__patient: {
    width: "100%"
  },
  frame: {
    border: "1px solid",
    padding: 16
  },
  highlight: {
    backgroundColor: theme.palette.background.default
  },
  divider__solid: {
    backgroundColor: "black"
  }
}));
const GENDER_LIST = makeIndexList(GENDER);
const DOC_CATEGORIES_LIST = makeIndexFormattedMessageList(DOC_CATEGORIES);
const DOC_TYPES_LIST = makeIndexFormattedMessageList(DOC_TYPES);
const DOC_STATUS_LIST = makeIndexFormattedMessageList(DOC_STATUS);
const DOC_PURPOSES_LIST = makeIndexFormattedMessageList(DOC_PURPOSES);

const useSearchDocName = () => {
  const itnl = useIntl();
  const [docTypes, setDocTypes] = useState(null);
  useEffect(() => {
    import("types/docissue").then(res => {
      setDocTypes(res);
    });
  }, []);

  const searchDocName = useCallback(
    (type, docNameValue, receiptType) => {
      let doctype = (() => {
        for (let key in DOC_TYPES) {
          if (DOC_TYPES[key].value === type) {
            return key;
          }
        }
      })();
      if (docTypes === null) {
        return "";
      }
      // console.log(doctype);
      // console.log(DOC_TYPES[doctype]);
      switch (DOC_TYPES[doctype].value) {
        // case DOC_TYPES.ORAL_EXAM_QUESTIONNAIRE.value:
        // case DOC_TYPES.ORAL_EXAM_RESULTS.value:
        //   return getTitle(receiptType);
        default:
          let list = docTypes[`DOC_NAMES_${doctype}`];
          if (list) {
            for (let key in list) {
              if (list[key]?.value === docNameValue) {
                return itnl.formatMessage({ id: list[key].localeKey });
              }
            }
          }
          break;
      }
      return "";
    },
    [docTypes, itnl]
  );
  return searchDocName;
};

const ResultList = props => {
  const {
    list,
    collapseRef,
    collapse,
    hasNext,
    onMoreContents,
    // onChange,
    onDelete
  } = props;
  const classes = useStyle({ collapseRef: collapseRef, collapse: collapse });
  const certClasees = useCertificationStyle();
  const generalClasses = useGeneralStyle();
  const [selected, setSelected] = useState(-1);
  const searchDocName = useSearchDocName();
  const [selectRef, setSelectRef] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  // const [openOutsidePrintDialog, setOpenOutsidePrintDialog] = useState(null);
  const intl = useIntl();
  return (
    <Box className={classes.root}>
      <DetailContext
        open={Boolean(selectRef)}
        anchorEl={selectRef}
        onClose={() => {
          setSelected(-1);
          setSelectRef(null);
        }}
        onShowDetail={() => {
          // console.log(selectedValue);
          setOpenDialog(true);
        }}
        allowDelete
        onDelete={() => {
          // console.log(selectedValue);
          onDelete && onDelete({ ...selectedValue });
        }}
      />
      {openDialog && Boolean(selectedValue?.id) && (
        <OverviewDialog
          id={selectedValue?.id}
          open={openDialog && Boolean(selectedValue?.id)}
          onClose={() => {
            setSelected(-1);
            setSelectRef(null);
            setOpenDialog(false);
            setSelectRef(null);
          }}
          baseClasses={
            selectedValue.category === DOC_CATEGORIES.GENERAL.value
              ? generalClasses
              : certClasees
          }
        />
      )}
      {/* {Boolean(openOutsidePrintDialog) && (
        <OutsidePrintDialog
          open={Boolean(openOutsidePrintDialog)}
          onClose={() => {
            setOpenOutsidePrintDialog(null);
          }}
          id={openOutsidePrintDialog}
        />
      )} */}
      <InfiniteTable
        heads={[
          {
            key: "date",
            width: 140,
            headComponent: () => <CommonHeader label={"발급일/시각"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={moment(cellData, "X").format("YYYY/MM/DD HH:mm")}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "patient",
            width: 100,
            headComponent: () => <CommonHeader label={"환자이름"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={cellData?.name || ""}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "patient",
            width: 100,
            headComponent: () => <CommonHeader label={"차트번호"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={cellData?.chartNumber || ""}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "patient",
            width: 100,
            headComponent: () => <CommonHeader label={"연령/성별"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={
                  cellData?.residentRegistrationNumber
                    ? `${getAgeToBirthDay(
                        calcBirthDate(cellData?.residentRegistrationNumber)
                      )}세/${intl.formatMessage({
                        id:
                          GENDER_LIST[
                            getSex(cellData?.residentRegistrationNumber)
                          ]
                      })}`
                    : ""
                }
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "doctor",
            width: 100,
            headComponent: () => <CommonHeader label={"담당의사"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={cellData?.name || ""}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "category",
            width: 70,
            headComponent: () => <CommonHeader label={"구분"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={DOC_CATEGORIES_LIST[cellData]}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "type",
            width: 70,
            headComponent: () => <CommonHeader label={"종류"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={DOC_TYPES_LIST[cellData]}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "name",
            headComponent: () => <CommonHeader label={"문서명"} />,
            component: ({ cellData, rowData }) => {
              // console.log(rowData);
              if (Boolean(rowData?.type === "PS")) {
                return (
                  <CommonItem
                    label={"원외처방전"}
                    onClick={e => {
                      setSelectRef(e.currentTarget);
                      setSelectedValue(rowData);
                    }}
                  />
                );
              } else {
                return (
                  <CommonItem
                    label={searchDocName(
                      rowData.type,
                      cellData,
                      rowData.receiptType
                    )}
                    onClick={e => {
                      setSelectRef(e.currentTarget);
                      setSelectedValue(rowData);
                    }}
                  />
                );
              }
            }
          },
          {
            key: "purpose",
            width: 100,
            headComponent: () => <CommonHeader label={"용도"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={DOC_PURPOSES_LIST[cellData]}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          },
          {
            key: "status",
            width: 70,
            headComponent: () => <CommonHeader label={"상태"} />,
            component: ({ cellData, rowData }) => (
              <CommonItem
                label={DOC_STATUS_LIST[cellData]}
                onClick={e => {
                  setSelectRef(e.currentTarget);
                  setSelectedValue(rowData);
                }}
              />
            )
          }
        ]}
        contents={list || []}
        selected={e => selected === e.id}
        onRowClick={e => {
          setSelected(e.rowData.id);
        }}
        hasNext={Boolean(hasNext)}
        onMoreContents={e => {
          onMoreContents && onMoreContents(e);
        }}
        rowClickHighlight
      />
    </Box>
  );
};

export default ResultList;
