
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  translate
} from "components";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import useStyles from './ButtonStyle';

export const getTimeStampOfMonth = () => {
  let fromDate = moment().startOf("month");
  let endDate = moment().endOf("month");
  return {
    from: moment(fromDate.format("YYYY-MM-DD 00:00:00")).unix(),
    to: moment(endDate.format("YYYY-MM-DD 23:59:59")).unix()
  }
}
const ButtonMonth = (props) => {
  const { onClick, isActive } = props
  const intl = useIntl();
  const classes = useStyles();
  // const lang = localStorage.getItem("lang");
  // let dateDiff = 0
  // switch (lang) {
  //   case "vi":
  //   case "ru":
  //     dateDiff = 1
  //     break;
  //   case "ar":
  //     dateDiff = -1
  //     break;
  //   default:
  //     dateDiff = 0
  // }
  return (
    <Box
      className={clsx({
        [classes.button]: true,
        [classes.selected]: isActive
      })}
      onClick={e => {
        const value = getTimeStampOfMonth()
        onClick(value)
      }}
    >
      {translate(intl, "common.ui.month")}
    </Box>
  );
};

ButtonMonth.defaultProps = {};

ButtonMonth.propTypes = {};

export default ButtonMonth;
