import React from "react";

const MinusIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <rect fill="currentColor" x="6" y="10" width="12" height="4" rx="1" />
    </g>
  </svg>
);
export default MinusIcon;
