import React from "react";

const BackendIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M12.026 0c.243 0 .458.186.5.414l.372 2.643c.614.257 1.171.586 1.686.986l2.485-1c.229-.086.486 0 .615.214l2 3.457c.128.215.071.486-.115.643l-2.114 1.657c.043.329.071.657.071.986 0 .329-.028.657-.07.971l2.113 1.658c.186.157.243.428.115.642l-2 3.458c-.129.214-.386.3-.615.214l-2.485-1c-.515.4-1.086.728-1.686.986l-.372 2.642c-.042.243-.257.429-.5.429h-4c-.242 0-.457-.186-.5-.414l-.37-2.643c-.615-.257-1.172-.586-1.687-.986l-2.485 1c-.229.086-.486 0-.615-.214l-2-3.457c-.128-.215-.071-.486.115-.643l2.114-1.657c-.043-.329-.072-.657-.072-.986 0-.329.03-.657.072-.971L.484 7.37C.298 7.214.24 6.943.369 6.73l2-3.458c.129-.214.386-.3.615-.214l2.485 1c.515-.4 1.086-.728 1.686-.986L7.526.43C7.57.186 7.784 0 8.026 0h4zm-1.71 4.434c-3.045 0-4.811 1.419-4.811 4.084v3.024l.004.232c.1 2.516 1.83 3.838 4.724 3.838 1.102 0 2.032-.092 3.144-.333l.394-.086v-2.43l-.612.14-.434.092c-.857.169-1.658.245-2.492.245-1.596 0-2.16-.452-2.16-1.726V8.518l.002-.147c.043-1.146.617-1.565 2.256-1.565.984 0 1.88.09 2.834.297l.606.13V4.822l-.399-.083-.442-.085c-.871-.155-1.679-.219-2.613-.219z"
            transform="translate(-4869 -262) translate(4869 262) translate(2.191 2)"
          />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-4869 -262) translate(4869 262)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default BackendIcon;
