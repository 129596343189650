import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  CCButton,
  CCDialogInputError,
  CCDialogSimple,
  CCIconButton,
  CCTextField,
  CCTypography
} from "../";
import { useIntl } from "../../../locales/language";
import { RemoveIcon } from "../../themes/common/icons";

const useStyles = makeStyles(theme => ({
  root: {},
  textfield: { width: 400, margin: 0 },
  btn: { marginRight: 8 },
  byte__group: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    "& > p": {
      fontSize: 14,
      color: theme.palette.normal.hover
    }
  },
  visible: { visibility: "visible" },
  unvisible: { visibility: "hidden" }
}));

const CCDialogSimpleAddMod = props => {
  const {
    onSave,
    onClose,
    value = "",
    multiline = true,
    title,
    selectedItem,
    maxLength = false,
    blank = false,
    ...others
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [newValue, setNewValue] = useState(value);
  const [openError, setOpenError] = useState({ state: false, value: null });

  const handleClose = () => {
    setNewValue(value);
    onClose && onClose();
  };

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <>
      <CCDialogSimple
        title={title ? title : intl.formatMessage({ id: "ccItem" })}
        contents={
          <>
            <CCTextField
              variant="outlined"
              className={classes.textfield}
              multiline={multiline}
              rows="4"
              value={newValue}
              InputProps={{
                inputProps: {
                  maxLength: maxLength ? maxLength : ""
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <CCIconButton
                      className={clsx({
                        [classes.visible]: newValue.length,
                        [classes.unvisible]: !newValue.length
                      })}
                      size="small"
                      onClick={() => {
                        setNewValue("");
                      }}
                    >
                      <RemoveIcon />
                    </CCIconButton>
                  </InputAdornment>
                )
              }}
              onChange={e => {
                setNewValue(e.target.value);
              }}
              error={!newValue}
            />
            {Boolean(maxLength) && (
              <div className={classes.byte__group}>
                <CCTypography>{newValue.length}</CCTypography>
                <CCTypography>/</CCTypography>
                <CCTypography>{maxLength}</CCTypography>
              </div>
            )}
          </>
        }
        endActions={
          <>
            <CCButton className={classes.btn} color="sub" onClick={handleClose}>
              {intl.formatMessage({ id: "ccCancel" })}
            </CCButton>
            <CCButton
              variant="contained"
              onClick={async () => {
                if (!newValue || (!blank && newValue.trim().length === 0)) {
                  setOpenError({ state: true });
                  return;
                }

                if (maxLength && newValue.length > maxLength) {
                  setOpenError({
                    state: true,
                    value: intl.formatMessage(
                      {
                        id: "ccWarningMaxLength"
                      },
                      {
                        length: maxLength
                      }
                    )
                  });

                  return;
                }
                if (newValue.length > 200) {
                  setOpenError({
                    state: true,
                    value: intl.formatMessage(
                      {
                        id: "ccWarningMaxLength"
                      },
                      {
                        length: 200
                      }
                    )
                  });

                  return;
                }

                setNewValue(value);
                onSave && (await onSave(newValue));
              }}
            >
              {intl.formatMessage({ id: "ccSave" })}
            </CCButton>
          </>
        }
        onCloseButton={handleClose}
        // onRefreshButton={() => {
        //   setNewValue(value);
        // }}
        onClose={handleClose}
        {...others}
      />
      <CCDialogInputError
        title={openError.value}
        open={openError.state}
        onClose={() => {
          setOpenError({ state: false });
        }}
      />
    </>
  );
};

CCDialogSimpleAddMod.propTypes = {
  /** Dialog open 유무 */
  open: PropTypes.bool,
  /** Dialog close 시키는 함수 */
  onclose: PropTypes.func,
  /** Dialog title */
  title: PropTypes.string,
  /** Dialog TextField에 작성될 문자열 */
  value: PropTypes.string,
  /** 내용 저장하는 함수 */
  onSave: PropTypes.func
};

CCDialogSimpleAddMod.defaultProps = {
  value: ""
};

export default CCDialogSimpleAddMod;
