import React from "react";

const ClaimnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="gjs2dhuvwa" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-1123.000000, -369.000000) translate(1123.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M18.667 8V5.333h-5.334V8h5.334zM5.333 10.667v14.666h21.334V10.667H5.333zM26.667 8c1.48 0 2.666 1.187 2.666 2.667v14.666c0 1.48-1.186 2.667-2.666 2.667H5.333c-1.48 0-2.666-1.187-2.666-2.667l.013-14.666C2.68 9.187 3.853 8 5.333 8h5.334V5.333c0-1.48 1.186-2.666 2.666-2.666h5.334c1.48 0 2.666 1.186 2.666 2.666V8h5.334z"
            transform="translate(-1123.000000, -369.000000) translate(1123.000000, 369.000000)"
          />
          <path
            fill="#00BFA5"
            d="M13.576 20.771L10.394 17.428 9.333 19.542 13.576 24 22.667 14.448 21.606 12.333z"
            transform="translate(-1123.000000, -369.000000) translate(1123.000000, 369.000000)"
          />
          <path
            fill="url(#gjs2dhuvwa)"
            d="M27 1c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4H5c-2.21 0-4-1.79-4-4V5c0-2.21 1.79-4 4-4h22zm-8.333 1.667h-5.334c-1.423 0-2.575 1.097-2.661 2.497l-.005.17V8H5.333C3.91 8 2.771 9.097 2.685 10.497l-.005.17-.013 14.666c0 1.423 1.097 2.575 2.497 2.662l.17.005h21.333c1.423 0 2.575-1.097 2.661-2.497l.005-.17V10.667c0-1.423-1.097-2.575-2.497-2.662L26.666 8h-5.333V5.333c0-1.423-1.097-2.575-2.497-2.661l-.17-.005zm8 8v14.666H5.333V10.667h21.334zm-5.06 1.666l-8.031 8.438-3.182-3.343-1.06 2.114L13.575 24l9.09-9.552-1.06-2.115zm-2.94-7V8h-5.334V5.333h5.334z"
            transform="translate(-1123.000000, -369.000000) translate(1123.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default ClaimnIcon;
