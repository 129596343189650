import pkce from "pkce-challenge";

const { code_verifier, code_challenge } = pkce();

if (
  !sessionStorage.getItem("code_verifier") ||
  !sessionStorage.getItem("code_challenge")
) {
  sessionStorage.setItem("code_verifier", code_verifier);
  sessionStorage.setItem("code_challenge", code_challenge);
}

// Default Settings for Clever_OAuth process.
const defaultSettings = {
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  response_type: "code",
  authCodeRedirectUri: window.location.origin.toString() + "/auth",
  scope: "openid+profile+hospitalInfo",
  code_challenge: sessionStorage.getItem("code_challenge"),
  code_challenge_method: "S256",
  code_verifier: sessionStorage.getItem("code_verifier"),
  post_logout_uri: window.location.origin.toString() + "/intro"
};

// FIXME: LOCAL_TEST_CODE
// const defaultSettings = {
//   client_id: "auth_test",
//   client_secret: "123",
//   response_type: "code",
//   authCodeRedirectUri: "http://localhost:3000/auth",
//   scope: "openid+profile",
//   code_challenge: sessionStorage.getItem("code_challenge"),
//   code_challenge_method: "S256",
//   code_verifier: sessionStorage.getItem("code_verifier")
// };

// console.log(defaultSettings);

export { defaultSettings };
