import React from "react";

const FontsizeIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M22 11h-4v8h-2v-8h-4V9h10v2zM2 5v2h5v12h2V7h5V5H2z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default FontsizeIcon;
