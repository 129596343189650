import React from "react";
import GroupBreak from "../GroupBreak";
import GroupMessageList from "../GroupMessageList";

const GroupMessage = props => {
  const { labId, items, type, timestamp, meId, endOfGroup } = props;

  if (type !== "line") {
    return (
      <GroupMessageList
        items={items}
        meId={meId}
        labId={labId}
        endOfGroup={endOfGroup}
      />
    );
  } else {
    return <GroupBreak timestamp={timestamp} />;
  }
};

export default GroupMessage;
