import { useLoadScript } from "@react-google-maps/api";

const lib = ["places", "geometry", "localContext", "drawing"];
const useLoadScriptWithLocales = props => {
  const { libraries = lib, lang = "en" } = props;
  return useLoadScript({
    googleMapsApiKey: props.apiKey,
    libraries: libraries,
    language: lang
  });
};
useLoadScriptWithLocales.defaultProps = {
  lang: "en",
  libraries: ["places", "geometry", "localContext", "drawing"]
};
export default useLoadScriptWithLocales;
