import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { useFindArabic } from "../../../locales/utils";
import { CCTooltip } from "../../components";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: localStorage.getItem("lang") === "ru" ? 12 : 14,
    padding: "6px 16.5px",
    " &.MuiButton-root": {
      textTransform: "none"
    }
  },
  text: {
    color: props => {
      if (props.color === "selected") return "#ffffff";
      if (props.color === "icon") return theme.palette.icon;
      if (props.color === "error") return theme.palette.error.main;
      if (props.color === "sub") return theme.palette.sub.main;
      if (props.color === "primary") return theme.palette.primary.main;

      return theme.palette.secondary.dark;
    },
    background: props => {
      if (props.color === "selected") return theme.palette.select.main;
      return "none";
    },
    "&:hover": {
      background: props => {
        if (props.color === "selected") return theme.palette.select.dark;
        return "rgba(0, 0, 0, 0.16)";
      }
    },
    "&:disabled": {
      color: props => {
        if (props.color === "selected") return "#ffffff";
        return theme.palette.disabled.rgba;
      },
      backgroundColor: props => {
        if (props.color === "selected") return theme.palette.select.main;
        return "none";
      }
    }
  },
  textSizeSmall: {
    fontSize: 12,
    padding: "1.5px 8px"
  },
  contained: {
    transition: "none",
    color: props => {
      if (props.color === "normal") {
        if (props.size === "medium") {
          return theme.palette.icon;
        }
        return "black";
      } else return "white";
    },
    background: props => {
      if (props.color === "primary") return theme.palette.primary.main;
      if (props.color === "secondary") return theme.palette.secondary.main;
      if (props.color === "error") return theme.palette.error.main;
      if (props.color === "selected") return theme.palette.select.main;
      if (props.color === "normal") return theme.palette.normal.main;
      if (props.color === "sub") return theme.palette.sub.main;
      return theme.palette.primary.main;
    },
    "&:hover": {
      background: props => {
        if (props.color === "primary") return theme.palette.primary.dark;
        if (props.color === "secondary") return theme.palette.secondary.dark;
        if (props.color === "error") return theme.palette.error.dark;
        if (props.color === "selected") return theme.palette.select.dark;
        if (props.color === "normal") return theme.palette.normal.dark;
        if (props.color === "sub") return theme.palette.sub.dark;
        return "#0097a7";
      }
    },
    "&:disabled": {
      color: props => {
        if (props.color === "selected") return "#ffffff";
        return "rgba(0, 0, 0, 0.38)";
      },
      backgroundColor: props => {
        if (props.color === "selected") return theme.palette.select.main;
        return theme.palette.inactive.rgba;
      }
    },
    "&:focus": {
      border: props => {
        if (props.color === "selected")
          return `1px solid ${theme.palette.focusBorder}`;
      }
    },
    border: props => {
      if (props.color === "normal") return "1px solid rgba(0, 0, 0, 0.16)";
      if (props.color === "selected") return "1px solid rgba(0, 0, 0, 0.16)";
      else return "0";
    },
    padding: props => {
      if (props.color === "normal") return "5px 15.5px";
      if (props.color === "selected") return "5px 15.5px";
      else return "6px 16.5px";
    }
  },
  containedSizeSmall: {
    fontSize: 12,
    transition: "none",
    "&:focus": {
      border: props => {
        if (props.color === "selected")
          return `1px solid ${theme.palette.focusBorder}`;
      }
    },
    border: props => {
      if (props.color === "normal") return "1px solid rgba(0, 0, 0, 0.16)";
      if (props.color === "selected") return "1px solid rgba(0, 0, 0, 0.16)";
      else return "none";
    },
    padding: props => {
      if (props.color === "normal") return "0.5px 7px";
      if (props.color === "selected") return "0.5px 7px";
      else return "1.5px 8px";
    }
  },
  outlined: {
    color: props => {
      if (props.color === "selected") return "#ffffff";
      return theme.palette.icon;
    },
    background: props => {
      if (props.color === "selected") return theme.palette.select.main;
      return "none";
    },
    borderColor: props => {
      if (props.color === "selected") return theme.palette.select.main;
      return "rgba(0, 0, 0, 0.16)";
    },
    "&:hover": {
      background: props => {
        if (props.color === "selected") return theme.palette.select.dark;
        return "#ececec";
      }
    },
    "&:disabled": {
      color: props => {
        if (props.color === "selected") return "#ffffff";
        return "rgba(0, 0, 0, 0.38)";
      },
      backgroundColor: props => {
        if (props.color === "selected") return theme.palette.select.main;
        return "none";
      },
      borderColor: props => {
        if (props.color === "selected") return theme.palette.select.dark;
        return theme.palette.inactive.rgba;
      }
    }
  },
  outlinedSizeSmall: {
    fontSize: 12,
    padding: "0.5px 8px"
  }
}));

const useCustomStyles = makeStyles(() => ({
  ellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  arabic_fix: {
    direction: "rtl",
    textAlign: "left"
  }
}));
const CCButton = forwardRef((props, ref) => {
  const { color, tooltip, ...other } = props;
  const { findArabic } = useFindArabic();
  const classes = useStyles(props);
  const customClasses = useCustomStyles();

  return (
    <>
      {tooltip && (
        <CCTooltip title={tooltip}>
          <div>
            <Button
              classes={classes}
              className={customClasses.ellipsis}
              {...other}
              ref={ref}
              disableRipple={true}
              disableFocusRipple={true}
              children={
                <span
                  className={clsx(customClasses.ellipsis, {
                    [customClasses.arabic_fix]: findArabic(props.children)
                  })}
                >
                  {props.children}
                </span>
              }
            />
          </div>
        </CCTooltip>
      )}
      {!tooltip && (
        <Button
          classes={classes}
          className={customClasses.ellipsis}
          {...other}
          ref={ref}
          disableRipple={true}
          disableFocusRipple={true}
          children={
            <span
              className={clsx(customClasses.ellipsis, {
                [customClasses.arabic_fix]: findArabic(props.children)
              })}
            >
              {props.children}
            </span>
          }
        />
      )}
    </>
  );
});

CCButton.propTypes = {
  /** 버튼 색상 */
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "selected",
    "normal",
    "sub",
    "icon"
  ]),
  /** 버튼 타입 */
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  /** 버튼 크기 */
  size: PropTypes.oneOf(["medium", "small"]),
  /** 비활성화 */
  disabled: PropTypes.bool,
  /** tooltip으로 입력된 문자열이 표시됨 */
  tooltip: PropTypes.string
};

CCButton.defaultProps = {
  color: "primary",
  variant: "text",
  size: "medium",
  disabled: false
};

export default CCButton;
