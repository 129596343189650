import React from "react";

const BrightnessIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.76 5.29L4.96 3.5 3.55 4.91 5.34 6.7l1.42-1.41zM1 10.95h3v2H1v-2zM11 1h2v2.95h-2V1zm8.04 2.495l1.408 1.407-1.79 1.79-1.407-1.408 1.789-1.789zm-1.8 15.115l1.79 1.8L20.44 19l-1.8-1.79-1.4 1.4zM20 10.95h3v2h-3v-2zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 14h2v2.95h-2v-2.95zm-7.45-.96l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z"
    />
  </svg>
);

export default BrightnessIcon;
