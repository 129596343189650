import { makeStyles } from "@material-ui/styles";
import { RouteContext } from "context/";
import React, { useContext, useEffect } from "react";
import { CCButton } from "styles/components";
import { useIntl } from "utils/language";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  inner: {}
}));

const Timeout = () => {
  const classes = useStyles();
  const { dispatchRoute } = useContext(RouteContext);
  const intl = useIntl();

  useEffect(() => {
    sessionStorage.setItem("token", "");
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        {translate( intl,"timeoutMsg" )}
      </div>
      <CCButton
        variant="contained"
        color="primary"
        onClick={() => {
          dispatchRoute({ path: "/signin" });
        }}
      >
        {translate( intl,"loginText" )}
      </CCButton>
    </div>
  );
};

export default Timeout;
