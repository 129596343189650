import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { Grid, makeStyles, } from "@material-ui/core";
import {  CCAddressSearchDialog } from "styles/src/components/CCAddressField/component";
import clsx from "clsx";
import { useIntl } from "react-intl";
import {
  CVTextField,
  translate
} from "components";

const useStyles = makeStyles(() => ({
  left__input: { width: '630px' },
  inpuptMargin: {
    margin: "8px 0"
  },
}))


const defaultValue = {
  address: "",
  coordinate: { latitude: 0, longitude: 0 },
  placeId: "",
  detailAddress: ""
}
const CVGoogleMapAddress = props => {
  const { value, onChange, leftError, leftRequired, addressOld = "", leftProps, rightProps, notUseGoogleMapApi } = props;
  const intl = useIntl();
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false);
  
  const [location, setLocation] = useState(value || defaultValue);

  useEffect(() => {
    setLocation(value)
  }, [value])

  useEffect(() => {
    if (window.variables?.category === "common.ui.lab" && window.variables?.openAddressInputDialog) {
      setOpenDialog(true)
    } else{
      setOpenDialog(false)
    }
  }, [])

  return (
    <Grid container direction="column">
      <CVTextField
        label={translate( intl,"common.ui.address1" )}
        endIcon={!notUseGoogleMapApi && <SearchIcon />}
        onClickIcon={e => setOpenDialog(true)}
        variant={"outlined"}
        margin={"dense"}
        value={location?.address || addressOld}
        className ={clsx(classes.left__input, classes.inpuptMargin, leftProps?.className)}
        required={leftRequired}
        error={leftError}
        InputProps={{ readOnly: !notUseGoogleMapApi }}
        onChange={e => {
          if (notUseGoogleMapApi) {
            setLocation({
              ...value,
              address: e.target.value
          });
          onChange({
            ...value,
            address: e.target.value
          })
          }
         
        }}
        {...leftProps}
      />
      <CVTextField
        label={ translate( intl,"common.ui.address2" )}
        variant={ "outlined"}
        margin={ "dense"}
        className ={clsx(classes.left__input,classes.inpuptMargin, rightProps?.className)}
        value={ location?.detailAddress}
        onChange={ e => {
          setLocation({
              ...value,
              detailAddress: e.target.value
          });
          onChange({
            ...value,
            detailAddress: e.target.value
          })
        }}
        {...rightProps}
      />
      <CCAddressSearchDialog
        apiKey={'AIzaSyDc6dr50xs2ZDRGoM7EHhRkr5irkCMF9Ls'}
        value={location}
        open={openDialog}
        onChange={e => {
          onChange && onChange(e);
        }}
        onClose={() => {
          setOpenDialog(false);
          window.variables = { };
        }}
      />
    </Grid>
  );
};


export default CVGoogleMapAddress;
