import React, { forwardRef, useState } from 'react';
import { CCTextField } from 'styles/src/components';
import { VisibleIcon } from 'styles/src/themes/common/icons';
import InvisibleIcon from 'styles/src/themes/common/icons/InvisibleIcon';
import { ENTRY_LIMIT } from '../../types/constants';

const CVPasswordField = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <CCTextField
      {...props}
      InputProps={{
        inputProps: {
          maxLength: ENTRY_LIMIT.MEDIUM_SIZE
        },
      }}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      endIcon={showPassword ? <InvisibleIcon /> : <VisibleIcon />}
      onClickIcon={() => {
        setShowPassword(!showPassword);
      }}
    />
  );
});

export default CVPasswordField;
