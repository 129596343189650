import { RadioGroup } from "@material-ui/core";
import { DialogPermissionWarn } from "components";
import React, { forwardRef, useState } from "react";

const CVRadioGroup = forwardRef((props, ref) => {
  const { children, permission, onChange, ...others } = props;
  const [open, setOpen] = useState(false);
  const isPermission = true;

  const handleChange = () => {
    setOpen(true);
  };

  return (
    <>
      <RadioGroup
        ref={ref}
        onChange={isPermission ? onChange : handleChange}
        {...others}
      >
        {children}
      </RadioGroup>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default CVRadioGroup;
