import { useMutation } from "@apollo/react-hooks";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { translate, enqueueToast } from "components";
import { CVPasswordField } from "components/index";
import gql from "graphql-tag";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";
import { WarningIcon } from "styles/icons";
import { CCDialogSimple } from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import { validPassword } from "utils/password";
import { useSnackbar } from "notistack";
import CVButton from "../CVButton";
const CHANGE_PASSWORD = gql`
  mutation ChangeStaffPassword($oldPassword: String, $newPassword: String!) {
    changeStaffPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      staffId
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: 312,
    padding: 8
  },
  form_item: { display: "flex" },
  fullWidth: {
    width: "100%"
  },
  desc: { padding: " 0 8px", color: theme.palette.grey[400] },
  warning_color: { color: theme.palette.error.main },
}));

const PasswordChangeDialog = props => {
  const { open, onClose = () => { }, forceOpen = false } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const currentPassword = useRef();
  const newPassword = useRef();
  const confirmPassword = useRef();
  const [error, setError] = useState("");
  const intl = useIntl();
  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: data => {
      console.log("changePassword", data);
      enqueueToast(
        enqueueSnackbar,
        translate(intl, "common.message.changePasswordSuccess")
      );
      // refetch();
    }
  });

  return (
    <>
      <CCDialogSimple
        title={translate(intl, "common.ui.changePassword")}
        contentsPadding={false}
        open={open}
        onClose={forceOpen ? () => { } : () => onClose && onClose(false)}
        onCloseButton={forceOpen ? null : () => onClose && onClose(false)}
        contents={
          <Grid container direction={"column"} className={classes.root}>
            <Grid item>
              <CCTypography className={classes.desc}>
                {translate(intl, "common.message.changePasswordNote")}
              </CCTypography>
            </Grid>
            <Grid item className={classes.form_item}>
              <CVPasswordField
                type={"password"}
                label={translate(intl, "common.ui.currentPassword")}
                inputRef={currentPassword}
                variant={"outlined"}
                margin={"dense"}
                InputProps={{
                  inputProps: {
                    autoComplete: "current-password"
                  }
                }}
                className={classes.fullWidth}
              />
            </Grid>
            <Grid item className={classes.form_item}>
              <CVPasswordField
                type={"password"}
                label={translate(intl, "common.ui.newPassword")}
                inputRef={newPassword}
                variant={"outlined"}
                margin={"dense"}
                InputProps={{
                  inputProps: {
                    autoComplete: "new-password"
                  }
                }}
                className={classes.fullWidth}
              />
            </Grid>
            <Grid item className={classes.form_item}>
              <CVPasswordField
                type={"password"}
                label={translate(intl, "common.ui.verifyPassword")}
                inputRef={confirmPassword}
                variant={"outlined"}
                margin={"dense"}
                InputProps={{
                  inputProps: {
                    autoComplete: "new-password"
                  }
                }}
                className={classes.fullWidth}
              />
            </Grid>
          </Grid>
        }
        endActions={
          <>
            {!forceOpen && (
              <CVButton variant={"text"} onClick={() => onClose()}>
                {translate(intl, "common.ui.cancel")}
              </CVButton>
            )}
            <CCButton
              variant={"contained"}
              onClick={() => {
                if (
                  !Boolean(newPassword.current.value) ||
                  !Boolean(confirmPassword.current.value) ||
                  !validPassword(newPassword.current.value) ||
                  newPassword.current.value !== confirmPassword.current.value
                ) {
                  setError(
                    translate(intl, "common.message.changePasswordNote")
                  );
                  return;
                }

                if (
                  currentPassword.current.value === newPassword.current.value
                ) {
                  setError(
                    translate(intl, "common.message.changePasswordError2")
                  );
                  return;
                }
                changePassword({
                  variables: {
                    oldPassword: currentPassword.current.value,
                    newPassword: newPassword.current.value
                  }
                }).then(res => {
                  console.log("registerNewLab result", res)
                  onClose();
                })
                  .catch(error => {
                    const { graphQLErrors } = error;
                    if (graphQLErrors) {
                      graphQLErrors.forEach(graphQLError => {
                        setError(graphQLError?.message);
                      });
                    }
                  });

                // onClose();
              }}
            >
              {translate(intl, "common.ui.save")}
            </CCButton>
          </>
        }
      />
      <CCDialogSimple
        open={Boolean(error)}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => setError("")}
        title={<WarningIcon className={classes.warning_color} />}
        contents={
          <Box className={classes.text}>
            <CCTypography className={classes.warning_color}>
              {error}
            </CCTypography>
          </Box>
        }
        endActions={
          <CCButton
            className={classes.disagree}
            color="secondary"
            onClick={() => setError("")}
          >
            {intl.formatMessage({ id: "common.ui.ok" })}
          </CCButton>
        }
      />
    </>
  );
};

export default PasswordChangeDialog;
