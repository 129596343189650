import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { InfiniteTable } from "components";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { useIntl } from "utils/language";
import { useQuery } from "@apollo/client";
import { DBStorageContext } from "context/DBContext/components";
import { SEARCH_PRODUCT_LOG } from "queries/product";
import { CCIconButton, CCTooltip } from "styles/src/components";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/utils/TableItems";
import { PictureIcon } from "styles/src/themes/common/icons";
import Checkbox from "@material-ui/core/Checkbox";
import { useFormContext, Controller } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: 425,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  container: { height: "100%" },
  container__filter: {
    textAlign: "center"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  item__table: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    textAlign: "center"
  },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  text__description: {
    color: theme.palette.disabled.hex
  },
  button__sms: {
    width: "110px",
    height: "24px",
    padding: "0px"
  },
  button__filter: {
    minWidth: "100px",
    minHeight: "10px",
    margin: "16px"
  },
  select: { width: "100px", margin: "4px" },
  select__w126: { width: "126px" },
  select__w155: { width: "155px" },

  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center"
  },
  head__field: {
    width: "100px"
  },
  headertwo: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "80px",
    alignItems: "center"
  },
  headerfilter: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  header__table: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  field: {
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    textAlign: "center"
  },
  table: {
    height: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  shrink: {
    background: theme.palette.common.white,
    paddingRight: "4px"
  },
  button_register: {
    padding: "0px",
    width: "120px",
    margin: "10px"
  },
  button_right: {
    marginTop: "10px",
    marginRight: "10px"
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  icon__star: {
    margin: 12,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  primary__color: {
    color: theme.palette.primary.main
  },
  error__color: {
    color: theme.palette.error.main
  }
}));

const SearchResults = forwardRef((props, ref) => {
  const {
    result = [],
    option = "1",
    setValues = () => {},
    onChangeProduct = () => {}
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [selectList, setSelectList] = useState([]);

  const { control, getValues } = useFormContext();

  useImperativeHandle(ref, () => selectList);

  const [searchResult, setSearchResult] = useState();
  const { data, refetch } = useQuery(SEARCH_PRODUCT_LOG, {
    variables: {
      def: "1",
      sort: { indexKey: "createTime", order: true }
    },
    onCompleted: async () => {}
  });

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  useEffect(() => {
    refetch();
    setSearchResult(data?.searchProductLog);
  }, [data, searchResult,  refetch]);

  useEffect(() => {
    onChangeProduct(selectList);
    setValues(selectList);
  }, [selectList, onChangeProduct, setValues]);

  useEffect(() => {
    if (option) {
      setSelectList([]);
    }
  }, [option]);

  return (
    <Box className={classes.root}>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.item__table} item>
          <Controller
            name={"productList"}
            control={control}
            defaultValue={getValues("productList") || selectList}
            render={props => {
              const { ref, value } = props;

              return (
                <InfiniteTable
                  inputRef={ref}
                  heads={[
                    {
                      key: "",
                      headComponent: ({ rowData, cellData }) => {
                        let _data = result.filter(onlyUnique);
                        return (
                          <Checkbox
                            checked={selectList?.length === _data?.length}
                            style={{ padding: 10 }}
                            disableRipple
                            onChange={e => {
                              if (e.target.checked) {
                                setSelectList(_data);
                              } else {
                                setSelectList([]);
                              }
                            }}
                          />
                        );
                      },
                      width: 80,
                      component: ({ rowData }) => {
                        let _checked = selectList?.includes(rowData);
                        return (
                          <TableItem
                            disableTypography
                            label={
                              <Checkbox
                                checked={_checked}
                                style={{ padding: 10 }}
                                disableRipple
                                onChange={e => {
                                  if (e.target.checked) {
                                    if (Boolean(selectList?.length > 0)) {
                                      setSelectList([...selectList, rowData]);
                                    } else {
                                      setSelectList([rowData]);
                                    }
                                  } else {
                                    setSelectList(
                                      selectList.filter(e => e !== rowData)
                                    );
                                  }
                                }}
                                className={classes.row__focus}
                              />
                            }
                          />
                        );
                      }
                    },
                    {
                      key: "",
                      width: 40,
                      headComponent: () => <TableHeader label={"#"} />,
                      component: ({ rowData, rowIndex }) => {
                        return <TableItem label={rowIndex + 1} />;
                      }
                    },
                    {
                      key: "major",
                      width: 150,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "majorCategory" })}
                        />
                      ),
                      component: ({ cellData }) => {
                        return <TableItem label={cellData ? cellData : ""} />;
                      }
                    },
                    {
                      key: "productName",
                      width: 420,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "productName" })}
                        />
                      ),
                      component: ({ cellData }) => (
                        <TableItem label={cellData ? cellData : ""} />
                      )
                    },
                    {
                      key: "storage",
                      width: 25,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "unit" })}
                        />
                      ),
                      component: ({ cellData }) => (
                        <TableItem
                          label={cellData?.unit === "BIN" ? "BIN" : "EA"}
                        />
                      )
                    },
                    {
                      key: "manufacture",
                      width: 150,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "manufacturer" })}
                        />
                      ),
                      component: ({ cellData }) => (
                        <TableItem label={cellData ? cellData : ""} />
                      )
                    },
                    {
                      key: "image",
                      width: 40,
                      headComponent: () => (
                        <TableHeader
                          label={intl.formatMessage({ id: "image" })}
                        />
                      ),
                      component: ({ cellData }) => {
                        return cellData?.url ? (
                          <TableItem
                            disableTypography
                            label={
                              <CCTooltip
                                title={
                                  <>
                                    <Box
                                      style={{
                                        width: 300,
                                        height: 300,
                                        backgroundImage: `url(${cellData?.url})`,
                                        backgroundPosition: "center center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat"
                                      }}
                                    />
                                  </>
                                }
                              >
                                <CCIconButton
                                  style={{ padding: 0, margin: 0 }}
                                  disableRipple
                                  variant={"text"}
                                  size={"small"}
                                >
                                  {option === "1" ? <PictureIcon /> : ""}
                                </CCIconButton>
                              </CCTooltip>
                            }
                          />
                        ) : null;
                      }
                    }
                  ]}
                  contents={result || []}
                  selected={e => {
                    if (value) {
                      return e === value;
                    }
                    return false;
                  }}
                  onRowClick={({ rowData }) => {
                    if (value && value?.productID === rowData?.productID) {
                      setSelectList(null);
                      return;
                    }
                    // setFocusIndex(rowIndex);
                  }}
                  rowHoverHightlight={true}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      {/*{Boolean(detail) && (*/}
      {/*  <DetailResultsDialog*/}
      {/*    value={detail}*/}
      {/*    open={Boolean(detail)}*/}
      {/*    onClose={() => setDetail(false)}*/}
      {/*  />*/}
      {/*)}*/}
    </Box>
  );
});

export default SearchResults;
