import { Box, makeStyles } from "@material-ui/core";
import { CVTable, Loading, translate } from "components";
import moment, * as moment_tz from "moment-timezone";
import React, { useState } from "react";
import { CCButton, CCDialogSimple, CCTypography } from "styles/src/components";
import { ProgressIcon } from "styles/src/themes/common/icons";
import { DESIGN_CONFIRM_STATUS, } from "types/labOrder";
import { GET_DESIGN_CONFIRM_REQUESTS } from "queries/labOrder";
import { makeList } from "types/utils";
import { RequestDesignConfirmationModal } from "../";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    width: 757,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column"
  },
  send__request__button: {
    alignSelf: "flex-end",
    margin: "4px 17px 20px 0"
  },
  table: {
    height: 468,
    borderTop: `1px solid ${theme.palette.border.main}`,
    textAlign: "center"
  }
}));


const DesignConfirmationModal = props => {
  const { open, onClose, orderId, dentalName, labName, patientName } = props;

  const intl = useIntl();
  const classes = useStyles();
  const labOrderTypeItem = makeList(DESIGN_CONFIRM_STATUS);

  const [selectedDesignConfirmation, setSelectedDesignConfirmation] = useState({
    dentalName
  });
  const [
    requestDesignConfirmationVisible,
    setRequestDesignConfirmationVisible
  ] = useState("close");
  const [isAddingRequest, setIsAddingRequest] = useState(false);

  const { data, loading, refetch } = useQuery(GET_DESIGN_CONFIRM_REQUESTS, {
    variables: {
      id: orderId
    },
  });


  return !loading && orderId ? (
    <>
      <CCDialogSimple
        open={open}
        contentsPadding={false}
        title={translate(intl, "common.ui.designConfirmationNumber", {
          Number: data?.orderDetail?.designConfirmRequests.length
        })}
        onClose={onClose}
        onCloseButton={onClose}
        maxWidth={"md"}
        contents={
          <Box className={classes.root}>
            <CCButton
              color={"primary"}
              variant={"contained"}
              startIcon={<ProgressIcon />}
              onClick={e => {
                setIsAddingRequest(true);
                setSelectedDesignConfirmation({ sender: dentalName });
                setRequestDesignConfirmationVisible("create");
              }}
              className={classes.send__request__button}
            >
              {
                translate(intl, "common.ui.sendRequest")
              }
            </CCButton>
            <Box className={classes.table__box}>
              <Loading open={false} zIndex={99} />
              <CVTable
                // permission={permission}
                alignCenter={true}
                className={classes.table}
                onRowClick={e => {
                  setIsAddingRequest(false);
                  setSelectedDesignConfirmation(Object.assign({}, e.data));
                  setRequestDesignConfirmationVisible("view");
                }}
                heads={[
                  {
                    label: `${translate(intl, "labManageGold.date")}`,
                    key: "date",
                    className: classes.cell,
                    width: 164,
                    component: ({ rowData }) => (
                      <CCTypography>
                        {moment(rowData?.date, "X")
                          .tz(moment_tz.tz.guess(true))
                          .format("L LT")}
                      </CCTypography>
                    )
                  },
                  {
                    label: `${translate(intl, "common.ui.sender")}`,
                    key: "sender",
                    className: classes.cell,
                    width: 125,
                    component: ({ rowData }) => (
                      <CCTypography>
                        {rowData.createdBy === "Lab" ? translate(intl, "labManageGold.lab") : translate(intl, "common.ui.clinic")}
                      </CCTypography>
                    )
                  },
                  {
                    label: `${translate(intl, "common.ui.status")}`,
                    key: "status",
                    className: classes.cell,
                    width: 126,
                    component: ({ rowData }) => {
                      const color =
                        rowData.status === "2"
                          ? "primary"
                          : rowData.status === "3"
                            ? "error"
                            : "inherit";
                      const statusType = labOrderTypeItem.find(
                        // eslint-disable-next-line eqeqeq
                        type => type.value == rowData.status
                      );
                      return (
                        <CCTypography color={color}>
                          {statusType?.label}
                        </CCTypography>
                      );
                    }
                  },
                  {
                    label: `${translate(intl, "payment.note")}`,
                    key: "note",
                    className: classes.cell
                  }
                ]}
                contents={data?.orderDetail?.designConfirmRequests}
              />
            </Box>
          </Box>
        }
      />

      <RequestDesignConfirmationModal
        open={requestDesignConfirmationVisible !== "close"}
        onClose={() => {
          setRequestDesignConfirmationVisible("close");
          refetch()
        }}
        isCreateOrView={requestDesignConfirmationVisible}
        value={selectedDesignConfirmation}
        labOrderId={orderId}
        labName={labName}
        patientName={patientName}
        isAddingRequest={isAddingRequest}
      />
    </>
  )
    : "";
};

DesignConfirmationModal.defaultProps = {};

DesignConfirmationModal.propTypes = {};

export default DesignConfirmationModal;
