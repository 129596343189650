export { default as CCFlattenDateTimePicker } from "./CCFlattenDateTimePicker";
export { default as CCFlattenDatePicker } from "./CCFlattenDatePicker";
export { default as CCFlattenTimePicker } from "./CCFlattenTimePicker";
export { default as CCFlattenDateTimeFieldQuattro } from "./CCFlattenDateTimeFieldQuattro";
export { default as CCFlattenDateField } from "./CCFlattenDateField";
export { default as CCFlattenTimeField } from "./CCFlattenTimeField";
export { default as CCFlattenTimeSelect } from "./CCFlattenTimeSelect";
export const getWeek = value => {
  let _week = {
    begin: value.clone().startOf("week"),
    end: value.clone().endOf("week")
  };
  let _month = {
    begin: value.clone().startOf("month"),
    end: value.clone().endOf("month")
  };
  return {
    begin: _month.begin.isSameOrAfter(_week.begin)
      ? _month.begin.clone()
      : _week.begin.clone(),
    end: _month.end.isSameOrBefore(_week.end)
      ? _month.end.clone()
      : _week.end.clone()
  };
};
