import React from "react";

const GoldIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M9.633 12c.497 0 .92.366.99.859L11.5 19h-10l.877-6.141c.07-.493.493-.859.99-.859h6.266zm11 0c.497 0 .92.366.99.859L22.5 19h-10l.877-6.141c.07-.493.493-.859.99-.859h6.266zm-5.5-8c.497 0 .92.366.99.859L17 11H7l.877-6.141c.07-.493.493-.859.99-.859h6.266z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default GoldIcon;
