import {gql} from "@apollo/client";

const CREATE_PHOTO = gql`
  mutation createPhoto(
    $hospitalId: String!
    $id: String!
    $type: String!
    $date: Date!
    $patient: String!
    $content: InputPhotoContent!
    $log: InputLogDetail
  ) {
    createPhoto(
      hospitalId: $hospitalId
      id: $id
      type: $type
      date: $date
      patient: $patient
      content: $content
      log: $log
    )
  }
`;

const UPDATE_PHOTO = gql`
  mutation updatePhoto(
    $hospitalId: String!
    $id: String!
    $content: InputPhotoContent!
    $log: InputLogDetail
  ) {
    updatePhoto(
      hospitalId: $hospitalId
      id: $id
      content: $content
      log: $log
    ) {
      id
    }
  }
`;

const REMOVE_PHOTO = gql`
  mutation removePhoto(
    $hospitalId: String!
    $id: String!
    $log: InputLogDetail
  ) {
    removePhoto(hospitalId: $hospitalId, id: $id, log: $log) {
      id
    }
  }
`;

const SEARCH_PHOTO = gql`
  query searchPhoto($hospitalId: String!, $args: InputSearchPhoto) {
    searchPhoto(hospitalId: $hospitalId, args: $args) {
      id
      date
      type
      patient
      lastModifiedTime
      content {
        filetype
        filename
        fileattr
        filenode
        folder
        object
        source
        thumbnail
        content
        region
        tools
        detail
        tags
        target
      }
    }
  }
`;

const SEARCH_PHOTO_NOAUTH = gql`
  query searchPhotoNoAuth($hospitalId: String, $args: InputSearchPhoto) {
    searchPhotoNoAuth(hospitalId: $hospitalId, args: $args) {
      id
      date
      type
      patient
      lastModifiedTime
      content {
        filetype
        filename
        fileattr
        filenode
        folder
        object
        source
        thumbnail
        content
        region
        tools
        detail
        tags
        target
      }
    }
  }
`;

const GET_PHOTO = gql`
  query getPhoto($hospitalId: String!, $id: String!) {
    getPhoto(hospitalId: $hospitalId, id: $id) {
      id
      date
      type
      patient
      content {
        filetype
        filename
        fileattr
        filenode
        folder
        object
        source
        thumbnail
        content
        region
        tools
        detail
        tags
        target
      }
    }
  }
`;

const BATCH_GET_PHOTO = gql`
  query batchGetPhoto($hospitalId: String!, $id: [String!]) {
    batchGetPhoto(hospitalId: $hospitalId, id: $id) {
      id
      date
      type
      patient
      createTime
      lastModifiedStaff
      lastModifiedTime
      content {
        content
        detail
        fileattr
        filename
        filenode
        filetype
        folder
        object
        region
        tools
        source
        tags
        target
        thumbnail
      }
    }
  }
`;

const GET_PHOTO_SETTING = gql`
  query getConfig($hospitalId: String!) {
    getConfig(hospitalId: $hospitalId, category: "photoSetting") {
      photoSetting {
        tags
      }
    }
  }
`;

const UPDATE_PHOTO_SETTING = gql`
  mutation updateConfig(
    $hospitalId: String!
    $photoSetting: InputPhotoSetting
  ) {
    updateConfig(
      hospitalId: $hospitalId
      photoSetting: $photoSetting
      category: "photoSetting"
    )
  }
`;

export {
  CREATE_PHOTO,
  UPDATE_PHOTO,
  REMOVE_PHOTO,
  SEARCH_PHOTO,
  SEARCH_PHOTO_NOAUTH,
  GET_PHOTO,
  BATCH_GET_PHOTO,
  GET_PHOTO_SETTING,
  UPDATE_PHOTO_SETTING
};
