import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { NumberFormatCustom } from 'components/Chart/components/ChartInput/components/PlanTab/Utils';
import React, { useEffect, useReducer, useState } from 'react';
import {
    CCButton,
    CCDialogSimple,
    CCIconButton,
    CCTextField
} from 'styles/src/components';
import CCDialogInputError from 'styles/src/components/CCDialogInputError';
import DiscountIcon from 'styles/src/themes/common/icons/DiscountIcon';
import RemoveIcon from 'styles/src/themes/common/icons/RemoveIcon';
import CHART from 'types/chart';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    activeIcon: {
      color: theme.palette.secondary.main,
    },
    discountSelect: {
      width: 114,
    },
    discountPercentageInput: {
      width: 76,
    },
    discountValueInput: {
      width: 128,
    },
    dialogContent: {
      minHeight: 110,
      boxSizing: 'border-box',
    },
  }),
  { name: 'TreatmentPriceItem' },
);

// function NumberFormatCustom(props) {
//   const { inputRef, onChange, ...other } = props;
//   return (
//     <NumberFormat
//       {...other}
//       className={props.className}
//       getInputRef={inputRef}
//       onValueChange={(values) => {
//         onChange({
//           target: {
//             name: props.name,
//             value: values.value,
//           },
//         });
//       }}
//       thousandSeparator
//       displayType={"input"}
//     />
//   );
// }

const getDiscountRate = (value, discount = '', discountOptions = []) => {
  if (value?.direct) {
    return '직접입력';
  }
  if (discount === 'direct') {
    return '직접입력';
  } else {
    let _idx = discountOptions.findIndex((item) => item.id === discount);
    if (_idx > -1) {
      return `${discountOptions[_idx].value}%`;
    } else {
      return '직접입력';
    }
  }
};

const initializer = (props) => {
  const { open, discountOption, rowData } = props;
  let _discount = open?.direct ? 'direct' : open?.uuid ? open.uuid : 'direct';
  let _discountRate = getDiscountRate(open, _discount, discountOption);
  // console.log(props);
  return {
    open: Boolean(open),
    value: {
      ...open,
      direct: _discount === 'direct' ? true : Boolean(open?.direct),
    },
    discountOption: [
      { id: 'direct', content: '직접입력', value: 'direct' },
      ...discountOption,
    ],
    inputErr: false,
    discount: _discount,
    discountRate: _discountRate,
    normalPrice: rowData.content.item.useOption
      ? rowData.content.item.option?.price
        ? rowData.content.item.option?.price
        : 0
      : rowData.content.item.price,
    discountMenuItems: discountOption.reduce(
      (acc, cur) => {
        acc.push({
          key: `${cur.value}-${cur.content}`,
          value: cur.id,
          label: `${cur.content} (${cur.value}%)`,
        });
        return acc;
      },
      [
        {
          key: 'direct-direct',
          value: 'direct',
          label: '직접입력',
        },
      ],
    ),
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...initializer(action.target),
      };
    case 'setDiscount':
      // console.log(action);
      if (action.target === 'direct') {
        return {
          ...state,
          discount: action.target,
          discountRate: getDiscountRate(
            {
              ...state?.value,
              direct: true,
            },
            action.target,
            state.discountOption,
          ),
          value: {
            ...state?.value,
            direct: true,
          },
        };
      } else {
        let _index = state.discountOption.findIndex(
          (item) => item.id === action.target,
        );
        // console.log("_index", _index);
        if (_index === -1) {
          return state;
        }
        let _discountOption = state.discountOption[_index];
        let _value = {
          ...state.value,
          ..._discountOption,
          uuid: _discountOption.id,
          direct: false,
          result: Number(
            Math.round(
              state.normalPrice * (Number(_discountOption.value) / 100),
            ),
          ),
        };
        delete _value.id;
        return {
          ...state,
          discount: _discountOption.id,
          discountRate: getDiscountRate(
            _value,
            action.target,
            state.discountOption,
          ),
          value: _value,
        };
      }
    case 'setResultDirect':
      return {
        ...state,
        value: {
          ...state.value,
          result: Number(action.target),
        },
      };
    case 'setInputErr':
      return {
        ...state,
        inputErr: action.target,
      };
    default:
      break;
  }
  return state;
};

const DiscountDialog = (props) => {
  const { open, onClose, onChange, rowData, rowIndex } = props;
  const classes = useStyles();
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  // console.log(state);
  // console.log(props);
  useEffect(() => {
    dispatchState({ type: 'init', target: props });
  }, [props]);
  return (
    <>
      <CCDialogSimple
        open={Boolean(open)}
        title={'할인입력'}
        maxWidth={'lg'}
        contents={
          <Box className={clsx(classes.dialogContent)}>
            <Box>
              <Grid container justify={'flex-start'} alignItems={'center'}>
                <Grid item>
                  <CCTextField
                    value={state.normalPrice}
                    label={'정상 비용(원)'}
                    variant={'outlined'}
                    margin={'dense'}
                    readOnly
                    disabled
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        style: { textAlign: 'right' },
                        maxLength: 11,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <CCTextField
                    value={state.discount}
                    label={'할인구분'}
                    variant={'outlined'}
                    margin={'dense'}
                    select={true}
                    InputProps={{
                      className: clsx(classes.discountSelect),
                    }}
                    onChange={(e) => {
                      dispatchState({
                        type: 'setDiscount',
                        target: e.target.value,
                      });
                    }}
                  >
                    {state.discountMenuItems &&
                      state.discountMenuItems.map((item) => {
                        return (
                          <MenuItem key={item.key} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                  </CCTextField>
                </Grid>
                <Grid item>
                  <CCTextField
                    value={state.discountRate}
                    label={'할인율(%)'}
                    variant={'outlined'}
                    margin={'dense'}
                    readOnly
                    disabled
                    onChange={() => {}}
                    InputProps={{
                      className: clsx(classes.discountPercentageInput),
                    }}
                  />
                </Grid>
                <Grid item className={clsx(classes.discountValueInput)}>
                  <CCTextField
                    value={state.value?.result || 0}
                    disabled={!state.value?.direct}
                    label={'할인(원)'}
                    variant={'outlined'}
                    margin={'dense'}
                    onChange={(e) => {
                      if (e.target.value !== state?.value?.result)
                        dispatchState({
                          type: 'setResultDirect',
                          target: Number(e.target.value),
                        });
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        style: { textAlign: 'right' },
                        maxLength: 11,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container justify={'flex-end'} alignItems={'center'}>
                <Grid item className={clsx(classes.discountValueInput)}>
                  <CCTextField
                    disabled
                    value={state.normalPrice - state?.value?.result}
                    label={'할인 후 비용(원)'}
                    variant={'outlined'}
                    margin={'dense'}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        style: { textAlign: 'right' },
                        maxLength: 11,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        }
        endActions={
          <>
            <CCButton
              variant='text'
              onClick={() => {
                onClose && onClose();
              }}
            >
              취소
            </CCButton>
            <CCButton
              variant='contained'
              onClick={() => {
                let _price = state.normalPrice - state?.value?.result;
                if (_price < 0) {
                  dispatchState({ type: 'setInputErr', target: true });
                  return true;
                }

                onChange &&
                  onChange({
                    rowIndex: rowIndex,
                    rowData: {
                      ...rowData,
                      discount: {
                        ...state.value,
                      },
                      price: _price,
                    },
                  });
                onClose && onClose();
              }}
            >
              저장/입력
            </CCButton>
          </>
        }
        onCloseButton={() => {
          onClose && onClose();
        }}
        onClose={() => {
          onClose && onClose();
        }}
      />
      <CCDialogInputError
        open={state.inputErr}
        onClose={() => {
          dispatchState({ type: 'setInputErr', target: false });
        }}
      />
    </>
  );
};

const TreatmentPriceItem = (props) => {
  const { rowIndex, rowData, cellData, onChange, discountOption, pl } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(null);

  return (
    <>
      <DiscountDialog
        rowData={rowData}
        open={open}
        discountOption={discountOption}
        onChange={onChange}
        onClose={() => setOpen(null)}
        rowIndex={rowIndex}
      />
      {Boolean(rowData?.content?.item?.insurance) ? (
        <CCTextField
          variant={'outlined'}
          margin={'dense'}
          value={'건강보험 수가'}
          disabled={true}
          readOnly
          endIcon={
            <DiscountIcon
              className={clsx({
                [classes.activeIcon]: Boolean(rowData.discount.result),
              })}
            />
          }
        />
      ) : (
        <CCTextField
          variant={'outlined'}
          margin={'dense'}
          value={cellData < 0 ? 0 : cellData}
          disabled={
            pl.status === CHART.TREATMENT_PLAN.STATUS.PLAN.CLOSING.value
          }
          readOnly
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position='end'>
                  <CCIconButton
                    color={'sub'}
                    size={'small'}
                    tooltip={'할인취소'}
                    onClick={() => {
                      onChange({
                        rowIndex: rowIndex,
                        rowData: {
                          ...rowData,
                          discount: {
                            result: 0,
                            content: '',
                            direct: false,
                            value: '',
                          },
                        },
                        price: Number(
                          rowData.content.item.useOption
                            ? rowData.content.item.option?.price
                              ? rowData.content.item.option?.price
                              : 0
                            : rowData.content.item.price,
                        ),
                      });
                    }}
                  >
                    <RemoveIcon />
                  </CCIconButton>
                  <CCIconButton
                    color={Boolean(rowData.discount.result) ? 'active' : 'sub'}
                    size={'small'}
                    tooltip={'할인입력'}
                    onClick={
                      pl.status ===
                      CHART.TREATMENT_PLAN.STATUS.PLAN.CLOSING.value
                        ? null
                        : () => setOpen({ ...rowData.discount })
                    }
                  >
                    <DiscountIcon
                      className={clsx({
                        [classes.activeIcon]: Boolean(rowData.discount.result),
                      })}
                    />
                  </CCIconButton>
                </InputAdornment>
              </>
            ),
            style: { paddingRight: 8 },
            inputComponent: NumberFormatCustom,
            inputProps: {
              style: { textAlign: 'right' },
              maxLength: 11,
            },
          }}
        />
      )}
    </>
  );
};

export default TreatmentPriceItem;
