import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { MenunextIcon } from "../../../../themes/common/icons";
import CCMenu from "../../CCMenu";
import CCMenuItem from "../CCMenuItem";

const useStyle = makeStyles(
  theme => ({
    root: {
      color: props => {
        return props.color ? props.color : "";
      },
      justifyContent: "space-between"
    },
    icon: {
      color: theme.palette.input.main
    }
  }),
  { name: "CCNestedMenuItem" }
);

const CCNestedMenuItem = props => {
  const {
    className,
    color,
    disabled,
    label,
    menuItems,
    onClose,
    value,
    NestedMenuItemComponent,
    MenuItemComponent,
    ...others
  } = props;

  const classes = useStyle(props);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleItemClick = event => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleNestedMenuClose = event => {
    setOpen(false);
    onClose(event);
  };

  return (
    <>
      {Boolean(MenuItemComponent) && (
        <MenuItemComponent
          className={clsx(className, classes.root)}
          color={color ? color : ""}
          disabled={disabled ? disabled : false}
          onClick={handleItemClick}
          {...others}
        >
          {label}
          <MenunextIcon className={classes.icon} />
        </MenuItemComponent>
      )}
      {!Boolean(MenuItemComponent) && (
        <CCMenuItem
          className={clsx(className, classes.root)}
          color={color ? color : ""}
          disabled={disabled ? disabled : false}
          onClick={handleItemClick}
          {...others}
        >
          {label}
          <MenunextIcon className={classes.icon} />
        </CCMenuItem>
      )}
      <CCMenu
        MenuItemComponent={MenuItemComponent}
        NestedMenuItemComponent={NestedMenuItemComponent}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        MenuItemProps={{
          className: clsx(className, classes.root)
        }}
        menuItems={menuItems}
        onClose={handleNestedMenuClose}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      />
    </>
  );
};

export default CCNestedMenuItem;
