import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { translate } from "components";
import React from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/src/components";
import { LAB_ORDER_STATE } from "types/labOrder";
import { makeIndexFormattedMessageList } from "types/utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "6px 8px",
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    marginRight: 12,
    width: 82,
    minWidth: 82,
    padding: 6,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.common.white,
    paddingLeft: 4,
    wordBreak: "break-word"
  },
  shipped__item: {
    backgroundColor: "rgba(0, 0, 0, 0.16)",
    "& span": {
      textDecoration: "line-through"
    }
  },
  ordered: {
    border: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.common.white,
    color: "#01579b"
  },
  title: { wordBreak: "break-word" },
  caddes: {
    backgroundColor: "#40c4ff"
  },
  req: {
    backgroundColor: "#00000061"
  },
  complete: {
    backgroundColor: "#01579b"
  },
  inprod: {
    backgroundColor: "#ffab00"
  },
  confirmed: { backgroundColor: "#00000061" },
  shipped: {
    backgroundColor: "#00897b"
  }
}));

const OrderItem = props => {
  const { status = "0", title = "", onClick } = props;

  const classes = useStyles();
  const intl = useIntl();

  // For temporary case, need to change the value of labOrder in locale.js
  // for consistent locale in LabOrder and Calendar
  const renderStatus = status => {
    if (status === LAB_ORDER_STATE.COMPLETE.value)
      return translate(intl, "labOrder.complete");
    else if (status === LAB_ORDER_STATE.CAD_DESIGN.value)
      return translate(intl, "labOrder.cadDesign");
    return orderStatus[status];
  };

  const orderStatus = makeIndexFormattedMessageList(LAB_ORDER_STATE);

  return (
    <Box
      className={clsx(classes.root, {
        [classes.shipped__item]: status === LAB_ORDER_STATE.SHIPPED.value
      })}
      onClick={onClick}
    >
      <Box
        className={clsx(classes.status, {
          [classes.ordered]: status === LAB_ORDER_STATE.ORDERED.value,
          [classes.caddes]: status === LAB_ORDER_STATE.CAD_DESIGN.value,
          [classes.req]: status === LAB_ORDER_STATE.CONFIRM_REQUEST.value,
          [classes.confirmed]: status === LAB_ORDER_STATE.CONFIRMED.value,
          [classes.inprod]: status === LAB_ORDER_STATE.IN_PROD.value,
          [classes.complete]: status === LAB_ORDER_STATE.COMPLETE.value,
          [classes.shipped]: status === LAB_ORDER_STATE.SHIPPED.value
        })}
      >
        {renderStatus(status)}
      </Box>
      <CCTypography variant="body1" component="span" className={classes.title}>
        {title}
      </CCTypography>
    </Box>
  );
};

export default OrderItem;
