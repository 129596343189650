import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { CCPaper} from "styles/src/components";
import { LabOrderTotal } from "./components";

const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  paper: { height: "100%", position: "relative" },
}));

const LabOrderView = props => {
  const { dispatch, permission, onModify, state } = props;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root)}>
      <CCPaper className={classes.paper}>
        {/* <PermissionBackdrop permission={permission} /> */}
        <LabOrderTotal
          permission={permission}
          onModify={onModify}
          state={state}
          dispatch={dispatch}
        />
      </CCPaper>
    </Box>
  );
};

LabOrderView.defaultProps = {};

LabOrderView.propTypes = {};

export default LabOrderView;
