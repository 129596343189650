import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { ViewBody, ViewBottom, ViewOrderField } from "../";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1490
  }
}));

const ViewContents = props => {
  const {
    value,
    deliveryUpdate,
    onClose,
    onModify,
    onSave,
    onChange,
    buttonHidden,
    permission
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ViewOrderField value={value} />
      <ViewBody
        permission={permission}
        value={value}
        deliveryUpdate={deliveryUpdate}
        onChange={onChange}
      />
      <ViewBottom
        permission={permission}
        value={value}
        onClose={onClose}
        onModify={onModify}
        onSave={onSave}
        buttonHidden={buttonHidden}
      />
    </Box>
  );
};

ViewContents.defaultProps = {};

ViewContents.propTypes = {};

export default ViewContents;
