import gql from "graphql-tag";

const ON_NOTIFICATION_ADDED = gql`
  subscription onNotificationAdded{
    onNotificationAdded{
      id
      category
      title
    }
  }
`;

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($input: UpdateNotificationInput!){
    updateNotification(input: $input){
      id
      category
      title
    }
  }
`;
const GET_NOTIFICATIONS = gql`
  query getNotifications{
    getNotifications{
      items{
        id
        category
        title
        labId
        labName
        clinicId
        clinicName
        date
      }
      total
    }
  }
`;


const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $hospitalId: String!
    $category: String
    $title: String
    $keyId: String
    $localeKey: String
    $localeArgs: [String]
    $dateArgs: [Date]
  ) {
    createNotification(
      hospitalId: $hospitalId
      category: $category
      title: $title
      keyId: $keyId
      localeKey: $localeKey
      localeArgs: $localeArgs
      dateArgs: $dateArgs
    )
  }
`;

const REMOVE_NOTIFICATION = gql`
  mutation removeNotification($hospitalId: String!, $id: String!) {
    removeNotification(hospitalId: $hospitalId, id: $id)
  }
`;

const SEARCH_NOTIFICATION = gql`
  query searchNotification(
    $hospitalId: String!
    $args: InputSearchNotification
    $comparison: String
    $match: String
    $sort: InputSort
  ) {
    searchNotification(
      hospitalId: $hospitalId
      args: $args
      comparison: $comparison
      match: $match
      sort: $sort
    ) {
      hospitalId
      id
      category
      title
      keyId
      localeKey
      localeArgs
      dateArgs
    }
  }
`;

export {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  ON_NOTIFICATION_ADDED,
  CREATE_NOTIFICATION, 
  REMOVE_NOTIFICATION, 
  SEARCH_NOTIFICATION
};

