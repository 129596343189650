import { useApolloClient } from "@apollo/client";
import { AppContext } from "context";
import { number } from "prop-types";
import { CREATE_PRODUCT_LOG } from "queries/product";

import { useContext } from "react";
import { getCustomFormat, getTimestamp } from "utils/datetime";

const useCreateInventoryLog = () => {
  const client = useApolloClient();
  const { appContext } = useContext(AppContext);

  const createLog = async (e, productInfo, filterArray) => {
    const changedAmount = e?.inOut
      ? productInfo?.storage?.currentCnt - Number(e?.currentCntOut)
      : Number(productInfo?.storage?.currentCnt) + Number(e?.currentCnt)
    try {
      console.log("createLog.productInfo 1", productInfo)
      client.mutate({
        mutation: CREATE_PRODUCT_LOG,

        variables: {
          input: {
            def: "1",
            major: productInfo?.major,
            minor: productInfo?.minor,
            binCnt: productInfo?.storage?.binCnt,
            productName: productInfo?.productName,
            productID: productInfo?.productID,
            manufacture: productInfo?.manufacture,
            unit: productInfo?.storage?.unit,
            previousAmount: productInfo?.storage?.currentCnt,
            changedAmount: changedAmount,
            changedCnt: e?.inOut ? Number(e?.currentCntOut) : Number(e?.currentCnt),
            detail: e?.detailInformation,
            remarks: e?.remarks,
            retailPrice: e?.retailPrice,
            inOut: e?.inOut ? "inventoryShipping" : "inventoryReceiving",
            expire: Boolean(e?.expireList?.length > 0)
              ? e?.expireList.map(item => {
                return Boolean(
                  productInfo?.storage?.expire
                    ?.map(e =>
                      getCustomFormat(e?.expireDate, "X", "YYYY-MM-DD")
                    )
                    ?.includes(
                      getCustomFormat(item?.expireDate, "X", "YYYY-MM-DD")
                    )
                ) && e?.inOut
                  ? {
                    expireDate: item?.expireDate,
                    expireCount: Number(item?.expireCount)
                  }
                  : {
                    expireDate: Boolean(
                      getCustomFormat(getTimestamp(), "X", "YYYY-MM-DD") ===
                      getCustomFormat(item?.expireDate, "X", "YYYY-MM-DD")
                    )
                      ? item?.expireDate
                      : item?.expireDate?.unix(),
                    expireCount: Number(item?.expireCount)
                  };
              })
              : [],
            staffName: appContext?.staff?.id
          }

        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const createLogIn = async (e, productInfo, filterArray) => {
    const changedAmount = Number(productInfo?.storage?.currentCnt) + Number(e?.currentCnt);
    console.log("createLog.productInfo 2", productInfo)
    try {
      client.mutate({
        mutation: CREATE_PRODUCT_LOG,
        variables: {
          input: {
            def: "1",
            major: productInfo?.major,
            minor: productInfo?.minor,
            binCnt: productInfo?.storage?.binCnt,
            productName: productInfo?.productName,
            productID: productInfo?.productID,
            manufacture: productInfo?.manufacture,
            unit: productInfo?.storage?.unit,
            binCnt: productInfo?.storage?.binCnt,
            previousAmount: productInfo?.storage?.currentCnt,
            changedAmount: changedAmount,
            changedCnt: Number(e?.currentCnt),
            detail: e?.detailInformation_tab0,
            remarks: e?.remarks,
            retailPrice: e?.retailPrice,
            inOut: "inventoryReceiving",
            expire: Boolean(e?.expireList?.length > 0)
              ? e?.expireList.map(item => {
                return Boolean(
                  productInfo?.storage?.expire
                    ?.map(e =>
                      getCustomFormat(e?.expireDate, "X", "YYYY-MM-DD")
                    )
                    ?.includes(
                      getCustomFormat(item?.expireDate, "X", "YYYY-MM-DD")
                    )
                ) && e?.inOut
                  ? {
                    expireDate: item?.expireDate,
                    expireCount: Number(item?.expireCount)
                  }
                  : {
                    expireDate: Boolean(
                      getCustomFormat(getTimestamp(), "X", "YYYY-MM-DD") ===
                      getCustomFormat(item?.expireDate, "X", "YYYY-MM-DD")
                    )
                      ? item?.expireDate
                      : item?.expireDate?.unix(),
                    expireCount: Number(item?.expireCount)
                  };
              })
              : [],
            staffName: appContext?.staff?.id
          }

        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const createLogOut = async (e, productInfo, filterArray) => {
    const changedAmount = Number(productInfo?.storage?.currentCnt) - Number(e?.currentCntOut)

    const newExpireList = e.expireListOut ? e.expireListOut.filter(newExpireItem => {
      return newExpireItem.count;
    }).map(newExpireItem => {
      return {
        ...newExpireItem,
        expireDate: newExpireItem.expireDate && newExpireItem.expireDate?.unix ? newExpireItem.expireDate.unix() : newExpireItem.expireDate,
        expireCount: Number(newExpireItem.count)
      }
    }) : [];
    console.log("createLog.productInfo 3", productInfo)

    try {
      client.mutate({
        mutation: CREATE_PRODUCT_LOG,
        variables: {
          input: {
            def: "1",
            major: productInfo?.major,
            minor: productInfo?.minor,
            productName: productInfo?.productName,
            productID: productInfo?.productID,
            manufacture: productInfo?.manufacture,
            unit: productInfo?.storage?.unit,
            binCnt: productInfo?.storage?.binCnt,
            previousAmount: productInfo?.storage?.currentCnt,
            changedAmount: changedAmount,
            changedCnt: Number(e?.currentCntOut),
            detail: e?.detailInformation_tab1,
            remarks: e?.remarks,
            retailPrice: e?.retailPrice,
            inOut: "inventoryShipping",
            expire: newExpireList || [],
            staffName: appContext?.staff?.id
          }

        }
      });
    } catch (e) {
      console.error(e);
    }
  };
  return { createLog, createLogOut, createLogIn };
};

export default useCreateInventoryLog;
