import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteTable from "components/InfiniteTable";
import moment from "moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { GENDER } from "types/patient";
import { ORALEXAM_TYPES, PATIENT_TYPE, RECEIPT_STATUS } from "types/receipt";
import { makeIndexFormattedMessageList, makeIndexList } from "types/utils";
import { calcBirthDate, getAgeToBirthDay, getSex } from "utils/patient";
import { CommonHeader } from "../../../ListHeader";
import { CommonItem } from "../../../ListItem";

const useStyle = makeStyles(theme => ({
  root: {
    height: props => {
      if (props?.collapse && props?.collapseRef?.current) {
        return `calc(100% - ${props?.collapseRef?.current.offsetHeight}px)`;
      } else {
        return "100%";
      }
    }
  }
}));
const GENDER_LIST = makeIndexList(GENDER);
const STATUS_LIST = makeIndexFormattedMessageList(RECEIPT_STATUS);
const PATIENT_TYPE_LIST = makeIndexFormattedMessageList(PATIENT_TYPE);
const ORALEXAM_TYPE_LIST = makeIndexFormattedMessageList(ORALEXAM_TYPES);

const ResultList = props => {
  const { list, collapseRef, collapse, hasNext, onMoreContents } = props;
  const classes = useStyle({ collapseRef: collapseRef, collapse: collapse });
  const [selected, setSelected] = useState(null);
  const intl = useIntl();
  return (
    <Box className={classes.root}>
      <InfiniteTable
        heads={[
          {
            key: "reservationDate",
            headComponent: () => <CommonHeader label={"예약시각"} />,
            component: ({ cellData }) => (
              <CommonItem
                label={
                  Boolean(cellData)
                    ? moment(cellData, "X").format("YYYY/MM/DD HH:mm")
                    : "-"
                }
              />
            )
          },
          {
            key: "receiptDate",
            headComponent: () => <CommonHeader label={"접수시각"} />,
            component: ({ cellData }) => (
              <CommonItem
                label={moment(cellData, "X").format("YYYY/MM/DD HH:mm")}
              />
            )
          },
          // {
          //   key: "receiptDate",
          //   width: 100,
          //   headComponent: () => <CommonHeader label={"대기"} />,
          //   component: ({ cellData }) => (
          //     <TimerItem origin={moment(cellData, "X")} />
          //   )
          // },
          {
            key: "patient",

            headComponent: () => <CommonHeader label={"환자이름"} />,
            component: ({ cellData }) => (
              <CommonItem label={cellData?.name || ""} />
            )
          },
          {
            key: "patient",

            headComponent: () => <CommonHeader label={"차트번호"} />,
            component: ({ cellData }) => (
              <CommonItem label={cellData?.chartNumber || ""} />
            )
          },
          {
            key: "patient",

            headComponent: () => <CommonHeader label={"연령/성별"} />,
            component: ({ cellData }) => (
              <CommonItem
                label={
                  cellData?.residentRegistrationNumber
                    ? `${getAgeToBirthDay(
                        calcBirthDate(cellData?.residentRegistrationNumber)
                      )}세/${intl.formatMessage({
                        id:
                          GENDER_LIST[
                            getSex(cellData?.residentRegistrationNumber)
                          ]
                      })}`
                    : ""
                }
              />
            )
          },
          {
            key: "type",

            headComponent: () => <CommonHeader label={"접수구분"} />,
            component: ({ cellData }) => (
              <CommonItem
                label={
                  cellData === ORALEXAM_TYPES.NONE.value
                    ? "일반"
                    : ORALEXAM_TYPE_LIST[cellData]
                }
              />
            )
          },
          {
            key: "patient",

            headComponent: () => <CommonHeader label={"환자구분"} />,
            component: ({ cellData }) => (
              <CommonItem
                label={PATIENT_TYPE_LIST[cellData?.newOrExistingPatient]}
              />
            )
          },
          {
            key: "status",

            headComponent: () => <CommonHeader label={"상태"} />,
            component: ({ cellData }) => (
              <CommonItem label={STATUS_LIST[cellData]} />
            )
          },
          {
            key: "doctor",
            width: 100,
            headComponent: () => <CommonHeader label={"담당의사"} />,
            component: ({ cellData }) => (
              <CommonItem label={cellData?.name || ""} />
            )
          }
        ]}
        contents={list || []}
        selected={e => selected === e.id}
        onRowClick={e => {
          setSelected(e.rowData.id);
        }}
        hasNext={Boolean(hasNext)}
        onMoreContents={e => {
          onMoreContents && onMoreContents(e);
        }}
        rowClickHighlight
      />
    </Box>
  );
};

export default ResultList;
