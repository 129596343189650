import React from "react";

const NewIcon = props => {
  const { needBackground, ...others } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...others}
    >
      <g fill="none" fillRule="evenodd">
        {Boolean(needBackground) && (
          <circle cx="12" cy="12" r="10" fill={"#fff"} />
        )}
        <path
          fill="currentColor"
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm-1.6 5H8v9.953h2.4V10.76l3.527 6.193h2.386V7H13.92v6.193L10.4 7z"
        />
        <path d="M0 0H24V24H0z" />
      </g>
    </svg>
  );
};
export default NewIcon;
