import Box from "@material-ui/core/Box";
import { CVButton, CVDialogSimpleDel, DialogCancel } from "components";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogInputError,
  CCDialogSimple
} from "styles/components";
import { CheckIcon } from "styles/icons";
import StaffField from "../StaffField";
import { translate } from "components";
import moment from "moment";
const initField = {
  birthDate: "",
  cellphoneNumber: "",
  telephoneNumber: "",
  staffId: "",
  staffName: "",
  status: "",
  position: "",
  occupation: "",
  authority: 2,
  hiredDate: moment().unix(),
  resignedDate: "",
  salary: "",
  address: ""
};

const DialogStaffAdd = props => {
  const {
    open,
    onClose,
    selectedStaff = null,
    createStaff,
    updateStaff
  } = props;

  const intl = useIntl();

  // value
  const [field, setField] = useState({ ...initField });
  const [errorDialog, setErrorDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const staffFieldError = useRef({});

  // function
  const handleCancelDialog = () => {
    if (isModify) {
      setCancelDialog(true);
    } else {
      onClose();
    }
  };
  const validateForm = () => {
    const hasError = Object.values(staffFieldError.current).includes(true);
    if (hasError) {
      setErrorDialog(true);
      return false;
    }
    return true;
  };
  const verifyChanges = () => {
    if (!selectedStaff) return true;

    for (let key of Object.keys(initField)) {
      if (initField[key] !== selectedStaff[key]) return true;
    }

    return false;
  };
  const handleOnSubmit = () => {
    const isFormValid = validateForm();
    const hasChanged = verifyChanges();

    if (!isFormValid) return;
    if (!hasChanged) return onClose();
    const input = {
      staffName: field.staffName,
      staffId: field.staffId,
      birthDate: Number(field.birthDate),
      cellphoneNumber: field.cellphoneNumber,
      telephoneNumber: field.telephoneNumber,
      status: Number(field.status),
      position: Number(field.position),
      occupation: Number(field.occupation),
      authority: Number(field.authority),
      hiredDate: Number(field.hiredDate),
      resignedDate: Number(field.resignedDate),
      salary: field?.salary ? parseFloat(field.salary.toString().replaceAll(/,/gi, "")) : "",
      address: field.address
    };

    if (!selectedStaff) {
      createStaff({
        variables: {
          input
        }
      });
    } else {
      updateStaff({
        variables: {
          input
        }
      });
    }
    return onClose();
  };

  // useEffect
  useEffect(() => {
    if (selectedStaff) {
      setField(prevState => ({
        ...prevState,
        ...selectedStaff
      }));
    } else {
      setField({ ...initField });
    }
  }, [selectedStaff]);

  return (
    <Box>
      <CCDialogSimple
        open={open}
        title={intl.formatMessage({
          id: "settings.employee.dialogStaffAddTitle"
        })}
        contents={
          <StaffField
            errors={staffFieldError.current}
            field={field}
            setIsModify={setIsModify}
            onHandle={e => {
              setField(e);
            }}
            onHandleError={error => {
              staffFieldError.current = {
                ...staffFieldError.current,
                ...error
              };
            }}
            mode={Boolean(selectedStaff) ? "modify" : "add"}
          />
        }
        contentsPadding={false}
        maxWidth={"md"}
        endActions={
          <>
            <CCButton onClick={handleCancelDialog}>
              {translate(intl, "common.ui.cancel")}
            </CCButton>
            <CVButton
              permission={"employeeInfoGeneralSetting"}
              onClick={handleOnSubmit}
              startIcon={<CheckIcon />}
              variant="contained"
            >
              {translate(intl, "save")}
            </CVButton>
          </>
        }
        onClose={handleCancelDialog}
        onCloseButton={handleCancelDialog}
      />
      {errorDialog && (
        <CCDialogInputError
          open={errorDialog}
          title={intl.formatMessage({
            id: "settings.employee.invalidInput"
          })}
          onClose={() => setErrorDialog(false)}
        />
      )}

      {deleteDialog && (
        <CVDialogSimpleDel
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          onDisagree={() => setDeleteDialog(false)}
          onAgree={() => { }}
        />
      )}

      {cancelDialog && (
        <DialogCancel
          open={cancelDialog}
          onClose={() => setCancelDialog(false)}
          onAgree={onClose}
          onDisagree={() => setCancelDialog(false)}
        />
      )}
    </Box>
  );
};

export default DialogStaffAdd;
