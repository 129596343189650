import { Grid, makeStyles } from "@material-ui/core";
import { CVButton, translate } from "components";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple, CCTextField } from "styles/components";
import { CheckIcon } from "styles/icons";
import { LAB_GOLD_TYPES_MAPPING } from "types/goldManagement";
import { LabGoldField } from "views/Normal/GoldManagement/components";
import { CCFlattenDateField, NumberFormatCustom } from "../../../../../components";

const useStyles = makeStyles(() => ({
  root: {
    padding: 8,
    width: 360
  },
  input__group: {
    display: "flex"
  },
  labgold__field: {
    width: 123
  },
  addamount__field: {
    width: 278
  },
  stock__field: {
    width: 155,
    marginLeft: -8
  },
  add_button: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 8
  }
}));

const AddStockModal = props => {
  const { open, goldStockDetail, onClose, onAdd = () => {} } = props;

  const classes = useStyles();
  const intl = useIntl();
  const today = moment.utc(moment().format("L"), "L").unix();
  const [fields, setFields] = useState({
    date: today,
    type: "",
    amount: "0.00"
  });

  const stock = useMemo(
    () =>
      goldStockDetail[LAB_GOLD_TYPES_MAPPING[fields.type]]
        ? goldStockDetail[LAB_GOLD_TYPES_MAPPING[fields.type]].toFixed(2)
        : "0.00",
    [fields.type, goldStockDetail]
  );

  const handleOnFieldChange = field => e => {
    setFields({ ...fields, [field]: e.target.value });
  };
  const handleOnAdd = () => {
    onAdd(fields.date, fields.type, Number(fields.amount));
    onClose();
  };

  return (
    <CCDialogSimple
      title={translate( intl,"labManageGold.addStock" )}
      open={open}
      onClose={onClose}
      onCloseButton={onClose}
      contentsPadding={false}
      contents={
        <Grid container direction={"column"} className={classes.root}>
          <Grid item>
            {/* <CCDateField
              variant={"outlined"}
              width={123}
              label={translate( intl,"labManageGold.date" )}
              value={fields.date}
              onChange={handleOnFieldChange("date")}
            /> */}
            <CCFlattenDateField
              width={123}
              label={translate(intl, "labManageGold.date")}
              value={moment.unix(fields.date)}
              onChange={e => {
                console.log('e', e)
                setFields({ ...fields, date: moment.utc(e, "L").unix() });
              }}
            />
          </Grid>
          <Grid item className={classes.input__group}>
            <LabGoldField
              label={translate( intl,"common.ui.type" )}
              value={fields.type}
              className={classes.labgold__field}
              onChange={handleOnFieldChange("type")}
              includeAll={false}
            />
            <CCTextField
              variant="outlined"
              label={translate( intl,"labManageGold.stockG" )}
              margin="dense"
              value={stock}
              className={classes.stock__field}
              disabled
            />
          </Grid>
          <Grid item>
            <CCTextField
              variant="outlined"
              label={translate( intl,"labManageGold.addAmountG" )}
              margin="dense"
              value={fields.amount}
              className={classes.addamount__field}
              onChange={handleOnFieldChange("amount")}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item className={classes.add_button}>
            <CVButton
              permission="goldManager"
              color="primary"
              variant="contained"
              startIcon={<CheckIcon />}
              onClick={handleOnAdd}
            >
              {translate( intl,"labManageGold.add" )}
            </CVButton>
          </Grid>
        </Grid>
      }
    />
  );
};

export default AddStockModal;
