import { useCallback } from "react";
import { useIntl } from "react-intl";
import { ORALEXAM_TYPES } from "types/receipt";

const ORALEXAM_UNITS = {
  TOOTH: {
    value: "tooth",
    localeKey: "oralexamUnit.tooth"
  },
  PLANE: {
    value: "plane",
    localeKey: "oralexamUnit.plane"
  },
  DOUBLE: {
    value: "double",
    localeKey: "oralexamUnit.double"
  },
  MULTIPLE: {
    value: "multiple",
    localeKey: "oralexamUnit.multiple"
  },
  MANY: {
    value: "many",
    localeKey: "oralexamUnit.many"
  }
};
const PHRASE_SETTING = {
  DATA_TYPE: {
    LIST: {
      value: "0",
      localeKey: "oralExam.phraseSetting.dataType.list"
    },
    ACCORDING_TO_SCHOOL: {
      value: "1",
      localeKey: "oralExam.phraseSetting.dataType.accordingToSchool"
    }
  },
  SCHOOL: {
    ELEMENTARY: {
      value: "0",
      localeKey: "oralExam.phraseSetting.school.elementary"
    },
    MIDDLE: { value: "1", localeKey: "oralExam.phraseSetting.school.middle" },
    HIGH: { value: "2", localeKey: "oralExam.phraseSetting.school.high" }
  },
  CATEGORY: {
    INFANT: {
      DEFAULT: {
        value: "00",
        localeKey: "oralExam.phraseSetting.category.infant.default"
      },
      ETC: {
        DEFAULT: {
          value: "0000",
          localeKey: "oralExam.phraseSetting.category.infant.etc"
        }
      },
      ADDITIONAL: {
        DEFAULT: {
          value: "0001",
          localeKey: "oralExam.phraseSetting.category.infant.additional"
        }
      },
      ANALYSIS: {
        DEFAULT: {
          value: "0002",
          localeKey: "oralExam.phraseSetting.category.infant.analysis"
        }
      }
    },
    NORMAL_AND_WHOLE_LIFE: {
      DEFAULT: {
        value: "01",
        localeKey: "oralExam.phraseSetting.category.normalAndWholeLife.default"
      },
      ACTIONS: {
        DEFAULT: {
          value: "0100",
          localeKey:
            "oralExam.phraseSetting.category.normalAndWholeLife.actions"
        }
      },
      ACTIVE_MANAGEMENT: {
        DEFAULT: {
          value: "0101",
          localeKey:
            "oralExam.phraseSetting.category.normalAndWholeLife.activeManagement"
        }
      }
    },
    STUDENT_CHECKUP: {
      DEFAULT: {
        value: "02",
        localeKey: "oralExam.phraseSetting.category.studentCheckup.default"
      },
      COMPREHENSIVE_OPINION: {
        DEFAULT: {
          value: "0200",
          localeKey:
            "oralExam.phraseSetting.category.studentCheckup.comprehensiveOpinion"
        }
      },
      HOME_ADVICE: {
        DEFAULT: {
          value: "0201",
          localeKey: "oralExam.phraseSetting.category.studentCheckup.homeAdvice"
        }
      },
      DEVELOPMENT_DIRECTION: {
        DEFAULT: {
          value: "0202",
          localeKey:
            "oralExam.phraseSetting.category.studentCheckup.developmentDirection"
        }
      }
    },
    OUT_OF_SCHOOL: {
      DEFAULT: {
        value: "03",
        localeKey: "oralExam.phraseSetting.category.outOfSchool.default"
      },
      ACTIONS: {
        DEFAULT: {
          value: "0300",
          localeKey: "oralExam.phraseSetting.category.outOfSchool.actions"
        }
      }
    }
  }
};

const ORALEXAM_TOOTH_TYPE = {
  CARIES: {
    value: "caries",
    localeKey: "oralexamUnit.caries"
  },
  CARIESDOUBT: {
    value: "cariesDoubt",
    localeKey: "oralexamUnit.cariesDoubt"
  },
  REHABILITATE: {
    value: "rehabilitate",
    localeKey: "oralexamUnit.rehabilitate"
  },
  SEALANT: {
    value: "sealant",
    localeKey: "oralexamUnit.sealant"
  },
  NORMAL: {
    value: "normal",
    localeKey: "oralexamUnit.normal"
  }
};

const ORALEXAM_STATE = {
  ORAL_EXAMINATION_UNENTERED: {
    value: "1",
    localeKey: "oralExamState.oralExaminationUnentered"
  },
  ORAL_RESULTS_UNENTERED: {
    value: "2",
    localeKey: "oralExamState.oralResultsUnentered"
  },
  COMPLETED_SCREENING: {
    value: "3",
    localeKey: "oralExamState.completedScreening"
  }
};

const ONLY_ORALEXAM_TYPES = {
  ...ORALEXAM_TYPES,
  STUDENT: {
    value: "7",
    localeKey: "oralexamType.student"
  }
};

const EXAM_TYPES = {
  GENERAL: {
    value: "general",
    localeKey: "oralexamType.general"
  },
  STUDENT: {
    value: "student",
    localeKey: "oralexamType.student"
  }
};

const ORALEXAM_FILE = {
  NON_GENERATE: {
    value: "0",
    localeKey: "oralexamFile.nonGenerate"
  },
  GENERATE: {
    value: "1",
    localeKey: "oralexamFile.generate"
  }
};

function getLocaleKey(target, category) {
  for (let i in target) {
    if (target[i].DEFAULT.value === category) {
      return target[i]?.DEFAULT?.localeKey;
    } else {
      let _temp = { ...target[i] };
      delete _temp.DEFAULT;
      if (Object.keys(_temp).length > 0) {
        let _res = getLocaleKey(_temp, category);
        if (_res) {
          return _res;
        }
      }
    }
  }
}
function getData(target, category) {
  for (let i in target) {
    if (target[i].category === category) {
      return { ...target[i] };
    } else if (
      target[i].hasOwnProperty("items") &&
      category.includes(target[i].category)
    ) {
      let _res = getData([...target[i].items], category);
      if (_res) {
        return _res;
      }
    }
  }
}

const useSearchLocaleKey = props => {
  return getLocaleKey;
};

const useLocaleTree = props => {
  const itnl = useIntl();
  const getLocaleKey = useSearchLocaleKey();
  const getLocale = useCallback(
    items => {
      let _res = [...items];
      for (let i in _res) {
        if (Boolean(_res[i]?.category)) {
          _res[i].name = itnl.formatMessage({
            id: getLocaleKey(PHRASE_SETTING.CATEGORY, _res[i]?.category)
          });
        }
        if (Boolean(_res[i]?.items)) {
          _res[i].items = [...getLocale(_res[i]?.items)];
        }
      }
      return _res;
    },
    [getLocaleKey, itnl]
  );
  return getLocale;
};

// console.log(
//   PHRASE_SETTING.getLocaleKey(
//     PHRASE_SETTING.CATEGORY,
//     PHRASE_SETTING.CATEGORY.STUDENT_CHECKUP.COMPREHENSIVE_OPINION.DEFAULT.value
//   )
// );
const Oralexam = {
  ORALEXAM_UNITS: ORALEXAM_UNITS,
  PHRASE_SETTING: PHRASE_SETTING,
  useLocaleTree: useLocaleTree,
  useSearchLocaleKey: useSearchLocaleKey
};

export {
  ORALEXAM_UNITS,
  PHRASE_SETTING,
  ORALEXAM_TOOTH_TYPE,
  ORALEXAM_STATE,
  ONLY_ORALEXAM_TYPES,
  EXAM_TYPES,
  ORALEXAM_FILE,
  useLocaleTree,
  useSearchLocaleKey,
  getLocaleKey,
  getData
};
export default Oralexam;
