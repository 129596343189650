import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVButton, CVDialogSimple } from "components";
import React from "react";
import { CCButton, CCTypography } from "styles/src/components";
import { WarningIcon } from "styles/src/themes/common/icons";
import { useIntl } from "utils/language";


const useStyles = makeStyles(theme => ({
  root: { width: "100%" },
  titleText: {
    color: props => {
      switch (props?.titleColor) {
        case "primary":
          return theme.palette.primary.main;
        case "secondary":
          return theme.palette.secondary.main;
        case "error":
          return theme.palette.error.main;
        case "sub":
          return theme.palette.sub.main;
        default:
          return theme.palette.error.main;
      }
    }
  },
  subtitleText: {
    color: props => {
      switch (props?.subtitleColor) {
        case "primary":
          return theme.palette.primary.main;
        case "secondary":
          return theme.palette.secondary.main;
        case "error":
          return theme.palette.error.main;
        case "sub":
          return theme.palette.sub.main;
        default:
          return theme.palette.sub.main;
      }
    }
  },
  text: { marginTop: 8, width: "100%" },
  disagree: { marginRight: 8 },
  icon: {
    color: props => {
      switch (props?.iconColor) {
        case "primary":
          return theme.palette.primary.main;
        case "secondary":
          return theme.palette.secondary.main;
        case "error":
          return theme.palette.error.main;
        case "sub":
          return theme.palette.sub.main;
        default:
          return theme.palette.error.main;
      }
    },
    marginRight: 8
  }
}));

const DialogWarningMessage = props => {
  const intl = useIntl();
  const {
    permission,
    open,
    onClose,
    title = intl.formatMessage({ id: "inputError" }),
    titleColor,
    subTitle,
    subTitleColor,
    onAgree,
    agreeButtonColor = "error",
    agreeButtonLabel = intl.formatMessage({ id: "common.ui.confirm" }),
    agreeButtonVariant = "contained",
    onSecondAction,
    secondaryActionColor = "secondary",
    secondaryActionLabel = intl.formatMessage({ id: "common.ui.confirm" }),
    onDisagree,
    disagreeButtonLabel = intl.formatMessage({ id: "cancel" }),
    disagreeButtonColor = "secondary",
    noCancel,
    noIcon = false,
    contents,
    iconColor,
    maxWidth = "sm",
    onRefresh,
    disableTypography = false,
    refreshButtonLabel = intl.formatMessage({ id: "refresh" }),
    ...others
  } = props;

  const classes = useStyles({
    iconColor: iconColor,
    titleColor: titleColor,
    subTitleColor: subTitleColor,
    agreeButtonColor: agreeButtonColor,
    secondaryActionColor: secondaryActionColor,
    disagreeButtonColor: disagreeButtonColor
  });

  return (
    <CVDialogSimple
      PaperProps={{ style: { minWidth: 280 } }}
      maxWidth={maxWidth}
      open={open}
      permission={permission}
      isHeader={false}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <Box>
          {!Boolean(noIcon) && <WarningIcon className={classes.icon} />}
          {Boolean(disableTypography) && <Box>{contents}</Box>}
          {!Boolean(disableTypography) && (
            <Box className={classes.text}>
              <CCTypography className={classes.titleText}>{title}</CCTypography>
              {subTitle && (
                <CCTypography className={classes.subtitleText}>
                  {subTitle}
                </CCTypography>
              )}
            </Box>
          )}
        </Box>
      }
      endActions={
        <>
          {onRefresh && (
            <CCButton
              className={classes.disagree}
              color="secondary"
              variant={"text"}
              onClick={() => {
                onRefresh && onRefresh();
              }}
            >
              {refreshButtonLabel}
            </CCButton>
          )}
          {onSecondAction && (
            <CVButton
              // permission={permission}
              className={classes.disagree}
              color={secondaryActionColor}
              variant={"text"}
              onClick={() => {
                onSecondAction && onSecondAction();
              }}
            >
              {secondaryActionLabel}
            </CVButton>
          )}
          {onDisagree && (
            <CCButton
              className={classes.disagree}
              color={disagreeButtonColor}
              variant={"text"}
              onClick={() => {
                onDisagree && onDisagree();
              }}
            >
              {disagreeButtonLabel}
            </CCButton>
          )}
          {onAgree && (
            <CVButton
              // permission={permission}
              className={classes.disagree}
              variant={agreeButtonVariant}
              color={agreeButtonColor}
              onClick={() => {
                onAgree && onAgree();
              }}
            >
              {agreeButtonLabel}
            </CVButton>
          )}
        </>
      }
      {...others}
    />
  );
};
export default DialogWarningMessage;
