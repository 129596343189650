import { makeStyles } from "@material-ui/styles";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from "react";
import { CCTypography } from "styles/components";
import { BringInIcon } from "styles/icons";
import { useIntl } from "utils/language";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useProductValidation from "validations/useProductValidation";
import { getTimestamp } from "utils/datetime";
import { DBStorageContext } from "context/DBContext/components";
import { Box, FormControlLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DialogWarningMessage, enqueueToast } from "components";
import { useSnackbar } from "notistack";
import {
  CCButton,
  CCDialog,
  CCDialogAlert,
  CCDialogContent,
  CCDialogTitle,
  CCIconButton
} from "styles/src/components";
import { TabContext } from "views/Normal/InventoryManagement/Component/Stock/Stock";
import {
  useCreateProduct,
  useSearchProduct
} from "views/Normal/InventoryManagement/Component/Stock/Component/ProductTab/hooks";
import { CVTextField, ErrorTooltip, RadioGroup } from "components";
import { useApolloClient } from "@apollo/client";

import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import { makeList } from "utils/type";
import { PRODUCT_CATEGORY } from "types/inventory";
import produce from "immer";
import { useQuery } from "@apollo/client";
import axios from "axios";
import { SEARCH_PRODUCT_PAGINATION } from "queries/product";
import { ProductResults } from "../../../../../SearchProductTab/Component";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 920,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  },
  head__field: {
    width: 300,
    display: "flex",
    flexWrap: "wrap"
    // gap: 5,
  },
  head__an__field: {
    width: 120
  },
  head__an__search__field: {
    width: 230
  },
  button__filter: {
    marginTop: "8px"
  },
  content__top__border: {
    height: "100%",
    width: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },

  content__bottom__border: {
    height: "100%",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  }
}));

const initData = {
  hospital: "",
  important: false,
  unit: 1,
  currentCnt: 0,
  optCnt: 0,
  alert: false,
  editDate: getTimestamp(),
  editorID: "",
  canExpire: true,
  expire: {
    expireCount: 0,
    expireDate: getTimestamp()
  },
  binCnt: 0,
  barcode: false,
  isAppropriate: false
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case "getMinor":
      draft.minor = Object.values(action.target);
      return draft;
    case "getMajor":
      draft.major = Object.values(action.target);
      return draft;
    default:
      break;
  }
});

const DetailDialog = props => {
  const intl = useIntl();
  const client = useApolloClient();

  const { open, onClose, product = [] } = props;

  const classes = useStyles();
  const selectRef = useRef();

  const _url = process.env.REACT_APP_PAM_URL;
  const [alertOpen, setAlertOpen] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [alrDialog, setAlrDialog] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [supplierResult, setSupplierResult] = useState([]);
  const [option, setOption] = useState("1");

  const [state, dispatchState] = useReducer(reducer, { minor: [], major: [] });

  const { data } = useQuery(SEARCH_PRODUCT_PAGINATION, {
    variables: {
      def: "1",
      comparison: "or",
      match: "contains"
    },
    onCompleted: () => {
      try {
        // setError(null);
        // setLoading(true);
        axios({
          method: "POST",
          url: `${_url}/api/v1/material_item/list`,
          data: {
            materialId: sessionStorage.getItem("clinicId")
          },
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
          }
        })
          .then(response => {
            setSupplierResult(
              response?.data?.rows?.map(e => {
                return {
                  ...e,
                  major: e?.category1,
                  minor: e?.category2,
                  productName: e?.name,
                  manufacture: e?.manufacturer
                };
              })
            );
          })

          .catch(err => { });
      } catch (e) {
        // setError(e);
      }
    }
  });

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const ALL_SELECTION = intl.formatMessage({ id: "all" });
  const productCategory = makeList(PRODUCT_CATEGORY);

  const { searchProduct } = useSearchProduct();
  const { createProduct } = useCreateProduct();

  const { setProduct } = useContext(TabContext);

  const { enqueueSnackbar } = useSnackbar();
  const { dispatchDBStorage } = useContext(DBStorageContext);

  const { schema } = useProductValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const { getValues, watch, control, errors } = methods;

  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);

  const onSave = useCallback(
    async e => {
      const selected = selectRef?.current;

      const _product = await searchProduct(e, selected);

      if (_product) {
        setAlrDialog({
          title: intl.formatMessage(
            { id: "alrExistingproduct" },
            { br: () => <br /> }
          ),
          onAgree: () => {
            setAlrDialog(null);
          }
        });
      } else {
        await createProduct(e).then(() => {
          enqueueToast(
            enqueueSnackbar,
            ` ${intl.formatMessage(
              {
                id: "updateLogDefault"
              },
              {
                name: intl.formatMessage({ id: "inventoryManagement" })
              }
            )}`
          );
        });
        onClose();

        setProduct({
          major: Boolean(e?.selectedList) ? e?.selectedList?.major : e?.major,
          productName: Boolean(e?.selectedList)
            ? e?.selectedList?.productName
            : e?.productName
        });

        dispatchDBStorage({ type: "init" });
      }
    },
    [
      dispatchDBStorage,
      enqueueSnackbar,
      intl,
      onClose,
      setProduct,
      createProduct,
      searchProduct
    ]
  );

  const onCreate = () => {
    methods.handleSubmit(onSave, errors => {
      console.error(errors);
    })();
  };

  const onClosure = () => {
    if (Object.keys(methods.formState.dirtyFields)?.length > 0 || selectRef) {
      setCancelDialog({
        title: intl.formatMessage(
          { id: "confirmCancelwritingInfo" },
          { br: () => <br /> }
        ),
        onClose: () => {
          setCancelDialog(null);
        },
        onDisagree: () => {
          setCancelDialog(null);
        },
        onAgree: () => {
          onClose && onClose();
        }
      });
    } else {
      onClose && onClose();
    }
  };

  useEffect(() => {
    if (option === "1") {
      setSearchResult(
        data?.searchProductPagination?.data.sort(a => {
          if (a?.storage?.important) {
            return -1;
          } else {
            return 1;
          }
        })
      );
    }

    if (option === "0") {
      dispatchState({
        type: "getMajor",
        target: {
          ...supplierResult?.map(e => e?.category1).filter(onlyUnique)
        }
      });
    } else if (option === "1" && data?.searchProductPagination?.data) {
      dispatchState({
        type: "getMajor",
        target: {
          ...data.searchProductPagination?.data
            ?.map(item => item.major)
            .filter(onlyUnique)
        }
      });
    }
  }, [data, searchResult, option, supplierResult]);

  return (
    <>
      <FormProvider {...methods}>
        <CCDialog open={open} className={classes.root} onClose={onClose}>
          <CCDialogTitle onCloseButton={onClosure}>
            <Grid item>
              <Box display="flex" flexDirection="row">
                <CCTypography variant="h3">
                  {/*{intl.formatMessage({ id: "registerProduct" })}*/}
                  품목 검색/추가
                </CCTypography>
              </Box>
            </Grid>
          </CCDialogTitle>
          <CCDialogContent
            noPadding={true}
            style={{ overflow: "hidden", marginBottom: -50 }}
          >
            <Box>
              <CVTextField
                className={classes.head__field}
                variant={"outlined"}
                margin={"dense"}
                name={"searchField"}
                defaultValue={product?.name || ""}
                value={product?.name || ""}
                disabled={true}
                readOnly={true}
                label={intl.formatMessage({ id: "partner" })}
              />
            </Box>
            <Box className={classes.content__top__border}>
              <Controller
                name={"category"}
                control={control}
                defaultValue={getValues("category") || "0"}
                render={props => {
                  const { ref, value, onChange, onBlur } = props;

                  return (
                    <FormControlLabel
                      control={
                        <RadioGroup
                          tabList={productCategory}
                          value={value}
                          required={true}
                          onChange={e => {
                            onChange(e.value);
                            setOption(e.value);
                          }}
                          inputRef={ref}
                          onBlur={onBlur}
                          defaultValue={value}
                        />
                      }
                    />
                  );
                }}
              />
            </Box>

            <Box>
              {/*<Box className={clsx(classes.root, className)}>*/}
              <Controller
                name={"major"}
                control={control}
                defaultValue={getValues("major") || undefined}
                render={props => {
                  const { ref, value, onChange, onBlur } = props;
                  return (
                    <ErrorTooltip
                      open={Boolean(errors?.major?.message)}
                      errorMsg={errors?.major?.message}
                      placement={"top-start"}
                    >
                      <CVTextField
                        inputRef={ref}
                        control={control}
                        name={"major"}
                        // required={watch("directInput")}
                        className={classes.head__an__field}
                        variant={"outlined"}
                        margin={"dense"}
                        label={intl.formatMessage({ id: "majorCategory" })}
                        select
                        value={value || ALL_SELECTION}
                        disabled={state.major === []}
                        onChange={e => {
                          onChange(e?.target?.value);
                        }}
                        onBlur={onBlur}
                      >
                        <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                          {ALL_SELECTION}
                        </MenuItem>
                        {Boolean(state?.major) &&
                          state.major.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                      </CVTextField>
                    </ErrorTooltip>
                  );
                }}
              />

              <Controller
                name={"minor"}
                control={control}
                defaultValue={getValues("minor") || null}
                render={props => {
                  const { ref, value, onChange, onBlur } = props;
                  return (
                    <CVTextField
                      inputRef={ref}
                      control={control}
                      name={"minor"}
                      className={classes.head__an__field}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({ id: "minorCategory" })}
                      select
                      value={value || ALL_SELECTION}
                      onChange={e => {
                        onChange(e?.target?.value);
                      }}
                      disabled={
                        state.minor === [] ||
                        watch("major") === null ||
                        watch("major") === "All"
                      }
                      onBlur={onBlur}
                    >
                      <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                        {ALL_SELECTION}
                      </MenuItem>
                    </CVTextField>
                  );
                }}
              />

              <Controller
                name={"productName"}
                control={control}
                defaultValue={getValues("productName") || undefined}
                render={props => {
                  const { ref, value, onChange, onBlur } = props;
                  return (
                    <ErrorTooltip
                      open={Boolean(errors?.productName?.message)}
                      errorMsg={errors?.productName?.message}
                      placement={"top-start"}
                    >
                      <CVTextField
                        inputRef={ref}
                        control={control}
                        name={"productName"}
                        required={watch("directInput")}
                        className={classes.head__an__search__field}
                        permission={false}
                        variant={"outlined"}
                        margin={"dense"}
                        label={intl.formatMessage({ id: "productName" })}
                        value={value}
                        onChange={e => onChange(e?.target?.value)}
                        onKeyPress={e =>
                          e.key === "Enter" ? onChange(e?.target?.value) : null
                        }
                        onBlur={onBlur}
                      />
                    </ErrorTooltip>
                  );
                }}
              />

              <Controller
                name={"manufacture"}
                control={control}
                defaultValue={getValues("manufacture") || undefined}
                render={props => {
                  const { ref, value, onChange, onBlur } = props;
                  return (
                    <CVTextField
                      inputRef={ref}
                      control={control}
                      name={"manufacture"}
                      className={classes.head__an__search__field}
                      permission={false}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({ id: "manufacturer" })}
                      value={value}
                      onChange={e => onChange(e?.target?.value)}
                      onKeyPress={e =>
                        e.key === "Enter"
                          ? e => onChange(e?.target?.value)
                          : null
                      }
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <CCButton
                className={classes.button__filter}
                variant="contained"
                color="secondary"
                startIcon={<SearchIcon />}
                disabled={watch("directInput")}
                onClick={() => { }}
              >
                {intl.formatMessage({ id: "filter" })}
              </CCButton>
            </Box>

            <Box>
              <ProductResults
                result={option === "1" ? searchResult : supplierResult}
                option={option}
              />
              {/*<RequestTable />*/}
            </Box>
          </CCDialogContent>

          <Box
            style={{
              overflowY: "auto",
              padding: 10,
              display: "flex",
              justifyContent: "flex-end"
            }}
            marginLeft={"auto"}
            className={classes.icon__end}
          >
            <>
              <CCButton
                className={classes.cancel__icon}
                variant={"text"}
                color={"normal"}
                onClick={onClosure}
              >
                {intl.formatMessage({ id: "cancel" })}
              </CCButton>
              <CCButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  onCreate();
                }}
              >
                {intl.formatMessage({ id: "save" })}
              </CCButton>
              <CCDialogAlert
                contents={
                  <Box className={classes.root}>
                    <CCTypography>
                      {intl.formatMessage({ id: "registerProduct" })}
                      <CCIconButton color={"secondary"} variant={"contained"}>
                        <BringInIcon />
                      </CCIconButton>
                      {intl.formatMessage({ id: "clickBtn" })}
                    </CCTypography>
                  </Box>
                }
                open={alertOpen}
                onClose={setCloseAlert}
                onAgree={setCloseAlert}
                onDisagree={setCloseAlert}
              />
              <CCDialogAlert
                contents={
                  <CCTypography>
                    {intl.formatMessage({ id: "registerProduct" })}
                    <CCIconButton color={"secondary"} variant={"contained"}>
                      <BringInIcon />
                    </CCIconButton>
                    {intl.formatMessage({ id: "registerInputContent" })}
                  </CCTypography>
                }
                open={saveAlertOpen}
                onClose={setCloseSaveAlert}
                onAgree={setCloseSaveAlert}
                onDisagree={setCloseSaveAlert}
              />
            </>
          </Box>
        </CCDialog>
      </FormProvider>
      <DialogWarningMessage open={Boolean(cancelDialog)} {...cancelDialog} />
      <DialogWarningMessage open={Boolean(alrDialog)} {...alrDialog} />
    </>
  );
};

export default DetailDialog;
