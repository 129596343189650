/*eslint react-hooks/exhaustive-deps: 0*/
import { useApolloClient } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { CVButton, CVTextField, DialogWarningMessage, enqueueToast, ErrorTooltip, translate } from "components";
import { DBStorageContext } from "context/DBContext/components";
import { useSnackbar } from "notistack";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CCTypography } from "styles/components";
import { BringInIcon } from "styles/icons";
import {
  CCButton,
  CCDialog,
  CCDialogAlert,
  CCDialogContent,
  CCDialogTitle,
  CCIconButton
} from "styles/src/components";
import { ClipIcon } from "styles/src/themes/common/icons";
import { getTimestamp } from "utils/datetime";
import { useIntl } from "utils/language";
import useProductValidation from "validations/useProductValidation";
import {
  useCreateProduct,
  useSearchProduct
} from "views/Normal/InventoryManagement/Component/Stock/Component/ProductTab/hooks";
import { TabContext } from "views/Normal/InventoryManagement/Component/Stock/Stock";
import RequestTable from "./components/RequestTable";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 900,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  },
  head__field: {
    width: 300,
    display: "flex",
    flexWrap: "wrap",
    // gap: 5,
    marginTop: -10
  },
  head__search__field: {
    width: 860
  },
  background: {
    background: theme.palette.background.default
  },
  image__button: {
    marginLeft: 10
  },
  typo: {
    marginLeft: 15,
    color: theme.palette.disabled.rgba
  }
}));

const initData = {
  hospital: "",
  important: false,
  unit: 1,
  currentCnt: 0,
  optCnt: 0,
  alert: false,
  editDate: getTimestamp(),
  editorID: "",
  canExpire: true,
  expire: {
    expireCount: 0,
    expireDate: getTimestamp()
  },
  binCnt: 0,
  barcode: false,
  isAppropriate: false
};

const ProductAddDialog = props => {
  const intl = useIntl();
  const client = useApolloClient();

  const { open, onClose } = props;

  const classes = useStyles();
  const selectRef = useRef();

  const [alertOpen, setAlertOpen] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [alrDialog, setAlrDialog] = useState(false);

  const { searchProduct } = useSearchProduct();
  const { createProduct } = useCreateProduct();

  const { setProduct } = useContext(TabContext);

  const { enqueueSnackbar } = useSnackbar();
  const { dispatchDBStorage } = useContext(DBStorageContext);

  const _url = process.env.REACT_APP_PAM_URL;
  const [vendor, setVendor] = useState([]);
  const [selected, setSelected] = useState(false);
  const [item, setItem] = useState([]);

  const { schema } = useProductValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const { control, errors, getValues } = methods;

  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);

  const onSave = useCallback(
    async e => {
      const selected = selectRef?.current;

      const _product = await searchProduct(e, selected);

      if (_product) {
        setAlrDialog({
          title: intl.formatMessage(
            { id: "alrExistingproduct" },
            { br: () => <br /> }
          ),
          onAgree: () => {
            setAlrDialog(null);
          }
        });
      } else {
        await createProduct(e).then(() => {
          enqueueToast(
            enqueueSnackbar, translate(intl, "common.ui.itemHasBeenRegistered")
          );
        });
        onClose();

        setProduct({
          major: Boolean(e?.selectedList) ? e?.selectedList?.major : e?.major,
          productName: Boolean(e?.selectedList)
            ? e?.selectedList?.productName
            : e?.productName
        });

        dispatchDBStorage({ type: "init" });
      }
    },
    [
      dispatchDBStorage,
      enqueueSnackbar,
      intl,
      onClose,
      setProduct,
      createProduct,
      searchProduct
    ]
  );

  const onCreate = () => {
    methods.handleSubmit(onSave, errors => {
      console.error(errors);
    })();
  };

  const onClosure = () => {
    if (Object.keys(methods.formState.dirtyFields)?.length > 0 || selectRef) {
      setCancelDialog({
        title: intl.formatMessage(
          { id: "confirmCancelwritingInfo" },
          { br: () => <br /> }
        ),
        onClose: () => {
          setCancelDialog(null);
        },
        onDisagree: () => {
          setCancelDialog(null);
        },
        onAgree: () => {
          onClose && onClose();
        }
      });
    } else {
      onClose && onClose();
    }
  };

  const fetchVendor = async () => {
    try {
      // setError(null);
      // setLoading(true);
      axios({
        method: "GET",
        url: `${_url}/api/v1/clinic/${sessionStorage.getItem(
          "clinicId"
        )}/material`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(async response => {
          setVendor(response?.data);
        })

        .catch(err => { });
    } catch (e) {
      // setError(e);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchVendor();
  }, []);

  useEffect(() => {
    for (const v of Object.keys(vendor)) {
      let _recom = vendor[v];
      if (selected === _recom?.name) {
        setItem(_recom);
      }
    }
  }, [selected, vendor]);

  return (
    <>
      <FormProvider {...methods}>
        <CCDialog open={open} className={classes.root} onClose={onClose}>
          <CCDialogTitle onCloseButton={onClosure}>
            <Grid item>
              <Box display="flex" flexDirection="row">
                <CCTypography variant="h3">
                  {intl.formatMessage({ id: "estimateRequest" })}
                </CCTypography>
              </Box>
            </Grid>
          </CCDialogTitle>
          <CCDialogContent
            className={classes.background}
            noPadding={false}
            style={{ overflow: "hidden" }}
          >
            <Box>
              <Controller
                name={"partner"}
                control={control}
                defaultValue={getValues("partner") || undefined}
                render={props => {
                  const { ref, value, onChange, onBlur } = props;

                  return (
                    <ErrorTooltip
                      open={Boolean(errors?.major?.message)}
                      errorMsg={errors?.major?.message}
                      placement={"top-start"}
                    >
                      <CVTextField
                        inputRef={ref}
                        control={control}
                        name={"major"}
                        required={true}
                        className={classes.head__field}
                        variant={"outlined"}
                        margin={"dense"}
                        label={intl.formatMessage({ id: "partner" })}
                        select
                        value={value}
                        disabled={vendor?.length === 0}
                        onChange={e => {
                          onChange(e?.target?.value);
                          setSelected(e?.target?.value);
                        }}
                        onBlur={onBlur}
                      >
                        {/*<MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>*/}
                        {/*  {ALL_SELECTION}*/}
                        {/*</MenuItem>*/}
                        {Boolean(vendor?.length > 0) &&
                          vendor?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item?.name}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                      </CVTextField>
                    </ErrorTooltip>
                  );
                }}
              />
            </Box>
            <Box style={{ marginLeft: 7 }}>
              <RequestTable item={item} />
            </Box>
            <Box>
              <CVTextField
                className={classes.head__search__field}
                permission={false}
                variant={"outlined"}
                margin={"dense"}
                label={intl.formatMessage({ id: "requestList" })}
                placeholder={intl.formatMessage(
                  { id: "enterRequestDescription" },
                  { br: () => <br /> }
                )}
                value={""}
                rows="4"
                multiline={true}
              />
            </Box>
            <Box>
              <CVButton
                color="normal"
                startIcon={<ClipIcon />}
                variant={"contained"}
                className={classes.image__button}
                onClick={() => {
                  // setRequestDialog(true);
                }}
              >
                {intl.formatMessage({ id: "attachImage" })}
              </CVButton>
              <CCTypography variant={"body"} className={classes.typo}>
                {intl.formatMessage({ id: "attachableFileType" })}
              </CCTypography>
            </Box>
          </CCDialogContent>

          <Box
            style={{
              overflowY: "auto",
              padding: 10,
              display: "flex",
              justifyContent: "flex-end"
            }}
            marginLeft={"auto"}
            className={classes.icon__end}
          >
            <>
              <CCButton
                className={classes.cancel__icon}
                variant={"text"}
                color={"normal"}
                onClick={onClosure}
              >
                {intl.formatMessage({ id: "cancel" })}
              </CCButton>
              <CCButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  onCreate();
                }}
              >
                {intl.formatMessage({ id: "save" })}
              </CCButton>
              <CCDialogAlert
                contents={
                  <Box className={classes.root}>
                    <CCTypography>
                      {intl.formatMessage({ id: "registerProduct" })}
                      <CCIconButton color={"secondary"} variant={"contained"}>
                        <BringInIcon />
                      </CCIconButton>
                      {intl.formatMessage({ id: "clickBtn" })}
                    </CCTypography>
                  </Box>
                }
                open={alertOpen}
                onClose={setCloseAlert}
                onAgree={setCloseAlert}
                onDisagree={setCloseAlert}
              />
              <CCDialogAlert
                contents={
                  <CCTypography>
                    {intl.formatMessage({ id: "registerProduct" })}
                    <CCIconButton color={"secondary"} variant={"contained"}>
                      <BringInIcon />
                    </CCIconButton>
                    {intl.formatMessage({ id: "registerInputContent" })}
                  </CCTypography>
                }
                open={saveAlertOpen}
                onClose={setCloseSaveAlert}
                onAgree={setCloseSaveAlert}
                onDisagree={setCloseSaveAlert}
              />
            </>
          </Box>
        </CCDialog>
      </FormProvider>
      <DialogWarningMessage open={Boolean(cancelDialog)} {...cancelDialog} />
      <DialogWarningMessage open={Boolean(alrDialog)} {...alrDialog} />
    </>
  );
};

export default ProductAddDialog;
