import React from "react";

const SettlementIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M18 12c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm.705 1H17.37v.965c-.86.185-1.555.745-1.555 1.605 0 1.025.85 1.535 2.09 1.835 1.115.265 1.335.655 1.335 1.07 0 .305-.22.795-1.2.795-.915 0-1.275-.41-1.325-.935h-.98c.055.97.78 1.52 1.635 1.7V21h1.335v-.955c.87-.165 1.555-.665 1.56-1.58 0-1.26-1.08-1.695-2.09-1.955-1.01-.26-1.335-.535-1.335-.955 0-.48.45-.82 1.2-.82.79 0 1.085.38 1.11.935h.98c-.03-.765-.495-1.47-1.425-1.695V13zM17 2c1.105 0 2 .895 2 2v6.577c-.325-.05-.66-.077-1-.077-3.59 0-6.5 2.91-6.5 6.5 0 .698.11 1.37.314 2H4c-1.105 0-2-.895-2-2V4c0-1.105.895-2 2-2h13zm-2.209 4l-6.505 6.556-3.077-3.092L4 10.68 8.286 15 16 7.226 14.791 6z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default SettlementIcon;
