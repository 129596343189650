import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Grid, makeStyles } from "@material-ui/core";
import axios from "axios";
import { CVButton } from "components";
// import ImageViewer from "components/FileViewer/Component/ImageViewer/ImageViewer";
import ImageViewer from  "components/Explorer/Component/FilePhoto/component/FileViewer/Component/ImageViewer/ImageViewer.js";
import { Explorer } from "components";
import PropTypes from "prop-types";
import { CREATE_PHOTO, GET_PHOTO } from "queries/photo";
import React, { useEffect, useRef, useState } from "react";
import Files from "react-butterfiles";
import { CCDialogSimpleDel } from "styles/components";
import {
  CCButton,
  CCMenu,
  CCTooltip,
  CCTypography
} from "styles/src/components";
import CCDialogSimple from "styles/src/components/CCDialogSimple";
import CCIconButton from "styles/src/components/CCIconButton";
import { ClipIcon, WarningIcon } from "styles/src/themes/common/icons";
import { getTimestampFromDate } from "utils/datetime";
import { getImageInfo } from "utils/image";
import { useIntl } from "utils/language";
import { getImage } from "utils/photo";
import { getObject, putObject, putObjectBlob, Target } from "utils/s3";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles(theme => ({
  root: {},
  error: {
    color: theme.palette.error.main
  },
  btn: {
    width: 140,
    padding: "6px"
  },
  filename: {
    display: "inline-flex",
    minWidth: 0
  },
  filename__base: {
    maxWidth: 400,
    textOverflow: "ellipsis",
    whiteSpace: "noWrap",
    overflow: "hidden"
  }
}));

const ImageUploadButton = props => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    value, // 따로 저장 하는 값 photoId
    buttonIcon,
    onChange, // photo onChange
    onlyDirect, // true 일 경우 클라우드 없이 바로 올리기
    nonAttachPhoto, // true 인경우 클라우드 사진 선택시 그대로 onChange
    title = intl.formatMessage({ id: "addImage" }),
    target,
    disablePreview,
    tab,
    patient,
    category,
    folder,
    startIcon,
    accept,
    allowExtension,
    filetype,
    color = "normal",
    maxSize,
    handleLoading,
    handleProgress,
    clearPhoto = false
  } = props;

  const fileUploadButton = useRef();
  const fileUploadIconButton = useRef();
  const [open, setOpen] = useState("");
  const [openSizeCheck, setOpenSizeCheck] = useState(false);
  const [explorerOpen, setExplorerOpen] = useState(false);
  const hospitalId = localStorage.getItem("hospitalId");
  const [openLogoMenu, setOpenLogoMenu] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [openImageView, setOpenImageView] = useState(false);
  const [del, setDel] = useState(false);
  const cancelTokenSource = useRef();

  const [createPhoto] = useMutation(CREATE_PHOTO, {
    onCompleted: data => {
      if (data.createPhoto) {
        getPhoto({
          variables: { hospitalId: hospitalId, id: data.createPhoto }
        });
      }
    }
  });

  const fetchObjectUrl = async photo => {
    if (photo.content.object) {
      const url = await getImage(photo);
      photo.content.source = url;
    }
    return photo;
  };

  const [getPhoto] = useLazyQuery(GET_PHOTO, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data.getPhoto) {
        onChange(data.getPhoto);
        setPhoto(data.getPhoto);
      }
    }
  });

  const handleBlobFileChange = async file => {
    const ext = file.name.slice(file.name.lastIndexOf(".") + 1).toUpperCase();
    if (allowExtension.includes(ext)) {
      if (file) {
        handleLoading(true);
        const fileUUID = uuid();
        cancelTokenSource.current = axios.CancelToken.source();

        await putObject(
          target,
          hospitalId,
          fileUUID,
          file,
          handleProgress,
          cancelTokenSource.current?.token
        );
        cancelTokenSource.current = null;
        const url = await getObject(target, hospitalId, fileUUID);
        url && handleSavePhoto(url, file, fileUUID, ext);
        handleLoading(false);
      }
    } else {
      setOpen(allowExtension.join(", "));
      handleLoading(false);
    }
  };

  const handleSavePhoto = (url, file, fileUUID, ext) => {
    let filetypeState;
    switch (ext) {
      case "STL":
        filetypeState = "stl";
        break;
      case "ZIP":
        filetypeState = "zip";
        break;
      default:
        filetypeState = filetype;
        break;
    }

    switch (filetypeState) {
      case "image":
      case "stamp":
      case "logo":
        // console.log(
        //   "로그입니당,",
        //   patient?.id ? category : "library",
        //   filetypeState
        // );
        getImageInfo(url)
          .then(img => {
            const fields = file?.name?.split(".");
            const detail = {
              width: img?.width,
              height: img?.height,
              size: file?.size,
              extension: fields[fields.length - 1]
            };
            createPhoto({
              variables: {
                hospitalId: hospitalId,
                id: "",
                type: patient?.id ? category : "library",
                date: getTimestampFromDate(),
                lastModifiedTime: getTimestampFromDate(),
                patient: patient?.id ? patient.id : "__libraries__",
                content: {
                  filetype: filetypeState,
                  filename: file?.name || "temp_file",
                  filenode: uuid(),
                  folder: folder,
                  object: fileUUID,
                  detail: JSON.stringify(detail)
                }
              }
            });
          })
          .catch(e => console.error(e));
        break;
      case "stl":
      case "zip":
      default:
        createPhoto({
          variables: {
            hospitalId: hospitalId,
            id: "",
            type: patient?.id ? category : "library",
            date: getTimestampFromDate(),
            lastModifiedTime: getTimestampFromDate(),
            patient: patient?.id ? patient.id : "__libraries__",
            content: {
              filetype: filetypeState,
              filename: file?.name || "temp_file",
              filenode: uuid(),
              folder: folder,
              object: fileUUID,
              detail: JSON.stringify({
                extension: filetypeState
              })
            }
          }
        });
        break;
    }
  };

  const handlePhotoChange = photo => {
    const detail = photo?.content?.detail;
    const photoSize = JSON.parse(detail)?.size;
    const { extension } = detail ? JSON.parse(detail) : {};

    if (!Boolean(detail) || !Boolean(extension)) {
      setOpen(allowExtension.join(", "));
      return;
    }

    if (extension && !allowExtension.includes(extension.toUpperCase())) {
      setOpen(allowExtension.join(", "));
      return;
    }

    if (
      detail &&
      photoSize &&
      photoSize > maxSize.split("mb")[0] * 1024 * 1024
    ) {
      setOpenSizeCheck(true);
      return;
    } else if (nonAttachPhoto) {
      onChange(photo);
    } else {
      fetchObjectUrl(photo)
        .then(async photo => {
          const url = photo.content.source;

          console.log("URL>>>", url);
          const response = await fetch(url);
          return response.blob();
        })
        .then(blobFile => {
          if (blobFile) {
            const fileUUID = uuid();
            putObjectBlob(
              target,
              hospitalId,
              fileUUID,
              blobFile,
              handleProgress
            ).then(() => {
              createPhoto({
                variables: {
                  hospitalId: hospitalId,
                  id: "",
                  type: patient?.id ? category : "library",
                  date: getTimestampFromDate(),
                  lastModifiedTime: getTimestampFromDate(),
                  patient: patient?.id ? patient.id : "__libraries__",
                  content: {
                    filetype: filetype,
                    content: photo?.content?.content,
                    //photo?.content?.filetype,
                    filename: photo?.content?.filename,
                    filenode: uuid(),
                    folder: folder,
                    object: fileUUID,
                    detail: photo?.content?.detail
                  }
                }
              });
            });
          }
        });
    }

    setExplorerOpen(false);
  };

  useEffect(() => {
    if (value) {
      getPhoto({ variables: { hospitalId: hospitalId, id: value } });
    }
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel();
      }
    };
  }, [value, getPhoto, hospitalId]);

  useEffect(() => {
    if (clearPhoto) setPhoto(null);
  }, [clearPhoto]);

  useEffect(() => {
    console.log("PHOTO >>>", photo);
  }, [photo]);

  return (
    <>
      <Files
        multiple={true}
        maxSize={maxSize}
        multipleMaxSize={maxSize}
        multipleMaxCount={1}
        accept={accept && accept}
        onSuccess={files => handleBlobFileChange(files[0])}
        onError={errors => {
          if (errors[0].type === "maxSizeExceeded") {
            setOpenSizeCheck(true);
          } else if (errors[0].type === "unsupportedFileType") {
            setOpen(allowExtension.join(", "));
          } else {
            console.error(errors);
          }
        }}
      >
        {({ browseFiles }) => {
          return (
            <Grid container alignItems={"center"}>
              <Grid item hidden={!!buttonIcon}>
                <CCTooltip title={title}>
                  <CVButton
                    className={classes.btn}
                    variant={"contained"}
                    color={color}
                    startIcon={startIcon}
                    children={title}
                    ref={fileUploadButton}
                    onClick={() =>
                      onlyDirect ? browseFiles() : setOpenLogoMenu(true)
                    }
                  />
                </CCTooltip>
              </Grid>
              <Grid item hidden={!buttonIcon}>
                <CCIconButton
                  children={buttonIcon}
                  ref={fileUploadIconButton}
                  onClick={() =>
                    onlyDirect ? browseFiles() : setOpenLogoMenu(true)
                  }
                />
              </Grid>
              <Grid item>
                <CCMenu
                  anchorEl={
                    buttonIcon
                      ? fileUploadIconButton.current
                      : fileUploadButton.current
                  }
                  open={openLogoMenu}
                  onClose={() => setOpenLogoMenu(false)}
                  menuItems={[
                    {
                      value: "1",
                      label: intl.formatMessage({ id: "getExplorer" }),
                      onClick: () => {
                        setExplorerOpen(true);
                      }
                    },
                    {
                      value: "2",
                      label: intl.formatMessage({ id: "getPC" }),
                      onClick: browseFiles
                    }
                  ]}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                />
              </Grid>
              <Grid item style={{ margin: "0 8px" }} hidden={disablePreview}>
                <Grid container alignItems={"center"}>
                  <Grid item>
                    <Box
                      style={{
                        width: 788,
                        backgroundColor: "#000",
                        height: "100%",
                        backgroundImage: `url(${photo?.url})`,
                        backgroundPosition: "center center",
                        backgroundOrigin: "border-box",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat"
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Files>
      {del && (
        <CCDialogSimpleDel
          open={del}
          onAgree={() => {
            setDel(false);
            setPhoto(null);
            onChange(null);
          }}
          onDisagree={() => setDel(false)}
          onClose={() => setDel(false)}
        />
      )}
      {explorerOpen && (
        <Explorer
          open={explorerOpen}
          patient={patient ? patient : ""}
          onClose={() => setExplorerOpen(false)}
          onFileSelect={handlePhotoChange}
          tab={tab}
        />
      )}
      {openImageView && (
        <CCDialogSimple
          maxWidth="md"
          open={openImageView}
          onClose={() => setOpenImageView(false)}
          title={intl.formatMessage({ id: "showImage" })}
          onCloseButton={() => setOpenImageView(false)}
          contents={
            <Box style={{ height: 660 }}>
              <ImageViewer clickedItem={photo} />
            </Box>
          }
          endActions={
            <CCButton
              variant={"contained"}
              onClick={() => setOpenImageView(false)}
            >
              {intl.formatMessage({ id: "check" })}
            </CCButton>
          }
        />
      )}
      {Boolean(open) && (
        <CCDialogSimple
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpen("")}
          contents={
            <Grid container direction={"column"}>
              <CCTypography className={classes.error}>
                {intl.formatMessage({ id: "cantLoadFileType" })}
              </CCTypography>
              <CCTypography className={classes.error}>
                {intl.formatMessage(
                  { id: "allowFileType" },
                  { fileType: open }
                )}
              </CCTypography>
            </Grid>
          }
          endActions={
            <CCButton color="primary" onClick={() => setOpen("")}>
              {intl.formatMessage({ id: "check" })}
            </CCButton>
          }
        />
      )}
      {openSizeCheck && (
        <CCDialogSimple
          style={{ width: 300 }}
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpenSizeCheck(false)}
          contents={
            <>
              <CCTypography className={classes.error}>
                {intl.formatMessage(
                  { id: "maxAttachmentSize" },
                  {
                    br: () => <br />,
                    size: maxSize === "0.3mb" ? "300kb" : maxSize
                  }
                )}
              </CCTypography>
            </>
          }
          endActions={
            <CCButton color="primary" onClick={() => setOpenSizeCheck("")}>
              {intl.formatMessage({ id: "check" })}
            </CCButton>
          }
        />
      )}
    </>
  );
};

ImageUploadButton.defaultProps = {
  value: "",
  onChange: e => console.log(e),
  onlyDirect: false, // 바로 파일 등록 시 사용
  nonAttachPhoto: false,
  target: Target.PHOTO,
  disablePreview: true, // 오른쪽 파일 링크
  tab: 2,
  category: "photo",
  folder: "attatchment",
  startIcon: <ClipIcon />,
  //accept: ["image/jpg", "image/jpeg", "image/png"],
  allowExtension: ["PNG", "JPG", "JPEG"],
  maxSize: "10mb",
  handleLoading: _ => _,
  handleProgress: _ => _
};

ImageUploadButton.propTypes = {
  patient: PropTypes.object // { id: patientContext.id, value: patientContext }
};

export default ImageUploadButton;
