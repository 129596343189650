import moment from 'moment';
import React from 'react';
import IMaskInput from 'react-imask/esm/input';
import MaskedInput from 'react-text-mask';
import { GENDER } from 'types/patient';

const getKoreanAge = (birth, date = moment()) =>
  (Number(date.format('YYYY')) - Number(birth.format('YYYY')) + 1).toString();

const getKoreanMonths = (birth, date = moment(moment(), 'YYYY/MM/DD')) =>
  parseInt(moment(date).diff(birth, 'day') / 30);

const getAge = (birth, date = moment()) => date.diff(birth, 'year');
const getAgeMonths = (birth, date = moment()) => date.diff(birth, 'months');

const getAgeToBirthDay = (birthDay) => {
  if (!!birthDay) {
    return getAge(moment(birthDay.toString(), 'YYYY/MM/DD'));
  }
};

const calcBirthDate = (value) => {
  // 주민 번호에서 생년월일 뽑을때 사용 XXXXXX-XXXXXXX
  if (!!value) {
    let numValue = value.replace(/[^\d]/g, ''); // 숫자만 뽑기
    if (numValue.length < 7) {
      return '';
    } else {
      let YY = numValue.slice(0, 2);
      let MM = numValue.slice(2, 4);
      let DD = numValue.slice(4, 6);
      let C = numValue.slice(6, 7);

      if (C === '9' || C === '0') {
        return `18${YY}/${MM}/${DD}`;
      } else if (C === '3' || C === '4' || C === '7' || C === '8') {
        return `20${YY}/${MM}/${DD}`;
      } else {
        return `19${YY}/${MM}/${DD}`;
      }
    }
  } else {
    return '';
  }
};

const getSex = (ResidentRegistrationNumber) => {
  if (ResidentRegistrationNumber) {
    return ResidentRegistrationNumber.substring(7, 8) % 2 === 1
      ? GENDER.MALE.value
      : GENDER.FEMALE.value;
  }
};

const getSexStrToValue = (genderValue) => {
  if (genderValue) {
    if (genderValue === GENDER.MALE.value) {
      return '남';
    } else if (genderValue === GENDER.FEMALE.value) {
      return '여';
    } else return '';
  } else return '';
};

const getSexStr = (ResidentRegistrationNumber) => {
  if (ResidentRegistrationNumber) {
    let genderValue = getSex(ResidentRegistrationNumber);
    if (genderValue === GENDER.MALE.value) {
      return '남';
    } else if (genderValue === GENDER.FEMALE.value) {
      return '여';
    } else return '';
  } else return '';
};

// 주민등록번호 형식 맞추기
const ResidentRegistrationNumberMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      guide={true} // 가이드 사용 여부, 기본값 true
      placeholderChar={'\u2000'} // 가이드 문자 공백 "\u2000" 으로 지정
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /[\d,*]/,
        /[\d,*]/,
        /[\d,*]/,
        /[\d,*]/,
        /[\d,*]/,
        /[\d,*]/,
      ]}
      showMask={false} //
      keepCharPositions={false} // 중간 삭제 시 뒤에 값 자리 위치
      {...other}
    />
  );
};

const ResidentRegistrationNumberIMask = (props) => (
  <IMaskInput
    {...props}
    mask={[{ mask: '000000-0000000' }, { mask: '000000-0*******' }]}
  />
);

// 주민 번호 validation check
const ResidentRegistrationNumberValidationCheck = (value) => {
  let numValue = value.replace(/[^\d]/g, ''); // 숫자만 뽑기
  if (numValue.length && numValue.length === 13) {
    let arrNum1 = [...numValue.slice(0, 6)]; // 주민번호 앞자리숫자 6개를 담을 배열
    let arrNum2 = [...numValue.slice(6, 13)]; // 주민번호 뒷자리숫자 7개를 담을 배열
    let tempSum = 0;

    for (let i = 0; i < 6; i++) {
      tempSum += arrNum1[i] * (2 + i);
    } // 주민번호 검사방법을 적용하여 앞 번호를 모두 계산하여 더함

    for (let i = 0; i < 6; i++) {
      if (i >= 2) {
        tempSum += arrNum2[i] * i;
      } else {
        tempSum += arrNum2[i] * (8 + i);
      }
    } // 같은방식으로 앞 번호 계산한것의 합에 뒷번호 계산한것을 모두 더함

    return (11 - (tempSum % 11)) % 10 === Number(arrNum2[6]);
  } else return false;
};

//생년월일 validation check
const birthDateValidationCheck = (value) => {
  if (value) {
    let numValue = value.replace(/[^\d]/g, '');
    if (numValue.length && numValue.length === 8) {
      let birthDate = moment(value, 'YYYY/MM/DD');
      return birthDate.isValid() && moment().diff(birthDate, 'days') > 0;
    } else return false;
  } else return false;
};

// 년월일 형식 맞추기 YYYY/MM/DD
const DateMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      guide={true} // 가이드 사용 여부, 기본값 true
      placeholderChar={'\u2000'} // 가이드 문자 공백 "\u2000" 으로 지정
      mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
      showMask={false}
      keepCharPositions={true} // 중간 삭제 시 뒤에 값 자리 위치
      {...other}
    />
  );
};

// 주민등록 번호 "*" 마스킹
const regNumMask = (value) => {
  if (value && value.length) {
    return value.slice(0, 8) + value.slice(8, 15).replace(/[\d]/g, '*');
  } else return '';
};

//***** 정규식 *****//
// 한글만
const replaceToKr = (value) => {
  if (value) {
    return value.replace(
      /(?:[^\s\uAC00-\uD7A3\u3131-\u314E\u314F-\u3163\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55])+/g,
      '',
    );
  }
};

// 숫자만
const replaceToNum = (value) => {
  if (value) {
    return value.replace(/(?:[^\w])+/g, '');
  }
};

// 영어, 공백
const replaceToEn = (value) => {
  if (value) {
    return value.replace(/(?:[^a-zA-Z\s])+/g, '');
  }
};

// 숫자,영어
const replaceToEnNum = (value) => {
  if (value) {
    return value.replace(/(?:[^a-zA-Z\w]|_)+/g, '');
  }
};

// 한글,영어,공백 이외 전부 제거
const replaceToKrEn = (value) => {
  if (value) {
    return value.replace(
      /(?:[^a-zA-Z\s\uAC00-\uD7A3\u3131-\u314E\u314F-\u3163\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55])+/g,
      '',
    );
  }
};

// 영어, 한글, 숫자, 공백 이외 전부 제거
const replaceToKrEnNum = (value) => {
  if (value) {
    return value.replace(
      /(?:[^\w\s\uAC00-\uD7A3\u3131-\u314E\u314F-\u3163\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55]|_)+/g,
      '',
    );
  }
};

const numFormat = (variable) => {
  variable = Number(variable).toString();
  if (Number(variable) < 10 && variable.length === 1) variable = '0' + variable;
  return variable;
};

export {
    getKoreanAge,
    getKoreanMonths,
    calcBirthDate,
    getAgeToBirthDay,
    getSex,
    ResidentRegistrationNumberMask,
    ResidentRegistrationNumberIMask,
    ResidentRegistrationNumberValidationCheck,
    DateMask,
    getAge,
    getAgeMonths,
    replaceToEnNum,
    birthDateValidationCheck,
    regNumMask,
    replaceToKr,
    replaceToNum,
    replaceToEn,
    replaceToKrEn,
    replaceToKrEnNum,
    numFormat,
    getSexStr,
    getSexStrToValue,
};

