import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Badge, Box, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { translate } from "components";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton } from "styles/src/components";
import { CheckIcon } from "styles/src/themes/common/icons";
import { LAB_ORDER_TYPE } from "types/labOrder";
import { makeIndexList } from "types/utils";
import DesignConfirmationModal from "../DesignConfirmationModal";
import CVTextField from "components/CVTextField";

import { ON_DESIGN_CONFIRM_ADDED, GET_DESIGN_CONFIRM_REQUESTS } from "queries/labOrder";

const useBadgeStyles = makeStyles(theme => ({
  root: {},
  colorPrimary: { backgroundColor: theme.palette.error.main }
}));

const GET_DESIGN_CONFIRM_REQUEST = gql`
  query GetDesignConfirmRequest($labOrderId: String) {
    designConfirmRequestsList(labOrderId: $labOrderId) {
      requests {
        date
        sender
        status
        note
        attachments {
          target
          id
          name
          type
        }
      }
    }
  }
`;

const MARK_CONFIRM_DESIGN_READ = gql`
mutation markConfirmDesignAsRead($labOrderId: String!) {
  markConfirmDesignAsRead(orderId: $labOrderId) {
    id
    designConfirmRequests{
    isLabRead
    isClinicRead
  }
  }
}
`

const useStyles = makeStyles(theme => ({
  root: { padding: 8 }
}));

const orderTypeIndex = makeIndexList(LAB_ORDER_TYPE);

const ViewOrderField = props => {
  const { value } = props;
  const {
    patient,
    clinic,
    requestId,
    createdBy,
    type,
    doctor,
    requestDate,
    labName,
    deliveryRequestDate,
    designConfirmMessageRead
  } = value;

  const classes = useStyles();
  const intl = useIntl();
  const badgeClasses = useBadgeStyles();
  const [designConfirmationVisible, setDesignConfirmationVisible] =
    useState(false);
  const [badgeDesignConfirmMessageRead, setBadgeDesignConfirmMessageRead] = useState(designConfirmMessageRead || 0)
  const [getDesignConfirmRequestList] = useLazyQuery(
    GET_DESIGN_CONFIRM_REQUEST,
    {
      variables: {
        labOrderId: value.id
      }
    }
  );

  const { data: dataDesignConfirm, subscribeToMore, refetch } = useQuery(GET_DESIGN_CONFIRM_REQUESTS, {
    variables: {
      id: value.id
    },
  });

  const [markConfirmDesignRead] = useMutation(
    MARK_CONFIRM_DESIGN_READ,
    {
      variables: {
        labOrderId: value.id
      },
      onCompleted: data => {
        refetch()
      }
    }
  );

  useEffect(() => {
    subscribeToMore({
      document: ON_DESIGN_CONFIRM_ADDED,
      variables: { orderId: value.id },
      shouldResubscribe: true,
      updateQuery: (prev, { subscriptionData }) => {
        refetch();
      }
    })
  }, [refetch, subscribeToMore, value.id]);

  const newDesignConfirmUnRead = useMemo(() => {
    return designConfirmationVisible ? 0 : (dataDesignConfirm?.orderDetail?.designConfirmRequests?.filter(item => !item.isLabRead).length || 0)
  }, [dataDesignConfirm, designConfirmationVisible])

  return (
    <Box className={clsx(classes.root)}>
      <Grid container wrap={"nowrap"} alignItems={"center"}>
        <Grid item>
          <CVTextField
            style={{ width: 150 }}
            label={translate(intl, "common.ui.orderNo")}
            value={requestId}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>
        <Grid item>
          <CVTextField
            style={{ width: 100 }}
            label={translate(intl, "common.ui.orderType")}
            value={translate(intl, orderTypeIndex[type])}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>
        <Grid item>
          <CVTextField
            style={{ width: 130 }}
            label={translate(intl, "labManageGold.cleverNormal")}
            value={
              createdBy && createdBy === "Clever"
                ? translate(intl, "settings.clinics.clever")
                : translate(intl, "settings.clinics.general")
            }
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>

        <Grid item style={{ margin: "auto" }} />
        {value.isClever && (
          <Grid item style={{ padding: 8 }}>
            <Badge
              classes={badgeClasses}
              badgeContent={newDesignConfirmUnRead}
              color="primary"
            >
              <CCButton
                startIcon={<CheckIcon />}
                variant={"outlined"}
                color={"secondary"}
                onClick={() => {
                  markConfirmDesignRead()
                  setDesignConfirmationVisible(true)
                }}
              >
                {translate(intl, "common.ui.designConfirm")}
              </CCButton>
            </Badge>
            {designConfirmationVisible &&
              <DesignConfirmationModal
                open={designConfirmationVisible}
                onClose={() => {
                  markConfirmDesignRead()
                  setDesignConfirmationVisible(false)
                }}
                orderId={value.id}
                dentalName={clinic?.name}
                patientName={patient?.name}
                labName={labName}
                getDesignConfirmRequestList={getDesignConfirmRequestList}
              />
            }
          </Grid>
        )}
      </Grid>
      <Grid container wrap={"nowrap"}>
        <Grid item>
          <CVTextField
            style={{ width: 130 }}
            label={translate(intl, "common.ui.orderDate")}
            value={moment.unix(requestDate).format("L")}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>

        <Grid item>
          <CVTextField
            style={{ width: 130 }}
            label={translate(intl, "common.ui.dueDate")}
            value={moment.unix(deliveryRequestDate).format("L")}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>
        <Grid item>
          <CVTextField
            style={{ width: 120 }}
            label={translate(intl, "common.ui.clinic")}
            value={clinic?.name}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>
        <Grid item>
          <CVTextField
            style={{ width: 100 }}
            label={translate(intl, "common.ui.drName")}
            value={doctor}
            InputLabelProps={{ shrink: !!doctor }}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>

        <Grid item>
          <CVTextField
            style={{ width: 100 }}
            label={translate(intl, "common.ui.patientName")}
            value={patient?.name}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>
        <Grid item>
          <CVTextField
            style={{ width: 60 }}
            label={translate(intl, "common.ui.age")}
            value={patient?.age}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>
        <Grid item>
          <CVTextField
            style={{ width: 80 }}
            label={translate(intl, "common.ui.sex")}
            value={patient?.sex === 1 ? "M" : "F"}
            InputLabelProps={{ shrink: !!patient?.sex }}
            variant="outlined"
            margin="dense"
            disabled
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ViewOrderField.propTypes = {};

export default ViewOrderField;
