import React from "react";

const DaygoIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M18 13c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zM8 2v2h8V2h2v2h1c1.05 0 1.918.82 1.994 1.851L21 6l.001 6.803c-.607-.351-1.282-.6-2-.72L19 9H5v11h7.342c.263.746.67 1.424 1.187 2H5c-1.06 0-1.919-.82-1.995-1.851L3 20l.01-14c0-1.05.802-1.918 1.84-1.994L5 4h1V2h2zm10 12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 1l3 3-3 3-.705-.705 1.79-1.795H15v-1h4.085l-1.79-1.795L18 15zm-9-4v2H7v-2h2zm4 0v2h-2v-2h2zm4 0v1.083c-.718.12-1.393.368-2 .72V11h2z"
            transform="translate(-5277 -192) translate(5277 192)"
          />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5277 -192) translate(5277 192)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default DaygoIcon;
