import { CircularProgress, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CCIconButton, CCTextField } from "styles/components";
import { RemoveIcon, SearchIcon } from "styles/src/themes/common/icons";

const useStyles = makeStyles(() => ({
  root: {},
  adorned__end: { paddingRight: 8 },
  visible: { visibility: "visible" },
  unvisible: { visibility: "hidden" }
}));

const CCSearchTextField = forwardRef((props, ref) => {
  const {
    className,
    onIconClick = () => { },
    onKeyPress,
    loading = false,
    inputRef,
    onClear = null,
    ...others
  } = props;
  const classes = useStyles();

  const pandding = () => { };
  const [display, setDisplay] = useState(false);
  const [text, setText] = useState(false);
  const intl = useIntl();

  const onHandleChange = useCallback(() => {
    const value = inputRef.current.value;
    if (!!value) {
      setText(true);
    } else {
      setText(false);
    }
  }, [inputRef]);

  const onMouseOver = useCallback(() => setDisplay(true), []);

  const onMouseOut = useCallback(() => setDisplay(false), []);

  const onCustomClear = useCallback(() => {
    onClear();
    setText(false);
  }, [onClear]);

  const visible = useMemo(() => text && display, [display, text]);

  return (
    <CCTextField
      className={className}
      placeholder={intl.formatMessage({ id: "ccSearch" })}
      variant="outlined"
      margin="dense"
      onChange={onHandleChange}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      InputProps={{
        classes: {
          adornedEnd: classes.adorned__end
        },
        inputProps: {
          maxLength: 200
        },
        endAdornment: (
          <InputAdornment position="end">
            {onClear && (
              <CCIconButton
                className={clsx({
                  [classes.visible]: visible,
                  [classes.unvisible]: !visible
                })}
                size="small"
                onClick={loading ? pandding : onCustomClear}
                disabled={loading}
              >
                <RemoveIcon />
              </CCIconButton>
            )}
            <CCIconButton
              size="small"
              onClick={loading ? pandding : onIconClick}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <SearchIcon />
              )}
            </CCIconButton>
          </InputAdornment>
        )
      }}
      onKeyPress={loading ? pandding : onKeyPress}
      inputRef={inputRef}
      {...others}
    />
  );
});

export default CCSearchTextField;
