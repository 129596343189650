import React from "react";

const OfficialIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M20.35 16c.913 0 1.65.722 1.65 1.615v3.231h-2.2V23h-6.6v-2.154H11v-3.23c0-.894.737-1.616 1.65-1.616zm-1.406 2.833h-4.888v2.917h4.888v-2.917zM10 1c1.095 0 2.03.716 2.443 1.729l.064.171h3.715c.93 0 1.698.772 1.772 1.745L18 4.8l-.001 6.214h-2.133V5H4.134v13h4.873v2H3.778c-.083 0-.162-.004-.24-.013l-.116-.015c-.346-.077-.658-.267-.898-.523-.16-.18-.293-.38-.382-.608-.066-.171-.113-.358-.132-.55L2 18.1V4.8c0-.266.053-.513.142-.732.09-.227.222-.436.382-.607.24-.257.552-.447.898-.523.077-.019.154-.03.234-.034l.122-.004h3.715C7.867 1.798 8.844 1 10 1zm10.167 16.083c-.336 0-.611.263-.611.584 0 .32.275.583.61.583.337 0 .612-.262.612-.583 0-.321-.275-.584-.611-.584zM11.512 13v2H6v-2h5.512zm8.655 0v2h-7.334v-2h7.334zM14 10v2H6v-2h8zm0-3v2H6V7h8zm-4-4c-.292 0-.533.227-.533.5s.241.5.533.5c.292 0 .533-.227.533-.5S10.292 3 10 3z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default OfficialIcon;
