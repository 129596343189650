import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useRef } from "react";
import { useFindArabic } from "../../../locales/utils";

const useStyles = makeStyles(theme => ({
  highlighted: {
    padding: 0,
    border: "none"
  },
  highlighted__typography: {
    backgroundColor: "yellow",
    color: "black !important"
  },
  arabic_fix: {
    direction: "rtl",
    textAlign: "left"
  }
}));
const Highlighter = ({ children, highlight, onRef = () => {}, component }) => {
  const classes = useStyles();
  useEffect(() => {
    onRef(inputRef);
  }, [highlight, onRef]);

  const inputRef = useRef([]);
  if (!highlight) return children;
  const regexp = new RegExp(highlight, "gi");
  const matches = children.match(regexp);
  var parts = children.split(new RegExp(`${highlight.replace()}`, "gi"));

  for (var i = 0; i < parts.length; i++) {
    if (i !== parts.length - 1) {
      let match = matches[i];
      // While the next part is an empty string, merge the corresponding match with the current
      // match into a single <span/> to avoid consequent spans with nothing between them.
      while (parts[i + 1] === "") {
        i++;
        if (matches[i]) match += matches[i];
      }
      parts[i] = (
        <React.Fragment key={i}>
          {parts[i]}
          <button
            className={classes.highlighted}
            ref={ref => {
              inputRef.current = inputRef.current.concat(ref);
            }}
          >
            <Typography
              variant="h5"
              className={classes.highlighted__typography}
            >
              {match}
            </Typography>
          </button>
        </React.Fragment>
      );
    }
  }

  return <span>{parts}</span>;
};

const CCTypography = forwardRef((props, ref) => {
  const { findArabic } = useFindArabic();
  const { children, highlight, onRef, ...others } = props;
  const classes = useStyles();
  if (!highlight) {
    if (typeof children === "string" || typeof children === "object") {
      return (
        <Typography
          {...others}
          ref={ref}
          className={clsx(
            {
              [classes.arabic_fix]: findArabic(children)
            },
            others?.className
          )}
        >
          {children}
        </Typography>
      );
    }
    return (
      <Typography {...others} ref={ref}>
        {children}
      </Typography>
    );
  }

  return (
    <Typography {...others} ref={ref}>
      <Highlighter highlight={highlight} onRef={onRef}>
        {children}
      </Highlighter>
    </Typography>
  );
});
CCTypography.propTypes = {
  /** 글자 크기 설정 */
  variant: PropTypes.string
};

export default CCTypography;
