import { DialogWarn } from "components";
import React from "react";
import { useIntl } from "react-intl";
import translate from "../Translate";

const DialogPermissionWarn = props => {
  const { open, onClose } = props;
  const intl = useIntl();

  return (
    <DialogWarn
      open={open}
      onClose={onClose}
      text={translate(intl, "common.messages.youDoNotHavePermissionToEditTheContents")}
    />
  );
};

export default DialogPermissionWarn;
