import { Box, makeStyles, MenuItem } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { CCTextField } from "styles/components";
import { translate } from "components";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex"
  }
}));

const ClinicFields = props => {
  const { clinic, type, clinicList, onChangeClinic } = props;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <CCTextField
        label={translate( intl,"labManageGold.clinic" )}
        variant="outlined"
        margin="dense"
        select
        fullWidth
        value={clinic}
        onChange={onChangeClinic}
      >
        {clinicList.map(option => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </CCTextField>

      <CCTextField
        label={translate( intl,"labManageGold.cleverNormal" )}
        variant="outlined"
        margin="dense"
        value={type}
        disabled
      />
    </Box>
  );
};

export default ClinicFields;
