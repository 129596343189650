import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import PatientSelector from "components/PatientSelector";
import PermissionBackdrop from "components/PermissionBackdrop";
import React, { useEffect, useReducer } from "react";
import { CCDialog, CCDialogContent, CCTabs } from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import CCDialogTitle from "styles/src/components/CCDialogTitle";
import CCTabPanel from "styles/src/components/CCTabPanel";
import { MovedownIcon, MoveupIcon } from "styles/src/themes/common/icons";
import { Document, FilePhoto, Receipt, Reservation } from "./Component";

const tabsHeight = 52;
const headerHeight = 90;
const useStyles = makeStyles(theme => ({
  root: { height: "100%", maxWidth: 1829 },
  searchHeader: {
    height: headerHeight,
    maxHeight: headerHeight,
    boxSizing: "border-box",
    overflowY: "hidden"
  },
  searchInput: {
    width: 194
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    height: tabsHeight,
    boxSizing: "border-box"
  },
  panel: {
    height: `calc(100%  - ${tabsHeight}px)`,
    overflow: "auto",
    position: "relative"
  }
}));

// const EXPLORER_TABS = {
//   RESERVATION: 0,
//   RECEIPT: 1,
//   MEDICAL: 2,
//   PAYMENT: 3,
//   ORAL_EXAM: 4,
//   SMS: 5,
//   CALL_HISTORY: 6,
//   LAB_ORDER: 7,
//   FILE_PHOTO: 8,
//   DOCUMENT: 9
// };

const PermissionReservation = props => {
  const permission = true;
  if (permission) {
    return <Reservation {...props} />;
  } else {
    return <PermissionBackdrop permission={"reservation"} />;
  }
};
const PermissionReceipt = props => {
  const permission = true;
  if (permission) {
    return <Receipt {...props} />;
  } else {
    return <PermissionBackdrop permission={"desk"} />;
  }
};

const PermissionFilePhoto = props => {
  const permission = true;
  if (permission) {
    return <FilePhoto {...props} />;
  } else {
    return <PermissionBackdrop permission={"photoView"} />;
  }
};

const tabList = [
  {
    label: "예약",
    component: props => <PermissionReservation {...props} />
  },
  {
    label: "접수/내원",
    component: props => <PermissionReceipt {...props} />
  },
  {
    label: "파일/사진",
    component: props => <PermissionFilePhoto {...props} />
  },
  {
    label: "문서발급",
    component: props => <Document {...props} />
  }
];

const initializer = props => {
  // console.log("initializer", props);
  const {
    patient,
    tab = 0,
    onClose = () => {},
    open = false,
    filter,
    onChange = () => {},
    onFileSelect = null
  } = props;
  return {
    patient: patient,
    tab: tab,
    onClose: onClose,
    open: open,
    filter,
    collapse: true,
    onChange: onChange,
    onFileSelect: onFileSelect
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setPatient":
      return { ...state, patient: action.target };
    case "tab":
      return { ...state, tab: action.target };
    case "collapse":
      return { ...state, collapse: action.target };
    case "open":
      return { ...state, open: action.target };
    default:
      break;
  }

  return state;
};

const Explorer = props => {
  const { open, patient } = props;
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const classes = useStyles();
  useEffect(() => {
    dispatchState({ type: "open", target: open });
  }, [open]);
  useEffect(() => {
    // console.log("useEffect", patient);
    dispatchState({ type: "setPatient", target: { ...patient } });
  }, [patient]);
  // console.log(state);
  return (
    <CCDialog
      fullWidth
      open={state.open}
      PaperProps={{ className: classes.root }}
      onClose={state.onClose}
    >
      <CCDialogTitle onCloseButton={state.onClose}>
        {"Clever 탐색기"}
      </CCDialogTitle>
      <CCDialogContent className={classes.searchHeader}>
        <Grid container justify={"space-between"} alignItems={"center"}>
          <Grid item>
            <PatientSelector
              className={classes.searchInput}
              value={state.patient}
              onChange={e => {
                console.log(e);
                dispatchState({ type: "setPatient", target: e });
              }}
            />
          </Grid>
          <Grid item>
            {state.tab !== 2 && (
              <CCButton
                startIcon={state.collapse ? <MoveupIcon /> : <MovedownIcon />}
                variant={"outlined"}
                color={"secondary"}
                onClick={() =>
                  dispatchState({ type: "collapse", target: !state.collapse })
                }
              >
                {"조건검색"}
              </CCButton>
            )}
          </Grid>
        </Grid>
      </CCDialogContent>
      <CCDialogContent noPadding className={classes.panel}>
        <CCTabs
          className={classes.tabs}
          tabList={tabList.map(el => el.label)}
          selectedTab={state.tab}
          onChangeTab={e => dispatchState({ type: "tab", target: e })}
        />
        {tabList.map((d, i) => (
          <CCTabPanel
            className={classes.panel}
            key={i}
            value={state.tab}
            index={i}
          >
            {d.component(state)}
          </CCTabPanel>
        ))}
      </CCDialogContent>
    </CCDialog>
  );
};
export default Explorer;
