import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { PhotoTile } from 'components/index';
import React from 'react';
// import { getObject, Target } from "utils/s3";

const useStyle = makeStyles((theme) => ({
  root: { width: 128, height: 102, cursor: 'pointer' },
}));

const FileItem = (props) => {
  // const { data, onClick, currentItem } = props;
  // const [image, setImage] = useState('');
  const classes = useStyle();
  // useEffect(() => {
  //   let func = () => {
  //     switch (data?.content?.filetype) {
  //       case "paper":
  //       case "image":
  //         getObject(
  //           Target.PHOTO,
  //           localStorage.getItem('labId'),
  //           data.content.object
  //         ).then(res => {
  //           setImage(res);
  //         });
  //         break;
  //       case "stamp":
  //         getObject(
  //           Target.STAMP,
  //           localStorage.getItem('labId'),
  //           data.content.object
  //         ).then(res => {
  //           setImage(res);
  //         });
  //         break;
  //       case "logo":
  //         getObject(
  //           Target.LOGO,
  //           localStorage.getItem('labId'),
  //           data.content.object
  //         ).then(res => {
  //           setImage(res);
  //         });
  //         break;
  //       case "video":
  //         setImage("/contents/video.svg");
  //         break;
  //       case "consult":
  //         setImage("/contents/slide.svg");
  //         break;
  //       case "folder":
  //         setImage("/contents/folder.svg");
  //         break;
  //       default:
  //         setImage("/contents/image.svg");
  //     }
  //   };
  //   func();
  // }, [data, setImage]);

  return (
    <Grid item className={classes.root}>
      {/* <PhotoTile
        photo={data}
        image={image}
        tileChecked={data?.content?.filenode === currentItem?.content?.filenode}
        header={data?.content?.filename || '파일명 없음'}
        tileMode='none'
        tileHeight={100}
        onClick={(e, photo) => {
          onClick && onClick(data);
        }}
        onClickMore={(e) => {}}
        onCheck={(e) => {}}
      /> */}
    </Grid>
  );
};

export default FileItem;
