import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVTextField } from "components";
import React from "react";
import { useIntl } from "utils/language";
import ButtonTabs from "./components/ButtonTabs";

const height = 150;

const useStyles = makeStyles(theme => ({
  root: { width: "100%", marginTop: "30px" },
  chief__complaint__input: {
    marginBottom: "16px"
  },
  chief__complaint__table: {
    height: `calc(100% - ${height}px)`
  },
  head__field: {
    width: 130
  },
  head__storage__field: {
    width: 170
  },
  head__search__field: {
    width: 320
  },
  bin__field: {
    marginLeft: 20,
    width: 80
  },
  margin__field: {
    marginLeft: 10,
    paddingBottom: 0,
    paddingLeft: 0
  },
  font__field: {
    fontSize: 14,
    marginTop: 20,
    marginLeft: 10,
    marginRight: 20,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    weight: "bold"
  },
  label__font__field: {
    fontSize: 14,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  top__margin__field: {
    marginTop: "-10px"
  },
  bottom__margin__field: {
    marginBottom: "-40px"
  },
  button__filter: {
    marginTop: "5px"
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  }
}));

const DetailModify = props => {
  const { value } = props;

  const intl = useIntl();

  const classes = useStyles();

  const tabList =
    value?.inOut === "inventoryReceiving"
      ? [
        "registerProduct",
        "inventorySurvey",
        "errorCorrection",
        "otherPurchase",
        "otherAcquisition"
      ]
      : ["materialUsage", "errorCorrection", "otherReturn", "otherLoss"];

  return (
    <>
      <Box className={clsx(classes.root)}>
        <CVTextField
          className={classes.head__field}
          variant={"outlined"}
          margin={"dense"}
          name={"searchField"}
          defaultValue={value?.major || ""}
          value={value?.major || ""}
          disabled={true}
          readOnly={true}
          label={intl.formatMessage({ id: "majorCategory" })}
        />
        <CVTextField
          className={classes.head__search__field}
          variant={"outlined"}
          margin={"dense"}
          name={"searchField"}
          defaultValue={value?.productName || ""}
          value={value?.productName || ""}
          disabled={true}
          readOnly={true}
          label={intl.formatMessage({ id: "productName" })}
        />
        <CVTextField
          className={classes.head__field}
          variant={"outlined"}
          margin={"dense"}
          name={"searchField"}
          defaultValue={value?.manufacture || ""}
          value={value?.manufacture || ""}
          disabled={true}
          readOnly={true}
          label={intl.formatMessage({ id: "manufacturer" })}
        />

        <ButtonTabs expireData={[]} detail={value} tabList={tabList} />
      </Box>
    </>
  );
};

export default DetailModify;
