import React from "react";

const EnterIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M11 9L12.42 10.42 8.83 14 18 14 18 4 20 4 20 16 8.83 16 12.42 19.58 11 21 5 15z"
            transform="translate(-5176 -192) translate(5176 192)"
          />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5176 -192) translate(5176 192)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default EnterIcon;
