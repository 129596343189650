import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, Grid, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import {
  CVButton,
  CVTable,
  PermissionBackdrop,
  translate,
  useReadPermission
} from "components";
import { AppContext } from "context";
import moment from "moment";
import {
  GET_ACCUMULATE_PAYMENT,
  PAYMENT_LIST,
  UPDATE_PAYMENT_NOTE
} from "queries/payment";
import { GET_STAFF_LIST, PARTNER_LIST } from "queries/setting";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCIconButton,
  CCTextField,
  CCTypography
} from "styles/components";
import { AgreementIcon } from "styles/icons";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import { makeList } from "types";
import { PAYMENT_STATUS } from "types/payment";
import { makeIndexFormattedMessageList } from "types/utils";
import { formatCommas } from "utils/number";
import { initialState, reducer } from "views/Normal/Payment/reducer";
import {
  BillPaper, DatePicker, EditNoteDialog,
  PaymentDetailDialog,
  PaymentDialog
} from "./components";
// import { CCDatePicker } from "styles/components";

const width = 930;
const padding = 12;
const minWidth = 900;
const useStyles = makeStyles(() => ({
  root: { height: "100%" },
  container: { height: "100%" },
  item__left: {
    // width: `calc(100% - ${width}px)`,
    position: "relative",
    flex: 1,
    minWidth: minWidth,
    margin: "12px 12px 0 12px",
    border: "1px solid #b0bec5",
    borderRadius: 8,
    boxSizing: "border-box"
  },
  item__right: {
    width: width,
    padding: padding
  },
  width130: {
    width: 130
  },
  button__icon: {
    margin: 0,
    padding: 0
  },
  color__grey: {
    color: "#b0bec5"
  },
  table: {
    height: "calc(100vh - 315px)",
    width: "inherit",
    paddingBottom: 36,
    borderTop: "1px solid #b0bec5",
    borderBottom: "1px solid #b0bec5",
    borderRight: "1px solid #b0bec5",
  },
  border__right: {
    borderRight: "1px solid #b0bec5 "
  },
  color__red: {
    color: "#f50057"
  },
  color__green: {
    color: "#00897b"
  },
  pricetag: {
    width: 300,
    marginTop: 24,
    marginBottom: 24,
    justifyContent: "space-between"
  },
  divided: {
    width: 1,
    height: 36,
    backgroundColor: "#b0bec5",
    margin: "0 16px"
  },
  item__padding: {
    padding: padding
  },
  filter: {
    padding: "16px 0"
  },
  padding0: { padding: 0 },
  cell: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  cell__full: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  font__bold: {
    fontWeight: "bold"
  },
  note: {
    textDecoration: "underline",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "60%",
    textAlign: "center"
  },
  minwidth__100: {
    minWidth: 100
  },
  minwidth__120: {
    minWidth: 120
  },
  maxwidth__200: {
    minWidth: 200
  }
}));

const Payment = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { appContext } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { searchVariables, labSetting, labOrder } = state;
  const readPermission = useReadPermission("payment.billManage");
  const labOrderStateItem = makeList(PAYMENT_STATUS);
  const labOrderState = makeIndexFormattedMessageList(PAYMENT_STATUS);

  const [anchorEl, setAnchorEl] = useState(false);
  const [openAnchorEl, setOpenAnchorEl] = useState(false);
  const [filterState, setFilterState] = useState(searchVariables);
  const [openBill, setOpenBill] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openEditNoteDialog, setOpenEditNoteDialog] = useState(false);

  const { data: paymentHistoryData, refetch: searchLabRefetch } = useQuery(
    PAYMENT_LIST,
    {
      variables: {
        query: {
          ...searchVariables,
          status: searchVariables.status === "1" ? "" : searchVariables.status
        }
      },
      fetchPolicy: "network-only",
      pollInterval: 30000 // 30초 간격 폴링
    }
  );
  const [updatePaymentHistoryNote] = useMutation(UPDATE_PAYMENT_NOTE, {
    onCompleted: () => searchLabRefetch()
  });
  const { data: paymentAccumulate, refetch: refetchAccumulate } = useQuery(
    GET_ACCUMULATE_PAYMENT,
    {
      variables: {
        query: {
          ...searchVariables
        }
      },
      fetchPolicy: "network-only",
      pollInterval: 30000 // 30초 간격 폴링
    }
  );
  useQuery(PARTNER_LIST, {
    variables: {
      query: { skip: 0, limit: 20, keyword: "" }
    },
    onCompleted: data => {
      dispatch({
        type: "SET_SETTING",
        name: "labSetting",
        value: data?.partnerList?.items
      });
    }
  });
  useQuery(GET_STAFF_LIST, {
    variables: {
      query: { limit: 20, searchText: "" }
    },
    onCompleted: data => {
      dispatch({
        type: "CHANGE_VALUE",
        value: { staff: data?.getStaffList?.items }
      });
    }
  });

  const refetchPaymentHistoryAndAccummulate = () => {
    searchLabRefetch();
    refetchAccumulate();
  };
  const handleSearchVariables = async value => {
    await setFilterState(preState => ({
      ...preState,
      ...value
    }));

    if (value.isYearFilter !== undefined) {
      dispatch({
        type: "CHANGE_SEARCH_VARIABLES",
        value
      });
    }

    // console.log('handleSearchVariables.filterState', filterState);
  };

  const eventComponent = ({ rowData, component, data }) => {
    return (
      component || (
        <Box
          className={classes.cell__full}
          onClick={e => {
            setOpenAnchorEl(true);
            setAnchorEl(rowData);
          }}
        >
          {data}
        </Box>
      )
    );
  };
  const heads = [
    // {
    //   label: translate( intl,"payment.month" ),
    //   key: "month",
    //   className: clsx(classes.cell),
    //   width: 120,
    //   component: ({ rowData }) =>
    //     eventComponent({
    //       rowData,
    //       data: (
    //         <CCTypography>
    //           {rowData?.month === "all"
    //             ? rowData?.year
    //             : lang === "ko"
    //             ? `${rowData?.year}/${
    //                 rowData?.month.length === 1
    //                   ? `0${rowData?.month}`
    //                   : rowData?.month
    //               }`
    //             : `${
    //                 rowData?.month.length === 1
    //                   ? `0${rowData?.month}`
    //                   : rowData?.month
    //               }/${rowData?.year}`}
    //         </CCTypography>
    //       )
    //     })
    // },
    {
      label: translate(intl, "payment.sendDate"),
      key: "date",
      className: clsx(classes.cell),
      width: 120,
      component: ({ rowData }) =>
        eventComponent({
          rowData,
          data: (
            <CCTypography>
              {rowData.sendBillToCleverAt ? moment.unix(rowData.sendBillToCleverAt).format("L") : ""}
            </CCTypography>
          )
        })
    },
    {
      label: translate(intl, "payment.clinic"),
      key: "clinic",
      className: clsx(classes.cell, classes.border__right),
      flex: 1,
      component: ({ rowData }) => {
        // if (rowData?.clinic?.id) 
        return (
          <Grid
            container
            alignItems="center"
            justify="space-around"
            style={{ textAlign: "center", wordBreak: "break-word" }}
            onClick={e => {
              setOpenAnchorEl(true);
              setAnchorEl(rowData);
            }}
          >
            <CCTypography
              className={clsx({
                [classes.color__green]:
                  rowData?.clinic?.createdBy === "Clever" ? true : false
              })}
            >
              {rowData?.clinic?.name}
            </CCTypography>
            {/* <CCIconButton color={"primary"} className={classes.button__icon}>
                <CommitmentIcon />
              </CCIconButton> */}
          </Grid>
        );
      }
    },
    {
      label: translate(intl, "payment.status"),
      key: "status",
      className: clsx(classes.cell, classes.border__right),
      width: 90,
      component: ({ rowData }) => (
        <CCTypography
          onClick={e => {
            setOpenAnchorEl(true);
            setAnchorEl(rowData);
          }}
          className={clsx({
            [classes.cell__full]: true,
            [classes.color__red]: ["3", "4"].includes(rowData?.status),
            [classes.color__grey]: ["1", "2"].includes(rowData?.status)
          })}
        >
          {labOrderState[rowData?.status]}
        </CCTypography>
      )
    },

    {
      label: <b> {translate(intl, "payment.charged")}</b>,
      key: "charged",
      width: 130,
      className: clsx(classes.cell, classes.border__right, classes.fontbold),
      component: ({ rowData }) =>
        eventComponent({
          rowData,
          data: rowData?.charged ? formatCommas(rowData?.charged) : "-"
        })
    },

    {
      label: <p>{translate(intl, "payment.prothesisPrice")}</p>,
      key: "prothesisPrice",
      width: 200,
      className: clsx(classes.cell, classes.border__right),
      component: ({ rowData }) =>
        eventComponent({
          rowData,
          data: rowData?.prothesisPrice
            ? formatCommas(rowData?.prothesisPrice)
            : "-"
        })
    },

    // {
    //   label: translate(intl, "payment.goldPrice" ),
    //   key: "goldPrice",
    //   width: 110,
    //   className: clsx(classes.cell, classes.br),
    //   component: ({ rowData }) =>
    //     eventComponent({
    //       rowData,
    //       data: rowData?.goldPrice ? formatCommas(rowData?.goldPrice) : "-"
    //       // rowData?.type === LAB_ORDER_TYPE.NEW_REQUEST.value ? (
    //       //   <CCTypography variant={'h5'} color={'secondary'}>
    //       //     {labOrderType[rowData?.type]}
    //       //   </CCTypography>
    //       // ) : (
    //       //   labOrderType[rowData?.prosthesis]
    //       // ),
    //     })
    // },
    {
      label: <p>{translate(intl, "payment.dC")}</p>,
      key: "dc",
      className: clsx(classes.cell, classes.border__right),
      width: 110,
      component: ({ rowData }) =>
        eventComponent({
          rowData,
          data: rowData?.dc ? formatCommas(rowData?.dc) : "-"
        })
    },
    {
      label: <b> {translate(intl, "payment.totalPaid")} </b>,
      key: "totalPaid",
      width: 130,
      className: clsx(classes.cell, classes.border__right, classes.color__green),
      // className: clsx(
      //   classes.cell,
      //   classes.border__right,
      //   classes.color__green
      // ),
      component: ({ rowData }) => {
        return eventComponent({
          rowData,
          data: rowData?.totalPaid ? formatCommas(rowData?.totalPaid) : "-"
        });
      }

    },
    {
      label: <p>{translate(intl, "payment.card")}</p>,
      key: "cardPaymentAmount",
      width: 110,
      className: clsx(classes.cell),
      component: ({ rowData }) => {
        return eventComponent({
          rowData,
          data: rowData?.cardPaymentAmount
            ? formatCommas(rowData?.cardPaymentAmount)
            : "-"
        });
      }
    },
    {
      label: <p>{translate(intl, "payment.cash")}</p>,
      key: "cashPaymentAmount",
      width: 110,
      className: clsx(classes.cell, classes.border__right),

      component: ({ rowData }) => {
        return eventComponent({
          rowData,
          data: rowData?.cashPaymentAmount
            ? formatCommas(rowData?.cashPaymentAmount)
            : "-"
        });
      }
    },
    {
      label: <b>{translate(intl, "payment.unpaid")}</b>,
      key: "unpaid",
      width: 120,
      className: clsx(classes.cell, classes.border__right, classes.color__red),
      component: ({ rowData }) => {
        return eventComponent({
          rowData,
          data: rowData?.unpaid ? formatCommas(rowData?.unpaid) : "-"
        });
      }
    },

    // {
    //   label: <b>{translate(intl, "payment.note")}</b>,
    //   key: "note",
    //   width: 160,
    //   className: clsx(classes.cell, classes.border__right),
    //   component: ({ rowData }) => {
    //     return (
    //       <Grid
    //         container
    //         alignItems="center"
    //         justify="space-around"
    //         onClick={e => {
    //           setOpenAnchorEl(true);
    //           setAnchorEl(rowData);
    //         }}
    //       >
    //         <CCTooltip
    //           title={rowData.note || translate( intl,"payment.note" )}
    //         >
    //           <CCTypography className={classes.note}>
    //             {rowData.note || translate( intl,"payment.note" )}
    //           </CCTypography>
    //         </CCTooltip>

    //         <CCIconButton
    //           color={"primary"}
    //           className={classes.button__icon}
    //           onClick={e => {
    //             e.stopPropagation();
    //             setOpenEditNoteDialog(rowData);
    //           }}
    //         >
    //           <ModifyIcon />
    //         </CCIconButton>
    //       </Grid>
    //     );
    //   }
    // },
    {
      label: translate(intl, "payment.bills"),
      key: "bill",
      width: 80,
      className: clsx(classes.cell, classes.border__right),
      component: ({ rowData }) => {
        return (
          <CCIconButton
            color={"primary"}
            className={classes.button__icon}
            onClick={() => setOpenBill(rowData)}
          >
            <AgreementIcon />
          </CCIconButton>
        );
      }
    },
    {
      label: "",
      key: "id",
      width: 80,
      className: clsx(classes.cell, classes.minwidth__120),
      component: ({ rowData }) => {
        if (["3", "4"].includes(rowData?.status)) {
          return (
            <CCButton
              variant={"outlined"}
              color={"primary"}
              style={{
                textTransform: "unset",
              }}
              onClick={() => setOpenPaymentDialog(rowData)}
            >
              {translate(intl, "payment.payment")}
            </CCButton>
          );
        } else {
          return (
            <Box
              onClick={e => {
                setOpenAnchorEl(true);
                setAnchorEl(rowData);
              }}
              style={{ width: "100%", height: "100%" }}
            />
          );
        }
      }
    }
  ];

  useEffect(() => {
    searchLabRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAnchorEl]);

  return (
    <Box className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item className={classes.item__left}>
          {appContext.ready &&
            (!readPermission ? (
              <PermissionBackdrop permission={"payment.billManage"} />
            ) : (
              <Grid container>
                {/* <CCTypography variant="h4" className={classes.item__padding}>
                  {translate( intl,"payment.payment" )}
                </CCTypography> */}
                <Grid
                  container
                  alignItems="center"
                  className={classes.item__padding}
                >
                  <DatePicker
                    classes={classes}
                    onChange={(key, value) => {
                      handleSearchVariables({
                        [key]: value
                      });
                    }}
                    monthFilterValue={filterState.monthFilterValue}
                    yearFilterValue={filterState.yearFilterValue}
                    isYearFilter={filterState.isYearFilter}
                  />
                  <Box className={classes.divided} />
                  <CCTextField
                    className={classes.width130}
                    label={translate(intl, "payment.clinic")}
                    margin={"dense"}
                    variant={"outlined"}
                    value={filterState.clinic || ""}
                    select
                    onChange={e => {
                      // if (e.target.value !== "all") {
                      handleSearchVariables({ clinic: e.target.value });
                      // } else {
                      //   handleSearchVariables({ clinic: undefined });
                      // }
                    }}
                  >
                    {labSetting && [
                      <CCMenuItem key={"all"} value={"all"}>
                        {translate(intl, "common.ui.all")}
                      </CCMenuItem>,
                      ...labSetting.map(item => (
                        <CCMenuItem key={item.id} value={item.id}>
                          {item.name}
                        </CCMenuItem>
                      ))
                    ]}
                  </CCTextField>
                  <CCTextField
                    select
                    className={classes.width130}
                    label={translate(intl, "payment.status")}
                    margin={"dense"}
                    variant={"outlined"}
                    value={filterState.status || ""}
                    onChange={e => {
                      handleSearchVariables({
                        status: e.target.value
                      });
                    }}
                  >
                    {/* <CCMenuItem key={'선택없음'} value={''}>
                선택없음
              </CCMenuItem> */}
                    {labOrderStateItem.map(item => (
                      <CCMenuItem key={item.value} value={item.value}>
                        {item.label}
                      </CCMenuItem>
                    ))}
                  </CCTextField>

                  <CVButton
                    variant={"contained"}
                    color={"secondary"}
                    startIcon={<SearchIcon />}
                    style={{
                      textTransform: "unset",
                    }}
                    onClick={() => {
                      // handleRefetchData();
                      // handleRefetchAccumulate();

                      dispatch({
                        type: "CHANGE_SEARCH_VARIABLES",
                        value: {
                          ...filterState,
                          clinic:
                            filterState.clinic === "all"
                              ? ""
                              : filterState.clinic
                        }
                      });
                    }}
                  >
                    {translate(intl, "payment.search")}

                  </CVButton>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  className={classes.item__padding}
                >
                  <Grid container className={classes.pricetag}>
                    <CCTypography className={classes.color__grey}>
                      <p>{translate(intl, "payment.totalCharged")}</p>
                      {/* Total Charged ($) */}
                    </CCTypography>
                    <CCTypography variant="h4">
                    <p>{formatCommas(
                        paymentAccumulate?.paymentGetAccumulateAmount
                          .accumulateCharged
                      )}</p>
                    </CCTypography>
                  </Grid>

                  <Box className={classes.divided} />
                  <Grid container className={classes.pricetag}>
                    <CCTypography className={classes.color__grey}>
                      <p>{translate(intl, "payment.paid")}</p>
                      {/* Paid ($) */}
                    </CCTypography>
                    <CCTypography variant="h4" color="primary">
                      <p> {formatCommas(
                        paymentAccumulate?.paymentGetAccumulateAmount
                          .accumulatePaid
                      )}</p>
                    </CCTypography>
                  </Grid>

                  <Box className={classes.divided} />
                  <Grid container className={classes.pricetag}>
                    <CCTypography className={classes.color__grey}>
                      <p>{translate(intl, "payment.unpaid")}</p>
                      {/* Unpaid ($) */}
                    </CCTypography>
                    <CCTypography
                      variant="h4"
                      className={clsx({
                        [classes.color__red]:
                          paymentAccumulate?.paymentGetAccumulateAmount
                            .accumulateUnPaid !== 0
                      })}
                    >
                      <p>{formatCommas(
                        paymentAccumulate?.paymentGetAccumulateAmount
                          .accumulateUnPaid
                      )}</p>
                    </CCTypography>
                  </Grid>
                </Grid>
                {/*{!loading && (*/}
                {
                  <CVTable
                    heads={heads}
                    contents={paymentHistoryData?.paymentHistoryList?.items}
                    className={classes.table}
                  />
                }
              </Grid>
            ))}
        </Grid>
      </Grid>
      {Boolean(openPaymentDialog) && (
        <PaymentDialog
          labSetting={labSetting}
          open={Boolean(openPaymentDialog)}
          onClose={() => {
            setOpenPaymentDialog(false);
          }}
          labOrder={labOrder.staff}
          value={openPaymentDialog}
          refetch={refetchPaymentHistoryAndAccummulate}
        />
      )}
      {Boolean(openEditNoteDialog) && (
        <EditNoteDialog
          open={Boolean(openEditNoteDialog)}
          onClose={() => {
            setOpenEditNoteDialog(false);
          }}
          value={openEditNoteDialog.note}
          onChange={val => {
            updatePaymentHistoryNote({
              variables: {
                id: openEditNoteDialog.id,
                note: val
              }
            });
          }}
        />
      )}
      {anchorEl && openAnchorEl && (
        <PaymentDetailDialog
          open={openAnchorEl}
          onClose={() => {
            setOpenAnchorEl(false);
          }}
          labOrder={labOrder}
          id={anchorEl.id}
        />
      )}
      {Boolean(openBill) && (
        <BillPaper
          open={Boolean(openBill)}
          note={openBill.note}
          id={openBill.id}
          ids={[openBill.id]}
          clinic={openBill.clinic.id}
          onClose={() => {
            setOpenBill(false);
          }}
        />
      )}
    </Box>
  );
};

Payment.defaultProps = {};

Payment.propTypes = {};

export default Payment;
