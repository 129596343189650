import React from "react";

const HeightIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 16.017L12.003 21 7 16.022l1.444-1.427 2.568 2.554V6.85L8.444 9.405 7 7.978 12.003 3 17 7.983l-1.418 1.41-2.548-2.535v10.283l2.548-2.535L17 16.017z"
    />
  </svg>
);
export default HeightIcon;
