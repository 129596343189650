import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import produce from "immer";
import React, { useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  CCDialogSimpleAddMod,
  CCDialogSimpleDel,
  CCIconButton,
  CCTooltip
} from "../";
import {
  RightIcon,
  SearchIcon,
  SelectPersonIcon,
  SettingIcon,
  SignoutIcon,
  SpreadoverIcon,
  StatisticsIcon,
  UpIcon,
  WarningIcon
} from "../../themes/common/icons";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  },
  container: {
    flexDirection: "column",
    overflow: "hidden"
  },
  selected: {
    background: "green"
  },
  text: {
    opacity: "0.87",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.71",
    letterSpacing: "-0.13px",
    color: "#000000",
    width: "100%",
    height: "36px",
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: "8px"
  }
}));
/**
 * @deprecated
 */
const CCTrees = props => {
  const { treeData, onChange, fold, spread, onClickItem } = props;
  const classes = useStyles();

  const [id, setId] = useState(null);
  const [el, setEl] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [text, setText] = useState(null);
  const [leafNode, setLeafNode] = useState(null);

  const rootRef = useRef(null);

  const displayTree = (data, pd) => {
    const table = [];
    for (let i = 0; i < data.length; i++) {
      table.push(
        <Box key={data[i].id}>
          <Typography
            component="div"
            onClick={makeBackground}
            className={classes.text}
            id={data[i].id}
            name={data[i].name}
            style={{ paddingLeft: pd }}
            role={data[i].hasOwnProperty("item") ? "leaf" : "parent"}
          >
            {!data[i].hasOwnProperty("item") && data[i].open && (
              <UpIcon className={classes.icon} />
            )}
            {!data[i].hasOwnProperty("item") && !data[i].open && (
              <WarningIcon className={classes.icon} />
            )}
            {data[i].hasOwnProperty("item") && (
              <RightIcon className={classes.icon} />
            )}
            {data[i].name}
          </Typography>
          <Box>
            {!data[i].hasOwnProperty("item") &&
              displayTree(data[i].items, pd + 10)}
          </Box>
        </Box>
      );
    }

    return table;
  };

  // 트리 클릭 시 실행되는 함수
  const makeBackground = e => {
    const Target = e.currentTarget;
    const Parent = Target.parentNode;
    const Id = Target.getAttribute("id");
    const Text = Target.getAttribute("name");

    let isLeaf = false;

    // tree color setting
    if (el !== null && el.target !== Target) {
      el.target.style.background = "none";
    }
    Target.style.background = "yellow";

    // tree fold, spread
    if (Parent.childNodes[1].style.display === "none") {
      Parent.childNodes[1].style.display = "block";
    } else {
      Parent.childNodes[1].style.display = "none";
    }

    // leaf node 검사
    if (e.target.getAttribute("role") === "leaf") {
      isLeaf = true;
    }

    // leaf node 가 아닌 경우
    if (!isLeaf) {
      const newData = produce(treeData, draftState => {
        changeOpen(draftState, Id, false);
      });
      setLeafNode(false);
      onChange(newData);
    }
    // leaf node 인 경우
    else {
      setLeafNode(true);
      displayItem(treeData, Id, false);
    }

    setId(Id);
    setEl({ target: Target });
    setText(Text);
  };

  // 폴더인 경우 open toggle 함수
  const changeOpen = (data, id, isRecursive) => {
    if (isRecursive) return;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        data[i].open = !data[i].open;
        return changeOpen(null, null, true);
      }
      if (!data[i].hasOwnProperty("item")) {
        changeOpen(data[i].items, id, false);
      }
    }
  };

  // leaf node 인 경우 항목 출력 함수
  const displayItem = (data, id, isRecursive) => {
    if (isRecursive) return;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        onClickItem(data[i].item);
        return displayItem(null, null, true);
      }
      if (!data[i].hasOwnProperty("item")) {
        displayItem(data[i].items, id, false);
      }
    }
  };

  const openUpdateDialog = () => {
    if (id === null) {
      alert("수정할 폴더 및 파일 위치를 선택하세요.");
    } else {
      if (id === "root") {
        alert("수정 불가");
      } else {
        setOpenUpdate(true);
      }
    }
  };

  const openDeleteDialog = () => {
    if (id === null) {
      alert("삭제할 폴더 및 파일 위치를 선택하세요.");
    } else {
      if (id === "root") {
        alert("삭제 불가");
      } else {
        setOpenDelete(true);
      }
    }
  };

  const openAddCategoryDialog = () => {
    if (id === null) {
      alert("추가할 위치를 선택하세요");
    } else {
      if (leafNode) {
        alert("항목에는 추가할 수 없습니다.");
      } else {
        setOpenAddCategory(true);
      }
    }
  };

  const openAddItemDialog = () => {
    if (id === null) {
      alert("추가할 위치를 선택하세요");
    } else {
      if (leafNode) {
        alert("항목에는 추가할 수 없습니다.");
      } else {
        setOpenAddItem(true);
      }
    }
  };

  const closeUpdateDialog = () => {
    setOpenUpdate(false);
  };

  const closeDeleteDialog = () => {
    setOpenDelete(false);
  };

  const closeAddCategoryDialog = () => {
    setOpenAddCategory(false);
  };

  const closeAddItemDialog = () => {
    setOpenAddItem(false);
  };

  const foldTree = () => {
    const Tree = rootRef.current.childNodes;
    for (let i = 0; i < Tree.length; i++) {
      Tree[i].childNodes[1].style.display = "none";
    }
  };

  const openTree = () => {
    const Tree = rootRef.current.childNodes;
    for (let i = 0; i < Tree.length; i++) {
      Tree[i].childNodes[1].style.display = "block";
    }
  };

  const rootClick = e => {
    const Target = e.target;

    Target.style.background = "yellow";

    if (el !== null) {
      if (Target !== el.target) {
        el.target.style.background = "none";
      }
    }
    setEl({ target: Target });
    setLeafNode(null);
    setId("root");
  };

  const updateTreeData = (data, newValue, isRecursive) => {
    if (isRecursive) return;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        data[i].name = newValue;
        return updateTreeData(null, null, true);
      }
      if (!data[i].hasOwnProperty("item")) {
        updateTreeData(data[i].items, newValue, false);
      }
    }
  };

  const deleteTreeData = (data, isRecursive) => {
    if (isRecursive) return;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        data.splice(i, 1);
        setId(null);
        setEl(null);
        setText(null);
        setLeafNode(null);
        return deleteTreeData(null, true);
      }
      if (!data[i].hasOwnProperty("item")) {
        deleteTreeData(data[i].items, false);
      }
    }
  };

  const addCategoryTreeData = (data, newValue, isRecursive) => {
    if (isRecursive) return;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        data[i].items.push({
          name: newValue,
          items: [],
          id: uuid(),
          open: false
        });
        return addCategoryTreeData(null, null, true);
      }
      if (!data[i].hasOwnProperty("item")) {
        addCategoryTreeData(data[i].items, newValue, false);
      }
    }
  };

  const addItemTreeData = (data, newValue, isRecursive) => {
    if (isRecursive) return;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        data[i].items.push({
          name: newValue,
          item: {},
          id: uuid(),
          open: true
        });
        return addItemTreeData(null, null, true);
      }
      if (!data[i].hasOwnProperty("item")) {
        addItemTreeData(data[i].items, newValue, false);
      }
    }
  };

  const updateSave = newValue => {
    const newData = produce(treeData, draftState => {
      updateTreeData(draftState, newValue, false);
    });
    onChange(newData);
    closeUpdateDialog();
  };

  const delTreeSave = () => {
    const newData = produce(treeData, draftState => {
      deleteTreeData(draftState, false);
    });
    onChange(newData);
    closeDeleteDialog();
  };

  const addCategorySave = newValue => {
    let newData;

    // 최상단에서 추가하는 경우
    if (id === "root") {
      newData = produce(treeData, draftState => {
        draftState.push({
          name: newValue,
          items: [],
          id: uuid(),
          open: false
        });
      });
    }
    // 그 외
    else {
      newData = produce(treeData, draftState => {
        addCategoryTreeData(draftState, newValue, false);
      });
    }
    onChange(newData);
    closeAddCategoryDialog();
  };

  const addItemSave = newValue => {
    let newData;

    // 최산단에서 추가하는 경우
    if (id === "root") {
      newData = produce(treeData, draftState => {
        draftState.push({
          name: newValue,
          item: {},
          id: uuid(),
          open: false
        });
      });
    } else {
      newData = produce(treeData, draftState => {
        addItemTreeData(draftState, newValue, false);
      });
    }

    onChange(newData);
    closeAddItemDialog();
  };

  return (
    <Box>
      <Grid container className={classes.container}>
        <Grid item>
          <CCTooltip title={<Box>수정</Box>}>
            <CCIconButton onClick={openUpdateDialog}>
              <SearchIcon />
            </CCIconButton>
          </CCTooltip>
          <CCTooltip title={<Box>삭제</Box>}>
            <CCIconButton onClick={openDeleteDialog}>
              <SelectPersonIcon />
            </CCIconButton>
          </CCTooltip>
          <CCTooltip title={<Box>카테고리 추가</Box>}>
            <CCIconButton onClick={openAddCategoryDialog}>
              <SettingIcon />
            </CCIconButton>
          </CCTooltip>
          <CCTooltip title={<Box>항목 추가</Box>}>
            <CCIconButton onClick={openAddItemDialog}>
              <SignoutIcon />
            </CCIconButton>
          </CCTooltip>
          {fold && (
            <CCTooltip title={<Box>접기</Box>}>
              <CCIconButton onClick={foldTree}>
                <SpreadoverIcon />
              </CCIconButton>
            </CCTooltip>
          )}
          {spread && (
            <CCTooltip title={<Box>열기</Box>}>
              <CCIconButton onClick={openTree}>
                <StatisticsIcon />
              </CCIconButton>
            </CCTooltip>
          )}
        </Grid>
        <Grid item onClick={rootClick}>
          부모
        </Grid>
        <Grid item ref={rootRef} id={"tree"}>
          {displayTree(treeData, 10)}
        </Grid>
      </Grid>

      {openUpdate && (
        <CCDialogSimpleAddMod
          open={openUpdate}
          onClose={closeUpdateDialog}
          title={"수정"}
          value={text}
          onSave={updateSave}
          onCancel={closeUpdateDialog}
        />
      )}
      {openDelete && (
        <CCDialogSimpleDel
          open={openDelete}
          onClose={closeDeleteDialog}
          onAgree={delTreeSave}
          onDisagree={closeDeleteDialog}
        />
      )}
      {openAddCategory && (
        <CCDialogSimpleAddMod
          open={openAddCategory}
          onClose={closeAddCategoryDialog}
          title={"추가"}
          onSave={addCategorySave}
          onCancel={closeAddCategoryDialog}
        />
      )}
      {openAddItem && (
        <CCDialogSimpleAddMod
          open={openAddItem}
          onClose={closeAddItemDialog}
          title={"추가"}
          onSave={addItemSave}
          onCancel={closeAddItemDialog}
        />
      )}
    </Box>
  );
};

export default CCTrees;
