import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

const OverflowTip = props => {
  const { children, className, placement, ...others } = props;

  const classes = useStyles();

  const textElementRef = useRef();

  const compareSize = useCallback(() => {
    if (children) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  }, [textElementRef, children]);

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, [compareSize]);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    [compareSize]
  );

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      title={children ? children : ""}
      interactive
      disableHoverListener={!hoverStatus}
      placement={placement ? placement : "bottom"}
    >
      <CCTypography
        ref={textElementRef}
        className={clsx(className, classes.root)}
        {...others}
      >
        {children}
      </CCTypography>
    </Tooltip>
  );
};

export default OverflowTip;
