import {
    DialogLoading,

    DialogPermissionWarn, useWritePermission
} from "components";
import React, { useState } from "react";
import { CCDialogSimple } from "styles/components";

const CVDialogSimple = props => {
  const {
    children,
    permission,
    onDeleteButton,
    loading = false,
    loadingText = "",
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const isPermission = useWritePermission(permission);

  const handleClick = () => {
    setOpen(true);
  };

  const options = (() => {
    const opt = {};
    if (onDeleteButton) {
      if (isPermission) opt.onDeleteButton = onDeleteButton;
      else opt.onDeleteButton = handleClick;
    }
    return opt;
  })();

  if (loading) return <DialogLoading open={loading} text={loadingText} />;
  return (
    <>
      <CCDialogSimple {...options} {...others} />
      {open && (
        <DialogPermissionWarn
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default CVDialogSimple;
