import React from "react";

const DownIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M7.5 9.5l5 5 5-5z" />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default DownIcon;
