import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import React from "react";
import {
  CCDialogContent,
  CCPaper,
  CCTable,
  CCTypography
} from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "640px",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    overflow: "auto"
  },
  text__header: {
    margin: "8px 16px"
  },
  table: {
    height: "calc(100% - 1px) !important",
    boxSizing: "border-box",
    overflow: "hidden"
    //width: "100%"
  },
  cell: {
    display: "flex",
    height: "36px",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const TableTab = props => {
  const { head, content, width = "100%", onSelect } = props;
  const classes = useStyles();
  const headexample = [
    {
      label: "날짜",
      key: "date",
      width: 120,
      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell}>
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "내원 환자수",
      key: "total",
      width: 150,
      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell}>
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "신환",
      key: "newPatients",
      width: 150,
      component: ({ cellData, rowData }) => {
        const percentage = Math.round((cellData / rowData.total) * 100);
        return (
          <Box className={classes.cell}>
            <CCTypography>{cellData}</CCTypography>
            <CCTypography>{`(${percentage}%)`}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "구환",
      key: "returningPatients",
      flex: 1,
      component: ({ cellData, rowData }) => {
        const percentage = Math.round((cellData / rowData.total) * 100);
        return (
          <Box className={classes.cell}>
            <CCTypography>{cellData}</CCTypography>
            <CCTypography>{`(${percentage}%)`}</CCTypography>
          </Box>
        );
      }
    }
  ];

  return (
    <CCPaper className={classes.root} style={{ width: width, boxSizing: 'border-box' }}>
      <CCDialogContent noPadding>
        <CCTable
          className={classes.table}
          heads={head ? head : headexample}
          contents={content}
          onRowClick={onSelect}
        />
      </CCDialogContent>
    </CCPaper>
  );
};

export default TableTab;
