import React from "react";

const MedicinepayIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M17.034 12.034c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm2.278-8.279c2.046 2.046 2.303 5.21.773 7.538-.614-.327-1.286-.558-1.996-.674 1.368-1.571 1.304-3.954-.191-5.45-1.511-1.51-3.927-1.56-5.498-.15l-.16.15-3.535 3.536 3.732 3.732c-1.176 1.177-1.903 2.802-1.903 4.597 0 1.102.274 2.14.758 3.05-2.327 1.531-5.49 1.274-7.537-.772-2.279-2.28-2.339-5.946-.18-8.298l.18-.188 7.071-7.07c2.341-2.341 6.145-2.341 8.486 0zm-1.573 9.279h-1.335v.965c-.86.185-1.555.745-1.555 1.605 0 1.025.85 1.535 2.09 1.835 1.115.265 1.335.655 1.335 1.07 0 .305-.22.795-1.2.795-.915 0-1.275-.41-1.325-.935h-.98c.055.97.78 1.52 1.635 1.7v.965h1.335v-.955c.87-.165 1.555-.665 1.56-1.58 0-1.26-1.08-1.695-2.09-1.955-1.01-.26-1.335-.535-1.335-.955 0-.48.45-.82 1.2-.82.79 0 1.085.38 1.11.935h.98c-.03-.765-.495-1.47-1.425-1.695v-.975z"
            transform="translate(-4809 -192) translate(4809 192)"
          />
          <path
            d="M0 0H24V24H0z"
            transform="translate(-4809 -192) translate(4809 192)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default MedicinepayIcon;
