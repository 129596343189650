import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
  height: { height: "100%" },
  root: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: theme.palette.sub.mainHex
    // zIndex: props => props?.zIndex || 9999
  },
  container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }
}));

const DisableTable = props => {
  const { open, msg, zIndex } = props;
  const classes = useStyles({ zIndex: zIndex });

  return (
    <>
      {open && (
        <Box className={clsx(classes.root, classes.height)}>
          <Grid container className={clsx(classes.container, classes.height)}>
            <Grid item>
              <Box>{msg && msg}</Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

DisableTable.defaultProps = {
  noAnimation: false
};

export default DisableTable;
