import { DialogPermissionWarn } from 'components';
import React, { forwardRef, useState } from 'react';
import CCMenuItem from 'styles/src/components/CCMenu/Component/CCMenuItem';

const CVMenuItem = forwardRef((props, ref) => {
  const { children, permission, onClick, ...others } = props;
  const [open, setOpen] = useState(false);
  const isPermission = true;

  const handleChange = () => {
    setOpen(true);
  };

  return (
    <>
      <CCMenuItem
        ref={ref}
        onClick={isPermission ? onClick : handleChange}
        {...others}
      >
        {children}
      </CCMenuItem>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default CVMenuItem;
