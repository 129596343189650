/*eslint react-hooks/exhaustive-deps: 0*/
import { makeStyles } from "@material-ui/styles";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { CCTypography } from "styles/components";
import { BringInIcon, CheckIcon } from "styles/icons";
import { useIntl } from "utils/language";
import { FormProvider, useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getTimestamp } from "utils/datetime";
import { DBVendorContext } from "context/DBContext/components";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DialogWarningMessage } from "components";
import {
  CCButton,
  CCDialog,
  CCDialogAlert,
  CCDialogContent,
  CCDialogTitle,
  CCIconButton
} from "styles/src/components";
import MenuItem from "@material-ui/core/MenuItem";
import { CVTextField, ErrorTooltip } from "components";
import axios from "axios";
import EstimateSelectTable from "./Component/EstimateSelectTable";
import useQuoteValidation from "validations/useQuoteValidation";
import { getObject, Target } from "utils/s3";
import { ImageUploadButton } from "views/Normal/InventoryManagement/Component/utils";
import { ImageResults } from "../EstimateResults/Component/EstimateDetailDialog/components";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 1000,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  head__field: {
    width: 300,
    display: "flex",
    flexWrap: "wrap",
    // gap: 5,
    marginTop: -10
  },
  head__search__field: {
    // marginRight: "30px",
    width: "100%"
  },
  background: {
    background: theme.palette.background.default
  },
  image__button: {
    marginLeft: 10
  },
  typo: {
    marginLeft: 15,
    color: theme.palette.disabled.rgba
  }
}));

const initData = {
  hospital: "",
  important: false,
  unit: 1,
  currentCnt: 0,
  optCnt: 0,
  alert: false,
  editDate: getTimestamp(),
  editorID: "",
  canExpire: true,
  expire: {
    expireCount: 0,
    expireDate: getTimestamp()
  },
  binCnt: 0,
  barcode: false,
  isAppropriate: false,
  images: []
};

const EstimateCreateDialog = props => {
  const intl = useIntl();

  const { open, onClose } = props;

  const classes = useStyles();
  const selectRef = useRef();

  const [alertOpen, setAlertOpen] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);

  const [cancelDialog, setCancelDialog] = useState(false);
  // const { dispatchDBVendor } = useContext(DBVendorContext);

  const [products, setProducts] = useState([]);
  const [modify, setModify] = useState(false);

  const selectedIndexRef = useRef(null);
  const [vendor, setVendor] = useState([]);
  const [selected, setSelected] = useState(vendor[0]);
  const [files, setFiles] = useState([]);
  const [pam, setPam] = useState(true);

  const { schema } = useQuoteValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const { getValues, control, errors } = methods;

  const watchList = useWatch({
    control: control,
    defaultValue: files,
    name: ["images"]
  });

  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);

  const createQuote = async (e, products, files) => {
    try {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_PAM_URL}/api/v1/order`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        },
        data: {
          type: "Quote",
          materialId: Number(e?.partner?.id),
          requestMessage: e?.message,
          items:
            products?.length > 0
              ? pam
                ? products.map(e => {
                    return {
                      category1: e.category1,
                      category2: e?.category2,
                      name: e?.name,
                      unit1: e?.unit1,
                      unit2: e?.unit2
                    };
                  })
                : products.map(e => {
                    return {
                      category1: e.major,
                      category2: e?.minor,
                      name: e?.productName,
                      unit1: e?.unit1,
                      unit2: e?.unit2 !== "EA" ? e?.unit2 : null
                      // unit1: e?.storage?.unit,
                      // unit2:
                      //   e?.storage?.unit === "EA" ? null : e?.storage?.binCnt
                    };
                  })
              : [],
          images: files?.length > 0 ? files : []
        }
      })
        .then(async response => {
          setVendor(response?.data);
        })
        .catch(err => {
          console.log("ERROR >>>", err);
        });

      // dispatchDBVendor();
      onClose();
    } catch (e) {
      console.error(e);

      onClose();
      // setError(e);
    }
    // setLoading(false);
  };

  const onSave = useCallback(
    async e => {
      await createQuote(e, products, files);
    },
    [products, files]
  );

  const onCreate = () => {
    methods.handleSubmit(onSave, errors => {
      console.error(errors);
    })();
  };

  const onClosure = () => {
    if (Object.keys(methods.formState.dirtyFields)?.length > 0 || selectRef) {
      setCancelDialog({
        title: intl.formatMessage(
          { id: "confirmCancelwritingInfo" },
          { br: () => <br /> }
        ),
        onClose: () => {
          setCancelDialog(null);
        },
        onDisagree: () => {
          setCancelDialog(null);
        },
        onAgree: () => {
          onClose && onClose();
        }
      });
    } else {
      onClose && onClose();
    }
  };

  const fetchVendor = async () => {
    try {
      // setError(null);
      // setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_PAM_URL
        }/api/v1/clinic/${sessionStorage.getItem("clinicId")}/material`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(async response => {
          setVendor(response?.data);
        })

        .catch(err => {});
    } catch (e) {
      // setError(e);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchVendor();
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <CCDialog open={open} className={classes.root} onClose={onClose}>
          <CCDialogTitle onCloseButton={onClosure}>
            <Grid item>
              <Box display="flex" flexDirection="row">
                <CCTypography variant="h3">
                  {intl.formatMessage({ id: "estimateRequest" })}
                </CCTypography>
              </Box>
            </Grid>
          </CCDialogTitle>
          <CCDialogContent
            className={classes.background}
            noPadding={false}
            style={{ overflow: "hidden" }}
          >
            <Box>
              <Controller
                name={"partner"}
                control={control}
                defaultValue={getValues("partner") === "undefined" || vendor[0]}
                render={props => {
                  const { ref, value, onChange, onBlur } = props;
                  return (
                    <ErrorTooltip
                      open={Boolean(errors?.partner?.message)}
                      errorMsg={errors?.partner?.message}
                      placement={"top-start"}
                    >
                      <CVTextField
                        inputRef={ref}
                        control={control}
                        name={"partner"}
                        required={true}
                        className={classes.head__field}
                        variant={"outlined"}
                        margin={"dense"}
                        label={intl.formatMessage({ id: "partner" })}
                        select
                        value={value}
                        defaultValue={vendor[0]}
                        disabled={vendor?.length === 0}
                        onChange={e => {
                          onChange(e?.target?.value);
                          setSelected(e?.target?.value);
                        }}
                        onBlur={onBlur}
                      >
                        {Boolean(vendor?.length > 0) &&
                          vendor?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                      </CVTextField>
                    </ErrorTooltip>
                  );
                }}
              />
            </Box>
            <Box style={{ marginBottom: "100px", marginLeft: 7 }}>
              <EstimateSelectTable
                name={"productList"}
                value={products}
                onChange={value => {
                  setProducts(value);
                  if (!modify) {
                    setModify(true);
                  }
                }}
                partner={selected}
                ref={selectedIndexRef}
                pam={pam}
                changePam={value => setPam(value)}
              />
            </Box>
            <Box>
              <Controller
                name={"message"}
                control={control}
                defaultValue={getValues("message") || null}
                render={props => {
                  const { ref, value, onChange, onBlur } = props;
                  return (
                    <CVTextField
                      name={"message"}
                      className={classes.head__search__field}
                      permission={false}
                      variant={"outlined"}
                      margin={"dense"}
                      label={intl.formatMessage({ id: "requestList" })}
                      placeholder={intl.formatMessage(
                        { id: "enterRequestDescription" },
                        { br: () => <br /> }
                      )}
                      inputRef={ref}
                      onChange={e => onChange(e.target.value)}
                      onBlur={onBlur}
                      value={value}
                      rows="4"
                      multiline={true}
                    />
                  );
                }}
              />
            </Box>
            <Box>
              <Controller
                name={"images"}
                control={control}
                defaultValue={watchList ? watchList : []}
                render={props => {
                  const { ref: formRef, value, onChange, onBlur } = props;

                  return (
                    <ErrorTooltip
                      open={Boolean(errors?.images?.message)}
                      errorMsg={errors?.images?.message}
                      placement={"top-start"}
                    >
                      <ImageUploadButton
                        name={"images"}
                        inputRef={formRef}
                        onChange={e => {
                          try {
                            //TODO : inventory 로 바꾸기
                            getObject(
                              Target.PHOTO,
                              localStorage.getItem("hospitalId"),
                              e?.content?.object,
                              60 * 60 * 24 * 7
                            ).then(res => {
                              console.log("RES>>>", res);
                              onChange(
                                value?.length > 0 ? value.concat(res) : res
                              );
                              setFiles(prev => (prev ? prev.concat(res) : []));
                            });
                          } catch (e) {
                            console.error(e);
                            setFiles([]);
                          }
                        }}
                        onDelete={e => {
                          try {
                            getObject(
                              "photo",
                              localStorage.getItem("hospitalId"),
                              e?.content?.object,
                              60 * 60 * 24 * 7
                            ).then(res => {
                              onChange(
                                value?.length > 0 ? value.concat(res) : res
                              );
                            });
                          } catch (e) {
                            console.error(e);
                          }
                        }}
                        target={Target.PHOTO}
                        filetype={"photo"}
                        value={value?.id || ""}
                        title={intl.formatMessage({ id: "attachImage" })}
                        disablePreview={true}
                        allowExtension={["PNG", "JPG", "JPEG"]}
                        accept={["image/jpg", "image/jpeg", "image/png"]}
                        onBlur={onBlur}
                        onError={errors?.images}
                      />
                    </ErrorTooltip>
                  );
                }}
              />
              <CCTypography variant={"body"} className={classes.typo}>
                {intl.formatMessage({ id: "attachableFileType" })}
              </CCTypography>

              {files?.length > 0 && (
                <Grid container wrap={"nowrap"} style={{ marginTop: 60 }}>
                  <ImageResults
                    images={
                      files?.length > 0
                        ? files.map(e => {
                            return { url: e };
                          })
                        : []
                    }
                  />
                </Grid>
              )}
            </Box>
          </CCDialogContent>

          <Box
            style={{
              overflowY: "auto",
              padding: 10,
              display: "flex",
              justifyContent: "flex-end"
            }}
            marginLeft={"auto"}
            className={classes.icon__end}
          >
            <>
              <CCButton
                className={classes.cancel__icon}
                variant={"text"}
                color={"normal"}
                onClick={onClosure}
              >
                {intl.formatMessage({ id: "cancel" })}
              </CCButton>
              <CCButton
                variant={"contained"}
                color={"primary"}
                disabled={products?.length === 0 || !products}
                startIcon={<CheckIcon />}
                onClick={() => {
                  onCreate();
                }}
              >
                {intl.formatMessage({ id: "estimateRequest" })}
              </CCButton>
              <CCDialogAlert
                contents={
                  <Box className={classes.root}>
                    <CCTypography>
                      {intl.formatMessage({ id: "registerProduct" })}
                      <CCIconButton color={"secondary"} variant={"contained"}>
                        <BringInIcon />
                      </CCIconButton>
                      {intl.formatMessage({ id: "clickBtn" })}
                    </CCTypography>
                  </Box>
                }
                open={alertOpen}
                onClose={setCloseAlert}
                onAgree={setCloseAlert}
                onDisagree={setCloseAlert}
              />
              <CCDialogAlert
                contents={
                  <CCTypography>
                    {intl.formatMessage({ id: "registerProduct" })}
                    <CCIconButton color={"secondary"} variant={"contained"}>
                      <BringInIcon />
                    </CCIconButton>
                    {intl.formatMessage({ id: "registerInputContent" })}
                  </CCTypography>
                }
                open={saveAlertOpen}
                onClose={setCloseSaveAlert}
                onAgree={setCloseSaveAlert}
                onDisagree={setCloseSaveAlert}
              />
            </>
          </Box>
        </CCDialog>
      </FormProvider>
      <DialogWarningMessage open={Boolean(cancelDialog)} {...cancelDialog} />
    </>
  );
};

export default EstimateCreateDialog;
