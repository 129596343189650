import { DialogPermissionWarn, TeethSelector } from "components";
import React, { useState } from "react";

// onChange : selects state 변환
const TeethSelectors = props => {
  const {
    width,
    height,
    layout,
    manual = false,
    selects,
    hiddens = [],
    labels = [],
    buttons,
    vborder = 0,
    hborder = 0,
    onChange,
    background,
    disabled = true,
    // permission
  } = props;
  const isPermission = true;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const onSelect = indices => {
    const addTeeth = indices.filter(d => selects.indexOf(d) === -1);
    const delTeeth = [];

    for (let i = 0; i < addTeeth.length; i++) {
      const teeth = addTeeth[i];

      if (indices.includes(teeth)) {
        const item = teeth < 51 ? teeth + 40 : teeth - 40;
        if (teeth >= 51) {
          if (!selects.includes(teeth) && !selects.includes(item)) {
            continue;
          }
        }
        if (indices.includes(item)) {
          delTeeth.push(item);
        }
      }
    }

    const newTeeth = indices.filter(d => !delTeeth.includes(d));

    onChange(newTeeth);
  };

  return (
    <>
      <TeethSelector
        width={width}
        height={height || (layout === "normal" ? 120 : 60)}
        layout={layout}
        manual={manual}
        hborder={hborder}
        vborder={vborder}
        sources={selects}
        hiddens={hiddens}
        buttons={buttons}
        labels={labels}
        // onSelect={onSelect}
        onSelect={isPermission ? onSelect : handleClick}
        background={background}
        disabled={disabled}
      />
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default TeethSelectors;
