// import { useApolloClient } from "@apollo/client";
import { AppContext } from "context";
import { CREATE_PRODUCT, CREATE_PRODUCT_LOG } from "queries/product";
import { useContext } from "react";
import { getTimestamp } from "utils/datetime";

const useCreateProduct = (client) => {
  // const client = useApolloClient();
  const { appContext } = useContext(AppContext);

  const createProduct = async e => {
    return client
      .mutate({
        mutation: CREATE_PRODUCT,
        variables: {
          input: {
            def: "1",
            major: Boolean(e?.selectedList) ? e?.selectedList?.major : e?.major,
            minor: Boolean(e?.selectedList) ? e?.selectedList?.minor : e?.minor,
            productName: Boolean(e?.selectedList)
              ? e?.selectedList?.productName
              : e?.productName,
            manufacture: Boolean(e?.selectedList?.manufacture)
              ? e?.selectedList?.manufacture
              : e?.manufacture,
            storage: {
              hospital: localStorage.getItem("hospitalId"),
              important: false,
              alert: Boolean(e?.optCnt) && Boolean(e?.alert === "2"),
              barcode: e?.barcode,
              binCnt: e?.binCnt,
              canExpire: e?.canExpire === "2",
              currentCnt: Number(e?.currentCnt),
              optCnt: Number(e?.optCnt),
              unit: Boolean(e?.unit === "2") ? "BIN" : "EA",
              expire: Boolean(e?.canExpire && e?.canExpire === '2' && e?.expireList?.length > 0)
                ? e?.expireList.map(item => {
                  return {
                    expireDate: Boolean(typeof item?.expireDate === "number")
                      ? item?.expireDate
                      : item?.expireDate?.unix(),
                    expireCount: Number(item?.expireCount)
                  };
                })
                : [],
              editDate: getTimestamp(),
              editorID: appContext?.staff?.id,
              isAppropriate: Boolean(e?.currentCnt >= e?.optCnt)
            }
          }
        }
      })
      .then(async r => {
        const _product = r?.data?.createProduct;

        await client.mutate({
          mutation: CREATE_PRODUCT_LOG,
          variables: {
            input: {
              def: "1",
              binCnt: _product?.storage?.binCnt,
              major: _product?.major,
              minor: _product?.minor,
              productName: _product?.productName,
              productID: _product?.productID,
              manufacture: _product?.manufacture,
              unit: _product?.storage?.unit,
              previousAmount: 0,
              changedAmount: Number(_product?.storage?.currentCnt),
              changedCnt: Number(_product?.storage?.currentCnt),
              detail: "registerProduct",
              inOut: "inventoryReceiving",
              expire: _product?.storage?.expire,
              staffName: appContext?.staff?.id
            }
          }
        });
        return _product
      })
  }



  return { createProduct };
};

export default useCreateProduct;
