import React from "react";

const NumbertenIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M9.334 17.09V7.123h-.212L5 8.6v1.606l2.358-.731v7.615h1.976zm6.576.137c1.098 0 1.932-.365 2.502-1.094.57-.73.855-1.782.855-3.158v-1.832c-.014-1.336-.308-2.36-.882-3.073C17.81 7.357 16.98 7 15.896 7c-1.084 0-1.915.36-2.491 1.08-.577.72-.865 1.775-.865 3.165v1.832c.014 1.335.308 2.36.882 3.076.574.716 1.404 1.074 2.488 1.074zm0-1.593c-.487 0-.842-.197-1.063-.592-.22-.394-.331-.996-.331-1.808v-2.42c.013-.76.13-1.32.352-1.678.22-.358.564-.536 1.028-.536.479 0 .831.188 1.057.564.225.376.338.976.338 1.8v2.366c-.01.78-.12 1.358-.335 1.736-.214.379-.563.568-1.046.568z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default NumbertenIcon;
