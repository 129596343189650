import { AppBar, Grid, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { FullSizeInfo } from "./components";


const useStyles = makeStyles(theme => ({
  height: { height: "100%" },
  root: {
    height: theme.topbar.height,
    backgroundColor: "white",
    boxShadow: "none",
    borderBottom: "2px solid #646d77",
    color: "#000000",
    minWidth: 500,
    left: 0
  },
  toolbar: {
    height: "100%",
    padding: 0
  },
  container: {
    height: "100%"
  },
  left__item: {
    width: 80,
    height: "100%"
  },
  left__item__container: {
    justifyContent: "center",
    alignContent: "center",
    height: "100%"
  },
  left__item__container__icon: {
    color: "#0097a7",
    cursor: "pointer"
  },
  middle__item: {
    // flexGrow: 1,
    alignSelf: "center",
    margin: "0px 8px",
    height: "100%"
  },
  middle__item__container__item: {
    alignSelf: "center"
  },
  logo: {
    marginLeft: 45
  },
  right__item: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    flexGrow: 1
  }
}));

const Topbar = props => {
  const { onOpen } = props;
  const classes = useStyles();

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.container}>
          <Grid item className={classes.left__item}>
            <Grid container className={classes.left__item__container}>
              <MenuIcon
                className={classes.left__item__container__icon}
                onClick={onOpen}
              />
            </Grid>
          </Grid>

          <Grid item className={classes.right__item}>
            <Grid className={classes.logo}>
              <img
                src={process.env.PUBLIC_URL + "/clever-lab.png"}
                alt="logo"
              />
            </Grid>

            <FullSizeInfo />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
