import React from "react";

const FoldallIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M18 2c2.21 0 4 1.79 4 4v12c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4V6c0-2.21 1.79-4 4-4h12zm-5.995 8L7 15.355 8.549 17l3.456-3.698L15.462 17 17 15.355 12.005 10zM12 5l-5 5.355L8.547 12 12 8.302 15.453 12 17 10.355 12 5z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default FoldallIcon;
