import gql from "graphql-tag";

const UPDATE_SMTP_SETTING = gql`
  mutation CreateSmtpSetting($labId: String!, $smtpSetting: SmtpSettingInput) {
    createSmtpSetting(
      input: {
        labId: $labId
        smtpSetting: $smtpSetting
        category: "smtpSetting"
      }
    ) {
      code
    }
  }
`
export { UPDATE_SMTP_SETTING };

