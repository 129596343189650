import { Box, FormControlLabel, Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  CVRadioGroup, DialogPermissionWarn, enqueueToast, translate, useWritePermission
} from "components";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogActions,
  CCDialogContent,
  CCDialogTitle,
  CCPaper
} from "styles/components";
import { CheckIcon } from "styles/icons";
import { useLanguage } from "utils/language";
const useStyle = makeStyles(theme => ({
  root: {
    maxWidth: 625,
    minWidth: 625,
    position: "relative"
  }
}));

var langList = [
  {
    label: "English",
    value: "en"
  }
];

const LanguageSetting = () => {
  const classes = useStyle();
  const intl = useIntl();
  const [language, setLanguage] = useLanguage();
  const [langState, setLangState] = useState(language);
  const { enqueueSnackbar } = useSnackbar();
  const settingPermission = "languagesOtherSetting";
  const isPermission = useWritePermission(settingPermission);
  // value
  const [errorPermission, setErrorPermission] = useState(false);

  // select language list by region
  if (process.env.REACT_APP_LANGUAGE_REGION) {
    const getLangs = () => {
      switch (process.env.REACT_APP_LANGUAGE_REGION) {
        case "vi":
          return [{
            label: "Tiếng Việt",
            value: "vi"
          }]
        case "ko":
          return [{
            label: "한국어",
            value: "ko"
          }]
        case "ru":
          return [{
            label: "Pусский",
            value: "ru"
          }]
        case "ar":
          return [{
            label: "عربى",
            value: "ar"
          }]
        default:
          return [
            {
              label: "English",
              value: "en"
            }
          ]
      }
    }
    langList = [{
      label: "English",
      value: "en"
    }].concat(getLangs())
  }
  return (
    <Box className={classes.root}>
      <CCPaper>
        <CCDialogTitle>
          {intl.formatMessage({
            id: "settings.language.title"
          })}
        </CCDialogTitle>
        <CCDialogContent>
          <CVRadioGroup
            value={langState}
            onChange={e => {
              if (!isPermission) {
                setErrorPermission(true);
                return
              }
              setLangState(e.target.value);
            }}
          >
            {langList.map(item => {
              return (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              );
            })}
          </CVRadioGroup>
        </CCDialogContent>
        <CCDialogActions>
          <CCButton
            variant="text"
            color="sub"
            onClick={() => {
              setLangState(language);
            }}
          >
            {intl.formatMessage({
              id: "cancel"
            })}
          </CCButton>
          <CCButton
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={() => {
              if (!isPermission) {
                setErrorPermission(true);
                return
              }
              setLanguage(langState);
              enqueueToast(
                enqueueSnackbar,
                translate(intl, "common.ui.saved")
              );
            }}
          >
            {intl.formatMessage({
              id: "save"
            })}
          </CCButton>
        </CCDialogActions>
      </CCPaper>
      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
    </Box>
  );
};

export default LanguageSetting;
