import React, { useMemo } from "react";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { getCustomFormat } from "utils/datetime";

const useProductValidation = () => {
  const intl = useIntl();
  const findDuplicates = arr =>
    arr.filter((item, index) => arr.indexOf(item) !== index);

  const schema = useMemo(() => {
    return yup.object({
      directInput: yup.boolean(),
      major: yup
        .string()
        .nullable()
        .when(["directInput"], {
          is: item => {
            return Boolean(item);
          },
          then: yup
            .string()
            .nullable()
            .required(
              intl.formatMessage(
                { id: "didntEnterValue" },
                { item: intl.formatMessage({ id: "majorCategory" }) },
                { br: () => <br /> }
              )
            )
        }),
      minor: yup.string().nullable(),
      productName: yup
        .string()
        .nullable()
        .when(["directInput"], {
          is: item => {
            return Boolean(item);
          },
          then: yup
            .string()
            .nullable()
            .required(
              intl.formatMessage(
                { id: "didntEnterValue" },
                { item: intl.formatMessage({ id: "productName" }) },
                { br: () => <br /> }
              )
            )
        }),
      manufacture: yup.string().nullable(),
      unit: yup.string().nullable(),
      important: yup.boolean(),
      binCnt: yup.number().when(["unit"], {
        is: item => {
          return Boolean(parseInt(item, 10) === 2);
        },
        then: yup
          .number()
          .required(intl.formatMessage(
            { id: "didntEnterValue" },
            { item: intl.formatMessage({ id: "cntforBin" }) },
            { br: () => <br /> }
          )).moreThan(0, intl.formatMessage(
            { id: "didntEnterValue" },
            { item: intl.formatMessage({ id: "cntforBin" }) },
            { br: () => <br /> }
          ))
      }),
      storage: yup.object({
        'binCnt': yup.number().when(["unit"], {
          is: item => {
            return Boolean(item === "BIN");
          },
          then: yup
            .number()
            .required(intl.formatMessage(
              { id: "didntEnterValue" },
              { item: intl.formatMessage({ id: "cntforBin" }) },
              { br: () => <br /> }
            ))
            .moreThan(0, intl.formatMessage(
              { id: "didntEnterValue" },
              { item: intl.formatMessage({ id: "cntforBin" }) },
              { br: () => <br /> }
            ))
        }),
      }).nullable().default({}),
      currentCnt: yup.number().nullable(),
      optCnt: yup.number().nullable(),
      alert: yup.string(),
      canExpire: yup.string(),
      expireList: yup.array().test("expireCntError", (value, context) => {
        const _currentCnt = Number(context?.parent?.currentCnt) || 0;
        const _storageInside = Number(context?.parent?.storage?.currentCnt) || 0;
        const _canExpire = context?.parent?.canExpire;
        if (
          Boolean(context?.parent?.expireList?.length > 0) &&
          (_canExpire === "2" || _canExpire === undefined)
        ) {
          const _expireList = context?.parent?.expireList
            ?.map(e => Number(e?.expireCount))
            .reduce((pv, cv) => pv + cv, 0);

          const _duplicateCheck = findDuplicates(
            context?.parent?.expireList?.map(e =>
              getCustomFormat(e?.expireDate, "X", "L")
            )
          );

          if (Boolean(_duplicateCheck?.length > 0)) {
            return context.createError({
              message: `${intl.formatMessage({ id: "duplicateDateAlert" })}`
            });
          }

          if (_currentCnt > 0) {
            if (_currentCnt > _expireList) {
              return context.createError({
                message: `${intl.formatMessage({
                  id: "currentStockExpireCntAlert"
                })}`
              });
            }
          } else {
            //GLQT1401
            if (_currentCnt < _expireList) {
              return context.createError({
                message: `${intl.formatMessage({
                  id: "currentStockExpireCntAlert"
                })}`
              });
            }
            //-----------------
            if (_storageInside > 0) {
              if (_storageInside > _expireList) {
                return context.createError({
                  message: `${intl.formatMessage({
                    id: "currentStockExpireCntAlert"
                  })}`
                });
              }
            }
          }
        }

        return true;
      }),
      expireListOut: yup.array().test("expireCntError", (value, context) => {
        const _currentCnt = Number(context?.parent?.currentCnt) || 0;
        console.log("expireListOut _currentCnt", _currentCnt)
        const _storageInside = Number(context?.parent?.storage?.currentCnt) || 0;
        console.log("expireListOut _storageInside", _storageInside)

        if (
          Boolean(context?.parent?.expireListOut?.length > 0)
        ) {
          const _expireOutCount = context?.parent?.expireListOut
            ?.map(e => Number(e?.count))
            .reduce((pv, cv) => pv + cv, 0);
          console.log("expireListOut _expireList", _expireOutCount)
          if (_currentCnt > 0) {
            if (_currentCnt > _expireOutCount) {
              return context.createError({
                message: `${intl.formatMessage({
                  id: "outcomingCntAlert"
                })}`
              });
            }
          } else {
            if (_storageInside > 0) {
              if (_storageInside > _expireOutCount) {
                return context.createError({
                  message: `${intl.formatMessage({
                    id: "outcomingCntAlert"
                  })}`
                });
              }
            }
          }
        }

        return true;
      }),
      selectedList: yup
        .mixed()
        .nullable()
        .when(["directInput"], {
          is: item => {
            return Boolean(!item);
          },
          then: yup
            .mixed()
            .nullable()
            .required(
              intl.formatMessage(
                { id: "noSelectProduct" },
                {},
                { br: () => <br /> }
              )
            )
        }),
      productCreate: yup.mixed()
    });
  }, [intl]);

  const schemaDetailModify = useMemo(() => {
    return yup.object({
      directInput: yup.boolean(),
      major: yup
        .string()
        .nullable()
        .when(["directInput"], {
          is: item => {
            return Boolean(item);
          },
          then: yup
            .string()
            .nullable()
            .required(
              intl.formatMessage(
                { id: "didntEnterValue" },
                { item: intl.formatMessage({ id: "majorCategory" }) },
                { br: () => <br /> }
              )
            )
        }),
      minor: yup.string().nullable(),
      productName: yup
        .string()
        .nullable()
        .when(["directInput"], {
          is: item => {
            return Boolean(item);
          },
          then: yup
            .string()
            .nullable()
            .required(
              intl.formatMessage(
                { id: "didntEnterValue" },
                { item: intl.formatMessage({ id: "productName" }) },
                { br: () => <br /> }
              )
            )
        }),
      manufacture: yup.string().nullable(),
      unit: yup.string().nullable(),
      important: yup.boolean(),
      binCnt: yup.number().when(["unit"], {
        is: item => {
          return Boolean(parseInt(item, 10) === 2);
        },
        then: yup
          .number()
          .required(intl.formatMessage(
            { id: "didntEnterValue" },
            { item: intl.formatMessage({ id: "cntforBin" }) },
            { br: () => <br /> }
          )).moreThan(0, intl.formatMessage(
            { id: "didntEnterValue" },
            { item: intl.formatMessage({ id: "cntforBin" }) },
            { br: () => <br /> }
          ))
      }),
      storage: yup.object({
        'binCnt': yup.number().when(["unit"], {
          is: item => {
            return Boolean(item === "BIN");
          },
          then: yup
            .number()
            .required(intl.formatMessage(
              { id: "didntEnterValue" },
              { item: intl.formatMessage({ id: "cntforBin" }) },
              { br: () => <br /> }
            ))
            .moreThan(0, intl.formatMessage(
              { id: "didntEnterValue" },
              { item: intl.formatMessage({ id: "cntforBin" }) },
              { br: () => <br /> }
            ))
        }),
      }).nullable().default({}),
      currentCnt: yup.number().nullable(),
      optCnt: yup.number().nullable(),
      alert: yup.string(),
      canExpire: yup.string(),
      expireList: yup.array().test("expireCntError", (value, context) => {
        const _storageInside = Number(context?.parent?.storage?.currentCnt) || 0;
        // const _currentCnt = Number(context?.parent?.currentCnt) || 0;
        const _currentCnt = _storageInside;
        const _canExpire = context?.parent?.canExpire;
        if (
          Boolean(context?.parent?.expireList?.length > 0) &&
          (_canExpire === "2" || _canExpire === undefined)
        ) {
          console.log("context?.parent?.expireList", context?.parent?.expireList);
          const _expireListCount = context?.parent?.expireList
            ?.map(e => Number(e?.expireCount))
            .reduce((pv, cv) => pv + cv, 0);
          console.log("_expireListCount", _expireListCount);
          const _duplicateCheck = findDuplicates(
            context?.parent?.expireList?.map(e =>
              getCustomFormat(e?.expireDate, "X", "L")
            )
          );

          if (Boolean(_duplicateCheck?.length > 0)) {
            return context.createError({
              message: `${intl.formatMessage({ id: "duplicateDateAlert" })}`
            });
          }

          if (_currentCnt > 0) {
            console.log("_currentCnt > 0");
            if (_currentCnt > _expireListCount) {
              console.log("_currentCnt > _expireList");
              return context.createError({
                message: `${intl.formatMessage({
                  id: "currentStockExpireCntAlert"
                })}`
              });
            }
          } else {
            //GLQT1401
            console.log("_currentCnt >= 0");
            if (_currentCnt < _expireListCount) {
              console.log("_currentCnt < _expireListCount");
              return context.createError({
                message: `${intl.formatMessage({
                  id: "currentStockExpireCntAlert"
                })}`
              });
            }
            //-----------------
            if (_storageInside > 0) {
              console.log("_storageInside > 0");
              if (_storageInside > _expireListCount) {
                console.log("_storageInside > _expireListCount");
                return context.createError({
                  message: `${intl.formatMessage({
                    id: "currentStockExpireCntAlert"
                  })}`
                });
              }
            }
          }
        }

        return true;
      }),
      expireListOut: yup.array().test("expireCntError", (value, context) => {
        const _currentCnt = Number(context?.parent?.currentCnt) || 0;
        console.log("expireListOut _currentCnt", _currentCnt)
        const _storageInside = Number(context?.parent?.storage?.currentCnt) || 0;
        console.log("expireListOut _storageInside", _storageInside)

        if (
          Boolean(context?.parent?.expireListOut?.length > 0)
        ) {
          const _expireOutCount = context?.parent?.expireListOut
            ?.map(e => Number(e?.count))
            .reduce((pv, cv) => pv + cv, 0);
          console.log("expireListOut _expireList", _expireOutCount)
          if (_currentCnt > 0) {
            if (_currentCnt > _expireOutCount) {
              return context.createError({
                message: `${intl.formatMessage({
                  id: "outcomingCntAlert"
                })}`
              });
            }
          } else {
            if (_storageInside > 0) {
              if (_storageInside > _expireOutCount) {
                return context.createError({
                  message: `${intl.formatMessage({
                    id: "outcomingCntAlert"
                  })}`
                });
              }
            }
          }
        }

        return true;
      }),
      selectedList: yup
        .mixed()
        .nullable()
        .when(["directInput"], {
          is: item => {
            return Boolean(!item);
          },
          then: yup
            .mixed()
            .nullable()
            .required(
              intl.formatMessage(
                { id: "noSelectProduct" },
                {},
                { br: () => <br /> }
              )
            )
        }),
      productCreate: yup.mixed()
    });
  }, [intl]);
  return { schema, schemaDetailModify };
};

export default useProductValidation;
