import { Paper, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { CCIconButton, CCTypography } from "../";
import { SearchIcon } from "../../themes/common/icons";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    border: "none",
    boxShadow: "none"
  },
  flexContainer2: {
    padding: props => {
      if (["large", "medium"].includes(props.size)) return "16px 8px";
      else return `0px 16px`;
    },
    height: props => {
      if (props.size === "small") return "36px";
      else if (props.size === "smallest") return "28px";
    },
    opacity: props => {
      if (["small"].includes(props.size)) return "0.7";
      return "1";
    },
    fontSize: "inherit",
    fontStretch: "inherit",
    letterSpacing: "inherit",
    lineHeight: "inherit",
    minHeight: "inherit",
    maxWidth: "inherit",
    minWidth: props => {
      if (props.size === "small") return "fit-content";
      return "none";
    },
    textTransform: "none"
  },
  color: {
    "& .Mui-selected": {
      opacity: "1",
      "& span > *": {
        color: "inherit"
      }
    }
  },
  icon: {
    position: "absolute",
    top: props => {
      if (props.size === "small") return 6;
      if (props.size === "smallest") return 2;
      if (props.size === "large") return 8;
      else return 6;
    },
    right: 8,
    margin: 0
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    paddingRight: "52px",
    minHeight: props => {
      if (props.size === "small") return "inherit";
      else if (props.size === "smallest") return "28px";
    },
    height: props => {
      if (props.size === "small") return "36px";
      else if (props.size === "smallest") return "28px";
      else return "auto";
    },
    "& .MuiTabs-scroller": {
      height: props => {
        if (props.size === "small") return "36px";
        else if (props.size === "smallest") return "28px";
        else return "auto";
      }
    },
    "& .MuiTabs-root": {
      minHeight: props => {
        if (props.size === "small") return "inherit";
        else if (props.size === "smallest") return "inherit";
      },
      height: props => {
        if (props.size === "small") return "36px";
        else if (props.size === "smallest") return "28px";
        else return "auto";
      }
    }
  },
  tab: {
    "&:hover": {
      backgroundColor: theme.palette.inactive.rgba
    }
  }
}));

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
};

const CCTabs = props => {
  const {
    className,
    size,
    tabList,
    selectedTab,
    onChangeTab,
    onSearchBtn
  } = props;

  const classes = useStyles(props);

  const handleChange = (event, newValue) => {
    onChangeTab(newValue);
  };

  const variant = size => {
    if (size === "large") return "h4";
    else if (size === "small") return "body1";
    else if (size === "smallest") return "body2";
    else return "h5";
  };

  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          className={clsx(className, classes.tabs)}
          value={selectedTab}
          classes={{ root: classes.color }}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="secondary"
        >
          {tabList.map((d, i) => (
            <Tab
              disableRipple={true}
              disableFocusRipple={true}
              classes={{ root: classes.flexContainer2 }}
              className={classes.tab}
              key={i}
              label={
                typeof d === "string" ? (
                  <CCTypography variant={variant(size)}>{d}</CCTypography>
                ) : (
                  d()
                )
              }
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
        {onSearchBtn && (
          <CCIconButton
            size={["small", "smallest"].includes(size) ? "small" : "medium"}
            color={"normal"}
            className={classes.icon}
            variant={"contained"}
            onClick={onSearchBtn}
          >
            <SearchIcon />
          </CCIconButton>
        )}
      </Paper>
    </>
  );
};

CCTabs.propTypes = {
  /** 버튼 크기를 설정 */
  size: PropTypes.oneOf(["large", "medium", "small", "smallest"]),
  /** 버튼 이름 설정 */
  tabList: PropTypes.array.isRequired,
  /** 선택된 버튼 */
  selectedTab: PropTypes.number,
  /** 탭 변경시 호출되는 함수 */
  onChangeTab: PropTypes.func,
  /** 버튼 클릭 시 호출되는 함수 */
  onSearchBtn: PropTypes.func
};

CCTabs.defaultProps = {
  size: "large",
  tabList: [
    "한글",
    "English",
    "체다치즈볼",
    "바텍네트웍스",
    "한글1",
    "English1",
    "체다치1즈볼",
    "바1텍네트웍스"
  ]
};

export default CCTabs;
