import deepmerge from "deepmerge";

const _CATEGORY_DEFAULT = {
  // DESK: { DEFAULT: { value: '00', localeKey: 'desk' } },
  ORDERS: {
    DEFAULT: { value: "01", localeKey: "orders" }
  },
  MESSENGER: { DEFAULT: { value: "02", localeKey: "messenger" } },
  CALENDAR: {
    DEFAULT: { value: "03", localeKey: "calendar" }
  },
  // GOLD_MANAGER: {
  //   DEFAULT: { value: "04", localeKey: "goldManager" }
  // },
  PAYMENT: {
    DEFAULT: { value: "05", localeKey: "menuPayment" },
    CREATE_BILL: {
      DEFAULT: {
        value: "0501", localeKey: "payment.createBill"
      }
    },
    MANAGE_BILL: {
      DEFAULT: {
        value: "0502", localeKey: "payment.billManage"
      }
    },
  },
  INVENTORY_MANAGEMENT: {
    DEFAULT: { value: "08", localeKey: "inventoryManagement" },
    INVENTORY_MANAGEMENT: {
      DEFAULT: {
        value: "0800", localeKey: "inventoryManagement"
      }
    }
  },
  STATISTICS: {
    DEFAULT: { value: "06", localeKey: "statistics" }
  },
  SETTING: {
    DEFAULT: {
      value: "07",
      localeKey: "setting"
    },
    GENERAL: {
      DEFAULT: {
        value: "0700",
        localeKey: "generalSetting"
      },
      LAB: {
        DEFAULT: {
          value: "070000",
          localeKey: "labGeneralSetting"
        }
      },
      SMTP_SETTING: {
        DEFAULT: {
          value: "070001",
          localeKey: "smtpGeneralSetting"
        }
      },
      EMPLOYEE_INFO: {
        DEFAULT: {
          value: "070002",
          localeKey: "employeeInfoGeneralSetting"
        }
      }
    },
    PARTNERS: {
      DEFAULT: {
        value: "0701",
        localeKey: "partnersSetting"
      },
      CLINICS: {
        DEFAULT: {
          value: "070100",
          localeKey: "clinicsPartnersSetting"
        }
      },
      OTHER_PARTNERS: {
        DEFAULT: {
          value: "070101",
          localeKey: "otherPartnersPartnersSetting"
        }
      },
      PRODUCT_PRICES: {
        DEFAULT: {
          value: "070102",
          localeKey: "productPricesPartnersSetting"
        }
      },
      PRODUCTION_PROCESS: {
        DEFAULT: {
          value: "070103",
          localeKey: "productionProcess"
        }
      }
    },
    OTHERS: {
      DEFAULT: {
        value: "0702",
        localeKey: "otherSetting"
      },
      AUTHORIZATION: {
        DEFAULT: {
          value: "070200",
          localeKey: "authorizationOtherSetting"
        }
      },
      ORDERS_DETAILS: {
        DEFAULT: {
          value: "070201",
          localeKey: "orderDetailsOtherSetting"
        }
      },
      LANGUAGE: {
        DEFAULT: {
          value: "070202",
          localeKey: "languagesOtherSetting"
        }
      },
      ADVANCED: {
        DEFAULT: {
          value: "070203",
          localeKey: "advancedOtherSetting"
        }
      }
    }
  }
};

const _CATEGORY_KOREA = {
  ELECTRONIC_SIGNATURE_AND_LOG: {
    ELECTRONIC_SIGNATURE: {
      DIAGNOSTIC_RADIATION_RECORDS: {
        DEFAULT: {
          value: "070001",
          localeKey: "diagnosticRadiationRecords"
        }
      },
      PERSONAL_INFORMATION: {
        DEFAULT: {
          value: "070003",
          localeKey: "personalInformation"
        }
      },
      CO_PAYMENT_MANAGEMENT: {
        DEFAULT: {
          value: "070004",
          localeKey: "coPaymentManagement"
        }
      },
      CHECK_ACCESS_LOG: {
        DEFAULT: {
          value: "070005",
          localeKey: "checkAccessLog"
        }
      }
    }
  },
  CUSTOMER_MANAGEMENT: {
    DEFAULT: {
      value: "08",
      localeKey: "customerManagement"
    }
  },
  MANAGEMENT_STATISTICS: {
    CASH_AND_BUDGET_MANAGEMENT: {
      DEFAULT: {
        value: "0901",
        localeKey: "cashAndBudgetManagement"
      },
      DAILY_BUDGET_MANAGEMENT: {
        DEFAULT: {
          value: "090101",
          localeKey: "dailyBudgetManagement"
        }
      },
      MONTHLY_BUDGET_MANAGEMENT: {
        DEFAULT: {
          value: "090102",
          localeKey: "monthlyBudgetManagement"
        }
      }
    }
  },
  PREFERENCES: {
    JX_SETTING: {
      DEFAULT: {
        value: "1004",
        localeKey: "jxSetting"
      }
    },
    PRESCRIPTION_SETTING: {
      DEFAULT: {
        value: "1006",
        localeKey: "prescriptionSetting"
      }
    },
    WIRE_SETTING: {
      DEFAULT: {
        value: "1008",
        localeKey: "wireSetting"
      }
    },
    FILE_SETTING: {
      DEFAULT: {
        value: "1010",
        localeKey: "fileSetting"
      }
    },
    FIXTURE_SETTING: {
      DEFAULT: {
        value: "1011",
        localeKey: "fixtureSetting"
      }
    },
    RADIO_GRAPH_DECODE_SETTING: {
      DEFAULT: {
        value: "1014",
        localeKey: "radioGraphDecodeSetting"
      }
    },
    UNINSURANCE_ACTION_SETTING: {
      DEFAULT: {
        value: "1016",
        localeKey: "uninsuranceActionSetting"
      }
    },
    CHECK_INSURANCE: {
      DEFAULT: {
        value: "1017",
        localeKey: "checkInsurance"
      }
    },
    SCHOOL_SETTING: {
      DEFAULT: {
        value: "1021",
        localeKey: "schoolSetting"
      }
    },
    BANK_ACCOUNT_SETTING: {
      DEFAULT: {
        value: "1022",
        localeKey: "bankAccountSetting"
      }
    },
    ORAL_EXAM_PHRASE_SETTING: {
      DEFAULT: {
        value: "1023",
        localeKey: "oralExamPhraseSetting"
      }
    },
    SMS_SETTING: {
      DEFAULT: {
        value: "1028",
        localeKey: "smsSetting"
      }
    },
    ENTRUSTED_INSTITUTION_SETTING: {
      DEFAULT: {
        value: "1029",
        localeKey: "entrustedInstitutionSetting"
      }
    },
    PARTNER_SETTING: {
      DEFAULT: {
        value: "1030",
        localeKey: "partnerSetting"
      }
    },
    KT_CALL_MANAGER_API_SETTING: {
      DEFAULT: {
        value: "1031",
        localeKey: "ktCallManagerAPISetting"
      }
    },
    POPBILL_SMS_SETTING: {
      DEFAULT: {
        value: "1032",
        localeKey: "popBillSMSSetting"
      }
    },
    DIAGNOSIS: {
      DEFAULT: {
        value: "1036",
        localeKey: "diagnosisSetting"
      }
    }
  }
};
const _CATEGORY_GLOBAL = {
  ZALO_INTEGRATION_SETTING: {
    DEFAULT: {
      value: "1035",
      localeKey: "zaloIntegrationSetting"
    }
  },
  DIAGNOSIS: {
    DEFAULT: {
      value: "1036",
      localeKey: "diagnosisSetting"
    }
  }
};

const recursive = value => {
  let list = [];

  Object.keys(value).forEach(el => {
    if (el === "DEFAULT") {
      list = list.concat(value[el].localeKey);
    } else {
      list = list.concat(recursive(value[el]));
    }
  });

  return list;
};

const permissionList = recursive({
  _CATEGORY_DEFAULT,
  ...(process.env.REACT_APP_OPMODE === "global"
    ? _CATEGORY_GLOBAL
    : _CATEGORY_KOREA)
});

let CATEGORY =
  process?.env?.REACT_APP_OPMODE === "global"
    ? deepmerge(_CATEGORY_DEFAULT, _CATEGORY_GLOBAL)
    : deepmerge(_CATEGORY_DEFAULT, _CATEGORY_KOREA);

const _PERMISSION_DEFAULT = {
  MANAGER: { value: "0", localeKey: "L1" },
  ACCOUNTANT: { value: "1", localeKey: "L2" },
  GENERAL: { value: "2", localeKey: "L3" }
};
const _PERMISSION_KOREA = {};
const _PERMISSION_GLOBAL = {};

let PERMISSION =
  process?.env?.REACT_APP_OPMODE === "global"
    ? deepmerge(_PERMISSION_DEFAULT, _PERMISSION_GLOBAL)
    : deepmerge(_PERMISSION_DEFAULT, _PERMISSION_KOREA);

function getLocaleKey(target, category) {
  for (let i in target) {
    if (!Boolean(target[i]?.DEFAULT?.value)) {
      console.log(Boolean(target[i]?.DEFAULT?.value));
    }
    if (target[i].DEFAULT.value === category) {
      return target[i]?.DEFAULT?.localeKey;
    } else {
      let _temp = { ...target[i] };
      delete _temp.DEFAULT;
      if (Object.keys(_temp).length > 0) {
        let _res = getLocaleKey(_temp, category);
        if (_res) {
          return _res;
        }
      }
    }
  }
}

const useSearchLocaleKey = props => {
  return getLocaleKey;
};

function getFlatPermission(permissionData) {
  return permissionData.reduce((acc, cur) => {
    let _key = getLocaleKey(CATEGORY, cur.category);
    if (!_key) {
      return acc;
    }
    // console.log(cur);
    if (cur?.permission) {
      acc[_key] = {
        read: cur.permission.read,
        write: cur.permission.write
      };
    }
    if (cur?.items) {
      acc = { ...acc, ...getFlatPermission(cur.items) };
    }
    return acc;
  }, {});
}

function getWholeFlatPermission(permission) {
  let _res = {};
  for (let i in permission) {
    let _index = Object.values(PERMISSION).findIndex(
      item => item.value === permission[i].category
    );
    if (_index) {
      _res[Object.values(PERMISSION)[_index].localeKey.split(".")[1]] = {
        ...getFlatPermission(permission[i].data)
      };
    }
  }
  return _res;
}

const PERMISSIONS = {
  PERMISSION: PERMISSION,
  CATEGORY: CATEGORY,
  useSearchLocaleKey: useSearchLocaleKey,
  getWholeFlatPermission: getWholeFlatPermission
};

export default PERMISSIONS;
export {
  CATEGORY,
  PERMISSION,
  useSearchLocaleKey,
  getWholeFlatPermission,
  getFlatPermission,
  permissionList,
  _PERMISSION_DEFAULT
};
