import React from "react";

const Specialcalculation = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M17.5 12.5c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm.705 1H16.87v.965c-.86.185-1.555.745-1.555 1.605 0 1.025.85 1.535 2.09 1.835 1.115.265 1.335.655 1.335 1.07 0 .305-.22.795-1.2.795-.915 0-1.275-.41-1.325-.935h-.98c.055.97.78 1.52 1.635 1.7v.965h1.335v-.955c.87-.165 1.555-.665 1.56-1.58 0-1.26-1.08-1.695-2.09-1.955-1.01-.26-1.335-.535-1.335-.955 0-.48.45-.82 1.2-.82.79 0 1.085.38 1.11.935h.98c-.03-.765-.495-1.47-1.425-1.695V13.5zM9.5 1.5c1.156 0 2.133.798 2.507 1.9h3.715c.978 0 1.778.855 1.778 1.9l-.001 5.7-.232.004c-.664.024-1.302.146-1.901.354V5.5H3.634v13h7.444c.11.712.335 1.386.656 2.001L3.278 20.5c-.125 0-.24-.01-.356-.029-.346-.076-.658-.265-.898-.522-.16-.18-.293-.38-.382-.608-.089-.228-.142-.485-.142-.741V5.3c0-.266.053-.513.142-.732.09-.227.222-.436.382-.607.24-.257.552-.447.898-.523.116-.028.231-.038.356-.038h3.715C7.367 2.298 8.344 1.5 9.5 1.5zm1.813 14H5.5v-2h6.876c-.463.593-.826 1.268-1.063 2zm2.187-5v1.877c-.052.04-.103.08-.153.123H5.5v-2h8zm0-3v2h-8v-2h8zm-4-4c-.292 0-.533.227-.533.5s.241.5.533.5c.292 0 .533-.227.533-.5s-.241-.5-.533-.5z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default Specialcalculation;
