import { Box, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { translate } from "components";
import { CCFlattenDateField } from "components/CCFlattenDateTimePicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCTextField } from "styles/components";
import { SearchIcon } from "styles/src/themes/common/icons";
import { LabGoldField } from "views/Normal/GoldManagement/components";

const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    padding: 8
  },
  input: {
    backgroundColor: theme.palette.background.paper
  },
  timeInput: {
    width: 130
  },
  selectInput: {
    width: 142
  },
  memoInput: {
    width: 236
  },
  adduse__select: {
    width: 88
  },
  marginLeft8: {
    marginLeft: 8
  },
  marginLeft3: {
    marginLeft: 3
  },
  marginLeft1: {
    marginLeft: 1
  },
  goldtype: {
    width: 136
  }
}));

const QueryFilter = props => {
  const { filter, onChange } = props;

  const classes = useStyle();
  const intl = useIntl();

  const [value, setValue] = useState(filter);

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  return (
    <Box className={classes.root}>
      <Grid
        container
        direction={"column"}
        justify={"center"}
        // alignItems={"center"}
        wrap={"nowrap"}
      >
        <Grid item>
          <Grid container alignItems={"center"} wrap={"nowrap"}>
            <Grid item>
              <CCFlattenDateField
                label={translate(intl, "labManageGold.date")}
                onChange={e => {
                  setValue({
                    ...value,
                    filter: {
                      ...value?.filter,
                      args: {
                        ...value?.filter?.args,
                        date: {
                          ...value?.filter?.args?.date,
                          begin: e.startOf("day").unix()
                        }
                      }
                    }
                  });
                }}
                value={
                  value?.filter?.args?.date?.begin
                    ? moment(value.filter.args.date.begin, "X")
                    : false
                }
                className={clsx(classes.input, classes.timeInput)}
              />
            </Grid>
            <Grid item>{"~"}</Grid>
            <Grid item>
              <CCFlattenDateField
                label={translate(intl, "labManageGold.date")}
                onChange={e => {
                  setValue({
                    ...value,
                    filter: {
                      ...value?.filter,
                      args: {
                        ...value?.filter?.args,
                        date: {
                          ...value?.filter?.args?.date,
                          end: e.endOf("day").unix()
                        }
                      }
                    }
                  });
                }}
                value={
                  value?.filter?.args?.date?.end
                    ? moment(value.filter.args.date.end, "X")
                    : false
                }
                className={clsx(classes.input, classes.timeInput)}
              />
            </Grid>

            <Grid item className={classes.marginLeft1}>
              <CCButton
                variant={"contained"}
                size={"small"}
                color={"normal"}
                onClick={() => {
                  setValue({
                    ...value,
                    filter: {
                      ...value?.filter,
                      args: {
                        ...value?.filter?.args,
                        date: {
                          begin: moment().startOf("day").unix(),
                          end: moment().endOf("day").unix()
                        }
                      }
                    }
                  });
                }}
              >
                {translate(intl, "labManageGold.date")}
              </CCButton>
            </Grid>
            <Grid item className={classes.marginLeft1}>
              <CCButton
                variant={"contained"}
                size={"small"}
                color={"normal"}
                onClick={() => {
                  setValue({
                    ...value,
                    filter: {
                      ...value?.filter,
                      args: {
                        ...value?.filter?.args,
                        date: {
                          begin: moment().startOf("week").unix(),
                          end: moment().endOf("week").unix()
                        }
                      }
                    }
                  });
                }}
              >
                {translate(intl, "labManageGold.week")}
              </CCButton>
            </Grid>
            <Grid item className={classes.marginLeft1}>
              <CCButton
                variant={"contained"}
                size={"small"}
                color={"normal"}
                onClick={() => {
                  setValue({
                    ...value,
                    filter: {
                      ...value?.filter,
                      args: {
                        ...value?.filter?.args,
                        date: {
                          begin: moment().startOf("month").unix(),
                          end: moment().endOf("month").unix()
                        }
                      }
                    }
                  });
                }}
              >
                {translate(intl, "labManageGold.month")}
              </CCButton>
            </Grid>
            <Grid item>
              {value?.filter?.args?.hasOwnProperty("addUse") && (
                <CCTextField
                  variant="outlined"
                  select
                  label={translate(intl, "labManageGold.addUse")}
                  fullWidth
                  margin="dense"
                  value={value?.filter?.args?.addUse}
                  className={classes.adduse__select}
                  onChange={e => {
                    console.log("addUse e.target.value", e.target.value);
                    setValue({
                      ...value,
                      filter: {
                        ...value?.filter,
                        args: {
                          ...value?.filter?.args,
                          addUse: e.target.value
                        }
                      }
                    });
                  }}
                >
                  {[
                    { value: "All", localeKey: "common.ui.all" },
                    { value: "Add", localeKey: "labManageGold.add" },
                    { value: "Use", localeKey: "labManageGold.use" }
                  ].map(option => {
                    return (
                      <MenuItem
                        className={classes.cell__select}
                        key={option.value}
                        value={option.value}
                      >
                        {translate(intl, option.localeKey)}
                      </MenuItem>
                    );
                  })}
                </CCTextField>
              )}
            </Grid>
            <Grid item>
              <LabGoldField
                label={translate(intl, "common.ui.type")}
                value={value?.filter?.args?.goldType}
                className={classes.goldtype}
                onChange={e => {
                  console.log("e.target.value", e.target.value);
                  setValue({
                    ...value,
                    filter: {
                      ...value?.filter,
                      args: {
                        ...value?.filter?.args,
                        goldType:
                          e.target.value === "All" ? "" : e.target.value + ""
                      }
                    }
                  });
                }}
                includeAll={true}
              />
            </Grid>
            <Grid item className={classes.marginLeft1}>
              <CCButton
                style={{
                  height: 36,
                  paddingTop: 0,
                  paddingBottom: 0
                }}
                variant={"contained"}
                color={"secondary"}
                startIcon={<SearchIcon />}
                onClick={() => {
                  onChange && onChange({ ...value });
                }}
              >
                {translate(intl, "common.ui.search")}
              </CCButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QueryFilter;
