import React from "react";

const DollarvietIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm3.256 15.955H8.883v1.044h6.373v-1.044zM15.64 4.9h-2.288v1.213h-2.365v1.49h2.365v.384c0 .236.015.489.046.76l.065.542.058.434h-.077a2.804 2.804 0 0 0-.921-.876c-.39-.235-.886-.353-1.49-.353-.963 0-1.74.348-2.335 1.044-.594.696-.89 1.725-.89 3.087 0 1.342.294 2.36.883 3.057.588.696 1.349 1.044 2.28 1.044.604 0 1.096-.12 1.475-.361.315-.2.578-.438.787-.714l.119-.17h.092l.445 1.091h1.751V7.603h1.168v-1.49H15.64V4.9zm-3.9 5.452c.675 0 1.138.197 1.39.592.222.35.346.836.37 1.457l.005.24v.215c-.02.686-.158 1.198-.414 1.535-.256.338-.696.507-1.321.507-.522 0-.927-.187-1.213-.56-.287-.374-.43-.935-.43-1.682 0-.747.143-1.318.43-1.712.286-.395.68-.592 1.182-.592z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default DollarvietIcon;
