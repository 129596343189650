import { useMutation } from "@apollo/react-hooks";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  CCTableToolbar,
  CVDialogSimpleDel,
  DialogError,
  DialogPermissionWarn,
  useWritePermission,
  TypographyTooltip
} from "components";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCPaper, CCTypography } from "styles/components";
import { CVButton, translate } from "components";
import DialogClinicAdd from "../DialogClinicAdd";

const CREATE_PARTNER = gql`
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      id
    }
  }
`;

const UPDATE_PARTNER = gql`
  mutation UpdatePartnerInfo($input: UpdatePartnerInfoInput) {
    updatePartnerInfo(input: $input) {
      id
    }
  }
`;

const REMOVE_PARTNER = gql`
  mutation unLinkPartner($id: ID!) {
    unLinkPartner(id: $id) {
      code
      message
    }
  }
`;

const IS_PARTNER_EXIST_ORDER = gql`
  mutation isPartnerExistOrder($id: ID!) {
    isPartnerExistOrder(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  root: { flex: 1 },
  title: {
    position: "relative",
    padding: "7px 16px 21px"
  },
  table: {
    height: "calc(100% - 45px)"
  },
  add__button: {
    position: "absolute",
    top: 6,
    right: 8,
    color: theme.palette.icon
  },
  cell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  text__ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  classClinic: {
    color: "#00897B"
  },
  classGeneral: {
    color: "#212121"
  },
}));

const useTableStyles = makeStyles(theme => ({
  table__head__row: {
    height: '36px',
  }
}))

const eventComponent = ({ data }) => (
  <Grid
    container
    alignItems={"center"}
    justify={"center"}
    style={{ width: "100%", height: "100%" }}
  >
    <Grid item>{data}</Grid>
  </Grid>
);

const ClinicInformation = props => {
  const {
    data: partnerData,
    loading: partnerLoading,
    refetch,
    handleSetKeyWordSearch
  } = props;

  // classes
  const classesCustom = useTableStyles();
  const classes = useStyles();
  const intl = useIntl();
  const settingPermission = "clinicsPartnersSetting";
  const isPermission = useWritePermission(settingPermission);
  // value
  const [errorPermission, setErrorPermission] = useState(false);
  const [data, setData] = useState({});
  const [warningDelete, setWarningDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [dialogClinicAddVisible, setDialogClinicAddVisible] = useState(false);

  // graphql
  const [createPartner] = useMutation(CREATE_PARTNER, {
    onCompleted: () => {
      refetch();
    }
  });
  const [isPartnerExistOrder] = useMutation(IS_PARTNER_EXIST_ORDER);
  const [updatePartnerInfo] = useMutation(UPDATE_PARTNER, {
    onCompleted: () => {
      refetch();
    }
  });
  const [removePartner] = useMutation(REMOVE_PARTNER, {
    onCompleted: () => {
      setDeleteDialog(false);
      refetch();
    }
  });

  // useEffect
  useEffect(() => {
    setLoading(partnerLoading);
    if (!partnerLoading && partnerData) {
      const clinic = partnerData?.partnerList?.items?.filter(item => item.createdBy === "Clever")
        .sort((a, b) => a >= b ? 1 : -1) || []
      const general = partnerData?.partnerList?.items?.filter(item => item.createdBy !== "Clever")
        .sort((a, b) => a >= b ? 1 : -1) || []
      setData([...clinic, ...general]);
    }
  }, [partnerData, partnerLoading]);

  // inReturn
  const heads = [
    {
      label: translate(intl, "common.ui.clinicName"),
      key: "name",
      width: 200,
      className: classes.cell,
      component: ({ cellData, rowData }) => {
        return (
          <TypographyTooltip placement="top"
            className={clsx({
              [classes.classClinic]: rowData.createdBy === "Clever",
              [classes.classGeneral]: rowData.createdBy !== "Clever"
            })}
          >
            {cellData ? cellData : ""}
          </TypographyTooltip>
        );
      }
    },
    // {
    //   label: translate( intl,"settings.clinics.clever" ),
    //   key: "createdBy",
    //   width: 80,
    //   className: classes.cell,
    //   component: cellData => {
    //     return (
    //       <Box>
    //         {cellData.rowData.createdBy === "Clever" ? (
    //           <span>O</span>
    //         ) : (
    //           <span>X</span>
    //         )}
    //       </Box>
    //     );
    //   }
    // },
    {
      label: translate(intl, "common.ui.address"),
      key: "address",
      width: 350,
      className: classes.cell,
      component: ({ cellData, rowData }) => {
        return (
          <TypographyTooltip placement="top">
            {cellData}
          </TypographyTooltip>
        );
      }
    },
    {
      label: translate(intl, "common.ui.telNo"),
      key: "tel",
      width: 120,
      className: classes.cell,
      component: ({ cellData }) => (cellData ? cellData : "")
    },
    {
      label: translate(intl, "common.ui.email"),
      key: "email",
      width: 320,
      className: classes.cell,
      component: ({ cellData }) => (cellData ? cellData : "")
    },
    {
      label: translate(intl, "common.ui.manager"),
      key: "manager",
      width: 200,
      className: classes.cell,
      component: ({ cellData }) => (cellData ? cellData : "")
    },
    // {
    //   label: translate( intl,"common.ui.managerCellNoShort" }),
    //   key: "managerCellNo",
    //   width: 124,
    //   className: classes.cell,
    //   component: ({ cellData }) => (cellData ? cellData : "")
    // },
    {
      label: translate(intl, "common.ui.discount2"),
      key: "discount",
      width: 100,
      className: classes.cell,
      component: ({ cellData: discount }) => <Box>{discount}%</Box>
    },
    {
      label: translate(intl, "common.ui.payOn"),
      key: "paymentDate",
      width: 130,
      className: classes.cell,
      component: ({ cellData }) => (cellData ? cellData : "")
    },
    // {
    //   label: translate( intl,"settings.lab.erc" ),
    //   key: "tax",
    //   width: 150,
    //   className: classes.cell,
    //   component: ({ cellData }) => (cellData ? cellData : "")
    // },
  ];

  return (
    <CCPaper className={clsx(classes.root)}>
      <Box className={classes.title}>
        <CCTypography variant="h4">{`${intl.formatMessage({
          id: "settings.clinics.myClinics"
        })} (${data.length
          ? data.length
          : 0
          })`}</CCTypography>
        <Box className={classes.icon}>
          <CVButton
            permission={"clinicsPartnersSetting"}
            className={classes.add__button}
            variant="contained"
            color="normal"
            size="small"
            onClick={() => {
              setSelectedClinic(null);
              setDialogClinicAddVisible(true);
            }}
          >
            {translate(intl, "settings.clinics.addClinicNormal")}
          </CVButton>
        </Box>
      </Box>
      {
        <>
          <CCTableToolbar
            permission={"clinicsPartnersSetting"}
            heads={heads}
            contents={
              !loading ? data : []
            }
            className={classes.table}
            classes={classesCustom}
            onSearch={word => {
              handleSetKeyWordSearch(word);
              refetch({
                query: { skip: 0, limit: 20, keyword: word }
              });
            }}
            onDelClick={e => {
              if (!isPermission) {
                setErrorPermission(true);
                return;
              }
              isPartnerExistOrder({ variables: { id: e.rowData.id } })
                .then(res => {
                  if (res?.data?.isPartnerExistOrder) {
                    setWarningDelete(true);
                  } else {
                    setSelectedClinic(e.rowData);
                    setDeleteDialog(true);
                  }
                })
                .catch(() => {
                  setWarningDelete(true);
                });
            }}
            onModClick={e => {
              if (!isPermission) {
                setErrorPermission(true);
                return;
              }
              setSelectedClinic(e.rowData);
              setDialogClinicAddVisible(true);
            }}
          />
        </>
      }

      {warningDelete && (
        <DialogError
          open={warningDelete}
          onClose={() => setWarningDelete(false)}
          content={translate(
            intl,
            "common.ui.clinicsThatHaveOrdersSavedCouldNotBeDeleted"
          )}
        />
      )}

      {deleteDialog && (
        <CVDialogSimpleDel
          open={deleteDialog}
          maxWidth="xs"
          warningTitle={intl.formatMessage({
            id: "settings.clinic.deleteClinicWarningTitle"
          })}
          warningDesc={intl.formatMessage({
            id: "settings.clinic.deleteClinicWarningDesc"
          })}
          onClose={() => setDeleteDialog(false)}
          onDisagree={() => setDeleteDialog(false)}
          onAgree={() => {
            removePartner({
              variables: {
                id: selectedClinic.id
              }
            });
            setDeleteDialog(false);
          }}
        />
      )}

      {dialogClinicAddVisible && (
        <DialogClinicAdd
          open={dialogClinicAddVisible}
          selectedClinic={selectedClinic}
          createClinic={createPartner}
          updateClinic={updatePartnerInfo}
          onClose={() => setDialogClinicAddVisible(false)}
        />
      )}
      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
    </CCPaper>
  );
};

export default ClinicInformation;
