import { makeStyles, Radio } from "@material-ui/core";
import React from "react";
import CCButton from "styles/src/components/CCButton";

const useStyles = makeStyles(theme => ({
  root: {},
  padding0: { padding: 0 },
  button: { textTransform: 'unset' }
}));

const RadioButton = props => {
  const { value, checked, onChange } = props;

  const classes = useStyles();

  return (
    <CCButton
      variant={"outlined"}
      color={checked ? "selected" : "normal"}
      onClick={() => onChange({ type: value })}
      className={classes.button}
      startIcon={
        <Radio
          size={"small"}
          style={checked ? { color: "white" } : {}}
          classes={{ root: classes.padding0 }}
          checked={checked}
          disabled
          value={value}
        />
      }
      {...props}
    />
  );
};

RadioButton.defaultProps = {};

RadioButton.propTypes = {};

export default RadioButton;
