import React from "react";

const PhotoIconBigFolderIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="98"
    viewBox="0 0 120 98"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H120V98H0z" />
      <path
        fill="currentColor"
        fillOpacity=".54"
        d="M50.2 2H20.8c-5.39 0-9.751 4.388-9.751 9.75L11 70.25c0 5.362 4.41 9.75 9.8 9.75h78.4c5.39 0 9.8-4.388 9.8-9.75V21.5c0-5.363-4.41-9.75-9.8-9.75H60L50.2 2z"
      />
    </g>
  </svg>
);
export default PhotoIconBigFolderIcon;
