import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { getObject } from "utils/s3";

export const useGetAttachments = (attachments, target, labId) => {
  const [data, setData] = useState([]);

  // use for cancel request when a component is being removed from the DOM
  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    Promise.all(
      [...attachments].map(async attachment => {
        try {
          const url = await getObject(
            target,
            attachment.bucket || labId,
            attachment.fileId
          );
          return url;
        } catch (err) {
          return "";
        }
      })
    ).then(data => {
      if (!signal.aborted) {
        setData(data);
      }
    });

    return () => {
      // cancel request
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data
  };
};

export const saveAttachmentToDisk = (url, fileName) => {
  fetch(url)
    .then(res => res.blob())
    .then(blob => {
      saveAs(blob, fileName);
    });
};
