import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { translate } from "components";
import CVButton from "components/CVButton";
import React from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple, CCTypography } from "styles/components";
import { WarningIcon } from "styles/src/themes/common/icons";

const useStyles = makeStyles(theme => ({
  root: {},
  warning___color: { color: theme.palette.error.main },
  desc___color: { color: theme.palette.sub.main },
  text: { margin: "0 16px", width: 280 },
  disagree: { marginRight: 8 }
}));

const DialogDelete = props => {
  const {
    permission,
    open,
    onClose,
    onAgree,
    value,
    onDisagree = onClose
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  return (
    <CCDialogSimple
      open={open}
      onClose={onClose}
      onCloseButton={onClose}
      title={<WarningIcon className={classes.warning___color} />}
      contentsPadding={false}
      contents={
        <Box>
          <Box className={classes.text}>
            <CCTypography className={classes.warning___color}>
              {translate(intl, "common.ui.areYouSureYouWantToDeleteIt")}
            </CCTypography>
            <CCTypography className={classes.desc___color}>
              {translate(intl, "common.ui.deletedValuesCannotBeRecovered")}
            </CCTypography>
          </Box>
        </Box>
      }
      endActions={
        <>
          <CVButton
            permission={permission}
            className={classes.disagree}
            color="sub"
            onClick={() => {
              onDisagree && onDisagree();
            }}
          >
            취소
          </CVButton>
          <CVButton
            permission={permission}
            variant="contained"
            color="error"
            onClick={() => {
              onAgree && onAgree(value);
            }}
          >
            삭제
          </CVButton>
        </>
      }
    />
  );
};

export default DialogDelete;
