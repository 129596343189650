import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CVMenu } from "components";
import moment from "moment";
import {
  ADD_LAB_STORAGE_GOLD,
  GOLD_LAB_STORAGE_DETAIL
} from "queries/goldManagement";
import { PARTNER_LIST } from "queries/setting";
import React, { useEffect, useReducer } from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple, CCTypography } from "styles/components";
import { GOLD_OWNERSHIP_TYPES, LAB_GOLD_TYPES } from "types/goldManagement";
import { makeIndexFormattedMessageList } from "types/utils";
import { LabOrderModal } from "views/Normal/DentalLab/components";
import {
  AddStockModal,
  ClinicFields,
  CurrentStockBox,
  HistoryTable
} from "views/Normal/GoldManagement/components";
import { translate } from "../../../../../../../components";

const useStyles = makeStyles(() => ({
  root: {
    padding: 8,
    width: 925,
    minHeight: 820
  },
  input__group: {
    width: "50%"
  },
  cell__center: {
    textAlign: "center"
  },
  mouse__hover: {
    cursor: "pointer"
  },
  padding8: { padding: 8 }
}));

const initializer = props => {
  const { clinicId } = props;
  return {
    filter: {
      args: {
        date: {
          begin: moment().startOf("week").unix(),
          end: moment().endOf("week").unix()
        },
        goldType: "",
        addUse: "All"
      }
    },
    selectedClinic: clinicId,
    clinicType: "",
    anchorEl: null,
    selectedContent: {},
    labOrderModalVisible: false,
    addStockModalVisible: false
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.target.filter
        }
      };
    case "setAnchorEl":
      return {
        ...state,
        anchorEl: action.target
      };
    case "setSelectedContent":
      return {
        ...state,
        selectedContent: action.target
      };
    case "setSelectedClinic":
      return {
        ...state,
        selectedClinic: action.target
      };
    case "setClinicType":
      return {
        ...state,
        clinicType: action.target
      };
    case "setLabOrderModalVisible":
      return {
        ...state,
        labOrderModalVisible: action.target
      };
    case "setAddStockModalVisible":
      return {
        ...state,
        addStockModalVisible: action.target
      };
    default:
      break;
  }

  return state;
};

const labGoldTypes = makeIndexFormattedMessageList(LAB_GOLD_TYPES);

const goldTypes = {
  inlay: "Inlay",
  aType: "A-Type",
  sA: "S-A",
  pt: "PT",
  pfg: "PFG"
};

const LabStorageHistoryModal = props => {
  const { open, onClose, clinicId } = props;

  const classes = useStyles();
  const intl = useIntl();
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const {
    anchorEl,
    labOrderModalVisible,
    selectedContent,
    addStockModalVisible,
    selectedClinic,
    clinicType,
    filter
  } = state;

  const { data: clinicListData, loading: clinicListLoading } = useQuery(
    PARTNER_LIST,
    {
      variables: {
        query: { sourcingGoldMethod: GOLD_OWNERSHIP_TYPES.LAB_STORAGE.value }
      }
    }
  );
  const {
    data: goldLabStorageDetailData,
    loading: goldLabStorageDetailLoading,
    refetch: goldLabStorageDetailRefetch
  } = useQuery(GOLD_LAB_STORAGE_DETAIL, {
    variables: {
      query: {
        id: selectedClinic,
        updateStorageDate: {
          from: filter.args.date.begin,
          to: filter.args.date.end
        },
        goldType: filter.args.goldType,
        isAddGoldToStorage:
          filter.args.addUse === "All"
            ? undefined
            : filter.args.addUse === "Add"
      }
    }
  });
  const [addLabStorageStock] = useMutation(ADD_LAB_STORAGE_GOLD);

  const updateClinicType = id => {
    const clinic = clinicListData.partnerList.items.find(
      item => item.id === id
    );

    dispatchState({
      type: "setClinicType",
      target: clinic?.createdBy === "Lab" ? "Normal" : "Clever"
    });
  };
  const handleOnChangeClinic = e => {
    updateClinicType(e.target.value);
    dispatchState({
      type: "setSelectedClinic",
      target: e.target.value
    });
    goldLabStorageDetailRefetch({
      query: {
        id: e.target.value,
        updateStorageDate: {}
      }
    });
  };
  const handleOnFilter = e => {
    dispatchState({ type: "filter", target: e });

    goldLabStorageDetailRefetch({
      query: {
        id: selectedClinic,
        updateStorageDate: {
          from: state.filter.args.date.begin,
          to: state.filter.args.date.end
        },
        goldType: state.filter.args.goldType,
        isAddGoldToStorage:
          state.filter.args.addUse === "All"
            ? undefined
            : state.filter.args.addUse === "Add"
      }
    });
  };
  const handleOnAddLabGoldStock = (importDate, goldType, goldAddedAmount) => {
    addLabStorageStock({
      variables: {
        input: {
          id: selectedClinic,
          goldType,
          weight: goldAddedAmount,
          updateStorageAt: importDate
        }
      }
    }).then(() => {
      goldLabStorageDetailRefetch({
        query: {
          id: selectedClinic,
          updateStorageDate: {
            from: filter.args.date.begin,
            to: filter.args.date.end
          },
          goldType: filter.args.goldType,
          isAddGoldToStorage:
            filter.args.addUse === "All"
              ? undefined
              : filter.args.addUse === "Add"
        }
      });
    });
  };

  useEffect(() => {
    if (clinicListData?.partnerList) {
      updateClinicType(clinicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicListData]);

  const eventComponent = ({ component, data, isAddGoldToStorage }) => {
    return (
      component || (
        <Box
          className={clsx(classes.mouse__hover)}
          onClick={e => {
            !isAddGoldToStorage &&
              dispatchState({ type: "setAnchorEl", target: e.target });
          }}
        >
          {data}
        </Box>
      )
    );
  };

  const stockHistoryHeads = [
    {
      key: "date",
      className: classes.cell__center,
      label: translate( intl,"labManageGold.date" ),
      width: 150,
      component: ({ rowData }) => {
        return eventComponent({
          isAddGoldToStorage: rowData.isAddGoldToStorage,
          data: (
            <CCTypography>
              {rowData.updateStorageAt
                ? moment.unix(rowData.updateStorageAt).format("L")
                : "-"}
            </CCTypography>
          )
        });
      }
    },
    {
      key: "addUse",
      className: classes.cell__center,
      label: translate(intl, "labManageGold.addUse"),
      component: ({ rowData }) => {
        const color = rowData.isAddGoldToStorage === true ? "primary" : "error";
        return eventComponent({
          isAddGoldToStorage: rowData.isAddGoldToStorage,
          data: (
            <CCTypography color={color}>
              {color === "primary" ? "Add" : "Use"}
            </CCTypography>
          )
        });
      }
    },
    {
      key: "type",
      className: classes.cell__center,
      label: translate( intl,"common.ui.type" ),
      component: ({ rowData }) =>
        eventComponent({
          isAddGoldToStorage: rowData.isAddGoldToStorage,
          data: <CCTypography>{labGoldTypes[rowData.goldType]}</CCTypography>
        })
    },

    {
      key: "weight",
      className: classes.cell__center,
      label: translate( intl,"labManageGold.weightG" ),
      component: ({ rowData }) =>
        eventComponent({
          isAddGoldToStorage: rowData.isAddGoldToStorage,
          data: (
            <CCTypography>
              {rowData.weight ? rowData.weight.toFixed(2) : 0}
            </CCTypography>
          )
        })
    }
  ];

  return (
    <>
      <CCDialogSimple
        title={translate( intl,"labManageGold.labStorageHistory" )}
        open={open}
        maxWidth={"xl"}
        fullWidth={false}
        onClose={onClose}
        onCloseButton={onClose}
        contentsPadding={false}
        contents={
          <Grid
            container
            wrap={"nowrap"}
            direction={"column"}
            className={classes.root}
          >
            <Grid item className={classes.input__group}>
              {!clinicListLoading && (
                <ClinicFields
                  clinic={selectedClinic}
                  type={clinicType}
                  clinicList={clinicListData.partnerList.items}
                  onChangeClinic={handleOnChangeClinic}
                />
              )}
            </Grid>

            <Grid item className={classes.padding8}>
              {!goldLabStorageDetailLoading && (
                <CurrentStockBox
                  goldTypes={goldTypes}
                  goldStock={goldLabStorageDetailData?.goldLabStorageDetail}
                  onClickAddStockButton={() =>
                    dispatchState({
                      type: "setAddStockModalVisible",
                      target: true
                    })
                  }
                />
              )}
            </Grid>

            <Grid item className={classes.padding8}>
              {!goldLabStorageDetailLoading && (
                <HistoryTable
                  title={translate(intl, "labManageGold.stockHistory")}
                  heads={stockHistoryHeads}
                  contents={
                    goldLabStorageDetailData?.goldLabStorageDetail.history
                  }
                  filter={{
                    filter: state.filter
                  }}
                  onRowClick={e => {
                    dispatchState({
                      type: "setSelectedContent",
                      target: e.data
                    });
                  }}
                  onFilter={handleOnFilter}
                />
              )}

              {Boolean(anchorEl) && (
                <CVMenu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  menuItems={[
                    {
                      label: translate(intl, "labManageGold.moveToOrder"),
                      onClick: () =>
                        dispatchState({
                          type: "setLabOrderModalVisible",
                          target: true
                        })
                    }
                  ]}
                  onClose={() =>
                    dispatchState({ type: "setAnchorEl", target: null })
                  }
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                />
              )}
            </Grid>
          </Grid>
        }
      />

      {labOrderModalVisible && (
        <LabOrderModal
          open={labOrderModalVisible}
          onClose={() =>
            dispatchState({
              type: "setLabOrderModalVisible",
              target: false
            })
          }
          value={selectedContent.orderId}
          onModify={() => {}}
        />
      )}

      {addStockModalVisible && (
        <AddStockModal
          open={addStockModalVisible}
          onClose={() => {
            dispatchState({
              type: "setAddStockModalVisible",
              target: false
            });
          }}
          goldStockDetail={goldLabStorageDetailData?.goldLabStorageDetail}
          onAdd={handleOnAddLabGoldStock}
        />
      )}
    </>
  );
};

export default LabStorageHistoryModal;
