import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, makeStyles } from "@material-ui/core";
import {
  CVButton,
  enqueueToastSave,
  DialogPermissionWarn,
  useWritePermission,
  useReadPermission,
  translate,
  DialogWarningMessage,
  PermissionBackdrop
} from "components";
import { useSnackbar } from "notistack";
import { UPDATE_PROCESS_SETTING, GET_CONFIG_PROCESS } from "queries/setting";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCDialogActions,
  CCDialogContent,
  CCDialogTitle,
  CCPaper,
  CCDialogTitleAddMod as AddItemDialog,
} from "styles/components";
import { CheckIcon } from "styles/icons";
import { v4 as uuid } from "uuid";
import Process from "./components/Process/Process";
import Stages from "./components/Stages/Stages";

const useStyles = makeStyles(theme => ({
  root: { height: "100%", width: "fit-content" },
  content: {
    display: 'flex',
    width: 864,
    justifyContent: 'space-evenly'
  },
  title: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    marginBottom: 20
  }
}));

const ProductionProcess = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // value
  const settingPermission = "productionProcess";
  const isPermission = useWritePermission(settingPermission);
  const isReadPermission = useReadPermission(settingPermission);
  const [errorPermission, setErrorPermission] = useState(false);
  const [values, setValues] = useState([]);
  const [currentProcess, setCurrentProcess] = useState({});
  const [currentStage, setCurrentStage] = useState({});
  const [openDialog, setOpenDialog] = useState({ open: false });
  const [valueEditAction, setValueAction] = useState(false)
  const [errorDialog, setErrorDialog] = useState(false)

  // graphql
  const { data, refetch } = useQuery(GET_CONFIG_PROCESS, {
    variables: {
      category: "productProcessSetting",
      labId: localStorage.getItem("labId")
    },
    fetchPolicy: "network-only"
  });
  const [createProductProcessSetting] = useMutation(
    UPDATE_PROCESS_SETTING
  );
  const validateValues = (val) => {
    let validation = {}
    if (val.length === 0) {
      validation = { isValid: false, message: translate(intl, "productProcessError") }

    } else {
      val.every((cur) => {
        if (cur.stages.length === 0) {
          validation = { isValid: false, message: translate(intl, "productStagesError") }
          return false
        } else {
          validation = { isValid: true, message: '' }
          return false
        }

      })
    }
    return validation

  }

  // function
  const onSave = () => {
    const checkBeforeSave = validateValues(values)
    if (!checkBeforeSave.isValid) {
      setErrorDialog(checkBeforeSave.message)
    } else {
      createProductProcessSetting({
        variables: {
          category: "productProcessSetting",
          productProcessSetting: values
        }
      }).then(() => {
        refetch()
        enqueueToastSave(intl, enqueueSnackbar);

      });
    }
  };
  const onCancel = () => {
    setValues(data?.getConfig?.productProcessSetting);
  };


  const onDeleteItem = (idProcess, idStage) => {
    if (idStage) {
      setValues(prevState =>
        prevState.map(item =>
          item.id === idProcess ?
            {
              ...item,
              stages: item.stages.filter(stage => stage.id !== idStage
              )
            } : item
        )
      )
    } else {
      setValues(prevState =>
        prevState.filter(item =>
          item.id !== idProcess
        )
      )
    }
  };

  const openChangeItem = (idProcess, idStage) => {
    setOpenDialog({ open: true, idProcess, idStage })
  };

  const onChangeItem = value => {
    const { idProcess, idStage } = openDialog
    const updatedProcess = values.find(item => item.id === idProcess)
    const updatedStages = updatedProcess?.stages.find(item => item.id === idStage)
    const newProcess = {
      id: uuid(),
      processName: value,
      stages: [{ id: uuid(), stageName: "new Stage" }]
    }
    const newStage = {
      id: uuid(),
      stageName: value,
    }
    if (updatedProcess) {
      if (idStage) {
        if (updatedStages) {
          setValues(prevState =>
            prevState.map(item =>
              item.id === idProcess ?
                {
                  ...item,
                  stages: item.stages.map(stage =>
                    stage.id === idStage ?
                      { ...stage, stageName: value } : stage
                  )
                } : item
            )
          )
        } else {
          setValues(prevState =>
            prevState.map(item =>
              item.id === idProcess ?
                { ...item, stages: [newStage, ...item.stages] } : item
            )
          )
        }
      } else {
        setValues(prevState =>
          prevState.map(item =>
            item.id === idProcess ?
              { ...item, processName: value } : item
          )
        )
      }
    } else {
      setValues(prevState => [newProcess, ...prevState])
    }
    setOpenDialog({ open: false });
  }
  const onClickProcess = (val) => {
    setCurrentProcess(val)
  }
  const onClickStage = (val) => {
    setCurrentStage(val)
  }

  const moveItem = (index, arr, step) => {
    if (index === -1 || (index === 0 && step === -1) || (index === arr.length - 1 && step === 1)) return arr

    const newList = [...arr]
    newList[index + step] = arr[index]
    newList[index] = arr[index + step]

    return newList
  }

  const moveProcess = (step) => {
    const indexItem = values.findIndex(item => item.id === currentProcess.id)
    setValues(moveItem(indexItem, values, step))
  }

  const moveStage = (step) => {
    const indexItem = currentProcess.stages.findIndex(item => item.id === currentStage.id)

    setValues(prevState => prevState.map(item => item.id === currentProcess.id ?
      { ...item, stages: moveItem(indexItem, item.stages, step) } :
      item
    ))
  }

  // useEffect
  useEffect(() => {
    if (data && data.getConfig && data.getConfig.productProcessSetting) {
      setValues(data.getConfig.productProcessSetting);
    }
  }, [data]);
  useEffect(() => {
    setCurrentProcess(prevProcess => values.find(item => item.id === prevProcess.id) || {})
    setCurrentStage(prevStage => currentProcess?.stages?.find(item => item.id === prevStage.id) || {})



    setValueAction(openDialog.idStage ? values.find(item => item.id === openDialog.idProcess)?.stages.find(item => item.id === openDialog.idStage)?.stageName :
      values.find(item => item.id === openDialog.idProcess)?.processName)
  }, [currentProcess.stages, openDialog.idProcess, openDialog.idStage, values]);

  return (
    <Box className={classes.root}>
      {
          !isReadPermission ? <PermissionBackdrop permission={settingPermission} /> :
            (
              <CCPaper type="normal" style={{ height: "100%", position: "relative" }}>
                <CCDialogTitle className={classes.title}>
                  {translate(intl, "productionProcess")}
                </CCDialogTitle>
                <CCDialogContent
                  noPadding={true}
                  className={classes.content}
                  style={{ height: `calc(100% - 56px - 88px)` }}
                >
                  <Process
                    values={values}
                    onChangeItem={openChangeItem}
                    onDeleteItem={onDeleteItem}
                    onClickItem={onClickProcess}
                    moveItem={moveProcess}
                    currentProcessIndex={values.findIndex(item => item.id === currentProcess?.id)}
                  />
                  <Stages
                    values={currentProcess}
                    onChangeItem={openChangeItem}
                    onClickItem={onClickStage}
                    moveItem={moveStage}
                    onDeleteItem={onDeleteItem}
                    currentStageIndex={currentProcess?.stages?.findIndex(item => item.id === currentStage?.id)}
                  />
                </CCDialogContent>
                <CCDialogActions>
                  <CVButton
                    variant="text"
                    color="sub"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    {translate(intl, "common.ui.cancel")}
                  </CVButton>
                  <CVButton
                    variant="contained"
                    startIcon={<CheckIcon />}
                    onClick={() => {
                      if (!isPermission) {
                        setErrorPermission(true);
                        return;
                      }
                      onSave();
                    }}
                  >
                    {translate(intl, "common.ui.save")}
                  </CVButton>
              </CCDialogActions>
              </CCPaper>
            )
        }

      {openDialog.open &&
        <AddItemDialog
          title={intl.formatMessage({
            id: valueEditAction ? 'common.ui.edit' : "labManageGold.add"
          })}
          open={openDialog.open}
          value={valueEditAction}
          onSave={onChangeItem}

          onClose={() => setOpenDialog({ open: false })}
          maxLength={60}
        />
      }
      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
      {Boolean(errorDialog) &&
        <DialogWarningMessage
          open={Boolean(errorDialog)}
          title={errorDialog}
          agreeButtonLabel={intl.formatMessage({ id: "common.ui.common.ui.ok" })}
          onAgree={() => { setErrorDialog(false) }}
          onClose={() => { setErrorDialog(false) }}
        />}
    </Box>
  );
};

export default ProductionProcess;
