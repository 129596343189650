import React, { useReducer } from "react";

const initialState = null;

const reducer = (state, action) => {
  return action;
};

const PatientContext = React.createContext();
const PatientContextConsumer = PatientContext.Consumer;

const PatientContextProvider = props => {
  const [patientContext, dispatchPatient] = useReducer(reducer, initialState);
  const value = { patientContext, dispatchPatient };

  return (
    <PatientContext.Provider value={value}>
      {props.children}
    </PatientContext.Provider>
  );
};

export default PatientContextProvider;
export { PatientContext, PatientContextConsumer };
