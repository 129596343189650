import awss3 from './awss3';
import minio from './minio';

const exportedObj = process.env.REACT_APP_OPMODE === 'global' ? minio : awss3;
const Target = exportedObj.Target;
const requestAction = exportedObj.requestAction;
const getPresignedPostData = exportedObj.getPresignedPostData;
const getObject = exportedObj.getObject;
const putObject = exportedObj.putObject;
const putObjectBlob = exportedObj.putObjectBlob;
const deleteObject = exportedObj.deleteObject;

export {
    requestAction,
    getPresignedPostData,
    getObject,
    putObject,
    putObjectBlob,
    deleteObject,
    Target,
};

