import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "utils/language";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useProductValidation from "validations/useProductValidation";
import { getTimestamp } from "utils/datetime";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  CCButton,
  CCDialog,
  CCDialogContent,
  CCDialogTitle,
  CCDialogActions
} from "styles/src/components";
import { CVTextField } from "components";
import axios from "axios";
import clsx from "clsx";
import {
  ProductResults,
  DetailResults,
  ImageResults
} from "views/Normal/InventoryManagement/Component/Purchase/Component/OrderReturnTab/Component/OrderDetailDialog/components";
import { LocalizeVariable } from "utils/env";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 2000,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  },
  head__field: {
    width: 300,
    display: "flex",
    flexWrap: "wrap"
    // gap: 5,
  },
  supplier__field: {
    width: 400
  },
  trading__number__field: {
    width: 300
  },
  total__estimate__field: {
    width: 150
  },
  money__field: {
    width: 120
  },
  background: {
    background: theme.palette.background.default
  },
  head__an__field: {
    width: 120
  },
  head__an__search__field: {
    width: 230
  },
  button__filter: {
    marginTop: "8px"
  },
  content__top__border: {
    height: "100%",
    width: "100%",
    // backgroundColor: theme.palette.result.main,
    borderTop: `1px solid ${theme.palette.border.main}`,
    // borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },

  content__bottom__border: {
    height: "100%",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  widthPer60: {
    width: "60%",
    padding: "4px 6px"
  },
  widthPer40: {
    width: "20%"
  },
  width100: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10
  },
  width50Per: {
    width: "50%"
  },
  width60: {
    width: "60%"
  },
  width40: {
    width: "40%"
  },
  width70: {
    width: "70%"
  },
  width30: {
    width: "30%"
  },
  contents: {
    overflowY: "hidden",
    width: 1200,
    minWidth: 1075,
    height: 800
  },
  detailField: {
    width: 900
  },
  innerNext: {
    width: 500,
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginBottom: "-100px"
  },
  divider: {
    height: "80%",
    width: 1,
    borderLeft: `1px solid ${theme.palette.border.main}`,
    margin: 5
  },
  actions: {
    height: 40
  },
  table__root: {
    height: 500,
    backgroundColor: theme.palette.common.white,
    boxSizing: "border-box",
    width: 800
  },
  table__container: {
    height: "97%"
  },
  table__item__table: {
    height: "calc(100% - 60px - 30px)",
    width: "100%",
    // alignItems: "center",
    // textAlign: "center",
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: "8px"
  },
  table__title__table: {
    height: 30,
    display: "space-between"
  }
}));

const OrderDetailDialog = props => {
  const intl = useIntl();
  const initData = {
    hospital: "",
    important: false,
    unit: 1,
    currentCnt: 0,
    optCnt: 0,
    alert: false,
    editDate: getTimestamp(),
    editorID: "",
    canExpire: true,
    expire: {
      expireCount: 0,
      expireDate: getTimestamp()
    },
    binCnt: 0,
    barcode: false,
    isAppropriate: false
  };

  const { open, onClose, order, product = [] } = props;
  const { schema } = useProductValidation();

  const classes = useStyles();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const [products, setProducts] = useState([]);

  const fetchVendor = useCallback(async () => {
    try {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_PAM_URL}/api/v1/order/${product}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken")
        }
      })
        .then(response => {
          setProducts(response?.data);
        })
        .catch(e => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      fetchVendor();
    }
  }, [product, fetchVendor]);

  return (
    <>
      <FormProvider {...methods}>
        <CCDialog open={open} onClose={onClose} maxWidth={"lg"}>
          <CCDialogTitle onCloseButton={onClose} className={classes.title}>
            {intl.formatMessage({ id: "detailInfo" })}
          </CCDialogTitle>
          <CCDialogContent className={classes.contents}>
            <form name={"patientReservationDialogForm"}>
              <Grid container wrap={"nowrap"} justifyContent={"space-between"}>
                <Grid item className={classes.detailField}>
                  <Grid item>
                    <CVTextField
                      className={classes.trading__number__field}
                      variant={"outlined"}
                      margin={"dense"}
                      name={"searchField"}
                      value={order?.orderNumber || ""}
                      disabled={true}
                      readOnly={true}
                      label={intl.formatMessage({ id: "supplierNumber" })}
                    />
                    <CVTextField
                      className={classes.money__field}
                      variant={"outlined"}
                      margin={"dense"}
                      name={"searchField"}
                      value={intl.formatMessage({
                        id: `pam${order?.status}`
                      })}
                      disabled={true}
                      readOnly={true}
                      label={intl.formatMessage({ id: "supplierNumber" })}
                    />
                  </Grid>
                  <Grid item>
                    <CVTextField
                      className={classes.supplier__field}
                      variant={"outlined"}
                      margin={"dense"}
                      name={"searchField"}
                      value={order?.Material?.name || ""}
                      disabled={true}
                      readOnly={true}
                      label={intl.formatMessage({ id: "partner" })}
                    />
                    <CVTextField
                      className={classes.total__estimate__field}
                      variant={"outlined"}
                      margin={"dense"}
                      name={"searchField"}
                      value={order?.totalSum || ""}
                      disabled={true}
                      readOnly={true}
                      label={intl.formatMessage(
                        { id: "totalEstimateAmount" },
                        { cost: LocalizeVariable.currencyUnit }
                      )}
                    />
                  </Grid>
                  <ProductResults products={products} />
                </Grid>
                <Grid item>
                  <Box className={classes.divider} />
                </Grid>
                <Grid item className={classes.innerNext}>
                  <DetailResults result={[]} info={products} />
                </Grid>
              </Grid>
              <Grid container wrap={"nowrap"} style={{ marginTop: -40 }}>
                <ImageResults images={products?.OrderImages} />
              </Grid>
            </form>
          </CCDialogContent>

          <CCDialogActions className={classes.actions}>
            <CCButton
              className={clsx(classes.marginright__8)}
              onClick={() => onClose()}
              color={"primary"}
            >
              {intl.formatMessage({ id: "checkClose" })}
            </CCButton>
          </CCDialogActions>
        </CCDialog>
      </FormProvider>
    </>
  );
};

export default OrderDetailDialog;
