import React from "react";

const MoonborderIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M7.946 5.95c-3.144 3.033-3.273 7.823-.288 10.692 2.986 2.868 7.96 2.735 11.104-.299 1.036-1 1.74-2.2 2.117-3.45-2.638.745-5.524.176-7.525-1.747-2.001-1.922-2.598-4.7-1.83-7.243A8.456 8.456 0 0 0 7.946 5.95zm.694.72a7.352 7.352 0 0 1 1.323-1.014l.257-.146-.005.064c-.135 2.241.64 4.438 2.241 6.09l.205.203c1.714 1.648 4.039 2.451 6.413 2.331l.14-.01-.102.167c-.235.364-.506.71-.81 1.031l-.235.237c-2.765 2.668-7.127 2.786-9.716.298-2.512-2.415-2.459-6.428.118-9.08l.171-.172z"
      />
    </g>
  </svg>
);
export default MoonborderIcon;
