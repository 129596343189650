import { useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CVDialogSimpleDel, DialogError, translate } from "components";
import {
  DELETE_ORDER,
  GET_GOLD_UNIT_PRICE_MANAGE_DETAIL,
  GET_LAB_ORDER_DETAIL,
  UPDATE_ORDER
} from "queries/labOrder";
import { IS_ORDER_BELONGS_TO_PAYMENT } from "queries/payment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple } from "styles/src/components";
import { LAB_ARRIVE_TYPE } from "types/labOrder";
import {
  DialogPermissionWarn,
  useWritePermission
} from "../../../../../components";
import { LabOrderPrint, ViewContents } from "./components";

const useStyles = makeStyles(theme => ({
  root: {}
}));

const initValue = {
  patientName: "",
  chartNumber: "",
  age: "",
  sex: "",
  doctorName: "",
  staffName: "",
  dentalName: "",
  requestNamber: "",
  labName: "",
  labType: "",
  requestType: "",
  requestDate: "",
  deliveryRequestDate: "",
  status: "1",
  clinicGoldPrice: {
    ownerShip: 0,
    goldType: 0,
    goldPrice: 0,
    goldUsed: 0,
    goldConsumed: 0,
    goldReceived: 0,
    remainGoldCalc: 0,
    totalGoldPriceCalc: 0
  }
};

const LabOrderModal = props => {
  const {
    open,
    onClose,
    refetchOrders,
    value,
    onModify,
    permission,
    buttonHidden,
    ...others
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  const isPermission = useWritePermission(permission);
  const [contents, setContents] = useState(initValue);
  const [openPrint, setOpenPrint] = useState(false);
  const [errorPermission, setErrorPermission] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteOrderDialogVisible, setDeleteOrderDialogVisible] =
    useState(false);

  const { loading, data } = useQuery(GET_LAB_ORDER_DETAIL, {
    variables: {
      id: value
    }
  });
  const { data: goldUnitPriceData, loading: goldUnitPriceManageDetailLoading } =
    useQuery(GET_GOLD_UNIT_PRICE_MANAGE_DETAIL, {
      variables: { query: { importDate: {} } }
    });
  const [deleteOrders] = useMutation(DELETE_ORDER, {
    onComplete: () => {
      refetchOrders();
    }
  });


  const { data: isOrderBelongsToPaidPaymentData } = useQuery(
    IS_ORDER_BELONGS_TO_PAYMENT,
    {
      fetchPolicy: "network-only",
      variables: { ids: [value] }
    }
  );

  const [updateOrder] = useMutation(UPDATE_ORDER, {
    onComplete: () => {
      refetchOrders();
      onClose();
    }
  });

  const deliveryUpdate = deliveryType => {
    if (deliveryType === LAB_ARRIVE_TYPE.ARRIVE.value) {
      // updateLabOrder({
      //   variables: {
      //     deliveryType: LAB_ARRIVE_TYPE.NON_ARRIVE.value,
      //     log: {
      //       category: LOG_CATEGORY.LAB_ORDER.value,
      //       contents: `${contents.name}(${contents.chartNumber})의 기공의뢰 정보가 업데이트 되었습니다.`,
      //       patient: contents.name
      //     }
      //   }
      // });
    } else if (deliveryType === LAB_ARRIVE_TYPE.NON_ARRIVE.value) {
      // updateLabOrder({
      //   variables: {
      //     deliveryType: LAB_ARRIVE_TYPE.ARRIVE.value,
      //     log: {
      //       category: LOG_CATEGORY.LAB_ORDER.value,
      //       contents: `${contents.name}(${contents.chartNumber})의 기공의뢰 정보가 업데이트 되었습니다.`,
      //       patient: contents.name
      //     }
      //   }
      // });
    }
  };
  const handleOnChangeField = (field, value) => {
    setContents(prevState => ({ ...prevState, [field]: value }));
  };
  const handleOnSave = () => {
    const { id, status, clinicGoldPrice, clinicProthesisPrice, productProcess } = contents;
    updateOrder({
      variables: {
        input: {
          id,
          status,
          clinicGoldPrice: {
            ...clinicGoldPrice,
            goldType: clinicGoldPrice.goldType + ""
          },
          clinicProthesisPrice,
          productProcess 
        }
      }
    }).then(() => {
      refetchOrders();
    });
  };

  useEffect(() => {
    if (data?.orderDetail) {
      const item = { ...data.orderDetail };
      setContents(item);
    }

    if (goldUnitPriceData?.goldUnitPriceManageDetail) {
      setContents(prevState => ({
        ...prevState,
        goldUnitPriceManageDetail: goldUnitPriceData.goldUnitPriceManageDetail
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, goldUnitPriceData]);

  return !loading && !goldUnitPriceManageDetailLoading && data?.orderDetail ? (
    <>
    
      {open && (
        <CCDialogSimple
          open={open}
          maxWidth={"xl"}
          onClose={onClose}
          contentsPadding={false}
          className={clsx(classes.root)}
          title={translate(intl, "common.ui.orderForm")}
          onCloseButton={onClose}
          onPrintButtonF={() => setOpenPrint(true)}
          isDeleteButton={!data?.orderDetail?.isClever}
          onDeleteButton={() => {
            if (!isPermission) {
              setErrorPermission(true);
            } else setDeleteOrderDialogVisible(true);
          }}
          contents={
            <ViewContents
              // loading={loading}
              permission={permission}
              value={contents}
              deliveryUpdate={deliveryUpdate}
              onClose={onClose}
              onModify={onModify}
              onChange={handleOnChangeField}
              buttonHidden={buttonHidden}
              onSave={handleOnSave}
            />
          }
          {...others}
        />
      )}
      {openPrint && (
        <CCDialogSimple
          maxWidth={false}
          open={openPrint}
          title={translate(intl, "common.ui.prosthesisOrderForm")}
          onClose={() => setOpenPrint(false)}
          onCloseButton={() => setOpenPrint(false)}
          contents={<LabOrderPrint labOrderId={contents.id} open={openPrint} />}
        />
      )}
      {deleteOrderDialogVisible && (
        <CVDialogSimpleDel
          open={deleteOrderDialogVisible}
          onClose={() => setDeleteOrderDialogVisible(false)}
          onDisagree={() => setDeleteOrderDialogVisible(false)}
          onAgree={() => {
            setDeleteOrderDialogVisible(false);
            if (
              isOrderBelongsToPaidPaymentData?.isOrderBelongsToPaidPayment?.isOrdersBelongToSendCleverPayment
            ) {
              setConfirmDelete(true);
            } else {
              deleteOrders({
                variables: { id: data?.orderDetail.id }
              }).then(rs => {
                const responseCode = rs?.data?.deleteOrders?.code;

                if(responseCode === "ORDER02") {
                  setConfirmDelete(true);
                } else {
                  onClose();
                }
              });
            }
          }}
        />
      )}

      {confirmDelete && (
        <DialogError
          open={Boolean(confirmDelete)}
          content={translate(intl, "deleteOrderInBillWarning")}
          onClose={() => setConfirmDelete(false)}
        />
      )}
      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
    </>
  ) : (
    ""
  );
};

LabOrderModal.defaultProps = {
  //value: "987be0af-5ee2-4883-8cac-02ac92548e31" //id
  value: "", //id,
  buttonHidden: false
};

LabOrderModal.propTypes = {};

export default LabOrderModal;
