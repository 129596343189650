import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import {
  CCDialog,
  CCDialogActions,
  CCDialogTitle,
  CCTypography
} from "styles/components";
import CCDialogContent from './CCDialogContent'
const useStyles = makeStyles(() => ({
  dialog: { position: "relative" },
  actions: { justifyContent: "space-between" }
}));

const CCDialogSimple = props => {
  const {
    classes: userClasses,
    open,
    onClose,
    isHeader = true,
    title,
    contents,
    contentsPadding,
    startActions,
    endActions,
    onPrintButton,
    onPrintButtonF,
    printRef,
    onBeforeGetContent,
    onBeforePrint,
    onAfterPrint,
    onCloseButton,
    isRefreshButton = true,
    onRefreshButton,
    onRendered,
    isDeleteButton = true,
    onDeleteButton,
    maxWidth,
    fullWidth,
    isExportButton = true,
    onExportButton,
    PaperProps,
    fullScreen = false,
    disableTypography = false
  } = props;
  const classes = useStyles();

  return (
    <CCDialog
      className={clsx(classes.dialog, userClasses && userClasses.root)}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      onRendered={onRendered}
      PaperProps={PaperProps}
    >
      {isHeader && (
        <CCDialogTitle
          onClick={event => {
            event.stopPropagation();
            // event.preventDefault()
          }}
          disableTypography={disableTypography}
          classes={{ root: userClasses && userClasses.title }}
          onPrintButton={onPrintButton ? onPrintButton : undefined}
          onPrintButtonF={onPrintButtonF ? onPrintButtonF : undefined}
          printRef={onPrintButton && printRef ? printRef : undefined}
          onBeforeGetContent={
            onBeforeGetContent ? onBeforeGetContent : undefined
          }
          onBeforePrint={onBeforePrint ? onBeforePrint : undefined}
          onAfterPrint={onAfterPrint ? onAfterPrint : undefined}
          onRefreshButton={
            onRefreshButton && isRefreshButton ? onRefreshButton : undefined
          }
          onCloseButton={onCloseButton ? onCloseButton : undefined}
          onDeleteButton={
            onDeleteButton && isDeleteButton ? onDeleteButton : undefined
          }
          onExportButton={
            onExportButton && isExportButton ? onExportButton : undefined
          }
        >
          {title}
        </CCDialogTitle>
      )}
      <CCDialogContent noPadding={!contentsPadding}>
        {typeof contents === "string" && (
          <CCTypography
            className={classes.content__typography}
            variant="body1"
            component="div"
          >
            {contents}
          </CCTypography>
        )}
        {typeof contents !== "string" && contents}
      </CCDialogContent>
      {(startActions || endActions) && (
        <CCDialogActions
          className={clsx(classes.actions, userClasses && userClasses.actions)}
        >
          <Box>{startActions}</Box>
          <Box>{endActions}</Box>
        </CCDialogActions>
      )}
    </CCDialog>
  );
};

CCDialogSimple.propTypes = {
  /** Dialog 유무 */
  open: PropTypes.bool,
  /** Dialog close 버튼 클릭 시 실행되는 함수 */
  onClose: PropTypes.func,
  /** Dialog 제목 */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  /** Dialog 내용(content)에 작성될 내용 */
  contents: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  /** Dialog 하단 왼쪽 버튼 */
  startActions: PropTypes.element,
  /** Dialog 하단 오른쪽 버튼 */
  endActions: PropTypes.element,
  /** Dialog 닫기 버튼 클릭 시 실행되는 함수 */
  onCloseButton: PropTypes.func,
  /** Dialog 새로고침 버튼 클릭 시 실행되는 함수 */
  onRefreshButton: PropTypes.func,
  /** Dialog 프린트 버튼 클릭 시 실행되는 함수 */
  onPrintButton: PropTypes.func,
  /** Dialog 내보내기 버튼 클릭 시 실행되는 함수 */
  onExportButton: PropTypes.func,
  /** Dialog 내용(content) padding 유무 */
  contentsPadding: PropTypes.bool,
  /** material ui 기준으로 width 설정 */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** fullWidth 설정 */
  fullWidth: PropTypes.bool
};

CCDialogSimple.defaultProps = {
  open: false,
  contentsPadding: true
};

export default CCDialogSimple;
