import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { GET_UNIQUE_CATEGORY } from "queries/product"
import CCTypography from "styles/src/components/CCTypography";
import { useIntl } from "utils/language";
import { InoutTab, ProductTab } from "./Component";

const useStyle = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    minWidth: 1430
  },
  tabList: {
    padding: "9px 16px",
    borderBottom: `1px solid ${theme.palette.border}`
  },
  tabs: {
    padding: "0 10px",
    height: 36,
    minWidth: 113,
    borderRadius: 25,
    backgroundColor: theme.palette.grey[500],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 16,
    "& span": {
      color: theme.palette.common.white
    },
    "&:hover": {
      backgroundColor: theme.palette.select.hover
    }
  },
  selected: {
    backgroundColor: theme.palette.select.main
  }
}));

export const TabContext = createContext({
  setTabs: () => { },
  setProduct: () => { }
});

const Stock = props => {
  const { keyId } = props;
  const classes = useStyle();
  const [tabs, setTabs] = useState("0");
  const [product, setProduct] = useState(keyId || "");
  const intl = useIntl();
  // let history = useHistory();

  const tabList = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "productInventoryStatus" }),
        value: "0"
      },
      {
        label: intl.formatMessage({ id: "inventoryHistory" }),
        value: "1"
      }
    ],
    [intl]
  );

  const { data } = useQuery(GET_UNIQUE_CATEGORY, {
    variables: {
      query: {
        isOnlyForLab: false
      }
    },
  });

  useEffect(() => {
    if (keyId) {
      setProduct({
        productID: keyId
      });
      setTabs("0");
      // history.replace();
    }
  }, [keyId]);

  return (
    <Box className={classes.root}>
      <Grid container className={classes.tabList}>
        {tabList.map(item => {
          return (
            <Grid
              key={JSON.stringify(item)}
              className={clsx(classes.tabs, {
                [classes.selected]: item.value === tabs
              })}
              item
              onClick={() => {
                setTabs(item.value);
                setProduct(null);
              }}
            >
              <CCTypography variant={"h5"} component={"span"}>
                {item.label}
              </CCTypography>
            </Grid>
          );
        })}
      </Grid>
      <TabContext.Provider value={{ setTabs, setProduct }}>
        {tabs === "0" && <ProductTab product={product} keyId={keyId} majorProps={data?.getUniqueCategory || []} />}
        {tabs === "1" && <InoutTab product={product} majorProps={data?.getUniqueCategory || []} />}
      </TabContext.Provider>
    </Box>
  );
};

export default Stock;
