import { Box, Grid, makeStyles } from "@material-ui/core";
import {
  PhoneNumberFieldNoMask as PhoneNumberField, PhoneNumberRegionField,
  CVButton,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCDialogActions,
  CCDialogContent,
  CCDialogTitle,
  CCDialogTitleAddMod as ModifyDialog,
  CCPaper,
  CCTextField,
  CCDialogInputError
} from "styles/components";
import { CheckIcon } from "styles/icons";
import { BankField } from "../";
import { CVTextField, translate, } from "components";
import { CCTypography } from "../../../../../../../styles/src/components";

const useStyles = makeStyles(theme => ({
  root: {
    height: "539px",
    width: "624px",
    margin: "19px 33px"
  },
  icon: { margin: 0, color: theme.palette.sub.main },
  panel__content: {
    height: "calc( 100% - 68px - 68px - 32px )"
  },
  input: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%"
  },
  left__field: {
    width: "98px"
  },
  right__domain: {
    width: "171px"
  },
  right__account: {
    width: "329px",
    left: "-1px"
  },
  title: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const EmailRegExp =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*(\.[a-zA-Z]{2,})$/i;

const InputPanel = props => {
  const { item, onChange } = props;
  const intl = useIntl();
  const classes = useStyles();

  const [name, setPartnerName] = useState("");
  const [ein, setEin] = useState("");
  const [tel, setPhoneNumber] = useState("");
  const [fax, setFaxNumber] = useState("");
  const [email, setEmail] = useState("");
  const [bank, setBank] = useState("");
  const [bankAccountNo, setBankAccountNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [representativeName, setManager] = useState("");
  const [representativeContact, setManagerCellNo] = useState("");
  const [notes, setNotes] = useState("");
  const [errorsEmail, setErrorsEmail] = useState(false);
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);
  const [openModify, setOpenModify] = useState(false);

  const onEditTitle = newValue => {
    setPartnerName(newValue);
    // onChange({
    //   ...item,
    //   name: newValue
    // });
  };
  const onChangeNumber = (newValue, setValue) => {
    if (!isNaN(newValue)) {
      var trimmed = newValue.replace(/\.|\s/g, "");
      setValue(trimmed);
    }
  };
  const reset = useCallback(() => {
    if (item) {
      const {
        name,
        ein,
        tel,
        fax,
        email,
        bank,
        bankAccountNo,
        holder,
        manager,
        managerCellNo,
        notes
      } = item;
      setPartnerName(name ? name : "");
      setEin(ein ? ein : "");
      setPhoneNumber(tel ? tel : "");
      setFaxNumber(fax ? fax : "");
      setEmail(email ? email : "");
      setBank(bank ? bank : "");
      setBankAccountNumber(bankAccountNo ? bankAccountNo : "");
      setAccountHolder(holder ? holder : "");
      setManager(manager ? manager : "");
      setManagerCellNo(managerCellNo ? managerCellNo : "");
      setNotes(notes ? notes : "");
      setErrorsEmail(false);
    }
  }, [item]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    setErrorsEmail(!email || (email && !EmailRegExp.test(email)));
  }, [email]);

  return (
    <CCPaper className={classes.root}>
      <CCDialogTitle disableTypography>
        <CCTypography className={classes.title} variant="h3">{name}</CCTypography>
        {/* <CCIconButton
          className={classes.icon}
          onClick={() => setOpenModify(true)}
        >
          <ModifyIcon />
          </CCIconButton> */}
      </CCDialogTitle>
      <CCDialogContent className={classes.panel__content}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Box width="259px">
                  <CVTextField
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    label={translate(
                      intl,
                      "settings.otherPartners.partnerName"
                    )}
                    value={name}
                    InputProps={{
                      inputProps: {
                        maxLength: 50
                      }
                    }}
                    onChange={({ target }) => setPartnerName(target.value)}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box width="239px">
                  <CCTextField
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    label="BRN"
                    value={ein}
                    InputProps={{
                      inputProps: {
                        maxLength: 20
                      }
                    }}
                    onChange={({ target }) =>
                      onChangeNumber(target.value, setEin)
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Box width="171px">
                  <PhoneNumberRegionField
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    label={translate(intl, "common.ui.telNo")}
                    value={tel}
                    InputProps={{
                      inputProps: {
                        maxLength: 20
                      }
                    }}
                    onChange={({ target }) => setPhoneNumber(target.value)}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box width="171px">
                  <PhoneNumberField
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    label={translate(intl, "common.ui.faxNo")}
                    value={fax}
                    InputProps={{
                      inputProps: {
                        maxLength: 20
                      }
                    }}
                    onChange={({ target }) => setFaxNumber(target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {/* <EmailField
              onChange={({ target }) => setEmail(target.value)}
              value={email}
              emailClassName={classes.left__field}
              domainClassName={classes.right__domain}
            /> */}
            <Box width="259px">
              <CVTextField
                className={classes.input}
                variant="outlined"
                margin="dense"
                label={translate(intl, "common.ui.email")}

                InputProps={{
                  inputProps: {
                    maxLength: 200
                  }
                }}
                error={errorsEmail}
                value={email}
                onChange={({ target }) => {
                  const _email = target.value;
                  setEmail(_email);
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <BankField
                  variant="outlined"
                  margin="dense"
                  bankClassName={classes.left__field}
                  bankLabel={translate(intl, "common.ui.bank")}
                  bankValue={bank}
                  accountClassName={classes.right__account}
                  accountLabel={translate(intl, "common.ui.bankAccountNo")}
                  accountNumber={bankAccountNo}
                  onChange={value => {
                    setBank(value.bank);
                    setBankAccountNumber(value.account);
                  }}
                  errorOnChange={error => { console.log('error', error) }}
                />
              </Grid>
              <Grid item>
                <Box width="98px">
                  <CVTextField
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    label={translate(intl, "settings.lab.holder")}
                    InputProps={{
                      inputProps: {
                        maxLength: 50
                      }
                    }}
                    value={accountHolder}
                    onChange={({ target }) => setAccountHolder(target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Box width="98px">
                  <CVTextField
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    label={translate(intl, "common.ui.manager")}
                    InputProps={{
                      inputProps: {
                        maxLength: 50
                      }
                    }}
                    value={representativeName}
                    onChange={({ target }) => setManager(target.value)}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box width="171">
                  <PhoneNumberRegionField
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    label={translate(intl, "common.ui.managerCellNoShort")}
                    value={representativeContact}
                    onChange={({ target }) => setManagerCellNo(target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CCTextField
              className={classes.input}
              variant="outlined"
              margin="dense"
              multiline
              fullWidth
              rows={3}
              label={translate(intl, "payment.bill.notes")}
              InputProps={{
                inputProps: {
                  maxLength: 500
                }
              }}
              value={notes}
              onChange={({ target }) => setNotes(target.value)}
            />
          </Grid>
        </Grid>
      </CCDialogContent>
      <CCDialogActions>
        <CVButton
          variant="text"
          color="sub"
          onClick={() => {
            reset();
          }}
        >
          {translate(intl, "common.ui.cancel")}
        </CVButton>
        <CVButton
          variant="contained"
          onClick={() => {
            if (errorsEmail) {
              setOpenErrorsDialog(true)
              return;
            }
            onChange({
              ...item,
              name: name,
              ein: ein,
              tel: tel,
              fax: fax,
              email: email,
              bank: bank,
              bankAccountNo: bankAccountNo,
              holder: accountHolder,
              manager: representativeName,
              managerCellNo: representativeContact,
              notes: notes
            });
          }}
          startIcon={<CheckIcon />}
        >
          {translate(intl, "common.ui.save")}
        </CVButton>
      </CCDialogActions>
      <ModifyDialog
        title="Title edit"
        open={openModify}
        onClose={() => setOpenModify(false)}
        value={name}
        onSave={value => {
          onEditTitle(value);
          setOpenModify(false);
        }}
      />
      {Boolean(openErrorsDialog) && (
        <CCDialogInputError
          open={Boolean(openErrorsDialog)}
          title={translate(intl, 'signUp.error.email')}
          onClose={() => setOpenErrorsDialog(false)}
        />
      )}
    </CCPaper>
  );
};

export default InputPanel;
