import React from "react";

const DiscountIcon18 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M9 1.5c4.142 0 7.5 3.358 7.5 7.5 0 4.142-3.358 7.5-7.5 7.5-4.142 0-7.5-3.358-7.5-7.5 0-4.142 3.358-7.5 7.5-7.5zm2.17 7.655c-.614 0-1.115.182-1.502.544-.344.322-.535.736-.573 1.24l-.007.194v.488c0 .602.191 1.085.573 1.45.383.365.89.547 1.523.547.629 0 1.132-.181 1.51-.544.336-.322.522-.734.56-1.235l.006-.192v-.475c0-.628-.192-1.121-.576-1.48-.385-.358-.89-.537-1.513-.537zm-.447-4.39l-4.687 7.502.93.5 4.686-7.5-.93-.502zm.448 5.491c.246 0 .444.08.593.24.125.134.197.304.218.51l.006.127v.5c0 .594-.268.89-.804.89-.237 0-.435-.085-.593-.256-.127-.138-.203-.297-.228-.48l-.01-.14v-.494c0-.277.075-.496.225-.656.15-.16.347-.24.593-.24zM6.576 3.75c-.62 0-1.12.183-1.503.55-.34.327-.528.74-.566 1.242l-.007.192v.468c0 .615.192 1.104.577 1.467.384.362.889.544 1.513.544.62 0 1.12-.18 1.503-.54.34-.321.528-.734.566-1.238l.007-.193v-.475c0-.62-.191-1.11-.573-1.473-.383-.363-.888-.544-1.517-.544zm0 1.1c.255 0 .455.082.6.245.116.13.186.292.21.487l.008.152v.488c0 .272-.073.49-.218.653-.145.162-.34.243-.586.243-.246 0-.445-.08-.597-.24-.126-.134-.2-.303-.221-.509l-.006-.127V5.76c0-.285.074-.508.224-.669.15-.16.345-.24.586-.24z"
      />
      <path d="M0 0H18V18H0z" />
    </g>
  </svg>
);
export default DiscountIcon18;
