import React from "react";

const ExplorerIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M17 12c2.21 0 4 1.79 4 4 0 .658-.166 1.276-.456 1.82l-.114.2L23 20.59 21.59 22l-2.57-2.57c-.59.35-1.28.57-2.02.57-2.21 0-4-1.79-4-4s1.79-4 4-4zM9 3l2 2h8c1.05 0 1.918.82 1.994 1.851L21 7l.001 5.688C20.045 11.536 18.604 10.8 17 10.8c-2.86 0-5.2 2.34-5.2 5.2 0 1.115.356 2.152.96 3.001L3 19c-1.05 0-1.918-.82-1.994-1.851L1 17l.01-12c0-1.05.81-1.918 1.841-1.994L3 3h6zm8 11c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            transform="translate(-5074 -264) translate(5074 264)"
          />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5074 -264) translate(5074 264)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default ExplorerIcon;
