import NotoSansBlack from "./NotoSansKR-Black.ttf";
import NotoSansBold from "./NotoSansKR-Bold.ttf";
import NotoSansDemiLight from "./NotoSansKR-DemiLight.ttf";
import NotoSansLight from "./NotoSansKR-Light.ttf";
import NotoSansMedium from "./NotoSansKR-Medium.ttf";
import NotoSansRegular from "./NotoSansKR-Regular.ttf";
import NotoSansThin from "./NotoSansKR-Thin.ttf";

const NotoSans = {
  Black: NotoSansBlack,
  Bold: NotoSansBold,
  DemiLight: NotoSansDemiLight,
  Light: NotoSansLight,
  Medium: NotoSansMedium,
  Regular: NotoSansRegular,
  Thin: NotoSansThin
};
export { NotoSans };
