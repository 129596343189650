import { Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { IconMenu } from "../";

const useStyles = makeStyles(theme => ({
  root: {},
  ma: { marginTop: 8 },
  border: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    margin: "12px 4px"
  }
}));
const usePopoverStyles = makeStyles(theme => ({
  paper: { borderRadius: 20, padding: 12, width: 310, minWidth: 310 }
}));

const DialogSmallSizeInfo = props => {
  const { open, anchorEl, onClose } = props;
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();

  return (
    <Popover
      classes={popoverClasses}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <div className={classes.border} />
      <IconMenu />
    </Popover>
  );
};

export default DialogSmallSizeInfo;
