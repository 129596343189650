import { InputAdornment, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { default as PropTypes, default as propTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  CCFlattenDatePicker,
  CCIconButton,
  CCTextField
} from 'styles/components'
import { DateIcon, MoonborderIcon, MoonIcon } from 'styles/icons'
// import { CVFlattenDatePicker } from "components";
import { monthYearFormat } from '../../../../../utils/utils'

const useStyle = makeStyles(
  theme => ({
    root: {},
    dateButton: {
      color: theme.palette.icon
    },
    selected__button: {
      color: theme.palette.select.main
    },
    padding__right: { paddingRight: 8 },
    disabled: {
      color: theme.palette.disabled
    }
  }),
  { name: 'MonthPicker' }
)
const MonthPicker = props => {
  const {
    value,
    onChange,
    lunar,
    lunarOnChange,
    label,
    disabled,
    dateIcon,
    dateIconTooltip,
    components,
    checkedList,
    onChangeHeader,
    onPickerChange = () => { },
    onClickIconPermission,
    mode,
    needWeek,
    preserveCurrentTime,
    forceFormat,
    ...others
  } = props

  const classes = useStyle()
  const lang = localStorage.getItem('lang')
  const dateFormat = monthYearFormat(lang)

  const [pickerOpen, setPickerOpen] = useState(false)
  const [momentValue, setMomentValue] = useState(value)
  const [textValue, setTextValue] = useState(value.clone().format(dateFormat))

  const Icon = dateIcon || DateIcon
  const tooltip = dateIconTooltip ? { tooltip: dateIconTooltip } : {}

  useEffect(() => {
    if (!value) {
      setTextValue('')
      return
    }
    setMomentValue(prevState => {
      if (prevState === false) {
        return value
      } else if (value.format(dateFormat) !== prevState.format(dateFormat)) {
        return value
      } else {
        return prevState
      }
    })
    setTextValue(`${value.clone().format(dateFormat)}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, momentValue, mode])

  return (
    <>
      <CCFlattenDatePicker
        open={pickerOpen}
        value={momentValue === false ? moment() : momentValue}
        onChange={e => {
          onChange(e)
          setPickerOpen(false)
          onPickerChange(false)
        }}
        onClose={() => {
          setPickerOpen(false)
          onPickerChange(false)
        }}
        components={components}
        checkedList={checkedList}
        onChangeHeader={onChangeHeader}
        mode={mode}
        needWeek={needWeek}
        preserveCurrentTime={preserveCurrentTime}
      />
      <CCTextField
        {...others}
        disabled={disabled}
        variant='outlined'
        margin='dense'
        label={label}
        value={textValue}
        // onChange={(e) => setTextValue(e.target.value)}
        onBlur={() =>
          setTextValue(
            momentValue === false ? '' : momentValue.format(dateFormat)
          )}
        InputProps={{
          classes: { adornedEnd: classes.padding__right },
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>
              <CCIconButton
                size='small'
                onClick={() => {
                  if (onClickIconPermission) {
                    onClickIconPermission()
                    return
                  }
                  if (disabled) return
                  setPickerOpen(true)
                  onPickerChange(true)
                }}
                {...tooltip}
              >
                <Icon
                  className={clsx(classes.dateButton, {
                    [classes.disabled]: disabled
                  })}
                />
              </CCIconButton>
              {lunarOnChange && (
                <CCIconButton
                  size='small'
                  onClick={() => {
                    if (onClickIconPermission) {
                      onClickIconPermission()
                      return
                    }
                    if (disabled) return
                    lunarOnChange(!lunar)
                  }}
                  tooltip={lunar ? '음력' : '양력'}
                  className={clsx({
                    [classes.selected__button]: lunar,
                    [classes.disabled]: disabled
                  })}
                >
                  {lunar ? <MoonIcon /> : <MoonborderIcon />}
                </CCIconButton>
              )}
            </InputAdornment>
          )
        }}
      />
    </>
  )
}

MonthPicker.propTypes = {
  value: propTypes.oneOfType([
    propTypes.instanceOf(moment),
    propTypes.bool,
    propTypes.shape({
      begin: propTypes.instanceOf(moment),
      end: propTypes.instanceOf(moment)
    })
  ]).isRequired,
  onChange: propTypes.func.isRequired,
  lunar: propTypes.bool,
  lunarOnChange: propTypes.func,
  label: propTypes.string,
  mode: PropTypes.oneOf(['day', 'week']),
  // readOnly: propTypes.bool
  needWeek: PropTypes.bool,
  preserveCurrentTime: propTypes.bool
}
MonthPicker.defaultProps = {
  // readOnly: true
  mode: 'day',
  label: '날짜',
  needWeek: false,
  preserveCurrentTime: false
}

export default MonthPicker
