import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import {
  CVButton,
  DialogPermissionWarn,
  Loading,
  translate,
  TypographyTooltip,
  useWritePermission,
  InfiniteTable
} from "components";
import { TeethGraphItem } from "components/Chart/components/ChartInput/components/PlanTab/components/TreatmentPlanList/components";
import CVMenu from "components/CVMenu";
import CVTable from "components/CVTable";
import DentalRequestDialog from "components/DentalLabRequestDialog";
import LabOrderProcessModal from "../LabOrderProcessModal";
import { AppContext } from "context";
import moment from "moment";
import {
  CREATE_ORDER,
  GET_LAB_ORDER,
  MARK_MESSAGE_READ,
  UPDATE_ORDER_STATUS,
  ON_LAB_ORDER_UPDATED
} from "queries/labOrder";
import { GET_STAFF_LIST } from "queries/setting";
import { InfoIcon } from "styles/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCDialogSimple,
  CCIconButton,
  CCTooltip,
  CCTypography
} from "styles/src/components";
import {
  AddIcon,
  SaveIcon,
  SMSIcon,
  WarningIcon
} from "styles/src/themes/common/icons";
import {
  LAB_ARRIVE_TYPE,
  LAB_ORDER_FILTER_STATE,
  LAB_ORDER_TYPE
} from "types/labOrder";
import { makeIndexFormattedMessageList } from "types/utils";
import { formatCommas } from "utils/number";
import { v4 as uuid } from "uuid";
import { LabOrderInput, LabOrderModal } from "../../../";
import {
  CCSearchTextField,
  DialogError,
  DialogErrorConfirm
} from "components";
import { IS_ORDER_BELONGS_TO_PAYMENT } from "../../../../../../../queries/payment";
import { LabOrderPrint } from "../../../LabOrderModal/components";
import LabOrderFilter from "../LabOrderFilter";
import LabOrderChangeStatusBar from "../LabOrderChangeStatusBar";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/utils/TableItems";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: "8px 0",
  },
  table: {
    height: "100%"
  },
  mouse__hover: {
    cursor: "pointer"
  },
  br: {
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  prosthesis: {
    minWidth: 170,
    whiteSpace: "wrap",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    wordBreak: "break-word"
  },
  table__box: {
    position: "relative",
    flex: 1,
    minHeight: '400px'
  },
  padding8: {
    padding: 8
  },
  side__padding: {
    paddingLeft: 8,
    paddingRight: 8
  },
  table__top: {
    borderBottom: "1px solid #dcdcdc"
  },
  padding__result: {
    paddingTop: 8,
    paddingBottom: 8
  },
  content: {
    padding: "0 16px"
  },
  warning___color: { color: theme.palette.error.main },
  text__ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  teeth__minwidth160: {
    minWidth: "160px"
  },
  width130: {
    width: "130px"
  },
  width__prothesis: {
    minWidth: "130px",
    width: "170px",
  },
  text__fitcontent: {
    width: "fit-content"
  },
  divideHorizontal: {
    background: theme.palette.border.main,
    height: "36px",
    margin: "0 16px",
    width: 1
  },
  button__neworder: {
    background: theme.palette.primary.main,
    textTransform: 'none',
    marginRight: 8,
    color: 'white',
    fontWeight: 'bold'
  },
  button__process: {
    textTransform: 'none',
    fontWeight: 'bold'
  },
  textfield: {
    width: 384
  },
  table__body: {
    overflow: 'overlay'
  },
  table__head__row: {
    paddingRight: 0
  },
  button: {
    textTransform: "unset",
  },
  checkbox__cell: {
    margin: 'auto 0'
  },
  text__clinic__clever: {
    color: "#00897b"
  }
}));

const useStylesChip = makeStyles((theme) => ({
  root: {
    color: 'white',
    whiteSpace: 'break-spaces',
  },
  label: {
    whiteSpace: 'break-spaces',
    lineHeight: 1.1
  },
  1: { background: "#ffffff", color: '#01579b !important', border: `1px solid ${theme.palette.border.main}`, }, //order
  2: { background: "#40c4ff" }, //cad design
  3: { background: "rgba(0, 0, 0, 0.38)" }, //confirm req
  4: { background: "rgba(0, 0, 0, 0.38)" }, //confirm
  5: { background: "#ffab00" }, //in production
  6: { background: "#01579b" }, //complete
  7: { background: "#00897b" }, //shipped
  awaiting: { background: "#e0e0e0​", color: '#rgba(0, 0, 0, 0.87)​' },
  arrived: { background: "rgba(0, 0, 0, 0.78) !important", color: '#ffffff' },

}))

const LabOrderTotal = props => {
  const { dispatch, permission, onModify, state } = props;
  const { labSetting, searchVariables } = state;

  const classes = useStyles();
  const classesChip = useStylesChip();
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const isPermission = useWritePermission(permission);
  const labOrderState = makeIndexFormattedMessageList(LAB_ORDER_FILTER_STATE);
  const labOrderType = makeIndexFormattedMessageList(LAB_ORDER_TYPE);

  const [laboratoryOpen, setLaboratoryOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPermissionWarn, setOpenPermissionWarn] = useState(false);
  const [openDetail, setOpenDetail] = useState(false); //false
  const [isLoading, setIsLoading] = useState(false); //false
  const [openNewOrder, setOpenNewOrder] = useState(false); //false
  const [openEmail, setOpenEmail] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContent, setSelectContent] = useState({});
  const [mailOpen, setMailOpen] = useState(false);
  const [openDialogCheckDataLabOrder, setOpenDialogCheckDataLabOrder] =
    useState();
  const [confirmChangePaidStatus, setConfirmChangePaidStatus] = useState(false);
  const [confirmChangeCleverStatus, setConfirmChangeCleverStatus] =
    useState(false);
  const [listIdOrderChecked, setListIdOrderChecked] = useState([]);
  // const [cursorId, setCursorId] = useState()
  const [searchLabData, setSearchLabData] = useState({
    hasNext: false,
    items: [],
    hasPrev: false,
    total: 0,
  })
  const inputRef = useRef();

  const { data: isOrderBelongsToPaidPaymentData, refetch } = useQuery(
    IS_ORDER_BELONGS_TO_PAYMENT,
    {
      fetchPolicy: "network-only",
      variables: {
        ids: selectedContent?.id ? [selectedContent.id] : listIdOrderChecked
      }
    }
  );
  const {
    data: searchLabDataGraphql,
    loading: searchLabLoading,
    refetch: searchLabRefetch,
    fetchMore,
    subscribeToMore
  } = useQuery(GET_LAB_ORDER, {
    variables: {
      query: {
        ...searchVariables,
        limit: 1000,
        // cursor: cursorId
      }
    },
    skip: !appContext.waitCheckNotify,
    fetchPolicy: "network-only",
    pollInterval: 3000
  });

  useEffect(() => {
    if (searchLabDataGraphql?.orderList)
      setSearchLabData(searchLabDataGraphql)
  }, [searchLabDataGraphql])

  // const handleLoadMore = () => {
  //   searchLabRefetch({
  //     variables: {
  //       ...searchVariables,
  //       limit: 30,
  //       // cursor: searchLabData
  //     }
  //   }).then((res) => {
  //     console.log('response', res)
  //   })
  // }

  useQuery(GET_STAFF_LIST, {
    variables: {
      query: { limit: 20, searchText: "" }
    },
    onCompleted: data => {
      dispatch({
        type: "CHANGE_VALUE",
        value: { staff: data?.getStaffList?.items }
      });
    }
  });
  const [createOrder] = useMutation(CREATE_ORDER, {
    variables: {
      labId: localStorage.getItem("labId")
    },
    // onCompleted: data => {
    //   searchLabRefetch();
    // },
    awaitRefetchQueries: true
  });
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS, {
    // onCompleted: data => {
    //   searchLabRefetch();
    // }
  });
  const [markMessageRead] = useMutation(MARK_MESSAGE_READ, {
    onCompleted: data => {
      searchLabRefetch();
    }
  });

  const handleSearchVariables = value => {
    dispatch({
      type: "CHANGE_SEARCH_VARIABLES",
      value: value
    });
  };
  const handleSearchTextClick = () => {
    handleSearchVariables({ keyword: inputRef.current.value });
  };
  const onSave = async labOrder => {
    await createOrder({
      variables: {
        requestId: labOrder.requestId || uuid(),
        requestDate: labOrder.requestDate,
        deliveryRequestDate: labOrder.deliveryRequestDate,
        type: labOrder.type,
        clinic: labOrder.clinic,
        patient: labOrder.patient,
        teeth: labOrder.teeth,
        prosthesis: labOrder.prosthesis,
        unites: labOrder.teeth.length,
        used: labOrder.used,
        consume: labOrder.consume,
        status: labOrder.status,
        arrival: labOrder.arrival,
        mailCheck: labOrder.mailCheck,
        manufacture: labOrder.manufacture,
        photo: labOrder.photo,
        pontic: labOrder.pontic,
        shade: labOrder.shade,
        missings: labOrder.missing,
        detail: labOrder.detail,
        staffId: labOrder.staffId,
        note: labOrder.note,
        doctor: labOrder.doctor
      }
    }).then(() => {
      searchLabRefetch();
      // refetch();
    });
  };
  const changeStatusOrder = e => {
    if (!isPermission) {
      setOpenPermissionWarn(true);
      return;
    }
    if (
      isOrderBelongsToPaidPaymentData.isOrderBelongsToPaidPayment
        .isOrdersBelongToSendCleverPayment
    ) {
      setConfirmChangeCleverStatus(true);
    } else {
      updateOrderStatus({
        variables: {
          ids: [selectedContent.id],
          status: e.target.value.toString()
        }
      }).then((rs) => {
        if (rs.data?.updateOrderStatus?.code === "ORDER02") {
          setConfirmChangeCleverStatus(true);
        }
        searchLabRefetch();
        // refetch();
      });
    }
  };
  const changeAllStatusOrder = status => {
    if (
      isOrderBelongsToPaidPaymentData.isOrderBelongsToPaidPayment
        .isOrdersBelongToSendCleverPayment
    ) {
      setConfirmChangeCleverStatus(true);
    } else if (
      isOrderBelongsToPaidPaymentData.isOrderBelongsToPaidPayment
        .isOrdersBelongToPaidPayment
    ) {
      setConfirmChangePaidStatus(true);
    } else {
      updateOrderStatus({
        variables: {
          ids: listIdOrderChecked,
          status
        }
      }).then(() => {
        setListIdOrderChecked([]);
        searchLabRefetch()
      });
    }
  };
  const handleOnCloseNewOrderDialog = () => {
    dispatch({ type: "RESET_LAB_ORDER" });
    setOpenNewOrder(false);
  };
  const tempSaveClick = () => {
    if (!state.labOrder.clinic) {
      setOpenDialogCheckDataLabOrder(
        translate(intl, "common.messages.theClinicSFieldIsRequested")
      );
      return;
    }
    if (!state.labOrder.patient.name) {
      setOpenDialogCheckDataLabOrder(
        translate(intl, "common.messages.thePatientFieldIsRequested")
      );
      return;
    }
    if (!state.labOrder.teeth.length > 0) {
      setOpenDialogCheckDataLabOrder(
        translate(intl, "common.messages.theTeethSFieldIsRequested")
      );
      return;
    }

    if (!state.labOrder.prosthesis) {
      setOpenDialogCheckDataLabOrder(
        translate(intl, "common.messages.theProsthesisSFieldIsRequested")
      );
      return;
    }

    onSave(state.labOrder);
    handleOnCloseNewOrderDialog();
  };

  const eventComponent = ({
    component,
    data,
    componentClassName,
    noTooltips = false
  }) => {
    const className =
      componentClassName
        ? clsx(classes.mouse__hover, ...componentClassName)
        : clsx(classes.mouse__hover)

    return (
      component || (
        <TableItem label={data} tooltip={!noTooltips} className={className} />
      )
    );
  };

  const menuItemList = [
    {
      permission: permission,
      value: LAB_ORDER_FILTER_STATE.ORDERED.value,
      disabled:
        selectedContent?.status === LAB_ORDER_FILTER_STATE.ORDERED.value,
      label: translate(intl, "labOrder.ordered"),
      onClick: changeStatusOrder
    },
    {
      permission: permission,
      value: LAB_ORDER_FILTER_STATE.CAD_DESIGN.value,
      disabled:
        selectedContent?.status === LAB_ORDER_FILTER_STATE.CAD_DESIGN.value,
      label: translate(intl, "labOrder.filterCadDesign"),
      onClick: changeStatusOrder
    },

    {
      permission: permission,
      value: LAB_ORDER_FILTER_STATE.IN_PROD.value,
      label: translate(intl, "labOrder.inProd"),
      disabled:
        selectedContent?.status === LAB_ORDER_FILTER_STATE.IN_PROD.value,
      onClick: changeStatusOrder
    },
    {
      permission: permission,
      value: LAB_ORDER_FILTER_STATE.COMPLETE.value,
      label: translate(intl, "labOrder.filterComplete"),
      disabled:
        selectedContent?.status === LAB_ORDER_FILTER_STATE.COMPLETE.value,
      onClick: changeStatusOrder
    },
    {
      permission: permission,
      value: LAB_ORDER_FILTER_STATE.SHIPPED.value,
      disabled:
        selectedContent?.status === LAB_ORDER_FILTER_STATE.SHIPPED.value,
      label: translate(intl, "labOrder.shipped"),
      onClick: changeStatusOrder
    },
    {
      permission: permission,
      value: uuid(),
      label: translate(intl, "labOrder.detail"),
      onClick: () => {
        setOpenDetail(true);
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await searchLabRefetch()
      setIsLoading(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVariables]);

  const handleFetchMoreUp = ({ startIndex, stopIndex }) => {
    console.log("{ startIndex, stopIndex }", { startIndex, stopIndex })
    console.log("last cursor", searchLabData?.orderList?.items?.slice(-1)?.[0])
    fetchMore({
      variables: {
        query: {
          ...searchVariables,
          limit: 50,
          cursor: searchLabData?.orderList?.items?.slice(-1)?.[0]?.id,
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log("fetchMoreResult", fetchMoreResult, prev)
        if (!fetchMoreResult) return prev;
        const oldItems = prev.orderList.items;
        const newItems = fetchMoreResult.orderList.items;
        return {
          ...prev,
          orderList: {
            ...prev.orderList,
            items: [...oldItems, ...newItems],
            hasNext: fetchMoreResult.orderList.hasNext
          },

        }
      }
    });
  };
  return (
    <Box className={clsx(classes.root)}>
      <Grid
        container
        wrap={"nowrap"}
        alignItems={"center"}
        justify={"space-between"}
        className={classes.padding8}
      >
        <Grid item>
          <CCSearchTextField
            placeholder={intl.formatMessage({
              id: "labOrder.filterPlaceHolder"
            })}
            className={classes.textfield}
            variant="outlined"
            margin="dense"
            inputRef={inputRef}
            onKeyPress={e => {
              if (e.key === "Enter") {
                handleSearchTextClick();
              }
            }}
            onIconClick={handleSearchTextClick}
            onClear={() => {
              inputRef.current.value = "";
              handleSearchTextClick();
            }}
          />
        </Grid>
        <Grid item>
          <Grid container>
            <CVButton
              className={classes.button__process}
              variant={"outlined"}
              startIcon={<InfoIcon />}
              onClick={() => {
                setLaboratoryOpen(true);
              }}
            >
              {translate(intl, "common.ui.process")}
            </CVButton>
            <div className={classes.divideHorizontal} />
            <CVButton
              className={classes.button__neworder}
              permission={permission}
              variant={"outlined"}
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenNewOrder(true);
              }}
            >
              {translate(intl, "labOrder.newOrder")}
            </CVButton>
          </Grid>
        </Grid>
      </Grid>
      <LabOrderFilter
        handleSearchVariables={handleSearchVariables}
        permission={permission}
        listIdOrderChecked={listIdOrderChecked}
        changeAllStatusOrder={changeAllStatusOrder}
        labSetting={labSetting}
      />
      <LabOrderProcessModal
        open={laboratoryOpen}
        onClose={() => setLaboratoryOpen(false)}
      />
      <Grid
        container
        wrap={"nowrap"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className={clsx(classes.side__padding, classes.table__top)}
      >
        <Grid
          item
          className={clsx(classes.side__padding, classes.padding__result)}
        >
          <CCTypography
            variant={"h5"}
            align={"center"}
            color={"secondary"}
            display={"inline"}
          >
            {searchLabData?.orderList?.items?.length}
          </CCTypography>{" "}
          <CCTypography display={"inline"}>
            {translate(intl, "common.ui.results")}
          </CCTypography>
        </Grid>
      </Grid>

      <Box className={classes.table__box}>
        {(searchLabLoading || isLoading) ? (
          <Loading open={searchLabLoading || isLoading} zIndex={99} />
        ) : (
          <InfiniteTable
            permission={permission}
            // className={classes.table}
            // classes={{
            //   table__head__row: classes.table__head__row,
            //   table__body: classes.table__body
            // }}
            onRowClick={e => {
              setSelectContent(e.rowData);
              setAnchorEl(e.e.target);
            }}
            heads={[
              {
                key: "checked",
                width: 40,
                className: () => classes.checkbox__cell,

                headComponent: () => {
                  return (
                    <Grid container style={{ margin: "auto", height: '100%', marginLeft: 4 }} alignItems="center" >
                      <Checkbox
                        checked={
                          !!listIdOrderChecked &&
                          listIdOrderChecked.length !== 0 &&
                          listIdOrderChecked.length ===
                          searchLabData?.orderList?.items?.length
                        }
                        style={{ padding: 0 }}
                        disableRipple
                        onChange={e => {
                          setSelectContent({});
                          if (
                            listIdOrderChecked.length ===
                            searchLabData?.orderList?.items?.length
                          ) {
                            setListIdOrderChecked([]);
                          } else {
                            setListIdOrderChecked(
                              searchLabData?.orderList?.items?.map(
                                item => item.id
                              )
                            );
                          }
                        }}
                      />
                    </Grid>
                  );
                },
                component: ({ cellData, rowData }) => {
                  return (
                    <Checkbox
                      style={{ padding: "0 0 0 4px", height: "100%" }}
                      disableRipple
                      checked={
                        !!listIdOrderChecked.find(item => item === rowData.id)
                      }
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      onChange={e => {
                        setSelectContent({});
                        if (
                          !!listIdOrderChecked.find(item => item === rowData.id)
                        ) {
                          setListIdOrderChecked([
                            ...listIdOrderChecked.filter(
                              item => item !== rowData.id
                            )
                          ]);
                        } else {
                          setListIdOrderChecked([
                            ...listIdOrderChecked,
                            rowData.id
                          ]);
                        }
                      }}
                    />
                  );
                }
              },
              {
                label: translate(intl, "common.ui.orderNo"),
                key: "requestId",
                className: () => classes.cell,
                width: 120,
                component: ({ rowData }) =>
                  eventComponent({
                    data: <CCTypography>{rowData?.requestId}</CCTypography>
                  })
              },
              {
                label: translate(intl, "common.ui.orderDate"),
                key: "requestDate",
                className: () => classes.cell,
                width: 120,
                component: ({ rowData }) => {
                  return eventComponent({
                    data: moment.unix(rowData?.requestDate).format("L")
                  });
                }
              },

              {
                label: translate(intl, "common.ui.dueDate"),
                key: "dueDate",
                className: () => classes.cell,
                width: 120,
                component: ({ rowData }) => {
                  return eventComponent({
                    data:
                      rowData?.deliveryRequestDate &&
                      moment.unix(rowData?.deliveryRequestDate).format("L")
                  });
                }
              },
              {
                label: translate(intl, "common.ui.orderType"),
                key: "orderType",
                width: 100,
                className: () => classes.cell,
                component: ({ rowData }) =>
                  eventComponent({
                    data:
                      rowData?.type === LAB_ORDER_TYPE.NEW_REQUEST.value ? (
                        <CCTypography variant={"h5"} color={"secondary"}>
                          {labOrderType[rowData?.type]}
                        </CCTypography>
                      ) : (
                        labOrderType[rowData?.type]
                      )
                  })
              },
              {
                label: translate(intl, "common.ui.clinic"),
                key: "clinic",
                width: 130,
                className: () => classes.cell,
                component: ({ rowData }) => {
                  return (
                    eventComponent({
                      data: rowData?.clinic?.name,
                      componentClassName: [clsx(classes.text__ellipsis, classes.width130, { [classes.text__clinic__clever]: rowData?.clinic.createdBy === "Clever" })]
                    })
                  )
                }
              },
              {
                label: translate(intl, "common.ui.patientName"),
                key: "patient",
                width: 130,
                className: () => classes.cell,
                component: ({ rowData }) =>
                  eventComponent({
                    data: rowData?.patient.name,
                    componentClassName: [classes.text__ellipsis, classes.width130]
                  })
              },
              {
                label: translate(intl, "common.ui.toothNo"),
                key: "teeth",
                className: () => clsx(classes.cell, classes.teeth__minwidth160),
                width: 210,
                component: ({ rowData }) => {
                  return <TeethGraphItem cellData={rowData?.teeth} />;
                }
              },
              {
                label: translate(intl, "common.ui.prosthesis"),
                key: "prosthesis",
                width: 170,
                className: () => clsx(classes.cell, classes.br, classes.prosthesis),
                component: ({ rowData }) => {
                  return eventComponent({
                    data: rowData?.manufacture?.name,
                    componentClassName: [classes.text__ellipsis, classes.width__prothesis]
                  });
                }
              },
              {
                label: translate(intl, "common.ui.unites"),
                key: "unites",
                className: () => classes.cell,
                width: 90,
                component: ({ rowData }) =>
                  eventComponent({
                    data: rowData?.unites
                  })
              },
              {
                label: <div>{translate(intl, "labOrder.totalPrice")}</div>,
                key: "totalPrice",
                width: 130,
                className: () => clsx(classes.cell, classes.br),
                component: ({ rowData }) => {
                  const totalGoldPriceCalc = rowData?.clinicGoldPrice
                    ?.totalGoldPriceCalc
                    ? rowData?.clinicGoldPrice?.totalGoldPriceCalc
                    : 0;
                  const totalPrice =
                    rowData?.clinicProthesisPrice.totalPrice +
                    totalGoldPriceCalc;
                  return eventComponent({
                    data: formatCommas(totalPrice),
                    componentClassName: [classes.text__ellipsis]
                  });
                }
              },
              {
                label: translate(intl, "common.ui.status"),
                key: "status",
                width: 140,
                className: () => classes.cell,
                component: ({ rowData }) => {
                  return <Chip
                    label={labOrderState[rowData?.status]}
                    classes={{ label: classesChip.label }}
                    className={clsx(classesChip.root, classesChip[rowData?.status])}
                  />
                }
              },
              {
                label: translate(intl, "labOrder.arrive1"),
                key: "arrival",
                width: 120,
                className: () => classes.cell,
                component: ({ rowData }) => {
                  return eventComponent({
                    noTooltips: true,
                    data:
                      rowData?.arrival === LAB_ARRIVE_TYPE.ARRIVE.value ? (
                        <Chip
                          label={translate(intl, "labOrder.arrive")}
                          className={classesChip.arrived}
                        />
                      ) : rowData?.arrival ===
                        LAB_ARRIVE_TYPE.NON_ARRIVE.value ? (
                        <Chip label={translate(intl, "labOrder.awaiting")}
                          className={classesChip.awaiting}
                        />
                      ) : (
                        ""
                      )
                  });
                }
              },
              {
                label: translate(intl, "common.ui.message"),
                key: "memo",
                width: 100,
                className: () => classes.cell,
                component: ({ rowData }) => {
                  if (!rowData?.messageRead)
                    return (
                      <CCTooltip
                        placement="top"
                        arrow
                        title={translate(intl, "order.newMessages")}
                      >
                        <CCIconButton color={"primary"}>
                          <SMSIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectContent(rowData);
                              setOpenDetail(true);
                            }}
                          />
                        </CCIconButton>
                      </CCTooltip>
                    );
                }
              }
            ]}
            contents={searchLabData?.orderList?.items}
            rowStyle={(data) => listIdOrderChecked.includes(data.id) ? { backgroundColor: '#40c4ff' } : {}}
            hasNext={searchLabData?.orderList?.hasNext || false}
            // hasNext={true}
            onMoreContents={handleFetchMoreUp}
            rowCount={searchLabData?.data?.orderList?.total}
          />
        )}
      </Box>
      {
        listIdOrderChecked.length > 0 &&
        <LabOrderChangeStatusBar
          changeAllStatusOrder={changeAllStatusOrder}
          permission={permission}
          listIdOrderChecked={listIdOrderChecked}
          selectedOrders={listIdOrderChecked.length}
        />
      }

      <CCDialogSimple
        title={<WarningIcon className={classes.icon} />}
        open={open}
        onClose={() => setOpen(false)}
        contents={translate(intl, "labOrder.dateError")}
        endActions={
          <CVButton
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            {translate(intl, "common.ui.confirm")}
          </CVButton>
        }
      />
      {
        Boolean(anchorEl) && (
          <CVMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            menuItems={menuItemList}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          />
        )
      }

      {
        openDetail && (
          <LabOrderModal
            permission={permission}
            open={openDetail}
            onClose={() => {
              setOpenDetail(false);
              markMessageRead({
                variables: { orderId: selectedContent.id }
              })
                .then(() => {
                  searchLabRefetch();
                })
                .catch(e => {
                  searchLabRefetch();
                });
            }}
            value={selectedContent.id}
            onModify={onModify}
            refetchOrders={searchLabRefetch}
          />
        )
      }
      {
        openNewOrder && (
          <CCDialogSimple
            maxWidth={"md"}
            open={openNewOrder}
            contentsPadding={false}
            title={translate(intl, "labOrder.newOrder")}
            onCloseButton={handleOnCloseNewOrderDialog}
            onClose={handleOnCloseNewOrderDialog}
            contents={
              <Grid container className={classes.content}>
                <LabOrderInput
                  permission={permission}
                  state={state}
                  dispatch={dispatch}
                />
              </Grid>
            }
            endActions={
              <CVButton
                margin={"dense"}
                variant={"contained"}
                startIcon={<SaveIcon />}
                onClick={tempSaveClick}
                className={classes.button}
              >
                {translate(intl, "common.ui.save")}
              </CVButton>
            }
          />
        )
      }

      {
        openEmail && (
          <DentalRequestDialog
            open={openEmail}
            onClose={() => setOpenEmail(false)}
            id={[selectedContent.id]}
            onComplete={e => {
              setOpenEmail(false);
              if (e && e.split(",").length) {
                e.split(",").map(item => {
                  return {};
                });
              }
            }}
            onFail={e => {
              setMailOpen(true);
            }}
          />
        )
      }
      {
        openPrint && (
          <CCDialogSimple
            maxWidth={false}
            open={openPrint}
            title={"Qigong request form output"}
            onClose={() => setOpenPrint(false)}
            onCloseButton={() => setOpenPrint(false)}
            contents={
              <LabOrderPrint labOrderId={selectedContent.id} open={openPrint} />
            }
          />
        )
      }
      <CCDialogSimple
        open={mailOpen}
        onclose={() => setMailOpen(false)}
        title={"Failed to send"}
        contents={`Email transmission failed.`}
        endActions={
          <CVButton onClick={() => setMailOpen(false)}>
            {translate(intl, "common.ui.confirm")}
          </CVButton>
        }
      />
      <DialogErrorConfirm
        title={<WarningIcon className={classes.icon} />}
        open={Boolean(openDialogCheckDataLabOrder)}
        onClose={() => setOpenDialogCheckDataLabOrder(false)}
        contents={
          <CCTypography className={classes.warning___color}>
            {openDialogCheckDataLabOrder}
          </CCTypography>
        }
        onClick={() => setOpenDialogCheckDataLabOrder(false)}
      />
      <DialogPermissionWarn
        open={openPermissionWarn}
        onClose={() => {
          setOpenPermissionWarn(false);
        }}
      />
      {
        confirmChangePaidStatus && (
          <DialogError
            open={Boolean(confirmChangePaidStatus)}
            content={
              selectedContent.id
                ? translate(intl, "changeStatusAOrder")
                : translate(intl, "changeStatusManyOrder")
            }
            onClose={() => setConfirmChangePaidStatus(false)}
          />
        )
      }
      {
        confirmChangeCleverStatus && (
          <DialogError
            open={Boolean(confirmChangeCleverStatus)}
            content={translate(intl, "changeCleverStatusOrder")}
            onClose={() => setConfirmChangeCleverStatus(false)}
          />
        )
      }


    </Box >
  );
};

LabOrderTotal.defaultProps = {};

LabOrderTotal.propTypes = {};

export default LabOrderTotal;
