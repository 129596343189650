import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { CCIconButton, CCTable } from "styles/components";
import { AddIcon, SettingIcon } from "styles/src/themes/common/icons";

const toolbarHeight = 52;
const useStyles = makeStyles(theme => ({
  root: { minWidth: 400, height: "100%", position: "relative" },
  adornedEnd: { paddingRight: 8 },
  icon__btn: { borderRadius: 50, margin: 0 },
  textfield: { width: 200 },
  toolbar__container: {
    top: 0,
    right: 0,
    position: "absolute",
    zIndex: 1,
    padding: "0 8px",
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  table__container: { height: `calc(100% - ${toolbarHeight}px)` },
  table__container__item: { height: "100%", width: "100%" }
}));

const CategoriesTable = props => {
  const {
    className,
    onSearch,
    onUpClick,
    onDownClick,
    onSettingClick,
    onAddClick,
    customComponent,
    ...others
  } = props;

  // classes
  const classes = useStyles();

  // inReturn
  const component = props => {
    if (customComponent) return customComponent(props);
    return <CCTable {...props} />;
  };

  return (
    <Box className={clsx(className, classes.root)}>
      <Grid container justify="flex-end" className={classes.toolbar__container}>
        {onDownClick && (
          <CCIconButton
            className={classes.icon__btn}
            onClick={() => {
              onDownClick && onDownClick();
            }}
          >
            <KeyboardArrowDownIcon />
          </CCIconButton>
        )}
        {onUpClick && (
          <CCIconButton
            className={classes.icon__btn}
            onClick={() => {
              onUpClick && onUpClick();
            }}
          >
            <KeyboardArrowUpIcon />
          </CCIconButton>
        )}
        {onSettingClick && (
          <CCIconButton
            className={classes.icon__btn}
            onClick={() => {
              onSettingClick && onSettingClick();
            }}
          >
            <SettingIcon />
          </CCIconButton>
        )}
        {onAddClick && (
          <CCIconButton
            className={classes.icon__btn}
            onClick={() => {
              onAddClick && onAddClick();
            }}
          >
            <AddIcon />
          </CCIconButton>
        )}
      </Grid>
      <Grid container className={classes.table__container}>
        <Grid item className={classes.table__container__item}>
          {component(others)}
        </Grid>
      </Grid>
    </Box>
  );
};

CategoriesTable.propTypes = {
  onSearch: PropTypes.func,
  onDownClick: PropTypes.func,
  onUpClick: PropTypes.func,
  onSettingClick: PropTypes.func,
  onAddClick: PropTypes.func
};

export default CategoriesTable;
