import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { InfiniteTable } from "components";
import React, { useState } from "react";
import { CAPITALIZE_TYPE, useIntl } from "utils/language";
import { MenunextIcon } from "styles/src/themes/common/icons";
import { getCustomFormat, getDateToTimestamp } from "utils/datetime";
import { CCIconButton } from "styles/src/components";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/utils/TableItems";
import { LocalizeVariable } from "utils/env";
import { EstimateDetailDialog } from "views/Normal/InventoryManagement/Component/Purchase/Component/EstimationTab/Component/EstimateResults/Component";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  container: { height: "100%" },
  container__filter: {
    textAlign: "center"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  item__table: {
    height: "calc(100% - 60px - 30px)",
    width: "100%",
    alignItems: "center",
    textAlign: "center"
  },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  text__description: {
    color: theme.palette.disabled.hex
  },
  button__sms: {
    width: "110px",
    height: "24px",
    padding: "0px"
  },
  button__filter: {
    minWidth: "100px",
    minHeight: "10px",
    margin: "16px"
  },
  select: { width: "100px", margin: "4px" },
  select__w126: { width: "126px" },
  select__w155: { width: "155px" },
  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center"
  },
  head__field: {
    width: "100px"
  },
  headertwo: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "80px",
    alignItems: "center"
  },
  headerfilter: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  header__table: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  field: {
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    textAlign: "center"
  },
  table: {
    height: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  shrink: {
    background: theme.palette.common.white,
    paddingRight: "4px"
  },
  button_register: {
    padding: "0px",
    width: "120px",
    margin: "10px"
  },
  button_right: {
    marginTop: "10px",
    marginRight: "10px"
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  icon__star: {
    margin: 12,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  primary__color: {
    color: theme.palette.primary.main
  },
  error__color: {
    color: theme.palette.error.main
  },
  change__color: {
    color: theme.palette.secondary.main
  }
}));

const EstimateResults = props => {
  const { result, onMoreContents, hasNext } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [detail, setDetail] = useState(false);

  return (
    <Box className={classes.root}>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.item__table} item>
          <InfiniteTable
            heads={[
              {
                key: "orderNumber",
                headComponent: () => <TableHeader label={"registerNumber"} />,
                width: 160,
                label: () => <TableHeader label={"registerNumber"} />,
                component: ({ cellData }) => {
                  return <TableItem label={cellData ? cellData : ""} />;
                }
              },
              {
                key: "status",
                headComponent: () => (
                  <TableHeader label={intl.formatMessage({ id: "status" })} />
                ),
                width: 70,
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      className={{
                        [classes.change__color]: cellData === "Quoted"
                      }}
                      label={
                        cellData === "Quote"
                          ? // "estimateRequest"
                            intl.formatMessage({ id: "request" })
                          : // intl.formatMessage(
                          //   { id: "estimateRequest" }
                          // )
                          cellData === "Quoted"
                          ? intl.formatMessage({ id: "arrive" })
                          : ""
                      }
                    />
                  );
                }
              },
              {
                key: "updatedAt",
                width: 230,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "modifyDateTime" })}
                  />
                ),
                component: ({ cellData }) => {
                  // let _date = new Date(cellData).toString();

                  let _format = getCustomFormat(
                    getDateToTimestamp(cellData),
                    "X",
                    "YYYY-MM-DD HH:mm"
                  );

                  return <TableItem label={_format ? _format : ""} />;
                }
              },
              {
                key: "Clinic",
                width: 270,
                headComponent: () => (
                  <TableHeader
                    // label={intl.formatMessage({ id: "partner" })}
                    label={"Partner"}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData?.name ? cellData?.name : ""} />
                )
              },
              {
                key: "OrderItems",
                width: 660,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage(
                      { id: "tradingProduct" },
                      {},
                      { capitalizeType: CAPITALIZE_TYPE.FIRST_WORD }
                    )}
                  />
                ),
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      label={
                        cellData?.length > 1
                          ? intl.formatMessage(
                              { id: "cntExceptProduct" },
                              {
                                product: cellData[0]?.name,
                                cnt: cellData?.length - 1
                              }
                            )
                          : cellData
                          ? cellData[0]?.name
                          : ""
                      }
                    />
                  );
                }
              },
              {
                key: "totalSum",
                width: 160,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage(
                      { id: "totalEstimateAmount" },
                      { cost: LocalizeVariable.currencyUnit }
                    )}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData : ""} />
                )
              },
              {
                key: "Clinic",
                width: 180,
                headComponent: () => (
                  <TableHeader label={intl.formatMessage({ id: "manager" })} />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData?.manager : ""} />
                )
              },

              {
                key: "id",
                width: 60,
                component: ({ cellData, rowData }) => {
                  return (
                    <TableItem
                      disableTypography
                      label={
                        <CCIconButton
                          style={{ padding: 0, margin: 0 }}
                          disableRipple
                          variant={"text"}
                          size={"small"}
                          onClick={() => {
                            console.log(
                              "CELL >>>",
                              cellData,
                              typeof cellData,
                              "ROW >>>",
                              rowData
                            );

                            if (cellData) {
                              setDetail(cellData);
                            }
                          }}
                        >
                          <MenunextIcon />
                        </CCIconButton>
                      }
                    />
                  );
                }
              }
            ]}
            contents={result || []}
            hasNext={hasNext}
            onMoreContents={onMoreContents}
          />
        </Grid>
      </Grid>

      {Boolean(detail) && (
        <EstimateDetailDialog
          open={Boolean(detail)}
          onClose={() => setDetail(false)}
          product={detail ? parseInt(detail) : null}
        />
      )}
    </Box>
  );
};

export default EstimateResults;
