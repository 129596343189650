import React from "react";

const RadiationIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12 2c5.523 0 10 4.477 10 10 0 3.716-2.027 6.96-5.036 8.683l-.052.035c-1.066.6-2.254 1.012-3.516 1.187-.029.002-.057.006-.085.01-.066.01-.133.018-.2.025-.096.01-.192.019-.29.027-.053.005-.106.009-.16.012l-.21.011-.091.004c-.12.004-.24.006-.36.006-.233 0-.465-.008-.694-.024l-.021-.002C6.095 21.608 2 17.282 2 12 2 6.477 6.477 2 12 2zm0 1c-1.63 0-3.157.433-4.475 1.19l2.994 5.2C9.612 9.908 9 10.883 9 12v.005l-6 .003.004.257c.093 3.219 1.876 6.013 4.492 7.529l3.007-5.193c.44.254.952.399 1.497.399.55 0 1.066-.148 1.509-.406l3.004 5.194c2.68-1.556 4.484-4.458 4.487-7.78l-6-.003V12c0-1.113-.606-2.084-1.505-2.602l2.993-5.2C15.168 3.435 13.634 3 12 3zm0 7c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default RadiationIcon;
