import { Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Chips, translate } from "components";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";
import AttachmentArchiveDialog from "../AttachmentArchiveDialog";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "6px 8px 5px 8px",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  chips: {
    padding: "0 8px",
    marginLeft: 12,
    cursor: "pointer"
  }
}));

const MessengerHeader = props => {
  const { chatGroupId } = props;

  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Box className={classes.root}>
        <CCTypography variant={"h4"}>{translate(intl, "common.ui.messages")}</CCTypography>
        <Chips
          background={theme.palette.sub.main}
          color={"white"}
          className={classes.chips}
          onClick={() => setOpen(true)}
        >
          {translate(intl, "common.ui.attachmentArchive")}
        </Chips>
      </Box>
      {open && (
        <AttachmentArchiveDialog
          open={open}
          chatGroupId={chatGroupId}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default MessengerHeader;
