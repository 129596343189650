/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { Vector3 } from "@babylonjs/core";
import "@babylonjs/inspector";
import React, {
  forwardRef,
  Suspense,
  useContext,
  useMemo,
  useRef
} from "react";
import {
  AssetManagerContext,
  AssetManagerContextProvider,
  Engine,
  Scene,
  TaskType,
  useAssetManager,
  useBeforeRender
} from "react-babylonjs";

const ModelFallback = props => {
  const { totalCount = 1 } = props;
  const ref = useRef();
  const context = useContext(AssetManagerContext);

  useBeforeRender(scene => {
    if (ref.current) {
      var dt = scene.getEngine().getDeltaTime();

      const rpm = 10;
      ref.current.rotation.x = Math.PI / 4;
      ref.current.rotation.y += (rpm / 60) * Math.PI * 2 * (dt / 1000);
    }
  });

  const event = context?.lastProgress?.eventData;

  return (
    <>
      <adtFullscreenUi name="ui">
        <rectangle name="rect" height="50px" width="150px">
          <rectangle>
            {event !== undefined && (
              <textBlock
                text={`${event.totalCount - event.remainingCount}/${
                  event.totalCount
                }`}
                fontStyle="bold"
                fontSize={20}
                color="white"
              />
            )}
            {event === undefined && (
              <textBlock
                text={`0/${totalCount}`}
                fontStyle="bold"
                color="white"
              />
            )}
          </rectangle>
        </rectangle>
      </adtFullscreenUi>
      <box ref={ref} name="fallback" size={2} />
    </>
  );
};

const Model3D = props => {
  const { baseurl, filename } = props;
  const assetManagerResult = useAssetManager([
    {
      taskType: TaskType.Mesh,
      rootUrl: baseurl,
      sceneFilename: filename,
      name: "model0"
    }
  ]);

  useMemo(() => {
    if (baseurl && filename) {
      const task = assetManagerResult.taskNameMap["model0"];
      task.loadedMeshes[0].position = new Vector3(0, 0, 0);
      task.loadedMeshes[0].scaling = new Vector3(1, 1, 1);
    }
  }, [baseurl, filename]);

  return null;
};

const CCMesh3D = forwardRef((props, ref) => {
  const {
    width = 800,
    height = 600,
    antialias = false,
    baseurl,
    filename
  } = props;

  return (
    <Engine
      antialias={antialias}
      adaptToDeviceRatio
      canvasId="mesh3d"
      width={width}
      height={height}
    >
      <Scene>
        <arcRotateCamera
          name="camera0"
          alpha={Math.PI / 2}
          beta={Math.PI / 2}
          radius={9.0}
          target={Vector3.Zero()}
          minZ={0.001}
        />
        <hemisphericLight
          name="light0"
          intensity={0.7}
          direction={Vector3.Up()}
        />
        <AssetManagerContextProvider>
          <Suspense fallback={<ModelFallback />}>
            <Model3D baseurl={baseurl} filename={filename} />
          </Suspense>
        </AssetManagerContextProvider>
      </Scene>
    </Engine>
  );
});

export default CCMesh3D;
