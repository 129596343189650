import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CCTypography } from 'styles/src/components';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingLeft: (props) => (props.left ? 8 : 0),
    cursor: 'pointer',
  },
}));

const NextItem = (props) => {
  const { next = [], left = true, onClick = () => {} } = props;
  const classes = useStyle({ left: left });
  return (
    <Grid
      container
      wrap={'nowrap'}
      alignItems={'center'}
      className={classes.root}
      onClick={onClick}
    >
      <CCTypography variant={'body1'}>
        {next[0]?.cc
          ? `${next[0]?.cc.split(',')[0]}${
              next[0]?.cc.split(',').length - 1 > 0
                ? `(${next[0]?.cc.split(',').length - 1})`
                : ``
            }`
          : ''}
      </CCTypography>
      {next?.length - 1 > 0 && (
        <Chip size='small' label={`+${next?.length - 1}`} />
      )}
    </Grid>
  );
};
export default NextItem;
