import { makeStyles, Popover } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CCDatePicker from "../CCDatePicker";

/**
 * @deprecated
 */
const useStyle = makeStyles(theme => ({
    root: {},
    button: { fontWeight: "bold" },
    grayButton: {
      color: theme.palette.sub.main
    },
    noPaddingTop: {
      paddingTop: 0
    }
  }),
  { name: "CCDatePickerPopover" }
);

const CCDatePickerPopover = props => {
  const {
    open,
    onClose,
    type,
    begin,
    end,
    onChange,
    // anchorEl,
    ...others
  } = props;
  const [openPopover, setOpenPopover] = useState(open);
  useEffect(() => {
    setOpenPopover(open);
  }, [open]);
  const [last, setLast] = useState(type === "day" ? begin : [begin, end]);
  const classes = useStyle();
  useEffect(() => {
    if (type === "day") {
      setLast(begin);
    } else {
      setLast([begin, end]);
    }
  }, [begin, end, type]);
  return (
    <Popover
      open={openPopover}
      onClose={() => {
        setOpenPopover(false);
        onClose();
      }}
      {...others}
    >
      <CCDatePicker
        type={type}
        begin={type === "day" ? last : last[0]}
        end={type === "day" ? moment() : last[1]}
        onChange={e => {
          setLast(e);
        }}
      />
      <DialogActions className={clsx(classes.noPaddingTop)}>
        <Button
          color={"secondary"}
          size={"small"}
          className={clsx(classes.button, classes.grayButton)}
          onClick={() => {
            setOpenPopover(false);
            onClose();
          }}
        >
          취소
        </Button>
        <Button
          color={"secondary"}
          size={"small"}
          className={clsx(classes.button)}
          onClick={() => {
            onChange(last);
            setOpenPopover(false);
            onClose();
          }}
        >
          확인
        </Button>
      </DialogActions>
    </Popover>
  );
};
CCDatePickerPopover.propTypes = {
  /** Popover Open */
  open: PropTypes.bool.isRequired,
  /** Close Event Handler */
  onClose: PropTypes.func.isRequired,
  /** Calendar Type */
  type: PropTypes.oneOf(["day", "week", "between"]).isRequired,
  /** Date value (Day, Week, Between) */
  begin: PropTypes.instanceOf(moment).isRequired,
  /** Date value (Only Between) */
  end: PropTypes.instanceOf(moment),
  /** Value Change Handler */
  onChange: PropTypes.func.isRequired
};
CCDatePickerPopover.defaultProps = {
  begin: moment(),
  end: moment()
};
export default CCDatePickerPopover;
