import { useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { enqueueToastSave, translate } from "components";
import CVButton from "components/CVButton";
import { useSnackbar } from "notistack";
import {
  GET_CONFIG_PRICING,
  UPDATE_PROSTHESIS_PRICE_SETTING
} from "queries/setting";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogActions,
  CCPaper,
  CCTreeExp,
  CCTypography,
  getItems
} from "styles/src/components";
import CCDialogInputError from "styles/src/components/CCDialogInputError";
import CCDialogTitle from "styles/src/components/CCDialogTitle";
import CCTextField from "styles/src/components/CCTextField";
import { updateItem } from "styles/src/components/CCTreeExp";
import { SearchIcon } from "styles/src/themes/common/icons";
import DentalLabSettingContents from "./components/DentalLabSettingContents";
import { CCTooltip } from "styles/src/components";
import { truncate } from "../../../../../utils/utils";

const titleHeight = 62;
const navWidth = 260;
const useStyles = makeStyles(theme => ({
  root: { height: "100%", position: "relative" },
  title: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    height: titleHeight,
    boxSizing: "border-box"
  },
  inner__title: {
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center"
    }
  },
  contents: { height: `calc(100% - ${titleHeight}px)` },
  tree__nav: {
    width: navWidth,
    height: "100%"
  },
  content: {
    background: "#ececec",
    width: `calc(100% - ${navWidth}px)`,
    height: "100%",
    padding: "12px 18px"
  },
  flex__box: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  box: {
    width: 80,
    height: 10,
    border: `4px solid ${theme.palette.sub.main}`
  },
  typography: {
    color: theme.palette.sub.main,
    margin: "10px 0"
  },
  paper: {
    width: 1100,
    position: "relative",
    height: "100%"
  }
}));

const totalDepth = 2;
const CrownSetting = () => {
  // classes
  const classes = useStyles();
  const permission = "productPricesPartnersSetting";
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // value
  const [contents, setContents] = useState([]);
  const [history, setHistory] = useState([0, 0]); //[]
  const [searchMaterialValue, setSearchMaterialValue] = useState(null);
  const [searchMaterialValueTooltip, setSearchMaterialValueTooltip] =
    useState(null);
  const [inputError, setInputError] = useState(false);
  const [currentClinic, setCurrentClinic] = useState(null);
  const inputRef = useRef(null);
  const searchInputRef = useRef(null);

  // graphql
  const { data, refetch } = useQuery(GET_CONFIG_PRICING, {
    variables: {
      category: "prosthesisPriceSetting",
      labId: localStorage.getItem("labId")
    },
    fetchPolicy: "network-only"
  });
  const [createProsthesisPriceSetting] = useMutation(
    UPDATE_PROSTHESIS_PRICE_SETTING
  );

  // function
  // return false: if any material or unit is empty
  const checkEmptyMaterial = newContents => {
    for (var labIndex = 0; labIndex < newContents.length; labIndex++) {
      const lab = newContents[labIndex];
      for (var tabIndex = 0; tabIndex < lab.items.length; tabIndex++) {
        const contents = lab.items[tabIndex].item.contents;
        for (var i = 0; i < contents.length; i++) {
          const cur = contents[i];
          for (var j = 0; j < cur.tabContents.length; j++) {
            const lt = cur.tabContents[j];
            if (
              !lt.material ||
              !lt.unit ||
              lt.material.length === 0 ||
              lt.unit.length === 0
            ) {
              setInputError({
                lab: lab.name,
                clinic: lab.items[tabIndex].name,
                category: cur.category,
                index: j
              });
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  // useEffect
  useEffect(() => {
    if (data && data.getConfig && data.getConfig.prosthesisPriceSetting) {
      setContents(data.getConfig.prosthesisPriceSetting);
      if (currentClinic === null)
        setCurrentClinic(
          data.getConfig.prosthesisPriceSetting[0]?.items[0]?.partnerId
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const item = useMemo(() => {
    if (contents.length && history.length) {
      const tmp = getItems(contents, history);
      return tmp ? tmp.item : null;
    }
    return null;
  }, [contents, history]);

  return (
    <CCPaper type={"normal"} className={classes.root}>
      <CCDialogTitle className={classes.title}>
        {translate(intl, "common.ui.productPrice")}
      </CCDialogTitle>
      <Grid container className={classes.contents}>
        <Grid item className={classes.tree__nav}>
          <CCTreeExp
            title={translate(intl, "common.ui.price")}
            totalDepth={totalDepth}
            treeData={contents}
            onChange={newContents => {
              if (newContents.type === "state") {
                setContents(newContents.item);
              }
              if (newContents.selected) {
                setHistory(newContents.selected);
              }
            }}
            selected={history}
            onClickItem={(item, history) => {
              setCurrentClinic(item.partnerId);
              setHistory(history);
            }}
            disabledToolbar={true}
          />
        </Grid>
        <Grid item className={classes.content}>
          {history.length !== totalDepth ? (
            <Box className={classes.flex__box}>
              <Box className={classes.box} />
              <CCTypography className={classes.typography}>
                No item is selected. Please select an item from the left menu
              </CCTypography>
            </Box>
          ) : (
            <CCPaper className={classes.paper}>
              <CCDialogTitle className={classes.inner__title}>
                {history && contents[history[0]]?.items[history[1]]?.name}
              </CCDialogTitle>
              <Box style={{ padding: "0 12px" }}>
                <Grid container justify={"space-between"}>
                  <Grid item>
                    <CCTooltip
                      placement={"top"}
                      arrow
                      title={searchMaterialValueTooltip}
                    >
                      <CCTextField
                        margin={"dense"}
                        variant={"outlined"}
                        label={translate(intl, "common.ui.search")}
                        InputProps={{
                          inputProps: { maxLength: 200 },
                          onKeyPress: event => {
                            if (event.key === "Enter") {
                              setSearchMaterialValue(
                                searchInputRef.current.value
                              );
                            }
                          }
                        }}
                        onClickIcon={e => {
                          setSearchMaterialValue(searchInputRef.current.value);
                        }}
                        style={{ width: 350 }}
                        endIcon={<SearchIcon />}
                        inputRef={searchInputRef}
                        value={
                          searchInputRef?.current?.value
                            ? searchInputRef.current.value
                            : ""
                        }
                        onChange={e => {
                          setSearchMaterialValueTooltip(
                            searchInputRef.current.value
                          );
                        }}
                      />
                    </CCTooltip>
                  </Grid>
                  <Grid item>
                    <CCTextField
                      margin={"dense"}
                      variant={"outlined"}
                      label={translate(intl, "common.ui.discount2")}
                      disabled
                      style={{ width: 65 }}
                      value={
                        history &&
                        contents[history[0]]?.items[history[1]]?.discount + "%"
                      }
                    />
                    <CCTextField
                      margin={"dense"}
                      variant={"outlined"}
                      label={translate(
                        intl,
                        "settings.productPrice.cleverClinicNormalClinic"
                      )}
                      style={{ width: 229 }}
                      value={
                        contents[history[0]]?.items[history[1]]?.createdBy ===
                          "Lab"
                          ? translate(intl, "common.ui.normalClinic")
                          : translate(intl, "common.ui.cleverClinic")
                      }
                      disabled
                    ></CCTextField>
                  </Grid>
                </Grid>
              </Box>
              <DentalLabSettingContents
                value={item}
                ref={inputRef}
                permission={permission}
                currentClinic={currentClinic}
                contentFilter={searchMaterialValue}
              />
              <CCDialogActions>
                <CVButton
                  variant="text"
                  color="sub"
                  onClick={() => {
                    inputRef.current.handleCancel();
                  }}
                >
                  {translate(intl, "common.ui.cancel")}
                </CVButton>
                <CVButton
                  permission={permission}
                  variant="contained"
                  onClick={() => {
                    const newItem = inputRef.current.getData();

                    const newContents = updateItem(contents, history, newItem);

                    const isDataValid = checkEmptyMaterial(newContents);
                    if (isDataValid) {
                      createProsthesisPriceSetting({
                        variables: {
                          labId: localStorage.getItem("labId"),
                          prosthesisPriceSetting: newContents,
                          category: "prosthesisPriceSetting"
                        }
                      }).then(() => {
                        refetch();

                        enqueueToastSave(intl, enqueueSnackbar);
                        if (window.opener) {
                          window.opener.postMessage(
                            { source: "clboard", payload: "close" },
                            "*"
                          );
                          window.close();
                        }
                      });
                    }
                  }}
                >
                  {translate(intl, "common.ui.save")}
                </CVButton>
              </CCDialogActions>
            </CCPaper>
          )}
        </Grid>
      </Grid>

      <CCDialogInputError
        title={`${translate(
          intl,
          "common.messages.theEnteredValueIsIncorrect"
        )} ${truncate(inputError.lab)} > ${truncate(
          inputError.clinic
        )} > ${truncate(inputError.category)} > No.${inputError.index + 1}`}
        open={inputError}
        onClose={() => setInputError(false)}
      />
    </CCPaper>
  );
};

CrownSetting.defaultProps = {};

CrownSetting.propTypes = {};

export default CrownSetting;
