import { Box, debounce, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { translate } from "components";
import FileUploadPaper from "components/FileUploadPaper";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { CCTextField } from "styles/src/components";
import { Detail, LabOrderTeethSelector, Pontic, ShadeSelector } from "../";

const height = 180;

const useStyles = makeStyles(theme => ({
  root: {
    width: "inherit",
    position: "relative"
  },
  container: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.border.main}`,
    // borderBottom: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default,
    paddingTop: 8,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 0,
    overflowY: "hidden"
  },
  side__margin0: {
    marginRight: 0,
    marginLeft: 0
  },
  item__shade: { height: height, width: 310 },
  item__pontic: { height: height, width: 110 },
  item__detail: { height: height, width: 140 },
  item__photo: { height: height, width: 280 }
}));

const LabOrderSetting = props => {
  const { value, onChange, dispatch } = props;
  const { note, shadeSetting, ponticSetting, photo } = value;

  const classes = useStyles();
  const intl = useIntl();

  const handleFileChange = photo => {
    onChange({ photo });
  };

  const debouncedOnChangeInput = useCallback(
    debounce(nextValue => onChange(nextValue), 400),
    [] // will be created only once initially
  );

  return (
    <Box className={clsx(classes.root)}>
      <Grid container className={clsx(classes.container)} justify={"center"}>
        <Grid item style={{ height: 250, width: "100%", marginBottom: 8 }}>
          <LabOrderTeethSelector
            value={value}
            onChange={onChange}
            dispatch={dispatch}
            selectedClinicId={value.clinic}
          />
        </Grid>

        <Grid item style={{ width: "100%", paddingBottom: 8 }}>
          <Grid
            container
            alignItems={"flex-start"}
            justify={"space-between"}
            style={{ height: "100%", width: "100%" }}
            wrap={"nowrap"}
          >
            <Grid item className={classes.item__shade}>
              <ShadeSelector
                value={value}
                onChange={onChange}
                open={!shadeSetting}
              />
            </Grid>
            <Grid item className={classes.item__pontic}>
              <Pontic value={value} onChange={onChange} open={!ponticSetting} />
            </Grid>
            <Grid item className={classes.item__detail}>
              <Detail value={value} onChange={onChange} />
            </Grid>
            <Grid item className={classes.item__photo}>
              <FileUploadPaper
                onlyDirect
                title={translate(intl, "labManageGold.attachments")}
                value={value?.photo}
                onChange={handleFileChange}
                xs={6}
                message={``}
                actionExt={true}
                readonly={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Grid
            container
            alignItems={"center"}
            display={"flex"}
            style={{ height: "100%", width: "100%" }}
            wrap={"nowrap"}
          >
            <Grid item style={{ flex: 1 }}>
              <CCTextField
                style={{
                  marginLeft: 0,
                  width: "100%"
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 200
                  }
                }}
                variant={"outlined"}
                margin={"dense"}
                label={translate(intl, "common.ui.additionalInformation")}
                valueDefault={note || ""}
                onChange={e => {
                  debouncedOnChangeInput({ note: e.target.value });
                }}
              />
            </Grid>

            {/* <Grid item>
              <CCButton
                variant={'contained'}
                color={'secondary'}
                startIcon={<BringInIcon />}
                onClick={onSaveList}
                disabled={!validationSaveCheck}
              >
                저장/목록이동
              </CCButton>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

LabOrderSetting.defaultProps = {};

LabOrderSetting.propTypes = {};

export default LabOrderSetting;
