export {
  AbbreviationIcon,
  AccessibleIcon,
  AddClaimIcon,
  AddIcon,
  AgreementIcon,
  AlignBottomIcon,
  AlignTopIcon,
  AnnounceIcon,
  AnnounceOffIcon,
  AnnounceOnIcon,
  AssessmentIcon,
  BackendIcon,
  BackspaceIcon,
  BadIcon,
  BarcodeIcon,
  BarcodenIcon,
  BrightnessIcon,
  BringInIcon,
  BringupIcon,
  ButtonIcon,
  CalculationIcon,
  CalculationIcon18,
  CalendarselIcon,
  CallIcon,
  CameraIcon,
  CategoryIcon,
  CCIcon,
  ChairIcon,
  ChairSelectIcon,
  ChartIcon,
  ChartitemIcon,
  ChartnIcon,
  CheckallIcon,
  CheckDateIcon,
  CheckedIcon,
  CheckIcon,
  CircleIcon,
  CircularIcon,
  CircularIcon18,
  ClaimexcludedIcon,
  ClaimIcon,
  ClaimincludedIcon,
  ClaimnIcon,
  ClipIcon,
  CloudIcon,
  ColorinvertIcon,
  ColorpickerIcon,
  CommitmentIcon,
  CommitnIcon,
  ComplaintIcon,
  ContrastIcon,
  CorporationIcon,
  CrmIcon,
  CrmnIcon,
  CropIcon,
  CTIcon,
  CustomermanageIcon,
  DategoIcon,
  DateIcon,
  DaygoIcon,
  DeleteIcon,
  DentalmirrorIcon,
  DenturesIcon,
  DeskIcon,
  DesknIcon,
  DigitalSignIcon,
  DigitalSignnIcon,
  DiscountIcon,
  DiscountIcon18,
  DiseasesIcon,
  DocumentIcon,
  DollaregyptIcon,
  DollarkorIcon,
  DollarruIcon,
  DollarvietIcon,
  DotIcon,
  DownIcon,
  DownloadIcon,
  DownloadnIcon,
  DrawingIcon,
  EnterIcon,
  EraserIcon,
  ExcelIcon,
  ExplorerIcon,
  EzdentiIcon,
  FamilyIcon,
  FemaleIcon,
  FilterIcon,
  FlagIcon,
  FlashIcon,
  FoldallIcon,
  FolderIcon,
  FoldIcon,
  FontsizeIcon,
  FullviewIcon,
  GammaIcon,
  GoldIcon,
  GooglelogoIcon,
  GotoendIcon,
  GotofirstIcon,
  HappyIcon,
  HeightIcon,
  HelpIcon,
  HolidayIcon,
  HomeIcon,
  HrIcon,
  HueIcon,
  ImplantIcon,
  InfoIcon,
  InvisibleeditIcon,
  InvisibleIcon,
  JxIcon,
  KeyboardIcon,
  KeyIcon,
  LeftIcon,
  LockIcon,
  MainIcon,
  MaleIcon,
  MedicineIcon,
  MedicinepayIcon,
  MembershipIcon,
  MenucloseIcon,
  MenunextIcon,
  MenuopenIcon,
  MessageAddIcon,
  MessageaddIcon18,
  MessageIcon,
  MessageIcon18,
  MicIcon,
  MinusIcon,
  ModifyIcon,
  MoonborderIcon,
  MoonIcon,
  MoonstandIcon,
  MorebIcon,
  MoreIcon,
  MovedownIcon,
  MoveIcon,
  MoveupIcon,
  NewIcon,
  NonannounceIcon,
  NotificationsIcon,
  NotificationsoffIcon,
  NumbereightIcon,
  NumberelevenIcon,
  NumberfiveIcon,
  NumberfourIcon,
  NumbernineIcon,
  NumberoneIcon,
  NumbersevenIcon,
  NumbersixIcon,
  NumbertenIcon,
  NumberthreeIcon,
  NumbertwelveIcon,
  NumbertwoIcon,
  OfficialIcon,
  OpenfolderIcon,
  OpeninnewIcon,
  OralexamIcon,
  OralexamnIcon,
  OverviewIcon,
  PaidIcon,
  PathIcon,
  PauseIcon,
  PeriodIcon,
  PersonIcon,
  PhotoIcon,
  PhotoIconBigBackIcon,
  PhotoIconBigFolderIcon,
  PhotoIconBigVideoIcon,
  PhotonIcon,
  PhotosizeIcon,
  PictureIcon,
  PlusIcon,
  PregnantIcon,
  PrintIcon,
  ProgressIcon,
  ProgressIcon18,
  QuotesIcon,
  RadiationIcon,
  RecordsIcon,
  RedoIcon,
  ReducesizeIcon,
  ReflectrightIcon,
  ReflectupIcon,
  RefreshIcon,
  RemoveIcon,
  ReservationIcon,
  ReservationnIcon,
  ResetIcon,
  ResignIcon,
  ResizeIcon,
  RestIcon,
  RightIcon,
  RotateIcon,
  RotateleftIcon,
  RotaterightIcon,
  SaturationIcon,
  SaveIcon,
  ScannerIcon,
  SchoolIcon,
  ScreensaverIcon,
  ScreenshotIcon,
  SearchIcon,
  SelectPersonIcon,
  SentIcon,
  SettingIcon,
  SettingnIcon,
  SettlementIcon,
  ShoppingIcon,
  SignatureIcon,
  SignoutIcon,
  SmalldownIcon,
  SmallupIcon,
  SMSIcon,
  SpecialcalculationIcon,
  SpreadoverIcon,
  SquarefilledIcon,
  SquareIcon,
  StarIcon,
  StatisticsIcon,
  StatisticsnIcon,
  StockIcon,
  StraightenIcon,
  SubtractIcon,
  TabkeyIcon,
  TackIcon,
  TagIcon,
  TakedownIcon,
  TakeinIcon,
  TextIcon,
  TimeIcon,
  TodayIcon,
  UndoIcon,
  UnlockIcon,
  UpdownsIcon,
  UpIcon,
  VideoIcon,
  VisibleeditIcon,
  VisibleIcon,
  WarningIcon,
  WidthIcon,
  XrayIcon,
  ZaloIcon,
  ZalonIcon,
  ZoominIcon,
  ZoomoutIcon
} from "../src/themes/common/icons";
