import RECEIPT_STATUS from "types/receipt";

const TYPE = {
  PATIENT: { value: "0", localeKey: "reservationType.patient" },
  SCHEDULE: { value: "1", localeKey: "reservationType.schedule" },
};
const PATIENT_STATUS = {
  RESERVATION: {
    value: "0",
    localeKey: "patientStatus.reservation",
  },
  IMPLEMENTATION: {
    value: "2",
    localeKey: "patientStatus.implementation",
  },
  NOT_IMPLEMENTATION: {
    value: "3",
    localeKey: "patientStatus.notImplementation",
  },
  RECEIPT: {
    value: RECEIPT_STATUS.RECEIPT.value,
    localeKey: "patientStatus.receipt",
  },
  CANCEL: { value: "4", localeKey: "patientStatus.cancel" },
  // HOLD: { value: "5", localeKey: "patientStatus.hold" }
};

const PATIENT_SEARCH_STATUS = {
  IMPLEMENTATION: {
    value: PATIENT_STATUS.IMPLEMENTATION.value,
    localeKey: PATIENT_STATUS.IMPLEMENTATION.localeKey,
  },
  NOT_IMPLEMENTATION: {
    value: PATIENT_STATUS.NOT_IMPLEMENTATION.value,
    localeKey: PATIENT_STATUS.NOT_IMPLEMENTATION.localeKey,
  },
  CANCEL: {
    value: PATIENT_STATUS.CANCEL.value,
    localeKey: PATIENT_STATUS.CANCEL.localeKey,
  },
  // HOLD: {
  //   value: PATIENT_STATUS.HOLD.value,
  //   localeKey: PATIENT_STATUS.HOLD.localeKey
  // }
};

const SCHEDULE_TYPE = {
  VACATION: { value: "0", localeKey: "scheduleType.vacation" },
  MORNING_HALF_VACATION: {
    value: "1",
    localeKey: "scheduleType.morningHalfVacation",
  },
  AFTERNOON_HALF_VACATION: {
    value: "2",
    localeKey: "scheduleType.afternoonHalfVacation",
  },
  LEGAL_VACATION: {
    value: "3",
    localeKey: "scheduleType.legalVacation",
  },
  SEMINAR: { value: "4", localeKey: "scheduleType.seminar" },
  GOING_OUT: { value: "5", localeKey: "scheduleType.goingOut" },
  VISIT: { value: "6", localeKey: "scheduleType.visit" },
  ETC: { value: "7", localeKey: "scheduleType.etc" },
};

const SCHEDULE_STATUS = {
  REGISTRATION: {
    value: "0",
    localeKey: "scheduleStatus.registration",
  },
  IMPLEMENTATION: {
    value: "1",
    localeKey: "scheduleStatus.implementation",
  },
  NOT_IMPLEMENTATION: {
    value: "2",
    localeKey: "scheduleStatus.notImplementation",
  },
  CANCEL: { value: "3", localeKey: "scheduleStatus.cancel" },
  // HOLD: { value: "4", localeKey: "scheduleStatus.hold" }
};

const SCHEDULE_CONTEXT_LIST = {
  REGISTRATION: {
    value: SCHEDULE_STATUS.REGISTRATION.value,
    localeKey: "scheduleContextMenu.registration",
  },
  MODIFY: { value: "5", localeKey: "scheduleContextMenu.modify" },
  IMPLEMENTATION: {
    value: SCHEDULE_STATUS.IMPLEMENTATION.value,
    localeKey: "scheduleContextMenu.implementation",
  },
  NOT_IMPLEMENTATION: {
    value: SCHEDULE_STATUS.NOT_IMPLEMENTATION.value,
    localeKey: "scheduleContextMenu.notImplementation",
  },
  // HOLD: {
  //   value: SCHEDULE_STATUS.HOLD.value,
  //   localeKey: "scheduleContextMenu.hold"
  // },
  CANCEL: {
    value: SCHEDULE_STATUS.CANCEL.value,
    localeKey: "scheduleContextMenu.cancel",
  },
  DELETE: { value: "6", localeKey: "scheduleContextMenu.delete" },
};

const PATIENTS_CONTEXT_LIST = {
  RESERVATION: {
    value: PATIENT_STATUS.RESERVATION.value,
    localeKey: "patientContextMenu.reservation",
  },
  RESERVATION_MODIFY: {
    value: "6",
    localeKey: "patientContextMenu.modify",
  },
  SELECT: {
    value: "7",
    localeKey: "patientContextMenu.select",
  },
  IMPLEMENTATION: {
    value: PATIENT_STATUS.IMPLEMENTATION.value,
    localeKey: "patientContextMenu.implementation",
  },
  RECEIPT: {
    value: PATIENT_STATUS.RECEIPT.value,
    localeKey: "patientContextMenu.receipt",
  },
  CANCEL: {
    value: PATIENT_STATUS.CANCEL.value,
    localeKey: "patientContextMenu.cancel",
  },
  // HOLD: {
  //   value: PATIENT_STATUS.HOLD.value,
  //   localeKey: "patientContextMenu.hold"
  // },
  DELETE: {
    value: "8",
    localeKey: "patientContextMenu.delete",
  },
};

const STATUS = {
  PATIENT: PATIENT_STATUS,
  SCHEDULE: SCHEDULE_STATUS,
};

const RESERVATION = {
  TYPE: TYPE,
  STATUS: STATUS,
  SUB_TYPE: {
    SCHEDULE_TYPE: SCHEDULE_TYPE,
  },
  CONTEXT_LIST: {
    SCHEDULE_CONTEXT_LIST: SCHEDULE_CONTEXT_LIST,
    PATIENTS_CONTEXT_LIST: PATIENTS_CONTEXT_LIST,
  },
  SEARCH: {
    PATIENT_SEARCH_STATUS: PATIENT_SEARCH_STATUS,
  },
};

export {
  RESERVATION,
  STATUS,
  PATIENT_STATUS,
  TYPE,
  SCHEDULE_STATUS,
  SCHEDULE_TYPE,
  SCHEDULE_CONTEXT_LIST,
  PATIENTS_CONTEXT_LIST,
  PATIENT_SEARCH_STATUS,
};
export default RESERVATION;
