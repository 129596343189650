import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { CVTextField } from "components";

const MAX_VAL = 999999999999;

const NumberFormatPayment = props => {
  const { inputRef, onChange, ...other } = props;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value < MAX_VAL) return inputObj;
  };
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({ target: { value: values.formattedValue } });
      }}
      isAllowed={withValueLimit}
      thousandSeparator
      decimalScale={2}
      displayType={"input"}
      {...other}
    />
  );
};
const PriceAmountTextField = props => {
  const {
    value,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);

  useEffect(() => {
    if (value) {
      setError(false);
    } 
    required && value === "" && setError(true);
  }, [value, required]);

  const handleOnChange = (e) => {
    onChange(e);
    // if (value) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
  };
  return (
    <CVTextField
      InputProps={{
        inputComponent: NumberFormatPayment,
      }}
      margin={margin}
      variant={variant}
      label={label}
      onChange={handleOnChange}
      error={error}
      required={required}
      value={value}
      {...others}

    />
  );
};

PriceAmountTextField.defaultProps = {
  value: "",
  margin: "dense",
  variant: "outlined",
  label: "",
  required: false,
  errorOnChange: () => { }
};

PriceAmountTextField.propTypes = {
  onChange: PropTypes.func,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default PriceAmountTextField;