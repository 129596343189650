import React from "react";

const PlusIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M13 6a1 1 0 0 1 1 1v3h3a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-3.001L14 17a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1l-.001-3H7a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h3V7a1 1 0 0 1 1-1h2z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PlusIcon;
