import { useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import React, { useState } from "react";
import { ClinicInformation, PartnerRequest } from "./components";

const useStyles = makeStyles(() => ({
  root: { height: "100%", position: "relative", flexDirection: "column"}
}));

const PARTNER_LIST = gql`
  query PartnerList($query: DefaultQueryListInput!) {
    partnerList(query: $query) {
      items {
        id
        name
        email
        tel
        fax
        tax
        address
        address2
        location{
          address
          placeId
          detailAddress
          coordinate{
            latitude
            longitude
          }
        }
        city
        state
        zipCode
        manager
        managerCellNo
        discount
        paymentDate
        type
        isLink

        createdBy
      }
      hasNext
      total
    }
  }
`;

const PARTNER_REQUEST_LIST = gql`
  query GetPartnerRequestList($input: PartnerRequestListInput!) {
    partnerRequestList(input: $input) {
      items {
        id
        name
        address
        location{
          address
          placeId
          detailAddress
          coordinate{
            latitude
            longitude
          }
        }
        tel
        manager
        managerCellNo
        createdBy
        createdAt
        status
      }
    }
  }
`;

const ClinicInfoSetting = () => {
  // classes
  const classes = useStyles();

  // value
  const [keyword, setKeyword] = useState("");

  // graphql
  const {
    data: partnerRequestData,
    loading: partnerRequestLoading,
    refetch: partnerRequestRefetch
  } = useQuery(PARTNER_REQUEST_LIST, {
    variables: {
      input: {
        limit: 100000
      }
    },
    pollInterval: 3000
  });
  const {
    data: partnerData,
    loading: partnerLoading,
    refetch: partnerRefetch
  } = useQuery(PARTNER_LIST, {
    variables: {
      query: { skip: 0, limit: 10000, keyword }
    },
    pollInterval: 3000
  });

  // function
  const handleSetKeyWordSearch = val => setKeyword(val);

  return (
    <Grid container className={classes.root}>
      <PartnerRequest
        data={partnerRequestData}
        loading={partnerRequestLoading}
        refetch={partnerRequestRefetch}
        partnerRefetch={partnerRefetch}
      />
      <ClinicInformation
        data={partnerData}
        loading={partnerLoading}
        refetch={partnerRefetch}
        handleSetKeyWordSearch={handleSetKeyWordSearch}
      />
    </Grid>
  );
};

export default ClinicInfoSetting;
