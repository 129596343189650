import Box from "@material-ui/core/Box";
import { pdf } from "@react-pdf/renderer";
import { Loading } from "components/index";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { useIntl } from "react-intl";
import { RequestDoc } from "../../components";

const Viewer = props => {
  const { data, hospital } = props;

  const intl = useIntl();

  const [doc, setDoc] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getStamp = async () => {
      if (data?.stamp) {
        const blob = await pdf(
          <RequestDoc
            intl={intl}
            stamp={data.stamp}
            data={data}
            hospital={hospital}
          />
        ).toBlob();
        setDoc(URL.createObjectURL(blob));
      } else {
        const blob = await pdf(
          <RequestDoc
            intl={intl}
            stamp={null}
            data={data}
            hospital={hospital}
          />
        ).toBlob();
        setDoc(URL.createObjectURL(blob));
      }
    };
    if (doc === "") {
      getStamp();
    }
    return () => {
      URL.revokeObjectURL(doc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, doc, setDoc, hospital]);

  return (
    <Box style={{ width: "100%", height: "100%", position: "relative" }}>
      <Loading open={loading} msg={"의뢰서 로딩..."} />
      <Iframe
        url={doc}
        width={"100%"}
        height={"100%"}
        style={{ border: "none" }}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </Box>
  );

  // return stamp ? (
  //   <PDFViewer width={"100%"} height={"100%"} style={{ border: "none" }}>
  //     <RequestDoc stamp={stamp} />
  //   </PDFViewer>
  // ) : (
  //   <div>TESt</div>
  // );
};

export default Viewer;
