import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVTextField, DialogWarningMessage } from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CCPaper,
  CCTextFieldNumber,
  CCTypography
} from "styles/src/components";
import { useIntl } from "utils/language";
import { getCustomFormat } from "utils/datetime";
import { CCTable } from "styles/src/components";

const head__height = 20;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    maxWidth: "100%"
  },
  paper: {
    position: "relative",
    height: "300px",
    width: "95%"
  },
  expire_table: {
    height: "360px"
  },
  container: {
    height: head__height
  },
  expire__item: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 8
  },
  subitem: {
    marginTop: 8,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 8
  },
  mouse__hover: {
    cursor: "pointer"
  },
  table: {
    height: "250px",
    position: "relative"
    // overflow: "scroll"
  },
  date__field: {
    width: 150
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  },
  table__head: {
    marginTop: 10,
    padding: 10
  },
  table__body: {
    overflow: "scroll",
    height: "250px"
  }
}));

const OutgoingTable = props => {
  const intl = useIntl();
  const { currentCntOut: currentCnt, value, fixed, onChange } = props;
  const classes = useStyles();
  const permission = "desk";
  const [total, setTotal] = useState(currentCnt);
  const [change, setChange] = useState(false);
  const [alert, setAlert] = useState(false);
  const [reset, setReset] = useState(false);

  const onModify = useCallback(
    row => {
      const { option, rowIndex, e } = row;
      setChange(e);

      const counts = value.map(item => item?.count);
      const dates = value.map(item => item?.expireDate);
      const expireCounts = value.map(item => item?.expireCount);
      const ModMap = [];

      for (let i = 0; i < value.length; i++) {
        if (i === rowIndex) {
          if (option) {
            dates[rowIndex] = e;
          } else {
            counts[rowIndex] = e;
          }
        }

        ModMap.push({
          expireDate: dates[i],
          expireCount: Number(expireCounts[i]),
          count: reset ? 0 : Number(counts[i]),
          // originExpireCount: expireCounts[i]
        });
      }

      onChange(ModMap);
      setReset(false);
    },
    [onChange, value, reset]
  );

  const heads = useMemo(() => {
    return [
      {
        key: "expireDate",
        value: "expireDate",
        width: 160,
        component: ({ rowIndex }) => (
          <CVTextField
            disabled={true}
            label={intl.formatMessage({ id: "expireDate" })}
            margin={"dense"}
            variant={"outlined"}
            value={getCustomFormat(fixed[rowIndex]?.expireDate, "X", "L")}
          />
        )
      },
      {
        key: "expireCount",
        value: "expireCount",
        width: 120,
        component: ({ rowIndex }) => (
          <CVTextField
            disabled={true}
            label={intl.formatMessage({ id: "inventoryCount" })}
            margin={"dense"}
            variant={"outlined"}
            value={fixed[rowIndex]?.expireCount || 0}
          />
        )
      },
      {
        key: "count",
        value: "count",
        width: 120,
        component: ({ rowIndex }) => (
          <CCTextFieldNumber
            label={intl.formatMessage({ id: "inventoryCount" })}
            number={value[rowIndex]?.count}
            decimalPoint={0}
            step={1}
            onChange={e => {
              onModify({ option: false, rowIndex, e });
              setChange(e);
            }}
          />
        )
      }
    ];
  }, [intl, onModify, value, fixed]);

  useEffect(() => {
    if (Boolean(value?.length > 0 && change)) {
      let totalOut = 0;
      for (const v of Object.keys(value)) {
        totalOut = totalOut + Number(value[v]?.count);
        if (Number(fixed[v]?.expireCount) < Number(value[v]?.count)) {
          setReset(true);
          setAlert({
            title: intl.formatMessage(
              { id: "inputCntExceedCurrentCount" },
              {
                count: Number(fixed[v]?.expireCount)
              },
              {}
            ),
            onAgree: () => {
              onModify({ option: false, rowIndex: v, e: 0 });
              setAlert(false);
            }
          });
        }
        else if (totalOut > currentCnt) {
          setReset(true);
          setAlert({
            title: intl.formatMessage(
              { id: "outgoingSameWithExpireAlert" },
            ),
            onAgree: () => {
              onModify({ option: false, rowIndex: v, e: 0 });
              setAlert(false);
              setTotal(0)
            }
          });
        }
      }

      setTotal(
        Number(
          value
            .map(e => e?.count)
            .reduce(
              (acc, cur) => (cur > 0 ? Number(acc) + Number(cur) : Number(acc)),
              0
            )
        )
      );
    }
  }, [
    value,
    currentCnt,
    total,
    intl,
    onChange,
    change,
    fixed,
    onModify
  ]);
  return (
    <Box className={clsx(classes.root)}>
      <CCPaper className={clsx(classes.paper)}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={clsx(classes.container)}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid
                item
                // className={clsx(classes.expire__item)}
                style={{ padding: 10 }}
              >
                <CCTypography variant={"h4"}>
                  {intl.formatMessage({ id: "expireDateSetting" })}
                </CCTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container alignItems={"center"}>
            <Grid
              item
              className={clsx(classes.subitem)}
              style={{ padding: 10 }}
            >
              <CCTypography>
                {`${intl.formatMessage({ id: "leftCntforSetting" })} : ${currentCnt - total > 0 ? currentCnt - total : 0
                  }`}
              </CCTypography>
            </Grid>
          </Grid>
        </Grid>

        <Box className={clsx(classes.table)}>
          <CCTable
            classes={classes}
            permission={permission}
            heads={heads.map(item => ({ ...item, className: classes.cell }))}
            contents={value}
          />
        </Box>
        <DialogWarningMessage
          open={Boolean(alert)}
          agreeButtonLabel={intl.formatMessage({ id: "check" })}
          {...alert}
        />
      </CCPaper>
    </Box>
  );
};
export default OutgoingTable;
