import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CCTypography } from 'styles/src/components';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '8px 16px',
    minHeight: 37,
  },
  item: {
    cursor: 'pointer',
  },
}));

const FilePath = (props) => {
  const { folderPath, currentPointer, onChange, patient } = props;
  const classes = useStyle();
  return (
    <Grid item className={classes.root}>
      <Breadcrumbs separator={'>'}>
        <CCTypography variant={'h5'} onClick={() => {}}>
          {currentPointer?.type === 'library'
            ? '라이브러리'
            : currentPointer?.type === 'consult'
            ? '클레버 컨설트'
            : currentPointer?.type === 'photo'
            ? patient?.value?.name || '환자폴더'
            : ''}
        </CCTypography>
        {folderPath &&
          folderPath.map((item) => {
            return (
              <CCTypography
                className={classes.item}
                key={item.id}
                variant={'h5'}
                onClick={() => {
                  onChange && onChange(item);
                }}
              >
                {item.content.filename}
              </CCTypography>
            );
          })}
      </Breadcrumbs>
    </Grid>
  );
};

export default FilePath;
