import { DialogPermissionWarn } from 'components';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CCFlattenTimeField } from 'components';
const CVFlattenTimeField = (props) => {
  const intl = useIntl()
  const {
    children,
    permission,
    onChange,
    onKeyDown,
    onKeyPress,
    onClickIcon,
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const isPermission = true;

  const handleChange = () => {
    setOpen(true);
  };

  return (
    <>
      <CCFlattenTimeField
        label={intl.formatMessage({ id: "common.ui.time" })}
        onChange={isPermission ? onChange : handleChange}
        onKeyDown={isPermission ? onKeyDown : handleChange}
        onKeyPress={isPermission ? onKeyPress : handleChange}
        onClickIcon={isPermission ? onClickIcon : handleChange}
        {...others}
      >
        {children}
      </CCFlattenTimeField>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default CVFlattenTimeField;
