import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DialogWarningMessage, enqueueToast, translate } from "components";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  CCButton, CCDialogContent
} from "styles/src/components";
import { getTimestamp } from "utils/datetime";
import { useIntl } from "utils/language";
import useProductValidation from "validations/useProductValidation";
import {
  useCreateProduct
} from "views/Normal/InventoryManagement/Component/Stock/Component/ProductTab/hooks";
import { TabContext } from "views/Normal/InventoryManagement/Component/Stock/Stock";
import ProductCreate from "../ProductCreate/ProductCreate";
import { useApolloClient } from "@apollo/client";

const useStyles = makeStyles(theme => ({

  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "inherit",
      width: 900,
      display: "inline-block"
    }
  },
  box: {
    maxWidth: "inherit",
    width: "100%"
  },
  form: {
    background: theme.palette.result.main
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end"
  },
  border: {
    width: 1,
    height: "36px",
    marginRight: -40,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  height: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.border.main}` // width: 764
  }
}));

const initData = {
  hospital: "",
  important: false,
  unit: 1,
  currentCnt: 0,
  optCnt: 0,
  alert: false,
  editDate: getTimestamp(),
  editorID: "",
  canExpire: true,
  expire: {
    expireCount: 0,
    expireDate: getTimestamp()
  },
  binCnt: 0,
  barcode: false,
  isAppropriate: false
};

const IndividualRegistration = props => {
  const intl = useIntl();
  const client = useApolloClient();

  const { onClose, onCancel, refetchGetProductList } = props;

  const classes = useStyles();
  const selectRef = useRef();

  const [expireD, setExpireD] = useState({
    expireDate: getTimestamp(),
    expireCount: 0
  });
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorDialogContent, setErrorDialogContent] = useState(false);

  const { createProduct } = useCreateProduct(client);

  const { setProduct } = useContext(TabContext);

  const { enqueueSnackbar } = useSnackbar();

  const { schema } = useProductValidation();
  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const { control, errors, getValues } = methods;


  const onSave = useCallback(
    async e => {
      await createProduct(e)
        .then((res) => {
          onClose();

          enqueueToast(
            enqueueSnackbar, translate(intl, "common.ui.itemHasBeenRegistered")
          );

          setProduct({
            productID: res.id,
            major: Boolean(e?.selectedList) ? e?.selectedList?.major : e?.major,
            productName: Boolean(e?.selectedList)
              ? e?.selectedList?.productName
              : e?.productName
          });
        })
        .catch((error) => {
          console.log("error IndividualRegistration", error);
          console.log("error IndividualRegistration", JSON.stringify(error));
          const { graphQLErrors, networkError } = error;

          setOpenErrorDialog(true);
          if (graphQLErrors?.length > 0) {
            graphQLErrors.forEach(graphQLError => {
              setErrorDialogContent(translate(intl, graphQLError.message))
            });
          }
          else if (networkError?.result?.errors) {
            const nErrors = networkError.result.errors;
            nErrors.forEach(networkError => {
              setErrorDialogContent(translate(intl, networkError.message))
            });
          }
          else {
            setErrorDialogContent(intl.formatMessage({ id: "errorMsg.unkownerror" }))
          }
        })
      refetchGetProductList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createProduct, onClose, refetchGetProductList, enqueueSnackbar, intl, setProduct]
  );
  console.log("IndividualRegistration");
  const onError = error => {
    console.error("IndividualRegistration", "onError", "error", error);
    const errorMessages = Object.keys(error).map(key => {
      const errorMessage = error[key].message
      return errorMessage;
    })
    if (errorMessages && errorMessages.length > 0) {
      setOpenErrorDialog(true);
      setErrorDialogContent(translate(intl, errorMessages[0]));
    }

  }
  const onCreate = () => {
    const formValues = getValues();
    console.log("formValues", formValues);
    let formValidationMessage = validateForm(formValues);
    if (!formValidationMessage) {
      methods.handleSubmit(onSave, onError)();
    } else {
      setOpenErrorDialog(true);
      setErrorDialogContent(formValidationMessage);
    }

  };

  const validateForm = (values) => {
    // {isValidForm: true/false, errorMessage: "error1"}
    let formValidationMessage = null;
    if ((formValidationMessage = validateAdequateExceedCurrentStock(values))) {
      return formValidationMessage;
    }
    return formValidationMessage;
  }

  const validateAdequateExceedCurrentStock = (values) => {
    return Number(values.optCnt) > Number(values.currentCnt) ? intl.formatMessage({ id: "errorAdequateExceedCurrentStock" }) : null;
  }


  return (
    <>
      <FormProvider {...methods}>
        <CCDialogContent noPadding={true} style={{ overflowX: "hidden" }}>
          <Controller
            name={"productCreate"}
            control={control}
            defaultValue={selectRef || 1}
            render={props => {
              const { value } = props;
              return (
                <Box>
                  <ProductCreate
                    value={initData}
                    expireD={expireD}
                    onChange={e => setExpireD(e)}
                    inputRef={value}
                  />
                  <DialogWarningMessage
                    open={openErrorDialog}
                    title={errorDialogContent}
                    onAgree={e => {
                      // methods.reset(e);
                      setOpenErrorDialog(false);
                    }}
                    onClose={e => {
                      // methods.reset(e);
                      setOpenErrorDialog(false);
                    }}
                  />
                </Box>
              );
            }}
          />
        </CCDialogContent>
      </FormProvider>

      <Box
        style={{
          overflowY: "auto",
          padding: 10,
          display: "flex",
          justifyContent: "flex-end"
        }}
        marginLeft={"auto"}
        className={classes.icon__end}
      >
        <CCButton
          className={classes.cancel__icon}
          variant={"text"}
          color={"normal"}
          onClick={onCancel}
        >
          {intl.formatMessage({ id: "cancel" })}
        </CCButton>
        <CCButton
          variant={"contained"}
          color={"primary"}
          onClick={(e) => {
            onCreate();
          }}
        >
          {intl.formatMessage({ id: "save" })}
        </CCButton>

      </Box>
    </>
  );
};

export default IndividualRegistration;
