import { DialogPermissionWarn } from 'components';
import React, { useState } from 'react';
import { CCLabelCheckBox } from 'styles/src/components';

const CVLabelCheckBox = (props) => {
  const { children, permission, onChange, ...others } = props;
  const [open, setOpen] = useState(false);
  const isPermission = true;

  const handleChange = () => {
    setOpen(true);
  };

  return (
    <>
      <CCLabelCheckBox
        onChange={isPermission ? onChange : handleChange}
        {...others}
      >
        {children}
      </CCLabelCheckBox>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default CVLabelCheckBox;
