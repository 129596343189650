const getImageMapping = (
  width0,
  height0,
  width1,
  height1,
  padding = 0,
  fitToBounds = false
) => {
  let ox = 0;
  let oy = 0;
  let sx = 1;
  let sy = 1;

  if (fitToBounds) {
    sx = (width1 - padding * 2) / width0;
    sy = (height1 - padding * 2) / height0;
  } else {
    if (width1 / height1 > width0 / height0) {
      let th = height1;
      let tw = th * (width0 / height0);

      sx = (th - padding * 2) / height0;
      sy = (tw - padding * 2) / width0;
      ox = (width1 - tw) / 2;
    } else {
      let tw = width1;
      let th = tw * (height0 / width0);

      sx = (th - padding * 2) / height0;
      sy = (tw - padding * 2) / width0;
      oy = (height1 - th) / 2;
    }
  }

  return {
    ox: ox,
    oy: oy,
    sx: sx,
    sy: sy
  };
};

const getImageInfo = url => {
  return new Promise((resolve, reject) => {
    var image = new Image();
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
    image.onerror = () => {
      resolve(null);
    };
    image.crossOrigin = "Anonymous";
    image.src = url;
  });
};

export { getImageMapping, getImageInfo };
