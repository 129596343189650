import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid, MenuList, Popover } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  CVButton,
  CVLabelCheckBox,
  CVTextFieldDualType,
  enqueueToast,
  Loading,
  translate
} from "components";
import CVTextField from "components/CVTextField";
import { useSnackbar } from "notistack";
import { GET_SMTP_SETTING, SEND_EMAIL_TEST } from "queries/setting";
import { UPDATE_SMTP_SETTING } from "queries/smtpSetting";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { IMaskInput } from "react-imask";
import { useIntl } from "react-intl";
import {
  CCDialogContent,
  CCDialogInputError,
  CCDialogTitle,
  CCPaper,
  CCTextField
} from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import CCDialogActions from "styles/src/components/CCDialogActions";
import CCTypography from "styles/src/components/CCTypography";
import CheckIcon from "styles/src/themes/common/icons/CheckIcon";
import HelpIcon from "styles/src/themes/common/icons/HelpIcon";
import SMSIcon from "styles/src/themes/common/icons/SMSIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
const EmailRegExp =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*(\.[a-zA-Z]{2,})$/i;

const useStyle = makeStyles(
  theme => ({
    root: {
      width: 624,
      position: "relative"
    },
    help__button: {
      color: theme.palette.icon
    },
    content: {
      overflowY: "hidden"
    },
    subText: {
      color: theme.palette.sub.main,
      paddingLeft: 5
    },
    input: {
      width: 268
    },
    menuList: { padding: 0 },
    menuItem: {
      minWidth: 110,
      padding: `4px 8px`
    },
    title: { alignSelf: "center", marginLeft: 4 }
  }),
  { name: "SMTPSetting" }
);

const SMTP_SERVER = [
  {
    label: "google.com",
    value: "smtp.gmail.com",
    port: "587",
    ssl: true
  }
];
const initData = {
  smtpUrl: "",
  ssl: true,
  port: "",
  sender: "",
  password: "",
  direct: false
};

const postMask = props => {
  return <IMaskInput {...props} mask={"00000"} />;
};
const emailMask = props => {
  return <IMaskInput {...props} mask={/^\S*@?\S*$/} />;
};

const initializer = data => {
  return { ...data };
};
const reducer = (state, action) => {
  switch (action.type) {
    case "init":
      return {
        ...state,
        ...action.target,
        direct: !Boolean(
          SMTP_SERVER.findIndex(item => item.value === action.target.address) >
          -1
        )
      };
    case "change":
      return { ...state, ...action.target };
    default:
      break;
  }
  return state;
};

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "row",
//     backgroundColor: "#E4E4E4"
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });
// const myDoc = (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );

const validateValue = error => {
  // if (error?.smtpUrl) {
  //   return "This field is required.";
  // }
  // if (error?.port) {
  //   return "This field is required.";
  // }
  // if (error?.email) {
  //   return "Email is a invalid value.";
  // }
  // if (error?.password) {
  //   return "This field is required.";
  // }

  return null;
};

const SMTPSetting = props => {
  const classes = useStyle();
  const intl = useIntl();
  const [state, dispatchState] = useReducer(reducer, initData, initializer);
  const { enqueueSnackbar } = useSnackbar();

  const [inputErr, setInputErr] = useState(null);
  const [openHelp, setOpenHelp] = useState(null);
  const [isSending, setIsSending] = useState(null);
  const [disableSendEmail, setDisableSendEmail] = useState(null);

  const error = useRef({
    smtpUrl: false,
    port: false,
    email: false,
    password: false
  });

  const { data, loading, refetch } = useQuery(GET_SMTP_SETTING, {
    variables: {
      labId: localStorage.getItem("labId")
    },
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (data?.getConfig?.smtpSetting) {
      dispatchState({ type: "init", target: data?.getConfig?.smtpSetting });

      error.current = {
        ...error.current,
        // smtpUrl: data?.getConfig?.smtpSetting?.smtpUrl?.trim() === "",
        email:
          data?.getConfig?.smtpSetting?.sender?.trim() &&
          !EmailRegExp.test(data?.getConfig?.smtpSetting?.sender?.trim())
        //  || data?.getConfig?.smtpSetting?.sender?.trim() === "")
        // && (data?.getConfig?.smtpSetting?.sender?.trim(),
        // password: data?.getConfig?.smtpSetting?.password?.trim() === "",
        // port:
        // data?.getConfig?.smtpSetting?.port === undefined ||
        // data?.getConfig?.smtpSetting?.port + "" === ""
      };
    }
  }, [data])

  const [createSmtpSetting] = useMutation(UPDATE_SMTP_SETTING, {
    onCompleted: () => {
      enqueueToast(
        enqueueSnackbar,
        translate(intl, "common.messages.theSettingsHaveBeenSaved")
      );
      refetch();
    }
  });
  const [sendEmailTest] = useMutation(SEND_EMAIL_TEST);

  const onError = (key, value) => {
    error.current = { ...error.current, [key]: value };
  };

  const handleSendEmail = async () => {
    const language = localStorage.getItem("lang");
    setIsSending("loading");
    setDisableSendEmail(true);
    sendEmailTest({
      variables: {
        from: state.sender,
        password: state.password,
        host: state.smtpUrl,
        port: parseInt(state.port),
        ssl: state.ssl,
        language
      }
    })
      .then(res => {
        setIsSending("sent");
        if (
          res.data &&
          res.data.sendEmailTest &&
          res.data.sendEmailTest.code === 200
        ) {
          enqueueToast(
            enqueueSnackbar,
            intl.formatMessage(
              { id: "settings.smtp.toastSentTestMail" },
              { email: state.sender }
            )
          );
        } else {
          enqueueToast(
            enqueueSnackbar,
            intl.formatMessage({ id: "settings.smtp.toastFailedSentTestMail" }, { email: state.sender })
          );
        }
        setDisableSendEmail(false);
        refetch();
      })
      .catch(error => {
        setIsSending("sent");
        enqueueToast(
          enqueueSnackbar,
          translate(intl, "settings.smtp.toastFailedSentTestMail", { email: state.sender })
        );
        setDisableSendEmail(false);
        refetch();
      });
  };
  //17 action 0
  // const [createLog] = useMutation(CREATE_LOG);
  const onClickHelp = (url = "/PDF/help/smtp/smtp.pdf") => {
    window.open(url, "_blank");
  };

  let manualList = []
  if (process.env.REACT_APP_LANGUAGE_REGION) {
    switch (process.env.REACT_APP_LANGUAGE_REGION) {
      case "vi":
        manualList.push({
          label: "VietNam",
          value: "/PDF/help/smtp/[Clever_Lab_User_Manual]_SMTP_Setting_VIE.pdf",
        })
        break
      case "ko":
        manualList.push({
          label: "Korean",
          value: "/PDF/help/smtp/[Clever_Lab_User_Manual]_SMTP_Setting_KOR.pdf",
        })
        break
      case "ru":
        manualList.push({
          label: "Russian",
          value: "/PDF/help/smtp/[Clever_Lab_User_Manual]_SMTP_Setting_RUS.pdf",
        })
        break
      case "ar":
        manualList.push({
          label: "English",
          value: "/PDF/help/smtp/[Clever_Lab_User_Manual]_SMTP_Setting_ENG.pdf",
        })
        manualList.push({
          label: "عربى",
          value: "/PDF/help/smtp/[Clever_Lab_User_Manual]_SMTP_Setting_ARB.pdf",
        })
        break
      default:
        manualList.push({
          label: "English",
          value: "/PDF/help/smtp/[Clever_Lab_User_Manual]_SMTP_Setting_ENG.pdf",
        })
    }
  }
  return (
    <Box className={classes.root}>
      <CCPaper>
        <Loading open={loading} msg={"Loading..."} />
        <CCDialogTitle>
          {translate(intl, "common.ui.smtp")}
        </CCDialogTitle>
        <CCDialogContent className={classes.content}>
          <Grid container direction={"column"}>
            <Grid item>
              <Grid container alignItems={"center"}>
                <Grid item>
                  <CVTextFieldDualType
                    permission={"smtpGeneralSetting"}
                    className={classes.input}
                    value={state.smtpUrl}
                    label={intl.formatMessage({
                      id: "settings.smtp.smtpSetting"
                    })}
                    variant={"outlined"}
                    margin={"dense"}
                    error={error.current.smtpUrl}
                    directInput={state.direct}
                    onChange={e => {
                      // onError("smtpUrl", e.target.value.trim() === "");
                      if (state.direct) {
                        dispatchState({
                          type: "change",
                          target: {
                            smtpUrl: e.target.value,
                            direct: !e.target.select
                          }
                        });
                      } else {
                        let _index = SMTP_SERVER.findIndex(
                          item => item.value === e.target.value
                        );
                        if (_index > -1) {
                          // onError("port", !SMTP_SERVER[_index].port);
                          dispatchState({
                            type: "change",
                            target: {
                              smtpUrl: e.target.value,
                              direct: !e.target.select,
                              port: SMTP_SERVER[_index].port,
                              ssl: SMTP_SERVER[_index].ssl
                            }
                          });
                        } else {
                          dispatchState({
                            type: "change",
                            target: {
                              smtpUrl: e.target.value,
                              direct: !e.target.select
                            }
                          });
                        }
                      }
                    }}
                  >
                    {SMTP_SERVER.map(item => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </CVTextFieldDualType>
                </Grid>
                <Grid item>
                  <CVLabelCheckBox
                    permission={"smtpGeneralSetting"}
                    color={"secondary"}
                    checked={state.ssl}
                    onChange={e => {
                      dispatchState({
                        type: "change",
                        target: { ssl: e.target.checked }
                      });
                    }}
                    label={translate(intl, "settings.smtp.ssl")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CVTextField
                permission={"smtpGeneralSetting"}
                className={classes.input}
                value={state.port + ""}
                label={translate(intl, "settings.smtp.port")}
                error={error.current.port}
                variant={"outlined"}
                margin={"dense"}
                onChange={e => {
                  // onError(
                  //   "port",
                  //   !e.target.value || e.target.value + "" === ""
                  // );
                  dispatchState({
                    type: "change",
                    target: { port: e.target.value }
                  });
                }}
                InputProps={{
                  inputComponent: postMask,
                  inputProps: {
                    maxLength: 20
                  }
                }}
              />
            </Grid>
            <Grid item>
              <CVTextField
                permission={"smtpGeneralSetting"}
                className={classes.input}
                value={state.sender}
                label={translate(intl, "settings.smtp.emailAccount")}
                variant={"outlined"}
                margin={"dense"}
                error={error.current.email}
                onChange={e => {
                  onError(
                    "email",
                    Boolean(e.target.value.trim() &&
                      !EmailRegExp.test(e.target.value.trim()))
                  );
                  dispatchState({
                    type: "change",
                    target: { sender: e.target.value }
                  });
                }}
                InputProps={{
                  inputComponent: emailMask,
                  inputProps: {
                    maxLength: 200
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"}>
                <Grid item>
                  <CCTextField
                    permission={"smtpGeneralSetting"}
                    className={classes.input}
                    type={"password"}
                    value={state.password}
                    label={translate(intl, "signIn.ui.password")}
                    error={error.current.password}
                    variant={"outlined"}
                    margin={"dense"}
                    InputProps={{
                      inputProps: {
                        maxLength: 200
                      }
                    }}
                    onChange={e => {
                      // onError("password", e.target.value.trim() === "");
                      dispatchState({
                        type: "change",
                        target: { password: e.target.value }
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  {/* <BlobProvider document={myDoc}>
                    {props => {
                      return ( */}
                  <CVButton
                    disabled={disableSendEmail}
                    permission={"smtpGeneralSetting"}
                    className={classes.help__button}
                    variant={"outlined"}
                    color={"normal"}
                    startIcon={
                      isSending === "loading" ? (
                        <CircularProgress size={20} color="normal" />
                      ) : isSending === "sent" ? (
                        <SMSIcon />
                      ) : (
                        <SMSIcon />
                      )
                    }
                    onClick={async () => {
                      // if (
                      //   !state.sender ||
                      //   !state.password ||
                      //   !state.smtpUrl ||
                      //   !state.port
                      // ) {
                      //   setOpen(true);
                      // } else {
                      //   let _content = {};
                      //   let _attachment = await blobToBase64(props.blob);
                      //   _content["sender"] = state.sender.toString();
                      //   _content["receiver"] = state.sender.toString();
                      //   _content["title"] = "[Clever]SMTP Test Mail";
                      //   _content["password"] = state.password.toString();
                      //   _content["port"] = state.port.toString();
                      //   _content["ssl"] = Boolean(state?.ssl)
                      //     ? "true"
                      //     : "false";
                      //   _content["smtpUrl"] = state.smtpUrl.toString();
                      //   _content["content"] = testMailTemplate;
                      //   _content["id"] = "";
                      //   // _content["attachment"] = [
                      //   //   { name: "test1.pdf", data: _attachment },
                      //   //   { name: "test2.pdf", data: _attachment },
                      //   //   { name: "test3.pdf", data: _attachment },
                      //   //   { name: "test4.pdf", data: _attachment },
                      //   //   { name: "test5.pdf", data: _attachment }
                      //   // ];
                      //   _content["attachmentCount"] = "0";
                      //   Email(_content);
                      // }

                      handleSendEmail();
                      // alert("Test Mail 발송 되었습니다.");
                    }}
                  >
                    {intl.formatMessage({
                      id: "settings.smtp.sentTestMail"
                    })}
                  </CVButton>
                  {/* );
                    }}
                  </BlobProvider> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CCTypography
                variant={"body1"}
                component={"div"}
                className={classes.subText}
              >
                {translate(intl, "settings.smtp.helpGuide.1")}
              </CCTypography>
              <CCTypography
                variant={"body1"}
                component={"div"}
                className={classes.subText}
              >

                {translate(intl, "settings.smtp.helpGuide.2")}
              </CCTypography>
            </Grid>
          </Grid>
        </CCDialogContent>
        <CCDialogActions>
          <Grid container justify={"space-between"} alignItems={"center"}>
            <Grid item>
              <CVButton
                className={classes.help__button}
                variant={"outlined"}
                color={"normal"}
                startIcon={<HelpIcon />}
                onClick={e => {
                  if (manualList.length > 1) {
                    setOpenHelp(e.currentTarget)
                  } else if (manualList.length === 1) {
                    onClickHelp(
                      manualList[0].value,
                      "_blank"
                    );
                  }
                }
                }
              >
                {translate(intl, "settings.smtp.help")}
              </CVButton>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  {/*<CCButton*/}
                  {/*  variant={"text"}*/}
                  {/*  color={"normal"}*/}
                  {/*  onClick={() => {*/}
                  {/*    setTestOpen(true);*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {"취소"}*/}
                  {/*</CCButton>*/}
                  <CVButton
                    variant={"text"}
                    color={"normal"}
                    onClick={() => {
                      refetch().then(result => {
                        if (result?.data?.getConfig?.smtpSetting) {
                          dispatchState({
                            type: "init",
                            target: data?.getConfig?.smtpSetting
                          });

                          error.current = {
                            ...error.current,
                            // smtpUrl:
                            //   data?.getConfig?.smtpSetting?.smtpUrl?.trim() ===
                            //   "",
                            email:
                              // (data?.getConfig?.smtpSetting?.sender?.trim() &&
                              //   data?.getConfig?.smtpSetting?.sender?.trim() ===
                              //   "") ||
                              data?.getConfig?.smtpSetting?.sender?.trim() &&
                              !EmailRegExp.test(
                                data?.getConfig?.smtpSetting?.sender?.trim()
                              )
                            // password:
                            // data?.getConfig?.smtpSetting?.password?.trim() ===
                            // "",
                            // port:
                            //   data?.getConfig?.smtpSetting?.port ===
                            //   undefined ||
                            //   data?.getConfig?.smtpSetting?.port + "" === ""
                          };
                        }
                      });
                    }}
                  >
                    {translate(intl, "common.ui.cancel")}
                  </CVButton>
                </Grid>
                <Grid item>
                  <CVButton
                    permission={"smtpGeneralSetting"}
                    variant={"contained"}
                    color={"primary"}
                    startIcon={<CheckIcon />}
                    onClick={() => {
                      if (validateValue(error.current)) {
                        setInputErr(validateValue(error.current));
                        return;
                      }
                      createSmtpSetting({
                        variables: {
                          // labId: localStorage.getItem('labId'),
                          labId: localStorage.getItem("labId"),

                          smtpSetting: {
                            password: state.password,
                            port: parseInt(state.port),
                            sender: state.sender,
                            smtpUrl: state.smtpUrl,
                            ssl: state.ssl
                          }
                        }
                      }).then(() => {
                        if (window.opener) {
                          window.opener.postMessage(
                            { source: "clboard", payload: "close" },
                            "*"
                          );
                          window.close();
                        }
                      });
                    }}
                  >
                    {translate(intl, "common.ui.save")}
                  </CVButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CCDialogActions>
      </CCPaper>
      {/* <CCDialogInputError
        open={open}
        onClose={() => setOpen(false)}
        title={
          translate( intl,"settings.smtp.setupConfirm"})
        }
      /> */}
      {Boolean(inputErr) && (
        <CCDialogInputError
          open={Boolean(inputErr)}
          title={inputErr}
          onClose={() => setInputErr(null)}
        />
      )}
      {/*<DentalRequestDialog open={testOpen} onClose={() => setTestOpen(false)} />*/}

      {Boolean(openHelp) && (
        <Popover
          anchorEl={openHelp}
          onClose={() => setOpenHelp(false)}
          open={Boolean(openHelp)}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className={classes.menuList}>
            {manualList.map(each => {
              return (
                <MenuItem
                  className={clsx(classes.menuItem)}
                  onClick={() => {
                    onClickHelp(
                      each.value,
                      "_blank"
                    );
                  }}
                >
                  <CCTypography variant={"body1"} className={classes.title}>
                    {each.label}
                  </CCTypography>
                </MenuItem>
              )
            })}

          </MenuList>
        </Popover>
      )}
    </Box>
  );
};
export default SMTPSetting;
