const LAB_GOLD_TYPES = {
  INLAY: {
    value: "1",
    localeKey: "partner.lab.goldType.inlay"
  },
  A_TYPE: {
    value: "2",
    localeKey: "partner.lab.goldType.aType"
  },
  SA: {
    value: "3",
    localeKey: "partner.lab.goldType.sa"
  },
  PT: {
    value: "4",
    localeKey: "partner.lab.goldType.pt"
  },
  PFG: {
    value: "5",
    localeKey: "partner.lab.goldType.pfg"
  }
};

const LAB_GOLD_TYPES_MAPPING = {
  1: "inlay",
  2: "aType",
  3: "sA",
  4: "pt",
  5: "pfg"
};

const GOLD_OWNERSHIP_TYPES = {
  LAB_GOLD: {
    value: "1",
    localeKey: "partner.lab.goldOwnership.labGold"
  },
  CLINIC: {
    value: "2",
    localeKey: "partner.lab.goldOwnership.clinic"
  },
  LAB_STORAGE: {
    value: "3",
    localeKey: "partner.lab.goldOwnership.labStorage"
  }
};

const GOLD_SOURCING_METHOD_TYPES = {
  RECEIVED_GOLD: {
    value: "2",
    localeKey: "partner.clinic.goldSourcingMethod.receivedGold"
  },
  USE_SUPPLIED_GOLD: {
    value: "3",
    localeKey: "partner.clinic.goldSourcingMethod.useSuppliedGold"
  },
  USE_LABS_GOLD: {
    value: "1",
    localeKey: "partner.clinic.goldSourcingMethod.useLabsGold"
  }
};

export {
  LAB_GOLD_TYPES,
  GOLD_OWNERSHIP_TYPES,
  LAB_GOLD_TYPES_MAPPING,
  GOLD_SOURCING_METHOD_TYPES
};
