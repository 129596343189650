import React, { useEffect, useState } from "react";
import { useIntl } from "utils/language";
import {
  CVButton,
  CVTextField,
  DialogWarningMessage,
  ErrorTooltip
} from "components";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from "@material-ui/core";
import {
  CCTextFieldNumber,
  CCTooltip,
  CCTypography
} from "styles/src/components";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
import ExpireTable from "../ExpireTable";
import { getTimestamp } from "utils/datetime";
import IMask from "imask";
import OutgoingTable from "../OutgoingTable";

const useStyles = makeStyles(theme => ({
  small__button: {
    padding: 2,
    margin: 8,
    height: "20px",
    width: "290px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba,
    "&:hover": {
      backgroundColor: theme.palette.select.main
    }
  },
  font__field: {
    fontSize: 14,
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    weight: "bold"
  },
  head__field: {
    width: "100px"
  },
  head__storage__field: {
    width: props => {
      if (props) {
        return 405;
      } else {
        return 505;
      }
    }
  },
  back: {
    backgroundColor: theme.palette.result.main
  },
  selected: {
    backgroundColor: theme.palette.select.main
  },
  text__field: {
    width: "80px"
  },
  detail__border: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "content-box"
  },
  background__color: {
    backgroundColor: theme.palette.result.main,
    padding: 2,
    boxSizing: "inherit"
  },
  expire__table: {
    height: 250
  }
}));

const ButtonTabs = props => {
  const { tabList, detail, inOut, expireData, expireList } = props;
  const intl = useIntl();
  const { control, watch, errors } = useFormContext();
  const [alertDialog, setAlertDialog] = useState(false);
  const [needPrice, setNeedPrice] = useState(false);

  const classes = useStyles(needPrice);

  const numberFilter = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
  const vietFilter = /^[0-9]*$/;

  const numberPipe = new IMask.createPipe({
    mask:
      process?.env?.REACT_APP_REGION === "vietnam" ? vietFilter : numberFilter
  });

  const detailInformation = watch(`detailInformation_tab${inOut}`)

  useEffect(() => {
    if (
      Boolean(
        detailInformation === "otherPurchase" ||
        detailInformation === "otherReturn"
      )
    ) {
      setNeedPrice(true);
    } else {
      setNeedPrice(false);
    }
  }, [detailInformation]);

  return (
    <>
      <Box className={classes.detail__border}>
        <Box>
          <CCTypography className={classes.font__field}>
            {intl.formatMessage({ id: `detailInformation` })}
          </CCTypography>
        </Box>
        <Box>
          <Controller
            name={`detailInformation_tab${inOut}`}
            control={control}
            defaultValue={inOut === 1 ? "materialUsage" : "errorCorrection"}
            render={props => {
              const { onChange, onBlur } = props.field;
              return tabList?.map((d, i) => (
                <CCTooltip
                  title={intl.formatMessage({ id: `${tabList[i]}Tip` })}
                >
                  <CVButton
                    key={tabList[i]}
                    variant={"contained"}
                    color="normal"
                    className={clsx(classes.small__button, {
                      [classes.selected]:
                        watch(`detailInformation_tab${inOut}`) === tabList[i]
                    })}
                    onClick={() => {
                      onChange(tabList[i]);
                    }}
                    onBlur={onBlur}
                  >
                    {intl.formatMessage({ id: d }, { br: () => <br /> })}
                  </CVButton>
                </CCTooltip>
              ));
            }}
          />
        </Box>
      </Box>

      <Grid>
        <Box>
          {!inOut
            ? <Controller
              name={"currentCnt"}
              control={control}
              defaultValue={detail?.receivingCount || 0}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;
                return (
                  <ErrorTooltip
                    open={Boolean(errors?.currentCnt?.message)}
                    errorMsg={errors?.currentCnt?.message}
                    placement={"bottom-start"}
                  >
                    <CCTextFieldNumber
                      className={classes.text__field}
                      label={
                        inOut === 0
                          ? intl.formatMessage({ id: "receivingQuantity" })
                          : intl.formatMessage({ id: "shippingCnt" })
                      }
                      number={value || 0}
                      decimalPoint={0}
                      step={1}
                      onChange={e => {
                        if (
                          Number(detail?.storage?.currentCnt) < Number(e) &&
                          inOut === 1
                        ) {
                          setAlertDialog({
                            title: intl.formatMessage(
                              { id: "inputCntExceedCurrentCount" },
                              {
                                count: Number(detail?.storage?.currentCnt)
                              },
                              {}
                            ),
                            onAgree: () => {
                              onChange(0);
                              setAlertDialog(false);
                            }
                          });
                        } else {
                          onChange(numberPipe(e));
                        }
                      }}
                      inputRef={ref}
                      onBlur={onBlur}
                      inputProps={{
                        maxLength: 5
                      }}
                    />
                  </ErrorTooltip>
                );
              }}
            />
            : <Controller
              name={"currentCntOut"}
              control={control}
              defaultValue={detail?.receivingCount || 0}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;
                return (
                  <ErrorTooltip
                    open={Boolean(errors?.currentCnt?.message)}
                    errorMsg={errors?.currentCnt?.message}
                    placement={"bottom-start"}
                  >
                    <CCTextFieldNumber
                      className={classes.text__field}
                      label={
                        inOut === 0
                          ? intl.formatMessage({ id: "receivingQuantity" })
                          : intl.formatMessage({ id: "shippingCnt" })
                      }
                      number={value || 0}
                      decimalPoint={0}
                      step={1}
                      onChange={e => {
                        if (
                          Number(detail?.storage?.currentCntOut) < Number(e) &&
                          inOut === 1
                        ) {
                          setAlertDialog({
                            title: intl.formatMessage(
                              { id: "inputCntExceedCurrentCount" },
                              {
                                count: Number(detail?.storage?.currentCntOut)
                              },
                              {}
                            ),
                            onAgree: () => {
                              onChange(0);
                              setAlertDialog(false);
                            }
                          });
                        } else {
                          onChange(numberPipe(e));
                        }
                      }}
                      inputRef={ref}
                      onBlur={onBlur}
                      inputProps={{
                        maxLength: 5
                      }}
                    />
                  </ErrorTooltip>
                );
              }}
            />}


          {needPrice && (
            <Controller
              name={"retailPrice"}
              control={control}
              defaultValue={detail?.retailPrice || 0}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;
                return (
                  <CVTextField
                    className={classes.text__field}
                    value={value}
                    inputRef={ref}
                    onChange={e => onChange(numberPipe(e.target.value))}
                    onBlur={onBlur}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "unitPriceGold" })}
                    inputProps={{
                      maxLength: 15
                    }}
                  />
                );
              }}
            />
          )}

          <Controller
            name={"remarks"}
            control={control}
            defaultValue={detail?.remarks || ""}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;
              return (
                <ErrorTooltip
                  open={Boolean(errors?.expireList?.message)}
                  errorMsg={errors?.expireList?.message}
                  placement={"bottom-middle"}
                  popperOptions={{
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "230,80"
                      }
                    }
                  }}
                >
                  <CVTextField
                    className={clsx(
                      classes.head__field,
                      classes.head__storage__field
                    )}
                    value={value}
                    inputRef={ref}
                    onChange={e => onChange(e.target.value)}
                    onBlur={onBlur}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "remarks" })}
                    inputProps={{
                      maxLength: 200
                    }}
                  />
                </ErrorTooltip>
              );
            }}
          />
        </Box>

        {Boolean(expireData?.length > 0) && (
          <Box className={classes.expire__table}>
            {inOut === 0 ? (
              <Controller
                name={"expireList"}
                control={control}
                defaultValue={[
                  {
                    expireDate: getTimestamp(),
                    expireCount: 0
                  }
                ]}
                render={props => {
                  const { ref, value, onChange, onBlur } = props.field;
                  return (
                    <ExpireTable
                      expire={expireList?.canExpire}
                      currentCnt={watch("currentCnt")}
                      value={value}
                      onChange={e => {
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  );
                }}
              />
            ) : (
              <Controller
                name={"expireListOut"}
                control={control}
                defaultValue={((expireData) => {
                  const eData = expireData.map(e => {
                    return { ...e, count: e.count ? e.count : 0 }
                  })
                  return eData;
                })(expireData)}
                render={props => {
                  const { ref, value, onChange, onBlur } = props.field;
                  return (
                    <OutgoingTable
                      currentCntOut={watch("currentCntOut")}
                      value={value}
                      fixed={expireData}
                      onChange={e => {
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  );
                }}
              />
            )}
          </Box>
        )}

        <DialogWarningMessage
          open={Boolean(alertDialog)}
          agreeButtonLabel={intl.formatMessage({ id: "check" })}
          {...alertDialog}
        />
      </Grid>
    </>
  );
};

export default ButtonTabs;
