import React from "react";

const MessageIcon18 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M15 1.5c.825 0 1.5.675 1.5 1.5v9c0 .825-.675 1.5-1.5 1.5H4.5l-3 3L1.507 3c0-.825.668-1.5 1.493-1.5zM13.5 9h-9v1.5h9V9zm0-2.25h-9v1.5h9v-1.5zm0-2.25h-9V6h9V4.5z"
      />
      <path d="M0 0H18V18H0z" />
    </g>
  </svg>
);
export default MessageIcon18;
