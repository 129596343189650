import PERMISSIONS from "types/permission";

const JOB_TYPES = {
  TECHNICIAN: { value: "1", localeKey: "jobTypes.technician" },
  ACCOUNTANT: { value: "2", localeKey: "jobTypes.accountant" }
};

const JOB_POSITION = {
  STAFF: { value: "1", localeKey: "jobPosition.staff" },
  MANAGER: { value: "2", localeKey: "jobPosition.manager" }
};

const CHARGE = {
  NO_CHOICE: { value: "1", localeKey: "charge.noChoice" },
  DOCTOR_IN_CHARGE: { value: "2", localeKey: "charge.doctorInCharge" },
  STAFF_IN_CHARGE: { value: "3", localeKey: "charge.staffInCharge" }
};

const STATUS = {
  WORKING: { value: "0", localeKey: "status.working" },
  IN_OFFICE: { value: "1", localeKey: "status.inOffice" },
  LEAVE_OF_ABSENCE: { value: "2", localeKey: "status.leaveOfAbsence" },
  MATERNITY_LEAVE: { value: "3", localeKey: "status.maternityLeave" },
  RESIGNED: { value: "4", localeKey: "status.resigned" }
};

const PERMISSION = PERMISSIONS.PERMISSION;

const PAY_BANK = {
  SHINHAN_BANK: { value: "1", localeKey: "payBank.shinhanBank" },
  KOOKMIN_BANK: { value: "2", localeKey: "payBank.kookminBank" },
  WOORI_BANK: { value: "3", localeKey: "payBank.wooriBank" },
  HANA_BANK: { value: "4", localeKey: "payBank.hanaBank" },
  SC_FIRST_BANK: { value: "5", localeKey: "payBank.scFirstBank" },
  KOREA_CITY_BANK: { value: "6", localeKey: "payBank.koreaCityBank" },
  AGRICULTURAL_COOPERATION: {
    value: "7",
    localeKey: "payBank.agriculturalCooperation"
  },
  SIOUX_COOPERATION: { value: "8", localeKey: "payBank.siouxCooperation" },
  INDUSTRIAL_BANK: { value: "9", localeKey: "payBank.industrialBank" },
  EXPORT_IMPORT_BANK_OF_KOREA: {
    value: "10",
    localeKey: "payBank.exportImportBankOfKorea"
  },
  CORPORATE_BANK: { value: "11", localeKey: "payBank.corporateBank" },
  GYEONGNAM_BANK: { value: "12", localeKey: "payBank.gyeongnamBank" },
  GWANGJU_BANK: { value: "13", localeKey: "payBank.gwangjuBank" },
  DAEGU_BANK: { value: "14", localeKey: "payBank.daeguBank" },
  BUSAN_BANK: { value: "15", localeKey: "payBank.busanBank" },
  JEONBUK_BANK: { value: "16", localeKey: "payBank.jeonbukBank" },
  JEJU_BANK: { value: "17", localeKey: "payBank.jejuBank" }
};

const WORKING_FORM = {
  FULL_TIME: { value: "1", localeKey: "workingForm.fullTime" },
  PART_TIME: { value: "2", localeKey: "workingForm.partTime" },
  SUBSTITUTE_DOCTOR: { value: "3", localeKey: "workingForm.substituteDoctor" },
  OTHER: { value: "4", localeKey: "workingForm.other" }
};

export {
  JOB_POSITION,
  JOB_TYPES,
  CHARGE,
  STATUS,
  PERMISSION,
  PAY_BANK,
  WORKING_FORM
};
export default JOB_TYPES;
