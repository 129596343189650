import moment from "moment";
import React from "react";
import { CCTypography } from "styles/components";
import { generateOrdersPieGraph, generateSalesBarGraph } from "types/graph";
import { formatDateTitle } from "utils/graph";
import { translate } from "../components";

export const parseOrders = (data, searchDate) => {
  if (!data) {
    return {
      total: 0,
      new: 0,
      remake: 0,
      mend: 0
    };
  } else {
    const value = data.find(
      item => moment(searchDate).locale('en').format("YYYY-MM-DD") === item.time || moment(searchDate).locale('en').format("YYYY-MM") === item.time || moment(searchDate).locale('en').format("YYYY") === item.time
    );

    return {
      total: value?.total || 0,
      new: value?.new || 0,
      remake: value?.remake || 0,
      mend: value?.mend || 0
    };
  }
};

export const parseOrdersData = (data = [], labels, intl) => {
  const totalData = [];
  const NewData = [];
  const remakeData = [];
  const mendData = [];
  labels.forEach(label => {
    const { total, new: newValue, remake, mend } = parseOrders(data, label);
    totalData.push(total);
    NewData.push(newValue);
    remakeData.push(remake);
    mendData.push(mend);
  });
  return [
    {
      name: translate(intl, "common.ui.total"),
      type: "line",
      data: totalData
    },
    {
      name: translate(intl, "common.ui.new"),
      type: "column",
      data: NewData
    },
    {
      name: translate(intl, "labOrder.modifyRequest"),
      type: "column",
      data: mendData
    },
    {
      name: translate(intl, "common.ui.remake"),
      type: "column",
      data: remakeData
    }
  ];
};

export const generateSalesFields = (dateLabel, currentDate, dateOption) => {
  return [
    {
      title: `의사별 총 매출액 (${dateLabel})`,
      label: "total",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const industryExpense = value && value.gongdan ? value.gongdan : 0;
        const insureCard =
          value && value.charge && value.charge.card ? value.charge.card : 0;
        const insureAccount =
          value && value.charge && value.charge.account
            ? value.charge.account
            : 0;
        const insureCash =
          value && value.charge && value.charge.cash ? value.charge.cash : 0;
        // const insureTotal =
        //   value && value.charge && value.charge.price ? value.charge.price : 0;
        const insureTotal = insureCard + insureCash + insureAccount;

        const uninsureCard =
          value && value.uninsured && value.uninsured.card
            ? value.uninsured.card
            : 0;
        const uninsureAccount =
          value && value.uninsured && value.uninsured.account
            ? value.uninsured.account
            : 0;
        const uninsureCash =
          value && value.uninsured && value.uninsured.cash
            ? value.uninsured.cash
            : 0;

        // const uninsureTotal =
        //   value && value.uninsured && value.uninsured.price
        //     ? value.uninsured.price
        //     : 0;
        const uninsureTotal = uninsureCard + uninsureCash + uninsureAccount;
        const advance = value && value.sunsu ? value.sunsu : 0;
        return industryExpense + insureTotal + uninsureTotal + advance;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 보험 (공단부담금+본인부담금)(${dateLabel})`,
      label: "insured",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const industryExpense = value && value.gongdan ? value.gongdan : 0;
        const insureCard =
          value && value.charge && value.charge.card ? value.charge.card : 0;
        const insureAccount =
          value && value.charge && value.charge.account
            ? value.charge.account
            : 0;
        const insureCash =
          value && value.charge && value.charge.cash ? value.charge.cash : 0;
        // const insureTotal =
        //   value && value.charge && value.charge.price ? value.charge.price : 0;
        const insureTotal = insureCard + insureCash + insureAccount;
        return industryExpense + insureTotal;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 비보험+선수금 (${dateLabel})`,
      label: "uninsured",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const uninsureCard =
          value && value.uninsured && value.uninsured.card
            ? value.uninsured.card
            : 0;
        const uninsureAccount =
          value && value.uninsured && value.uninsured.account
            ? value.uninsured.account
            : 0;
        const uninsureCash =
          value && value.uninsured && value.uninsured.cash
            ? value.uninsured.cash
            : 0;
        // const uninsureTotal =
        //   value && value.uninsured && value.uninsured.price
        //     ? value.uninsured.price
        //     : 0;
        const uninsureTotal = uninsureCard + uninsureCash + uninsureAccount;
        const advance = value && value.sunsu ? value.sunsu : 0;
        return uninsureTotal + advance;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    }
  ];
};

export const salesStructure = intl => {
  return {
    title: translate(intl, "menuStatistics.orders"),
    // fetchURL: process.env.REACT_APP_CF_QUERY_URL + "/payments",
    dataParser: parseOrdersData,
    singleDataParser: parseOrders,
    generateFields: generateSalesFields,
    graphFunction: generateSalesBarGraph,
    pieGraphFunction: value =>
      generateOrdersPieGraph(
        [value.insured, value.uninsured],
        value.insured + value.uninsured,
        intl
      ),
    head: [
      {
        label: translate(intl, "labManageGold.no"),
        key: "date",
        width: 50,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography>{rowIndex + 1}</CCTypography>;
        }
      },
      {
        label: translate(intl, "common.ui.clinic"),
        key: "clinic",
        flex: 1,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography style={{textOverflow: "ellipsis", wordBreak: "break-all"}}>{cellData}</CCTypography>;
        }
      },
      {
        label: translate(intl, "common.ui.new"),
        key: "new",
        width: 80,
        component: ({ cellData, rowData }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      },
      {
        label: translate(intl, "labOrder.modifyRequest"),
        key: "mend",
        width: 80,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      },
      {
        label: translate(intl, "common.ui.remake"),
        key: "remake",
        width: 80,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      },
      {
        label: translate(intl, "common.ui.total"),
        key: "total",
        width: 80,
        component: ({ cellData, rowData }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      }
    ]
  }
};
