import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { YearPicker, YearMonthPicker } from "components";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
// import MonthPicker from "../MonthPicker";
import { translate } from "components";
const useStyles = makeStyles(theme => ({
  button: {
    width: "70px",
    height: "30px",
    display: "flex",
    color: "white",
    cursor: "pointer",
    borderRadius: "18px",
    backgroundColor: theme.palette.sub.main,
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px"
  },
  divided: {
    width: 1,
    height: 36,
    backgroundColor: "#b0bec5",
    margin: "0 16px"
  },
  width130: { width: 130 },
  selected: {
    backgroundColor: theme.palette.select.main
  }
}));

const DatePicker = props => {
  const { isYearFilter, onChange, monthFilterValue, yearFilterValue } = props;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Box
        className={clsx({
          [classes.button]: true,
          [classes.selected]: !isYearFilter
        })}
        onClick={() => onChange("isYearFilter", false)}
      >
        {translate(intl, "payment.month")}
      </Box>
      <Box
        className={clsx({
          [classes.button]: true,
          [classes.selected]: isYearFilter
        })}
        variant={"outlined"}
        onClick={() => onChange("isYearFilter", true)}
      >
        {translate(intl, "payment.year")}
      </Box>
      <Box className={classes.divided} />
      {!isYearFilter ? (
        <YearMonthPicker
          label=""
          className={classes.width130}
          margin={"dense"}
          variant={"outlined"}
          value={
            monthFilterValue
              ? moment(
                  `${monthFilterValue}/01/${yearFilterValue}`,
                  "MM/DD/YYYY"
                ).unix()
              : moment().unix()
          }
          onChange={e => {
            onChange(
              "monthFilterValue",
              `${moment.unix(e.target.value).get("month") + 1}`
            );
            onChange(
              "yearFilterValue",
              `${moment.unix(e.target.value).get("year")}`
            );
          }}
        />
      ) : (
        <YearPicker
          value={
            yearFilterValue
              ? moment(`01/01/${yearFilterValue}`).unix()
              : moment().unix()
          }
          onChange={e => {
            onChange(
              "yearFilterValue",
              moment(e.target.value, "X").get("year").toString()
            );
          }}
        />
      )}
    </>
  );
};

export default DatePicker;
