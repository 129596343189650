import { makeStyles } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogSimple
} from "styles/components";
import { WarningIcon } from "styles/icons";

const useStyles = makeStyles(theme => ({
  root: {},
  error: {
    color: theme.palette.error.main
  }
}));

const DialogErrorConfirm = props => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <CCDialogSimple
      open={props.open}
      title={<WarningIcon className={classes.error} />}
      onClose={props.onClose}
      contents={props.contents}
      endActions={
        <CCButton color="primary" onClick={props.onClick}>
          <div style={{
            textTransform: "initial",
            fontWeight: "bold",
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.43,
            letterSpacing: 'normal',
            textAlign: 'center',
            color: "#01579b"
          }}>
            {intl.formatMessage({ id: "common.ui.confirm" })}</div>
        </CCButton>
      }
    />
  )
}

export default DialogErrorConfirm