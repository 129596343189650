import Paper, { Path, Point, Rectangle } from "paper";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";

const hitOptions = {
  segments: false,
  stroke: false,
  fill: false,
  tolerance: 5
};

const WIDTH = 450;
const HEIGHT = 450;

const CCSketch = forwardRef((props, ref) => {
  const {
    className,
    width,
    height,
    backgroundColor,
    strokeColor,
    strokeWidth,
    simplification,
    fullySelected,
    contents,
    onDraw
  } = props;
  const canvasRef = useRef(null);
  const [paper] = useState(new Paper.PaperScope());
  const [current, setCurrent] = useState(null);
  const [segment, setSegment] = useState(null);
  const [point, setPoint] = useState([0, 0]);
  const [offset, setOffset] = useState([0, 0]);

  const handleDown = e => {
    paper.activate();

    var hitResult = paper.project.hitTest(
      new Point(point[0], point[1]),
      hitOptions
    );

    if (!hitResult) {
      setCurrent(
        new Path({
          strokeColor: strokeColor,
          strokeWidth: (strokeWidth * WIDTH) / width,
          fullySelected: fullySelected ? fullySelected : false
        })
      );
    } else {
      setCurrent(hitResult.item);
      if (hitResult.type === "segment") {
        setSegment(hitResult.segment);
      } else if (hitResult.type === "stroke") {
        var location = hitResult.location;
        setSegment(
          hitResult.item.insert(
            location.index + 1,
            new Point(point[0], point[1])
          )
        );
        hitResult.item.smooth();
      }
    }
  };

  const handleUp = e => {
    paper.activate();

    if (current != null) {
      if (simplification) {
        current.simplify(simplification);
      }

      current.fullySelected = fullySelected ? fullySelected : false;

      onDraw(current.pathData);

      setCurrent(null);
      setSegment(null);
    }
  };

  const handleMove = e => {
    paper.activate();

    const point = getCanvasPoint(e);

    if (segment != null) {
      segment.point.x += point[0] - offset[0];
      segment.point.y += point[1] - offset[1];
    } else if (current != null) {
      current.add(point[0], point[1]);
    }

    setOffset(point);
    setPoint(point);
  };

  const getCanvasPoint = e => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    var clientX = e.clientX;
    var clientY = e.clientY;

    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX;
      clientY = e.changedTouches[0].clientY;
    }

    clientX -= rect.left;
    clientY -= rect.top;

    return [(clientX * WIDTH) / width, (clientY * HEIGHT) / height];
  };

  useEffect(() => {
    paper.setup(canvasRef.current);

    return () => {
      paper.remove();
    };
  }, [paper]);

  useEffect(() => {
    paper.activate();
    paper.project.clear();
    paper.project.view.matrix.reset();
    paper.project.view.scale(width / WIDTH, height / HEIGHT, new Point(0, 0));

    if (backgroundColor) {
      var background = new Path.Rectangle(
        new Rectangle(new Point(0, 0), new Point(WIDTH, HEIGHT))
      );
      background.locked = true;
      background.fillColor = backgroundColor;
    }

    if (contents) {
      contents.forEach(cmd => {
        var item = new Path(cmd);
        item.strokeColor = strokeColor;
        item.strokeWidth = (strokeWidth * WIDTH) / width;
      });
    }
  }, [
    paper,
    width,
    height,
    contents,
    backgroundColor,
    strokeColor,
    strokeWidth
  ]);

  useImperativeHandle(ref, () => ({
    dispatchReset() {
      paper.activate();
      paper.project.clear();
    }
  }));

  return (
    <canvas
      className={className}
      ref={canvasRef}
      width={width}
      height={height}
      onMouseDown={e => (onDraw ? handleDown(e) : {})}
      onMouseUp={e => (onDraw ? handleUp(e) : {})}
      onMouseMove={e => (onDraw ? handleMove(e) : {})}
      onMouseOut={e => (onDraw ? handleUp(e) : {})}
    />
  );
});

CCSketch.propTypes = {
  /** Canvas width 길이 */
  width: PropTypes.number.isRequired,
  /** Canvas height 길이 */
  height: PropTypes.number.isRequired,
  /** Background 색상 */
  backgroundColor: PropTypes.string,
  /** 펜 색상 */
  strokeColor: PropTypes.string.isRequired,
  /** 펜 width */
  strokeWidth: PropTypes.number,
  /** Props 작성 필요 */
  simplification: PropTypes.number,
  /** Props 작성 필요 */
  fullySelected: PropTypes.bool,
  /** Props 작성 필요 */
  contents: PropTypes.array,
  /** Props 작성 필요 */
  onDraw: PropTypes.func
};

CCSketch.defaultProps = {
  width: WIDTH,
  height: HEIGHT,
  strokeColor: "#000000",
  strokeWidth: 3,
  fullySelected: false
};

export default CCSketch;
