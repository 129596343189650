import Box from "@material-ui/core/Box";
import { useSearchForExplorer } from "components/Explorer/util";
import { Loading } from "components/index";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { DOC_CATEGORIES } from "types/docissue";
import { FilterCollapse, ResultList } from "./component";

const SEARCH_DOCISSUE_FOR_EXPLORER_PAGINATION = gql`
  query searchDocIssueForExplorerPagination(
    $args: InputSearchDocIssue
    $comparison: String
    $hospitalId: String!
    $match: String
    $page: InputPage
  ) {
    content: searchDocIssuePagination(
      hospitalId: $hospitalId
      comparison: $comparison
      match: $match
      page: $page
      args: $args
    ) {
      ... on RetSearchDocIssuePagination {
        data {
          ... on RetDocIssue {
            category #구분
            contents
            date
            lastModifiedTime
            doctor {
              ... on RetStaff {
                id
                name
              }
            }
            hospitalId
            id
            issueNumber
            name #문서명
            patient {
              ... on RetPatient {
                id
                name
                residentRegistrationNumber
                chartNumber
              }
            }
            purpose #용도
            staff {
              ... on RetStaff {
                id
                name
              }
            }
            status #status
            type # 종류
          }
        }
        lastKey
      }
    }
  }
`;
const SEARCH_DOCISSUE_FOR_EXPLORER = gql`
  query searchDocIssueForExplorer(
    $args: InputSearchPatient
    $subArgs: InputSearchDocIssue
    $comparison: String
    $hospitalId: String!
    $match: String
  ) {
    content: searchPatient(
      args: $args
      comparison: $comparison
      hospitalId: $hospitalId
      match: $match
    ) {
      ... on RetPatient {
        id
        name
        residentRegistrationNumber
        chartNumber
        docissue(args: $subArgs) {
          ... on RetDocIssue {
            category
            lastModifiedTime
            contents
            date
            doctor {
              ... on RetStaff {
                id
                name
              }
            }
            hospitalId
            id
            issueNumber
            name
            purpose
            staff {
              ... on RetStaff {
                id
                name
              }
            }
            status
            type
          }
        }
      }
    }
  }
`;

const adaptData = list =>
  list.reduce((acc, cur) => {
    return acc.concat(
      cur?.docissue?.map &&
        cur.docissue.map(item => {
          return {
            ...item,
            patient: {
              id: cur.id,
              name: cur.name,
              chartNumber: cur.chartNumber,
              residentRegistrationNumber: cur.residentRegistrationNumber
            }
          };
        })
    );
  }, []);
const reducer = (state, action) => {
  switch (action.type) {
    // case "list":
    //   return { ...state, list: [...adaptData(action.target)] };
    case "filter":
      return { ...state, filter: { ...action.target } };
    case "addPatientFilter":
      return {
        ...state,
        filter: {
          ...state.filter,
          args: {
            ...state?.filter?.args,
            id: action.target
          }
        }
      };
    case "removePatientFilter":
      let _state = { ...state };
      if (_state?.filter?.args?.id) {
        delete _state.filter.args.id;
      }
      return _state;
    default:
      break;
  }
  return state;
};

const initializer = props => {
  // console.log(props);
  return {
    ...props,
    filter: {
      ...props.filter,
      args: {},
      subArgs: {
        date: {
          begin:
            props?.filter?.receiptDate?.begin || moment().startOf("day").unix(),
          end: props?.filter?.receiptDate?.end || moment().endOf("day").unix()
        }
      }
    }
  };
};

const Document = props => {
  const { patient } = props;
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const collapseRef = useRef();
  const [list, setList] = useState([]);

  const { response, loading, nextPage, hasNext } = useSearchForExplorer(
    state?.filter?.args,
    state.filter?.subArgs,
    SEARCH_DOCISSUE_FOR_EXPLORER,
    SEARCH_DOCISSUE_FOR_EXPLORER_PAGINATION,
    adaptData,
    null,
    (a, b) => b.date - a.date,
    100
  );

  useEffect(() => {
    // console.log(response);
    if (response?.length >= 0) {
      setList([...response]);
    }
  }, [response]);

  useEffect(() => {
    // console.log("Calendar-useEffect", patient);
    if (patient?.id) {
      dispatchState({
        type: "addPatientFilter",
        target: patient.id
      });
    } else {
      dispatchState({
        type: "removePatientFilter"
      });
    }
  }, [patient]);

  return (
    <Box style={{ height: "100%", overflowY: "hidden", position: "relative" }}>
      <Box style={{ height: "100%", minWidth: 1500 }}>
        <Loading open={loading} msg={"Loading..."} />
        <FilterCollapse
          ref={collapseRef}
          collapse={props.collapse}
          filter={state.filter}
          onChange={e => {
            dispatchState({ type: "filter", target: { ...e } });
          }}
        />
        <ResultList
          list={
            list?.length > 0
              ? list.filter(
                  item =>
                    item.category !== DOC_CATEGORIES.ORAL_EXAMINATION.value
                )
              : []
          }
          collapseRef={collapseRef}
          collapse={props.collapse}
          hasNext={Boolean(hasNext !== null)}
          onMoreContents={() => {
            nextPage && nextPage();
          }}
        />
      </Box>
    </Box>
  );
};

export default Document;
