import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import React from "react";
import { ViewDetailLabOrder } from "views/Normal/DentalLab/components/LabOrderModal/components/index";
import ViewNoteLabOrder from "views/Normal/DentalLab/components/LabOrderModal/components/ViewNoteLabOrder";
import ApolloProvider from "../ApolloProvider";

const useStyles = makeStyles(theme => ({
  root: { width: "100%" },
  left: { width: "inherit" },
  right: {
    width: "inherit",
    borderLeft: `1px solid ${theme.palette.border.main}`
  }
}));

const ViewBody = props => {
  const { value, deliveryUpdate, onChange, permission } = props;

  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.root)} wrap={"nowrap"}>
      <Grid item className={classes.left}>
        <ViewDetailLabOrder
          permission={permission}
          value={value}
          deliveryUpdate={deliveryUpdate}
          onChange={onChange}
        />
      </Grid>
      {value.isClever && (
        <Grid item className={classes.right}>
          <ApolloProvider>
            <ViewNoteLabOrder value={value} />
          </ApolloProvider>
        </Grid>
      )}
    </Grid>
  );
};

ViewBody.defaultProps = {};

ViewBody.propTypes = {};

export default ViewBody;
