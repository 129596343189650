import { Box } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { CCTypography } from "styles/components";
import { formatCommas } from "utils/number";
import XLSX from "xlsx";

export const patientHeader = classes => {
  return [
    {
      label: "날짜",
      key: "date",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "내원 환자수",
      key: "total",
      width: 150,
      component: ({ cellData, rowData }) => {
        const total = rowData.new + rowData.existing;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{total}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "신환",
      key: "new",
      width: 150,
      component: ({ cellData, rowData }) => {
        const total = rowData.new + rowData.existing;
        const percentage = total
          ? Math.round((cellData / total) * 1000) / 10
          : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
            <CCTypography>{`(${percentage}%)`}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "구환",
      key: "existing",
      width: 170,
      component: ({ cellData, rowData }) => {
        const total = rowData.new + rowData.existing;
        const percentage = total
          ? Math.round((cellData / total) * 1000) / 10
          : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
            <CCTypography>{`(${percentage}%)`}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const salesHeader = classes => {
  return [
    {
      label: "날짜",
      key: "date",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "매출액 (원)",
      key: "total",
      width: 150,
      component: ({ cellData, rowData }) => {
        const industryExpense = rowData.gongdan ? rowData.gongdan : 0;
        // const insureTotal =
        //   rowData.charge && rowData.charge.price ? rowData.charge.price : 0;
        // const uninsureTotal =
        //   rowData.uninsured && rowData.uninsured.price
        //     ? rowData.uninsured.price
        //     : 0;
        const insureCard =
          rowData && rowData.charge && rowData.charge.card
            ? rowData.charge.card
            : 0;
        const insureAccount =
          rowData && rowData.charge && rowData.charge.account
            ? rowData.charge.account
            : 0;
        const insureCash =
          rowData && rowData.charge && rowData.charge.cash
            ? rowData.charge.cash
            : 0;
        const uninsureCard =
          rowData && rowData.uninsured && rowData.uninsured.card
            ? rowData.uninsured.card
            : 0;
        const uninsureAccount =
          rowData && rowData.uninsured && rowData.uninsured.account
            ? rowData.uninsured.account
            : 0;
        const uninsureCash =
          rowData && rowData.uninsured && rowData.uninsured.cash
            ? rowData.uninsured.cash
            : 0;
        const uninsureTotal = uninsureCard + uninsureCash + uninsureAccount;
        const insureTotal = insureCard + insureCash + insureAccount;
        const advance = rowData.sunsu ? rowData.sunsu : 0;

        const total = industryExpense + insureTotal + uninsureTotal + advance;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{formatCommas(total ? total : 0)}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "보험 (원)",
      key: "price",
      width: 150,
      component: ({ cellData, rowData }) => {
        const industryExpense = rowData.gongdan ? rowData.gongdan : 0;
        // const insureTotal =
        //   rowData.charge && rowData.charge.price ? rowData.charge.price : 0;
        const insureCard =
          rowData && rowData.charge && rowData.charge.card
            ? rowData.charge.card
            : 0;
        const insureAccount =
          rowData && rowData.charge && rowData.charge.account
            ? rowData.charge.account
            : 0;
        const insureCash =
          rowData && rowData.charge && rowData.charge.cash
            ? rowData.charge.cash
            : 0;
        const insureTotal = insureCard + insureCash + insureAccount;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>
              {formatCommas(industryExpense + insureTotal)}
            </CCTypography>
          </Box>
        );
      }
    },
    {
      label: "비보험 + 선수금 (원)",
      key: "uninsured",
      width: 170,
      component: ({ cellData, rowData }) => {
        // const uninsureTotal =
        //   rowData.uninsured && rowData.uninsured.price
        //     ? rowData.uninsured.price
        //     : 0;
        const uninsureCard =
          rowData && rowData.uninsured && rowData.uninsured.card
            ? rowData.uninsured.card
            : 0;
        const uninsureAccount =
          rowData && rowData.uninsured && rowData.uninsured.account
            ? rowData.uninsured.account
            : 0;
        const uninsureCash =
          rowData && rowData.uninsured && rowData.uninsured.cash
            ? rowData.uninsured.cash
            : 0;
        const uninsureTotal = uninsureCard + uninsureCash + uninsureAccount;
        const advance = rowData.sunsu ? rowData.sunsu : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{formatCommas(uninsureTotal + advance)}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const uninsuredPatientHeader = classes => {
  return [
    {
      label: "날짜",
      key: "date",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "임플란트",
      key: "implant",
      width: 120,
      component: ({ cellData, rowData }) => {
        const value = rowData["0"] ? rowData["0"].count : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{value}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "교정",
      key: "correction",
      width: 120,
      component: ({ cellData, rowData }) => {
        const value = rowData["3"] ? rowData["3"].count : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{value}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "보철",
      key: "prosthesis",
      width: 120,
      component: ({ cellData, rowData }) => {
        const valueDent = rowData["1"] ? rowData["1"].count : 0;
        const valuePros = rowData["8"] ? rowData["8"].count : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{valueDent + valuePros}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "미용치료(과세)",
      key: "beauty",
      width: 120,
      component: ({ cellData, rowData }) => {
        const value = rowData["5"] ? rowData["5"].count : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{value}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "기타",
      key: "other",
      width: 120,
      component: ({ cellData, rowData }) => {
        const childrenValue = rowData["2"] ? rowData["2"].count : 0;
        const otherValue = rowData["4"] ? rowData["4"].count : 0;
        const listedValue = rowData["6"] ? rowData["6"].count : 0;
        const preservationValue = rowData["7"] ? rowData["7"].count : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>
              {childrenValue + otherValue + listedValue + preservationValue}
            </CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const uninsuredSalesHeader = classes => {
  return [
    {
      label: "날짜",
      key: "date",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "임플란트 (원)",
      key: "implant",
      width: 170,
      component: ({ cellData, rowData }) => {
        const value = rowData["0"] ? rowData["0"].price : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{formatCommas(value ? value : 0)}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "교정 (원)",
      key: "correction",
      width: 170,
      component: ({ cellData, rowData }) => {
        const value = rowData["3"] ? rowData["3"].price : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{formatCommas(value ? value : 0)}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "보철 (원)",
      key: "prosthesis",
      width: 170,
      component: ({ cellData, rowData }) => {
        const valueDent = rowData["1"] ? rowData["1"].price : 0;
        const valuePros = rowData["8"] ? rowData["8"].price : 0;
        const value = valueDent + valuePros;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{formatCommas(value ? value : 0)}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "미용치료(과세) (원)",
      key: "beauty",
      width: 170,
      component: ({ cellData, rowData }) => {
        const value = rowData["5"] ? rowData["5"].price : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{formatCommas(value ? value : 0)}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "기타 (원)",
      key: "other",
      width: 170,
      component: ({ cellData, rowData }) => {
        const childrenValue = rowData["2"] ? rowData["2"].price : 0;
        const otherValue = rowData["4"] ? rowData["4"].price : 0;
        const listedValue = rowData["6"] ? rowData["6"].price : 0;
        const preservationValue = rowData["7"] ? rowData["7"].price : 0;
        const value =
          childrenValue + otherValue + listedValue + preservationValue;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{formatCommas(value ? value : 0)}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

const parseDataToWeeks = (label, settings) => {
  if (settings) {
    const dayArray = [];
    label.forEach((value, index) => {
      const daySetting = settings[index];
      if (!daySetting.holiday) {
        dayArray.push(value);
      }
    });
    return dayArray;
  }
  return label;
};

export const daysHeader = (classes, settings) => {
  const labels = [
    { key: "sunday", label: "일" },
    { key: "monday", label: "월" },
    { key: "tuesday", label: "화" },
    { key: "wednesday", label: "수" },
    { key: "thursday", label: "목" },
    { key: "friday", label: "금" },
    { key: "saturday", label: "토" }
  ];

  const parseLabels = parseDataToWeeks(labels, settings);

  const fields = parseLabels.map(({ label, key }) => {
    return {
      label: label,
      key: key,
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    };
  });

  return [
    {
      label: "",
      key: "label",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    ...fields,
    {
      label: "합계",
      key: "total",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const hoursHeader = classes => {
  return [
    {
      label: "",
      key: "label",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "10시이전",
      key: "0-10",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "10-12시",
      key: "10-12",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "12-14시",
      key: "12-14",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "14-16시",
      key: "14-16",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "16-18시",
      key: "16-18",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "18-20시",
      key: "18-20",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "20시이후",
      key: "20-24",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "합계",
      key: "total",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const routesHeader = (classes, label) => {
  return [
    {
      label: label,
      key: "name",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "내원 환자수",
      key: "total",
      width: 150,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "신환",
      key: "new",
      width: 150,
      component: ({ cellData, rowData }) => {
        const total = rowData.new + rowData.existing;
        const percentage = total
          ? Math.round((cellData / total) * 1000) / 10
          : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
            <CCTypography>{`(${percentage}%)`}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "구환",
      key: "existing",
      width: 170,
      component: ({ cellData, rowData }) => {
        const total = rowData.new + rowData.existing;
        const percentage = total
          ? Math.round((cellData / total) * 1000) / 10
          : 0;
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
            <CCTypography>{`(${percentage}%)`}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const zonesHeader = (classes, label) => {
  return [
    {
      label: label,
      key: "name",
      // width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "신환 (명)",
      key: "new",
      // width: 150,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const recommenderHeader = classes => {
  return [
    {
      label: "소개 환자",
      key: "name",
      width: 150,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "누적 순매출액",
      key: "sales",
      width: 200,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true,
              [classes.cell__selected]: rowData.isSelected
            })}
          >
            <CCTypography>{formatCommas(cellData)}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: "소개 환자수",
      key: "patient",
      width: 150,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const ageDistributionHeader = classes => {
  const parseLabels = [
    { key: "0-10", label: "10대 미만" },
    { key: "10-20", label: "10대" },
    { key: "20-30", label: "20대" },
    { key: "30-40", label: "30대" },
    { key: "40-50", label: "40대" },
    { key: "50-60", label: "50대" },
    { key: "60-70", label: "60대" },
    { key: "70-80", label: "70대" },
    { key: "80-90", label: "80대" },
    { key: "90-0", label: "90대 이상" }
  ];
  const fields = parseLabels.map(({ label, key }) => {
    return {
      label: label,
      key: key,
      width: 100,
      component: ({ cellData, rowData }) => {
        const total = cellData.new + cellData.existing;
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{total}</CCTypography>
          </Box>
        );
      }
    };
  });

  return [
    {
      label: "",
      key: "label",
      width: 80,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    ...fields,
    {
      label: "합계",
      key: "total",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const ageSalesDistributionHeader = (classes, selection) => {
  const parseLabels =
    selection === 0
      ? [
          { key: "0-10", label: "10대 미만" },
          { key: "10-20", label: "10대" },
          { key: "20-30", label: "20대" },
          { key: "30-40", label: "30대" },
          { key: "40-50", label: "40대" },
          { key: "50-60", label: "50대" },
          { key: "60-70", label: "60대" },
          { key: "70-80", label: "70대" },
          { key: "80-90", label: "80대" },
          { key: "90-0", label: "90대 이상" }
        ]
      : [
          { key: "male", label: "남성" },
          { key: "female", label: "여성" }
        ];
  const fields = parseLabels.map(({ label, key }) => {
    return {
      label: label,
      key: key,
      width: 100,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{formatCommas(cellData)}</CCTypography>
          </Box>
        );
      }
    };
  });

  return [
    {
      label: "",
      key: "label",
      width: 100,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{cellData}</CCTypography>
          </Box>
        );
      }
    },
    ...fields,
    {
      label: "합계",
      key: "total",
      width: 120,
      component: ({ cellData, rowData }) => {
        return (
          <Box
            className={clsx({
              [classes.cell]: true
            })}
          >
            <CCTypography>{formatCommas(cellData)}</CCTypography>
          </Box>
        );
      }
    }
  ];
};

export const buildExportData = (heads, data) => {
  let exportData = [];
  let item;

  data.forEach(el => {
    item = heads.reduce((ac, cv) => {
      for (let key in el) if (cv.id === key) ac[cv.label] = el[key];
      return ac;
    }, {});

    exportData.push(item);
  });

  return exportData;
};

export const buildXLSXData = (heads, data, sheetName) => {
  const exportData = buildExportData(heads, data);
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(exportData);

  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  return XLSX.write(wb, {
    bookType: "xlsx",
    type: "binary"
  });
};