import React from "react";

const EzdentiIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M21 3c1.105 0 2 .895 2 2v14c0 1.105-.895 2-2 2H3c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2h18zm-9.217 3H4v11.996h7.817v-1.633H5.886v-3.706h5.493V11.02H5.886V7.619h5.897V6zm8.898 2.747h-7.768v1.532h5.443l-5.41 6.302v1.414H21v-1.532h-5.728l5.409-6.317V8.747z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default EzdentiIcon;
