import gql from "graphql-tag";

const PAYMENT_DETAIL = gql`
  query paymentDetail($id: String) {
    paymentDetail(id: $id) {
      id
      prothesisPrice
      goldPrice
      year
      month
      sendBillToCleverAt
      cardPaymentAmount
      cashPaymentAmount
      dc
      charged
      unpaidAmount
      totalPaid
      status
      unpaid
      clinic {
        id
        name
        email
        fax
        tel
        paymentDate
        isClever
        createdBy
      }
      note
      paymentHistory {
        totalAmountOnPayment
        order
        id
        receiptAt
        transactionType
        receiptAtStr
        cardPaymentAmount
        cashPaymentAmount
        note
        isRefund
        staffName
        staffId
      }
      clinicSendGoldByType {
        goldType
        used
        goldSettingPrice
        charged
      }
      arrivedProsthesis
      goldContents {
        material
        quantity
      }
    }
  }
`;

const PAYMENT_LIST = gql`
  query PaymentHistoryList($query: PaymentQueryInput) {
    paymentHistoryList(query: $query) {
      items {
        id
        prothesisPrice
        goldPrice
        sendBillToCleverAt
        cardPaymentAmount
        cashPaymentAmount
        dc
        charged
        unpaidAmount
        totalPaid
        status
        unpaid
        clinic {
          id
          name
          email
          fax
          tel
          createdBy
        }
        note
        paymentHistory {
          id
          totalAmountOnPayment
          order
          receiptAt
          transactionType
          receiptAtStr
          cardPaymentAmount
          cashPaymentAmount
          note
        }
      }
    }
  }
`;
const PAYMENT_BILL_DETAIL = gql`
  query paymentBillDetail($id: String) {
    paymentBillDetail(id: $id) {
      id
      unpaidAmount
      dc
      total
      prosthesis
      gold
      lab {
        id
        bankAccountNumber
        bankAccountHolder
        bankName
        managerName
        labName
        labTelNumber
        email
      }
      month
      note
      orders {
        id
        orderDate
        orderDateStr
        patient
        teeth
        cost
        unit
        totalPrice
        amount
        gold {
          received
          used
          consumed
        }
      }
      partner {
        address
        address2
        city
        discount
        email
        fax
        id
        isClever
        isLink
        manager
        managerCellNo
        name
        paymentDate
        sourcingGoldMethod
        state
        tax
        tel
        type
        zipCode
      }
      status
      year
      clinicSendGoldByType {
        goldType
        received
        used
        consumed
      }
      beginPaymentCycleUnix
      endPaymentCycleUnix
      term
    }
  }
`;

const BILL_CONFIRM_DETAIL = gql`
query paymentBillConfirm($id: ID, $clinic: String, $ids: [String]) {
  paymentBillConfirm(id: $id, clinic: $clinic, orderIds: $ids) {
    prosthesis
    gold
    unpaidAmount
    dc
    year
    month
    total
    note
    lab {
        id
        bankAccountNumber
        bankAccountHolder
        bankName
        managerName
        labName
        labTelNumber
        email
      }
    orders {
      id
      orderDate
      unit # Ex. Oral, Teeth 
      gold {
        received
        used
        consumed
      }
      totalPrice
      amount # number of teeth. This is unit field on front end
      teeth
      cost
      patient 
    }
    clinicSendGoldByType {
      goldType
      received
      used
      consumed
    }
    partner {
      id
      address
      address2
      city
      discount
      email
      fax
      id
      isClever
      isLink
      manager
      managerCellNo
      name
      paymentDate
      sourcingGoldMethod
      state
      tax
      tel
      type
    }
    lab {
      id
    }
    sendBillToCleverAt
  }
}`
const UPDATE_PAYMENT = gql`
  mutation payment($input: PaymentInput) {
    payment(input: $input) {
      code
      message
    }
  }
`;

const IS_ORDER_BELONGS_TO_PAYMENT = gql`
  query isOrderBelongsToPaidPayment($ids: [ID!]) {
    isOrderBelongsToPaidPayment(ids: $ids) {
      isOrdersBelongToPaidPayment
      isOrdersBelongToSendCleverPayment
    }
  }
`;

const SEND_EMAIL = gql`
  mutation sendEmail(
    $to: String!
    $subject: String!
    $text: String
    $html: String
    $attachments: [EmailAttachmentsInput]
  ) {
    sendEmail(
      to: $to
      subject: $subject
      text: $text
      html: $html
      attachments: $attachments
    ) {
      code
      message
    }
  }
`;

const SEND_PAYMENT_BILL = gql`
mutation sendPaymentBill(
  $clinic: String!,
  $orderIds: [String],
  $note: String, 
  $sendBillTo: String
) {
  sendPaymentBill(input: {
    clinic: $clinic,
    orderIds: $orderIds,
    note: $note
    sendBillTo: $sendBillTo
  }) {
    code
  }
}`

const UPDATE_BILL_PAYMENT = gql`
  mutation updatePaymentBill(
    $id: String!
    $url: String
    $savedBill: PaymentBillDetailInput
  ) {
    updatePaymentBill(id: $id, input: { url: $url }, savedBill: $savedBill) {
      code
    }
  }
`;
const UPDATE_PAYMENT_NOTE = gql`
  mutation updatePaymentHistoryNote(
    $id: ID
    $note: String
    $paymentNote: PaymentLogNoteInput
  ) {
    updatePaymentHistoryNote(
      input: { id: $id, note: $note, paymentNote: $paymentNote }
    ) {
      code
    }
  }
`;

const GET_ACCUMULATE_PAYMENT = gql`
  query PaymentGetAccumulateAmount($query: PaymentQueryInput) {
    paymentGetAccumulateAmount(query: $query) {
      accumulateCharged
      accumulatePaid
      accumulateUnPaid
    }
  }
`;

const CREAT_BILL_GET = gql`
query PaymentBillOrderList($query: OrderListFilterInput ){
  paymentBillOrderList(query: $query ) {
    items {
      id
      requestId
      requestDate
      deliveryRequestDate
      type
      patient {
        name
      }
      prosthesis
      status
      isOrderSentOnBill
      clinic {
        id
        name
      }
    }
    total
    hasNext
  }
}`
export {
  CREAT_BILL_GET,
  SEND_EMAIL,
  IS_ORDER_BELONGS_TO_PAYMENT,
  UPDATE_BILL_PAYMENT,
  PAYMENT_BILL_DETAIL,
  BILL_CONFIRM_DETAIL,
  PAYMENT_DETAIL,
  UPDATE_PAYMENT_NOTE,
  SEND_PAYMENT_BILL,
  UPDATE_PAYMENT,
  PAYMENT_LIST,
  GET_ACCUMULATE_PAYMENT
};
