import React from "react";

const GotofirstIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M18.41 16.59 13.82 12l4.59-4.59L17 6l-6 6 6 6 1.41-1.41zM6 6h2v12H6V6z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default GotofirstIcon;
