import { Checkbox, FormControlLabel } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import React, { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  root: {},
  error__color: {
    marginLeft: props => (props.requirePosition === "right" ? 5 : 0),
    color: theme.palette.error.main,
    marginRight: props => (props.requirePosition === "left" ? 5 : 0)
  },
  color: {
    color: props => {
      if (props.color === "error") return theme.palette.error.main;
    }
  }
}));

const CCLabelCheckBox = props => {
  const {
    color,
    label,
    required,
    checked,
    labelColor,
    errorOnChange,
    requirePosition,
    error,
    onBlur,
    inputRef,
    ...others
  } = props;
  const classes = useStyles(props);

  useEffect(() => {
    required && errorOnChange(!checked);
  }, [errorOnChange, checked, required]);

  return (
    <FormControlLabel
      checked={checked}
      error={error}
      required={required}
      control={
        <Checkbox
          error={error}
          inputRef={inputRef}
          required={required}
          onBlur={onBlur}
          className={classes.color}
          color={color}
        />
      }
      label={
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {required && requirePosition === "left" && (
            <Box className={classes.error__color}>{"*"}</Box>
          )}
          <Box style={{ color: labelColor }}>{label}</Box>
          {required && requirePosition === "right" && (
            <Box className={classes.error__color}>{"*"}</Box>
          )}
        </Grid>
      }
      {...others}
    />
  );
};

CCLabelCheckBox.defaultProps = {
  color: "primary",
  checked: false,
  errorOnChange: () => {},
  requirePosition: "left"
};

CCLabelCheckBox.propTypes = {
  color: PropTypes.string,
  // label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  errorOnChange: PropTypes.func,
  requirePosition: PropTypes.oneOf(["left", "right"])
};

export default CCLabelCheckBox;
