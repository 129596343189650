import { Box, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: { display: "flex" },
  chip__usagehistory: {
    padding: "0 16px",
    height: 36,
    backgroundColor: theme.palette.sub.main,
    "&:focus": {
      backgroundColor: theme.palette.select.main
    },
    "&:hover": {
      backgroundColor: theme.palette.select.main
    },
    marginRight: 16
  },
  chip__stackmanage: {
    width: 276,
    height: 36,
    backgroundColor: theme.palette.sub.main,
    "&:focus": {
      backgroundColor: theme.palette.select.main
    },
    "&:hover": {
      backgroundColor: theme.palette.select.main
    }
  },
  chip__selected: {
    backgroundColor: theme.palette.select.main
  },
  chip__typography: { color: "white" }
}));

const LabGoldTabSelector = props => {
  const { panel, onClick } = props;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box>
        <Chip
          className={clsx(classes.chip__usagehistory, {
            [classes.chip__selected]: panel === 0
          })}
          label={
            <CCTypography className={classes.chip__typography} variant="h5">
              {translate( intl,"labManageGold.usageHistory" )}
            </CCTypography>
          }
          onClick={() => onClick && onClick(0)}
        />
      </Box>
      <Box>
        <Chip
          className={clsx(classes.chip__stackmanage, {
            [classes.chip__selected]: panel === 1
          })}
          label={
            <CCTypography className={classes.chip__typography} variant="h5">
              {translate( intl,"labManageGold.stockManagement" )}
            </CCTypography>
          }
          onClick={() => onClick && onClick(1)}
        />
      </Box>
    </Box>
  );
};

export default LabGoldTabSelector;
