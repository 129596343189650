import { Box, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import clsx from "clsx";
import { translate } from "components";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple, CCTypography } from "styles/components";
import { getObject } from "utils/s3";
import { LabInfo, ProsthesisPrice, Samples } from "./components";

const useStyles = makeStyles(theme => ({
  heading__icon: {
    color: theme.palette.disabled,
    alignItems: "center"
  },
  root: { height: "calc(100% - 200px)" },
  container: {
    width: "100%",
    height: "calc(100vh - 180px) !important"
  },
  table__container: {
    display: "flex",
    width: "100%",
    padding: "0px 16px"
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "100%",
    height: "474px",
    color: theme.palette.border.main,
    overflow: "hidden"
  },
  panel__heading: {
    padding: "7px 0px 7px 16px"
  },
  panel__content: {
    height: "100%",
    // height: 'calc(100% - 32px)',
    background: "#cfd8dcde",
    paddingTop: 16,
    paddingBottom: 16,
    width: "100%"
  },
  secondarytext: {
    color: "#b0bec5",
    fontSize: 14
  },
  cell__text__disabled: { color: theme.palette.disabled },
  cell__selected__row: {
    color: theme.palette.common.white
  },
  cell__select: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: "8px"
  },
  text__direct: {
    width: "100%",
    textAlign: "center"
  },
  cell__input: {
    width: 98,
    backgroundColor: theme.palette.common.white,
    textAlignLast: "left"
  },
  icon: {
    color: theme.palette.sub.main,
    padding: "4px",
    margin: "0px"
  },
  empty__body: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.border.main
  },
  header: {
    borderBottom: "1px solid #b0bec5",
    padding: "8px 16px 16px 16px"
  },
  wordBreak: { wordBreak: "break-all" },
  marginbottom8: { marginBottom: 8 },

  positionrelative: {
    position: "relative"
  },
  line: {
    background: "#b0bec5",
    width: " 100%",
    height: 2,
    position: "absolute",
    bottom: 0,
    zIndex: 1
  },
  paddingmiddle: {
    padding: "0 16px "
  },
  boldtitle: {
    fontWeight: "bold",
    padding: "16px 16px 0px 16px"
  }
}));

const useTabsStyles = makeStyles(theme => ({
  root: {
    minHeight: 24,
    position: "relative"
  },

  scrollButtons: { width: 30 },
  indicator: { color: "#01579b", zIndex: 2 }
}));

const useTabStyles = makeStyles(theme => ({
  root: {
    // minWidth: 50,
    // minHeight: 24,
    // margin: '6px 4px',
    // padding: 0,
    // borderRadius: 25,
    // opacity: 1,
    fontWeight: "bold",
    textTransform: "none"
  }
}));
const useTableStyles = makeStyles(theme => ({
  title: {
    padding: 0
  }
}));

const DialogPreview = props => {
  const { open, onClose, previewState, avatar } = props;

  // classes
  const classes = useStyles();
  const tabsClasses = useTabsStyles();
  const tabClasses = useTabStyles();
  const tableClasses = useTableStyles();
  const intl = useIntl();

  // value
  const [value, setValue] = useState(0);
  const [image, setImage] = useState("");
  const loading = false;
  const data = { labPreview: previewState };
  const [
    samples
    // setSamples
  ] = useState(data.labPreview.prothesisImages);

  // useEffect
  useEffect(() => {
    let func = () => {
      getObject(avatar?.target, localStorage.getItem("labId"), avatar?.id).then(
        res => {
          setImage(res);
        }
      );
    };
    func();
  }, [avatar]);

  return (
    <CCDialogSimple
      maxWidth="md"
      fullWidth
      classes={tableClasses}
      className={classes.root}
      open={open}
      contentsPadding={false}
      title={
        <Grid container direction="column">
          <CCTypography className={classes.boldtitle}>
            {translate(intl, "settings.lab.cleverLabs")}
          </CCTypography>
          <Grid item>
            <Grid container className={classes.header}>
              <Grid
                item
                style={{
                  marginRight: 16,
                  width: 50,
                  height: 50,
                  backgroundImage: `url(${
                    image || "avatarDefaultPreview.png"
                  })`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}
              />
              <Grid item>
                <Grid container direction="column">
                  <CCTypography
                    variant="h4"
                    className={[classes.marginbottom8, classes.wordBreak]}
                  >
                    {data?.labPreview?.labName + " "}
                    <span className={classes.secondarytext}>
                      {data?.labPreview?.specialties}
                    </span>
                  </CCTypography>

                  <CCTypography className={[classes.wordBreak]}>
                    {data?.labPreview?.statusMessage}
                  </CCTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      onCloseButton={() => {
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
      contents={
        <Grid className={classes.container} container>
          <Grid item className={classes.panel__content}>
            <CCTypography
              className={clsx(classes.secondarytext, classes.paddingmiddle)}
            >
              {translate(intl, "settings.lab.aboutUs")}
            </CCTypography>
            <CCTypography
              className={[classes.paddingmiddle, classes.wordBreak]}
            >
              {data?.labPreview?.aboutUs}
            </CCTypography>

            <Grid container direction="column">
              <Box className={classes.positionrelative}>
                <Tabs
                  classes={tabsClasses}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="secondary"
                  value={value}
                  onChange={(e, v) => {
                    setValue(v);
                  }}
                >
                  <Tab
                    classes={tabClasses}
                    label={translate(intl, "common.ui.prosthesis")}
                  />

                  <Tab
                    classes={tabClasses}
                    label={translate(intl, "settings.lab.samples")}
                  />
                  <Tab
                    classes={tabClasses}
                    label={translate(intl, "settings.lab.labInfo")}
                  />
                </Tabs>
                <div className={classes.line} />
              </Box>

              {!loading &&
                (value === 0 ? (
                  <ProsthesisPrice labId={data?.labPreview?.labId} />
                ) : value === 1 ? (
                  <Samples samples={samples} />
                ) : (
                  <LabInfo data={data?.labPreview} />
                ))}
            </Grid>
          </Grid>
        </Grid>
      }
      // endActions={
      //   <>
      //     <CCButton
      //       color='sub'
      //       onClick={() => {
      //         onClose();
      //       }}
      //     >
      //       취소
      //     </CCButton>
      //     <CCButton
      //       variant='contained'
      //       onClick={() => {
      //         onChange(surgeryValues);
      //       }}
      //     >
      //       저장/입력
      //     </CCButton>
      //   </>
      // }
    />
  );
};

DialogPreview.propTypes = {};

export default DialogPreview;
