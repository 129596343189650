import { Grid, makeStyles } from "@material-ui/core";
import { translate } from "components";
import { CVButton } from "components/index";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Files from "react-butterfiles";
import { useIntl } from "react-intl";
import { CCButton, CCTypography } from "styles/src/components";
import CCDialogSimple from "styles/src/components/CCDialogSimple";
import CCIconButton from "styles/src/components/CCIconButton";
import { ClipIcon, WarningIcon } from "styles/src/themes/common/icons";
import { Target } from "utils/s3";
const useStyles = makeStyles(theme => ({
  root: {},
  error: {
    color: theme.palette.error.main
  }
}));

const UploadExcelFileButton = props => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    buttonIcon,
    onChange,
    title,
    disabled,
    startIcon,
    accept,
    allowExtension,
    color = "normal",
    maxSize,
    handleLoading,
    handleResetData
  } = props;

  const [open, setOpen] = useState("");
  const [openSizeCheck, setOpenSizeCheck] = useState(false);
  const [openUploadedCheck, setOpenUploadedCheck] = useState(false);
  const [uploadChecked, setUploadChecked] = useState(false);
  const fileUploadButton = useRef();
  const fileUploadIconButton = useRef();

  const handleBlobFileChange = async file => {
    const ext = file.name.slice(file.name.lastIndexOf(".") + 1).toUpperCase();
    if (allowExtension.includes(ext)) {
      if (file) {
        onChange(file)
        setUploadChecked(true)
      }
    } else {
      setOpen(allowExtension.join(", "));
      handleLoading(false);
    }
  };

  return (
    <>
      <Files
        multiple={false}
        maxSize={maxSize}
        multipleMaxSize={maxSize}
        multipleMaxCount={1}
        accept={accept && accept}
        onSuccess={async ([selectedFile]) => handleBlobFileChange(selectedFile)}
        onError={errors => {
          if (errors[0].type === "maxSizeExceeded") {
            setOpenSizeCheck(true);
          } else if (errors[0].type === "unsupportedFileType") {
            setOpen(allowExtension.join(", "));
          } else {
            console.error(errors);
          }
        }}
      >
        {({ browseFiles }) => {
          return (
            <Grid container alignItems={"center"}>
              <Grid item hidden={!!buttonIcon}>
                <CVButton
                  disabled={disabled}
                  variant={"contained"}
                  color={color}
                  startIcon={startIcon}
                  children={title}
                  ref={fileUploadButton}
                  onClick={() => {
                    if (!uploadChecked) {
                      browseFiles()
                    } else {
                      setOpenUploadedCheck(true)
                    }
                  }}
                />
              </Grid>
              <Grid item hidden={!buttonIcon}>
                <CCIconButton
                  children={buttonIcon}
                  ref={fileUploadIconButton}
                  onClick={() => { browseFiles() }}
                />
              </Grid>
              {Boolean(openUploadedCheck) && (
                <CCDialogSimple
                  style={{ width: 300 }}
                  open={Boolean(openUploadedCheck)}
                  title={<WarningIcon className={classes.error} />}
                  onClose={() => setOpenUploadedCheck(false)}
                  contents={
                    <>
                      <CCTypography className={classes.error}>
                        {translate(intl, "uploadExcelCheck", { br: () => <br /> })}
                      </CCTypography>
                    </>
                  }
                  endActions={
                    <>

                      <CCButton
                        variant={"text"}
                        color={"normal"}
                        onClick={() => setOpenUploadedCheck(false)}
                      >
                        {translate(intl, "cancel")}
                      </CCButton>
                      <CCButton variant="contained" onClick={() => {
                        setOpenUploadedCheck(false)
                        browseFiles()
                        setUploadChecked(false)
                        handleResetData()
                      }}>
                        {translate(intl, "common.ui.confirm")}
                      </CCButton>
                    </>

                  }
                />
              )}
            </Grid>
          );
        }}
      </Files>

      {Boolean(open) && (
        <CCDialogSimple
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpen("")}
          contents={
            <>
              <CCTypography className={classes.error}>
                {translate(intl, "common.ui.thisFileExtensionsIsNotAllowed")}
              </CCTypography>
              <CCTypography>
                {translate(
                  intl,
                  "common.ui.youCanOnlyUploadPngJpgJpegZipStlFiles",
                  { number: open, extensions: allowExtension.join(", ") }
                )}
              </CCTypography>
            </>
          }
          endActions={
            <CCButton color="primary" onClick={() => setOpen("")}>
              {translate(intl, "common.ui.confirm")}
            </CCButton>
          }
        />
      )}
      {openSizeCheck && (
        <CCDialogSimple
          style={{ width: 300 }}
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpenSizeCheck(false)}
          contents={
            <>
              <CCTypography className={classes.error}>
                {translate(intl, "common.ui.uploadFileSizeExceed", { sizeMB: (maxSize === "0.3mb" ? "300kb" : maxSize) })}

              </CCTypography>
            </>
          }
          endActions={
            <CCButton color="primary" onClick={() => setOpenSizeCheck("")}>
              {translate(intl, "ccCheck")}
            </CCButton>
          }
        />
      )}

    </>
  );
};

UploadExcelFileButton.defaultProps = {
  value: "",
  onChange: e => console.log(e),
  onlyDirect: false, // 바로 파일 등록 시 사용
  nonAttachPhoto: false,
  title: "Profile Image",
  target: Target.PHOTO,
  disablePreview: true, // 오른쪽 파일 링크
  tab: 2,
  category: "photo",
  folder: "attachment",
  startIcon: <ClipIcon />,
  //accept: ["image/jpg", "image/jpeg", "image/png"],
  allowExtension: ["XLSX"],
  maxSize: "10000mb",
  handleLoading: _ => _
};

UploadExcelFileButton.propTypes = {
  patient: PropTypes.object // { id: patientContext.id, value: patientContext }
};

export default UploadExcelFileButton;
