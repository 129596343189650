import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const CCTabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "100%", overflow: "hidden" }}>{children}</Box>
      )}
    </Typography>
  );
};

CCTabPanel.propTypes = {
  /** 현재 선택된(보여지는) tab */
  value: PropTypes.number,
  /** 해당 탭 들의 순서 (ex : 0, 1, 2, 3, 4) */
  index: PropTypes.number
};

export default CCTabPanel;
