import React, { useReducer } from "react";

const initialState = {
  refresh: 0
};

const reducer = (state, action) => {
  return { ...state, refresh: state.refresh + 1 };
};

const DBVendorContext = React.createContext();
const DBVendorContextConsumer = DBVendorContext.Consumer;

const DBVendorContextProvider = props => {
  const [dbVendorContext, dispatchDBVendor] = useReducer(reducer, initialState);

  const value = { dbVendorContext, dispatchDBVendor };

  return (
    <DBVendorContext.Provider value={value}>
      {props.children}
    </DBVendorContext.Provider>
  );
};
export default DBVendorContextProvider;
export { DBVendorContext, DBVendorContextConsumer };
