import CVTextField from 'components/CVTextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import IMaskInput from 'react-imask/esm/input';
import {
    formatPhoneNumber,
    isValidPhoneNumber
} from 'react-phone-number-input';

const phoneNumberMask = (props) => (
  <IMaskInput
    {...props}
    mask={[
      { mask: '000-0000-0000' },
      { mask: '000-000-0000' },
      { mask: '00-0000-0000' },
      { mask: '00-000-0000' },
    ]}
  />
);

const PhoneNumberField = (props) => {
  const {
    permission,
    value,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);

  useEffect(() => {
    if (value) {
      const phoneNumber = value.replace(/[^\d]/g, ''); // 숫자만 따로 추출
      const krPhoneNumber = '+82'.concat(phoneNumber);
      if (!isValidPhoneNumber(krPhoneNumber)) {
        setError(true);
      } else setError(false);
    } else setError(false);

    required && value === '' && setError(true);
  }, [value, required]);

  const handlePhoneField = (name) => (e) => {
    console.log('name =>> ', name);
    const phoneNumber = e.target.value.replace(/[^\d]/g, ''); // 숫자만 따로 추출
    const krPhoneNumber = '+82'.concat(phoneNumber);
    //번호가 있고 한국 유효한 번호가 아니면 에러표시
    if (
      name === 'onBlur' &&
      (!phoneNumber || !isValidPhoneNumber(krPhoneNumber))
    ) {
      setError(true);
      return;
    }

    phoneNumber.length > 8 && phoneNumber.startsWith('02')
      ? onChange({
          target: {
            value: isValidPhoneNumber(krPhoneNumber)
              ? formatPhoneNumber(krPhoneNumber)
              : e.target.value,
          },
        })
      : phoneNumber.length > 9
      ? onChange({
          target: {
            value: isValidPhoneNumber(krPhoneNumber)
              ? formatPhoneNumber(krPhoneNumber)
              : e.target.value,
          },
        })
      : onChange({ target: { value: e.target.value } });
  };

  return (
    <CVTextField
      permission={permission}
      margin={margin}
      variant={variant}
      label={label}
      onChange={handlePhoneField('onChange')}
      error={error}
      required={required}
      value={value || ''}
      onBlur={handlePhoneField('onBlur')}
      InputProps={{
        inputComponent: phoneNumberMask,
      }}
      {...others}
    />
  );
};

PhoneNumberField.defaultProps = {
  value: '',
  margin: 'dense',
  variant: 'outlined',
  label: '전화번호',
  required: false,
  errorOnChange: () => {},
};

PhoneNumberField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func,
};

export default PhoneNumberField;
