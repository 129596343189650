import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "../../../locales/language";
import {
  AddIcon,
  InfoIcon,
  ModifyIcon,
  SubtractIcon,
  UpdownsIcon
} from "../../themes/common/icons";
import CCButton from "../CCButton";
import CCIconButton from "../CCIconButton";
import CCTextFieldNumber from "../CCTextFieldNumber";
import CCTooltip from "../CCTooltip";
import CCTypography from "../CCTypography";
import { getJsonParse } from "../../utils/jsonValue";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflow: "hidden",
    boxSizing: "border-box"
    // borderTop: "1px solid #dcdcdc",
    // borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table: {
    height: "100%",
    position: "relative",
    // borderTop: "1px solid #dcdcdc",
    // borderBottom: "1px solid #dcdcdc",
    boxSizing: "border-box"
  },
  table__title: {
    padding: 8,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__head: {},
  table__body: {
    position: "relative",
    minHeight: 40,
    overflow: "auto",
    backgroundColor: theme.palette.result.blank
  },
  table__head__sort: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  table__head__row: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  table__head__cell: {
    flex: 1,
    fontSize: 14,
    padding: 8,
    boxSizing: "border-box",
    display: "inline-flex",
    minWidth: 0,
    justifyContent: "center",
    alignItems: "center"
    // alignSelf: "center"
  },
  table__head__label: {
    lineHeight: "24px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  table__head__cell__del: {
    display: "flex",
    // alignItems: "center",
    minWidth: 24,
    padding: "0px 4px"
  },
  table__head__cell__cursor: { cursor: "pointer" },
  table__body__row: {
    display: "flex",
    minHeight: 36,
    borderBottom: `1px solid ${theme.palette.background.default}`,
    backgroundColor: "#FFFFFF"
  },
  table__body__row__select: {
    background: `${theme.palette.select.main} !important`
  },
  table__body__cell: {
    flex: 1,
    fontSize: 14,
    overflow: "hidden",
    overflowWrap: "break-word",
    padding: "8px 4px",
    // alignSelf: "center",
    boxSizing: "border-box"
  },
  table__body__cell__del: {
    minWidth: 16,
    // alignSelf: "center",
    padding: "8px 4px"
  },
  table__body__cell__hover: {
    cursor: "pointer",
    "&:hover": {
      background: `${theme.palette.result.main} !important`
    }
  },
  table__contents__blank: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0
    // backgroundColor: "#000000 !important",
    // opacity: 0.54
  },
  table__contents__blank__container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  table__contents__blank__item: { color: "white" },
  table__day__button: {
    width: 230,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    top: 4,
    right: 40,
    borderRadius: 20,
    minWidth: 24,
    minHeight: 24,
    padding: 0,
    margin: 0,
    color: theme.palette.icon,
    "& > div": {
      margin: 0,
      height: 25,
      flexDirection: "unset"
    },
    "& > button": {
      padding: 0,
      fontSize: 12
    }
  },
  table__add__button: {
    position: "absolute",
    top: 4,
    right: 4,
    borderRadius: 20,
    minWidth: 24,
    minHeight: 24,
    padding: 0,
    margin: 0,
    color: theme.palette.icon
  },
  table__del__icon: { fontSize: 16 },
  table__sort__icon: {
    color: theme.palette.sub.main,
    width: 19,
    height: 19
  },
  table__icon__button: {
    borderRadius: 20,
    color: theme.palette.input.main,
    minWidth: 24,
    minHeight: 24,
    padding: 0,
    margin: "0 !important"
  },
  hidden: {
    display: "none"
  },
  visibilityHidden: {
    visibility: "hidden"
  },
  table__body__pagination__button: {
    width: "100%"
  },
  align__center: {
    display: "flex",
    alignItems: "center"
  }
}));

const CCTable = props => {
  const {
    classes: userClass,
    className,
    heads,
    contents,
    onDelClick,
    onAddClick,
    onModClick,
    onRowClick,
    onDay,
    onFilter,
    step,
    stepEvent,
    onUpClick,
    onDownClick,
    title,
    labelShow,
    selectedRow,
    delTooltip,
    alignCenter = false,
    rowStyle = () => {}
  } = props;
  const classes = useStyles();
  const refBody = useRef(null);
  const refBodyRow = useRef(null);
  const refHead = useRef(null);
  const intl = useIntl();

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [sortOrder, setSortOrder] = useState({
    key: "",
    order: 0
  });

  const lang = localStorage.getItem("lang");

  useEffect(() => {
    const handleResize = () => {
      setHeight(refHead.current.offsetHeight);
      setWidth(refBody.current.offsetWidth - refBody.current.scrollWidth);
    };
    handleResize();
  }, []);

  useEffect(() => {
    setHeight(refHead.current.offsetHeight);
    setWidth(refBody.current.offsetWidth - refBody.current.scrollWidth);
  }, [contents]);

  return (
    <Box
      className={clsx(
        {
          [classes.root]: true,
          [className]: true
        },
        userClass && userClass.root
      )}
    >
      <Box className={classes.table}>
        <Box
          className={clsx({
            [classes.table__head]: true
          })}
          ref={refHead}
        >
          {title && (
            <Box className={classes.table__title}>
              <CCTypography variant="h5">{title}</CCTypography>
            </Box>
          )}
          {labelShow && (
            <Box
              className={clsx(
                classes.table__head__row,
                userClass && userClass.table__head__row
              )}
              style={{
                // width: `calc(100% - ${width}px)`
                paddingRight: lang === "ar" ? 0 : width,
                paddingLeft: lang === "ar" ? width : 0
              }}
            >
              {onDelClick && <Box className={classes.table__head__cell__del} />}
              {onModClick && <Box className={classes.table__head__cell__del} />}
              {onUpClick && <Box className={classes.table__head__cell__del} />}
              {onDownClick && (
                <Box className={classes.table__head__cell__del} />
              )}
              {heads.map((head, headIndex) => {
                return (
                  <Box
                    className={clsx({
                      [classes.table__head__cell]: true,
                      [head.className]: true
                    })}
                    style={head.width && { width: head.width, flex: "unset" }}
                    key={headIndex}
                  >
                    {(() => {
                      if (typeof head.label !== "function") {
                        if (head.sort) {
                          return (
                            <div
                              className={classes.table__head__sort}
                              onClick={() =>
                                setSortOrder(sortOrder => {
                                  if (sortOrder.key === head.key)
                                    return {
                                      key: head.key,
                                      order: (sortOrder.order + 1) % 2
                                    };
                                  else return { key: head.key, order: 0 };
                                })
                              }
                            >
                              <CCTooltip title={head.label}>
                                <CCTypography
                                  className={classes.table__head__label}
                                  component={"span"}
                                  style={{
                                    fontWeight:
                                      head.key === sortOrder.key &&
                                      sortOrder.order !== 2 &&
                                      600
                                  }}
                                >
                                  {head.label}
                                </CCTypography>
                              </CCTooltip>
                              <UpdownsIcon
                                className={classes.table__sort__icon}
                              />
                            </div>
                          );
                        } else
                          return (
                            <CCTooltip title={head.label}>
                              <CCTypography
                                className={classes.table__head__label}
                                component={"span"}
                              >
                                {head.label}
                              </CCTypography>
                            </CCTooltip>
                          );
                      } else {
                        return head.label({ headIndex: headIndex });
                      }
                    })()}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
        <Box
          className={clsx(
            classes.table__body,
            userClass && userClass.table__body
          )}
          ref={refBody}
          style={{ height: `calc(100% - ${height}px)` }}
        >
          {contents
            .filter((rowData, rowIndex) => {
              let ret = true;
              for (let i = 0; i < heads.length; i++) {
                if (heads[i].filter) {
                  ret = false;
                  if (
                    heads[i].filter({
                      rowIndex: rowIndex,
                      key: heads[i].key,
                      data: rowData[heads[i].key]
                    })
                  )
                    return true;
                }
              }

              if (onFilter) {
                ret = false;
                if (onFilter(rowData)) return true;
              }

              return ret;
            })
            .sort((a, b) => {
              const alpha = getJsonParse(sortOrder.key, a);
              const beta = getJsonParse(sortOrder.key, b);
              if (sortOrder.order === 0) {
                // 오름차순
                if (isNaN(alpha) || isNaN(beta))
                  return alpha < beta ? -1 : alpha > beta ? 1 : 0;
                return alpha - beta;
              }
              if (sortOrder.order === 1) {
                // 내림차순
                if (isNaN(alpha) || isNaN(beta))
                  return alpha > beta ? -1 : alpha < beta ? 1 : 0;
                return beta - alpha;
              }
              return 0;
            })
            .map((rowData, rowIndex) => {
              return (
                <Box
                  className={clsx(userClass && userClass.table__body__row, {
                    [classes.table__body__row]: true,
                    [classes.table__body__cell__hover]: onRowClick,
                    [classes.table__body__row__select]: selectedRow === rowIndex
                  })}
                  key={rowIndex}
                  ref={refBodyRow}
                  style={rowStyle(rowData)}
                >
                  {onDelClick && (
                    <Box
                      className={clsx(classes.table__body__cell__del, {
                        [classes.align__center]: alignCenter
                      })}
                    >
                      <CCIconButton
                        className={clsx({
                          [classes.table__icon__button]: true
                        })}
                        color="primary"
                        onClick={e => {
                          e.stopPropagation();
                          onDelClick({ rowIndex: rowIndex, rowData });
                        }}
                        variant="flat"
                        size="small"
                        tooltip={delTooltip}
                      >
                        <SubtractIcon className={classes.table__del__icon} />
                      </CCIconButton>
                    </Box>
                  )}
                  {onModClick && (
                    <Box
                      className={clsx(classes.table__body__cell__del, {
                        [classes.align__center]: alignCenter
                      })}
                    >
                      <CCIconButton
                        className={clsx({
                          [classes.table__icon__button]: true
                        })}
                        color="primary"
                        onClick={e => {
                          e.stopPropagation();
                          onModClick({ rowIndex: rowIndex, rowData });
                        }}
                        variant="flat"
                        size="small"
                      >
                        <ModifyIcon className={classes.table__del__icon} />
                      </CCIconButton>
                    </Box>
                  )}
                  {onUpClick && (
                    <Box
                      className={clsx(classes.table__body__cell__del, {
                        [classes.align__center]: alignCenter
                      })}
                    >
                      <CCIconButton
                        className={clsx({
                          [classes.table__icon__button]: true
                        })}
                        color="primary"
                        onClick={e => {
                          e.stopPropagation();
                          onUpClick({ rowIndex: rowIndex, rowData });
                        }}
                      >
                        <KeyboardArrowUpIcon
                          className={classes.table__del__icon}
                        />
                      </CCIconButton>
                    </Box>
                  )}
                  {onDownClick && (
                    <Box
                      className={clsx(classes.table__body__cell__del, {
                        [classes.align__center]: alignCenter
                      })}
                    >
                      <CCIconButton
                        className={classes.table__icon__button}
                        color="primary"
                        onClick={e => {
                          e.stopPropagation();
                          onDownClick({
                            rowIndex: rowIndex,
                            rowData
                          });
                        }}
                      >
                        <KeyboardArrowDownIcon
                          className={classes.table__del__icon}
                        />
                      </CCIconButton>
                    </Box>
                  )}
                  {heads.map((head, headIndex) => {
                    return (
                      <Box
                        className={clsx({
                          [classes.table__body__cell]: head.hasOwnProperty(
                            "padding"
                          )
                            ? head.padding
                            : true,
                          [head.className]: true,
                          [classes.table__body__cell__hover]: head.hover
                        })}
                        style={
                          head.width && { width: head.width, flex: "unset" }
                        }
                        key={headIndex}
                        onClick={e => {
                          // setSelectRow(rowIndex);
                          onRowClick &&
                            onRowClick({
                              e,
                              rowIndex: rowIndex,
                              key: head.key,
                              data: rowData
                            });
                        }}
                      >
                        {(() => {
                          if (typeof head.component !== "function") {
                            return (
                              <CCTypography
                                style={{ lineHeight: "24px" }}
                                component={"span"}
                              >
                                {typeof rowData[head.key] === "string"
                                  ? rowData[head.key]
                                  : JSON.stringify(rowData[head.key])}
                              </CCTypography>
                            );
                          } else {
                            return head.component({
                              rowIndex: rowIndex,
                              key: head.key,
                              cellData: rowData[head.key],
                              rowData: rowData,
                              viewIndex: rowIndex
                            });
                          }
                        })()}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          {step && (
            <Box>
              <Button
                className={classes.table__body__pagination__button}
                onClick={() => {
                  stepEvent && stepEvent();
                }}
              >
                ....
              </Button>
            </Box>
          )}
          {!contents.length && (
            <Box className={classes.table__contents__blank}>
              <Grid
                container
                className={classes.table__contents__blank__container}
              >
                <Grid item className={classes.table__contents__blank__item}>
                  <CCTypography variant="body2">
                    {intl.formatMessage({ id: "ccHasNoData" })}
                  </CCTypography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        {Boolean(onDay) && (
          <div className={classes.table__day__button}>
            <CCTypography>{intl.formatMessage({ id: "ccDays" })}</CCTypography>
            <CCTooltip title={intl.formatMessage({ id: "ccAddDrug" })}>
              <div>
                <InfoIcon />
              </div>
            </CCTooltip>
            <CCTextFieldNumber
              step={1}
              decimalPoint={2}
              number={onDay.days}
              value={onDay.days}
              onChange={value => {
                console.log("valuevaluevalue", value);
                onDay.handleDay(value);
              }}
              onBlur={onDay.onBlur}
            />
            <CCButton
              variant="contained"
              color="normal"
              onClick={() => {
                onDay.onDayClick();
              }}
            >
              {intl.formatMessage({ id: "ccApply" })}
            </CCButton>
          </div>
        )}

        {onAddClick && (
          <CCIconButton
            className={classes.table__add__button}
            onClick={e => {
              onAddClick && onAddClick(e);
            }}
          >
            <AddIcon />
          </CCIconButton>
        )}
      </Box>
    </Box>
  );
};

CCTable.propTypes = {
  /** Table label에 사용될 배열 */
  heads: PropTypes.array.isRequired,
  /** Table contents에 사용될 배열 */
  contents: PropTypes.array.isRequired,
  /** Table item 추가 함수 */
  onAddClick: PropTypes.func,
  /** Table item 수정 함수 */
  onModClick: PropTypes.func,
  /** Table item 삭제 함수 */
  onDelClick: PropTypes.func,
  /** Table item 위치 위로 이동 */
  onUpClick: PropTypes.func,
  /** Table item 위치 아래로 이동 */
  onDownClick: PropTypes.func,
  /** Table item 검색 함수 */
  onFilter: PropTypes.func
};

CCTable.defaultProps = {
  labelShow: true,
  heads: [],
  contents: []
};

export default CCTable;
