import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { forwardRef, useImperativeHandle } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ExpireTable from "../ExpireTable";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "18px !important"
    // background: theme.palette.background.default
  },
  chief__complaint__input: {
    marginBottom: "16px"
  },
  chief__complaint__table: {
    height: "200px"
    // height: `calc(100% - ${height}px)`
  },
  head__field: {
    width: 130
  },
  head__storage__field: {
    width: 150
  },
  head__search__field: {
    width: 250
  },
  bin__field: {
    marginLeft: 20,
    width: 80
  },
  margin__field: {
    marginLeft: 10,
    paddingBottom: 0,
    paddingLeft: 0
  },
  font__field: {
    fontSize: 10,
    marginTop: 20,
    marginLeft: 20,
    color: theme.palette.disabled,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  label__font__field: {
    fontSize: 14,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  top__margin__field: {
    marginTop: "-10px"
  },
  bottom__margin__field: {
    marginBottom: "-40px"
  },
  button__filter: {
    marginTop: "5px"
  },
  info__button: { color: theme.palette.disabled.hex },
  border: {
    width: 1,
    height: "20px",
    marginLeft: 10,
    marginTop: 15,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  required: {
    color: theme.palette.error.main
  }
}));

const ProgressTable = forwardRef((props, ref) => {
  const { modify, className } = props;

  const classes = useStyles();

  const { control, watch } = useFormContext();

  useImperativeHandle(ref, () => ref?.current);

  return (
    <>
      <Box className={clsx(classes.root, className)}>
        <Box className={classes.chief__complaint__table}>
          <Controller
            name={"expireList"}
            control={control}
            defaultValue={modify?.storage?.expire}
            render={props => {
              const { ref, value, onChange, onBlur } = props;
              return (
                <ExpireTable
                  data={modify}
                  currentCnt={watch("storage.currentCnt")}
                  value={value}
                  onChange={e => {
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  inputRef={ref}
                />
              );
            }}
          />
        </Box>
      </Box>
    </>
  );
});
export default ProgressTable;
