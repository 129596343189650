import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useRef, useMemo } from "react";
import { SmalldownIcon, SmallupIcon } from "../../themes/common/icons";
import CCTextField from "../CCTextField";

const useStyles = makeStyles(theme => ({
  root: {
    width: 98
  },
  end__adornment: {
    flexDirection: "column",
    margin: 0,
    height: "auto",
    "&:hover": {
      cursor: "pointer"
    }
  },
  up: {
    marginBottom: "5px",
    color: theme.palette.icon
  },
  down: {
    color: theme.palette.icon
  },
  white__background__color: {
    backgroundColor: theme.palette.common.white
  }
}));

const CCTextFieldNumber = props => {
  const {
    className,
    number,
    onChange,
    decimalPoint = 2,
    step = 0.5,
    negative = false,
    InputProps,
    disabled,
    ...others
  } = props;

  const classes = useStyles();

  const inputRef = useRef();
  const value = useMemo(() => {
    const type = typeof number;

    if (type === "number") return number.toFixed(decimalPoint);
    if (type === "string") return Number(number).toFixed(decimalPoint);
    return number;
  }, [decimalPoint, number]);

  const upNumber = () => {
    if (disabled) return;
    const newNumber = Number(number) + step;
    onChange(newNumber.toFixed(decimalPoint));
  };

  const downNumber = () => {
    if (disabled) return;
    const newNumber = Number(number) - step;
    if (!negative && newNumber < 0) return;
    onChange(newNumber.toFixed(decimalPoint));
  };

  return (
    <CCTextField
      className={clsx(classes.root, className)}
      value={value}
      onChange={e => {
        // const start = e.target.selectionStart;
        const value = e.target.value;
        const dotCount = (value.match(/\./g) || []).length;
        const isNum = /[^0-9]/g;
        const nums = value.split(".");

        if (dotCount === 2) return;
        if (isNum.test(value.replace(".", ""))) return;
        if (nums.length === 2 && nums[1].length > decimalPoint) return;

        onChange(value);

        // setTimeout(() => {
        //   inputRef.current.selectionStart = inputRef.current.selectionEnd = start;
        // }, 0);
      }}
      variant="outlined"
      margin="dense"
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end" className={classes.end__adornment}>
            <SmallupIcon className={classes.up} onClick={upNumber} />
            <SmalldownIcon className={classes.down} onClick={downNumber} />
          </InputAdornment>
        )
      }}
      disabled={disabled}
      inputRef={inputRef}
      {...others}
    />
  );
};

CCTextFieldNumber.propTypes = {};

export default CCTextFieldNumber;
