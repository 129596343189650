import { Grid, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
// import { translate } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    backgroundColor: theme.palette.common.black,
    overflow: "hidden"
  },
  icon__cancel: {
    cursor: "pointer",
    color: "white",
    marginLeft: 8
  },
  image: {
    margin: "16px 32px"
  },
  header: {
    padding: 16
  },
  color__white: {
    color: "white"
  }
}));

const LabOrderProcessModal = props => {
  const { onClose } = props;

  const classes = useStyles();
  const processLang = localStorage.getItem("lang");
  return (
    <Dialog maxWidth={false} {...props}>
      <DialogContent
        style={{ padding: 0 }}
        classes={{
          root: classes.root
        }}
        onClick={onClose}
      >
        {/* <Grid container justify="space-between" className={classes.header}>
          <CCTypography className={classes.color__white}>{translate( intl,"common.ui.process")}</CCTypography>
          <CancelIcon className={classes.icon__cancel} />
        </Grid> */}
        <Grid
        // className={classes.image}
        >
          <img
            style={{ width: "100%" }}
            src={`/Laboratory/popup-process${processLang}.svg`}
            alt={"Laboratory-popup-process"}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

LabOrderProcessModal.defaultProps = {};

LabOrderProcessModal.propTypes = {};

export default LabOrderProcessModal;
