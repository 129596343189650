import moment from "moment";
import React, { useReducer } from "react";
import "styles/src/themes/common/font-notosans.css";
import { basicTheme, blueTheme } from "styles/themes";
import { theme as localTheme } from "theme";
import { getMomentLocale } from "utils/datetime";

const initialState = () => ({
  language: localStorage.getItem("lang") || process.env.REACT_APP_DEFAULT_LANGUAGE || "en",
  refresh: 0,
  debug: {
    log: true,
    warn: true,
    error: true,
    info: true
  },
  theme: { ...basicTheme, ...localTheme },
  route: { path: "", props: null },
  permission: null,
  needNotify: true,
  ready: false,
  waitCheckNotify: false,
  bg: `url(bg/login${(moment().unix() % 5) + 1}.png)`,
  automaticLogOut: 240
});

const fontChange = (targetLanguage, targetTheme) => {
  const fontFamily = targetLanguage === "ko" ? "Noto Sans" : "Roboto";

  const newTypography = {
    ...targetTheme.typography,
    fontFamily: ["Noto Sans", fontFamily].join(",")
  };

  const newTheme = {
    ...basicTheme,
    typography: newTypography,
    ...localTheme
  };

  return newTheme;
};

const initializer = () => {
  const theme = fontChange(initialState().language, initialState().theme);
  getMomentLocale(initialState().language);
  return { ...initialState(), theme: theme };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "init":
      return initializer();
    case "language":
      localStorage.setItem("lang", action.target);
      const newTheme = fontChange(action.target, state.theme);
      getMomentLocale(action.target);
      return { ...state, language: action.target, theme: newTheme };
    case "refresh":
      return { ...state, refresh: state.refresh + 1 };
    case "debug":
      return { ...state, debug: action.target };
    case "theme":
      if (action.target === basicTheme.themeName) {
        const newTheme = fontChange(state.language, basicTheme);
        return { ...state, theme: newTheme };
      } else if (action.target === blueTheme.themeName) {
        const newTheme = fontChange(state.language, blueTheme);
        return { ...state, theme: newTheme };
      }
      return state;
    case "token":
    case "me":
    case "ready":
    case "route":
    case "permission":
    case "needNotify":
    case "waitCheckNotify":
      return { ...state, [action.type]: action.target };
    case "automaticLogOut":
      return { ...state, automaticLogOut: action.target };
    default:
      return state;
  }
};

const AppContext = React.createContext();
const AppContextConsumer = AppContext.Consumer;

const AppContextProvider = props => {
  const theme = fontChange(initialState().language, initialState().theme);
  const [appContext, dispatchApp] = useReducer(reducer, {
    ...initialState(),
    theme: theme
  });
  const value = { appContext, dispatchApp };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export default AppContextProvider;
export { AppContext, AppContextConsumer };
