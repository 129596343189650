import { Box, Grid, makeStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";
import {
  CVFlattenDateField,
  CVTextField,
  translate,
  PhoneNumberRegionField
} from "components";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/src/components";
import { EmailField } from "../../../ClinicInfoSetting/components/ClinicInfoField/components";
import { BankField } from "../../../PartnerSetting/components";
import DialogAvatarView from "../DialogAvatarView/DialogAvatarView";
import UploadImageButton from "../UploadImageButton";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "inherit"
  },
  line: {
    height: 1,
    width: "100%",
    background: " #b0bec5",
    margin: "16px 0"
  },
  content: {
    padding: 16,
    height: "100%"
  },
  header: {
    fontWeight: "bold",
    padding: "8px 16px",
    borderBottom: "1px solid #b0bec5",
    width: "100%"
  },
  inputWidth216: {
    width: 280
  },
  inputWidth169: {
    width: 169,
    margin: "8px 16px"
  },
  inputWidth500: {
    flex: 1
  },
  inputWidth330: {
    width: 330
  },
  inputWidthFull: {
    width: "100%"
  },
  inpuptMargin: {
    margin: "8px 0"
  },
  inputMarginMiddle: {
    margin: "8px 16px"
  },
  inputMarginPhoneNumber: {
    margin: '8px 16px 8px 0 !important'
  },
  requeired: {
    color: "#b0bec5"
  },
  button: {
    width: 150,
    margin: "8px 0"
  },
  avatar: {
    color: "#0277bd",
    cursor: "pointer",
    textDecoration: "underline",
    marginLeft: 16
  },
  iconcancel: {
    cursor: "pointer",
    color: "#b0bec5",
    marginLeft: 8
  },
  left__field: {
    width: "98px"
  },

  right__account: {
    width: "329px",
    left: "-1px"
  },
  input: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%"
  }
}));

const noop = () => {};

const Basic = ({ onChange, value, onError }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [viewImage, setDialogViewImage] = useState(false);

  const errors = useMemo(() => {
    const localErrors = {};
    localErrors.labName = !(value.labName || "").trim();
    localErrors.managerName = !(value.managerName || "").trim();
    localErrors.address = !(value.address || "").trim();
    localErrors.email = !(value.email || "").trim();
    localErrors.labTelNumber = !(value.labTelNumber) || (value?.labTelNumber || '').length < 8;
    localErrors.managerCellNumber = !(value.managerCellNumber) || (value?.managerCellNumber || '').length < 8;
    localErrors.bankName = !(value.bankName || "").trim();
    localErrors.bankAccountHolder = !(value.bankAccountHolder || "").trim();
    localErrors.bankAccountNumber = !(value.bankAccountNumber || "").trim();
    return localErrors;
  }, [value]);

  useEffect(() => {
    Object.keys(errors).forEach(key => {
      onError(key, errors[key]);
    });
  }, [errors, onError]);

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.content} direction="column">
        <CVTextField
          className={clsx(classes.inputWidth216, classes.inpuptMargin)}
          variant={"outlined"}
          margin={"dense"}
          value={value.labId}
          onChange={e => onChange("labId", e.target.value)}
          label={translate(intl, "settings.lab.cleverLabId")}
          // InputProps={{ readOnly: true }}
          disabled={true}
        />
        <CVFlattenDateField
          className={clsx(classes.inputWidth169, classes.inpuptMargin)}
          onChange={e => {
            onChange("openDate", moment(e).unix());
          }}
          value={value.openDate ? moment.unix(value.openDate) : false}
          label={translate(intl, "settings.lab.openDate")}
        />

        <CVTextField
          className={clsx(classes.inputWidth500, classes.inpuptMargin)}
          variant={"outlined"}
          margin={"dense"}
          label={translate(intl, "common.ui.labName")}
          onChange={e => {
            onChange("labName", e.target.value);
          }}
          InputProps={{ inputProps: { maxLength: 40 } }}
          value={value.labName}
          required
          error={errors.labName}
        />

        <CVTextField
          className={clsx(classes.inputWidth216, classes.inpuptMargin)}
          variant={"outlined"}
          margin={"dense"}
          label={translate(intl, "common.ui.managerName")}
          onChange={e => {
            onChange("managerName", e.target.value);
          }}
          InputProps={{ inputProps: { maxLength: 30 } }}
          value={value.managerName}
          required
          error={errors.managerName}
        />

        <CVTextField
          className={clsx(classes.inputWidth500, classes.inpuptMargin)}
          variant={"outlined"}
          margin={"dense"}
          label={translate(intl, "common.ui.address")}
          error={errors.address}
          InputProps={{ inputProps: { maxLength: 200 } }}
          onChange={e => {
            onChange("address", e.target.value);
          }}
          value={value.address}
          required
        />

        <EmailField
          className={clsx(classes.inputWidth216, classes.inpuptMargin)}
          variant={"outlined"}
          margin={"dense"}
          value={value.email}
          errorOnChange={noop}
          InputProps={{ inputProps: { maxLength: 200 } }}
          onChange={e => {
            onChange("email", e.target.value);
          }}
          label={translate(intl, "common.ui.email")}
          required
        />

        <Grid container>
          <PhoneNumberRegionField
            className={clsx(classes.inputWidth216, classes.inputMarginPhoneNumber)}
            value={value.labTelNumber}
            label={translate(intl, "common.ui.telNo")}
            required
            onChange={e => {
              onChange("labTelNumber", e.target.value)
            }}
            errorOnChange={noop}
            error={errors.labTelNumber}
          />
          <PhoneNumberRegionField
            className={clsx(classes.inputWidth216, classes.inputMarginPhoneNumber)}
            value={value.managerCellNumber}
            label={translate(intl, "common.ui.cellNo")}
            required
            onChange={e => {
              onChange("managerCellNumber", e.target.value)
            }}
            errorOnChange={noop}
            error={errors.managerCellNumber}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <BankField
                variant="outlined"
                margin="dense"
                bankClassName={classes.left__field}
                bankLabel={translate(intl, "payment.bill.bank")}
                bankValue={value.bankName}
                accountClassName={classes.right__account}
                accountLabel={intl.formatMessage({
                  id: "settings.lab.bankAccountNo"
                })}
                errorBank={errors.bankName}
                errorAccount={errors.bankAccountNumber}
                accountNumber={value.bankAccountNumber}
                onChange={val => {
                  onChange("bankName", val.bank);
                  onChange("bankAccountNumber", val.account);
                }}
                errorOnChange={noop}
                required
              />
            </Grid>
            <Grid item>
              <Box width="98px">
                <CVTextField
                  className={classes.input}
                  variant="outlined"
                  margin="dense"
                  label={translate(intl, "settings.lab.holder")}
                  value={value.bankAccountHolder}
                  required
                  onChange={({ target }) => {
                    onChange("bankAccountHolder", target.value);
                  }}
                  error={errors.bankAccountHolder}
                  InputProps={{ inputProps: { maxLength: 50 } }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <CVTextField
          className={clsx(classes.inputWidth216, classes.inpuptMargin)}
          variant={"outlined"}
          margin={"dense"}
          value={value.erc}
          label={translate(intl, "settings.lab.erc")}
          InputProps={{ inputProps: { maxLength: 20 } }}
          onChange={e => {
            console.log('asfas', e.target.value)
            onChange("erc", e.target.value.trim().replace(/[^a-z0-9]/gi, ""))
          }}
        />
        <Grid container alignItems="center">
          <Grid item>
            <UploadImageButton
              onlyDirect={true}
              title={translate(intl, "settings.lab.profileImage")}
              onChange={({ id, target, type, name }) => {
                onChange("avatar", { id, target, type, name });
              }}
            />
          </Grid>

          {value.avatar?.id && (
            <>
              <CCTypography
                className={classes.avatar}
                onClick={() => setDialogViewImage(true)}
              >
                {value.avatar.name}
              </CCTypography>
              <CancelIcon
                className={classes.iconcancel}
                onClick={() =>
                  onChange("avatar", { id: "", target: "", type: "", name: "" })
                }
              />
            </>
          )}
        </Grid>
      </Grid>

      <DialogAvatarView
        open={viewImage}
        avatar={value.avatar}
        onClose={() => setDialogViewImage(false)}
      />
    </Grid>
  );
};

export default Basic;
