import { useQuery } from "@apollo/react-hooks";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  translate,
  YearMonthPicker,
  YearPicker,
  CVButton
  // YearWeekPicker
} from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple, CCTypography } from "styles/components";
import { SearchIcon } from "styles/icons";
import { CCFlattenDateField } from "../CCFlattenDateTimePicker";
import { TableTab } from "./components";
// import {
//   DEFECTIVE_PROTHESES_STATISTICS,
//   ORDER_PROSTHESES_STATISTICS,
//   ORDER_STATISTICS,
//   SALES_ESTIMATES_STATISTICS
// } from "queries/statistics";

const tabWidth = 110;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    flexDirection: "row",
    overflow: "auto"
  },
  container: { height: "100%", width: "100%" },
  row__options: {
    display: "flex",
    padding: "16px",
    alignItems: "center"
  },
  row__search: {
    height: "60px",
    width: "100%",
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  field__date: {
    width: "130px",
    margin: "0px 8px 0px 0px",
    backgroundColor: theme.palette.common.white
  },
  tab: {
    // width: "70px",
    height: "30px",
    display: "flex",
    borderRadius: "18px",
    backgroundColor: theme.palette.sub.main,
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px"
  },
  selected: {
    backgroundColor: theme.palette.select.main
  },
  tab__text: { color: theme.palette.common.white },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    margin: "0px 8px"
  },
  field__doctor: {
    backgroundColor: theme.palette.common.white
  },
  button__search: {
    height: "36px",
    "min-width": "81px",
    padding: "8px",
    marginRight: "8px",
    alignItems: "center",
    color: theme.palette.common.white
  },
  row__header: {
    height: "88px",
    width: "100%",
    position: "relative",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    alignItems: "center"
  },
  item__header: {
    height: "calc(100% - 16px)",
    width: "180px",
    display: "flex",
    padding: "8px 16px",
    flexDirection: "column",
    justifyContent: "space-around",
    borderRight: `1px solid ${theme.palette.border.main}`,
    color: theme.palette.sub.main
  },
  item__header__date: {
    borderColor: theme.palette.secondary.main
  },
  item__header__long: {
    width: "300px"
  },
  item__header__end: {
    borderRight: `0px`
  },
  container__graph: {
    height: "calc(100% - 84px - 60px - 88px)",
    overflow: "auto"
  },
  paper__graph: { height: "325px", width: "800px", margin: "16px" },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.common.white,
    height: "56px",
    boxSizing: "border-box",
    "& button:nth-child(1)": {
      width: tabWidth
    },
    "& button:nth-child(2)": {
      width: tabWidth,
      fontWeight: "bold"
    },
    "& button:nth-child(3)": {
      width: tabWidth
    },
    "& button:nth-child(4)": {
      width: tabWidth
    },
    "& button:nth-child(5)": {
      width: tabWidth
    },
    "& button:nth-child(6)": {
      width: tabWidth
    },
    "& .MuiTab-textColorPrimary.Mui-selected ": {
      "&   .MuiTypography-h4": {
        color: theme.palette.secondary.main
      }
    }
  },
  panel: {
    height: `calc(100% - 56px)`,
    width: "100%",
    overflow: "auto"
  },
  tab__container: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "row",
    backgroundColor: theme.palette.common.white
  },
  tab__content: {
    textAlign: "center",
    height: "100%",
    width: "100%"
  },
  button__today: {
    padding: 0,
    marginRight: 8
  }
}));

const DetailsDialog = props => {
  const {
    open,
    onClose,
    head,
    dateSelection,
    title,
    periodList,
    query,
    dataName,
    groupBy,
    selectedDate: selDate
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const today = moment.utc(moment().format("L"), "L").unix();
  const [timeSelection, setTimeSelection] = useState(dateSelection);
  const [selectedDate, setSelectedDate] = useState(selDate);
  const [content, setContent] = useState([]);
  // const [currentDate, setCurrentDate] = useState(selectedDate);
  const { data } = useQuery(query, {
    variables: {
      input: {
        currentDate: selectedDate,
        periodTime: periodList[timeSelection].valueEnum,
        groupBy,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
  });
  useEffect(() => {
    setContent(data ? data[dataName] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  useEffect(() => { setSelectedDate(selDate) }, [selDate])

  const timeInputFields = [
    <CCFlattenDateField
      label=""
      className={classes.field__date}
      value={moment.unix(selectedDate)}
      onChange={e => {
        setSelectedDate(moment.utc(e, "L").unix());
      }}
    />,
    <YearMonthPicker
      value={selectedDate}
      onChange={e => {
        setSelectedDate(e.target.value);
      }}
    />,
    <YearPicker
      value={selectedDate}
      onChange={e => {
        setSelectedDate(e.target.value);
      }}
    />
  ];

  // const dateOption = useMemo(() => {
  //   const options = ["day", "week", "month", "year"];
  //   return periodList
  //     ? periodList[timeSelection].value
  //     : options[timeSelection];
  // }, [timeSelection, periodList]);

  useEffect(() => { }, []);

  useEffect(() => {
    setTimeSelection(dateSelection);
  }, [dateSelection]);

  return (
    <CCDialogSimple
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      title={title}
      contentsPadding={false}
      contents={
        <Grid className={classes.container} container direction="column">
          <Grid item>
            <Grid container className={classes.row__options} direction="row">
              {periodList.map((value, index) => {
                return (
                  <Grid key={"selector-" + index} item>
                    <CVButton
                      className={clsx({
                        [classes.tab]: true,
                        [classes.selected]: timeSelection === index
                      })}
                      onClick={() => setTimeSelection(index)}
                    >
                      <CCTypography className={classes.tab__text}>
                        {value.label}
                      </CCTypography>
                    </CVButton>
                  </Grid>
                );
              })}
              <Grid item>
                <Box className={classes.divider} />
              </Grid>

              <Grid item>{timeInputFields[timeSelection]}</Grid>
              <CVButton
                className={classes.button__today}
                variant={"outlined"}
                // color="secondary"
                onClick={e => {
                  setSelectedDate(today);
                  // setTimeSelection(0);
                }}
              >
                {translate(intl, "labManageGold.today")}
              </CVButton>
              <Grid item>
                <CVButton
                  className={classes.button__search}
                  variant="contained"
                  color="secondary"
                  startIcon={
                    false ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <SearchIcon />
                    )
                  }
                // onClick={e => {
                //   setCurrentDate(selectedDate);
                // }}
                >
                  {translate(intl, "common.ui.search")}
                </CVButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Box className={classes.tab__container}>
              <Box className={classes.tab__content}>
                <TableTab head={head} content={content} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      }
      onClose={() => {
        setTimeSelection(dateSelection);
        setContent([]);
        onClose();
      }}
      onCloseButton={() => {
        setTimeSelection(dateSelection);
        setContent([]);
        onClose();
      }}
    />
  );
};

export default DetailsDialog;
