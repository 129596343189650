import { AppContext } from "context/AppContext";
import React, {
    useContext
} from 'react';
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRoute } from '../useRoute';

const Routes = (props) => {
  const { pages } = props;
  const [route, changeRoute] = useRoute();
  const { appContext } = useContext(AppContext);
  const intl = useIntl();

  return (
    <Switch>
      {pages.map((page, index) => {
        return (
          <Route
            key={index}
            path={page.path}
            render={(props) => {
              const Layout = page.layout;
              const Component = page.component;
              const token = sessionStorage.getItem('token');
              const mode = sessionStorage.getItem('mode');

              if (!window.opener) {
                if (page.token && !token) {
                  const alertMessage = intl.formatMessage({id: "signIn.login.sessionExpired1"})
                  alert(alertMessage);
                  changeRoute('/signin');
                  return null;
                } else if (page.mode !== 'common' 
                    && page.mode !== mode 
                    && page.path !== "/hidden" 
                    && page.path !== "/signin") {
                  const alertMessage = intl.formatMessage({id: "signIn.login.sessionExpired2"})
                  alert(alertMessage);
                  changeRoute('/signin');
                  return null;
                }
              } else {
                const token = appContext.token;
                const mode = window.opener.sessionStorage.getItem('mode');

                if (page.token && !token) {
                  const alertMessage = intl.formatMessage({id: "signIn.login.sessionExpired3"})
                  alert(alertMessage);
                  changeRoute('/signin');
                  return null;
                } else if (page.mode !== 'common' && page.mode !== mode) {
                  const alertMessage = intl.formatMessage({id: "signIn.login.sessionExpired4"})
                  alert(alertMessage);
                  changeRoute('/signin');
                  return null;
                }
              }

              return (
                <Layout pages={pages} {...props}>
                  <Component {...props} {...route.props} />
                </Layout>
              );
            }}
          />
        );
      })}
      <Redirect from='/' to='/signIn' />
    </Switch>
  );
};

export default Routes;
