import React from "react";

const CircularIcon18 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="6"
        cy="6"
        r="4"
        fill="currentColor"
        transform="translate(3 3)"
      />
      <path d="M0 0H18V18H0z" />
    </g>
  </svg>
);
export default CircularIcon18;
