import React from "react";

const NumbersixIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12.507 17.192c.647 0 1.227-.145 1.74-.437.512-.292.909-.7 1.189-1.224s.42-1.11.42-1.757c0-1.011-.255-1.827-.765-2.447-.51-.62-1.199-.93-2.065-.93-.806 0-1.474.27-2.003.807.11-.802.437-1.429.981-1.88.545-.451 1.264-.683 2.157-.697h.192V7h-.329c-1.007.005-1.892.234-2.655.687-.764.453-1.35 1.094-1.757 1.92C9.204 10.436 9 11.39 9 12.47v.71c0 1.217.322 2.19.967 2.92.645.729 1.492 1.093 2.54 1.093zm-.041-1.592c-.47 0-.834-.186-1.094-.558-.26-.37-.39-.887-.39-1.548v-.601c.123-.274.315-.494.575-.66.26-.166.549-.25.868-.25.446 0 .802.167 1.066.5.265.332.397.77.397 1.312 0 .533-.131.967-.393 1.302-.262.335-.605.503-1.03.503z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default NumbersixIcon;
