/*eslint no-unused-vars: 0*/

import { AppContext } from "context";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { CCPaper, CCTooltip, CCTypography } from "styles/components";
import { getTeethString } from "utils/teeth";

const TeethGraphFDI = props => {
  const { width, height, foreground, background, numbers, missings } = props;
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = 480;
    const height = 120;
    const ts = width / 16;

    ctx.beginPath();
    ctx.clearRect(0, 0, width, height);

    ctx.save();
    ctx.scale(canvas.width / width, canvas.height / height);

    ctx.fillStyle = background;
    ctx.fillRect(0, 0, width, height);

    ctx.strokeStyle = foreground;
    ctx.lineWidth = 2;

    ctx.beginPath();
    ctx.moveTo(0, height / 2);
    ctx.lineTo(width, height / 2);
    ctx.stroke();
    ctx.moveTo(width / 2, 0);
    ctx.lineTo(width / 2, height);
    ctx.stroke();

    var configs = [...Array(4).keys()].map(() => Array(8).fill(0));

    numbers.forEach(number => {
      const babies = number < 50 ? 0 : 1;
      const region = parseInt(((number - 10) % 40) / 10);
      const position = (number % 10) - 1;

      try {
        if (babies) {
          configs[region][position] = 2;
        } else {
          configs[region][position] = 1;
        }
      } catch (e) {
        console.error(e);
      }
    });

    missings.forEach(number => {
      const region = parseInt(((number - 10) % 40) / 10);
      const position = (number % 10) - 1;

      try {
        configs[region][position] = 3;
      } catch (e) {
        console.error(e);
      }
    });

    const texts = [
      ["1", "2", "3", "4", "5", "6", "7", "8"],
      ["A", "B", "C", "D", "E"]
    ];
    const offsets = [
      [ts * 8 - ts / 2, ts * 0.25, -1],
      [ts * 8 + ts / 2, ts * 0.25, 1],
      [ts * 8 + ts / 2, ts * 2 + ts * 0.25, 1],
      [ts * 8 - ts / 2, ts * 2 + ts * 0.25, -1]
    ];

    for (var region = 0; region < 4; region++) {
      for (var position = 0; position < 8; position++) {
        if (configs[region][position] === 1) {
          ctx.font = `${ts * 1.8}px bold monospace`;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = foreground;
          ctx.fillText(
            texts[0][position],
            ts * position * offsets[region][2] + offsets[region][0],
            ts + offsets[region][1]
          );
        } else if (configs[region][position] === 2) {
          ctx.font = `${ts * 1.8}px bold monospace`;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = foreground;
          ctx.fillText(
            texts[1][position],
            ts * position * offsets[region][2] + offsets[region][0],
            ts + offsets[region][1]
          );
        } else if (configs[region][position] === 3) {
          ctx.font = `${ts * 1.8}px bold monospace`;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = foreground;
          ctx.fillText(
            "x",
            ts * position * offsets[region][2] + offsets[region][0],
            ts + offsets[region][1]
          );
        }
      }
    }

    ctx.restore();
  }, [width, height, foreground, background, numbers, missings]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

const TeethGraphUniversal = props => {
  const { width, height, foreground, background, numbers, missings } = props;

  const contents = useMemo(() => {
    return getTeethString(numbers, "1");
  }, [numbers]);

  return (
    <CCTooltip title={contents} placement="top-end" arrow>
      <CCPaper
        style={{
          width: width,
          height: "100%",
          border: 0,
          backgroundColor: background
        }}
        variant="elevation"
        elevation={0}
        square
      >
        <CCTypography
          variant="body1"
          style={{
            color: foreground,
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word"
          }}
        >
          {contents}
        </CCTypography>
      </CCPaper>
    </CCTooltip>
  );
};

const TeethGraph = props => {
  const { children, ...others } = props;
  const { appContext } = useContext(AppContext);

  return appContext?.otherSetting?.toothNumberingSystem === "1" ? (
    <TeethGraphUniversal {...others}>{children}</TeethGraphUniversal>
  ) : (
    <TeethGraphFDI {...others}>{children}</TeethGraphFDI>
  );
};

TeethGraph.defaultProps = {
  foreground: "rgba(255, 255, 255, 1)",
  background: "rgba(0, 0, 0, 0)",
  numbers: [],
  missings: []
};

TeethGraph.propTypes = {
  /** 가로 길이 (가로 세로 비율 : 4:1) */
  width: PropTypes.number,
  /** 세로 길이 (가로 세로 비율 : 4:1) */
  height: PropTypes.number,
  /** 글자색 */
  foreground: PropTypes.string,
  /** 배경색 */
  background: PropTypes.string,
  /** 선택된 치식 */
  numbers: PropTypes.array,
  missings: PropTypes.array
};

export default TeethGraph;
