import { useMutation, useQuery } from "@apollo/react-hooks";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  CCTableToolbar,
  CVDialogSimpleDel,
  DialogError,
  DialogPermissionWarn,
  Loading,
  useWritePermission,
  TypographyTooltip,
  translate,
  CVIconButton,
} from "components";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCDialogTitle, CCPaper } from "styles/components";
import JOB_TYPES, { JOB_POSITION, PERMISSION, STATUS } from "types/staff";
import { makeIndexFormattedMessageList } from "types/utils";
import { formatUnixInSecToYYYYMMDD } from "../../../../../utils/utils";
import { DialogStaffAdd } from "./components";
import { ChairIcon, ModifyIcon, SubtractIcon } from "styles/icons";
import { enqueueToast } from "components/Toast";
import { useSnackbar } from "notistack";

const GET_STAFF_LIST = gql`
  query GetStaffList($query: GetStaffListQueryInput!) {
    getStaffList(query: $query) {
      total
      items {
        birthDate
        cellphoneNumber
        telephoneNumber
        staffId
        staffName
        lab {
          labId
          labName
          address
        }
        isChiefDirector
        status
        position
        occupation
        authority
        hiredDate
        resignedDate
        salary
        address
        initPassword
      }
    }
  }
`;

const CREATE_STAFF = gql`
  mutation CreateStaff($input: CreateStaffInput!) {
    createStaff(input: $input) {
      staffId
    }
  }
`;

const UPDATE_STAFF = gql`
  mutation UpdateStaff($input: UpdateStaffInput!) {
    updateStaff(input: $input) {
      staffId
    }
  }
`;

const DELETE_STAFF = gql`
  mutation DeleteStaff($id: ID!) {
    deleteStaff(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    position: "relative"
  },
  head__container: {
    position: "relative"
  },
  container__line: {
    width: "inherit"
  },
  container__item: {
    margin: 8
  },
  body__paper: {
    height: "100%"
  },
  head__field: {
    width: 120
  },
  head__search__field: {
    width: 160
  },
  container: {
    height: `calc(100% - 130px)`,
    position: "relative"
  },
  table: {
    overflow: "auto",
    height: "100%"
  },
  refreshIcon: {
    margin: 16
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  },
  check__icon: {
    color: theme.palette.primary.main
  },
  icon__button: {
    borderRadius: 20,
    color: "rgba(0, 0, 0, 0.38)",
    minWidth: 24,
    minHeight: 24,
    padding: 0,
    margin: "0 !important"
  }
}));

const EmployeeInfoSetting = () => {
  // classes
  const classes = useStyles();
  const intl = useIntl();
  const settingPermission = "employeeInfoGeneralSetting";
  const isPermission = useWritePermission("employeeInfoGeneralSetting");
  const jobTypeIndex = makeIndexFormattedMessageList(JOB_TYPES);
  const jobPositionIndex = makeIndexFormattedMessageList(JOB_POSITION);
  const statusIndex = makeIndexFormattedMessageList(STATUS);
  const permissionIndex = makeIndexFormattedMessageList(PERMISSION);
  const { enqueueSnackbar } = useSnackbar();

  // value
  const [errorPermission, setErrorPermission] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(null);
  const [preventModifyStaffDialog, setPreventModifyStaffDialog] = useState(
    false
  );
  const [staffSettingDialog, setStaffSettingDialog] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);

  // graphql
  const { data, loading, refetch } = useQuery(GET_STAFF_LIST, {
    variables: {
      query: { limit: 1000, searchText: "" }
    }
  });
  const [createStaff] = useMutation(CREATE_STAFF, {
    onCompleted: () => {
      enqueueToast(enqueueSnackbar, translate(intl, "common.ui.saved"));
      refetch();
    }
  });
  const [updateStaff] = useMutation(UPDATE_STAFF, {
    onCompleted: () => {
      enqueueToast(enqueueSnackbar, translate(intl, "common.ui.saved"));
      refetch();
    },
    onError: error => {
      const { graphQLErrors } = error;
      if (graphQLErrors) {
        graphQLErrors.forEach(graphQLError => {
          if (graphQLError.extensions.code === "STAFF06") {
            setPreventModifyStaffDialog(intl.formatMessage({
              id: "settings.employee.cannotDeleteStaff"
            }));
          }
        });
      }
    }
  });
  const [deleteStaff] = useMutation(DELETE_STAFF, {
    onCompleted: () => {
      refetch();
      setDeleteDialog(null);
    }
  });

  // inReturn
  const eventComponent = ({ data }) => (
    <Grid
      container
      alignItems={"center"}
      justify={"center"}
      style={{ width: "100%", height: "100%" }}
    >
      <TypographyTooltip placement="top">{data}</TypographyTooltip>
    </Grid>
  );
  const headers = [{
    label: "",
    key: "isChiefDirector",
    width: 35,
    component: ({ cellData, rowData }) => {
      return (
        <Grid
          container
          justifyContent={"center"}
        >
          {cellData ? (
            <CVIconButton
              permission={settingPermission}
              className={classes.icon__button}         
              onClick={() => {
                setPreventModifyStaffDialog(translate(
                  intl,
                  "common.ui.cantDeleteChief"
                ));
              }}
            >
              <ChairIcon />
            </CVIconButton>
          ): (
            <CVIconButton
              permission={settingPermission}
              className={classes.icon__button}               
              onClick={async () => {
                if (!isPermission) {
                  setErrorPermission(true);
                } else setDeleteDialog(rowData);
              }}
            >
              <SubtractIcon />
            </CVIconButton>
          )}
        </Grid>
      );
    }
  },
  {
    label: "",
    key: "id",
    width: 35,
    component: ({ rowData }) => {
      return (
        <Grid
          container
          justifyContent={"center"}
        >
          <CVIconButton
            permission={settingPermission}
            className={classes.icon__button}
            onClick={() => {
              if (!isPermission) {
                setErrorPermission(true);
                return;
              }
              setSelectedStaff(rowData);
              setStaffSettingDialog(true);
            }}
          >
            <ModifyIcon />
          </CVIconButton>
        </Grid>
      );
    }
  },
    {
      label: translate( intl,"settings.employee.occupation" ),
      key: "occupation",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: jobTypeIndex[cellData]
        })
    },
    {
      label: translate( intl,"settings.employee.position" ),
      key: "position",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: jobPositionIndex[cellData]
        })
    },
    {
      label: translate( intl,"common.ui.status" ),
      key: "status",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data:
            String(cellData) !== STATUS.WORKING.value
              ? statusIndex[cellData]
              : ""
        })
    },
    {
      label: translate( intl,"settings.employee.authority" ),
      key: "authority",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: permissionIndex[cellData]
        })
    },
    {
      label: translate( intl,"name" ),
      key: "staffName",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: cellData
        })
    },

    {
      label: translate( intl,"settings.employee.dateBirth" ),
      key: "birthDate",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: formatUnixInSecToYYYYMMDD(cellData)
        })
    },
    {
      label: translate( intl,"common.ui.cellNo" ),
      key: "cellphoneNumber",
      width: 137,
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: cellData
        })
    },
    {
      label: translate( intl,"common.ui.telNo" ),
      key: "telephoneNumber",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: cellData
        })
    },
    {
      label: translate( intl,"settings.employee.hiredDate" ),
      key: "hiredDate",
      className: classes.cell,
      width: 113,
      component: ({ cellData }) =>
        eventComponent({
          data: formatUnixInSecToYYYYMMDD(cellData)
        })
    },
    {
      label: translate( intl,"settings.employee.resignDate" ),
      key: "resignedDate",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: formatUnixInSecToYYYYMMDD(cellData)
        })
    },
    {
      label: translate( intl,"id" ),
      key: "staffId",
      className: classes.cell,
      component: ({ cellData }) =>
        eventComponent({
          data: cellData
        })
    }
  ];

  return (
    <Box className={classes.root}>
      <CCPaper className={classes.body__paper}>
        <Grid className={classes.head__container} container direction="column">
          <Grid item className={clsx(classes.container__line)}>
            <CCDialogTitle>
              {translate( intl,"settings.employee.info" )}
            </CCDialogTitle>
          </Grid>
        </Grid>

        <Box className={classes.container}>
          {!loading ? (
            <CCTableToolbar
              permission={settingPermission}
              className={classes.table}
              heads={headers}
              contents={
                data?.getStaffList?.items ? data.getStaffList.items : []
              }
              onSearch={word => {
                refetch({
                  query: { limit: 20, searchText: word }
                });
              }}
              onAddClick={() => {
                setStaffSettingDialog(true);
              }}
            />
          ) : (
            <Loading open={loading} zIndex={90} />
          )}
        </Box>
      </CCPaper>
      {staffSettingDialog && (
        <DialogStaffAdd
          open={staffSettingDialog}
          onClose={() => {
            setStaffSettingDialog(false);
            setSelectedStaff(null);
          }}
          selectedStaff={selectedStaff}
          createStaff={createStaff}
          updateStaff={updateStaff}
        />
      )}
      {deleteDialog && (
        <CVDialogSimpleDel
          open={Boolean(deleteDialog)}
          onClose={() => setDeleteDialog(null)}
          onDisagree={() => setDeleteDialog(null)}
          onAgree={async () => {
            if (!deleteDialog.staffId) return;
            await deleteStaff({
              variables: { id: deleteDialog.staffId }
            }).catch(error => {
              const { graphQLErrors } = error;
              if (graphQLErrors) {
                graphQLErrors.forEach(graphQLError => {
                  if (graphQLError.extensions.code === "STAFF06") {
                    setDeleteDialog(null);
                    setPreventModifyStaffDialog(intl.formatMessage({
                      id: "settings.employee.cannotDeleteStaff"
                    }));
                  }
                });
              }
            });
          }}
        />
      )}

      {Boolean(preventModifyStaffDialog) && (
        <DialogError
          open={Boolean(preventModifyStaffDialog)}
          onClose={() => setPreventModifyStaffDialog(false)}
          content={preventModifyStaffDialog}
        />
      )}

      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
    </Box>
  );
};

export default EmployeeInfoSetting;
