// import { useQuery, useMutation } from '@apollo/react-hooks';
import { Badge, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { CCIconButton, CCTypography } from "styles/components";
import { useQuery, useMutation, useSubscription } from "@apollo/react-hooks";
import { MenunextIcon, RemoveIcon, SubtractIcon } from "styles/icons";
import { GET_NOTIFICATIONS, UPDATE_NOTIFICATION, ON_NOTIFICATION_ADDED } from '../../../../../../queries/notification';
import { translate } from 'components'
import { useIntl } from "react-intl";



const useStyles = makeStyles(() => ({
  root: {},
  contents: { backgroundColor: "black" },
  head: {
    padding: "12px 12px 12px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  body: { height: 400, overflow: "auto", padding: "8px 12px" },
  row: {
    padding: "4px 12px 4px 0px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  head__text: {
    color: "white"
  },
  body__text: {
    color: "white",
    textDecoration: "underline",
    marginLeft: 8,
  },
  btn: {
    color: "white",
    margin: "0 4px 0 4px"
  },
  icon__button: { margin: 0 }
}));

const useBadgeStyles = makeStyles(theme => ({
  root: {},
  colorPrimary: { backgroundColor: theme.palette.error.main }
}));

const usePopoverStyles = makeStyles(() => ({
  root: {
    opacity: 0.8
  },
  paper: {
    width: 500,
    borderRadius: 8
  }
}));

const Notification = props => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const Popoverclasses = usePopoverStyles();
  const badgeClasses = useBadgeStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const intl = useIntl()
  const { data, refetch, subscribeToMore } = useQuery(GET_NOTIFICATIONS);

  const [removeNotification] = useMutation(UPDATE_NOTIFICATION, {
    onCompleted: () => {
      refetch();
    },
  });

  const buildNotificationTitle = (notification) => {
    switch (notification.category) {
      case 'CLINIC_REQUEST_PARTNERSHIP':
        return translate(intl, "notification.hasRequestedPartnership", {clinicName:notification.clinicName});
      case 'CLINIC_HAS_SUSPENDED':
        return translate(intl, "notification.hasSuspendedPartnership", {clinicName:notification.clinicName, labName:notification.labName });
      default:
        return '';
    }
  }

  useEffect(() => {
    subscribeToMore({
      document: ON_NOTIFICATION_ADDED,
      variables: { },
      shouldResubscribe: true,
      updateQuery: (prev, { subscriptionData }) => {
        return Object.assign({}, prev, {
          getNotifications: {
            ...prev.getNotifications,
            items: [subscriptionData.data.onNotificationAdded, ...prev.getNotifications.items],
            total: [subscriptionData.data.onNotificationAdded, ...prev.getNotifications.items].length
          }
        });
      }
    })
  }, [subscribeToMore]);

  useEffect(() => {
    if (data && data.getNotifications) {
      setNotifications(data.getNotifications.items || []);
    } else {
      setNotifications([]);
    }
  }, [data]);

  return (
    <>
      <CCIconButton
        className={classes.icon__button}
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Badge
          classes={badgeClasses}
          badgeContent={notifications.length}
          color="primary"
        >
          {props.children}
        </Badge>
      </CCIconButton>
      <Popover
        classes={Popoverclasses}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className={classes.contents}>
          <div className={classes.head}>
            <CCTypography className={classes.head__text} variant="h5">
              {translate(intl, "common.ui.notification", {num: notifications.length})}
            </CCTypography>
            <CCIconButton
              size="small"
              className={classes.btn}
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              <RemoveIcon />
            </CCIconButton>
          </div>
          <div className={classes.body}>
            {notifications.sort((a, b) => b.date - a.date).map((el, index) => (
              <div key={index} className={classes.row}>
                <CCIconButton size="small" className={classes.btn} onClick={() => {
                    const notification = notifications[index];
                    removeNotification({
                      variables: {
                        input: {
                          id: notification.id,
                          isRead: true
                        }
                      },
                    });
                  }}>
                  <SubtractIcon />
                </CCIconButton>
                <CCTypography
                  className={classes.body__text}
                  variant="body2"
                  onClick={() => {
                    const notification = notifications[index];
                    // check type of notification then redirect

                    const w = window.open(
                      `${window.location.origin}/setting`,
                      "_blank"
                    );
                    w.variables = { category: "common.ui.clinics" };
                  }}
                >
                  {buildNotificationTitle(el)}
                </CCTypography>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Notification;
