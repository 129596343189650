import React from "react";

const JxIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M15.502 13.587a1.143 1.143 0 0 1 1.996 0l4.345 7.65c.445.781-.11 1.763-.998 1.763h-8.69c-.889 0-1.443-.982-.998-1.764zm1.575 6.473h-1.154v1.176h1.154V20.06zM10 2c1.095 0 2.03.716 2.443 1.729l.064.171h3.715c.93 0 1.698.772 1.772 1.745L18 5.8l.001 5.698a2.99 2.99 0 0 0-2.135-.252V6H4.134v13h6.209l-1.154 2h-5.41c-.083 0-.162-.004-.24-.013l-.116-.015a1.755 1.755 0 0 1-.898-.523c-.16-.18-.293-.38-.382-.608a2.1 2.1 0 0 1-.132-.55L2 19.1V5.8c0-.266.053-.513.142-.732.09-.227.222-.436.382-.607.24-.257.552-.447.898-.523.077-.019.154-.03.234-.034l.122-.004h3.715C7.867 2.798 8.844 2 10 2zm7.077 14.12h-1.154v2.764h1.154V16.12zM13.229 14l-1.154 2H6v-2h7.229zM14 11v1.665l-.193.335H6v-2h8zm0-3v2H6V8h8zm-4-4c-.292 0-.533.227-.533.5s.241.5.533.5c.292 0 .533-.227.533-.5S10.292 4 10 4z"
      />
    </g>
  </svg>
);
export default JxIcon;
