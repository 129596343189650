import React from "react";

const HomeIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M8 17L8 11 12 11 12 17 17 17 17 9 20 9 10 0 0 9 3 9 3 17z"
        transform="translate(2 3)"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default HomeIcon;
