import { makeStyles, MenuList, Popover } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { CCMenuItem, CCNestedMenuItem } from "./Component";

const useStyle = makeStyles(
  () => ({
    root: {
      "& .MuiPaper-rounded": {
        borderRadius: 8
      }
    },
    menu: {
      padding: 0
    }
  }),
  { name: "CCMenu" }
);

const MenuItemsComponent = props => {
  const {
    menuItems,
    onClose,
    MenuItemComponent,
    NestedMenuItemComponent,
    ...other
  } = props;

  return (
    <>
      {menuItems.map((menuItem, index) => {
        // console.log(index);
        if (menuItem.hasOwnProperty("nestedMenuItems")) {
          return (
            <React.Fragment key={`${menuItem?.value} ${index} nestedMenuItems`}>
              {Boolean(NestedMenuItemComponent) && (
                <NestedMenuItemComponent
                  NestedMenuItemComponent={NestedMenuItemComponent}
                  MenuItemComponent={MenuItemComponent}
                  color={menuItem.color ? menuItem.color : ""}
                  disabled={menuItem.disabled ? menuItem.disabled : false}
                  key={`${menuItem?.value} ${index} NestedMenuItemComponent`}
                  label={menuItem.label}
                  menuItems={menuItem.nestedMenuItems}
                  onClose={onClose}
                  {...other}
                />
              )}
              {!Boolean(NestedMenuItemComponent) && (
                <CCNestedMenuItem
                  color={menuItem.color ? menuItem.color : ""}
                  disabled={menuItem.disabled ? menuItem.disabled : false}
                  key={`${menuItem?.value} ${index} "CCNestedMenuItem`}
                  label={menuItem.label}
                  menuItems={menuItem.nestedMenuItems}
                  onClose={onClose}
                  {...other}
                />
              )}
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={`${menuItem?.value} ${index} menuItems`}>
            {Boolean(MenuItemComponent) && (
              <MenuItemComponent
                borderBottom={menuItem.borderBottom}
                color={menuItem.color ? menuItem.color : ""}
                disabled={menuItem.disabled ? menuItem.disabled : false}
                disableGutters={true}
                key={`${menuItem?.value} ${index} MenuItemComponent`}
                onClick={event => {
                  menuItem.onClick(event);
                  onClose(event);
                }}
                permission={menuItem?.permission}
                value={menuItem?.value}
                {...other}
              >
                {menuItem.beginIcon ? menuItem.beginIcon : null}
                {menuItem.label || menuItem?.value}
                {menuItem.endIcon ? menuItem.endIcon : null}
              </MenuItemComponent>
            )}
            {!Boolean(MenuItemComponent) && (
              <CCMenuItem
                borderBottom={menuItem.borderBottom}
                color={menuItem.color ? menuItem.color : ""}
                disabled={menuItem.disabled ? menuItem.disabled : false}
                disableGutters={true}
                key={`${menuItem?.value} ${index} CCMenuItem`}
                onClick={event => {
                  menuItem.onClick(event);
                  onClose(event);
                }}
                value={menuItem?.value}
                {...other}
              >
                {menuItem.beginIcon ? menuItem.beginIcon : null}
                {menuItem.label || menuItem?.value}
                {menuItem.endIcon ? menuItem.endIcon : null}
              </CCMenuItem>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const CCMenu = props => {
  const {
    anchorEl = null,
    MenuItemProps,
    menuItems,
    MenuListProps,
    menuRef,
    onClose,
    open,
    MenuItemComponent,
    NestedMenuItemComponent,
    ...other
  } = props;

  const classes = useStyle();

  return (
    <Popover
      anchorEl={anchorEl}
      className={clsx(classes.root)}
      onClose={onClose}
      open={open}
      disableScrollLock={true}
      {...other}
    >
      <MenuList ref={menuRef} className={clsx(classes.menu)} {...MenuListProps}>
        <MenuItemsComponent
          MenuItemComponent={MenuItemComponent}
          NestedMenuItemComponent={NestedMenuItemComponent}
          menuItems={menuItems}
          onClose={onClose}
          {...MenuItemProps}
        />
      </MenuList>
    </Popover>
  );
};

CCMenu.propTypes = {
  /** Menu Open 유무 */
  open: PropTypes.bool.isRequired,
  /** Menu Close 시 실행되는 함수 */
  onClose: PropTypes.func.isRequired,
  /** MenuList에 설정할 ref */
  menuRef: PropTypes.object,
  /** MenuItem에 설정할 props */
  MenuItemProps: PropTypes.object,
  /** MenuList에 설정할 props */
  MenuListProps: PropTypes.object,
  /** Menu 클릭 시 보여지는 items. nestedMenuItems: Menu의 하위 items, menuItems와 동일하게 정의 */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node
      ]),
      color: PropTypes.string,
      onClick: PropTypes.func,
      borderBottom: PropTypes.bool,
      disabled: PropTypes.bool,
      beginIcon: PropTypes.element,
      endIcon: PropTypes.element,
      nestedMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.node
          ]),
          color: PropTypes.string,
          onClick: PropTypes.func,
          borderBottom: PropTypes.bool,
          disabled: PropTypes.bool,
          beginIcon: PropTypes.element,
          endIcon: PropTypes.element
        })
      )
    })
  )
};

export default CCMenu;
