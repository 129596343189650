import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { PermissionBackdrop, useReadPermission } from "components";
import { AppContext } from "context";
import React, { useContext } from "react";
import { SalesManagement } from "./components";

const tabWidth = 180;
const useStyles = makeStyles(theme => ({
  root: { height: "100%", width: "100%" },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default,
    height: "56px",
    boxSizing: "border-box",
    "& button:nth-child(1)": {
      width: tabWidth
    },
    "& button:nth-child(2)": {
      width: tabWidth,
      fontWeight: "bold"
    },
    "& button:nth-child(3)": {
      width: tabWidth
    },
    "& button:nth-child(4)": {
      width: tabWidth
    },
    "& button:nth-child(5)": {
      width: tabWidth
    },
    "& button:nth-child(6)": {
      width: tabWidth
    },
    "& .MuiTab-textColorPrimary.Mui-selected ": {
      "&   .MuiTypography-h4": {
        color: theme.palette.secondary.main
      }
    }
  },
  tab__text: { fontSize: "16px", fontWeight: "bold" },
  panel: {
    height: `calc(100% - 56px)`,
    width: "100%",
    overflow: "auto"
  },
  container: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "row",
    backgroundColor: theme.palette.common.white
  },
  tab__container: {
    height: "calc(100% - 16px)",
    width: "100%",
    position: "relative"
    //padding: "12px"
  }
}));

const Statistics = () => {
  const classes = useStyles();
  const readPermission = useReadPermission("statistics");
  const { appContext } = useContext(AppContext);

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.tab__container}>
          {appContext.ready &&
            (!readPermission ? (
              <PermissionBackdrop permission={"statistics"} />
            ) : (
              <SalesManagement />
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Statistics;
