import { Box, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVTextField, ErrorTooltip } from "components";
import React, { forwardRef, useImperativeHandle } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { useIntl } from "utils/language";
import { CCTypography } from "styles/src/components";
import { Controller, useFormContext } from "react-hook-form";
import ExpireTable from "../ExpireTable";

const height = 150;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "18px !important"
  },
  chief__complaint__input: {
    marginBottom: "16px"
  },
  expire__table: {
    height: `calc(100% - ${height}px)`,
    marginTop: "40px"
  },
  head__field: {
    width: 130
  },
  head__storage__field: {
    width: 150
  },
  head__search__field: {
    width: 250
  },
  bin__field: {
    marginLeft: 20,
    width: 80
  },
  margin__field: {
    marginLeft: 10,
    paddingBottom: 0,
    paddingLeft: 0
  },
  font__field: {
    fontSize: 10,
    marginTop: 20,
    marginLeft: 20,
    color: theme.palette.disabled,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  label__font__field: {
    fontSize: 14,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  top__margin__field: {
    marginTop: "-10px"
  },
  bottom__margin__field: {
    marginBottom: "-40px"
  },
  button__filter: {
    marginTop: "5px"
  },
  info__button: { color: theme.palette.disabled.hex },
  border: {
    width: 1,
    height: "20px",
    marginLeft: 10,
    marginTop: 15,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  required: {
    color: theme.palette.error.main
  }
}));

const DetailModify = forwardRef((props, ref) => {
  const { modify, className } = props;

  const intl = useIntl();
  const classes = useStyles();

  const { getValues, control, watch, errors } = useFormContext();

  useImperativeHandle(ref, () => ref?.current);

  return (
    <>
      <Box className={clsx(classes.root, className)}>
        <Controller
          name={"major"}
          control={control}
          defaultValue={modify?.major || null}
          render={props => {
            const { ref, value, onBlur } = props;
            return (
              <ErrorTooltip
                open={Boolean(errors?.major?.message)}
                errorMsg={errors?.major?.message}
                placement={"top-start"}
              >
                <CVTextField
                  inputRef={ref}
                  required={true}
                  className={classes.head__field}
                  variant={"outlined"}
                  margin={"dense"}
                  select
                  value={value || ""}
                  disabled={true}
                  readOnly={true}
                  label={intl.formatMessage({ id: "majorCategory" })}
                  onBlur={onBlur}
                >
                  <MenuItem key={modify?.major} value={modify?.major}>
                    {modify?.major}
                  </MenuItem>
                </CVTextField>
              </ErrorTooltip>
            );
          }}
        />
        <CVTextField
          className={classes.head__field}
          variant={"outlined"}
          margin={"dense"}
          select
          value={modify?.minor || ""}
          disabled={true}
          readOnly={true}
          label={intl.formatMessage({ id: "minorCategory" })}
        >
          <MenuItem key={modify?.minor} value={modify?.minor}>
            {modify?.minor}
          </MenuItem>
        </CVTextField>
        <Controller
          name={"productName"}
          control={control}
          defaultValue={modify?.productName}
          render={props => {
            const { ref, value, onChange, onBlur } = props;

            return (
              <ErrorTooltip
                open={Boolean(errors?.productName?.message)}
                errorMsg={errors?.productName?.message}
                placement={"top-start"}
              >
                <CVTextField
                  required={true}
                  className={classes.head__search__field}
                  variant={"outlined"}
                  margin={"dense"}
                  inputRef={ref}
                  name={"searchField"}
                  value={value || ""}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  onBlur={onBlur}
                  label={intl.formatMessage({ id: "productName" })}
                  // disabled={true}
                />
              </ErrorTooltip>
            );
          }}
        />
        <Controller
          name={"manufacture"}
          control={control}
          defaultValue={getValues("manufacture")}
          render={props => {
            const { ref, value, onChange, onBlur } = props;

            return (
              <CVTextField
                className={classes.head__search__field}
                variant={"outlined"}
                margin={"dense"}
                inputRef={ref}
                name={"searchField"}
                value={value || ""}
                onChange={e => {
                  onChange(e.target.value);
                }}
                onBlur={onBlur}
                label={intl.formatMessage({ id: "manufacturer" })}
                // disabled={true}
              />
            );
          }}
        />
        <Box>
          <Controller
            name={"storage.currentCnt"}
            control={control}
            defaultValue={getValues("storage.currentCnt") || 0}
            render={props => {
              const { ref, value, onChange, onBlur } = props;

              return (
                <CVTextField
                  className={classes.head__search__field}
                  name={"searchField"}
                  defaultValue={modify?.storage?.currentCnt}
                  value={value}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  variant={"outlined"}
                  margin={"dense"}
                  label={intl.formatMessage({ id: "currentCnt" })}
                  inputRef={ref}
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <CCTypography variant={"body1"}>
                          {watch("storage.unit") === "EA"
                            ? "EA"
                            : watch("storage.unit") === "BIN"
                            ? "BIN"
                            : modify?.storage?.unit}
                        </CCTypography>
                      </InputAdornment>
                    )
                  }}
                  disabled={true}
                />
              );
            }}
          />

          <Controller
            name={"storage.optCnt"}
            control={control}
            defaultValue={getValues("optCnt") || 0}
            render={props => {
              const { ref, value, onChange, onBlur } = props;

              return (
                <CVTextField
                  className={classes.head__search__field}
                  name={"searchField"}
                  inputRef={ref}
                  value={value}
                  onBlur={onBlur}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  variant={"outlined"}
                  margin={"dense"}
                  label={intl.formatMessage({ id: "optCnt" })}
                  InputProps={{
                    endAdornment: (
                      // <InputAdornment>
                      <CCTypography variant={"body1"} position={"end"}>
                        {watch("storage.unit") === "EA"
                          ? "EA"
                          : watch("storage.unit") === "BIN"
                          ? "BIN"
                          : modify?.storage?.unit}
                      </CCTypography>
                      // </InputAdornment>
                    )
                  }}
                  disabled={true}
                />
              );
            }}
          />
        </Box>
        <Box className={classes.expire__table}>
          <Controller
            name={"expireList"}
            control={control}
            defaultValue={modify?.storage?.expire}
            render={props => {
              const { ref, value, onChange, onBlur } = props;
              return (
                <ExpireTable
                  data={modify}
                  currentCnt={watch("storage.currentCnt")}
                  value={value}
                  onChange={e => {
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  inputRef={ref}
                />
              );
            }}
          />
        </Box>
      </Box>
    </>
  );
});
export default DetailModify;
