import React from "react";

const ExcelIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M14 2v3h7.044c.49 0 .894.35.95.803l.006.106V19.09c0 .502-.428.909-.956.909H14v3L2 20.81V4.19L14 2zm7 4h-7v1h1v2h-1v1h1v2h-1v1h1v2h-1v1h1v2h-1v1h7V6zm-1 10v2h-4v-2h4zm-9.07-8l-1.726.111-1.054 2.647-.069.238-.05.18-.044.174-.035.144c0-.071-.015-.138-.045-.201l-.045-.185-.05-.175-.054-.159-.905-2.514-1.665.11 1.775 4.12L5 16.609l1.627.106 1.092-2.7.05-.186.04-.164.035-.138v-.111l.035.195.035.165.03.127.03.09 1.137 2.88L11 17l-2.127-4.537L10.93 8zM20 13v2h-4v-2h4zm0-3v2h-4v-2h4zm0-3v2h-4V7h4z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default ExcelIcon;
