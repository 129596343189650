import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import * as moment_tz from "moment-timezone";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { CCTable, CCTypography } from "styles/components";
import { insertComma } from "utils/etc";
import { translate } from "components";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon
  },
  color__red: { color: "#f50057 !important" },
  marginTop: { marginTop: 12, width: "100%" },
  detail__table: {
    border: "1px solid black",
    background: "white",
    width: "100%"
    // height: "fit-content"
  },
  detail__table_custom: {
    background: "white",
    width: "100%"
    // height: "fit-content"
  },
  table_custom: {
    border: "1px solid black"
  },
  detail__totaltable: {
    width: "fit-content"
  },
  table3: {
    flex: 1,
    width: "fit-content",
    marginRight: 12
  },
  table1: {},
  table2: {},
  table4: { width: 360 },

  container: {
    flexDirection: "column",
    position: "relative",
    // borderTop: '1px solid #b0bec5',
    background: "#eceff1"
  },
  item1: { marginBottom: 15, padding: "0 10px" },
  title__heading: { paddingBottom: 20, textAlign: "center", width: "100%" },
  item2: {
    margin: 12,
    padding: 32,
    flex: 1,
    background: theme.palette.common.white
  },
  column1: { alignItems: "center", flex: 1 },
  detail__column: {
    justifyContent: "center",
    borderLeft: "1px solid #b0bec5",
    "&:not(:last-child)": {
      borderBottom: "1px solid #b0bec5"
    }
  },
  bottomlineLeft: {
    boxSizing: "border-box",
    width: "200px",
    padding: "4px 16px"
  },
  bottomlineRight: {
    boxSizing: "border-box",
    width: "350px",
    padding: "4px 16px",
    borderLeft: "1px solid #b0bec5"
  },
  box__cover: {
    padding: 32
  },
  cell: {
    "&:not(:last-child)": {
      borderRight: "1px solid #b0bec5 "
    },
    overflowWrap: "anywhere",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  center: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    "&:not(:nth-child(7))": {
      borderRight: "1px solid #b0bec5 "
    },
    wordBreak: "break-word",
    justifyContent: "center",
    textAlign: "center"
  },
  col1__table: {
    width: 110
  },
  col2__table: {
    width: 110
  },
  col3__table: {
    width: 120
  },
  col4__table: {
    width: 210,
    height: 35,
    borderBottom: "1px solid #b0bec5"
  },
  col5__table: {
    height: 35,
    width: 70
  },
  col6__table: {
    width: 60
  },
  col7__table: {
    width: 80
  },
  col8__table: {
    width: 100
  },
  col9__table: {
    width: 50
  },
  col10__table: {
    flex: 1
  },
  head__table2: {
    height: 40,
    background: "#eceff1",
    borderBottom: "1px solid black"
  },
  total: {
    width: 700,
    padding: "8px 8px",
    borderRight: "1px solid #b0bec5"
  },
  line__total__price: {
    borderTop: "1px solid black"
  },
  price: {
    flex: 1,
    padding: "8px 8px",
    textAlign: "center"
  },
  height_nodata: {
    height: 36
  },
  paddingTop8: {
    paddingTop: 8
  },
  textArea: {
    width: "100%",
    border: "none",
    textAlign: "left",
    // minWidth: 450,
    marginLeft: 0,
    marginRight: 0
  }
}));
const useTableStyles = makeStyles(() => ({
  table__body: {
    minHeight: 0,
    "& > div:nth-child(n)": {
      background: "white"
    },
    "& > div:not(:last-child)": {
      borderBottom: "1px solid #b0bec5"
    }
  }
}));

const useSummaryTableStyles = makeStyles(() => ({
  table__body: {
    minHeight: 0,
    "& > div:nth-child(n)": {
      background: "white"
    },
    "& > div:not(:last-child)": {
      borderBottom: "1px solid #b0bec5"
    },
    overflow: "hidden"
  }
}));

const Receipt = React.forwardRef((props, ref) => {
  const { data, note } = props;


  const classes = useStyles();
  const intl = useIntl();
  const tableClasses = useTableStyles();
  const summaryTableClasses = useSummaryTableStyles();
  const heads1 = [
    {
      key: "prosthesis",
      label: <CCTypography>{translate(intl, "payment.prosthesis")}</CCTypography>,
      className: classes.cell,
      flex: 1,
      component: ({ rowData }) => {
        return (
          <CCTypography>
            {rowData?.prosthesis ? insertComma(rowData?.prosthesis) : "-"}
          </CCTypography>
        );
      }
    },
    // {
    //   key: "gold",
    //   label: translate(intl, "payment.gold"),
    //   className: classes.cell,
    //   flex: 1,
    //   component: ({ rowData }) => (
    //     <CCTypography>
    //       {rowData?.gold ? insertComma(rowData?.gold) : "-"}
    //     </CCTypography>
    //   )
    // },
    {
      key: "upPaid",
      label: <CCTypography>{translate(intl, "payment.unpaid")}</CCTypography>,
      className: classes.cell,
      flex: 1,
      component: ({ rowData }) => (
        <CCTypography>
          {rowData?.unpaidAmount ? insertComma(rowData?.unpaidAmount) : "-"}
        </CCTypography>
      )
    },
    {
      key: "dc",
      label: ({ rowData }) =>(
        <CCTypography className={classes.color__red}>
          {translate(intl, "payment.bill.dC")}
        </CCTypography>
      ),
      className: classes.cell,
      flex: 1,
      component: ({ rowData }) => (
        <CCTypography className={classes.color__red}>
          {rowData?.dc ? insertComma(rowData?.dc) : "-"}
        </CCTypography>
      )
    },
    {
      key: "total",
      label: <CCTypography>{translate(intl, "payment.total")}</CCTypography>,
      className: classes.cell,
      flex: 1,
      component: ({ rowData }) => (
        <CCTypography>
          {rowData?.total ? insertComma(rowData?.total) : "-"}
        </CCTypography>
      )
    }
  ];
  const heads2 = [
    {
      key: "orderDate",
      className: classes.cell,
      width: 110,
      component: ({ rowData }) => (
        <CCTypography>
          {rowData?.orderDate
            ? moment_tz(rowData?.orderDate, "X")
                .tz(moment_tz.tz.guess(true))
                .format("L")
            : "-"}
        </CCTypography>
      )
    },
    {
      key: "patient",
      className: classes.cell,
      width: 110,
      component: ({ rowData }) => (
        <CCTypography>{rowData?.patient}</CCTypography>
      )
    },
    {
      key: "toothNo",
      className: classes.cell,
      width: 120,
      component: ({ rowData }) => (
        <CCTypography>#{rowData?.teeth.join(", ")}</CCTypography>
      )
    },
    // {
    //   key: "goldReceived",
    //   className: classes.cell,
    //   width: 70,
    //   component: ({ rowData }) => (
    //     <CCTypography>{rowData?.gold.received}</CCTypography>
    //   )
    // },
    // {
    //   key: "goldUsed",
    //   className: classes.cell,
    //   width: 60,
    //   component: ({ rowData }) => (
    //     <CCTypography>{rowData?.gold.used}</CCTypography>
    //   )
    // },
    // {
    //   key: "goldConsumed",
    //   className: classes.cell,
    //   width: 80,
    //   component: ({ rowData }) => (
    //     <CCTypography>{rowData?.gold.consumed}</CCTypography>
    //   )
    // },
    {
      key: "unitCost",
      className: classes.cell,
      width: 100,
      component: ({ rowData }) => {
        return (
          <CCTypography>
            {rowData?.cost ? insertComma(rowData?.cost) : "-"}
          </CCTypography>
        );
      }
    },
    {
      key: "unit",
      className: classes.cell,
      width: 50,
      component: ({ rowData }) => {
        return <CCTypography>{rowData?.amount}</CCTypography>;
      }
    },
    {
      key: "totalPrice",
      className: classes.cell,
      flex: 1,
      component: ({ rowData }) => {
        return (
          <CCTypography>
            {rowData?.totalPrice ? insertComma(rowData?.totalPrice) : "-"}
          </CCTypography>
        );
      }
    }
  ];

  const heads3 = [
    {
      key: "goldType",
      className: classes.cell,
      label: translate(intl, "payment.bill.goldType"),
      flex: 1,
      component: ({ rowData }) => (
        <CCTypography>
          {rowData?.goldType ? rowData?.goldType : ""}
        </CCTypography>
      )
    },
    {
      key: "received",
      className: classes.cell,
      label: translate(intl, "payment.bill.receivedG"),
      flex: 1,
      component: ({ rowData }) => (
        <CCTypography>{rowData?.received ? rowData?.received : 0}</CCTypography>
      )
    },
    {
      key: "used",
      className: classes.cell,
      label: translate(intl, "payment.bill.usedG"),
      flex: 1,
      component: ({ rowData }) => (
        <CCTypography>{rowData?.used ? rowData?.used : 0}</CCTypography>
      )
    },
    {
      key: "consumed",
      className: classes.cell,
      label: translate(intl, "payment.bill.consumedG"),
      flex: 1,
      component: ({ rowData }) => (
        <CCTypography>{rowData?.consumed ? rowData?.consumed : 0}</CCTypography>
      )
    }
  ];
  const heads4 = [
    {
      key: "notes",
      className: classes.cell,
      label: translate(intl, "payment.bill.notes"),
      component: ({ rowData }) => (
        <CCTypography
          rows={5}
          className={classes.textArea}
        >
          {note}
        </CCTypography>
      )
    }
  ];
  const contents3 = data?.clinicSendGoldByType ? data.clinicSendGoldByType : [];
  const contents4 = [{ notes: note }];

  const totalPrice = useMemo(() => {
    if (!data?.orders) return 0;
    let price = (data.orders).reduce((acc, cur) => {
      return acc + cur.totalPrice;
    }, 0);

    if (Number.isInteger(price)) return insertComma(price);

    return insertComma(Number(price).toFixed(2));
  }, [data.orders]);

  console.log({totalPrice})

  return (
    <div ref={ref} className={classes.box__cover}>
      <Grid container>
        <CCTypography variant="h3" className={classes.title__heading}>
          {translate(intl, "payment.bill.paymentBill")}
        </CCTypography>
        <Grid container className={classes.detail__table}>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
            className={classes.column1}
          >
            <CCTypography variant="h5">
              {data?.partner?.name ? data?.partner?.name : ""}
            </CCTypography>
            <CCTypography className={classes.paddingTop8}>
              {/* {translate(intl, "payment.bill.term")}:{" "}
              {data?.beginPaymentCycleUnix
                ? moment_tz(data.beginPaymentCycleUnix, "X")
                    .tz(moment_tz.tz.guess(true))
                    .format("L")
                : "-"}{" "}
              ~{" "}
              {data?.endPaymentCycleUnix
                ? moment_tz(data.endPaymentCycleUnix, "X")
                    .tz(moment_tz.tz.guess(true))
                    .format("L")
                : "-"} */}
              {translate(intl, "payment.bill.sentDate")}:{" "}
              {data?.sendBillToCleverAt
                ? moment_tz(data.sendBillToCleverAt, "X")
                    .tz(moment_tz.tz.guess(true))
                    .format("L")
                : moment_tz()
                    .tz(moment_tz.tz.guess(true))
                    .format("L")}{" "}
            </CCTypography>
          </Grid>
          <Grid
            container
            className={classes.detail__totaltable}
            direction="column"
          >
            <Grid container className={classes.detail__column}>
              <Box className={classes.bottomlineLeft}>
                {translate(intl, "payment.bill.labName")}
              </Box>
              <Box className={classes.bottomlineRight}>
                {data?.lab?.labName || "-"}
              </Box>
            </Grid>
            <Grid container className={classes.detail__column}>
              <Box className={classes.bottomlineLeft}>
                {translate(intl, "payment.bill.owner")}
              </Box>
              <Box className={classes.bottomlineRight}>
                {data?.lab?.managerName || "-"}
              </Box>
            </Grid>
            <Grid container className={classes.detail__column}>
              <Box className={classes.bottomlineLeft}>
                {translate(intl, "payment.bill.telNo")}
              </Box>
              <Box className={classes.bottomlineRight}>
                {data?.lab?.labTelNumber || "-"}
              </Box>
            </Grid>
            <Grid container className={classes.detail__column}>
              <Box className={classes.bottomlineLeft}>
                {translate(intl, "common.ui.email")}
              </Box>
              <Box className={classes.bottomlineRight}>
                {data?.lab?.email || "-"}
              </Box>
            </Grid>
            <Grid container className={classes.detail__column}>
              <Box className={classes.bottomlineLeft}>
                {translate(intl, "payment.bill.bank")}
              </Box>
              <Box className={classes.bottomlineRight}>
                {data?.lab?.bankName || "-"}
              </Box>
            </Grid>
            <Grid container className={classes.detail__column}>
              <Box className={classes.bottomlineLeft}>
                {translate(intl, "payment.bill.accountNo")}
              </Box>
              <Box className={classes.bottomlineRight} style={{wordBreak: "break-all" }}>
                {data?.lab?.bankAccountNumber || "-"} ({" "}
                {data?.lab?.bankAccountHolder || "-"})
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.marginTop}>
          <CCTable
            classes={summaryTableClasses}
            className={clsx({
              [classes.detail__table]: true,
              [classes.height_nodata]: ![props?.data].length.toExponential
            })}
            heads={heads1}
            contents={[props?.data]}
          />
        </Grid>
        <Grid item className={classes.marginTop}>
          <Grid container className={classes.table_custom}>
            <Grid container className={classes.head__table2}>
              <Grid item className={clsx(classes.col1__table, classes.center)}>
                <CCTypography>
                  {translate(intl, "payment.bill.orderDate")}
                </CCTypography>
              </Grid>
              <Grid item className={clsx(classes.col2__table, classes.center)}>
                <CCTypography>
                  {translate(intl, "payment.bill.patient")}
                </CCTypography>
              </Grid>
              <Grid item className={clsx(classes.col3__table, classes.center)}>
                <CCTypography>
                  {translate(intl, "payment.bill.toothNo")}
                </CCTypography>
              </Grid>
              {/* <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <CCTypography
                      className={clsx(classes.col4__table, classes.center)}
                    >
                      {translate(intl, "labManageGold.gold")}
                    </CCTypography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <CCTypography
                        className={clsx(classes.col5__table, classes.center)}
                      >
                        {translate(intl, "payment.bill.received")}
                      </CCTypography>
                      <CCTypography
                        className={clsx(classes.col6__table, classes.center)}
                      >
                        {translate(intl, "payment.bill.used")}
                      </CCTypography>
                      <CCTypography
                        className={clsx(classes.col7__table, classes.center)}
                      >
                        {translate(intl, "payment.bill.consumed")}
                      </CCTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item className={clsx(classes.col8__table, classes.center)}>
                <CCTypography>
                  <div>{translate(intl, "payment.bill.unitCostG")}</div>
                </CCTypography>
              </Grid>
              <Grid item className={clsx(classes.col9__table, classes.center)}>
                <CCTypography>
                  {translate(intl, "payment.bill.unit")}
                </CCTypography>
              </Grid>
              <Grid item className={clsx(classes.col10__table, classes.center)}>
                <CCTypography>
                  <div>{translate(intl, "payment.bill.total")}</div>
                </CCTypography>
              </Grid>
            </Grid>

            <CCTable
              classes={tableClasses}
              labelShow={false}
              className={clsx({
                [classes.detail__table_custom]: true,
                [classes.height_nodata]: !data?.orders.length
              })}
              heads={heads2}
              contents={data?.orders}
            />
            <Grid container className={classes.line__total__price}>
              <Grid item className={classes.total}>
                <CCTypography>
                  {translate(intl, "payment.bill.totalOrders")}:{" "}
                  {data?.orders.length}
                </CCTypography>
              </Grid>
              <Grid item className={classes.price}>
                <CCTypography>
                  {totalPrice}
                </CCTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={clsx(classes.marginTop)}>
          {/* <Grid item className={clsx(classes.marginTop, classes.table3)}>
            <CCTable
              classes={summaryTableClasses}
              className={clsx({
                [classes.detail__table]: true,
                [classes.height_nodata]: !contents3.length
              })}
              heads={heads3}
              contents={contents3}
            />
          </Grid> */}
          {/* <Grid item className={clsx(classes.marginTop, classes.table4)}> */}
            <CCTable
              classes={tableClasses}
              className={clsx(classes.detail__table)}
              heads={heads4}
              contents={contents4}
            />
          {/* </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
});

export default Receipt;
