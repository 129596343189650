import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVButton, InfiniteTable } from "components";
import React, { useState } from "react";
import { useIntl } from "utils/language";
import { MenunextIcon } from "styles/src/themes/common/icons";
import { getCustomFormat } from "utils/datetime";
import { CCIconButton } from "styles/src/components";
import { getDateToTimestamp } from "utils/datetime";
import {
  TableHeader,
  TableItem
} from "./Component/ProductAddDialog/components/TableItems";
import { LocalizeVariable } from "utils/env";
import { OrderCancelDialog } from "../../../StatusDialog";
import OrderDetailDialog from "../OrderDetailDialog";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  container: { height: "100%" },
  container__filter: {
    textAlign: "center"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  item__table: {
    height: "calc(100% - 60px - 30px)",
    width: "100%",
    alignItems: "center",
    textAlign: "center"
  },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  text__description: {
    color: theme.palette.disabled.hex
  },
  button__sms: {
    width: "110px",
    height: "24px",
    padding: "0px"
  },
  button__filter: {
    minWidth: "100px",
    minHeight: "10px",
    margin: "16px"
  },
  select: { width: "100px", margin: "4px" },
  select__w126: { width: "126px" },
  select__w155: { width: "155px" },
  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center"
  },
  head__field: {
    width: "100px"
  },
  headertwo: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "80px",
    alignItems: "center"
  },
  headerfilter: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  header__table: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  field: {
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    textAlign: "center"
  },
  table: {
    height: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  shrink: {
    background: theme.palette.common.white,
    paddingRight: "4px"
  },
  button_register: {
    padding: "0px",
    width: "120px",
    margin: "10px"
  },
  button_right: {
    marginTop: "10px",
    marginRight: "10px"
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    // width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  icon__star: {
    margin: 12,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  primary__color: {
    color: theme.palette.primary.main
  },
  error__color: {
    color: theme.palette.error.main
  },
  width100Per: {
    width: "100%"
  },
  width70Per: {
    width: "70%",
    paddingRight: 16
  },
  width60Per: {
    width: "60%"
  },
  width50Per: {
    width: "50%"
  },
  width40Per: {
    width: "40%"
  },
  width20Per: {
    width: "20%"
  }
}));

const SearchResults = props => {
  const { result, hasNext, onMoreContents } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [detail, setDetail] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);

  return (
    <Box className={classes.root}>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.item__table} item>
          <InfiniteTable
            heads={[
              {
                key: "orderNumber",
                headComponent: () => (
                  // <TableHeader label={intl.formatMessage({ id: "registerNumber" })} />
                  <TableHeader label={"supplierNumber"} />
                ),
                width: 140,
                label: () => (
                  // <TableHeader label={intl.formatMessage({ id: "registerNumber" })} />
                  <TableHeader label={"supplierNumber"} />
                ),
                component: ({ cellData }) => {
                  // let time = getMomentFormatTimestamp(cellData, "X");
                  return <TableItem label={cellData ? cellData : ""} />;
                }
              },
              {
                key: "status",
                headComponent: () => (
                  <TableHeader label={intl.formatMessage({ id: "status" })} />
                ),
                width: 100,
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      label={intl.formatMessage({ id: `pam${cellData}` })}
                    />
                  );
                }
              },
              {
                key: "status",
                // headComponent: () => (
                //   <TableHeader label={intl.formatMessage({ id: "status" })} />
                // ),
                width: 100,
                component: ({ cellData, rowData }) => {
                  if (cellData === "Order") {
                    return (
                      <CVButton
                        variant={"contained"}
                        color="normal"
                        className={classes.small__button}
                        onClick={e => {
                          setCancelDialog(rowData);
                          // if (selected?.productID === cellData) {
                          //   e.stopPropagation();
                          // }
                          //
                          // setInventory(cellData);
                        }}
                      >
                        {intl.formatMessage({ id: "cancel" })}
                      </CVButton>
                    );
                  }
                }
              },
              {
                key: "updatedAt",
                width: 180,
                headComponent: () => (
                  <TableHeader
                    // label={intl.formatMessage({ id: "modifyDateTime" })}
                    label={"modifyDateTime"}
                  />
                ),
                component: ({ cellData }) => {
                  // let _date = new Date(cellData).toString();
                  let _format = getCustomFormat(
                    getDateToTimestamp(cellData),
                    "X",
                    "YYYY-MM-DD HH:mm"
                  );

                  return <TableItem label={_format ? _format : ""} />;
                }
              },
              {
                key: "Clinic",
                width: 220,
                headComponent: () => (
                  <TableHeader
                    // label={intl.formatMessage({ id: "partner" })}
                    label={"Partner"}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData?.name ? cellData?.name : ""} />
                )
              },
              {
                key: "OrderItems",
                width: 650,
                headComponent: () => (
                  // <TableHeader label={intl.formatMessage({ id: "tradingProduct" })} />
                  <TableHeader
                    label={intl.formatMessage({ id: "tradingProduct" })}
                  />
                ),
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      label={
                        cellData?.length > 1
                          ? intl.formatMessage(
                              { id: "cntExceptProduct" },
                              {
                                product: cellData[0]?.name,
                                cnt: cellData?.length - 1
                              }
                            )
                          : cellData
                          ? cellData[0]?.name
                          : ""
                      }
                      // label={cellData?.length > 1 ? `${cellData[0]?.name} 외 ${cellData?.length - 1} 품목` : cellData ? cellData[0]?.name : ""}
                    />
                  );
                }
              },
              {
                key: "totalSum",
                width: 150,
                headComponent: () => (
                  <TableHeader
                    label={`${intl.formatMessage({ id: "totalOrderCost" })}(${
                      LocalizeVariable.currencyUnit
                    })`}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData : ""} />
                )
              },

              {
                key: "Clinic",
                width: 120,
                headComponent: () => (
                  <TableHeader
                    // label={intl.formatMessage({ id: "totalEstimateAmount" })}
                    label={intl.formatMessage({ id: "manager" })}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData?.manager : ""} />
                )
              },
              {
                key: "status",
                width: 250,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "confirmationOrRequest" })}
                  />
                ),
                component: ({ cellData }) => {
                  return (
                    <Grid container alignItems="center">
                      <Grid item className={classes.width50Per}>
                        {cellData === "Ordering" && (
                          <CVButton
                            variant={"contained"}
                            color="normal"
                            className={classes.small__button}
                            // onClick={e => {
                            //   if (selected?.productID === cellData) {
                            //     e.stopPropagation();
                            //   }
                            //
                            //   setInventory(cellData);
                            // }}
                          >
                            {intl.formatMessage({ id: "purchaseRequest" })}
                          </CVButton>
                        )}
                      </Grid>
                      <Grid item className={classes.width50Per}>
                        {cellData === "Ordering" && (
                          <CVButton
                            variant={"contained"}
                            color="normal"
                            className={classes.small__button}
                            // onClick={e => {
                            //   if (selected?.productID === cellData) {
                            //     e.stopPropagation();
                            //   }
                            //
                            //   setInventory(cellData);
                            // }}
                          >
                            {intl.formatMessage({ id: "returnRequest" })}
                          </CVButton>
                        )}
                      </Grid>
                    </Grid>
                  );
                }
              },

              {
                key: "id",
                width: 40,
                component: ({ cellData, rowData }) => {
                  return (
                    <TableItem
                      disableTypography
                      label={
                        <CCIconButton
                          style={{ padding: 0, margin: 0 }}
                          disableRipple
                          variant={"text"}
                          size={"small"}
                          onClick={() => {
                            if (rowData) {
                              setDetail(rowData);
                            }
                          }}
                        >
                          <MenunextIcon />
                        </CCIconButton>
                      }
                    />
                  );
                }
              }
            ]}
            contents={result || []}
            hasNext={hasNext}
            onMoreContents={onMoreContents}
            // contents={[]}
          />
        </Grid>
      </Grid>

      {Boolean(detail) && (
        <OrderDetailDialog
          open={Boolean(detail)}
          onClose={() => setDetail(false)}
          order={detail}
          product={detail?.id}
        />
      )}

      {Boolean(cancelDialog) && (
        <OrderCancelDialog
          open={Boolean(cancelDialog)}
          onClose={() => setCancelDialog(false)}
          selectedProduct={[cancelDialog?.OrderItems || []]}
          productList={cancelDialog}
          vendorList={cancelDialog?.materialId || 0}
        />
      )}
    </Box>
  );
};

export default SearchResults;
