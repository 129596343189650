/*eslint react-hooks/exhaustive-deps: 0*/
import { useApolloClient, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { SaveAltOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { DialogWarningMessage, enqueueToast, translate } from "components";
import { useSnackbar } from "notistack";
import { PRODUCT_DETAIL } from "queries/product";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  CCButton,
  CCDialogAlert,
  CCIconButton,
  CCTypography
} from "styles/components";
import { BringInIcon } from "styles/icons";
import {
  CCDialog,
  CCDialogContent,
  CCDialogTitle
} from "styles/src/components";
import { getCustomFormat, getTimestamp } from "utils/datetime";
import { useIntl } from "utils/language";
import { useProductLogValidation } from "validations";
import useCreateInventoryLog from "../../hooks/useCreateInventoryLog";
import useUpdateProduct from "../../hooks/useUpdateProduct";
import Tabs from "./components/Tabs";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "660px !important",
      width: 660,
      height: props => {
        if (Boolean(props?.length > 0)) {
          return 770;
        } else {
          return 470;
        }
      }
    }
  },
  box: {
    width: 660
  },
  icon__end: {
    display: "flex",
    justifyContent: "flex-end",
    overflowY: "hidden"
  },
  delete__button: {
    position: "absolute",
    left: 10,
    width: "200px"
  }
}));

const initData = {
  important: false,
  unit: "",
  currentCnt: 0,
  currentCntOut: 0,
  optCnt: 0,
  alert: false,
  editDate: getTimestamp(),
  editorID: "",
  canExpire: false,
  expire: {
    expireCount: 0,
    expireDate: getTimestamp(),
    count: 0,
  },
  binCnt: 0,
  barcode: false,
  isAppropriate: false,
};

const InventoryDialog = props => {
  const client = useApolloClient();
  const intl = useIntl();

  const { open, onClose, valueId, refetchProductList } = props;
  const [detail, setDetail] = useState(initData);

  const classes = useStyles(detail?.storage?.expire);
  const selectRef = useRef();
  const [alertOpen, setAlertOpen] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [cancelDialog, setCancelDialog] = useState(false);

  const { schema } = useProductLogValidation();
  const { createLog, createLogOut, createLogIn } = useCreateInventoryLog();
  const { useUpdateProductIn, useUpdateProductOut } = useUpdateProduct;
  const updateProductIn = useUpdateProductIn();
  const updateProductOut = useUpdateProductOut();

  const { data: productDetail, refetch } = useQuery(PRODUCT_DETAIL, {
    fetchPolicy: "network-only",
    variables: {
      id: valueId
    },
    // onCompleted: e => {
    //   if (e?.productDetail) {
    //     setDetail(e?.productDetail);
    //   }
    // }
  });

  useEffect(() => {
    if (productDetail?.productDetail) {
      methods.reset(productDetail?.productDetail);
      setDetail(productDetail?.productDetail);
    }
  }, [productDetail]);

  const methods = useForm({
    mode: `onSubmit`,
    defaultValues: initData,
    resolver: yupResolver(schema)
  });

  const { getValues } = methods;

  const [isInventoryOut, setIsInventoryOut] = useState(false);


  const setCloseAlert = useCallback(() => {
    setAlertOpen(false);
  }, []);

  const setCloseSaveAlert = useCallback(() => {
    setSaveAlertOpen(false);
  }, []);

  const saveIn = useCallback(
    async e => {
      console.log("saveIn detail", detail);
      console.log("saveIn e", e);
      const filterArray = detail?.storage?.expire?.reduce((pre, value) => {
        if (
          !Boolean(
            e?.expireList
              ?.map(e => getCustomFormat(e?.expireDate, "X", "YYYY-MM-DD"))
              .includes(getCustomFormat(value?.expireDate, "X", "YYYY-MM-DD"))
          )
        ) {
          pre.push(value);
        }
        return pre;
      }, []);
      Promise.all([
        createLogIn(e, detail, filterArray),
        updateProductIn(e, detail, filterArray)
      ]).then(() => {
        enqueueToast(
          enqueueSnackbar,
          ` ${intl.formatMessage({ id: "saveCompleted" })}`
        );
        refetchProductList();
        onClose();
      });

    },
    [
      client,
      onClose,
      enqueueSnackbar,
      intl,
      createLog,
      updateProductIn,
      refetchProductList
    ]
  );

  const saveOut = useCallback(
    async (e) => {
      e.inOut = false;

      const expireListOut = e?.expireListOut || [];
      const newExpireList = expireListOut.filter(expireItem => {
        return !expireItem.count || expireItem.count < expireItem.expireCount
      })

      Promise.all([
        createLogOut(e, detail,),
        updateProductOut(e, detail, newExpireList)
      ]).then(() => {
        enqueueToast(
          enqueueSnackbar,
          ` ${intl.formatMessage({ id: "saveCompleted" })}`
        );
        refetchProductList();
        onClose();
      });
    }
    , [detail])

  const onError = error => {
    const errorMessages = Object.keys(error).map(key => {
      const errorMessage = error[key].message
      return errorMessage;
    })
    if (errorMessages && errorMessages.length > 0) {
      setCancelDialog({
        title: errorMessages[0],
        onClose: () => {
          setCancelDialog(null);
        },
        onAgree: () => {
          setCancelDialog(null);
        }
      });
    }
  }

  const onCreate = () => {
    if (isInventoryOut) methods.handleSubmit(saveOut, onError)();
    else methods.handleSubmit(saveIn, onError)();
  }

  const onClosure = () => {
    if (Object.keys(methods.formState.dirtyFields)?.length > 0 || selectRef) {
      setCancelDialog({
        title: intl.formatMessage(
          { id: "confirmCancelwritingInfo" },
          { br: () => <br /> }
        ),
        agreeButtonLabel: translate(intl, "yes"),
        disagreeButtonLabel: translate(intl, "no"),
        onClose: () => {
          setCancelDialog(null);
        },
        onDisagree: () => {
          setCancelDialog(null);
        },
        onAgree: () => {
          onClose && onClose();
        }
      });
    } else {
      onClose && onClose();
    }
  };

  const handleOnChangeTab = (tabNo) => {
    setIsInventoryOut(tabNo === 1);
  }

  return (
    <>
      <FormProvider {...methods}>
        <CCDialog
          open={Boolean(open)}
          className={classes.root}
          maxWidth="md"
          onClose={onClosure}
        >
          <CCDialogTitle onCloseButton={onClosure} />
          <CCDialogContent
            noPadding={true}
            style={{ overflow: "hidden" }}
          >
            <Grid style={{ padding: 10 }} item>
              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="row">
                  <Box className={classes.box}>
                    <Tabs value={detail} ref={selectRef} onClose={onClose} handleOnChangeTab={handleOnChangeTab} />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Box
              style={{ overflowY: "hidden", padding: 10 }}
              marginLeft={"auto"}
              className={classes.icon__end}
            >
              <CCButton
                className={classes.cancel__icon}
                variant={"text"}
                color={"normal"}
                onClick={onClosure}
              >
                {intl.formatMessage({ id: "cancel" })}
              </CCButton>
              <CCButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  onCreate();
                }}
              >
                {intl.formatMessage({ id: "save" })}
              </CCButton>
              <CCDialogAlert
                contents={
                  <Box className={classes.box}>
                    <CCTypography>
                      {intl.formatMessage({ id: "registerProduct" })}
                      <CCIconButton color={"secondary"} variant={"contained"}>
                        <BringInIcon />
                      </CCIconButton>
                      {intl.formatMessage({ id: "clickBtn" })}
                    </CCTypography>
                  </Box>
                }
                open={alertOpen}
                onClose={setCloseAlert}
                onAgree={setCloseAlert}
                onDisagree={setCloseAlert}
              />
              <CCDialogAlert
                contents={
                  <CCTypography>
                    {intl.formatMessage({ id: "registerProduct" })}
                    <CCIconButton color={"secondary"} variant={"contained"}>
                      <BringInIcon />
                    </CCIconButton>
                    {intl.formatMessage({ id: "registerInputContent" })}
                  </CCTypography>
                }
                open={saveAlertOpen}
                onClose={setCloseSaveAlert}
                onAgree={setCloseSaveAlert}
                onDisagree={setCloseSaveAlert}
              />
            </Box>
          </CCDialogContent>
        </CCDialog>
      </FormProvider>
      {Boolean(cancelDialog) && <DialogWarningMessage open={Boolean(cancelDialog)} {...cancelDialog} />}
    </>
  );
};

export default InventoryDialog;
