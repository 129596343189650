import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import React, { forwardRef } from "react";

const useStyle = makeStyles(
  theme => ({
    root: {
      color: props => {
        return props.color ? props.color : "";
      },
      justifyContent: "center",
      minHeight: 38,
      minWidth: 81,
      padding: "10px 16px",
      textAlign: "center"
    },
    border__bottom: {
      borderBottom: `solid 1px ${theme.palette.border.main}`
    }
  }),
  { name: "CCMenuItem" }
);

const CCMenuItem = forwardRef((props, ref) => {
  const { className, borderBottom = false, endIcon, label, ...others } = props;
  const classes = useStyle(props);
  return (
    <MenuItem
      {...others}
      ref={ref}
      className={clsx(className, classes.root, {
        [classes.border__bottom]: borderBottom
      })}
    />
  );
});

export default CCMenuItem;
