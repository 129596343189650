import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import { ADD_LAB_GOLD_STOCK, GOLD_STOCK_DETAIL } from "queries/goldManagement";
import React, { useReducer } from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";
import { LAB_GOLD_TYPES } from "types/goldManagement";
import { makeIndexFormattedMessageList } from "types/utils";
import {
  AddStockModal,
  CurrentStockBox,
  HistoryTable
} from "views/Normal/GoldManagement/components";
import { translate } from "components";

const useStyles = makeStyles(() => ({
  root: {
    padding: "8px 16px 16px"
  },
  cell__center: {
    textAlign: "center"
  },
  stock__history__table: {
    marginTop: 16,
    width: "100%"
  }
}));

const initializer = () => {
  return {
    filter: {
      args: {
        date: {
          begin: moment().startOf("week").unix(),
          end: moment().endOf("week").unix()
        },
        goldType: undefined
      }
    },
    addStockModalVisible: false
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.target.filter
        }
      };
    case "setAddStockModalVisible":
      return {
        ...state,
        addStockModalVisible: action.target
      };
    default:
      break;
  }

  return state;
};

const LAB_GOLD_TYPES_MAPPING = makeIndexFormattedMessageList(LAB_GOLD_TYPES);
const goldTypes = {
  inlay: "Inlay",
  aType: "A-Type",
  sA: "S-A",
  pt: "PT",
  pfg: "PFG"
};

const StockManage = props => {
  const classes = useStyles();
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const { addStockModalVisible } = state;
  const intl = useIntl();

  const {
    data: goldStockDetailData,
    loading: goldStockDetailLoading,
    refetch: goldStockDetailRefetch
  } = useQuery(GOLD_STOCK_DETAIL, {
    variables: {
      query: {
        importDate: {
          from: state.filter.args.date.begin,
          to: state.filter.args.date.end
        },
        goldType: state.filter.args.goldType
      }
    }
  });
  const [addLabGoldStock] = useMutation(ADD_LAB_GOLD_STOCK);

  const handleOnAddLabGoldStock = (importDate, goldType, goldAddedAmount) => {
    addLabGoldStock({
      variables: {
        input: {
          importDate,
          goldType,
          goldAddedAmount
        }
      }
    }).then(() => {
      goldStockDetailRefetch();
    });
  };

  const stockHistoryHeads = [
    {
      key: "date",
      className: classes.cell__center,
      label: translate( intl,"labManageGold.date" ),
      width: 150,
      component: ({ rowData }) => (
        <CCTypography>
          {moment.unix(rowData.importDate).format("L")}
        </CCTypography>
      )
    },
    {
      key: "type",
      className: classes.cell__center,
      label: translate( intl,"common.ui.type" ),
      component: ({ rowData }) => (
        <CCTypography>{LAB_GOLD_TYPES_MAPPING[rowData.goldType]}</CCTypography>
      )
    },
    {
      key: "added",
      className: classes.cell__center,
      label: translate( intl,"labManageGold.addedG" ),
      component: ({ rowData }) => (
        <CCTypography color={"primary"} variant="h4">
          {Number(rowData.goldAddedAmount).toFixed(2)}
        </CCTypography>
      )
    },
    {
      key: "total",
      className: classes.cell__center,
      label: translate( intl,"labManageGold.totalG" ),
      component: ({ rowData }) => {
        console.log("StockManage total rowData", rowData);
        return (
          <CCTypography variant="h4">
            {Number(rowData.goldStockRemain).toFixed(2)}
          </CCTypography>
        );
      }
    }
  ];

  return (
    <>
      <Grid container direction={"column"} className={classes.root}>
        {!goldStockDetailLoading && (
          <CurrentStockBox
            goldTypes={goldTypes}
            goldStock={goldStockDetailData?.goldStockDetail}
            onClickAddStockButton={() =>
              dispatchState({
                type: "setAddStockModalVisible",
                target: true
              })
            }
          />
        )}

        {!goldStockDetailLoading && (
          <HistoryTable
            title={translate( intl,"labManageGold.stockHistory" )}
            heads={stockHistoryHeads}
            contents={
              goldStockDetailData?.goldStockDetail?.history
                ? goldStockDetailData.goldStockDetail.history
                : []
            }
            filter={{
              filter: state.filter
            }}
            styles={classes.stock__history__table}
            onFilter={e => {
              dispatchState({ type: "filter", target: e });
            }}
          />
        )}
      </Grid>

      {addStockModalVisible && (
        <AddStockModal
          open={addStockModalVisible}
          onClose={() => {
            dispatchState({
              type: "setAddStockModalVisible",
              target: false
            });
          }}
          goldStockDetail={goldStockDetailData?.goldStockDetail}
          onAdd={handleOnAddLabGoldStock}
        />
      )}
    </>
  );
};

export default StockManage;
