import { useQuery } from "@apollo/react-hooks";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVButton, DialogWarningMessage, translate } from "components";
import { AppContext, RouteContext } from "context/";
import gql from "graphql-tag";
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import { CCDialogSimple, CCTypography } from "styles/components";
import { useIntl } from "utils/language";

const useStyles = makeStyles(theme => ({
  root: {},
  warning___color: { color: theme.palette.error.main },

  text: { margin: "0 16px", width: 280 },
  disagree: { marginRight: 8 }
}));

const maxCnt = 60;

const GET_OTHER_SETTING = gql`
  query getConfig {
    labDetail {
      passwordChangeCycle
      automaticLogOut
    }
  }
`;

const DialogTimeout = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { dispatchRoute } = useContext(RouteContext);
  const { dispatchApp, appContext } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [warn, setWarn] = useState(false);
  const [cnt, setCnt] = useState(maxCnt);
  const id = useRef(null);
  const setTimer = useCallback(() => {
    if (appContext.automaticLogOut === null) return;
    if (id.current) clearTimeout(id.current);
    id.current = setTimeout(() => {
      id.current = null;
      setOpen(true);
      setCnt(maxCnt);
    }, [appContext.automaticLogOut * 1000 * 60 - 1000 * 60]);
  }, [appContext.automaticLogOut]);
  setTimer();

  useQuery(GET_OTHER_SETTING, {
    variables: {
      hospitalId: localStorage.getItem("hospitalId")
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.labDetail) {
        dispatchApp({
          target: data.labDetail.automaticLogOut,
          type: "automaticLogOut"
        })
      }
    }
  });

  useEffect(() => {
    const onClick = () => {
      setTimer();
    };

    window.addEventListener("click", onClick, true);
    window.addEventListener("keydown", onClick, true);

    return () => {
      window.removeEventListener("click", onClick, true);
      window.removeEventListener("keydown", onClick, true);

      clearTimeout(id.current);
      clearTimeout(cntTimerId.current);
    };
  }, [setTimer]);

  const cntTimerId = useRef(null);

  useEffect(() => {
    const countDown = () => {
      if (cntTimerId.current) {
        clearTimeout(cntTimerId.current);
      }
      cntTimerId.current = setTimeout(() => {
        cntTimerId.current = null;
        if (cnt) {
          setCnt(cnt - 1);
        } else {
          dispatchRoute({ path: "/timeout" });
          dispatchApp({ type: "permission", target: null });
        }
      }, [1000]);
    };

    if (open) countDown();
  }, [open, cnt, dispatchRoute, dispatchApp]);

  return (
    <>
      <CCDialogSimple
        open={open}
        contents={
          <Box>
            <Box className={classes.text}>
              <CCTypography className={classes.warning___color}>
                {translate(intl, "common.messages.logoutWarningInSeconds", {
                  cnt
                })}
                <br />
                {translate(intl, "common.messages.continue")}
         
              </CCTypography>
            </Box>
          </Box>
        }
        endActions={
          <>
            <CVButton
              className={classes.disagree}
              color="sub"
              onClick={() => {
                setOpen(false);
                setTimer();
              }}
            >
              {intl.formatMessage({ id: "extendConnection" })}
            </CVButton>
            <CVButton
              variant="contained"
              color="error"
              onClick={() => {
                dispatchRoute({ path: "/signin" });
                dispatchApp({ type: "permission", target: null });
              }}
            >
              {intl.formatMessage({ id: "logout" })}
            </CVButton>
          </>
        }
      />
      <DialogWarningMessage
        open={warn}
        title={translate(intl, "common.messages.logoutWarningResult")}
        onAgree={() => {
          dispatchRoute({ path: "/signin" });
          dispatchApp({ type: "permission", target: null });
          setWarn(false);
        }}
        onClose={() => {
          dispatchRoute({ path: "/signin" });
          dispatchApp({ type: "permission", target: null });
          setWarn(false);
        }}
      />
    </>
  );
};

export default DialogTimeout;
