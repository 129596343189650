import { Box, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  timestamp: {
    background: theme.palette.input.main,
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "14px",
    color: "white"
  }
}));

const GroupBreak = props => {
  const { timestamp } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.timestamp}>
        {moment(timestamp, "X").format("L")}
      </Typography>
    </Box>
  );
};

export default GroupBreak;
