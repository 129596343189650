import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateRangeIcon } from "@material-ui/pickers/_shared/icons/DateRangeIcon";
import { TimeIcon } from "@material-ui/pickers/_shared/icons/TimeIcon";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { CCDateTimeToolbar } from "./Component";

const OPEN_TYPE = {
  DATE: "date",
  YEAR: "year",
  MONTH: "month",
  HOURS: "hours",
  MINUTES: "minutes"
};
/**
 * @deprecated
 */
const CCDateTimePicker = props => {
  const { onChange, date, minutesStep } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        autoOk
        ampm={true}
        variant="determinate"
        openTo={OPEN_TYPE.DATE}
        value={date}
        onChange={onChange}
        minutesStep={minutesStep}
        ToolbarComponent={CCDateTimeToolbar}
        timeIcon={<TimeIcon htmlColor={"#ffffff"} />}
        dateRangeIcon={<DateRangeIcon htmlColor={"#ffffff"} />}
      />
    </MuiPickersUtilsProvider>
  );
};

CCDateTimePicker.propTypes = {
  /** Date Value ( Moment Object) */
  date: PropTypes.instanceOf(moment).isRequired,
  /** Date Change Event Handler */
  onChange: PropTypes.func.isRequired,
  /** Minutes Select Step in Clock UI */
  minutesStep: PropTypes.number
};

CCDateTimePicker.defaultProps = {
  date: moment(),
  onChange: () => {},
  minutesStep: 1
};

export default CCDateTimePicker;
