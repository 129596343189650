import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { CCTooltip, CCTypography } from "styles/src/components";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    minHeight: 36,
    cursor: "pointer"
  },
  typo: {
    maxWidth: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden"
  }
}));

const TableItem = props => {
  const {
    label = "-",
    onClick = () => {
    },
    className,
    left = false,
    tooltip = false
  } = props;
  const classes = useStyle();
  return (
    <Grid
      container
      justifyContent={left ? "flex-start" : "center"}
      alignItems={"center"}
      className={classes.root}
      onClick={onClick}
    >
      <Grid
        item
        container
        alignItems={"center"}
        justifyContent={left ? "flex-start" : "center"}
      >
        {tooltip ? (
          <CCTooltip title={label} placement="top">
            <CCTypography
              variant={"body1"}
              className={clsx(className, classes.typo)}
            >
              {label === "" ? "-" : label}
            </CCTypography>
          </CCTooltip>
        ) : (
          <CCTypography
            variant={"body1"}
            className={clsx(className, classes.typo)}
          >
            {label === "" ? "-" : label}
          </CCTypography>
        )}
      </Grid>
    </Grid>
  );
};
export default TableItem;
