import React from "react";

const DownloadIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M18 13v4H6v-4H4v6h16v-6h-2zm-4-8h-4v4H7l5 5 5-5h-3V5z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default DownloadIcon;
