import React from "react";

const DigitalSignnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="3y7d3u3dea" x1="50%" x2="50%" y1="70%" y2="100%">
        <stop offset="0%" stopColor="#00897B" />
        <stop offset="100%" stopColor="#00BEB2" />
      </linearGradient>
      <linearGradient id="mu98cclnzb" x1="50%" x2="50%" y1="50%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="url(#3y7d3u3dea)"
            d="M28 4.332v6.113l-1 1 1 1.001v2.25l-2.03-2.03-6.97 6.97V22h2.363L28 15.362v12.63H3.67V4.332H28zM13.328 16.261l-.272.13c-.047.023-.096.048-.145.075l-.23.125-.37.218-.57.363-.354.238-.374.261-.395.284-.415.305-.436.329-.456.35-.724.57-.77.62-.817.67.845 1.218.791-.65.745-.599.47-.372.667-.516.419-.316.397-.294.377-.272.355-.25.494-.332.302-.193.281-.17.26-.149c.11-.06.215-.115.313-.163l.224-.102-.07.182-.154.363-.19.459-.251.633-.143.388-.123.36c-.34 1.045-.417 1.746-.228 2.233.408 1.05 1.223.904 2.451.084l.156-.106.414-.295c.236-.162.439-.278.605-.347l.066-.026.106-.033.008.049c.006.043.01.095.012.155l.002.096H18l-.002-.14c-.06-1.86-1.333-2.149-2.964-1.03l-.292.208c-.168.119-.32.219-.454.3l-.16.09.048-.2.072-.262.048-.155.112-.338.134-.374.158-.41.181-.446.258-.613c.758-1.888-.028-3-1.81-2.203z"
            transform="translate(-736.000000, -369.000000) translate(736.000000, 369.000000)"
          />
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-736.000000, -369.000000) translate(736.000000, 369.000000)"
          />
          <path
            fill="url(#mu98cclnzb)"
            d="M28.003 1c1.467 0 2.667 1.2 2.667 2.666v1.581l-2.667 2.46V4.332H3.667v23.66h24.336v-8.79l2.667-2.61v11.742c0 1.466-1.2 2.666-2.667 2.666H3.667C2.2 31 1 29.8 1 28.334V3.666C1 2.2 2.2 1.013 3.667 1.013L28.003 1zM15.139 18.464l-.258.613-.18.446-.159.41-.134.374-.112.338-.048.155-.072.262-.047.2.031-.016c.122-.068.262-.155.42-.262l.3-.211c1.704-1.244 3.056-.998 3.118.921l.002.14h-1.399c0-.102-.005-.187-.014-.251l-.008-.05-.048.014c-.035.011-.077.026-.124.046-.166.069-.37.185-.605.347l-.414.295c-1.315.911-2.182 1.115-2.607.022-.19-.487-.112-1.188.228-2.232l.123-.361.182-.489.211-.532.191-.46.154-.362.07-.182-.083.036c-.137.061-.288.138-.454.23l-.26.147-.28.171-.462.299-.335.226-.355.25-.377.272-.397.294-.419.316-.667.516-.47.372-.745.6-.79.649L7 20.799l.817-.67.77-.62.724-.57.456-.35.436-.329.415-.305.395-.284.374-.26.44-.295.485-.307.37-.218c.236-.133.451-.243.646-.33 1.783-.797 2.569.315 1.811 2.203z"
            transform="translate(-736.000000, -369.000000) translate(736.000000, 369.000000)"
          />
          <path
            fill="#00E1D1"
            d="M19 19.637V22h2.363l6.97-6.97-2.363-2.363-6.97 6.97zm11.8-6.883c.266-.266.266-.695 0-.96l-1.593-1.595c-.266-.265-.695-.265-.96 0L27 11.446 29.554 14l1.247-1.246z"
            transform="translate(-736.000000, -369.000000) translate(736.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default DigitalSignnIcon;
