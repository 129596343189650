function range(start, end, step = 1) {
  var indices = [];

  for (var index = start; (end - index) * step >= 0; index += step) {
    indices.push(index);
  }

  return indices;
}

export { range };
