import { useMutation } from "@apollo/client";
import { Grid, makeStyles } from "@material-ui/core";
import { enqueueToastSave, CVButton } from "components";
import { useSnackbar } from "notistack";
import { UPLOAD_LAB } from "queries/setting";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CCDialogSimple, CCTypography } from "styles/components";
import { translate } from "../../../../../../../components";
import UploadImageButton from "../UploadImageButton";
import { Card } from "./components";

const useStyles = makeStyles(theme => ({
  heading__icon: {
    color: theme.palette.disabled,
    alignItems: "center"
  },
  root: { height: "calc(100% - 200px)" },
  container: {
    width: "100%",
    height: "500px",
    overflow: "auto",
    background: "#cfd8dcde",
    alignContent: "flex-start"
  },
  table__container: {
    display: "flex",
    width: "100%",
    padding: "0px 16px"
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "100%",
    height: "474px",
    color: theme.palette.border.main,
    overflow: "hidden"
  },
  panel__heading: {
    padding: "7px 0px 7px 16px"
  },
  secondarytext: {
    color: "#b0bec5",
    fontSize: 14
  },
  cell__text__disabled: { color: theme.palette.disabled },
  cell__selected__row: {
    color: theme.palette.common.white
  },
  cell__select: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: "8px"
  },
  text__direct: {
    width: "100%",
    textAlign: "center"
  },
  cell__input: {
    width: 98,
    backgroundColor: theme.palette.common.white,
    textAlignLast: "left"
  },
  icon: {
    color: theme.palette.sub.main,
    padding: "4px",
    margin: "0px"
  },
  empty__body: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.border.main
  },
  header: {
    borderBottom: "1px solid #b0bec5",
    padding: "32px 16px 8px 16px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  marginbottom8: { marginBottom: 8 },

  positionrelative: {
    position: "relative"
  },
  line: {
    background: "#b0bec5",
    width: " 100%",
    height: 2,
    position: "absolute",
    bottom: 0,
    zIndex: 1
  },
  paddingmiddle: {
    padding: "0 16px "
  },
  boldtitle: {
    fontWeight: "bold",
    padding: "16px 16px 0px 16px"
  }
}));

const useTableStyles = makeStyles(theme => ({
  title: {
    padding: 0
  }
}));

const DialogProsthesisImage = props => {
  const { value, onChange, refetch, open, onClose } = props;

  const classes = useStyles();
  const tableClasses = useTableStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // value
  const [uploadingImage, setUploadingImage] = useState(
    value?.prothesisImages || []
  );

  // graphql
  const [updateLab] = useMutation(UPLOAD_LAB);

  // function
  const handleUpdateLab = data => {
    onChange("prothesisImages", uploadingImage);
    updateLab({
      variables: {
        input: {
          ...value,
          prothesisImages: uploadingImage,
          representative: [value.representative.find(item => item.isChiefDirector)?.id]
        }
      }
    }).then(() => {
      refetch();
      enqueueToastSave(intl, enqueueSnackbar);
      onClose();
    });
  };
  const handleResetListImage = () => {
    setUploadingImage(value?.prothesisImages);
  };
  const handleUploadImage = imageVal => {
    setUploadingImage(data => {
      return data.map(item => (item.id === imageVal.id ? imageVal : item));
    });
  };
  const handleRemoveImage = id => {
    setUploadingImage(prev => {
      // onChange(
      //   "prothesisImages",
      //   prev.filter(item => item.id !== id)
      // );
      return prev.filter(item => item.id !== id);
    });
  };

  return (
    <CCDialogSimple
      maxWidth="sm"
      fullWidth
      classes={tableClasses}
      className={classes.root}
      open={open}
      contentsPadding={false}
      title={
        <Grid container direction="column">
          <CCTypography className={classes.boldtitle}>
            {translate(intl, "common.ui.prosthesisSampleImage")}
          </CCTypography>
          <Grid item>
            <Grid container className={classes.header}>
              <CCTypography variant="h4" className={classes.marginbottom8}>
                {uploadingImage?.length}{" "}
                <span className={classes.secondarytext}>
                  {translate(intl, "common.ui.imagesUploaded")}
                </span>
              </CCTypography>
              <Grid item>
                <UploadImageButton
                  permission="labGeneralSetting"
                  multiple={true}
                  title={translate(intl, "common.ui.upload")}
                  onlyDirect={true}
                  onChange={handleUploadImage}
                  disabled={uploadingImage.length >= 25}
                  handleLoading={id => {
                    if (!!id) {
                      setUploadingImage(prev => [
                        ...prev,
                        { id, loading: true }
                      ]);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      onClose={() => {
        onClose();
        handleResetListImage();
      }}
      contents={
        <Grid className={classes.container} container>
          {uploadingImage?.length > 0 &&
            uploadingImage?.map(item => (
              <Card
                key={item.id}
                type={item.type}
                target={item.target}
                id={item.id}
                loading={item.loading}
                handleRemoveImage={handleRemoveImage}
              />
            ))}
        </Grid>
      }
      endActions={
        <>
          <CVButton
            color="sub"
            onClick={() => {
              onClose();
              handleResetListImage();
            }}
          >
            {translate(intl, "common.ui.cancel")}
          </CVButton>
          <CVButton
            variant="contained"
            onClick={() => {
              handleUpdateLab();
            }}
          >
            {translate(intl, "common.ui.save")}
          </CVButton>
        </>
      }
    />
  );
};

export default DialogProsthesisImage;
