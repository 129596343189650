import React from "react";

const DesknIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="qae4a8un6a" x1="50%" x2="50%" y1="65%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-346.000000, -369.000000) translate(346.000000, 369.000000)"
          />
          <path
            fill="#00E1D1"
            d="M4.067 21.667L8 21.667 24.001 5.667 4.067 5.667z"
            transform="translate(-346.000000, -369.000000) translate(346.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M1.4 19.497v2.17l.005.168c.08 1.277 1.06 2.319 2.326 2.477l.166.016.17.005h6.666V27h10.664v-2.667h6.666l.168-.005c1.274-.08 2.304-1.068 2.463-2.32l.017-.173.005-.168.003-1.777c.183.672.281 1.38.281 2.11 0 4.418-3.582 8-8 8H9c-4.418 0-8-3.582-8-8 0-.874.14-1.715.4-2.503zm26.663 2.17H8L24 5.667h4.062v16zM26 18H16v2h10v-2z"
            transform="translate(-346.000000, -369.000000) translate(346.000000, 369.000000)"
          />
          <path
            fill="url(#qae4a8un6a)"
            d="M28.063 3l.168.005c1.333.083 2.41 1.148 2.494 2.492l.005.17-.014 16-.005.168c-.083 1.333-1.148 2.409-2.48 2.493l-.168.005h-6.666V27H10.733v-2.667H4.067l-.17-.005c-1.345-.084-2.409-1.16-2.492-2.493l-.005-.168v-16l.005-.17c.083-1.344 1.147-2.409 2.492-2.492L4.067 3h23.996zm0 2.667H4.067v16h23.996v-16zM26 18v2H16v-2h10z"
            transform="translate(-346.000000, -369.000000) translate(346.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default DesknIcon;
