/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint react-hooks/exhaustive-deps: 0*/

import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ColorizeIcon from "@material-ui/icons/Colorize";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { ImplantPlacementDialog, PhotoCanvas } from "components";
import FileUploadButton from "components/FileUploadButton";
import { PatientContext } from "context/PatientContext";
import * as html2canvas from "html2canvas";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";
import {
  BrightnessIcon,
  CircleIcon,
  ColorinvertIcon,
  CropIcon,
  DeleteIcon,
  DrawingIcon,
  EraserIcon,
  FlashIcon,
  FontsizeIcon,
  HueIcon,
  ImplantIcon,
  InvisibleIcon,
  MoonstandIcon,
  MoveIcon,
  PathIcon,
  PhotosizeIcon,
  PictureIcon,
  RedoIcon,
  ReflectrightIcon,
  ReflectupIcon,
  ResetIcon,
  SquareIcon,
  StraightenIcon,
  UndoIcon,
  VisibleIcon,
  ZoominIcon,
  ZoomoutIcon
} from "styles/icons";
import {
  CCButton,
  CCColorPicker,
  CCDialogSimple,
  CCTextField
} from "styles/src/components";
import TakeinIcon from "styles/src/themes/common/icons/TakeinIcon";
import useResizeObserver from "use-resize-observer";
// import { getObject, Target } from 'utils/s3';

const toolbarHeight = 48;
const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  divider: {
    alignSelf: "stretch",
    height: "auto",
    margin: theme.spacing(1, 0.5)
  },
  button__color: {
    margin: "4px",
    padding: 0
  },
  icon: {
    color: theme.palette.icon
  },
  disabled: {
    color: theme.palette.disabled
  },
  icon__color: {
    borderRadius: 4
  },
  text__weight: {
    width: "100px",
    margin: "4px"
  },
  text__layout: {
    width: "72px"
  },
  photomenu: {
    width: 160,
    flexDirection: "column"
  },
  photomenu__text: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  photomenu__action__text: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  photoupload: {
    width: 160,
    flexDirection: "column"
  },
  photoupload__text: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  photoupload__action__text: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  photofigure: {
    display: "flex",
    alignItems: "flex-start",
    width: 200,
    maxHeight: 300
  },
  photofigure__list: {
    overflowX: "hidden",
    overflowY: "auto"
  },
  photofigure__item: {
    margin: "6px 6px 6px 6px",
    border: `1px solid ${theme.palette.border.main}`
  },
  buttongroup: {
    display: "flex",
    alignItems: "center",
    margin: "4px 2px 4px 2px",
    border: `1px solid ${theme.palette.border.main}`
  }
}));

const layouts = [
  {
    name: "1x1",
    columns: 1,
    rows: 1,
    segments: {
      columns: [1.0],
      rows: [1.0]
    },
    templates: {
      columns: "100%",
      rows: "100%"
    }
  },
  {
    name: "2x1",
    columns: 2,
    rows: 1,
    segments: {
      columns: [0.5, 0.5],
      rows: [1.0]
    },
    templates: {
      columns: "50% 50%",
      rows: "100%"
    }
  },
  {
    name: "2x2",
    columns: 2,
    rows: 2,
    segments: {
      columns: [0.5, 0.5],
      rows: [0.5, 0.5]
    },
    templates: {
      columns: "50% 50%",
      rows: "50% 50%"
    }
  },
  {
    name: "3x2",
    columns: 3,
    rows: 2,
    segments: {
      columns: [0.33, 0.34, 0.33],
      rows: [0.5, 0.5]
    },
    templates: {
      columns: "33% 34% 33%",
      rows: "50% 50%"
    }
  }
];

const maxlayouts = 16;

const weights = {
  "1pt": 1,
  "2pt": 2,
  "4pt": 4,
  "8pt": 8,
  "16pt": 16
};

const figures = ["/contents/crown.svg", "/contents/crown-gold.svg"];

const filters = {
  brightness: {
    min: -100,
    max: 100,
    def: 0,
    step: 5,
    marks: [
      { value: -100, label: "-100%" },
      { value: 0, label: "0%" },
      { value: 100, label: "100%" }
    ]
  },
  saturation: {
    min: -100,
    max: 100,
    def: 0,
    step: 5,
    marks: [
      { value: -100, label: "-100%" },
      { value: 0, label: "0%" },
      { value: 100, label: "100%" }
    ]
  },
  contrast: {
    min: -100,
    max: 100,
    def: 0,
    step: 5,
    marks: [
      { value: -100, label: "-100%" },
      { value: 0, label: "0%" },
      { value: 100, label: "100%" }
    ]
  },
  huerotate: {
    min: 0,
    max: 360,
    def: 0,
    step: 5,
    marks: [
      { value: 0, label: "0" },
      { value: 180, label: "180" },
      { value: 360, label: "360" }
    ]
  },
  invert: {
    min: 0,
    max: 100,
    def: 0,
    step: 5,
    marks: [
      { value: 0, label: "0%" },
      { value: 100, label: "100%" }
    ]
  }
};

const PhotoMenu = props => {
  const { classes, open, position, commands, onClick, onClose } = props;

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      anchorPosition={{
        top: position.y || 0,
        left: position.x || 0
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      onClose={() => {
        onClose();
      }}
    >
      <Box className={classes.photomenu}>
        <Box>
          <MenuList id="photo-menu">
            {commands.includes("save") && (
              <MenuItem
                key="save"
                onClick={e => {
                  onClick && onClick("save");
                }}
              >
                <CCTypography
                  variant="h5"
                  className={classes.photomenu__action__text}
                >
                  원본 저장
                </CCTypography>
              </MenuItem>
            )}
            {commands.includes("saveas") && (
              <MenuItem
                key="saveas"
                onClick={e => {
                  onClick && onClick("saveas");
                }}
              >
                <CCTypography
                  variant="h5"
                  className={classes.photomenu__action__text}
                >
                  다른 이름으로 저장
                </CCTypography>
              </MenuItem>
            )}
          </MenuList>
        </Box>
      </Box>
    </Popover>
  );
};

const PhotoFigure = props => {
  const { classes, open, anchor, figures, onClose, onClick } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      onClose={() => {
        onClose();
      }}
    >
      <Box className={classes.photofigure}>
        <Grid container className={classes.photofigure__list}>
          {figures.map((figure, index) => (
            <Grid item xs={6} key={index}>
              <Box
                key={index}
                className={classes.photofigure__item}
                style={{
                  width: "85px",
                  height: "85px",
                  backgroundImage: `url(${figure})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover"
                }}
                onClick={() => {
                  onClick(figure);
                }}
              ></Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Popover>
  );
};

const PhotoEditor = forwardRef((props, ref) => {
  const {
    hospitalId,
    patientId,
    patientName,
    readonly,
    photo0,
    modes = "",
    onPhotoSave,
    onPhotoSaveAs,
    onSnapshotSaveAs,
    onSnapshotDownload,
    onChangePhoto
  } = props;
  const classes = useStyles();
  const canvasRefs = useRef(Array(maxlayouts));
  const intl = useIntl();
  const { ref: editorRef, width = 0, height = 0 } = useResizeObserver();
  const { enqueueSnackbar } = useSnackbar();
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [photoUploadAnchor, setPhotoUploadAnchor] = useState(null);
  const [openPhotoMenu, setOpenPhotoMenu] = useState(false);
  const [photoMenuPosition, setPhotoMenuPosition] = useState({});
  const [photoMenuIndex, setPhotoMenuIndex] = useState(0);
  const [openPhotoFigure, setOpenPhotoFigure] = useState(false);
  const [photoFigureAnchor, setPhotoFigureAnchor] = useState(null);
  const [photoFilterTarget, setPhotoFilterTarget] = useState(null);
  const [photoFilterAnchor, setPhotoFilterAnchor] = useState(null);
  const [photoFilterValue, setPhotoFilterValue] = useState(0);
  const [openImplantPlacement, setOpenImplantPlacement] = useState(false);
  const [implantPlacementTarget, setImplantPlacementTarget] = useState(null);
  const [implantPlacementTooth, setImplantPlacementTooth] = useState(null);
  const [implantPlacementIndex, setImplantPlacementIndex] = useState(null);
  const [openPhotoLoading, setOpenPhotoLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentLayout, setCurrentLayout] = useState(0);
  const [currentPlane, setCurrentPlane] = useState(0);
  const [currentColor, setCurrentColor] = useState("255,255,32,1");
  const [currentWeight, setCurrentWeight] = useState("1pt");
  const [currentFigure, setCurrentFigure] = useState(figures[0]);
  const [currentPhotos, setCurrentPhotos] = useState(
    Array(maxlayouts).fill(null)
  );
  const [currentPatient, setCurrentPatient] = useState(null);
  const [currentVisible, setCurrentVisible] = useState(true);
  const [currentFilters, setCurrentFilters] = useState({
    brightness: filters.brightness.def,
    saturation: filters.saturation.def,
    contrast: filters.contrast.def,
    huerotate: filters.huerotate.def,
    invert: filters.invert.def
  });
  const { patientContext } = useContext(PatientContext);

  const fetchObjectUrl = async photo => {
    // if (photo?.content?.object) {
    //   const url = await getObject(
    //     Target.PHOTO,
    //     hospitalId,
    //     photo.content.object,
    //   );
    //   photo.content.source = url;
    //   photo.content.thumbnail = url;
    // }

    return photo;
  };

  useEffect(() => {
    if (patientId !== currentPatient && !photo0) {
      setCurrentPhotos(Array(maxlayouts).fill(null));
    }

    setCurrentPatient(patientId);
  }, [patientId]);

  useEffect(() => {
    if (photo0) {
      fetchObjectUrl(photo0).then(photo => {
        setCurrentPhotos([photo]);
      });
    }
  }, [photo0]);

  useImperativeHandle(ref, () => ({
    selectPhoto(photo) {
      const index0 = currentPhotos.indexOf(null);
      const index1 = currentPhotos.indexOf(photo);

      if (
        index1 >= 0 &&
        index1 < layouts[currentLayout].columns * layouts[currentLayout].rows
      ) {
        var temp = currentPhotos;
        temp[index1] = null;
        setCurrentPhotos([...temp]);
      } else if (
        index0 >= 0 &&
        index0 < layouts[currentLayout].columns * layouts[currentLayout].rows
      ) {
        fetchObjectUrl(photo).then(photo => {
          var temp = currentPhotos;
          temp[index0] = photo;
          setCurrentPhotos([...temp]);
        });
      } else {
        fetchObjectUrl(photo).then(photo => {
          var temp = currentPhotos;
          temp[0] = photo;
          setCurrentPhotos([...temp]);
        });
      }
    },
    deletePhoto(photo) {
      const index = currentPhotos.indexOf(photo);

      if (index >= 0) {
        var temp = currentPhotos;
        temp[index] = null;
        setCurrentPhotos([...temp]);
      }
    },
    dispatchExport(index) {
      return canvasRefs.current[index].dispatchExport();
    },
    dispatchRegion(index) {
      return canvasRefs.current[index].dispatchRegion();
    }
  }));

  return (
    <Box className={classes.root}>
      {!readonly ? (
        <Box
          style={{
            height: toolbarHeight,
            display: "flex",
            padding: "0px 8px",
            justifyContent: "space-between",
            borderBottom: "1px solid #b0bec5"
          }}
          alignItems="center"
        >
          <Box style={{ display: "inline-flex" }}>
            <Tooltip title="이전">
              <IconButton
                size="small"
                onClick={() => {
                  canvasRefs.current[currentPlane].dispatchUndo();
                }}
                variant="text"
              >
                <UndoIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="되돌리기">
              <IconButton
                size="small"
                onClick={() => {
                  canvasRefs.current[currentPlane].dispatchRedo();
                }}
                variant="text"
              >
                <RedoIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="삭제">
              <IconButton
                size="small"
                onClick={() => {
                  canvasRefs.current[currentPlane].deactivateAction(
                    currentAction
                  );
                  canvasRefs.current[currentPlane].activateAction("delete");
                  setCurrentAction("delete");
                }}
                variant="text"
              >
                <EraserIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="전체삭제">
              <IconButton
                size="small"
                onClick={() => {
                  canvasRefs.current[currentPlane].dispatchClear();
                }}
                variant="text"
              >
                <DeleteIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Divider
              orientation="vertical"
              className={classes.divider}
            ></Divider>
            <CCButton
              className={classes.button__color}
              variant="outlined"
              margin="dense"
              onClick={() => setOpenColorPicker(true)}
            >
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                width={24}
                height={24}
                viewBox={"0 0 24 24"}
                className={clsx(classes.icon__color)}
              >
                <rect width={24} height={24} fill={`rgba(${currentColor})`} />
              </svg>
              <ColorizeIcon className={classes.icon} />
            </CCButton>
            <CCTextField
              className={classes.text__weight}
              startIcon={
                <Box style={{ margin: 0 }}>
                  <svg
                    xmlns={"http://www.w3.org/2000/svg"}
                    width={24}
                    height={24}
                    viewBox={"0 0 24 24"}
                    className={clsx(classes.icon__color)}
                  >
                    <line
                      x1="0"
                      y1="12"
                      x2="24"
                      y2="12"
                      stroke={`rgba(${currentColor})`}
                      strokeWidth={currentWeight}
                    />
                  </svg>
                </Box>
              }
              value={currentWeight}
              onChange={event => {
                setCurrentWeight(event.target.value);
              }}
              variant="outlined"
              margin="dense"
              select
            >
              {Object.keys(weights).map(weight => (
                <MenuItem key={weight} value={weight}>
                  <ListItemText primary={weight}></ListItemText>
                  <ListItemIcon>
                    <Box style={{ marginLeft: "8px", alignItems: "center" }}>
                      <svg
                        xmlns={"http://www.w3.org/2000/svg"}
                        width={82}
                        height={24}
                        viewBox={"0 0 82 24"}
                        className={clsx(classes.icon__color)}
                      >
                        <line
                          x1="0"
                          y1="12"
                          x2="82"
                          y2="12"
                          stroke={`rgba(0, 0, 0, 1)`}
                          strokeWidth={weight}
                        />
                      </svg>
                    </Box>
                  </ListItemIcon>
                </MenuItem>
              ))}
            </CCTextField>
            <Divider
              orientation="vertical"
              className={classes.divider}
            ></Divider>
            <Paper className={classes.buttongroup}>
              <Tooltip title="패스">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("path");
                    setCurrentAction("path");
                  }}
                  variant="text"
                >
                  <DrawingIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="선">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("line");
                    setCurrentAction("line");
                  }}
                  variant="text"
                >
                  <PathIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="원">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("circle");
                    setCurrentAction("circle");
                  }}
                  variant="text"
                >
                  <CircleIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="사각형">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("box");
                    setCurrentAction("box");
                  }}
                  variant="text"
                >
                  <SquareIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="텍스트">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("text");
                    setCurrentAction("text");
                  }}
                  variant="text"
                >
                  <FontsizeIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </Paper>
            <Divider
              orientation="vertical"
              className={classes.divider}
            ></Divider>
            <Paper className={classes.buttongroup}>
              <Tooltip title="이미지클립">
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFigureAnchor(e.target);
                    setOpenPhotoFigure(true);
                  }}
                  variant="text"
                >
                  <PictureIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="임플란트툴">
                <IconButton
                  size="small"
                  onClick={e => {
                    setOpenImplantPlacement(true);
                  }}
                  variant="text"
                >
                  <ImplantIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="자">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("ruler");
                    setCurrentAction("ruler");
                  }}
                  variant="text"
                >
                  <StraightenIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </Paper>
            <Divider
              orientation="vertical"
              className={classes.divider}
            ></Divider>
            <Paper className={classes.buttongroup}>
              <Tooltip title="잘라내기">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("crop");
                    setCurrentAction("crop");
                  }}
                  variant="text"
                >
                  <CropIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </Paper>
            <Divider
              orientation="vertical"
              className={classes.divider}
            ></Divider>
            <Paper className={classes.buttongroup}>
              <Tooltip title="초기화">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchReset();
                  }}
                  variant="text"
                >
                  <ResetIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Divider
                orientation="vertical"
                className={classes.divider}
              ></Divider>
              <Tooltip title="이동">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("move");
                    setCurrentAction("move");
                  }}
                  variant="text"
                >
                  <MoveIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="상하반전">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchScale(1.0, -1.0);
                  }}
                  variant="text"
                >
                  <ReflectupIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="좌우반전">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchScale(-1.0, 1.0);
                  }}
                  variant="text"
                >
                  <ReflectrightIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="확대">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchScale(1.25, 1.25);
                  }}
                  variant="text"
                >
                  <ZoominIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="축소">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchScale(0.8, 0.8);
                  }}
                  variant="text"
                >
                  <ZoomoutIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </Paper>
            <Divider
              orientation="vertical"
              className={classes.divider}
            ></Divider>
            <Paper className={classes.buttongroup}>
              <Tooltip title="초기화">
                <IconButton
                  size="small"
                  onClick={() => {
                    const temp = {
                      brightness: filters.brightness.def,
                      saturation: filters.saturation.def,
                      contrast: filters.contrast.def,
                      huerotate: filters.huerotate.def,
                      invert: filters.invert.def
                    };

                    for (
                      var plane = 0;
                      plane <
                      layouts[currentLayout].columns *
                        layouts[currentLayout].rows;
                      plane++
                    ) {
                      canvasRefs.current[plane].dispatchFilter(temp);
                    }

                    setCurrentFilters(temp);
                  }}
                  variant="text"
                >
                  <ResetIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Divider
                orientation="vertical"
                className={classes.divider}
              ></Divider>
              <Tooltip title={"명도: " + currentFilters["brightness"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("brightness");
                    setPhotoFilterValue(currentFilters["brightness"]);
                  }}
                  variant="text"
                >
                  <BrightnessIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"채도: " + currentFilters["saturation"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("saturation");
                    setPhotoFilterValue(currentFilters["saturation"]);
                  }}
                  variant="text"
                >
                  <FlashIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"대조: " + currentFilters["contrast"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("contrast");
                    setPhotoFilterValue(currentFilters["contrast"]);
                  }}
                  variant="text"
                >
                  <MoonstandIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"색조: " + currentFilters["huerotate"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("huerotate");
                    setPhotoFilterValue(currentFilters["huerotate"]);
                  }}
                  variant="text"
                >
                  <HueIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"반전: " + currentFilters["invert"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("invert");
                    setPhotoFilterValue(currentFilters["invert"]);
                  }}
                  variant="text"
                >
                  <ColorinvertIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </Paper>
          </Box>
          <Box style={{ display: "inline-flex" }}>
            {modes.includes("dialog") ? (
              <>
                <Tooltip title="원본보기">
                  <IconButton
                    size="small"
                    onClick={() => {
                      for (
                        var plane = 0;
                        plane <
                        layouts[currentLayout].columns *
                          layouts[currentLayout].rows;
                        plane++
                      ) {
                        canvasRefs.current[plane].dispatchVisible(
                          !currentVisible
                        );
                      }

                      setCurrentVisible(!currentVisible);
                    }}
                    variant="text"
                  >
                    {currentVisible ? (
                      <InvisibleIcon className={classes.icon} />
                    ) : (
                      <VisibleIcon className={classes.icon} />
                    )}
                  </IconButton>
                </Tooltip>
                <Divider orientation="vertical" className={classes.divider} />
                <FileUploadButton
                  onChange={photo => onChangePhoto(photo)}
                  patient={{ id: patientContext?.id, value: patientContext }}
                  title={"이미지 가져오기"}
                  startIcon={<TakeinIcon />}
                  target={true}
                  // target={Target.PHOTO}
                  filetype={"image"}
                  accept={["image/jpg", "image/jpeg", "image/png"]}
                />
              </>
            ) : (
              <>
                <CCTextField
                  className={classes.text__layout}
                  value={currentLayout}
                  onChange={event => {
                    setCurrentLayout(event.target.value);
                  }}
                  label="분할"
                  variant="outlined"
                  margin="dense"
                  select
                >
                  {layouts.map((layout, index) => (
                    <MenuItem key={layout.name} value={index}>
                      {`${layout.columns * layout.rows} 면`}
                    </MenuItem>
                  ))}
                </CCTextField>
                <Divider orientation="vertical" className={classes.divider} />
                <Tooltip title="원본보기">
                  <IconButton
                    size="small"
                    onClick={() => {
                      for (
                        var plane = 0;
                        plane <
                        layouts[currentLayout].columns *
                          layouts[currentLayout].rows;
                        plane++
                      ) {
                        canvasRefs.current[plane].dispatchVisible(
                          !currentVisible
                        );
                      }

                      setCurrentVisible(!currentVisible);
                    }}
                    variant="text"
                  >
                    {currentVisible ? (
                      <InvisibleIcon className={classes.icon} />
                    ) : (
                      <VisibleIcon className={classes.icon} />
                    )}
                  </IconButton>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  className={classes.divider}
                ></Divider>
                <Tooltip title="스크린샷">
                  <IconButton
                    size="small"
                    onClick={e => {
                      setPhotoUploadAnchor(e.currentTarget);
                    }}
                    variant="text"
                  >
                    <PhotosizeIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            height: toolbarHeight,
            display: "flex",
            padding: "0px 8px",
            justifyContent: "space-between",
            borderBottom: "1px solid #b0bec5"
          }}
          alignItems="center"
        >
          <Box style={{ display: "inline-flex" }}>
            <Paper className={classes.buttongroup}>
              <Tooltip title="초기화">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchReset();
                  }}
                  variant="text"
                >
                  <ResetIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Divider
                orientation="vertical"
                className={classes.divider}
              ></Divider>
              <Tooltip title="이동">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].deactivateAction(
                      currentAction
                    );
                    canvasRefs.current[currentPlane].activateAction("move");
                    setCurrentAction("move");
                  }}
                  variant="text"
                >
                  <MoveIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="상하반전">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchScale(1.0, -1.0);
                  }}
                  variant="text"
                >
                  <ReflectupIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="좌우반전">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchScale(-1.0, 1.0);
                  }}
                  variant="text"
                >
                  <ReflectrightIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="확대">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchScale(1.25, 1.25);
                  }}
                  variant="text"
                >
                  <ZoominIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="축소">
                <IconButton
                  size="small"
                  onClick={() => {
                    canvasRefs.current[currentPlane].dispatchScale(0.8, 0.8);
                  }}
                  variant="text"
                >
                  <ZoomoutIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </Paper>
            <Divider
              orientation="vertical"
              className={classes.divider}
            ></Divider>
            <Paper className={classes.buttongroup}>
              <Tooltip title="초기화">
                <IconButton
                  size="small"
                  onClick={() => {
                    const temp = {
                      brightness: filters.brightness.def,
                      saturation: filters.saturation.def,
                      contrast: filters.contrast.def,
                      huerotate: filters.huerotate.def,
                      invert: filters.invert.def
                    };

                    for (
                      var plane = 0;
                      plane <
                      layouts[currentLayout].columns *
                        layouts[currentLayout].rows;
                      plane++
                    ) {
                      canvasRefs.current[plane].dispatchFilter(temp);
                    }

                    setCurrentFilters(temp);
                  }}
                  variant="text"
                >
                  <ResetIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Divider
                orientation="vertical"
                className={classes.divider}
              ></Divider>
              <Tooltip title={"명도: " + currentFilters["brightness"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("brightness");
                    setPhotoFilterValue(currentFilters["brightness"]);
                  }}
                  variant="text"
                >
                  <BrightnessIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"채도: " + currentFilters["saturation"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("saturation");
                    setPhotoFilterValue(currentFilters["saturation"]);
                  }}
                  variant="text"
                >
                  <FlashIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"대조: " + currentFilters["contrast"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("contrast");
                    setPhotoFilterValue(currentFilters["contrast"]);
                  }}
                  variant="text"
                >
                  <MoonstandIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"색조: " + currentFilters["huerotate"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("huerotate");
                    setPhotoFilterValue(currentFilters["huerotate"]);
                  }}
                  variant="text"
                >
                  <HueIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"반전: " + currentFilters["invert"]}>
                <IconButton
                  size="small"
                  onClick={e => {
                    setPhotoFilterAnchor(e.target);
                    setPhotoFilterTarget("invert");
                    setPhotoFilterValue(currentFilters["invert"]);
                  }}
                  variant="text"
                >
                  <ColorinvertIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </Paper>
          </Box>
          <Box style={{ display: "inline-flex" }}>
            {modes.includes("dialog") ? (
              <>
                <Tooltip title="원본보기">
                  <IconButton
                    size="small"
                    onClick={() => {
                      for (
                        var plane = 0;
                        plane <
                        layouts[currentLayout].columns *
                          layouts[currentLayout].rows;
                        plane++
                      ) {
                        canvasRefs.current[plane].dispatchVisible(
                          !currentVisible
                        );
                      }

                      setCurrentVisible(!currentVisible);
                    }}
                    variant="text"
                  >
                    {currentVisible ? (
                      <InvisibleIcon className={classes.icon} />
                    ) : (
                      <VisibleIcon className={classes.icon} />
                    )}
                  </IconButton>
                </Tooltip>
                <Divider orientation="vertical" className={classes.divider} />
                <FileUploadButton
                  onChange={photo => onChangePhoto(photo)}
                  patient={{ id: patientContext?.id, value: patientContext }}
                  title={"이미지 가져오기"}
                  startIcon={<TakeinIcon />}
                  // target={Target.PHOTO}
                  target={true}
                  filetype={"image"}
                  accept={["image/jpg", "image/jpeg", "image/png"]}
                />
              </>
            ) : (
              <>
                <CCTextField
                  className={classes.text__layout}
                  value={currentLayout}
                  onChange={event => {
                    setCurrentLayout(event.target.value);
                  }}
                  label="분할"
                  variant="outlined"
                  margin="dense"
                  select
                >
                  {layouts.map((layout, index) => (
                    <MenuItem key={layout.name} value={index}>
                      {`${layout.columns * layout.rows} 면`}
                    </MenuItem>
                  ))}
                </CCTextField>
                <Divider orientation="vertical" className={classes.divider} />
                <Tooltip title="원본보기">
                  <IconButton
                    size="small"
                    onClick={() => {
                      for (
                        var plane = 0;
                        plane <
                        layouts[currentLayout].columns *
                          layouts[currentLayout].rows;
                        plane++
                      ) {
                        canvasRefs.current[plane].dispatchVisible(
                          !currentVisible
                        );
                      }

                      setCurrentVisible(!currentVisible);
                    }}
                    variant="text"
                  >
                    {currentVisible ? (
                      <InvisibleIcon className={classes.icon} />
                    ) : (
                      <VisibleIcon className={classes.icon} />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      )}
      <Box
        ref={editorRef}
        style={{
          height: `calc(100% - ${toolbarHeight}px)`
        }}
      >
        {width > 0 && height > 0 ? (
          <Box
            width="100%"
            height="100%"
            style={{
              display: "grid",
              gridTemplateColumns: layouts[currentLayout].templates.columns,
              gridTemplateRows: layouts[currentLayout].templates.rows
            }}
          >
            {[
              ...Array(
                layouts[currentLayout].columns * layouts[currentLayout].rows
              ).keys()
            ].map((_, plane) => {
              const column = plane % layouts[currentLayout].columns;
              const row = Math.floor(plane / layouts[currentLayout].columns);
              const segments = layouts[currentLayout].segments;

              return (
                <PhotoCanvas
                  ref={ref => {
                    canvasRefs.current[plane] = ref;
                  }}
                  key={plane}
                  width={width * segments.columns[column]}
                  height={height * segments.rows[row]}
                  layout={plane}
                  target={currentPhotos[plane] ? currentPhotos[plane].id : null}
                  source={
                    currentPhotos[plane]
                      ? currentPhotos[plane].content.source
                      : null
                  }
                  content={
                    currentPhotos[plane]
                      ? currentPhotos[plane].content.content
                      : null
                  }
                  region={
                    currentPhotos[plane]
                      ? JSON.parse(currentPhotos[plane].content.region)
                      : null
                  }
                  detail={
                    currentPhotos[plane]
                      ? JSON.parse(currentPhotos[plane].content.detail)
                      : null
                  }
                  modes={modes}
                  menus={
                    !readonly
                      ? ["subtract", "upload", "visible"]
                      : ["subtract", "visible"]
                  }
                  action={currentAction}
                  styles={{
                    color: `rgba(${currentColor})`,
                    weight: weights[currentWeight],
                    figure: currentFigure
                  }}
                  onDrop={(target, source) => {
                    setCurrentPhotos([
                      ...currentPhotos.map((photo, index) =>
                        index === target ? source : photo
                      )
                    ]);
                  }}
                  onPhotoAction={(target, type, point) => {
                    if (type === "visible") {
                      canvasRefs.current[plane].dispatchVisible(null);
                    } else if (type === "upload") {
                      setPhotoMenuPosition(point);
                      setOpenPhotoMenu(true);
                    } else if (type === "subtract") {
                      setCurrentPhotos([
                        ...currentPhotos.map((image, index) =>
                          index === target ? null : image
                        )
                      ]);
                    }
                  }}
                  onPhotoLoading={() => {
                    setOpenPhotoLoading(true);
                  }}
                  onPhotoLoaded={() => {
                    setOpenPhotoLoading(false);
                  }}
                  onImplantEdit={(target, tooth, implant) => {
                    setImplantPlacementTarget(target);
                    setImplantPlacementTooth(tooth);
                    setImplantPlacementIndex(implant);
                    setOpenImplantPlacement(true);
                  }}
                  onMouseDown={e => {}}
                  onMouseUp={e => {
                    setCurrentPlane(plane);
                  }}
                ></PhotoCanvas>
              );
            })}
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
      {openColorPicker && (
        <Box>
          <CCColorPicker
            open={openColorPicker}
            onChange={e => {
              setCurrentColor(`${e.r},${e.g},${e.b},${e.a}`);
            }}
            onClose={() => {
              setOpenColorPicker(false);
            }}
          />
        </Box>
      )}
      {Boolean(photoUploadAnchor) && (
        <Box>
          <Popover
            open={Boolean(photoUploadAnchor)}
            anchorEl={photoUploadAnchor}
            onClose={() => {
              setPhotoUploadAnchor(null);
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <Box className={classes.photoupload}>
              <Box>
                <MenuList id="photo-uploads">
                  {["saveas", "download"].map((upload, index) => (
                    <MenuItem
                      key={upload}
                      onClick={e => {
                        if (upload === "saveas") {
                          html2canvas(editorRef.current).then(canvas => {
                            onSnapshotSaveAs && onSnapshotSaveAs(canvas);
                          });
                        } else if (upload === "download") {
                          html2canvas(editorRef.current).then(canvas => {
                            onSnapshotDownload &&
                              onSnapshotDownload(
                                moment().format("YYYYMMDD") +
                                  "." +
                                  patientName +
                                  ".png",
                                canvas
                              );
                          });
                        }
                        setPhotoUploadAnchor(null);
                      }}
                    >
                      <CCTypography
                        variant="h5"
                        className={classes.photoupload__action__text}
                      >
                        {intl.formatMessage({ id: `photoActions.${upload}` })}
                      </CCTypography>
                    </MenuItem>
                  ))}
                </MenuList>
              </Box>
            </Box>
          </Popover>
        </Box>
      )}
      {openPhotoMenu && currentPhotos[currentPlane] && (
        <Box>
          <PhotoMenu
            classes={classes}
            open={openPhotoMenu}
            position={photoMenuPosition}
            commands={
              ["panorama", "pa", "cephalo"].includes(
                currentPhotos[currentPlane].content.filetype
              )
                ? ["saveas"]
                : ["save", "saveas"]
            }
            onClick={target => {
              if (target === "save") {
                const data = canvasRefs.current[currentPlane].dispatchExport();
                const region =
                  canvasRefs.current[currentPlane].dispatchRegion();

                if (data || region) {
                  const photo = currentPhotos[currentPlane];
                  if (photo) {
                    onPhotoSave && onPhotoSave(photo, data, region);
                  }
                }
              } else if (target === "saveas") {
                try {
                  const data =
                    canvasRefs.current[currentPlane].dispatchExport();
                  const region =
                    canvasRefs.current[currentPlane].dispatchRegion();

                  if (data || region) {
                    const photo = currentPhotos[currentPlane];
                    if (photo) {
                      onPhotoSaveAs && onPhotoSaveAs(photo, data, region);
                    }
                  }
                } catch (e) {}
              }

              setOpenPhotoMenu(false);
            }}
            onClose={() => {
              setOpenPhotoMenu(false);
            }}
          />
        </Box>
      )}
      {openPhotoFigure && (
        <Box>
          <PhotoFigure
            classes={classes}
            open={openPhotoFigure}
            anchor={photoFigureAnchor}
            figures={figures}
            onClose={() => {
              setOpenPhotoFigure(false);
            }}
            onClick={figure => {
              setCurrentFigure(figure);

              canvasRefs.current[currentPlane].deactivateAction(currentAction);
              canvasRefs.current[currentPlane].activateAction("figure");
              setCurrentAction("figure");

              setOpenPhotoFigure(false);
            }}
          ></PhotoFigure>
        </Box>
      )}
      {Boolean(photoFilterAnchor) && (
        <Box>
          <Popover
            open={Boolean(photoFilterAnchor)}
            anchorEl={photoFilterAnchor}
            onClose={() => {
              setPhotoFilterAnchor(null);
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <Box style={{ width: 240, margin: "12px 24px 8px 24px" }}>
              <Typography id="range-slider" variant="h6" gutterBottom>
                {photoFilterTarget &&
                  intl.formatMessage({
                    id: `photoFilters.${photoFilterTarget}`
                  })}
              </Typography>
              <Slider
                value={photoFilterValue}
                getAriaValueText={value => `${value}%`}
                aria-labelledby="discrete-slider-restrict"
                valueLabelDisplay="auto"
                min={photoFilterTarget && filters[photoFilterTarget].min}
                max={photoFilterTarget && filters[photoFilterTarget].max}
                step={photoFilterTarget && filters[photoFilterTarget].step}
                marks={photoFilterTarget && filters[photoFilterTarget].marks}
                onChange={(e, v) => {
                  const temp = {
                    ...currentFilters,
                    [photoFilterTarget]: v
                  };

                  for (
                    var plane = 0;
                    plane <
                    layouts[currentLayout].columns *
                      layouts[currentLayout].rows;
                    plane++
                  ) {
                    canvasRefs.current[plane].dispatchFilter(temp);
                  }

                  setPhotoFilterValue(v);
                  setCurrentFilters(temp);
                }}
              />
            </Box>
          </Popover>
        </Box>
      )}
      {openImplantPlacement && (
        <Box>
          <ImplantPlacementDialog
            open={openImplantPlacement}
            tooth={implantPlacementTooth}
            implant={implantPlacementIndex}
            onSelect={(tooth, implant, crown, tooltip, flip) => {
              canvasRefs.current[currentPlane].dispatchImplant(
                implantPlacementTarget,
                tooth,
                implant,
                crown,
                tooltip,
                flip
              );
            }}
            onClose={() => {
              setImplantPlacementTarget(null);
              setImplantPlacementTooth(null);
              setImplantPlacementIndex(null);
              setOpenImplantPlacement(false);
            }}
          ></ImplantPlacementDialog>
        </Box>
      )}
      {openPhotoLoading && (
        <Box>
          <CCDialogSimple
            open={openPhotoLoading}
            maxWidth="md"
            title="이미지 로딩.."
            onClose={() => {
              setOpenPhotoLoading(false);
            }}
            contents={
              <Box
                display="flex"
                width="100%"
                height="100%"
                flexDirection="column"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            }
            contentsPadding={true}
            endActions={
              <>
                <CCButton
                  color="sub"
                  onClick={() => {
                    setOpenPhotoLoading(false);
                  }}
                >
                  취소
                </CCButton>
              </>
            }
          />
        </Box>
      )}
    </Box>
  );
});

export default PhotoEditor;
