import { Assignment } from "@material-ui/icons";
import { Main as MainLayout, Minimal as MiniLayout } from "layouts";
import React from "react";
import {
  BarcodenIcon,
  CalculationIcon,
  CommitmentIcon,
  GoldIcon,
  ReservationIcon,
  SettingIcon,
  StatisticsIcon
} from "styles/icons";
import OrderCalendar from "./Calendar";
import DentalLab from "./DentalLab";
import GoldManagement from "./GoldManagement";
import Payment from "./Payment";
import Setting from "./Setting";
import SignIn from "./SignIn";
import Statistics from "./Statistics";
import InventoryMain from "./InventoryManagement/InventoryMain";

const pages = [
  {
    path: "/orders",
    title: "orders",
    icon: <CommitmentIcon />,
    component: DentalLab,
    layout: MainLayout,
    display: true
  },
  {
    path: "/calendar",
    title: "calendar",
    icon: <ReservationIcon />,
    component: OrderCalendar,
    layout: MainLayout,
    display: true
  },
  {
    path: "/payment",
    title: "menuPayment",
    icon: <CalculationIcon />,
    component: Payment,
    layout: MainLayout,
    display: true
  },
  {
    path: "/statistics",
    title: "statistics",
    icon: <StatisticsIcon />,
    component: Statistics,
    layout: MainLayout,
    display: true
  },
  {
    path: "/inventory",
    title: "inventory",
    icon: <BarcodenIcon />,
    component: InventoryMain,
    layout: MainLayout,
    display: true
  },
  {
    path: "/setting",
    title: "setting",
    icon: <SettingIcon />,
    layout: MainLayout,
    display: true,
    component: props => {
      const init = (() => {
        if (window.variables?.category) {
          return window.variables.category;
        }
        return "common.ui.smtp";
      })();
      return <Setting init={init} />;
    }
  },
  {
    path: "/signin",
    title: "signin",
    icon: <Assignment />,

    layout: MiniLayout,
    component: SignIn
  }
].map(el => {
  el.mode = "normal";
  return el;
});
export { pages };
