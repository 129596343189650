import { useMutation, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { DialogResultBlank, translate, CVButton } from "components";
import {
  GET_CONFIG_PRICING,
  UPDATE_PROSTHESIS_PRICE_SETTING
} from "queries/setting";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogTitleAddMod as AddDialog,
  CCDialogTitleAddMod as ModDialog
} from "styles/components";
import CCDialogSimple from "styles/src/components/CCDialogSimple";
import CheckIcon from "styles/src/themes/common/icons/CheckIcon";
import { uuid } from "uuidv4";
import { ENTRY_LIMIT } from "../../../../../../../types/constants";
import CategoriesTable from "../CategoriesTable";

const useStyles = makeStyles(theme => ({
  root: {}
}));

const CrownListDialog = props => {
  const { open, onClose, currentClinic } = props;

  // classes
  const classes = useStyles();
  const intl = useIntl();

  // value
  const [contents, setContents] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [addDialog, setAddDialog] = useState({ status: false, index: null });
  const [modDialog, setModDialog] = useState({ status: false, index: null });
  const [resultDialog, setResultDialog] = useState(false);

  // graphql
  const { data, refetch } = useQuery(GET_CONFIG_PRICING, {
    variables: {
      category: "prosthesisPriceSetting",
      labId: localStorage.getItem("labId")
    },
    fetchPolicy: "network-only"
  });
  const [createProsthesisPriceSetting] = useMutation(
    UPDATE_PROSTHESIS_PRICE_SETTING,
    {
      onCompleted: () => {
        refetch();
      }
    }
  );

  // useEffect
  useEffect(() => {
    if (data && data.getConfig) {
      const prosthesisPriceSetting = data.getConfig.prosthesisPriceSetting;
      if (prosthesisPriceSetting) {
        prosthesisPriceSetting.forEach(setting => {
          if (!setting.items) {
            return;
          } else {
            const clinicPrice = setting.items.find(item => {
              return item.partnerId === currentClinic;
            });
            if (clinicPrice) {
              const clinicCategories = clinicPrice.item.contents.map(
                content => {
                  return { ...content, category: content.category };
                }
              );
              setContents(clinicCategories);
            }
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [data, currentClinic]);
  let cnt = 0;
  useEffect(() => {
    if (filter && !cnt) {
      setResultDialog(true);
    }
  }, [filter, cnt]);

  return (
    <>
      <CCDialogSimple
        open={open}
        contentsPadding={false}
        onClose={onClose}
        onCloseButton={onClose}
        className={clsx(classes.root)}
        title={translate(intl, "settings.productPrice.categories")}
        contents={
          <Box style={{ height: 600 }}>
            <CategoriesTable
              heads={[
                {
                  label: translate(intl, "settings.productPrice.categories"),
                  key: "category"
                }
              ]}
              contents={contents}
              onFilter={rowData => {
                if (rowData.category.indexOf(filter) === -1) return false;
                cnt += 1;
                return true;
              }}
              onSearch={word => {
                setFilter(word);
              }}
              onAddClick={() => {
                setAddDialog({ status: true });
              }}
              onModClick={({ rowIndex }) => {
                setModDialog({ status: true, index: rowIndex });
              }}
              onDelClick={({ rowIndex }) => {
                const tmp = contents.filter((el, index) => {
                  return index !== rowIndex;
                });
                setContents(tmp);
              }}
              onDownClick={() => {
                if (selectedIndex === -1) return;
                if (selectedIndex === contents.length - 1) return;
                const newData = [...contents];
                const [deleteItem] = newData.splice(selectedIndex, 1);
                newData.splice(selectedIndex + 1, 0, deleteItem);
                setContents(newData);
                setSelectedIndex(selectedIndex + 1);
              }}
              onUpClick={() => {
                if (selectedIndex === -1) return;
                if (selectedIndex === 0) return;
                const newData = [...contents];
                const [deleteItem] = newData.splice(selectedIndex, 1);
                newData.splice(selectedIndex - 1, 0, deleteItem);
                setContents(newData);
                setSelectedIndex(selectedIndex - 1);
              }}
              onRowClick={({ rowIndex }) => {
                setSelectedIndex(rowIndex);
              }}
              selectedRow={selectedIndex}
            />
          </Box>
        }
        endActions={
          <>
            <CVButton
              onClick={() => {
                onClose();
              }}
            >
              {translate(intl, "common.ui.cancel")}
            </CVButton>
            <CVButton
              variant="contained"
              onClick={() => {
                createProsthesisPriceSetting({
                  variables: {
                    labId: localStorage.getItem("labId"),
                    category: "prosthesisPriceSetting",
                    prosthesisPriceSetting: data.getConfig.prosthesisPriceSetting.map(
                      item => ({
                        ...item,
                        items: item.items.map(data => {
                          if (data.partnerId !== currentClinic) {
                            return data;
                          }
                          return {
                            ...data,
                            item: { contents: contents }
                          };
                        })
                      })
                    )
                  }
                }).then(() => {
                  // refetch()
                });
                onClose();
              }}
              startIcon={<CheckIcon />}
            >
              {translate(intl, "common.ui.save")}
            </CVButton>
          </>
        }
      />
      <AddDialog
        title={translate(intl, "common.ui.category")}
        open={addDialog.status}
        onClose={() => {
          setAddDialog({ status: false });
        }}
        onSave={newValue => {
          setContents([
            ...contents,
            {
              category: newValue,
              id: uuid(),
              tabContents: []
            }
          ]);
          setAddDialog({ status: false });
        }}
        onCancel={() => {
          setAddDialog({ status: false });
        }}
        maxLength={ENTRY_LIMIT.NUMBER_FIELD}
      />
      <ModDialog
        title={translate(intl, "common.ui.edit")}
        open={modDialog.status}
        onClose={() => {
          setModDialog({ status: false });
        }}
        value={contents[modDialog.index] && contents[modDialog.index].category}
        onSave={newValue => {
          setContents(
            contents.map((el, index) => {
              if (modDialog.index === index)
                return {
                  ...el,
                  category: newValue
                };
              return el;
            })
          );
          setModDialog({ status: false });
        }}
        onCancel={() => {
          setModDialog({ status: false });
        }}
        maxLength={ENTRY_LIMIT.NUMBER_FIELD}
      />
      <DialogResultBlank
        open={resultDialog}
        onClose={() => {
          setResultDialog(false);
        }}
      />
    </>
  );
};

CrownListDialog.defaultProps = {};

CrownListDialog.propTypes = {};

export default CrownListDialog;
