import React from "react";

const AnnounceIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="m17.434 9.178.517 1.932 3.864-1.035-.518-1.932-3.863 1.035zm-.221 6.903c1.11.437 2.561 1.021 3.71 1.48.248-.616.495-1.24.744-1.856-1.148-.459-2.598-1.043-3.712-1.49-.247.625-.493 1.25-.742 1.866zm1.141-12.74c-.523-.408-1.05-.827-1.573-1.235-.765.971-1.729 2.203-2.47 3.146.524.409 1.05.827 1.573 1.235.741-.944 1.708-2.165 2.47-3.146zM3.394 10.87a2.006 2.006 0 0 0-1.415 2.45l.517 1.931a2.006 2.006 0 0 0 2.45 1.414l.966-.259 1.035 3.864 1.932-.517-1.035-3.864.966-.259 5.606 1.604L11.31 5.643 7.257 9.834 3.393 10.87zm11.884-.079a4.48 4.48 0 0 0-2.316-2.848l1.731 6.462c.68-1.02.929-2.33.585-3.614z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default AnnounceIcon;
