import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CCTypography } from 'styles/src/components';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingLeft: (props) => (props.left ? 8 : 0),
  },
}));

const DiseasesItem = (props) => {
  const { diseases = [], left = true } = props;
  const classes = useStyle({ left: left });
  return (
    <Grid
      container
      wrap={'nowrap'}
      alignItems={'center'}
      className={classes.root}
    >
      <CCTypography variant={'body1'}>
        {diseases[0]?.koreanName
          ? `${diseases[0]?.koreanName.split(',')[0]}${
              diseases[0]?.koreanName.split(',').length - 1 > 0
                ? ` (${diseases[0]?.koreanName.split(',').length - 1}) `
                : ``
            }`
          : ''}
      </CCTypography>
      {diseases?.length - 1 > 0 && (
        <Chip size='small' label={`+${diseases?.length - 1}`} />
      )}
    </Grid>
  );
};
export default DiseasesItem;
