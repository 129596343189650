import CVTextField from "components/CVTextField";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import IMaskInput from "react-imask/esm/input";
import {
  formatPhoneNumber,
  isValidPhoneNumber
} from "react-phone-number-input";

const phoneNumberMask = props => (
  <IMaskInput
    {...props}
    mask={[
      { mask: "000-000-0000" },
      { mask: "00-0000-0000" },
      { mask: "00-000-0000" }
    ]}
  />
);

const PhoneNumberField = props => {
  const {
    permission,
    value,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);

  const COUNTRY_CODE = process.env.PHONE_COUNTRY_CODE
    ? process.env.PHONE_COUNTRY_CODE
    : "+84";

  useEffect(() => {
    if (value) {
      const phoneNumber = value;
      const vietnamPhoneNumber = COUNTRY_CODE.concat(
        phoneNumber.replace(/[^\d]/g, "").substring(1)
      );
      if (!isValidPhoneNumber(vietnamPhoneNumber)) {
        setError(true);
      } else setError(false);
    }

    required && value === "" && setError(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, required]);

  const handlePhoneField = name => e => {
    const phoneNumber = e.target.value.replace(/[^\d]/g, ""); // 숫자만 따로 추출
    const vietnamPhoneNumber = COUNTRY_CODE.concat(phoneNumber);

    // replace 84 -> 0 when type 84
    const vnStandardPhoneNumber = phoneNumber.startsWith("84")
      ? "0" + phoneNumber.substring(2)
      : phoneNumber;

    if (
      required &&
      name === "onBlur" &&
      (!phoneNumber || !isValidPhoneNumber(vietnamPhoneNumber))
    ) {
      setError(true);
      return;
    }

    phoneNumber.length >= 9
      ? onChange({
          target: {
            value: isValidPhoneNumber(phoneNumber)
              ? formatPhoneNumber(vietnamPhoneNumber).replace(/[^\d]/g, "")
              : e.target.value
          }
        })
      : onChange({ target: { value: vnStandardPhoneNumber } });
  };

  return (
    <CVTextField
      permission={permission}
      margin={margin}
      variant={variant}
      label={label}
      onChange={handlePhoneField("onChange")}
      error={error}
      required={required}
      value={value || ""}
      onBlur={handlePhoneField("onBlur")}
      InputProps={{
        inputComponent: phoneNumberMask
      }}
      {...others}
    />
  );
};

PhoneNumberField.defaultProps = {
  value: "",
  margin: "dense",
  variant: "outlined",
  label: "전화번호",
  required: false,
  errorOnChange: () => {}
};

PhoneNumberField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default PhoneNumberField;
