/**
 * @deprecated
 */
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ReservationIcon } from "../../themes/common/icons";
import { CCFlattenDateTimePicker } from "../CCFlattenDateTimePicker";
import CCTextField from "../CCTextField";

const useStyles = makeStyles(
  theme => ({
    root: {},
    button: {
      color: theme.palette.icon
    }
  }),
  { name: "CCDateField" }
);

const CCDateTimeField = props => {
  const { value, onChange, minutesStep, ...others } = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleValue = value => {
    onChange({
      target: {
        name: props.name,
        value: value
          .format("YYYY/MM/DD hh:mm A")
          .toString()
          .replace("오전", "AM")
          .replace("오후", "PM")
      }
    });
    setAnchorEl(null);
  };

  const handleField = e => {
    onChange({ ...e, target: { value: e.target.value.toUpperCase() } });
  };

  return (
    <>
      <CCTextField
        {...others}
        margin={"dense"}
        variant={"outlined"}
        onClickIcon={e => {
          setAnchorEl(e.target);
        }}
        value={value.replace("오전", "AM").replace("오후", "PM")}
        endIcon={<ReservationIcon className={classes.button} />}
        onChange={handleField}
        // InputProps={{
        //   inputComponent: DateTimeMask
        // }}
      />
      <CCFlattenDateTimePicker
        open={Boolean(anchorEl)}
        value={value ? moment(value, "YYYY/MM/DD hh:mm A") : moment()}
        onChange={handleValue}
        onClose={() => setAnchorEl(null)}
      />
    </>
  );
};

CCDateTimeField.propTypes = {
  /** date 변경 시 실행되는 함수 */
  onChange: PropTypes.func,
  /** YYYY/MM/DD hh:mm A 형태의 string */
  value: PropTypes.string
};

export default CCDateTimeField;
