import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { TeethGraph, translate } from "components";
import React from "react";
import { useIntl } from "react-intl";
import { CCPaper, CCTable } from "styles/src/components";
const width = 160;

const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  }
}));

const LabOrderTable = props => {
  const { value, onDelClick } = props;

  const classes = useStyles();
  const intl = useIntl();

  const contents = value.map((item, index) => {
    return {
      ...item,
      id: item.id,
      no: index + 1,
      //orderId: "",// C{치과고유번호}{의뢰날짜}{환자차트번호}{의뢰순번}​  CVT021905015345201
      teeth: item.teeth,
      tableManufacture: item.manufacture?.name,
      tableShade: `
        ${item.shade?.top ? `I : ${item.shade?.top}` : ""}
        ${
          item.shade?.top && item.shade?.middle ? ` / ` : ""
        }                                      
        ${
          item.shade?.middle ? `M : ${item.shade?.middle}` : ""
        }                                
        ${item.shade?.middle && item.shade?.bottom ? ` / ` : ""}
        ${item.shade?.bottom ? `C : ${item.shade?.bottom}` : ""}
      `,
      pontic: item.pontic?.length ? item.pontic[0] : "",
      photo: item.photo?.length,
      detail: item.detail,
      detailToString: item.detail?.toString(),
      memo: item.memo || ""
    };
  });

  return (
    <CCPaper className={clsx(classes.root)}>
      <CCTable
        heads={[
          {
            key: "no",
            label: "순번",
            className: classes.cell
          },
          {
            key: "teeth",
            label: "치식",
            className: classes.cell,
            width: width + 16,
            component: ({ cellData, rowData }) => {
              return (
                <TeethGraph
                  width={120}
                  height={30}
                  foreground={"black"}
                  numbers={cellData || []}
                  missings={rowData.missings}
                />
              );
            }
          },
          {
            key: "tableManufacture",
            label: "종류",
            className: classes.cell
          },
          {
            key: "tableShade",
            label: "Shade",
            className: classes.cell
          },
          {
            key: "pontic",
            label: translate(intl, "labManageGold.pontic"),
            className: classes.cell
          },
          {
            key: "detailToString",
            label: translate(intl, "labOrder.detail"),
            className: classes.cell
          },
          {
            key: "photo",
            label: "첨부사진(매)",
            width: 110,
            className: classes.cell
          },
          {
            key: "memo",
            label: "추가사항",
            className: classes.cell
          }
        ]}
        contents={contents}
        onDelClick={onDelClick}
      />
    </CCPaper>
  );
};

LabOrderTable.defaultProps = {};

LabOrderTable.propTypes = {};

export default LabOrderTable;
