import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { CCTextField, CCTypography } from "../";
import { RemoveIcon } from "../../themes/common/icons";

const useStyles = makeStyles(theme => ({
  root: { width: 200 },
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0
    },
    "& li": {
      overflow: "hidden"
    }
  },
  paper: {
    width: 350,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    opacity: 0.9
  },
  option: {
    color: "white",
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.hover
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.select.main
    }
  },
  noOptions: {
    color: "white"
  },
  groupLabel: {
    background: "none",
    color: "#8fa2a9"
  }
}));

const useStyles2 = makeStyles(() => ({
  option: {
    color: "white",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 24
  }
}));

export default function ComboBox(props) {
  const {
    options = [],
    onChange = () => {},
    className,
    label,
    placeholder,
    renderOption = () => {},
    ...otehrs
  } = props;
  const classes = useStyles();
  const classes2 = useStyles2();

  return (
    <Autocomplete
      className={className}
      classes={classes}
      // id="combo-box-demo"
      options={options}
      renderInput={params => (
        <CCTextField
          {...params}
          fullWidth
          variant="outlined"
          margin="dense"
          placeholder={placeholder}
        />
      )}
      closeIcon={<RemoveIcon />}
      //   getOptionLabel={option => option.btnName}
      //   getOptionSelected={(option, value) => option.btnName === value.btnName}
      renderOption={option => {
        return (
          <CCTypography noWrap className={classes2.option}>
            {renderOption(option)}
            {/* {option.btnName} */}
          </CCTypography>
        );
      }}
      // selectOnFocus={true}
      //   groupBy={option => option.title}
      onChange={(e, v) => {
        onChange(v);
      }}
      {...otehrs}
    />
  );
}
