import moment from "moment";
import {
    generateGenderDistributionGraph,
    generateGenderPieGraph,
    generateIntroductionGraph,
    generatePatientBarGraph,
    greyScalePieGraph
} from "types/graph";

export const formatDateTitle = (value, option) => {
  const weekOfMonth = input => {
    const firstDayOfMonth = input.clone().startOf("month");
    const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

    const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

    return Math.ceil((input.date() + offset) / 7);
  };

  if (option === "day") {
    return moment.unix(value).format("YYYY/MM/DD");
  }
  if (option === "week") {
    const date = moment.unix(value).endOf("week");
    const year = date.format("YYYY");
    const month = date.format("M");
    const week = weekOfMonth(date);
    return `${year}년 ${month}월 ${week}주`;
  }
  if (option === "month") {
    return moment.unix(value).format("YYYY년 MM월");
  }
  if (option === "year") {
    return moment.unix(value).format("YYYY년");
  }
};

export const generateGenderFields = (dateLabel, currentDate, dateOption) => {
  return [
    {
      title: `의사별 총 연령/성별 분포도 (${dateLabel})`,
      label: "total",
      parser: (data, label) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const maleValue = maleData ? maleData[label] : undefined;
        const femaleValue = femaleData ? femaleData[label] : undefined;
        const maleTotal = maleValue ? maleValue.new + maleValue.existing : 0;
        const femaleTotal = femaleValue
          ? femaleValue.new + femaleValue.existing
          : 0;
        return maleTotal + femaleTotal;
      },
      pieParser: data => {
        //const values = data[field];
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const maleTotal = maleValue ? maleValue.new + maleValue.existing : 0;
          const femaleTotal = femaleValue
            ? femaleValue.new + femaleValue.existing
            : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 남성 환자 (${dateLabel})`,
      label: "male",
      parser: (data, label) => {
        const maleData = data.man;
        const maleValue = maleData ? maleData[label] : 0;
        const maleTotal = maleValue ? maleValue.new + maleValue.existing : 0;

        return maleTotal;
      },
      pieParser: data => {
        const maleData = data.man;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const maleTotal = maleValue ? maleValue.new + maleValue.existing : 0;
          total = total + maleTotal;
        });
        return total;
      },
      unit: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 여성 환자 (${dateLabel})`,
      label: "female",
      parser: (data, label) => {
        const femaleData = data.woman;
        const femaleValue = femaleData ? femaleData[label] : 0;
        const femaleTotal = femaleValue
          ? femaleValue.new + femaleValue.existing
          : 0;
        return femaleTotal;
      },
      pieParser: data => {
        //const values = data[field];
        const femaleData = data.woman;
        const keys = Object.keys(femaleData);
        let total = 0;
        keys.forEach(label => {
          const femaleValue = femaleData ? femaleData[label] : 0;
          const femaleTotal = femaleValue
            ? femaleValue.new + femaleValue.existing
            : 0;
          total = total + femaleTotal;
        });
        return total;
      },
      unit: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    }
  ];
};

export const parseGender = (ageData, age) => {
  if (ageData) {
    const value = ageData ? ageData[age] : undefined;

    const newValue = value && value.new ? value.new : 0;
    const returningValue = value && value.existing ? value.existing : 0;
    const total = newValue + returningValue;
    return total;
  }
  return 0;
};

export const parseGenderData = (data, labels, doctor = "") => {
  const maleSeries = [];
  const femaleSeries = [];
  let total = 0;

  if (data) {
    const { man, woman } =
      doctor !== ""
        ? data[doctor]
          ? data[doctor]
          : { man: undefined, woman: undefined }
        : data;
    labels.forEach(label => {
      const totalMan = parseGender(man, label);
      const totalWoman = parseGender(woman, label);
      maleSeries.push(totalMan);
      femaleSeries.push(totalWoman);
      total = total + totalMan + totalWoman;
    });
  }
  return {
    maleSeries: maleSeries,
    femaleSeries: femaleSeries,
    total: total
  };
};

export const genderStructure = {
  title: "연령/성별 분포도",
  // fetchURL: process.env.REACT_APP_CF_QUERY_URL + "/patients",
  dataParser: parseGenderData,
  singleDataParser: (data, doctor, currentLabel) => {
    const parseLabels = [
      "90-0",
      "80-90",
      "70-80",
      "60-70",
      "50-60",
      "40-50",
      "30-40",
      "20-30",
      "10-20",
      "0-10"
    ];
    return parseGenderData(data, parseLabels, doctor);
  },
  generateFields: generateGenderFields,
  graphFunction: seriesData => {
    const { maleSeries, femaleSeries, total } = seriesData;

    const maleSeriesFinal = maleSeries.map(value =>
      total ? Math.round((value / total) * 100) + 1 : 1
    );

    const femaleSeriesFinal = femaleSeries.map(value =>
      total ? Math.round((value / total) * -100 - 1) : -1
    );

    const min =
      femaleSeriesFinal.length > 0
        ? femaleSeriesFinal.reduce((prev, current) =>
            prev < current ? prev : current
          )
        : -50;
    const max =
      maleSeriesFinal.length > 0
        ? maleSeriesFinal.reduce((prev, current) =>
            prev > current ? prev : current
          )
        : 50;

    const limit =
      max === 1 && min === -1
        ? 50
        : Math.max(Math.ceil(min / -5) * 5, Math.ceil(max / 5) * 5);

    return generateGenderDistributionGraph(
      [
        {
          name: "남성",
          data: maleSeriesFinal
        },
        {
          name: "여성",
          data: femaleSeriesFinal
        }
      ],
      limit
    );
  },
  pieGraphFunction: value => {
    const { maleSeries, femaleSeries } = value;

    const maleTotal =
      maleSeries && maleSeries.length > 0
        ? maleSeries.reduce((prev, current) => prev + current)
        : 0;
    const femaleTotal =
      femaleSeries && femaleSeries.length > 0
        ? femaleSeries.reduce((prev, current) => prev + current)
        : 0;
    return generateGenderPieGraph([maleTotal, femaleTotal]);
  },
  dialogHeader: (data, currentDate, dateOption, classes) => {
    return [];
  },
  customLabels: data => [
    "90-0",
    "80-90",
    "70-80",
    "60-70",
    "50-60",
    "40-50",
    "30-40",
    "20-30",
    "10-20",
    "0-10"
  ],
  customFormatter: value => {
    switch (value) {
      case "90-0":
        return "90대 이상";
      case "80-90":
        return "80대";
      case "70-80":
        return "70대";
      case "60-70":
        return "60대";
      case "50-60":
        return "50대";
      case "40-50":
        return "40대";
      case "30-40":
        return "30대";
      case "20-30":
        return "20대";
      case "10-20":
        return "10대";
      case "0-10":
        return "10대 미만";
      default:
        return "";
    }
  },
  customDateRange: (value, dateOption) => {
    const startDate = moment
      .unix(value)
      .startOf(dateOption)
      .format("YYYYMMDD");
    const endDate = moment
      .unix(value)
      .endOf(dateOption)
      .format("YYYYMMDD");
    return { start: startDate, end: endDate };
  },
  parseTableData: data => {
    if (data) {
      const { man: maleEntries, woman: femaleEntries } = data;
      const keys = Object.keys(maleEntries);
      const total = { label: "합계" };
      let maleTotal = 0;
      let femaleTotal = 0;
      keys.forEach(key => {
        const maleValue = maleEntries[key];
        const femaleValue = femaleEntries[key];
        maleTotal = maleTotal + maleValue.new + maleValue.existing;
        femaleTotal = femaleTotal + femaleValue.new + femaleValue.existing;
        total[key] = {
          new: maleValue.new + femaleValue.new,
          existing: maleValue.existing + femaleValue.existing
        };
      });
      total.total = maleTotal + femaleTotal;
      return [
        { ...maleEntries, label: "남성", total: maleTotal },
        { ...femaleEntries, label: "여성", total: femaleTotal },
        total
      ];
    }
    return [];
  },
  tableWidth: "1220px"
};

export const parseAddress = (addressData, label) => {
  if (addressData) {
    const value = addressData ? addressData[label] : undefined;

    const newValue = value && value.new ? value.new : 0;
    const returningValue = value && value.existing ? value.existing : 0;
    const total = newValue + returningValue;
    return { ...value, total: total, new: newValue, existing: returningValue };
  }
  return { total: 0, new: 0, existing: 0 };
};

export const parseZoneData = (data, zoneLabel, doctor = "") => {
  const totalData = [];
  const newData = [];
  const existingData = [];
  const labelNames = [];
  const addressList = [];
  if (data) {
    const addressData =
      doctor !== ""
        ? data[doctor]
          ? data[doctor][zoneLabel]
          : undefined
        : data[zoneLabel];
    if (addressData) {
      const labels = Object.keys(addressData);
      labels.forEach(label => {
        const value = parseAddress(addressData, label);
        if (value.new > 0) {
          addressList.push({ ...value, label: label });
        } //labelNames.push(label);
      });
      addressList
        .sort((a, b) => b.new - a.new)
        .slice(0, 7)
        .forEach(value => {
          totalData.push(value.total);
          newData.push(value.new);
          existingData.push(value.existing);
          labelNames.push({
            label: value.label,
            province: value.sigungu ? value.sigungu : undefined,
            city: value.sido ? value.sido : undefined
          });
        });
    }
  }
  return {
    totalSeries: totalData,
    newSeries: newData,
    existingSeries: existingData,
    labels: labelNames
  };
};

export const generateZoneFields = (dateLabel, currentDate, dateOption) => {
  return [
    {
      title: `의사별 신환 환자 (${dateLabel})`,
      label: "new",
      parser: (data, label) => {
        const value = data[label];
        const retVal = value ? value.new : 0;
        return retVal;
      },
      pieParser: (data, field) => {
        const values = data[field];
        let total = 0;
        if (values) {
          const keys = Object.keys(values);
          keys.forEach(label => {
            const value = values[label];
            const newVal = value && value.new ? value.new : 0;
            total = total + newVal;
          });
        }
        return total;
      },
      unit: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    }
  ];
};

export const addressStructure = (field, title) => {
  return {
    title: "지역별 분포도 (" + title + ")",
    // fetchURL: process.env.REACT_APP_CF_QUERY_URL + "/patients",
    dataParser: (graphData, graphLabels, currentDoctor) =>
      parseZoneData(graphData, field, currentDoctor),
    singleDataParser: (graphData, currentDoctor) => {
      return parseZoneData(graphData, field, currentDoctor);
    },
    generateFields: generateZoneFields,
    graphFunction: value => {
      const { newSeries, labels } = value;
      return generatePatientBarGraph(
        [
          {
            name: "신환",
            type: "column",
            data: newSeries //[10, 12, 10, 7, 15, 22, 8]
          }
        ],
        labels,
        (value, isTooltip) => {
          if (isTooltip) {
            const province = value.province ? value.province + " " : "";
            const city = value.city ? value.city + " " : "";
            return value ? city + province + value.label : "";
          }
          return value ? value.label : "";
        }
      );
    },
    pieGraphFunction: value => {
      const { newSeries, labels } = value;
      const finalLabels = labels.map(value => value.label);
      return greyScalePieGraph(newSeries, finalLabels);
    },
    dialogHeader: (data, currentDate, dateOption, classes) => {
      return [];
    },
    parseField: field,
    customLabels: data => {
      if (data && data[field]) {
        const entry = data[field];
        const tempArray = [];
        const labels = entry ? Object.keys(entry) : [];
        labels.forEach(label => {
          const value = parseAddress(entry, label);
          tempArray.push({
            id: label,
            labelName: label,
            total: value.total
          });
        });
        return tempArray.sort((a, b) => b.total - a.total).slice(0, 7);
      }
      return [];
    },
    customFormatter: value => {
      return value ? value.labelName : value;
    },
    customDateRange: (value, dateOption) => {
      const startDate = moment
        .unix(value)
        .startOf(dateOption)
        .format("YYYYMMDD");
      const endDate = moment
        .unix(value)
        .endOf(dateOption)
        .format("YYYYMMDD");
      return { start: startDate, end: endDate };
    },
    parseTableData: data => {
      if (data) {
        const values = data[field];
        if (values) {
          const content = [];
          for (const [key, value] of Object.entries(values)) {
            content.push({
              name: key,
              new: value.new,
              existing: value.existing,
              total: value.new + value.existing
            });
          }
          return content;
        }
      }
      return [];
    }
  };
};

export const generateRecommenderFields = (
  dateLabel,
  currentDate,
  dateOption
) => {
  return [];
};

export const parseRecommenderData = (data, doctor = "") => {
  const labels = [];
  const salesSeries = [];
  const patientSeries = [];
  if (data) {
    const patientIds = Object.keys(data);
    const tempPatients = [];
    patientIds.forEach(id => {
      const patientData = data[id];
      tempPatients.push(patientData);
    });
    tempPatients
      .sort((a, b) =>
        b.sales === a.sales ? b.count - a.count : b.sales - a.sales
      )
      .slice(0, 10)
      .forEach(value => {
        labels.push(value.name ? value.name : "");
        salesSeries.push(value.sales ? value.sales : 0);
        patientSeries.push(value.count ? value.count : 0);
      });
  }
  return {
    labels: labels,
    salesSeries: salesSeries,
    patientSeries: patientSeries
  };
};

export const recommenderStructure = {
  title: "소개 환자 통계",
  // fetchURL: process.env.REACT_APP_CF_QUERY_URL + "/togethers",
  dataParser: (graphData, graphLabels, currentDoctor) =>
    parseRecommenderData(graphData),
  singleDataParser: (graphData, graphLabels, currentDoctor) => 0,
  generateFields: generateRecommenderFields,
  graphFunction: value => {
    const { salesSeries, patientSeries, labels } = value;
    return generateIntroductionGraph(
      [
        {
          name: "누적 순매출액(천원)",
          type: "line",
          data: salesSeries
        },
        {
          name: "소개 환자수(명)",
          type: "bar",
          data: patientSeries
        }
      ],
      labels
    );
  },
  pieGraphFunction: () => undefined,
  dialogHeader: (data, currentDate, dateOption, classes) => {
    return [];
  },
  parseField: "",
  customLabels: data => {
    return [];
  },
  customFormatter: value => {
    return value ? value.labelName : value;
  },
  customDateRange: (value, dateOption) => {
    const startDate = moment
      .unix(value)
      .startOf(dateOption)
      .format("YYYYMMDD");
    const endDate = moment
      .unix(value)
      .endOf(dateOption)
      .format("YYYYMMDD");
    return { start: startDate, end: endDate };
  },
  parseTableData: data => {
    if (data) {
      const content = [];
      const keys = Object.keys(data);
      keys.forEach(key => {
        const value = data[key];
        content.push({
          name: value.name,
          sales: value.sales,
          patient: value.count
        });
      });
      return content.sort((a, b) =>
        a.sales === b.sales ? b.patient - a.patient : b.sales - a.sales
      );
    }
    return [];
  }
};

export const parseDoctorValue = (
  data,
  label,
  entry,
  doctorList,
  field = ""
) => {
  const seriesData = [];
  const labels = [];
  doctorList.forEach(doctor => {
    const doctorData = data[doctor.id];
    if (doctorData) {
      const value = doctorData;
      const seriesValue = entry.pieParser
        ? entry.pieParser(value, field)
        : entry.parser(value, label);
      seriesData.push(seriesValue ? seriesValue : 0);
      labels.push(doctor.name);
    } else {
      seriesData.push(0);
      labels.push(doctor.name);
    }
  });
  return { series: seriesData, labels: labels };
};

export const parseDoctorData = (
  data,
  labels,
  entry,
  doctorList,
  field = ""
) => {
  const seriesData = [];
  doctorList.forEach(doctor => {
    const doctorData = data[doctor.id];
    if (doctorData) {
      const series = [];
      labels.forEach(labelValue => {
        const label =
          typeof labelValue === "string" ? labelValue : labelValue.id;
        const value = field !== "" ? doctorData[field] : doctorData;
        const seriesValue = value ? entry.parser(value, label) : 0;
        series.push(seriesValue);
      });
      const dataEntry = {
        name: doctor.name,
        type: series.length > 1 ? "line" : "bar",
        data: series
      };
      seriesData.push(dataEntry);
    } else {
      const dataEntry = {
        name: doctor.name,
        type: labels.length > 1 ? "line" : "bar",
        data: labels.map(() => 0)
      };
      seriesData.push(dataEntry);
    }
  });
  return seriesData;
};
