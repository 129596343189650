import { Box, FormControlLabel, Grid, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVTextField, ErrorTooltip } from "components";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { makeList } from "utils/type";
import { STOCK_ALERT, UNIT, EXSISTENT_ALERT } from "types/inventory";
import MenuItem from "@material-ui/core/MenuItem";
import { useIntl } from "utils/language";
import { CCTooltip, CCTypography } from "styles/src/components";
import { RadioGroup } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { InfoIcon } from "styles/src/themes/common/icons";
import { getTimestamp } from "utils/datetime";
import ExpireTable from "../ExpireTable";
import IMask from "imask";

const height = 150;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "18px !important",
    background: theme.palette.background.default
  },
  chief__complaint__input: {
    marginBottom: "16px"
  },
  chief__complaint__table: {
    height: `calc(100% - ${height}px)`
  },
  head__field: {
    width: 130
  },
  head__storage__field: {
    width: 150
  },
  head__search__field: {
    width: 250
  },
  bin__field: {
    marginLeft: 20,
    width: 120
  },
  margin__field: {
    marginLeft: 10,
    paddingBottom: 0,
    paddingLeft: 0
  },
  font__field: {
    fontSize: 10,
    marginTop: 20,
    marginLeft: 20,
    color: theme.palette.disabled,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  label__font__field: {
    fontSize: 14,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  top__margin__field: {
    marginTop: "-10px"
  },
  bottom__margin__field: {
    marginBottom: "-40px"
  },
  button__filter: {
    marginTop: "5px"
  },
  info__button: { color: theme.palette.disabled.hex },
  border: {
    width: 1,
    height: "20px",
    marginLeft: 10,
    marginTop: 15,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  required: {
    color: theme.palette.error.main
  }
}));

const eightFilter = "00000000";
const eightPipe = new IMask.createPipe({
  mask: eightFilter
});

//For DetailDialog
const DetailModify = forwardRef((props, ref) => {
  const { modify, className, methods, value } = props;

  const intl = useIntl();
  const [directInput, setDirectInput] = useState(false);
  const [change, setChange] = useState(false);
  const StockAlert = makeList(STOCK_ALERT);
  const ExistentAlert = makeList(EXSISTENT_ALERT);
  const Unit = makeList(UNIT);

  useEffect(() => {
    methods.reset(value);
  }, [methods, value])

  const classes = useStyles();

  const { getValues, control, watch, errors } = useFormContext();

  useImperativeHandle(ref, () => ref?.current);

  return (
    <>
      <Box className={clsx(classes.root, className)}>
        <Controller
          name={"major"}
          control={control}
          defaultValue={modify?.major || null}
          render={props => {
            const { ref, value, onBlur } = props.field;
            return (
              <ErrorTooltip
                open={Boolean(errors?.major?.message)}
                errorMsg={errors?.major?.message}
                placement={"top-start"}
              >
                <CVTextField
                  inputRef={ref}
                  required={true}
                  className={classes.head__field}
                  variant={"outlined"}
                  margin={"dense"}
                  value={value || ""}
                  disabled={true}
                  readOnly={true}
                  label={intl.formatMessage({ id: "majorCategory" })}
                  onBlur={onBlur}
                >
                  <MenuItem key={modify?.major} value={modify?.major}>
                    {modify?.major}
                  </MenuItem>
                </CVTextField>
              </ErrorTooltip>
            );
          }}
        />

        <CVTextField
          className={classes.head__field}
          variant={"outlined"}
          margin={"dense"}
          value={modify?.minor || ""}
          disabled={true}
          readOnly={true}
          label={intl.formatMessage({ id: "minorCategory" })}
        >
          <MenuItem key={modify?.minor} value={modify?.minor}>
            {modify?.minor}
          </MenuItem>
        </CVTextField>

        <Controller
          name={"productName"}
          control={control}
          defaultValue={modify?.productName}
          render={props => {
            const { ref, value, onChange, onBlur } = props.field;

            return (
              <ErrorTooltip
                open={Boolean(errors?.productName?.message)}
                errorMsg={errors?.productName?.message}
                placement={"top-start"}
              >
                <CVTextField
                  required={true}
                  className={classes.head__search__field}
                  variant={"outlined"}
                  margin={"dense"}
                  inputRef={ref}
                  name={"searchField"}
                  value={value || ""}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  onBlur={onBlur}
                  label={intl.formatMessage({ id: "productName" })}
                // disabled={true}
                />
              </ErrorTooltip>
            );
          }}
        />

        <Controller
          name={"manufacture"}
          control={control}
          defaultValue={getValues("manufacture")}
          render={props => {
            const { ref, value, onChange, onBlur } = props.field;

            return (
              <CVTextField
                className={classes.head__search__field}
                variant={"outlined"}
                margin={"dense"}
                inputRef={ref}
                name={"searchField"}
                value={value || ""}
                onChange={e => {
                  onChange(e.target.value);
                }}
                onBlur={onBlur}
                label={intl.formatMessage({ id: "manufacturer" })}
              // disabled={true}
              />
            );
          }}
        />

        <Box className={classes.chief__complaint__table}>
          <Controller
            name={"storage.unit"}
            control={control}
            defaultValue={getValues("storage.unit")}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;
              return (
                <FormControlLabel
                  control={
                    <RadioGroup
                      tabList={Unit}
                      value={value === "EA" ? "1" : "2"}
                      required={true}
                      onChange={e => {
                        onChange(e.value === "1" ? "EA" : "BIN");
                      }}
                      inputRef={ref}
                      onBlur={onBlur}
                      defaultValue={value}
                    />
                  }
                  label={
                    <Grid item className={classes.item}>
                      <Box display="flex" width="50px" flexDirection="row">
                        <CCTypography variant="h5" component={"span"}>
                          {intl.formatMessage({ id: "unit" })}
                        </CCTypography>
                        <CCTypography
                          variant={"h4"}
                          component={"span"}
                          className={classes.required}
                        >
                          {" *"}
                        </CCTypography>
                      </Box>
                    </Grid>
                  }
                  labelPlacement="start"
                  onChange={() => {
                    setDirectInput(!directInput);
                  }}
                />
              );
            }}
          />
          <Controller
            name={"storage.binCnt"}
            control={control}
            defaultValue={getValues("storage.binCnt") || 0}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;

              return (
                <CVTextField
                  className={clsx(
                    classes.bin__field,
                    classes.margin__field,
                    classes.bottom__margin__field
                  )}
                  inputRef={ref}
                  onChange={e => {
                    onChange(eightPipe(e.target.value || '0'))
                  }}
                  value={Boolean(watch("storage.unit") === "BIN") ? value : 0}
                  variant={"outlined"}
                  margin={"dense"}
                  label={intl.formatMessage({ id: "cntforBin" })}
                  name={"searchField"}
                  disabled={Boolean(watch("storage.unit") !== "BIN")}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </Box>

        <Box>
          <Controller
            name={"storage.currentCnt"}
            control={control}
            defaultValue={getValues("storage.currentCnt") || 0}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;

              return (
                <CVTextField
                  className={classes.head__search__field}
                  name={"searchField"}
                  defaultValue={modify?.storage?.currentCnt}
                  value={value}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  variant={"outlined"}
                  margin={"dense"}
                  label={intl.formatMessage({ id: "currentCnt" })}
                  inputRef={ref}
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <CCTypography variant={"body1"}>
                          {watch("storage.unit") === "EA"
                            ? "EA"
                            : watch("storage.unit") === "BIN"
                              ? "BIN"
                              : modify?.storage?.unit}
                        </CCTypography>
                      </InputAdornment>
                    )
                  }}
                  disabled={true}
                />
              );
            }}
          />

          <Controller
            name={"storage.optCnt"}
            control={control}
            defaultValue={getValues("optCnt") || 0}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;

              return (
                <CVTextField
                  className={classes.head__search__field}
                  name={"searchField"}
                  inputRef={ref}
                  value={value}
                  onBlur={onBlur}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  variant={"outlined"}
                  margin={"dense"}
                  label={intl.formatMessage({ id: "optCnt" })}
                  InputProps={{
                    endAdornment: (
                      // <InputAdornment>
                      <CCTypography variant={"body1"} position={"end"}>
                        {watch("storage.unit") === "EA"
                          ? "EA"
                          : watch("storage.unit") === "BIN"
                            ? "BIN"
                            : modify?.storage?.unit}
                      </CCTypography>
                      // </InputAdornment>
                    )
                  }}
                  disabled={true}
                />
              );
            }}
          />
        </Box>

        <Box className={classes.chief__complaint__table}>
          <Box display="flex" flexDirection="row">
            <CCTypography
              variant="h5"
              component={"span"}
              style={{ marginTop: 20, width: "200px" }}
            >
              {intl.formatMessage({
                id: "inventoryNotificationType"
              })}
            </CCTypography>
            <CCTooltip
              title={intl.formatMessage(
                { id: "informCurrentlessAdequate" },
                { br: () => <br /> }
              )}
              style={{ marginTop: 15 }}
              placement="top"
              arrow
            >
              <CCTypography
                variant="h5"
                component={"span"}
                className={classes.info__button}
              >
                <InfoIcon />
              </CCTypography>
            </CCTooltip>
            <div className={classes.border} />
            <Controller
              name={"alert"}
              control={control}
              defaultValue={getValues("alert")}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;
                return (
                  <FormControlLabel
                    className={classes.margin__field}
                    control={
                      <RadioGroup
                        tabList={StockAlert}
                        required={true}
                        value={Boolean(value) ? "2" : "1"}
                        onChange={() => {
                          onChange(!value);
                        }}
                        onBlur={onBlur}
                        inputRef={ref}
                      />
                    }
                    onChange={e => {
                      onChange(e.value);
                    }}
                  />
                );
              }}
            />
          </Box>
        </Box>
        <Box className={classes.table__height}>
          <Box display="flex" flexDirection="row">
            <CCTypography
              variant="h5"
              component={"span"}
              style={{ marginTop: 20, width: "200px" }}
            >
              {intl.formatMessage({
                id: "expireDateSetting"
              })}
            </CCTypography>
            <CCTooltip
              title={intl.formatMessage(
                { id: "navigateImminentExpireDate" },
                { br: () => <br /> }
              )}
              style={{ marginTop: 15 }}
              placement="top"
              arrow
            >
              <CCTypography
                variant="h5"
                component={"span"}
                className={classes.info__button}
              >
                <InfoIcon />
              </CCTypography>
            </CCTooltip>
            <div className={classes.border} />
            <Controller
              name={"canExpire"}
              control={control}
              defaultValue={
                getValues("canExpire") ||
                  Boolean(modify?.storage?.expire?.length > 0)
                  ? "2"
                  : "1"
              }
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;

                return (
                  <ErrorTooltip
                    open={Boolean(errors?.expireList?.message)}
                    errorMsg={errors?.expireList?.message}
                    placement={"bottom-end"}
                    popperOptions={{
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: "200,90"
                        }
                      }
                    }}
                  >
                    <FormControlLabel
                      className={classes.margin__field}
                      control={
                        <RadioGroup
                          className={classes.font__field}
                          tabList={ExistentAlert}
                          required={true}
                          value={value}
                          onBlur={onBlur}
                          inputRef={ref}
                        />
                      }
                      onChange={e => {
                        onChange(e?.value);
                        setChange(true);
                      }}
                    />
                  </ErrorTooltip>
                );
              }}
            />
          </Box>
        </Box>
        {(watch("canExpire") === "2" ||
          Boolean(errors?.productName?.message) ||
          (!change && Boolean(modify?.storage?.expire?.length > 0))) && (
            <Box className={classes.chief__complaint__table}>
              <Controller
                name={"expireList"}
                control={control}
                defaultValue={modify?.storage?.expire?.length > 0 ? modify?.storage?.expire : [{
                  expireCount: 0,
                  expireDate: getTimestamp()
                }]}
                render={props => {
                  const { ref, value, onChange, onBlur } = props.field;
                  return (
                    <ExpireTable
                      data={modify}
                      currentCnt={watch("storage.currentCnt")}
                      value={value}
                      onChange={e => {
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </Box>
          )}
      </Box>
    </>
  );
});
export default DetailModify;
