import React from "react";

const CrmIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M22 15c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1h-7c-.552 0-1-.448-1-1v-5c0-.552.448-1 1-1h7zm-10-2c.943 0 2.217.167 3.47.5H13.5c-.513 0-.936.386-.993.883l-.007.117V20H4v-3c0-2.66 5.33-4 8-4zm10 3l-3.5 2-3.5-2v1l3.5 2 3.5-2v-1zM12 4c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
            transform="translate(-5222 -191) translate(5222 191)"
          />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-5222 -191) translate(5222 191)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default CrmIcon;
