import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CCTextField } from "styles/components";
import { ENTRY_LIMIT } from "../../types/constants";
import { CCTooltip } from "styles/src/components";

function format(n, sep, decimals) {
  try {
    // eslint-disable-next-line no-useless-escape
    let numValue = n.replaceAll(/[^0-9\.]+/g, "") || "";
    return numValue.toString();
  } catch (e) {
    return n;
  }
}

const NumberField = props => {
  const {
    value,
    select,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);

  useEffect(() => {
    if(required){
      if (value) {
        setError(false);
      }else setError(true)
    };
  }, [value, required]);

  const handleOnChange = ({ target: { value } }) => {
    const localeValue = format(value.toString());
    onChange({ target: { value: localeValue } });
  };
  return (
    <CCTooltip placement={"top"} arrow title={select ? "" : value || ""}>
      <CCTextField
        margin={margin}
        variant={variant}
        label={label}
        onChange={handleOnChange}
        error={error}
        required={required}
        value={format(value) || ""}
        select={select}
        {...others}
        InputProps={{
          inputProps: {
            maxLength: ENTRY_LIMIT.NUMBER_FIELD
          }
        }}
      />
    </CCTooltip>
  );
};

NumberField.defaultProps = {
  value: "",
  margin: "dense",
  variant: "outlined",
  label: "",
  required: false,
  errorOnChange: () => {}
};

NumberField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default NumberField;
