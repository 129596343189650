import React from "react";

const OralexamnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="pnvgl07rxa" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#00E1D1" />
        <stop offset="100%" stopColor="#00BFA5" />
      </linearGradient>
      <linearGradient id="tkupvyadub" x1="50%" x2="50%" y1="50%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-1061.000000, -369.000000) translate(1061.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M19 2c6.627 0 12 5.373 12 12v4c0 6.627-5.373 12-12 12h-6c-1.403 0-2.75-.24-4.002-.683.433-.074.821-.396.941-.903l1.67-7.196c.174-.567.728-.972 1.34-.945l.231.001c.56.03 1.05.417 1.212.944l1.669 7.196c.263 1.114 1.82 1.333 2.292 0l.014-.04.074-.244.099-.386.072-.314.16-.765.133-.692.144-.795.322-1.896.116-.662c.411-2.2 1.068-4.347 1.957-6.402l.164-.39c-.224.019-.45.029-.68.029-3.545 0-6.547-2.327-7.56-5.537l-1.34-.948c-.242-.093-.473-.221-.682-.385l-.16-.117c-2.735-1.936-6.589-.596-7.47 3.58-.173.819.107 2.075.84 3.768l.26.62c.843 2.073 1.451 4.234 1.813 6.444l.113.677L7 25.454l.185.992.125.624.115.522.102.42.063.224.057.178c.062.174.142.322.236.445.362.17.734.323 1.115.458-.391.067-.82-.07-1.115-.458C3.815 26.937 1 22.797 1 18v-4C1 7.373 6.373 2 13 2h6zm1.28.667c-3.285 0-5.947 2.662-5.947 5.946s2.662 5.946 5.947 5.946c1.472 0 2.826-.54 3.87-1.436l.246.256v.723l4.574 4.565 1.363-1.363-4.565-4.574h-.722l-.256-.247.174-.213c.792-1.011 1.262-2.282 1.262-3.657 0-3.284-2.662-5.946-5.946-5.946zm0 1.83c2.277 0 4.116 1.838 4.116 4.116 0 1.516-.814 2.838-2.03 3.552l-.131.071-.065-.254c-.863-3.095-3.447-4.445-5.796-3.841l-.19.053.023-.19c.294-1.988 2.002-3.508 4.073-3.508z"
            transform="translate(-1061.000000, -369.000000) translate(1061.000000, 369.000000)"
          />
          <path
            fill="url(#pnvgl07rxa)"
            d="M25.768 12.73h-.722l-.256-.247c.896-1.043 1.436-2.397 1.436-3.87 0-3.284-2.662-5.946-5.946-5.946-3.285 0-5.947 2.662-5.947 5.946s2.662 5.946 5.947 5.946c1.472 0 2.826-.54 3.87-1.436l.246.256v.723l4.574 4.565 1.363-1.363-4.565-4.574zm-5.488 0c-2.278 0-4.117-1.84-4.117-4.117 0-2.278 1.839-4.117 4.117-4.117 2.277 0 4.116 1.839 4.116 4.117s-1.839 4.117-4.116 4.117z"
            transform="translate(-1061.000000, -369.000000) translate(1061.000000, 369.000000)"
          />
          <path
            fill="url(#tkupvyadub)"
            d="M11.186 8.87l.16.117c.21.164.44.292.683.385l1.338.948c1.014 3.21 4.016 5.537 7.561 5.537.23 0 .456-.01.68-.03l-.164.391c-.89 2.055-1.546 4.203-1.957 6.402l-.116.662-.322 1.896-.144.795-.133.692-.16.765-.072.314-.1.386-.073.243-.014.04c-.472 1.334-2.029 1.115-2.292 0l-1.67-7.195c-.162-.527-.651-.913-1.21-.944h-.231c-.613-.028-1.167.377-1.342.944L9.94 28.414c-.263 1.114-1.82 1.333-2.292 0l-.057-.178-.063-.224-.102-.42-.115-.522-.125-.624L7 25.454l-.258-1.495-.113-.677c-.362-2.21-.97-4.371-1.813-6.445l-.26-.619c-.733-1.693-1.013-2.949-.84-3.769.881-4.175 4.735-5.515 7.47-3.58zm11.05 3.367l-.046.025c-.57.299-1.22.468-1.91.468-2.278 0-4.117-1.84-4.117-4.117 0-.142.007-.281.02-.42 2.462-.747 5.243.683 6.053 4.044z"
            transform="translate(-1061.000000, -369.000000) translate(1061.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default OralexamnIcon;
