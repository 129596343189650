var callbacks = {};

const setOnMessageCallback = (type, callback) => {
  callbacks[type] = callback;
  // console.log(callbacks);
};

const isPlatformSupported = () => {
  const platform = navigator.platform;
  if (platform.includes("Win", 0)) return "";
  return platform;
};

const platformSupported = isPlatformSupported();

const getPlatformSupported = () => platformSupported;

export {
    setOnMessageCallback,
    callbacks,
    isPlatformSupported,
    getPlatformSupported
};

