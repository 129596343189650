import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CCTextFieldDuo } from "styles/components";

const bankList = [
  {
    value: "ACB",
    label: "Asia Commercial Joint Stock Bank"
  },
  {
    value: "TPBank",
    label: "Tien Phong Bank"
  },
  {
    value: "DAB",
    label: "DongA Bank"
  },
  {
    value: "SeABank",
    label: "South East Asia Bank"
  },
  {
    value: "BacABank",
    label: "Bac A Bank"
  },
  {
    value: "VPBank",
    label: "Vietnam Prosperity Bank"
  },
  {
    value: "VAB",
    label: "Viet A Bank"
  }
];

const BankField = props => {
  const {
    margin,
    variant,
    bankClassName,
    accountClassName,
    bankLabel,
    accountLabel,
    onChange,
    errorOnChange,
    bankValue,
    accountNumber,
    required,
    errorBank,
    errorAccount,
    ...others
  } = props;

  const [bankName, setBankName] = useState(bankValue);
  const [account, setAccount] = useState(accountNumber);
  const [isDirectInput, setIsDirectInput] = useState(false);
  const [errorLeft, setErrorLeft] = useState(false);
  const [errorRight, setErrorRight] = useState(false);
  useEffect(() => {
    errorOnChange(errorLeft || errorRight);
  }, [errorOnChange, errorLeft, errorRight]);

  const handleOnChange = () => {
    onChange({ bank: bankName, account: account });
  };
  const onChangeNumber = (newValue, setValue) => {
    if (!isNaN(newValue)) {
      var trimmed = newValue.replace(/\.|\s/g, "");
      setValue(trimmed);
    }
  };

  useEffect(() => {
    if (bankValue) {
      setBankName(bankValue);
      if (!bankList.map(({ value }) => value).includes(bankValue)) {
        setIsDirectInput(true);
      }
      setErrorLeft(false);
    } else {
      setBankName("");
      setIsDirectInput(false);
      required && setErrorLeft(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankValue]);
  useEffect(() => {
    if (accountNumber) {
      setAccount(accountNumber);
      setErrorRight(false);
    } else {
      setAccount("");
      required && setErrorRight(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber]);

  return (
    <Grid container wrap={"nowrap"}>
      <CCTextFieldDuo
        LeftProps={{
          // component: (
          //   <CCTextFieldDualType
          className: bankClassName,
          margin: margin,
          required: required,
          variant: variant,
          label: bankLabel,
          onBlur: handleOnChange,
          directinput: isDirectInput.toString(),
          value: bankName,
          error: errorLeft,
          onChange: ({ target }) => {
            setBankName(target.value);
            setIsDirectInput(!target.select);
          },
          tooltipProps: {
            arrow: true,
            placement: "top",
            title: bankName
          },
          InputProps: {
            inputProps: {
              maxLength: 50
            }
            // style: { margin: "8px 0" }
          }
          // children: {bankList.map(item => (,
          //   <MenuItem key={item.value} value={item.value}>
          //     {item.label}
          //   </MenuItem>
          // ))}
          // />
          // )
        }}
        RightProps={{
          className: accountClassName,
          margin: margin,
          variant: variant,
          label: accountLabel,
          // error: errorAccount,
          required: required,
          error: errorRight,
          tooltipProps: {
            arrow: true,
            placement: "top",
            title: account
          },
          InputProps: {
            inputProps: {
              maxLength: 20
            },
            style: { imeMode: "disabled" }
          },
          onBlur: handleOnChange,
          value: account,
          onChange: ({ target }) => {
            onChangeNumber(target.value, setAccount);
          }
        }}
        {...others}
      />
    </Grid>
  );
};

BankField.defaultProps = {};

BankField.propTypes = {
  onChange: PropTypes.func,
  bank: PropTypes.string,
  accountNumber: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default BankField;
