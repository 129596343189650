import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { CCTypography } from "styles/components";
import FileMessage from "../FileMessage";
import ImageMessage from "../ImageMessage";
import TextMessage from "../TextMessage";
import AudioMessage from "../AudioMessage";

const useStyles = makeStyles(theme => ({
  root: { width: "100%" },
  container: {
    display: "flex",
    marginTop: 26
  },
  message__container: {
    display: "flex",
    alignItems: "center",
    minWidth: 50,
    maxWidth: "calc(100% - 50px)"
  },
  name: {
    marginLeft: 8,
    marginBottom: 4,
    color: theme.palette.sub.main
  },
  message: {
    borderRadius: 3,
    padding: 8,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  sender_bg: {
    backgroundColor: theme.palette.secondary.main
  },
  time: {
    marginTop: 20,
    color: theme.palette.sub.main
  },
  row__reverse: {
    flexDirection: "row-reverse"
  },
  row: {
    flexDirection: "row"
  },
  ml8: {
    marginLeft: 8
  },
  mr8: {
    marginRight: 8
  }
}));

const renderMessage = message => {
  const messageType = message.attachments[0]?.contentType;
  switch (messageType) {
    case "PNG":
    case "JPG":
    case "JPEG":
      return <ImageMessage {...message} />;
    case "ZIP":
    case "STL":
    case "DCM":
      return <FileMessage {...message} />;
    case "opus":
      return <AudioMessage {...message} />;
    default:
      return <TextMessage {...message} />;
  }
};

const GroupMessageList = props => {
  const { items, meId, labId, endOfGroup, cursorIDSearchMessage } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {items.map((item, index) => {
        const isFromSender = "lab-" + meId === item.from.id;

        return (
          <Box
            key={index}
            className={clsx(
              classes.container,
              isFromSender ? classes.row__reverse : classes.row
            )}
          >
            <Box
              className={clsx(
                classes.message__container,
                isFromSender ? classes.row__reverse : classes.row
              )}
            >
              <Box>
                <CCTypography variant="body2" className={classes.name}>
                  {item.from.name.replace(/clever-|lab-/gi, "")}
                </CCTypography>
                {renderMessage({
                  ...item,
                  messageSearched: cursorIDSearchMessage === item.messageId,
                  key: item.id,
                  meId,
                  labId,
                  endOfList: endOfGroup && index === items.length - 1,
                  isFromSender: isFromSender,
                  styles: clsx(classes.message, {
                    [classes.sender_bg]: isFromSender
                  })
                })}
              </Box>
              <CCTypography
                variant="body2"
                className={clsx(
                  classes.time,
                  isFromSender ? classes.mr8 : classes.ml8
                )}
              >
                {moment(item.timestamp, "X").format("LT")}
              </CCTypography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default GroupMessageList;
