import React from "react";

const CrmnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="jkwjjsvx9a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#D8D8D8"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-801.000000, -369.000000) translate(801.000000, 369.000000)"
          />
          <path
            fill="url(#jkwjjsvx9a)"
            d="M5 1h22c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4H5c-2.21 0-4-1.79-4-4V5c0-2.21 1.79-4 4-4z"
            transform="translate(-801.000000, -369.000000) translate(801.000000, 369.000000)"
          />
          <path
            fill="#00BFA5"
            d="M28.333 19.667c.553 0 1 .447 1 1V28c0 .552-.447 1-1 1h-10c-.552 0-1-.448-1-1v-7.333c0-.553.448-1 1-1h10zM28 21l-4.667 2.667L18.667 21v1.333L23.333 25 28 22.333V21z"
            transform="translate(-801.000000, -369.000000) translate(801.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M14.667 5c-2.947 0-5.334 2.387-5.334 5.333 0 2.947 2.387 5.334 5.334 5.334 2.946 0 5.333-2.387 5.333-5.334C20 7.387 17.613 5 14.667 5zm0 12c1.256 0 2.954.222 4.625.666h-2.959c-.513 0-.935.387-.993.884l-.007.117v7.666H4v-4c0-2.321 3.045-3.889 6.147-4.694l.517-.128c.086-.02.172-.04.258-.058l.512-.106.507-.092.496-.078.244-.034.476-.056.232-.023.449-.036.216-.012.415-.014.198-.002z"
            transform="translate(-801.000000, -369.000000) translate(801.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default CrmnIcon;
