import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getCustomFormat } from "utils/datetime";
import { translate } from "components";
import * as yup from "yup";
const findDuplicates = arr =>
  arr.filter((item, index) => arr.indexOf(item) !== index);
const useProductLogValidation = () => {
  const intl = useIntl();
  const schema = useMemo(() => {
    return yup.object({
      productName: yup.string(),
      major: yup.string(),
      receivingCount: yup.number(),
      remarks: yup.string(),
      // binCnt: yup.nullable(),
      retailPrice: yup.number().nullable(),
      product: yup.mixed(),
      detailInformation: yup.string(),
      inOut: yup.boolean(),
      exceed: yup.boolean(),
      currentCnt: yup.string().test((value, context) => {
        if ((!value || Number(value) === 0) && !context?.parent?.inOut) {
          return context.createError({
            message: `${intl.formatMessage({
              id: "plzEnterIncomingCnt"
            })}`
          });
        } else {
          return true;
        }
      }),
      currentCntOut: yup.string().test((value, context) => {
        console.log("context?.parent", context?.parent, value)
        if ((!value || Number(value) === 0) && context?.parent?.inOut) {
          return context.createError({
            message: `${intl.formatMessage({
              id: "plzEnterOutgoingCnt"
            })}`
          })

        } else {
          return true;
        }
      }),

      expireList: yup.array().test("expireCntError", (value, context) => {
        console.log("expireCntError value", value);
        console.log("expireCntError context", context);
        // inOut = true   -> OUTGOING
        // inout = false  -> INCOMING

        if (!context?.parent?.inOut) {
          return validateExpireListIncomming(value, context, intl);
        } else {
          return validateExpireListOutgoing(value, context, intl);
        }

      })
    });
  }, [intl]);
  return { schema };
};

const validateExpireListIncomming = (value, context, intl) => {
  const _currentCnt = Number(context?.parent?.currentCnt);
  const _storageInside = Number(context?.parent?.storage?.currentCnt);
  const _exceedMsg = intl.formatMessage(
    { id: "incomingExpireCntAlert" },
    { cnt: _currentCnt }
  );
  const _expireMsg = intl.formatMessage(
    { id: "incomingExpireCntAlert" },
    { cnt: _currentCnt }
  );

  if (Boolean(context?.parent?.expireList?.length > 0)) {
    const _expireList = context?.parent?.expireList
      ?.map(e => Number(e?.expireCount))
      .reduce((pv, cv) => pv + cv, 0);

    const _duplicateCheck = findDuplicates(
      context?.parent?.expireList?.map(e =>
        getCustomFormat(e?.expireDate, "X", "L")
      )
    );

    if (Boolean(_duplicateCheck?.length > 0)) {
      return context.createError({
        message: `${intl.formatMessage({ id: "duplicateDateAlert" })}`
      });
    }

    if (_currentCnt > 0) {
      if (_currentCnt < _expireList) {
        return context.createError({
          message: _exceedMsg
        });
      } else if (_currentCnt > _expireList) {
        return context.createError({
          message: _expireMsg
        });
      }
    } else {
      if (_storageInside > 0) {
        if (_storageInside < _expireList) {
          return context.createError({
            message: _exceedMsg
          });
        } else if (_storageInside > _expireList) {
          return context.createError({
            message: _expireMsg
          });
        }
      }
    }
  }

  return true;
}

const validateExpireListOutgoing = (value, context, intl) => {
  const _currentCnt = Number(context?.parent?.currentCntOut);
  const _storageInside = Number(context?.parent?.storage?.currentCnt);
  if (_currentCnt > _storageInside) {
    return context.createError({
      message: translate(intl, "inputCntExceedCurrentCount", { count: _storageInside })
    });
  }
  const _exceedMsg = intl.formatMessage({ id: "outgoingSameWithExpireAlert" })
  const __expireList = context?.parent?.expireListOut || [];
  const _expireMsg = intl.formatMessage(
    { id: "outgoingSameWithExpireAlert" },
    { cnt: _currentCnt }
  )
  console.log("validateExpireListOutgoing _currentCnt", _currentCnt);
  console.log("validateExpireListOutgoing _storageInside", _storageInside);
  console.log("validateExpireListOutgoing __expireList", __expireList);
  if (Boolean(context?.parent?.expireList?.length > 0)) {
    const _expireListSumCount = __expireList
      ?.map(e => Number(e?.count))
      .reduce((pv, cv) => pv + cv, 0);
    console.log("validateExpireListOutgoing _expireListSumCount", _expireListSumCount)
    const _duplicateCheck = findDuplicates(
      __expireList.map(e =>
        getCustomFormat(e?.expireDate, "X", "L")
      )
    );
    console.log("validateExpireListOutgoing _duplicateCheck", _duplicateCheck)
    if (Boolean(_duplicateCheck?.length > 0)) {
      return context.createError({
        message: `${intl.formatMessage({ id: "duplicateDateAlert" })}`
      });
    }

    if (_currentCnt > 0) {
      console.log("validateExpireListOutgoing _currentCnt > 0")
      if (_currentCnt < _expireListSumCount) {
        console.log("validateExpireListOutgoing _currentCnt < _expireList")
        return context.createError({
          message: _exceedMsg
        });
      } else if (_currentCnt > _expireListSumCount) {
        console.log("validateExpireListOutgoing _currentCnt > _expireList")
        return context.createError({
          message: _expireMsg
        });
      }
    } else {
      console.log("validateExpireListOutgoing _currentCnt != 0")
      if (_storageInside > 0) {
        console.log("validateExpireListOutgoing _storageInside > 0")
        if (_storageInside < _expireListSumCount) {
          console.log("validateExpireListOutgoing _storageInside < _expireList")
          return context.createError({
            message: _exceedMsg
          });
        } else if (_storageInside > _expireListSumCount) {
          console.log("validateExpireListOutgoing _storageInside > _expireList")
          return context.createError({
            message: _expireMsg
          });
        }
      }
    }
  }
  return true;
}

export default useProductLogValidation;
