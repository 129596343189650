import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { IconMenu } from '../';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', width: '100%', justifyContent: 'space-between' },
  left: { display: 'flex' },
  ma: { alignItems: 'center', display: 'flex', width: 200 },
}));

const FullSizeInfo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left}></div>
      <div>
        <IconMenu />
      </div>
    </div>
  );
};

export default FullSizeInfo;
