import React from "react";

const SentIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M17.5 12c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm0 1c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 1l3 3-3 3-.705-.705 1.79-1.795H14.5v-1h4.085l-1.79-1.795L17.5 14zM19 1.5c1.05 0 1.918.82 1.994 1.851L21 3.5l.001 8.023c-1.01-.648-2.212-1.023-3.501-1.023-3.59 0-6.5 2.91-6.5 6.5 0 .168.006.335.019.5H3c-1.05 0-1.918-.82-1.994-1.851L1 15.5l.01-12c0-1.05.81-1.918 1.841-1.994L3 1.5h16zm0 2l-8 5-8-5v2l8 5 8-5v-2z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default SentIcon;
