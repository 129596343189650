import { foldNumber } from "./claim";

const linear = [
  0,
  18,
  17,
  16,
  15,
  14,
  13,
  12,
  11,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  0,
  48,
  47,
  46,
  45,
  44,
  43,
  42,
  41,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  0,
  55,
  54,
  53,
  52,
  51,
  61,
  62,
  63,
  64,
  65,
  0,
  85,
  84,
  83,
  82,
  81,
  71,
  72,
  73,
  74,
  75
];

function getTeethList(indices) {
  const adults = [
    0,
    18,
    17,
    16,
    15,
    14,
    13,
    12,
    11,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    0,
    48,
    47,
    46,
    45,
    44,
    43,
    42,
    41,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38
  ];

  var list = [];

  for (var index = 0; index < adults.length; index++) {
    if (adults[index] === 0) {
      list.push(adults[index]);
    } else if (indices.includes(adults[index])) {
      list.push(adults[index]);
    } else {
      list.push(adults[index] + 40);
    }
  }

  return list;
}

function getLinearFromTeeth(numbers, indices = linear) {
  return numbers
    .map(number => {
      if (indices.includes(number)) {
        return indices.indexOf(number);
      } else if (number < 50) {
        return indices.indexOf(number + 40);
      } else {
        return indices.indexOf(number - 40);
      }
    })
    .sort((a, b) => {
      return a - b;
    });
}

function getTeethFromLinear(numbers, indices = linear) {
  return numbers.map(index => indices[index]);
}

function getTeethGroup(numbers, equal, least, indices = linear) {
  var sequences = getLinearFromTeeth(numbers, indices).concat([99999]);
  var group = [];
  var array = [];

  sequences.forEach(index => {
    if (array.length === 0 || array[array.length - 1] + 1 === index) {
      array.push(index);
    } else if (least && array.length >= least) {
      group.push(getTeethFromLinear(array, indices));
      array = [index];
    } else {
      array = [index];
    }

    if (equal && array.length === equal) {
      group.push(getTeethFromLinear(array, indices));
      array = [index];
    }
  });

  return group;
}

function getTeethSME(numbers, indices = linear) {
  var list = getTeethFromLinear(getLinearFromTeeth(numbers, indices), indices);

  return [list[0], list[list.length - 1], ...list.slice(1, list.length - 1)];
}

function getTeethString(numbers, indices = linear) {
  var sequences = getLinearFromTeeth(numbers, indices).concat([99999]);
  var group = "";
  var array = [];

  sequences.forEach(index => {
    if (array.length === 0 || array[array.length - 1] + 1 === index) {
      array.push(index);
    } else {
      const list = getTeethFromLinear(array, indices);

      if (group.length === 0) {
        group += "#";
      } else {
        group += ",";
      }

      if (list.length === 1) {
        group += `${list[0]}`;
      } else {
        group += `${list[0]}-${list[list.length - 1]}`;
      }

      array = [index];
    }
  });

  return group;
}

function getTeethFormat(teeth) {
  let _res = [];
  let _temp = [...teeth].sort();
  let _teethUnit = {
    PERMANENT_TEETH: {
      UPPER_JAW: [
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28
      ],
      LOWER_JAW: [
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48
      ],
      FULL_JAWS: [
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48
      ]
    },
    BABY_TEETH: {
      UPPER_JAW: [51, 52, 53, 54, 55, 61, 62, 63, 64, 65],
      LOWER_JAW: [71, 72, 73, 74, 75, 81, 82, 83, 84, 85],
      FULL_JAWS: [
        51,
        52,
        53,
        54,
        55,
        61,
        62,
        63,
        64,
        65,
        71,
        72,
        73,
        74,
        75,
        81,
        82,
        83,
        84,
        85
      ]
    }
  };
  if (teeth.length === 0) {
    return "";
  }
  if (_temp.findIndex(item => item < 60) > -1) {
    if (checkTeeth(_temp, _teethUnit.PERMANENT_TEETH.FULL_JAWS)) {
      _res.push("영구치 전악");
      _temp = deleteTeeth(_temp, _teethUnit.PERMANENT_TEETH.FULL_JAWS);
    } else {
      if (checkTeeth(_temp, _teethUnit.PERMANENT_TEETH.UPPER_JAW)) {
        _res.push("영구치 상악");
        _temp = deleteTeeth(_temp, _teethUnit.PERMANENT_TEETH.UPPER_JAW);
      }
      if (checkTeeth(_temp, _teethUnit.PERMANENT_TEETH.LOWER_JAW)) {
        _res.push("영구치 하악");
        _temp = deleteTeeth(_temp, _teethUnit.PERMANENT_TEETH.LOWER_JAW);
      }
    }
  }
  if (_temp.findIndex(item => item > 60) > -1) {
    if (checkTeeth(_temp, _teethUnit.BABY_TEETH.FULL_JAWS)) {
      _res.push("유치 전악");
      _temp = deleteTeeth(_temp, _teethUnit.BABY_TEETH.FULL_JAWS);
    } else {
      if (checkTeeth(_temp, _teethUnit.BABY_TEETH.UPPER_JAW)) {
        _res.push("유치 상악");
        _temp = deleteTeeth(_temp, _teethUnit.BABY_TEETH.UPPER_JAW);
      }
      if (checkTeeth(_temp, _teethUnit.BABY_TEETH.LOWER_JAW)) {
        _res.push("유치 하악");
        _temp = deleteTeeth(_temp, _teethUnit.BABY_TEETH.LOWER_JAW);
      }
    }
  }

  const teethText = foldNumber(_temp);
  if (_res.length && teethText[0].length) {
    return _res.join(", ") + ", " + teethText.join(", ");
  } else if (!_res.length) {
    return teethText.join(", ");
  } else {
    return _res.join(", ");
  }
}
function checkTeeth(teeth, checkField) {
  return checkField.reduce((acc, cur) => {
    if (teeth.findIndex(item => cur === item) < 0) {
      acc = false;
    }
    return acc;
  }, true);
}
function deleteTeeth(source, target) {
  return source.reduce((acc, cur) => {
    if (target.findIndex(item => cur === item) < 0) {
      acc.push(cur);
    }
    return acc;
  }, []);
}

export {
    getTeethList,
    getTeethGroup,
    getTeethSME,
    getTeethString,
    getTeethFormat
};

