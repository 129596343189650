const LAB_ORDER_TYPE = {
  NEW_REQUEST: { value: "1", localeKey: "labOrder.newRequest" },
  MODIFY_REQUEST: { value: "2", localeKey: "labOrder.modifyRequest" },
  RE_REQUEST: { value: "3", localeKey: "labOrder.reRequest" }
};

const LAB_ORDER_STATE = {
  ORDERED: { value: "1", localeKey: "labOrder.ordered" },
  CAD_DESIGN: { value: "2", localeKey: "labOrder.cadDesign" },
  CONFIRM_REQUEST: { value: "3", localeKey: "labOrder.confirmRequest" },
  CONFIRMED: { value: "4", localeKey: "labOrder.confirmed" },
  IN_PROD: { value: "5", localeKey: "labOrder.inProd" },
  COMPLETE: { value: "6", localeKey: "labOrder.complete" },
  SHIPPED: { value: "7", localeKey: "labOrder.shipped" }
};

const LAB_ORDER_DETAIL_STATE = {
  ORDERED: { value: "1", localeKey: "labOrder.ordered" },
  CAD_DESIGN: { value: "2", localeKey: "labOrder.cadDesign" },
  IN_PROD: { value: "5", localeKey: "labOrder.inProd" },
  COMPLETE: { value: "6", localeKey: "labOrder.complete" },
  SHIPPED: { value: "7", localeKey: "labOrder.shipped" }
};

const LAB_ORDER_FILTER_STATE = {
  ORDERED: { value: "1", localeKey: "labOrder.ordered" },
  CAD_DESIGN: { value: "2", localeKey: "labOrder.filterCadDesign" },
  CONFIRM_REQUEST: { value: "3", localeKey: "labOrder.filterConfirmRequest" },
  CONFIRMED: { value: "4", localeKey: "labOrder.confirmed" },
  IN_PROD: { value: "5", localeKey: "labOrder.filterInProd" },
  COMPLETE: { value: "6", localeKey: "labOrder.filterComplete" },
  SHIPPED: { value: "7", localeKey: "labOrder.shipped" }
};

const LAB_ARRIVE_TYPE = {
  ARRIVE: { value: "1", localeKey: "labOrder.arrive" },
  NON_ARRIVE: { value: "2", localeKey: "labOrder.awaiting" }
};

const LAB_ARRIVE_TYPE_FOR_STATUS = {
  ARRIVE: {
    value: LAB_ARRIVE_TYPE.ARRIVE.value,
    localeKey: "labOrder.arrive1"
  },
  NON_ARRIVE: {
    value: LAB_ARRIVE_TYPE.NON_ARRIVE.value,
    localeKey: "labOrder.nonArrive1"
  }
};
const LAB_ARRIVE_TYPE_FOR_CONTEXT_MENU = {
  ARRIVE: {
    value: LAB_ARRIVE_TYPE.ARRIVE.value,
    localeKey: "labOrder.arrive1"
  },
  NON_ARRIVE: {
    value: LAB_ARRIVE_TYPE.NON_ARRIVE.value,
    localeKey: "labOrder.nonArrive1"
  },
  DETAIL: { value: "detail", localeKey: "labOrder.detail" }
};

const MAIL_CHECK_STATE = {
  CHECK: { value: "1", localeKey: "labOrder.check" },
  NON_CHECK: { value: "2", localeKey: "labOrder.nonCHeck" }
};

const NOTE_STATE = {
  CHECK: { value: "1", localeKey: "labOrder.check" },
  NON_CHECK: { value: "2", localeKey: "labOrder.nonCHeck" }
};

const TARGET = {
  CLEVER_LAB: { value: "1", localeKey: "labOrder.clever" },
  NON_CLEVER_LAB: { value: "2", localeKey: "labOrder.nonClever" }
};

const DESIGN_CONFIRM_STATUS = {
  CONFIRM: { value: "1", localeKey: "labOrder.confirm" },
  REQUEST: { value: "2", localeKey: "labOrder.request" },
  MEND: { value: "3", localeKey: "labOrder.mend" }
};

export {
  LAB_ORDER_TYPE,
  LAB_ORDER_STATE,
  LAB_ORDER_FILTER_STATE,
  MAIL_CHECK_STATE,
  NOTE_STATE,
  LAB_ORDER_DETAIL_STATE,
  TARGET,
  LAB_ARRIVE_TYPE,
  LAB_ARRIVE_TYPE_FOR_STATUS,
  LAB_ARRIVE_TYPE_FOR_CONTEXT_MENU,
  DESIGN_CONFIRM_STATUS
};
