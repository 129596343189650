import { CircularProgress, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { CCIconButton, CCTextField } from 'styles/components';
import { SearchIcon } from 'styles/icons';
import translate from '../Translate';

const useStyles = makeStyles(() => ({
  root: {},
  adorned__end: { paddingRight: 8 },
}));

const SearchTextField = forwardRef((props, ref) => {
  const {
    className,
    onIconClick = () => { },
    onKeyPress,
    loading = false,
    placeholder,
    label,
    ...others
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const pandding = () => { };

  return (
    <CCTextField
      className={className}
      placeholder={placeholder || translate(intl, "ccSearch")}
      variant='outlined'
      margin='dense'
      label={label || translate(intl, "ccSearch")}
      InputProps={{
        classes: {
          adornedEnd: classes.adorned__end,
        },

        endAdornment: (
          <InputAdornment position='end'>
            <CCIconButton
              size='small'
              onClick={loading ? pandding : onIconClick}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                <SearchIcon />
              )}
            </CCIconButton>
          </InputAdornment>
        ),
      }}
      onKeyPress={loading ? pandding : onKeyPress}
      {...others}
    />
  );
});

export default SearchTextField;
