import { DialogPermissionWarn } from 'components';
import React, { forwardRef, useState } from 'react';
import { CCTextField } from 'styles/components';
import { CCTooltip } from "styles/src/components";

const CVTextField = forwardRef((props, ref) => {
  const {
    children,
    permission,
    onChange,
    onKeyDown,
    onKeyPress,
    onClickIcon,
    value,
    select,
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const isPermission = true;

  const handleChange = () => {
    setOpen(true);
  };

  return (
    <>
    <CCTooltip placement={"top"} 
        arrow title={select ? "" : (value || "").toString()}
    >
      <CCTextField
        ref={ref}
        value={value}
        select={select}
        onChange={isPermission ? onChange : handleChange}
        onKeyDown={isPermission ? onKeyDown : handleChange}
        onKeyPress={isPermission ? onKeyPress : handleChange}
        onClickIcon={isPermission ? onClickIcon : handleChange}
        {...others}
      >
        {children}
      </CCTextField>
      </CCTooltip>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      
    </>
  );
});

export default CVTextField;
