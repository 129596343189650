import { Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { AddIcon } from "../../themes/common/icons";
import { CCTooltip } from "../../components";
import { CCChips } from "./Component";

// const useStyles = makeStyles(theme => ({
//   root: { height: "100%" },
//
//   chips: {
//     color: theme.palette.icon,
//     backgroundColor: "white",
//     border: `1px solid ${theme.palette.border.main}`
//   }
// }));

const useTabsStyles = makeStyles(theme => ({
  root: {
    minHeight: 24,
    alignItems: "center",
    backgroundColor: props => props.backgroundColor
  },
  flexContainer: {
    padding: "0 8px"
  },
  scrollButtons: {
    width: 30,
    color: props =>
      props.scrollButtonsColor ? props.scrollButtonsColor : "rgb(38, 50, 56)",
    height: 30,
    borderRadius: "50%",
    marginRight: 4,
    marginLeft: 4
  },
  indicator: { width: "0 !important" }
}));
const useTabStyles = makeStyles(theme => ({
  root: {
    minWidth: 50,
    minHeight: 24,
    margin: "6px 4px",
    padding: 0,
    borderRadius: 25,
    opacity: 1
  }
}));

const CCTabsChips = props => {
  const {
    tabList,
    value = 0,
    onChange,
    onClickIcon,
    onClickAdd,
    onClickMod,
    onClickDel,
    addLabel = "add",
    backgroundColor = "transparent",
    scrollButtonsColor
  } = props;

  const tabsClasses = useTabsStyles({
    backgroundColor: backgroundColor,
    scrollButtonsColor: scrollButtonsColor
  });
  const tabClasses = useTabStyles();

  return (
    <Tabs
      classes={tabsClasses}
      variant="scrollable"
      scrollButtons="auto"
      value={value}
      onChange={(e, v) => {
        if (tabList.length === v) {
          onClickAdd && onClickAdd();
          return;
        }
        onChange && onChange(v);
      }}
    >
      {tabList.map((el, index) => {
        return (
          <CCTooltip
            title={el.label}
            placement="top"
            arrow
            key={el?.id ? el.id : index}
          >
            <Tab
              classes={tabClasses}
              label={
                <CCChips
                  useAvatar={Boolean(el.hasOwnProperty("avatar"))}
                  avatar={el?.avatar}
                  chipIcon={el?.icon}
                  onClickIcon={
                    onClickIcon
                      ? () => {
                          onClickIcon(index);
                        }
                      : null
                  }
                  onClickMod={
                    onClickMod
                      ? () => {
                          onClickMod(index);
                        }
                      : null
                  }
                  onClickDel={
                    onClickDel
                      ? () => {
                          onClickDel(index);
                        }
                      : null
                  }
                  selected={value === index}
                >
                  {el.label}
                </CCChips>
              }
            />
          </CCTooltip>
        );
      })}
      {onClickAdd && (
        <CCTooltip title={addLabel} placement="top" arrow>
          <Tab
            classes={tabClasses}
            label={<CCChips chipIcon={<AddIcon />}>{addLabel}</CCChips>}
          />
        </CCTooltip>
      )}
    </Tabs>
  );
};
export default CCTabsChips;
