import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.topbar.height,
    height: "100%",
    minWidth: 1024,
    minHeight: 850
  },
  contentsShift: {
    marginLeft: theme.sidebar.width,
    width: `calc(100% - ${theme.sidebar.width}px)`
  }
}));

const Contents = props => {
  const { open, isDesktop, children } = props;
  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.contentsShift]: open && isDesktop
      })}
    >
      {children}
    </Box>
  );
};

export default Contents;
