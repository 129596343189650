// import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMutation, useQuery } from "@apollo/client";
import { Box, makeStyles } from "@material-ui/core";
import {
  enqueueToastSave,
  translate,
  useWritePermission,
  DialogPermissionWarn,
  CVTreeExp
} from "components";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
// import {
//   GET_PARTNERS_SETTING,
//   UPDATE_PARTNERS_SETTING,
// } from 'queries/partnerConfig';
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCDialogContent,
  CCDialogTitle,
  CCPaper
} from "styles/components";
import { CVIconButton } from "../../../../../components";
import { InputPanel } from "./components";

const GET_CONFIG_PARTNER = gql`
  query getConfig($labId: String, $category: String) {
    getConfig(labId: $labId, category: $category) {
      id
      category
      labId
      otherPartnerSetting {
        id
        name
        state
        items {
          id
          name
          state
          item {
            name
            ein
            tel
            fax
            email
            bank
            bankAccountNo
            holder
            manager
            managerCellNo
            notes
          }
        }
      }
    }
  }
`;

const UPDATE_CROWN_SETTING_CONFIG = gql`
  mutation createProsthesisPriceSetting(
    $input: CreateOtherPartnerSettingInput!
  ) {
    createOtherPartnerSetting(input: $input) {
      code
      message
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: { height: "100%", width: "100%" },
  title: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  content: {
    height: `calc(100% - 61px)`,
    flowDirection: "row",
    overflowY: "hidden !important",
    display: "flex"
  },
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  container__files: {
    height: "100%",
    width: "258px"
  },
  container__input: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  panel: {
    height: "539px",
    width: "624px",
    margin: "19px 33px"
  },
  icon: { margin: 0, color: theme.palette.sub.main },
  panel__content: {
    height: "calc( 100% - 68px - 68px - 32px )"
  },
  input: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%"
  },
  left__field: {
    width: "98px"
  },
  right__domain: {
    width: "171px"
  },
  right__partners: {
    width: "329px"
  }
}));

const PartnerSetting = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  // value
  const [content, setContent] = useState([]);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [selected, setSelected] = useState([]);
  const permission = "otherPartnersPartnersSetting";
  const isPermission = useWritePermission(permission);
  const [errorPermission, setErrorPermission] = useState(false);
  // graphql
  const { data, refetch } = useQuery(GET_CONFIG_PARTNER, {
    variables: {
      category: "otherPartnerSetting",
      labId: localStorage.getItem("labId")
    }
  });
  const [updatePartners] = useMutation(UPDATE_CROWN_SETTING_CONFIG, {
    onCompleted: () => {
      enqueueToastSave(intl, enqueueSnackbar);
      refetch();
    }
  });

  // function
  const onUpdate = updatedItem => {
    if (!selected) {
      return;
    }
    const newContent = content.map((folder, folderIndex) => {
      if (folderIndex === selected[0]) {
        const newItems = folder.items.map((item, itemIndex) => {
          if (itemIndex === selected[1]) {
            return {
              ...item,
              name: updatedItem.name,
              item: updatedItem
            };
          } else {
            return item;
          }
        });
        return {
          ...folder,
          items: newItems
        };
      } else {
        return folder;
      }
    });
    updatePartners({
      variables: {
        input: {
          labId: localStorage.getItem("labId"),
          category: "otherPartnerSetting",
          otherPartnerSetting: deleteTreeTitle(newContent)
        }
      }
    });
    setSelectedItem(updatedItem);
  };
  const onChangeActionCCTree = data => {
    updatePartners({
      variables: {
        input: {
          labId: localStorage.getItem("labId"),
          category: "otherPartnerSetting",
          otherPartnerSetting: deleteTreeTitle(data)
        }
      }
    });
  };
  const deleteTreeTitle = trees => {
    let _trees = JSON.parse(JSON.stringify(trees));
    _trees.forEach(eachTreeItem => {
      if (eachTreeItem.hasOwnProperty("item")) {
        if (eachTreeItem.item.hasOwnProperty("title")) {
          delete eachTreeItem.item["title"];
        }
        eachTreeItem.item.name = eachTreeItem.name;
      }
      if (eachTreeItem.hasOwnProperty("state")) {
        eachTreeItem.state = false;
      }
      if (eachTreeItem.hasOwnProperty("items")) {
        eachTreeItem.items = deleteTreeTitle(eachTreeItem.items);
      }
    });
    return _trees;
  };

  // useEffect
  useEffect(() => {
    if (data && data.getConfig && data.getConfig.otherPartnerSetting) {
      if (data.getConfig.otherPartnerSetting) {
        setContent(data.getConfig.otherPartnerSetting);
      }
    }
  }, [data]);

  return (
    <Box className={classes.root}>
      <CCPaper type="normal" style={{ height: "100%", position: "relative" }}>
        <CCDialogTitle className={classes.title}>
          {translate(intl, "common.ui.otherPartners")}
        </CCDialogTitle>
        <CCDialogContent className={classes.content} noPadding={true}>
          <Box>
            <CVTreeExp
              toolbarButton={CVIconButton}
              toolbarButtonProps={{ permission }}
              className={classes.container__files}
              treeData={content}
              totalDepth={2}
              title={translate(intl, "common.ui.partners")}
              initItem={name => ({
                title: name,
                name,
                ein: "",
                tel: "",
                fax: "",
                email: "",
                bank: "",
                bankAccountNo: "",
                holder: "",
                manager: "",
                managerCellNo: "",
                notes: ""
              })}
              onClickItem={(item, history) => {
                setSelectedItem(item.item);
                setSelected(history);
              }}
              onChange={async newItems => {
                const {
                  item: newContent,
                  selected: newSelected,
                  type
                } = newItems;
                const folder = newSelected
                  ? newContent[newSelected[0]]
                  : undefined;
                const item =
                  folder && folder.item
                    ? folder.item[newSelected[1]]
                    : undefined;
                setContent(newContent);
                setSelected(newSelected);
                setSelectedItem(item);

                if (type === "change") {
                  if (!isPermission) {
                    setErrorPermission(true);
                    return;
                  }
                  onChangeActionCCTree(newContent);
                }
              }}
            />
          </Box>
          <Box className={classes.container__input}>
            {selectedItem && selected && (
              <InputPanel
                item={selectedItem}
                onChange={update => {
                  if (!isPermission) {
                    setErrorPermission(true);
                    return;
                  }
                  onUpdate(update);
                  // enqueueToastSave(intl, enqueueSnackbar);
                }}
              />
            )}
          </Box>
        </CCDialogContent>
      </CCPaper>
      <DialogPermissionWarn
        open={errorPermission}
        onClose={() => {
          setErrorPermission(false);
        }}
      />
    </Box>
  );
};

export default PartnerSetting;
