import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVIconButton, InfiniteTable } from "components";
import React from "react";
import { useIntl } from "utils/language";
import { CCTypography } from "styles/src/components";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/utils/TableItems";
import ModifyIcon from "styles/src/themes/common/icons/ModifyIcon";

const tableStyles = makeStyles(() => ({
  table__title: {
    width: 1100,
    padding: "12px 12px 8px 16px",
    textAlign: "center"
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    height: 500,
    backgroundColor: theme.palette.common.white,
    boxSizing: "border-box",
    width: 800,
    textAlign: "center"
  },
  container: { height: "97%" },
  item: {
    display: "inline-flex"
    // alignItems: "center"
  },
  item__table: {
    height: "calc(100% - 60px - 30px)",
    width: "100%",
    // alignItems: "center",
    // textAlign: "center",
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: "8px"
  },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  row__divider: {
    width: 400,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  text__description: {
    color: theme.palette.disabled.hex
  },
  button__sms: {
    width: "110px",
    height: "24px",
    padding: "0px"
  },
  button__filter: {
    minWidth: "100px",
    minHeight: "10px",
    margin: "16px"
  },
  select: { width: "100px", margin: "4px" },
  select__w126: { width: "126px" },
  select__w155: { width: "155px" },
  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center"
  },
  head__field: {
    width: "100px"
  },
  title__table: {
    height: 30,
    display: "space-between",
    textAlign: "left"
  },
  headertwo: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "80px",
    alignItems: "center"
  },
  headerfilter: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  header__table: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  field: {
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    textAlign: "center"
  },
  table: {
    height: "460px",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  shrink: {
    background: theme.palette.common.white,
    paddingRight: "4px"
  },
  button_register: {
    padding: "0px",
    width: "120px",
    margin: "10px"
  },
  button_right: {
    marginTop: "10px",
    marginRight: "10px"
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  icon__star: {
    margin: 12,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  primary__color: {
    color: theme.palette.primary.main
  },
  error__color: {
    color: theme.palette.error.main
  },
  disabled__color: {
    color: theme.palette.disabled.hex
  },
  typo__color: {
    color: theme.palette.select.dark,
    textDecoration: "underline"
  }
}));

const ProductResults = props => {
  const { products } = props;
  const tableClasses = tableStyles();

  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.item__table} item>
          <InfiniteTable
            disableTypography={true}
            // inputRef={ref}
            title={
              <>
                <Box className={classes.title__table}>
                  <CCTypography variant={"h5"} style={{ marginBottom: 15 }}>
                    {intl.formatMessage({ id: "product" })}
                  </CCTypography>
                </Box>
              </>
            }
            classes={tableClasses}
            heads={[
              {
                key: "",
                width: 60,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "rawNumber" })}
                  />
                ),
                label: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "rawNumber" })}
                  />
                ),
                component: ({ rowIndex }) => {
                  return <TableItem label={rowIndex ? rowIndex + 1 : 1} />;
                }
              },
              {
                key: "name",
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "productName" })}
                  />
                ),
                width: 250,
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      className={{
                        [classes.change__color]: cellData === "Quoted"
                      }}
                      label={
                        cellData ? cellData : ""
                        // cellData === "Quote"
                        //   ? // "estimateRequest"
                        //   intl.formatMessage({ id: "request" })
                        //   : // intl.formatMessage(
                        //   //   { id: "estimateRequest" }
                        //   // )
                        //   cellData === "Quoted"
                        //     ? intl.formatMessage({ id: "arrive" })
                        //     : ""
                      }
                    />
                  );
                }
              },
              {
                key: "manufacturer",
                width: 100,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "manufacture" })}
                  />
                ),
                component: ({ cellData }) => {
                  // let _date = new Date(cellData).toString();

                  return <TableItem label={cellData ? cellData : ""} />;
                }
              },
              {
                key: "unit1",
                width: 80,
                headComponent: () => (
                  <TableHeader
                    // label={intl.formatMessage({ id: "partner" })}
                    label={intl.formatMessage({ id: "unit" })}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData : ""} />
                )
              },
              {
                key: "unitPrice",
                width: 120,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "payAmount" })}
                  />
                ),
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      label={
                        Number(cellData) !== 0 ? (
                          cellData
                        ) : (
                          <CCTypography className={classes.disabled__color}>
                            {intl.formatMessage({ id: "requestForQuote" })}
                          </CCTypography>
                        )
                      }
                      // label={cellData?.length > 1 ? `${cellData[0]?.name} 외 ${cellData?.length - 1} 품목` : cellData ? cellData[0]?.name : ""}
                    />
                  );
                }
              },
              {
                key: "expiration",
                width: 200,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "expirationDate" })}
                  />
                ),
                component: ({ cellData, rowData }) => {
                  let _date = cellData === null ? "9999/12/31" : cellData;

                  return (
                    <TableItem
                      label={
                        <>
                          {products?.status === "Ordering" ? (
                            <Grid
                              container
                              direction={"row"}
                              wrap={"nowrap"}
                              justifyContent={"space-between"}
                            >
                              <Grid item style={{ marginTop: 20 }}>
                                <CCTypography className={classes.typo__color}>
                                  {_date}
                                </CCTypography>
                              </Grid>
                              <Grid item style={{ marginBottom: 20 }}>
                                <CVIconButton
                                  color={"sub"}
                                  label={_date}
                                  //   onClick={() => {
                                  //   setOpen(rowData);
                                  // }}
                                >
                                  <ModifyIcon />
                                </CVIconButton>
                              </Grid>
                            </Grid>
                          ) : (
                            <CCTypography>{_date}</CCTypography>
                          )}
                        </>
                      }
                    />
                  );
                }
              }
            ]}
            contents={products?.OrderItems || []}
            rowHoverHightlight={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductResults;
