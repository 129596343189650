import CVIconButton from "components/CVIconButton";
import CVTextField from "components/CVTextField";
import React, { forwardRef, useEffect, useState } from "react";
import SubtractIcon from "styles/src/themes/common/icons/SubtractIcon";

const DeltalLabSettingContentMaterial = forwardRef((props, ref) => {
  const { onDelete, permission, data, onFieldChange } = props;

  // value
  const [errorMaterial, setErrorMaterial] = useState(!data.cellData);

  // useEffect
  useEffect(() => {
    setErrorMaterial(!data.cellData);
  }, [data.cellData]);

  return (
    <>
      <CVIconButton
        permission={permission}
        color={"sub"}
        onClick={() => onDelete(data.rowData.id)}
      >
        <SubtractIcon />
      </CVIconButton>
      <CVTextField
        permission={permission}
        style={{ width: 350 }}
        margin={"dense"}
        required
        variant={"outlined"}
        value={data.cellData}
        error={errorMaterial}
        InputProps={{
          inputProps: {
            maxLength: 200
          }
        }}
        onChange={e => {
          const value = e.target.value;
          setErrorMaterial(!value);
          onFieldChange(data.rowData.id, value, "material");
        }}
      />
    </>
  );
});

DeltalLabSettingContentMaterial.defaultProps = {};

DeltalLabSettingContentMaterial.propTypes = {};

export default DeltalLabSettingContentMaterial;
