import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { getWeek } from "../../../../index";
// import SmalldownIcon from "styles/src/themes/common/icons/SmalldownIcon";
// import SmallupIcon from "styles/src/themes/common/icons/SmallupIcon";

const useStyle = makeStyles(
  theme => ({
    root: {},
    end__adornment: {
      flexDirection: "column",
      margin: 0,
      height: "auto",
      "&:hover": {
        cursor: "pointer"
      }
    },
    up: {
      marginBottom: "5px",
      color: theme.palette.icon
    },
    down: {
      color: theme.palette.icon
    },
    monthInput: {
      width: 140,
      height: 32
    },
    yearInput: {
      width: 80,
      height: 32
    }
  }),
  { name: "CCFlattenDateTimePicker-DatePicker-Header" }
);

function* yearSelectItem() {
  for (let x = 1902; x < 2031; x++) {
    yield (
      <MenuItem key={x} value={x}>
        {moment().year(x).format("YYYY")}
      </MenuItem>
    );
  }
}

const Header = props => {
  const classes = useStyle();
  const { value, onChange, mode, gongdan } = props;
  // console.log("header", props);
  return (
    <>
      <TextField
        variant={"outlined"}
        margin={"dense"}
        value={mode === "day" ? value.year() : value.begin.year()}
        className={clsx(classes.yearInput)}
        select
        onChange={e => {
          // console.log(value);
          if (mode === "day") {
            if (Boolean(gongdan)) {
              onChange &&
                onChange(value.clone().locale("gongdan").year(e.target.value));
            } else {
              onChange && onChange(value.clone().year(e.target.value));
            }
          } else {
            if (Boolean(gongdan)) {
              let _moveMonth = value.begin
                .clone()
                .locale("gongdan")
                .year(e.target.value);
              onChange && onChange(getWeek(_moveMonth));
            } else {
              let _moveMonth = value.begin.clone().year(e.target.value);
              onChange && onChange(getWeek(_moveMonth));
            }
          }
        }}
      >
        {[...yearSelectItem()]}
      </TextField>
      <TextField
        variant={"outlined"}
        value={mode === "day" ? value.month() : value.begin.month()}
        select
        margin={"dense"}
        className={clsx(classes.monthInput)}
        onChange={event => {
          // console.log(event.target.value);
          if (mode === "day") {
            onChange && onChange(value.clone().month(event.target.value));
          } else {
            let _moveMonth = value.begin.clone().month(event.target.value);
            onChange && onChange(getWeek(_moveMonth));
          }
        }}
      >
        {moment
          .localeData()
          .months()
          .map((item, index) => {
            // console.log(item);
            // console.log(index);
            return (
              <MenuItem value={index} key={index + item}>
                {item}
              </MenuItem>
            );
          })}
      </TextField>
    </>
  );
};

Header.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["day", "week"])
};
export default Header;
