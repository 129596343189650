import Box from "@material-ui/core/Box";
import ReactAudioPlayer from 'react-audio-player';
import React, { useState, useEffect } from "react";
import { getObject, Target } from "utils/s3";

const FileMessage = props => {
  const { attachments = [] } = props;
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    getObject(Target.AUDIO, attachments[0].bucket, attachments[0]?.fileId).then(url => {
      setAudio(url);
    });
  }, [attachments]);

  return (
    <Box>
      {attachments.length &&
        attachments.map((attachment, index) => (
          <ReactAudioPlayer 
            controlsList="nodownload noplaybackrate"
            src={audio}
            controls
          />
        ))}
    </Box>
  );
};

export default FileMessage;
