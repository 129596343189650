import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import React, { useMemo } from "react";
import { CCTypography } from "styles/src/components";

const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: 788,
    height: "100%"
  },
  width100: { width: "100%" },
  height100: { height: "inherit" },
  detailTitle: {
    minHeight: 36,
    height: 36,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    padding: "0 16px",
    display: "flex",
    alignItems: "center"
  },
  detailContainer: {
    backgroundColor: theme.palette.background.default
  },
  detailItems: {
    width: "100%",
    minHeight: 36,
    height: 36,
    "&:nth-child(odd)": {
      backgroundColor: theme.palette.background.paper
    }
  },
  detailName: {
    minWidth: 90,
    padding: "0 16px"
  }
}));

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const DetailViewer = props => {
  const { clickedItem } = props;
  const classes = useStyle();
  const details = useMemo(() => {
    if (clickedItem?.content?.detail) {
      return JSON.parse(clickedItem.content.detail);
    } else {
      return null;
    }
  }, [clickedItem]);
  return (
    <Box className={classes.root}>
      <Grid item className={clsx(classes.detailTitle, classes.width100)}>
        <CCTypography variant={"h6"}>{"상세정보"}</CCTypography>
      </Grid>
      <Grid
        container
        className={clsx(
          classes.width100,
          classes.height100,
          classes.detailContainer
        )}
        direction={"column"}
      >
        {clickedItem?.date && (
          <Grid item className={classes.detailItems}>
            <Grid container alignItems={"center"} className={classes.height100}>
              <Grid item className={classes.detailName}>
                <CCTypography variant={"subtitle2"}>{"등록일"}</CCTypography>
              </Grid>
              <Grid item>{moment(clickedItem.date, "X").format("LLLL")}</Grid>
            </Grid>
          </Grid>
        )}
        {clickedItem?.lastModifiedTime && (
          <Grid item className={classes.detailItems}>
            <Grid container alignItems={"center"} className={classes.height100}>
              <Grid item className={classes.detailName}>
                <CCTypography variant={"subtitle2"}>{"수정일"}</CCTypography>
              </Grid>
              <Grid item>
                {moment(clickedItem?.lastModifiedTime, "X").format("LLLL")}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item className={classes.detailItems}>
          <Grid container alignItems={"center"} className={classes.height100}>
            <Grid item className={classes.detailName}>
              <CCTypography variant={"subtitle2"}>{"이름"}</CCTypography>
            </Grid>
            <Grid item>{clickedItem?.content?.filename || "파일명 없음"}</Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.detailItems}>
          <Grid container alignItems={"center"} className={classes.height100}>
            <Grid item className={classes.detailName}>
              <CCTypography variant={"subtitle2"}>{"파일타입"}</CCTypography>
            </Grid>
            <Grid item>
              {clickedItem?.content?.filetype || "파일타입 없음"}
            </Grid>
          </Grid>
        </Grid>
        {details?.size && (
          <Grid item className={classes.detailItems}>
            <Grid container alignItems={"center"} className={classes.height100}>
              <Grid item className={classes.detailName}>
                <CCTypography variant={"subtitle2"}>{"용량"}</CCTypography>
              </Grid>
              <Grid item>{formatBytes(details.size)}</Grid>
            </Grid>
          </Grid>
        )}
        {details?.width && details?.height && (
          <Grid item className={classes.detailItems}>
            <Grid container alignItems={"center"} className={classes.height100}>
              <Grid item className={classes.detailName}>
                <CCTypography variant={"subtitle2"}>{"크기"}</CCTypography>
              </Grid>
              <Grid item>{`${details.width}px X ${details.height}px`}</Grid>
            </Grid>
          </Grid>
        )}
        <Grid item className={classes.detailItems}>
          <Grid container alignItems={"center"} className={classes.height100}>
            <Grid item className={classes.detailName}>
              <CCTypography variant={"subtitle2"}>{"태그"}</CCTypography>
            </Grid>
            <Grid item>{clickedItem?.content?.tags || ""}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DetailViewer;
