import MenuItem from "@material-ui/core/MenuItem";
import { translate } from "components";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCTextField } from "styles/src/components";
import { makeList } from "types";
import { LAB_GOLD_TYPES } from "types/goldManagement";

const LabGoldField = props => {
  const {
    variant,
    label,
    margin,
    value,
    onChange,
    required,
    includeAll,
    ...others
  } = props;

  const labGoldTypes = makeList(LAB_GOLD_TYPES);
  const intl = useIntl();

  const [selectValue, setSelectValue] = useState("");

  const handleField = e => {
    onChange(e);
    setSelectValue(e.target.value);
  };

  useEffect(() => {
    String(value) ? setSelectValue(value) : setSelectValue("");
  }, [value, required]);

  return (
    <CCTextField
      value={selectValue}
      onChange={handleField}
      variant={variant}
      label={label}
      margin={margin}
      select
      {...others}
    >
      {includeAll
        ? [
            { value: "All", label: translate(intl, "common.ui.all") },
            ...labGoldTypes
          ].map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))
        : [...labGoldTypes].map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
    </CCTextField>
  );
};

LabGoldField.defaultProps = {
  value: "",
  variant: "outlined",
  label: "",
  margin: "dense"
};

LabGoldField.propTypes = {};

export default LabGoldField;
