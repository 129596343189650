import React from "react";

const TackIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M15.102 12.72l1.586-2.128c.657.578 1.719.5 2.413-.194.72-.72.777-1.834.127-2.484l-3.142-3.142c-.65-.65-1.764-.593-2.484.127-.695.694-.772 1.756-.194 2.413l-2.127 1.586c-1.1-.743-2.697-.553-3.768.518l-.354.354c-.24.24-.26.611-.042.828l2.75 2.75-3.482 3.48c-.24.241-.259.612-.042.829.217.217.588.198.828-.042l3.481-3.482 2.75 2.75c.217.217.588.198.828-.042l.354-.354c1.071-1.071 1.261-2.667.518-3.768z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default TackIcon;
