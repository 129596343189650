import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CCTextField } from "styles/components";

const RequiredTextField = props => {
  const {
    value,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;

  // value
  const [error, setError] = useState(false);

  // function
  const handleOnChange = ({ target: { value } }) => {
    onChange({ target: { value: value } });
    if (value) {
      setError(false);
    } else {
      setError(true);
    }
  };

  // useEffect
  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);

  useEffect(() => {
    if (value) {
      setError(false);
    } else setError(true);
    required && value === "" && setError(true);
  }, [value, required]);
  return (
    <CCTextField
      margin={margin}
      variant={variant}
      label={label}
      onChange={handleOnChange}
      error={error}
      required={required}
      value={value || ""}
      {...others}
    />
  );
};

RequiredTextField.defaultProps = {
  value: "",
  margin: "dense",
  variant: "outlined",
  label: "",
  required: false,
  errorOnChange: () => {}
};

RequiredTextField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default RequiredTextField;
