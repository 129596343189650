import { useMutation, useQuery } from "@apollo/react-hooks";
import { Checkbox, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { enqueueToastSave, PermissionBackdrop } from "components";
import CVButton from "components/CVButton";
import { AppContext, RouteContext } from "context";
import { useSnackbar } from "notistack";
import {
  GET_PERMISSION_SETTING,
  UPDATE_PERMISSION_SETTING
} from "queries/setting";
import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import {
  CCButton,
  CCDialogContent,
  CCDialogTitle,
  CCPaper,
  CCTable,
  CCTypography
} from "styles/src/components";
import CCDialogActions from "styles/src/components/CCDialogActions";
import { CheckIcon, MenunextIcon } from "styles/src/themes/common/icons";
import {
  CATEGORY,
  getFlatPermission,
  useSearchLocaleKey
} from "types/permission";
import { PERMISSION } from "types/staff";
import { CAPITALIZE_TYPE, FormattedMessage, useIntl } from "utils/language";
import { updatePermission } from "utils/permission";
import { v4 as uuid } from "uuid";
import { translate, PasswordConfirmDialog } from "components";
import { CCDialogSimple } from "../../../../../styles/src/components";
import { WarningIcon } from "../../../../../styles/src/themes/common/icons";
import gql from "graphql-tag";

const IS_LAB_CHIEF_NO_PASS = gql`
  query IsLabChiefNoPass {
    isLabChiefNoPass 
  }
`;

const leftPanelWidth = 200;
const rightPanelMargin = 16;
const paddingUnit = 16;
const permissionCheckWidth = 75;
const underArrowWidth = 34;
const minHeight = 36;
const useStyle = makeStyles(
  theme => ({
    root: {
      width: "100%",
      height: "100%"
    },
    cursorPointer: {
      cursor: "pointer"
    },
    padding16LeftRight: {
      paddingLeft: 16,
      paddingRight: 16
    },
    height100Percent: { height: "100%" },
    mainContent: { height: "calc(100% - 100px)", position: "relative" },
    permissionContent: {
      height: "calc(100% - 48px - 68px)"
    },
    leftPanel: {
      width: leftPanelWidth,
      minWidth: leftPanelWidth
    },
    rightPanel: {
      marginLeft: rightPanelMargin,
      minWidth: 600,
      width: 600,
      height: "100%"
    },
    levelTable: { borderTop: "1px solid #b0bec5" },
    permissionSection: {
      borderTop: "1px solid #b0bec5",
      height: "100%"
    },
    permissionTable: {
      width: "100%",
      height: "inherit",
      backgroundColor: theme.palette.background.default,
      overflowY: "hidden"
    },
    permissionTableHeader: {
      width: "100%",
      backgroundColor: theme.palette.common.white,
      height: minHeight,
      borderBottom: `1px solid ${theme.palette.border.main}`,
      paddingLeft: paddingUnit * 2
    },
    contentCol: {
      width: `calc(100% - ${permissionCheckWidth * 2}px)`,
      minHeight: minHeight,
      "& > div": {
        width: "100%",
        minHeight: minHeight,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
      }
    },
    readCol: {
      width: 75,
      minHeight: minHeight,
      "& > div": {
        // width: permissionCheckWidth,
        width: 75,
        minHeight: minHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    },
    writeCol: {
      width: 75,
      minHeight: minHeight,
      "& > div": {
        // width: permissionCheckWidth,
        width: 75,
        minHeight: minHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    },
    permissionTableContent: {
      width: "100%",
      height: `calc(100% - ${minHeight}px)`,
      overflowY: "auto"
    },

    permissionItem: {
      paddingLeft: paddingUnit * 2,
      // paddingRight: paddingUnit,
      width: "100%",
      // minHeight: minHeight,
      minHeight: "auto",
      boxSizing: "border-box",
      "&:nth-child(even)": {
        backgroundColor: theme.palette.common.white
      }
    },
    underArrowCol: {
      width: underArrowWidth,
      minHeight: minHeight,
      "& > *": {
        width: "100%",
        minHeight: minHeight,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: theme.palette.disabled
      }
    },
    permissionItemCol: {
      width: `calc(100% - ${permissionCheckWidth * 2}px)`,
      minHeight: minHeight,
      "& > div": {
        width: "100%",
        minHeight: minHeight,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
      }
    },
    secondDepthPermissionItem: {
      paddingLeft: paddingUnit * 3,
      width: "100%",
      minHeight: minHeight,
      "& > div": {
        width: "100%",
        minHeight: minHeight,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
      }
    },
    secondPermissionItemCol: {
      width: `calc(100% - ${permissionCheckWidth * 2}px)`
    },
    thirdDepthPermissionItem: {
      paddingLeft: paddingUnit * 5,
      width: "100%",
      minHeight: minHeight,
      "& > div": {
        minHeight: minHeight,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
      }
    },
    thirdPermissionItemCol: {
      width: `calc(100% - ${permissionCheckWidth * 2}px)`
    }
  }),
  { name: "PermissionSetting" }
);

const PermissionHeader = () => {
  const classes = useStyle();
  const intl = useIntl();
  return (
    <Grid
      container
      className={classes.permissionTableHeader}
      justify={"space-between"}
      alignItems={"center"}
    >
      <Grid item className={classes.contentCol}>
        <CCTypography component={"div"} variant={"body1"}>
          {translate(intl, "settings.authorization.list")}
        </CCTypography>
      </Grid>
      <Grid item className={classes.readCol}>
        <CCTypography component={"div"} variant={"body1"}>
          {translate(intl, "settings.authorization.readOnly")}
        </CCTypography>
      </Grid>
      <Grid item className={classes.writeCol}>
        <CCTypography component={"div"} variant={"body1"}>
          {translate(intl, "settings.authorization.modify")}
        </CCTypography>
      </Grid>
    </Grid>
  );
};

const NormalPermissionItem = props => {
  const { data, onChange } = props;
  // const [open, setOpen] = useState(true);
  const getLocaleKey = useSearchLocaleKey();
  const intl = useIntl();
  const classes = useStyle();
  const lang = localStorage.getItem('lang')

  return (
    <>
      <Grid item className={clsx(classes.permissionItem)}>
        <Grid container alignItems={"center"}>
          <Grid item className={classes.permissionItemCol}>
            <CCTypography component={"div"} variant={"body1"} style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
              {intl.formatMessage(
                { id: getLocaleKey(CATEGORY, data.category) },
                {},
                { capitalizeType: CAPITALIZE_TYPE.APA }
              )}
            </CCTypography>
          </Grid>
          <Grid item className={classes.readCol}>
            {Boolean(data?.permission?.hasOwnProperty("read")) && (
              <Grid container justify={"center"}>
                <Checkbox
                  checked={data?.permission?.read}
                  onChange={e => {
                    onChange &&
                      onChange({
                        category: data.category,
                        permission:
                          e.target.checked === false
                            ? {
                              write: false,
                              read: e.target.checked
                            }
                            : {
                              ...data?.permission,
                              read: e.target.checked
                            }
                      });
                  }}
                  disableRipple
                />
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.writeCol}>
            {Boolean(data?.permission?.hasOwnProperty("write")) && (
              <Grid container justify={"center"}>
                <Checkbox
                  checked={data?.permission?.write}
                  // disabled={!data?.permission?.read}
                  onChange={e => {
                    onChange &&
                      onChange({
                        category: data.category,
                        permission:
                          e.target.checked === true
                            ? {
                              read: true,
                              write: e.target.checked
                            }
                            : {
                              ...data?.permission,
                              write: e.target.checked
                            }
                      });
                  }}
                  disableRipple
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {Boolean(data?.items) && (
        <Grid item>
          <Grid container direction={"column"}>
            {[...SecondDepthPermissionItems(data?.items, onChange)]}
          </Grid>
        </Grid>
      )}
    </>
  );
};
const SecondDepthPermissionItem = props => {
  const { data, onChange } = props;
  const classes = useStyle();
  const intl = useIntl();
  const getLocaleKey = useSearchLocaleKey();
  const lang = localStorage.getItem('lang')

  return (
    <>
      <Grid item className={classes.secondDepthPermissionItem}>
        <Grid container alignItems={"center"}>
          <Grid
            item
            className={clsx(
              classes.permissionItemCol,
              classes.secondPermissionItemCol
            )}
          >
            <CCTypography
              component={"div"}
              variant={"body1"}
              style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}
            >{`• ${intl.formatMessage(
              {
                id: Boolean(getLocaleKey(CATEGORY, data.category))
                  ? getLocaleKey(CATEGORY, data.category)
                  : "noData"
              },
              {},
              { capitalizeType: CAPITALIZE_TYPE.APA }
            )}`}</CCTypography>
          </Grid>
          <Grid item className={classes.readCol}>
            {Boolean(data?.permission?.hasOwnProperty("read")) && (
              <Grid container justify={"center"}>
                <Checkbox
                  checked={data.permission.read}
                  onChange={e => {
                    onChange &&
                      onChange({
                        category: data.category,
                        permission:
                          e.target.checked === false
                            ? {
                              write: false,
                              read: e.target.checked
                            }
                            : {
                              ...data?.permission,
                              read: e.target.checked
                            }
                      });
                  }}
                  disableRipple
                />
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.writeCol}>
            {Boolean(data?.permission?.hasOwnProperty("write")) && (
              <Grid container justify={"center"}>
                <Checkbox
                  checked={data.permission.write}
                  // disabled={!data.permission.read}
                  onChange={e => {
                    onChange &&
                      onChange({
                        category: data.category,
                        permission:
                          e.target.checked === true
                            ? {
                              read: true,
                              write: e.target.checked
                            }
                            : {
                              ...data?.permission,
                              write: e.target.checked
                            }
                      });
                  }}
                  disableRipple
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {Boolean(data?.items) && (
        <Grid item>
          <Grid container direction={"column"}>
            {[...ThirdDepthPermissionItems(data.items, onChange)]}
          </Grid>
        </Grid>
      )}
    </>
  );
};
const ThirdDepthPermissionItem = props => {
  const { data, onChange } = props;
  const classes = useStyle();
  const intl = useIntl();
  const getLocaleKey = useSearchLocaleKey();
  const lang = localStorage.getItem('lang')
  return (
    <Grid container className={classes.thirdDepthPermissionItem}>
      <Grid
        item
        className={clsx(
          classes.permissionItemCol,
          classes.thirdPermissionItemCol
        )}
      >
        <CCTypography component={"div"} variant={"body1"} style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
          {`${lang === 'ar' ? '┘' : '└'}  ${intl.formatMessage(
            {
              id: Boolean(getLocaleKey(CATEGORY, data.category))
                ? getLocaleKey(CATEGORY, data.category)
                : "noData"
            },
            {},
            { capitalizeType: CAPITALIZE_TYPE.APA }
          )}`}
        </CCTypography>
      </Grid>
      <Grid item className={classes.readCol}>
        {Boolean(data?.permission?.hasOwnProperty("read")) && (
          <Grid container justify={"center"}>
            <Checkbox
              checked={data.permission.read}
              onChange={e => {
                onChange &&
                  onChange({
                    category: data.category,
                    permission:
                      e.target.checked === false
                        ? {
                          write: false,
                          read: e.target.checked
                        }
                        : {
                          ...data?.permission,
                          read: e.target.checked
                        }
                  });
              }}
              disableRipple
              disabled={data.category === "070200" && data?.freeze}
            />
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.writeCol}>
        {Boolean(data?.permission?.hasOwnProperty("write")) && (
          <Grid container justify={"center"}>
            <Checkbox
              checked={data.permission.write}
              // disabled={
              //   !data.permission.read ||
              //   (data.category === "070200" && data?.freeze)
              // }
              onChange={e => {
                onChange &&
                  onChange({
                    category: data.category,
                    permission:
                      e.target.checked === true
                        ? {
                          read: true,
                          write: e.target.checked
                        }
                        : {
                          ...data?.permission,
                          write: e.target.checked
                        }
                  });
              }}
              disableRipple
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

function* PermissionItems(list, page, onChange) {
  for (let i = 0; i < list.length; i++) {
    if (page !== list[i].page) {
      continue;
    }
    const dbCategory = list[i].category;
    const categoryValue = Object.entries(CATEGORY).filter((entry) => {
      // eslint-disable-next-line no-unused-vars
      const [key, value] = entry;
      return value.DEFAULT.value === dbCategory
    })
    if (categoryValue.length === 0) {
      continue;
    }
    yield (
      <NormalPermissionItem
        key={list[i].id}
        data={list[i]}
        onChange={onChange}
      />
    );
  }
}

function* SecondDepthPermissionItems(list, onChange) {
  for (let i = 0; i < list.length; i++) {
    yield (
      <SecondDepthPermissionItem
        key={list[i].id}
        data={list[i]}
        onChange={onChange}
      />
    );
  }
}

function* ThirdDepthPermissionItems(list, onChange) {
  for (let i = 0; i < list.length; i++) {
    yield (
      <ThirdDepthPermissionItem
        key={list[i].id}
        data={list[i]}
        onChange={onChange}
      />
    );
  }
}

const PermissionGroup = Object.values(PERMISSION).reduce((acc, cur) => {
  // if (cur.value === PERMISSION.NO_PERMISSIONS.value) return acc;
  acc.push({
    id: uuid(),
    content: cur
  });
  return acc;
}, []);

function reducer(state, action) {
  switch (action.type) {
    case "init":
      if (JSON.stringify(state.data) === JSON.stringify(action.target)) {
        return state;
      }
      return {
        ...state,
        data: [...action.target]
      };
    case "changePermissionGroup":
      return {
        ...state,
        ...action.target
      };

    case "updatePermission":
      let _temp = updatePermission(
        [...state.data],
        { ...action.target.permission },
        state.currentPermission,
        action.target.category
      );
      if (_temp) {
        return {
          ...state,
          data: [..._temp]
        };
      }
      break;
    default:
      break;
  }
  return state;
}

const WarningNotChiefDialog = (props) => {
  const {
    isLabChiefNoPass,
    warningNotChiefDialogOpen,
    setWarningNotChiefDialogOpen
  } = props;
  const classes = useStyle();
  const intl = useIntl();
  return (
    <CCDialogSimple
      open={!isLabChiefNoPass && warningNotChiefDialogOpen}
      onClose={() => { setWarningNotChiefDialogOpen(false) }}
      title={<WarningIcon className={classes.warning_color} />}
      contents={
        <Box className={classes.text}>
          <CCTypography className={classes.warning_color}>
            {translate(intl, "common.ui.warningOnlyChiefAccess")}
          </CCTypography>
        </Box>
      }
      endActions={
        <CCButton
          className={classes.disagree}
          color="secondary"
          onClick={() => { setWarningNotChiefDialogOpen(false) }}
        >
          {intl.formatMessage({ id: "common.ui.ok" })}
        </CCButton>
      }
    />
  )
}

const AuthenticatedBlock = props => {
  const { isAuthenticated
    , state, dispatchState, dataInitPermission, update, refetch, updateSetting } = props;

  const lang = localStorage.getItem('lang')
  const { appContext, dispatchApp } = useContext(AppContext);
  const classes = useStyle();
  const intl = useIntl();

  return (
    isAuthenticated &&
    <CCPaper className={classes.root}>
      <CCDialogTitle>
        {intl.formatMessage({
          id: "settings.authorization.authorizationSetting"
        })}
      </CCDialogTitle>
      <CCDialogContent className={classes.mainContent}>
        {/* <Loading open={loading} msg={"Loading..."} />
      <Loading open={updateLoading} msg={"Updating..."} /> */}
        <Grid container className={classes.height100Percent}>
          <Grid item className={classes.leftPanel}>
            <CCPaper>
              <CCDialogTitle disableTypography>
                <CCTypography variant={"h5"}>
                  {intl.formatMessage({
                    id: "settings.authorization.classify"
                  })}
                </CCTypography>
              </CCDialogTitle>
              <CCDialogContent noPadding>
                <CCTable
                  className={classes.levelTable}
                  labelShow={false}
                  selectedRow={PermissionGroup.findIndex(
                    item => item.content.value === state.currentPermission
                  )}
                  heads={[
                    {
                      key: "content",
                      component: props => {
                        const { cellData } = props;
                        return (
                          <Grid
                            container
                            justify={"space-between"}
                            alignItems={"center"}
                            className={classes.padding16LeftRight}
                          >
                            <Grid item>
                              <CCTypography variant={"body1"}>
                                <FormattedMessage
                                  id={cellData?.localeKey}
                                  capitalizeType={CAPITALIZE_TYPE.APA}
                                />
                              </CCTypography>
                            </Grid>
                            <Grid item>
                              <MenunextIcon style={{ transform: `rotateY(${lang === 'ar' ? '180deg' : '0deg'})` }} />
                            </Grid>
                          </Grid>
                        );
                      }
                    }
                  ]}
                  onRowClick={e => {
                    dispatchState({
                      type: "changePermissionGroup",
                      target: {
                        currentPermission: e.data.content.value,
                        data: [
                          ...JSON.parse(
                            JSON.stringify(
                              dataInitPermission?.getConfig?.permissionSetting
                            )
                          )
                        ]
                      }
                    });
                  }}
                  contents={PermissionGroup}
                />
              </CCDialogContent>
            </CCPaper>
          </Grid>
          <Grid item className={classes.rightPanel}>
            <CCPaper className={classes.height100Percent}>
              <CCDialogTitle disableTypography>
                <CCTypography variant={"h5"}>
                  {translate(intl, "settings.authorization.setting")}
                </CCTypography>
              </CCDialogTitle>
              <CCDialogContent noPadding className={classes.permissionContent}>
                <Grid container className={classes.permissionSection}>
                  <Grid item className={classes.permissionTable}>
                    <Grid
                      container
                      direction={"column"}
                      className={classes.height100Percent}
                    >
                      <Grid item>
                        <PermissionHeader />
                      </Grid>
                      <Grid
                        container
                        className={classes.permissionTableContent}
                        direction={"column"}
                        wrap={"nowrap"}
                      >
                        {state.data.length > 0 && [
                          ...PermissionItems(
                            [
                              ...state?.data[
                                state?.data?.findIndex(
                                  item =>
                                    item.category === state.currentPermission
                                ) || 0
                              ]?.data
                            ],
                            1,
                            update
                          )
                        ]}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CCDialogContent>
              <CCDialogActions>
                <Grid container justify={"flex-end"}>
                  <Grid item>
                    <CVButton variant={"text"} onClick={() => refetch()}>
                      {translate(intl, "common.ui.cancel")}
                    </CVButton>
                  </Grid>
                  <Grid item>
                    <CVButton
                      permission={"authorizationOtherSetting"}
                      variant={"contained"}
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        const permission = getFlatPermission(
                          state.data[appContext.me.permissionSetting.category]
                            .data
                        );
                        updateSetting({
                          variables: {
                            labId: localStorage.getItem("labId"),
                            category: "permissionSetting",
                            permissionSetting: [...state.data]
                          }
                        }).then(() => {
                          dispatchApp({
                            type: "permission",
                            target: permission
                          });
                        });
                      }}
                    >
                      {translate(intl, "common.ui.save")}
                    </CVButton>
                  </Grid>
                </Grid>
              </CCDialogActions>
            </CCPaper>
          </Grid>
        </Grid>
      </CCDialogContent>
    </CCPaper>
  )
}

const PermissionSetting = props => {
  // classes
  const classes = useStyle();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { appContext, dispatchApp } = useContext(AppContext);
  const [state, dispatchState] = useReducer(reducer, {
    currentPermission: PERMISSION.ACCOUNTANT.value,
    data: []
  });

  // graphql
  const { data: dataInitPermission, refetch } = useQuery(
    GET_PERMISSION_SETTING,
    {
      variables: {
        category: "permissionSetting",
        labId: localStorage.getItem("labId")
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: data => {
        if (data?.getConfig?.permissionSetting) {
          dispatchState({
            type: "init",
            target: [
              ...JSON.parse(JSON.stringify(data?.getConfig?.permissionSetting))
            ]
          });
        }
      }
    }
  );

  // const handleChangeTabAccount = () => {
  //   dispatchState({
  //     type: "init",
  //     target: [
  //       ...JSON.parse(
  //         JSON.stringify(dataInitPermission?.getConfig?.permissionSetting)
  //       )
  //     ]
  //   });
  // };
  const [updateSetting] = useMutation(UPDATE_PERMISSION_SETTING, {
    onCompleted: () => {
      enqueueToastSave(intl, enqueueSnackbar);
      refetch();
    }
  });

  // function
  const update = useCallback(
    e => {
      dispatchState({
        type: "updatePermission",
        target: e
      });
    },
    [dispatchState]
  );

  const { data: isLabChiefNoPassData, loading: isLabChiefNoPassLoading } = useQuery(IS_LAB_CHIEF_NO_PASS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  })

  const [isLabChiefNoPass, setIsLabChiefNoPass] = useState(false)
  const [confirmPasswordDialogOpen, setConfirmPasswordDialogOpen] = useState(isLabChiefNoPass)
  const [warningNotChiefDialogOpen, setWarningNotChiefDialogOpen] = useState(isLabChiefNoPass)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const { dispatchRoute } = useContext(RouteContext);
  const onClose = (isAuthenticatedSuccess = false, isForceLogout = false) => {
    setConfirmPasswordDialogOpen(false)
    setIsAuthenticated(!!isAuthenticatedSuccess)
    if (isForceLogout) {
      setTimeout(() => {
        dispatchRoute({ path: "/signin" });
        dispatchApp({ type: "init", target: null });
        dispatchApp({ type: "permission", target: null });
        sessionStorage.setItem("token", "");
      }, 2000)
    }
  }

  useEffect(() => {
    if (isLabChiefNoPassData && !isLabChiefNoPassLoading) {
      console.log("isLabChiefNoPassData", isLabChiefNoPassData)
      setIsLabChiefNoPass(!isLabChiefNoPassData.isLabChiefNoPass);
      setWarningNotChiefDialogOpen(!isLabChiefNoPassData.isLabChiefNoPass);
      setConfirmPasswordDialogOpen(isLabChiefNoPassData.isLabChiefNoPass);
    }
  }, [isLabChiefNoPassData, isLabChiefNoPassLoading])

  return (
    <>
      <WarningNotChiefDialog
        isLabChiefNoPass={isLabChiefNoPass}
        warningNotChiefDialogOpen={warningNotChiefDialogOpen}
        setWarningNotChiefDialogOpen={setWarningNotChiefDialogOpen}
        classes={classes}
        intl={intl}
      />
      <PasswordConfirmDialog
        open={confirmPasswordDialogOpen}
        onClose={onClose}
      />
      {(!isAuthenticated) && <PermissionBackdrop open={!isAuthenticated} />}
      <AuthenticatedBlock
        isAuthenticated={isAuthenticated}
        state={state}
        dispatchState={dispatchState}
        dataInitPermission={dataInitPermission}
        update={update}
        refetch={refetch}
        updateSetting={updateSetting}
      />
    </>
  );
};

export default PermissionSetting;
