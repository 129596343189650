import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { ApolloProvider } from "components";
import { AppContextProvider, RouteContextProvider } from "context";
import { AppContext } from "context/AppContext";
import { en, ko, vi, ar, ru } from "locales";
import { SnackbarProvider } from "notistack";
import React, { useContext } from "react";
import { IntlProvider } from "react-intl";
import { en as enStyles, ko as koStyles, vi as viStyles, ru as ruStyles, ar as arStyles } from "styles/locales";
import { useLanguage } from "utils/language";
import { getMomentLocale } from "../../utils/datetime";


const useStyles = makeStyles(() => ({
  root: { width: "100vw" },
  containerAnchorOriginBottomCenter: {}
}));

const NeedAppContext = props => {
  const { children } = props;
  const classes = useStyles();
  const { appContext } = useContext(AppContext);
  const [language] = useLanguage();
  const messages = {
    ko: { ...en, ...ko, ...koStyles },
    en: { ...en, ...enStyles },
    vi: { ...en, ...vi, ...viStyles },
    ru: { ...en, ...ru, ...ruStyles },
    ar: { ...en, ...ar, ...arStyles }
  };
  getMomentLocale(language)
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <ThemeProvider theme={createMuiTheme(appContext.theme)}>
        <ApolloProvider>
          <SnackbarProvider
            classes={{
              root: classes.root,
              containerAnchorOriginBottomCenter:
                classes.containerAnchorOriginBottomCenter
            }}
            maxSnack={3}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            {" "}
            {children}
          </SnackbarProvider>
        </ApolloProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

const ContextProvider = props => {
  const { children } = props;

  return (
    <AppContextProvider>
      <RouteContextProvider>
        <NeedAppContext children={children} />
      </RouteContextProvider>
    </AppContextProvider >
  );
};

export default ContextProvider;
