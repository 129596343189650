import React from "react";

const TextIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M6.426 14.219L6.426 1.543 10.996 1.543 10.996 0 0 0 0 1.543 4.561 1.543 4.561 14.219z"
        transform="translate(7 5)"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default TextIcon;
