import React from "react";
import { useIntl } from "utils/language";
import { CVButton, CVTextField } from "components";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { CCTypography } from "styles/src/components";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
import ExpireTable from "../ExpireTable";

const useStyles = makeStyles(theme => ({
  small__button: {
    padding: 2,
    margin: 8,
    height: "20px",
    width: "300px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba,
    "&:hover": {
      backgroundColor: theme.palette.select.main
    }
  },
  font__field: {
    fontSize: 14,
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    weight: "bold"
  },
  head__field: {
    width: "100px"
  },
  head__storage__field: {
    width: props => {
      if (props) {
        return 430;
      } else {
        return 530;
      }
    }
  },
  back: {
    backgroundColor: theme.palette.result.main
  },
  selected: {
    backgroundColor: theme.palette.select.main,
    cursor: "default"
  },
  text__field: {
    width: "80px"
  },
  detail__border: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    margin: "20px 0px",
    boxSizing: "content-box"
  }
}));

const ButtonTabs = props => {
  const { tabList, detail } = props;
  const intl = useIntl();
  const { control, watch } = useFormContext();
  const classes = useStyles(detail?.detail === "otherPurchase" || detail?.detail === "otherReturn");

  return (
    <>
      <Box className={classes.detail__border}>
        <Box>
          <CCTypography className={classes.font__field}>
            {intl.formatMessage({ id: "detailInformation" })}
          </CCTypography>
        </Box>
        <Box>
          {tabList?.map((d, i) => (
            <CVButton
              key={tabList[i]}
              variant={"contained"}
              color="normal"
              readOnly={true}
              disabled={detail?.detail !== tabList[i]}
              className={clsx(classes.small__button, {
                [classes.selected]: detail?.detail === tabList[i]
              })}
            >
              {intl.formatMessage({ id: d }, { br: () => <br /> })}
            </CVButton>
          ))}
        </Box>
      </Box>

      <Box>
        <CVTextField
          className={classes.text__field}
          variant={"outlined"}
          margin={"dense"}
          defaultValue={detail?.changedCnt}
          value={detail?.changedCnt || 0}
          label={intl.formatMessage({ id: "shippingCnt" })}
          disabled={true}
          readOnly={true}
        />

        {Boolean(
          detail?.detail === "otherPurchase" || detail?.detail === "otherReturn"
        ) && (
          <CVTextField
            className={classes.text__field}
            variant={"outlined"}
            margin={"dense"}
            defaultValue={detail?.retailPrice || 0}
            value={detail?.retailPrice || 0}
            label={intl.formatMessage({ id: "unitPriceGold" })}
            disabled={true}
            readOnly={true}
          />
        )}

        <CVTextField
          className={clsx(classes.head__field, classes.head__storage__field)}
          variant={"outlined"}
          margin={"dense"}
          defaultValue={detail?.remarks || ""}
          value={detail?.remarks || ""}
          label={intl.formatMessage({ id: "remarks" })}
          disabled={true}
          readOnly={true}
        />

        {Boolean(
          watch("detailInformation") === "otherPurchase" ||
          watch("detailInformation") === "otherReturn"
        ) && (
          <Controller
            name={"retailPrice"}
            control={control}
            defaultValue={0}
            render={props => {
              const { ref, value, onChange, onBlur } = props;
              return (
                <CVTextField
                  className={classes.text__field}
                  value={value}
                  inputRef={ref}
                  onChange={e => onChange(e.target.value)}
                  onBlur={onBlur}
                  variant={"outlined"}
                  margin={"dense"}
                  label={intl.formatMessage({ id: "unitPriceGold" })}
                />
              );
            }}
          />
        )}
      </Box>

      {Boolean(detail?.expire?.length > 0) && (
        <ExpireTable value={detail?.expire} />
      )}
    </>
  );
};

export default ButtonTabs;
