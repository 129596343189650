// import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import hangul from "hangul-js";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { VariableSizeList } from "react-window";
import { CCTextField } from "styles/components";
import { RemoveIcon, SearchIcon } from "styles/icons";
import { getAge } from "utils/patient";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node
};

const useStyles = makeStyles(theme => ({
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0,
      color: "red"
    }
  },
  popupIndicator: {
    color: theme.palette.icon
  },
  popupIndicatorOpen: { transform: "none" },
  paper: {
    width: 370,
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    opacity: 0.9
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.hover
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.select.main
    }
  },
  noOptions: {
    color: "white"
  }
}));

const useStyles2 = makeStyles(theme => ({
  textfield: { width: 250 },
  icon: {
    color: theme.palette.sub.main
  },
  option: {
    color: "white",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: 8
  },
  options__inner: { display: "flex" },
  option__name: { width: 120 },
  option__chartnumber: { width: 60 },
  option__birthdate: { width: 100 },
  option__sex: { width: 20 }
}));

// const extraction = text => {
//   const kor = [
//     "ㄱ",
//     "ㄲ",
//     "ㄴ",
//     "ㄷ",
//     "ㄸ",
//     "ㄹ",
//     "ㅁ",
//     "ㅂ",
//     "ㅃ",
//     "ㅅ",
//     "ㅆ",
//     "ㅇ",
//     "ㅈ",
//     "ㅉ",
//     "ㅊ",
//     "ㅋ",
//     "ㅌ",
//     "ㅍ",
//     "ㅎ"
//   ];
//   let result = "";

//   for (let i = 0; i < text.length; i++) {
//     let utf = text.charCodeAt(i) - 44032;

//     if (utf > -1 && utf < 11172) {
//       result += kor[Math.floor(utf / 588)];
//     } else {
//       result += text.charAt(i);
//     }
//   }
//   return result;
// };

const PatientSelector = props => {
  const {
    disabled = false,
    className,
    onChange,
    value = null,
    noOptionsText = "검색된 환자가 없습니다.",
    placeholder = "환자 검색",
    blank = true
  } = props;
  const classes = useStyles();
  const classes2 = useStyles2();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // const { data } = useQuery(SEARCH_PATIENT_SIMPLEINFO, {
  //   variables: { hospitalId: localStorage.getItem('labId') }
  // });

  // const [getPatient] = useLazyQuery(GET_PATIENT, {
  //   onCompleted: data => {
  //     onChange && onChange({ id: data.getPatient.id, value: data.getPatient });
  //   }
  // });

  // const options = useMemo(() => {
  //   if (data && data.searchPatient) {
  //     return data.searchPatient.map(patient => {
  //       return {
  //         id: patient.id,
  //         value: patient,
  //         cho: extraction(patient.name)
  //       };
  //     });
  //   } else {
  //     return [];
  //   }
  // }, [data]);

  return (
    <Autocomplete
      disabled={disabled}
      // id="virtualize-demo"
      className={className}
      disableListWrap
      classes={classes}
      ListboxComponent={ListboxComponent}
      // options={options}
      filterOptions={(items, { inputValue: input }) => {
        const v = inputValue;
        const tmp = items.filter(item => {
          if (item.value.name && item.value.name.indexOf(v) !== -1) return true;
          if (
            item.value.chartNumber &&
            item.value.chartNumber.indexOf(v) !== -1
          )
            return true;
          if (!hangul.search(item.value.name, v)) return true;
          if (item.cho && item.cho.indexOf(v) !== -1) return true;

          return false;
        });
        return tmp;
      }}
      renderInput={params => {
        return (
          <CCTextField
            {...params}
            margin="dense"
            variant="outlined"
            placeholder={placeholder}
          />
        );
      }}
      renderOption={option => {
        return (
          <div className={classes2.options__inner}>
            <Typography
              noWrap
              className={clsx(classes2.option, classes2.option__name)}
            >
              {`${option.value.name}(만 ${getAge(option.value.birthDate)}세)`}
            </Typography>
            <Typography
              noWrap
              className={clsx(classes2.option, classes2.option__chartnumber)}
            >
              {`#${option.value.chartNumber}`}
            </Typography>
            <Typography
              noWrap
              className={clsx(classes2.option, classes2.option__birthdate)}
            >
              {option.value.birthDate}
            </Typography>
            <Typography
              noWrap
              className={clsx(classes2.option, classes2.option__sex)}
            >
              {/* {getSex(option.value.residentRegistrationNumber)} */}
              {option.value.sex % 2 === 0 ? "여" : "남"}
            </Typography>
          </div>
        );
      }}
      popupIcon={<SearchIcon />}
      closeIcon={<RemoveIcon />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={e => {
        if (e.target.value === undefined && inputValue) setOpen(true);
      }}
      getOptionLabel={option => {
        return option.value
          ? `${option.value.name} (${option.value.chartNumber})`
          : "";
      }}
      onInputChange={(e, v, r) => {
        if (blank && !v) onChange && onChange(null);

        if (r === "input") setOpen(false);

        setInputValue(v);
      }}
      onChange={(e, v) => {
        if (v) {
          // getPatient({
          //   variables: {
          //     hospitalId: localStorage.getItem('labId'),
          //     id: v.id
          //   }
          // });
        }
      }}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      value={value}
      selectOnFocus={true}
      autoHighlight={true}
      noOptionsText={noOptionsText}
      onKeyPress={e => {
        if (!inputValue) return;
        if (e.key === "Enter") {
          setOpen(true);
        }
      }}
      inputValue={inputValue}
    />
  );
};
export default PatientSelector;
