import { MenuList, Popover } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { CCTypography } from "styles/src/components";

const useStyle = makeStyles(theme => ({
  menuList: { padding: 0 },
  menuItem: {
    minWidth: 110,
    padding: `4px 8px`
  },
  title: { alignSelf: "center", marginLeft: 4 }
}));

const HelpMenu = props => {
  const { anchorEl = null, onClose, open } = props;
  const classes = useStyle();

  const openManual = url => window.open(url);
  // const config = [
  //   { title: "English", filePath: "/PDF/help/En_Clever_Lab_Manual.pdf" },
  //   { title: "Tiếng Việt", filePath: "/PDF/help/En_Clever_Lab_Manual.pdf" },
  //   { title: "한국어", filePath: "/PDF/help/Kr_Clever_Lab_Manual.pdf" },
  //   { title: "Pусский", filePath: "/PDF/help/En_Clever_Lab_Manual.pdf" },
  //   { title: "عربى", filePath: "/PDF/help/En_Clever_Lab_Manual.pdf"}
  // ]

  let config = []
  if (process.env.REACT_APP_LANGUAGE_REGION) {
    let region = process.env.REACT_APP_LANGUAGE_REGION
      switch (region) {
        case "en":
          config.push( { title: "English", filePath: "/PDF/help/En_Clever_Lab_Manual.pdf" })
          break
        case "vi":
          config.push({ title: "Tiếng Việt", filePath: "/PDF/help/Vn_Clever_Lab_Manual.pdf" })
          break
        case "ko":
          config.push( { title: "한국어", filePath: "/PDF/help/Kr_Clever_Lab_Manual.pdf" })
          break
        case "ru":
          config.push({ title: "Pусский", filePath: "/PDF/help/Rus_Clever_Lab_Manual.pdf" })
          break
        case "ar":
          config.push( { title: "English", filePath: "/PDF/help/En_Clever_Lab_Manual.pdf" })
          config.push({ title: "عربى", filePath: "/PDF/help/Ar_Clever_Lab_Manual.pdf"})
      }
    }
  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <MenuList className={classes.menuList}>
        {config.map(each => {
          return (
            <MenuItem
              key={each.title}
              className={clsx(classes.menuItem)}
              onClick={() => {
                openManual(each.filePath, "_blank");
                onClose();
              }}
            >
            <CCTypography variant={"body1"} className={classes.title}>
              {each.title}
            </CCTypography>
          </MenuItem>
          )
        })}
      </MenuList>
    </Popover>
  );
};
export default HelpMenu;
