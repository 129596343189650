import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  CVFlattenDateField,
  CVIconButton,
  CVTable,
  DialogWarningMessage
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CCPaper,
  CCTextFieldNumber,
  CCTypography
} from "styles/src/components";
import { getMomentFormatTimestamp, getTimestamp } from "utils/datetime";
import { AddIcon } from "styles/src/themes/common/icons";
import { useIntl } from "utils/language";

const head__height = 40;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    maxWidth: "100%"
  },
  paper: {
    position: "relative",
    height: "300px",
    width: "95%"
  },

  container: {
    height: head__height
  },
  expire__item: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 8
  },
  subitem: {
    marginTop: 8,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 8
  },
  mouse__hover: {
    cursor: "pointer"
  },
  table: {
    height: "230px",
    position: "relative"
  },
  date__field: {
    width: 150
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  },
  table__head: {
    marginTop: 10,
    padding: 10
  },
  table__body: {
    overflowY: "scroll"
  }
}));

// For Incoming Inventory
const ExpireTable = props => {
  const intl = useIntl();
  const { expire, currentCnt, value, onChange } = props;
  const classes = useStyles();
  const permission = "desk";
  const [total, setTotal] = useState(currentCnt);
  const [alrDialog, setAlrDialog] = useState(false);
  const [zeroDialog, setZeroDialog] = useState(false);
  const [reset, setReset] = useState(false);

  const onAdd = () => {
    if (currentCnt - total > 0) {
      onChange([
        ...value,
        {
          expireDate: getTimestamp(),
          expireCount: 0
        }
      ]);
    } else {
      setZeroDialog({
        title: intl.formatMessage(
          { id: "alrInsertExpireInfo" },
          { br: () => <br /> }
        ),
        onAgree: () => {
          setZeroDialog(null);
        }
      });
    }
  };

  const onDelete = ({ rowIndex }) => {
    if (value?.length > 1) {
      onChange(value.filter((_, index) => index !== rowIndex));
    } else {
      setAlrDialog({
        title: intl.formatMessage(
          { id: "informNoExpire" },
          { br: () => <br /> }
        ),
        onAgree: () => {
          setAlrDialog(null);
        }
      });
    }
  };

  const onModify = useCallback(
    row => {
      const { option, rowIndex, e } = row;
      const counts = value.map(item => item?.expireCount);
      const dates = value.map(item => item?.expireDate);
      const ModMap = [];

      for (let i = 0; i < value.length; i++) {
        if (i === rowIndex) {
          if (option) {
            dates[rowIndex] = e;
          } else {
            counts[rowIndex] = e;
          }
        }
        ModMap.push({
          expireDate: dates[i],
          expireCount: reset ? 0 : counts[i]
        });
      }

      onChange(ModMap);
      setReset(false);
    },
    [onChange, reset, value]
  );

  const heads = useMemo(() => {
    return [
      {
        key: "expireDate",
        value: "expireDate",
        width: 180,
        component: ({ rowIndex }) => (
          <CVFlattenDateField
            label={intl.formatMessage({
              id: "expireDate"
            })}
            permission={permission}
            margin={"dense"}
            variant={"outlined"}
            value={getMomentFormatTimestamp(value[rowIndex].expireDate, "X")}
            onChange={e =>
              onModify({
                option: true,
                rowIndex,
                e
              })
            }
          />
        )
      },
      {
        key: "expireCount",
        value: "expireCount",
        width: 150,
        component: ({ rowIndex }) => (
          <CCTextFieldNumber
            label={intl.formatMessage({
              id: "inventoryCount"
            })}
            number={value[rowIndex].expireCount}
            decimalPoint={0}
            step={1}
            onChange={e =>
              onModify({
                option: false,
                rowIndex,
                e
              })
            }
          />
        )
      }
    ];
  }, [intl, onModify, value]);

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    console.log("useEffect value", value)
    if (Boolean(value?.length > 0)) {
      let totalOut = 0;
      for (const v of Object.keys(value)) {
        totalOut = totalOut + Number(value[v]?.expireCount);
        console.log("totalOut", totalOut);
        if (totalOut > currentCnt) {
          setReset(true);
          setAlert({
            title: intl.formatMessage(
              { id: "incomingExpireCntAlert" },
            ),
            onAgree: () => {
              onModify({ option: false, rowIndex: v, e: 0 });
              setAlert(false);
            }
          });
        }
      }

      setTotal(
        Number(
          value
            .map(e => e.expireCount)
            .reduce(
              (acc, cur) => (cur > 0 ? Number(acc) + Number(cur) : Number(acc)),
              0
            )
        )
      );
    }
  }, [value, currentCnt, total, expire, intl, onChange, onModify]);

  return (
    <Box className={clsx(classes.root)}>
      <CCPaper className={clsx(classes.paper)}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={clsx(classes.container)}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item style={{ paddingLeft: 10 }}>
                <CCTypography variant={"h4"}>
                  {intl.formatMessage({
                    id: "expireDateSetting"
                  })}
                </CCTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={clsx(classes.reception__item)}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item className={clsx(classes.reception__item)}>
                <CVIconButton onClick={() => onAdd()}>
                  <AddIcon />
                </CVIconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container alignItems={"center"}>
            <Grid item style={{ paddingLeft: 10 }}>
              <CCTypography>
                {`${intl.formatMessage({
                  id: "leftCntforSetting"
                })} : ${currentCnt - total >= 0 ? currentCnt - total : 0}`}
              </CCTypography>
            </Grid>
          </Grid>
        </Grid>

        <Box className={clsx(classes.table)}>
          <CVTable
            permission={permission}
            heads={heads.map(item => ({
              ...item,
              className: classes.cell
            }))}
            contents={value}
            onDelClick={onDelete}
            classes={classes.expire_table}
          />
        </Box>
      </CCPaper>
      <DialogWarningMessage open={Boolean(alrDialog)} {...alrDialog} />
      <DialogWarningMessage open={Boolean(zeroDialog)} {...zeroDialog} />
      <DialogWarningMessage
        open={Boolean(alert)}
        agreeButtonLabel={intl.formatMessage({ id: "check" })}
        {...alert}
      />
    </Box>
  );
};
export default ExpireTable;
