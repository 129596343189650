import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import { CCButton, CCDialogSimple, CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {},
  warning___color: { color: theme.palette.error.main },
  desc___color: { color: theme.palette.sub.main },
  text: { marginTop: 8, minWidth: 280 },
  disagree: { marginRight: 8 },
  loading: { textAlign: "center" }
}));

const DialogWarn = props => {
  const {
    open,
    onClose = () => { },
    text = "인자를 채워주세요.",
    loading = false
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <CCDialogSimple
      open={open}
      isHeader={false}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <Box className={classes.text}>
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <CCTypography className={classes.warning___color}>
              {text}
            </CCTypography>
          )}
        </Box>
      }
      endActions={
        !loading ? (
          <CCButton
            className={classes.disagree}
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            {intl.formatMessage({ id: "common.ui.ok" })}
          </CCButton>
        ) : (
          <></>
        )
      }
    />
  );
};

export default DialogWarn;
