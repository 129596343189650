import produce from "immer";
import moment from "moment";
import { SCHEDULE_CONTEXT_LIST } from "types/reservation";
import { makeIndexFormattedMessageList } from "types/utils";
import { getMaxTime, getMinTime, makeAdaptData } from "./utils";

const _getTimeSlotCount = context => {
  return context?.timeSlotCount || 3;
};
const _getTimeStep = context => {
  return context?.timeStep || 10;
};
const _getMin = (context, view) => {
  return context?.workTime
    ? getMinTime(context.workTime, view).toDate()
    : moment().startOf(view).toDate();
};
const _getMax = (context, view) => {
  return context?.workTime
    ? getMaxTime(
        context.workTime,
        _getTimeStep(context),
        _getTimeSlotCount(context),
        view
      ).toDate()
    : moment().endOf(view).toDate();
};
const _getCurrentMin = (context, view, currentDate) => {
  let _min = moment(_getMin(context, view));
  return moment(currentDate)
    .hour(_min.hour())
    .minute(_min.minute())
    .second(_min.second())
    .millisecond(_min.millisecond())
    .toDate();
};
const _getCurrentMax = (context, view, currentDate) => {
  let _max = moment(_getMax(context, view));
  return moment(currentDate)
    .hour(_max.hour())
    .minute(_max.minute() + 30)
    .second(_max.second())
    .millisecond(_max.millisecond())
    .toDate();
};
const _getNormalizeCurrentDate = (currentDate = new Date()) => {
  return moment(currentDate)
    .hour(moment().hour())
    .minute(Math.floor(moment().minute() / 30) * 30)
    .second(0)
    .millisecond(0)
    .toDate();
};
const _procContext = (context, view, currentDate = new Date()) => {
  return {
    reservationContext: context,
    timeStep: _getTimeStep(context),
    timeSlotCount: _getTimeSlotCount(context),
    min: _getMin(context, view),
    max: _getMax(context, view),
    currentMin: _getCurrentMin(context, view, currentDate),
    currentMax: _getCurrentMax(context, view, currentDate),
    normalizeCurrentDate: _getNormalizeCurrentDate(currentDate)
  };
};

const initializer = props => {
  return {
    currentView: props.defaultView || "week",
    currentDate: new Date(),
    anchorEl: null,
    selected: null,
    openDelDialog: null,
    events: [],
    adaptData: [],
    adaptStaffData: [],
    ccOpen: false,
    menuItem: makeIndexFormattedMessageList(SCHEDULE_CONTEXT_LIST),
    permissionDialog: false,
    ..._procContext(props.reservationContext, props.defaultView)
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setReservationContext":
      return produce(state, draft => {
        draft.reservationContext = action.target;
        draft.timeStep = _getTimeStep(action.target);
        draft.timeSlotCount = _getTimeSlotCount(action.target);
        draft.min = _getMin(action.target, draft.currentView);
        draft.max = _getMax(action.target, draft.currentView);
        draft.currentMin = _getCurrentMin(
          action.target,
          draft.currentView,
          draft.currentDate
        );
        draft.currentMax = _getCurrentMax(
          action.target,
          draft.currentView,
          draft.currentDate
        );
      });
    case "setCurrentView":
      return produce(state, draft => {
        draft.currentView = action.target;
        draft.min = _getMin(draft.reservationContext, action.target);
        draft.max = _getMax(draft.reservationContext, action.target);
        draft.currentMin = _getCurrentMin(
          draft.reservationContext,
          action.target,
          draft.currentDate
        );
        draft.currentMax = _getCurrentMax(
          draft.reservationContext,
          action.target,
          draft.currentDate
        );
      });
    case "setCurrentDate":
      return produce(state, draft => {
        draft.currentDate = action.target;
        draft.currentMin = _getCurrentMin(
          draft.reservationContext,
          draft.currentView,
          action.target
        );
        draft.currentMax = _getCurrentMax(
          draft.reservationContext,
          draft.currentView,
          action.target
        );
        draft.normalizeCurrentDate = _getNormalizeCurrentDate(action.target);
      });
    case "setAnchorEl":
      return produce(state, draft => {
        draft.anchorEl = action.target;
      });
    case "setEvents":
      return produce(state, draft => {
        draft.events = action.target;
      });
    case "setSelected":
      return produce(state, draft => {
        draft.selected = action.target;
      });
    case "setOpenDelDialog":
      return produce(state, draft => {
        draft.openDelDialog = action.target;
      });
    case "setNeedReceipt":
      return produce(state, draft => {
        draft.needReceipt = action.target;
      });
    case "setReceiptOpen":
      return produce(state, draft => {
        draft.receiptOpen = action.target;
      });
    case "setAdaptData":
      console.log(action.target);
      return produce(state, draft => {
        draft.adaptData = makeAdaptData(action.target, action.filter);
      });
    case "setCcOpen":
      return produce(state, draft => {
        draft.ccOpen = action.target;
      });
    case "setPermissionDialog":
      return produce(state, draft => {
        draft.permissionDialog = action.target;
      });
    default:
      return state;
  }
};

export { initializer, reducer };
