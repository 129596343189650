import React from "react";

const ChartitemIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M18.5 14L12 14 12 7 13 7 13 13 18.5 13z" />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default ChartitemIcon;
