import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useIntl } from "react-intl";
import { CCButton, CCDialogSimple, CCTypography } from 'styles/components';
import DialogErrorConfirm from '../DialogErrorConfirm'
const useStyles = makeStyles((theme) => ({
  root: {},
  warning___color: { color: theme.palette.error.main },
  desc___color: { color: theme.palette.sub.main },
  text: { marginTop: 8, width: 280 },
  disagree: { marginRight: 8 },
}));

const DialogError = (props) => {
  const { open, onClose, content } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <DialogErrorConfirm
    open={open}
    onClose={onClose}
    onClick={onClose}
    contents={
      <Box>
        <Box className={classes.text}>
          <CCTypography className={classes.warning___color}>
            {content}
          </CCTypography>
        </Box>
      </Box>
    }
    />
  );
};

export default DialogError;
