import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { CCPaper, CCTypography, CCButton, CCTooltip } from "styles/src/components";
import { InfiniteTable, translate, DialogWarningMessage, enqueueToast, DialogWarningConfirm } from "components";
import { useIntl } from "utils/language";
import { TableItem } from "../TableItems";
import { Grid, Box } from "@material-ui/core";
import { BATCH_REGISTRATION_PRODUCT, VERIFY_PRODUCT_EXCEL_FILE } from 'queries/product'
import { InfoIcon } from "styles/icons";
import UploadExcelFileButton from "../UploadExcelFileButton/UploadExcelFileButton";
import { useSnackbar } from "notistack";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
  root: {
    minWidth: 820,
    marginBottom: 10,
    padding: "6px important!",
    width: 1200,
    textAlign: "center",
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  category__table: {
    height: 600
  },
  button_row: {
    padding: 16
  },
  paper_table: {
    borderRadius: 'unset'
  },
  button_download: {
    textTransform: 'unset',
    height: 28
  },
  icon: {
    marginLeft: 8,
    marginTop: 6,
    color: 'gray'
  },
  summary: {
    padding: '8px 16px',
    borderTop: `1px solid ${theme.palette.border.main}`,
    width: '100%',
    textAlign: 'left',
  },
  color__blue: {
    color: '#0277BD',
    marginRight: 4
  },
  color__red: {
    color: theme.palette.error.main
  },
  row: {
    padding: '0 6px'
  }
}));

const DownloadBatchRegistrationExcelTemplate = () => {
  const classes = useStyle();
  const intl = useIntl();

  let currentLanguage = "en"

  switch (process.env.REACT_APP_LANGUAGE_REGION) {
    case "vi":
      currentLanguage = "vn";
      break;
    case "ar":
      currentLanguage = "eg";
      break;
    case "ru":
      currentLanguage = "ru";
      break;
    default:
      if (process.env.REACT_APP_LANGUAGE_REGION) {
        currentLanguage = process.env.REACT_APP_LANGUAGE_REGION;
      }
      break;
  }

  console.log("currentLanguage", currentLanguage);
  const filePath = `${window.location.origin}/Excel/Inventory/${currentLanguage}_item_batch.xlsx`;




  const handleDownload = () => {
    window.open(filePath, "_blank")
  }

  return (
    <CCButton
      variant="outlined"
      className={classes.button_download}
      onClick={handleDownload} >
      {intl.formatMessage({ id: "downloadExcelForm" })}
    </CCButton>)
}


const BatchRegistration = ({ onClose, onCancel, refetchGetProductList }) => {
  const classes = useStyle();
  const intl = useIntl();
  const client = useApolloClient();
  const [productList, setProductList] = useState([]);
  const [isOpenDialogNoProduct, setIsOpenDialogNoProduct] = useState(false)
  const [dataUpload, setDataUpload] = useState({
    successCount: 0,
    failCount: 0,
    total: 0,
    uploaded: false
  });
  const [errorDialog, setErrorDialog] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleResetData = (data) => {
    setProductList([])
    setDataUpload({
      successCount: 0,
      failCount: 0,
      total: 0,
      uploaded: false
    })
  }


  const uploadExcelFile = async (e) => {
    await client
      .mutate({
        mutation: VERIFY_PRODUCT_EXCEL_FILE,
        variables: {
          file: e.src.file
        }
      })
      .then(async ({ data }) => {
        if (data?.verifyProductExcelFile?.items) {
          const { successCount, failCount, items } = data.verifyProductExcelFile
          setProductList(items || [])

          setDataUpload({
            successCount,
            failCount,
            uploaded: true,
            total: successCount + failCount
          })
        }
      })
      .catch(errors => {
        const { graphQLErrors, networkError } = errors;
        if (graphQLErrors) {
          graphQLErrors.forEach(graphQLError => {
            setErrorDialog(graphQLError.message)
          });
        } else if (networkError?.result?.errors) {
          const nErrors = networkError.result.errors;
          nErrors.forEach(networkError => {
            setErrorDialog(networkError.message)
          });
        }
        else {
          console.log("uploadExcelFile error", errors)
          setErrorDialog(intl.formatMessage({ id: "errorMsg.unkownerror" }))
        }

      });
  }

  const openSuccessToast = () => {
    enqueueToast(
      enqueueSnackbar,
      ` ${intl.formatMessage(
        {
          id: "inventoryBatchRegistrationSuccess"
        }
      )}`
    );
  }


  const onSave = async () => {
    if (productList.filter(item => !item.isFailed).length === 0) {
      setErrorDialog(intl.formatMessage({ id: "errorMsg.allFailureItems" }))
    } else {
      await client
        .mutate({
          mutation: BATCH_REGISTRATION_PRODUCT,
          variables: {
            input: productList.map(item => {
              delete item.cost
              delete item.isFailed
              // delete item.failedCode
              delete item.failedMessage
              return item
            })
          }
        })
        .then(async ({ errors }) => {
          if (dataUpload.total) {
            openSuccessToast();
            onClose && onClose();
            refetchGetProductList();
          } else {
            openUploadNoProductDialog()
          }
        });
    }
  };

  const formatSummaryMessage = () => {
    const { successCount, failCount } = dataUpload;
    if (successCount || failCount) {
      return intl.formatMessage({ id: "summaryUploadBatch" }, { success: dataUpload.successCount, failure: dataUpload.failCount })
    }

    return intl.formatMessage({ id: "summaryUploadBatchNoProduct" })
  };

  const openUploadNoProductDialog = () => {
    setIsOpenDialogNoProduct(true);
  };

  const closeUploadNoProductDialog = () => {
    setIsOpenDialogNoProduct(false);
  }

  return (
    <Grid container className={classes.root}>
      <Grid container direction="row" className={classes.button_row} justify="space-between" alignItems="center">
        <Grid item>
          <Grid container wrap={"nowrap"} alignItems="center">
            <UploadExcelFileButton
              title={translate(intl, "uploadExeclFile")}
              onlyDirect={true}
              onChange={uploadExcelFile}
              handleResetData={handleResetData}
            />
            <CCTooltip title={<>
              {intl.formatMessage({ id: "maximumItemsCanRegister" }, { number: 500 })}
            </>} placement="top">
              <div><InfoIcon className={classes.icon} /></div>
            </CCTooltip>
          </Grid>
        </Grid>


        <DownloadBatchRegistrationExcelTemplate />
      </Grid>
      {isOpenDialogNoProduct &&
        <DialogWarningConfirm
          open={isOpenDialogNoProduct}
          // onClose={() =>
          //   dispatchState({ type: "hasAnotherConfirmError", target: false })
          // }
          onAgree={async () => {
            closeUploadNoProductDialog();
            // onChangeInput(hasConfirmationPlan.id);
            // dispatchState({
            //   type: "pickChart",
            //   target: { value: hasConfirmationPlan?.id }
            // });
            // dispatchState({ type: "hasAnotherConfirmError", target: false });
          }}
          title={intl.formatMessage({ id: "summaryUploadBatchNoProduct" })}
          // subTitle={"*최근 확정계획을 완료(또는 삭제)해 주세요."}
          agreeButtonLabel={intl.formatMessage({ id: "common.ui.confirm" })}
        // disagreeButtonLabel={intl.formatMessage({ id: "common.ui.confirm" })}
        />
      }

      {dataUpload.uploaded &&
        <CCTypography variant='body1' className={classes.summary}>
          <span className={classes.color__blue}>{dataUpload.total ? dataUpload.total : ''}</span>
          {formatSummaryMessage()}
        </CCTypography>
      }
      <CCPaper className={classes.paper_table}>
        <InfiniteTable
          heads={[
            {
              key: "isFailed",
              label: intl.formatMessage({ id: "status" }),
              width: 60,
              component: ({ cellData }) => {
                return (
                  <TableItem
                    disableTypography
                    label={cellData ? "Failure" : "Success"}
                    className={classes.row}
                  />
                );
              }
            },
            {
              key: "failedMessage",
              width: 200,
              label: intl.formatMessage({ id: "reasonForFailure" }),
              component: ({ cellData }) => {
                return (
                  <TableItem
                    tooltip={true}
                    label={cellData}
                    className={classes.row}
                  />
                );
              }
            },
            {
              key: "major",
              label: intl.formatMessage({ id: "mainCategory" }),
              width: 120,
              component: ({ cellData }) => {
                return (
                  <TableItem
                    label={cellData}
                    className={classes.row}
                    tooltip={true}
                  />
                );
              }
            },
            {
              key: "minor",
              label: intl.formatMessage({ id: "subCategory" }),
              width: 120,
              component: ({ cellData }) => {
                return (
                  <TableItem
                    label={cellData}
                    className={classes.row}
                    tooltip={true}
                  />
                );
              }
            },
            {
              key: "productName",
              label: intl.formatMessage({ id: "productName" }),
              width: 300,
              component: ({ cellData }) => {
                return (
                  <TableItem
                    label={cellData}
                    className={classes.row}
                    tooltip={true}
                  />
                );
              }
            },
            {
              key: "manufacture",
              label: intl.formatMessage({ id: "manufacturer" }),
              width: 120,
              component: ({ cellData }) => {
                return (
                  <TableItem
                    label={cellData}
                    className={classes.row}
                    tooltip={true}
                  />
                );
              }
            },

            {
              key: "unit",
              label: intl.formatMessage({ id: "unit" }),
              width: 60,
              component: ({ cellData, rowData }) => {
                return (
                  <TableItem
                    label={cellData === "EA" ? cellData : `${cellData} (${rowData?.binCnt})`}
                    className={classes.row}
                    tooltip={true}
                  />
                );
              }
            },
            {
              key: "currentCnt",
              label: intl.formatMessage({ id: "currentStock" }),
              width: 110,
              component: ({ cellData, rowData }) => {
                return (
                  <TableItem
                    label={cellData}
                    // className={[classes.row, classes.color__blue]}
                    className={clsx({
                      [classes.row]: true, 
                      [classes.color__red]: rowData?.optCnt >= rowData?.currentCnt
                    })}
                    tooltip={true}
                  />
                );
              }
            },
            {
              key: "optCnt",
              label: intl.formatMessage({ id: "adequateStock" }),
              width: 110,
              component: ({ cellData }) => {
                return (
                  <TableItem
                    label={cellData}
                    className={classes.row}
                    tooltip={true}
                  />
                );
              }
            },
          ]}
          contents={productList || []}
          rowClickHighlight={true}
          className={classes.category__table}
        />

      </CCPaper>

      <Box
        style={{
          overflowY: "auto",
          padding: 10,
          display: "flex",
          justifyContent: "flex-end"
        }}
        marginLeft={"auto"}
        className={classes.icon__end}
      >
        <CCButton
          className={classes.cancel__icon}
          variant={"text"}
          color={"normal"}
          onClick={onCancel}
        >
          {intl.formatMessage({ id: "cancel" })}
        </CCButton>
        <CCButton
          variant={"contained"}
          color={"primary"}
          onClick={onSave}
        >
          {intl.formatMessage({ id: "save" })}
        </CCButton>
      </Box>
      {Boolean(errorDialog) &&
        <DialogWarningMessage
          open={Boolean(errorDialog)}
          title={errorDialog}
          agreeButtonLabel={intl.formatMessage({ id: "common.ui.common.ui.ok" })}
          onClose={() => { setErrorDialog(false); }}
          onAgree={() => { setErrorDialog(false); }}
        />}
    </Grid>

  );
};

export default BatchRegistration;
