import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { CCButton, CCDialogSimple, CCTypography } from 'styles/components';
import { WarningIcon } from 'styles/src/themes/common/icons';
import translate from '../Translate';

const useStyles = makeStyles((theme) => ({
  root: {},
  warning___color: { color: theme.palette.error.main },
  text: { marginTop: 8, width: 280 },
  disagree: { marginRight: 8 },
}));

const DialogCancel = (props) => {
  const { open, onClose, onAgree, onDisagree } = props;
  const classes = useStyles();
  const intl = useIntl();
  return (
    <CCDialogSimple
      open={open}
      isHeader={false}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <Box>
          <Box>
            <WarningIcon className={classes.warning___color} />
          </Box>
          <Box className={classes.text}>
            <CCTypography className={classes.warning___color}>
              {translate(intl, "common.ui.changesWillNotBeSaved")}
            </CCTypography>
            <CCTypography className={classes.warning___color}>
              {translate(intl, "common.ui.doYouWantToProceed")}
            </CCTypography>
          </Box>
        </Box>
      }
      endActions={
        <>
          <CCButton
            className={classes.disagree}
            color='sub'
            onClick={() => {
              onDisagree && onDisagree();
            }}
          >
            {translate(intl, "no")}
          </CCButton>
          <CCButton
            variant='contained'
            color='error'
            onClick={() => {
              onAgree && onAgree();
            }}
          >
            {translate(intl, "yes")}
          </CCButton>
        </>
      }
    />
  );
};

export default DialogCancel;
