import React, { useReducer } from "react";

const initialState = {
  refresh: 0
};

const reducer = (state, action) => {
  return { ...state, refresh: state.refresh + 1 };
};

const DBNotificationContext = React.createContext();
const DBNotificationContextConsumer = DBNotificationContext.Consumer;

const DBNotificationContextProvider = props => {
  const [dbNotificationContext, dispatchDBNotification] = useReducer(
    reducer,
    initialState
  );
  const value = { dbNotificationContext, dispatchDBNotification };

  return (
    <DBNotificationContext.Provider value={value}>
      {props.children}
    </DBNotificationContext.Provider>
  );
};

export default DBNotificationContextProvider;
export { DBNotificationContext, DBNotificationContextConsumer };
