import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
// import { useIntl } from "../../../locales/language";
import { v4 } from "uuid";
import { useIntl } from "../../../locales/language";
// import { v4 } from "uuidv4";
import { CCButton, CCDialogSimple, CCTypography } from "../../components";

const useStyle = makeStyles(theme => ({
  root: {
    width: 312
  },
  sketchPicker: {
    boxShadow: "none !important",
    width: "312px !important",
    padding: "0px !important"
  },
  colorContainerRoot: {
    maxWidth: 312
  },
  colorContainer: {
    width: "100%",
    marginBottom: 8,
    "&:last-child": {
      marginBottom: 0
    }
  },
  margin__left__8: {
    marginLeft: 8
  },
  margin__top__14: { marginTop: 14 },
  moreColorButton: {
    width: 48,
    height: 24
  },
  pickerClose: {
    transform: "scale(1,0)",
    height: 0
  },
  colorButton: {
    borderRadius: 8,
    overflow: "hidden",
    width: 24,
    height: 24,
    cursor: "pointer",
    border: `1px solid ${theme.palette.border.main}`,
    "&:hover": {
      outline: `1px dotted ${theme.palette.font.hex}`
    }
  },
  selected: {
    outline: `1px dotted ${theme.palette.font.hex}`
  },
  colorButtonDisable: {
    cursor: "default",
    "&:hover": {
      outline: "none"
    }
  }
}));

const Default_Color = [
  { r: 252, g: 222, b: 227, a: 1 },
  { r: 253, g: 231, b: 217, a: 1 },
  { r: 253, g: 229, b: 165, a: 1 },
  { r: 231, g: 245, b: 213, a: 1 },
  { r: 210, g: 238, b: 250, a: 1 },
  { r: 229, g: 221, b: 234, a: 1 },
  { r: 255, g: 255, b: 32, a: 1 },
  { r: 255, g: 255, b: 255, a: 1 },
  { r: 0, g: 0, b: 0, a: 1 }
];
let BaseId = v4();
const ColorList = function* (list, onClick, color, id) {
  const classes = useStyle();
  for (let i in list) {
    let _id = BaseId + "Basic" + i;
    yield (
      <Grid
        key={i}
        item
        className={clsx(classes.colorButton, {
          [classes.selected]:
            JSON.stringify(color) === JSON.stringify(list[i]) && _id === id
        })}
        onClick={() => {
          onClick(list[i], _id);
        }}
      >
        <svg
          xmlns={"http://www.w3.org/2000/svg"}
          width={24}
          height={24}
          viewBox={"0 0 24 24"}
        >
          <rect
            width={24}
            height={24}
            fill={`rgba(${list[i].r},${list[i].g},${list[i].b},${list[i].a})`}
          />
        </svg>
      </Grid>
    );
  }
};

const RecentList = function* (list, onClick, color, id) {
  const classes = useStyle();
  for (let i = 0; i < 8; i++) {
    if (list[i]) {
      let _id = BaseId + "Recent" + i;
      yield (
        <Grid
          key={i}
          item
          className={clsx(classes.colorButton, {
            [classes.selected]:
              JSON.stringify(color) === JSON.stringify(list[i]) && _id === id
          })}
          onClick={() => {
            onClick(list[i], _id);
          }}
        >
          <svg
            xmlns={"http://www.w3.org/2000/svg"}
            width={24}
            height={24}
            viewBox={"0 0 24 24"}
          >
            <rect
              width={24}
              height={24}
              fill={`rgba(${list[i].r},${list[i].g},${list[i].b},${list[i].a})`}
            />
          </svg>
        </Grid>
      );
    } else {
      yield (
        <Grid
          key={i}
          item
          className={clsx(classes.colorButton, classes.colorButtonDisable)}
        >
          <svg
            xmlns={"http://www.w3.org/2000/svg"}
            width={24}
            height={24}
            viewBox={"0 0 24 24"}
          >
            <rect width={24} height={24} fill={`rgba(0,0,0,0)`} />
          </svg>
        </Grid>
      );
    }
  }
};

const RGBvalues = (function () {
  const _hex2dec = function (v) {
    return parseInt(v, 16);
  };

  const _splitHEX = function (hex) {
    let c;
    if (hex.length === 4) {
      c = hex.replace("#", "").split("");
      return {
        r: _hex2dec(c[0] + c[0]),
        g: _hex2dec(c[1] + c[1]),
        b: _hex2dec(c[2] + c[2]),
        a: 1
      };
    } else {
      return {
        r: _hex2dec(hex.slice(1, 3)),
        g: _hex2dec(hex.slice(3, 5)),
        b: _hex2dec(hex.slice(5)),
        a: 1
      };
    }
  };

  let _splitRGB = function (rgb) {
    let c = rgb.slice(rgb.indexOf("(") + 1, rgb.indexOf(")")).split(",");
    let flag = false,
      obj;
    c = c.map(function (n, i) {
      // eslint-disable-next-line
      return i !== 3 ? parseInt(n, 10) : (flag = true), parseFloat(n);
    });
    obj = {
      r: c[0],
      g: c[1],
      b: c[2],
      a: 1
    };
    if (flag) obj.a = c[3];
    return obj;
  };

  const color = function (col) {
    if (typeof col !== "string") {
      return col;
    }
    let slc = col.slice(0, 1);
    if (slc === "#") {
      return _splitHEX(col);
    } else if (slc.toLowerCase() === "r") {
      return _splitRGB(col);
    } else {
      console.log(
        "!Ooops! RGBvalues.color(" + col + ") : HEX, RGB, or RGBa strings only"
      );
    }
  };

  return {
    color: color
  };
})();

const CCColorPicker = props => {
  const { value, open, onChange, onClose } = props;
  const classes = useStyle();
  const intl = useIntl();
  const [openPicker, setOpenPicker] = useState(false);
  const [color, setColor] = useState(
    Boolean(value) ? RGBvalues.color(value) : null
  );
  const [recentList, setRecentList] = useState(
    localStorage.getItem("recentColor")
      ? JSON.parse(localStorage.getItem("recentColor"))
      : []
  );
  const [selectedId, setSelectedId] = useState("");
  console.log("color", color);
  useEffect(() => {
    setColor(prevState => {
      if (value && JSON.stringify(value) !== JSON.stringify(prevState)) {
        return value;
      }
      return prevState;
    });
  }, [value]);

  useEffect(() => {
    if (open) {
      setRecentList(
        localStorage.getItem("recentColor")
          ? JSON.parse(localStorage.getItem("recentColor"))
          : []
      );
    }
  }, [open]);

  const _onClickColor = (e, id) => {
    setSelectedId(id);
    setColor(e);
  };
  return (
    <Box>
      <CCDialogSimple
        open={open}
        title={intl.formatMessage({ id: "ccColor" })}
        onClose={() => onClose()}
        onCloseButton={() => onClose()}
        // onRefreshButton={() => setColor(value)}
        contents={
          <Grid container className={clsx(classes.root)}>
            <Grid item className={clsx({ [classes.pickerClose]: !openPicker })}>
              <SketchPicker
                color={color}
                onChange={e => {
                  setColor(e.rgb);
                }}
                className={clsx(classes.sketchPicker)}
                presetColors={[]}
              />
            </Grid>
            <Grid item className={clsx(classes.colorContainerRoot)}>
              <Grid container>
                <Grid item className={clsx(classes.colorContainer)}>
                  <Grid container direction={"column"}>
                    <Grid item>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <CCTypography variant={"h6"}>
                            {intl.formatMessage({
                              id: "ccBasic"
                            })}
                          </CCTypography>
                        </Grid>
                        <Grid item>
                          <CCButton
                            variant={"outlined"}
                            size={"small"}
                            onClick={() =>
                              setOpenPicker(prevState => !prevState)
                            }
                          >
                            {openPicker
                              ? intl.formatMessage({
                                  id: "ccSimply"
                                })
                              : intl.formatMessage({
                                  id: "ccDetail"
                                })}
                          </CCButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={clsx(classes.margin__top__14)}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        {[
                          ...ColorList(
                            Default_Color,
                            _onClickColor,
                            color,
                            selectedId
                          )
                        ]}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={clsx(classes.colorContainer)}>
                  <Grid container direction={"column"}>
                    <Grid item>
                      <CCTypography variant={"h6"}>
                        {intl.formatMessage({
                          id: "ccRecentlyUsed"
                        })}
                      </CCTypography>
                    </Grid>
                    <Grid item className={clsx(classes.margin__top__14)}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        {[
                          ...RecentList(
                            recentList,
                            _onClickColor,
                            color,
                            selectedId
                          )
                        ]}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        endActions={
          <>
            <CCButton
              key={"cancel"}
              variant={"outlined"}
              onClick={() => onClose()}
            >
              {intl.formatMessage({
                id: "ccCancel"
              })}
            </CCButton>
            <CCButton
              key={"apply"}
              disabled={!Boolean(color)}
              className={clsx(classes.margin__left__8)}
              variant={"contained"}
              onClick={() => {
                let _colorIndex = recentList.findIndex(item => {
                  return (
                    item?.r === RGBvalues.color(color)?.r &&
                    item?.g === RGBvalues.color(color)?.g &&
                    item?.b === RGBvalues.color(color)?.b &&
                    item?.a === RGBvalues.color(color)?.a
                  );
                });
                let _targetList = [...recentList];
                if (_colorIndex >= 0) {
                  _targetList.splice(_colorIndex, 1);
                }
                let _temp = [RGBvalues.color(color), ..._targetList].slice(
                  0,
                  9
                );
                localStorage.setItem("recentColor", JSON.stringify(_temp));
                onChange(RGBvalues.color(color));
                onClose();
              }}
            >
              {intl.formatMessage({
                id: "ccApply"
              })}
            </CCButton>
          </>
        }
      />
    </Box>
  );
};
CCColorPicker.propTypes = {
  /**
   * Default Color - rgba object or rgba string
   */
  value: propTypes.oneOfType([
    propTypes.shape({
      r: propTypes.number,
      g: propTypes.number,
      b: propTypes.number,
      a: propTypes.number
    }),
    propTypes.string
  ]),
  /**
   * Dialog Open
   */
  open: propTypes.bool.isRequired,
  /**
   * Value Change handler
   */
  onChange: propTypes.func.isRequired,
  /**
   * Close handler
   */
  onClose: propTypes.func.isRequired
};

export default CCColorPicker;
