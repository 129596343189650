import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CCTypography } from 'styles/src/components';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100%',
    minHeight: 54,
    cursor: 'pointer',
  },
}));

const CommonItem = (props) => {
  const { label = '-', onClick = () => {} } = props;
  const classes = useStyle();
  return (
    <Grid
      container
      justify={'center'}
      alignItems={'center'}
      className={classes.root}
      onClick={onClick}
    >
      <Grid item>
        <CCTypography variant={'body1'}>
          {label === '' ? '-' : label}
        </CCTypography>
      </Grid>
    </Grid>
  );
};
export default CommonItem;
