import React from "react";

const MaleIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M9.458 10.959l2.168 2.587c.086.103.232.15.376.142.14.008.286-.039.372-.142l2.168-2.587h2.722c1.033 0 1.837.685 1.726 1.47l-1.472 10.403c-.016.108-2.56.164-5.2.168h-.635c-2.642-.004-5.186-.06-5.2-.168L5.01 12.43c-.11-.786.693-1.471 1.726-1.471h2.722zM12 2c2.21 0 4 1.783 4 3.982 0 2.199-1.79 3.981-4 3.981S8 8.181 8 5.982C8 3.782 9.79 2 12 2z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default MaleIcon;
