import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import React from "react";
import { useIntl } from "react-intl";
import { CCIconButton, CCTooltip, CCTypography } from "styles/components";
import { InfoIcon } from "styles/icons";
import { translate } from "components";
const useStyle = makeStyles(theme => ({
  root: {
    width: 500,
    maxWidth: 500,
    minHeight: 232,
    padding: 16
  },
  tooltip: {
    "& p": {
      marginBottom: 16,
      color: theme.palette.white,
      "&:nth-last-of-type(-n+2)": { marginBottom: 0 }
    }
  },
  title: {
    color: theme.palette.white,
    marginBottom: 16
  },
  description: {
    color: `${theme.palette.select.main} !important`
  }
}));

const TooltipContent = () => {
  const classes = useStyle();
  const intl = useIntl();
  return (
    <Box className={classes.tooltip}>
      <CCTypography variant="h3" className={classes.title}>
        {translate( intl,"settings.clinics.goldSouceToolTip1" )}
      </CCTypography>
      <CCTypography variant="body1" className={classes.description}>
        {translate( intl,"settings.clinics.goldSouceToolTip2" )}
      </CCTypography>
      <CCTypography variant="body1">
        {translate( intl,"settings.clinics.goldSouceToolTip3" )}
      </CCTypography>
      <CCTypography variant="body1">
        {translate( intl,"settings.clinics.goldSouceToolTip4" )}
      </CCTypography>
      <CCTypography variant="body1">
        {translate( intl,"settings.clinics.goldSouceToolTip5" )}
      </CCTypography>
      <CCTypography variant="body1">
        {translate( intl,"settings.clinics.goldSouceToolTip6" )}
      </CCTypography>
    </Box>
  );
};

const GoldSourcingMethodTooltip = () => {
  const classes = useStyle();

  return (
    <CCTooltip
      disableFocusListener
      placement={localStorage.getItem("lang") === 'ar' ? 'left-end' : "right-end"}
      enterDelay={50}
      TransitionComponent={Zoom}
      title={<TooltipContent />}
      className={classes.root}
    >
      <CCIconButton color={"sub"}>
        <InfoIcon />
      </CCIconButton>
    </CCTooltip>
  );
};

export default GoldSourcingMethodTooltip;
