import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { MeridiemInput, TimeInput } from "./component";

const useStyle = makeStyles(
  theme => ({
    root: { height: 316, backgroundColor: "#ffffff" }
  }),
  { name: "CCFlattenDateTimePicker-TimePicker" }
);

const TimePicker = props => {
  const {
    value,
    onChange,
    step,
    disableMeridiem,
    stepperHours,
    stepperMinutes
  } = props;
  const classes = useStyle();
  return (
    <Grid
      className={clsx(classes.root)}
      container
      direction={"column"}
      wrap={"nowrap"}
    >
      <TimeInput
        value={value.clone()}
        onChange={onChange}
        step={step}
        disableMeridiem={disableMeridiem}
        stepperHours={stepperHours}
        stepperMinutes={stepperMinutes}
      />
      {!Boolean(disableMeridiem) && (
        <MeridiemInput value={value.clone()} onChange={onChange} />
      )}
    </Grid>
  );
};

TimePicker.propTypes = {
  value: PropTypes.instanceOf(moment).isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  disableMeridiem: PropTypes.bool,
  stepperMinutes: PropTypes.bool,
  stepperHours: PropTypes.bool
};
TimePicker.defaultProps = {
  step: 10,
  disableMeridiem: false
};
export default TimePicker;
