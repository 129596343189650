import React from "react";

const PeriodIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="4"
        cy="4"
        r="2.667"
        fill="currentColor"
        transform="translate(8 14)"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default PeriodIcon;
