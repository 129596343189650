import { useApolloClient } from "@apollo/client";
// import useCreateNotification from "./useCreateNotification";
import { AppContext } from "context";
import { UPDATE_PRODUCT } from "queries/product";
import { useContext } from "react";
import { getCustomFormat, getTimestamp } from "utils/datetime";
import moment from "moment";

const useUpdateProductIn = () => {
  const client = useApolloClient();
  const { appContext } = useContext(AppContext);

  const updateProduct = async (e, productInfo, filterArray) => {
    const _changed = productInfo?.storage?.currentCnt + Number(e?.currentCnt);
    delete productInfo.hospital;

    const calculateExpireList = (inputValue, currentValue) => {
      const expireDateToUnix = expireItem => {
        const expireDate = expireItem.expireDate && expireItem.expireDate?.unix ? expireItem.expireDate.unix() : expireItem.expireDate;
        return { ...expireItem, expireDate }
      }
      const expireCountToNumber = expireItem => {
        return { ...expireItem, expireCount: Number(expireItem.expireCount) }
      }
      const filterZeroCount = expireItem => {
        return expireItem.expireCount
      }
      const inputExpireList = inputValue?.expireList?.map(expireDateToUnix).map(expireCountToNumber).filter(filterZeroCount) || [];
      const currentExpireList = currentValue?.storage?.expire?.map(expireDateToUnix).map(expireCountToNumber).filter(filterZeroCount);
      const mergeExpireLists = (inputExpireList, currentExpireList) => {
        const mergedList = currentExpireList.map(item => { return { ...item } });
        const sameDay = (unix1, unix2) => {
          return getCustomFormat(unix1,
            "X",
            "YYYY-MM-DD")
            === getCustomFormat(unix2,
              "X",
              "YYYY-MM-DD");
        }
        inputExpireList.forEach(inputExpireItem => {
          const sameDateExpireItem = mergedList.find(currentExpireItem => {
            return sameDay(currentExpireItem.expireDate, inputExpireItem.expireDate)
          });
          if (sameDateExpireItem) {
            sameDateExpireItem.expireCount = Number(sameDateExpireItem.expireCount) + Number(inputExpireItem.expireCount)
            console.log("sameDateExpireItem", sameDateExpireItem);
          } else {
            const newDateExpireItem = {
              ...inputExpireItem,
              expireCount: Number(inputExpireItem.expireCount)
            }
            console.log("newDateExpireItem", newDateExpireItem);
            mergedList.push(newDateExpireItem);
          }
        })
        console.log("mergedList", mergedList)
        return mergedList;
      }
      return mergeExpireLists(inputExpireList, currentExpireList);
    }

    const newExpireList = calculateExpireList(e, productInfo);
    try {
      await client
        .mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            input: {
              ...productInfo,
              storage: {
                ...productInfo?.storage,
                editorID: appContext?.staff?.id,
                editDate: getTimestamp(),
                canExpire: Boolean(e?.expireList),
                currentCnt: _changed,
                isAppropriate: _changed >= productInfo?.storage?.optCnt,
                expire: newExpireList,
              }
            }
          }
        })
    } catch (e) {
      console.error(e);
    }
  };
  return updateProduct;
};


const useUpdateProductOut = () => {
  const client = useApolloClient();
  const { appContext } = useContext(AppContext);

  const updateProductOut = async (e, productInfo, filterArray) => {
    console.log("updateProductOut e", e);
    console.log("updateProductOut productInfo", productInfo);
    console.log("updateProductOut filterArray", filterArray);
    delete productInfo.hospital

    const newCount = productInfo?.storage?.currentCnt - e?.currentCntOut;
    console.log("updateProductOut newCount", newCount);

    try {
      await client
        .mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            input: {
              ...productInfo,
              storage: {
                ...productInfo?.storage,
                editorID: appContext?.staff?.id,
                editDate: getTimestamp(),
                canExpire: Boolean(e?.expireList),
                currentCnt: newCount,
                isAppropriate: newCount >= productInfo?.storage?.optCnt,
                expire: filterArray.map(item => {
                  return { ...item, expireCount: item.expireCount - item.count }
                })
              }
            }
          }
        })
    } catch (e) {
      console.error(e);
    }
  };
  return updateProductOut;
};
const useUpdateProduct = { useUpdateProductIn, useUpdateProductOut }
export default useUpdateProduct;
