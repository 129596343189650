import { DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(() => ({
  root: { padding: 16 },
  spacing: {}
}));

const CCDialogActions = props => {
  const { classes: userClasses, ...others } = props;
  const classes = useStyles();

  return (
    <DialogActions
      classes={{
        root: clsx(userClasses && userClasses.root, classes.root),
        spacing: clsx(userClasses && userClasses.spacing, classes.spacing)
      }}
      {...others}
    >
      {props.children}
    </DialogActions>
  );
};

export default CCDialogActions;
