import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";
import React from "react";

const useStyle = makeStyles(
  () => ({
    root: { userSelect: "none" }
  }),
  { name: "CCFlattenDateTimePicker-DatePicker-Calendar-WeekHeader" }
);

function* WeekHeaderItem(gongdan, gongdanLocale) {
  let _startOfWeek = Boolean(gongdan)
    ? moment().locale("gongdan").startOf("week")
    : moment().startOf("week");
  let _endOfWeek = Boolean(gongdan)
    ? moment().locale("gongdan").endOf("week")
    : moment().endOf("week");
  do {
    yield (
      <Grid
        key={"WeekHeader" + _startOfWeek.unix()}
        item
        style={{ width: 36, height: 36 }}
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant={"body1"}>
          {/*{item.toUpperCase().charAt(0)}*/}
          {_startOfWeek.format("dd").toUpperCase()}
        </Typography>
      </Grid>
    );
    _startOfWeek = _startOfWeek.add(1, "day");
    // console.log(_startOfWeek.unix() >= _endOfWeek.unix());
  } while (_startOfWeek.unix() < _endOfWeek.unix());
}

const WeekHeader = props => {
  const { gongdan, gongdanLocale } = props;
  // console.log("weekHeader", props);
  const classes = useStyle();
  return (
    <Grid className={clsx(classes.root)} item container justifyContent={"center"}>
      {/*{moment*/}
      {/*  .localeData()*/}
      {/*  .weekdaysMin()*/}
      {/*  .map(item => {*/}
      {/*    console.log(item);*/}
      {/*    console.log(moment.localeData().weekdaysMin());*/}
      {/*    return (*/}
      {/*      <Grid*/}
      {/*        key={"WeekHeader" + item}*/}
      {/*        item*/}
      {/*        style={{ width: 36, height: 36 }}*/}
      {/*        container*/}
      {/*        justifyContent={"center"}*/}
      {/*        alignItems={"center"}*/}
      {/*      >*/}
      {/*        <Typography variant={"body1"}>*/}
      {/*          /!*{item.toUpperCase().charAt(0)}*!/*/}
      {/*          {item.toUpperCase()}*/}
      {/*        </Typography>*/}
      {/*      </Grid>*/}
      {/*    );*/}
      {/*  })}*/}
      {[...WeekHeaderItem(gongdan, gongdanLocale)]}
    </Grid>
  );
};

export default WeekHeader;
