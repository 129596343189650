import { Box, Grid, makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { translate } from "components";
import ImageViewer from "components/Explorer/Component/FilePhoto/component/FileViewer/Component/ImageViewer/ImageViewer";
import { CVButton, CVDialogSimpleDel } from "components/index";
// import jimp from "jimp";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Files from "react-butterfiles";
import { useIntl } from "react-intl";
import { CCButton, CCMenu, CCTypography } from "styles/src/components";
import CCDialogSimple from "styles/src/components/CCDialogSimple";
import CCIconButton from "styles/src/components/CCIconButton";
import { ClipIcon, WarningIcon } from "styles/src/themes/common/icons";
import RemoveIcon from "styles/src/themes/common/icons/RemoveIcon";
import { getObject, putObject, Target } from "utils/s3";
import { v4 as uuid } from "uuid";
const useStyles = makeStyles(theme => ({
  root: {},
  error: {
    color: theme.palette.error.main
  }
}));

const UploadImageButton = props => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    multiple = false,
    // value, // 따로 저장 하는 값 photoId
    buttonIcon,
    onChange, // photo onChange
    onlyDirect, // true 일 경우 클라우드 없이 바로 올리기
    title,
    target,
    disabled,
    disablePreview,
    startIcon,
    accept,
    allowExtension,
    // filetype,
    color = "normal",
    maxSize,
    permission = '',
    handleLoading
  } = props;

  const [open, setOpen] = useState("");
  const [openSizeCheck, setOpenSizeCheck] = useState(false);
  const labId = localStorage.getItem("labId");
  const [openLogoMenu, setOpenLogoMenu] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [openImageView, setOpenImageView] = useState(false);
  const [del, setDel] = useState(false);
  const fileUploadButton = useRef();
  const fileUploadIconButton = useRef();

  // const [createPhoto] = useMutation(CREATE_PHOTO, {
  //   onCompleted: (data) => {
  //     if (data.createPhoto) {
  //       getPhoto({
  //         variables: { labId: labId, id: data.createPhoto },
  //       });
  //     }
  //   },
  // });

  // const fetchObjectUrl = async photo => {
  //   if (photo.content.object) {
  //     const url = await getObject(
  //       photo.content.filetype === "image"
  //         ? Target.PHOTO
  //         : photo.content.filetype,
  //       labId,
  //       photo.content.object
  //     );
  //     photo.content.source = url;
  //     photo.content.thumbnail = url;
  //   }
  //   return photo;
  // };

  // const [getPhoto] = useLazyQuery(GET_PHOTO, {
  //   onCompleted: (data) => {
  //     if (data.getPhoto) {
  //       onChange(data.getPhoto);
  //       setPhoto(data.getPhoto);
  //     }
  //   },
  // });

  const handleBlobFileChange = async file => {
    const ext = file.name.slice(file.name.lastIndexOf(".") + 1).toUpperCase();
    if (allowExtension.includes(ext)) {
      if (file) {
        const fileUUID = uuid();
        handleLoading(fileUUID);
        await putObject(target, labId, fileUUID, file);
        const url = await getObject(target, labId, fileUUID);

        url &&
          onChange({
            id: fileUUID,
            target,
            name: file.name,
            type: file.type
          });
        handleLoading(false);
      }
    } else {
      setOpen(allowExtension.join(", "));
      handleLoading(false);
    }
  };

  // const handleSavePhoto = (url, file, fileUUID, ext) => {
  //   let filetypeState;
  //   switch (ext) {
  //     case "STL":
  //       filetypeState = "stl";
  //       break;
  //     case "ZIP":
  //       filetypeState = "zip";
  //       break;
  //     default:
  //       filetypeState = filetype;
  //       break;
  //   }

  //   switch (filetypeState) {
  //     case "image":
  //     case "stamp":
  //     case "logo":
  //       jimp
  //         .read(url)
  //         .then(image => {
  //           const fields = file?.name?.split(".");
  //           const detail = {
  //             width: image.bitmap.width,
  //             height: image.bitmap.height,
  //             size: file?.size,
  //             extension: fields[fields.length - 1]
  //           };
  //           // createPhoto({
  //           //   variables: {
  //           //     labId: labId,
  //           //     id: '',
  //           //     type: patient?.id ? category : 'library',
  //           //     date: getTimestampFromDate(),
  //           //     lastModifiedTime: getTimestampFromDate(),
  //           //     patient: patient?.id ? patient.id : '__libraries__',
  //           //     content: {
  //           //       filetype: filetypeState,
  //           //       filename: file?.name || 'temp_file',
  //           //       filenode: uuid(),
  //           //       folder: folder,
  //           //       object: fileUUID,
  //           //       detail: JSON.stringify(detail),
  //           //     },
  //           //   },
  //           // });
  //         })
  //         .catch(e => console.error(e));
  //       break;
  //     default:
  //       // createPhoto({
  //       //   variables: {
  //       //     labId: labId,
  //       //     id: '',
  //       //     type: patient?.id ? category : 'library',
  //       //     date: getTimestampFromDate(),
  //       //     lastModifiedTime: getTimestampFromDate(),
  //       //     patient: patient?.id ? patient.id : '__libraries__',
  //       //     content: {
  //       //       filetype: filetypeState,
  //       //       filename: file?.name || 'temp_file',
  //       //       filenode: uuid(),
  //       //       folder: folder,
  //       //       object: fileUUID,
  //       //     },
  //       //   },
  //       // });

  //       break;
  //   }
  // };

  // useEffect(() => {
  //   if (value) {
  //     getPhoto({ variables: { labId: labId, id: value } });
  //   }
  // }, [value, getPhoto, labId]);

  return (
    <>
      <Files
        multiple={multiple}
        maxSize={maxSize}
        multipleMaxSize={maxSize}
        // multipleMaxCount={1}
        accept={accept && accept}
        onSuccess={async (files) => {
          if (multiple) {
            for (let i in files) {
              await handleBlobFileChange(files[i])
            }
          } else { handleBlobFileChange(files[0]) }

        }}
        onError={errors => {
          if (errors[0].type === "maxSizeExceeded") {
            setOpenSizeCheck(true);
          } else if (errors[0].type === "unsupportedFileType") {
            setOpen(allowExtension.join(", "));
          } else {
            console.error(errors);
          }
        }}
      >
        {({ browseFiles }) => {
          return (
            <Grid container alignItems={"center"}>
              <Grid item hidden={!!buttonIcon}>
                <CVButton
                  // permission={ }
                  permission={permission}
                  disabled={disabled}
                  variant={"contained"}
                  color={color}
                  startIcon={startIcon}
                  children={title}
                  ref={fileUploadButton}
                  onClick={() =>
                    onlyDirect ? browseFiles() : setOpenLogoMenu(true)
                  }
                />
              </Grid>
              <Grid item hidden={!buttonIcon}>
                <CCIconButton
                  children={buttonIcon}
                  ref={fileUploadIconButton}
                  onClick={() =>
                    onlyDirect ? browseFiles() : setOpenLogoMenu(true)
                  }
                />
              </Grid>
              <Grid item>
                <CCMenu
                  anchorEl={
                    buttonIcon
                      ? fileUploadIconButton.current
                      : fileUploadButton.current
                  }
                  open={openLogoMenu}
                  onClose={() => setOpenLogoMenu(false)}
                  menuItems={[
                    {
                      value: "2",
                      label: "PC에서 가져오기",
                      onClick: browseFiles
                    }
                  ]}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                />
              </Grid>
              <Grid item style={{ margin: "0 8px" }} hidden={disablePreview}>
                <Grid container alignItems={"center"}>
                  <Grid item hidden={!photo}>
                    <Link
                      onClick={() => setOpenImageView(true)}
                      component="button"
                    >
                      <CCTypography variant="body1">
                        {photo?.content?.filename}
                      </CCTypography>
                    </Link>
                  </Grid>
                  <Grid item hidden={!photo}>
                    <CCIconButton
                      variant={"text"}
                      style={{ margin: 0 }}
                      onClick={() => {
                        setDel(true);
                      }}
                    >
                      <RemoveIcon />
                    </CCIconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Files>
      {del && (
        <CVDialogSimpleDel
          open={del}
          onAgree={() => {
            setDel(false);
            setPhoto(null);
            onChange(null);
          }}
          onDisagree={() => setDel(false)}
          onClose={() => setDel(false)}
        />
      )}

      {openImageView && (
        <CCDialogSimple
          maxWidth="md"
          open={openImageView}
          onClose={() => setOpenImageView(false)}
          title={translate(intl, "common.ui.viewImage")}
          onCloseButton={() => setOpenImageView(false)}
          contents={
            <Box style={{ height: 660 }}>
              <ImageViewer clickedItem={photo} />
            </Box>
          }
          endActions={
            <CCButton
              variant={"contained"}
              onClick={() => setOpenImageView(false)}
            >
              {translate(intl, "common.ui.save")}
            </CCButton>
          }
        />
      )}
      {Boolean(open) && (
        <CCDialogSimple
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpen("")}
          contents={
            <>
              <CCTypography className={classes.error}>
                {translate(intl, "common.ui.thisFileExtensionsIsNotAllowed")}
              </CCTypography>
              <CCTypography>
                {translate(
                  intl,
                  "common.ui.youCanOnlyUploadPngJpgJpegZipStlFiles",
                  { number: open, extensions: allowExtension.join(", ") }
                )}
              </CCTypography>
            </>
          }
          endActions={
            <CCButton color="primary" onClick={() => setOpen("")}>
              {translate(intl, "common.ui.confirm")}
            </CCButton>
          }
        />
      )}
      {openSizeCheck && (
        <CCDialogSimple
          style={{ width: 300 }}
          open
          title={<WarningIcon className={classes.error} />}
          onClose={() => setOpenSizeCheck(false)}
          contents={
            <>
              <CCTypography className={classes.error}>
                {translate(intl, "common.ui.uploadFileSizeExceed", { sizeMB: (maxSize === "0.3mb" ? "300kb" : maxSize) })}

              </CCTypography>
            </>
          }
          endActions={
            <CCButton color="primary" onClick={() => setOpenSizeCheck("")}>
              {translate(intl, "ccCheck")}
            </CCButton>
          }
        />
      )}
    </>
  );
};

UploadImageButton.defaultProps = {
  value: "",
  onChange: e => console.log(e),
  onlyDirect: false, // 바로 파일 등록 시 사용
  nonAttachPhoto: false,
  title: "Profile Image",
  target: Target.PHOTO,
  disablePreview: true, // 오른쪽 파일 링크
  tab: 2,
  category: "photo",
  folder: "attachment",
  startIcon: <ClipIcon />,
  //accept: ["image/jpg", "image/jpeg", "image/png"],
  allowExtension: ["PNG", "JPG", "JPEG"],
  maxSize: "10mb",
  handleLoading: _ => _
};

UploadImageButton.propTypes = {
  patient: PropTypes.object // { id: patientContext.id, value: patientContext }
};

export default UploadImageButton;
