import React from "react";

const PhotonIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient id="xzzipk3n8a" x1="50%" x2="50%" y1="50%" y2="100%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#CFD8DC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#FFF"
            fillOpacity=".01"
            d="M0 0H32V32H0z"
            transform="translate(-541.000000, -369.000000) translate(541.000000, 369.000000)"
          />
          <path
            fill="#00897B"
            d="M27 3c1.105 0 2 .895 2 2v22c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2h22zM12.667 16L6 24h20l-5-6.4-3.383 4.336L12.667 16zM20.5 8C19.117 8 18 9.117 18 10.5s1.117 2.5 2.5 2.5 2.5-1.117 2.5-2.5S21.883 8 20.5 8z"
            transform="translate(-541.000000, -369.000000) translate(541.000000, 369.000000)"
          />
          <path
            fill="url(#xzzipk3n8a)"
            d="M9 1v2H5c-1.054 0-1.918.816-1.995 1.85L3 5v22c0 1.054.816 1.918 1.85 1.995L5 29h22c1.054 0 1.918-.816 1.995-1.85L29 27V5c0-1.054-.816-1.918-1.85-1.995L27 3h-4V1h4c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4H5c-2.21 0-4-1.79-4-4V5c0-2.21 1.79-4 4-4h4zm3.667 15l4.95 5.936L21 17.6l5 6.4H6l6.667-8zM20.5 8c1.383 0 2.5 1.117 2.5 2.5S21.883 13 20.5 13 18 11.883 18 10.5 19.117 8 20.5 8z"
            transform="translate(-541.000000, -369.000000) translate(541.000000, 369.000000)"
          />
          <path
            fill="#00E1D1"
            d="M9 0H23V3H9z"
            transform="translate(-541.000000, -369.000000) translate(541.000000, 369.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default PhotonIcon;
