import React from "react";

const BarcodeIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="currentColor">
            <path
              d="M8 10v8H0v-8h8zm10 6v2h-2v-2h2zm-4 0v2h-2v-2h2zm-8-4H2v4h4v-4zm6 2v2h-2v-2h2zm4 0v2h-2v-2h2zm-2-2v2h-2v-2h2zm4 0v2h-2v-2h2zm-6-2v2h-2v-2h2zm4 0v2h-2v-2h2zM8 0v8H0V0h8zm10 0v8h-8V0h8zM6 2H2v4h4V2zm10 0h-4v4h4V2z"
              transform="translate(-4920 -192) translate(4920 192) translate(3 3)"
            />
          </g>
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-4920 -192) translate(4920 192)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default BarcodeIcon;
