import React from "react";

const DollarkorIcon = props => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zM8.042 7H6.15l.686 3.805H6v1.14h1.038l.2 1.11H6v1.14h1.44L8.125 18h2.36l.736-3.805h1.54L13.515 18h2.343l.686-3.805H18v-1.14h-1.255l.2-1.11H18v-1.14h-.854L17.833 7H15.94l-.619 3.805h-1.556L13.063 7h-2.126l-.72 3.805h-1.54L8.043 7zm1.556 7.195-.2 1.54-.185-1.54h.385zm5.189 0-.184 1.54-.218-1.54h.402zM10 11.945l-.2 1.11h-.754l-.184-1.11H10zm2.31 0 .217 1.11h-1.07l.217-1.11h.636zm2.828 0-.167 1.11h-.77l-.218-1.11h1.155zm-3.146-2.557.167 1.417h-.335l.168-1.417z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default DollarkorIcon;
