import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  PhoneNumberRegionField,
  PriceAmountTextField
} from "components";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { CCDateField, CCTextField, CCTypography } from "styles/components";
import { makeList } from "types";
import { JOB_POSITION, PERMISSION, STATUS } from "types/staff";
import { formatUnixInSecToYYYYMMDD } from "utils/utils";
import { translate } from "../../../../../../../components";
import IdField from "../IdField";
import JobTypeField from "../JobTypeField";
import RequiredTextField from "../RequiredTextField";
import RequiredDateField from "../RequiredDateField";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8
  },
  grid__container: {
    overflow: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  width170: { width: 170 },
  width276: { width: 276 },
  width271: { width: 271 },
  width568: { width: 568 },
  error__color: { color: theme.palette.error.main },
  divider: {
    width: "100%",
    padding: "16px 8px"
  },
  inputMarginPhoneNumber: {
    margin: '8px !important'
  },
  marginTop8: { marginTop: 8 },
  authority__desc: {
    marginTop: 8,
    marginLeft: 8,
    color: theme.palette.input.main
  },
  id__desc: {
    marginLeft: 8,
    lineHeight: "20px",
    color: theme.palette.input.main,
    "& span": {
      color: theme.palette.input.hover
    }
  }
}));

const StaffField = props => {
  const { field, errors, setIsModify, onHandle, onHandleError, mode } = props;


  // classes
  const classes = useStyles(props);
  const jobPosition = makeList(JOB_POSITION);
  const status = makeList(STATUS);
  const authority = makeList(PERMISSION);
  const intl = useIntl();

  // function
  const handleField = name => e => {
    setIsModify(true);
    if (
      name === "birthDate" ||
      name === "resignedDate" ||
      name === "hiredDate"
    ) {
      onHandle({ ...field, [name]: String(moment(e.target.value, "L").unix()) });
      return;
    }
    onHandle({ ...field, [name]: e.target.value });
  };
  const handleFieldError = name => errorState => {
    console.log('handleFieldError', name, errorState)
    onHandleError({ [name]: errorState });
  };

  return (
    <Box className={classes.root}>
      <Grid className={classes.grid__container} container direction="column">
        <Grid item className={classes.marginTop8}>
          <Grid container>
            <JobTypeField
              label={translate(intl, "settings.employee.occupation")}
              className={classes.width170}
              value={field.occupation}
              onChange={handleField("occupation")}
              errorOnChange={handleFieldError("occupation")}
              required
            />
            <CCTextField
              className={classes.width170}
              variant={"outlined"}
              label={translate(intl, "settings.employee.position")}
              value={field.position || ""}
              onChange={handleField("position")}
              margin={"dense"}
              select
            >
              {jobPosition.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </CCTextField>
            <CCTextField
              className={classes.width170}
              variant={"outlined"}
              label={translate(intl, "settings.employee.status")}
              value={field.status || ""}
              onChange={(event) => {
                if (event.target.value === STATUS.RESIGNED.value) {
                  if (!field.resignedDate) {
                    onHandle({ ...field, resignedDate: moment().unix(), status: event.target.value })
                    return
                  }
                }
                handleField("status")(event)
              }}
              margin={"dense"}
              select
            >
              {status.map(
                item =>
                  item.value !== STATUS.WORKING.value && (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      className={clsx({
                        [classes.error__color]:
                          item.value === STATUS.RESIGNED.value
                      })}
                    >
                      {item.label}
                    </MenuItem>
                  )
              )}
            </CCTextField>
          </Grid>
        </Grid>

        <Grid item className={classes.marginTop8}>
          <CCDateField
            className={classes.width170}
            variant={"outlined"}
            label={translate(intl, "settings.employee.hiredDate")}
            value={formatUnixInSecToYYYYMMDD(field.hiredDate)}
            onChange={handleField("hiredDate")}
            margin={"dense"}
            InputLabelProps={{
              shrink: !!field.hiredDate
            }}
          />
          <CCDateField
            className={classes.width170}
            variant={"outlined"}
            label={translate(intl, "settings.employee.resignDate")}
            value={formatUnixInSecToYYYYMMDD(field.resignedDate)}
            InputLabelProps={{
              shrink: !!field.resignedDate
            }}
            onChange={handleField("resignedDate")}
            margin={"dense"}
            disabled={field.status !== STATUS.RESIGNED.value}
          />
        </Grid>
        <Grid item className={classes.marginTop8}>
          <CCTextField
            className={classes.width170}
            variant={"outlined"}
            label={translate(intl, "settings.employee.authority")}
            value={field.authority === 0 ? 0 : (field.authority || 2)}
            onChange={handleField("authority")}
            margin={"dense"}
            select
          >
            {authority.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </CCTextField>
        </Grid>

        <Grid item>
          <CCTypography variant={"body2"} className={classes.authority__desc}>
            {translate(intl, "settings.employee.accessNote")}
          </CCTypography>
        </Grid>
        <Grid item className={classes.divider}>
          <Divider variant={"fullWidth"} />
        </Grid>

        <Grid item>
          <Grid item>
            <Tooltip title={field.staffId || ""} placement="top">
              <IdField
                required
                className={classes.width271}
                value={field.staffId || ""}
                onChange={handleField("staffId")}
                errorOnChange={handleFieldError("staffId")}
                disabled={mode !== "add"}
                mode={mode}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <CCTypography variant={"body2"} className={classes.id__desc}>
              {translate(intl, "settings.employee.idNote1")}
            </CCTypography>
          </Grid>
          <Grid item>
            <CCTypography variant={"body2"} className={classes.id__desc}>
              {translate(intl, "settings.employee.idNote2")}
            </CCTypography>
          </Grid>
          <Grid item>
            <CCTypography variant={"body2"} className={classes.id__desc}>
              {/* {translate(intl, "common.ui.theInitialPasswordIs_1234abcd")}{" "} */}
              {translate(intl, "common.ui.theInitialPasswordIs_1234abcd")}
            </CCTypography>
          </Grid>
        </Grid>

        <Grid item className={classes.divider}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid item>
          <Tooltip title={field.staffName || ""} placement="top">
            <RequiredTextField
              className={clsx(classes.field, classes.width276)}
              variant={"outlined"}
              label={translate(intl, "common.ui.name")}
              value={field.staffName || ""}
              onChange={handleField("staffName")}
              errorOnChange={handleFieldError("staffName")}
              InputProps={{
                inputProps: {
                  maxLength: 50
                }
              }}
              required
            />
          </Tooltip>
          <RequiredDateField
            className={clsx(classes.field, classes.field__birthDate)}
            variant={"outlined"}
            width={200}
            label={translate(intl, "settings.employee.dateBirth")}
            value={formatUnixInSecToYYYYMMDD(field.birthDate)}
            onChange={handleField("birthDate")}
            errorOnChange={handleFieldError("birthDate")}
            InputLabelProps={{
              shrink: !!field.birthDate
            }}
            required
          />
        </Grid>

        <Grid className={classes.marginTop8}>
          <Tooltip title={field.cellphoneNumber || ""} placement="top">
            <PhoneNumberRegionField
              minLength={5}
              className={clsx(classes.inputMarginPhoneNumber, classes.width276)}
              label={translate(intl, "common.ui.cellNo")}
              value={field.cellphoneNumber || ""}
              onChange={e => {
                handleField("cellphoneNumber")(e)
                handleFieldError("cellphoneNumber")(e.target.value.length < 5)
              }}
              // errorOnChange={handleFieldError("cellphoneNumber")}
              required
              error={errors.cellphoneNumber}
            // InputProps={{
            //   inputProps: {
            //     maxLength: 20
            //   }
            // }}
            />
          </Tooltip>
          <Tooltip title={field.telephoneNumber || ""} placement="top">
            <PhoneNumberRegionField
              className={clsx(classes.inputMarginPhoneNumber, classes.width276)}
              label={translate(intl, "common.ui.telNo")}
              value={field.telephoneNumber || ""}
              onChange={e => {
                handleField("telephoneNumber")(e)
                // handleFieldError("telephoneNumber")(e.target.value.length < 5)
              }}
            // error={errors.managerCellNumber}
            // InputProps={{
            //   inputProps: {
            //     maxLength: 20
            //   }
            // }}
            />
          </Tooltip>
        </Grid>

        <Grid item className={classes.marginTop8}>
          <Tooltip title={field.address || ""} placement="top">
            <CCTextField
              className={clsx(classes.field, classes.width568)}
              variant={"outlined"}
              label={translate(intl, "common.ui.address")}
              value={field.address || ""}
              onChange={handleField("address")}
              margin={"dense"}
              InputProps={{
                inputProps: {
                  maxLength: 200
                }
              }}
            />
          </Tooltip>
        </Grid>

        <Grid item className={classes.marginTop8}>
          <Tooltip title={field?.salary ? field?.salary : ""} placement="top">
            <PriceAmountTextField
              className={clsx(classes.field, classes.width568)}
              variant={"outlined"}
              label={translate(intl, "settings.employee.salary")}
              value={field?.salary ? field?.salary?.toString() : ""}
              onChange={handleField("salary")}
              margin={"dense"}
              errorOnChange={handleFieldError("salary")}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StaffField;
