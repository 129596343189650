import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { CCTooltip } from "../../components";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 14,
    minWidth: 36,
    minHeight: 36,
    padding: 6,
    margin: "8px 0"
  },
  text: {
    borderRadius: 20,
    color: props => {
      if (props.color === "selected") return theme.palette.select.main;
      if (props.color === "primary") return theme.palette.primary.main;
      if (props.color === "sub") return theme.palette.sub.main;

      return theme.palette.icon;
    },
    background: props => {
      return "none";
    },
    "&:hover": {
      // background: props => {
      //   return "white";
      // },
      // color: props => {
      //   if (props.color === "selected") return theme.palette.select.main;
      //   return "white";
      // }
    },
    "&:disabled": {
      color: props => {
        if (props.color === "selected") return "#ffffff";
        return "rgba(0, 0, 0, 0.38)";
      },
      backgroundColor: props => {
        if (props.color === "selected") return theme.palette.select.main;
        return "none";
      }
    }
  },
  contained: {
    borderRadius: 4,
    background: props => {
      if (props.color === "secondary") return theme.palette.secondary.main;
      if (props.color === "selected") return theme.palette.select.main;
      if (props.color === "error") return theme.palette.error.main;
      if (props.color === "normal") return theme.palette.normal.main;
      if (props.color === "primary") return theme.palette.primary.main;
      if (props.color === "sub") return theme.palette.sub.main;

      return theme.palette.secondary.main;
    },
    "&:hover": {
      background: props => {
        if (props.color === "primary") return theme.palette.primary.dark;
        if (props.color === "secondary") return theme.palette.secondary.dark;
        if (props.color === "error") return theme.palette.error.dark;
        if (props.color === "selected") return theme.palette.select.main;
        if (props.color === "normal") return theme.palette.normal.dark;
        if (props.color === "sub") return theme.palette.sub.dark;

        return theme.palette.secondary.dark;
      },
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.38)",
      backgroundColor: props => {
        if (props.color === "selected") return theme.palette.select.main;
        return theme.palette.inactive.rgba;
      },
      boxShadow: "none"
    },
    border: 0,
    color: props => {
      if (props.color === "normal") return theme.palette.icon;
      return "white";
    },
    boxShadow: props => {
      if (props.color === "selected") return "none";
      return "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)";
    }
  },
  outlined: {
    borderRadius: 4,
    color: props => {
      if (props.color === "selected") return "#ffffff";
      return theme.palette.icon;
    },
    background: props => {
      if (props.color === "selected") return theme.palette.select.main;
      return "rgba(0, 0, 0, 0)";
    },
    border: props => {
      if (props.color === "selected") return "none";
      return "solid 1px rgba(0, 0, 0, 0.16)";
    },
    "&:hover": {
      background: props => {
        if (props.color === "selected") return theme.palette.select.main;
        return theme.palette.background.default;
      }
    },
    "&:disabled": {
      color: props => {
        if (props.color === "selected") return "#ffffff";
        return "rgba(0, 0, 0, 0.38)";
      },
      backgroundColor: props => {
        if (props.color === "selected") return theme.palette.select.main;
        return theme.palette.inactive.rgba;
      },
      border: props => {
        if (props.color === "selected") return "none";
        if (props.color === "default") return "none";
        return `solid 1px ${theme.palette.inactive.rgba}`;
      },
      boxShadow: "none"
    },
    boxShadow: props => {
      if (props.color === "selected") return "none";
      return "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)";
    }
  },
  sizeSmall: {
    padding: 0,
    minWidth: "24px",
    minHeight: "24px"
  }
}));

const CCIconButton = forwardRef((props, ref) => {
  const { color, className, variant, size, tooltip, ...other } = props;
  const classes = useStyles(props);
  let variantClass = clsx(className, classes.root);

  if (variant === "text") {
    variantClass = clsx(variantClass, classes.text);
  } else if (variant === "outlined") {
    variantClass = clsx(variantClass, classes.outlined);
  } else if (variant === "contained") {
    variantClass = clsx(variantClass, classes.contained);
  } else {
    variantClass = clsx(variantClass);
  }

  if (size === "small") {
    variantClass = clsx(variantClass, classes.sizeSmall);
  }

  return (
    <>
      {tooltip && (
        <CCTooltip title={tooltip}>
          <span>
            <IconButton
              className={variantClass}
              {...other}
              ref={ref}
              disableRipple={true}
              disableFocusRipple={true}
            />
          </span>
        </CCTooltip>
      )}
      {!tooltip && (
        <IconButton
          className={variantClass}
          {...other}
          ref={ref}
          disableRipple={true}
          disableFocusRipple={true}
        />
      )}
    </>
  );
});

CCIconButton.propTypes = {
  /** Button 색상 설정 */
  color: PropTypes.string,
  /** Button 배경 설정 */
  variant: PropTypes.string,
  /** Button 크기 설정 */
  size: PropTypes.string,
  /** Button hover 시 보이는 텍스트 설정 */
  tooltip: PropTypes.string
};

CCIconButton.defaultProps = {
  color: "default",
  variant: "text"
};

export default CCIconButton;
