import React from "react";

const CCIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M20 2c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H6l-4 4 .01-18c0-1.1.89-2 1.99-2zM8.58 5c-1.05 0-1.885.39-2.507 1.168C5.358 7.065 5 8.308 5 9.894c0 1.502.355 2.696 1.066 3.58.618.766 1.424 1.149 2.419 1.149.791 0 1.458-.24 2-.72.492-.437.857-1.105 1.094-2.005l.067-.277-1.492-.578c-.13.686-.345 1.186-.64 1.501-.297.315-.648.473-1.055.473-.554 0-1.005-.247-1.352-.743-.347-.495-.52-1.328-.52-2.5 0-1.13.178-1.94.533-2.432.356-.49.815-.736 1.378-.736.393 0 .735.134 1.025.403.253.235.433.56.54.975l.04.183 1.524-.444c-.19-.817-.49-1.432-.901-1.847C10.15 5.292 9.435 5 8.58 5zm7.693 0c-1.049 0-1.885.39-2.507 1.168-.715.897-1.073 2.14-1.073 3.726 0 1.502.356 2.696 1.067 3.58.618.766 1.424 1.149 2.418 1.149.792 0 1.458-.24 2-.72.492-.437.857-1.105 1.094-2.005l.067-.277-1.491-.578c-.132.686-.345 1.186-.641 1.501-.297.315-.648.473-1.054.473-.555 0-1.005-.247-1.352-.743-.347-.495-.52-1.328-.52-2.5 0-1.13.177-1.94.532-2.432.356-.49.815-.736 1.378-.736.393 0 .735.134 1.025.403.254.235.434.56.54.975l.04.183 1.524-.444c-.19-.817-.49-1.432-.901-1.847-.576-.584-1.29-.876-2.146-.876z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
);
export default CCIcon;
