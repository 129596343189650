import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { CCTypography } from "styles/src/components";

const useStyle = makeStyles(theme => ({
  root: {
    height: "100%",
    paddingLeft: 0,
    boxSizing: "border-box"
  }
}));

const CommonHeader = props => {
  const { label } = props;
  const classes = useStyle();
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.root}
    >
      <Grid item>
        <CCTypography variant={"body1"}>{label}</CCTypography>
      </Grid>
    </Grid>
  );
};
export default CommonHeader;
