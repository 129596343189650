// import { useQuery } from "@apollo/react-hooks";
import { DialogWarn } from 'components';
import React, { useState } from 'react';

// const SEARCH_LOG_PAGINATION = gql`
//   query searchLogPagination(
//     $hospitalId: String!
//     $args: InputSearchLog
//     $page: InputPage
//     $sort: InputSort
//   ) {
//     searchLogPagination(
//       hospitalId: $hospitalId
//       args: $args
//       page: $page
//       sort: $sort
//     ) {
//       data {
//         ip
//         date
//         staff
//         staffName
//       }
//     }
//   }
// `;

const DialogLastConnection = (props) => {
  const { open, onClose } = props;
  const [date] = useState('');
  const [ip] = useState('');
  // const { loading } = useQuery(SEARCH_LOG_PAGINATION, {
  //   variables: {
  //     hospitalId: localStorage.getItem('labId'),
  //     args: {
  //       staff: localStorage.getItem("id"),
  //       category: "18",
  //       action: "3"
  //     },
  //     page: {
  //       limit: 10
  //     },
  //     sort: {
  //       indexKey: "date",
  //       order: true
  //     }
  //   },
  //   // skip: !open,
  //   fetchPolicy: "network-only",
  //   onCompleted: data => {
  //     if (data && data.searchLogPagination) {
  //       const latest = data.searchLogPagination.data[1];
  //       if (latest) {
  //         setDate(moment.unix(latest.date).format("YYYY-MM-DD HH:mm:ss"));
  //         setIp(latest.ip);
  //       }
  //     }
  //   }
  // });

  return (
    <DialogWarn
      open={open}
      onClose={onClose}
      // loading={loading}
      text={
        <>
          마지막 접속 날짜는 {date} 입니다.
          <br />
          (IP: {ip})
        </>
      }
    />
  );
};

export default DialogLastConnection;
