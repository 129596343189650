import moment from "moment";
import "moment/locale/ko";
import "moment/locale/vi";
import "moment/locale/ru";
import "moment/locale/ar";

function getTimestamp() {
  return moment().unix();
}
const getMomentLocale = lang => {
  moment.locale(lang);
};

const getMoment = (timestamp = null) =>
  timestamp ? moment(timestamp) : moment();

function getTimestampMsec() {
  return moment().valueOf();
}

function getUtcTime(format) {
  return moment.utc().format(format);
}

function getLocalTime(format) {
  return moment().format(format);
}

function getUtcTimeFromTimestamp(timestamp, format) {
  return moment.unix(timestamp).utc().format(format);
}

function getLocalTimeFromTimestamp(timestamp, format) {
  return (
    moment
      .unix(timestamp)
      // .tz(process.env.TIME_ZONE)
      .format(format)
  );
}

function getTimestampFromDate(date, format = "YYYY-MM-DD HH:mm:ss") {
  let input = date;

  if (!input) input = moment().format(format);

  return moment(input).unix();
}

function getTimeRangeFromTimestamp(timestamp, dateformat) {
  let begin = null;
  let end = null;

  if (!timestamp || !dateformat) return null;

  let date = moment
    .unix(timestamp)
    .tz(process.env.TIME_ZONE)
    .format("YYYY-MM-DD HH:mm:ss");

  // console.log("date", date);

  begin = moment(date).startOf(dateformat).unix();
  end = moment(date).endOf(dateformat).unix();

  // console.log("begin>", moment.unix(begin).format("YYYY-MM-DD HH:mm:ss"));
  // console.log("end>", moment.unix(end).format("YYYY-MM-DD HH:mm:ss"));

  return {
    begin: begin,
    end: end
  };
}

const timeDiff = (x, y) => moment.duration(x.diff(y));
const dayDiff = (x, y) => x.isSame(y, "days");
const monthDiff = (x, y) => x.isSame(y, "month");
const yearDiff = (x, y) => x.isSame(y, "years");
const isSameDay = (x, y) => dayDiff(x, y) && monthDiff(x, y) && yearDiff(x, y);
const makeDateClone = date => date.clone();
const dayIsBetween = (start, end, between) =>
  start &&
  end &&
  between &&
  between.isSameOrBefore(end, "days") &&
  between.isSameOrAfter(start, "days");
const sortFunc = (a, b) => {
  if (b.isAfter(a, "days")) return -1;
  if (b.isBefore(a, "days")) return 1;
  return 0;
};
const isFirstDayOfWeek = date =>
  isSameDay(makeDateClone(date), makeDateClone(date).startOf("week"));
const isLastDayOfWeek = date =>
  isSameDay(makeDateClone(date), makeDateClone(date).endOf("week"));
const hourDiff = (x, y) => x.isSame(y, "hours");
const minutesDiff = (x, y) => x.isSame(y, "minutes");
/**
 * @function getDateRange
 * @param {object}[x=moment]
 * @param {string}[unitOfTime='day'||unitOfTime='week'||unitOfTime='month']
 * @returns {object}[object={end: number, begin: number}]
 */
const getDateRange = (x, unitOfTime) => ({
  begin: x.startOf(unitOfTime).unix(),
  end: x.endOf(unitOfTime).unix()
});
/**
 * @function timeDiffStr
 * @param {object} [x=moment]
 * @param {object} [y=moment]
 * @param {string} format
 * @returns {string}
 */
const timeDiffStr = (x, y, format) => moment(x.diff(y)).utc().format(format);

const getUnix = timestamp => moment.unix(timestamp);

const unixCloneSubtractTimestamp = (timestamp, number, type) => {
  return getUnix(timestamp).clone().subtract(number, type).unix();
};

const unixCloneAddTimestamp = (timestamp, number, type) => {
  return getUnix(timestamp).clone().add(number, type).unix();
};

const getCustomFormat = (timestamp, input, result) => {
  if (Boolean(input)) {
    return moment(timestamp, input).format(result);
  }
  return moment(timestamp).format(result);
};

const getDateToTimestamp = (date, format = null) => {
  if (format) {
    return moment(date, format).unix();
  }
  return moment(new Date(date)).unix();
};

const getEndTimestamp = (
  type,
  timestamp = null,
  momentObj = null,
  momentType = null
) => {
  if (type && momentObj && momentType) {
    return moment(momentObj, momentType)
      .endOf(type)
      .unix();
  }
  if (timestamp && momentObj) {
    return moment(momentObj)
      .unix(timestamp)
      .endOf(type)
      .unix();
  }
  if (timestamp) {
    return getUnix(timestamp)
      .endOf(type)
      .unix();
  }
  if (momentObj) {
    return moment(momentObj)
      .endOf(type)
      .unix();
  }
  return moment()
    .endOf(type)
    .unix();
};

const getMomentFormatTimestamp = (timestamp, format = "L") =>
  moment(timestamp, format);

export {
  getMoment,
  getMomentLocale,
  getTimestamp,
  getTimestampMsec,
  getUtcTime,
  getLocalTime,
  getUtcTimeFromTimestamp,
  getLocalTimeFromTimestamp,
  getTimestampFromDate,
  getTimeRangeFromTimestamp,
  timeDiff,
  dayDiff,
  monthDiff,
  isSameDay,
  makeDateClone,
  dayIsBetween,
  sortFunc,
  isFirstDayOfWeek,
  isLastDayOfWeek,
  hourDiff,
  minutesDiff,
  timeDiffStr,
  getDateRange,
  unixCloneSubtractTimestamp,
  unixCloneAddTimestamp,
  getUnix,
  getCustomFormat,
  getDateToTimestamp,
  getEndTimestamp,
  getMomentFormatTimestamp
};
