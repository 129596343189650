import { Box, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(
  () => ({
    height: { height: "100%" },
    root: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: "gray",
      opacity: 0.9,
      zIndex: props => props?.zIndex || 9999
    },
    container: {
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center"
    }
  }),
  { name: "CCLoading" }
);

const Loading = props => {
  const { open, msg, noAnimation, zIndex } = props;
  const classes = useStyles({ zIndex: zIndex });

  return (
    <>
      {open && (
        <Box className={clsx(classes.root, classes.height)}>
          <Grid container className={clsx(classes.container, classes.height)}>
            <Grid item>
              {!noAnimation && <img src="loading.gif" alt="loading_gif" height="200" />}
              <Box>{msg && msg}</Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

Loading.defaultProps = {
  noAnimation: false
};

export default Loading;
