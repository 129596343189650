import { ONLY_ORALEXAM_TYPES } from "types/oralexam";

const DOC_CATEGORIES = {
  GENERAL: {
    //동의서/문진표
    value: "general",
    localeKey: "docCategories.general"
  },
  CERTIFICATION: {
    //제증명
    value: "certification",
    localeKey: "docCategories.certification"
  },
  ORAL_EXAMINATION: {
    //구강검진
    value: "oralExamination",
    localeKey: "docCategories.oralExamination"
  }
};

const DOC_TYPES = {
  QUESTIONNAIRE: {
    // 문진표
    value: "questionnaire",
    localeKey: "docTypes.questionnaire"
  },
  CONSENT: {
    //동의서
    value: "consent",
    localeKey: "docTypes.consent"
  },
  PRECAUTION: {
    value: "precaution",
    localeKey: "docTypes.precaution"
  },
  HEALTHINSURANCE: {
    value: "healthInsurance",
    localeKey: "docTypes.healthInsurance"
  },
  MEDICALBENEFIT: {
    value: "medicalBenefit",
    localeKey: "docTypes.medicalBenefit"
  },
  DOCISSUE: {
    value: "docIssue",
    localeKey: "docTypes.docIssue"
  },
  ORAL_EXAM_QUESTIONNAIRE: {
    // 구강검진 문진표
    value: "oralExamQuestionnaire",
    localeKey: "docTypes.oralExamQuestionnaire"
  },
  ORAL_EXAM_RESULTS: {
    //구강검진 결과표
    value: "oralExamResults",
    localeKey: "docTypes.oralExamResults"
  }
};

const DOC_NAMES_QUESTIONNAIRE = {
  NEWPATIENTDETAIL: {
    value: "newPatientDetail",
    localeKey: "docNamesQuestionnaire.newPatientDetail"
  },
  NEWPATIENTSIMPLE: {
    value: "newPatientSimple",
    localeKey: "docNamesQuestionnaire.newPatientSimple"
  },
  MEDICALHISTORY: {
    value: "medicalHistory",
    localeKey: "docNamesQuestionnaire.medicalHistory"
  },
  IMPLANTPATIENT: {
    value: "implantPatient",
    localeKey: "docNamesQuestionnaire.implantPatient"
  },
  ANTISMOKINGTREATMENT: {
    value: "antiSmokingTreatment",
    localeKey: "docNamesQuestionnaire.antiSmokingTreatment"
  },
  JAWJOINTDISEASE: {
    value: "jawJointDisease",
    localeKey: "docNamesQuestionnaire.jawJointDisease"
  }
};

const DOC_NAMES_CONSENT = {
  DENTALSURGERY: {
    value: "dentalSurgery",
    localeKey: "docNamesConsent.dentalSurgery"
  },
  ROOTCANAL: {
    value: "rootCanal",
    localeKey: "docNamesConsent.rootCanal"
  },
  IMPLANT: {
    value: "implant",
    localeKey: "docNamesConsent.implant"
  },
  SEDATIONMETHOD: {
    value: "sedationMethod",
    localeKey: "docNamesConsent.sedationMethod"
  },
  DENTURE: {
    value: "denture",
    localeKey: "docNamesConsent.denture"
  },
  ORTHODONTICS: {
    value: "orthodontics",
    localeKey: "docNamesConsent.orthodontics"
  },
  PHOTOGRAPHCONSENT: {
    value: "photographConsent",
    localeKey: "docNamesConsent.photographConsent"
  },
  MEDICALRECORDSACCESSCONSENT: {
    value: "medicalRecordsAccessConsent",
    localeKey: "docNamesConsent.medicalRecordsAccessConsent"
  },
  MEDICALRECORDSACCESSDELEGATION: {
    value: "medicalRecordsAccessDelegation",
    localeKey: "docNamesConsent.medicalRecordsAccessDelegation"
  },
  EXTRACTIONTAKINGOVER: {
    value: "extractionTakingOver",
    localeKey: "docNamesConsent.extractionTakingOver"
  },
  PERSONALINFORMATIONCOLLECTION: {
    value: "personalInformationCollection",
    localeKey: "docNamesConsent.personalInformationCollection"
  },
  PERSONALINFORMATIONTHIRDPARTIES: {
    value: "personalInformationThirdParties",
    localeKey: "docNamesConsent.personalInformationThirdParties"
  }
};

const DOC_NAMES_PRECAUTION = {
  TARTARREMOVAL: {
    value: "tartarRemoval",
    localeKey: "docNamesPrecaution.tartarRemoval"
  },
  IMPLANT: {
    value: "implant",
    localeKey: "docNamesPrecaution.implant"
  },
  DENTURE: {
    value: "denture",
    localeKey: "docNamesPrecaution.denture"
  },
  TOOTHEXTRACTION: {
    value: "toothExtraction",
    localeKey: "docNamesPrecaution.toothExtraction"
  },
  TEETHWHITENING: {
    value: "teethWhitening",
    localeKey: "docNamesPrecaution.teethWhitening"
  },
  ROOTCANAL: {
    value: "rootCanal",
    localeKey: "docNamesPrecaution.rootCanal"
  },
  ORTHODHEADGEARFACEBOW: {
    value: "orthodHeadGearFaceBow",
    localeKey: "docNamesPrecaution.orthodHeadGearFaceBow"
  },
  ORTHODFACEMASKCHINCAP: {
    value: "orthodFaceMaskChinCap",
    localeKey: "docNamesPrecaution.orthodFaceMaskChinCap"
  },
  ORTHODRUBBERBAND: {
    value: "orthodRubberBand",
    localeKey: "docNamesPrecaution.orthodRubberBand"
  },
  ORTHODFIXED: {
    value: "orthodFixed",
    localeKey: "docNamesPrecaution.orthodFixed"
  },
  ORTHODREMOVABLEAPPLIANCE: {
    value: "orthodRemovableAppliance",
    localeKey: "docNamesPrecaution.orthodRemovableAppliance"
  },
  ORTHODTRANSPARENTAPPLIANCE: {
    value: "orthodTransparentAppliance",
    localeKey: "docNamesPrecaution.orthodTransparentAppliance"
  }
};

const DOC_NAMES_HEALTHINSURANCE = {
  TARTARREMOVALREGISTRATIONCANCEL: {
    value: "tartarRemovalRegistrationCancel",
    localeKey: "docNamesHealthInsurance.tartarRemovalRegistrationCancel"
  },
  DENTUREREGISTRATION: {
    value: "dentureRegistration",
    localeKey: "docNamesHealthInsurance.dentureRegistration"
  },
  DENTURECHANGE: {
    value: "dentureChange",
    localeKey: "docNamesHealthInsurance.dentureChange"
  },
  DENTUREMAINTENANCECANCAL: {
    value: "dentureMaintenanceCancal",
    localeKey: "docNamesHealthInsurance.dentureMaintenanceCancal"
  },
  IMPLANTREGISTRATION: {
    value: "implantRegistration",
    localeKey: "docNamesHealthInsurance.implantRegistration"
  },
  IMPLANTCHANGE: {
    value: "implantChange",
    localeKey: "docNamesHealthInsurance.implantChange"
  }
};

const DOC_NAMES_MEDICALBENEFIT = {
  TARTARREMOVALREGISTRATIONCANCEL: {
    value: "tartarRemovalRegistrationCancel",
    localeKey: "docNamesMedicalBenefit.tartarRemovalRegistrationCancel"
  },
  DENTUREREGISTRATION: {
    value: "dentureRegistration",
    localeKey: "docNamesMedicalBenefit.dentureRegistration"
  },
  DENTURECHANGE: {
    value: "dentureChange",
    localeKey: "docNamesMedicalBenefit.dentureChange"
  },
  DENTUREMAINTENANCECANCAL: {
    value: "dentureMaintenanceCancal",
    localeKey: "docNamesMedicalBenefit.dentureMaintenanceCancal"
  },
  IMPLANTREGISTRATION: {
    value: "implantRegistration",
    localeKey: "docNamesMedicalBenefit.implantRegistration"
  },
  IMPLANTCHANGE: {
    value: "implantChange",
    localeKey: "docNamesMedicalBenefit.implantChange"
  }
};

const DOC_NAMES_DOCISSUE = {
  DIAGNOSIS: {
    value: "diagnosis",
    localeKey: "docNamesDocIssue.diagnosis"
  },
  INJURYDIAGNOSIS: {
    value: "injuryDiagnosis",
    localeKey: "docNamesDocIssue.injuryDiagnosis"
  },
  TREATMENTCONFIRMATION: {
    value: "treatmentConfirmation",
    localeKey: "docNamesDocIssue.treatmentConfirmation"
  },
  OPERATIONCONFIRMATION: {
    value: "operationConfirmation",
    localeKey: "docNamesDocIssue.operationConfirmation"
  },
  TREATMENTREQUEST: {
    value: "treatmentRequest",
    localeKey: "docNamesDocIssue.treatmentRequest"
  },
  TREATMENTPLAN: {
    value: "treatmentPlan",
    localeKey: "docNamesDocIssue.treatmentPlan"
  },
  DOCTORNOTE: {
    value: "doctorNote",
    localeKey: "docNamesDocIssue.doctorNote"
  },
  AMBULATORYCONFIRMATION: {
    value: "ambulatoryConfirmation",
    localeKey: "docNamesDocIssue.ambulatoryConfirmation"
  },
  EXPENSEESTIMATION: {
    value: "expenseEstimation",
    localeKey: "docNamesDocIssue.expenseEstimation"
  },
  MILITARYDIAGNOSIS: {
    value: "militaryDiagnosis",
    localeKey: "docNamesDocIssue.militaryDiagnosis"
  },
  RADIATIONOPINION: {
    value: "radiationOpinion",
    localeKey: "docNamesDocIssue.radiationOpinion"
  },
  VISITINGNURSINGDIRECTIONS: {
    value: "visitingNursingDirections",
    localeKey: "docNamesDocIssue.visitingNursingDirections"
  },
  MEDICALBENEFITREQUEST: {
    value: "medicalBenefitRequest",
    localeKey: "docNamesDocIssue.medicalBenefitRequest"
  },
  TREATMENTRESPONSE: {
    value: "treatmentResponse",
    localeKey: "docNamesDocIssue.treatmentResponse"
  },
  MEDICALRECORD: {
    value: "chart",
    localeKey: "docNamesDocIssue.medicalRecord"
  }
};

const DOC_NAMES_ORALEXAM = { ...ONLY_ORALEXAM_TYPES };

const DOC_STATUS = {
  SAVED: {
    value: 0,
    localeKey: "docStatus.saved"
  },
  ISSUED: {
    value: 1,
    localeKey: "docStatus.issued"
  }
};

const DOC_PURPOSES = {
  INSURANCE: {
    value: "0",
    localeKey: "docPurposes.insurance"
  },
  WORK: {
    value: "1",
    localeKey: "docPurposes.work"
  },
  SCHOOL: {
    value: "2",
    localeKey: "docPurposes.school"
  },
  MILITARY: {
    value: "3",
    localeKey: "docPurposes.military"
  },
  POLICE: {
    value: "4",
    localeKey: "docPurposes.police"
  },
  COURT: {
    value: "5",
    localeKey: "docPurposes.court"
  },
  TREATMENT: {
    value: "6",
    localeKey: "docPurposes.treatment"
  },
  ETC: {
    value: "7",
    localeKey: "docPurposes.etc"
  }
};

const DOC_ACTION_TARGETS = {
  TEXT: "text",
  CHECK: "check",
  RADIO: "radio",
  DATA_TEXT: "dataText",
  DATA_CHECK: "dataCheck",
  DATA_RADIO: "dataRadio",
  DATA_DATE_BEGIN: "dataDateBegin",
  DATA_DATE_END: "dataDateEnd"
};

export {
  DOC_CATEGORIES,
  DOC_TYPES,
  DOC_STATUS,
  DOC_NAMES_QUESTIONNAIRE,
  DOC_NAMES_CONSENT,
  DOC_NAMES_PRECAUTION,
  DOC_NAMES_HEALTHINSURANCE,
  DOC_NAMES_MEDICALBENEFIT,
  DOC_NAMES_DOCISSUE,
  DOC_PURPOSES,
  DOC_NAMES_ORALEXAM,
  DOC_ACTION_TARGETS
};
