import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVTextField, ErrorTooltip, RadioGroup, DialogWarningMessage } from "components";
import IMask from "imask";
import produce from "immer";
import { GET_PRODUCT_SAMPLES, GET_UNIQUE_CATEGORY, GET_UNIQUE_SUBCATEGORY } from "queries/product";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CCButton, CCTooltip, CCTypography } from "styles/src/components";
import { InfoIcon } from "styles/src/themes/common/icons";
import { EXSISTENT_ALERT, STOCK_ALERT, UNIT } from "types/inventory";
import { getTimestamp } from "utils/datetime";
import { useIntl } from "utils/language";
import { makeList } from "utils/type";
import CategoryTable from "../CategoryTable";
import ExpireTable from "../ExpireTable";

const height = 150;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "12px !important",
    background: theme.palette.background.default
  },
  table__height: {
    height: `calc(100% - ${height}px)`
  },
  head__field: {
    width: 130
  },
  head__storage__field: {
    width: 200
  },
  head__search__field: {
    width: 200
  },
  bin__field: {
    marginLeft: 20,
    width: 120
  },
  margin__field: {
    marginLeft: 10,
    paddingBottom: 0,
    paddingLeft: 0
  },
  font__field: {
    fontSize: 10,
    marginTop: 20,
    marginLeft: 20,
    color: theme.palette.disabled,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  small__font__field: {
    fontSize: 8,
    marginLeft: 20,
    color: theme.palette.disabled,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  label__font__field: {
    fontSize: 14,
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  top__margin__field: {
    marginTop: "-10px"
  },
  bottom__margin__field: {
    marginBottom: "-40px"
  },
  button__filter: {
    marginTop: "8px"
  },
  typo__filter: {
    marginBottom: "15px"
  },
  headertwo: {
    width: "100%",
    boxSizing: "border-box",
    padding: "4px 4px",
    display: "flex",
    flexDirection: "row",
    height: "50px",
    alignItems: "center"
  },
  container__filter: {
    textAlign: "center"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  item__padding: {
    padding: 10
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  caption: {
    color: theme.palette.sub.main
  },
  required: {
    color: theme.palette.error.main
  },
  form: {
    background: theme.palette.result.main
  },
  info__button: { color: theme.palette.disabled.hex },
  border: {
    width: 1,
    height: "20px",
    marginLeft: 10,
    marginTop: 15,
    borderRight: `1px solid ${theme.palette.border.main}`
  },
  margin: {
    marginTop: 20
  }
}));

const reducer = produce((draft, action) => {
  switch (action.type) {
    case "getMinor":
      draft.minor = action.target;
      return draft;
    case "getMajor":
      draft.major = action.target;
      return draft;
    default:
      break;
  }
});

const eightFilter = "00000000";

const eightPipe = new IMask.createPipe({
  mask: eightFilter
});

const ProductCreate = props => {
  const { value, className } = props;
  const intl = useIntl();

  const [state, dispatchState] = useReducer(reducer, { minor: [], major: [] });
  const [searchResult, setSearchResult] = useState();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const StockAlert = makeList(STOCK_ALERT);
  const ExistAlert = makeList(EXSISTENT_ALERT);
  const Unit = makeList(UNIT);

  const ALL_SELECTION = intl.formatMessage({ id: "all" });

  const classes = useStyles();

  const { getValues, control, watch, errors, setValue } = useFormContext();

  const _majorAll = watch("major") !== ALL_SELECTION;

  useQuery(GET_UNIQUE_CATEGORY, {
    variables: {
      query: {
        isOnlyForLab: false
      }
    },
    onCompleted: data => {
      if (data?.getUniqueCategory) {
        dispatchState({
          type: "getMajor",
          target: data?.getUniqueCategory
        });
      }

    }
  });

  const [getProduct] = useLazyQuery(GET_PRODUCT_SAMPLES, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data?.getProductSamples) {
        setSearchResult(data.getProductSamples);
      }
    }
  });

  const [getMinor] = useLazyQuery(GET_UNIQUE_SUBCATEGORY, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data?.getUniqueSubCategory) {
        dispatchState({
          type: "getMinor",
          target: data?.getUniqueSubCategory
        });
        setValue("minor", "All")
      }

    }
  });

  // function onlyUnique(value, index, self) {
  //   return self.indexOf(value) === index;
  // }

  // useImperativeHandle(ref, () =>
  //   watch("directInput") ? selectedValue : ref?.current
  // );


  const tableOnDelete = useCallback(
    tableData => {
      const { rowIndex } = tableData;

      const newContents = value.filter((_, index) => index !== rowIndex);
      // onChange(newContents);
    },
    [value]
  );

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.headertwo}>
          <Grid
            className={classes.container__filter}
            container
            direction="row"
          >
            <Grid item className={classes.item}>
              <Box display="flex" width="130px" flexDirection="row">
                <Box display="flex" flexDirection="row">
                  <CCTypography variant="h5">
                    {intl.formatMessage({ id: "searchProduct" })}
                  </CCTypography>
                  <CCTypography
                    variant={"h4"}
                    component={"span"}
                    className={classes.required}
                  >
                    {" *"}
                  </CCTypography>
                </Box>
              </Box>
            </Grid>
            <Box display="flex" width="180px" flexDirection="row">
              <Box display="flex" padding="6px" flexDirection="row">
                <Controller
                  name={"directInput"}
                  control={control}
                  defaultValue={getValues("directInput") || false}
                  render={props => {
                    const { ref: formRef, value, onChange, onBlur } = props.field;
                    return (
                      <ErrorTooltip
                        open={Boolean(errors?.name?.message)}
                        errorMsg={errors?.name?.message}
                        placement={"top-start"}
                      >
                        <FormControlLabel
                          ref={formRef}
                          className={classes.margin__field}
                          control={<Checkbox checked={value} value={value} />}
                          label={
                            <CCTypography variant={"body1"}>
                              {intl.formatMessage({ id: "directInput" })}
                            </CCTypography>
                          }
                          onChange={() => {
                            onChange(!value);
                          }}
                          onBlur={onBlur}
                        />
                      </ErrorTooltip>
                    );
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Box>

        <Box className={clsx(classes.root, className)}>
          <Controller
            name={"major"}
            control={control}
            defaultValue={getValues("major") || undefined}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;
              return (
                <ErrorTooltip
                  open={Boolean(errors?.major?.message)}
                  errorMsg={errors?.major?.message}
                  placement={"top-start"}
                >
                  <CVTextField
                    inputRef={ref}
                    control={control}
                    name={"major"}
                    required={watch("directInput")}
                    className={classes.head__field}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "majorCategory" })}
                    select
                    value={value || ALL_SELECTION}
                    disabled={state.major === []}
                    onChange={e => {
                      onChange(e?.target?.value);
                      getMinor({
                        variables: {
                          category: e?.target?.value === "All" ? '' : e?.target?.value
                        }
                      });
                    }}
                    onBlur={onBlur}
                  >
                    <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                      {ALL_SELECTION}
                    </MenuItem>
                    {Boolean(state?.major) &&
                      state.major.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </CVTextField>
                </ErrorTooltip>
              );
            }}
          />

          <Controller
            name={"minor"}
            control={control}
            defaultValue={getValues("minor") || null}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;
              return (
                <CVTextField
                  inputRef={ref}
                  control={control}
                  name={"minor"}
                  className={classes.head__field}
                  variant={"outlined"}
                  margin={"dense"}
                  label={intl.formatMessage({ id: "minorCategory" })}
                  select
                  value={value || ALL_SELECTION}
                  onChange={e => {
                    onChange(e?.target?.value);
                  }}
                  disabled={
                    state.minor === [] ||
                    watch("major") === null ||
                    watch("major") === "All"
                  }
                  onBlur={onBlur}
                >
                  <MenuItem key={"ALL_SELECTION"} value={ALL_SELECTION}>
                    {ALL_SELECTION}
                  </MenuItem>
                  {Boolean(state?.minor) &&
                    state.minor.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </CVTextField>
              );
            }}
          />

          <Controller
            name={"productName"}
            control={control}
            defaultValue={getValues("productName") || undefined}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;
              return (
                <ErrorTooltip
                  open={Boolean(errors?.productName?.message)}
                  errorMsg={errors?.productName?.message}
                  placement={"top-start"}
                >
                  <CVTextField
                    inputRef={ref}
                    control={control}
                    name={"productName"}
                    required={watch("directInput")}
                    className={classes.head__search__field}
                    permission={false}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "productName" })}
                    value={value}
                    onChange={e => onChange(e?.target?.value)}
                    onKeyPress={e =>
                      e.key === "Enter" ? onChange(e?.target?.value) : null
                    }
                    onBlur={onBlur}
                  />
                </ErrorTooltip>
              );
            }}
          />

          <Controller
            name={"manufacture"}
            control={control}
            defaultValue={getValues("manufacture") || undefined}
            render={props => {
              const { ref, value, onChange, onBlur } = props.field;
              return (
                <CVTextField
                  inputRef={ref}
                  control={control}
                  name={"manufacture"}
                  className={classes.head__search__field}
                  permission={false}
                  variant={"outlined"}
                  margin={"dense"}
                  label={intl.formatMessage({ id: "manufacturer" })}
                  value={value}
                  onChange={e => onChange(e?.target?.value)}
                  onKeyPress={e =>
                    e.key === "Enter" ? e => onChange(e?.target?.value) : null
                  }
                  onBlur={onBlur}
                />
              );
            }}
          />

          <CCButton
            className={classes.button__filter}
            variant="contained"
            color="secondary"
            startIcon={<SearchIcon />}
            disabled={watch("directInput")}
            onClick={() => {
              getProduct({
                variables: {
                  query: {
                    uniqueCategory: false,
                    category: getValues("major") === "All" ? '' : getValues("major"),
                    subCategory: getValues("minor") === "All" ? '' : getValues("minor"),
                    productName: getValues("productName"),
                    manufacture: getValues("manufacture"),
                  }
                }
              });
            }}
          >
            {intl.formatMessage({ id: "filter" })}
          </CCButton>

          <Box className={classes.table__height}>
            {
              <CategoryTable
                name={"selectedList"}
                // inputRef={ref}
                contents={searchResult}
                onDelete={tableOnDelete}
                // ref={register("selectedList", {
                //   required: watch("directInput")
                // })}
                value={value}
                directInput={watch("directInput")}
              />
            }
          </Box>

          <Box className={classes.table__height}>
            <Controller
              name={"unit"}
              control={control}
              defaultValue={getValues("unit") || ""}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;
                return (
                  <FormControlLabel
                    control={
                      <RadioGroup
                        required={true}
                        tabList={Unit}
                        value={value === "2" ? "2" : "1"}
                        onChange={e => {
                          onChange(e.value);
                        }}
                        inputRef={ref}
                        onBlur={onBlur}
                        defaultValue={0}
                      />
                    }
                    label={
                      <Grid item className={classes.item}>
                        <Box display="flex" width="50px" flexDirection="row">
                          <CCTypography variant="h5" component={"span"}>
                            {intl.formatMessage({ id: "unit" })}
                          </CCTypography>
                          <CCTypography
                            variant={"h4"}
                            component={"span"}
                            className={classes.required}
                          >
                            {" *"}
                          </CCTypography>
                        </Box>
                      </Grid>
                    }
                    labelPlacement="start"
                    onChange={e => {
                      onChange(e?.value);
                      // setDirectInput(!directInput);
                    }}
                  />
                );
              }}
            />

            <Controller
              name={"binCnt"}
              control={control}
              defaultValue={getValues("binCnt") || 0}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;
                return (
                  <CVTextField
                    className={clsx(
                      classes.bin__field,
                      classes.margin__field,
                      classes.bottom__margin__field
                    )}
                    inputRef={ref}
                    onChange={e => {
                      onChange(eightPipe(e.target.value));
                    }}
                    value={Boolean(watch("unit") === "2") ? value : 0}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "cntforBin" })}
                    name={"searchField"}
                    disabled={Boolean(watch("unit") !== "2")}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </Box>

          <Box>
            <Grid item className={classes.item}>
              <Box display="flex" flexDirection="row" style={{ margin: 15, paddingRight: 10 }}>
                <CCTypography
                  variant="h5"
                  component={"span"}
                  style={{ marginTop: 5, width: "40px" }}
                >
                  {intl.formatMessage({ id: "stockCount" })}
                </CCTypography>
              </Box>
            </Grid>
            <Controller
              name={"currentCnt"}
              control={control}
              defaultValue={getValues("currentCnt") || 0}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;

                return (
                  <CVTextField
                    className={clsx(
                      classes.head__field,
                      classes.head__storage__field
                    )}
                    value={value}
                    inputRef={ref}
                    onChange={e => onChange(eightPipe(e.target.value))}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>
                          {watch("unit") === "2"
                            ? "BIN"
                            : "EA"}
                        </InputAdornment>
                      )
                    }}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "currentCnt" })}
                  />
                );
              }}
            />

            <Controller
              name={"optCnt"}
              control={control}
              defaultValue={getValues("optCnt") || 0}
              render={props => {
                const { ref, value, onChange, onBlur } = props.field;
                return (
                  <CVTextField
                    className={clsx(
                      classes.head__field,
                      classes.head__storage__field
                    )}
                    value={value}
                    inputRef={ref}
                    onChange={e => onChange(eightPipe(e.target.value))}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>
                          {watch("unit") === "2"
                            ? "BIN"
                            : "EA"}
                        </InputAdornment>
                      )
                    }}
                    variant={"outlined"}
                    margin={"dense"}
                    label={intl.formatMessage({ id: "optCnt" })}
                  />
                );
              }}
            />
          </Box>

          <Box className={classes.table__height}>
            <Box display="flex" flexDirection="row">
              <CCTypography
                variant="h5"
                component={"span"}
                style={{ marginTop: 20, width: "200px" }}
              >
                {intl.formatMessage({
                  id: "inventoryNotificationType"
                })}
              </CCTypography>
              <CCTooltip
                title={intl.formatMessage(
                  { id: "informCurrentlessAdequate" },
                  { br: () => <br /> }
                )}
                style={{ marginTop: 15 }}
                placement="top"
                arrow
              >
                <CCTypography
                  variant="h5"
                  component={"span"}
                  className={classes.info__button}
                >
                  <InfoIcon />
                </CCTypography>
              </CCTooltip>
              <div className={classes.border} />

              <Controller
                name={"alert"}
                control={control}
                defaultValue={getValues("alert")}
                render={props => {
                  const { ref, value, onChange, onBlur } = props.field;

                  return (
                    <FormControlLabel
                      className={classes.margin__field}
                      control={
                        <RadioGroup
                          className={classes.font__field}
                          tabList={StockAlert}
                          required={true}
                          value={
                            watch("optCnt") > 0 && value ? value : "1"
                          }
                          onBlur={onBlur}
                          inputRef={ref}
                        />
                      }
                      onChange={e => {
                        if (watch("optCnt") > 0) {
                          onChange(e?.value);
                        } else {
                          setOpenErrorDialog(intl.formatMessage({
                            id: "warningOptCntBeforeAlert"
                          }))
                        }
                      }}
                      labelPlacement="start"
                    />
                  );
                }}
              />
            </Box>
          </Box>

          <Box className={classes.table__height}>
            <Box display="flex" flexDirection="row">
              <CCTypography
                variant="h5"
                component={"span"}
                style={{ marginTop: 20, width: "200px" }}
              >
                {intl.formatMessage({
                  id: "expireDateSetting"
                })}
              </CCTypography>
              <CCTooltip
                title={intl.formatMessage(
                  { id: "navigateImminentExpireDate" },
                  { br: () => <br /> }
                )}
                style={{ marginTop: 15 }}
                placement="top"
                arrow
              >
                <CCTypography
                  variant="h5"
                  component={"span"}
                  className={classes.info__button}
                >
                  <InfoIcon />
                </CCTypography>
              </CCTooltip>
              <div className={classes.border} />

              <Controller
                name={"canExpire"}
                control={control}
                defaultValue={getValues("canExpire")}
                render={props => {
                  const { ref, value, onChange, onBlur } = props.field;
                  return (
                    <ErrorTooltip
                      open={Boolean(errors?.expireList?.message)}
                      errorMsg={errors?.expireList?.message}
                      placement={"bottom-end"}
                      popperOptions={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "270,80"
                          }
                        }
                      }}
                    >
                      <FormControlLabel
                        className={classes.margin__field}
                        control={
                          <RadioGroup
                            className={classes.font__field}
                            tabList={ExistAlert}
                            required={true}
                            value={value === "2" ? "2" : "1"}
                            onBlur={onBlur}
                            inputRef={ref}
                          />
                        }
                        onChange={e => {
                          onChange(e?.value);
                        }}
                        labelPlacement="start"
                      />
                    </ErrorTooltip>
                  );
                }}
              />
            </Box>
          </Box>

          {watch("canExpire") === "2" && (
            <Box className={classes.table__height}>
              <Controller
                name={"expireList"}
                control={control}
                defaultValue={[
                  {
                    expireDate: getTimestamp(),
                    expireCount: 0
                  }
                ]}
                render={props => {
                  const { ref, value, onChange, onBlur } = props.field;
                  return (
                    <ExpireTable
                      expire={watch("canExpire")}
                      currentCnt={watch("currentCnt")}
                      value={value}
                      onChange={e => onChange(e)}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </Box>
          )}
        </Box>
        {Boolean(openErrorDialog) && <DialogWarningMessage
          open={Boolean(openErrorDialog)}
          title={openErrorDialog}
          onAgree={e => {
            setOpenErrorDialog(false);
          }}
          onClose={e => {
            setOpenErrorDialog(false);
          }}
        />}
      </Box>
    </>
  );
};

export default ProductCreate;
