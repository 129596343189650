import { formatCommas } from "utils/number";
import moment from "moment";
import { translate } from "components";

export const generatePatientBarGraph = (
  series,
  categories,
  formatter,
  isDark = false
) => {
  return {
    series: series,
    options: {
      colors: ["#0277bd", "#00bfa5", ""],
      chart: {
        height: 290,
        type: "line",
        stacked: false,
        foreColor: isDark ? "#FFFFFF" : "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [1, 1, 1]
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: value => {
            const formattedValue = formatter ? formatter(value, false) : value;
            if (formattedValue && isNaN(value) && formattedValue.length > 6) {
              return formattedValue.slice(0, 5) + "...";
            }
            return formattedValue;
          }
        }
      },
      yaxis: [
        {
          min: 0,
          forceNiceScale: true,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          },
          title: {
            rotate: 0,
            offsetX: 20,
            offsetY: 125
          },
          labels: {
            align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
            formatter: value => {
              if (value === Infinity) {
                return 0;
              }
              if (value) {
                return formatCommas(Math.round(value * 100) / 100);
              }
              return value;
            }
          },
        },
        {
          min: 0,
          opposite: true,
          forceNiceScale: true,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          },
          title: {
            rotate: 0,
            offsetX: 20,
            offsetY: 125
          },
          labels: {
            formatter: value => {
              if (value === Infinity) {
                return 0;
              }
              if (value) {
                return formatCommas(Math.round(value * 100) / 100);
              }
              return value;
            }
          },
        }
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
        x: {
          formatter: (value, { dataPointIndex }) => {
            return formatter
              ? formatter(categories[dataPointIndex], true)
              : categories[dataPointIndex];
          }
        },
        theme: isDark ? "dark" : "light"
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 },
        onItemClick: {
          toggleDataSeries: false
        }
      }
    }
  };
};

export const generatePatientPieGraph = (series, total = 0, isDark = false) => {
  return {
    series: series,
    options: {
      colors: ["#00bfa5", "#0277bd"],
      chart: {
        width: 250,
        type: "pie",
        foreColor: isDark ? "#FFFFFF" : "#878787"
      },
      labels: ["신환", "구환"],
      legend: { 
        show: false,
        onItemClick: {
          toggleDataSeries: false
        }
      },
      title: {
        // text: `${total} (명)`,
        align: "center",
        style: {
          color: isDark ? "#FFFFFF" : "#878787"
        }
      },
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      }
    }
  };
};

export const generateSalesBarGraph = (
  series,
  categories,
  formatter,
  isDark = false
) => {
  const lang = localStorage.getItem("lang")
  return {
    series: series,
    options: {
      colors: ["#878787", "#00bfa5", "#ffab00", "#0277bd"],
      chart: {
        height: 290,
        type: "line",
        stacked: false,
        foreColor: isDark ? "#FFFFFF" : "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [1, 1, 1]
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: formatter
        }
      },
      yaxis: [
        {
          seriesName: "합계",
          title: {
            // text: "(천원)",
            rotate: 0,
            offsetX: 35,
            offsetY: 125
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          },
          labels: {
            align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
            formatter: value => {
              // if (value) {
              //   return formatCommas(value / 1000);
              // }
              return value;
            }
          }
        }
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 },
        onItemClick: {
          toggleDataSeries: false
        }
      }
    }
  };
};

export const generateOrdersPieGraph = (series, total, intl, isDark = false) => {
  return {
    series: series,
    options: {
      colors: ["#00bfa5", "#ffab00", "#0277bd"],
      chart: {
        width: 250,
        type: "pie",
        foreColor: isDark ? "#FFFFFF" : "#878787"
      },
      labels: [translate(intl, "menuStatistics.new"), translate(intl, "labOrder.modifyRequest"), translate(intl, "menuStatistics.remake")],
      legend: { show: false },
      title: {
        text: translate(intl, "statistic.orderPie.title", {total: total || 0}),
        align: "center",
        style: {
          color: "#878787"
        }
      },
      tooltip: {
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      }
    }
  };
};

export const generateOrderedProsthesesGraph = (
  series,
  categories,
  formatter,
  unit = "(천원)",
  isDark = false
) => {
  return {
    series: series,
    type:"bar",
    options: {
      colors: ["#00bfa5"],
      chart: {
        height: 290,
        type: "line",
        stacked: false,
        foreColor: isDark ? "#FFFFFF" : "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [1, 1, 1]
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: formatter
        }
      },
      yaxis: 
        {
          title: {
            // text: unit,
            rotate: 0,
            offsetX: unit === "(천원)" ? 10 : 5,
            offsetY: 115
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          },
          labels: {
            align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
            formatter: value => {
              // console.log(value);
              // if (value && unit === "(천원)") {
              //   return formatCommas(value / 1000);
              // }
              return value;
            }
          }
        }
      ,
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 }
      }
    }
  };
};
export const generateProsthesesGraph = (
  series,
  categories,
  formatter,
  unit = "(천원)",
  isDark = false
) => {
  return {
    series: series,
    options: {
      colors: ["#0277bd", "#ffab00", "#00bfa5"],
      chart: {
        height: 290,
        type: "line",
        stacked: false,
        foreColor: isDark ? "#FFFFFF" : "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      stroke: {
        width: [1, 1, 1]
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: formatter
        }
      },
      yaxis: [
        {
          title: {
            // text: unit,
            rotate: 0,
            offsetX: unit === "(천원)" ? 10 : 5,
            offsetY: 115
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          },
          labels: {
            align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
            formatter: value => {
              return value;
            }
          }
        }
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft",
          offsetY: 30,
          offsetX: 60
        },
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 },
        onItemClick: {
          toggleDataSeries: false
        }
      }
    }
  };
};

export const generateUninsuredPieGraph = (
  series,
  total = 0,
  unit = "(원)",
  isDark = false
) => {
  return {
    series: series,
    options: {
      colors: ["#ffab00", "#0277bd", "#00bfa5", "#f50057", "#878787"],
      chart: {
        width: 250,
        type: "pie",
        foreColor: isDark ? "#FFFFFF" : "#878787"
      },
      labels: ["임플란트", "교정", "보철", "미용치료(과세)", "기타"],
      legend: { show: false },
      title: {
        text: formatCommas(total) + " " + unit,
        align: "center",
        style: {
          color: "#878787"
        }
      },
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      }
    }
  };
};

export const generateDoctorLineGraph = (
  series,
  categories,
  formatter,
  unit = "(천원)"
) => {
  return {
    series: series,
    options: {
      chart: {
        height: 290,
        type: "line",
        stacked: false,
        foreColor: "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      stroke: {
        width: [1, 1, 1]
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: formatter
        }
      },
      yaxis: [
        {
          title: {
            text: unit,
            rotate: 0,
            offsetX: unit === "(천원)" ? 15 : 5,
            offsetY: 115
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          },
          labels: {
            align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
            formatter: value => {
              // if (value && unit === "(천원)") {
              //   if (value >= 1000) {
              //     return formatCommas(value / 1000);
              //   }
              //   return Math.round(value * 10) / 10000;
              // }
              //return Math.round(value * 100) / 100;
              return value;
            }
          }
        }
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 }
      }
    }
  };
};

export const generateDoctorPieGraph = (
  series,
  total = 0,
  labels,
  unit = "(원)"
) => {
  return {
    series: series,
    options: {
      chart: {
        width: 250,
        type: "pie",
        foreColor: "#ababab"
      },
      labels: labels,
      legend: { show: false },
      title: {
        text: formatCommas(total) + " " + unit,
        align: "center",
        style: {
          color: "#878787"
        }
      },
      tooltip: {
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      }
    }
  };
};

export const generateUninsuredDemographicGraph = (series, categories) => {
  return {
    type: "bar",
    series: series,
    options: {
      colors: ["#ffab00", "#0277bd", "#00bfa5", "#f50057", "#878787"],
      chart: {
        height: "100%",
        type: "bar",
        stacked: true,
        foreColor: "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1
      },
      xaxis: {
        categories: categories
      },
      yaxis: {
        title: {
          // text: "(천원)",
          rotate: 0,
          offsetX: 15,
          offsetY: 195
        },
        show: true,
        axisBorder: {
          show: true
        },
        labels: {
          align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
          formatter: value => {
            // if (value >= 1000) {
            //   return formatCommas(value / 1000);
            // }
            // return Math.round(value * 10) / 10000;
            return value;
          }
        }
      },
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 }
      }
    }
  };
};

export const generateIntroductionGraph = (series, categories) => {
  return {
    series: series,
    options: {
      colors: ["#f50057", "#ffab00"],
      chart: {
        height: "100%",
        type: "bar",
        stacked: false,
        foreColor: "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [1, 1, 1]
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: value => {
            const formattedValue = value;
            if (formattedValue && isNaN(value) && formattedValue.length > 6) {
              return formattedValue.slice(0, 5) + "...";
            }
            return formattedValue;
          }
        }
      },
      yaxis: [
        {
          title: {
            // text: "(천원)",
            rotate: 0,
            offsetX: 10,
            offsetY: 115
          },
          seriesName: "누적 순매출액(천원)",
          show: true,
          axisBorder: {
            show: true,
            color: "#f50057"
          },
          labels: {
            align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
            formatter: value => {
              return formatCommas(value / 1000);
            }
          }
        },
        {
          seriesName: "소개 환자수(명)",
          show: true,
          opposite: true,
          axisBorder: {
            show: true,
            color: "#ffab00"
          }
        }
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        },
        x: {
          formatter: (value, { dataPointIndex }) => {
            return categories[dataPointIndex];
          }
        }
      },
      legend: {
        show: false,
        position: "top"
      }
    }
  };
};

export const greyScalePieGraph = (series, labels, unit = "(명)") => {
  const total =
    series.length > 0 ? series.reduce((prev, current) => prev + current) : 0;
  return {
    series: series,
    options: {
      chart: {
        width: 250,
        type: "pie",
        foreColor: "#878787"
      },
      theme: {
        monochrome: {
          enabled: true,
          color: "#212121"
        }
      },
      labels: labels,
      legend: { show: false },
      title: {
        text: formatCommas(total) + unit,
        align: "center",
        style: {
          color: "#878787"
        }
      },
      tooltip: {
        theme: "dark",
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            `<style>
              .tooltip {
                border-width: 0px;
                background: rgba(0, 0, 0, 0.54);
                color: white;
                padding: 8px;
              } 
            </style>` +
            '<div class="tooltip">' +
            "<span>" +
            w.globals.labels[seriesIndex] +
            ": " +
            formatCommas(series[seriesIndex]) +
            "</span>" +
            "</div>"
          );
        }
      }
    }
  };
};

export const generateGenderPieGraph = (series, unit = "명") => {
  const total = series.reduce((prev, current) => prev + current);
  return {
    series: series,
    options: {
      colors: ["#00bfa5", "rgba(245,0,87,0.38)"],
      chart: {
        width: 250,
        type: "pie",
        foreColor: "#878787"
      },
      theme: {
        monochrome: {
          enabled: false
        }
      },
      labels: ["남", "여"],
      legend: { show: false },
      title: {
        text: `${formatCommas(total)}(${unit})`,
        align: "center",
        style: {
          color: "#878787"
        }
      },
      tooltip: {
        theme: false,
        custom: undefined,
        y: {
          formatter: value => {
            if (value) {
              return formatCommas(value);
            }
            return value;
          }
        }
      }
    }
  };
};

export const generateGenderDistributionGraph = (series, limit) => {
  return {
    type: "bar",
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 440,
        stacked: true,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors: ["#00bfa5", "rgba(245,0,87,0.38)"],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      grid: {
        xaxis: {
          lines: {
            //show: false
          }
        }
      },
      yaxis: {
        min: -limit,
        max: limit,
        title: {
          // text: 'Age',
        }
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return val;
          }
        },
        y: {
          formatter: function (val) {
            return Math.abs(val) - 1 + "%";
          }
        }
      },
      xaxis: {
        categories: [
          "90대 이상",
          "80대",
          "70대",
          "60대",
          "50대",
          "40대",
          "30대",
          "20대",
          "10대",
          "10대 미만"
        ],
        labels: {
          formatter: function (val) {
            return Math.abs(Math.round(val)) + "%";
          }
        }
      }
    }
  };
};

export const generateBudgetGraph = (series, categories) => {
  return {
    series: series,
    options: {
      colors: ["#00bfa5", "rgba(245,0,87,0.38)", "#ffab00"],
      chart: {
        height: 290,
        type: "area",
        stacked: false,
        foreColor: "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },
      fill: {
        type: "gradient",
        gradient: { opacityFrom: 0.5, opacityTo: 0.5 }
      },
      xaxis: {
        categories: categories,
        title: {
          text: "현금순이익 현황",
          offsetY: 10
        }
      },
      yaxis: [
        {
          seriesName: "value",
          show: true,
          labels: {
            align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
            formatter: value => {
              return formatCommas(value);
            }
          }
        }
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        }
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 }
      },
      annotations: {
        xaxis: [
          {
            x: moment().format("YYYY/MM"),
            strokeDashArray: 0,
            borderColor: "rgba(0, 0, 0, 0.54)",
            label: {
              offsetY: -8,
              borderColor: "rgba(0, 0, 0, 0.54)",
              orientation: "horizontal",
              style: {
                color: "#fff",
                background: "rgba(0, 0, 0, 0.54)"
              },
              text: "현제"
            }
          }
        ]
      }
    }
  };
};

export const generateExpenseGraph = (series, categories) => {
  return {
    series: series,
    options: {
      colors: ["#0277bd", "rgba(245,0,87,0.38)", "#ffab00"],
      chart: {
        height: 290,
        type: "area",
        stacked: false,
        foreColor: "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },
      fill: {
        type: "gradient",
        gradient: { opacityFrom: 0.5, opacityTo: 0.5 }
      },
      annotations: {
        xaxis: [
          {
            x: moment().format("YYYY/MM"),
            strokeDashArray: 0,
            borderColor: "rgba(0, 0, 0, 0.54)",
            label: {
              borderColor: "rgba(0, 0, 0, 0.54)",
              offsetY: -8,
              orientation: "horizontal",
              style: {
                color: "#fff",
                background: "rgba(0, 0, 0, 0.54)"
              },
              text: "현제"
            }
          }
        ]
      },
      xaxis: {
        categories: categories
      },
      yaxis: [
        {
          seriesName: "value",
          show: true,
          labels: {
            align: localStorage.getItem("lang") === 'ar' ? 'center' : 'right',
            formatter: value => {
              if (value) {
                return formatCommas(value);
              }
              return value;
            }
          }
        }
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        }
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 }
      }
    }
  };
};

export const generateAreaGraph = (series, categories) => {
  return {
    series: series,
    options: {
      //colors: ["#878787", "#f50057", "#ffab00"],
      chart: {
        height: 290,
        type: "area",
        stacked: false,
        foreColor: "#878787",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        width: "100%"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1
      },
      fill: {
        type: "gradient",
        gradient: { opacityFrom: 0.5, opacityTo: 0.5 }
      },
      xaxis: {
        categories: categories
      },
      yaxis: [
        {
          seriesName: "value",
          show: false
        }
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        }
      },
      legend: {
        horizontalAlign: "center",
        offsetX: 40,
        markers: { radius: 0, offsetX: localStorage.getItem("lang") === 'ar' ? 5 : 0 }
      }
    }
  };
};
