import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import {
  translate,
  CVButton,
  CCFlattenDateField,
  TypographyTooltip
} from "components";
import clsx from "clsx";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import { useIntl } from "react-intl";
import moment from "moment";
import { SearchIcon, WarningIcon } from "styles/src/themes/common/icons";
import { makeList } from "types";
import {
  LAB_ARRIVE_TYPE,
  LAB_ORDER_FILTER_STATE,
  LAB_ORDER_TYPE
} from "types/labOrder";
import {
  CCButton,
  CCDialogSimple,
  CCTextField,
  CCTypography
} from "styles/src/components";

const useStyles = makeStyles(theme => ({
  box: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.default
  },
  padding8: {
    padding: 8,
    margin: "auto 0"
  },
  button: {
    // minWidth: "130px",
    width: "fit-content",
    height: "fit-content",
    padding: "0 8px",
    marginRight: 8,
    textTransform: "none",
    background: "white",
    minWidth: 'fit-content',
    "&:hover": {
      background: "#0277bd",
      color: "white"
    }
  },
  btn__active: {
    background: "#0277bd",
    color: "white"
  },
  input: {
    width: 130,
    background: 'white'
  },
  divideHorizontal: {
    background: theme.palette.border.main,
    height: "36px",
    margin: "0 8px",
    width: 1
  },
  filterByDate: {
    background: "white !important",
    margin: "8px 8px 8px 0",
    width: 210
  },
  menuItem: {
    maxWidth: 450,
    wordBreak: "break-word",
    whiteSpace: "break-spaces"
  },
  warning___color: { color: theme.palette.error.main },
  dateSearch: { flex: 1 },
  btnSearch: {
    marginLeft: 8,
    textTransform: "none",
    fontWeight: 'bold'
  }
}));

const LabOrderFilter = props => {
  const classes = useStyles();
  const intl = useIntl();
  const initValueFilter = {
    typeFilter: "orderDate",
    clinic: "all",
    status: "all",
    arrival: "all",
    type: "all",
    rangeDate: "today",
    from: moment().startOf("day"),
    to: moment().endOf("day")
  };

  const {
    handleSearchVariables,
    labSetting
  } = props;
  const labOrderTypeItem = makeList(LAB_ORDER_TYPE);
  const labOrderStateItem = makeList(LAB_ORDER_FILTER_STATE);
  const labArriveTypeItem = makeList(LAB_ARRIVE_TYPE);

  const [openInvalidDate, setOpenInvalidDate] = useState(false);
  const [valueFilter, setValueFilter] = useState(initValueFilter);
  const [hideDateFilter, setHideDateFilter] = useState(false);

  const handleChangeFilterValue = values => {
    setValueFilter(prev => ({ ...prev, ...values }));
  };
  const handleSearch = () => {
    const obj = {
      orderDateFilter:
        valueFilter.typeFilter === "orderDate"
          ? {
            from: valueFilter.from.unix(),
            to: valueFilter.to.unix()
          }
          : undefined,
      dueDateFilter:
        valueFilter.typeFilter === "dueDate"
          ? {
            from: valueFilter.from.unix(),
            to: valueFilter.to.unix()
          }
          : undefined,
      clinic: valueFilter.clinic !== "all" ? valueFilter.clinic : undefined,
      status: valueFilter.status !== "all" ? valueFilter.status : undefined,
      arrival: valueFilter.arrival !== "all" ? valueFilter.arrival : undefined,
      type: valueFilter.type !== "all" ? valueFilter.type : undefined
    };
    handleSearchVariables(obj);
  };

  return (
    <Box className={clsx(classes.box)}>
      <Grid
        container
        wrap={"nowrap"}
        alignItems={"center"}
        className={classes.padding8}
      >
        <Grid item className={classes.dateSearch}>
          <Grid container className={classes.padding8} alignItems="center">
            <CCTextField
              className={classes.filterByDate}
              margin={"dense"}
              variant={"outlined"}
              value={valueFilter.typeFilter}
              select
              onChange={e => {
                handleChangeFilterValue({ typeFilter: e.target.value });
                if (e.target.value === "all") {
                  handleChangeFilterValue({
                    ...initValueFilter,
                    typeFilter: "all"
                  });
                  setHideDateFilter(true);
                } else {
                  setHideDateFilter(false);
                }
              }}
            >
              {[
                {
                  id: "all",
                  name: translate(intl, "common.ui.all"),
                  value: "all"
                },
                {
                  id: "orderDate",
                  name: translate(intl, "common.ui.byOrderDate"),
                  value: "orderDate"
                },
                {
                  id: "dueDate",
                  name: translate(intl, "common.ui.byDueDate"),
                  value: "dueDate"
                }
              ].map(item => (
                <CCMenuItem
                  key={item.id}
                  value={item.id}
                  className={clsx(classes.menuItem)}
                >
                  <TypographyTooltip placement={"top"}>
                    {item.name}
                  </TypographyTooltip>
                </CCMenuItem>
              ))}
            </CCTextField>
            <div className={classes.divideHorizontal} />
            {!hideDateFilter && (
              <>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <CCFlattenDateField
                        label=""
                        className={classes.input}
                        margin={"dense"}
                        variant={"outlined"}
                        value={valueFilter.from}
                        onChange={e => {
                          if (
                            moment
                              .duration(e.startOf("day").diff(valueFilter.to))
                              .asDays() < 0
                          ) {
                            handleChangeFilterValue({
                              from: e.startOf("day"),
                              rangeDate: ""
                            });
                          } else {
                            setOpenInvalidDate(true);
                          }
                        }}
                      />
                    </Grid>
                    ~
                    <Grid item>
                      <CCFlattenDateField
                        label=""
                        className={classes.input}
                        margin={"dense"}
                        variant={"outlined"}
                        value={valueFilter.to}
                        onChange={e => {
                          if (
                            moment
                              .duration(e.endOf("day").diff(valueFilter.from))
                              .asDays() > 0
                          ) {
                            handleChangeFilterValue({
                              to: e.endOf("day"),
                              rangeDate: ""
                            });
                          } else {
                            setOpenInvalidDate(true);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <CVButton
                  variant={"outlined"}
                  className={clsx({
                    [classes.button]: true,
                    [classes.btn__active]: valueFilter.rangeDate === "today"
                  })}
                  onClick={() => {
                    handleChangeFilterValue({
                      rangeDate: "today",
                      from: moment().startOf("day"),
                      to: moment().endOf("day")
                    });
                  }}
                >
                  {translate(intl, "labManageGold.today")}
                </CVButton>
                <CVButton
                  className={clsx({
                    [classes.button]: true,
                    [classes.btn__active]: valueFilter.rangeDate === "week"
                  })}
                  variant={"outlined"}
                  onClick={() => {
                    handleChangeFilterValue({
                      rangeDate: "week",
                      from: moment().startOf("week"),
                      to: moment().endOf("week")
                    });
                  }}
                >
                  {translate(intl, "labManageGold.week")}
                </CVButton>
                <CVButton
                  className={clsx({
                    [classes.button]: true,
                    [classes.btn__active]: valueFilter.rangeDate === "month"
                  })}
                  variant={"outlined"}
                  onClick={() => {
                    handleChangeFilterValue({
                      rangeDate: "month",
                      from: moment().startOf("month"),
                      to: moment().endOf("month")
                    });
                  }}
                >
                  {translate(intl, "common.ui.month")}
                </CVButton>
              </>
            )}

          </Grid>
        </Grid>

        <Grid item>
          <Grid container className={classes.padding8} alignItems="center">
            <div className={classes.divideHorizontal} />
            <Grid item>
              <CCTextField
                className={classes.input}
                label={translate(intl, "common.ui.clinic")}
                margin={"dense"}
                variant={"outlined"}
                value={valueFilter.clinic}
                select
                onChange={e => {
                  handleChangeFilterValue({ clinic: e.target.value });
                }}
              >
                {labSetting &&
                  [
                    {
                      id: "all",
                      name: translate(intl, "common.ui.all"),
                      value: "all"
                    },
                    ...labSetting
                  ].map(item => (
                    <CCMenuItem
                      key={item.id}
                      value={item.id}
                      className={clsx(classes.menuItem)}
                    >
                      <TypographyTooltip placement={"top"}>
                        {item.name}
                      </TypographyTooltip>
                    </CCMenuItem>
                  ))}
              </CCTextField>
            </Grid>
            <Grid item>
              <CCTextField
                select
                className={classes.input}
                label={translate(intl, "common.ui.orderType")}
                margin={"dense"}
                variant={"outlined"}
                value={valueFilter.type}
                onChange={e => {
                  handleChangeFilterValue({ type: e.target.value });
                }}
              >
                {[
                  {
                    id: "all",
                    label: translate(intl, "common.ui.all"),
                    value: "all"
                  },
                  ...labOrderTypeItem
                ].map(item => (
                  <CCMenuItem
                    key={item.value}
                    value={item.value}
                    className={classes.menuItem}
                  >
                    <TypographyTooltip placement={"top"}>
                      {item.label}
                    </TypographyTooltip>
                  </CCMenuItem>
                ))}
              </CCTextField>
            </Grid>
            <Grid item>
              <CCTextField
                select
                className={classes.input}
                label={translate(intl, "common.ui.status")}
                margin={"dense"}
                variant={"outlined"}
                value={valueFilter.status}
                onChange={e => {
                  handleChangeFilterValue({ status: e.target.value });
                }}
              >
                {[
                  {
                    id: "all",
                    label: translate(intl, "common.ui.all"),
                    value: "all"
                  },
                  ...labOrderStateItem
                ].map(item => (
                  <CCMenuItem
                    key={item.value}
                    value={item.value}
                    className={classes.menuItem}
                  >
                    <TypographyTooltip placement={"top"}>
                      {item.label}
                    </TypographyTooltip>
                  </CCMenuItem>
                ))}
              </CCTextField>
            </Grid>
            <Grid item>
              <CCTextField
                select
                className={classes.input}
                label={translate(intl, "labOrder.arrive1")}
                margin={"dense"}
                variant={"outlined"}
                value={valueFilter.arrival}
                onChange={e => {
                  handleChangeFilterValue({ arrival: e.target.value });
                }}
              >
                {[
                  {
                    id: "all",
                    label: translate(intl, "common.ui.all"),
                    value: "all"
                  },
                  ...labArriveTypeItem
                ].map(item => (
                  <CCMenuItem
                    key={item.value}
                    value={item.value}
                    className={classes.menuItem}
                  >
                    <TypographyTooltip placement={"top"}>
                      {item.label}
                    </TypographyTooltip>
                  </CCMenuItem>
                ))}
              </CCTextField>
            </Grid>
            <Grid item >
              <CVButton
                className={classes.btnSearch}
                variant={"contained"}
                color={"secondary"}
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                {translate(intl, "common.ui.search")}
              </CVButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      <CCDialogSimple
        title={<WarningIcon className={classes.icon} />}
        open={openInvalidDate}
        onClose={() => setOpenInvalidDate(false)}
        contents={
          <CCTypography className={classes.warning___color}>
            {translate(intl, "labOrder.dueDateBeforeOrderedDate")}
          </CCTypography>
        }
        endActions={
          <CCButton
            variant="contained"
            color="primary"
            onClick={() => setOpenInvalidDate(false)}
          >
            {translate(intl, "common.ui.confirm")}
          </CCButton>
        }
      />
    </Box>
  );
};

export default LabOrderFilter;
