import { useLazyQuery } from "@apollo/react-hooks";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVButton, translate } from "components";
import { CVPasswordField } from "components/index";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CCTypography } from "styles/components";
import { WarningIcon } from "styles/icons";
import { CCDialogSimple } from "styles/src/components";

const IS_LAB_CHIEF = gql`
  query IsLabChief($password: String) {
    isLabChief(password: $password) 
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: 312,
    padding: 8
  },
  form_item: { display: "flex" },
  fullWidth: {
    width: "100%"
  },
  desc: { padding: " 0 8px", color: 'red' },
  warning_color: { color: theme.palette.error.main }
}));

const PasswordChangeDialog = props => {
  const { open, onClose = (success = false, forceLogout = false) => { } } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const currentPassword = useRef();
  const [error, setError] = useState("");
  const intl = useIntl();

  const [isLabChief] = useLazyQuery(IS_LAB_CHIEF, {
    fetchPolicy: "no-cache"
  })

  const onSubmit = () => {
    isLabChief({
      variables: {
        password: currentPassword.current.value
      }
    })
      .then(res => {
        if (res?.data?.isLabChief) {
          onClose(true)
        } 
      }).catch(error => {
        if (error.message.includes('Invalid username or password')) {
          setError(translate(intl, 'signUp.error.confirmPassword'))
        } else if (error.message.includes('Your account has been locked')) {
          let serviceNumber = process.env.REACT_APP_DEFAULT_SUPPORT_PHONE ? process.env.REACT_APP_DEFAULT_SUPPORT_PHONE : ""
          if (process.env.REACT_APP_DEFAULT_WHATSAPP) {
            serviceNumber += `/ WhatsApp: ${process.env.REACT_APP_DEFAULT_WHATSAPP}`
          }
          setError(translate(intl, 'errorMsg.auth03', { serviceNumber: serviceNumber }))
          onClose(false, true)
        } else {
          setError(error.message)
        }
      })
  }
  return (
    <>
      <CCDialogSimple
        title={translate(intl, "signUp.ui.confirmPassword")}
        contentsPadding={false}
        open={open}
        onClose={() => onClose()}
        onCloseButton={() => onClose()}
        contents={
          <Grid container direction={"column"} className={classes.root}>
            <Grid item>
              <CCTypography className={classes.desc}>
                {translate(intl, "common.ui.confirmPasswordNote1")}
              </CCTypography>
            </Grid>
            <Grid item className={classes.form_item}>
              <CVPasswordField
                type={"password"}
                label={translate(intl, "common.ui.currentPassword")}
                inputRef={currentPassword}
                variant={"outlined"}
                margin={"dense"}
                InputProps={{
                  inputProps: {
                    autoComplete: "current-password"
                  }
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
                className={classes.fullWidth}
              />
            </Grid>
            <Grid item>
              <CCTypography className={classes.desc}>
                {translate(intl, "common.ui.confirmPasswordNote2")}
              </CCTypography>
            </Grid>
          </Grid>
        }
        endActions={
          <>
            <CVButton variant={"text"} onClick={() => onClose()}>
              {translate(intl, "common.ui.cancel")}
            </CVButton>
            <CVButton
              variant={"contained"}
              onClick={onSubmit}
            >
              {translate(intl, "common.ui.input")}
            </CVButton>
          </>
        }
      />
      <CCDialogSimple
        open={Boolean(error)}
        onClose={() => setError("")}
        title={<WarningIcon className={classes.warning_color} />}
        contents={
          <Box className={classes.text}>
            <CCTypography className={classes.warning_color}>
              {error}
            </CCTypography>
          </Box>
        }
        endActions={
          <CVButton
            className={classes.disagree}
            color="secondary"
            onClick={() => setError("")}
          >
            {intl.formatMessage({ id: "common.ui.ok" })}
          </CVButton>
        }
      />
    </>
  );
};

export default PasswordChangeDialog;
