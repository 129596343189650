import { ApolloProvider as Provider } from "@apollo/react-hooks";
import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient } from "@apollo/client";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import React, { useMemo } from "react";

const ApolloProvider = props => {
  const client = useMemo(() => {
    const token = sessionStorage.getItem("token");
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ""
        }
      };
    });
    const link = authLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      authLink.concat(
        new WebSocketLink({
          uri: process.env.REACT_APP_CF_GRAPHQL_SOCKET,
          options: {
            reconnect: true,
            connectionParams: {
              Authorization: token ? `Bearer ${token}` : ""
            },
            lazy: true
          }
        })
      ),
      authLink.concat(
        createUploadLink({
          uri: process.env.REACT_APP_CF_GRAPHQL_URL,
          headers: {
            "keep-alive": "true"
          }
        })
      )
    );

    return new ApolloClient({
      connectToDevTools: true,
      cache: new InMemoryCache(),
      link: link,
      defaultOptions: {
        query: {
          fetchPolicy: "network-only"
        },
        watchQuery: {
          fetchPolicy: "network-only"
        }
      }
    });
  }, []);

  return <Provider client={client}>{props.children}</Provider>;
};

export default ApolloProvider;
