// import { Grid } from "@material-ui/core";
// import clsx from "clsx";
import moment from "moment";
import React from "react";
import { CCTypography } from "styles/components";
import {
    generateGenderPieGraph,

    generateOrderedProsthesesGraph, generateUninsuredDemographicGraph,

    greyScalePieGraph
} from "types/graph";
import { formatDateTitle } from "utils/graph";
import { sumArray } from "utils/number";
import { translate } from "../components";
export const parseOrderedProstheses = data => {
  if (!data) {
    return {
      value: 0
    };
  } else {
    return {
      value: data.value
    };
  }
};

export const parseOrderedProsthesesData = (data = [], intl) => {
  const implantData = [];

  data
    .sort((a, b) => {
      if (a.value > b.value) return -1;
      if (a.value < b.value) return 1;
      return 0;
    })
    .slice(0, 5)
    .forEach(label => {
      const { value } = parseOrderedProstheses(label);

      implantData.push(value);
    });
  return [
    {
      name: translate(intl, "common.ui.prosthesis"),
      type: "column",
      data: implantData
    }
  ];
};

export const generateUninsuredPatientsField = (
  dateLabel,
  currentDate,
  dateOption
) => {
  return [
    {
      title: `의사별 임플란트 (${dateLabel})`,
      label: "implant",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const implant = value && value["0"] ? value["0"].count : 0;
        return implant;
      },
      unit: "(명)",
      unitPie: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 교정 (${dateLabel})`,
      label: "correction",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const correctionValue = value && value["3"] ? value["3"].count : 0;
        return correctionValue;
      },
      unit: "(명)",
      unitPie: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 보철 (${dateLabel})`,
      label: "prosthesis",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const dentureValue = value && value["1"] ? value["1"].count : 0;
        const prosthesisValue = value && value["8"] ? value["8"].count : 0;
        return dentureValue + prosthesisValue;
      },
      unit: "(명)",
      unitPie: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 미용치료(과세) (${dateLabel})`,
      label: "beauty",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const beautyValue = value && value["5"] ? value["5"].count : 0;
        return beautyValue;
      },
      unit: "(명)",
      unitPie: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 기타 (${dateLabel})`,
      label: "other",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const childrenValue = value && value["2"] ? value["2"].count : 0;
        const otherValue = value && value["4"] ? value["4"].count : 0;
        const listedValue = value && value["6"] ? value["6"].count : 0;
        const preservationValue = value && value["7"] ? value["7"].count : 0;
        return childrenValue + otherValue + listedValue + preservationValue;
      },
      unit: "(명)",
      unitPie: "(명)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    }
  ];
};

export const orderedProsthesesStructure = intl => {
  return {
    title: translate(intl, "menuStatistics.orderedProstheses"),
    dataParser: parseOrderedProsthesesData,
    singleDataParser: parseOrderedProstheses,
    generateFields: generateUninsuredPatientsField,
    graphFunction: generateOrderedProsthesesGraph,
    head: [
      {
        label: translate(intl, "labManageGold.no"),
        key: "date",
        width: 50,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography>{rowIndex + 1}</CCTypography>;
        }
      },
      {
        label: translate(intl, "common.ui.prosthesis"),
        key: "category",
        flex: 1,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      },
      {
        label: translate(intl, "menuStatistics.orders"),
        key: "value",
        width: 100,
        component: ({ cellData, rowData }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      }
    ]
  };
};

export const parseDefectiveProstheses = data => {
  if (!data) {
    return {
      normal: 0,
      defective: 0,
      total: 0
    };
  } else {
    return {
      normal: data.normal,
      defective: data.defective,
      total: data.total
    };
  }
};

export const parseDefectiveProsthesesData = (data = [], intl) => {
  const normalData = [];
  const defectiveData = [];
  const totalData = [];

  data
    .sort((a, b) => {
      if (a.defective > b.defective) return -1;
      if (a.defective < b.defective) return 1;
      return 0;
    })
    .slice(0, 5)
    .forEach(item => {
      const { normal, defective, total } = parseDefectiveProstheses(item);

      normalData.push(normal);
      defectiveData.push(defective);
      totalData.push(total);
    });
  return [
    {
      name: translate(intl, "menuStatistics.normal"),
      type: "column",
      data: normalData
    },
    {
      name: translate(intl, "menuStatistics.defective"),
      type: "column",
      data: defectiveData
    },
    {
      name: translate(intl, "menuStatistics.total"),
      type: "line",
      data: totalData
    }
  ];
};

export const generateUninsuredSalesField = (
  dateLabel,
  currentDate,
  dateOption
) => {
  return [
    {
      title: `의사별 임플란트 (${dateLabel})`,
      label: "implant",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const implant = value && value["0"] ? value["0"].price : 0;
        return implant;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 교정 (${dateLabel})`,
      label: "correction",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const correctionValue = value && value["3"] ? value["3"].price : 0;
        return correctionValue;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 보철 (${dateLabel})`,
      label: "prosthesis",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const dentureValue = value && value["1"] ? value["1"].price : 0;
        const prosthesisValue = value && value["8"] ? value["8"].price : 0;
        return dentureValue + prosthesisValue;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 미용치료(과세) (${dateLabel})`,
      label: "beauty",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const beautyValue = value && value["5"] ? value["5"].price : 0;
        return beautyValue;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 기타 (${dateLabel})`,
      label: "other",
      parser: (data, label) => {
        const value = data ? data[label] : undefined;
        const childrenValue = value && value["2"] ? value["2"].price : 0;
        const otherValue = value && value["4"] ? value["4"].price : 0;
        const listedValue = value && value["6"] ? value["6"].price : 0;
        const preservationValue = value && value["7"] ? value["7"].price : 0;
        return childrenValue + otherValue + listedValue + preservationValue;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    }
  ];
};

export const defectiveProsthesesStructure = intl => {
  return {
    title: translate(intl, "menuStatistics.defectiveProstheses"),
    // fetchURL: process.env.REACT_APP_CF_QUERY_URL + "/uninsured",
    dataParser: parseDefectiveProsthesesData,
    singleDataParser: parseDefectiveProstheses,
    generateFields: generateUninsuredSalesField,
    graphFunction: generateOrderedProsthesesGraph,
    head: [
      {
        label: translate(intl, "labManageGold.no"),
        key: "no",
        width: 50,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography>{rowIndex + 1}</CCTypography>;
        }
      },
      {
        label: translate(intl, "common.ui.prosthesis"),
        key: "category",
        flex: 1,
        component: ({ cellData, rowIndex }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      },
      {
        label: translate(intl, "common.ui.defected"),
        key: "defective",
        width: 100,
        component: ({ cellData, rowData }) => {
          return <CCTypography>{cellData}</CCTypography>;
        }
      }
    ]
  };
};

export const parseUninsuredAgeData = (data, labels, doctor = "") => {
  const maleSeries = {
    implant: [],
    prosthesis: [],
    correction: [],
    beauty: [],
    other: []
  };
  const femaleSeries = {
    implant: [],
    prosthesis: [],
    correction: [],
    beauty: [],
    other: []
  };
  if (data) {
    const { man, woman } =
      doctor !== ""
        ? data[doctor]
          ? data[doctor]
          : { man: undefined, woman: undefined }
        : data;
    labels.forEach(label => {
      const {
        implant: implantMan,
        prosthesis: prosthesisMan,
        correction: correctionMan,
        beauty: beautyMan,
        other: otherMan
      } = parseDefectiveProstheses(man);
      maleSeries.implant.push(implantMan);
      maleSeries.correction.push(correctionMan);
      maleSeries.prosthesis.push(prosthesisMan);
      maleSeries.beauty.push(beautyMan);
      maleSeries.other.push(otherMan);

      const {
        implant: implantWoman,
        prosthesis: prosthesisWoman,
        correction: correctionWoman,
        beauty: beautyWoman,
        other: otherWoman
      } = parseDefectiveProstheses(woman);

      femaleSeries.implant.push(implantWoman);
      femaleSeries.correction.push(correctionWoman);
      femaleSeries.prosthesis.push(prosthesisWoman);
      femaleSeries.beauty.push(beautyWoman);
      femaleSeries.other.push(otherWoman);
    });
  }
  return { maleSeries: maleSeries, femaleSeries: femaleSeries };
};

export const generateAgeDemographicField = (
  dateLabel,
  currentDate,
  dateOption
) => {
  return [
    {
      title: `의사별 임플란트 (${dateLabel})`,
      label: "implant",
      parser: (data, label) => {
        if (data) {
          const { man, woman } = data;
          const maleValue = man ? man[label] : undefined;
          const femaleValue = woman ? woman[label] : undefined;
          const maleImplant =
            maleValue && maleValue["0"] ? maleValue["0"].price : 0;
          const femaleImplant =
            femaleValue && femaleValue["0"] ? femaleValue["0"].price : 0;
          return maleImplant + femaleImplant;
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const maleTotal =
            maleValue && maleValue["0"] ? maleValue["0"].price : 0;
          const femaleTotal =
            femaleValue && femaleValue["0"] ? femaleValue["0"].price : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 교정 (${dateLabel})`,
      label: "correction",
      parser: (data, label) => {
        if (data) {
          const { man, woman } = data;
          const maleValue = man ? man[label] : undefined;
          const femaleValue = woman ? woman[label] : undefined;
          const maleCorrection =
            maleValue && maleValue["3"] ? maleValue["3"].price : 0;
          const femaleCorrection =
            femaleValue && femaleValue["3"] ? femaleValue["3"].price : 0;
          return maleCorrection + femaleCorrection;
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const maleTotal =
            maleValue && maleValue["3"] ? maleValue["3"].price : 0;
          const femaleTotal =
            femaleValue && femaleValue["3"] ? femaleValue["3"].price : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 보철 (${dateLabel})`,
      label: "prosthesis",
      parser: (data, label) => {
        if (data) {
          const { man, woman } = data;
          const maleValue = man ? man[label] : undefined;
          const femaleValue = woman ? woman[label] : undefined;
          const dentureValueM =
            maleValue && maleValue["1"] ? maleValue["1"].price : 0;
          const prosthesisValueM =
            maleValue && maleValue["8"] ? maleValue["8"].price : 0;
          const dentureValueF =
            femaleValue && femaleValue["1"] ? femaleValue["1"].price : 0;
          const prosthesisValueF =
            femaleValue && femaleValue["8"] ? femaleValue["8"].price : 0;

          return (
            dentureValueF + dentureValueM + prosthesisValueM + prosthesisValueF
          );
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          let maleTotal =
            maleValue && maleValue["1"] ? maleValue["1"].price : 0;
          maleTotal =
            maleTotal + maleValue && maleValue["8"] ? maleValue["8"].price : 0;
          let femaleTotal =
            femaleValue && femaleValue["1"] ? femaleValue["1"].price : 0;
          femaleTotal =
            femaleTotal + femaleValue && femaleValue["8"]
              ? femaleValue["8"].price
              : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 미용치료(과세) (${dateLabel})`,
      label: "beauty",
      parser: (data, label) => {
        if (data) {
          const { man, woman } = data;
          const maleValue = man ? man[label] : undefined;
          const femaleValue = woman ? woman[label] : undefined;
          const beautyValueM =
            maleValue && maleValue["5"] ? maleValue["5"].price : 0;
          const beautyValueF =
            femaleValue && femaleValue["5"] ? femaleValue["5"].price : 0;
          return beautyValueM + beautyValueF;
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const maleTotal =
            maleValue && maleValue["5"] ? maleValue["5"].price : 0;
          const femaleTotal =
            femaleValue && femaleValue["5"] ? femaleValue["5"].price : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 기타 (${dateLabel})`,
      label: "other",
      parser: (data, label) => {
        if (data) {
          const { man, woman } = data;
          const maleValue = man ? man[label] : undefined;
          const femaleValue = woman ? woman[label] : undefined;
          const childrenValueM =
            maleValue && maleValue["2"] ? maleValue["2"].price : 0;
          const otherValueM =
            maleValue && maleValue["4"] ? maleValue["4"].price : 0;
          const listedValueM =
            maleValue && maleValue["6"] ? maleValue["6"].price : 0;
          const preservationValueM =
            maleValue && maleValue["7"] ? maleValue["7"].price : 0;
          const childrenValueF =
            femaleValue && femaleValue["2"] ? femaleValue["2"].price : 0;
          const otherValueF =
            femaleValue && femaleValue["4"] ? femaleValue["4"].price : 0;
          const listedValueF =
            femaleValue && femaleValue["6"] ? femaleValue["6"].price : 0;
          const preservationValueF =
            femaleValue && femaleValue["7"] ? femaleValue["7"].price : 0;

          return (
            childrenValueF +
            childrenValueM +
            otherValueF +
            otherValueM +
            listedValueF +
            listedValueM +
            preservationValueF +
            preservationValueM
          );
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const childrenValueM =
            maleValue && maleValue["2"] ? maleValue["2"].price : 0;
          const otherValueM =
            maleValue && maleValue["4"] ? maleValue["4"].price : 0;
          const listedValueM =
            maleValue && maleValue["6"] ? maleValue["6"].price : 0;
          const preservationValueM =
            maleValue && maleValue["7"] ? maleValue["7"].price : 0;
          const childrenValueF =
            femaleValue && femaleValue["2"] ? femaleValue["2"].price : 0;
          const otherValueF =
            femaleValue && femaleValue["4"] ? femaleValue["4"].price : 0;
          const listedValueF =
            femaleValue && femaleValue["6"] ? femaleValue["6"].price : 0;
          const preservationValueF =
            femaleValue && femaleValue["7"] ? femaleValue["7"].price : 0;

          total =
            total +
            childrenValueF +
            childrenValueM +
            otherValueF +
            otherValueM +
            listedValueF +
            listedValueM +
            preservationValueF +
            preservationValueM;
        });

        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    }
  ];
};

export const generateGenderDemographicField = (
  dateLabel,
  currentDate,
  dateOption
) => {
  return [
    {
      title: `의사별 임플란트 (${dateLabel})`,
      label: "implant",
      parser: (data, label) => {
        if (data) {
          const parseLabels = [
            "90-0",
            "80-90",
            "70-80",
            "60-70",
            "50-60",
            "40-50",
            "30-40",
            "20-30",
            "10-20",
            "0-10"
          ];
          const { maleSeries, femaleSeries } = parseUninsuredAgeData(
            data,
            parseLabels
          );
          if (label === "male") {
            return sumArray(maleSeries.implant);
          } else {
            return sumArray(femaleSeries.implant);
          }
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const maleTotal =
            maleValue && maleValue["0"] ? maleValue["0"].price : 0;
          const femaleTotal =
            femaleValue && femaleValue["0"] ? femaleValue["0"].price : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 교정 (${dateLabel})`,
      label: "correction",
      parser: (data, label) => {
        if (data) {
          const parseLabels = [
            "90-0",
            "80-90",
            "70-80",
            "60-70",
            "50-60",
            "40-50",
            "30-40",
            "20-30",
            "10-20",
            "0-10"
          ];
          const { maleSeries, femaleSeries } = parseUninsuredAgeData(
            data,
            parseLabels
          );
          if (label === "male") {
            return sumArray(maleSeries.correction);
          } else {
            return sumArray(femaleSeries.correction);
          }
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const maleTotal =
            maleValue && maleValue["3"] ? maleValue["3"].price : 0;
          const femaleTotal =
            femaleValue && femaleValue["3"] ? femaleValue["3"].price : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 보철 (${dateLabel})`,
      label: "prosthesis",
      parser: (data, label) => {
        if (data) {
          const parseLabels = [
            "90-0",
            "80-90",
            "70-80",
            "60-70",
            "50-60",
            "40-50",
            "30-40",
            "20-30",
            "10-20",
            "0-10"
          ];
          const { maleSeries, femaleSeries } = parseUninsuredAgeData(
            data,
            parseLabels
          );
          if (label === "male") {
            return sumArray(maleSeries.prosthesis);
          } else {
            return sumArray(femaleSeries.prosthesis);
          }
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          let maleTotal =
            maleValue && maleValue["1"] ? maleValue["1"].price : 0;
          maleTotal =
            maleTotal + maleValue && maleValue["8"] ? maleValue["8"].price : 0;
          let femaleTotal =
            femaleValue && femaleValue["1"] ? femaleValue["1"].price : 0;
          femaleTotal =
            femaleTotal + femaleValue && femaleValue["8"]
              ? femaleValue["8"].price
              : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 미용치료(과세) (${dateLabel})`,
      label: "beauty",
      parser: (data, label) => {
        if (data) {
          const parseLabels = [
            "90-0",
            "80-90",
            "70-80",
            "60-70",
            "50-60",
            "40-50",
            "30-40",
            "20-30",
            "10-20",
            "0-10"
          ];
          const { maleSeries, femaleSeries } = parseUninsuredAgeData(
            data,
            parseLabels
          );
          if (label === "male") {
            return sumArray(maleSeries.beauty);
          } else {
            return sumArray(femaleSeries.beauty);
          }
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const maleTotal =
            maleValue && maleValue["5"] ? maleValue["5"].price : 0;
          const femaleTotal =
            femaleValue && femaleValue["5"] ? femaleValue["5"].price : 0;
          total = total + femaleTotal + maleTotal;
        });
        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    },
    {
      title: `의사별 기타 (${dateLabel})`,
      label: "other",
      parser: (data, label) => {
        if (data) {
          const parseLabels = [
            "90-0",
            "80-90",
            "70-80",
            "60-70",
            "50-60",
            "40-50",
            "30-40",
            "20-30",
            "10-20",
            "0-10"
          ];
          const { maleSeries, femaleSeries } = parseUninsuredAgeData(
            data,
            parseLabels
          );
          if (label === "male") {
            return sumArray(maleSeries.other);
          } else {
            return sumArray(femaleSeries.other);
          }
        }
        return 0;
      },
      pieParser: (data, field) => {
        const maleData = data.man;
        const femaleData = data.woman;
        const keys = Object.keys(maleData);
        let total = 0;
        keys.forEach(label => {
          const maleValue = maleData ? maleData[label] : 0;
          const femaleValue = femaleData ? femaleData[label] : 0;
          const childrenValueM =
            maleValue && maleValue["2"] ? maleValue["2"].price : 0;
          const otherValueM =
            maleValue && maleValue["4"] ? maleValue["4"].price : 0;
          const listedValueM =
            maleValue && maleValue["6"] ? maleValue["6"].price : 0;
          const preservationValueM =
            maleValue && maleValue["7"] ? maleValue["7"].price : 0;
          const childrenValueF =
            femaleValue && femaleValue["2"] ? femaleValue["2"].price : 0;
          const otherValueF =
            femaleValue && femaleValue["4"] ? femaleValue["4"].price : 0;
          const listedValueF =
            femaleValue && femaleValue["6"] ? femaleValue["6"].price : 0;
          const preservationValueF =
            femaleValue && femaleValue["7"] ? femaleValue["7"].price : 0;

          total =
            total +
            childrenValueF +
            childrenValueM +
            otherValueF +
            otherValueM +
            listedValueF +
            listedValueM +
            preservationValueF +
            preservationValueM;
        });

        return total;
      },
      unit: "(천원)",
      unitPie: "(원)",
      pieTitle: formatDateTitle(currentDate, dateOption)
    }
  ];
};

export const demographicStructure = selection => {
  return {
    title: `비급여 진료항목별 매출액 (${selection ? "성별" : "연령"})`,
    // fetchURL: process.env.REACT_APP_CF_QUERY_URL + "/uninsured",
    dataParser: (graphData, graphLabels, currentDoctor) => {
      const parseLabels = [
        "0-10",
        "10-20",
        "20-30",
        "30-40",
        "40-50",
        "50-60",
        "60-70",
        "70-80",
        "80-90",
        "90-0"
      ];
      return parseUninsuredAgeData(graphData, parseLabels, currentDoctor);
    },
    singleDataParser: (data, doctor, currentLabel) => {
      const parseLabels = [
        "90-0",
        "80-90",
        "70-80",
        "60-70",
        "50-60",
        "40-50",
        "30-40",
        "20-30",
        "10-20",
        "0-10"
      ];
      return parseUninsuredAgeData(data, parseLabels, doctor);
    },
    generateFields:
      selection === 0
        ? generateAgeDemographicField
        : generateGenderDemographicField,
    graphFunction: seriesData => {
      const { maleSeries, femaleSeries } = seriesData;

      if (selection === 0) {
        const implantSeries = maleSeries.implant.map((value, index) => {
          return value + femaleSeries.implant[index];
        });
        const correctionSeries = maleSeries.correction.map((value, index) => {
          return value + femaleSeries.correction[index];
        });
        const prosthesisSeries = maleSeries.prosthesis.map((value, index) => {
          return value + femaleSeries.prosthesis[index];
        });
        const beautySeries = maleSeries.beauty.map((value, index) => {
          return value + femaleSeries.beauty[index];
        });
        const otherSeries = maleSeries.other.map((value, index) => {
          return value + femaleSeries.other[index];
        });

        return generateUninsuredDemographicGraph(
          [
            {
              name: "임플란트",
              type: "bar",
              data: implantSeries
            },
            {
              name: "교정",
              type: "bar",
              data: correctionSeries
            },
            {
              name: "보철",
              type: "bar",
              data: prosthesisSeries
            },

            {
              name: "미용치료(과세)",
              type: "bar",
              data: beautySeries
            },
            {
              name: "기타",
              type: "bar",
              data: otherSeries
            }
          ],
          [
            "90대 이상",
            "80대",
            "70대",
            "60대",
            "50대",
            "40대",
            "30대",
            "20대",
            "10대",
            "10대 미만"
          ].reverse()
        );
      } else {
        const implantSeries = [
          sumArray(maleSeries.implant),
          sumArray(femaleSeries.implant)
        ];
        const correctionSeries = [
          sumArray(maleSeries.correction),
          sumArray(femaleSeries.correction)
        ];
        const prosthesisSeries = [
          sumArray(maleSeries.prosthesis),
          sumArray(femaleSeries.prosthesis)
        ];
        const beautySeries = [
          sumArray(maleSeries.beauty),
          sumArray(femaleSeries.beauty)
        ];
        const otherSeries = [
          sumArray(maleSeries.other),
          sumArray(femaleSeries.other)
        ];

        return generateUninsuredDemographicGraph(
          [
            {
              name: "임플란트",
              type: "bar",
              data: implantSeries
            },
            {
              name: "교정",
              type: "bar",
              data: correctionSeries
            },
            {
              name: "보철",
              type: "bar",
              data: prosthesisSeries
            },
            {
              name: "미용치료(과세)",
              type: "bar",
              data: beautySeries
            },
            {
              name: "기타",
              type: "bar",
              data: otherSeries
            }
          ],
          ["남", "여"]
        );
      }
    },
    pieGraphFunction: value => {
      const { maleSeries, femaleSeries } = value;
      if (selection === 0) {
        const implantSeries = maleSeries.implant.map((value, index) => {
          return value + femaleSeries.implant[index];
        });
        const correctionSeries = maleSeries.correction.map((value, index) => {
          return value + femaleSeries.correction[index];
        });
        const prosthesisSeries = maleSeries.prosthesis.map((value, index) => {
          return value + femaleSeries.prosthesis[index];
        });
        const beautySeries = maleSeries.beauty.map((value, index) => {
          return value + femaleSeries.beauty[index];
        });
        const otherSeries = maleSeries.other.map((value, index) => {
          return value + femaleSeries.other[index];
        });

        const ageSeries = implantSeries.map((value, index) => {
          return (
            value +
            prosthesisSeries[index] +
            correctionSeries[index] +
            beautySeries[index] +
            otherSeries[index]
          );
        });

        return greyScalePieGraph(
          ageSeries.reverse(),
          [
            "90대 이상",
            "80대",
            "70대",
            "60대",
            "50대",
            "40대",
            "30대",
            "20대",
            "10대",
            "10대 미만"
          ].reverse(),
          "(원)"
        );
      } else {
        const implantSeries = [
          sumArray(maleSeries.implant),
          sumArray(femaleSeries.implant)
        ];
        const correctionSeries = [
          sumArray(maleSeries.correction),
          sumArray(femaleSeries.correction)
        ];
        const prosthesisSeries = [
          sumArray(maleSeries.prosthesis),
          sumArray(femaleSeries.prosthesis)
        ];
        const beautySeries = [
          sumArray(maleSeries.beauty),
          sumArray(femaleSeries.beauty)
        ];
        const otherSeries = [
          sumArray(maleSeries.other),
          sumArray(femaleSeries.other)
        ];

        const total = [
          implantSeries[0] +
            correctionSeries[0] +
            prosthesisSeries[0] +
            beautySeries[0] +
            otherSeries[0],
          implantSeries[1] +
            correctionSeries[1] +
            prosthesisSeries[1] +
            beautySeries[1] +
            otherSeries[1]
        ];
        return generateGenderPieGraph(total, "원");
      }
    },
    dialogHeader: (data, currentDate, dateOption, classes) => {
      return [];
    },
    customLabels: data =>
      selection === 0
        ? [
            "90-0",
            "80-90",
            "70-80",
            "60-70",
            "50-60",
            "40-50",
            "30-40",
            "20-30",
            "10-20",
            "0-10"
          ].reverse()
        : ["male", "female"],
    customFormatter: value => {
      switch (value) {
        case "90-0":
          return "90대 이상";
        case "80-90":
          return "80대";
        case "70-80":
          return "70대";
        case "60-70":
          return "60대";
        case "50-60":
          return "50대";
        case "40-50":
          return "40대";
        case "30-40":
          return "30대";
        case "20-30":
          return "20대";
        case "10-20":
          return "10대";
        case "0-10":
          return "10대 미만";
        case "male":
          return "남";
        case "female":
          return "여";
        default:
          return value;
      }
    },
    customDateRange: (value, dateOption) => {
      const startDate = moment
        .unix(value)
        .startOf(dateOption)
        .format("YYYYMMDD");
      const endDate = moment.unix(value).endOf(dateOption).format("YYYYMMDD");
      return { start: startDate, end: endDate };
    },
    parseTableData: data => {
      if (data) {
        const { man, woman } = data;
        const implantData = { label: "임플란트", male: 0, female: 0 };
        const correctionData = { label: "교정", male: 0, female: 0 };
        const prosthesisData = { label: "보철", male: 0, female: 0 };
        const beautyData = { label: "미용치료(과세)", male: 0, female: 0 };
        const otherData = { label: "기타", male: 0, female: 0 };
        const totalData = { label: "합계", male: 0, female: 0 };
        let implantTotal = 0;
        let correctionTotal = 0;
        let prosthesisTotal = 0;
        let beautyTotal = 0;
        let otherTotal = 0;
        let totalSales = 0;
        const keys = Object.keys(man);
        keys.forEach(key => {
          const {
            implant: implantMale,
            correction: correctionMale,
            prosthesis: prosthesisMale,
            beauty: beautyMale,
            other: otherMale
          } = parseDefectiveProstheses(man);
          const {
            implant: implantFemale,
            correction: correctionFemale,
            prosthesis: prosthesisFemale,
            beauty: beautyFemale,
            other: otherFemale
          } = parseDefectiveProstheses(woman);
          implantTotal = implantTotal + implantMale + implantFemale;
          correctionTotal = correctionTotal + correctionMale + correctionFemale;
          prosthesisTotal = prosthesisTotal + prosthesisMale + prosthesisFemale;
          beautyTotal = beautyTotal + beautyMale + beautyFemale;
          otherTotal = otherTotal + otherMale + otherFemale;
          totalSales =
            totalSales +
            implantMale +
            correctionMale +
            prosthesisMale +
            beautyMale +
            otherMale +
            implantFemale +
            correctionFemale +
            prosthesisFemale +
            beautyFemale +
            otherFemale;
          if (selection === 0) {
            implantData[key] = implantMale + implantFemale;
            correctionData[key] = correctionMale + correctionFemale;
            prosthesisData[key] = prosthesisMale + prosthesisFemale;
            beautyData[key] = beautyMale + beautyFemale;
            otherData[key] = otherMale + otherFemale;
            totalData[key] =
              implantMale +
              correctionMale +
              prosthesisMale +
              beautyMale +
              otherMale +
              implantFemale +
              correctionFemale +
              prosthesisFemale +
              beautyFemale +
              otherFemale;
          } else {
            implantData.male = implantMale + implantData.male;
            correctionData.male = correctionMale + correctionData.male;
            prosthesisData.male = prosthesisMale + prosthesisData.male;
            beautyData.male = beautyData.male + beautyMale;
            otherData.male = otherMale + otherData.male;
            totalData.male =
              totalData.male +
              implantMale +
              correctionMale +
              prosthesisMale +
              beautyMale +
              otherMale;
            implantData.female = implantFemale + implantData.female;
            correctionData.female = correctionFemale + correctionData.female;
            prosthesisData.female = prosthesisFemale + prosthesisData.female;
            beautyData.female = beautyData.female + beautyFemale;
            otherData.female = otherFemale + otherData.female;
            totalData.female =
              totalData.female +
              implantFemale +
              correctionFemale +
              prosthesisFemale +
              beautyFemale +
              otherFemale;
          }
        });
        return [
          { ...implantData, total: implantTotal },
          { ...correctionData, total: correctionTotal },
          { ...prosthesisData, total: prosthesisTotal },
          { ...beautyData, total: beautyTotal },
          { ...otherData, total: otherTotal },
          { ...totalData, total: totalSales }
        ];
      }
      return [];
    },
    tableWidth: selection === 0 ? "1120px" : "420px"
  };
};
