import { useQuery } from "@apollo/client";
import { Box, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { translate } from "components";
import CVButton from "components/CVButton";
import CVTable from "components/CVTable";
import CVTextField from "components/CVTextField";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { useIntl } from "react-intl";
import {  GET_CONFIG_PROCESS } from "queries/setting";
import { CCMenuItem } from "styles/src/components/CCMenu/Component";
import CCTabPanel from "styles/src/components/CCTabPanel";
import CCTextField from "styles/src/components/CCTextField";
import CCTabs from "styles/src/components/CCTabs";
import { uuid } from "uuidv4";
import CrownListDialog from "views/Normal/Setting/components/CrownSetting/components/CrownListDialog";
import PriceAmountTextField from "../../../../../../../components/PriceAmountTextField/PriceAmountTextField";
import DeltalLabSettingContentMaterial from "../DentalLabSettingContentMaterial";

const useStyles = makeStyles(theme => ({
  root: { width: "100%", height: "calc(100% - 176px)"},
  table__tab: {
    width: `calc(100% - 160px)`
  },
  right__button: {
    flex: 1,
    borderBottom: "1px solid #b0bec5",
    height: 49
  },
  cell: {
    textAlign: "center",
    color: theme.palette.text.primary
  },
  productionProcess: {
    width: 250
  }
}));


const prosthUnitItems = ["Teeth", "Oral"];

const DentalLabSettingContents = forwardRef((props, ref) => {
  const { value, permission, currentClinic, contentFilter } = props;

  // classes
  const classes = useStyles();
  const intl = useIntl();

  // value
  const [selectedTab, setSelectedTab] = useState(0);
  const [field, setField] = useState(value || {});
  const [open, setOpen] = useState(false); //false

  const { data: processData } = useQuery(GET_CONFIG_PROCESS, {
    variables: {
      category: "productProcessSetting",
      labId: localStorage.getItem("labId")
    },
    fetchPolicy: "network-only"
  });
const processItems = processData?.getConfig?.productProcessSetting || [];

  // function
  const tabList = field?.contents
    ? field.contents.map(item => {
        return item.category;
      })
    : [];
  const tabContents = field?.contents
    ? field.contents.map(item => item.tabContents)
    : [];
  const category = field?.contents
    ? field.contents.map(item => ({
        tabList: item.category,
        id: item.id
      }))
    : [];
  useImperativeHandle(ref, () => ({
    getData: () => {
      return field;
    },
    handleCancel: () => handleCancel()
  }));
  const handleCancel = () => {
    setField(value);
  };
  // 삭제 버튼
  const onDelete = id => {
    setField({
      ...field,
      contents: field.contents.map(item => ({
        ...item,
        tabContents: item.tabContents.filter(data => data.id !== id)
      }))
    });
  };
  // 재료 필드 수정
  const onFieldChange = (id, value, key) => {
    setField({
      ...field,
      contents: field.contents.map(item => ({
        ...item,
        tabContents: item.tabContents.map(data =>
          data.id === id ? { ...data, [key]: value } : data
        )
      }))
    });
  };
  // 추가
  const onAddList = () => {
    setField({
      ...field,
      contents: field.contents.map(item => {
        if (item.id !== category[selectedTab].id) {
          return item;
        }
        return {
          ...item,
          tabContents: [
            ...item.tabContents,
            {
              material: "",
              price: "0",
              unit: "Teeth",
              id: uuid()
            }
          ]
        };
      })
    });
  };

  // useEffect
  useEffect(() => {
    setField(value || {});
  }, [value]);

  return (
    <Box className={clsx(classes.root)}>
      <Grid container alignItems={"center"}>
        <Grid item className={classes.table__tab}>
          <CCTabs
            size="medium"
            tabList={tabList ? tabList : []}
            selectedTab={selectedTab}
            onChangeTab={index => {
              setSelectedTab(index);
            }}
          />
        </Grid>
        <Grid item className={classes.right__button}>
          <Grid
            container
            alignItems={"center"}
            justify={"center"}
            style={{ width: "100%", height: "100%" }}
          >
            <CVButton
              permission={permission}
              size={"small"}
              variant={"outlined"}
              onClick={() => setOpen(true)}
            >
              {translate(intl, "settings.productPrice.setCategory")}
            </CVButton>
          </Grid>
        </Grid>
      </Grid>

      {tabContents &&
        tabContents.map((item, index) => (
          <CCTabPanel key={index} value={selectedTab} index={index} style={{ height: 'calc(100% - 50px)' }}>
            
              <CVTable
                permission={permission}
                onAddClick={() => onAddList()}
                heads={[
                  {
                    key: "material",
                    label: translate(intl, "settings.productPrice.material"),
                    width: 430,
                    className: classes.cell,
                    component: data => (
                      <DeltalLabSettingContentMaterial
                        onDelete={onDelete}
                        permission={permission}
                        data={data}
                        onFieldChange={onFieldChange}
                      />
                    )
                  },
                  {
                    key: "unit",
                    label: translate(intl, "settings.productPrice.unit"),
                    width: 80,
                    className: classes.cell,
                    component: data => {
                      return (
                        <>
                          <Grid
                            container
                            wrap={"nowrap"}
                            alignItems={"center"}
                            justify={"center"}
                          >
                            <Grid item>
                              <CVTextField
                                permission={permission}
                                margin={"dense"}
                                variant={"outlined"}
                                required
                                value={data.cellData || ""}
                                onChange={e =>
                                  onFieldChange(
                                    data.rowData.id,
                                    e.target.value,
                                    "unit"
                                  )
                                }
                                select
                              >
                                {prosthUnitItems.map(item => (
                                  <CCMenuItem key={item} value={item}>
                                    {item}
                                  </CCMenuItem>
                                ))}
                              </CVTextField>
                            </Grid>
                          </Grid>
                        </>
                      );
                    }
                  },
                  {
                    key: "price",
                    label: translate(intl, "settings.productPrice.priceVnd"),
                    width: 270,
                    className: classes.cell,
                    component: data => {
                      return (
                        <PriceAmountTextField
                          style={{ width: 250 }}
                          margin={"dense"}
                          variant={"outlined"}
                          value={data.cellData}
                          onChange={e => {
                            onFieldChange(
                              data.rowData.id,
                              e.target.value,
                              "price"
                            );
                          }}
                          onBlur={e => {
                            onFieldChange(
                              data.rowData.id,
                              e.target.value,
                              "price"
                            );
                          }}
                        />
                      );
                    }
                  },
                  {
                    key: "productProcess",
                    label: translate(intl, "productionProcess"),
                    width: 270,
                    className: classes.cell,
                    component: data => {
                      return (
                        <>
                          <Grid
                            container
                            wrap={"nowrap"}
                            alignItems={"center"}
                            justify={"center"}
                          >
                            <Grid item>
                              <CCTextField
                                permission={permission}
                                margin={"dense"}
                                variant={"outlined"}
                                required
                                className={classes.productionProcess}
                                value={data.cellData?.id ||"n/a"}
                                onChange={e => {
                                  onFieldChange(
                                    data.rowData.id, processItems.find(item => item.id === e.target.value) || 
                                    {
                                      id: null,
                                      processName: null,
                                      stages: null
                                    },
                                    "productProcess"
                                  )
                                }
                                }
                                select
                              >
                                <CCMenuItem key={"n/a"} value={'n/a'}>
                                    N/A
                                </CCMenuItem>
                                {processItems.map(item => (
                                  <CCMenuItem key={item.id} value={item.id}>
                                    {item.processName}
                                  </CCMenuItem>
                                ))}
                              </CCTextField>
                            </Grid>
                          </Grid>
                        </>
                      );
                    }
                  },
                ]}
                contents={item.filter((item, index) => {
                  if (!contentFilter) {
                    return true;
                  } else {
                    return item.material
                      .toLowerCase()
                      .includes(contentFilter.toLowerCase());
                  }
                })}
              />
          </CCTabPanel>
        ))}
      {open && <CrownListDialog
        open={open}
        onClose={() => setOpen(false)}
        category={category}
        currentClinic={currentClinic}
      />}
    </Box>
  );
});

DentalLabSettingContents.defaultProps = {};

DentalLabSettingContents.propTypes = {};

export default DentalLabSettingContents;
