import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import PropTypes from "prop-types";
import React from "react";
import { CCButton, CCDialogSimple } from "../";
import { useIntl } from "../../../locales/language";
import CCTypography from "../CCTypography";

const useStyles = makeStyles(theme => ({
  title: { width: 316 },
  icon: {
    color: theme.palette.error.main,
    marginRight: 8
  },
  text1: {
    color: theme.palette.error.main
  },
  text2: {
    color: theme.palette.sub.main
  }
}));

const CCDialogSimpleDel = props => {
  const { onAgree, onDisagree, onClose, ...others } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <CCDialogSimple
      classes={classes}
      title={<WarningIcon className={classes.icon} />}
      contents={
        <>
          <CCTypography className={classes.text1}>
            {intl.formatMessage({ id: "ccAskDelete" })}
          </CCTypography>
          <CCTypography className={classes.text2}>
            {`* ${intl.formatMessage({ id: "ccDeleteWaring" })}`}
          </CCTypography>
        </>
      }
      endActions={
        <>
          <CCButton
            color="sub"
            onClick={() => {
              onDisagree && onDisagree();
            }}
          >
            {intl.formatMessage({ id: "ccCancel" })}
          </CCButton>
          <CCButton
            variant="contained"
            color="error"
            onClick={() => {
              onAgree && onAgree();
            }}
          >
            {intl.formatMessage({ id: "ccDelete" })}
          </CCButton>
        </>
      }
      // onCloseButton={onClose}
      onClose={onClose}
      {...others}
    />
  );
};

CCDialogSimpleDel.propTypes = {
  /** Dialog open 유무 */
  open: PropTypes.bool,
  /** Dialog close 시키는 함수 */
  onClose: PropTypes.func,
  /** 해당 item 삭제 동의 함수 */
  onAgree: PropTypes.func,
  /** 해당 item 삭제 비동의 함수 */
  onDisagree: PropTypes.func
};

export default CCDialogSimpleDel;
