import React from "react";

const CommitmentIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M16.065 10.26a.893.893 0 0 1 1.26 0l2.036 2.036a.893.893 0 0 1 0 1.26l-1.57 1.568 4.528 4.528c.286.285.681.428.681.832 0 .405-.158.786-.445 1.073a1.503 1.503 0 0 1-1.07.443c-.405 0-.548-.395-.834-.681l-4.527-4.527-1.57 1.568a.886.886 0 0 1-1.26 0h.001l-2.035-2.034a.893.893 0 0 1 0-1.26zM13.756 2c1.848-.04 3.666 1.255 4.202 3.754.113.532-.002 1.28-.346 2.247-.31-.005-.622.11-.858.346l-5.417 5.419-.144-.614a1.16 1.16 0 0 0-1.02-.795l-.216-.001a1.16 1.16 0 0 0-1.15.796l-1.43 6.072a.996.996 0 0 1-.867.77L6.411 20c-.397.002-.805-.236-1-.776l-.057-.18c-.162-.547-.387-1.655-.674-3.322l-.055-.319a30 30 0 0 0-1.688-6.032l-.265-.646c-.56-1.323-.77-2.313-.63-2.97C2.562 3.332 4.283 2.041 6.071 2h.173c.727.016 1.46.24 2.12.678l.218.154c.187.143.393.255.61.334l1.701 1.203c.097.069.21.097.32.09l.081-.014c.08-.02.156-.06.218-.118l.065-.074a.468.468 0 0 0-.047-.597l-.064-.055-.63-.445c.155-.059.304-.134.445-.226l.137-.098a4.034 4.034 0 0 1 2.165-.824L13.756 2zm-1.292 16.552l.596.596a1.182 1.182 0 0 0 1.38.218l.118-.071-.023.058c-.19.408-.516.61-.847.642l-.1.005a.995.995 0 0 1-.964-.776l-.16-.672zm.155-15.285l-.114.056.042.089c.175.414.154.897-.078 1.301l-.076.12-.02.028a1.49 1.49 0 0 1-1.93.406l-.127-.08-1.613-1.14-.086-.036a3.275 3.275 0 0 1-.437-.238l-.2-.142c-1.875-1.416-4.372-.407-4.96 2.333-.081.38.064 1.114.48 2.146l.093.225a31 31 0 0 1 1.889 6.19l.129.71.204 1.166c.223 1.233.399 2.051.514 2.414l.065.186c.006.013.006.005.01-.01l1.449-6.138a2.154 2.154 0 0 1 1.94-1.498h.263l.187.005c.494.035.952.239 1.308.572l.102.102 4.395-4.395c.189-.188.406-.337.64-.443l.15-.06.024-.08c.116-.433.157-.77.132-1l-.014-.092c-.527-2.459-2.593-3.524-4.36-2.697z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default CommitmentIcon;
