import { DialogPermissionWarn } from 'components';
import React, { forwardRef, useState } from 'react';
import CCNestedMenuItem from 'styles/src/components/CCMenu/Component/CCNestedMenuItem';

const CVNestedMenuItem = forwardRef((props, ref) => {
  const {
    children,
    permission,
    // onClick,
    MenuItemComponent,
    NestedMenuItemComponent,
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  // const handleChange = () => {
  //   setOpen(true);
  // };

  return (
    <>
      <CCNestedMenuItem
        MenuItemComponent={MenuItemComponent}
        NestedMenuItemComponent={NestedMenuItemComponent}
        {...others}
      >
        {children}
      </CCNestedMenuItem>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default CVNestedMenuItem;
