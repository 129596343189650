import { useQuery } from "@apollo/client";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ExcelDownloadButton, InfiniteTable } from "components";
import { DBStorageContext } from "context/DBContext/components";
import { SEARCH_PRODUCT_LOG } from "queries/product";
import React, { useContext, useEffect, useState } from "react";
import { CCTypography } from "styles/components";
import { CCIconButton } from "styles/src/components";
import {
  BarcodeIcon,
  MenunextIcon,
  MinusIcon,
  PlusIcon
} from "styles/src/themes/common/icons";
import { getCustomFormat, getMoment } from "utils/datetime";
import { CAPITALIZE_TYPE, useIntl } from "utils/language";
import { buildXLSXData } from "utils/table";
import InventoryDialog from "views/Normal/InventoryManagement/Component/Stock/Component/InoutTab/Component/SearchResults/Component/InventoryDialog";
import {
  TableHeader,
  TableItem
} from "views/Normal/InventoryManagement/Component/Stock/Component/ProductTab/Component/ProductCreateDialog/components/TableItems";
import useGetStaff from "views/Normal/InventoryManagement/Component/Stock/Component/ProductTab/hooks/useGetStaff";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  container: { height: "100%" },
  container__filter: {
    textAlign: "center"
  },
  item: {
    display: "inline-flex",
    alignItems: "center"
  },
  item__table: {
    height: "calc(100% - 60px - 30px)",
    width: "100%",
    alignItems: "center",
    textAlign: "center"
  },
  divider: {
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`
  },
  description: {
    display: "flex",
    width: "10px",
    height: "38px",
    borderLeft: `1px solid ${theme.palette.border.main}`,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8px"
  },
  text__description: {
    color: theme.palette.disabled.hex
  },
  button__sms: {
    width: "110px",
    height: "24px",
    padding: "0px"
  },
  button__filter: {
    minWidth: "100px",
    minHeight: "10px",
    margin: "16px"
  },
  select: { width: "100px", margin: "4px" },
  select__w126: { width: "126px" },
  select__w155: { width: "155px" },
  header: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "12px 12px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center"
  },
  head__field: {
    width: "100px"
  },
  headertwo: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "80px",
    alignItems: "center"
  },
  headerfilter: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box",
    padding: "8px 8px 8px 16px",
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center"
  },
  header__table: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  field: {
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    textAlign: "center"
  },
  table: {
    height: "100%",
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: "border-box"
  },
  shrink: {
    background: theme.palette.common.white,
    paddingRight: "4px"
  },
  button_register: {
    padding: "0px",
    width: "120px",
    margin: "10px"
  },
  button_right: {
    marginTop: "10px",
    marginRight: "10px"
  },
  small__button: {
    padding: 2,
    margin: 8,
    height: "24px",
    width: "40px",
    fontSize: "12px",
    fontWeight: "light",
    color: theme.palette.font.rgba
  },
  icon__button: {
    margin: 8,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  icon__star: {
    margin: 12,
    width: "24px",
    height: "24px",
    padding: "3px 5px",
    color: theme.palette.disabled.rgba
  },
  primary__color: {
    color: theme.palette.primary.main
  },
  error__color: {
    color: theme.palette.error.main
  }
}));
const REGISTER_PRODUCT = "registerProduct"; // create new product
const ERR_CORRECTION = "errorCorrection"; // error correction
const OTHER_PURCHASE = "otherPurchase"; // purchase
const OTHER_RETURN = "otherReturn"; // return
const OTHER_ACQUISITION = "otherAcquisition"; // etc
const MATERIAL_USAGE = "materialUsage"; // used product


const SearchResults = props => {
  const { result } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [detail, setDetail] = useState(false);
  const [staff, setStaff] = useState(null);
  const { getStaff } = useGetStaff();

  // const { dbStorageContext } = useContext(DBStorageContext);
  const isArabic = localStorage.getItem("lang") === "ar";

  const [searchResult, setSearchResult] = useState();
  const { data: productLogList, refetch, loading } = useQuery(SEARCH_PRODUCT_LOG, {
    variables: {
      query: {}
    },
  });

  useEffect(() => {
    console.log("useEffect", "productList")
    if (!loading && productLogList) {
      console.log("useEffect", "productList", "loaded", productLogList)
      setSearchResult(productLogList?.productLogList?.items || []);
    }
  }, [productLogList, loading])
  // const { data, refetch } = useQuery(SEARCH_PRODUCT_LOG, {
  //   variables: {
  //     def: "1",
  //     sort: { indexKey: "createTime", order: true }
  //   },
  //   onCompleted: async () => {
  //     const _staff = await getStaff();
  //     setStaff(_staff);
  //   }
  // });

  // useEffect(() => {
  //   refetch();
  //   setSearchResult(data?.searchProductLog);
  // }, [data, searchResult, refetch]);

  const excelHeader = [
    {
      label: intl.formatMessage(
        { id: "dateTime" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "createTime"
    },
    {
      label: intl.formatMessage(
        { id: "type" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "inOut"
    },
    {
      label: intl.formatMessage(
        { id: "detailedClassification" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "detail"
    },
    {
      label: intl.formatMessage(
        { id: "majorCategory" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "major"
    },
    {
      label: intl.formatMessage(
        { id: "importantProduct" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "storage"
    },
    {
      label: intl.formatMessage(
        { id: "productName" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "productName"
    },
    {
      label: intl.formatMessage(
        { id: "manufacturer" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "manufacture"
    },
    {
      label: intl.formatMessage(
        { id: "productName" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "productName"
    },
    {
      label: intl.formatMessage(
        { id: "unit" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "unit"
    },
    {
      label: intl.formatMessage(
        { id: "cntforBin" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "binCnt"
    },
    {
      label: intl.formatMessage(
        { id: "previousAmount" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "previousAmount"
    },
    {
      label: intl.formatMessage(
        { id: "changedAmount" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "changedAmount"
    },
    {
      label: intl.formatMessage(
        { id: "increase" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "changedCnt"
    },
    {
      label: intl.formatMessage(
        { id: "staffInCharge" },
        {},
        { capitalizeType: CAPITALIZE_TYPE.APA }
      ),
      id: "staffName"
    }
  ];

  const formatDetailExcelField = (detailType) => {
    let formatDetailType = '';
    switch(detailType) {
      case REGISTER_PRODUCT:
        formatDetailType = 'Register product';
        // code block
        break;
      case ERR_CORRECTION:
        formatDetailType = 'Error correction';
        break;
      case OTHER_PURCHASE:
        formatDetailType = 'Purchase';
        // code block
        break;
      case OTHER_RETURN:
        formatDetailType = 'Return';
        // code block
        break; 
      case OTHER_ACQUISITION:
        formatDetailType = 'Etc.'
        // code block
        break; 
      case MATERIAL_USAGE:
        formatDetailType = 'Used product'
        // code block
        break;
      default:
        break;
        // code block
    }
    return formatDetailType
  }

  // console.log("SearchResult ExcelDownloadButton searchResult", searchResult);
  return (
    <Box className={classes.root}>
      <Grid className={classes.container} container direction="column">
        <Grid style={{ height: "40px" }} item>
          <Box className={classes.header}>
            <CCTypography variant="h5">
              {intl.formatMessage({ id: "searchResult" })} ({result?.length})
            </CCTypography>

            <Box marginLeft="auto">
              <ExcelDownloadButton
                type="icon"
                exportData={
                  searchResult?.length &&
                  buildXLSXData(
                    isArabic ? excelHeader.reverse() : excelHeader,
                    searchResult.map(e => {
                      console.log("eeee", e)
                      return {
                        ...e,
                        binCnt: e?.binCnt,
                        inOut: e?.inOut
                          ? intl.formatMessage({ id: e?.inOut })
                          : "1",
                        detail: formatDetailExcelField(e.detail),
                        // createTime: getCustomFormat(e?.createTime, "X", "LT"),
                        //getMoment().locale("en").format("YYYYMMDDHHmm")
                        createTime: 
                          localStorage.getItem("lang") === "ar"
                            ? getCustomFormat(e?.createTime, "X", "L h:mm A")
                            : getCustomFormat(e?.createTime, "X", "L HH:mm"),
                        staff: staff?.filter(
                          item => item?.id === e?.staffName
                        )[0]?.name
                      };
                    }),
                    `Inventory`
                  )
                }
                exportFileName={`${intl.formatMessage({
                  id: "inventoryHistory"
                })}_${getMoment()
                  .locale("en")
                  .format("YYYYMMDDHHmm")}`}
              />
            </Box>
          </Box>
        </Grid>
        <Grid className={classes.item__table} item>
          <InfiniteTable
            heads={[
              {
                key: "detail",
                width: 50,
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      label={cellData === "barcode" ? <BarcodeIcon /> : null}
                    />
                  );
                }
              },
              {
                key: "createTime",
                headComponent: () => (
                  <TableHeader label={intl.formatMessage({ id: "dateTime" })} />
                ),
                width: 280,
                label: () => (
                  <TableHeader label={intl.formatMessage({ id: "dateTime" })} />
                ),
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      label={
                        cellData
                          ? // ? getMoment(cellData).creationData().locale.longDateFormat("LT");
                          localStorage.getItem("lang") === "ar"
                            ? getCustomFormat(cellData, "X", "L h:mm A")
                            : getCustomFormat(cellData, "X", "L HH:mm")
                          : ""
                      }
                    />
                  );
                }
              },
              {
                key: "detail",
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "detailedClassification" })}
                  />
                ),
                width: 160,
                component: ({ cellData }) => {
                  return (
                    <TableItem
                      label={
                        cellData
                          ? intl.formatMessage(
                            { id: cellData },
                            { br: () => <br /> }
                          )
                          : ""
                      }
                    />
                  );
                }
              },
              {
                key: "major",
                width: 240,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "majorCategory" })}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData : ""}
                    tooltip={true} />
                )
              },
              {
                key: "productName",
                width: 450,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "productName" })}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData : ""}
                    tooltip={true} />
                )
              },
              {
                key: "unit",
                width: 50,
                headComponent: () => (
                  <TableHeader label={intl.formatMessage({ id: "unit" })} />
                ),
                component: (data) => {
                  const { cellData } = data
                  return (<TableItem tooltip={true} label={cellData && cellData === "EA" ? cellData : cellData + "(" + data.rowData.binCnt + ")"} />)
                }
              },
              {
                key: "previousAmount",
                width: 140,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "previousAmount" })}
                  />
                ),
                component: ({ cellData }) => (
                  <TableItem label={cellData ? cellData : ""} />
                )
              },
              {
                key: "inOut",
                width: 40,
                headComponent: () => <TableHeader label={<PlusIcon />} />,
                component: ({ cellData }) => (
                  <TableItem
                    // label={cellData ? intl.formatMessage({ id: cellData }) : ""}
                    label={
                      cellData === "inventoryReceiving" ? (
                        <PlusIcon />
                      ) : (
                        <MinusIcon />
                      )
                    }
                    className={{
                      [classes.primary__color]:
                        cellData === "inventoryReceiving",
                      [classes.error__color]: cellData === "inventoryShipping"
                    }}
                  />
                )
              },
              {
                width: 10,
                headComponent: () => <TableHeader label={"/"} />
              },
              {
                key: "changedCnt",
                headComponent: () => <TableHeader label={<MinusIcon />} />,
                width: 40,
                component: ({ cellData }) => {
                  return <TableItem label={cellData ? cellData : ""} />;
                }
              },
              {
                key: "changedAmount",
                width: 140,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "changedAmount" })}
                  />
                ),
                component: ({ cellData }) => {
                  return <TableItem label={cellData ? cellData : ""} />;
                }
              },
              {
                key: "staffName",
                width: 140,
                headComponent: () => (
                  <TableHeader
                    label={intl.formatMessage({ id: "staffInCharge" })}
                  />
                ),
                component: ({ cellData }) => {
                  let editList = [];

                  if (staff?.length > 0) {
                    staff.forEach(e => {
                      if (cellData === e?.id) {
                        editList.push(e);
                      }
                    });
                  }

                  return (
                    <TableItem
                      label={editList[0]?.name ? editList[0]?.name : cellData}
                    />
                  );
                }
              },
              {
                key: "id",
                width: 40,
                component: ({ cellData, rowData }) => {
                  return (
                    <TableItem
                      disableTypography
                      label={
                        <CCIconButton
                          style={{ padding: 0, margin: 0 }}
                          disableRipple
                          variant={"text"}
                          size={"small"}
                          onClick={() => {
                            if (cellData) {
                              setDetail(rowData);
                            }
                          }}
                        >
                          <MenunextIcon />
                        </CCIconButton>
                      }
                    />
                  );
                }
              }
            ]}
            contents={result || []}
            rowHoverHightlight={true}
          />
        </Grid>
      </Grid>

      {Boolean(detail?.id) && (
        <InventoryDialog
          value={detail}
          open={Boolean(detail?.id)}
          onClose={() => setDetail(false)}
        />
      )}
    </Box>
  );
};

export default SearchResults;
