import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import { CCTextField, CCTooltip } from 'styles/src/components';
import CHART from 'types/chart';

function getOption(flatData, id) {
  let _index = flatData.findIndex((item) => item.id === id);
  if (_index === -1) {
    return [];
  }
  if (
    !flatData[_index].item.useOption ||
    !flatData[_index]?.item?.option?.length
  ) {
    return [];
  }
  return flatData[_index].item.option;
}

const TreatmentOptionItem = (props) => {
  const {
    rowIndex,
    rowData,
    cellData,
    flatData,
    onChange,
    pl,
    disabled,
  } = props;
  const [option, setOption] = useState(
    flatData ? [...getOption(flatData, rowData.content.uuid)] : [],
  );
  useEffect(() => {
    if (flatData && flatData.length > 0 && rowData.content?.uuid) {
      setOption([...getOption(flatData, rowData.content.uuid)]);
    }
  }, [flatData, cellData, rowData]);
  useEffect(() => {
    if (!Boolean(cellData?.item?.option?.id) && option.length > 0) {
      // console.log(cellData?.item?.option?.id);
      onChange &&
        onChange({
          rowIndex: rowIndex,
          rowData: {
            ...rowData,
            content: {
              ...cellData,
              item: {
                ...cellData.item,
                option: {
                  ...option[0],
                },
              },
            },
          },
        });
    }
  }, [onChange, cellData, rowIndex, rowData, option]);
  return (
    <CCTooltip title={cellData?.item?.option?.content || '선택없음'}>
      <CCTextField
        value={
          Boolean(cellData?.item?.option?.id) ? cellData?.item?.option?.id : ''
        }
        variant={'outlined'}
        margin={'dense'}
        select={true}
        disabled={
          disabled ||
          !Boolean(cellData?.item?.useOption) ||
          pl.status === CHART.TREATMENT_PLAN.STATUS.PLAN.CLOSING.value
        }
        InputProps={{
          style: { width: 120 },
        }}
        onChange={(e) => {
          let _index = option.findIndex((item) => item.id === e.target.value);
          if (_index === -1) {
            return;
          }
          let _temp = {
            rowIndex: rowIndex,
            rowData: {
              ...rowData,
              content: {
                ...cellData,
                item: {
                  ...cellData.item,
                  option: {
                    ...option[_index],
                  },
                },
              },
            },
          };
          onChange && onChange(_temp);
        }}
      >
        {option &&
          option.map((item, index) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.content}
              </MenuItem>
            );
          })}
      </CCTextField>
    </CCTooltip>
  );
};
export default TreatmentOptionItem;
