import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

const useStyle = makeStyles(
  theme => ({
    root: {
      backgroundColor: theme.palette.secondary.main,
      padding: "4px 0"
      // borderTopLeftRadius: 8,
      // borderTopRightRadius: 8
    },
    typo: {
      color: theme.palette.common.white
    }
  }),
  { name: "CCFlattenDateTimePicker-Header" }
);
function weekOfMonth(input = moment(), gongdan = false) {
  const firstDayOfMonth = gongdan
    ? input.clone().locale("gondan").startOf("month")
    : input.clone().startOf("month");
  const firstDayOfWeek = gongdan
    ? firstDayOfMonth.clone().locale("gondan").startOf("week")
    : firstDayOfMonth.clone().startOf("week");

  const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

  return Math.ceil((input.date() + offset) / 7);
}
const Header = props => {
  const { value, type, mode, gongdan } = props;
  const classes = useStyle();
  const _value = Boolean(mode === "week")
    ? {
        begin: Boolean(gongdan)
          ? value.begin.clone().locale("gongdan")
          : value.begin.clone(),
        end: Boolean(gongdan)
          ? value.end.clone().locale("gongdan")
          : value.end.clone()
      }
    : Boolean(gongdan)
    ? value.clone().locale("gongdan")
    : value.clone();
  return (
    <Box className={clsx(classes.root)}>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          {mode === "day" ? (
            <Typography className={clsx(classes.typo)} variant={"h4"}>
              {type === "date" && moment(_value.toDate()).format("LL")}
              {type === "time" && moment(_value.toDate()).format("LT")}
              {type === "dateTime" && moment(_value.toDate()).format("LLLL")}
            </Typography>
          ) : (
            <Typography className={clsx(classes.typo)} variant={"h4"}>
              {type === "date" && `${weekOfMonth(_value.begin, gongdan)}주`}
              {type === "time" && moment(_value.begin.toDate()).format("LT")}
              {type === "dateTime" &&
                moment(_value.begin.toDate()).format("LLLL")}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
Header.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  type: PropTypes.oneOf(["date", "time", "dateTime"]).isRequired,
  mode: PropTypes.oneOf(["day", "week"])
};
Header.defaultProps = {
  mode: "day"
};
export default Header;
