import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CCTextFieldDuo } from "../../components";
import { CCAddressSearchDialog } from "./component";

const CCAddressField = props => {
  const { value, onChange, apiKey, LeftProps, RightProps, ...others } = props;
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <CCTextFieldDuo
        LeftProps={{
          label: "Address",
          endIcon: <SearchIcon />,
          onClickIcon: e => setOpenDialog(true),
          variant: "outlined",
          margin: "dense",
          value: value?.address,
          readOnly: true,
          ...LeftProps
        }}
        RightProps={{
          label: "detail",
          variant: "outlined",
          margin: "dense",
          value: value?.detailAddress,
          onChange: e => {
            onChange &&
              onChange({
                ...value,
                detailAddress: e.target.value
              });
          },
          ...RightProps
        }}
        {...others}
      />
      <CCAddressSearchDialog
        apiKey={apiKey}
        value={value}
        open={openDialog}
        onChange={e => {
          console.log("onChange", e);
          onChange && onChange(e);
          // dispatchState({ type: "setValue", target: e });
        }}
        onClose={() => {
          setOpenDialog(false);
        }}
      />
    </>
  );
};
CCAddressField.propTypes = {
  apiKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    address: PropTypes.string,
    coordinates: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number
    }),
    placeId: PropTypes.string
  })
};

CCAddressField.defaultProps = {
  margin: "dense",
  variant: "outlined"
};

export default CCAddressField;
