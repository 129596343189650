import { DialogPermissionWarn, useWritePermission } from 'components';
import React, { forwardRef, useState } from 'react';
import { CCIconButton } from 'styles/components';

const CVIconButton = forwardRef((props, ref) => {
  const { children, permission, onClick, ...others } = props;
  const [open, setOpen] = useState(false);
  const isPermission = useWritePermission(permission);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <CCIconButton
        onClick={isPermission ? onClick : handleClick}
        ref={ref}
        {...others}
      >
        {children}
      </CCIconButton>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default CVIconButton;
