import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { Navigate, Views } from "react-big-calendar";
import { Box, Grid, makeStyles, useTheme } from "@material-ui/core";
import { CCIconButton, CCSimpleDateNav, CCTypography } from "styles/components";
// import { CVFlattenDatePicker } from "components";
import { CCFlattenDatePicker as CVFlattenDatePicker } from "styles/components";
import { DateIcon } from "styles/icons";
import MonthWeekTab from "../MonthWeekTab";
import { LAB_ORDER_STATE } from "types/labOrder";
import { FormattedMessage, useIntl } from "utils/language";
import { translate } from "components";

const useStyle = makeStyles(theme => ({
  root: {
    minHeight: 80
  },
  control: {
    padding: "0px 16px 11px 12px"
  },
  status: {
    padding: "8px 16px"
  },
  statustext: {
    marginRight: 16,
    fontWeight: 600
  },
  pending: {
    color: theme.palette.error.light
  },
  shipped: {
    color: theme.palette.primary.main
  },
  cancel: {
    color: theme.palette.secondary.main
  },
  tabs: {
    "& .Mui-selected": {
      border: "none"
    }
  },
  datefield: { width: "fit-content", height: 24 },
  datebutton: {
    margin: "0 0 0 8px",
    padding: 0,
    minWidth: 0,
    minHeight: 0
  }
}));

const Toolbar = props => {
  const { date, label, onNavigate, onView, view, views, localizer } = props;

  const classes = useStyle();
  const theme = useTheme();
  const intl = useIntl();

  const [openDatePicker, setOpenDatePicker] = useState(null);
  const [selected, setSelected] = useState(view);
  const [currentDate, setCurrentDate] = useState(date);

  const getWeekOfMonth = (input) => {
    const firstDayOfMonth = input.clone().startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

    return Math.ceil((input.date() + offset) / 7);
  }
  useEffect(() => {
    setSelected(view);
  }, [view]);

  useEffect(() => {
    setCurrentDate(date);
  }, [date]);

  const handleClickMonthWeek = useCallback((view) => {
    setSelected(view);
    onView(view);
  }, [onView]);

  const weekTitle = useMemo(() => {
    const genWeekTitle = () => {
      // const weekNums = ["1st", "2nd", "3rd", "4th", "5th"];
      const labels = label.split(" ");
      const dateOfThurOfSelectedWeek = moment(currentDate).add(4 - currentDate.getDay(), "days")
      const dayPositionInWeek = dateOfThurOfSelectedWeek.date();
      const weekOfMonth = Math.ceil(dayPositionInWeek / 7);
      const weekNum = translate(intl, `calendar.toolbar.weekNum${weekOfMonth}`);
      const month = localizer.format(dateOfThurOfSelectedWeek, "MMMM");
      const weekLabel = `${labels[1]}-${labels[labels.length - 1]}`;
      const title = translate(intl, "calendar.toolbar.weekTitle", {
        month: month,
        weekNum: weekNum,
        label: weekLabel,
      });
      return title;
    };
    if (view === "week") return genWeekTitle();

    return localizer.format(currentDate, "MMMM YYYY");
  }, [view, currentDate, localizer, intl, label]);

  return (
    <Box className={clsx(classes.root)}>
      <Grid container className={clsx(classes.status)}>
        <Grid item>
          <CCTypography variant={"body1"} className={clsx(classes.statustext)}>
            {`${translate( intl,"labOrder.dueToday" )} ${
              localizer.messages.status?.total
            }`}
          </CCTypography>
        </Grid>
        <Grid item>
          <CCTypography
            variant={"body1"}
            className={clsx(classes.statustext, classes.shipped)}
          >
            <FormattedMessage id={LAB_ORDER_STATE.SHIPPED.localeKey} />
            {" " + localizer.messages.status?.shipped}
          </CCTypography>
        </Grid>
        <Grid item>
          <CCTypography
            variant={"body1"}
            className={clsx(classes.statustext, classes.pending)}
          >
            {translate(intl, "labOrder.pending")}
            {" " + localizer.messages.status?.pending}
          </CCTypography>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={"center"}
        justify={"space-between"}
        className={clsx(classes.control)}
      >
        <Grid item>
          <CCSimpleDateNav
            title={translate(
              intl,
              view === "week" ? "ccWeek" : "common.ui.month"
            )}
            step={view}
            value={date}
            onChange={e => {
              onNavigate(Navigate.DATE, e);
            }}
          />
        </Grid>
        <Grid
          item
          container
          justify={"center"}
          alignItems={"center"}
          className={clsx(classes.datefield)}
        >
          <CCTypography component={"span"} variant={"h5"}>
            {weekTitle}
          </CCTypography>
          <CCIconButton
            className={clsx(classes.datebutton)}
            variant={"flat"}
            onClick={e => {
              setOpenDatePicker(e.currentTarget);
            }}
          >
            <DateIcon color={theme.palette.secondary.main} />
          </CCIconButton>
          <CVFlattenDatePicker
            value={moment(date)}
            onChange={e => {
              onNavigate(Navigate.DATE, e.toDate());
              setOpenDatePicker(null);
            }}
            open={Boolean(openDatePicker)}
            onClose={e => {
              setOpenDatePicker(null);
            }}
          />
        </Grid>
        <Grid item>
          {views.includes(Views.MONTH) && views.includes(Views.WEEK) && (
            <MonthWeekTab
              className={clsx(classes.tabs)}
              selected={selected}
              onChangePeriod={handleClickMonthWeek}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(Toolbar);
