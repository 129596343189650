import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { translate } from "components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    height: 24
  },
  toggle__button: {
    fontSize: 12,
    padding: "3px 8px",
    lineHeight: 1.5,
    backgroundColor: theme.palette.common.white
  },
  selected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.select.main
  }
}));

const DATE = Object.freeze({
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
  AGENDA: "agenda"
});

const MonthWeekTab = props => {
  const {
    selected,
    onChangePeriod,
    className,
    ...other
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [dateType, setDateType] = useState(selected ?? "");

  const handleAlignment = (event, newDateType) => {
    setDateType(newDateType);
  };

  useEffect(() => {
    if (selected) {
      setDateType(selected);
    }
  }, [selected]);

  const handleChangePeriod = (type) => () => {
    onChangePeriod(type);
  };

  return (
    <ButtonGroup
      className={clsx(className, classes.root)}
      size={"small"}
      onChange={handleAlignment}
      variant={"contained"}
      {...other}
    >
      <Button
        size="small"
        className={clsx(classes.toggle__button, {
          [classes.selected]: dateType === DATE.MONTH
        })}
        onClick={handleChangePeriod(DATE.MONTH)}
      >
        {translate(intl, "common.ui.month")}
      </Button>
      <Button
        size="small"
        className={clsx(classes.toggle__button, {
          [classes.selected]: dateType === DATE.WEEK
        })}
        onClick={handleChangePeriod(DATE.WEEK)}
      >
        {translate(intl, "ccWeek")}
      </Button>
    </ButtonGroup>
  );
};

MonthWeekTab.propTypes = {
  selected: PropTypes.oneOf(["month", "week", "day", "agenda"]),
  onChangePeriod: PropTypes.func
};

MonthWeekTab.defaultProps = {
  selected: DATE.DAY
};

export default MonthWeekTab;
export { DATE };
