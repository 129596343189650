import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import clsx from 'clsx'
import { CCTextField } from "styles/components";
import { ENTRY_LIMIT } from "../../../../../../../types/constants";
import { CCTooltip } from "styles/src/components";
import NumberFormat from "react-number-format";
import {
  CVTextField,
  translate,
  NumberField,
  PhoneNumberRegionField,
} from "components";
import {
  EmailField,
  RequiredTextField
} from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
    height: 500
  },
  grid__container: {
    height: "100%"
  },
  typo__container: {
    margin: 8
  },
  item__marginLeft: {
    marginLeft: '8px !important',
    marginRight: '8px !important',
  },
  width358: { width: 358 },
  width171: { width: 171 },
  width98: { width: 98 },
  marginBottom8: { marginBottom: 8 },
  address: { margin: 8, width: 360 }
}));

const NumberFormatDiscount = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({ target: { value: values.formattedValue } });
      }}
      allowNegative={false}
      displayType={"input"}
      decimalSeparator={false}
      {...other}
    />
  );
};

const paymentDateList = [
  { label: "1st", value: "1st" },
  { label: "2nd", value: "2nd" },
  { label: "3rd", value: "3rd" },
  { label: "4th", value: "4th" },
  { label: "5th", value: "5th" },
  { label: "6th", value: "6th" },
  { label: "7th", value: "7th" },
  { label: "8th", value: "8th" },
  { label: "9th", value: "9th" },
  { label: "10th", value: "10th" },
  { label: "11th", value: "11th" },
  { label: "12th", value: "12th" },
  { label: "13th", value: "13th" },
  { label: "14th", value: "14th" },
  { label: "15th", value: "15th" },
  { label: "16th", value: "16th" },
  { label: "17th", value: "17th" },
  { label: "18th", value: "18th" },
  { label: "19th", value: "19th" },
  { label: "20th", value: "20th" },
  { label: "21st", value: "21st" },
  { label: "22nd", value: "22nd" },
  { label: "23rd", value: "23rd" },
  { label: "24th", value: "24th" },
  { label: "25th", value: "25th" },
  { label: "26th", value: "26th" },
  { label: "27th", value: "27th" },
  { label: "28th", value: "28th" },
  { label: "29th", value: "29th" },
  { label: "30th", value: "30th" },
  { label: "31st", value: "31st" }
];

const ClinicInfoField = props => {
  const { field, clinicFieldError, onHandle, onHandleError, disableSomeFields } = props;

  const classes = useStyles(props);
  const intl = useIntl();

  const handleField = name => e => {
    onHandle({ ...field, [name]: e.target.value });
  };
  const handleFieldError = name => errorState => {
    onHandleError({ [name]: errorState });
  };

  return (
    <Box className={classes.root}>
      <Grid className={classes.grid__container} container direction="column">
        <Grid item className={classes.marginBottom8}>
          <RequiredTextField
            required
            label={translate(intl, "common.ui.clinicName")}
            value={field.name || ""}
            className={classes.width358}
            onChange={handleField("name")}
            InputProps={{
              inputProps: {
                maxLength: 50
              }
            }}
            disabled={disableSomeFields}
            errorOnChange={handleFieldError("name")}
          />
          <EmailField
            required
            label={translate(intl, "common.ui.email")}
            value={field.email || ""}
            className={classes.width171}
            onChange={handleField("email")}
            InputProps={{
              inputProps: {
                maxLength: 200
              }
            }}
            disabled={disableSomeFields}
            errorOnChange={handleFieldError("email")}
          />
        </Grid>

        <Grid item className={classes.marginBottom8}>
          <PhoneNumberRegionField
            disabledClickFlag={disableSomeFields}
            required
            label={translate(intl, "common.ui.telNo")}
            value={field.tel || ""}
            className={clsx(classes.width171, classes.item__marginLeft)}
            onChange={(e) => {
              handleField("tel")(e)
              handleFieldError("tel")(e.target.value.length <5)
            }}
            error={clinicFieldError.tel}
            disabled={disableSomeFields}
            minLength={5}
          />
          {/* <PhoneNumberField
            required
            label={translate( intl,"common.ui.faxNo" })}
            value={field.fax || ""}
            className={classes.width171}
            onChange={handleField("fax")}
            errorOnChange={handleFieldError("fax")}
          /> */}
          <NumberField
            required
            label={translate(intl, "settings.lab.erc")}
            value={field.tax || ""}
            className={classes.width171}
            onChange={handleField("tax")}
            errorOnChange={handleFieldError("tax")}
          />
        </Grid>

        <Grid item className={classes.marginBottom8}>
          <RequiredTextField
            required
            label={translate(intl, "common.ui.address")}
            value={field.address || ""}
            className={classes.width358}
            onChange={handleField("address")}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.MEDIUM_SIZE
              }
            }}
            disabled={disableSomeFields}
            errorOnChange={handleFieldError("address")}
          />
        </Grid>

        {/* <Grid item className={classes.marginBottom8}>
          <RequiredTextField
            required
            label={translate( intl,"common.ui.city" })}
            value={field.city || ""}
            className={classes.width171}
            onChange={handleField("city")}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.MEDIUM_SIZE
              }
            }}
            errorOnChange={handleFieldError("city")}
          />
          <RequiredTextField
            required
            label={translate( intl,"common.ui.state" })}
            value={field.state || ""}
            className={classes.width171}
            onChange={handleField("state")}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.MEDIUM_SIZE
              }
            }}
            errorOnChange={handleFieldError("state")}
          />
          <RequiredTextField
            required
            label={translate( intl,"common.ui.zipCode" })}
            value={field.zipCode || ""}
            type={"number"}
            className={classes.width98}
            onChange={handleField("zipCode")}
            errorOnChange={handleFieldError("zipCode")}
          />
        </Grid> */}

        <Grid item className={classes.marginBottom8}>
          <CVTextField
            variant={"outlined"}
            margin={"dense"}
            label={translate(intl, "common.ui.manager")}
            value={field.manager || ""}
            className={classes.width358}
            onChange={handleField("manager")}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.NAME
              }
            }}
            disabled={disableSomeFields}
          // errorOnChange={handleFieldError("manager")}
          />
          {/* <PhoneNumberField
            label={translate( intl,"common.ui.managerCellNoShort" })}
            value={field.managerCellNo || ""}
            className={classes.width171}
            onChange={handleField("managerCellNo")}
            errorOnChange={handleFieldError("managerCellNo")}
          /> */}
        </Grid>

        <Grid item className={classes.marginBottom8}>
          <CCTooltip placement={"top"} arrow title={field.discount}>
            <CCTextField
              required
              variant={"outlined"}
              margin={"dense"}
              label={translate(intl, "common.ui.discount")}
              value={!field.discount ? 0 : field.discount}
              className={classes.width171}
              // error={field.discount >= 0}
              onChange={e => {
                if (e.target.value < 0) {
                  e.target.value = 0;
                } else if (e.target.value > 100) {
                  e.target.value = 100;
                }
                onHandle({ ...field, discount: e.target.value });
                // handleFieldError("discount")(e.target.value >= 0);
              }}
              InputProps={{
                inputProps: { min: 0, max: 100 },
                inputComponent: NumberFormatDiscount
              }}
            // errorOnChange={handleFieldError("discount")}
            />
          </CCTooltip>

          <CCTextField
            required
            variant={"outlined"}
            margin={"dense"}
            label={translate(intl, "common.ui.payOn")}
            value={field.paymentDate || ""}
            error={!field.paymentDate}
            className={classes.width171}
            onChange={(e) => {
              handleFieldError("paymentDate")(!Boolean(e.target.value));
              onHandle({ ...field, paymentDate: e.target.value });
            }}
            // errorOnChange={handleFieldError("paymentDate")}
            select
          >
            {paymentDateList.map(item => (
              <MenuItem
                key={item.value}
                value={item.value}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {item.label}
              </MenuItem>
            ))}
          </CCTextField>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClinicInfoField;
