import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#00897b1a",
    color: "white",
    zIndex: props => {
      if (props.type === "permission") return theme.zIndex.permissionBackdrop;
      if (props.type === "loading") return theme.zIndex.permissionBackdrop;
      return theme.zIndex.default;
    },
    justifyContent: "center"
  },
  box: {
    alignSelf: "center",
    userSelect: "none"
  }
}));

const Backdrop = props => {
  const { open, msg, className, onClick = () => {} } = props;
  const classes = useStyles(props);

  return (
    <>
      {open && (
        <Box className={clsx(classes.root, className)} onClick={onClick}>
          <Box className={classes.box}>{msg && msg}</Box>
        </Box>
      )}
    </>
  );
};

Backdrop.propTypes = {
  type: PropTypes.oneOf(["permission", "loading", undefined])
};

export default Backdrop;
